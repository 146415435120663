import * as React from "react";
import SectionsPageContainer from '../../containers/sections-page-container'
function SectionsPage(): React.ReactElement {
  return (
   <>
   <SectionsPageContainer/>
   </>
  );
}

export { SectionsPage };