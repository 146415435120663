import React, { useEffect, useRef, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { PiDotsThreeVertical, PiWarningCircle } from "react-icons/pi";
import FileAddModal from "./components/FileAddModal";
import Axios from "../../../../../services/axios";
import { FaPlus } from "react-icons/fa";
import FileItem from "./components/FileItem";
import { Button } from "../../../../../components/isbul-ui";
import { IoAdd } from "react-icons/io5";
import AbstractItem from "../save-abstract/components/AbstractItem";
import AbstractAddModal from "../save-abstract/components/AbstractAddModal";
import { dbUserAbstractType } from "../../../../../types";
import { useIsMobile } from "../../../../../hooks/useIsMobile";

export type fileType = {
  created_at: string;
  cv_id: number;
  description: string;
  filename: string;
  format: string;
  id: number;
  path: string;
  updated_at: string;
  user_id: number;
  original_name: string;
};

export type cvType = {
  id: number;
  title: string;
};

const axios = new Axios();

export default function SaveFile({
  isCvPage = false,
  isEnglish = false,
}: {
  isCvPage?: boolean;
  isEnglish?: boolean;
}) {
  const isMobile = useIsMobile();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isFileModalOpen, setIsFileModalOpen] = useState<boolean>(false);
  const [isAbstractModalOpen, setIsAbstractModalOpen] =
    useState<boolean>(false);
  const [isEditAbstractModalOpen, setIsEditAbstractModalOpen] =
    useState<boolean>(false);

  const [editId, setEditId] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // file states
  const [cvFiles, setCvFiles] = useState<fileType[]>([]);
  const [cvs, setCvs] = useState<cvType[]>([]);
  const [selectedFile, setSelectedFile] = useState<fileType | undefined>(
    undefined
  );

  //abstract states
  const [abstracts, setAbstracts] = useState<dbUserAbstractType[]>([]);
  const [selectedAbstract, setSelectedAbstract] = useState<
    dbUserAbstractType | undefined
  >(undefined);

  const toggleEdit = (id: number) => {
    setEditId(editId === id ? null : id);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setEditId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function getAllDatas() {
    getAllCvIds().then((data) => {
      getCvFiles(data).then((data) => {
        setCvFiles(data);
      });
      getAbstracts();
    });
  }

  useEffect(() => {
    getAllDatas();
  }, []);

  async function getAllCvIds(): Promise<number[]> {
    try {
      const response = await axios.userAuthRequest({
        endpoint: "user/profilim/cv/all",
        type: "get",
        payload: {},
      });

      if (response?.data?.data) {
        setCvs(
          response.data.data.map((d: any) => {
            return {
              id: d.id,
              title: d.title,
            };
          })
        );
        const cvIds: number[] = response.data.data.map((d: any) => {
          return d.id;
        });
        return cvIds;
      } else {
        return [];
      }
    } catch (error) {
      console.log(`Cv fetch failed. Error: ${error}`);
      return [];
    }
  }

  async function getCvFiles(cvIds: number[]): Promise<fileType[]> {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/files?cv_id=${cvIds[0].toString()}`,
        type: "get",
        payload: {},
      });

      if (response?.data?.files) {
        const files: fileType[] = JSON.parse(
          JSON.stringify(response.data.files)
        );
        return files;
      } else {
        return [];
      }
    } catch (error) {
      console.log(`Cv files fetch failed. Error: ${error}`);
      return [];
    }
  }

  async function getAbstracts() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: "user/profilim/text",
        type: "get",
        payload: {},
      });

      if (response && response.data && response.data.data) {
        setAbstracts(response.data.data);
      } else {
        setAbstracts([]);
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      setAbstracts([]);
    }
  }

  async function deleteCvFile(cv_id: number, fileId: number) {
    try {
      const formData = new FormData();

      formData.append("cv_id", cv_id.toString());
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/files/delete/${fileId}`,
        type: "delete",
        payload: formData,
      });

      getAllDatas();

      console.log("delete response", response);
    } catch (error) {
      console.log(`File delete failed. Error${error}`);
    }
  }

  const [activeTab, setActiveTab] = useState<"all" | "files" | "abstracts">(
    "all"
  );

  useEffect(() => {
    if (isMobile.isMobile) {
      setActiveTab("files");
    } else {
      setActiveTab("all");
    }
  }, [isMobile]);

  useEffect(() => {
    console.log({ isEditAbstractModalOpen });
  }, [isEditAbstractModalOpen]);

  return (
    <div className="px-2">
      <div className="flex lg:hidden flex-row items-center justify-between p-2 mt-2">
        <div className="flex flex-col gap-1">
          <button
            className={`text-sm font-poppins text-gray-800`}
            onClick={() => {
              setActiveTab("files");
            }}
          >
            {isEnglish ? "My Files" : "Dosyalarım"}
          </button>
          <hr
            className={`${
              activeTab == "files" ? "border-gray-800" : "border-transparent"
            }`}
          />
        </div>
        <div className="flex flex-col gap-1">
          <button
            className={`text-sm font-poppins text-gray-800`}
            onClick={() => {
              setActiveTab("abstracts");
            }}
          >
            {isEnglish ? "My Abstracts" : "Ön Yazılarım"}
          </button>
          <hr
            className={`${
              activeTab == "abstracts"
                ? "border-gray-800"
                : "border-transparent"
            }`}
          />
        </div>
      </div>

      {/* Files section */}
      {/* Burada lg:flex hidden flex olarak değiştirildi */}
      {activeTab != "abstracts" && (
        <>
          <div
            className={`lg:flex flex flex-col p-2 items-center w-full bg-white rounded-md ${
              isCvPage ? "shadow-none" : "shadow-lg"
            } `}
          >
            <div className="flex items-center justify-between w-full px-3 md:px-1 py-3 border-b">
              <span className="text-mains text-md poppins-semibold">
                {isEnglish ? "My Uploaded Files" : "Kayıtlı Dosyalarım"}
              </span>
              <Button
                size="sm"
                onClick={() => {
                  setSelectedFile((prev) => undefined);
                  setIsFileModalOpen(true);
                }}
              >
                <Button.Icon>
                  <IoAdd />
                </Button.Icon>
                {isEnglish ? "Add File" : "Dosya Ekle"}
              </Button>
              {/* <button className="bg-mains px-2 py-2 md:py-3 md:px-4 flex items-center gap-x-2 hover:bg-[#267ec1] duration-300 rounded-md" onClick={() => {
                setSelectedFile((prev) => undefined)
                setIsFileModalOpen(true)
              }}>
                <BsPlus className="text-xl font-medium text-white font-poppins" />
                <span className="font-medium text-white uppercase font-poppins text-sm md:text-base">
                  Dosya Ekle
                </span>
              </button> */}
              {isFileModalOpen && (
                <FileAddModal
                  cvs={cvs}
                  getAllDatas={getAllDatas}
                  setIsOpen={setIsFileModalOpen}
                  initialData={selectedFile}
                  isEnglish={isEnglish}
                />
              )}
            </div>
            {/* 
            TODO: Eski kısım silinecek
            <div className="flex flex-col gap-6 p-2 w-full">
              <h1 className="text-xl font-poppins text-mains">
                {isEnglish? 'Do you want to present yourself more effectively in your CV?': 'Özgeçmişinizde kendinizi daha fazla anlatmak mı istiyorsunuz?'}
              </h1>
              <ul className="list-disc list-inside marker:text-mains marker:text-xl text-gray-700 font-poppins text-sm">
                <li>
                  {isEnglish? 'Your self-prepared CV': 'Kendi hazırladığınız özgeçmişinizi'}
                </li>
                <li>
                  {isEnglish? 'Your projects, presentations, articles': 'Projelerinizi, sunumlarınızı, makalelerinizi'}
                </li>
                <li>
                  {isEnglish? 'Your education and certification documents': 'Eğitim ve sertifika belgelerinizi'}
                </li>
                <li>
                  {isEnglish? 'You can add your portfolio to your CV': 'Portfolyonuzu ekleyebilirsiniz'}
                </li>
              </ul>
            </div> */}

            {cvFiles.length == 0 && (
              <div className="flex flex-row gap-2 border border-gray-200 rounded-md mt-4 w-full">
                <div className="flex p-4 rounded-l-md items-center justify-center bg-red-600 min-h-full">
                  <PiWarningCircle className="text-3xl text-white" />
                </div>
                <div className="flex flex-col gap-1 h-full justify-center py-2 items-start">
                  <label className="font-poppins font-semibold text-gray-700 text-sm">
                    {isEnglish
                      ? "No uploaded files found."
                      : "Kayıtlı dosya bulunamadı."}
                  </label>
                  {isEnglish ? (
                    <label className="font-poppins font-semibold text-gray-700 text-sm">
                      You can add new file with{" "}
                      <button
                        className="text-mains underline"
                        onClick={() => {
                          setSelectedFile((prev) => undefined);
                          setIsFileModalOpen(true);
                        }}
                      >
                        Add File
                      </button>{" "}
                      button.
                    </label>
                  ) : (
                    <label className="font-poppins font-semibold text-gray-700 text-sm">
                      <button
                        className="text-mains underline underline-offset-4"
                        onClick={() => {
                          setSelectedFile((prev) => undefined);
                          setIsFileModalOpen(true);
                        }}
                      >
                        Dosya Ekle'ye
                      </button>
                      &nbsp;tıklayarak CV'nize dosya ekleyebilirsiniz.
                    </label>
                  )}
                </div>
              </div>
            )}

            {cvFiles.length > 0 && (
              <div className="h-10 w-full px-3 md:px-4 py-2 hidden lg:flex justify-between items-center hover:bg-[#ebebeb] duration-100">
                <div className="flex items-center w-full basis-4/12">
                  <span className="flex justify-start w-full text-xs poppins-semibold text-mains ">
                    {isEnglish ? "File Name" : "Dosya Adı"}
                  </span>
                </div>
                <span className="flex justify-start w-full text-xs poppins-semibold text-mains basis-3/12">
                  {isEnglish ? "Description" : "Açıklama"}
                </span>
                <span className="flex justify-start w-full text-xs poppins-semibold text-mains basis-3/12">
                  {isEnglish ? "CV" : "Özgeçmiş"}
                </span>
                <span className="flex justify-end w-full text-xs poppins-semibold text-mains basis-2/12">
                  {isEnglish ? "Actions" : "İşlemler"}
                </span>
              </div>
            )}

            {cvFiles.map((file) => {
              return (
                <FileItem
                  key={`cvFileItem${file.id}`}
                  file={file}
                  setIsModalOpen={setIsFileModalOpen}
                  setSelectedFile={setSelectedFile}
                  cvs={cvs}
                  getAllDatas={getAllDatas}
                  isEnglish={isEnglish}
                />
              );
            })}
          </div>
          {isModalOpen && (
            <FileAddModal
              getAllDatas={() => {}}
              cvs={[]}
              setIsOpen={setIsModalOpen}
            />
          )}
        </>
      )}

      {activeTab != "files" && (
        <>
          {isAbstractModalOpen && (
            <AbstractAddModal
              setIsOpen={setIsAbstractModalOpen}
              getAllDatas={getAllDatas}
              selectedAbstract={selectedAbstract}
            />
          )}
          {isEditAbstractModalOpen && (
            <AbstractAddModal
              isEdit={true}
              setIsOpen={setIsEditAbstractModalOpen}
              getAllDatas={getAllDatas}
              selectedAbstract={selectedAbstract}
            />
          )}

          <div
            className={`lg:flex flex flex-col p-2 items-center w-full bg-white rounded-md ${
              isCvPage ? "shadow-none" : "shadow-lg"
            } mt-6`}
          >
            <div className="flex items-center justify-between w-full px-3 md:px-1 py-3 border-b">
              <span className="text-mains text-md poppins-semibold">
                {isEnglish ? "My Saved Abstracts" : "Kayıtlı Ön Yazılarım"}
              </span>
              <Button
                size="sm"
                onClick={() => {
                  setSelectedAbstract((prev) => undefined);
                  setIsAbstractModalOpen(true);
                }}
              >
                <Button.Icon>
                  <IoAdd />
                </Button.Icon>
                {isEnglish ? "Add Abstract" : "Ön Yazı Ekle"}
              </Button>
            </div>

            {abstracts.length == 0 && (
              <div className="flex flex-row gap-2 border border-gray-200 rounded-md mt-4 w-full">
                <div className="flex p-4 rounded-l-md items-center justify-center bg-red-600 min-h-full">
                  <PiWarningCircle className="text-3xl text-white" />
                </div>
                <div className="flex flex-col gap-1 h-full justify-center py-2 items-start">
                  <label className="font-poppins font-semibold text-gray-700 text-sm">
                    {isEnglish
                      ? "No uploaded abstract found."
                      : "Kayıtlı ön yazınız bulunamadı."}
                  </label>
                  {isEnglish ? (
                    <label className="font-poppins font-semibold text-gray-700 text-sm">
                      You can add new abstract with{" "}
                      <button
                        className="text-mains underline"
                        onClick={() => {
                          setSelectedAbstract((prev) => undefined);
                          setIsAbstractModalOpen(true);
                        }}
                      >
                        Add Abstract
                      </button>{" "}
                      button.
                    </label>
                  ) : (
                    <label className="font-poppins font-semibold text-gray-700 text-sm">
                      <button
                        className="text-mains"
                        onClick={() => {
                          setSelectedAbstract((prev) => undefined);
                          setIsAbstractModalOpen(true);
                        }}
                        style={{ textDecoration: "underline" }}
                      >
                        Ön Yazı Ekle'ye
                      </button>
                      &nbsp;tıklayarak CV'nize ön yazı ekleyebilirsiniz.
                    </label>
                  )}
                </div>
              </div>
            )}

            {abstracts.length > 0 && (
              <div className="hidden lg:flex h-10 w-full px-3 md:px-4 py-2 items-center justify-between hover:bg-[#ebebeb] duration-100">
                <span className="flex justify-start w-full text-xs truncate poppins-semibold text-mains basis-4/12">
                  {isEnglish ? 'Abstract name' : 'Ön Yazı Adı'}
                </span>

                <span className="flex justify-start w-full text-xs poppins-semibold text-mains basis-4/12">
                  {isEnglish ? 'Abstract' : 'İçerik'}
                </span>

                <span className="flex justify-end w-full text-xs poppins-semibold text-mains basis-4/12">
                  {isEnglish ? 'Settings' : 'İşlemler'}
                </span>
              </div>
            )}

            {abstracts.map((item) => (
              <AbstractItem
                key={`absItem${item.id}`}
                abstract={item}
                getAllDatas={getAllDatas}
                setSelectedAbstract={setSelectedAbstract}
                setIsModalOpen={setIsEditAbstractModalOpen}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
