import { LabelHTMLAttributes } from "react";
import { useContext } from "react";
import { InputAreaContext, inputAreaContextType } from ".";
import classNames from "classnames";






type labelPropsType = LabelHTMLAttributes<HTMLLabelElement> & {

}






export default function Label({
    className,
    children,
    ...props
}: labelPropsType) {

    const { required }: inputAreaContextType = useContext(InputAreaContext)

    const cn = classNames({
        'text-gray-700 font-semibold font-poppins text-[13px]': true
    })
    return(
        <label
        className={`${cn} ${className}`}
        {...props}
        >
            {children} {required && <b className="text-red-600">*</b>}
        </label>
    )
}