import React, { useState, useEffect } from "react";
import Axios from "../../../../../../services/axios";
import { useCookie } from "../../../../../../hooks/useCookie";
import { CookieName } from "../../../../../../constants/enums";
import Swal from "sweetalert2";
import { IoClose } from "react-icons/io5";
import { InputArea } from "../../../../../../components/isbul-ui";

const axios = new Axios();

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function CustomModal({ isOpen, onClose }: CustomModalProps) {
  const [title, setTitle] = useState("");
  const [counter, setCounter] = useState<number>(0);
  const [errorState, setErrorState] = useState<boolean>(false);

  const { getCookie } = useCookie();
  const companyUserToken = getCookie(CookieName.CompanyUserToken);

  //fetch folders
  useEffect(() => {
    const createCvFolder = async () => {
      if (counter === 0) return;
      try {
        await axios.createCvFolder(title, companyUserToken);
        Swal.fire({
          icon: "success",
          title: "Klasör Başarıyla Oluşturuldu",
          showConfirmButton: true,
        });
        onClose();
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Klasör Eklenemedi",
          showConfirmButton: true,
        });
        throw new Error("Error create Cv Folder: " + error);
      }
    };

    createCvFolder();
  }, [counter]);

  const handleSubmit = async () => {
    if (title.trim() == "") {
      setErrorState(true);
      return;
    }
    setErrorState(false);
    setCounter(counter + 1);
  };

  const handleClose = () => {
    setErrorState(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-600">
      <div className="fixed inset-0 bg-gray-900 opacity-75"></div>
      <div className="absolute bg-white rounded-lg px-6 py-4 min-w-[80%] sm:min-w-[500px]">
        <div className="flex items-center justify-between  mb-5 border-b pb-3">
          <h1 className="text-xl text-primary200  scale-x-105 ml-5  ">
            Klasör Oluştur
          </h1>
          <span
            className=" text-2xl  text-gray-500 cursor-pointer    "
            onClick={handleClose}
          >
            <IoClose />
          </span>
        </div>

        <div className="w-full   px-2 pb-2  text-left">
          <InputArea
            error={errorState}
            className="w-full"
            required
            mode={title.length == 0 ? "empty" : "dirty"}
          >
            <InputArea.Label>Başlık</InputArea.Label>
            <InputArea.Input
              value={title || ""}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
            {errorState && (
              <InputArea.Error>Başlık Alanı Girin</InputArea.Error>
            )}
          </InputArea>
        </div>

        <div className="flex items-center justify-end gap-5   border-t mt-3 pt-3">
          <button
            className="bg-gry200/30  text-black px-6 py-2 text-xl  rounded-md mt-2 "
            onClick={handleClose}
          >
            Kapat
          </button>
          <button
            type="button"
            className="bg-primary200 text-white px-6 py-2 text-xl  rounded-md mt-2 "
            onClick={handleSubmit}
          >
            Kaydet
          </button>
        </div>
      </div>
    </div>
  );
}

export { CustomModal };
