import React, { useEffect } from 'react';
import CustomAccordion from '../../../../components/common/custom-accordion';
import { useFormik } from 'formik';
import { CgSpinner } from 'react-icons/cg';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import FormField from '../../../../components/common/form-field';
import Axios from '../../../../services/axios';
import { CustomButton } from '../../../../components/common/custom-button';

type formField = {
  label: string;
  name: string;
  required?: boolean;
  type?: string;
  [key: string]: any; //
};
const axios = new Axios();

function CompanySmsNotification() {
  const { companyUser } = useSelector((state: any) => state.companyUser);

  const updateNotification = async (values: { [key: string]: string }) => {
    try {
      await axios
        .authRequest({
          type: 'put',
          endpoint: 'companies/account-settings/sms-notification-update',
          payload: {
            _method: 'PUT',
            ...values,
          },
        })
        .then(() => {
          toast.success('Sms bildirim seçimleriniz başarıyla güncellendi.');
        });
    } catch (error: any) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.errors) {
        // Check if errors exist in the response
        const apiErrors = error.response.data.errors;
        // Iterate through the errors and display them using toast
        Object.keys(apiErrors).forEach((key) => {
          apiErrors[key].forEach((errorMessage: string) => {
            toast.error(errorMessage);
          });
        });
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        // If there is a single error message
        toast.error(error.response.data.error);
      } else {
        // If no specific error message received
        toast.error('An error occurred while changing password');
      }

      // Redirect to login if token is invalid
      if (
        error.response &&
        error.response.data &&
        error.response.data.error === 'Token is invalid'
      ) {
        window.location.href = '/company/login';
      }
    }
  };
  const formFields: formField[] = [
    {
      label: 'SMS bildirimi almak istiyor musun?',
      name: 'sms_general_notification',
      type: 'checkbox',
    },
  ];

  const initialValues = formFields.reduce((acc, field) => {
    acc[field.name] = 0;
    return acc;
  });

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      await updateNotification(values);
      setSubmitting(false);
    },
  });
  const showError = () => {
    if (formik.errors && Object.keys(formik.errors).length > 0) {
      Object.keys(formik.errors).forEach((key) => {
        toast.error(formik.errors[key]?.toString());
      });
    }
  };

  useEffect(() => {
    if (companyUser) {
      formFields.forEach((field) => {
        if (companyUser[field.name] === 1) {
          formik.setFieldValue(field.name, 1);
        }
      });
    }
  }, [companyUser]);

  return (
    <CustomAccordion
      title="SMS Bildirimleri   "
      subtitle="İsbul.net'ten bilgilendirme SMS’leri alma konusunda tercihini belirleyebilirsin.">
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 gap-4 max-w-[75%] m-auto">
          {formFields.map((field, index) => (
            <FormField
              onChange={(e) => {
                formik.setFieldValue(field.name, e ? 1 : 0);
              }}
              key={index}
              label={field.label}
              name={field.name}
              type={field.type}
              checked={formik.values[field.name]}
            />
          ))}
          <div className="flex justify-end">
          <CustomButton
            onClick={() => showError()}
            className="bg-blue-700 px-5 py-2 mt-4 text text-white rounded "
            type="submit"
            isSubmitting={formik.isSubmitting}>
            Kaydet
          </CustomButton>
        </div>
        </div>
        
      </form>
    </CustomAccordion>
  );
}

export default CompanySmsNotification;
