import { GeneralPackages } from "../../login-page-container/company/login/general-packages";










export default function PacketPageContainer() {
    return(
        <div className="container mx-auto">
            <GeneralPackages/>
        </div>
    )
}