import { SelectComponent } from "../../../components/select-component-new";
import { RadioComponent } from "../../../components/radio-component-new";
import useFetchCdnQuery from "../../../../../../../../hooks/useFetchCdnQuery";
import React, { useContext, useEffect, useState } from "react";
import {
  CandidatePoolContext,
  CandidatePoolFilterProps,
} from "../../../../../index";
import MultiSelect from "../../../components/multi-select-component-new";

interface PersonalInfoSectionProps {
  isVisible: boolean;
}

function PersonalInfoSection({ isVisible }: PersonalInfoSectionProps) {
  const [showMilitaryStatus, setShowMilitaryStatus] = useState(false);
  const { newSelectedValues, setNewSelectedValues } =
    useContext(CandidatePoolContext);

  //DRIVING LICANCES SELECT DATA
  const [drivingLicensesData, setDrivingLicensesData] = useState<any>([]);
  const [drivingLicancesSearchTerm, setDrivingLicancesSearchTerm] =
    useState("");
  const drivingLicensesQuery = useFetchCdnQuery(
    "driving-license-classes",
    "get-driving-licence-classes",
    "driving_license_classes",
    drivingLicancesSearchTerm
  );
  useEffect(() => {
    if (drivingLicensesQuery.data !== undefined) {
      setDrivingLicensesData([...drivingLicensesQuery?.data]);
    }
  }, [drivingLicensesQuery.data, drivingLicensesQuery.fetchStatus]);

  //SRC SELECT DATA
  const [srcData, setSrcData] = useState<any>([]);
  const [drivingLicancesSrcSearchTerm, setDrivingLicancesSrcSearchTerm] =
    useState("");
  const srcQuery = useFetchCdnQuery(
    "get-driving-licence-srcs",
    "get-driving-licence-srcs",
    "driving_license_srcs",
    drivingLicancesSrcSearchTerm
  );
  useEffect(() => {
    if (srcQuery.data !== undefined) {
      setSrcData([...srcQuery?.data]);
    }
  }, [srcQuery.data, srcQuery.fetchStatus]);

  //SRC SELECT DATA
  const [genderData, setGenderData] = useState<any>([]);
  const genderQuery = useFetchCdnQuery("genders", "get-genders", "genders");
  useEffect(() => {
    if (genderQuery.data !== undefined) {
      setGenderData([...genderQuery?.data]);
    }
  }, [genderQuery.data, genderQuery.fetchStatus]);

  //day data
  const dayData: any[] = [];
  for (let i = 1; i <= 31; i++) {
    dayData.push({ label: `${i}`, id: i });
  }
  //month data
  const monthNames = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];
  const monthData: any = [];
  for (let i = 0; i < 12; i++) {
    monthData.push({ label: monthNames[i], id: i + 1 });
  }
  //year data
  const currentYear = new Date().getFullYear();
  const startYear = 2013;

  const yearData: any = [];
  for (let year = currentYear; year >= startYear; year--) {
    yearData.push({ label: year.toString(), id: year });
  }

  //martial status data
  const martialStatusData: any = [
    { label: "Fark Etmez", id: 0 },
    { label: "Evli", id: 1 },
    { label: "Bekar", id: 2 },
  ];

  //smoke status data
  const smokeStatusData: any = [
    { label: "Fark Etmez", id: 0 },
    { label: "Evet", id: 1 },
    { label: "Hayır", id: 2 },
  ];

  //driving license data
  const drivingLicenseData: any = [
    { label: "Fark Etmez", id: 0 },
    { label: "Var", id: 1 },
    { label: "Yok", id: 2 },
  ];

  const militaryStatusData: any = [
    { label: "Yapıldı", id: 1 },
    { label: "Muaf", id: 2 },
    { label: "Tecilli", id: 3 },
    { label: "Belirtilmemiş", id: 4 },
  ];

  //year gap data
  const yearGapData: any = [];
  for (let age = 15; age <= 80; age++) {
    yearGapData.push({ label: `${age}`, id: age });
  }

  //year gap data
  const maxyearGapData = [];

  // Find min_age in selectedValues array
  const startingAge = newSelectedValues.min_age
    ? newSelectedValues.min_age
    : 15;

  if (startingAge) {
    for (let age = +startingAge + 1; age <= 80; age++) {
      maxyearGapData.push({ label: `${age}`, id: age });
    }
  }

  useEffect(() => {
    if (newSelectedValues.gender === 1) {
      setShowMilitaryStatus(true);
    } else {
      setShowMilitaryStatus(false);
    }
  }, [newSelectedValues.gender]);

  //return <></>;
  return (
    <section
      className={`text-gray-600 mx-1 my-8 md:mx-2 ${isVisible ? "" : "hidden"}`}
    >
      <p className="my-1">Özgeçmiş Güncelleme Tarihi</p>
      <div className="flex gap-5 md:w-1/2 ">
        <SelectComponent
          data={dayData}
          keyString={"label"}
          label=""
          placeholder="Gün"
          onChange={(value) => {
            let newValues: CandidatePoolFilterProps = {
              ...newSelectedValues,
              cv_update_date_day: value as number,
            };
            if (value === 0) {
              delete newValues.cv_update_date_day;
            }
            setNewSelectedValues(newValues);
          }}
          selectedvalue={newSelectedValues.cv_update_date_day}
        />
        <SelectComponent
          keyString={"label"}
          data={monthData}
          label=""
          placeholder="Ay"
          onChange={(value) => {
            let newValues: CandidatePoolFilterProps = {
              ...newSelectedValues,
              cv_update_date_month: value as number,
            };
            if (value === 0) {
              delete newValues.cv_update_date_month;
            }
            setNewSelectedValues(newValues);
          }}
          selectedvalue={newSelectedValues.cv_update_date_month}
        />
        <SelectComponent
          keyString={"label"}
          data={yearData}
          label=""
          placeholder="Yıl"
          onChange={(value) => {
            let newValues: CandidatePoolFilterProps = {
              ...newSelectedValues,
              cv_update_date_year: value as number,
            };
            if (value === 0) {
              delete newValues.cv_update_date_year;
            }
            setNewSelectedValues(newValues);
          }}
          selectedvalue={newSelectedValues.cv_update_date_year}
        />
      </div>

      <div className={"md:flex md:flex-row gap-5"}>
        <div className="flex flex-col md:flex-row gap-5 my-3 md:w-1/3 ">
          <SelectComponent
            keyString={"name"}
            data={genderData}
            label="Cinsiyet"
            onChange={(value) => {
              let newValues: CandidatePoolFilterProps = {
                ...newSelectedValues,
                gender: value as number,
              };
              if (value === 0) {
                delete newValues.gender;
              }
              setNewSelectedValues(newValues);
            }}
            selectedvalue={newSelectedValues.gender}
          />
        </div>

        {showMilitaryStatus && (
          <div className="flex flex-col md:flex-row gap-5 my-3 md:w-1/3 ">
            <SelectComponent
              keyString={"label"}
              data={militaryStatusData}
              label="Askerlik Durumu"
              onChange={(value) => {
                let newValues: CandidatePoolFilterProps = {
                  ...newSelectedValues,
                  military_status: value as number,
                };
                if (value === 0) {
                  delete newValues.military_status;
                }
                setNewSelectedValues(newValues);
              }}
              selectedvalue={newSelectedValues.military_status}
            />
          </div>
        )}
      </div>

      <div className="flex flex-row md:flex-row gap-5 my-3 md:w-1/3 ">
        <SelectComponent
          keyString={"label"}
          data={yearGapData}
          label="Yaş Aralığı / Min"
          onChange={(value) => {
            let newValues: CandidatePoolFilterProps = {
              ...newSelectedValues,
              min_age: value as number,
            };
            if (value === 0) {
              delete newValues.min_age;
            }
            setNewSelectedValues(newValues);
          }}
          selectedvalue={newSelectedValues.min_age}
        />
        <SelectComponent
          keyString={"label"}
          data={maxyearGapData}
          label="Yaş Aralığı / Max"
          onChange={(value) => {
            let newValues: CandidatePoolFilterProps = {
              ...newSelectedValues,
              max_age: value as number,
            };
            if (value === 0) {
              delete newValues.max_age;
            }
            setNewSelectedValues(newValues);
          }}
          selectedvalue={newSelectedValues.max_age}
        />
      </div>

      <div className="flex justify-between flex-col md:flex-row  max-w-[800px]">
        <RadioComponent
          id={"medenihal"}
          keyString={"label"}
          header="Medeni Hal"
          data={martialStatusData}
          onChange={(value) => {
            let newValues: CandidatePoolFilterProps = {
              ...newSelectedValues,
              marital_status: value,
            };
            if (value === 0) {
              delete newValues.marital_status;
            }
            setNewSelectedValues(newValues);
          }}
          selected={newSelectedValues.marital_status}
        />

        <RadioComponent
          id={"sigarause"}
          keyString={"label"}
          header="Sigara Kullanımı"
          data={smokeStatusData}
          onChange={(value) => {
            let newValues: CandidatePoolFilterProps = {
              ...newSelectedValues,
              smoke_status: value,
            };
            if (value === 0) {
              delete newValues.smoke_status;
            }
            setNewSelectedValues(newValues);
          }}
          selected={newSelectedValues.smoke_status}
        />
      </div>

      <div className="flex flex-col items-start gap-1  md:mr-20">
        <RadioComponent
          id={"ehliyet"}
          keyString={"label"}
          header="Ehliyet"
          data={drivingLicenseData}
          onChange={(value) => {
            let newValues: CandidatePoolFilterProps = {
              ...newSelectedValues,
              driving_licence_status: value,
            };
            if (value === 0) {
              delete newValues.driving_licence_status;
            }
            setNewSelectedValues(newValues);
          }}
          selected={newSelectedValues.driving_licence_status}
        />

        {newSelectedValues.driving_licence_status === 1 && (
          <div className=" w-full md:pr-[20rem] lg:pr-[40rem] mb-2 flex flex-col gap-2 pt-3 ">
            <MultiSelect
              isFetching={drivingLicensesQuery.isFetching}
              keyString={"name"}
              data={drivingLicensesData}
              label="Ehliyet Sınıfı"
              onChange={(value) => {
                let newValues: CandidatePoolFilterProps = {
                  ...newSelectedValues,
                  driving_licence_classes: value,
                };
                if (value.length <= 0) {
                  delete newValues.driving_licence_classes;
                }
                setNewSelectedValues(newValues);
              }}
              selectedValues={
                newSelectedValues.driving_licence_classes
                  ? newSelectedValues.driving_licence_classes
                  : []
              }
              setSearchData={setDrivingLicancesSearchTerm}
              searchData={drivingLicancesSearchTerm}
            />

            <MultiSelect
              isFetching={srcQuery.isFetching}
              keyString={"name"}
              data={srcData}
              label="SRC Belgesi"
              onChange={(value) => {
                let newValues: CandidatePoolFilterProps = {
                  ...newSelectedValues,
                  driving_licence_srcs: value,
                };
                if (value.length <= 0) {
                  delete newValues.driving_licence_srcs;
                }
                setNewSelectedValues(newValues);
              }}
              selectedValues={
                newSelectedValues.driving_licence_srcs
                  ? newSelectedValues.driving_licence_srcs
                  : []
              }
              setSearchData={setDrivingLicancesSrcSearchTerm}
              searchData={drivingLicancesSrcSearchTerm}
            />
          </div>
        )}
      </div>
    </section>
  );
}

export { PersonalInfoSection };
