import Axios from "../services/axios"
import { cdnDistrictType } from "../types"






const axios = new Axios()

type getDistrictsPropsType = {
    search?: string,
    with_all?: 0 | 1,
    limit?: number,
    city_id?: number,
    only_ids?: number[]
}



export async function getDistricts({
    search,
    with_all,
    limit,
    city_id,
    only_ids
}: getDistrictsPropsType): Promise<cdnDistrictType[]> {
    try {

        const response = await axios.request({
            endpoint: `cdn/get-districts?limit=100?
            ${search? `search=${search}&`: ''}
            ${with_all? `with_all=${with_all}&`: ''}
            ${limit? `limit=${limit}&`: ''}
            ${city_id? `city_id=${city_id}&`: ''}
            ${only_ids? `only_ids=${only_ids}&`: ''}
            `,
            type: 'get',
            payload: {}
        })

        if (response && response.data && response.data.districts) {
            return JSON.parse(JSON.stringify(response.data.districts))
        } else {
            return []
        }
        
    } catch (error) {
        console.log(`Districts fetch failed. Error: ${error}`)
        return []
    }
}