import { ButtonHTMLAttributes } from "react"
import { useContext, MouseEvent } from "react"
import { SelectDropdownContext, selectDropdownContextType } from "."
import classNames from "classnames"



type itemPropsType = ButtonHTMLAttributes<HTMLButtonElement> & {

}






export default function Item({
    type = 'button',
    className,
    onClick,
    ...props
}: itemPropsType) {

    const { setIsOpen }: selectDropdownContextType = useContext(SelectDropdownContext)

    const cn = classNames({
        'p-2 text-start hover:bg-gray-100 transition-all': true
    })
    
    return(
        <button 
        {...props} 
        type={type}
        className={`${cn} ${className}`}
        onClick={((e) => itemOnClick(e))}>

        </button>
    )

    function itemOnClick(e: MouseEvent<HTMLButtonElement>) {
        if (onClick) onClick(e)

        setIsOpen(false)
    }
}