import {
  IconBellFilled,
  IconMail,
  IconMenu2,
  IconPlus,
} from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";
import ProfileDownMenu from "../profile-down-menu";
import { ICompanyUser } from "../../../../../../types/ICompanyUser";
import { IoIosArrowDown } from "react-icons/io";
import { FaRegPenToSquare } from "react-icons/fa6";
import { IoMdPaperPlane } from "react-icons/io";
import { HiMiniArrowDownTray } from "react-icons/hi2";
import { BsPrinter } from "react-icons/bs";
import { TiArrowSortedUp } from "react-icons/ti";
import Axios from "../../../../../../services/axios";
import { CookieName } from "../../../../../../constants/enums";
import { useCookie } from "../../../../../../hooks/useCookie";
import Cookies from "js-cookie";
import { CiTrash } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { Notification } from "../../../../../../types/Notification";
import { onNotificationClick } from "../../../../../../lib/utils";

interface IHeaderRightButtonGroup {
  readonly type: "base" | "fixed";
  readonly companyUser: ICompanyUser;
  readonly userType: string;
}

const axios = new Axios();

const HeaderRightButtonGroup: React.FC<IHeaderRightButtonGroup> = (
  props: IHeaderRightButtonGroup
) => {
  const emptyAvatar =
    props.userType === "company"
      ? "https://isbull.s3.eu-north-1.amazonaws.com/default-images/company-logo.png?v=2.9.90"
      : "https://isbull.s3.eu-north-1.amazonaws.com/default-images/user-profile.png";
  const color = props.type === "base" ? "white" : "mains";

  const [userCvId, setUserCvId] = useState<number | null>(null);
  const userToken = Cookies.get("userToken");
  const { getCookie } = useCookie();
  const companyUserToken = getCookie(CookieName.CompanyUserToken);
  const [unreadNotifications, setUnreadNotifications] = useState<number>(0);
  const [cvAvatar, setCvAvatar] = useState<string | null>(null);
  const [companyCvAvatar, setCompanyCvAvatar] = useState<string | null>(null);
  const [notificationData, setNotificationData] = useState<Notification[]>([]);
  const notificationRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (userToken) {
      const fetchCv = async () => {
        try {
          const response = await axios.request({
            endpoint: `user/profilim/cv`,
            type: "get",
            payload: {},
          });

          if (response?.data?.data) {
            setCvAvatar(response.data.user.profile);
          }
        } catch (error) {
          console.log("Error fetching data:", error);
        }
      };

      fetchCv();
    }

    if (companyUserToken) {
      const fetchCompanyCv = async () => {
        try {
          const response = await axios.authRequest({
            endpoint: `companies/account-settings/get-setting-info`,
            type: "get",
            payload: {},
          });

          if (response?.data?.company) {
            setCompanyCvAvatar(response.data.company.logo);
          }
        } catch (error) {
          console.log("Error fetching data:", error);
        }
      };

      fetchCompanyCv();
    }
  }, [userToken, companyUserToken, axios]);

  useEffect(() => {
    async function getCvId() {
      try {
        const response = await axios.userAuthRequest({
          endpoint: `user/profilim/cv`,
          type: "get",
          payload: {},
        });

        if (response && response.data && response.data.data.id) {
          setUserCvId(response.data.data.id ?? null);
        }
      } catch (error) {
        console.log(`User CV fetch failed. Error: ${error}`);
      }
    }

    async function getUnreadNotifications() {
      try {
        const response = await axios.userAuthRequest({
          endpoint: "user/profilim/notification/get-unread-count",
          type: "get",
          payload: {},
        });

        if (response && response.data && response.data.count) {
          setUnreadNotifications(response.data.count);
        } else {
          setUnreadNotifications(0);
        }
      } catch (error) {
        console.log(`Error: ${error}`);
        setUnreadNotifications(0);
      }
    }

    if (userToken) {
      Promise.all([getCvId(), getUnreadNotifications()]);
    }
  }, [userToken]);

  const [remainingAdvertCount, setRemainingAdvertCount] = useState<number>(0);

  React.useEffect(() => {
    const fetchHeaderCardItems = async () => {
      if (companyUserToken) {
        try {
          const response = await axios.request({
            type: "get",
            endpoint: "companies/home/get-count-dashboard",
            payload: {
              headers: {
                Authorization: `Bearer ${companyUserToken}`,
              },
            },
          });

          console.log(response);

          if (response && response.data && response.data.data) {
            setRemainingAdvertCount(
              response.data.data.right_to_create_advert_count
            );
          }
        } catch (error) {
          throw new Error("Error fetching header card items: " + error);
        }
      }
    };

    async function getCompanyUnreadNotifications() {
      try {
        const response = await axios.authRequest({
          endpoint: "companies/notification/get-unread-count",
          type: "get",
          payload: {},
        });

        if (response && response.data && response.data.count) {
          setUnreadNotifications(response.data.count);
        } else {
          setUnreadNotifications(0);
        }
      } catch (error) {
        console.log(`Error: ${error}`);
        setUnreadNotifications(0);
      }
    }

    fetchHeaderCardItems();
    if (companyUserToken) {
      getCompanyUnreadNotifications();
    }
  }, [companyUserToken]);

  const [notificationModalOpen, setNotificationModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (userToken) {
      const fetchNotifications = async () => {
        try {
          const response = await axios.userAuthRequest({
            endpoint: `user/profilim/notification/get-all`,
            type: "get",
            payload: {},
          });

          console.log(response);

          if (response && response.data && response.data.notifications) {
            setNotificationData(response.data.notifications);
          } else {
            setNotificationData([]);
          }
        } catch (error) {
          console.log(`Error: ${error}`);
        }
      };
      fetchNotifications();
    }

    if (companyUserToken) {
      const fetchCompanyNotifications = async () => {
        try {
          const response = await axios.authRequest({
            endpoint: `companies/notification/get-all`,
            type: "get",
            payload: {},
          });

          console.log(response);

          if (response && response.data && response.data.notifications) {
            setNotificationData(response.data.notifications);
          } else {
            setNotificationData([]);
          }
        } catch (error) {
          console.log(`Error: ${error}`);
        }
      };
      fetchCompanyNotifications();
    }
  }, [userToken, companyUserToken]);

  const handleDelete = async (id: number) => {
    const response = await axios.userAuthRequest({
      type: "delete",
      endpoint: `user/profilim/notification/delete/${id}`,
      payload: "",
    });

    const newNotificationData = notificationData.filter(
      (notification) => notification.id !== id
    );

    setNotificationData(newNotificationData);
  };

  const handleAllDelete = async () => {
    const response = await axios.userAuthRequest({
      type: "delete",
      endpoint: `user/profilim/notification/delete-all`,
      payload: "",
    });

    console.log(response, "response");

    setNotificationData([]);
  };

  const handleCompanyDelete = async (id: number) => {
    const response = await axios.authRequest({
      type: "delete",
      endpoint: `companies/notification/delete/${id}`,
      payload: "",
    });

    const newNotificationData = notificationData.filter(
      (notification) => notification.id !== id
    );

    setNotificationData(newNotificationData);
  };

  const handleCompanyAllDelete = async () => {
    const response = await axios.authRequest({
      type: "delete",
      endpoint: `companies/notification/delete-all`,
      payload: "",
    });

    console.log(response, "response");

    setNotificationData([]);
  };

  const isTodayNotifications = (date: string) => {
    const today = new Date();
    const dateObj = new Date(date);
    const isSameDay =
      dateObj.getDate() === today.getDate() &&
      dateObj.getMonth() === today.getMonth() &&
      dateObj.getFullYear() === today.getFullYear();

    return isSameDay ? "Yeni!" : "";
  };

  // Handle click outside to close notification modal
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target as Node) &&
        !buttonRef.current?.contains(event.target as Node)
      ) {
        setNotificationModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationRef, buttonRef]);

  const handleButtonClick = () => {
    setNotificationModalOpen((prev) => !prev);
  };

  console.log("notificationData", notificationData);

  const [isSideMenuOpen, changeStatusSıdeMenu] = useState<boolean>(false);

  return (
    <div className="h-full items-center py-5 hidden lg:flex gap-12 font-poppins">
      {props.userType === "company" ? (
        remainingAdvertCount > 0 ? (
          <a
            href="/isveren/ilanlar/olustur/ilan-bilgileri"
            className="text-white font-medium flex items-center bg-[#de5b2b] py-3 rounded-md"
          >
            <div className="h-full w-40 rounded-md bg-[#de5b2b] flex items-center justify-center cursor-pointer">
              <IconPlus className="text-white mr-2" />
              İlan Ekle
            </div>
          </a>
        ) : (
          <a
            href="/isveren/ürünler"
            className="text-white font-medium flex items-center bg-[#de5b2b] py-3 rounded-md"
          >
            <div className="h-full w-40 rounded-md bg-[#de5b2b] flex items-center justify-center cursor-pointer">
              <IconPlus className="text-white mr-2" />
              İlan Ekle
            </div>
          </a>
        )
      ) : (
        <>
          <div className="relative group">
            <div className="text-white font-medium flex items-center bg-[#de5b2b] py-2 rounded-md px-2">
              <div className="h-full w-48 rounded-md bg-[#de5b2b] flex items-center justify-center cursor-pointer gap-2">
                <span className="text-[15px] font-poppins">Özgeçmişlerim</span>
                <IoIosArrowDown className="text-white" />
              </div>
            </div>
            <div className="absolute top-[40px] p-2 bg-white w-full max-w-[207px] z-50 rounded-md shadow-xl hidden group-hover:block">
              <div className="relative">
                <div className="absolute top-[-26px] right-0">
                  <TiArrowSortedUp className="text-3xl text-white" />
                </div>
                <a
                  href={`/profilim/cv/duzenle/${userCvId}`}
                  className="font-poppins text-[14px] border-b px-1 py-[6px] whitespace-nowrap flex justify-start items-center gap-2"
                >
                  <FaRegPenToSquare />
                  Özgeçmişimi Düzenle
                </a>
                <a
                  href={`/profilim/cv/goruntule/${userCvId}?action=share`}
                  className="font-poppins text-[14px] border-b px-1 py-[6px] whitespace-nowrap flex justify-start items-center gap-2"
                >
                  <IoMdPaperPlane />
                  Özgeçmişimi Gönder
                </a>
                <a
                  href={`/profilim/cv/goruntule/${userCvId}?action=download`}
                  className="font-poppins text-[14px] border-b px-1 py-[6px] whitespace-nowrap flex justify-start items-center gap-2"
                >
                  <HiMiniArrowDownTray />
                  Özgeçmişimi İndir
                </a>
                <a
                  href={`/profilim/cv/goruntule/${userCvId}?action=print`}
                  className="font-poppins text-[14px] px-1 py-[6px] whitespace-nowrap flex justify-start items-center gap-2"
                >
                  <BsPrinter />
                  Özgeçmişimi Yazdır
                </a>
              </div>
            </div>
          </div>
        </>
      )}

      {companyUserToken ? (
        <div className="relative">
          <button
            ref={buttonRef}
            onClick={handleButtonClick}
            className="relative"
          >
            <IconBellFilled
              className={`text-${color} cursor-pointer mt-2`}
              size={30}
            />
            {unreadNotifications > 0 && (
              <div className="absolute flex -right-[6px] -top-[6px] w-[22px] h-[22px] bg-red-600 rounded-full items-center justify-center text-center text-[11px] text-white font-poppins">
                <label>
                  {unreadNotifications > 9 ? "+9" : unreadNotifications}
                </label>
              </div>
            )}
          </button>
          {notificationModalOpen && (
            <>
              <div
                ref={notificationRef}
                className={`bg-white absolute -left-[185px] top-12 z-[9999] min-w-[450px] max-h-[388px] ${
                  notificationData.length > 0 ? "overflow-y-scroll" : ""
                } rounded-lg shadow-lg`}
              >
                {notificationData.length > 0 ? (
                  <>
                    <div className="bg-[#2253AF] sticky top-0 text-white font-poppins poppins-medium whitespace-nowrap w-full h-12 flex justify-center items-center">
                      Sizin İçin Hazırladık
                    </div>

                    <div className="flex flex-col">
                      {notificationData.map((notification) => (
                        <div
                          key={notification.id}
                          onClick={() =>
                            onNotificationClick(notification, navigate)
                          }
                          className="flex justify-start items-center gap-4 p-3 border border-b cursor-pointer"
                        >
                          <div className="min-w-[40px]">
                            <img
                              className="rounded-full w-10 h-10"
                              src={notification.logo}
                              alt=""
                            />
                          </div>

                          <div className="flex flex-col justify-center items-start gap-1 w-full">
                            <p className="font-poppins poppins-semibold text-sm text-start text-mains">
                              {notification.title}
                            </p>

                            <p className="font-poppins poppins-medium text-xs text-start">
                              {notification.detail}
                            </p>
                          </div>

                          <div className="flex justify-center items-center gap-2">
                            <p className="font-poppins poppins-medium text-xs text-mains">
                              {isTodayNotifications(notification.created_at)}
                            </p>
                            <button
                              onClick={() => {
                                if (userToken) {
                                  handleDelete(notification.id);
                                }

                                if (companyUserToken) {
                                  handleCompanyDelete(notification.id);
                                }
                              }}
                            >
                              <CiTrash className="text-red-500" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div
                      onClick={() => {
                        if (userToken) {
                          handleAllDelete();
                        }

                        if (companyUserToken) {
                          handleCompanyAllDelete();
                        }
                      }}
                      className="flex justify-center items-center bg-[#E5E6EC] w-full text-sm font-poppins poppins-medium sticky bottom-0 h-10 cursor-pointer"
                    >
                      Bildirimleri Temizle
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col justify-center items-center h-20 text-mains font-poppins poppins-medium relative">
                    <div className="w-full bg-mains h-5 absolute top-0"></div>
                    <div className="flex justify-center items-center mt-4">
                      Bildirim Bulunamadı
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <div className="relative">
            <button
              ref={buttonRef}
              onClick={handleButtonClick}
              className="relative"
            >
              <IconBellFilled
                className={`text-${color} cursor-pointer mt-2`}
                size={30}
              />
              {unreadNotifications > 0 && (
                <div className="absolute flex -bottom-1 -right-1 w-[22px] h-[22px] bg-red-600 rounded-full items-center justify-center text-center text-[10px] text-white font-poppins">
                  <label>
                    {unreadNotifications > 9 ? "+9" : unreadNotifications}
                  </label>
                </div>
              )}
            </button>
            {notificationModalOpen && (
              <>
                <div
                  ref={notificationRef}
                  className={`bg-white absolute -left-[185px] top-12 z-[9999] min-w-[450px] max-h-[388px] ${
                    notificationData.length > 0 ? "overflow-y-scroll" : ""
                  } rounded-lg shadow-lg`}
                >
                  {notificationData.length > 0 ? (
                    <>
                      <div className="bg-[#2253AF] sticky top-0 text-white font-poppins poppins-medium whitespace-nowrap w-full h-12 flex justify-center items-center">
                        Sizin İçin Hazırladık
                      </div>

                      <div className="flex flex-col">
                        {notificationData.map((notification) => (
                          <div
                            key={notification.id}
                            onClick={() => onNotificationClick(notification, navigate)}
                            className="flex justify-start items-center gap-4 p-3 border border-b cursor-pointer"
                          >
                            <div className="min-w-[40px]">
                              <img
                                className="rounded-full w-10 h-10"
                                src={notification.logo}
                                alt=""
                              />
                            </div>

                            <div className="flex flex-col justify-center items-start gap-1 w-full">
                              <p className="font-poppins poppins-semibold text-sm text-start text-mains">
                                {notification.title}
                              </p>

                              <p className="font-poppins poppins-medium text-xs text-start">
                                {notification.detail}
                              </p>
                            </div>

                            <div className="flex justify-center items-center gap-2">
                              <p className="font-poppins poppins-medium text-xs text-mains">
                                {isTodayNotifications(notification.created_at)}
                              </p>
                              <button
                                onClick={() => {
                                  if (userToken) {
                                    handleDelete(notification.id);
                                  }

                                  if (companyUserToken) {
                                    handleCompanyDelete(notification.id);
                                  }
                                }}
                              >
                                <CiTrash className="text-red-500" />
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div
                        onClick={() => {
                          if (userToken) {
                            handleAllDelete();
                          }

                          if (companyUserToken) {
                            handleCompanyAllDelete();
                          }
                        }}
                        className="flex justify-center items-center bg-[#E5E6EC] w-full text-sm font-poppins poppins-medium sticky bottom-0 h-10 cursor-pointer"
                      >
                        Bildirimleri Temizle
                      </div>
                    </>
                  ) : (
                    <div className="flex flex-col justify-center items-center h-20 text-mains font-poppins poppins-medium relative">
                      <div className="w-full bg-mains h-5 absolute top-0"></div>
                      <div className="flex justify-center items-center mt-4">
                        Bildirim Bulunamadı
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}

      {companyUserToken ? (
        <a href="/isveren/mesajlar">
          <IconMail className={`text-${color} cursor-pointer`} size={30} />
        </a>
      ) : (
        <a href="/profilim/mesajlar">
          <IconMail className={`text-${color} cursor-pointer`} size={30} />
        </a>
      )}

      {props.companyUser?.name != undefined &&
        props.companyUser?.surname != undefined && (
          <div
            className="h-full flex items-center gap-4 cursor-pointer"
            onClick={() => changeStatusSıdeMenu(!isSideMenuOpen)}
          >
            {cvAvatar || companyCvAvatar ? (
              <img
                src={cvAvatar ?? companyCvAvatar ?? emptyAvatar}
                className="object-contain rounded-full border border-white h-3/4"
                alt="avatar"
              />
            ) : (
              <img
                src={emptyAvatar}
                className="object-contain rounded-full border border-white h-3/4"
                alt="avatar"
              />
            )}

            <div
              className={`text-${color} font-normal text-lg lg:max-w-[280px] xl:max-w-[148px] overflow-hidden text-ellipsis lg:text-center`}
            >
              {props.companyUser?.name + " " + props.companyUser?.surname}
            </div>
            <IconMenu2 className={`text-${color}`} size={25} />
            {props.userType === "company" ? (
              <ProfileDownMenu
                companyUser={props.companyUser}
                userType="company"
                isSideMenuOpen={isSideMenuOpen}
                setIsSideMenuOpen={changeStatusSıdeMenu} // Pass the setter function
              />
            ) : (
              <ProfileDownMenu
                companyUser={props.companyUser}
                userType="user"
                isSideMenuOpen={isSideMenuOpen}
                setIsSideMenuOpen={changeStatusSıdeMenu} // Pass the setter function
              />
            )}
          </div>
        )}
    </div>
  );
};

export default HeaderRightButtonGroup;
