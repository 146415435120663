import { ButtonHTMLAttributes, MouseEvent } from "react"
import { useContext } from "react"
import { MultiSelectDropdownContext, multiSelectDropdownContextType } from "."
import classNames from "classnames"
import { IoChevronDown } from "react-icons/io5"




type triggerPropsType = ButtonHTMLAttributes<HTMLButtonElement> & {

}






export default function Trigger({
    className,
    type = 'button',
    children,
    onClick,
    ...props
}: triggerPropsType) {

    const { isOpen, setIsOpen, mode, error }: multiSelectDropdownContextType = useContext(MultiSelectDropdownContext)

    const cn = classNames({
        'py-2 px-4 rounded-md font-poppins flex flex-row gap-2 items-center text-[14px] line-clamp-1 whitespace-nowrap': true,
        'border-gray-400 border text-gray-600': mode == 'empty',
        'border-blue-600 border text-blue-600': mode == 'dirty',
        'border-red-600 border text-red-600 bg-red-50': error == true,
        'formGlow border-blue-500 border': isOpen == true
    })

    
    return(
        <button
        {...props}
        type={type}
        className={`${cn} ${className}`}
        onClick={((e) => buttonOnClick(e))}
        >
            {children} <IoChevronDown className={`${isOpen? 'rotate-180': ''} transition-all ml-auto`}/>
        </button>
    )

    function buttonOnClick(e: MouseEvent<HTMLButtonElement>) {
        if (onClick) {
            onClick(e)
        }

        setIsOpen((prev) => !prev)
    }
}