import { TbCheck, TbChevronDown, TbChevronUp } from "react-icons/tb";
import { useEffect, useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/20/solid";
import { Switch } from "@headlessui/react";
import { toast } from "react-toastify";
import Axios from "../../../services/axios";
import { CgSpinner } from "react-icons/cg";
import { useSelector } from "react-redux";
import {
  getCompanyUser,
  sendUserVerifySms,
} from "../../../store/features/companyUserSlice";
import { useAppDispatch } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CustomDropdown from "../add-create-form/addDetail/content/custom-dropdown";

type Props = {
  label: React.ReactNode;
  type?: string;
  name?: string;
  value?: string;
  placeholder?: string;
  className?: string;
  required?: boolean;
  phoneVerified?: boolean;
  emailVerified?: boolean;
  onChange: (e: any) => void;
  options?: Option[];
  error?: string;
  valid?: boolean;
  invalid?: any;
  checked?: boolean | number;
  disabled?: boolean;
  phoneMaskType?: string;
};

type Option = {
  value: string;
  label: string;
};

const iconClass = "w-5 h-5 text-gray-700";
const MAX_LENGTH = 3000;
const axios = new Axios();

const FormField = (props: Props) => {
  const [password, setPassword] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);

  const {
    label,
    type,
    name,
    value,
    placeholder,
    className,
    required,
    phoneVerified,
    emailVerified,
    onChange,
    options,
    invalid,
    checked,
    disabled,
  } = props;

  const getInitialStateFromHTML = (html: string | null): EditorState => {
    if (!html || typeof html !== "string") {
      return EditorState.createEmpty();
    }

    try {
      const blocksFromHtml = htmlToDraft(html);
      if (blocksFromHtml.contentBlocks) {
        const contentState = ContentState.createFromBlockArray(
          blocksFromHtml.contentBlocks,
          blocksFromHtml.entityMap
        );
        return EditorState.createWithContent(contentState);
      }
    } catch (error) {
      console.error("HTML'i Draft'a dönüştürme hatası:", error);
    }

    return EditorState.createEmpty();
  };

  const [isFocused, setIsFocused] = useState(false);
  const [icon, setIcon] = useState(<TbChevronDown className={iconClass} />);
  const [verifyingEmail, setVerifyingEmail] = useState(false);
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [charCount, setCharCount] = useState(0);
  const [editorEmpty, setEditorEmpty] = useState(true);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { companyUser } = useSelector((state: any) => state.companyUser);

  const handleEditorChange = (editorState: any) => {
    const contentState = editorState.getCurrentContent();
    const plainText = contentState.getPlainText("");

    if (plainText?.length <= MAX_LENGTH) {
      setEditorState(editorState);
    } else if (editorState.getCurrentContent() !== contentState) {
    }
    setEditorState(editorState);
    const currentContent = editorState.getCurrentContent();
    const htmlContent = draftToHtml(convertToRaw(currentContent));
    onChange(htmlContent);
  };

  useEffect(() => {
    const currentContent = editorState.getCurrentContent();
    const plainText = currentContent.getPlainText("");
    const currentCharCount = plainText?.length;
    setCharCount(currentCharCount);
  }, [editorState]);

  useEffect(() => {
    if (value) {
      const newEditorState = getInitialStateFromHTML(value);
      setEditorState(newEditorState);
    }
  }, []);

  useEffect(() => {
    // `value` değişikliğini algıla ve içerik farklı ise güncelle
    const contentFromState = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    if (value !== contentFromState && value) {
      // Basit bir string karşılaştırması yapılıyor
      const newEditorState = getInitialStateFromHTML(value);
      setEditorState(newEditorState);
    }
  }, [value]); // value değişikliklerine yanıt ver

  const isEditorEmpty = () => {
    const contentState = editorState.getCurrentContent();
    return !contentState.hasText();
  };

  // EditorState'i her güncellediğinizde kontrol edin
  useEffect(() => {
    const empty = isEditorEmpty();
    setEditorEmpty(empty); // Bu bir state olabilir
  }, [editorState]);

  useEffect(() => {
    if (isFocused) {
      setIcon(<TbChevronUp className={iconClass} />);
    } else {
      setIcon(<TbChevronDown className={iconClass} />);
    }
  }, [isFocused]);

  const sendEmailVerify = async (email: string) => {
    setVerifyingEmail(true);
    try {
      await axios.authRequest({
        endpoint: "companies/account-settings/send-verify-email",
        type: "post",
        payload: {
          email,
        },
      });
      dispatch(getCompanyUser());
      toast.success("Email gönderildi.");
      setVerifyingEmail(false);
    } catch (error: any) {
      toast.error(error.response.data.errors.email[0]);
      setVerifyingEmail(false);
      console.log(error);
    }
  };

  const handlePhoneVerification = async () => {
    const cleanedValue = value?.replace(/[^\d]/g, "") || "";
    if (cleanedValue.length < 11) {
      toast.error(
        "Cep telefonu numarası 11 haneden az olamaz. Geçerli bir numara giriniz."
      );
      return;
    }

    if (!companyUser?.phone) {
      toast.error("Telefon numarası bulunamadı.");
      return;
    }

    await dispatch(sendUserVerifySms({ phone: companyUser.phone }));
    navigate("/verify/phone");
  };

  const validatePhoneNumber = (phone: string) => {
    const phoneRegex = /^0\(\d{3}\) \d{3} \d{2} \d{2}$/;
    return phoneRegex.test(phone);
  };

  const toolbarOptions = {
    options: [
      "inline",
      "blockType",
      "fontSize",
      "fontFamily",
      "list",
      "textAlign",
      "remove",
      "history",
    ],
    inline: { options: ["bold", "italic", "underline", "strikethrough"] },
    blockType: {
      options: [
        "Normal",
        "H1",
        "H2",
        "H3",
        "H4",
        "H5",
        "H6",
        "Blockquote",
        "Code",
      ],
    },
    // Diğer seçenekler için yapılandırma ekleyebilirsiniz
  };

  const Fields = () => {
    if (type === "textarea") {
      return (
        <textarea
          className={`!outline-none  w-full border ${
            value ? "border-primary100" : "border-gray-400"
          } text-[14px]  focus:border-blue-700 transition-all rounded-md p-3 placeholder:text-blue-700 text-blue-700 min-h-32 ${
            invalid ? "bg-[#ff000014] border-red-500" : ""
          }`}
          placeholder={placeholder ? placeholder : ""}
          name={name ? name : ""}
          value={value}
          required={required ? required : false}
          onChange={onChange}
        />
      );
    }
    if (type === "editor") {
      return (
        <div className="mt-5 text-sm">
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            toolbar={toolbarOptions}
            wrapperClassName={`wrapper-class ${
              editorEmpty ? "empty-editor" : ""
            }`}
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
          />
          <style>{`
            .DraftEditor-root {
              min-height: 200px;
              border: 1px solid #d1d5db;
              padding: 10px;
              cursor: text;
              direction: ltr;
              border-radius: 6px;
            }
            .empty-editor .DraftEditor-root {
              //border-color: red;  // Boş olduğunda border kırmızı olur
            }
          `}</style>
          <div style={{ marginTop: 10 }}>{MAX_LENGTH - charCount}</div>
        </div>
      );
    }
    if (type === "select") {
      return (
        <div onClick={() => setIsFocused(!isFocused)}>
          <select
            className={`!bg-transparent !outline-none !appearance-none relative w-full border ${
              value ? "border-primary100" : "border-gray-400"
            } text-[14px]  focus:border-blue-700 transition-all 
                    rounded-md p-3 placeholder:text-blue-700 text-blue-700 min-h-10 ${
                      invalid ? "bg-[#ff000014] border-red-500" : ""
                    }`}
            name={name ? name : ""}
            value={value}
            onChange={onChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              setIsFocused(false);
            }}
          >
            {options?.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            {icon}
          </div>
        </div>
      );
    }
    if (type === "number") {
      return (
        <div className="relative">
          <input
            className={`!outline-none w-full border ${
              value ? "border-primary100" : "border-gray-400"
            } text-[14px]  focus:border-blue-700 transition-all 
                        rounded-md p-3 placeholder:text-blue-700 text-blue-700 min-h-10 ${
                          invalid || !isValidPhone
                            ? "bg-red-100 !border-red-500"
                            : ""
                        }`}
            placeholder={
              name === "land_phone" ? "0(___) ___ __ __" : "0(5__) ___ __ __"
            }
            type="text"
            max={16}
            name={name ? name : ""}
            value={value?.replace(
              /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
              "$1($2) $3 $4 $5"
            )}
            onChange={(event: any) => {
              const inputVal = event.target.value.replace(/[^\d]/g, "");
              if (name === "land_phone" && inputVal.startsWith("05")) {
                toast.error(
                  'Sabit telefon numaraları "05" ile başlayamaz.Geçerli bir numara giriniz'
                );
                event.target.value = "";
                return;
              }
              if (props.phoneMaskType === "phone") {
                event.target.value = event.target.value?.replace(
                  /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
                  "$1 ($2) $3 $4 $5"
                );
                if (
                  event.target.value.length === 1 &&
                  !(event.nativeEvent.inputType === "deleteContentBackward")
                ) {
                  event.target.value = "0(5";
                }
                if (
                  event.target.value.length === 5 &&
                  !(event.nativeEvent.inputType === "deleteContentBackward")
                ) {
                  event.target.value = event.target.value + ") ";
                }
                if (
                  event.target.value.length === 10 &&
                  !(event.nativeEvent.inputType === "deleteContentBackward")
                ) {
                  event.target.value = event.target.value + " ";
                }
                if (
                  event.target.value.length === 13 &&
                  !(event.nativeEvent.inputType === "deleteContentBackward")
                ) {
                  event.target.value = event.target.value + " ";
                }
                // max length 16 char
                if (event.target.value.length > 16) {
                  event.target.value = event.target.value.slice(0, 16);
                }
                event.target.value = event.target.value?.replace(
                  /[^\d\(\)\s]/g,
                  ""
                );
              }

              onChange(event);
            }}
            onBlur={(e) => {
              const isValid = validatePhoneNumber(e.target.value);
              setIsValidPhone(isValid);
              // if (!isValid) {
              //   toast.error(
              //     ""
              //   );
              // }
              setIsFocused(false);
            }}
            onFocus={() => {
              setIsFocused(true);
              if (props.phoneMaskType === "phone") {
                // Select all value
                const input = document.querySelector(
                  `input[name=${name}]`
                ) as HTMLInputElement;
                if (input) {
                  input.select();
                }
              }
            }}
          />
          {companyUser?.phone && name === "phone" ? (
            !phoneVerified ? (
              <button
                type="button"
                disabled={!value}
                className="absolute px-3 text-sm top-[50%] -translate-y-1/2 end-0 bg-gray-500 text-white h-full 
                flex justify-center items-center rounded-e-md border border-s-0 
                border-gray-400 text-[14px] focus:border-blue-700 transition-all"
                onClick={handlePhoneVerification}
              >
                Onayla
              </button>
            ) : (
              <div
                className={`absolute top-[50%] -translate-y-1/2 text-xl end-0 text-white h-full 
                w-14 flex justify-center items-center rounded-e-md border border-s-0
                 ${
                   value ? "border-primary100" : "border-gray-400"
                 } text-[14px]  focus:border-blue-700 transition-all bg-green-500`}
              >
                <TbCheck />
              </div>
            )
          ) : (
            ""
          )}
        </div>
      );
    }
    if (type === "password") {
      return (
        <div className="relative">
          <input
            className={`!outline-none w-full border ${
              value ? "border-primary100" : "border-gray-400"
            } text-[14px]  focus:border-blue-700 transition-all rounded-md p-3 placeholder:text-blue-700 text-blue-700 min-h-10 pr-24 ${
              invalid ? "bg-[#ff000014] !border-red-500" : ""
            }`}
            placeholder={placeholder ? placeholder : ""}
            type={password ? "password" : "text"}
            name={name ? name : ""}
            value={value}
            onChange={onChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(props.value !== "")}
            required={required}
          />
          <div
            className="absolute flex items-center justify-center gap-3 -translate-y-1/2 cursor-pointer top-1/2 right-4"
            onClick={() => setPassword(!password)}
          >
            {password ? (
              <>
                <span className="text-sm font-semibold text-blue-700">
                  Göster
                </span>
                <EyeIcon className=" w-[24px] h-[24px] text-lg text-blue-700" />
              </>
            ) : (
              <>
                <span className="text-sm font-semibold text-blue-700">
                  Gizle
                </span>
                <EyeSlashIcon className="w-[24px] h-[24px]text-lg text-blue-700" />
              </>
            )}
          </div>
        </div>
      );
    }
    if (type === "checkbox") {
      return (
        <div className="flex items-center justify-between gap-3 text-[14px] border-b mb-1 pb-1">
          <label className="flex-1 text-sm text-gray-600">{label}</label>
          <div className="flex items-center justify-center gap-3">
            <span className="text-black ">
              {checked === 1 ? "Açık" : "Kapalı"}
            </span>
            <Switch
              checked={checked === 1}
              name={name ? name : ""}
              value={value ? value : "0"}
              onChange={(e) => {
                onChange(e ? 1 : 0);
              }}
              className={`${
                checked ? "bg-blue-600" : "bg-gray-200"
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <div
                className={`${
                  checked ? "translate-x-6" : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              ></div>
            </Switch>
          </div>
        </div>
      );
    }
    if (type === "search") {
      return (
        <div>
          <CustomDropdown
            defaultVal={value}
            fcolor={"text-title"}
            bcolor={"border-mains"}
            noRequired
            isSingle
            options={[]}
            placeholder="Seçiniz"
            id="sectorsName"
            onSelectionChange={onChange}
            searchEndpoint="cdn/get-sectors"
            searchKey="sectors"
          />
        </div>
      );
    }
    return (
      <div className="relative">
        <input
          className={`!outline-none w-full border ${
            value ? "border-primary100" : "border-gray-400"
          } text-[14px]  focus:border-blue-700 transition-all rounded-md p-3 ${
            type === "email" ? "pr-[70px]" : ""
          } placeholder:text-blue-700 text-blue-700 min-h-10 ${
            invalid ? "bg-[#ff000014] !border-red-500" : ""
          } ${disabled && ""}`}
          placeholder={placeholder ? placeholder : ""}
          type={type ? type : "text"}
          name={name ? name : ""}
          value={value}
          onChange={onChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(props.value !== "")}
          required={required}
          disabled={disabled}
        />
        {companyUser?.email ? (
          type === "email" && emailVerified ? (
            <div
              className={`absolute top-[50%] -translate-y-1/2 text-xl end-0 text-white h-full w-14 flex justify-center items-center rounded-e-md border border-s-0 ${
                value ? "border-primary100" : "border-gray-400"
              } text-[14px]  focus:border-blue-700 transition-all bg-green-500`}
            >
              <TbCheck />
            </div>
          ) : (
            type === "email" && (
              <button
                type="button"
                disabled={!value}
                className="absolute px-3 text-sm top-[50%] -translate-y-1/2 end-0 bg-gray-500 text-white h-full flex justify-center items-center rounded-e-md border border-s-0 ${value ? 'border-primary100': 'border-gray-400'} text-[14px]  focus:border-blue-700 transition-all"
                onClick={() => {
                  if (value) sendEmailVerify(value);
                }}
              >
                {verifyingEmail ? (
                  <CgSpinner className="animate-spin" />
                ) : (
                  "Onayla"
                )}
              </button>
            )
          )
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <label
      className={`flex flex-col gap-1 relative font-poppins w-full ${className}`}
      onClick={() => {
        if (disabled) {
          toast.error(`${label} Alanını değiştiremezsiniz`);
        }
        setIsFocused(true);
      }}
    >
      {type !== "checkbox" && (
        <div
          className={`text-gray-600 md:text-sm text-xs font-semibold rounded absolute z-10 transition-all 
              -top-5 -start-1 px-2 !bg-transparent
               ${disabled && "pb-[50px] pr-[200px]"}
           ${invalid && "!text-red-700"}`}
        >
          {label}
          {label === "Posta Kodu" && (
            <a
              href="https://postakodu.ptt.gov.tr/"
              target="_blank"
              rel="noreferrer"
              className="h-full text-xs font-semibold text-primary100"
            >
              {" "}
              (Nasıl öğrenirim?){" "}
            </a>
          )}
          {required && <span className="h-full text-red-500"> * </span>}
        </div>
      )}
      {Fields()}
    </label>
  );
};

export default FormField;
