import { LabelHTMLAttributes } from "react"
import { useContext } from "react"
import { MultiSelectDropdownContext, multiSelectDropdownContextType } from "."




type errorPropsType = LabelHTMLAttributes<HTMLLabelElement> & {

}







export default function Error({
    className,
    ...props
}: errorPropsType) {

    const { error }: multiSelectDropdownContextType = useContext(MultiSelectDropdownContext)
    
    if (error) {
        return(
            <label {...props} className={`${className} text-xs text-red-600`}>

            </label>
        )
    } return null
}