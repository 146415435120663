/* eslint-disable react-hooks/rules-of-hooks */
import React, { Fragment, useEffect, useState } from "react";
import Card from "../../setting-page/content/Card";
import { Link, useParams } from "react-router-dom";
import Axios from "../../../../services/axios";
import { BsEye } from "react-icons/bs";
import { PiListNumbersBold } from "react-icons/pi";
import { useGetMonthName } from "../../../../hooks/useFormatDate";
import { IApplicantsData } from "../../candidate-pool-page/types";
import { FaCity } from "react-icons/fa";
import { MdBlinds, MdWorkOutline } from "react-icons/md";
import { RiErrorWarningLine, RiGraduationCapLine } from "react-icons/ri";
import JobExModal from "../components/JobExModal";
import { CgCornerDownRight, CgMathPlus, CgTrash } from "react-icons/cg";
import Swal from "sweetalert2";
import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import useFetchCdnQuery from "../../../../hooks/useFetchCdnQuery";
import { BsSearch } from "react-icons/bs";
import { TbSchool } from "react-icons/tb";
import { PiBagSimpleFill } from "react-icons/pi";
import { CiLocationOn, CiUser } from "react-icons/ci";
import { IoPerson } from "react-icons/io5";
import { AxiosError } from "axios";

type Props = {};
const axios = new Axios();
const FolderShowPage = (props: Props) => {
  const { folderId } = useParams();
  const [cvs, setCvs] = useState<IApplicantsData[]>([]);
  const [open, setOpen] = useState(false);
  const [jobEx, setJobEx] = useState<any>([]);
  const [selectedCvs, setSelectedCvs] = useState<IApplicantsData[]>([]);
  const statusOptions = useFetchCdnQuery(
    "statuses",
    "get-company-candidate-statuses",
    "company_candidate_statuses"
  ).data as any;
  const [selected, setSelected] = useState<any>();
  const witdth = window.innerWidth;

  const getCvs = async () => {
    try {
      const response = await axios.authRequest({
        endpoint: `companies/folder/${folderId}/get-folder-cvs`,
        type: "get",
        payload: {},
      });
      console.log("cvs", response.data.cvs);
      setCvs(response.data.cvs);
    } catch (error) {
      console.log(error);
    }
  };

  const getJobExperience = async (id: string) => {
    try {
      const response = await axios.authRequest({
        endpoint: `companies/cv/${id}/get-job-experiences`,
        type: "get",
        payload: {},
      });
      setJobEx(response.data.job_experiences);
    } catch (error) {
      console.log(error);
    }
  };

  const extractCvs = async (cv_ids: string[]) => {
    try {
      await axios.authRequest({
        endpoint: `companies/folder/${folderId}/multi-extract-cv-from-folder`,
        type: "post",
        payload: {
          cv_ids,
        },
      });
      Swal.fire({
        icon: "success",
        title: "Seçili Adaylar Klasörden Çıkartıldı!",
        timer: 3000,
      });
      await getCvs();
      setSelectedCvs([]);
    } catch (error: any) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: error.response?.data?.error || "Bir hata oluştu",
        timer: 3000,
      });
    }
  };

  // companies/folder/{{companyFolder}}/multi-candidate-status-change
  const changeStatus = async (
    status_id: string,
    company_folder_cv_ids: string[]
  ) => {
    try {
      await axios.authRequest({
        endpoint: `companies/folder/${folderId}/multi-candidate-status-change`,
        type: "put",
        payload: {
          company_candidate_status_id: status_id,
          company_folder_cv_ids: company_folder_cv_ids,
        },
      });

      await getCvs();
      setSelectedCvs([]);
      setSelected(statusOptions[0]);
      Swal.fire({
        icon: "success",
        title: "Durum Değiştirildi!",
        timer: 3000,
      });
    } catch (error: any) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: error.response?.data?.error || "Bir hata oluştu",
        timer: 3000,
      });
    }
  };
  useEffect(() => {
    getCvs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelected(
      statusOptions && statusOptions.length > 0 ? statusOptions[0] : null
    );
  }, [statusOptions]);

  return (
    <div className="container mx-auto my-5">
      {cvs && cvs.length > 0 && (
        <Card>
          <div className="flex items-center justify-start px-2 py-1 bg-gray-100 border border-gray-300 gap-x-2 rounded-2xl">
            <BsSearch className="text-xl text-gray-700" />
            <input
              type="text"
              placeholder="Aday Ara"
              className="w-full py-2 pl-1 text-xs text-gray-700 bg-gray-100 outline-none poppins-semibold rounded-2xl"
            />
          </div>
        </Card>
      )}

      <JobExModal jobEx={jobEx} open={open} setOpen={setOpen} />
      <div>
        <div className="flex flex-col justify-around p-3 mb-5 bg-white">
          <h1 className="px-1 mb-4 text-base font-medium font-poppins">
            Klasördeki Adaylar
          </h1>

          {/*
					TODO: Eski aday tasarımı
					<div className='flex items-center justify-start w-full gap-3 p-5 duration-200 bg-gray-100 hover:bg-gray-200'>
						<input
							type='checkbox'
							className='my-auto accent-title'
							onChange={(e) => {
								if (e.target.checked) {
									setSelectedCvs(cvs);
								} else {
									setSelectedCvs([]);
								}
							}}
						/>

						<img
							src='https://picsum.photos/100'
							alt=''
						/>

						<div className='flex flex-col gap-2'>
							<div className='flex items-center justify-start gap-2'>
								<TbSchool className='text-base text-gray-700' />
								<span className='text-sm font-medium text-gray-700 text-nowrap font-poppins'>
									Lisans
								</span>
							</div>

							<div className='flex items-center justify-start gap-2'>
								<PiBagSimpleFill className='text-base text-gray-700' />
								<span className='text-sm font-medium text-gray-700 text-nowrap font-poppins'>
									Çalışmıyor
								</span>
							</div>

							<div className='flex items-center justify-start gap-2'>
								<CiLocationOn className='text-base text-gray-700' />
								<span className='text-sm font-medium text-gray-700 text-nowrap font-poppins'>
									Battalgazi / Malatya
								</span>
							</div>

							<div className='flex items-center justify-start gap-2'>
								<CiUser className='text-base text-gray-700' />
								<span className='text-sm font-medium text-gray-700 text-nowrap font-poppins'>
									İncelenmedi
								</span>
							</div>
						</div>
					</div> */}
        </div>
        {cvs && cvs.length > 0 && (
          <div className="flex flex-row bg-white gap-2 p-2 items-center justify-between text-mains font-poppins font-semibold">
            <input
              type="checkbox"
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedCvs(cvs);
                } else {
                  setSelectedCvs([]);
                }
              }}
            />
            <div className="flex w-full items-center justify-start md:justify-center">
              Aday
            </div>
            <div className="hidden md:flex w-full items-center justify-center">
              Meslek/Eğitim Bilgisi
            </div>
            <div className="hidden md:flex w-full items-center justify-center">
              Son İş Deneyimi
            </div>
            <div className="hidden md:flex w-full items-center justify-center">
              İşlemler
            </div>
          </div>
        )}
        <div className="flex flex-wrap gap-5">
          {!cvs && (
            <div className="flex flex-row items-center rounded-md bg-white shadow-md gap-2 w-full">
              <div className="py-3 px-2 rounded-l-md items-center justify-center flex bg-red-600">
                <RiErrorWarningLine className="text-2xl text-white" />
              </div>
              <div className="py-3 px-2 text-gray-700">
                Klasöre eklenen aday bulunamadı
              </div>
            </div>
          )}
          {cvs && cvs.length == 0 && (
            <div className="flex flex-row items-center rounded-md bg-white shadow-md gap-2 w-full">
              <div className="py-3 px-2 rounded-l-md items-center justify-center flex bg-red-600">
                <RiErrorWarningLine className="text-2xl text-white" />
              </div>
              <div className="py-3 px-2 text-gray-700">
                Klasöre eklenen aday bulunamadı
              </div>
            </div>
          )}

          {cvs && cvs.length > 0
            ? cvs.map((item: any, index: number) =>
                witdth > 991 ? (
                  <>
                    <div className="flex justify-around w-full gap-2 p-2 py-4 transition-all bg-white border flex-nowrap border-s-8 hover:border-title columns-auto">
                      <div className="flex items-center justify-center px-7">
                        <input
                          type="checkbox"
                          checked={selectedCvs.includes(item)}
                          className="my-auto accent-title"
                          onChange={() => {
                            if (selectedCvs.includes(item)) {
                              setSelectedCvs(
                                selectedCvs.filter((cv) => cv !== item)
                              );
                            } else {
                              setSelectedCvs([...selectedCvs, item]);
                            }
                          }}
                        />
                      </div>
                      <div className="flex flex-wrap items-center w-full col-auto gap-2">
                        {item.user.profile && (
                          <img
                            src={item.user.profile}
                            className="border-2 rounded-full w-14 h-14 border-title"
                            onError={(e) => {
                              (e.target as HTMLImageElement).src =
                                "/assets/images/user-profile.png";
                            }}
                            alt={item.user.name}
                          />
                        )}
                        {!item.user.profile && (
                          <div className="border-2 rounded-full w-14 h-14 border-title bg-gray-100 flex items-center justify-center">
                            <IoPerson className="flex text-3xl text-gray-500" />
                          </div>
                        )}
                        <div className="flex flex-col w-1/2 P">
                          <span className="text-sm font-semibold text-title text-nowrap">
                            {item?.user?.name} {item.user?.surname}
                          </span>
                          <span className="text-sm font-semibold text-nowrap">
                            {item.district?.name && item.district?.name + " , "}
                            {item.city?.name}
                          </span>
                          <span className="text-sm font-semibold text-nowrap">
                            {item?.gender && item?.gender + " , "} {item?.age}
                          </span>
                          <span className="text-sm font-semibold text-nowrap">
                            {item?.company_candidate_status}
                          </span>
                          {false && (
                            <span className="text-xs font-semibold text-title text-nowrap">
                              {statusOptions &&
                              item.user.status &&
                              statusOptions[item.user.status]
                                ? statusOptions.map((status: any) =>
                                    status.id === item.user.status
                                      ? status.name
                                      : ""
                                  )
                                : "Belirtilmemiş"}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-wrap items-center justify-center text-sm text-gray-600 w-full col-auto gap-2 text-center">
                        <span className="w-full text-center">
                          {item.education_status
                            ? item.education_status
                            : "Belirtilmemiş"}{" "}
                        </span>
                      </div>
                      <div className="flex flex-wrap items-center justify-center w-full text-sm text-gray-600 col-auto gap-2 text-center">
                        <span className="text-center">
                          {item.last_job_experience?.position
                            ? item.last_job_experience?.position
                            : "Belirtilmemiş"}
                        </span>
                        <span className="text-center">
                          {item.start_date
                            ? JSON.parse(item.start_date).year
                            : ""}{" "}
                          {item.end_date &&
                            "/ " +
                              useGetMonthName(
                                JSON.parse(item.start_date).month
                              )}
                        </span>
                        <span className="text-center">
                          {item.last_job_experience?.company_name}
                        </span>
                      </div>
                      <div className="flex flex-col items-center justify-center w-full gap-2">
                        <Link
                          to={`/isveren/cv/${item.company_folder_cv.id}`}
                          className="p-2 text-blue-500 transition-all rounded-lg active:bg-blue-500 active:text-white"
                        >
                          <BsEye className="w-6 h-6" />
                        </Link>
                        <button
                          className="p-2 text-blue-500 transition-all rounded-lg active:bg-blue-500 active:text-white"
                          onClick={async () => {
                            setJobEx([]);
                            await getJobExperience(item.company_folder_cv.id);
                            setOpen(true);
                          }}
                        >
                          <PiListNumbersBold className="w-6 h-6" />
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex justify-around w-full gap-2 p-2 py-4 transition-all bg-white border rounded-lg flex-nowrap hover:border-title columns-auto mt-1">
                    <div className="flex items-center justify-center px-3">
                      <input
                        type="checkbox"
                        className="my-auto accent-title"
                        checked={selectedCvs.includes(item)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedCvs(
                              selectedCvs.filter((cv) => cv !== item)
                            );
                          } else {
                            setSelectedCvs([...selectedCvs, item]);
                          }
                        }}
                      />
                    </div>
                    <div className="flex flex-wrap items-center w-full col-auto gap-2">
                      <img
                        src={item.user.profile}
                        className="border-2 rounded-full w-14 h-14 border-title"
                        onError={(e) => {
                          (e.target as HTMLImageElement).src =
                            "/assets/images/user-profile.png";
                        }}
                        alt={item.user.name}
                      />
                      <div className="flex flex-col w-1/2 P">
                        <span className="text-nowrap">
                          {item?.user?.name} {item?.gender}{" "}
                          {item?.age && " , " + item?.age}
                        </span>
                        <span className="text-sm text-nowrap flex items-center gap-0.5">
                          {item?.district?.name && <FaCity />}{" "}
                          {item?.district?.name + ", "}
                          {item?.city?.name}
                        </span>
                        <span className="text-sm text-nowrap flex items-center gap-0.5">
                          {item?.education_status && <RiGraduationCapLine />}{" "}
                          {item?.education_status}
                        </span>
                        <span className="text-sm text-nowrap flex items-center gap-0.5">
                          {item?.company_candidate_status && <MdWorkOutline />}{" "}
                          {item?.company_candidate_status}
                        </span>
                        <span className="text-sm text-nowrap flex items-center gap-0.5">
                          {item?.company_candidate_status && <MdBlinds />}
                          {item?.company_candidate_status
                            ? "incelendi"
                            : "incelenmedi"}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              )
            : ""}
          {selectedCvs.length ? (
            <div className="flex flex-wrap items-center justify-start gap-3 my-3">
              <CgCornerDownRight className="hidden text-3xl md:block" />
              <Listbox
                defaultValue={statusOptions && statusOptions[0]}
                value={selected}
                onChange={setSelected}
              >
                <div className="relative w-full mt-1 md:w-auto z-10">
                  <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white border-2 border-title py-3 pl-3 pr-10 text-left focus:outline-none text-lg sm:text-sm  min-w-[178px] min-h-[47px]">
                    <span className="block font-semibold truncate text-title">
                      {selected?.name}
                    </span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <ChevronDownIcon
                        className="w-5 h-5 text-title"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute w-auto py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black/5 focus:outline-none sm:text-sm">
                      {statusOptions &&
                        statusOptions.map((option: any, optionIdx: any) => (
                          <Listbox.Option
                            key={optionIdx}
                            className={({ active }) =>
                              `relative cursor-default select-none py-2 px-4 ${
                                option.id === selected?.id
                                  ? "ps-10 bg-blue-200 text-title"
                                  : ""
                              } ${
                                active
                                  ? "bg-blue-200 text-title"
                                  : "text-gray-900"
                              }`
                            }
                            value={option}
                          >
                            {({ selected: isSelected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    isSelected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  {option.name}
                                </span>
                                {isSelected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-title">
                                    <CheckIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>

              <button
                className="bg-title rounded-lg px-4 py-3 flex items-center justify-center text-white min-w-[178px] min-h-[47px] md:w-auto w-full"
                onClick={async () => {
                  if (selected && selected.id) {
                    await changeStatus(
                      selected.id,
                      selectedCvs.map((item: any) => item.company_folder_cv.id)
                    );
                  }
                }}
              >
                <CgMathPlus className="text-2xl" />
                <span className="ml-2 font-bold">Olarak Değiştir</span>
              </button>
              <button
                className="flex items-center justify-center w-full gap-3 px-4 py-3 text-red-600 md:w-auto"
                onClick={() => {
                  Swal.fire({
                    icon: "warning",
                    title: "İşlemi Onaylayın",
                    text: "Seçili CV'leri silmek istediğinize emin misiniz?",
                    confirmButtonColor: "#2253af",
                    confirmButtonText: "Evet",
                    cancelButtonText: "Hayır",
                    showCancelButton: true,
                    showCloseButton: true,
                    preConfirm: async () => {
                      await extractCvs(
                        selectedCvs.map(
                          (item: any) => item.company_folder_cv.cv_id
                        )
                      );
                    },
                  });
                }}
              >
                <CgTrash className="text-xl" />
                <span>Seçili Adayları Çıkart</span>
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default FolderShowPage;
