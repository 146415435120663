import React, { useEffect, useState } from "react";
import {
  cdnCityType,
  cdnCountryType,
  cdnPositionLevelType,
  cdnPositionType,
  cdnSectionType,
  cdnSectorType,
  cdnWorkingTypeType,
  dbUserJobExperienceType,
} from "../../../../../types";
import WorkExperienceForm from "./components/WorkExperienceForm";
import { getPositions } from "../../../../../customHooks/getPositions";
import { getPositionLevels } from "../../../../../customHooks/getPositionLevels";
import { getSectors } from "../../../../../customHooks/getSectors";
import { getSections } from "../../../../../customHooks/getSections";
import { getCountries } from "../../../../../customHooks/getCountries";
import { getWorkingTypes } from "../../../../../customHooks/getWorkingTypes";
import { getCities } from "../../../../../customHooks/getCities";
import Axios from "../../../../../services/axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../../components/isbul-ui";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { DivOverlay } from "leaflet";
import { PiWarningCircle } from "react-icons/pi";
import { CvLayoutContext, cvLayoutContextType } from "..";
import { useContext } from "react";

const axios = new Axios();

const WorkExperiences: React.FC = () => {
  const { isEnglish }: cvLayoutContextType = useContext(CvLayoutContext);

  const params = useParams();
  const cvId = params.slug;

  const navigate = useNavigate();

  // Page states
  const [loadingDatas, setLoadingDatas] = useState<boolean>(true);
  const [positions, setPositions] = useState<cdnPositionType[]>([]);
  const [positionLevels, setPositionLevels] = useState<cdnPositionLevelType[]>(
    []
  );
  const [sectors, setSectors] = useState<cdnSectorType[]>([]);
  const [sections, setSections] = useState<cdnSectionType[]>([]);
  const [countries, setCountries] = useState<cdnCountryType[]>([]);
  const [workingTypes, setWorkingTypes] = useState<cdnWorkingTypeType[]>([]);
  const [cities, setCities] = useState<cdnCityType[]>([]);
  const [jobExperiences, setJobExperiences] = useState<
    dbUserJobExperienceType[]
  >([]);
  const [jobExperiencesLength, setJobExperiencesLength] = useState<number>(1);

  /* Get cdn datas for from fields */
  async function getAllCdns() {
    const [
      cdnPositions,
      cdnPositionLevels,
      cdnSectors,
      cdnSections,
      cdnCountries,
      cdnWorkingTypes,
      cdnCities,
    ] = await Promise.all([
      getPositions({ lang: isEnglish ? "en" : "tr" }),
      getPositionLevels({ lang: isEnglish ? "en" : "tr" }),
      getSectors({ lang: isEnglish ? "en" : "tr" }),
      getSections({ lang: isEnglish ? "en" : "tr" }),
      getCountries({ lang: isEnglish ? "en" : "tr" }),
      getWorkingTypes({ lang: isEnglish ? "en" : "tr" }),
      getCities({ lang: isEnglish ? "en" : "tr" }),
    ]);

    setPositions(cdnPositions);
    setPositionLevels(cdnPositionLevels);
    setSectors(cdnSectors);
    setSections(cdnSections);
    setCountries(cdnCountries);
    setWorkingTypes(cdnWorkingTypes);
    setCities(cdnCities);
  }

  // Get all job experiences
  async function getAllJobExperiences() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/exp/index?cv_id=${cvId}`,
        type: "get",
        payload: {},
      });

      if (response && response.data && response.data.job_experiences) {
        setJobExperiences(
          JSON.parse(JSON.stringify(response.data.job_experiences))
        );
        setJobExperiencesLength(
          JSON.parse(JSON.stringify(response.data.job_experiences)).length
        );
      } else {
        setJobExperiencesLength(0);
      }
    } catch (error) {
      console.log(`Job experiences fetch failed. Error: ${error}`);
    }
  }

  // Initial scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    async function getAllDatas() {
      setLoadingDatas(true);
      await getAllCdns();
      await getAllJobExperiences();
      setLoadingDatas(false);
    }

    getAllDatas();
  }, []);

  const [isAddClicked, setIsAddClicked] = useState<boolean>(false);

  return (
    <div className="flex flex-col w-full gap-y-2 lg:gap-y-2 rounded-md bg-white pb-20 pt-2 lg:pb-5">
      {/* <span className="hidden lg:flex poppins-semibold text-sm text-mains py-4 border-b border-mains px-4">
      İş Deneyimleri
    </span> */}

      {/* Empty experience form */}
      <WorkExperienceForm
        jobExperience={null}
        countries={countries}
        positionLevels={positionLevels}
        positions={positions}
        sections={sections}
        sectors={sectors}
        cities={cities}
        workingTypes={workingTypes}
        getAllJobExperiences={getAllJobExperiences}
        jobExperiencesLength={jobExperiencesLength}
        isAddClicked={isAddClicked}
        setIsAddClicked={setIsAddClicked}
        isEnglish={isEnglish}
        cvId={cvId}
      />

      {/* Empty experiences message */}
      {jobExperiences.length == 0 && (
        <div className="flex flex-row gap-4 m-4 border border-gray-200 rounded-md">
          <div className="flex p-4 bg-red-600 rounded-l-md items-center justify-center">
            <PiWarningCircle className="text-3xl text-white" />
          </div>
          <div className="flex flex-col justify-center gap-1 py-2 pr-1">
            <p className="font-poppins font-semibold text-gray-700 text-sm">
              {isEnglish
                ? "You don't have any work experience added yet."
                : "Henüz eklenmiş bir iş deneyiminiz yok."}
            </p>
            {isEnglish ? (
              <p className="font-poppins text-sm text-gray-700 font-semibold">
                You can add new work experince with{" "}
                <button
                  className="text-mains underline"
                  onClick={() => setIsAddClicked(true)}
                >
                  Add Work Experience
                </button>{" "}
                button.
              </p>
            ) : (
              <p className="font-poppins text-sm text-gray-700 font-semibold">
                <button
                  className="text-mains underline"
                  onClick={() => setIsAddClicked(true)}
                >
                  İş Deneyimi Ekle
                </button>{" "}
                butonuna tıklayarak hemen yeni bir iş deneyimi ekleyebilirsiniz.
              </p>
            )}
          </div>
        </div>
      )}

      {/* All experiences mapping */}
      {!loadingDatas &&
        jobExperiences.map((exp, i) => {
          return (
            <div key={`expForm${exp.id}`}>
              <hr />
              <WorkExperienceForm
                jobExperience={exp}
                cities={cities}
                countries={countries}
                positionLevels={positionLevels}
                positions={positions}
                sections={sections}
                sectors={sectors}
                workingTypes={workingTypes}
                getAllJobExperiences={getAllJobExperiences}
                isEnglish={isEnglish}
                cvId={cvId}
              />
            </div>
          );
        })}

      <hr />

      {/* Prev and next buttons */}
      <div className="flex flex-row items-center justify-between px-4">
        <Button
          variant="secondary"
          size="sm"
          onClick={() => navigate(`/profilim/cv/duzenle/${cvId}/dosyalar`)}
        >
          <Button.Icon>
            <IoChevronBack />
          </Button.Icon>
          {isEnglish ? "Go Back" : "Geri Dön"}
        </Button>

        <Button
          size="sm"
          onClick={() =>
            navigate(`/profilim/cv/duzenle/${cvId}/egitim-bilgileri`)
          }
        >
          {isEnglish ? "Continue" : "Devam Et"}
          <Button.Icon>
            <IoChevronForward />
          </Button.Icon>
        </Button>
      </div>
    </div>
  );
};

export default WorkExperiences;
