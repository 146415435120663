import { MdKeyboardArrowDown } from "react-icons/md";
import { useState } from "react";

interface IUserSettings {
  headerTitle: string;
  content: string;
  children?: React.ReactNode;
  isActive?: boolean;
}

const ContentTitleInfo = ({
  headerTitle,
  content,
  children,
  isActive,
}: IUserSettings) => {
  const [isOpen, setIsOpen] = useState(false || isActive);

  return (
    <div className="bg-[#fff] w-full px-5 shadow-md">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between items-center py-2 gap-5 "
      >
        <div className="flex flex-col gap-3 text-gray-500">
          <p className="text-blue-800 font-bold text-base">{headerTitle}</p>
          <p className="text-sm text-gray-500 font-medium">{content}</p>
        </div>
        <MdKeyboardArrowDown
          className={`text-blue-700 transform ${isOpen ? "rotate-180" : ""}`}
          size={30}
        />
      </div>
      {isOpen && children}
    </div>
  );
};

export default ContentTitleInfo;
