import Axios from "../axios";

const AdvertService = {
  getSimilerAdvert: (id: number) => {
    const axios = new Axios();
    return axios.request({
      type: "get",
      endpoint: `adverts/${id}/get-similar-adverts?limit=5`,
      payload: {},
    });
  },

  getSimilerSectorAdvert: (id: number) => {
    const axios = new Axios();
    return axios.request({
      type: "get",
      endpoint: `companies/company-detail/${id}/get-similar-sector-adverts?limit=5`,
      payload: {},
    });
  },

  getAdvertDetailById: (id: number) => {
    const axios = new Axios();
    return axios.request({
      type: "get",
      endpoint: `adverts/${id}/detail`,
      payload: {},
    });
  },
};

export { AdvertService };
