import React, { useState } from "react";
import { FaCheck, FaRegCircle } from "react-icons/fa";
import { FaArrowRight, FaRegBuilding } from "react-icons/fa6";
import { IoLocationOutline } from "react-icons/io5";
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from "react-icons/io";

type CvSelect = {
  inPopup?: boolean;
  onSuccess?: (a: any) => void;
  onCancel?: () => void;
  onReady?: () => void;
  cvs: any[];
  adData: any;
  setSelectedCvsId: React.Dispatch<React.SetStateAction<number>>;
  setSelectedCvs: React.Dispatch<React.SetStateAction<any | null>>;
};

const CvSelect: React.FC<CvSelect> = ({
  inPopup = false,
  onSuccess = (id: any) => {},
  onCancel = () => {},
  onReady = () => {},
  cvs,
  adData,
  setSelectedCvsId,
  setSelectedCvs,
}) => {
  const [selected, setSelected] = useState(
    cvs[0] !== undefined ? cvs[0].id : 0
  );

  function formatDate(dateString: string): string {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("tr-TR", options);
    return formattedDate.split(".").join(".");
  }

  onReady && onReady();
  return (
    <div>
      <div className={"p-5"}>
        <div className="flex flex-col justify-center items-center w-full  gap-1  border-b pb-3">
          <div className="flex w-full justify-between items-center">
            <p className="text-mains font-poppins poppins-medium text-lg">
              {adData?.advert?.title}
            </p>
            <p className=" font-poppins poppins-medium text-xs text-gray-600">
              {formatDate(adData?.company_detail?.created_at)}
            </p>
          </div>

          <div className="flex flex-col justify-center items-start w-full gap-[2px]">
            <p className=" font-poppins poppins-medium text-xs flex justify-center items-center gap-1 text-gray-600">
              <FaRegBuilding className=" text-xs text-mains" />
              {adData.company_detail.company_name
                ? adData.company_detail.company_name
                : "FİRMA ADI GİZLİ"}
            </p>
            <p className=" font-poppins poppins-medium text-xs flex justify-center items-center gap-1 text-gray-600">
              <IoLocationOutline className=" text-xs text-mains" />
              {adData.districts
                ? adData.districts.length > 0
                  ? adData.districts[0].name + " / " + adData.cities[0].name
                  : adData.cities[0].name
                : adData.cities[0].name}
            </p>
          </div>
        </div>

        <div className="flex flex-col items-start  pt-3 pb-2 w-full justify-center">
          <p className="font-poppins poppins-medium text-[13px] text-gray-600">
            Özgeçmiş
          </p>

          <div className=" flex flex-col justify-center items-start pt-3 pb-3">
            {cvs.map((el) => {
              return (
                <p
                  onClick={() => {
                    setSelected(el.id);
                    setSelectedCvs(cvs.filter((cv: any) => cv.id === el.id)[0]);
                    setSelectedCvsId(el.id);
                  }}
                  className="font-poppins poppins-medium text-sm flex justify-center items-center gap-1 mt-1 text-gray-600 cursor-pointer"
                >
                  {selected === el.id ? (
                    <div className={"relative"}>
                      <IoMdRadioButtonOn className="text-mains text-lg" />
                      <IoMdRadioButtonOff className=" text-lg absolute left-0 top-0" />
                    </div>
                  ) : (
                    <IoMdRadioButtonOff className=" text-lg" />
                  )}
                  <span>{el?.title}</span>-
                  <span>{el?.lang === "tr" ? "Türkçe" : "English"}</span>-
                  <span>%{el?.score} </span>
                </p>
              );
            })}
          </div>
        </div>
      </div>
      <div onClick={() => {}} className="w-full rounded-md p-5 border-t ">
        <button
          onClick={() => {
            onSuccess && onSuccess(selected);
          }}
          className="bg-mains text-white font-poppins poppins-medium text-base w-full  rounded-md h-12 flex justify-center items-center gap-1"
        >
          Başvur
          <FaArrowRight className="text-xl" />
        </button>
      </div>
    </div>
  );
};

export default CvSelect;
