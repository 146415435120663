import React, { useEffect, useState } from "react";
import { BsBuildings, BsHeart, BsArrowRightCircleFill } from "react-icons/bs";
import { CiCalendarDate } from "react-icons/ci";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineWorkOutline } from "react-icons/md";
import services from "../../../../../services/profile-services";
import { TbAlertTriangleFilled } from "react-icons/tb";
import Axios from "../../../../../services/axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { FaQuestion } from "react-icons/fa6";
import { FiPlus } from "react-icons/fi";

const FormeJob: React.FC = () => {
  const navigate = useNavigate();
  const axios = new Axios();
  const [data, setData] = useState<any[]>([]);
  const userToken = Cookies.get("userToken");

  useEffect(() => {
    const getForMeAdverts = async () => {
      try {
        const response = await axios.userAuthRequest({
          type: "get",
          endpoint: "user/profilim/get-for-me-adverts",
          payload: "",
        });

        if (response && response.data && response.data.adverts) {
          setData(response.data.adverts);
        } else {
          setData([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getForMeAdverts();
  }, []);

  const truncate = (str: string, n: number) => {
    if (!str) {
      return "";
    }
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const convertDateFormat = (createdAt: string): string => {
    const date: Date = new Date(createdAt);
    const today: Date = new Date();

    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return "Bugün";
    } else {
      const options: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      };
      return date.toLocaleDateString("tr-TR", options);
    }
  };

  const handleisFavorite = async (
    id: number,
    isCurrentlyFavorited: boolean
  ) => {
    if (userToken) {
      try {
        if (isCurrentlyFavorited) {
          await axios.request({
            type: "delete",
            endpoint: `adverts/${id}/remove-favorite`,
            payload: {},
          });
          Swal.fire({
            icon: "success",
            title: "Favorilerden Kaldırıldı",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          await axios.request({
            type: "post",
            endpoint: `adverts/${id}/add-favorite`,
            payload: {},
          });
          Swal.fire({
            icon: "success",
            title: "Favorilere Eklendi",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        // Update the data state to reflect the favorite change
        setData((prevData) =>
          prevData.map((job) =>
            job.advert.id === id
              ? { ...job, is_favorite: !isCurrentlyFavorited }
              : job
          )
        );
      } catch (err) {
        console.log("Error:", err);
        Swal.fire({
          icon: "error",
          title: "Bir hata oluştu",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const [cvId, setCvId] = useState<number>(0);

  useEffect(() => {
    const fetchCv = async () => {
      try {
        const response = await axios.request({
          endpoint: `user/profilim/cv`,
          type: "get",
          payload: {},
        });

        console.log(response);

        if (response?.data?.data) {
          setCvId(response.data.data.id);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchCv();
  }, []);

  return (
    <>
      <div className="flex flex-col w-full items-center shadow-lg bg-white rounded-md ">
        <div className="w-full px-4 py-3 border-b">
          <span className="text-mains text-md poppins-semibold">
            Bana Uygun ilanlar
          </span>
        </div>
        <div className="flex flex-col gap-y-4 px-4 py-3 w-full">
          {data?.length === 0 ? (
            <div className="h-auto flex flex-col gap-y-5 w-full justify-center items-center py-5">
              <div className="items-center flex ">
                <FaQuestion className="text-red-500 text-7xl" />
              </div>

              <span className="text-sm poppins-medium">
                Bana Uygun İş İlanı Yok mu?
              </span>
              <span className="poppins-medium text-xs text-center">
                Seni daha yakından tanıyıp sana özel ilanları önerebilmemiz için
              </span>
              <button className="text-sm poppins-medium flex justify-center items-center gap-1 bg-mains px-4 py-3 text-white rounded-md mt-4">
                <FiPlus className="text-base" />
                <a href={`profilim/cv/duzenle/${cvId}/is-tercihleri`}>
                  İŞ TERCİHLERİ EKLE
                </a>
              </button>
            </div>
          ) : (
            data?.slice(1, 4).map((job, index) => (
              <div
                key={index}
                className={` bg-gray-200 relative w-full rounded-md lg:rounded-none !border !border-gray-300 lg:!border-transparent h-[96px] lg:h-32 px-2 md:px-4 md:py-2 gap-x-2 md:gap-x-5 flex hover-border-animation lg:shadow-md`}
              >
                <div className="flex-shrink-0 w-1/5 lg:w-1/6 flex justify-center items-center ">
                  <img
                    className="object-contain h-full max-w-[55px] md:max-w-[81px] max-h-[55px] md:max-h-[81px] "
                    src="https://isbull.s3.eu-north-1.amazonaws.com/default-images/company-logo.png?v=2.9.90"
                    alt="Company Logo"
                  />
                </div>

                <div className="flex flex-col items-start justify-center w-5/6 group md:gap-2">
                  <div className="flex items-center justify-start w-full h-auto">
                    <div className="flex items-center justify-between w-full">
                      <h3>
                        <a
                          target="blank"
                          href={`/is-ilanlari/${job.advert.slug}`}
                          className="text-main text-xs lg:text-lg poppins-semibold md:poppins-medium text-[#2253af] w-[100%] line-clamp-2"
                        >
                          <span> {job.advert.title}</span>
                        </a>
                      </h3>
                    </div>
                  </div>

                  <div className="flex items-center justify-between w-full h-auto lg:items-center gap-x-1">
                    <BsBuildings className="hidden text-xs text-gray-600 lg:flex lg:text-base" />
                    <span className="md:poppins-regular poppins-medium text-[10px] lg:text-xs text-gray-500 w-full line-clamp-1">
                      {truncate(job.company_detail.company_name, 30)}
                    </span>
                    <div
                      onClick={() =>
                        handleisFavorite(job.advert.id, job.is_favorite)
                      }
                    >
                      {userToken && (
                        <>
                          {job.is_favorite ? (
                            <FaHeart className="text-sm text-red-500" />
                          ) : (
                            <FaRegHeart className="text-sm text-red-500" />
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-row items-end justify-between w-full h-auto">
                    <div className="flex flex-col justify-between w-1/2 lg:flex-row ">
                      <div className="flex items-center justify-start w-full h-full gap-1 lg:gap-x-1">
                        <MdOutlineWorkOutline className="text-xs text-gray-600 lg:text-base" />
                        <span className="poppins-light text-[8px] lg:text-[10px] text-gray-600 w-full truncate">
                          {job.working_types[0].title}
                        </span>
                      </div>
                      <div className="flex items-center justify-start w-full h-full gap-1 lg:gap-x-1">
                        <IoLocationOutline className="flex-shrink-0 text-xs text-gray-600 lg:text-base" />
                        <span className="poppins-light text-[8px] lg:text-[10px] text-gray-600 w-full truncate">
                          {job.cities[0].name}
                        </span>
                      </div>
                    </div>
                    <div className="flex items-center justify-start gap-1 ml-auto lg:gap-x-1">
                      <CiCalendarDate className="text-xs text-gray-600 lg:text-base" />
                      <span className="poppins-light text-[8px] lg:text-[10px] text-gray-600 w-full truncate">
                        {convertDateFormat(job.advert.created_at)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {data.length > 0 && (
        <button
          onClick={() => navigate("/is-ilanlari?total=413&last_page=12")}
          className="w-full flex justify-end items-center pr-8 font-poppins poppins-medium underline text-[13px] text-gray-500"
        >
          <span>Tümünü Gör</span>
          <BsArrowRightCircleFill className="text-mains text-lg ml-2" />
        </button>
      )}
    </>
  );
};

export default FormeJob;
