import { HTMLAttributes, createElement } from "react"
import classNames from "classnames"
import { useContext } from "react"
import { SelectDropdownContext, selectDropdownContextType } from "."




type itemsPropsType = HTMLAttributes<HTMLDivElement> & {

}





export default function Items({
    className,
    children,
    ...props
}: itemsPropsType) {

    const { isOpen }: selectDropdownContextType = useContext(SelectDropdownContext)

    const cn = classNames({
        'absolute -bottom-px max-h-[200px] translate-y-full w-full left-0 border border-gray-400 rounded-md p-2 flex flex-col gap-2 bg-white overflow-auto z-[100]': true
    })

    if (isOpen) {
        return createElement('div', {
            className: `${cn} ${className}`,
            ...props
        }, children)
    } else {
        return null
    }

    /* return createElement('div', {
        className: `${cn} ${className}`,
        ...props
    }, isOpen == true? children: null) */
}