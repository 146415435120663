import SearchSection from "./SearchSection"
import AlphabetCollapes from "./AlphabetCollapes"
const Body: React.FC = () => {
  return <div className="h-auto bg-gray-100  flex flex-col  w-full mx-auto ">
    <SearchSection/>
    <AlphabetCollapes/>
  </div>;
};

export default Body;
