import Button from "../../../components/form-components/button";
import { useState } from "react";
import CookieSettingsModal from "./CookieSettingModal";
import { IoMdClose } from "react-icons/io";

export default function CookieComponent({
  acceptCookie,
}: {
  acceptCookie: () => void;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [closeCookie, setCloseCookie] = useState<boolean>(true);

  return (
    <>
      {closeCookie && (
        <div
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          className="fixed bottom-0 left-0 w-screen bg-[#de5b2b] p-4 z-[99999999999]"
        >
          <>
            <div className="flex flex-col gap-2 container mx-auto text-white relative">
              <IoMdClose
                className="absolute -right-1 md:-top-1 md:right-5 text-white text-lg cursor-pointer"
                onClick={() => setCloseCookie(false)}
              />

              <label className="font-semibold font-poppins">
                Sana özel bir kariyer deneyimi için çalışıyoruz.
              </label>
              <div className="flex  flex-col  md:flex-row gap-2 md:gap-0 items-center">
                <p className="text-gray-100 text-sm font-poppins md:w-4/5">
                  Kariyer ihtiyaçlarını anlamak, sana özel iş fırsatları sunmak
                  ve deneyimini iyileştirmek için çerezler kullanıyoruz.
                  &quot;Kabul Et&quot; seçeneğine tıklayarak çerezleri
                  onaylayabilir, çerez ayarları için &quot;Ayarlar'a&quot;
                  tıklayabilirsin.
                </p>

                <div className="flex flex-row gap-2 md:gap-4 items-center justify-between md:justify-end w-full px-4 md:w-1/5">
                  <button
                    className="text-[14px] underline"
                    onClick={() => setIsOpen(true)}
                    style={{
                      textDecoration: "underline",
                      textUnderlineOffset: "3px",
                    }}
                  >
                    Ayarlar
                  </button>
                  <Button size="sm" onClick={() => acceptCookie()}>
                    Kabul Et
                  </Button>
                </div>
              </div>
            </div>
          </>

          {isOpen && (
            <CookieSettingsModal
              acceptCookie={acceptCookie}
              setIsModalOpen={setIsOpen}
            />
          )}
        </div>
      )}
    </>
  );
}
