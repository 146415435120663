import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addOrUpdateQueryParam } from "../../../../hooks/useAddOrUpdateQueryParam";
import { FaChevronDown } from "react-icons/fa";
import usePartialBold from "../../../../customHooks/usePartialBold";
import Cookies from "js-cookie";

interface IResult {
  header?: string;
  headerRight?: string;
  items: {
    title: string;
    alt?: string;
    id: number;
    param?: string;
    name?: string;
    type?: string;
    isRecent?: boolean;
    search?: any;
  }[];
}

interface ISearchBarInput {
  placeHolder: string;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  results: IResult[];
  style?: React.CSSProperties;
  isRounded?: boolean;
  setInputValue: React.Dispatch<React.SetStateAction<any>>;
  handleClear: () => void;
}

const SearchBarInput: React.FC<ISearchBarInput> = (props: ISearchBarInput) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const searchTerm = props.searchTerm;

  const inputRef = useRef<HTMLInputElement>(null);

  const inputStyle = isActive ? "border-b border-gray-400 " : "";
  const innerStyle = isActive
    ? "mx-[-2px] border-2 border-t-0 border-blue-500 shadow-2xl"
    : "h-0";

  const borderStyle = props.isRounded
    ? `!rounded-md xl:!rounded-none`
    : `!rounded-l-md xl:!rounded-none`;

  const RenderItem: React.FC<{
    title: string;
    alt?: string;
    id: number;
    param?: string;
    name?: string;
    type?: string;
    isRecent?: boolean;
    search?: any;
    setInputValue: React.Dispatch<React.SetStateAction<any>>;
    setSearchTerm: React.Dispatch<React.SetStateAction<any>>;
  }> = ({
    title,
    alt,
    id,
    param,
    setInputValue,
    setSearchTerm,
    name,
    type,
    isRecent,
    search,
  }) => {
    return (
      <button
        onClick={() => {
          setIsActive(false);
          setSearchTerm(title);
          setInputValue({
            id: id,
            title: title,
            alt: alt,
            param: param,
            name: name,
            type: type,
            isRecent,
            search,
          });
        }}
        className="flex justify-start text-gray-600 items-center gap-2 w-full p-2 text-left hover:bg-gray-100 transition-all cursor-pointer group"
      >
        <div
          className="text-sm font-medium group-hover/position:text-blue-500 text-nowrap overflow-hidden text-ellipsis"
          dangerouslySetInnerHTML={{
            __html: usePartialBold({
              boldString: searchTerm,
              fullString: title,
            }),
          }}
        />
        {alt && (
          <div className="text-xs font-semibold text-gray-400 flex">
            ({alt})
          </div>
        )}
      </button>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setTimeout(() => {
          setIsActive(false);
        }, 100);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef]);

  const savedSearches = JSON.parse(Cookies.get("recentSearches") || "[]");

  return (
    <div
      ref={inputRef}
      style={props.style}
      className={`md:border md:border-r-0 h-full bg-white ${borderStyle} group ${
        isActive
          ? "border-blue-500 md:border-2 md:border-r-2 rounded-none"
          : "border-gray-500"
      }`}
    >
      <div className={`flex h-full ${inputStyle}`}>
        <input
          type="text"
          className="outline-none h-full w-full ml-3 placeholder:font-semibold placeholder:text-sm text-sm"
          placeholder={props.placeHolder}
          value={props.searchTerm}
          onChange={(e) => {
            props.setSearchTerm(e.target.value);
          }}
          onFocus={() => {
            setIsActive(true);
          }}
        />
        <FaChevronDown
          className={`text-gray-400 ${
            isActive ? "rotate-180" : ""
          } h-full my-auto mx-2 transition-all`}
          onClick={() => setIsActive((prev) => !prev)}
        />
      </div>
      <div className={`bg-white ${innerStyle} shadow-black transition-all`}>
        {isActive && (
          <div className="w-full h-full">
            {props?.results.map((el, i) => (
              <div key={i}>
                {el.header && el.header !== "" && (
                  <>
                    {" "}
                    <div className="flex justify-between w-full p-2">
                      {el.header && (
                        <div className="text-sm font-semibold text-gray-500">
                          {el.header}
                        </div>
                      )}
                      {el.headerRight && (
                        <div
                          onClick={props.handleClear}
                          className="text-sm font-medium text-blue-500 cursor-pointer"
                        >
                          {el.headerRight}
                        </div>
                      )}
                    </div>
                  </>
                )}

                <div className="overflow-auto max-h-40">
                  {el.items.map((item, j) => (
                    <RenderItem
                      key={j}
                      id={item.id}
                      title={item.title}
                      param={item.param}
                      name={item.name}
                      type={item.type}
                      alt={item.alt}
                      isRecent={item.isRecent}
                      search={item.search}
                      setInputValue={props.setInputValue}
                      setSearchTerm={props.setSearchTerm}
                    />
                  ))}
                </div>
                {props?.results.length > i + 1 && (
                  <div className="h-0.5 w-full bg-gray-200" />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBarInput;
