/* eslint-disable react-hooks/exhaustive-deps */
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import React, { memo, useEffect, useState } from "react";
import services from "../../../../services/search-services";
import { useContext } from "react";
import { Checkbox } from "antd";
import { FaRegTrashAlt } from "react-icons/fa";
import Axios from "../../../../services/axios";
import Swal from "sweetalert2";
import { IItem } from "../../../job-page-container/main/filter-section-content/FilterSectionContentCopy";

interface IFilterCategoryAccordion {
  maxSelect?: number;
  name: string;
  initialSelectedItems?: { cities: IItem[]; districts: IItem[] };
  initialNewSelectedItems?: { cities: IItem[]; districts: IItem[] };
  placeHolder?: string;
  initialDatas: any;
  api?: {
    endoint: string;
    apiWay: string;
    propertyName: string;
  };
  items?: IItem[];
  isOpen?: boolean;
  onChange?: (e: { cities: IItem[]; districts: IItem[] }) => void;
  deleteItem?: boolean;
}

const FilterAccordionWorkAlarmCityDistrict: React.FC<
  IFilterCategoryAccordion
> = (props: IFilterCategoryAccordion) => {
  const [selectedCities, setSelectedCities] = useState<IItem[]>([]);
  const [newSelectedCities, setNewSelectedCities] = useState<IItem[]>([]);
  const [selectedDistricts, setSelectedDistricts] = useState<IItem[]>([]);
  const [newSelectedDistricts, setNewSelectedDistricts] = useState<IItem[]>([]);
  const [isActive, setIsActive] = useState(props.isOpen || false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState<IItem[]>(
    props.initialDatas ? props.initialDatas : []
  );
  const axios = new Axios();

  const selectedValuesLength = 0;

  // Update filteredItems when props.items changes
  useEffect(() => {
    //console.log(props.items);
    setFilteredItems(props.initialDatas ? props.initialDatas : []);
  }, [props.initialDatas]);

  //FIXME: Bu kısım sürekli döngüye sebep oluyor bu yüzden kaldırıldı buglar tespit edilip düzeltilecek
  useEffect(() => {
    setSelectedCities(props.initialSelectedItems?.cities || []);
    setSelectedDistricts(props.initialSelectedItems?.districts || []);
  }, [props.initialSelectedItems]);

  useEffect(() => {
    setNewSelectedCities(props.initialNewSelectedItems?.cities || []);
    setNewSelectedDistricts(props.initialNewSelectedItems?.districts || []);
  }, [props.initialNewSelectedItems]);

  useEffect(() => {
    const delay = setTimeout(() => {
      search();
    }, 500);

    return () => {
      clearTimeout(delay);
    };
  }, [searchTerm]);

  useEffect(() => {
    isActive && search();
  }, [isActive]);

  const search = async () => {
    if (props.items) {
      setFilteredItems(
        props.items?.filter((d) =>
          d.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
        )
      );
      return;
    }

    if (searchTerm.length > 0) {
      if (props.api && isActive) {
        const options = await services.searchServicesCustom(
          props.api.endoint,
          searchTerm,
          "[]",
          10 + newSelectedDistricts.length + newSelectedCities.length
        );
        console.log(options);
        setFilteredItems(
          options.data[props.api.apiWay].map((el: any) => ({
            [props.api!.propertyName]: el[props.api!.propertyName],
            id: el.id,
            slug: el.slug,
            type: el.type,
          }))
        );
      }
    } else {
      setFilteredItems(props.initialDatas ? props.initialDatas : []);
    }
  };

  const isChecked = (id: number, type: string) => {
    if (type == "district") {
      return newSelectedDistricts.some((item) => item.id === id);
    }
    return newSelectedCities.some((item) => item.id === id);
  };

  const addOrRemoveItem = (el: any) => {
    console.log("adding or removing", el);
    /*
      {name: 'İstanbul - Tümü', id: 83, slug: 'istanbul', type: 'city'}
      {name: 'İstanbul - Avrupa', id: 81, slug: 'istanbul-avrupa', type: 'city'}
      {name: 'İstanbul - Anadolu', id: 82, slug: 'istanbul-anadolu', type: 'city'}
*/
    const type = isChecked(el.id, el.type) ? "remove" : "add";
    let newSelectedItems;

    if (el.type == "district") {
      if (type === "add") {
        if (
          props.maxSelect &&
          newSelectedDistricts.length === props.maxSelect
        ) {
          newSelectedItems = [el];
        } else {
          newSelectedItems = [...newSelectedDistricts, el];
        }
      } else {
        newSelectedItems = newSelectedDistricts.filter(
          (item: any) =>
            item[props.api!.propertyName] !== el[props.api!.propertyName]
        );
      }
      setNewSelectedDistricts(newSelectedItems);
      setSelectedDistricts(newSelectedItems);
      return;
    }

    if (type === "add") {
      if (props.maxSelect && newSelectedCities.length === props.maxSelect) {
        newSelectedItems = [el];
      } else {
        let extraArr: any = [];
        let extra = [
          {
            name: "İstanbul - Avrupa",
            id: 81,
            slug: "istanbul-avrupa",
            type: "city",
          },
          {
            name: "İstanbul - Anadolu",
            id: 82,
            slug: "istanbul-anadolu",
            type: "city",
          },
        ];
        if (el.id === 83) {
          extraArr = extra.filter((el: any) => {
            return !(
              newSelectedCities.filter((ct: any) => ct.id === el.id).length > 0
            );
          });
        }
        newSelectedItems =
          el.id !== 83
            ? [...newSelectedCities, el]
            : [...newSelectedCities, el, ...extraArr];
      }
    } else {
      newSelectedItems = newSelectedCities.filter(
        (item: any) =>
          item[props.api!.propertyName] !== el[props.api!.propertyName]
      );
    }

    setNewSelectedCities(newSelectedItems);
    setSelectedCities(newSelectedItems);
  };

  useEffect(() => {
    //FIXME: Bu kısım yukarıdaki props.initialSelectedItemsa bağlı useEffectin sürekli triggerlanmasına sebep oluyor

    if (
      JSON.stringify(props.initialNewSelectedItems) !==
      JSON.stringify({
        cities: newSelectedCities,
        districts: newSelectedDistricts,
      })
    ) {
      props.onChange &&
        props.onChange({
          cities: newSelectedCities,
          districts: newSelectedDistricts,
        });
    }
  }, [newSelectedCities, newSelectedDistricts]);

  const RenderTitle = (title: string) => {
    const searchTermLocale = searchTerm.toLocaleLowerCase("tr-TR");
    const titleLocale = title.toLocaleLowerCase("tr-TR");

    const parts = titleLocale.split(searchTermLocale);

    if (parts.length <= 1) return title; // No match found

    let reconstructedTitle = "";
    let currentIndex = 0;

    parts.forEach((part, index) => {
      if (index > 0) {
        const matchStart = titleLocale.indexOf(searchTermLocale, currentIndex);
        const originalMatch = title.substring(
          matchStart,
          matchStart + searchTerm.length
        );
        reconstructedTitle += `<span style="font-weight: 600;"><u>${originalMatch}</u></span>`;
        currentIndex = matchStart + searchTerm.length;
      }
      reconstructedTitle += title.substring(
        currentIndex,
        currentIndex + part.length
      );
      currentIndex += part.length;
    });

    return reconstructedTitle;
  };

  return (
    <div className="w-full bg-gray-200 border border-gray-400 rounded select-none">
      {/** Accordion Header */}
      <div
        onClick={() => {
          setIsActive(!isActive);
        }}
        className="flex items-center justify-between w-full p-3 cursor-pointer"
      >
        <div className="text-sm text-gray-500 poppins-semibold">
          {props.name}
        </div>
        <IconChevronDown
          className={`${isActive && "rotate-180"} transition-all`}
        />
      </div>
      {/** Accordion Body */}
      <div className={`transition-all ${isActive ? "h-42" : "h-0"}`}>
        {isActive && (
          <div className="w-full">
            {props.placeHolder && (
              <div className="flex items-center w-11/12 px-2 py-2 mx-auto mb-2 bg-white border border-black rounded gap-x-2">
                <IconSearch size={18} />
                <input
                  className="h-6 text-sm bg-transparent outline-none poppins-medium"
                  placeholder={props.placeHolder}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            )}

            {(selectedCities.length > 0 || selectedDistricts.length > 0) && (
              <div className="flex flex-col gap-y-2">
                {selectedCities.map((item: any, i) => {
                  return (
                    <div key={i} className="flex items-center px-4 gap-x-2">
                      <input
                        type="checkbox"
                        checked={isChecked(item.id, item.type)}
                        className="test"
                        onChange={(e) => {
                          addOrRemoveItem(item);
                        }}
                      />

                      <div
                        onClick={() => {
                          addOrRemoveItem(item);
                        }}
                        className="text-sm text-gray-600 poppins-medium"
                        dangerouslySetInnerHTML={{
                          __html: RenderTitle(
                            item[
                              props.api?.propertyName
                                ? props.api?.propertyName
                                : "title"
                            ]
                          ),
                        }}
                      />
                    </div>
                  );
                })}
                {selectedDistricts.map((item: any, i) => {
                  return (
                    <div key={i} className="flex items-center px-4 gap-x-2">
                      <input
                        type="checkbox"
                        checked={isChecked(item.id, item.type)}
                        className="test"
                        onChange={(e) => {
                          addOrRemoveItem(item);
                        }}
                      />

                      <div
                        onClick={() => {
                          addOrRemoveItem(item);
                        }}
                        className="text-sm text-gray-600 poppins-medium"
                        dangerouslySetInnerHTML={{
                          __html: RenderTitle(
                            item[
                              props.api?.propertyName
                                ? props.api?.propertyName
                                : "title"
                            ]
                          ),
                        }}
                      />
                    </div>
                  );
                })}

                <div className="w-full bg-gray-300 p-[1px] my-1"></div>
              </div>
            )}

            <div className="flex flex-col mb-2 overflow-auto gap-y-3 lg:gap-y-[10px] max-h-36 mt-3">
              {filteredItems
                .filter(
                  (el) =>
                    el.id !==
                      selectedCities.find((compare) => compare.id === el.id)
                        ?.id &&
                    el.id !==
                      selectedDistricts.find((compare) => compare.id === el.id)
                        ?.id
                )
                .map((item: any, i) => {
                  //console.log(item);
                  return (
                    <div key={i} className="flex items-center px-4 gap-x-2">
                      <>
                        <input
                          type="checkbox"
                          checked={isChecked(item.id, item.type)}
                          className="test"
                          onChange={(e) => {
                            addOrRemoveItem(item);
                          }}
                        />
                        <div
                          onClick={() => {
                            addOrRemoveItem(item);
                          }}
                          className="text-sm text-gray-600 poppins-medium"
                          dangerouslySetInnerHTML={{
                            __html: RenderTitle(
                              item[
                                props.api?.propertyName
                                  ? props.api?.propertyName
                                  : "title"
                              ]
                            ),
                          }}
                        />
                      </>
                    </div>
                  );
                })}

              {/*{selectedItems.slice(selectedValuesLength).map((item, i) => {*/}
              {/*  return (*/}
              {/*    <div key={i} className="flex items-center px-4 gap-x-2">*/}
              {/*      <>*/}
              {/*        <input*/}
              {/*          type="checkbox"*/}
              {/*          checked={isChecked(item.title)}*/}
              {/*          className="test"*/}
              {/*          onChange={(e) => {*/}
              {/*            addOrRemoveItem(item);*/}
              {/*          }}*/}
              {/*        />*/}
              {/*        <div*/}
              {/*          onClick={() => {*/}
              {/*            addOrRemoveItem(item);*/}
              {/*          }}*/}
              {/*          className="text-sm text-gray-600 poppins-medium"*/}
              {/*          dangerouslySetInnerHTML={{*/}
              {/*            __html: RenderTitle(item.title + " asd2"),*/}
              {/*          }}*/}
              {/*        />*/}
              {/*      </>*/}
              {/*    </div>*/}
              {/*  );*/}
              {/*})}*/}

              {/*{filteredItems?.map((item, i) => {*/}
              {/*  if (*/}
              {/*    selectedItems.find(*/}
              {/*      (selectedItem) => selectedItem.id === item.id*/}
              {/*    )*/}
              {/*  ) {*/}
              {/*    return null;*/}
              {/*  } else {*/}
              {/*    return (*/}
              {/*      <div key={i} className="flex items-center px-4 gap-x-2">*/}
              {/*        <>*/}
              {/*          {props.deleteItem !== true && (*/}
              {/*            <input*/}
              {/*              type="checkbox"*/}
              {/*              checked={isChecked(item.title)}*/}
              {/*              className="test"*/}
              {/*              onChange={(e) => {*/}
              {/*                addOrRemoveItem(item);*/}
              {/*              }}*/}
              {/*            />*/}
              {/*          )}*/}

              {/*          <div*/}
              {/*            onClick={() => {*/}
              {/*              addOrRemoveItem(item);*/}
              {/*            }}*/}
              {/*            className={`text-sm text-gray-600 poppins-medium  ${*/}
              {/*              props.deleteItem === true ? "w-full" : ""*/}
              {/*            } `}*/}
              {/*            dangerouslySetInnerHTML={{*/}
              {/*              __html: RenderTitle(item.title),*/}
              {/*            }}*/}
              {/*          />*/}
              {/*          {props.deleteItem === true && (*/}
              {/*            <FaRegTrashAlt*/}
              {/*              className="text-red-500 cursor-pointer"*/}
              {/*              onClick={() => {*/}
              {/*                handleDeleteItem(item);*/}
              {/*              }}*/}
              {/*            />*/}
              {/*          )}*/}
              {/*        </>*/}
              {/*      </div>*/}
              {/*    );*/}
              {/*  }*/}
              {/*})}*/}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(FilterAccordionWorkAlarmCityDistrict);
