import React from 'react';
import Breadcrump from '../../../../components/common/profile/breadcrump';
import Main from './main';




const Body: React.FC = () => {

 

  return (
    <div className='w-full flex flex-col gap-y-6'>
    <Breadcrump type="page" pageName='Mülakatlar'/>
    <Main/>

    </div>
  );
};

export default Body;
