import { ButtonHTMLAttributes, MouseEvent } from "react"
import classNames from "classnames"
import { useContext } from "react"
import { SelectDropdownContext, selectDropdownContextType } from "."
import { IoChevronDown } from "react-icons/io5"


type triggerPropsType = ButtonHTMLAttributes<HTMLButtonElement> & {

}






export default function Trigger({
    className,
    children,
    onClick,
    type = 'button',
    ...props
}: triggerPropsType) {

    const { mode, isOpen, setIsOpen, error }: selectDropdownContextType = useContext(SelectDropdownContext)

    const cn = classNames({
        'py-2 px-4 rounded-md font-poppins flex flex-row gap-2 items-center text-[14px] line-clamp-1 overflow-hidden text-ellipsis whitespace-nowrap': true,
        'border-gray-400 border text-gray-600': mode == 'empty',
        'border-blue-600 border text-blue-600': mode == 'dirty',
        'border-red-600 border text-red-600 bg-red-50': error == true,
        'formGlow border-blue-500 border': isOpen == true
    })

    
    return(
        <button 
        {...props} 
        type={type} 
        className={`${cn} ${className}`} 
        onClick={((e) => buttonOnClickFunction(e))}>
            {children} <IoChevronDown className={`${isOpen? 'rotate-180': ''} transition-all ml-auto`}/>
        </button>
    )

    function buttonOnClickFunction(e: MouseEvent<HTMLButtonElement>) {
        if (onClick) {
            onClick(e) 
        }
        setIsOpen((prev) => !prev)
    }
}