import React, { useState } from "react";
import DefaultCv from "./my-cv";
import CvDetails from "./cv-detail";
import { useParams } from "react-router-dom";

const Body: React.FC = () => {
  const { slug } = useParams();

  return (
    <div className="w-full flex flex-col gap-y-6">
      {/* <DefaultCv /> */}
      {/* Pass the PDF generation function to the button component */}
      <CvDetails /> {/* Pass the generated PDF to the CvDetails component */}
    </div>
  );
};

export default Body;
