import React, { useCallback, useEffect, useState } from "react";
import { CalendarPlusIcon } from "../../../theme/icons";
import { IconChevronRight, IconMapPin } from "@tabler/icons-react";
import { CustomListBox } from "../../../components/common/custom-listbox";
import { CustomButton } from "../../../components/common/login/custom-button";
import Axios from "../../../services/axios";
import { useCookie } from "../../../hooks/useCookie";
import { CookieName } from "../../../constants/enums";
import moment from "moment";
import "moment/locale/tr";
import Swal from "sweetalert2";
import Select from "../../../components/form-components/select";
import Button from "../../../components/form-components/button";
import { IoDocumentTextOutline } from "react-icons/io5";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import { FiAlertCircle } from "react-icons/fi";
import { BiError } from "react-icons/bi";
import { CustomCombobox } from "../../../components/common/custom-combobox";
import { useRef } from "react";
import {
  RiArrowGoBackFill,
  RiEditLine,
  RiFileCopy2Line,
  RiEyeLine,
  RiLock2Line,
  RiDeleteBinLine,
  RiArchive2Line,
  RiFileLine,
  RiLock2Fill,
} from "react-icons/ri";
import { Tabs } from "antd";
import { Tooltip } from "react-tooltip";
import { useNavigate, useLocation } from "react-router-dom";
import { CardSection } from "../../login-page-container/company/login/general-packages/packages-contents/card-section";
import { CustomCard } from "../../../components/common/login/custom-card";
import { GeneralPackages } from "../../login-page-container/company/login/general-packages";
import { Pagination } from "../../../components/isbul-ui/pagination";
import { CustomDropdown } from "../../../components/common/add-create-form/addDetail/content/custom-dropdown";
import { IoMdArrowDropup } from "react-icons/io";
import { set } from "lodash";

const axios = new Axios();

const _Alert = (
  title: string,
  text: string,
  icon: "error" | "info" | "question" | "success" | "warning",
  showCancelButton: boolean = true,
  confirmButtonColor: string = "#2253af",
  cancelButtonColor: string = "red",
  cancelButtonText: string = "İptal",
  confirmButtonText: string = "Evet"
): Promise<boolean> => {
  return Swal?.fire({
    title: title,
    text: text,
    icon: icon,
    showCancelButton: showCancelButton,
    confirmButtonColor: confirmButtonColor,
    cancelButtonColor: cancelButtonColor,
    cancelButtonText: cancelButtonText,
    confirmButtonText: confirmButtonText,
  }).then((result) => {
    return result.isConfirmed;
  });
};

interface City {
  readonly id: number;
  readonly name: string;
}

interface Ad {
  readonly id: number;
  readonly title: string;
  readonly cities: City[];
  readonly created_at: string;
  readonly updated_at: string;
  readonly publish_date: string;
  readonly end_date: string;
  readonly status: string;
  readonly is_active: number;
  readonly advert_applies_count: number;
  readonly today_advert_applies_count: number;
  readonly slug: string;
  readonly is_use_doping: number;
  readonly is_draft: number;
  readonly doping_end_date: string;
}

type TransactionsType = {
  icon: React.ReactElement;
  name: string;
  link?: string;
  slug?: string;
};

const Draft_Transactions: TransactionsType[] = [
  { icon: <RiEditLine />, name: "İlanı Düzenle" },
  { icon: <RiFileCopy2Line />, name: "İlanı Kopyala" },
  { icon: <RiEyeLine />, name: "İlanı Görüntüle" },
  { icon: <RiLock2Line />, name: "Onaya Gönder" },
];

const Waiting_Transactions: TransactionsType[] = [
  { icon: <RiEditLine />, name: "İlanı Düzenle" },
  { icon: <RiFileCopy2Line />, name: "İlanı Kopyala" },
  { icon: <RiEyeLine />, name: "İlanı Görüntüle" },
  { icon: <RiLock2Line />, name: "Onay Sürecini İptal Et" },
  { icon: <RiArchive2Line />, name: "Arşivle" },
  { icon: <RiArchive2Line />, name: "İlanı Sil" },
];

const Active_Transactions: TransactionsType[] = [
  { icon: <RiEditLine />, name: "İlanı Düzenle" },
  { icon: <RiFileCopy2Line />, name: "İlanı Kopyala" },
  { icon: <RiEyeLine />, name: "İlanı Görüntüle" },
  { icon: <RiFileLine />, name: "Başvurular" },
  { icon: <RiLock2Fill />, name: "İlanı Pasif Yap" },
  { icon: <RiLock2Fill />, name: "İlan Tarihini Güncelle" },
];

const Passive_Transactions: TransactionsType[] = [
  { icon: <RiFileCopy2Line />, name: "İlanı Kopyala" },
  { icon: <RiEyeLine />, name: "İlanı Görüntüle" },
  { icon: <RiFileLine />, name: "Başvurular" },
  { icon: <RiArchive2Line />, name: "Arşivle" },
];

const Archive_Transactions: TransactionsType[] = [
  { icon: <RiEditLine />, name: "İlanı Düzenle" },
  { icon: <RiFileCopy2Line />, name: "İlanı Kopyala" },
  { icon: <RiEyeLine />, name: "İlanı Görüntüle" },
  { icon: <RiArchive2Line />, name: "Arşivden Çıkar" },
];

const Rejected_Transactions: TransactionsType[] = [
  { icon: <RiEditLine />, name: "İlanı Düzenle" },
  { icon: <RiFileCopy2Line />, name: "İlanı Kopyala" },
  { icon: <RiEyeLine />, name: "İlanı Görüntüle" },
  { icon: <RiArchive2Line />, name: "Arşivle" },
];

type AdsSectionType = {
  adsData: Ad[];
  setAdsData: React.Dispatch<React.SetStateAction<Ad[]>>;
  FindClick?: () => void;
  onChangeFindValue?: (val: string | number) => void;
  paginationData: any;
  setPaginationData: any;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  fetchAdsData?: (page: number, status?: string) => void;
  setLastPage: any;
  setSelectedStatus: React.Dispatch<React.SetStateAction<string>>;
  handleChangePendingStatus?: any;
  handleDeleteChangeArchiveStatus?: any;
  selectedStatus?: string;
};

type statusDType = {
  name: string;
  array: TransactionsType[];
};

const AdsSection: React.FC<AdsSectionType> = ({
  adsData,
  setAdsData,
  FindClick,
  onChangeFindValue,
  paginationData,
  setPaginationData,
  currentPage,
  setCurrentPage,
  fetchAdsData,
  setLastPage,
  setSelectedStatus,
  handleChangePendingStatus,
  handleDeleteChangeArchiveStatus,
  selectedStatus,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeParam = queryParams.get("active");

  const [selectedValue, setSelectedValue] = React.useState<string | undefined>(
    undefined
  );
  const { getCookie } = useCookie();
  const companyUserToken = getCookie(CookieName.CompanyUserToken);
  const [statusD, setStatusD] = React.useState<statusDType[]>([
    { name: "ACTIVE", array: Active_Transactions },
    { name: "PENDING", array: Waiting_Transactions },
    { name: "PENDING_UPDATE", array: Waiting_Transactions },
    { name: "ARCHIVED", array: Archive_Transactions },
    { name: "DRAFT", array: Draft_Transactions },
    { name: "PASSIVE", array: Passive_Transactions },
    { name: "UPDATE", array: Waiting_Transactions },
    { name: "REJECTED", array: Rejected_Transactions },
  ]);

  const [checkeddatas, setCheckedDatas] = React.useState<Ad[]>([]);
  const [isMasterChecked, setIsMasterChecked] = useState(false);

  const truncate = (str: string, n: number) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  // İlanı güncelleme işlemi

  const handleUpdateAd = async (id: number) => {
    const response = await axios.authRequest({
      type: "post",
      endpoint: `companies/home/${id}/update-date`,
      payload: "",
    });

    if (response?.status === 200) {
      const updatedAds = adsData?.map((ad) => {
        if (ad.id === id) {
          return { ...ad, updated_at: new Date().toISOString() };
        }
        return ad;
      });

      Swal.fire({
        title: "Başarılı",
        text: "İlan tarihi güncellendi",
        icon: "success",
      });

      setAdsData(updatedAds);
    } else {
      Swal.fire({
        title: "Hata",
        text: "İlan tarihi güncellenemedi",
        icon: "error",
      });
    }
  };

  const [adVisible, setAdVisible] = React.useState<boolean>(false);

  useEffect(() => {
    const checkAdVisibility = () => {
      if (!adsData) {
        return;
      }

      const currentDate = new Date();
      const twoDaysAgo = new Date(currentDate);
      twoDaysAgo.setDate(currentDate.getDate() - 2);

      const isAnyAdVisible = adsData.some((ad) => {
        const updatedDate = new Date(ad.updated_at);
        return updatedDate <= twoDaysAgo;
      });

      setAdVisible(isAnyAdVisible);
    };

    checkAdVisibility();
  }, [adsData]);

  useEffect(() => {
    Swal.close();
  }, []);

  const getUrlByName = (
    adId: number,
    name: string,
    slug?: string,
    isActive: boolean = false
  ): string => {
    switch (name) {
      case "İlanı Düzenle":
        if (isActive) {
          Swal.fire({
            html: `<div class="text-red-500"> <p>İlanın başlığı, metni veya sektör - bölüm - pozisyon bilgilerinde yapacağınız değişiklikler destek ekibi tarafından onaylandıktan sonra aktif olacaktır!<br><br>İlanı şu anki pozisyondan farklı bir pozisyona dönüştürürseniz değişiklik talebiniz onaylanmayacaktır. Farklı bir pozisyon için eleman arıyorsanız yeni ilan eklemeniz gerekir. Kaldığınız yerden bu ilanı yayınlamaya devam etmek ister misiniz?</p> </div>`,
            icon: "error",
            showCancelButton: true,
            confirmButtonText: "Evet",
            cancelButtonText: "Hayır",
            confirmButtonColor: "#2253af",
            cancelButtonColor: "#6c757d",
          }).then((result) => {
            if (!result.isConfirmed) {
              navigate(`/isveren`);
            }
          });
        }
        return `/isveren/ilanlar/ilan/${adId}/guncelle`;
      case "İlanı Kopyala":
        Swal.fire({
          title: "Başarılı!",
          text: "İlan kopyalandı",
          icon: "success",
        });
        return `/isveren/ilanlar/ilan/${adId}/guncelle`;
      case "İlanı Görüntüle":
        return isActive
          ? `/is-ilanlari/${slug}`
          : `/isveren/ilanlar/ilan/${adId}/onizleme`;
      case "Başvurular":
        return `/isveren/basvurular/${adId}`;
      case "Arşivle":
        return ``;
      default:
        return "#";
    }
  };

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  const [activeKey, setActiveKey] = useState("1");
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const triggerRef = useRef<HTMLParagraphElement | null>(null);
  const [selectAdvertType, setSelectAdvertType] =
    useState<string>("Tüm İlanlar");

  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleOpenMenu = () => {
    if (activeKey === "1") {
      setIsOpenMenu(!isOpenMenu);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      triggerRef.current &&
      !triggerRef.current.contains(event.target as Node)
    ) {
      setIsOpenMenu(false);
    }
  };

  const handleItemClick = (item: any) => {
    setSelectAdvertType(item);
    setIsOpenMenu(false);
  };

  useEffect(() => {
    if (isOpenMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenMenu]);

  // İlanları filtreleme işlemi

  useEffect(() => {
    let status = "";

    console.log(selectAdvertType);

    if (activeParam === "archived") {
      setSelectAdvertType("Arşiv");
      status = "ARCHIVED";
    } else {
      switch (selectAdvertType) {
        case "Aktif İlanlar":
          status = "ACTIVE";
          break;
        case "Pasif İlanlar":
          status = "PASSIVE";
          break;
        case "Onay Bekleyenler":
          status = "PENDING";
          break;
        case "Taslak":
          status = "DRAFT";
          break;
        case "Arşiv":
          status = "ARCHIVED";
          break;
        case "Reddedildi":
          status = "REJECTED";
          break;
        default:
          status = "";
          setAdsData(adsData);
          break;
      }
    }

    const handleFilterAds = async (status: string) => {
      let endpoint;

      if (status === "ARCHIVED") {
        endpoint = `companies/home/get-adverts?status=ARCHIVED&not_show_archive=0`;
      } else {
        endpoint = `companies/home/get-adverts?status=${status}&not_show_archive=1`;
      }

      const response = await axios.request({
        type: "get",
        endpoint: endpoint,
        payload: {
          headers: {
            Authorization: `Bearer ${companyUserToken}`,
          },
        },
      });

      console.log(response.data.data.adverts);

      setAdsData(response.data.data.adverts.data);
      setPaginationData(response.data.data.adverts);
      setLastPage(response.data.data.adverts.last_page);
    };

    handleFilterAds(status);
  }, [selectAdvertType, activeParam]);

  // İlanı dopingleme işlemi

  const handleAlert = async (id: number) => {
    const result = await Swal.fire({
      title: "İlanı Dopingle",
      text: "İlanınızın 7 gün boyunca en üstte görünmesini ve 50 kat daha fazla başvuru almasını sağlamak için ilanınızı dopingleyin. Onaylıyor musunuz ? ",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2253af",
      cancelButtonColor: "#888",
      cancelButtonText: "Kapat",
      confirmButtonText: "Onayla",
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.authRequest({
          type: "post",
          endpoint: `companies/home/${id}/use-doping`,
          payload: "",
        });

        Swal.fire("Başarılı!", "İlanınız başarıyla dopinglendi.", "success");

        const updatedAds = adsData?.map((ad) => {
          if (ad.id === id) {
            return { ...ad, is_use_doping: 1 };
          }
          return ad;
        });

        setAdsData(updatedAds);
      } catch (error: any) {
        if (
          error.response.data.error ===
          "Doping hakkınız bulunmamaktadır. Ürünler menüsü altından doping paketlerine ulaşabilirsiniz."
        ) {
          Swal.fire({
            title: "Hata!",
            html: error.response.data.error,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: "Tamam",
            confirmButtonText: "Doping Satın Al",
          }).then((response) => {
            if (response.isConfirmed) {
              navigate("/isveren/ürünler");
            }
          });
        } else {
          Swal.fire("Hata!", error?.response?.data?.error, "error");
        }
      }
    }
  };

  // Tüm ilanları güncelleme işlemi

  const handleUpdateAllAds = async () => {
    const result = await Swal.fire({
      title: "Tüm ilanları güncellemek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2253af",
      cancelButtonColor: "#888",
      cancelButtonText: "Kapat",
      confirmButtonText: "Onayla",
    });

    if (result.isConfirmed) {
      try {
        const twoDaysAgo = new Date();
        twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
        twoDaysAgo.setHours(0, 0, 0, 0);

        /*
        TODO: Yeni kısım test edilip sonra silinecek
        const hasRecentAds = adsData?.some((ad) => {
          const adDate = new Date(ad.updated_at);
          return adDate >= twoDaysAgo;
        });

        if (hasRecentAds) {
          Swal.fire("Hata!", "Tüm İlanlar Güncel", "error");
          return;
        } */

        const response = await axios.authRequest({
          type: "post",
          endpoint: `companies/home/update-date-all`,
          payload: "",
        });

        if (response && response.status == 200) {
          Swal.fire(
            "Başarılı!",
            "Tüm ilanlarınız başarıyla güncellendi.",
            "success"
          );
        } else {
          Swal.fire(
            "Başarısız!",
            "İlan tarihleri güncellenirken bir hata meydana geldi.",
            "warning"
          );
        }

        const updatedAds = adsData?.map((ad) => {
          return { ...ad, updated_at: new Date().toISOString() };
        });

        setAdsData(updatedAds);
      } catch (error) {
        console.error("Error updating all ads:", error);
        Swal.fire("Hata!", "Bir hata oluştu, lütfen tekrar deneyin.", "error");
      }
    }
  };

  // İlanları seçme işlemi

  const handleMasterCheckboxChange = (e: any) => {
    const isChecked = e.target.checked;
    setIsMasterChecked(isChecked);
    if (isChecked) {
      setCheckedDatas(adsData);
    } else {
      setCheckedDatas([]);
    }
  };

  // İlanı arşivleme işlemi

  const handleArchiveAd = async (id: number) => {
    const result = await Swal.fire({
      title: "İlanı Arşivlemek İstediğinize Emin Misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2253af",
      cancelButtonColor: "#888",
      cancelButtonText: "Kapat",
      confirmButtonText: "Onayla",
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.authRequest({
          type: "post",
          endpoint: `companies/home/change-archive-status`,
          payload: {
            advert_ids: [id],
            status: "in",
          },
        });

        Swal.fire("Başarılı!", "İlanınız başarıyla arşivlendi.", "success");

        const updatedAds = adsData?.filter((ad) => ad.id !== id);

        setAdsData(updatedAds);
      } catch (error) {
        console.error("Error archiving ad:", error);
        Swal.fire(
          "Hata!",
          "Sadece Pasif ve Taslak olan ilanları Arşive gönderebilirsiniz.",
          "error"
        );
      }
    }
  };

  // İlanı arşivden çıkarma işlemi

  const handleChangeArchiveStatus = async (id: number) => {
    console.log(id);

    const result = await Swal.fire({
      title: "İlanı Arşivden çıkarmak istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2253af",
      cancelButtonColor: "#888",
      cancelButtonText: "Kapat",
      confirmButtonText: "Onayla",
    });

    if (result.isConfirmed) {
      // Optimistically update the local state
      const originalAdsData = [...adsData];
      const updatedAds = adsData.filter((ad) => ad.id !== id);

      setAdsData(updatedAds);

      try {
        const response = await axios.authRequest({
          type: "post",
          endpoint: `companies/home/${id}/change-status`,
          payload: {
            new_status: "PASSIVE",
          },
        });

        if (response.status === 200) {
          Swal.fire(
            "Başarılı!",
            "İlanınız başarıyla arşivden çıkarıldı.",
            "success"
          );
        } else {
          // Revert the local state if the API call fails
          setAdsData(originalAdsData);
          Swal.fire(
            "Hata!",
            "İlanı arşivden çıkarma işlemi başarısız oldu.",
            "error"
          );
        }
      } catch (error) {
        // Revert the local state if the API call fails
        setAdsData(originalAdsData);
        Swal.fire(
          "Hata!",
          "İlanı arşivden çıkarma işlemi başarısız oldu.",
          "error"
        );
      }
    }
  };

  // İlanı pasif yapma işlemi

  console.log("AD data", adsData);

  const handleAddPassive = async (id: number) => {
    try {
      const response = await axios.authRequest({
        type: "post",
        endpoint: `companies/home/${id}/change-status`,
        payload: {
          new_status: "PASSIVE",
        },
      });

      Swal.fire(
        "Başarılı!",
        "İlanınız başarıyla pasif hale getirildi.",
        "success"
      );

      const updatedAds = adsData?.map((ad) => {
        if (ad.id === id) {
          return { ...ad, status: "PASSIVE" };
        }
        return ad;
      });

      setAdsData(updatedAds);
    } catch (error) {
      console.error("Error changing status:", error);
      Swal.fire("Hata!", "İlanınız pasif hale getirilemedi.", "error");
    }
  };

  // mobil için tabs oluşturuldu

  const tabItems = [
    {
      label: (
        <p
          ref={triggerRef}
          onClick={() => handleOpenMenu()}
          className="font-poppins poppins-medium text-xs flex justify-center items-center gap-1"
        >
          {selectAdvertType}
          {activeKey === "1" ? (
            <MdKeyboardArrowUp size={20} />
          ) : (
            <MdKeyboardArrowDown size={20} />
          )}{" "}
        </p>
      ),
      key: "1",
      children: (
        <div className="relative" ref={menuRef}>
          <div className="bg-white mb-4 p-4 rounded-b-lg flex justify-start items-center">
            <CustomCombobox
              FindClick={FindClick}
              onChangeValue={onChangeFindValue}
            />
          </div>

          {isOpenMenu && (
            <>
              <div>
                <IoMdArrowDropup className="absolute -top-[10px] left-36 text-4xl z-50" />
              </div>

              <div className="absolute top-3 bg-white max-w-[191px] w-full shadow-xl border border-black rounded-md">
                {[
                  "Tüm İlanlar",
                  "Aktif İlanlar",
                  "Pasif İlanlar",
                  "Onay Bekleyenler",
                  "Taslak",
                  "Arşiv",
                  "Reddedildi",
                ].map((item) => (
                  <p
                    key={item}
                    className="font-poppins poppins-medium text-[11px] border-b border-[#a3a3a36e] p-[6px] pl-3 cursor-pointer"
                    onClick={() => handleItemClick(item)}
                  >
                    {item}
                  </p>
                ))}
              </div>
            </>
          )}

          {adsData?.length === 0 ? (
            <div className="flex items-center mt-4 mb-12 ml-4 mr-4 shadow-lg border rounded-xl">
              <span className="bg-[red] border border-blue mr-4 h-full text-white pt-3 pb-3 pl-1 pr-1 rounded-md">
                <FiAlertCircle size={35} />
              </span>

              <p className="font-poppins poppins-semibold text-sm flex justify-center flex-col items-center gap-[6px]">
                <span>Kayıtlı İlan Bulunamadı</span>
                <a
                  href="/isveren/ilanlar/olustur/ilan-bilgileri"
                  className="underline text-blue-400 underline-offset-[5px]"
                >
                  Yeni İlan Oluşturmak İçin Tıklayın
                </a>
              </p>
            </div>
          ) : (
            adsData.map((ad: Ad) => (
              <div
                key={ad.id}
                className="bg-white shadow-xl lg:shadow-md p-3 lg:p-4 rounded-lg text-gray-600 poppins-medium mb-2"
              >
                <div className="flex items-center flex-wrap lg:flex-nowrap justify-between">
                  <div className="flex items-center lg:w-4/12 lg:pl-4 gap-x-2">
                    <input
                      className="hidden lg:block"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCheckedDatas((prev) => [...prev, ad]);
                        } else {
                          setCheckedDatas((prev) =>
                            prev.filter((item) => item.id !== ad.id)
                          );
                        }
                      }}
                      checked={checkeddatas.some((item) => item.id === ad.id)}
                      type="checkbox"
                    />

                    <div className="flex items-center lg:ml-4 gap-x-4">
                      <div
                        className={`${
                          new Date(ad.updated_at) <=
                          new Date(new Date().setDate(new Date().getDate() - 2))
                            ? "opacity-100"
                            : "opacity-0"
                        } lg:block hidden`}
                        onClick={() => {
                          handleUpdateAd(ad.id);
                        }}
                      >
                        <a
                          className="cursor-pointer"
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="İlan Tarihini Güncelle"
                          data-tooltip-place="top"
                        >
                          <CalendarPlusIcon size={28} color="#6B7280" />
                        </a>
                        <Tooltip id="my-tooltip" />
                      </div>

                      <div className="flex flex-col gap-y-[6px] lg:gap-y-2">
                        <span
                          onClick={() => {
                            const url = getUrlByName(
                              ad.id,
                              "İlanı Görüntüle",
                              ad.slug,
                              ad.is_active == 1 ? true : false
                            );

                            navigate(url);
                          }}
                          className="lg:poppins-semibold poppins-medium text-sm lg:text-base cursor-pointer"
                        >
                          {truncate(ad.title, 12)}
                        </span>
                        <div className="flex items-center gap-x-1 w-full">
                          <IconMapPin className=" w-3 h3  lg:w-5 lg:h-5 flex-shrink-0" />
                          <label className="poppins-medium text-[11px] lg:text-[13px] min-w-[97px]">
                            {ad.cities[0]?.name || ""}
                          </label>
                        </div>

                        <div className="text-start flex lg:hidden flex-col gap-y-1">
                          <span className="lg:poppins-semibold poppins-medium text-[11px] lg:text-base">
                            {moment(ad.updated_at)
                              .locale("tr")
                              .format("DD MMMM YYYY")
                              .toString()}
                          </span>
                        </div>

                        <span
                          className={`
                    ${
                      ad.is_draft === 1
                        ? "bg-[#453CE6]"
                        : ad.status === "ACTIVE"
                        ? "bg-[#579E3D]"
                        : ad.status === "PENDING" ||
                          ad.status === "PENDING_UPDATE"
                        ? "bg-company-card"
                        : ad.status === "ARCHIVED"
                        ? "bg-gray-700"
                        : ad.status === "PASSIVE"
                        ? "bg-gray-500"
                        : ad.status === "UPDATE"
                        ? "bg-orange-500"
                        : ad.status === "REJECTED"
                        ? "bg-red-500"
                        : ""
                    }
                    text-white py-[6px] rounded-full text-[10px] text-center hidden lg:flex justify-center items-center whitespace-nowrap min-w-[90px] max-w-[90px]`}
                        >
                          {ad.is_draft === 1
                            ? "Taslak"
                            : ad.status === "ACTIVE"
                            ? "Aktif"
                            : ad.status === "PENDING" ||
                              ad.status === "PENDING_UPDATE"
                            ? "Onay Bekliyor"
                            : ad.status === "REJECTED"
                            ? "Reddedildi"
                            : ad.status === "ARCHIVED"
                            ? "Arşivlendi"
                            : ad.status === "PASSIVE"
                            ? "Pasif"
                            : ad.status === "UPDATE"
                            ? "Güncelleme Onayı Bekliyor"
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="flex w-3/12 lg:w-2/12 text-center flex-col gap-y-1 lg:underline mt-[6px]">
                    <span
                      className="cursor-pointer font-poppins text-[11px] lg:text-sm"
                      onClick={() => navigate(`/isveren/basvurular/${ad.id}`)}
                    >
                      <span className="hidden lg:block">
                        {ad.advert_applies_count} Toplam
                      </span>

                      <p className="lg:hidden flex justify-center items-center gap-[2px]">
                        <IoDocumentTextOutline className="text-base" />{" "}
                        <span>{ad.advert_applies_count}</span>
                        <span>Başvuru</span>
                      </p>
                    </span>
                    <span
                      className="cursor-pointer font-poppins text-[11px] lg:text-sm font-semibold"
                      onClick={() => navigate(`/isveren/basvurular/${ad.id}`)}
                    >
                      <span className="lg:block hidden">
                        {ad.today_advert_applies_count} Yeni
                      </span>
                    </span>
                  </div>

                  <span
                    className={`
              ${
                ad.status === "ARCHIVED"
                  ? "bg-gray-700"
                  : ad.is_draft === 1
                  ? "bg-[#453CE6]"
                  : ad.status === "ACTIVE"
                  ? "bg-[#579E3D]"
                  : ad.status === "PENDING" || ad.status === "PENDING_UPDATE"
                  ? "bg-company-card"
                  : ad.status === "PASSIVE"
                  ? "bg-gray-500"
                  : ad.status === "UPDATE"
                  ? "bg-orange-500"
                  : ad.status === "REJECTED"
                  ? "bg-red-500"
                  : ""
              }
              text-white py-[6px] rounded-full text-[10px] text-center lg:flex justify-center items-center whitespace-nowrap min-w-[90px] max-w-[90px] mt-[4px]`}
                  >
                    {ad.status === "ARCHIVED"
                      ? "Arşivlendi"
                      : ad.is_draft === 1
                      ? "Taslak"
                      : ad.status === "ACTIVE"
                      ? "Aktif"
                      : ad.status === "PENDING" ||
                        ad.status === "PENDING_UPDATE"
                      ? "Onay Bekliyor"
                      : ad.status === "PASSIVE"
                      ? "Pasif"
                      : ad.status === "UPDATE"
                      ? "Güncelleme Onayı Bekliyor"
                      : ad.status === "REJECTED"
                      ? "Reddedildi"
                      : ""}
                  </span>

                  <div className="md:w-3/12 text-center hidden lg:flex flex-col gap-y-1 text-sm ">
                    <span>
                      {moment(ad.created_at)
                        .locale("tr")
                        .format("DD MMMM YYYY")
                        .toString()}
                    </span>
                    <span>
                      {moment(ad.updated_at)
                        .locale("tr")
                        .format("DD MMMM YYYY")
                        .toString()}
                    </span>
                  </div>

                  <div className="w-full lg:w-4/12 text-right text-sm flex gap-y-1 gap-x-2 justify-between min-w-[305px] mt-4">
                    <div className="flex gap-x-2 items-center justify-between w-full">
                      {ad.is_active === 1 && ad.is_draft === 0 ? (
                        <>
                          {ad.status === "ACTIVE" && (
                            <CustomButton
                              onClick={() => handleAlert(ad.id)}
                              text="Dopingle"
                              className="ml-2 text-white poppins-medium bg-company-card py-2 px-3 rounded-md hover:bg-company-card/80 w-full max-w-[115px]"
                            />
                          )}

                          <CustomButton
                            onClick={() =>
                              navigate(`/isveren/basvurular/${ad.id}`)
                            }
                            text="Başvurular"
                            className="text-white poppins-medium bg-mains py-2 px-3 rounded-md hover:bg-mains/80 max-w-[115px]"
                          />
                          <Select
                            required={true}
                            selectedValue={selectedValue}
                            placeholder="İşlemler"
                            className="border-2 border-[#1565c0] rounded-md max-w-[100px] bg-[#F8F8F8]"
                          >
                            <Select.Input
                              className="w-[100px] flex justify-center items-center font-poppins text-[13px] !min-h-[20px] text-gray-500 border-none !pr-3"
                              size={30}
                              placeholder="İşlemler"
                            />
                            <Select.Items>
                              {Active_Transactions.map((item, i) => {
                                if (
                                  item.name === "İlan Tarihini Güncelle" &&
                                  new Date(ad.updated_at) >
                                    new Date(
                                      new Date().setDate(
                                        new Date().getDate() - 2
                                      )
                                    )
                                ) {
                                  return null;
                                }
                                if (
                                  item.name === "İlanı Pasif Yap" &&
                                  ad.status === "PASSIVE"
                                ) {
                                  return null;
                                }
                                if (
                                  item.name === "İlan Tarihini Güncelle" &&
                                  ad.status === "PASSIVE"
                                ) {
                                  return null;
                                }
                                return (
                                  <Button
                                    onClick={() => {
                                      if (
                                        item.name == "İlan Tarihini Güncelle"
                                      ) {
                                        handleUpdateAd(ad.id);
                                      }
                                      const url = getUrlByName(
                                        ad.id,
                                        item.name,
                                        ad.slug,
                                        true
                                      );
                                      if (item.name === "Arşivle") {
                                        handleArchiveAd(ad.id);
                                      }
                                      if (item.name == "İlanı Pasif Yap") {
                                        handleAddPassive(ad.id);
                                      }
                                      navigate(url);
                                    }}
                                    size="xs"
                                    className="text-xs bg-transparent hover:text-mains !border !border-b !border-b-gray-300 !rounded-none hover:underline hover:bg-gray-100 !px-[10px] !py-[8px]"
                                    type="button"
                                    variant="secondary"
                                  >
                                    <Button.Icon className="w-4 h-4">
                                      {item.icon}
                                    </Button.Icon>
                                    {item.name}
                                  </Button>
                                );
                              })}
                            </Select.Items>
                          </Select>
                        </>
                      ) : (
                        <div className="w-full flex gap-x-2">
                          {ad.status === "ACTIVE" && (
                            <CustomButton
                              onClick={() => handleAlert(ad.id)}
                              text="Dopingle"
                              className="ml-2 text-white poppins-medium bg-company-card py-2 px-3 rounded-md hover:bg-company-card/80 w-full max-w-[115px]"
                            />
                          )}
                          <CustomButton
                            onClick={() =>
                              navigate(`/isveren/basvurular/${ad.id}`)
                            }
                            text="Başvurular"
                            className="text-white poppins-medium bg-mains py-2 px-3 rounded-md hover:bg-mains/80 max-w-[115px]"
                          />
                          <Select
                            required={true}
                            selectedValue={selectedValue}
                            placeholder="İşlemler"
                            className="border-2 border-[#1565c0] rounded-md max-w-[100px] bg-[#F8F8F8]"
                          >
                            <Select.Input
                              className="w-[100px] flex justify-center items-center font-poppins text-[13px] !min-h-[20px] text-gray-500 border-none !pr-3"
                              size={30}
                              placeholder="İşlemler"
                            />
                            <Select.Items className="!right-1/2 !-translate-x-[50px] w-[200px]">
                              {statusD
                                .filter((e) => e.name === ad.status)
                                .map((filteredItem, index) =>
                                  filteredItem?.array?.map((item, i) => {
                                    if (
                                      item.name === "Onay Sürecini İptal Et" &&
                                      ad.status === "PENDING" &&
                                      ad.is_draft === 1
                                    ) {
                                      return null;
                                    }
                                    if (
                                      item.name === "Onaya Gönder" &&
                                      ad.status === "PENDING" &&
                                      ad.is_draft === 0
                                    ) {
                                      return null;
                                    }
                                    if (
                                      item.name === "İlanı Sil" &&
                                      (ad.status === "PENDING" ||
                                        ad.status === "PASSIVE") &&
                                      ad.is_draft === 0
                                    ) {
                                      return null;
                                    }
                                    if (
                                      item.name === "İlanı Kopyala" &&
                                      ad.status === "PENDING" &&
                                      ad.is_draft === 0
                                    ) {
                                      return null;
                                    }
                                    if (
                                      item.name === "Arşivle" &&
                                      ad.status === "PENDING" &&
                                      ad.is_draft === 0
                                    ) {
                                      return null;
                                    }
                                    if (
                                      item.name === "Onaya Gönder" &&
                                      (ad.status === "PENDING" ||
                                        ad.status === "PASSIVE") &&
                                      ad.is_draft === 0
                                    ) {
                                      return null;
                                    }
                                    return (
                                      <Button
                                        key={i}
                                        onClick={() => {
                                          const url = getUrlByName(
                                            ad.id,
                                            item.name,
                                            ad.slug
                                          );
                                          navigate(url);
                                          if (item.name === "Arşivle") {
                                            handleArchiveAd(ad.id);
                                          }
                                          switch (item.name) {
                                            case "İlanı Sil":
                                              handleDelete(ad.id);
                                              break;
                                            case "Arşivden Çıkar":
                                              handleChangeArchiveStatus(ad.id);
                                              break;
                                            case "Onay Sürecini İptal Et":
                                              handleDeleteChangeArchiveStatus(
                                                ad.id
                                              );
                                              break;
                                            case "Onaya Gönder":
                                              handleChangePendingStatus(ad.id);
                                              break;
                                            default:
                                              break;
                                          }
                                        }}
                                        size="xs"
                                        className="text-xs bg-transparent hover:text-mains !border !border-b !border-b-gray-300 !rounded-none hover:underline hover:bg-gray-100 !px-[10px] !py-[8px]"
                                        type="button"
                                        variant="secondary"
                                      >
                                        <Button.Icon className="w-4 h-4">
                                          {item.icon}
                                        </Button.Icon>
                                        {item.name}
                                      </Button>
                                    );
                                  })
                                )}
                            </Select.Items>
                          </Select>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      ),
    },
    {
      label: (
        <p className="font-poppins poppins-medium text-xs flex justify-center items-center gap-1">
          Ürünlerimiz
          {activeKey === "2" ? (
            <MdKeyboardArrowUp size={20} />
          ) : (
            <MdKeyboardArrowDown size={20} />
          )}
        </p>
      ),
      key: "2",
      children: (
        <>
          <GeneralPackages />
        </>
      ),
    },
  ];

  const handleDelete = async (id: number) => {
    try {
      const result = await Swal.fire({
        title: "İlanı Silmek İstediğinize Emin Misiniz?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2253af",
        cancelButtonColor: "#888",
        cancelButtonText: "İptal",
        confirmButtonText: "Evet",
      });

      if (result.isConfirmed) {
        // Optimistically update the local state
        const originalAdsData = [...adsData];
        const updatedAds = adsData.filter((ad) => ad.id !== id);

        setAdsData(updatedAds);

        try {
          const response = await axios.authRequest({
            type: "delete",
            endpoint: `companies/home/${id}/delete-advert`,
            payload: {},
          });

          if (response.status === 200) {
            Swal.fire("Başarılı!", "İlanınız başarıyla silindi.", "success");
          } else {
            // Revert the local state if the API call fails
            setAdsData(originalAdsData);
            Swal.fire("Hata!", "İlanı silme işlemi başarısız oldu.", "error");
          }
        } catch (error) {
          // Revert the local state if the API call fails
          setAdsData(originalAdsData);
          Swal.fire("Hata!", "İlanı silme işlemi başarısız oldu.", "error");
        }
      }
    } catch (error) {}
  };

  //console.log(adsData, "adsData");

  return (
    <>
      {adsData && adsData.length > 0 ? (
        <>
          {" "}
          <div className="bg-white p-4 rounded-lg text-mains poppins-semibold mb-4 hidden lg:block">
            <div className="flex items-center overflow-scroll md:overflow-auto pb-2 md:pb-0 gap-8 md:gap-0">
              <div className="flex items-center md:w-4/12 pl-4 gap-x-2">
                <input
                  type="checkbox"
                  checked={isMasterChecked}
                  onChange={handleMasterCheckboxChange}
                />
                <div className="flex items-center ml-4 gap-x-5">
                  <div className="md:w-6 h-6"></div>
                  <span className=" w-[68px] ">İlan adı</span>
                </div>
              </div>
              <div className="md:w-2/12 text-center">
                <span>Başvurular</span>
              </div>
              <div className="md:w-3/12 text-center">
                <span>Yayınlanma/Güncellenme</span>
              </div>
              <div className="md:w-4/12 text-right pr-4">
                <span>İşlemler</span>
              </div>
            </div>
          </div>{" "}
        </>
      ) : (
        <> </>
      )}

      {adsData &&
        adsData.length > 0 &&
        adsData.some((e) => e.is_draft === 1) && (
          <div className="flex justify-end">
            <label className="text-red-600 mr-2 flex justify-center items-center gap-1">
              <BiError size={20} className="flash" />
              Taslakta ilanınız var
            </label>
          </div>
        )}

      <Tabs
        className="block lg:hidden"
        activeKey={activeKey}
        onChange={onChange}
        type="card"
        items={tabItems.map((tabItem) => ({
          ...tabItem,
          label: (
            <div
              className={`${
                activeKey === tabItem.key
                  ? "bg-[#DE5B2B] text-white border border-[#DE5B2B]"
                  : "bg-[#F3F5F6] text-gray-600"
              } w-full text-center py-2 rounded-t-lg border border-gray-300 h-10 flex justify-center items-center`}
            >
              {tabItem.label}
            </div>
          ),
        }))}
      />

      <div className="hidden lg:flex flex-col gap-2">
        {adsData && adsData.length > 0 ? (
          <>
            {adsData.map((ad: Ad) => (
              <div
                key={ad.id}
                className="bg-white shadow-sm p-3 lg:p-4 rounded-lg text-gray-600 poppins-medium"
              >
                <div className="flex items-center flex-wrap lg:flex-nowrap justify-between gap-5">
                  <div className="flex items-center lg:w-4/12 lg:pl-4 gap-x-2">
                    <input
                      className="hidden lg:block"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCheckedDatas((prev) => [...prev, ad]);
                        } else {
                          setCheckedDatas((prev) =>
                            prev.filter((item) => item.id !== ad.id)
                          );
                        }
                      }}
                      checked={checkeddatas.some((item) => item.id === ad.id)}
                      type="checkbox"
                    />

                    <div className="flex items-center lg:ml-4 gap-x-4">
                      {ad.is_active === 1 &&
                      ad.is_draft === 0 &&
                      ad.status !== "PASSIVE" ? (
                        <div
                          className={`${
                            new Date(ad.updated_at) <=
                            new Date(
                              new Date().setDate(new Date().getDate() - 2)
                            )
                              ? "opacity-100"
                              : "opacity-0"
                          } `}
                          onClick={() => {
                            handleUpdateAd(ad.id);
                          }}
                        >
                          <a
                            className="cursor-pointer"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="İlan Tarihini Güncelle"
                            data-tooltip-place="top"
                          >
                            <CalendarPlusIcon size={28} color="#6B7280" />
                          </a>
                          <Tooltip id="my-tooltip" />
                        </div>
                      ) : (
                        <div
                          className={`${
                            new Date(ad.updated_at) <=
                            new Date(
                              new Date().setDate(new Date().getDate() - 2)
                            )
                              ? ""
                              : ""
                          } opacity-0 `}
                          onClick={() => {
                            handleUpdateAd(ad.id);
                          }}
                        >
                          <a
                            className="cursor-pointer"
                            data-tooltip-id="my-tooltip"
                            data-tooltip-content="İlan Tarihini Güncelle"
                            data-tooltip-place="top"
                          >
                            <CalendarPlusIcon size={28} color="#6B7280" />
                          </a>
                          <Tooltip id="my-tooltip" />
                        </div>
                      )}

                      <div className="flex flex-col gap-y-[6px] lg:gap-y-2">
                        <span
                          onClick={() => {
                            const url = getUrlByName(
                              ad.id,
                              "İlanı Görüntüle",
                              ad.slug
                            );

                            navigate(url);
                          }}
                          className="lg:poppins-semibold poppins-medium text-sm lg:text-base cursor-pointer"
                        >
                          {truncate(ad.title, 12)}
                        </span>
                        <div className="flex items-center gap-x-1">
                          <IconMapPin className=" w-3 h3  lg:w-5 lg:h-5" />
                          <span className="poppins-medium  text-[11px] lg:text-[13px]">
                            {ad.cities[0]?.name || ""}
                          </span>
                        </div>

                        <div className="text-start flex lg:hidden flex-col gap-y-1">
                          <span className="lg:poppins-semibold poppins-medium text-[11px] lg:text-base">
                            {moment(ad.updated_at)
                              .locale("tr")
                              .format("DD MMMM YYYY")
                              .toString()}
                          </span>
                        </div>

                        <span
                          className={`
    ${
      ad.status === "ARCHIVED"
        ? "bg-gray-700"
        : ad.is_draft === 1
        ? "bg-[#453CE6]"
        : ad.status === "ACTIVE"
        ? "bg-[#579E3D]"
        : ad.status === "PENDING" || ad.status === "PENDING_UPDATE"
        ? "bg-company-card"
        : ad.status === "PASSIVE"
        ? "bg-gray-500"
        : ad.status === "UPDATE"
        ? "bg-orange-500"
        : ad.status === "REJECTED"
        ? "bg-red-500"
        : ""
    }
    text-white py-[6px] rounded-full text-[10px] text-center hidden lg:flex justify-center items-center whitespace-nowrap min-w-[90px] max-w-[90px]`}
                        >
                          {ad.status === "ARCHIVED"
                            ? "Arşivlendi"
                            : ad.is_draft === 1
                            ? "Taslak"
                            : ad.status === "ACTIVE"
                            ? "Aktif"
                            : ad.status === "PENDING" ||
                              ad.status === "PENDING_UPDATE"
                            ? "Onay Bekliyor"
                            : ad.status === "REJECTED"
                            ? "Reddedildi"
                            : ad.status === "PASSIVE"
                            ? "Pasif"
                            : ad.status === "UPDATE"
                            ? "Güncelleme Onayı Bekliyor"
                            : ""}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="flex w-2/12 text-center flex-col gap-y-1 underline">
                    <span
                      className="cursor-pointer font-poppins text-[11px] lg:text-sm"
                      onClick={() => navigate(`/isveren/basvurular/${ad.id}`)}
                    >
                      {ad.advert_applies_count} Toplam
                    </span>
                    <span
                      className="cursor-pointer font-poppins text-[11px] lg:text-sm font-semibold"
                      onClick={() => navigate(`/isveren/basvurular/${ad.id}`)}
                    >
                      {ad.today_advert_applies_count} Yeni
                    </span>
                  </div>

                  <span
                    className={`
                ${ad.status === "ACTIVE" && "bg-green-500"} 
                ${ad.status === "PENDING" && "bg-orange-500"} 
                   ${ad.status === "PENDING_UPDATE" && "bg-orange-500"} 
                ${ad.status === "ARCHIVED" && "bg-gray-700"} 
                  ${ad.status === "DRAFT" && "bg-[#453CE6]"} 
                  ${ad.status === "PASSIVE" && "bg-gray-500"} 
                  ${ad.status === "UPDATE" && "bg-orange-500"} 
                  ${ad.status === "REJECTED" && "bg-red-500"}
                      text-white rounded-full text-[10px] text-center p-2 flex lg:hidden justify-center items-center`}
                  >
                    {ad.status === "ACTIVE" && "Aktif"}
                    {ad.status === "PENDING" && "Onay Bekliyor"}
                    {ad.status === "PENDING_UPDATE" && "Onay Bekliyor"}
                    {ad.status === "ARCHIVED" && "Arşivlendi"}
                    {ad.status === "DRAFT" && "Taslak"}
                    {ad.status === "PASSIVE" && "Pasif"}
                    {ad.status === "UPDATE" && "Güncelleme Onayı Bekliyor"}
                    {ad.status === "REJECTED" && "Reddedildi"}
                  </span>

                  <div className="md:w-3/12 text-center hidden lg:flex flex-col gap-y-1 text-sm ">
                    <span>
                      {moment(ad.created_at)
                        .locale("tr")
                        .format("DD MMMM YYYY")
                        .toString()}
                    </span>
                    <span>
                      {moment(ad.updated_at)
                        .locale("tr")
                        .format("DD MMMM YYYY")
                        .toString()}
                    </span>
                  </div>

                  <div className="w-full   lg:w-4/12 text-right text-sm flex flex-row gap-y-1 gap-x-2 justify-end">
                    <div className="flex gap-x-2 items-center">
                      {ad.is_active === 1 &&
                      ad.is_draft === 0 &&
                      ad.status !== "PASSIVE" ? (
                        <>
                          {ad.is_use_doping === 0 &&
                            ad.is_draft === 0 &&
                            ad.doping_end_date === null &&
                            ad.status === "ACTIVE" && (
                              <CustomButton
                                onClick={() => handleAlert(ad.id)}
                                text="Dopingle"
                                className="text-white poppins-medium bg-company-card py-2 px-3 rounded-md hover:bg-company-card/80 w-full"
                              />
                            )}
                          <CustomButton
                            onClick={() =>
                              navigate(`/isveren/basvurular/${ad.id}`)
                            }
                            text="Başvurular"
                            className="text-white poppins-medium bg-mains py-2 px-3 rounded-md hover:bg-mains/80 max-w-[115px]"
                          />
                        </>
                      ) : (
                        ""
                      )}

                      {ad.is_active === 1 && ad.is_draft === 0 ? (
                        <>
                          <Select
                            required={true}
                            selectedValue={selectedValue}
                            placeholder="İşlemler"
                            className="border-2 border-[#1565c0] rounded-md max-w-[100px] bg-[#F8F8F8]"
                          >
                            <Select.Input
                              className="w-[100px] flex justify-center items-center font-poppins text-[13px] !min-h-[20px] text-gray-500 border-none !pr-3"
                              size={30}
                              placeholder="İşlemler"
                            />

                            <Select.Items>
                              {Active_Transactions.map((item, i) => {
                                if (
                                  item.name === "İlan Tarihini Güncelle" &&
                                  new Date(ad.updated_at) >
                                    new Date(
                                      new Date().setDate(
                                        new Date().getDate() - 2
                                      )
                                    )
                                ) {
                                  return null;
                                }

                                if (
                                  item.name === "İlanı Pasif Yap" &&
                                  ad.status === "PASSIVE"
                                ) {
                                  return null;
                                }

                                if (
                                  item.name === "İlan Tarihini Güncelle" &&
                                  ad.status === "PASSIVE"
                                ) {
                                  return null;
                                }

                                return (
                                  <Button
                                    onClick={() => {
                                      if (
                                        item.name == "İlan Tarihini Güncelle"
                                      ) {
                                        handleUpdateAd(ad.id);
                                      }

                                      const url = getUrlByName(
                                        ad.id,
                                        item.name,
                                        ad.slug,
                                        true
                                      );

                                      if (item.name === "Arşivle") {
                                        handleArchiveAd(ad.id);
                                      }

                                      if (item.name == "İlanı Pasif Yap") {
                                        handleAddPassive(ad.id);
                                      }

                                      navigate(url);
                                    }}
                                    size="xs"
                                    className="text-xs bg-transparent hover:text-mains !border !border-b !border-b-gray-300 !rounded-none  hover:underline hover:bg-gray-100 !px-[10px] !py-[8px]"
                                    type="button"
                                    variant="secondary"
                                  >
                                    <Button.Icon className="w-4 h-4">
                                      {item.icon}
                                    </Button.Icon>
                                    {item.name}
                                  </Button>
                                );
                              })}
                            </Select.Items>
                          </Select>
                        </>
                      ) : (
                        <div className="w-full">
                          {
                            <Select
                              required={true}
                              selectedValue={selectedValue}
                              placeholder="İşlemler"
                              className="border-2 border-[#1565c0] rounded-md max-w-[100px] bg-[#F8F8F8]"
                            >
                              <Select.Input
                                className="w-[100px] flex justify-center items-center font-poppins text-[13px] !min-h-[20px] text-gray-500 border-none !pr-3"
                                size={30}
                                placeholder="İşlemler"
                              />
                              <Select.Items>
                                {statusD
                                  .filter((e) => e.name === ad.status)
                                  .map((filteredItem, index) =>
                                    filteredItem?.array?.map((item, i) => {
                                      // Exclude "Onay Sürecini İptal Et" button for non-PENDING ads
                                      if (
                                        item.name ===
                                          "Onay Sürecini İptal Et" &&
                                        ad.status === "PENDING" &&
                                        ad.is_draft === 1
                                      ) {
                                        return null;
                                      }

                                      // Exclude "Onaya Gönder" button for PENDING or PASSIVE ads
                                      if (
                                        item.name === "Onaya Gönder" &&
                                        (ad.status === "PENDING" ||
                                          ad.status === "PASSIVE") &&
                                        ad.is_draft === 0
                                      ) {
                                        return null;
                                      }
                                      if (
                                        item.name === "İlanı Sil" &&
                                        (ad.status === "PENDING" ||
                                          ad.status === "PASSIVE") &&
                                        ad.is_draft === 0
                                      ) {
                                        return null;
                                      }

                                      if (
                                        item.name === "İlanı Kopyala" &&
                                        ad.status === "PENDING" &&
                                        ad.is_draft === 0
                                      ) {
                                        return null;
                                      }

                                      if (
                                        item.name === "Arşivle" &&
                                        ad.status === "PENDING" &&
                                        ad.is_draft === 0
                                      ) {
                                        return null;
                                      }

                                      if (
                                        item.name === "Arşivle" &&
                                        ad.is_draft === 1
                                      ) {
                                        return null;
                                      }

                                      return (
                                        <Button
                                          key={i}
                                          onClick={() => {
                                            const url = getUrlByName(
                                              ad.id,
                                              item.name,
                                              ad.slug
                                            );
                                            navigate(url);

                                            if (item.name === "Arşivle") {
                                              handleArchiveAd(ad.id);
                                            }

                                            switch (item.name) {
                                              case "İlanı Sil":
                                                handleDelete(ad.id);

                                                break;

                                              case "Arşivden Çıkar":
                                                handleChangeArchiveStatus(
                                                  ad.id
                                                );
                                                break;

                                              case "Onay Sürecini İptal Et":
                                                handleDeleteChangeArchiveStatus(
                                                  ad.id
                                                );
                                                break;

                                              case "Onaya Gönder":
                                                handleChangePendingStatus(
                                                  ad.id
                                                );
                                                break;

                                              default:
                                                break;
                                            }
                                          }}
                                          size="xs"
                                          className="text-xs bg-transparent hover:text-mains !border !border-b !border-b-gray-300 !rounded-none hover:underline hover:bg-gray-100 !px-[10px] !py-[8px]"
                                          type="button"
                                          variant="secondary"
                                        >
                                          <Button.Icon className="w-4 h-4">
                                            {item.icon}
                                          </Button.Icon>
                                          {item.name}
                                        </Button>
                                      );
                                    })
                                  )}
                              </Select.Items>
                            </Select>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className="flex items-center mt-4 mb-12 ml-4 mr-4 shadow-lg border rounded-xl">
            <span className="bg-[red] border border-blue mr-4 h-full text-white pt-3 pb-3 pl-1 pr-1 rounded-md">
              <FiAlertCircle size={35} />
            </span>
            <p className="font-poppins poppins-semibold text-base flex justify-center items-center gap-[6px]">
              <span>Kayıtlı İlan Bulunamadı</span>
              <a
                href="/isveren/ilanlar/olustur/ilan-bilgileri"
                className="underline text-blue-400 underline-offset-[5px]"
              >
                Yeni İlan Oluşturmak İçin Tıklayın
              </a>
            </p>
          </div>
        )}
      </div>
      {/*
      {activeKey === "1" && currentAds && currentAds.length > 0 && (
        <div className="flex justify-center items-center w-full mt-4">
          <Pagination
            currentPage={currentPage}
            firstPageNumber={1}
            lastPageNumber={totalPages}
          >
            <Pagination.ArrowLeftButton
              onClick={() => setCurrentPage((p) => Math.max(p - 1, 1))}
            />
            <Pagination.Items>
              {pages.map((p) => (
                <Pagination.PageButton
                  key={`p-${p}`}
                  pageNumber={p}
                  onClick={() => setCurrentPage(p)}
                >
                  {p}
                </Pagination.PageButton>
              ))}
            </Pagination.Items>
            <Pagination.ArrowRightButton
              onClick={() => setCurrentPage((p) => Math.min(p + 1, totalPages))}
            />
          </Pagination>
        </div>
      )} */}

      <div className="hidden lg:flex  flex-row justify-between mt-4 gap-2 md:gap-0">
        {checkeddatas.length > 0 && (
          <div
            className="flex flex-row md:h-10 gap-x-2 items-center bg-gray-400 py-2 px-2 md:px-8 rounded-lg cursor-pointer
            hover:bg-gray-400/80"
          >
            {selectedStatus === "ARCHIVED" ? (
              <>
                {" "}
                <CustomButton
                  onClick={() => {
                    if (checkeddatas.length > 0) {
                      Swal.fire({
                        title: "Onay",
                        text: "Seçili ilanları arşivden çıkarmak istediğinize emin misiniz?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#2253af",
                        cancelButtonColor: "#888",
                        cancelButtonText: "Hayır,vazgeç",
                        confirmButtonText: "Evet",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          const advert_ids = checkeddatas.map(
                            (item) => item.id
                          );

                          axios
                            .authRequest({
                              type: "post",
                              endpoint: `companies/home/change-archive-status`,
                              payload: {
                                advert_ids: advert_ids,
                                status: "out",
                              },
                            })
                            .then((response: any) => {
                              Swal.fire(
                                "Başarılı!",
                                "Seçili ilanlar başarıyla arşivden çıkarıldı.",
                                "success"
                              );

                              const updatedAds = adsData.filter(
                                (ad) => !advert_ids.includes(ad.id)
                              );

                              setAdsData(updatedAds);
                            })
                            .catch((error: any) => {
                              console.error("Error archiving ads:", error);
                              Swal.fire(
                                "Hata!",
                                "Bir hata oluştu, lütfen tekrar deneyin.",
                                "error"
                              );
                            });
                        }
                      });
                    }
                  }}
                  text={"Seçilileri Arşivden Çıkar"}
                  className="text-white poppins-semibold"
                />
              </>
            ) : (
              <>
                {" "}
                <CustomButton
                  onClick={() => {
                    if (checkeddatas.length > 0) {
                      Swal.fire({
                        title: "Onay",
                        text: "Seçili ilanları arşive göndermek istediğinize emin misiniz?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#2253af",
                        cancelButtonColor: "#888",
                        cancelButtonText: "Hayır, Vazgeç",
                        confirmButtonText: "Evet",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          const advert_ids = checkeddatas.map(
                            (item) => item.id
                          );

                          axios
                            .authRequest({
                              type: "post",
                              endpoint: `companies/home/change-archive-status`,
                              payload: {
                                advert_ids: advert_ids,
                                status: "in",
                              },
                            })
                            .then((response: any) => {
                              Swal.fire(
                                "Başarılı!",
                                "Seçili ilanlar başarıyla arşivlendi.",
                                "success"
                              );

                              const updatedAds = adsData.filter(
                                (ad) => !advert_ids.includes(ad.id)
                              );

                              setAdsData(updatedAds);
                            })
                            .catch((error: any) => {
                              console.error(
                                "Error archiving ads:",
                                error?.response?.data?.error
                              );
                              Swal.fire(
                                "Hata!",
                                error?.response?.data?.error,
                                "error"
                              );
                            });
                        }
                      });
                    }
                  }}
                  text={"Seçilileri Arşive Gönder"}
                  className="text-white poppins-semibold"
                />
              </>
            )}

            <IconChevronRight
              size={20}
              className="text-white poppins-semibold"
            />
          </div>
        )}

        <div className="flex flex-row md:h-10 gap-x-2 items-center bg-mains py-2 px-2 md:px-8 rounded-lg cursor-pointer hover:bg-mains/80">
          <CustomButton
            text="Tüm İlan Tarihlerini Güncelle"
            className="text-white poppins-semibold"
            onClick={() => handleUpdateAllAds()}
          />
          <IconChevronRight size={20} className="text-white poppins-semibold" />
        </div>
      </div>
    </>
  );
};

export { AdsSection };
