import React, { useEffect, useState } from "react";
import Axios from "../../../services/axios";
import Card from "../setting-page/content/Card";
import Swal from "sweetalert2";
import { CgTrash } from "react-icons/cg";
import { PiPlus } from "react-icons/pi";
import { FiAlertCircle } from "react-icons/fi";

type Props = {};
export type References = {
  id: number;
  company_name: string;
  is_use: number;
  created_at: string;
};
const axios = new Axios();
const ReferencesPage = (props: Props) => {
  const [references, setReferences] = useState<References[]>([]);
  const [filteredReferences, setFilteredReferences] = useState<References[]>(
    []
  );
  const [selectedReferences, setSelectedReferences] = useState<References[]>(
    []
  );
  const [modalOpen, setModalOpen] = useState(false);
  const getReferences = async () => {
    try {
      const response = await axios.authRequest({
        endpoint: "companies/reference/get-references",
        type: "get",
        payload: {},
      });
      setReferences(response.data.references);
      setFilteredReferences(response.data.references);
    } catch (error) {
      console.log(error);
    }
  };
  // delete references
  const deleteReferences = async (ids: string[]) => {
    try {
      const result = await Swal.fire({
        title: "Emin misiniz?",
        text: "Seçilen referanslar silinecek!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, Sil!",
        cancelButtonText: "İptal",
      });

      if (result.isConfirmed) {
        const response = await axios.authRequest({
          endpoint: "companies/reference/delete-by-selected",
          type: "delete",
          payload: { data: { ids } },
        });

        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Başarılı",
            text: "Referanslar başarıyla silindi.",
          });
          getReferences();
          setSelectedReferences([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getReferences();
  }, []);
  // if modal closed refresh references

  useEffect(() => {
    if (!modalOpen) {
      getReferences();
    }
  }, [modalOpen]);

  console.log(selectedReferences);

  return (
    <div className="container m-auto flex flex-col gap-3  mt-2">
      <Card>
        <div className="flex justify-between">
          <h1 className="text-2xl font-bold text-title">
            Referans Davetiyelerim
          </h1>
          <a
            href="/isveren/referanslar/olustur"
            className="bg-title rounded-md  md:flex hidden p-2 text-white  justify-center items-center gap-1 text-sm mx-2"
          >
            Yeni Davet Gönder
            <PiPlus />
          </a>
        </div>
      </Card>

      {references.length > 0 && (
        <div className="flex-nowrap justify-around my-5 bg-white py-3 rounded-md border-t-2  md:flex hidden">
          <div className="md:px-10 px-6 flex justify-center items-center">
            <input
              type="checkbox"
              className="accent-title my-auto"
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedReferences(references);
                } else {
                  setSelectedReferences([]);
                }
              }}
            />
          </div>
          <div className="w-full">
            <h2 className="text-xl text-title font-bold">Gönderilen Firma</h2>
          </div>
          <div className="w-full">
            <h2 className="text-xl text-title font-bold text-center">
              Gönderim Tarihi
            </h2>
          </div>
          <div className="w-full">
            <h2 className="text-xl text-title font-bold text-center">
              Kullanım Durumu
            </h2>
          </div>
        </div>
      )}
      <Card className="!p-0">
        <div className="flex flex-wrap gap-3">
          {filteredReferences.length > 0 ? (
            filteredReferences.map((item) => (
              <div
                key={item.id}
                className="bg-white py-2 rounded-md w-full border-b md:ps-0 ps-9  relative"
              >
                <div className="flex justify-around md:flex-nowrap flex-wrap my-5">
                  <div className="md:px-10 md:static absolute top-1/2 -translate-y-1/2 md:translate-y-0 start-4 px-0 flex justify-center items-center w-auto">
                    <input
                      type="checkbox"
                      className="accent-title my-auto"
                      checked={selectedReferences.some(
                        (selectedItem) => selectedItem.id === item.id
                      )}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedReferences([...selectedReferences, item]);
                        } else {
                          setSelectedReferences(
                            selectedReferences.filter(
                              (selectedItem) => selectedItem.id !== item.id
                            )
                          );
                        }
                      }}
                    />
                  </div>
                  <div className="w-full md:ps-0 ps-4">
                    <h3 className="md:font-normal font-semibold">
                      {item.company_name}
                    </h3>
                  </div>
                  <div className="md:w-full w-1/2">
                    <h3 className=" text-center">{item.created_at}</h3>
                  </div>
                  <div className="md:w-full w-1/2">
                    <h3 className=" text-center">
                      {item.is_use ? "Kullanımda" : "Kullanılmadı"}
                    </h3>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="flex items-stretch mt-8 mb-12 ml-4 mr-4 shadow-md rounded-md w-full">
              <span className="flex items-center justify-center bg-red-500 text-white p-3 rounded-l-md">
                <FiAlertCircle size={35} />
              </span>
              <p className="flex items-center font-poppins text-sm poppins-medium p-4">
                Kayıtlı davet bulunamadı
              </p>
            </div>
          )}
        </div>
      </Card>
      <div className="flex p-2 justify-center">
        <a
          href="/isveren/referanslar/olustur"
          className="bg-transparent rounded-full md:hidden flex px-3 py-2 text-title border-title border  justify-center items-center gap-1 text-sm mx-2"
        >
          Yeni Davet Gönder
          <PiPlus />
        </a>
      </div>
      <div className="py-2 flex justify-end w-full">
        {selectedReferences.length > 0 && (
          <button
            className="px-4 py-3 flex gap-3 items-center justify-center text-red-600 md:w-auto w-full"
            onClick={() => {
              deleteReferences(
                selectedReferences.map((item) => item.id.toString())
              );
            }}
          >
            <CgTrash className="text-xl" />
            <span>Seçilenleri Sil</span>
          </button>
        )}
      </div>
    </div>
  );
};

export default ReferencesPage;
