import React, { useState, useEffect } from "react";
import "leaflet/dist/leaflet.css";
import { FaTimes } from "react-icons/fa";
import { useCookie } from "../../../../hooks/useCookie";
import { CookieName } from "../../../../constants/enums";
import Axios from "../../../../services/axios";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

const axios = new Axios();
interface WorkingLocationPopupProps {
  onClose: () => void;
  onSuccess?: () => void;
}

const AddNotePopup: React.FC<WorkingLocationPopupProps> = ({ onClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { slug } = useParams();

  useEffect(() => {
    const timeout = setTimeout(() => {
      document.body.classList.add("overflow-hidden", "h-auto");
      setIsVisible(true);
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  const handleCloseClick = () => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
      document.body.classList.remove("overflow-hidden", "h-auto");
    }, 500);
  };

  const handleSubmit = () => {
    if (isLoading) {
      return;
    }
    if (text.trim().length === 0) {
      Swal.fire({
        title: "Hata!",
        text: "Yorum alanı zorunludur",
        icon: "error",
      });
      return;
    }
    setIsLoading(true);
    axios
      .authRequest({
        type: "post",
        endpoint: `companies/cv/${slug}/comment/add-comment`,
        payload: { note: text },
      })
      .then((response: any) => {
        Swal.fire({
          icon: "success",
          title: "Yorum Kaydedildi",
          confirmButtonText: "Tamam",
        });
        handleCloseClick();
      })
      .catch((err: any) => {
        Swal.fire({
          title: "Hata!",
          text: "Yorum alanı zorunludur",
          icon: "error",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="fixed inset-0 z-[1050] flex items-start justify-center overflow-y-auto ">
      <div
        className="fixed inset-0 bg-black opacity-50 h-auto "
        onClick={handleCloseClick}
      ></div>

      <div
        className={`bg-white my-10 max-md:my-0 max-md:fixed max-md:top-0 max-md:left-0 max-md:w-screen max-md:h-screen max-md:overflow-y-auto md:rounded-lg md:shadow-lg  md:relative md:min-w-[40%] transition-all duration-500 ease-in-out transform ${
          isVisible ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-10"
        }`}
      >
        <div className={"flex justify-between border-b-2 py-5 px-5"}>
          <div className={"text-mains font-bold text-lg"}>Yorum Ekle</div>
          <div
            onClick={handleCloseClick}
            className={
              "flex justify-self-end justify-center items-center w-5 h-5 "
            }
          >
            <FaTimes className={"text-gray-400"} />
          </div>
        </div>

        <div className="flex flex-col items-start justify-center  p-5 w-full">
          <div className="w-full mt-2">
            Yorum : <span className={"align-super text-red-500"}>*</span>
          </div>
          <div className="w-full mt-2">
            <textarea
              className={"w-full border min-h-36 p-3"}
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
          </div>
        </div>

        <div className={"flex justify-end gap-5 border-t-2 py-5 px-5"}>
          <div
            onClick={handleCloseClick}
            className={
              "p-3 bg-gray-200 hover:bg-[#ff7f1d] hover:text-white transition-all rounded cursor-pointer font-bold"
            }
          >
            Kapat
          </div>
          <div
            onClick={() => {
              handleSubmit();
            }}
            className={
              "p-3 bg-mains text-white justify-self-center rounded cursor-pointer font-bold"
            }
          >
            Kaydet
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNotePopup;
