import { Dispatch, HTMLAttributes, SetStateAction, createContext, useState } from "react"
import Trigger from "./Trigger"
import Content from "./Content"






type formModalPropsType = HTMLAttributes<HTMLDivElement> & {
    isOpen?: boolean,
    setIsOpen?: Dispatch<SetStateAction<boolean>>,
    onClosing?: () => void
}


export type formModalContextType = {
    isOpen: boolean,
    setIsOpen: Dispatch<SetStateAction<boolean>>,
    onClosing: () => void
}


export const FormModalContext = createContext<formModalContextType>({
    isOpen: false,
    setIsOpen: () => {},
    onClosing: () => {}
})



export default function FormModal({
    isOpen: initialIsOpen,
    setIsOpen: initialSetIsOpen,
    onClosing,
    className,
    ...props
}: formModalPropsType) {

    const [isOpen, setIsOpen] = useState<boolean>(initialIsOpen?? false)

    const value: formModalContextType = {
        isOpen: initialIsOpen != undefined? initialIsOpen: isOpen,
        setIsOpen: initialSetIsOpen != undefined? initialSetIsOpen: setIsOpen,
        onClosing: onClosing? onClosing: () => {}
    }

    return (
        <FormModalContext.Provider value={value}>
            <div
            className={`${className} `}
            {...props}
            >

            </div>
        </FormModalContext.Provider>
    )
}



FormModal.Trigger = Trigger
FormModal.Content = Content