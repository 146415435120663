import React, { useEffect, useRef } from "react";
import { SVG } from "@svgdotjs/svg.js";

interface CircularProgressProps {
  percentage: number;
  trackColor?: string;
  width?: number;
  height?: number;
  strokeWidth?: number;
}

export const CircularProgress: React.FC<CircularProgressProps> = ({
  percentage,
  trackColor = "#eee",
  width = 220,
  height = 220,
  strokeWidth = 14,
}) => {
  const divRef = useRef<HTMLDivElement>(null);

  const randomId = Math.random().toString(36).substring(7);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.innerHTML = "";

      const draw = SVG().addTo(divRef.current).size(width, height);

      // Define gradient
      const gradient = draw
        .gradient("linear", (add) => {
          add.stop(0, "#ff0000"); // Red at 0%
          add.stop(1, "#49ca77"); // Green at 100%
        })
        .from(0, 0)
        .to(1, 0);
      gradient.id("progress-gradient" + randomId);

      const radius = (width - strokeWidth * 2) / 2;
      const center = width / 2;

      draw
        .circle(radius * 2)
        .fill("none")
        .stroke({ color: trackColor, width: strokeWidth })
        .center(center, center);

      // Draw the progress path
      const totalLength = 2 * Math.PI * radius;
      const progressPath = draw
        .path(
          `M ${center},${
            center - radius
          } A ${radius},${radius} 0 1,1 ${center},${
            center + radius
          } A ${radius},${radius} 0 1,1 ${center},${center - radius}`
        )
        .fill("none")
        .stroke({
          color: `url(#${gradient.id()})`,
          width: strokeWidth,
          linecap: "round",
          dasharray: totalLength.toString(),
          dashoffset: totalLength - (totalLength * percentage) / 100,
        });

      // Hollow center circle
      draw
        .circle(radius * 1.5)
        .center(center, center)
        .fill("#eee");

      // Percentage text
      draw
        .text(`%${percentage}`)
        .font({ size: radius * 0.45, family: "Helvetica, Arial, sans-serif" })
        .attr({
          x: center,
          y: center,
          "text-anchor": "middle",
          "dominant-baseline": "middle",
          dy: "0.4em", // Adjust this value to move text down
        })
        .fill("#2253af");
    }
  }, [percentage, trackColor, width, height, strokeWidth]);

  return (
    <div
      ref={divRef}
      className="flex items-center justify-center"
      style={{ width: `${width}px`, height: `${height}px` }}
    ></div>
  );
};
