import {
  Dispatch,
  HTMLAttributes,
  SetStateAction,
  createContext,
  useState,
  useRef,
} from "react";
import classNames from "classnames";
import Trigger from "./Trigger";
import Label from "./Label";
import Error from "./Error";

// Component Type
type radioGroupPropsType = HTMLAttributes<HTMLDivElement> & {
  selectedValue?: string;
  setSelectedValue?: Dispatch<SetStateAction<string>>;
  required?: boolean;
  error?: boolean;
};

// Context Type
export type radioContextType = {
  required?: boolean;
  error?: boolean;
  selectedValue: string;
  setSelectedValue: Dispatch<SetStateAction<string>>;
};

// Context Provider
export const RadioContext = createContext<radioContextType>({
  required: false,
  error: false,
  selectedValue: "",
  setSelectedValue: () => {},
});

export function RadioGroup({
  className,
  selectedValue: initialSelectedValue,
  setSelectedValue: initialSetSelectedValue,
  children,
  required,
  error,
  ...props
}: radioGroupPropsType) {
  const [selectedValue, setSelectedValue] = useState<string>(
    initialSelectedValue || ""
  );

  const cn = classNames({
    "flex flex-col gap-1 relative": true,
  });

  const value: radioContextType = {
    required,
    error,
    selectedValue:
      initialSelectedValue != undefined ? initialSelectedValue : selectedValue,
    setSelectedValue:
      initialSetSelectedValue != undefined
        ? initialSetSelectedValue
        : setSelectedValue,
  };

  return (
    <RadioContext.Provider value={value}>
      <div className={`${cn} ${className}`} {...props}>
        {children}
      </div>
    </RadioContext.Provider>
  );
}

RadioGroup.Trigger = Trigger;
RadioGroup.Label = Label;
RadioGroup.Error = Error;
