import { useEffect, useState } from "react";
import { Button, InputArea } from "../../../components/isbul-ui";
import { useForm, SubmitHandler } from "react-hook-form";
import Axios from "../../../services/axios";
import { isAxiosError } from "axios";
import Swal from "sweetalert2";
import { AiOutlineLoading3Quarters } from "react-icons/ai";






type formDataType = {
    email: string | null,
    phone: string | null
}


const axios = new Axios()




export default function ForgotPasswordCompany() {
    
    const [activeTab, setActiveTab] = useState<'email' | 'phone'>('email')
    const [phone, setPhone] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        getFieldState,
        formState: { errors }
    } = useForm<formDataType>()

    const onSubmit: SubmitHandler<formDataType> = ((data) => {
        sendFormAction(data)
    })

    register('email', {
        required: activeTab == 'email' ? 'E-Posta' : false
    })

    register('phone', {
        required: activeTab == 'phone' ? 'Telefon Numarası' : false
    })

    useEffect(() => {
        window.scrollTo({top: 0})
    }, [])

    useEffect(() => {
        setValue('email', email)
        clearErrors('email')
    }, [email])

    useEffect(() => {
        let phoneRaw = phone.replaceAll(' ', '')
        phoneRaw = phoneRaw.replaceAll('(', '')
        phoneRaw = phoneRaw.replaceAll(')', '')
        setValue('phone', `0${phoneRaw}`)
        clearErrors('phone')
    }, [phone])

    function onError() {
        const errorArray = Object.entries(errors).map(([type, message]) => {
            return message.message
        })

        Swal.fire({
            icon: 'warning',
            title: 'Uyarı',
            html: `
            <div>
            Eksik alanlar: <br/>
            <p class="text-red-600">
                ${errorArray.join('<br/>')}
            </p>
            </div>
            `
        })
    }
    return(
        <div className="container mx-auto my-10 font-poppins min-h-[calc(80vh)] h-full">
            <div className="flex flex-col items-center gap-6 mx-auto w-full max-w-[500px] bg-white rounded-md border border-gray-300 p-6">
                <h1 className="text-2xl">
                    Şifremi Unuttum
                </h1>
                <label className="text-gray-700 text-sm">
                    Şifre sıfırlama bağlantısını gönderebilmemiz için e-posta adresini veya telefon numaranı girmelisin.
                </label>
                <div className="flex items-center bg-gray-200 w-full p-1 rounded-md">
                    <Button
                    type="button"
                    className="w-full"
                    variant={activeTab == 'email' ? 'ghost' : 'secondary'}
                    size="sm"
                    onClick={() => {
                        setActiveTab('email')
                    }}
                    >
                        E-Posta
                    </Button>
                    <Button
                    type="button"
                    className="w-full"
                    variant={activeTab == 'phone' ? 'ghost' : 'secondary'}
                    size="sm"
                    onClick={() => {
                        setActiveTab('phone')
                    }}
                    >
                        Telefon
                    </Button>
                </div>
                <form className="flex flex-col gap-6 w-full" onSubmit={handleSubmit(onSubmit, onError)}>
                    {activeTab == 'email' && (
                        <InputArea
                        className="w-full"
                        mode={email.length == 0 ? 'empty' : 'dirty'}
                        error={errors.email?.message ? true : false}
                        >
                            <InputArea.Input
                            placeholder="E-posta adresi"
                            value={email || ''}
                            onChange={((e) => {
                                setEmail(e.target.value)
                            })}
                            />
                        </InputArea>
                    )}
                    {activeTab == 'phone' && (
                        <InputArea
                        className="w-full"
                        mode={phone.length == 0 ? 'empty' : 'dirty'}
                        error={errors.phone?.message ? true : false}
                        >
                            <InputArea.Input
                            placeholder="(5__) ___ __ __"
                            value={phone || ''}
                            onClick={() => {
                                if (phone == "" || phone == "(" || phone == "(5") {
                                    setPhone("(5");
                                }
                            }}
                            onChange={((e) => {
                                let value = e.target.value;

                                value = value.replace(/\D/g, "");
                                if (!value.startsWith("5")) {
                                value = "5" + value;
                                }

                                // Phone number formatting
                                let formattedValue = "";
                                if (value.length > 0)
                                formattedValue += `(${value.substring(0, 3)}`;
                                if (value.length >= 4)
                                formattedValue += `) ${value.substring(3, 6)}`;
                                if (value.length >= 7)
                                formattedValue += ` ${value.substring(6, 8)}`;
                                if (value.length >= 9)
                                formattedValue += ` ${value.substring(8, 10)}`;

                                setPhone(formattedValue);
                            })}
                            onBlur={() => {
                                if (phone == "(5") {
                                    setPhone("");
                                }
                            }}
                            onKeyDown={(e) => {
                                if (
                                  phone == "(5" &&
                                  e.key == "ArrowLeft"
                                ) {
                                  e.preventDefault();
                                }
                            }}
                            />
                        </InputArea>
                    )}
                    <Button
                    type="submit"
                    >
                        {isLoading ? (
                            <Button.Icon>
                                <AiOutlineLoading3Quarters className="animate-spin text-xl"/>
                            </Button.Icon>
                        ): 'Gönder'}
                    </Button>
                </form>
            </div>
        </div>
    )

    async function sendFormAction(data: formDataType) {

        if (isLoading) return

        setIsLoading(true)
        try {
            const response = await axios.authRequest({
                endpoint: `companies/forgot-password/${activeTab == 'email' ? 'send-forgot-email' : 'send-forgot-sms'}`,
                type: 'post',
                payload: data
            })

            console.log('response', response)

            if (response && response.data && response.data.status_code == 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Başarılı',
                    text: activeTab == 'email' ? 'Telefon numaranıza şifre yenileme bağlantısı gönderdik.' : 'E-posta adresinize şifre yenileme bağlantısı gönderdik.'
                })
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Uyarı',
                    text: 'Şifre sıfırlama işlemi gerçekleşirken beklenmedik bir hata meydana geldi.'
                })
            }
        } catch (error) {
            console.log(`Error: ${error}`)
            if (isAxiosError(error) && error.response && error.response.data) {
                if (activeTab == 'email') {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Uyarı',
                        text: error.response.data?.error
                    })
                } else if (activeTab == 'phone') {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Uyarı',
                        text: error.response.data?.errors?.phone?? error.response.data.error
                    })
                } else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Uyarı',
                        text: 'Şifre sıfırlama işlemi gerçekleşirken bir hata meydana geldi.'
                    })
                }
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Uyarı',
                    text: 'Şifre sıfırlama işlemi gerçekleşirken beklenmedik bir hata meydana geldi.'
                })
            }

        }
        setIsLoading(false)
    }
}