import React from "react";
import { FiSearch } from "react-icons/fi";
import { useState, useEffect } from "react";
import Axios from "../../../../../services/axios";
import { useRef } from "react";

interface Option {
  id: number;
  title: string;
  slug: string;
}

const SearchSection: React.FC = () => {
  const axios = new Axios();

  const [term, setTerm] = useState<string>("");
  const [filteredOptions, setFilteredOptions] = useState<Option[]>([]);
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>(null);

  const handleTerm = async (term: string) => {
    setTerm(term);
  };

  async function searchByTerm() {
    try {
      if (term.trim().length >= 0) {
        const response = await axios.request({
          endpoint: `cdn/get-position-dictionaries?search=${term}&limit=100`,
          type: "get",
        });

        setFilteredOptions(response.data.position_dictionaries);
      }
    } catch (error) {
      console.log(error);
      setFilteredOptions([]);
    }
  }

  useEffect(() => {
    const timeOut = setTimeout(() => searchByTerm(), 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [term]);

  useEffect(() => {
    // inputa tıklama durumunu kontrol ediyoruz
    if (isInputFocused) {
      searchByTerm();
    }
  }, [isInputFocused]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setIsInputFocused(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [divRef]);

  return (
    <div className="flex justify-center section-search-section">
      <div className="flex flex-wrap w-full lg:w-3/4">
        {/* Left Column */}
        <div
          className="w-full px-8 mb-4 lg:w-3/5 lg:px-0"
          style={{ paddingTop: "60px" }}
        >
          <h1 className="text-2xl font-bold mb-4 text-title font-poppins">
            Pozisyonlar
          </h1>
          <p className="text-paragraph mb-4 font-poppins">
            1575 farklı pozisyon hakkında iş tanımından maaş bilgisine kadar tüm
            merak ettiklerini burada keşfet!
          </p>
          <div ref={divRef} className="relative w-full mb-4 lg:w-3/4 lg:mb-0 ">
            <input
              type="text"
              placeholder="Aramak için Kelime girin"
              onFocus={() => setIsInputFocused(true)}
              onChange={(e) => handleTerm(e.target.value)}
              className="w-full px-4 py-3 border border-gray-300 shadow-sm focus:outline-none"
            />
            <FiSearch className="absolute text-lg text-gray-400 transform -translate-y-1/2 right-3 top-1/2" />

            {isInputFocused && term.length >= 0 && (
              <div className="w-full border-2 max-h-[200px] overflow-y-auto absolute mt-[1px]">
                {filteredOptions.map((option) => {
                  const startIndex = option.title
                    .toLowerCase()
                    .indexOf(term.toLowerCase());
                  const endIndex = startIndex + term.length;
                  const beforeMatch = option.title.substring(0, startIndex);
                  const match = option.title.substring(startIndex, endIndex);
                  const afterMatch = option.title.substring(endIndex);

                  return (
                    <a
                      key={option.id}
                      className="flex items-center justify-between w-full px-4 py-3 bg-white hover:bg-gray-100 hover:text-title duration-300"
                      href={`/pozisyon/${option.slug}`}
                    >
                      <span>
                        {beforeMatch}
                        <span className="font-bold">{match}</span>
                        {afterMatch}
                      </span>
                    </a>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        {/* Right Column */}
        <div className="flex items-center justify-center hidden w-2/5 lg:flex">
          <img
            src="/assets/images/PositionsHeader.png"
            alt="Description"
            className="h-auto max-w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default SearchSection;
