import { ReactNode } from "react";
import { useContext } from "react";
import classNames from "classnames";
import { TabsContext } from "./index";

type tabPropsType = {
  children: ReactNode;
  tabId: string;
  className?: string;
};

export function Tab({ children, tabId, className }: tabPropsType) {
  const context = useContext(TabsContext);

  if (!context) {
    throw new Error("Tab component must be used within a Tabs provider");
  }

  const { selectedTab, setSelectedTab } = context;

  const cn = classNames({
    "p-2 cursor-pointer": true,
    "border-b-2 border-blue-500": selectedTab === tabId,
    [className as string]: !!className,
  });

  return (
    <div className={cn} onClick={() => setSelectedTab(tabId)}>
      {children}
    </div>
  );
}
