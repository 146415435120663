import { useEffect, useRef, useState } from "react";
import { IoClose, IoReturnUpBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/form-components/button";
import { GrSend } from "react-icons/gr";
import Axios from "../../../../services/axios";


interface SaveAndNextButtonProps {
  advertId: string | undefined;
  companyEmail: string | undefined;
  skip?: boolean,
  skipText?: string
}
const SaveAndNextButton: React.FC<SaveAndNextButtonProps> = ({
  advertId,
  companyEmail,
  skip,
  skipText
}) => {
  const navigate = useNavigate();
  const customeAxios = new Axios();
  const divRef = useRef<HTMLDivElement>(null)

  const handleNavigate = async () => {
    const response = await customeAxios.authRequest({
      type: "post",
      endpoint: `companies/advert/success/${advertId}`,
      payload: advertId,
    });
    console.log(response);
    if (advertId) {
      navigate(`/isveren/ilanlar/ilan/${advertId}/basarili`, {
        state: { companyEmail },
      });
    }
  }

  const handleEditNavigate = () => {

    if (advertId) {
      navigate(`/isveren/ilanlar/ilan/${advertId}/onizleme`, {
        state: { companyEmail },
      });
    }
  };

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        divRef.current &&
        !divRef.current.contains(event.target as Node)
      ) {
        setIsPopupOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => document.removeEventListener("mousedown", handleClickOutside)
  }, [])

  return (
    <div className="md:flex md:justify-between w-full my-3 font-poppins mt-3">
      <button
        onClick={handleEditNavigate}
        className="w-full md:w-auto px-12 h-[50px] flex justify-center
                items-center text-gray-600 bg-[#e5e6ec] rounded-md 
                cursor-pointer text-xl
                transition-colors duration-300 mb-4 md:mb-0"
        id="new-button-id"
      >
        <IoReturnUpBack className="mr-2" />
        Geri Dön
      </button>
      {!skip ? (
        <button
          onClick={handleNavigate}
          className="w-full md:w-auto px-6 h-[50px] flex justify-center
                  items-center text-white bg-[#2253AF] rounded-md 
                  cursor-pointer text-lg hover:bg-[#1e4a8c] 
                  transition-colors duration-300"
          id="save-to-publish"
        >
          Kaydet ve Devam Et &gt;
        </button>
      ) : (
        <button
          onClick={() => setIsPopupOpen(true)}
          className="w-full md:w-auto px-6 h-[50px] flex justify-center
                  items-center text-white bg-[#2253AF] rounded-md 
                  cursor-pointer text-lg hover:bg-[#1e4a8c] 
                  transition-colors duration-300"
        >
          Satın Almadan Devam Et &gt;
        </button>
      )}

      {isPopupOpen ? (
        <div className="fixed flex items-center justify-center top-0 left-0 w-screen h-screen bg-black bg-opacity-30 z-[10]">
          <div ref={divRef} className="flex flex-col gap-2 max-w-[600px] bg-white p-4 m-4 lg:m-0 rounded-md">
            <div className="flex flex-row items-center justify-between">
              <label className="text-mains text-xl">İlanı Dopingle</label>
              <button type="button" onClick={() => setIsPopupOpen(false)}>
                <IoClose className="text-xl text-gray-600" />
              </button>
            </div>
            <hr />
            <p>
              İlanınızın 7 gün boyunca en üstte görünmesini ve 50 kat daha fazla başvuru almasını sağlamak için doping satın almak ister misiniz?
            </p>
            <hr />
            <div className="flex flex-col-reverse lg:flex-row items-center justify-between gap-2">
              <Button variant="secondary"
                onClick={handleNavigate}
                className="w-full lg:w-auto"
              >
                Satın Almadan Devam Et
                <Button.Icon>
                  <GrSend />
                </Button.Icon>
              </Button>
              <Button
                className="w-full lg:w-auto items-center justify-center"
              >
                Satın Al
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default SaveAndNextButton;
