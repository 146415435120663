import React, { useEffect } from "react";
import EduInfo from "./EducationInformation";
import ForeignLanguage from "./ForeignLanguage";
import UzmanlıkBilgiler from "./utils/UzmanlıkBilgiler";
import { Button } from "../../../../../components/isbul-ui";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { useContext } from "react";
import { CvLayoutContext, cvLayoutContextType } from "..";



const EducationInformation: React.FC = () => {

  const { isEnglish }: cvLayoutContextType = useContext(CvLayoutContext)

  const params = useParams()
  const cvId = params.slug

  const navigate = useNavigate()

  // Initial page scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  return (
    <div className="mb-20 flex flex-col gap-y-6 gap-x-2 p-2 lg:p-0 bg-gray-100">

      {/* Educations section */}
      <EduInfo />

      {/* Lanugages section */}
      <ForeignLanguage />

      {/* Master section */}
      <UzmanlıkBilgiler />

      <hr/>

      {/* prev and next buttons */}
      <div className="flex flex-row items-center justify-between p-2 bg-white">
        <Button
        variant="secondary"
        size="sm"
        onClick={() => navigate(`/profilim/cv/duzenle/${cvId}/is-deneyimleri`)}
        >
          <Button.Icon>
            <IoChevronBack/>
          </Button.Icon>
          {isEnglish? 'Go Back': 'Geri Gön'}
        </Button>

        <Button
        size="sm"
        onClick={() => navigate(`/profilim/cv/duzenle/${cvId}/seminer-kurs-egitimler`)}
        >
          {isEnglish? 'Continue': 'Devam Et'}
          <Button.Icon>
            <IoChevronForward/>
          </Button.Icon>
        </Button>
      </div>
    </div>
  );
};

export default EducationInformation;
