import { LuLoader2 } from "react-icons/lu";
import { TiArrowSortedUp } from "react-icons/ti";
import React, { useEffect, useState, useRef } from "react";
import services from "../../../../services/search-services";
import usePartialBold from "../../../../customHooks/usePartialBold";
import { positionStateTypes } from "../contents/searchBar";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { IconChevronDown } from "@tabler/icons-react";

const PositionSearch = ({
  onChange,
}: {
  onChange?: (arg: positionStateTypes) => void;
}) => {
  const [showResult, setShowResult] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [positions, setPositions] = useState<any[]>([]);
  const [companies, setCompanies] = useState<any[]>([]);
  const [isFetchingPosition, setIsFetchingPosition] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleFocus = () => {
    setShowResult(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setShowResult(false);
    }, 100);
  };

  const toggleDropdown = () => {
    setShowResult(!showResult);
  };

  const handleSearchTermChange = (value: string) => {
    onChange &&
      onChange({
        positionId: null,
        companyId: null,
        searchTerm: value,
        isSearching: true,
      });
    setSearchTerm(value);
  };

  const handlePositionSelect = (title: string, id: number) => {
    onChange &&
      onChange({
        positionId: id,
        companyId: null,
        searchTerm: title,
        isSearching: false,
      });
    setSearchTerm(title);
  };

  const handleCompanySelect = (title: string, id: number) => {
    onChange &&
      onChange({
        positionId: null,
        companyId: id,
        searchTerm: title,
        isSearching: false,
      });
    setSearchTerm(title);
  };

  const getBoldText = (fullString: string, boldString: string) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return usePartialBold({ fullString, boldString });
  };

  useEffect(() => {
    const loadData = async () => {
      setIsFetchingPosition(true);
      const positionsAndCompanies =
        await services.searchServicesPositionsOrCompanies(searchTerm, 10);
      setPositions(positionsAndCompanies.data.positions);
      setCompanies(positionsAndCompanies.data.companies);
      setIsFetchingPosition(false);
    };

    const debounce = setTimeout(() => {
      loadData();
    }, 300);
    return () => {
      clearTimeout(debounce);
    };
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowResult(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className={"relative w-full h-full "} ref={dropdownRef}>
      <div className="flex flex-row items-center w-full h-full py-1 max-md:px-2 md:pl-6 bg-white border border-white">
        <input
          onFocus={handleFocus}
          value={searchTerm}
          onChange={(e) => {
            handleSearchTermChange(e.target.value);
          }}
          placeholder="Pozisyon veya şirket ara.."
          type="text"
          className="w-full h-full text-sm text-black placeholder-gray-400 outline-none poppins-regular text-ellipsis"
        />
        <div className="flex justify-end w-[20%]">
          {/* {isFetchingPosition && (
            <LuLoader2 className={"animate-spin mr-3"}></LuLoader2>
          )} */}
          <IconChevronDown
            className={`${showResult ? "rotate-180" : ""} transition-all`}
            size={18}
            onClick={toggleDropdown}
          />
        </div>
      </div>
      {showResult && (
        <div className="absolute bg-white border p-4 w-full  lg:w-[593px] xl:w-[695px] 2xl:w-[755px] rounded-xl mt-5 shadow-lg left-0 z-50">
          <div className="relative md:flex md:max-h-80  space-x-4">
            <div className="absolute -top-[45px] left-0">
              <TiArrowSortedUp className="text-5xl text-white" />
            </div>

            <div className="w-full md:w-1/2 overflow-y-auto">
              <h4 className="mt-6 font-semibold text-gray-500">POZİSYON</h4>
              <ul>
                {positions.map((position) => {
                  return (
                    <li
                      key={position.id}
                      onClick={() => {
                        handlePositionSelect(position.title, position.id);
                      }}
                      className="py-1 pt-3 text-gray-500 underline hover:bg-gray-300 cursor-pointer"
                    >
                      <button className={"text-left"}>
                        <div
                          className="text-sm font-medium group-hover/position:text-blue-500 line-clamp-1 text-ellipsis"
                          dangerouslySetInnerHTML={{
                            __html: getBoldText(position.title, searchTerm),
                          }}
                        />
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="w-full md:w-1/2 overflow-y-auto">
              <h4 className="mt-6 font-semibold text-gray-500">FİRMA</h4>
              <ul>
                {companies.map((company) => {
                  return (
                    <li
                      onClick={() =>
                        handleCompanySelect(
                          company.company_name,
                          company.company_id
                        )
                      }
                      key={company.id}
                      className="py-1 pt-3 text-gray-500 underline hover:bg-gray-300 cursor-pointer"
                    >
                      <button
                        onClick={() =>
                          handleCompanySelect(
                            company.company_name,
                            company.company_id
                          )
                        }
                        className={"text-left"}
                      >
                        <span className="flex items-center justify-start underline uppercase text-start">
                          <div
                            className="text-sm font-medium group-hover/position:text-blue-500 line-clamp-1 text-ellipsis"
                            dangerouslySetInnerHTML={{
                              __html: getBoldText(
                                company.company_name,
                                searchTerm
                              ),
                            }}
                          />
                        </span>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PositionSearch;
