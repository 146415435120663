import { ICustomCard } from "../../../../containers/login-page-container/company/login/types/ICustomCard";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { CustomButton } from "../custom-button";

function FreeCard(props: ICustomCard): React.ReactElement {
  const { isMobile } = useIsMobile();

  if (isMobile) {
    return (
      <div className="w-full flex flex-col gap-y-3 relative border-white bg-mains text-white border-2 rounded-md">
        <div className="flex flex-row items-center p-5 w-full justify-between">
          <div className="flex flex-col gap-y-1">
            <h2 className="poppins-bold text-xl text-white">{props.name}</h2>
            <p className="poppins-medium text-gray-200 text-sm">
              {props.description as string}
            </p>
          </div>
        </div>
        <div className="px-5">
          <CustomButton
            text={"Ücretsiz İlan Yayınla"}
            className="py-2 text-white bg-company-card text-lg poppins-semibold w-full h-[40px] rounded-md mb-2 mx-auto mt-2"
            onClick={props.onClick}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="transition-all duration-500 shadow-xl w-full min-h-[640px] flex flex-col gap-y-5 items-center pt-5 relative bg-mains/95  hover:shadow-lg hover:shadow-company-card border-mains hover:border-company-card justify-between text-sm rounded-md">
      <h2 className="poppins-semibold text-2xl text-white">{props.name}</h2>
      <div className="h-[1px] w-full bg-white opacity-50" />
      <div className="p-5">
        <p className="poppins-medium text-white text-center text-xl tracking-wide">
          {props.description as string}
        </p>
      </div>
      <div className="h-[1px] w-full bg-white opacity-50" />
      <CustomButton
        text={"Ücretsiz İlan Yayınla"}
        className="px-4 py-2 text-white bg-company-card text-lg poppins-semibold w-[95%] h-[50px] rounded-md mb-2"
        onClick={props.onClick}
      />
    </div>
  );
}

export { FreeCard };
