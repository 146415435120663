import React from 'react';
import { CgSpinner } from 'react-icons/cg';

interface CustomButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  isSubmitting: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}

export const CustomButton: React.FC<CustomButtonProps> = ({
  onClick,
  children,
  isSubmitting,
  className,
  type,
  disabled,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`bg-blue-700 hover:bg-blue-500 transition-all duration-300 ease-in-out px-7 py-2 
      mt-4  text-md text-white rounded  ${className}`}
      disabled={isSubmitting}>
      {isSubmitting ? <CgSpinner className="spin" /> : children}
    </button>
  );
};
