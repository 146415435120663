import React, { useEffect, useState } from "react";
import services from "../../../../../services/profile-services/index";
import { FcCheckmark } from "react-icons/fc";
import { TbSquareRoundedPlusFilled } from "react-icons/tb";
import { Link } from "react-router-dom";
import Axios from "../../../../../services/axios";
import { useParams } from "react-router-dom";
import { AiOutlineLoading } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import { useContext } from "react";
import { CvLayoutContext, cvLayoutContextType } from "..";
import { CircularProgress } from "../../../../../components/common/profile/left-dashboard/CircularProgress";

type categoryItemType = {
  link: string;
  text: string;
  text_en: string;
  icon: React.ReactNode;
  required: boolean;
};

type completeDataType = {
  title: string;
  category: string;
  items: categoryItemType[];
};

const initialPages: completeDataType = {
  category: "Bölümler",
  title: "Bölümler",
  items: [
    {
      link: "",
      text: "Kişisel Bilgiler",
      text_en: "Personal Informations",
      icon: <AiOutlineLoading className="text-xl animate-spin" />,
      required: true,
    },
    {
      link: "iletisim-bilgileri",
      text: "İletişim Bilgileri",
      text_en: "Contact Information",
      icon: <AiOutlineLoading className="text-xl animate-spin" />,
      required: true,
    },
    {
      link: "is-tercihleri",
      text: "İş Tercihleri",
      text_en: "Job Preferences",
      icon: <AiOutlineLoading className="text-xl animate-spin" />,
      required: false,
    },
    {
      link: "is-deneyimleri",
      text: "İş Deneyimleri",
      text_en: "Work Experiences",
      icon: <AiOutlineLoading className="text-xl animate-spin" />,
      required: false,
    },
    {
      link: "egitim-bilgileri",
      text: "Eğitim Bilgileri & Yabancı Diller",
      text_en: "Education & Language",
      icon: <AiOutlineLoading className="text-xl animate-spin" />,
      required: true,
    },
    {
      link: "referanslar",
      text: "Referanslar",
      text_en: "References",
      icon: <AiOutlineLoading className="text-xl animate-spin" />,
      required: false,
    },
    {
      link: "sinavlar-sertifikalar",
      text: "Sınavlar & Sertifikalar",
      text_en: "Exams & Certificates",
      icon: <AiOutlineLoading className="text-xl animate-spin" />,
      required: false,
    },
    {
      link: "seminer-kurs-egitimler",
      text: "Seminer, Kurs ve Eğitimler",
      text_en: "Seminars and Courses",
      icon: <AiOutlineLoading className="text-xl animate-spin" />,
      required: false,
    },
    {
      link: "basari-odul-ve-burslar",
      text: "Başarı & Ödül ve Burslar",
      text_en: "Achievements & Awards",
      icon: <AiOutlineLoading className="text-xl animate-spin" />,
      required: false,
    },
    {
      link: "hobiler",
      text: "Hobiler",
      text_en: "Hobbies",
      icon: <AiOutlineLoading className="text-xl animate-spin" />,
      required: false,
    },
    {
      link: "dosyalar",
      text: "Dosyalar",
      text_en: "Files",
      icon: <AiOutlineLoading className="text-xl animate-spin" />,
      required: false,
    },
  ],
};

type pageInfoType = {
  id: number;
  name: string;
  link: string;
  description: string | null;
  lang_en: string;
};

const axios = new Axios();

const Category: React.FC = () => {
  const { score, isEnglish }: cvLayoutContextType = useContext(CvLayoutContext);

  const params = useParams();
  const cvId = params.slug;

  const [datas, setDatas] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [completeStatus, setCompleteStatus] = useState<completeDataType[]>([
    initialPages,
  ]);

  useEffect(() => {
    const fetchIncompleteDetail = async () => {
      const credentials = localStorage.getItem("token");
      if (credentials) {
        try {
          const data = "await services.incomplateDetail(credentials);";
          console.log(data);
        } catch (error: any) {
          setError(error.message || "Bir Hata Oluştu checkUser Fetch");
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchIncompleteDetail();
  }, []);

  async function checkPagesStatus() {
    //getCvScore()
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/miss-info/${cvId}`,
        type: "get",
        payload: {},
      });

      if (response && response.data && response.data.data) {
        const pages: pageInfoType[] = response.data.data;

        const completedPages: completeDataType = {
          category: `${
            isEnglish ? "Completed Sections" : "Tamamlanmış Bölümler"
          }`,
          title: `${isEnglish ? "Completed Sections" : "Tamamlanmış Bölümler"}`,
          items: [],
        };

        const inCompletedPages: completeDataType = {
          category: `${
            isEnglish ? "In Completed Sections" : "Tamamlanmamış Bölümler"
          }`,
          title: `${
            isEnglish ? "In Completed Sections" : "Tamamlanmamış Bölümler"
          }`,
          items: [],
        };

        inCompletedPages.items = pages.map((p) => {
          return {
            link: p.link.replaceAll("/", ""),
            text: p.name,
            text_en: p.lang_en,
            icon: (
              <TbSquareRoundedPlusFilled className="text-xl text-orange-400" />
            ),
            required:
              initialPages.items.find(
                (i) =>
                  i.text.toLocaleLowerCase("tr-TR") ==
                  p.name.toLocaleLowerCase("tr-TR")
              )?.required ?? false,
          };
        });

        for (const item of initialPages.items) {
          if (!inCompletedPages.items.find((i) => i.text == item.text)) {
            completedPages.items.push({
              text: item.text,
              text_en: item.text_en,
              link: item.link,
              icon: <FcCheckmark className="text-xl" />,
              required: item.required,
            });
          }
        }

        let allPages = [completedPages];
        if (inCompletedPages.items.length > 0) {
          allPages = [completedPages, inCompletedPages];
        }

        setCompleteStatus(allPages);
      }
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  }

  useEffect(() => {
    checkPagesStatus();
  }, [score]);

  /* useEffect(() => {
    checkPagesStatus()
  }, []) */

  if (isLoading) {
    return <div>Yükleniyor...</div>;
  }

  if (error) {
    return <div>Hata: {error}</div>;
  }

  return (
    <div className="col-span-3 flex gap-x-20 rounded-md">
      <div className="flex flex-col lg:flex-col gap-4 w-full rounded-md">
        {completeStatus?.map((section, index) => {
          return (
            <>
              {section?.items.length > 0 && (
                <div
                  key={index}
                  className="w-full flex flex-col bg-white p-3 rounded-md shadow-md"
                >
                  <span className="text-sm poppins-semibold text-mains">
                    {section?.title}
                  </span>
                  <div className="flex flex-col overflow-x-auto gap-x-2 gap-y-2 py-3">
                    {section?.items?.map((item, idx) => (
                      <Link to={item?.link}>
                        <div
                          key={idx}
                          className={`flex gap-x-2 items-center justify-start py-2 w-full border px-2 rounded-md border-${section?.category}-400`}
                        >
                          <div className="w-5">{item?.icon}</div>
                          <label className="text-xs poppins-semibold whitespace-nowrap text-ellipsis overflow-hidden cursor-pointer">
                            {isEnglish ? item.text_en : item.text}
                          </label>
                          {item.required && (
                            <b className="text-red-600 text-xs">*</b>
                          )}
                        </div>
                      </Link>
                    ))}
                  </div>
                  {index == completeStatus.length - 1 && (
                    <div className="flex flex-col gap-y-3">
                      <div className="flex items-center justify-center w-full">
                        <span className="text-xl text-center poppins-medium text-mains">
                          Özgeçmiş Doluluk Oranı
                        </span>
                      </div>

                      <div className="flex items-center justify-center w-full">
                        <CircularProgress percentage={score} />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          );
        })}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
    </div>
  );
};

export default Category;
