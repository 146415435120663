import React, { useEffect } from "react";
import Axios from "../../../../services/axios";
import { LuLoader2 } from "react-icons/lu";
import { Link } from "react-router-dom";
const axios = new Axios();

const Packages = () => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [packages, setPackages] = React.useState<any[]>([]);

  useEffect(() => {
    axios
      .authRequest({
        type: "get",
        endpoint: `companies/packet/`,
        payload: {},
      })
      .then((response: any) => {
        setPackages(
          response.data.packets.filter((el: any) => {
            return [18, 19, 20, 21].includes(el.id);
          })
        );
      })
      .catch(() => {
        console.log("Paketler Alınamadı");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div
      className={
        "flex flex-col items-center  bg-gray-200 py-5 text-center w-full overflow-hidden"
      }
    >
      {isLoading ? (
        <div>
          <LuLoader2 className={"animate-spin"} />
        </div>
      ) : (
        <div className={"w-full "}>
          <div className={"mb-3"}>
            Adayların iletişim bilgilerini görüntülemek için{" "}
            <span className={"font-bold"}>
              CV görüntüleme hakkı satın almalısınız.
            </span>
          </div>
          <div
            className={
              "flex flex-row  w-full sm:justify-center sm:items-center overflow-x-auto"
            }
          >
            <div className={"max-sm:px-5 flex gap-3"}>
              {packages.map((el: any) => {
                return (
                  <div
                    className={"bg-white rounded-md overflow-hidden min-w-40"}
                  >
                    <div className={"bg-mains p-3 text-white"}>
                      {el.cv} Cv Hakkı
                    </div>
                    <div className={"flex p-3 justify-center items-center"}>
                      <div
                        className={
                          "bg-gray-200 border-dashed border-2 border-gray-300 rounded-md px-3 py-1"
                        }
                      >
                        {el.prices} <span className={"text-xs"}>TL</span>
                      </div>
                      <div className={"text-xs ml-3"}>+KDV</div>
                    </div>
                    <div className={"w-full p-3"}>
                      <Link to={`/isveren/paket/${el.id}/satin-al`}>
                        <div
                          className={
                            "w-full bg-mains rounded-md text-white cursor-pointer"
                          }
                        >
                          Satın Al
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Packages;
