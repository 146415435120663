import React from "react";
import PropTypes from "prop-types";

import { SideBar } from "../../../pages";
import "./MobileMenu.scss";

const MobileMenu = (porps: { open: any; handleActionMobileMenu: any }) => {
	const { open, handleActionMobileMenu } = porps;
	const onClick = (event: { title: string; subTitle?: string }) => {
		console.log("Sidebar item clicked", event);
	};

	return (
		<div className={open === true ? "mobile-menu open" : "mobile-menu "}>
			<div className='mobile-menu__left-side'>
				<SideBar onClick={onClick} />
			</div>
			<div
				className='mobile-menu__right-side'
				onClick={() => {
					handleActionMobileMenu(false);
				}}
			/>
		</div>
	);
};

MobileMenu.propTypes = {
	open: PropTypes.any,
	handleActionMobileMenu: PropTypes.any,
};

export default MobileMenu;
