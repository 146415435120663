type formatType =
  | "cellPhone"
  | "landPhone"
  | "cellPhoneWithZero"
  | "landPhoneWithZero"
  | "creditCard";

export function useFormat({
  type = "cellPhone",
  value,
}: {
  type?: formatType;
  value: string;
}): string {
  if (type == "cellPhone") {
    if (/^[5][0-9]{0,9}$/.test(value.replace(/\D/g, ""))) {
      return formatCellPhone(value);
    } else {
      if (value == "(5") {
        return "(5";
      } else {
        return "";
      }
    }
  } else {
    return "";
  }
}

function formatCellPhone(value: string) {
  const digits = value.replace(/\D/g, "");

  let formatted = "(";

  if (digits.length > 0 && digits[0] != "5") {
    formatted += "5";
  }

  for (let i = 0; i < digits.length; i++) {
    if (i == 3) {
      formatted += ") ";
    }

    if (i == 6) {
      formatted += " ";
    }

    if (i == 8) {
      formatted += "-";
    }

    formatted += digits[i];
  }

  return formatted;
}
