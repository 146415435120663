import React, { useState, useEffect, useContext } from "react";
import { HeaderSection } from "./header-section";
import { CardsSection } from "./cards-section";
import { UpdateSection } from "./update-section";
import { IApplicantsData } from "../types";

import { QueryFunction, useQuery, UseQueryResult } from "@tanstack/react-query";
import Axios from "../../../../services/axios";
import { useCookie } from "../../../../hooks/useCookie";
import { CookieName } from "../../../../constants/enums";
import SkeletonContainer from "./components/loader-skeleton";
import { CandidatePoolContext } from "../index";
import useFetchCdnQuery from "../../../../hooks/useFetchCdnQuery";

const axios = new Axios();

function InfoSection({
  queryParams,
}: {
  queryParams: string;
}): React.ReactElement {
  const [page, setPage] = useState(1);
  //start fetch cards data

  const { setPagination } = useContext(CandidatePoolContext);

  const { getCookie } = useCookie();
  const companyUserToken = getCookie(CookieName.CompanyUserToken);

  const [pagesCount, setPagesCount] = useState(1);

  //fetch cards data
  const fetchCards: QueryFunction = async ({ queryKey }) => {
    const queryParams = queryKey[1];
    const companyUserToken = queryKey[2];

    try {
      const response = await axios.request({
        type: "get",
        endpoint: `companies/candidate-pool/get-filtered-users${
          queryParams && queryParams
        }`,
        payload: {
          headers: {
            Authorization: `Bearer ${companyUserToken}`,
          },
        },
      });
      if (response && response.data && response.data.data) {
        //setPagination(response.data.pagination);
        return response;
      }
    } catch (error) {
      throw new Error("Error fetching header card items: " + error);
    }
  };
  useEffect(() => {
    setPage(1);
    setCardCheckedStatus({});
  }, [queryParams]);

  const [data, setData] = useState<any>([]);
  const dataQuery: UseQueryResult<any, Error> = useQuery({
    queryKey: ["candidate_pool_data", queryParams, companyUserToken],
    queryFn: fetchCards,
    staleTime: Infinity,
  });
  useEffect(() => {
    if (dataQuery.data !== undefined) {
      setData(dataQuery.data.data.data);
      setPagination(dataQuery.data.data.pagination);
    }
  }, [dataQuery.data, dataQuery.fetchStatus]);

  //end

  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [cardCheckedStatus, setCardCheckedStatus] = useState<{
    [key: number]: boolean;
  }>({});
  const [checkedData, setCheckedData] = useState<number[]>([]);

  const handleCardCheckboxToggle = (id: number) => {
    setCardCheckedStatus((prevStatus) => ({
      ...prevStatus,
      [id]: !prevStatus[id],
    }));
  };

  const handleAllCardsCheckboxToggle = () => {
    setIsCheckedAll((prevIsCheckedAll) => !prevIsCheckedAll);
    const newCheckedStatus = {} as { [key: number]: boolean };
    data?.forEach((data: IApplicantsData) => {
      newCheckedStatus[data.cv.cv_id] = !isCheckedAll;
    });
    setCardCheckedStatus(newCheckedStatus);
  };

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    //console log checked cards
    const checkedIds = Object.entries(cardCheckedStatus)
      .filter(([id, checked]) => checked)
      .map(([id]) => parseInt(id));
    setCheckedData(checkedIds);

    // Determine isChecked
    const newIsChecked = checkedIds.length > 0;
    setIsChecked(newIsChecked);
  }, [cardCheckedStatus]);

  return (
    <section>
      {data.length > 0 && (
        <HeaderSection
          onAllCheckboxToggle={handleAllCardsCheckboxToggle}
          isCheckedAll={isCheckedAll}
        />
      )}
      {dataQuery.isLoading && <SkeletonContainer />}
      {!dataQuery.isLoading && (
        <CardsSection
          cardCheckedStatus={cardCheckedStatus}
          onCardCheckboxToggle={handleCardCheckboxToggle}
          data={data}
          pagesCount={pagesCount}
          currentPage={page}
          setCurrentPage={setPage}
        />
      )}
      {isChecked && <UpdateSection checkedData={checkedData} />}
    </section>
  );
}

export { InfoSection };
