import { LabelHTMLAttributes, useContext } from "react"
import { SelectSearchDropdownContext, selectSearchDropdownContextType } from "."





type errorPropsType = LabelHTMLAttributes<HTMLLabelElement> & {

}





export default function Error({
    className,
    ...props
}: errorPropsType) {

    const { error }: selectSearchDropdownContextType = useContext(SelectSearchDropdownContext)

    if (error) {
        return (
            <label {...props} className={`${className} text-xs text-red-600`}>
                
            </label>
        )
    } else {
        return null
    }
}