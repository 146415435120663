import * as React from "react";
import HelpPageContainer from "../../containers/help-page-container";


function HelpPage(): React.ReactElement {
  return (
    <>
      <HelpPageContainer />
    </>
  );
}

export { HelpPage };
