import FreeAdvertPageContainer from "../../containers/free-advert-page-container";














export default function FreeAdvertPage() {
    return(
        <FreeAdvertPageContainer/>
    )
}