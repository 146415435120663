import React from "react";
import Breadcrump from "../../../../components/common/profile/breadcrump";
import İncomplete from "./incomplate";
import Contact from "./contact";
import İncompleteDetail from "./incomplate-detail";
import FormeJob from "./formejob";
import SaveCompany from "./save-company";

//TODO: There is no set function in the project. why you use get function?
//TODO: you can use state in here instead of localstorage

const Body: React.FC = () => {
  // const incompleteValue = localStorage.getItem("incomplete") || "0";

  return (
    <div className="w-full flex flex-col gap-y-6">
      <Breadcrump type="dashboard" />
      <İncomplete />
      <Contact />
      <İncompleteDetail />
      <FormeJob />
      <SaveCompany />
    </div>
  );
};

export default Body;
