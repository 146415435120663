import React, { useEffect, useState } from "react";
import { CiUser } from "react-icons/ci";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { PiHeadphonesBold } from "react-icons/pi";
import { IoMdNotifications } from "react-icons/io";
import { NavLink } from "react-router-dom";
import { FaListUl } from "react-icons/fa";
import {
  FaFileSignature,
  FaFortAwesomeAlt,
  FaHeart,
  FaRegBell,
  FaRegNewspaper,
  FaRegUser,
} from "react-icons/fa";
import useScrollToTop from "../../../../../hooks/useScrollToTop";
import { CircularProgress } from "../CircularProgress";
import Axios from "../../../../../services/axios";
import SubMenu from "antd/es/menu/SubMenu";
import { useCookie } from "../../../../../hooks/useCookie";
import { useAppDispatch } from "../../../../../store/store";
import { clearCompanyUser } from "../../../../../store/features/companyUserSlice";
import { clearCurrentUser } from "../../../../../store/features/userSlice";
import { CookieName } from "../../../../../constants/enums";


const menus = [
  {
    name: "Profilim",
    link: "/profilim",
    icon: <FaRegUser className="text-gray-500" />,
  },
  {
    name: "Özgeçmişler",
    link: "/profilim/cv",
    icon: <FaRegNewspaper className="text-gray-500" />,
  },
  {
    name: "Dosyalar & Ön Yazılar",
    link: "/profilim/dosyalar-onyazilar",
    icon: <FaFileSignature className="text-gray-500" />,
  },
  {
    name: "Başvurularım",
    link: "/profilim/başvurular",
    icon: <CiUser className="text-gray-500" />,
  },
  {
    name: "Favorilerim",
    link: "/profilim/favoriler",
    icon: <FaHeart className="text-gray-500" />,
    collapse: true,
    submenu: [
      { title: "Kayıtlı İlanlar", url: "/profilim/favoriler/liste" },
      {
        title: "Takipteki Firmalar",
        url: "/profilim/follow",
      },
      {
        title: "Kayıtlı Filtreler",
        url: "/profilim/kayitli-aramalar",
      },
      {
        title: "Son İncelenen İlanlar",
        url: "/profilim/son-incelenen-ilanlar",
      },
    ],
  },
  {
    name: "Bildirimlerim",
    link: "/profilim/bildirimler",
    icon: <IoMdNotifications className="text-gray-500" />,
  },
  {
    name: "İş Alarmları",
    link: "/profilim/is-alarmlari",
    icon: <FaRegBell className="text-gray-500" />,
  },
  {
    name: "Mesajlar",
    link: "/profilim/mesajlar",
    icon: <FaFortAwesomeAlt className="text-gray-500" />,
  },
  {
    name: "Mülakatlarım",
    link: "/profilim/mulakat-listesi",
    icon: <FaFortAwesomeAlt className="text-gray-500" />,
  },
  {
    name: "Bana Uygun İlanlar",
    link: "/is-ilanlari?for_me_adverts=1",
    icon: <FaListUl className="text-gray-500" />,
  },
  {
    name: "Ayarlar",
    link: "/profilim/ayarlar",
    icon: <FaRegUser className="text-gray-500" />,
  },
  {
    name: "Yardım",
    link: "/yardim",
    icon: <PiHeadphonesBold className="text-gray-500" />,
  },
  {
    name: "Çıkış",
    link: "/cikis",
    icon: <FaRegUser className="text-gray-500" />,
  },
];

const Body: React.FC = () => {
  const axios = new Axios();
  const scrollToTop = useScrollToTop();
  const [openSubmenu, setOpenSubmenu] = useState<string | null>(null);
  const pathname = window.location.pathname;
  const [favoritesLength, setFavoritesLength] = useState<number>(0);
  const [followLength, setFollowLength] = useState<number>(0);
  const [filterLength, setFilterLength] = useState<number>(0);
  const [viewsLength, setViewsLength] = useState<number>(0);
  const [cvScore, setCvScore] = useState<number>(0);
  const [unreadNotification, setUnreadNotifications] = useState<number>(0);
  const { removeCookie } = useCookie();
  const dispatch = useAppDispatch();


  const handleSubmenuToggle = (name: string) => {
    setOpenSubmenu(openSubmenu === name ? null : name);
  };

  const fetchFavoritesLength = async () => {
    try {
      const response = await axios.request({
        type: "get",
        endpoint: "user/profilim/favoriler",
        payload: {},
      });

      setFavoritesLength(response?.data?.data?.length);
    } catch (error) {
      console.error("Error fetching applies:", error);
    }
  };

  const fetchFollowLength = async () => {
    try {
      const response = await axios.request({
        type: "get",
        endpoint: "user/profilim/follow/get-follow-companies",
        payload: {},
      });

      setFollowLength(response?.data?.data?.length);
    } catch (error) {
      console.error("Error fetching applies:", error);
    }
  };

  const fetchFilterLength = async () => {
    try {
      const response = await axios.request({
        type: "get",
        endpoint: "user/profilim/kayitli-aramalar",
        payload: {},
      });

      setFilterLength(response?.data?.data?.length);
    } catch (error) {
      console.error("Error fetching applies:", error);
    }
  };

  const fetchViewsLength = async () => {
    try {
      const response = await axios.request({
        type: "get",
        endpoint: "user/profilim/last-viewed-adverts/get-all",
        payload: {},
      });

      setViewsLength(response?.data?.data?.length);
    } catch (error) {
      console.error("Error fetching applies:", error);
    }
  };

  async function getCvScore() {
    try {
      axios
        .userAuthRequest({
          endpoint: "user/profilim/cv-score",
          type: "get",
          payload: {},
        })
        .then((response: any) => {
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.score
          ) {
            setCvScore(response.data.data.score);
          } else {
            setCvScore(0);
          }
        });
    } catch (error) {
      console.log(`Error: ${error}`);
      setCvScore(0);
    }
  }
  async function logOut(){
    console.log('logOut')

    removeCookie(CookieName.CompanyUserToken);
    removeCookie(CookieName.CompanyUserData);
    removeCookie(CookieName.UserToken);
    removeCookie(CookieName.UserData);
    dispatch(clearCompanyUser());
    dispatch(clearCurrentUser());
   

  }

  async function getUnreadNotifications() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: "user/profilim/notification/get-unread-count",
        type: "get",
        payload: {},
      });

      if (response && response.data && response.data.count) {
        setUnreadNotifications(response.data.count);
      } else {
        setUnreadNotifications(0);
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      setUnreadNotifications(0);
    }
  }
  useEffect(() => {
    Promise.all([
      fetchFilterLength(),
      fetchFollowLength(),
      fetchFavoritesLength(),
      fetchViewsLength(),
      getCvScore(),
      getUnreadNotifications(),
    ]);
  }, []);


  
  console.log('ajdsahlsduhaosudgasdı')
  console.log(menus)
  //console.log(filterLength);

  return (
    <div className="flex h-auto mt-8 bg-white rounded-md shadow-lg">
      <div className="flex flex-col w-full py-3 gap-y-4">
        <div className="flex flex-col w-full">
        {menus.map((menu, index) => (
          <div key={index}>
            <NavLink
              to={menu.link}
              onClick={() => {
                if (menu.name === "Çıkış") {
                  logOut();
                } else {
                  scrollToTop();
                  if (menu.submenu) handleSubmenuToggle(menu.name);
                }
              }}
              className={({ isActive }) =>
                `flex items-center justify-between border-b border-l-4 ${
                  isActive ? "border-l-title" : "border-l-transparent"
                } group`
              }
              end={menu.link === "/profilim"}
            >
              <div className="flex items-center h-full p-4 gap-x-2 relative">
                {menu.icon}
                <span className="text-gray-800 group-hover:text-title">
                  {menu.name}
                </span>
                {menu.name == "Bildirimlerim" && unreadNotification > 0 && (
                  <div className="absolute top-[10px] left-[23px] w-[16px] h-[16px] bg-red-600 rounded-full flex justify-center items-center">
                    <span className="text-white text-xs">
                      {unreadNotification > 9 ? "9+" : unreadNotification}
                    </span>
                  </div>
                )}
              </div>
              {menu.submenu ? (
                openSubmenu === menu.name ? (
                  <MdKeyboardArrowDown className="text-xl group-hover:text-title" />
                ) : (
                  <MdKeyboardArrowRight className="text-xl group-hover:text-title" />
                )
              ) : (
                <MdKeyboardArrowRight className="text-xl group-hover:text-title" />
              )}
            </NavLink>
            {menu.submenu && openSubmenu === menu.name && (
              <div className="flex flex-col pl-8">
                {menu.submenu.map((sub, subIndex) => (
                  <NavLink
                    to={sub.url}
                    key={subIndex}
                    onClick={scrollToTop}
                    className={({ isActive }) =>
                      `flex items-center justify-between border-b border-l-4 ${
                        isActive
                          ? "border-l-title"
                          : "border-l-transparent w-full"
                      } group`
                    }
                    end={sub.url === "/profilim"}
                  >
                    <div className="flex items-center justify-between h-full p-4 gap-x-2 w-full">
                      <span className="text-sm text-gray-800 group-hover:text-title">
                        {sub.title}
                      </span>
                      {sub.title === "Kayıtlı İlanlar" && favoritesLength > 0 && (
                        <span className="text-sm bg-red-500 rounded-full text-white w-7 h-7 flex items-center justify-center">
                          {favoritesLength}
                        </span>
                      )}
                      {sub.title === "Takipteki Firmalar" && followLength > 0 && (
                        <span className="text-sm bg-red-500 rounded-full text-white w-7 h-7 flex items-center justify-center">
                          {followLength}
                        </span>
                      )}
                      {sub.title === "Kayıtlı Filtreler" && filterLength > 0 && (
                        <span className="text-sm bg-red-500 rounded-full text-white w-7 h-7 flex items-center justify-center">
                          {filterLength}
                        </span>
                      )}
                      {sub.title === "Son İncelenen İlanlar" && viewsLength > 0 && (
                        <span className="text-sm bg-red-500 rounded-full text-white w-7 h-7 flex items-center justify-center">
                          {viewsLength}
                        </span>
                      )}
                    </div>
                  </NavLink>
                ))}
              </div>
            )}
          </div>
))}

        </div>

        <div className="flex flex-col gap-y-3">
          <div className="flex items-center justify-center w-full">
            <span className="text-xl text-center poppins-medium text-mains">
              Özgeçmiş Doluluk Oranı
            </span>
          </div>

          <div className="flex items-center justify-center w-full">
            <CircularProgress percentage={cvScore} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
