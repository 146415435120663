import { IApplicantsData } from "../../../../types";
import { PiPaintBrushBroadDuotone } from "react-icons/pi";
import { CiCalendar } from "react-icons/ci";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { BiSolidFactory } from "react-icons/bi";

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: IApplicantsData;
}

function CustomModal({ isOpen, onClose, data }: CustomModalProps) {
  if (!isOpen) return null;

  const calculateBeginDate = () => {
    if (
      !data.last_job_experience?.start_date ||
      data.last_job_experience?.start_date === "{}"
    ) {
      return "Bilinmiyor";
    }

    // Parse the JSON string into an object
    const dateObj = JSON.parse(data.last_job_experience.start_date);

    // Define month names array
    const monthNames = [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ];

    // Extract month and year from the object
    const { month, year } = dateObj;
    return `${monthNames[parseInt(month) - 1]} ${year}`;
  };
  const calculateEndDate = () => {
    if (
      !data.last_job_experience?.end_date ||
      data.last_job_experience?.end_date === "{}"
    ) {
      return "Bilinmiyor";
    }

    // Parse the JSON string into an object
    const dateObj = JSON.parse(data.last_job_experience.end_date);

    // Define month names array
    const monthNames = [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ];

    // Extract month and year from the object
    const { month, year } = dateObj;
    return `${monthNames[parseInt(month) - 1]} ${year}`;
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-600">
      <div className="fixed inset-0 bg-gray-900 opacity-75"></div>
      <div className="absolute bg-white rounded-lg px-10 py-5">
        <div className="flex items-center justify-between  mb-5">
          <h1 className="text-xl text-primary300  scale-x-125 scale-y-105 ml-8  ">
            Adayın Son İş Deneyimleri
          </h1>
          <span
            className=" text-2xl  text-gray-500 cursor-pointer    "
            onClick={onClose}
          >
            X
          </span>
        </div>

        {data.last_job_experience ? (
          <div className="w-[60vw] flex items-center gap-8  bg-gray-600/10 text-gray-700 px-3 py-5 rounded-md">
            <p className="text-blue-400 text-2xl scale-y-125 scale-x-105 ml-3 ">
              01
            </p>
            <div>
              <h3 className="flex items-center justify-start gap-2 text-xl mb-2 text-gray-500 ">
                <BiSolidFactory className="w-6 h-6" />
                {data.last_job_experience.company_name}
              </h3>
              <div className="flex gap-2  ">
                <p className="text-primary100 flex gap-1 items-center justify-center ">
                  <MdKeyboardDoubleArrowRight className="w-3 h-3" />
                  <span className="text-gray-600">
                    {data.last_job_experience.sector_id ?? "Sektör Bilgisi Yok"}
                  </span>
                  <MdKeyboardDoubleArrowRight className="w-3 h-3" />
                </p>
                <p>{data.last_job_experience.position}</p>
              </div>
              <div className="mt-3 flex  gap-5 text-sm">
                <div className="flex gap-1">
                  <CiCalendar className="w-5 h-5 " />
                  <p>
                    İşe Başlama :{" "}
                    <span className="text-primary100">
                      {calculateBeginDate()}
                    </span>
                  </p>
                </div>
                <div className="flex gap-1">
                  <CiCalendar className="w-5 h-5" />
                  <p>
                    İşten Ayrılma :{" "}
                    <span className="text-primary100">
                      {calculateEndDate()}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-[60vw] border border-red-600 bg-red-500/20 text-red-700 px-3 py-5 rounded-md">
            İş Deneyimi Belirtilmemiş
          </div>
        )}
      </div>
    </div>
  );
}

export { CustomModal };
