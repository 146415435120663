import React from "react";
import AlphabetCollapse from "./alphetsection/index";
import SearchSection from "./searchsection/index";


const ContentIndex: React.FC = () => {
  return (

    <div className="h-auto bg-gray-100  flex flex-col  w-full mx-auto ">
      <SearchSection />
      <AlphabetCollapse />
    </div>

  )
};

export { ContentIndex }
