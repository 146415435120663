import Axios from "../services/axios"
import { cdnGeneralLanguageType } from "../types"







type getGeneralLanguagesPropsType = {
    search?: string,
    limit?: number,
    only_ids?: number[]
}




const axios = new Axios()


export async function getGeneralLanguages({
    limit,
    search,
    only_ids
}: getGeneralLanguagesPropsType): Promise<cdnGeneralLanguageType[]> {
    try {
        const response = await axios.request({
            endpoint: `cdn/get-general-languages?
            ${search? `search=${search}&`: ''}
            ${limit? `limit=${limit}&`: ''}
            ${only_ids? `only_ids=${JSON.stringify(only_ids)}&`: ''}
            `,
            type: 'get'
        })

        if (response && response.data && response.data.general_languages) {
            return JSON.parse(JSON.stringify(response.data.general_languages))
        } else {
            return []
        }
    } catch (error) {
        console.log(`General languages fetch failed. Error: ${error}`)
        return []
    }
}