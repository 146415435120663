import { useParams } from "react-router-dom";
import {
  Button,
  InputArea,
  SelectDropdown,
  SelectSearchDropdown,
  Textarea,
} from "../../../../../../components/isbul-ui";
import { useIsMobile } from "../../../../../../hooks/useIsMobile";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  cdnCityType,
  cdnCountryType,
  cdnPositionLevelType,
  cdnPositionType,
  cdnSectionType,
  cdnSectorType,
  cdnWorkingTypeType,
  dbUserJobExperienceType,
} from "../../../../../../types";
import { getPositions } from "../../../../../../customHooks/getPositions";
import { getPositionLevels } from "../../../../../../customHooks/getPositionLevels";
import { getSectors } from "../../../../../../customHooks/getSectors";
import { getSections } from "../../../../../../customHooks/getSections";
import { getCountries } from "../../../../../../customHooks/getCountries";
import { getCities } from "../../../../../../customHooks/getCities";
import Swal from "sweetalert2";
import { SubmitHandler, useForm } from "react-hook-form";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoCheckmark } from "react-icons/io5";
import Axios from "../../../../../../services/axios";
import { CvLayoutContext, cvLayoutContextType } from "../..";
import { getWorkingTypes } from "../../../../../../customHooks/getWorkingTypes";

type payloadDataType = {
  cv_id: number;
  company_name: string;
  position: number | null;
  position_level: number | null;
  sector: number | null;
  section: number | null;
  is_abroad: number;
  city?: number;
  country?: number;
  working_type: number | null;
  start_date_month: number | null;
  start_date_year: number | null;
  now_working: 0 | 1;
  end_date_month?: number;
  end_date_year?: number;
  leaving_reason: string | null;
  work_info: string;
};

type monthType = {
  id: number;
  name: string;
  name_en: string;
};

const months: monthType[] = [
  { id: 1, name: "Ocak", name_en: "January" },
  { id: 2, name: "Şubat", name_en: "February" },
  { id: 3, name: "Mart", name_en: "March" },
  { id: 4, name: "Nisan", name_en: "April" },
  { id: 5, name: "Mayıs", name_en: "May" },
  { id: 6, name: "Haziran", name_en: "June" },
  { id: 7, name: "Temmuz", name_en: "July" },
  { id: 8, name: "Ağustos", name_en: "August" },
  { id: 9, name: "Eylül", name_en: "September" },
  { id: 10, name: "Ekim", name_en: "October" },
  { id: 11, name: "Kasım", name_en: "November" },
  { id: 12, name: "Aralık", name_en: "December" },
];

const currentYear = new Date().getFullYear();

const totalYear = currentYear - 1974 + 1;

const years: number[] = Array.from({ length: totalYear }, (_, i) => i + 1974);

const axios = new Axios();

export default function WorkExperienceFormComponent({
  jobExperience,
  isAddClicked,
  setIsAddClicked,
  initialPositions,
  initialPositionLevels,
  initialSectors,
  initialSections,
  initialCountries,
  initialWorkingTypes,
  initialCities,
  isEnglish = false,
  cvId,
  getAllJobExperiences,
  setIsModalOpen,
  setIsPageOpen,
  inPopup = false,
  onSuccess = () => {},
  onCancel = () => {},
  onReady = () => {},
  setIsEditOpen,
}: {
  jobExperience?: dbUserJobExperienceType | null;
  isAddClicked?: boolean;
  setIsAddClicked?: Dispatch<SetStateAction<boolean>>;
  initialPositions?: cdnPositionType[];
  initialPositionLevels?: cdnPositionLevelType[];
  initialSectors?: cdnSectorType[];
  initialSections?: cdnSectionType[];
  initialCountries?: cdnCountryType[];
  initialWorkingTypes?: cdnWorkingTypeType[];
  initialCities?: cdnCityType[];
  isEnglish?: boolean;
  cvId?: string;
  getAllJobExperiences?: () => void;
  setIsModalOpen?: Dispatch<SetStateAction<boolean>>;
  setIsPageOpen?: Dispatch<SetStateAction<boolean>>;
  inPopup?: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
  onReady?: () => void;
  setIsEditOpen?: Dispatch<SetStateAction<boolean>>;
}) {
  const { showCvScore }: cvLayoutContextType = useContext(CvLayoutContext);

  const params = useParams();

  const isMobile = useIsMobile();

  // Page states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  // Page header add click catcher
  useEffect(() => {
    if (
      isAddClicked != undefined &&
      setIsAddClicked != undefined &&
      !jobExperience
    ) {
      if (isAddClicked) {
        if (setIsModalOpen) {
          setIsModalOpen(true);
        }
        setIsAddClicked(false);
      }
    }
  }, [isAddClicked]);

  // General states
  const [isNotTurkey, setIsNotTurkey] = useState<boolean>(false);
  const [isStillWorking, setIsStillWorking] = useState<boolean>(false);
  const [positions, setPositions] = useState<cdnPositionType[]>(
    initialPositions || []
  );
  const [positionLevels, setPositionLevels] = useState<cdnPositionLevelType[]>(
    initialPositionLevels || []
  );
  const [sectors, setSectors] = useState<cdnSectorType[]>(initialSectors || []);
  const [sections, setSections] = useState<cdnSectionType[]>(
    initialSections || []
  );
  const [countries, setCountries] = useState<cdnCountryType[]>(
    initialCountries || []
  );
  const [workingTypes, setWorkingTypes] = useState<cdnWorkingTypeType[]>(
    initialWorkingTypes || []
  );
  const [cities, setCities] = useState<cdnCityType[]>(initialCities || []);
  const [timeDiff, setTimeDiff] = useState<string>("");

  // Initial states changes
  // Position
  useEffect(() => {
    setPositions(initialPositions || []);
  }, [initialPositions]);

  // Position levels
  useEffect(() => {
    setPositionLevels(initialPositionLevels || []);
  }, [initialPositionLevels]);

  // Sectors
  useEffect(() => {
    setSectors(initialSectors || []);
  }, [initialSectors]);

  // Sections
  useEffect(() => {
    setSections(initialSections || []);
  }, [initialSections]);

  // Countries
  useEffect(() => {
    setCountries(initialCountries || []);
  }, [initialCountries]);

  // Working types
  useEffect(() => {
    setWorkingTypes(initialWorkingTypes || []);
  }, [initialWorkingTypes]);

  // Cities
  useEffect(() => {
    setCities(initialCities || []);
  }, [initialCities]);

  // Form states
  const [selectedPosition, setSelectedPosition] =
    useState<cdnPositionType | null>(null);
  const [selectedPositionLevel, setSelectedPositionLevel] =
    useState<cdnPositionLevelType | null>(null);
  const [selectedSector, setSelectedSector] = useState<cdnSectorType | null>(
    null
  );
  const [selectedSection, setSelectedSection] = useState<cdnSectionType | null>(
    null
  );
  const [selectedCountry, setSelectedCountry] = useState<cdnCountryType | null>(
    null
  );
  const [selectedCity, setSelectedCity] = useState<cdnCityType | null>(null);
  const [selectedWorkingType, setSelectedWorkingType] =
    useState<cdnWorkingTypeType | null>(null);
  const [selectedStartMonth, setSelectedStartMonth] =
    useState<monthType | null>(null);
  const [selectedEndMonth, setSelectedEndMonth] = useState<monthType | null>(
    null
  );
  const [selectedStartYear, setSelectedStartYear] = useState<number | null>(
    null
  );
  const [selectedEndYear, setSelectedEndYear] = useState<number | null>(null);
  // Search states
  const [searchPosition, setSearchPosition] = useState<string>("");
  const [searchPositionLevel, setSearchPositionLevel] = useState<string>("");
  const [searchSector, setSearchSector] = useState<string>("");
  const [searchSection, setSearchSection] = useState<string>("");
  const [searchCountry, setSearchCountry] = useState<string>("");
  const [searchCity, setSearchCity] = useState<string>("");

  // Search events
  // Positions
  useEffect(() => {
    async function search() {
      if (searchPosition.trim().length > 0) {
        const response = await getPositions({
          search: searchPosition,
          lang: isEnglish ? "en" : "tr",
        });
        setPositions(response);
      } else {
        setPositions(initialPositions || []);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchPosition]);

  // Position levels
  useEffect(() => {
    async function search() {
      if (searchPositionLevel.trim().length > 0) {
        const response = await getPositionLevels({
          search: searchPositionLevel,
          lang: isEnglish ? "en" : "tr",
        });
        setPositionLevels(response);
      } else {
        setPositionLevels(initialPositionLevels || []);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchPositionLevel]);

  // Sector
  useEffect(() => {
    async function search() {
      if (searchSector.trim().length > 0) {
        const response = await getSectors({
          search: searchSector,
          lang: isEnglish ? "en" : "tr",
        });
        setSectors(response);
      } else {
        setSectors(initialSectors || []);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchSector]);

  // Section
  useEffect(() => {
    async function search() {
      if (searchSection.trim().length > 0) {
        const response = await getSections({
          search: searchSection,
          lang: isEnglish ? "en" : "tr",
        });
        setSections(response);
      } else {
        setSections(initialSections || []);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchSection]);

  // Country
  useEffect(() => {
    async function search() {
      if (searchCountry.trim().length > 0) {
        const response = await getCountries({
          search: searchCountry,
          lang: isEnglish ? "en" : "tr",
        });
        setCountries(response);
      } else {
        setCountries(initialCountries || []);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchCountry]);

  // City
  useEffect(() => {
    async function search() {
      if (searchCity.trim().length > 0) {
        const response = await getCities({
          search: searchCity,
          lang: isEnglish ? "en" : "tr",
        });
        setCities(response);
      } else {
        setCities(initialCities || []);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchCity]);

  // Form states change event
  // Positions
  useEffect(() => {
    if (selectedPosition) {
      setValue("position", selectedPosition.id);
      clearErrors("position");
    } else {
      setValue("position", null);
    }
  }, [selectedPosition]);

  // Position levels
  useEffect(() => {
    if (selectedPositionLevel) {
      setValue("position_level", selectedPositionLevel.id);
      clearErrors("position_level");
    } else {
      setValue("position_level", null);
    }
  }, [selectedPositionLevel]);

  // Sectors
  useEffect(() => {
    if (selectedSector) {
      setValue("sector", selectedSector.id);
      clearErrors("sector");
    } else {
      setValue("sector", null);
    }
  }, [selectedSector]);

  // Sections
  useEffect(() => {
    if (selectedSection) {
      setValue("section", selectedSection.id);
      clearErrors("section");
    } else {
      setValue("section", null);
    }
  }, [selectedSection]);

  // Country
  useEffect(() => {
    if (selectedCountry) {
      setValue("country", selectedCountry.id);
      clearErrors("country");
    } else {
      setValue("country", undefined);
    }
  }, [selectedCountry]);

  // City
  useEffect(() => {
    if (selectedCity) {
      setValue("city", selectedCity.id);
      clearErrors("city");
    } else {
      setValue("city", undefined);
    }
  }, [selectedCity]);

  // Working types
  useEffect(() => {
    if (selectedWorkingType) {
      setValue("working_type", selectedWorkingType.id);
      clearErrors("working_type");
    } else {
      setValue("working_type", null);
    }
  }, [selectedWorkingType]);

  // Start month
  useEffect(() => {
    if (selectedStartMonth) {
      setValue("start_date_month", selectedStartMonth.id);
      clearErrors("start_date_month");
    } else {
      setValue("start_date_month", null);
    }
  }, [selectedStartMonth]);

  // Start year
  useEffect(() => {
    if (selectedStartYear) {
      if (selectedEndYear) {
        if (selectedStartYear > selectedEndYear) {
          Swal.fire({
            icon: "error",
            title: `${isEnglish ? "Error" : "Hata"}`,
            text: `${
              isEnglish
                ? "A start date must be selected before the end date"
                : "İşten ayrılma tarihiniz işe başlama tarihinizden daha eski olamaz"
            }`,
          });

          return;
        }
      }
      setValue("start_date_year", selectedStartYear);
      clearErrors("start_date_year");
    } else {
      setValue("start_date_year", null);
    }
  }, [selectedStartYear]);

  // End month
  useEffect(() => {
    if (selectedEndMonth) {
      setValue("end_date_month", selectedEndMonth.id);
      clearErrors("end_date_month");
    } else {
      setValue("end_date_month", undefined);
    }
  }, [selectedEndMonth]);

  // End year
  useEffect(() => {
    if (selectedEndYear) {
      setValue("end_date_year", selectedEndYear);
      clearErrors("end_date_year");
    } else {
      setValue("end_date_year", undefined);
    }
  }, [selectedEndYear]);

  // Is abroad
  useEffect(() => {
    if (isNotTurkey) {
      setValue("is_abroad", 1);
    } else {
      setValue("is_abroad", 0);
    }
  }, [isNotTurkey]);

  // Now working
  useEffect(() => {
    if (isStillWorking) {
      setValue("now_working", 1);
    } else {
      setValue("now_working", 0);
      setSelectedEndMonth(null);
      setSelectedEndYear(null);
    }
  }, [isStillWorking]);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors, isValid },
    watch,
    getFieldState,
    setError,
  } = useForm<payloadDataType>({
    criteriaMode: "all",
  });

  const onSubmit: SubmitHandler<payloadDataType> = (data) => {
    if (jobExperience) {
      updateJobExperience(data);
    } else {
      addJobExperience(data);
    }
  };

  // Custom registers
  // Position
  register("position", {
    required: "Pozisyon",
  });

  // Position level
  register("position_level", {
    required: "Pozisyon Seviyesi",
  });

  // Sector
  register("sector", {
    required: "Sektör",
  });

  // Section
  register("section", {
    required: "Bölüm",
  });

  // Country
  register("country", {
    required: isNotTurkey ? "Ülke" : false,
  });

  // City
  register("city", {
    required: !isNotTurkey ? "Şehir" : false,
  });

  // Working type
  register("working_type", {
    required: "Çalışma Şekli",
  });

  // Start month
  register("start_date_month", {
    required: "İşe Başlama Ayı",
  });

  // Start year
  register("start_date_year", {
    required: "İşe Başlama Yılı",
  });

  // End month
  register("end_date_month", {
    required: isStillWorking ? false : "İşten Ayrılma Ayı",
  });

  // End year
  register("end_date_year", {
    required: isStillWorking ? false : "İşten Ayrılma Yılı",
  });

  // Watch form values
  const company_name = watch("company_name") || "";
  const work_info = watch("work_info") || "";
  const leaving_reason = watch("leaving_reason") || "";

  // Get initial values
  useEffect(() => {
    if (jobExperience) {
      //console.log("exp", jobExperience);
      getJobExperienceValues(jobExperience);
    }
  }, [jobExperience]);

  async function getJobExperienceValues(exp: dbUserJobExperienceType) {
    setValue("company_name", exp.company_name);
    setValue("leaving_reason", exp.leaving_reason);
    setValue("work_info", exp.work_info);

    // Is abroad
    if (exp.is_abroad == "0") {
      setIsNotTurkey(false);
    } else {
      setIsNotTurkey(true);
    }

    // Is working
    if (exp.now_working == 0) {
      setIsStillWorking(false);
    } else {
      setIsStillWorking(true);
    }

    setSelectedStartMonth(
      months.find((m) => m.id == exp.start_date.month) || null
    );
    setSelectedStartYear(exp.start_date.year);

    if (exp.end_date?.month) {
      setSelectedEndMonth(
        months.find((m) => m.id == exp.end_date?.month) || null
      );
    }

    if (exp.end_date?.year) {
      setSelectedEndYear(exp.end_date.year);
    }

    // Position
    setSelectedPosition(exp.position_list || null);

    // Position level
    setSelectedPositionLevel(exp.position_level_list || null);

    // Sector
    setSelectedSector(exp.sector_list || null);

    // Section
    setSelectedSection(exp.section_list || null);

    // Country
    if (exp.country_id) {
      setSelectedCountry(exp.country_list || null);
    }

    // City
    if (exp.city_id) {
      setSelectedCity(exp.city_list || null);
    }

    // Working type
    setSelectedWorkingType(exp.working_type_list || null);

    // Working time
    if (exp.end_date?.month && exp.end_date.year) {
      let startDate = new Date();
      startDate = new Date(startDate.setDate(1));
      startDate = new Date(startDate.setMonth(exp.start_date.month - 1));
      startDate = new Date(startDate.setFullYear(exp.start_date.year));

      let endDate = new Date();
      endDate = new Date(endDate.setDate(1));
      endDate = new Date(endDate.setMonth(exp.end_date.month - 1));
      endDate = new Date(endDate.setFullYear(exp.end_date.year));

      const monthDiff =
        (endDate.getFullYear() - startDate.getFullYear()) * 12 +
        (endDate.getMonth() - startDate.getMonth());
      const yearDiff = endDate.getFullYear() - startDate.getFullYear();

      if (monthDiff >= 12) {
        setTimeDiff(
          `${yearDiff} ${isEnglish ? "Year" : "Yıl"} ${
            monthDiff % 12 > 0
              ? `${monthDiff % 12} ${isEnglish ? `Month` : `Ay`}`
              : ``
          }`
        );
      } else {
        setTimeDiff(`${monthDiff} ${isEnglish ? "Month" : "Ay"}`);
      }
    }
  }

  // Date check if start date greater than end date
  function dateCheck({
    startMonth,
    startYear,
    endMonth,
    endYear,
  }: {
    startMonth?: number;
    startYear?: number;
    endMonth?: number;
    endYear?: number;
  }): boolean {
    if (startMonth && startYear && endMonth && endYear) {
      const startDate = new Date(startYear, startMonth - 1, 1);
      const endDate = new Date(endYear, endMonth - 1, 1);

      if (startDate.getTime() > endDate.getTime()) {
        /* Swal.fire({
            icon: "error",
            title: `${isEnglish? 'Error': "Hata"}`,
            text: `${isEnglish? 'A start date must be selected before the end date': "İşten ayrılma tarihiniz işe başlama tarihinizden daha eski olamaz"}`,
            }); */

        const errorMessage = `${
          isEnglish
            ? "A start date must be selected before the end date"
            : "İşten ayrılma tarihiniz işe başlama tarihinizden daha eski olamaz"
        }`;
        setError("start_date_month", { message: errorMessage });
        setError("start_date_year", { message: errorMessage });
        setError("end_date_month", { message: errorMessage });
        setError("end_date_year", { message: errorMessage });

        return false;
      }

      clearErrors("start_date_month");
      clearErrors("start_date_year");
      clearErrors("end_date_month");
      clearErrors("end_date_year");

      return true;
    }

    return true;
  }

  onReady && onReady();
  return (
    <form
      className="flex flex-col gap-y-4 px-6 pt-2 pb-20 lg:pb-2"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <label className="text-mains font-semibold">
        {jobExperience
          ? `${isEnglish ? "Edit Work Experience" : "İş Deneyimi Düzenle"}`
          : `${isEnglish ? "Add Work Experience" : "İş Deneyimi Ekle"}`}
      </label>
      <hr className="border-mains" />
      <div className="flex flex-col gap-y-6 lg:flex-row">
        {/* Left section */}
        <div className="flex flex-col gap-y-6 basis-2/3 p-2">
          {/* Company name input */}
          <div className="flex px-2">
            <InputArea
              className="w-full"
              required
              mode={company_name.length == 0 ? "empty" : "dirty"}
              error={errors.company_name?.message ? true : false}
            >
              <InputArea.Label>
                {isEnglish ? "Company Name" : "Firma Adı"}
              </InputArea.Label>
              <InputArea.Input
                className="line-clamp-1 text-ellipsis overflow-auto whitespace-nowrap"
                {...register("company_name", {
                  required: "Firma Adı",
                })}
                value={company_name}
                onChange={(e) => {
                  setValue("company_name", e.target.value);
                  clearErrors("company_name");
                }}
              />
            </InputArea>
          </div>
          <div className="flex flex-col gap-y-6 lg:flex-row items-center">
            {/* Positions select */}
            <div className="flex w-full lg:w-1/2 lg:basis-1/2 px-2">
              <SelectSearchDropdown
                className="w-full"
                required
                mode={selectedPosition ? "dirty" : "empty"}
                error={errors.position?.message ? true : false}
              >
                <SelectSearchDropdown.Label>
                  {isEnglish ? "Position" : "Pozisyon"}
                </SelectSearchDropdown.Label>
                <SelectSearchDropdown.Trigger
                  onClick={async () => {
                    if (
                      searchPosition.trim().length == 0 &&
                      positions.length == 0
                    ) {
                      setIsLoadingData(true);
                      const res = await getPositions({});
                      setPositions(res);
                      setIsLoadingData(false);
                    }
                  }}
                >
                  <SelectSearchDropdown.Value>
                    {selectedPosition
                      ? `${
                          isEnglish
                            ? `${selectedPosition.title_en}`
                            : `${selectedPosition.title}`
                        }`
                      : `${isEnglish ? "Select" : "Seçiniz"}`}
                  </SelectSearchDropdown.Value>
                  <SelectSearchDropdown.Input
                    placeholder={isEnglish ? "Search" : "Arama Yap"}
                    value={searchPosition || ""}
                    onChange={(e) => setSearchPosition(e.target.value)}
                  />
                </SelectSearchDropdown.Trigger>
                <SelectSearchDropdown.Items>
                  {isLoadingData && (
                    <SelectSearchDropdown.Item>
                      <AiOutlineLoading3Quarters className="animate-spin mx-auto" />
                    </SelectSearchDropdown.Item>
                  )}
                  {positions.map((pos) => {
                    return (
                      <SelectSearchDropdown.Item
                        key={`posItem${pos.id}`}
                        onClick={() => setSelectedPosition(pos)}
                      >
                        {isEnglish ? pos.title_en : pos.title}
                      </SelectSearchDropdown.Item>
                    );
                  })}
                </SelectSearchDropdown.Items>
              </SelectSearchDropdown>
            </div>

            {/* Position levels select */}
            <div className="flex w-full lg:w-1/2 lg:basis-1/2 px-2">
              <SelectSearchDropdown
                className="w-full"
                required
                mode={selectedPositionLevel ? "dirty" : "empty"}
                error={errors.position_level?.message ? true : false}
              >
                <SelectSearchDropdown.Label>
                  {isEnglish ? "Position Level" : "Pozisyon Seviyesi"}
                </SelectSearchDropdown.Label>
                <SelectSearchDropdown.Trigger
                  onClick={async () => {
                    if (
                      searchPositionLevel.trim().length == 0 &&
                      positionLevels.length == 0
                    ) {
                      setIsLoadingData(true);
                      const res = await getPositionLevels({});
                      setPositionLevels(res);
                      setIsLoadingData(false);
                    }
                  }}
                >
                  <SelectSearchDropdown.Value>
                    {selectedPositionLevel
                      ? `${
                          isEnglish
                            ? `${selectedPositionLevel.name_en}`
                            : `${selectedPositionLevel.name}`
                        }`
                      : `${isEnglish ? "Select" : "Seçiniz"}`}
                  </SelectSearchDropdown.Value>
                  <SelectSearchDropdown.Input
                    placeholder={`${isEnglish ? "Search" : "Arama yap"}`}
                    value={searchPositionLevel || ""}
                    onChange={(e) => setSearchPositionLevel(e.target.value)}
                  />
                </SelectSearchDropdown.Trigger>
                <SelectSearchDropdown.Items>
                  {isLoadingData && (
                    <SelectSearchDropdown.Item>
                      <AiOutlineLoading3Quarters className="animate-spin mx-auto" />
                    </SelectSearchDropdown.Item>
                  )}
                  {positionLevels.map((lvl) => {
                    return (
                      <SelectSearchDropdown.Item
                        key={`lvlSelect${lvl.id}`}
                        onClick={() => setSelectedPositionLevel(lvl)}
                      >
                        {isEnglish ? lvl.name_en : lvl.name}
                      </SelectSearchDropdown.Item>
                    );
                  })}
                </SelectSearchDropdown.Items>
              </SelectSearchDropdown>
            </div>
          </div>
          <div className="flex flex-col gap-y-6 lg:flex-row items-center">
            {/* Sector select */}
            <div className="flex w-full lg:w-1/2 lg:basis-1/2 px-2">
              <SelectSearchDropdown
                className="w-full"
                required
                mode={selectedSector ? "dirty" : "empty"}
                error={errors.sector?.message ? true : false}
              >
                <SelectSearchDropdown.Label>
                  {isEnglish ? "Industry" : "Sektör"}
                </SelectSearchDropdown.Label>
                <SelectSearchDropdown.Trigger
                  onClick={async () => {
                    if (
                      searchSector.trim().length == 0 &&
                      sectors.length == 0
                    ) {
                      setIsLoadingData(true);
                      const res = await getSectors({});
                      setSectors(res);
                      setIsLoadingData(false);
                    }
                  }}
                >
                  <SelectSearchDropdown.Value>
                    {selectedSector
                      ? `${
                          isEnglish
                            ? `${selectedSector.title_en}`
                            : `${selectedSector.title}`
                        }`
                      : `${isEnglish ? "Select" : "Seçiniz"}`}
                  </SelectSearchDropdown.Value>
                  <SelectSearchDropdown.Input
                    placeholder={`${isEnglish ? "Search" : "Arama Yap"}`}
                    value={searchSector || ""}
                    onChange={(e) => setSearchSector(e.target.value)}
                  />
                </SelectSearchDropdown.Trigger>
                <SelectSearchDropdown.Items>
                  {isLoadingData && (
                    <SelectSearchDropdown.Item>
                      <AiOutlineLoading3Quarters className="mx-auto animate-spin" />
                    </SelectSearchDropdown.Item>
                  )}
                  {sectors.map((sector) => {
                    return (
                      <SelectSearchDropdown.Item
                        key={`sectorSelect${sector.id}`}
                        onClick={() => setSelectedSector(sector)}
                      >
                        {isEnglish ? sector.title_en : sector.title}
                      </SelectSearchDropdown.Item>
                    );
                  })}
                </SelectSearchDropdown.Items>
              </SelectSearchDropdown>
            </div>

            {/* Section select */}
            <div className="flex w-full lg:w-1/2 lg:basis-1/2 px-2">
              <SelectSearchDropdown
                className="w-full"
                required
                mode={selectedSection ? "dirty" : "empty"}
                error={errors.section?.message ? true : false}
              >
                <SelectSearchDropdown.Label>
                  {isEnglish ? "Department" : "Bölüm"}
                </SelectSearchDropdown.Label>
                <SelectSearchDropdown.Trigger
                  onClick={async () => {
                    if (
                      searchSection.trim().length == 0 &&
                      sections.length == 0
                    ) {
                      setIsLoadingData(true);
                      const res = await getSections({});
                      setSections(res);
                      setIsLoadingData(false);
                    }
                  }}
                >
                  <SelectSearchDropdown.Value>
                    {selectedSection
                      ? `${
                          isEnglish
                            ? `${selectedSection.title_en}`
                            : `${selectedSection.title}`
                        }`
                      : `${isEnglish ? "Select" : "Seçiniz"}`}
                  </SelectSearchDropdown.Value>
                  <SelectSearchDropdown.Input
                    placeholder={`${isEnglish ? "Search" : "Arama Yap"}`}
                    value={searchSection || ""}
                    onChange={(e) => setSearchSection(e.target.value)}
                  />
                </SelectSearchDropdown.Trigger>
                <SelectSearchDropdown.Items>
                  {isLoadingData && (
                    <SelectSearchDropdown.Item>
                      <AiOutlineLoading3Quarters className="mx-auto animate-spin" />
                    </SelectSearchDropdown.Item>
                  )}
                  {sections.map((section) => {
                    return (
                      <SelectSearchDropdown.Item
                        key={`sectionSelect${section.id}`}
                        onClick={() => setSelectedSection(section)}
                      >
                        {isEnglish ? section.title_en : section.title}
                      </SelectSearchDropdown.Item>
                    );
                  })}
                </SelectSearchDropdown.Items>
              </SelectSearchDropdown>
            </div>
          </div>
          <div className="flex flex-col-reverse gap-y-6 lg:flex-row items-center">
            {/* Country-city select */}
            {isNotTurkey ? (
              <div className="flex w-full lg:w-1/2 lg:basis-1/2 px-1">
                <SelectSearchDropdown
                  className="w-full"
                  required
                  mode={selectedCountry ? "dirty" : "empty"}
                  error={errors.country?.message ? true : false}
                >
                  <SelectSearchDropdown.Label>
                    {isEnglish ? "Country Select" : "Ülke Seçiniz"}
                  </SelectSearchDropdown.Label>
                  <SelectSearchDropdown.Trigger
                    onClick={async () => {
                      if (searchCountry.length == 0 && countries.length == 0) {
                        setIsLoadingData(true);
                        const res = await getCountries({});
                        setCountries(res);
                        setIsLoadingData(false);
                      }
                    }}
                  >
                    <SelectSearchDropdown.Value>
                      {selectedCountry
                        ? `${
                            isEnglish
                              ? `${selectedCountry.name_en}`
                              : `${selectedCountry.name_tr}`
                          }`
                        : `${isEnglish ? "Select" : "Seçiniz"}`}
                    </SelectSearchDropdown.Value>
                    <SelectSearchDropdown.Input
                      placeholder={`${isEnglish ? "Search" : "Arama Yap"}`}
                      value={searchCountry || ""}
                      onChange={(e) => setSearchCountry(e.target.value)}
                    />
                  </SelectSearchDropdown.Trigger>
                  <SelectSearchDropdown.Items>
                    {countries.map((country) => {
                      return (
                        <SelectSearchDropdown.Item
                          key={`countySelect${country.id}`}
                          onClick={() => setSelectedCountry(country)}
                        >
                          {isEnglish ? country.name_en : country.name_tr}
                        </SelectSearchDropdown.Item>
                      );
                    })}
                  </SelectSearchDropdown.Items>
                </SelectSearchDropdown>
              </div>
            ) : (
              <div className="flex w-full lg:w-1/2 lg:basis-1/2 px-2">
                <SelectSearchDropdown
                  className="w-full"
                  required
                  mode={selectedCity ? "dirty" : "empty"}
                  error={errors.city?.message ? true : false}
                >
                  <SelectSearchDropdown.Label>
                    {isEnglish ? "City Select" : "Şehir Seçiniz"}
                  </SelectSearchDropdown.Label>
                  <SelectSearchDropdown.Trigger
                    onClick={async () => {
                      if (searchCity.trim().length == 0 && cities.length == 0) {
                        setIsLoadingData(true);
                        const res = await getCities({});
                        setCities(res);
                        setIsLoadingData(false);
                      }
                    }}
                  >
                    <SelectSearchDropdown.Value>
                      {selectedCity
                        ? `${
                            isEnglish
                              ? `${selectedCity.name}`
                              : `${selectedCity.name}`
                          }`
                        : `${isEnglish ? "Select" : "Seçiniz"}`}
                    </SelectSearchDropdown.Value>
                    <SelectSearchDropdown.Input
                      placeholder={`${isEnglish ? "Search" : "Arama Yap"}`}
                      value={searchCity || ""}
                      onChange={(e) => setSearchCity(e.target.value)}
                    />
                  </SelectSearchDropdown.Trigger>
                  <SelectSearchDropdown.Items>
                    {isLoadingData && (
                      <SelectSearchDropdown.Item>
                        <AiOutlineLoading3Quarters className="mx-auto animate-spin" />
                      </SelectSearchDropdown.Item>
                    )}
                    {cities.map((city) => {
                      return (
                        <SelectSearchDropdown.Item
                          key={`citySelect${city.id}`}
                          onClick={() => setSelectedCity(city)}
                        >
                          {isEnglish ? city.name_en ?? city.name : city.name}
                        </SelectSearchDropdown.Item>
                      );
                    })}
                  </SelectSearchDropdown.Items>
                </SelectSearchDropdown>
              </div>
            )}

            {/* Working type select */}
            <div className="flex w-full lg:w-1/2 lg:basis-1/2 px-2">
              <SelectDropdown
                className="w-full"
                required
                mode={selectedWorkingType ? "dirty" : "empty"}
                error={errors.working_type?.message ? true : false}
              >
                <SelectDropdown.Label>
                  {isEnglish ? "Employment Type" : "Çalışma Şekli"}
                </SelectDropdown.Label>
                <SelectDropdown.Trigger
                  onClick={async () => {
                    if (workingTypes.length == 0) {
                      const res = await getWorkingTypes({ limit: 20 });
                      setWorkingTypes(res);
                    }
                  }}
                >
                  {selectedWorkingType
                    ? `${
                        isEnglish
                          ? `${selectedWorkingType.title_en}`
                          : `${selectedWorkingType.title}`
                      }`
                    : `${isEnglish ? "Select" : "Seçiniz"}`}
                </SelectDropdown.Trigger>
                <SelectDropdown.Items>
                  {workingTypes.map((wt) => {
                    return (
                      <SelectDropdown.Item
                        key={`wtSelect${wt.id}`}
                        onClick={() => setSelectedWorkingType(wt)}
                      >
                        {isEnglish ? wt.title_en : wt.title}
                      </SelectDropdown.Item>
                    );
                  })}
                </SelectDropdown.Items>
              </SelectDropdown>
            </div>
          </div>

          {/* Not turkey checkbox */}
          <div className="px-1">
            <button
              type="button"
              className="flex flex-row items-center gap-2 text-sm text-gray-600"
              onClick={() => setIsNotTurkey((prev) => !prev)}
            >
              <div
                className={`flex w-5 h-5 aspect-square border border-gray-300 items-center justify-center ${
                  isNotTurkey ? "bg-mains" : "bg-white"
                } transition-all`}
              >
                {isNotTurkey && (
                  <IoCheckmark className="flex text-white text-base" />
                )}
              </div>
              {isEnglish ? "Abroad" : "Yurt Dışı"}
            </button>
          </div>

          <div className="flex flex-col gap-y-6 lg:flex-row items-end">
            {/* Starting date select */}
            <div className="flex flex-col gap-1 w-full">
              <SelectDropdown.Label>
                {isEnglish ? "Start Date" : "İşe Başlama Tarihi"}{" "}
                <b className="text-red-600">*</b>
              </SelectDropdown.Label>
              <div className="flex w-full lg:w-auto lg:basis-1/2 items-end px-0 lg:px-0 lg:p-0">
                {/* Month select */}
                <div className="flex basis-1/2 px-1">
                  <SelectDropdown
                    className="w-full"
                    required
                    mode={selectedStartMonth ? "dirty" : "empty"}
                    error={errors.start_date_month?.message ? true : false}
                  >
                    <SelectDropdown.Trigger>
                      {selectedStartMonth
                        ? `${
                            isEnglish
                              ? `${selectedStartMonth.name_en}`
                              : `${selectedStartMonth.name}`
                          }`
                        : `${isEnglish ? "Month" : "Ay"}`}
                    </SelectDropdown.Trigger>
                    <SelectDropdown.Items>
                      {months.map((month) => {
                        return (
                          <SelectDropdown.Item
                            key={`startMonth${month.id}`}
                            onClick={() => {
                              const res = dateCheck({
                                startMonth: month.id,
                                startYear: selectedStartYear ?? undefined,
                                endMonth: selectedEndMonth?.id,
                                endYear: selectedEndYear ?? undefined,
                              });

                              if (res) {
                                setSelectedStartMonth(month);
                              }
                            }}
                          >
                            {isEnglish ? month.name_en : month.name}
                          </SelectDropdown.Item>
                        );
                      })}
                    </SelectDropdown.Items>
                  </SelectDropdown>
                </div>

                {/* Year select */}
                <div className="flex basis-1/2 px-1">
                  <SelectDropdown
                    className="w-full"
                    mode={selectedStartYear ? "dirty" : "empty"}
                    error={errors.start_date_year?.message ? true : false}
                  >
                    <SelectDropdown.Trigger>
                      {selectedStartYear
                        ? selectedStartYear
                        : `${isEnglish ? "Year" : "Yıl"}`}
                    </SelectDropdown.Trigger>
                    <SelectDropdown.Items>
                      {years
                        .sort((a, b) => b - a)
                        .map((year) => {
                          return (
                            <SelectDropdown.Item
                              key={`syear${year}`}
                              onClick={() => {
                                const res = dateCheck({
                                  startMonth: selectedStartMonth?.id,
                                  startYear: year,
                                  endMonth: selectedEndMonth?.id,
                                  endYear: selectedEndYear ?? undefined,
                                });

                                if (res) {
                                  setSelectedStartYear(year);
                                }
                              }}
                            >
                              {year}
                            </SelectDropdown.Item>
                          );
                        })}
                    </SelectDropdown.Items>
                  </SelectDropdown>
                </div>
              </div>
            </div>

            {/* Space for desktop */}
            {isStillWorking && <div className="hidden lg:flex w-full"></div>}
            {/* Ending date select */}
            {!isStillWorking && (
              <div className="flex flex-col gap-1 w-full">
                <SelectDropdown.Label className="px-1">
                  {isEnglish ? "End Date" : "İşten Ayrılma Tarihi"}{" "}
                  <b className="text-red-600">*</b>
                </SelectDropdown.Label>
                <div className="flex w-full lg:w-auto lg:basis-1/2 items-end px-0 lg:px-0 lg:p-0">
                  {/* Month select */}
                  <div className="flex basis-1/2 px-1">
                    <SelectDropdown
                      className="w-full"
                      required
                      mode={selectedEndMonth ? "dirty" : "empty"}
                      error={errors.end_date_month?.message ? true : false}
                    >
                      {/* <SelectDropdown.Label>
                                İşten Ayrılma Tarihi
                            </SelectDropdown.Label> */}
                      <SelectDropdown.Trigger>
                        {selectedEndMonth
                          ? `${
                              isEnglish
                                ? `${selectedEndMonth.name_en}`
                                : `${selectedEndMonth.name}`
                            }`
                          : `${isEnglish ? "Month" : "Ay"}`}
                      </SelectDropdown.Trigger>
                      <SelectDropdown.Items>
                        {months.map((month) => {
                          return (
                            <SelectDropdown.Item
                              key={`endMonth${month.id}`}
                              onClick={() => {
                                const res = dateCheck({
                                  startMonth: selectedStartMonth?.id,
                                  startYear: selectedStartYear ?? undefined,
                                  endMonth: month.id,
                                  endYear: selectedEndYear ?? undefined,
                                });

                                if (res) {
                                  setSelectedEndMonth(month);
                                }
                              }}
                            >
                              {isEnglish ? month.name_en : month.name}
                            </SelectDropdown.Item>
                          );
                        })}
                      </SelectDropdown.Items>
                    </SelectDropdown>
                  </div>

                  {/* Year select */}
                  <div className="flex basis-1/2 px-1">
                    <SelectDropdown
                      className="w-full"
                      mode={selectedEndYear ? "dirty" : "empty"}
                      error={errors.end_date_year?.message ? true : false}
                    >
                      <SelectDropdown.Trigger>
                        {selectedEndYear
                          ? selectedEndYear
                          : `${isEnglish ? "Year" : "Yıl"}`}
                      </SelectDropdown.Trigger>
                      <SelectDropdown.Items>
                        {years
                          .sort((a, b) => b - a)
                          .map((year) => {
                            return (
                              <SelectDropdown.Item
                                key={`eyear${year}`}
                                onClick={() => {
                                  const res = dateCheck({
                                    startMonth: selectedStartMonth?.id,
                                    startYear: selectedStartYear ?? undefined,
                                    endMonth: selectedEndMonth?.id,
                                    endYear: year,
                                  });

                                  if (res) {
                                    setSelectedEndYear(year);
                                  }
                                }}
                              >
                                {year}
                              </SelectDropdown.Item>
                            );
                          })}
                      </SelectDropdown.Items>
                    </SelectDropdown>
                  </div>
                </div>
              </div>
            )}
          </div>
          {errors.start_date_month &&
            errors.start_date_month?.message != "İşe Başlama Ayı" && (
              <label className="text-xs font-poppins text-red-600">
                {errors.start_date_month?.message}
              </label>
            )}
          {/* Still working checkbox */}
          <div className="px-1">
            <button
              type="button"
              className="flex flex-row items-center gap-2 text-sm text-gray-600"
              onClick={() => setIsStillWorking((prev) => !prev)}
            >
              <div
                className={`flex w-5 h-5 aspect-square border border-gray-300 items-center justify-center ${
                  isStillWorking ? "bg-mains" : "bg-white"
                } transition-all`}
              >
                {isStillWorking && (
                  <IoCheckmark className="flex text-white text-base" />
                )}
              </div>
              {isEnglish ? "Currently Employed" : "Halen Çalışıyorum"}
            </button>
          </div>

          {/* Leaving reason */}
          {!isStillWorking && (
            <Textarea
              className="w-full px-1"
              mode={leaving_reason.length == 0 ? "empty" : "dirty"}
            >
              <Textarea.Label>
                {isEnglish ? "Reason fo leaving" : "Ayrılma Nedeni"}
              </Textarea.Label>
              <Textarea.Field
                {...register("leaving_reason")}
                value={leaving_reason || ""}
                onChange={(e) => {
                  setValue("leaving_reason", e.target.value);
                  clearErrors("leaving_reason");
                }}
              />
            </Textarea>
          )}
        </div>

        {/* Right section */}
        <div className="flex flex-col basis-1/3 px-2">
          <Textarea
            required
            className="h-full px-1"
            mode={work_info.length == 0 ? "empty" : "dirty"}
            error={errors.work_info?.message ? true : false}
          >
            <Textarea.Label>
              {isEnglish ? "Job Description" : "İş Tanımı"}
            </Textarea.Label>
            <Textarea.Field
              className="h-full min-h-[200px]"
              {...register("work_info", {
                required: "İş Tanımı",
                maxLength: {
                  value: 3000,
                  message: "En fazla 3000 karakter girebilirsiniz",
                },
              })}
              value={work_info}
              onChange={(e) => {
                setValue("work_info", e.target.value);
                clearErrors("work_info");
              }}
            />
          </Textarea>
          <label
            className={`text-xs p-1 font-poppins ${
              3000 - work_info.length < 0 ? "text-red-600" : "text-gray-600"
            }`}
          >
            {3000 - work_info.length}
          </label>
        </div>
      </div>

      {/* Buttons section */}
      {!jobExperience ? (
        <div className="flex flex-row gap-2 ml-auto items-center justify-between mt-6">
          <Button
            type="button"
            className="flex"
            variant="secondary"
            size="sm"
            onClick={() => {
              onCancel && onCancel();
              if (setIsModalOpen) {
                setIsModalOpen(false);
              }
            }}
          >
            {isEnglish ? "Cancel" : "Vazgeç"}
          </Button>
          <Button type="submit" size="sm">
            {isEnglish ? "Save" : "Kaydet"}
          </Button>
        </div>
      ) : (
        <div className="flex flex-row items-center justify-between mt-6 p-2">
          <div className="flex ml-auto flex-row items-center gap-2">
            <Button
              type="button"
              variant="secondary"
              size="sm"
              onClick={() => {
                onCancel && onCancel();
                if (setIsEditOpen) {
                  setIsEditOpen(false);
                }
              }}
            >
              {isEnglish ? "Cancel" : "Vazgeç"}
            </Button>
            <Button type="submit" size="sm">
              {isEnglish ? "Save" : "Kaydet"}
            </Button>
          </div>
        </div>
      )}
    </form>
  );

  async function addJobExperience({ cv_id, ...others }: payloadDataType) {
    if (isLoading) return;

    setIsLoading(true);

    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/exp/create`,
        type: "post",
        payload: {
          cv_id: cvId,
          job_experiences: {
            ...others,
          },
        },
      });

      if (response && response.status == 200) {
        onSuccess && onSuccess();
        if (getAllJobExperiences) {
          getAllJobExperiences();
        }

        /* Clear form fields */
        setValue("company_name", "");
        setValue("leaving_reason", "");
        setValue("work_info", "");
        setSelectedPosition(null);
        setSelectedPositionLevel(null);
        setSelectedSector(null);
        setSelectedSection(null);
        setSelectedCity(null);
        setSelectedCountry(null);
        setSelectedWorkingType(null);
        setIsNotTurkey(false);
        setIsStillWorking(false);
        setSelectedStartMonth(null);
        setSelectedStartYear(null);
        setSelectedEndMonth(null);
        setSelectedEndYear(null);

        if (setIsPageOpen) {
          setIsPageOpen(false);
        }
        if (setIsModalOpen) {
          setIsModalOpen(false);
        }

        showCvScore && showCvScore();
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "İş deneyimi eklenirken bir hata meydana geldi.",
        });
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "İş deneyimi eklenirken beklenmedik bir hata meydana geldi.",
      });
    }

    setIsLoading(false);
  }

  async function updateJobExperience({ cv_id, ...others }: payloadDataType) {
    if (isLoading) return;

    setIsLoading(true);

    if (jobExperience) {
      try {
        const response = await axios.userAuthRequest({
          endpoint: `user/profilim/cv/exp/update`,
          type: "put",
          payload: {
            cv_id: cvId,
            job_experiences: {
              id: jobExperience.id,
              ...others,
            },
          },
        });

        if (response && response.data) {
          if (getAllJobExperiences) {
            getAllJobExperiences();
          }

          if (setIsEditOpen) {
            setIsEditOpen(false);
          }
          showCvScore && showCvScore();
        } else {
          Swal.fire({
            icon: "error",
            title: "Hata",
            text: "İş deneyimi güncellenirken bir hata meydana geldi.",
          });
        }
      } catch (error) {
        console.log(`Error: ${error}`);
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "İş deneyimi güncellenirken beklenmedik bir hata meydana geldi.",
        });
      }
    }

    setIsLoading(false);
  }
}
