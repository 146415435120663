import * as React from "react";
import SiteMapPageContainer from '../../containers/sitemap-page-container'
function SiteMapPage(): React.ReactElement {
  return (
   <>
   <SiteMapPageContainer/>
   </>
  );
}

export { SiteMapPage };