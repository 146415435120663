import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IconChevronDown } from "@tabler/icons-react";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import { useHamburgerMenu } from "../../../../../../hooks/useHamburgerMenu";
import { CookieName } from "../../../../../../constants/enums";
import { useCookie } from "../../../../../../hooks/useCookie";

interface IHamburgerList {
  title: string;
  icon?: React.ReactNode;
  isMenu: boolean;
  menu?: React.ReactNode;
  link?: string;
  onClick?: () => void;
  children?: any;
  isOpen?: boolean;
}

const HamburgerList: React.FC<IHamburgerList> = ({
  title,
  icon,
  isMenu,
  menu,
  link,
  onClick,
  children,
  isOpen,
}) => {
  const [openMenu, setOpenMenu] = useState(isOpen);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const { getCookie } = useCookie();

  const companyUserTokenCookie = getCookie(CookieName.CompanyUserToken);
  const companyUserToken = companyUserTokenCookie ? companyUserTokenCookie : "";

  const userTokenCookie = getCookie(CookieName.UserToken);
  const userToken = userTokenCookie ? userTokenCookie : "";

  const { isHamburger, setIsHamburger, hamburgerRef } = useHamburgerMenu();

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
    if (!children) {
      onClick?.();
    }
  };

  const toggleSubMenu = () => {
    setOpenSubMenu(!openSubMenu);
  };

  const handleCloseSideMenu = () => {
    if (companyUserToken || userToken) {
      setIsHamburger(false);
    }
  };
  
  const Content = (
    <>
      <div className="flex items-center w-full">
        {icon}
        <div
          onClick={toggleSubMenu}
          className="flex lg:flex-col flex-row justify-between ml-2 text-sm text-white w-full"
        >
          <span onClick={() => handleCloseSideMenu()}>{title}</span>
          {children && (
            <span className="ml-2">
              {openSubMenu ? (
                <MdOutlineKeyboardArrowUp size={20} />
              ) : (
                <IconChevronDown size={20} />
              )}
            </span>
          )}
        </div>
      </div>
      {openSubMenu && children && (
        <div className="flex flex-col w-full ml-3 mt-1 overflow-hidden transition-all">
          {children.map((item: any, index: number) => (
            <Link
              to={item.path}
              key={index}
              className="my-1 text-sm text-white"
            >
              {item.title}
            </Link>
          ))}
        </div>
      )}
      {isMenu && (
        <IconChevronDown
          className={`text-white transition-all ${
            openMenu ? "rotate-180" : ""
          }`}
          size={16}
        />
      )}
    </>
  );

  return (
    <>
      {link ? (
        <Link
          to={link}
          className="flex flex-col items-center justify-between w-full py-2 cursor-pointer"
          onClick={toggleMenu}
        >
          {Content}
        </Link>
      ) : (
        <div
          className="flex flex-row lg:flex-col items-center justify-between w-full py-2 cursor-pointer"
          onClick={toggleMenu}
        >
          {Content}
        </div>
      )}
      {isMenu && menu && openMenu && (
        <div className="flex flex-col w-full ml-3 mt-1  transition-all">
          {menu}
        </div>
      )}
    </>
  );
};

export default HamburgerList;
