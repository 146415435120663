import { ReactNode } from "react";
import cn from "classnames";

interface TabListProps {
  children: ReactNode;
  className?: string;
}

const TabList: React.FC<TabListProps> = ({ children, className }) => (
  <div
    className={cn(
      "flex items-center bg-gray-200 w-full p-1 rounded-md",
      className
    )}
  >
    {children}
  </div>
);

export default TabList;
