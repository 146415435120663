import React, { useState, useEffect } from 'react';
import { IoIosClose, IoMdSearch } from 'react-icons/io';

interface ExamplePopupPopupProps {
  onClose: () => void;
}

const ExamplePopup: React.FC<ExamplePopupPopupProps> = ({ onClose }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Trigger the animation to start a short moment after the component mounts
    const timeout = setTimeout(() => setIsVisible(true), 10); // Start the animation
    return () => clearTimeout(timeout);
  }, []);

  const handleCloseClick = () => {
    // Start the closing animation
    setIsVisible(false);

    // Wait for the animation to complete before calling the onClose prop
    setTimeout(() => {
      onClose();
    }, 500); // This duration should match your transition duration
  };

  return (
<div className="fixed inset-0 z-[100] flex items-center justify-center overflow-y-auto">
  <div className="absolute inset-0 bg-black opacity-50" onClick={handleCloseClick}></div>
  <div className={`bg-white p-4 sm:p-6 rounded-lg shadow-lg max-h-[calc(100vh-20px)] overflow-auto relative max-w-screen-md w-full mx-4 sm:mx-6 md:mx-auto transition-all duration-500 ease-in-out transform ${isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-10'}`}>
    <div className="flex justify-between items-center">
      <h2 className="text-lg sm:text-xl font-semibold text-[#2253af]">ÖRNEK İLAN METNİ</h2>
      <button type='button' title='close-button' onClick={handleCloseClick} className="text-gray-600 hover:text-gray-800 p-2">
        <IoIosClose size={24} />
      </button>
    </div>
    <div className="mt-6">
      <p className="text-sm sm:text-base text-gray-700">
            ABC Şirketi olarak, dinamik bir pazarlama ekibimize katılacak yetenekli bir Yaratıcı Pazarlama Uzmanı arıyoruz. Eğer yaratıcı fikirler üretebiliyor, proaktif bir şekilde çalışabiliyor ve pazarlama dünyasında kendinizi geliştirmek istiyorsanız, sizi aramızda görmek isteriz!
          </p>
          <div className="mt-4">
            <h3 className="text-lg font-semibold text-[#2253af]">Aranan Nitelikler:</h3>
            <ul className="list-disc list-inside mt-2 text-gray-700">
              <li>En az 3 yıl pazarlama deneyimi</li>
              <li>Yaratıcı pazarlama stratejileri geliştirebilen</li>
              <li>Sosyal medya yönetimi konusunda deneyimli</li>
              <li>İyi düzeyde yazılı ve sözlü iletişim becerilerine sahip</li>
              <li>Ekip çalışmasına uyumlu ve liderlik yeteneklerine sahip</li>
              <li>Analitik düşünme ve problem çözme becerileri gelişmiş</li>
              <li>Eğitim alanında lisans veya denk bir derece</li>
            </ul>
          </div>
          <div className="mt-4">
            <h3 className="text-lg font-semibold text-[#2253af]">İş Tanımı:</h3>
            <ul className="list-disc list-inside mt-2 text-gray-700">
              <li>Şirketin pazarlama stratejilerini oluşturmak ve uygulamak</li>
              <li>Yaratıcı içerik ve kampanya geliştirmek</li>
              <li>Sosyal medya platformlarını etkin bir şekilde yönetmek</li>
              <li>Pazarlama performansını izlemek ve raporlamak</li>
              <li>Rekabet analizi yaparak pazarlama stratejilerini güncellemek</li>
            </ul>
          </div>
          <div className="mt-4">
            <h3 className="text-lg font-semibold text-[#2253af]">Başvuru İçin:</h3>
            <p className="text-gray-700">
              [e-posta adresi] veya [telefon numarası] ile bize ulaşarak başvuru yapabilirsiniz
            </p>
            <p className="text-gray-700">Not: Sadece uygun adaylarla iletişime geçilecektir.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamplePopup;
