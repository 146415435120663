import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CompanyRegisters from "./company/register/index";
import ProfileRegisters from "./profile/register/index";
import ProfileLogins from "./profile/login/index";
import { CompanyLogins } from "./company/login";

const CompanyLogin: React.FC = () => {
  return (
    <div className="">
      <div className="w-full">
        <Routes>
          <Route path="/" element={<CompanyLogins />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </div>
  );
};

const CompanyRegister: React.FC = () => {
  return (
    <div className="">
      <div className="w-full">
        <Routes>
          <Route path="/" element={<CompanyRegisters />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </div>
  );
};

const ProfileRegister: React.FC = () => {
  return (
    <div className="">
      <div className="w-full">
        <Routes>
          <Route path="/" element={<ProfileRegisters />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </div>
  );
};

const ProfileLogin: React.FC = () => {
  return (
    <div className="">
      <div className="w-full">
        <Routes>
          <Route path="/" element={<ProfileLogins />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </div>
  );
};

export { ProfileLogin, ProfileRegister, CompanyLogin, CompanyRegister };
