import Axios from "../services/axios";
import { cdnSectionType } from "../types";

type getUniversityBranchesPropsType = {
  search?: string;
  limit?: number;
};

const axios = new Axios();

export async function getSectionDictionaries({
  limit,
  search,

}: getUniversityBranchesPropsType): Promise<cdnSectionType[]> {
  try {
    const response = await axios.request({
      endpoint: `cdn/get-section-dictionaries?
            ${search ? `search=${search}&` : ""}
            ${limit ? `limit=${limit}&` : ""}
            `,
      type: "get",
    });

    if (response && response.data && response.data.section_dictionaries) {
      return JSON.parse(JSON.stringify(response.data.section_dictionaries));
    } else {
      return [];
    }
  } catch (error) {
    console.log(`University branches fetch failed. Error: ${error}`);
    return [];
  }
}
