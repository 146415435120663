import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  IconSearch,
  IconList,
  IconMail,
  IconUser,
  IconMenu2,
  IconSquarePlus,
  IconBuilding,
  IconFileText,
} from "@tabler/icons-react";
import { Row, Col } from "antd";
import Cookies from "js-cookie";

import "./MobileFooter.scss";

import { useCookie } from "../../../../../hooks/useCookie";
import { CookieName } from "../../../../../constants/enums";
import { HamburgerContent } from "../../../../layout/header/components/hamburger/hamburger-content/index";

import { useHamburgerMenu } from "../../../../../hooks/useHamburgerMenu";

interface MobileFooterProps {
  handleActionMobileMenu: () => void;
}

interface MenuItem {
  name: string;
  link?: string;
  icon: JSX.Element;
}

const MobileFooter: React.FC<MobileFooterProps> = ({
  handleActionMobileMenu,
}) => {
  const userToken = Cookies.get("userToken");
  const companyToken = Cookies.get("companyUserToken");

  const [activeMenu, setActiveMenu] = useState<string>("");

  let footerMenu: MenuItem[] = [];

  if (userToken) {
    footerMenu = [
      { name: "İş Ara", link: "/is-ilanlari", icon: <IconSearch stroke={2} /> },
      {
        name: "Başvurularım",
        link: "/profilim/başvurular",
        icon: <IconList stroke={2} />,
      },
      {
        name: "Mesajlar",
        link: "/profilim/mesajlar",
        icon: <IconMail stroke={2} />,
      },
      { name: "Profilim", link: "/profilim", icon: <IconUser stroke={2} /> },
      { name: "Menü", icon: <IconMenu2 stroke={2} /> },
    ];
  } else if (companyToken) {
    footerMenu = [
      {
        name: "İlanlarım",
        link: "/isveren",
        icon: <IconFileText stroke={2} />,
      },
      {
        name: "İlan Yayınla",
        link: "/isveren/ilanlar/olustur/ilan-bilgileri",
        icon: <IconSquarePlus stroke={2} />,
      },
      {
        name: "Mesajlar",
        link: "/isveren/mesajlar",
        icon: <IconMail stroke={2} />,
      },
      { name: "Menü", icon: <IconMenu2 stroke={2} /> },
    ];
  } else {
    footerMenu = [
      {
        name: "İş Ara",
        link: "/is-ilanlari?total=413&last_page=12",
        icon: <IconSearch stroke={2} />,
      },
      {
        name: "İş Arayan Giriş",
        link: "/profilim/giris-yap",
        icon: <IconUser stroke={2} />,
      },
      {
        name: "İlan Yayınla",
        link: "/isveren/kayit-ol",
        icon: <IconSquarePlus stroke={2} />,
      },
      {
        name: "Firma Giriş",
        link: "/isveren/giris-yap",
        icon: <IconBuilding stroke={2} />,
      },
    ];
  }

  const { getCookie } = useCookie();
  const companyUserCookie = getCookie(CookieName.CompanyUserData);
  const companyUser = companyUserCookie ? JSON.parse(companyUserCookie) : null;

  const userCookie = getCookie(CookieName.UserData);
  const user = userCookie ? JSON.parse(userCookie) : null;

  const { isHamburger, setIsHamburger, hamburgerRef } = useHamburgerMenu();

  const handleMenuClick = (menuName: string) => {
    setActiveMenu(menuName);
    if (menuName === "Menü") {
      setIsHamburger(!isHamburger);
    }
  };


  console.log(footerMenu)

  return (
    <>
      <div className="mobile-footer md:hidden block">
        <Row gutter={[24, 20]} justify="space-around">
          {footerMenu.map((menu, index) => (
            <Col key={index}>
              <Link to={menu.link ? menu.link : "#"}>
                <div
                  className={`mobile-footer__content ${
                    activeMenu === menu.name ? "active" : ""
                  }`}
                  onClick={() => handleMenuClick(menu.name)}
                >
                  <div ref={hamburgerRef}>
                    <span>{menu.icon}</span>
                  </div>
                  <span className="font-poppins text-[10px]">{menu.name}</span>
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
      <HamburgerContent
        isLogined={!!companyUser || !!user}
        isHamburger={isHamburger}
        companyUser={companyUser}
        userType={companyUser ? "company" : "user"}
        setIsHamburger={setIsHamburger}
      />
    </>
  );
};

MobileFooter.propTypes = {
  handleActionMobileMenu: PropTypes.func.isRequired,
};

export default MobileFooter;
