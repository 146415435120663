import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps"
import { Dispatch, SetStateAction } from "react"
import { IoClose, IoSearch } from "react-icons/io5"
import { googleMapsApiKey,  } from "../../../../../../constants/enums"
import Button from "../../../../../form-components/button"
import Swal from "sweetalert2"
import { MapLocation } from "../.."






type mapLocationType = {
    lat: number,
    longitude: number
}



export default function MapLocationSelect({
    isPopupOpen,
    setIsPopupOpen,
    selectedMapLocation,
    setSelectedMapLocation,
    updateMapLocation
}: {
    isPopupOpen: boolean,
    setIsPopupOpen: Dispatch<SetStateAction<boolean>>,
    selectedMapLocation: mapLocationType,
    setSelectedMapLocation: Dispatch<SetStateAction<mapLocationType>>,
    updateMapLocation: (location: MapLocation) => void
}) {
    return(
        <div className="fixed w-screen h-screen max-w-screen max-h-screen flex items-center justify-center bg-black top-0 left-0 bg-opacity-20 z-40">
            <div className="flex flex-col gap-2 bg-white max-w-[800px] w-full max-h-[calc(100vh-20px)] overflow-auto relative rounded-md border border-gray-400 p-4 z-[1]">
                <div className="flex flex-row items-center gap-2 justify-between">
                    <label className="text-xl font-semibold text-bold">Haritadan bir konum seçin</label>
                    <button type="button" onClick={() => setIsPopupOpen(false)}>
                        <IoClose className="text-xl"/>
                    </button>
                </div>
                <hr/>
                <div className="flex flex-row items-center p-2 bg-white border border-gray-400 rounded-md ">
                    <button type="button" className="text-xl text-gray-400 mx-2">
                        <IoSearch/>
                    </button>
                    <input
                    className="outline-none w-full"
                    />
                    
                </div>
                <APIProvider apiKey={googleMapsApiKey}>
                <Map
                style={{
                    width: '100%',
                    height: '300px'
                }}
                defaultZoom={5}
                defaultCenter={{ lat: selectedMapLocation?.lat, lng: selectedMapLocation?.longitude}}
                gestureHandling={'greedy'}
                disableDefaultUI={false}
                onClick={((e) => {
                    /* updateMapLocation({
                    lat: e.detail.latLng?.lat || 0,
                    longitude: e.detail.latLng?.lng || 0
                    }) */
                    setSelectedMapLocation({
                        lat: e.detail.latLng?.lat || 39,
                        longitude: e.detail.latLng?.lng || 33
                    })
                })}
                >
                    {selectedMapLocation.lat != 38 && selectedMapLocation.longitude != 33 && (
                        <Marker position={{ lat: selectedMapLocation.lat, lng: selectedMapLocation.longitude}}/>
                    )}
                </Map>
                </APIProvider>
                <Button type="button" className="ml-auto" size="sm" onClick={() => {
                    if (selectedMapLocation.lat == 38 && selectedMapLocation.longitude == 33) {
                        Swal.fire({
                            title: 'Uyarı',
                            text: 'Lütfen kaydetmeden önce bir konum işaretleyiniz.',
                            icon: 'error',
                            confirmButtonText: 'Tamam'
                        })
                        return
                    }
                    handleLocationUpdate({lat: selectedMapLocation.lat, longitude: selectedMapLocation.longitude})
                    setIsPopupOpen(false)
                }}>
                    Kaydet
                </Button>
            </div>
        </div>
    )

    function handleLocationUpdate(location: MapLocation) {
        updateMapLocation(location);
    }

}