import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PersonelInformation from './personel-information';
import JobPrefences from './job-prefences';



const Body: React.FC = () => {
  return (
       <div className='flex h-auto  gap-y-3'>
        <div className='w-full'>
         <Routes>
           <Route path="/hosgeldin" element={<PersonelInformation />} />
           <Route path="/hosgeldin/istercihleri" element={<JobPrefences/>} />
           <Route path="/*" element={<Navigate to="hosgeldin" />} />
         </Routes>
        </div>
       </div>
  );
};

export default Body;