import {
Dispatch,
HTMLAttributes,
SetStateAction,
createContext,
useState,
} from "react";
import classNames from "classnames";
import TooltipTrigger  from "./Trigger";
import TooltipContent from "./Content";
  
// Component Type
type tooltipPropsType = HTMLAttributes<HTMLDivElement> & {
visible?: boolean;
setVisible?: Dispatch<SetStateAction<boolean>>;
content: string;
};

// Context Type
export type tooltipContextType = {
visible: boolean;
setVisible: Dispatch<SetStateAction<boolean>>;
content: string;
};

// Context Provider
export const TooltipContext = createContext<tooltipContextType>({
visible: false,
setVisible: () => {},
content: "",
});

export function Tooltip({
className,
visible: initialVisible,
setVisible: initialSetVisible,
children,
content,
...props
}: tooltipPropsType) {
const [visible, setVisible] = useState<boolean>(initialVisible || false);

const cn = classNames({
    'relative': true,
});

const value: tooltipContextType = {
    visible: initialVisible != undefined ? initialVisible : visible,
    setVisible: initialSetVisible != undefined ? initialSetVisible : setVisible,
    content: content,
};

return (
    <TooltipContext.Provider value={value}>
    <div className={`${cn} ${className}`} {...props}>
        {children}
    </div>
    </TooltipContext.Provider>
);
}

Tooltip.Trigger = TooltipTrigger;
Tooltip.Content = TooltipContent;