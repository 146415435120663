import { Route, Routes } from 'react-router-dom';
import Main from './main';










export default function InterviewsPageContainer() {
    return(
        <div className="">
            <div className="w-full">
                <Routes>
                    <Route path="/" element={<Main/>} />
                </Routes>
            </div>
        </div>
    )
}