import { ReactNode, useContext } from "react";
import cn from "classnames";
import { TabContext } from "./context";

interface TabPanelProps {
  children: ReactNode;
  id: string;
  className?: string;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, id, className }) => {
  const context = useContext(TabContext);
  if (!context) throw new Error("TabPanel must be used within a TabContainer");
  const { activeTab } = context;

  if (activeTab !== id) return null;

  return <div className={cn(className)}>{children}</div>;
};

export default TabPanel;