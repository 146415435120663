import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { addOrUpdateQueryParam } from '../../../../../../../hooks/useAddOrUpdateQueryParam';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

const Pagination: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [totalPage, setTotalPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const page = Number(searchParams.get('page')) || 1;
    const lastPage = Number(searchParams.get('last_page')) || 0;

    setCurrentPage(page);
    setTotalPage(lastPage);
  }, [location.search]);

  const changePageNumber = (pageNumber: number) => {
    const searchPath = addOrUpdateQueryParam(location, [
      { key: 'page', value: pageNumber.toString() },
    ]);

    navigate('/is-ilanlari' + searchPath);
  };

  const pageNumbers: number[] = [];
  for (let i = 1; i <= totalPage; i++) {
    // Sadece ilk, son ve mevcut sayfanın etrafındaki sayfaları göster
    if (
      i === 1 ||
      i === totalPage ||
      (i >= currentPage - 2 && i <= currentPage + 2)
    ) {
      pageNumbers.push(i);
    }
  }

  return (
    <div className="w-full h-full flex justify-center items-center">
      {/* Önceki sayfa butonu */}

      {/* <div
        onClick={() => {
          currentPage > 1 && changePageNumber(currentPage - 1);
        }}
        className={`h-full aspect-square mx-2 rounded border select-none flex items-center justify-center group transition-all ${
          currentPage > 1
            ? 'bg-white hover:bg-[#1565c0] cursor-pointer'
            : 'bg-gray-100'
        }`}
      >
        <IconChevronLeft
          className={`transition-all ${
            currentPage > 1 ? 'group-hover:text-white' : ''
          }`}
          size={16}
        />
      </div> */}

      {/* Sayfa numaraları */}
      {pageNumbers.map((number, index) => (
        <React.Fragment key={number}>
          {index > 0 && pageNumbers[index - 1] !== number - 1 && (
            <span className="mx-2 h-full aspect-square bg-gray-100 border border-gray-300 rounded flex items-center justify-center font-semibold select-none">
              ...
            </span>
          )}
          <button
            onClick={() => {
              changePageNumber(number);
            }}
            className={`h-full aspect-square mx-2 rounded border flex items-center justify-center group hover:bg-[#1565c0] transition-all ${
              currentPage === number ? 'bg-[#1565c0] text-white' : 'bg-white'
            }`}
          >
            <span className="group-hover:text-white font-semibold">
              {number}
            </span>
          </button>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Pagination;
