import * as React from "react";
import { useEffect, useState } from "react";
import { HeaderCard } from "./header-card/index";
import { StepsPointsCard } from './steps-poins-card/index'
import {AddHint} from "./add-hint"
import AdvertCreateForm from "./advert-create-form/index";
import {GetAdDataProvider} from "../../../store/contextApi/ad-api-context/get-ad-data"

function EditAd(): React.ReactElement {

  const [mobileActivePage, setMobileActivePage] = useState<number>(1)

    useEffect(() => {
      window.scrollTo({ top: 0 })
    }, []);
  return (
    <div className="container mx-auto flex flex-col gap-y-5 mb-20 md:mb-20">
      {/* <HeaderCard /> */}
      <StepsPointsCard
      />
      {/* <AddHint/> */}
      <GetAdDataProvider>
        <AdvertCreateForm
        mobileActivePage={mobileActivePage}
        setMobileActivePage={setMobileActivePage}
        />
      </GetAdDataProvider>
    </div>
  );
}
export { EditAd };
