import React, { createContext, useContext, useState, ReactNode, useCallback } from 'react';

interface AccordionContextType {
  openIndex: number | null;
  toggleOpen: (index: number) => void;
}

const AccordionContext = createContext<AccordionContextType | undefined>(undefined);

export const useAccordion = (): AccordionContextType => {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error('useAccordion must be used within an AccordionProvider');
  }
  return context;
};

interface AccordionProviderProps {
  children: ReactNode;
  initialOpenIndex?: number | null;
}

export const AccordionProvider: React.FC<AccordionProviderProps> = ({ children, initialOpenIndex = null }) => {
  const [openIndex, setOpenIndex] = useState<number | null>(initialOpenIndex);

  const toggleOpen = useCallback((index: number) => {
    setOpenIndex(prevIndex => (prevIndex === index ? null : index));
  }, []);

  return (
    <AccordionContext.Provider value={{ openIndex, toggleOpen }}>
      {children}
    </AccordionContext.Provider>
  );
};
