import { SelectComponent } from "./select-component";
import { FaPlus } from "react-icons/fa";
import { HiOutlineArrowUturnRight } from "react-icons/hi2";
import { useState, useEffect } from "react";
import { IChangeProps, IStatus, ITitle } from "../../types";
import useFetchCdnQuery from "../../../../../hooks/useFetchCdnQuery";
import Axios from "../../../../../services/axios";
import { useCookie } from "../../../../../hooks/useCookie";
import { CookieName } from "../../../../../constants/enums";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const axios = new Axios();

interface IFolder {
  id: number;
  title: string;
  company_folder_cvs_count?: number;
}

function UpdateSection({ checkedData }: { checkedData: number[] }) {
  const { getCookie } = useCookie();
  const companyUserToken = getCookie(CookieName.CompanyUserToken);

  const [folderValue, setFolderValue] = useState<number>(0);
  const [statusValue, setStatusValue] = useState<number>(0);
  const [fetchFolders, setFetchFolders] = useState<IFolder[]>([]);
  const [values, setValues] = useState<IChangeProps>({
    folder: 0,
    status: 0,
    arr: checkedData,
  });
  const [counter, setCounter] = useState<number>(0);

  //fetch folders
  const fetchFolderNames = async () => {
    try {
      const response = await axios.getCvFolders(companyUserToken);

      if (response && response.folders) {
        const res = response.folders;
        res.unshift({
          id: 0,
          title: "Yeni Klasör ekle",
          name: "Yeni Klasör ekle",
        });
        setFetchFolders(res);
      }
    } catch (error) {
      throw new Error("Error fetching folder names: " + error);
    }
  };
  useEffect(() => {
    fetchFolderNames();
  }, [companyUserToken]);
  const folders: ITitle[] = fetchFolders as ITitle[];
  const folderData = folders.map((item: ITitle) => {
    return {
      id: item.id,
      value: item.title,
      name: item.title,
    };
  });

  //fetch statuses
  const statusesQuery = useFetchCdnQuery(
    "statuses",
    "get-company-candidate-statuses",
    "company_candidate_statuses"
  );
  const StatusData = statusesQuery.data
    ? (statusesQuery.data.map((item: any) => {
        return {
          id: item.id,
          value: item.value,
          name: item.name,
        };
      }) as Array<IStatus>)
    : [];

  //set values
  useEffect(() => {
    setValues({
      folder: folderValue,
      status: statusValue,
      arr: checkedData,
    });
  }, [folderValue, statusValue, checkedData]);

  //send values
  useEffect(() => {
    const updateCvStatues = async () => {
      if (counter === 0) return;
      if (folderValue == 0) {
        toast.warning("Klasör seçimi yapmanız gerekiyor");
        return;
      }

      try {
        const response = await axios.updateMultiCvStatus(
          values.status,
          values.folder,
          values.arr,
          companyUserToken
        );

        if (response) {
          toast.success("Adaylar başarıyla klasöre eklendi");
        }
      } catch (error) {
        toast.warning("Aday yada Adaylar bu klasöre daha önce eklenmiş.");
      }
    };

    updateCvStatues();
  }, [counter]);

  const handleClick = () => {
    setCounter(counter + 1);
  };

  const handleFolderChange = (value: number) => {
    setFolderValue(value);
  };

  const handleStatusChange = (value: number) => {
    setStatusValue(value);
  };

  return (
    <div className="flex flex-col md:flex-row items-center gap-3 mx-2 my-5 md:mx-10  text-center">
      <HiOutlineArrowUturnRight className="text-primary200 text-2xl hidden md:block" />

      <SelectComponent
        blue={values.folder !== 0}
        data={folderData}
        label=""
        placeholder="Klasör Seçiniz"
        onChange={(value) => handleFolderChange(value)}
        selectedvalue={values.folder}
        callBack={() => {
          fetchFolderNames();
        }}
      />

      <SelectComponent
        data={StatusData}
        label=""
        placeholder="İncelenmedi"
        blue={true}
        onChange={(value) => handleStatusChange(value)}
        selectedvalue={values.status}
      />

      <button
        className="bg-primary200 text-white px-3 py-2 rounded-md flex items-center flex-1 w-full justify-center gap-2 "
        onClick={handleClick}
      >
        <FaPlus className="inline-block " />
        Seçilileri Klasöre Ekle
      </button>
    </div>
  );
}

export { UpdateSection };
