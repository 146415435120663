import { FilterSection } from "./filter-section";
import { useState, useEffect, createContext, useRef } from "react";
import CandidatePoolError from "../candidate-pool-page/filter-section/components/components/error-boundary";
import { InfoSection } from "./info-section";
import * as React from "react";
import { PaginationData } from "../index";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useCookie } from "../../../hooks/useCookie";
import { CookieName } from "../../../constants/enums";
import Axios from "../../../services/axios";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import { FiAlertCircle } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";
import moment from "moment";

export type CandidatePoolFilterProps = {
  total_work_experience_min?: number;
  total_work_experience_max?: number;
  district?: number;
  cv_update_date_day?: number;
  cv_update_date_month?: number;
  working_types?: number[];
  salary_expectation_min?: number;
  salary_expectation_max?: number;
  sectors?: number[];
  language?: number;
  language_level?: number;
  education_level?: number;
  university_branch?: number;
  high_school_branch?: number;
  university?: number;
  disabled_status?: number;
  disability_types?: number[];
  disability_percent?: number;
  military_status?: number;
  military_postponement_date?: string;
  position?: number;
  filter_type?: number;
  cv_update_date_year?: number;
  driving_licence_status?: number;
  driving_licence_classes?: number[];
  driving_licence_srcs?: number[];
  smoke_status?: number;
  gender?: number;
  sections?: number[];
  min_age?: number;
  max_age?: number;
  marital_status?: number;
  page?: number;
  city?: number;
  now_working?: number;
  status?: string;
  search?: string;
};
interface IFilter {
  id?: number;
  title: string;
  params: { [key: string]: number };
}
interface CandidadeType {
  setNewSelectedValues: React.Dispatch<
    React.SetStateAction<CandidatePoolFilterProps>
  >;
  newSelectedValues: CandidatePoolFilterProps;
  handleFilter: () => void;
  clearFilters: () => void;
  queryParams: string;
  detailFiltersOpen: boolean;
  setDetailFiltersOpen: React.Dispatch<React.SetStateAction<boolean>>;
  scrollRef?: React.MutableRefObject<any>;
  savedSearchFilters: IFilter[];
  fetchSearchItems: () => void;
  pagination: PaginationData | null;
  setPagination: React.Dispatch<React.SetStateAction<PaginationData | null>>;
  showFilterSaveButton: boolean;
  setShowFilterSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
  syncCounter: number;
  setSyncCounter: React.Dispatch<React.SetStateAction<number>>;
  advertStatus: any;
  setAdvertStatus: React.Dispatch<React.SetStateAction<any>>;
  advertStatusData: any[];
  setAdvertStatusData: React.Dispatch<React.SetStateAction<any[]>>;
  checkedData: any[];
  setCheckedData: React.Dispatch<React.SetStateAction<any[]>>;
  isInit: boolean;
  advertdata: any;
  applyInfo: any;
  fetchApplyInfo: () => void;
}
export const ApplyPoolContext = createContext<CandidadeType>({
  newSelectedValues: {},
  setNewSelectedValues: () => {},
  handleFilter: () => {},
  clearFilters: () => {},
  queryParams: "",
  detailFiltersOpen: false,
  setDetailFiltersOpen: () => {},
  savedSearchFilters: [],
  fetchSearchItems: () => {},
  pagination: null,
  setPagination: () => {},
  showFilterSaveButton: true,
  setShowFilterSaveButton: () => {},
  syncCounter: 0,
  setSyncCounter: () => {},
  advertStatus: {},
  setAdvertStatus: () => {},
  advertStatusData: [],
  setAdvertStatusData: () => {},
  checkedData: [],
  setCheckedData: () => {},
  isInit: false,
  advertdata: {},
  applyInfo: {},
  fetchApplyInfo: () => {},
});
const axios = new Axios();
function AdvertAppliesPage(): React.ReactElement {
  const [filterConditionsArray, setFilterConditionsArray] = useState<any[]>([]);

  const [newSelectedValues, setNewSelectedValues] = useState<any>({});
  const [queryParams, setQueryParams] = useState("");
  const [queryParamsHold, setQueryParamsHold] = useState("");
  const [detailFiltersOpen, setDetailFiltersOpen] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [savedSearchFilters, setSavedSearchFilters] = useState<IFilter[]>([]);
  const [pagination, setPagination] = useState<PaginationData | null>(null);
  const location = useLocation();
  const { getCookie } = useCookie();
  const navigate = useNavigate();
  const companyUserToken = getCookie(CookieName.CompanyUserToken);
  const [isInit, setIsInit] = React.useState<boolean>(false);
  const [showFilterSaveButton, setShowFilterSaveButton] = useState(true);
  const [syncCounter, setSyncCounter] = React.useState<number>(0);
  const [advertStatus, setAdvertStatus] = useState<any>({});
  const [advertStatusData, setAdvertStatusData] = useState<any[]>([]);
  const [advertdata, setAdvertData] = useState<any>({});
  const [checkedData, setCheckedData] = useState<any[]>([]);
  const [applyInfo, setApplyInfo] = useState<any>({});
  const [showTimeUp, setShowTimeUp] = useState<boolean>(false);
  const { id } = useParams();

  const fetchApplyInfo = async () => {
    try {
      const response = await axios.request({
        type: "get",
        endpoint: `companies/advert-applies/${id}/get-info`,
        payload: {
          headers: {
            Authorization: `Bearer ${companyUserToken}`,
          },
        },
      });
      if (response && response.data && response.data.data) {
        setApplyInfo(response.data.data);
      }
    } catch (error) {
      throw new Error("Error fetching header card items: " + error);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let newParams: any = {};
    params.forEach((value, key) => {
      if (key === "status" || key === "search") {
        newParams[key] = value;
      } else {
        newParams[key] = JSON.parse(value);
      }
    });

    if (!isInit) {
      fetchApplyInfo();
      setNewSelectedValues(newParams);
      setIsInit(true);
    }
    setQueryParamsHold(location.search);
  }, [location.search]);

  useEffect(() => {
    setQueryParams(queryParamsHold);
  }, [queryParamsHold]);

  const handleFilter = (isEmpty = false) => {
    let constructedParams = "";
    Object.keys(isEmpty ? {} : newSelectedValues).forEach((key, index) => {
      if (key === "page") return;
      const value = newSelectedValues[key];
      constructedParams += `${index === 0 ? "?" : "&"}${key}=${
        typeof value === "object" ? `[${value}]` : value
      }`;
    });

    navigate(location.pathname + constructedParams);
    //setQueryParamsHold(constructedParams);
  };

  useEffect(() => {
    if (syncCounter > 0) {
      handleFilter();
    }
  }, [syncCounter]);

  const clearFilters = () => {
    setNewSelectedValues({});
    handleFilter(true);
  };

  const fetchSearchItems = async () => {
    try {
      const response = await axios.request({
        type: "get",
        endpoint: `companies/search/get-saved-filter`,
        payload: {
          headers: {
            Authorization: `Bearer ${companyUserToken}`,
          },
        },
      });
      if (response && response.data && response.data.company_searches) {
        const convertedFilters = response.data.company_searches.map(
          (item: { id: number; title: string; params: string }) => ({
            id: item.id,
            title: item.title,
            params: JSON.parse(item.params),
          })
        );

        setSavedSearchFilters(convertedFilters);
      }
    } catch (error) {
      throw new Error("Error fetching header card items: " + error);
    }
  };

  //fetch info
  const fetchCvViewCount: QueryFunction = async ({ queryKey }) => {
    try {
      const response = await axios.authRequest({
        type: "get",
        endpoint: `companies/advert/get-show/${id}`,
        payload: {
          headers: {
            Authorization: `Bearer ${companyUserToken}`,
          },
        },
      });
      if (response && response.data && response.data.data) {
        return response.data.data;
      }
    } catch (error) {
      throw new Error("Error fetching header card items: " + error);
    }
  };

  const cvCount = useQuery({
    queryKey: ["_advertDetailInfo"],
    queryFn: fetchCvViewCount,
    staleTime: Infinity,
  });
  useEffect(() => {
    setAdvertData(cvCount.data);
  }, [cvCount.data]);

  useEffect(() => {
    fetchSearchItems();
  }, []);

  useEffect(() => {
    if (advertdata?.advert?.last_date_to_see_personal_info_apply) {
      setShowTimeUp(
        moment(
          advertdata?.advert?.last_date_to_see_personal_info_apply
        ).valueOf() -
          moment().valueOf() <
          0
      );
    }
  }, [advertdata]);

  const value = {
    newSelectedValues,
    setNewSelectedValues,
    handleFilter,
    clearFilters,
    queryParams,
    detailFiltersOpen,
    setDetailFiltersOpen,
    scrollRef,
    savedSearchFilters,
    fetchSearchItems,
    pagination,
    setPagination,
    showFilterSaveButton,
    setShowFilterSaveButton,
    syncCounter,
    setSyncCounter,
    advertStatus,
    setAdvertStatus,
    advertStatusData,
    setAdvertStatusData,
    isInit,
    advertdata,
    checkedData,
    setCheckedData,
    applyInfo,
    fetchApplyInfo,
  };

  return (
    <main className=" flex flex-col items-center">
      <ApplyPoolContext.Provider value={value}>
        <div className="  min-h-screen w-full max-w-[1200px] ">
          <CandidatePoolError>
            <div id={"scroll-to-this"} ref={scrollRef}></div>
            <FilterSection />
            {showTimeUp && (
              <div className="mx-10">
                <div className="flex items-stretch mt-4 mb-9 border border-gray-300 bg-white rounded-md w-full">
                  <span className="flex items-center justify-center bg-red-500 text-white p-3 rounded-l-md">
                    <FiAlertCircle size={35} />
                  </span>
                  <div className={"p-5 flex flex-col items-start w-full"}>
                    <p className="flex flex-col sm:flex-row justify-start items-center font-poppins text-sm poppins-medium  ">
                      Başvuru İnceleme Süreniz Dolmuştur.
                    </p>
                    <p className="flex flex-col sm:flex-row justify-start items-center font-poppins text-sm poppins-light mt-1  ">
                      Üyeliğinizde aktif bir ürün olmadığı için başvuruları
                      inceleyememektesiniz.
                    </p>
                    <p className="flex flex-col sm:flex-row justify-start items-center font-poppins text-sm poppins-light mt-1  ">
                      Başvurularınızı incelemek için paketlerimizden size uygun
                      olanı seçebilirsiniz.
                    </p>
                    <p className="flex flex-col sm:flex-row justify-start items-center font-poppins text-sm poppins-light mt-1  ">
                      CV Görüntüleme paketi satın alarak tüm eski
                      başvurularınızı incelemeye devam edebilirsiniz.
                    </p>
                    <hr className={"my-3 border-gray-400 w-full"} />
                    <Link to={"/isveren/ürünler"}>
                      <button className="bg-primary200 font-semibold text-white px-3 py-2 rounded-md flex items-center flex-1  justify-center ">
                        Ürünleri İncele
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            )}

            <InfoSection queryParams={queryParams} />
          </CandidatePoolError>
        </div>
      </ApplyPoolContext.Provider>
    </main>
  );
}

export { AdvertAppliesPage };
