import { LabelHTMLAttributes, useContext } from "react";
import { RadioButtonContext } from ".";

const Label = ({
	children,
	className,
	...props
}: LabelHTMLAttributes<HTMLLabelElement>) => {
	const { error, required } = useContext(RadioButtonContext);

	return (
		<label
			className={`${className} ${
				error ? "text-red-600" : "text-[13px] font-semibold font-poppins text-gray-700"
			}`}
			{...props}>
			{children} {required && <b className='text-red-600'>*</b>}
		</label>
	);
};

export default Label;
