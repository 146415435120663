import { ButtonHTMLAttributes } from "react"









type itemPropsType = ButtonHTMLAttributes<HTMLButtonElement> & {
    selected?: boolean
}






export default function Item({
    className,
    selected,
    type = 'button',
    ...props
}: itemPropsType) {
    return(
        <button {...props} type={type} className={`${className} p-2 ${selected? 'bg-[#0c63e4] text-white': 'bg-white text-mains'} hover:bg-[#0c63e4] hover:text-white text-start transition-all`}>

        </button>
    )
}