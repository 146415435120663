import React, { useContext, useEffect, useRef, useState } from "react";
import { IoLocation } from "react-icons/io5";
import { MdOutlineMail, MdOutlinePhoneEnabled } from "react-icons/md";
import services from "../../../../../services/profile-services/index";
import { useParams } from "react-router-dom";
import ProfileHeader from "./profileHeader";
import SideBar from "./sideBare";
import Details from "./details";
import { IReview } from "./types/reviewCv";
import MobileDownloadAndShare from "./components/MobileDownloadAndShare";
import Axios from "../../../../../services/axios";
import Notes from "../../components/Notes";
import { CVPreviewContext } from "../index";
import { LuLoader2 } from "react-icons/lu";
import OpenCvInfo from "../../components/OpenCvInfo";
import OpenCvPopup from "../../components/OpenCvPopup";
import AddNotePopup from "../../components/AddNotePopup";
import Message from "../../../../profile-page-container/message";
import Messages from "../../components/Messages";
import Folders from "../../components/Folders";
import BlockCandidate from "../../components/BlockCandidate";
import SendMessagePopup from "../../components/SendMessagePopup";
import AddFolderPopup from "../../components/AddFolderPopup";
import Interviews from "../../components/Interviews";
import AddInterviewPopup from "../../components/AddInterviewPopup";

const axios = new Axios();

const CvDetails: React.FC = () => {
  const {
    loading,
    data,
    error,
    isBlock,
    cvViewCount,
    addNoteShow,
    setAddNoteShow,
    openCvShow,
    setOpenCvShow,
    cvHtml,
    sendMessageShow,
    setSendMessageShow,
    addFolderShow,
    setAddFolderShow,
    interviewShow,
    setInterviewShow,
    profilePictureShow,
    setProfilePictureShow,
  } = useContext(CVPreviewContext);

  if (loading) {
    return (
      <div className={"flex min-h-screen justify-center "}>
        <LuLoader2 className={"mt-10 w-24 h-24 animate-spin text-gray-400"} />
      </div>
    );
  }

  if (error) {
    return (
      <div className={"flex min-h-screen justify-center mt-10"}>
        Hata: {error}
      </div>
    );
  }

  return (
    <div className={"container self-center justify-center"}>
      {profilePictureShow && (
        <div
          onClick={() => {
            setProfilePictureShow(false);
          }}
          className={
            "fixed top-0 left-0 z-[99999] w-screen h-screen bg-gray-600 bg-opacity-50"
          }
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={
              "absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            }
          >
            <img
              className=""
              src={`${data.user.profile}?time=${new Date().valueOf()}`}
              alt=""
              crossOrigin="anonymous"
            />
          </div>
        </div>
      )}

      <div className="my-6 font-medium sm:font-normal">
        <div className="">
          <div className="bg-white mb-2 box-shadow rounded-md hidden sm:block">
            {data && (
              <ProfileHeader
                data={{ cv: data.cv, user: data.user }}
                htmlRef={cvHtml}
              />
            )}
          </div>

          <div className={"max-sm:hidden"}>
            <OpenCvInfo />
          </div>

          <div className={"grid grid-cols-1 gap-6 lg:grid-cols-8 lg:gap-4"}>
            <div className={"lg:col-span-6"}>
              {data && (
                <div ref={cvHtml} className="flex flex-col sm:flex-row ">
                  <div className=" w-full  sm:w-1/4 min-w-[280px]">
                    {data?.user && (
                      <SideBar
                        user={data?.user}
                        cv={data.cv}
                        liveInfo={data?.cv_live_info}
                        socials={data?.cv_social_accounts}
                        skills={data?.competences || []}
                        isEnglish={data?.cv.lang == "en"}
                      />
                    )}
                  </div>
                  <div className="flex-grow">
                    {data && <Details data={data} />}
                  </div>
                </div>
              )}
            </div>
            {false && (
              <div className="bg-white rounded-md block sm:hidden">
                <MobileDownloadAndShare
                  htmlRef={cvHtml}
                  data={{ cv: data.cv, user: data.user }}
                />
              </div>
            )}
            <div className={"flex flex-col gap-3 lg:col-span-2"}>
              <Notes />
              <Messages />
              {!isBlock && <Interviews />}
              <Folders />
              <BlockCandidate />
            </div>
          </div>
        </div>
      </div>

      {addNoteShow && (
        <AddNotePopup
          onClose={() => {
            setAddNoteShow(false);
          }}
        />
      )}

      {sendMessageShow && (
        <SendMessagePopup
          onClose={() => {
            setSendMessageShow(false);
          }}
          onSuccess={() => {
            setSendMessageShow(false);
          }}
          id={data.user.id}
        />
      )}
      {interviewShow && (
        <AddInterviewPopup
          onClose={() => {
            setInterviewShow(false);
          }}
          onSuccess={() => {
            setInterviewShow(false);
          }}
          id={data.user.id}
        />
      )}

      {openCvShow && (
        <OpenCvPopup
          onClose={() => {
            setOpenCvShow(false);
          }}
        />
      )}
      {addFolderShow && (
        <AddFolderPopup
          onClose={() => {
            setAddFolderShow(false);
          }}
        />
      )}
    </div>
  );
};

export default CvDetails;
