import 'react-image-crop/dist/ReactCrop.css'
import ReactCrop, { PixelCrop, centerCrop, makeAspectCrop, type Crop } from 'react-image-crop'
import { ChangeEvent, useEffect, useRef, useState, DragEvent, Dispatch, SetStateAction } from 'react'
import { Button } from '../../../../../isbul-ui'
import { FaCloudUploadAlt } from 'react-icons/fa'
import { IoPerson } from 'react-icons/io5'
import Axios from '../../../../../../services/axios'
import Swal from 'sweetalert2'




const axios = new Axios()




export default function ImageCropper({
    getAllDatas,
    setIsModalOpen,
    isEnglish = false
}: {
    getAllDatas?: () => void,
    setIsModalOpen: Dispatch<SetStateAction<boolean>>,
    isEnglish?: boolean
}) {


    const canvasRef = useRef<HTMLCanvasElement>(null)
    const imgRef = useRef<HTMLImageElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const imageDivRef = useRef<HTMLDivElement>(null)
    const [croppedImageSrc, setCroppedImageSrc] = useState<string | null>(null)
    const [image, setImage] = useState<string | null>(null)
    const [imageFile, setImageFile] = useState<File | null>(null)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [crop, setCrop] = useState<Crop>({
        unit: 'px',
        x: 0,
        y: 0,
        width: 100,
        height: 100
    })
    const [isDragEnter, setIsDragEnter] = useState<boolean>(false)
    const [divKey, setDivKey] = useState<number>(new Date().getTime()) 

    useEffect(() => {
        if (imgRef && imgRef.current) {
            const img = imgRef.current
            const { width, height } = img.getBoundingClientRect()

            
            /* console.log('width', width)
            console.log('height', height) */

            
        }
    }, [imgRef, image])

    useEffect(() => {
        if (image && imageDivRef && imageDivRef.current) {
            const imageDiv = imageDivRef.current
            const { width, height } = imageDiv.getBoundingClientRect()

            /* console.log('w', width)
            console.log('h', height) */
            
            if (width < 116 || height < 116) {
                // TODO: Testlerden sonra tekrar bakılacak
                /* setImage(null)
                setErrorMessage(`${isEnglish? 'Please choose an image with the aspect ratio suitable for the profile photo.': 'Lütfen en/boy oranı profil fotoğrafı olmaya uygun bir görsel seçiniz.'}`) */
                
                //setDivKey(new Date().getTime())
                /* setCrop({
                    height: height <= width? height / 2: width / 2,
                    width: width <= height? width / 2: height / 2,
                    unit: 'px',
                    x: 0,
                    y: 0
                }) */
            } else {
                setErrorMessage(null)
            }
        }
    }, [image, imageDivRef])

    useEffect(() => {
        if (crop.width == 0 || crop.height == 0) {
            setCrop({
                unit: 'px',
                x: 0,
                y: 0,
                width: 100,
                height: 100
            })
        }
    }, [crop])
    return(
        <div className='flex flex-col lg:flex-row items-center'>
            <div className='flex flex-col basis-1/2 items-center p-2 mt-2 lg:mt-0'>
                <div className='flex flex-col gap-2 items-center'>
                    <div className='flex w-28 h-28 rounded-full bg-gray-100 items-center justify-center p-1'>
                        <canvas ref={canvasRef} className='hidden'/>
                        {croppedImageSrc && (
                            <img
                            src={croppedImageSrc}
                            className='w-full h-full aspect-square object-cover rounded-full'
                            />
                        )}
                        {!croppedImageSrc && (
                            <IoPerson className='text-6xl text-mains'/>
                        )}
                    </div>
                    {croppedImageSrc && (
                        <Button
                        onClick={uploadPhoto}
                        type='button'
                        size='sm'
                        >
                            {isEnglish? 'Save': 'Kaydet'}
                        </Button>
                    )}
                    <Button 
                    variant='secondary'
                    type='button' 
                    size='sm'
                    onClick={() => {
                        if (inputRef && inputRef.current) {
                            inputRef.current.click()
                        }
                    }}
                    >
                        <Button.Icon>
                            <FaCloudUploadAlt/>
                        </Button.Icon>
                        {isEnglish? 'Choose Image': 'Resim Seç'}
                    </Button>
                    <input 
                    ref={inputRef}
                    className='hidden' 
                    type='file' 
                    accept='image/*'
                    onChange={((e) => imageChange(e))}
                    />
                </div>
            </div>
            <div
            ref={imageDivRef}
            key={divKey}
            className='flex items-center justify-center basis-1/2 p-2 h-full'
            >
                {image && (
                    <ReactCrop
                    crop={crop}
                    onChange={((e) => {
                        setCrop(e)
                    })}
                    onComplete={((e) => {
                        setCrop((prev) => e)
                        cropComplete()
                    })}
                    aspect={1}
                    circularCrop
                    >
                        <img
                        ref={imgRef}
                        src={image}
                        />
                    </ReactCrop>
                )}
                {!image && (
                    <div 
                    className={`flex relative flex-col gap-2 lg:items-center lg:justify-center w-full min-h-[300px] h-full lg:border-l lg:border-l-gray-300 transition-all`}>
                        <hr className='flex lg:hidden w-full ml-0 lg:ml-4'/>
                        <div className='flex flex-col gap-1 font-poppins text-sm text-gray-600'>
                            {errorMessage? (
                                <label className='font-poppins text-red-600 pl-4 text-center'>{errorMessage}</label>
                            ): (
                                <ul className='list-disc pl-4 lg:pl-10 text-start marker:text-mains marker:text-xl'>
                                    <li>
                                        {isEnglish? 'Adding a photo increases the visibility of your CV by 70%.': "Fotoğraf eklemek CV'nizin görünürlüğünü %70 oranında arttırır."}
                                    </li>
                                    <li>
                                        {isEnglish? 'Make sure the photo is clear and smooth.': 'Fotoğrafın net ve düzgün olmasına dikkat edin.'}
                                    </li>
                                    <li>
                                        {isEnglish? 'Add a passport photo.': 'Vesikalık fotoğraf ekleyin.'}
                                    </li>
                                    <li>
                                        {isEnglish? 'Photo size should not be more than 20 MB.': "Fotoğraf boyutu 20 MB'dan fazla olmamalıdır."}
                                    </li>
                                    <li>
                                        {isEnglish? 'Photo types must be PNG, JPG, JPEG.': 'Fotoğraf türleri PNG, JPG, JPEG olmalıdır.'}
                                    </li>
                                </ul>
                            )}
                        </div>
                        <hr className='hidden lg:flex w-full ml-0 lg:ml-4'/>
                        <div className='hidden lg:flex flex-col gap-1 items-center'>
                            <FaCloudUploadAlt className='text-4xl text-gray-300'/>
                            <label className='font-poppins text-xs text-gray-600'>
                                {isEnglish? 'Drag Image': 'Resmi sürükleyiniz'}
                            </label>
                        </div>
                        <div 
                        onDrop={handleDrop}
                        onDragEnter={() => setIsDragEnter(true)}
                        onDragLeave={() => setIsDragEnter(false)}
                        onDragOver={handleDragOver}
                        className={`absolute top-0 left-0 w-full h-full rounded-md ${isDragEnter? 'border-2 border-dashed border-gray-600': 'border-2 border-transparent'} transition-all`}
                        >

                        </div>
                    </div>
                )}
            </div>
        </div>
    )

    async function uploadPhoto() {
        try {
            const formData = new FormData()

            const canvas = canvasRef.current
            if (!canvas) {
                Swal.fire({
                    icon: 'error',
                    title: 'Hata',
                    text: 'Lütfen resim alanı seçiniz.'
                })
                return
            }

            canvas.toBlob(async (blob) => {
                if (!blob) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Hata',
                        text: 'Lütfen geçerli bir dosya seçiniz.'
                    })
                    return
                }
                const file = new File([blob], 'croppedImage.png', { type: 'image/png' })
                formData.append('_method', 'PUT')
                formData.append('profile_logo', file)

                const response = await axios.userAuthRequest({
                    endpoint: `user/profilim/update`,
                    type: 'post',
                    payload: formData
                })

                if (response && response.status == 200) {
                    if (getAllDatas) {
                        getAllDatas()
                    }
                    setIsModalOpen(false)
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Hata',
                        text: 'Resim güncellenirken bir hata meydana geldi.'
                    })
                    return
                }
            }, 'image/png')
            

        } catch (error) {
            console.log(`Error: ${error}`)
        }
    }

    function handleDrop(e: DragEvent<HTMLDivElement>) {
        e.preventDefault()

        const file = e.dataTransfer.files[0]

        if (file) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setImage(reader.result as string)
            }
            reader.readAsDataURL(file)
        }
    }

    function handleDragOver(e: DragEvent<HTMLDivElement>) {
        e.preventDefault()
    }

    function imageChange(e: ChangeEvent<HTMLInputElement>) {
        const file = e.target.files?.[0]
        if (file) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setImage(reader.result as string)
            }
            reader.readAsDataURL(file)
        }
    }

    function cropComplete() {
        const canvas = canvasRef.current
        const img = imgRef.current

        if (canvas && img) {
            const context = canvas.getContext('2d')
            if (context) {

                const { naturalWidth, naturalHeight } = img

                const { width, height } = img.getBoundingClientRect()

                const xRatio = naturalWidth / width
                const yRatio = naturalHeight / height


                const sx = crop.x * xRatio
                const sy = crop.y * yRatio
                const sw = crop.width * xRatio
                const sh = crop.height * yRatio

                canvas.width = crop.width
                canvas.height = crop.height

                context.drawImage(
                    img,
                    sx,
                    sy,
                    sw,
                    sh,
                    0,
                    0,
                    crop.width,
                    crop.height
                )

                const croppedDataUrl = canvas.toDataURL()

                setCroppedImageSrc(croppedDataUrl)
            }
        }
    }
    
}
