import { IconUserExclamation } from '@tabler/icons-react';
import React from 'react';

interface IFilterCategoryInfo {
  categoryName: string;
}

const FilterCategoryInfo: React.FC<IFilterCategoryInfo> = (
  props: IFilterCategoryInfo
) => {
  return (
    <div className="h-full w-full bg-title rounded p-4 flex gap-x-4">
      <div className="h-full aspect-square rounded-full border-2 border-white p-1">
        <IconUserExclamation className="h-full w-full text-white" />
      </div>
      <span className="text-white">{`${props.categoryName} seçerek daha doğru ilanlara ulaşabilirsiniz.`}</span>
    </div>
  );
};

export default FilterCategoryInfo;
