import React, { useState } from "react";
import List from "../list";

const MainTitle: React.FC = () => {
  const [activeTab, setActiveTab] = useState<number | null>(1);

  const toggleContent = (tab: number) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className="flex flex-col w-full gap-y-6">
      <div className="flex items-center justify-between sm:justify-center px-5 gap-x-8 ">
        <button
          onClick={() => toggleContent(1)}
          className={`toggle-btn ${
            activeTab === 1 ? "active border-b border-mains px-2 py-1 " : "px-2"
          }`}
        >
          <span
            className={`text-xl font-[600] text-gray-400 ${
              activeTab === 1 ? "text-mains" : "text-gray-400"
            }`}
          >
            Başvurularım
          </span>
        </button>
        <button
          onClick={() => toggleContent(2)}
          className={`toggle-btn ${
            activeTab === 2
              ? "active border-b border-mains px-2 py-1  "
              : "px-2"
          }`}
        >
          <span
            className={`text-xl font-[600] text-gray-400 ${
              activeTab === 2 ? "text-mains" : "text-gray-400"
            }`}
          >
            Arşiv
          </span>
        </button>
      </div>
      <div className="flex flex-col w-full min-h-20 ">
        {/* İçeriği buraya ekleyebilirsiniz */}
        {activeTab === 1 && (
          <div>
            <List type="b1" />
          </div>
        )}
        {activeTab === 2 && (
          <div>
            <List type="b2" />
          </div>
        )}
      </div>
    </div>
  );
};

export default MainTitle;
