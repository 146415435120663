import {
  Dispatch,
  HTMLAttributes,
  SetStateAction,
  createContext,
  useState,
  ReactNode,
} from "react";
import classNames from "classnames";
import Trigger from "./Trigger";
import Content from "./Content";

// Component Type
type hovercardPropsType = {
  visible?: boolean;
  setVisible?: Dispatch<SetStateAction<boolean>>;
  content: ReactNode;
  children: ReactNode;
  className?: string;
};

// Context Type
export type hovercardContextType = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  content: ReactNode;
};

// Context Provider
export const HovercardContext = createContext<hovercardContextType>({
  visible: false,
  setVisible: () => {},
  content: null,
});

export function Hovercard({
  className,
  visible: initialVisible,
  setVisible: initialSetVisible,
  children,
  content,
}: hovercardPropsType) {
  const [visible, setVisible] = useState<boolean>(initialVisible || false);

  const cn = classNames({
    relative: true,
  });

  const value: hovercardContextType = {
    visible: initialVisible != undefined ? initialVisible : visible,
    setVisible: initialSetVisible != undefined ? initialSetVisible : setVisible,
    content: content,
  };

  return (
    <HovercardContext.Provider value={value}>
      <div className={`${cn} ${className}`}>{children}</div>
    </HovercardContext.Provider>
  );
}

Hovercard.Trigger = Trigger;
Hovercard.Content = Content;
