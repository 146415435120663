import { ReactNode } from "react";
import { useContext } from "react";
import { TabsContext } from "./index";

type tabPanelPropsType = {
  children: ReactNode;
  tabId: string;
  className?: string;
};

export function TabPanel({ children, tabId, className }: tabPanelPropsType) {
  const context = useContext(TabsContext);

  if (!context) {
    throw new Error("TabPanel component must be used within a Tabs provider");
  }

  const { selectedTab } = context;

  if (selectedTab !== tabId) {
    return null;
  }

  return <div className={className}>{children}</div>;
}
