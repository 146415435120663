import * as React from "react";
import { CustomButton } from "../../../../../../../components/common/login/custom-button";
import { ICustomCard } from "../../../types/ICustomCard";
import { PackageType } from "../../../constants/enum";
import { IoCheckmark } from "react-icons/io5";
import { useNavigate } from "react-router-dom";


interface IOtherSectionProps {
  readonly packages: ICustomCard[];
}

function OtherSection(
  props: IOtherSectionProps & { selectedButton: string }
): React.ReactElement {
  const filteredPackages = React.useMemo(() => {
    switch (props.selectedButton) {
      case PackageType.İlanPaketleri:
        return props.packages.filter(
          (pkg) => pkg.advert && pkg.advert > 0 && pkg.single_product === "1"
        );
      case PackageType.Doping:
        return props.packages.filter((pkg) => pkg.doping && pkg.doping > 0);
      case PackageType.CvGörüntülemePaketleri:
        return props.packages.filter((pkg) => pkg.cv && pkg.cv > 0);
      default:
        return [];
    }
  }, [props.packages, props.selectedButton]);

  const navigate = useNavigate()
  
  return (
    <>
      {filteredPackages.map((pkg) => (
        <div key={pkg.id}>
          <label
            htmlFor={`checkLabel-${pkg.id}`}
            className="flex flex-row px-4 py-2 bg-white gap-y-2"
          >
            <div
              key={pkg.id}
              className="flex flex-row items-center w-full justify-between p-4 rounded-md bg-mains/10"
            >
              <input
                id={`checkLabel-${pkg.id}`}
                type="checkbox"
                className="w-8"
              />
              <div className="flex flex-col w-full gap-x-1">
                <span className="text-base text-mains poppins-medium">
                  {pkg.advert} {pkg.name}
                </span>
                <span className="text-sm text-gray-600 poppins-medium">
                  {pkg.discounted_price
                    ? `${pkg.discounted_price} ₺`
                    : `${pkg.prices} ₺`}{" "}
                  + KDV
                </span>
              </div>
              <CustomButton
                text="Satın Al"
                className="h-12 text-lg text-white rounded-md w-28 bg-mains poppins-semibold"
                //onClick={pkg.onClick} TODO: Yeni hali test edilip silinecek
                onClick={() => navigate(`/isveren/paket/${pkg.id}/satin-al`)}
              />
            </div>
          </label>
        </div>
      ))}
    </>
  );
}

export { OtherSection };
