import React from 'react';
import SearchBar from './search-bar';
import List from './list';



const Main: React.FC = () => {
  return (
    <>
    <div className='flex flex-col gap-y-2  w-full items-center  py-3 bg-white rounded-md mb-10 md:mb-0'>
      {/* <SearchBar/> */}
      <List/>
    </div>
    </>
  );
};

export default Main;
