import { useState, useEffect } from "react";
import Axios from "../../../../services/axios";
import GeneralBaseInfo from "../general-base-info";
import ShareButtons from "../share-buttons";
import AddDetail from "../ad-detail";
import MainInfo from "../main-info";
import SaveAndNextButon from "../save-and-next-button";
import { useNavigate, useParams } from "react-router-dom";
import { ProductsPage } from "../../../../pages/products-page";
import { PackagesContents } from "../../../login-page-container/company/login/general-packages/packages-contents";
import { GeneralPackages } from "../../../login-page-container/company/login/general-packages";
interface MapLocation {
  lat: number;
  longitude: number;
}
interface City {
  id: number;
  country_id: number;
  name: string;
  slug: string;
  seo_name: string | null;
  // Add other properties as necessary
}

interface District {
  id: number;
  city_id: number;
  name: string;
  slug: string;
  content: string;
  // Add other properties as necessary
}
export interface Location {
  advert_id: number;
  city: City;
  city_id: number;
  created_at: string;
  district: District;
  district_id: number;
  id: number;
  updated_at: string;
}
interface PositionDetails {
  created_at: string;
  id: number;
  is_individual: number;
  order: number;
  slug: string;
  title: string;
  title_en: string;
  updated_at: string;
}

export interface Position {
  advert_id: number;
  created_at: string;
  id: number;
  position: PositionDetails;
  position_id: number;
  updated_at: string;
}
interface PositionLevelDetails {
  created_at: string;
  id: number;
  name: string;
  name_en: string | null;
  slug: string;
  updated_at: string;
}

export interface PositionLevel {
  advert_id: number;
  created_at: string;
  id: number;
  position_level: PositionLevelDetails;
  position_level_id: number;
  updated_at: string;
}
interface SectorDetails {
  created_at: string;
  id: number;
  is_publish: string;
  order: number;
  slug: string;
  title: string;
  title_en: string | null;
  updated_at: string;
}

export interface Sector {
  advert_id: number;
  created_at: string;
  id: number;
  sector: SectorDetails;
  sector_id: number;
  updated_at: string;
}
interface WorkingLocationDetails {
  created_at: string;
  id: number;
  order: number;
  slug: string;
  title: string;
  title_en: string;
  updated_at: string;
}

export interface WorkingLocation {
  advert_id: number;
  created_at: string;
  id: number;
  updated_at: string;
  working_location: WorkingLocationDetails;
  working_location_id: number;
}

interface DrivingLicenceSrc {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface DrivingLicenceSrcs {
  driving_licence_src: DrivingLicenceSrc;
}

interface JobBenefitsDetails {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface JobBenefits {
  job_benefit: JobBenefitsDetails;
}

export interface LanguageDetails {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface Languages {
  language: LanguageDetails;
}

interface WorkingTypeDetails {
  created_at: string;
  id: number;
  order: number;
  slug: string;
  title: string;
  title_en: string;
  updated_at: string;
}

export interface WorkingType {
  advert_id: number;
  created_at: string;
  id: number;
  updated_at: string;
  working_type: WorkingTypeDetails;
  working_type_id: number;
}
interface EducationLevelsDetails {
  created_at: string;
  id: number;
  order: number;
  slug: string;
  title: string;
  title_en: string;
  updated_at: string;
}
export interface EducationLevels {
  advert_id: number;
  created_at: string;
  id: number;
  updated_at: string;
  education_level: EducationLevelsDetails;
  education_level_id: number;
}
interface ExperienceLevelDetail {
  created_at: string;
  id: number;
  order: number;
  slug: string;
  title: string;
  title_en: string;
  updated_at: string;
  value: number;
}
export interface ExperienceLevel {
  experience_level_min: ExperienceLevelDetail;
  experience_level_max: ExperienceLevelDetail;
}
interface AdvertPayload {
  [key: string]: any;
  title: string;
  map_location: MapLocation;
  sections: number[]; // Array of section IDs
  sectors: Sector[]; // Array of sector IDs
  positions: Position[]; // Array of position IDs
  working_types: WorkingType[]; // Array of working type IDs
  working_locations: WorkingLocation[]; // Array of working location IDs
  driving_licence_srcs: DrivingLicenceSrcs[]; // Array of driving license source IDs
  job_benefits: JobBenefits[]; // Array of job benefit IDs
  languages: Languages[]; // Array of language IDs
  position_levels: PositionLevel[]; // Array of position level IDs
  experience_level: ExperienceLevel;
  experience_level_type: "not-matter" | "experienced" | "inexperienced";
  experience_level_min: number;
  experience_level_max: number;
  education_levels: EducationLevels[]; // Array of education level IDs
  employee_count: number;
  min_age: number;
  max_age: number;
  gender: number; // Gender ID
  language: number; // Primary language ID

  country: number; // Country ID
  keywords: string[]; // Array of keywords
  detail: string; // Detailed description
  hide_contact_info: "show_all" | "show_gsm" | "show_phone" | "hide_all";
  hide_employee_info: boolean;
  hide_company_info: boolean;
  locations: Location[]; // Array of locations (city and district IDs)

  is_send_message_users: boolean;
  is_disabled_employee: boolean;
  template_type: "DEFAULT" | "MAP" | "IMAGE" | "MAP-IMAGE";
  questions: { question: number; is_required: boolean }[];
  terms: number;
  advert_file: number;
  company: { name: ""; surname: ""; phone: ""; land_phone: ""; email: "" };
  company_detail: { facebook: ""; logo: ""; twitter: ""; whatsapp: "" };
}

const Body: React.FC = () => {
  const { advertId } = useParams();
  const [adData, setAdData] = useState<AdvertPayload>();
  const adDetail = adData?.advert.detail;
  const advertData = adData?.advert;
  const companyData = adData?.company;
  const companyDetail = adData?.company_detail;
  const companyLogo = adData?.company_detail.logo;
  const companyFullName = [companyData?.name, companyData?.surname]
    .filter(Boolean)
    .join(" ");
  const companyPhone = companyData?.phone;
  const companyLand_phone = companyData?.land_phone;
  const companyEmail = companyData?.email;
  const title = advertData?.title;
  {
    /*Arrays */
  }
  const positions = adData?.positions;
  const positionLevels = adData?.position_levels;
  const sectors = adData?.sectors;
  const locations = adData?.locations;
  const workingLocations = adData?.working_locations;
  const workingTypes = adData?.working_types;
  const languages = adData?.languages;
  const keywords = adData?.keywords;
  const jobBenefits = adData?.job_benefits;
  const drivingLicenceSrcs = adData?.driving_licence_srcs;
  const educationLevels = adData?.education_levels;
  const experienceLevel = adData?.experience_level;
  const isDisabledEmployee = adData?.advert.is_disabled_employee;

  const customeAxios = new Axios();

  useEffect(() => {
    const fetchAdData = async () => {
      if (!advertId) return;
      const respone = await customeAxios.authRequest({
        type: "get",
        endpoint: `companies/advert/get-show/${advertId}`,
        payload: "",
      });
      console.log(respone, "respone");
      if (respone.data) {
        setAdData(respone.data.data);
      } else return;
    };
    fetchAdData();
  }, [advertId]);

  const navigate = useNavigate();

  const handleNavigate = async () => {
    const response = await customeAxios.authRequest({
      type: "post",
      endpoint: `companies/advert/success/${advertId}`,
      payload: advertId,
    });
    console.log(response);
    if (advertId) {
      navigate(`/isveren/ilanlar/ilan/${advertId}/basarili`, {
        state: { companyEmail },
      });
    }
  }

  return (
    <div>
      <div className="mt-1">
        <div className="container mx-auto">
          <SaveAndNextButon
            advertId={advertId}
            companyEmail={companyEmail}
            skip
            skipText="Satın Almadan Devam Et"
          />
        </div>
        {/* <ProductsPage /> */}
        <div className="container mx-auto">
          <GeneralPackages
            freeNavigate={handleNavigate}
          />
        </div>
        <div className="container mx-auto">
          <SaveAndNextButon
            advertId={advertId}
            companyEmail={companyEmail}
            skip
            skipText="Satın Almadan Devam Et"
          />
        </div>
      </div>

    </div>
  );
};
export default Body;
