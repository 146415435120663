import { LabelHTMLAttributes } from "react"
import { useContext } from "react"
import { MultiSelectDropdownContext, multiSelectDropdownContextType } from "."




type labelPropsType = LabelHTMLAttributes<HTMLLabelElement> & {

}






export default function Label({
    onClick,
    className,
    children,
    ...props
}: labelPropsType) {

    const { required, setIsOpen }: multiSelectDropdownContextType = useContext(MultiSelectDropdownContext)

    return(
        <label 
        className={`${className} text-[13px] text-gray-700 font-semibold font-poppins`}
        onClick={(e) => {
            if (onClick) {
                onClick(e)
            }

            setIsOpen((prev) => !prev)
        }}
        {...props}
        >
            {children} {required? (
                <b className="text-red-600">*</b>
            ): null}
        </label>
    )
}