import Axios from "../services/axios";
import { cdnCityType } from "../types";

type getCitiesPropsType = {
  search?: string;
  country_id?: number;
  with_all?: 0 | 1;
  limit?: number;
  except_ids?: number[];
  only_ids?: number[];
  get_only_by_string?: string[];
  lang?: string;
};

const axios = new Axios();

export async function getCities({
  search,
  country_id,
  with_all,
  limit,
  except_ids,
  only_ids,
  get_only_by_string,
  lang,
}: getCitiesPropsType): Promise<cdnCityType[]> {
  try {
    const response = await axios.request({
      endpoint: `cdn/get-cities?
      ${lang ? `lang=${lang}&` : ""}
            ${search ? `search=${search}&` : ``}
            ${country_id ? `country_id=${country_id.toString()}&` : ""}
            ${with_all ? `with_all=${with_all}&` : ""}
            ${limit ? `limit=${limit}&` : ""}
            ${except_ids ? `except_ids=${JSON.stringify(except_ids)}&` : ""}
            ${only_ids ? `only_ids=${JSON.stringify(only_ids)}` : ""}
            ${
              get_only_by_string
                ? `get_only_by_string=${JSON.stringify(get_only_by_string)}&`
                : ""
            }
            `,
      type: "get",
    });

    if (response && response.data && response.data.cities) {
      return JSON.parse(JSON.stringify(response.data.cities));
    } else {
      return [];
    }
  } catch (error) {
    console.log(`Cities fetch failed. Error: ${error}`);
    return [];
  }
}
