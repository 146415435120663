import { LoginContent } from "../../../../components/common/login/login-content/index";
import { AlertContent } from "./alert-content";
import { GeneralPackages } from "./general-packages";

function CompanyLogins(): React.ReactElement {
	return (
		<div className='container flex flex-col mx-auto gap-y-6 mt-4'>
			<LoginContent
				type='login'
				categories='company'
			/>
			<AlertContent />
			<div className='flex items-center justify-between gap-1 md:gap-4'>
				<div className='w-full bg-mains rounded-md flex justify-center items-center text-white text-[10px] font-poppins py-1 md:py-3 md:rounded-md md:text-base'>
					Doping
				</div>
				<div className='w-full bg-mains rounded-md flex justify-center items-center text-white text-[10px] font-poppins py-1 md:py-3 md:rounded-md md:text-base'>
					Aday Havuzu
				</div>
				<div className='w-full bg-mains rounded-md flex justify-center items-center text-white text-[10px] font-poppins py-1 md:py-3 md:rounded-md md:text-base'>
					Manşet
				</div>
			</div>
			<GeneralPackages />
		</div>
	);
}

export { CompanyLogins };
