import { HTMLAttributes, useEffect, useRef } from "react";
import { useContext } from "react";
import { FormModalContext, formModalContextType } from ".";

type contentPropsType = HTMLAttributes<HTMLDivElement> & {};

export default function Content({ className, ...props }: contentPropsType) {
  const divRef = useRef<HTMLDivElement>(null);

  const { isOpen, setIsOpen, onClosing }: formModalContextType =
    useContext(FormModalContext);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        if (onClosing) {
          onClosing()
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef]);

  if (isOpen) {
    return (
      <div className="fixed top-0 left-0 flex items-center justify-center w-screen h-screen bg-black z-[100] backdrop-blur-sm bg-opacity-30">
        <div
          ref={divRef}
          className={`${className} bg-white shadow-form-glow p-4 rounded-md overflow-auto w-full h-full lg:w-[1200px] lg:h-auto max-w-[100vw] max-h-[100vh] lg:max-h-[calc(100vh-50px)] lg:max-w-[calc(100vw-50px)]`}
          {...props}
        ></div>
      </div>
    );
  } else {
    return null;
  }
}
