import { Dispatch, HTMLAttributes, SetStateAction, useRef, createContext, useState, useEffect } from "react"
import classNames from "classnames"
import { SelectDropdownContext } from "../select-dropdown"
import Label from "./Label"
import Trigger from "./Trigger"
import Items from "./Items"
import Item from "./Item"
import Error from "./Error"


type modeType = 'empty' | 'dirty'

type multiSelectDropownPropsType = HTMLAttributes<HTMLDivElement> & {
    required?: boolean,
    error?: boolean,
    mode?: modeType,
    isOpen?: boolean,
    setIsOpen?: Dispatch<SetStateAction<boolean>>
}

export type multiSelectDropdownContextType = {
    required?: boolean,
    error?: boolean,
    mode?: modeType,
    isOpen: boolean,
    setIsOpen: Dispatch<SetStateAction<boolean>>
}


export const MultiSelectDropdownContext = createContext<multiSelectDropdownContextType>({
    required: false,
    mode: 'empty',
    error: false,
    isOpen: false,
    setIsOpen: () => {}
})



export function MultiSelectDropdown({
    required,
    error,
    mode = 'empty',
    isOpen: initialIsOpen,
    setIsOpen: initialSetIsOpen,
    className,
    ...props
}: multiSelectDropownPropsType) {


    const divRef = useRef<HTMLDivElement>(null)

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const cn = classNames({
        'flex flex-col gap-1 relative': true
    })

    const value: multiSelectDropdownContextType = {
        error,
        mode,
        required,
        isOpen: initialIsOpen != undefined? initialIsOpen: isOpen,
        setIsOpen: initialSetIsOpen != undefined? initialSetIsOpen: setIsOpen
    }

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if ( divRef.current && !divRef.current.contains(event.target as Node)) {
                if (initialSetIsOpen) {
                    initialSetIsOpen(false)
                } else {
                    setIsOpen(false)
                }
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => document.removeEventListener("mousedown", handleClickOutside)
    }, [])
    return(
        <MultiSelectDropdownContext.Provider value={value}>
            <div ref={divRef} className={`${cn} ${className}`} {...props}>
            
            </div>
        </MultiSelectDropdownContext.Provider>
        
    )
}


MultiSelectDropdown.Label = Label
MultiSelectDropdown.Trigger = Trigger
MultiSelectDropdown.Items = Items
MultiSelectDropdown.Item = Item
MultiSelectDropdown.Error = Error