import React from "react";
import Contents from "./contents";

const LastViewedAds: React.FC = () => {
  return (
    <>
      <Contents />
    </>
  );
};

export default LastViewedAds;
