import { ICv } from "./types/cv";
import { ICvLiveInfo } from "./types/liveInfo";
import { IUser } from "./types/users";
import CardInfo from "./CardInfo";
import { CiLocationOn } from "react-icons/ci";
import { FiPhone } from "react-icons/fi";
import { MdMailOutline } from "react-icons/md";
import { useEffect } from "react";
import {
  FaDribbble,
  FaFacebook,
  FaGithub,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";
import { FaLinkedin, FaLocationDot, FaYoutube } from "react-icons/fa6";
import { cdnSkillType } from "../../../../../types";

interface SideBarProps {
  user: IUser;
  cv: ICv;
  liveInfo: ICvLiveInfo;
  socials: any[];
  skills?: cdnSkillType[];
  isEnglish?: boolean;
}

export default function SideBar({
  user,
  cv,
  liveInfo,
  socials,
  skills,
  isEnglish,
}: SideBarProps) {
  const {
    name,
    surname,
    email,
    profile,
    phone,
    phone_code,
    second_phone_code,
    second_phone,
  } = user;

  const { website } = cv;
  const { address, city, country, district } = liveInfo;

  const iconMap: { [key: string]: any } = {
    Instagram: <FaInstagram />,
    Twitter: <FaTwitter />,
    Facebook: <FaFacebook />,
    Dribbble: <FaDribbble />,
    Github: <FaGithub />,
    Youtube: <FaYoutube />,
    Linkedin: <FaLinkedin />,
  };

  //console.log('user', user)

  function formatPhone(phone: string) {
    if (phone.length != 10) {
      return phone;
    } else {
      return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)} ${phone.slice(
        6,
        8
      )} ${phone.slice(8, 10)}`;
    }
  }

  const formattedPhone = formatPhone(phone);
  const formattedSecondPhone = formatPhone(second_phone || "");

  //console.log('socials', socials)

  /* async function fetchImage() {
    
  }
  useEffect(() => {
    fetchImage()
  }, [user]) */

  useEffect(() => {
    console.log("user", user);
  }, [user]);

  return (
    <div className="flex flex-col h-full rounded-l-lg bg-transparent sm:bg-mains box-shadow text-gray-600 sm:text-white pb-0 sm:pb-8">
      {/* img */}
      <div className="flex justify-center bg-transparent sm:bg-mains mt-3">
        <img
          className="h-[117px] min-w-[117px] rounded-full object-cover aspect-square"
          src={`${user.profile}?time=${new Date().valueOf()}`}
          alt=""
          crossOrigin="anonymous"
        />
      </div>

      {/* mobile name area */}
      <h2 className="text-black sm:text-mains text-xl sm:text-2xl text-center font-semibold py-3 sm:hidden ">
        {name} {surname}
        {user.birth_date && (
          <span className="text-xl text-black px-3 ">
            (
            {new Date().getFullYear() - new Date(user.birth_date).getFullYear()}
            )
          </span>
        )}
      </h2>

      <div className="flex flex-col bg-white h-full rounded-lg shadow-sm sm:shadow-none sm:bg-mains pb-4">
        <div className=" bg-white sm:bg-transparent rounded-lg sm:rounded-none">
          <h4 className="text-xl p-3 mb-1 font-semibold sm:hidden">
            {isEnglish ? "Contact" : "İletişim"}
          </h4>

          {/* Address */}
          {address && district?.name && city?.name && country?.name_tr && (
            <div className={`mx-4 py-4 sm:border-none border-b `}>
              <CardInfo
                title={isEnglish ? "Address" : "Adres"}
                content={`
                  ${address}
                  <br/>
                  <span style="white-space: nowrap;">
                  ${district?.name} /
                  </span>
                   <span style="white-space: nowrap;">
                   ${isEnglish ? city?.name_en ?? city?.name : city?.name} /
                  </span>
                   <span style="white-space: nowrap;">
                   ${isEnglish ? country?.name_en : country?.name_tr}
                  </span>`}
                icon={<FaLocationDot />}
                isTruncate={false}
              />
            </div>
          )}

          {/* Telefon */}
          {phone && (
            <div className={`mx-4 py-4 sm:border-none border-b`}>
              <CardInfo
                title={isEnglish ? "Phone" : "Telefon"}
                content={`${phone_code} ${formattedPhone}`}
                icon={<FiPhone />}
                isTruncate={false}
              />
            </div>
          )}

          {/* Telefon 2 */}
          {second_phone && (
            <div className={`mx-4 py-4 sm:border-none border-b`}>
              <CardInfo
                title={isEnglish ? "Second Phone" : "2.Telefon"}
                content={`${second_phone_code} ${formattedSecondPhone}`}
                icon={<FiPhone />}
                isTruncate={false}
              />
            </div>
          )}

          {/* email */}
          {email && (
            <div className={`mx-4 py-4 sm:border-none border-b `}>
              <CardInfo
                title={isEnglish ? "E-Mail" : "E-Posta"}
                content={`${email}`}
                icon={<MdMailOutline />}
                isTruncate={false}
              />
            </div>
          )}

          {/* hobies */}
          {/* {cv?.hobby?.length ? (
            <div className="px-[16px]">
              <p className="text-sm font-semibold font-poppins my-2">Hobilerim</p>
              <div className="text-base" dangerouslySetInnerHTML={{ __html: cv?.hobby || "" }} />
            </div>
          ) : null} */}

          {/* Skills */}
          {/* {skills && skills.length > 0 && (
            <div className="hidden sm:block px-[16px]">
              <p className="text-sm font-semibold font-poppins my-2">
                {isEnglish ? "Skills" : "Yeteneklerrrr"}
              </p>
              <div className="flex flex-col gap-1">
                {skills.map((s) => {
                  return (
                    <label key={`skill${s.id}`} className="text-sm">
                      {s.name}
                    </label>
                  );
                })}
              </div>
            </div>
          )} */}

          {/* socials */}
          {socials?.length ? (
            <div className="flex flex-col px-[16px] gap-1">
              <p className="text-sm font-semibold text-gray-600 sm:text-white font-poppins my-1 pt-3">
                {isEnglish ? "Social Media" : "Sosyal Medya"}
              </p>
              {socials.map((item, index) => {
                const IconComponent = iconMap[item.social_account.name];
                return (
                  <CardInfo
                    key={index}
                    link={item.url}
                    icon={IconComponent}
                    isTruncate={true}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
