import React, { useRef } from 'react';
import ContactInfo from "./contact-info/index"
import ContactForm from "./contact-form/index"
const Contact: React.FC = () => {
    // Declare mapRef at the top level of the Contact component
    const mapRef = useRef<HTMLDivElement>(null);
    
  return (
    <div className="flex flex-col items-center font-poppins mb-8">
      {/* Full Width Map */}
      <div ref={mapRef} className="w-full">
        <iframe
          width="100%"
          height="450"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d458393.2807505469!2d28.83830264244115!3d40.99426397043528!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x14cab0ef82e4a059%3A0xbfca39c563412c7c!2zWWXFn2lscMSxbmFyIDM0MDY1IEV5w7xwc3VsdGFuL8Swc3RhbmJ1bA!3m2!1d41.072687699999996!2d28.9208388!5e0!3m2!1str!2str!4v1711182928753!5m2!1str!2str"
        >
        </iframe>
      </div>

    {/* Contact Form */}
    <div className="bg-white p-8 mt-8 rounded-lg shadow-lg max-w-6xl w-full">
    <div className="flex flex-wrap -mx-4">
    <ContactForm />
    <ContactInfo mapRef={mapRef}/>
       </div>
      </div>
    </div>
  );
};

export default Contact;
