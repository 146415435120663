import React from 'react';
import Contents from './contents';

const Application: React.FC = () => {
  return (
    <>
      <Contents/>
    </>
  );
};

export default Application;
