import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../services/axios";
import { toast } from "react-toastify";

const axiosInstance = new Axios();

export interface INotificationItem {
  code: string;
  description: string;
  status: boolean | null;
}



const initialState: INotificationItem[] = [];

// USER NOTIFICATION GET
export const getNotification = createAsyncThunk(
  "notification/getNotification",
  async () => {
    try {
      const response = await axiosInstance.request({
        type: "get",
        endpoint: "user/profilim/settings/get-email-preferences",
        payload: {},
      });
      if (!response.data) {
        throw new Error("Failed to fetch notification");
      }
      // 
      return response.data.data;
    } catch (error) {
      console.log("Error fetching user notification", error);
      throw error;
    }
  }
);

// USER NOTIFICATION POST
export const postNotification = createAsyncThunk(
  "notification/postNotification",
  async (checked: any) => {
    try {
      const response = await axiosInstance.request({
        type: "post",
        endpoint: "user/profilim/settings/set-email-preferences",
        payload: checked,
      });
      if (!response.data) {
        throw new Error("Failed to post notification");
      }
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      console.log("Error posting user notification", error);
      toast.error("Güncelleme esnasında bir hata oluştu!");
      throw error;
    }
  }
);

// USER NOTIFICATION SMS POST
export const postSms = createAsyncThunk(
  "notification/postSms",
  async (checked: any) => {
    try {
      const response = await axiosInstance.request({
        type: "post",
        endpoint: "user/profilim/settings/set-sms-preferences",
        payload: checked,
      });
      if (!response.data) {
        throw new Error("Failed to post sms information");
      }
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      console.log("Error posting user sms information", error);
      toast.error("Güncelleme esnasında bir hata oluştu!");
      throw error;
    }
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotification.fulfilled, (state, action) => {
      return action.payload;
    });
  },
});

export default notificationSlice.reducer;
