import { createElement } from "react";

export interface CardInfoProps {
  title?: string;
  content?: string;
  icon: any;
  isTruncate?: boolean;
  link?: string;
}



const CardInfo = ({ title, icon: Icon, content, isTruncate = false, link }: CardInfoProps) => {

  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <div className="min-w-4 text-base text-gray-600 sm:text-white">
          {Icon}
        </div>
        <span className="font-semibold px-1 text-gray-600 sm:text-white text-base font-poppins">{title}</span>
        {/* 
        TODO: Eski hali gerekli olursa silinecek
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className={`font-normal text-sm font-poppins ${isTruncate ? "truncate" : ""}`}
        >
          {link}
        </a> */}
        <label
          className={`font-normal text-sm text-gray-600 sm:text-white font-poppins ${isTruncate ? "truncate" : ""}`}
        >
          {link}
        </label>
      </div>
      <div className={`text-sm font-poppins text-gray-600 sm:text-white break-all ${isTruncate ? "truncate" : ""}`}
      dangerouslySetInnerHTML={{ __html: content || "" }}
      ></div>
    </div>
  );
}


export default CardInfo
