import React, { useState, useEffect, useRef } from "react";
import { set } from "react-hook-form";
import { FaAngleUp, FaTimes } from "react-icons/fa";
import { IData } from "../../../../types";

interface SelectProps {
  data: any;
  label: string;
  placeholder?: string;
  blue?: boolean;
  error?: boolean;
  onChange?: (value: number | number[]) => void;
  selectedvalue?: any | undefined;
  disabled?: boolean;
  keyString: string;
  checkWithValue?: boolean;
}

function SelectComponent({
  data,
  label,
  blue = false,
  placeholder = "Seçiniz",
  onChange,
  selectedvalue,
  keyString,
  disabled = false,
  checkWithValue = false,
  error = false,
}: SelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState("");
  const containerRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (value: any) => {
    //setSelected(value);
    if (checkWithValue) {
      onChange && onChange(value.value);
    } else {
      onChange && onChange(value.id);
    }

    setIsOpen(false);
  };
  const handleClearButton = () => {
    //setSearchData && setSearchData("");
    onChange && onChange(0);
  };

  useEffect(() => {
    if (!selectedvalue || selectedvalue === undefined || data.length === 0) {
      setSelectedLabel("");
    } else {
      setSelectedLabel(
        checkWithValue
          ? data.filter((el: any) => el.value === selectedvalue)[0][keyString]
          : data.filter((el: any) => el.id === selectedvalue)[0][keyString]
      );
    }
  }, [selectedvalue, data]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative flex-1 w-full" ref={containerRef}>
      <p className="text-sm text-gray-600 md:text-base">{label}</p>
      <button
        className={`${
          blue || (isOpen && !disabled)
            ? "border-primary200 text-primary200"
            : "border-gray-400"
        }
				${selectedLabel !== "" ? "text-primary200  border-mains" : "text-gray-600"}
				${error ? "bg-red-50 border-red-500" : "bg-white"}
				 border rounded-md text-sm md:text-base shadow-sm px-4 py-2 w-full flex justify-between items-center text-left text-nowrap text-ellipsis`}
        onClick={toggleDropdown}
        type="button"
      >
        {selectedLabel !== "" ? selectedLabel : placeholder}
        <div
          className={`flex transition-all ${
            selectedLabel !== "" || selectedvalue
              ? "w-4 justify-end"
              : "w-4 justify-end"
          }`}
        >
          <FaAngleUp
            onClick={(e) => {
              e.stopPropagation();
              toggleDropdown();
            }}
            className={`transition-transform duration-300 transform ${
              !(isOpen && !disabled) ? "rotate-180" : ""
            }`}
          />
        </div>
      </button>
      {isOpen && !disabled && (
        <div className="absolute z-10 mt-[1px] flex-1  bg-white  border-gray-300 w-full shadow-lg ">
          <div className="py-1 overflow-y-auto border-2 rounded-md shadow max-h-40">
            {data.map((item: any) => {
              const updatedLabel =
                item[keyString] === "Tumu" ? "Tümü" : item[keyString];
              return (
                <div
                  key={item.id + item[keyString]}
                  className="px-4 py-2 text-gray-700 cursor-pointer hover:bg-primary200 hover:text-white"
                  onClick={() => handleSelect(item)}
                >
                  {updatedLabel}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

const handleSelectChange = (
  name: string,
  value: string,
  arr: any[],
  selectedValues: any[],
  setSelectedValues: (value: any) => void
) => {
  let findId = arr.find((element) => element.label === value)?.value;

  if (
    name === "sectors" ||
    name === "sections" ||
    name === "driving_licence_srcs" ||
    name === "driving_licence_classes"
  ) {
    findId = `[${findId}]`;
  } else {
    findId = `${findId}`;
  }
  const existingIndex = selectedValues.findIndex(
    (element: any) => element[name] !== undefined
  );
  // const existingIndexChild = selectedValues.findIndex(
  //   (element: any) => "university_branch" in element
  // );

  if (existingIndex !== -1) {
    // If the attribute already exists, update its value
    const updatedValues = [...selectedValues];
    updatedValues[existingIndex] = {
      ...updatedValues[existingIndex],
      [name]: findId,
    };
    // if (existingIndexChild) {
    //   updatedValues[existingIndexChild] = {
    //     ...updatedValues[existingIndexChild],
    //     ["university_branch"]: undefined,
    //   };
    // }
    setSelectedValues(updatedValues);
  } else {
    // If the attribute doesn't exist, add a new entry
    setSelectedValues([...selectedValues, { [name]: findId }]);
  }
};

export { SelectComponent, handleSelectChange };
