import { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import ImageCropper from "./ImageCropper";
import { IoClose } from "react-icons/io5";

















export default function ProfilePhotoChanger({
    getAllDatas,
    setIsModalOpen,
    lang = 'tr'
}: {
    getAllDatas?: () => void,
    setIsModalOpen: Dispatch<SetStateAction<boolean>>,
    lang?: 'tr' | 'en'
}) {

    const divRef = useRef<HTMLDivElement>(null)

    const [isEnglish, setIsEnglish] = useState<boolean>(false)

    useEffect(() => {
        if (lang == 'en') {
            setIsEnglish(true)
        } else {
            setIsEnglish(false)
        }
    }, [lang])

    useEffect(() => {

        function handleClickOutside(event: MouseEvent) {
            if (divRef.current && !divRef.current.contains(event.target as Node)) {
                setIsModalOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }

    }, [divRef])
    return(
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-30 z-[100] ">
            <div ref={divRef} className="flex flex-col p-4 bg-white rounded-md max-w-[calc(100vw)] max-h-[calc(100vh)] lg:max-w-[1000px] lg:max-h-[calc(100vh-20px)] overflow-auto m-0 lg:m-3 h-full lg:h-auto w-full shadow-form-glow">
                <div className="flex flex-row items-center justify-between">
                    <label className="font-poppins text-lg font-semibold text-mains">
                        {isEnglish? 'Add Profile Photo': 'Profil Fotoğrafı Ekle'}
                    </label>
                    <button type="button" onClick={() => setIsModalOpen(false)}>
                        <IoClose className="text-gray-600 text-2xl"/>
                    </button>
                </div>
                <hr/>
                <ImageCropper
                isEnglish={isEnglish}
                setIsModalOpen={setIsModalOpen}
                getAllDatas={getAllDatas}
                />
            </div>
        </div>
    )
}