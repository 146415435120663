import * as React from "react";
import { EyeIcon, EyeOffIcon } from "../../../theme/icons";
import { InputType } from "../../../containers/login-page-container/company/register/constants/enum";
import MaskedInput from "react-text-mask";
import { useMask } from "@react-input/mask";

interface ICustomInputProps {
  readonly name?: string;
  readonly type: string;
  readonly value: string;
  readonly onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readonly placeholder: string;
  readonly className?: string;
  readonly phoneMaskType?: "phone" | "landline" | "taxNumber";
  maxLength?: number;
}

function CustomInput(props: ICustomInputProps): React.ReactElement {
  const [showPassword, setShowPassword] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);

  const telRef = useMask({
    mask: "(5__) ___ __ __",
    replacement: { _: /\d/ },
    showMask: true,
  });

  const landlineMask = useMask({
    mask: "(___) ___ __ __",
    replacement: { _: /\d/ },
    showMask: true,
  });

  const vergiRef = useMask({
    mask: "___________",
    replacement: { _: /\d/ },
    showMask: false,
  });

  const togglePasswordVisibility = (): void => {
    setShowPassword((prev) => !prev);
  };
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [displayPlaceholder, setDisplayPlaceholder] = React.useState(true);
  React.useEffect(() => {
    setDisplayPlaceholder(!props.value);

    // Setup to detect autofill
    const timeout = setTimeout(() => {
      setDisplayPlaceholder(!props.value);
    }, 100); // Checking after 100ms; adjust time as needed

    return () => clearTimeout(timeout);
  }, [props.value]);
  const renderPlaceholderWithRedAsterisk = () => {
    return (
      <label
        className={`  pointer-events-none text-xs poppins-medium
       items-center flex w-full mb-1
       gap-x-1
      `}
        style={{ color: "#6B7280" }}
      >
        {props.placeholder?.split("*").map((part, index) => (
          <React.Fragment key={index}>
            {index > 0 && <span style={{ color: "red" }}>*</span>}
            {part}
          </React.Fragment>
        ))}
      </label>
    );
  };

  const validatePhoneNumber = (phoneNumber: string): boolean => {
    return (
      phoneNumber.match(/^0 \(\d{3}\) \d{3} \d{2} \d{2}$/) !== null ||
      phoneNumber === ""
    );
  };

  const guideMask = (value: string): (string | RegExp)[] => {
    if (props.phoneMaskType === "landline") {
      if (value === "")
        return [
          "0",
          " ",
          "(",
          /\d/,
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
        ];
      if (value.startsWith("0 ("))
        return [
          "0",
          " ",
          "(",
          /\d/,
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
        ];
      return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    } else if (props.phoneMaskType === "phone") {
      if (value === "")
        return [
          "0",
          " ",
          "(",
          "5",
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
        ];
      if (value.startsWith("0 (5"))
        return [
          "0",
          " ",
          "(",
          "5",
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
        ];
      return [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    } else if (props.phoneMaskType === "taxNumber") {
      if (value === "")
        return [
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          /\d/,
        ];
      return [
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    }
    return [];
  };

  const handleFocus = () => {
    setIsFocused(true);
    if (phoneNumber === "") {
      if (props.phoneMaskType === "landline") {
        setPhoneNumber("0 (");
        setDisplayPlaceholder(false);
      } else if (props.phoneMaskType === "phone") {
        setPhoneNumber("0 (5");
        setDisplayPlaceholder(false);
      } else {
        setPhoneNumber("");
        setDisplayPlaceholder(false);
      }
      setDisplayPlaceholder(false);
    }
  };

  const getMaskRef = () => {
    if (props.phoneMaskType === "phone") {
      return telRef;
    } else if (props.phoneMaskType === "landline") {
      return landlineMask;
    } else if (props.phoneMaskType === "taxNumber") {
      return vergiRef;
    }
    return null;
  };

  const maskRef = getMaskRef();

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(props.value !== "");
    if (!validatePhoneNumber(event.target.value)) {
    } else if (event.target.value === "") {
      setPhoneNumber("");
      setDisplayPlaceholder(true);
    }
  };

  if (props.type === InputType.Password) {
    return (
      <div className="relative">
        {renderPlaceholderWithRedAsterisk()}
        <input
          name={props.name}
          type={showPassword ? "text" : "password"}
          value={props.value}
          onChange={props.onChange}
          maxLength={props.maxLength}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(props.value !== "")}
          className={`text-xs border border-gray-300 poppins-medium text-black px-2 w-full bg-white rounded-md outline-none ${
            props.className || ""
          } h-10`}
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute flex items-center right-2 top-7 gap-x-1"
        >
          <span className="text-xs poppins-medium text-mains">
            {showPassword ? "Gizle" : "Göster"}
          </span>
          {showPassword ? (
            <EyeIcon size={24} color="#195edd" />
          ) : (
            <EyeOffIcon size={24} color="#195edd" />
          )}
        </button>
      </div>
    );
  }

  if (props.type === InputType.Checkbox) {
    const handleCheckboxChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      props.onChange({
        ...event,
        target: {
          ...event.target,
          value: event.target.checked.toString(),
        },
      });
    };

    return (
      <div className="flex items-center gap-x-1">
        <input
          className="h-4 w-4"
          name={props.name}
          type="checkbox"
          onChange={handleCheckboxChange}
          checked={
            props.value === "true" || props.value === "false"
              ? JSON.parse(props.value)
              : props.value
          }
        />
        <label className="text-xs text-black poppins-medium">
          {props.placeholder}
        </label>
      </div>
    );
  }

  if (props.type === InputType.Tel) {
    return (
      <div className="relative">
        {renderPlaceholderWithRedAsterisk()}
        <input
          ref={maskRef}
          name={props.name}
          type={props.type}
          value={props.value}
          onChange={(event) => {
            setPhoneNumber(event.target.value);
            props.onChange(event);
            setDisplayPlaceholder(event.target.value === "");
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={`text-xs border border-gray-300 poppins-medium
         text-black px-2 w-full bg-white 
        rounded-md outline-none ${props.className || ""} h-10`}
        />
      </div>
    );
  }
  if (props.type === InputType.TCorvergi) {
    return (
      <div className="relative">
        {renderPlaceholderWithRedAsterisk()}
        <input
          ref={vergiRef}
          name={props.name}
          type={props.type}
          value={props.value}
          onChange={(event) => {
            setPhoneNumber(event.target.value);
            props.onChange(event);
            setDisplayPlaceholder(event.target.value === "");
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className={`text-xs border border-gray-300 poppins-medium
         text-black px-2 w-full bg-white 
        rounded-md outline-none ${props.className || ""} h-10`}
        />
      </div>
    );
  }

  return (
    <div className="relative">
      {renderPlaceholderWithRedAsterisk()}
      <input
        name={props.name}
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(props.value !== "")}
        className={`text-xs border border-gray-300 poppins-medium text-black px-2 
        w-full bg-white rounded-md outline-none ${props.className || ""} h-10`}
      />
    </div>
  );
}

export { CustomInput };
