import React, { useState, useEffect, useContext } from "react";
import "leaflet/dist/leaflet.css";
import { FaPlus, FaTimes } from "react-icons/fa";
import { useCookie } from "../../../../hooks/useCookie";
import { CookieName } from "../../../../constants/enums";
import Axios from "../../../../services/axios";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { SelectComponent } from "../../candidate-pool-page/info-section/update-section/select-component";
import { IChangeProps, IStatus, ITitle } from "../../candidate-pool-page/types";
import useFetchCdnQuery from "../../../../hooks/useFetchCdnQuery";
import { toast } from "react-toastify";
import { CVPreviewContext } from "../contents";

const axios = new Axios();
interface WorkingLocationPopupProps {
  onClose: () => void;
  onSuccess?: () => void;
}
interface IFolder {
  id: number;
  title: string;
  company_folder_cvs_count?: number;
}

const AddFolderPopup: React.FC<WorkingLocationPopupProps> = ({ onClose }) => {
  const [isVisible, setIsVisible] = useState(false);

  const { data, fetchIncompleteDetail } = useContext(CVPreviewContext);

  const { getCookie } = useCookie();
  const companyUserToken = getCookie(CookieName.CompanyUserToken);

  const [folderValue, setFolderValue] = useState<number>(0);
  const [statusValue, setStatusValue] = useState<number>(0);
  const [fetchFolders, setFetchFolders] = useState<IFolder[]>([]);
  const [values, setValues] = useState<IChangeProps>({
    folder: 0,
    status: 0,
    arr: [data.cv.id],
  });
  const [counter, setCounter] = useState<number>(0);

  //fetch folders
  const fetchFolderNames = async () => {
    try {
      const response = await axios.getCvFolders(companyUserToken);

      if (response && response.folders) {
        const res = response.folders;
        res.unshift({
          id: 0,
          title: "Yeni Klasör ekle",
          name: "Yeni Klasör ekle",
        });
        setFetchFolders(res);
      }
    } catch (error) {
      throw new Error("Error fetching folder names: " + error);
    }
  };
  useEffect(() => {
    fetchFolderNames();
  }, [companyUserToken]);
  const folders: ITitle[] = fetchFolders as ITitle[];
  const folderData = folders.map((item: ITitle) => {
    return {
      id: item.id,
      value: item.title,
      name: item.title,
    };
  });

  //fetch statuses
  const statusesQuery = useFetchCdnQuery(
    "statuses",
    "get-company-candidate-statuses",
    "company_candidate_statuses"
  );
  const StatusData = statusesQuery.data
    ? (statusesQuery.data.map((item: any) => {
        return {
          id: item.id,
          value: item.value,
          name: item.name,
        };
      }) as Array<IStatus>)
    : [];

  //set values
  useEffect(() => {
    setValues({
      folder: folderValue,
      status: statusValue,
      arr: [data.cv.id],
    });
  }, [folderValue, statusValue]);

  //send values
  useEffect(() => {
    const updateCvStatues = async () => {
      if (counter === 0) return;
      if (folderValue == 0) {
        toast.warning("Klasör seçimi yapmanız gerekiyor");
        return;
      }

      try {
        const response = await axios.updateMultiCvStatus(
          values.status,
          values.folder,
          values.arr,
          companyUserToken
        );

        if (response) {
          toast.success("Aday başarıyla klasöre eklendi");
          fetchIncompleteDetail();
          handleCloseClick();
        }
      } catch (error) {
        toast.warning("Aday bu klasöre daha önce eklenmiş.");
        //throw new Error("Error fetching updateCvStatues: " + error);
      }
    };

    updateCvStatues();
  }, [counter]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      document.body.classList.add("overflow-hidden", "h-auto");
      setIsVisible(true);
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  const handleCloseClick = () => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
      document.body.classList.remove("overflow-hidden", "h-auto");
    }, 500);
  };

  const handleClick = () => {
    setCounter(counter + 1);
  };

  const handleFolderChange = (value: number) => {
    setFolderValue(value);
  };

  const handleStatusChange = (value: number) => {
    setStatusValue(value);
  };

  return (
    <div className="fixed inset-0 z-[1050] flex items-start justify-center overflow-y-auto ">
      <div
        className="fixed inset-0 bg-black opacity-50 h-auto "
        onClick={handleCloseClick}
      ></div>

      <div
        className={`bg-white my-10 max-md:my-0 max-md:fixed max-md:top-0 max-md:left-0 max-md:w-screen max-md:h-screen max-md:overflow-y-auto md:rounded-lg md:shadow-lg  md:relative md:min-w-[40%] transition-all duration-500 ease-in-out transform ${
          isVisible ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-10"
        }`}
      >
        <div className={"flex justify-between border-b-2 py-5 px-5"}>
          <div className={"text-mains font-bold text-lg"}>Klasöre Ekle</div>
          <div
            onClick={handleCloseClick}
            className={
              "flex justify-self-end justify-center items-center w-5 h-5 "
            }
          >
            <FaTimes className={"text-gray-400"} />
          </div>
        </div>

        <div className="flex flex-col gap-3 items-start justify-center  p-5 w-full">
          <div className="w-full mt-2">
            Klasör<span className={"align-super text-red-500"}>*</span>:
          </div>
          <SelectComponent
            blue={values.folder !== 0}
            data={folderData}
            label=""
            placeholder="Klasör Seçiniz"
            onChange={(value) => handleFolderChange(value)}
            selectedvalue={values.folder}
            callBack={() => {
              fetchFolderNames();
            }}
          />
          <div className="w-full mt-2">Durum:</div>
          <SelectComponent
            data={StatusData}
            label=""
            placeholder="İncelenmedi"
            blue={true}
            onChange={(value) => handleStatusChange(value)}
            selectedvalue={values.status}
          />
        </div>

        <div className={"flex justify-end gap-5 border-t-2 py-5 px-5"}>
          <div
            onClick={handleCloseClick}
            className={
              "p-3 bg-gray-200 hover:bg-[#ff7f1d] hover:text-white transition-all rounded cursor-pointer font-bold"
            }
          >
            Kapat
          </div>
          <div
            onClick={handleClick}
            className={
              "p-3 bg-mains text-white justify-self-center rounded cursor-pointer font-bold flex items-center "
            }
          >
            <FaPlus className="inline-block mr-3" />
            Klasöre Ekle
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddFolderPopup;
