import { Button } from "../../../../../../components/isbul-ui";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ShareModal from "../shareModal";
import Axios from "../../../../../../services/axios";
import { ICv } from "../types/cv";
import { IUser } from "../types/users";

const axios = new Axios();

export default function MobileDownloadAndShare({
  htmlRef,
  data,
}: {
  htmlRef: any;
  data: {
    cv: ICv;
    user: IUser;
  };
}) {
  const [pdfUrl, setPdfUrl] = useState<any>();
  const [showModal, setShowModal] = useState(false);

  const { cv, user } = data;

  const location = useLocation();

  const action = new URLSearchParams(location.search).get("action");

  useEffect(() => {
    /* if (action == 'download') {
            download()
        } */
    if (action == "share") {
      setShowModal(true);
    }
  }, [action, htmlRef]);

  return (
    <div className="flex flex-row items-center justify-between p-4">
      <Button type="button" variant="ghost" size="sm">
        Paylaş
      </Button>
      <Button type="button" size="sm" onClick={download}>
        CV İndir
      </Button>

      <ShareModal
        showModal={showModal}
        setShowModal={setShowModal}
        pdfUrl={pdfUrl}
      />
    </div>
  );

  async function download() {
    if (htmlRef.current === null) return;

    const input = htmlRef.current;
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const canvas = await html2canvas(input, {
      useCORS: true,
      allowTaint: false,
    });
    const imgData = canvas.toDataURL("image/png");

    const imgProps = pdf.getImageProperties(imgData);
    const imgWidth = pdfWidth;
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pdfHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pdfHeight;
    }

    pdf.save(`${new Date().getTime()}.pdf`);
  }

  function share() {}
}
