import { ReactNode, useState } from "react";
import TabList from "./List";
import TabPanels from "./Panels";
import TabPanel from "./Panel";
import cn from "classnames";
import Tab from "./Tab";
import { TabContext } from "./context";

interface TabContainerProps {
  children: ReactNode;
  defaultActiveTab?: string;
  className?: string;
  activeTab?: string;
  setActiveTab?: (id: string) => void;
}

export const TabContainer: React.FC<TabContainerProps> & {
  TabList: typeof TabList;
  Tab: typeof Tab;
  TabPanels: typeof TabPanels;
  TabPanel: typeof TabPanel;
} = ({ children, defaultActiveTab, className, ...props }) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab || "");

  return (
    <TabContext.Provider
      value={{
        activeTab: props.activeTab ? props.activeTab : activeTab,
        setActiveTab: props.setActiveTab ? props.setActiveTab : setActiveTab,
      }}
    >
      <div className={cn("w-full max-w-2xl mx-auto", className)}>
        {children}
      </div>
    </TabContext.Provider>
  );
};

TabContainer.TabList = TabList;
TabContainer.Tab = Tab;
TabContainer.TabPanels = TabPanels;
TabContainer.TabPanel = TabPanel;
