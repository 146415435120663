import React from 'react';

const SalariesBasedOnExperience: React.FC = () => {
  const salaryData = [
    { ExperienceTime: "0-1", minSalary: 10000, avgSalary: 15000, maxSalary: 20000 },
    { ExperienceTime: "2-4", minSalary: 11000, avgSalary: 16000, maxSalary: 21000 },
    { ExperienceTime: "5-9", minSalary: 12000, avgSalary: 17000, maxSalary: 22000 },
    { ExperienceTime: "10 Yil +", minSalary: 13000, avgSalary: 18000, maxSalary: 23000 },
  ];

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      <div className="p-6">
        <h1 className='text-2xl'>Deneyime Göre Maaşlar</h1>
        <p className="mt-4">
        Yazılımcı, deneyimine göre aylık ne kadar maaş alır inceleyebilirsiniz.
        </p>
        <div className="grid grid-cols-4  gap-4 mb-2 mt-4 font-bold">
              <div>Yıl</div>
              <div>En Düşük Maaş</div>
              <div>Ortalama Maaş</div>
              <div>En Yüksek Maaş</div>
            </div>
            {salaryData.map((data) => (
                <div>
              <div key={data.ExperienceTime} className="grid grid-cols-4 gap-4 items-center mb-4 mt-4">
                <div className="flex items-center">
                  <div className="w-16 h-16 text-center flex justify-center items-center rounded-full bg-blue-500 text-white text-sm px-4">
                    {data.ExperienceTime}
                  </div>
                </div>
                <div>{data.minSalary.toLocaleString()} TL</div>
                <div>{data.avgSalary.toLocaleString()} TL</div>
                <div>{data.maxSalary.toLocaleString()} TL</div>
              </div>
            <hr ></hr>
          </div>
         ))}
      </div>
    </div>
  );
};

export default SalariesBasedOnExperience;
