import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import LeftDashboard from "../../components/common/profile/left-dashboard";

import HomePage from "./dashboard";
import Application from "./application";
import Cv from "./cv";
import Files from "./files";
import Favorite from "./favorite";
import WorkAlarm from "./work-alarm";
import Message from "./message";
import Interview from "./interview-list";
import Settings from "./settings";
import CvDetail from "./cv-detail/index";
import CvEdit from "./cv-edit/index";
import Welcome from "./welcome/index"; // Örnek olarak eksik bilgi bileşeni
import services from "../../services/profile-services";
import { useAppDispatch } from "../../store/store";
import { getUserInfo } from "../../store/content-reducer/personInfoSlice";
import { getNotification } from "../../store/content-reducer/notificationSlice";
import Body from "./favorite/saved-ads/contents";
import Breadcrump from "../../components/common/profile/breadcrump";

const Dashboard: React.FC = () => {
  const [userHasAccess, setUserHasAccess] = useState(true); // Kullanıcının erişim yetkisi

  const dispatch = useAppDispatch();
  const location = useLocation();
  console.log(location.pathname);

  useEffect(() => {
    dispatch(getUserInfo()); // sayfa yüklendiğinde ayarlar kısmı kullanıcı datasını almak için
    dispatch(getNotification()); // sayfa yüklendiğinde ayarlar kısmı  bildirimler datasını almak için

    const checkUserAccess = async () => {
      try {
        const hasAccess = await services.welcomeSetup();

        console.log(hasAccess.data);
        if (hasAccess && hasAccess.data.is_setup === true) {
          setUserHasAccess(true);
        } else {
          setUserHasAccess(false);
        }
      } catch (error) {
        console.error("Kullanıcı erişim yetkisi kontrol edilirken bir hata oluştu:", error);
      }
    };

    checkUserAccess();
  }, []);

  if (!userHasAccess) {
    return <Welcome />;
  }

  // Kullanıcı erişim iznine sahipse, dashboard içeriğini göster.
  return (
    <div className="container mx-auto md:mt-10 mb-10">
      <div className="lg:flex lg:gap-x-5 gap-y-5">
        {location.pathname !== "/cv/duzenle/:slug/*" && (
          <div className="hidden w-full lg:w-2/6 xl:w-3/12 2xl:w-3/12 lg:block">
            <LeftDashboard />
          </div>
        )}
        <div className="w-full mt-5 lg:w-full xl:w-full 2xl:w-full lg:mt-0">
          {/* <div className="mb-6">
            <Breadcrump type="page" pageName="AYARLAR" />
          </div> */}
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/cv" element={<Cv />} />
            <Route path="/cv/goruntule/:slug" element={<CvDetail />} />
            <Route path="/cv/duzenle/:slug/*" element={<CvEdit />} />
            <Route path="/dosyalar-onyazilar" element={<Files />} />
            <Route path="/başvurular" element={<Application />} />
            <Route path="/favoriler" element={<Favorite />} />
            <Route path="/favoriler/liste" element={<Body />} />
            {/* <Route path="/follow" element={<Body />} /> */}
            <Route path="/is-alarmlari" element={<WorkAlarm />} />
            <Route path="/mesajlar" element={<Message />} />
            <Route path="/mulakat-listesi" element={<Interview />} />
            <Route path="/ayarlar" element={<Settings />} />
            <Route path="hosgeldin" element={<Navigate to="/profilim" />} />
            <Route path="hosgeldin/istercihleri" element={<Navigate to="/profilim" />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
