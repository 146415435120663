import { LabelHTMLAttributes, useContext } from "react"
import { SelectSearchDropdownContext, selectSearchDropdownContextType } from "."






type labelPropsType = LabelHTMLAttributes<HTMLLabelElement> & {

}







export default function Label({
    onClick,
    className,
    children,
    ...props
}: labelPropsType) {

    const { required, setIsOpen }: selectSearchDropdownContextType = useContext(SelectSearchDropdownContext)

    return(
        <label 
        {...props} 
        className={`${className} text-[13px] text-gray-700 font-semibold font-poppins`}
        onClick={((e) => {
            if (onClick) {
                onClick(e)
            }

            setIsOpen((prev) => !prev)
        })}
        >
            {children} {required? (
                <b className="text-red-600">*</b>
            ): null}
        </label>
    )
}