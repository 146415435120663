import { Fragment, useEffect, useState } from "react";
import Axios from "../../../services/axios";
import Card from "../setting-page/content/Card";
import { BsSearch, BsThreeDotsVertical } from "react-icons/bs";
import { Popover, Transition } from "@headlessui/react";
import { HiOutlineTrash } from "react-icons/hi";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import CustomModal from "../../../components/common/custom-modal";
import Swal from "sweetalert2";
import { FiAlertCircle } from "react-icons/fi";

type Props = {};
type Search = {
  id: any;
  title: string;
  params: any;
};
const axios = new Axios();
const SavedSearches = (props: Props) => {
  const [search, setSearch] = useState<Search[]>([]);
  const [filteredSearch, setFilteredSearch] = useState<Search[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const getSavedSearch = async () => {
    const response = await axios.authRequest({
      endpoint: "companies/search/get-saved-filter",
      type: "get",
      payload: {},
    });
    setSearch(response.data.company_searches);
    setFilteredSearch(response.data.company_searches);
  };
  // delete search
  const deleteSearch = async (id: number) => {
    const response = await axios.authRequest({
      endpoint: "companies/search/delete-filter",
      type: "delete",
      payload: {
        data: {
          filter_id: id,
        },
      },
    });
    if (response.data.status) {
      getSavedSearch();
    }
  };
  const handelFilter = (e: any) => {
    const value = e.target.value;
    setFilteredSearch(
      search.filter((item) =>
        item.title.toLowerCase().includes(value.toLowerCase())
      )
    );
  };
  useEffect(() => {
    getSavedSearch();
  }, []);
  function generateLinkWithSortedParams(params: any) {
    const paramString = new URLSearchParams(JSON.parse(params)).toString();

    navigate(`/isveren/aday-havuzu?${paramString}`);
  }

  const handleDelete = (item: Search) => {
    Swal.fire({
      icon: "warning",
      title: "Uyarı!",
      text: "Bu kayıtlı aramayı silmek istediğinize emin misiniz?",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await deleteSearch(item.id);
        Swal.fire("Silindi!", "", "success");
      }
    });
  };
  const resultCard = (item: Search) => {
    return (
      <div className="flex items-center gap-2 w-full justify-between p-3 rounded-md bg-[#f8f8f8] shadow-md">
        <Link
          to={`/isveren/aday-havuzu?${new URLSearchParams(
            JSON.parse(item.params)
          ).toString()}`}
          className="text-lg capitalize font-semibold text-gray-500"
        >
          {item.title}
        </Link>
        <Popover>
          {({ open }) => (
            <div className="relative">
              <Popover.Button>
                <BsThreeDotsVertical className="text-gray-500 text-[24px]" />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute -end-1 top-4 z-10 mt-3  before:w-4 before:h-4 before:bg-white before:absolute before:rotate-45 before:-top-2 before:end-2">
                  <div className="flex flex-col bg-white p-3 shadow-md gap-2">
                    <Popover.Button
                      className="text-sm font-semibold flex justify-center break-keep items-center !text-nowrap text-nowrap gap-2 text-title border border-gray-300 p-2 rounded-md hover:bg-title hover:text-white transition-all"
                      onClick={() => {
                        handleDelete(item);
                      }}
                    >
                      <HiOutlineTrash className="w-[20px] h-[20px]" />
                      Aramayı Sil
                    </Popover.Button>
                    <Popover.Button
                      className="text-sm font-semibold flex justify-center !break-keep items-center !text-nowrap gap-2 text-title border border-gray-300 p-2 rounded-md hover:bg-title hover:text-white transition-all"
                      as={Link}
                      to={`/isveren/aday-havuzu?${new URLSearchParams(
                        JSON.parse(item.params)
                      ).toString()}`}
                    >
                      <MdOutlineRemoveRedEye className="w-[20px] h-[20px]" />
                      Görüntüle
                    </Popover.Button>
                  </div>
                </Popover.Panel>
              </Transition>
            </div>
          )}
        </Popover>
      </div>
    );
  };
  return (
    <>
      <div className="container m-auto">
        <Card className="my-3">
          <h1 className="text-[19px] text-title font-bold">
            KAYITLI ARAMALARIM
          </h1>
        </Card>
        <Card className="my-3">
          {filteredSearch.length > 0 && (
            <div className="flex justify-end">
              <div className="relative max-w-[375px] w-full">
                <BsSearch className="text-gray-500 text-[24px] absolute start-2 top-2" />

                <>
                  <input
                    type="text"
                    placeholder="Kayıt Ara"
                    className="ps-10 py-4 w-full focus-visible:outline-none focus:border-title transition-all h-[40px] text-sm rounded-md border-2 border-gray-300 "
                    onChange={handelFilter}
                  />
                </>
              </div>
            </div>
          )}

          {filteredSearch.length === 0 && (
            <>
              {" "}
              <div className="flex items-center mt-4 mb-12 ml-4 mr-4 shadow-lg border rounded-xl">
                <span className="bg-[red] border border-blue mr-4 h-full text-white pt-3 pb-3 pl-1 pr-1 rounded-md">
                  <FiAlertCircle size={35} />
                </span>

                <p className="font-poppins poppins-semibold text-sm flex justify-center flex-col items-center gap-[6px]">
                  <span>Kayıtlı Arama Bulunamadı.</span>
                  {/* <a
                    href="/isveren/ilanlar/olustur/ilan-bilgileri"
                    className="underline text-blue-400"
                  >
                    Yeni İlan Oluşturmak İçin Tıklayın
                  </a> */}
                </p>
              </div>
            </>
          )}

          <div className="flex mt-3 flex-col gap-3 ">
            {filteredSearch.length > 0
              ? filteredSearch.map((item) => resultCard(item))
              : search.map((item) => resultCard(item))}
          </div>
        </Card>
      </div>
    </>
  );
};

export default SavedSearches;
