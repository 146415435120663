import React, { useState, useRef } from 'react';

interface KeywordAreaProps {
    onKeywordsChange: (newKeywords: string[]) => void;
    contextKeywords?: string[]
  }
  export const KeywordArea: React.FC<KeywordAreaProps> = ({ onKeywordsChange, contextKeywords }) => {
    
    const [keywords, setKeywords] = useState<string[]>(
        contextKeywords?
        //TODO: burada contexten gelen veri ile tip uyuşmazlığı var kontrol edilecek
        contextKeywords.map((k: any) => {
            return k.keyword
        }):
        []
    );
    const [inputValue, setInputValue] = useState<string>('');
    const inputRef = useRef<HTMLInputElement>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && inputValue.trim()) {
            e.preventDefault(); // Prevent form submission
            if (!keywords.includes(inputValue.trim())) {
                const newKeywords = [...keywords, inputValue.trim()];
                setKeywords(newKeywords);
                onKeywordsChange(newKeywords); // Update parent state
                setInputValue('');
            }
        }
    };

    const removeKeyword = (index: number) => {
        const newKeywords = keywords.filter((_, i) => i !== index);
        setKeywords(newKeywords);
        onKeywordsChange(newKeywords); // Update parent state
    };

    const focusInput = () => {
        inputRef.current?.focus();
    };

    
    return (
        <div className='w-full mb-3'>
            <div className='w-full flex flex-col'>
                <label htmlFor="keywordInput" className='font-medium text-gray-500 text-sm mb-2 ml-0'>
                    Anahtar Kelimeler<small> (İlan arama ve filtrelemede bulunabilirliğinizi arttırmak için kullanılır)</small>
                </label>
                <div 
                    className='w-full flex flex-wrap items-center border border-gray-400 rounded-lg p-2 shadow-none'
                    onClick={focusInput}
                    style={{outline: 'none'}} // This removes the outline
                >
                    {keywords.map((keyword, index) => (
                        <div key={index} className="flex items-center bg-blue-100 text-blue-700 text-md px-2 py-1 rounded-xl m-1">
                            {keyword}
                            <button
                                type="button"
                                className='flex-1 py-1 px-2 border-none focus:outline-none focus:ring-0 text-gray-700'
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent the input from losing focus
                                    removeKeyword(index);
                                }}
                            >
                                &times;
                            </button>
                        </div>
                    ))}
                    <input
                        ref={inputRef}
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        className='flex-1 py-1 px-2 border-none focus:ring-0 text-gray-700 bg-transparent'
                        id="keywordInput"
                        style={{ outline: 'none' }} // This removes the outline on focus as well
                    />
                </div>
            </div>
        </div>
    );
};
