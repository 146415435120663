import React from "react";
import { ContentIndex } from "./contents";
const WorkAlarm: React.FC = () => {
  return (
    <>
      <ContentIndex />
    </>
  );
};

export default WorkAlarm;
