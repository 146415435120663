import { useEffect, useState } from "react";
import Axios from "../../../../../services/axios";
const axios = new Axios();

const ProvincesList: React.FC = () => {
  // Define provinces with their names and URLs
  // const provinces = [
  //   { name: "Adana", url: "/adana" },
  //   { name: "Adıyaman", url: "/adiyaman" },
  //   { name: "Afyonkarahisar", url: "/afyonkarahisar" },
  //   { name: "Ağrı", url: "/agri" },
  //   { name: "Aksaray", url: "/aksaray" },
  //   { name: "Amasya", url: "/amasya" },
  //   { name: "Ankara", url: "/ankara" },
  //   { name: "Antalya", url: "/antalya" },
  //   { name: "Ardahan", url: "/ardahan" },
  //   { name: "Artvin", url: "/artvin" },
  //   { name: "Aydın", url: "/aydin" },
  //   { name: "Balıkesir", url: "/balikesir" },
  //   { name: "Bartın", url: "/bartin" },
  //   { name: "Batman", url: "/batman" },
  //   { name: "Bayburt", url: "/bayburt" },
  //   { name: "Bilecik", url: "/bilecik" },
  //   { name: "Bingöl", url: "/bingol" },
  //   { name: "Bitlis", url: "/bitlis" },
  //   { name: "Bolu", url: "/bolu" },
  //   { name: "Burdur", url: "/burdur" },
  //   { name: "Bursa", url: "/bursa" },
  //   { name: "Çanakkale", url: "/canakkale" },
  //   { name: "Çankırı", url: "/cankiri" },
  //   { name: "Çorum", url: "/corum" },
  //   { name: "Denizli", url: "/denizli" },
  //   { name: "Diyarbakır", url: "/diyarbakir" },
  //   { name: "Düzce", url: "/duzce" },
  //   { name: "Edirne", url: "/edirne" },
  //   { name: "Elazığ", url: "/elazig" },
  //   { name: "Erzincan", url: "/erzincan" },
  //   { name: "Erzurum", url: "/erzurum" },
  //   { name: "Eskişehir", url: "/eskisehir" },
  //   { name: "Gaziantep", url: "/gaziantep" },
  //   { name: "Giresun", url: "/giresun" },
  //   { name: "Gümüşhane", url: "/gumushane" },
  //   { name: "Hakkâri", url: "/hakkari" },
  //   { name: "Hatay", url: "/hatay" },
  //   { name: "Iğdır", url: "/igdir" },
  //   { name: "Isparta", url: "/isparta" },
  //   { name: "İstanbul", url: "/istanbul" },
  //   { name: "İzmir", url: "/izmir" },
  //   { name: "Kahramanmaraş", url: "/kahramanmaras" },
  //   { name: "Karabük", url: "/karabuk" },
  //   { name: "Karaman", url: "/karaman" },
  //   { name: "Kars", url: "/kars" },
  //   { name: "Kastamonu", url: "/kastamonu" },
  //   { name: "Kayseri", url: "/kayseri" },
  //   { name: "Kırıkkale", url: "/kirikkale" },
  //   { name: "Kırklareli", url: "/kirklareli" },
  //   { name: "Kırşehir", url: "/kirsehir" },
  //   { name: "Kilis", url: "/kilis" },
  //   { name: "Kocaeli", url: "/kocaeli" },
  //   { name: "Konya", url: "/konya" },
  //   { name: "Kütahya", url: "/kutahya" },
  //   { name: "Malatya", url: "/malatya" },
  //   { name: "Manisa", url: "/manisa" },
  //   { name: "Mardin", url: "/mardin" },
  //   { name: "Mersin", url: "/mersin" },
  //   { name: "Muğla", url: "/mugla" },
  //   { name: "Muş", url: "/mus" },
  //   { name: "Nevşehir", url: "/nevsehir" },
  //   { name: "Niğde", url: "/nigde" },
  //   { name: "Ordu", url: "/ordu" },
  //   { name: "Osmaniye", url: "/osmaniye" },
  //   { name: "Rize", url: "/rize" },
  //   { name: "Sakarya", url: "/sakarya" },
  //   { name: "Samsun", url: "/samsun" },
  //   { name: "Siirt", url: "/siirt" },
  //   { name: "Sinop", url: "/sinop" },
  //   { name: "Sivas", url: "/sivas" },
  //   { name: "Şanlıurfa", url: "/sanliurfa" },
  //   { name: "Şırnak", url: "/sirnak" },
  //   { name: "Tekirdağ", url: "/tekirdag" },
  //   { name: "Tokat", url: "/tokat" },
  //   { name: "Trabzon", url: "/trabzon" },
  //   { name: "Tunceli", url: "/tunceli" },
  //   { name: "Uşak", url: "/usak" },
  //   { name: "Van", url: "/van" },
  //   { name: "Yalova", url: "/yalova" },
  //   { name: "Yozgat", url: "/yozgat" },
  //   { name: "Zonguldak", url: "/zonguldak" },
  // ];

  const [provincesColumns, setProvincesColumns] = useState<any[]>([]);

  // Function to divide provinces into 4 columns
  const getProvincesColumns = (provinces: any) => {
    const columns: { name: string; url: string }[][] = [[], [], [], []];
    provinces.forEach((province: any, index: number) => {
      columns[index % 4].push(province);
    });
    return columns;
  };

  // const provincesColumns = getProvincesColumns();

  useEffect(() => {
    // {{server}}/v1/cdn/get-cities?limit=100
    axios
      .request({ type: "get", endpoint: "cdn/get-cities?limit=100" })
      .then((response: any) => {
        const provinces = response.data.cities.map((city: any) => {
          return { name: city.name, url: `?cities=[${city.id}]`};
        });
        const provincesCols = getProvincesColumns(provinces);
        setProvincesColumns(provincesCols);
      })
      .catch(console.log);
  }, []);

  return (
    <div className="mx-auto container bg-gray-100 flex flex-col w-full py-10 font-poppins">
      <div className=" w-full px-4 lg:px-0 mx-auto">
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {provincesColumns.map((column, columnIndex) => (
            <div key={columnIndex}>
              <ul className="list-none pl-0 items-start  flex flex-col">
                {column.map((province:any, provinceIndex:number) => (
                  <li key={provinceIndex}>
                    <a
                      href={`/is-ilanlari${province.url}`}
                      className="text-[rgb(12,110,255)]"
                    >
                      {province.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProvincesList;
