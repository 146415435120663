import { LabelHTMLAttributes, useContext } from "react";
import { CheckboxContext, checkboxContextType } from ".";

type errorPropsType = LabelHTMLAttributes<HTMLLabelElement> & {};

export default function Error({ className, ...props }: errorPropsType) {
  const { error }: checkboxContextType = useContext(CheckboxContext);

  if (error) {
    return (
      <label {...props} className={`${className} text-xs text-red-600`}>
        {/* Error mesajını buraya yazabilirsiniz */}
      </label>
    );
  }
  return null;
}
