import Card from "../../company-page-container/setting-page/content/Card";
import Axios from "../../../services/axios";
import { CgSpinner } from "react-icons/cg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PageLoader } from "../../../components/common/page-loader";
import { getCompanyUser } from "../../../store/features/companyUserSlice";

type Props = {
  userType: "user" | "company";
};

type field = {
  name: string;
};

const axios = new Axios();

function VerifyEmailPage(props: Props) {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");
  const { companyUser } = useSelector((state: any) => state.companyUser);

  const dispatch = useDispatch();

  const sendEmailVerify = async () => {
    if (props.userType === "company") {
      await dispatch(getCompanyUser);
      try {
        await axios.authRequest({
          endpoint: `companies/account-settings/confirm-verify-email?token=${token}`,
          type: "get",
          payload: {
            token: token,
          },
        });
        navigate("/isveren/ayarlar");
      } catch (error: any) {
        console.log(error);
        toast.error(error.response.data.error);
      }
    } else {
      try {
        await axios.authRequest({
          endpoint: `user/profilim/send-verify-email?token=${token}`,
          type: "post",
          payload: {
            data: {
              token: token,
            },
          },
        });
        navigate("/user/profilim/ayarlar");
      } catch (error: any) {
        console.log(error);
        toast.error(error.response.data.error);
      }
    }
  };

  useEffect(() => {
    sendEmailVerify();
  }, []);
  if (props.userType === "company") {
    if (!companyUser) {
      return <PageLoader />;
    } else if (companyUser?.is_phone_verified) {
      navigate("/isveren/ayarlar");
    }
  }
  return (
    <div className="container mx-auto">
      <div className="flex flex-col gap-3 my-3">
        <Card className="h-[80dvh] flex flex-col justify-center items-center">
          <CgSpinner className="animate-spin text-title text-4xl m-auto my-4" />
          <h1 className="text-xl text-title font-extrabold text-center">
            E-posta adresinizi doğrulanyur .... lutfen bekleyiniz.
          </h1>
        </Card>
      </div>
    </div>
  );
}

export default VerifyEmailPage;
