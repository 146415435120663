import React, { useContext, useEffect, useState } from "react";
import { CVPreviewContext } from "../contents";
import Axios from "../../../../services/axios";
import { useParams } from "react-router-dom";
import moment from "moment";
import { MdOutlineMessage } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";

const axios = new Axios();

const BlockCandidate = () => {
  const { data } = useContext(CVPreviewContext);
  const { slug } = useParams();
  const [notes, setNotes] = useState([]);
  const { fetchIncompleteDetail } = useContext(CVPreviewContext);

  const handleBlock = () => {
    Swal.fire({
      html: `Adayı Engellemek istediğinize emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
      confirmButtonColor: "#2253af",
      cancelButtonColor: "#6c757d",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .authRequest({
            type: "post",
            endpoint: `companies/blacklist/add/${data.user.id}`,
            payload: {},
          })
          .then((reponse: any) => {
            Swal.fire({
              html: `Aday Engellendi`,
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Tamam",
              confirmButtonColor: "#2253af",
            });
            fetchIncompleteDetail();
          })
          .catch((error: any) => {
            Swal.fire({
              html: error.response.data.error,
              icon: "error",
              showCancelButton: false,
              confirmButtonText: "Tamam",
              confirmButtonColor: "#2253af",
            });
          });
      }
    });
  };
  const handleUnBlock = () => {
    Swal.fire({
      html: `Aday engelini kaldırmak istediğinize emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
      confirmButtonColor: "#2253af",
      cancelButtonColor: "#6c757d",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .authRequest({
            type: "delete",
            endpoint: `companies/blacklist/remove-to-blacklist?company_blacklist_id=${data.blacklist_user.id}`,
            payload: {},
          })
          .then((reponse: any) => {
            Swal.fire({
              html: `Aday Engelli Kaldırıldı`,
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Tamam",
              confirmButtonColor: "#2253af",
            });
            fetchIncompleteDetail();
          })
          .catch((error: any) => {
            Swal.fire({
              html: error.response.data.error,
              icon: "error",
              showCancelButton: false,
              confirmButtonText: "Tamam",
              confirmButtonColor: "#2253af",
            });
          });
      }
    });
  };
  return (
    <div
      className={
        "flex flex-col justify-center items-center p-5 bg-white gap-3 rounded-xl"
      }
    >
      <div className={"text-center text-2xl font-bold "}>
        {data.blacklist_user ? "Engeli Kaldır" : "Adayı Engelle"}
      </div>
      {data.blacklist_user ? (
        <div className={"text-center text-sm font-light"}>
          Bu adayı{" "}
          <span className={"font-semibold"}>
            {moment(data.blacklist_user.black_list_date).format(
              "DD.MM.YYYY HH.mm"
            )}
          </span>{" "}
          tarihinde engellediniz.
        </div>
      ) : (
        <div className={"text-center text-sm font-light"}>
          Adayı engellediğinizde firmanıza yaptığı başvurular size gösterilmez.
        </div>
      )}

      <div
        onClick={() => {
          data.blacklist_user ? handleUnBlock() : handleBlock();
        }}
        className={
          "mt-3 w-full px-5 py-2 border border-mains hover:bg-mains hover:text-white rounded text-center transition-all cursor-pointer font-bold text-mains"
        }
      >
        {data.blacklist_user ? "Engeli Kaldır" : "Adayı Engelle"}
      </div>
    </div>
  );
};

export default BlockCandidate;
