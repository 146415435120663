import React, { Dispatch, SetStateAction, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";
import { IoPerson } from "react-icons/io5";
import { Button } from "../../../isbul-ui";
import { FaCloudUploadAlt } from "react-icons/fa";
import ImageCropper from "../../../pages/MyResumes/CreateCV/PersonalInformation/components/ImageCropper";

interface CropArea {
  x: number;
  y: number;
  width: number;
  height: number;
}

export const PhotoUploadModal = ({
  isOpen,
  closeModal,
  onFileSelect,
  onSave,
  setIsModalOpen
}: {
  isOpen: boolean;
  closeModal: () => void;
  onFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSave: (file: File) => void;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [croppedImage, setCroppedImage] = useState<File | null>(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return null;
    }
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleSave = () => {
    if (selectedFile) {
      onSave(croppedImage!);
      setSelectedFile(null);
      setPreviewUrl(null);
    }
  };

  const handleSelectCroppedImage = () => {
    if (croppedImage) {
      onSave(croppedImage);
      closeModal();
      setSelectedFile(null);
      setPreviewUrl(null);
      setCroppedImage(null);
      setCroppedImageUrl(null);
    }
  };

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.setAttribute("crossOrigin", "anonymous"); // CORS politikaları için gerekebilir
      image.onload = () => resolve(image);
      image.onerror = reject;
      image.src = url;
    });

  const getCroppedImg = async (
    imageSrc: string,
    pixelCrop: CropArea
  ): Promise<File> => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      throw new Error("Unable to create canvas context");
    }

    // Ensuring the image never shows up distorted
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        resolve(new File([blob], "croppedImage.jpeg", { type: "image/jpeg" }));
      }, "image/jpeg");
    });
  };

  const onCropComplete = async (
    croppedArea: CropArea,
    croppedAreaPixels: CropArea
  ) => {
    try {
      const croppedImageFile = await getCroppedImg(
        previewUrl!,
        croppedAreaPixels
      );
      setCroppedImage(croppedImageFile);
    } catch (error) {
      console.error("Error during image cropping:", error);
    }
  };

  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={closeModal}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500  bg-opacity-75 transition-opacity " />
        </Transition.Child>

        <div className="fixed  z-10 inset-0 overflow-y-auto ">
          <div className="flex items-start sm:items-center justify-center min-h-full p-4 text-center  sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative font-poppins  bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-3xl sm:w-full">
                <button
                  onClick={closeModal}
                  className="absolute top-3 lg:top-8 right-3 text-gray-500 hover:text-gray-800"
                >
                  <AiOutlineClose size={24} />
                </button>
                <div className=" px-4 pt-3 sm:p-6 sm:pb-4 min-h-[250px] md:min-h-[300px] ">
                  <div className="sm:flex sm:items-start">
                    <div className="md:mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full h-full">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-mains"
                      >
                        PROFİL FOTOĞRAFI EKLE
                      </Dialog.Title>
                      <hr className="border-mains my-2"/>
                      {/* <div className="flex-col md:flex-row flex gap-x-5 h-full gap-4">
                        <div className="mt-2 basis-1/2 md:mt-4">
                          <label
                            htmlFor="fileChange"
                            className="flex w-full justify-between text-xs p-3 border rounded-lg items-center cursor-pointer"
                          >
                            <div>Fotoğraf Seçiniz</div>
                            <div className="flex items-center px-3 py-2 bg-primary100 text-white rounded text-sm space-x-1">
                              <AiOutlinePlus />
                              <div className=" ">Seçim</div>
                            </div>
                          </label>
                          <input
                            type="file"
                            hidden
                            accept=".jpg, .png, .jpeg"
                            id="fileChange"
                            onChange={handleFileChange}
                          />
                          <div className="w-full flex justify-center items-center">
                            {previewUrl && (
                              <div className="relative bg-white h-48 w-48 md:w-72 :h-72 mt-5">
                                <Cropper
                                  image={previewUrl}
                                  crop={crop}
                                  zoom={zoom}
                                  aspect={1 / 1}
                                  onCropChange={setCrop}
                                  onCropComplete={onCropComplete}
                                  onZoomChange={setZoom}
                                />
                                <div className="controls ">
                                  <input
                                    type="range"
                                    value={zoom}
                                    min={1}
                                    max={5}
                                    //step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e: any) => {
                                      setZoom(e.target.value);
                                    }}
                                    className="zoom-range"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                          {croppedImageUrl && (
                            <div className="cropped-image-preview">
                              <img src={croppedImageUrl} alt="Cropped" />
                              <button
                                onClick={handleSelectCroppedImage}
                                className="select-cropped-image-button"
                              >
                                Select Cropped Image
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="basis-1/2">
                          <div className="text-gray-400 mb-3 text-sm">
                            Fotoğraf Ekleme Hakkında Bilgiler
                          </div>
                          <div className='flex flex-col gap-1 font-poppins text-xs text-gray-600'>
                            <ul className='list-disc pl-4 lg:pl-10 marker:text-mains marker:text-xl'>
                                <li>
                                    Fotoğraf eklemek CV'nizin görünürlüğünü %70 oranında arttırır.
                                </li>
                                <li>
                                    Fotoğrafın net ve düzgün olmasına dikkat edin.
                                </li>
                                <li>
                                    Vesikalık fotoğraf ekleyin.
                                </li>
                                <li>
                                    Fotoğraf boyutu 20 MB'dan fazla olmamalıdır.
                                </li>
                                <li>
                                    Fotoğraf türleri PNG, JPG, JPEG olmalıdır.
                                </li>
                            </ul>
                          </div>
                        </div>
                      </div> */}
                      <ImageCropper
                      setIsModalOpen={setIsModalOpen}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-sm md:text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleSave}
                  >
                    Kaydet
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm md:text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={closeModal}
                  >
                    Kapat
                  </button>
                </div> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
