import React, { useState, useRef, useEffect } from "react";
import { MdExpandMore } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import AddingNewPhotoPopup from "./adding-new-photo-popup/index";
import Axios from "../../../../services/axios";
import { DataContextType } from "../../../../store/contextApi/ad-api-context/Iget-ad-data";
import Swal from "sweetalert2";
import MapLocationSelect from "../addDetail/content/working-locations/MapLocationSelect";
import { MapLocation } from "../../../../containers/company-page-container/creat-ads-page/advert-create-form";

// Options to display in the dropdown
const customeAxios = new Axios();
interface option {
  label: string;
  value: string;
}
const options: option[] = [
  { label: "ftrtt", value: "42" },
  { label: "grt5eye54", value: "29" },
  // Add more options here
];
interface FileItem {
  id: number;
  title: string;
}
interface TemplateSettingProps {
  onTemplateTypeChange: (
    templateType: "DEFAULT" | "MAP" | "IMAGE" | "MAP-IMAGE"
  ) => void;
  onImageSelect: (imageId: number) => void;
  contextData?: DataContextType;
  updateMapLocation?: (location: MapLocation) => void;
  initialLat?: number | null;
  initialLong?: number | null;
}
const TemplateSetting: React.FC<TemplateSettingProps> = ({
  onTemplateTypeChange,
  onImageSelect,
  contextData,
  updateMapLocation,
  initialLat,
  initialLong,
}) => {
  const containerRef = useRef(null);
  const [isPopupVisible, setIsPopupVisable] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isArrowChange, setIsArrowChange] = useState<boolean>(false);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [selectedMapLocation, setSelectedMapLocation] = useState<MapLocation>({
    lat: initialLat || 38,
    longitude: initialLong || 33,
  });
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(
    contextData && contextData.data?.advert.template_type
      ? contextData.data?.advert.template_type == "DEFAULT"
        ? "1"
        : contextData.data?.advert.template_type == "MAP"
        ? "2"
        : contextData.data?.advert.template_type == "IMAGE"
        ? "3"
        : contextData.data?.advert.template_type == "MAP_IMAGE"
        ? "4"
        : "1"
      : "1"
  );
  const [selectedOption, setSelectedOption] = useState<option | null>(
    contextData
      ? contextData.data?.advert_file.company_file
        ? {
            value: contextData.data.advert_file.company_file.id.toString(),
            label: contextData.data.advert_file.company_file.title,
          }
        : null
      : null
  );
  const [selectedImage, setSelectedImage] = useState<string>("");
  const toggleOpen = () => setIsOpen(!isOpen);
  const [addImages, setAddImage] = useState<any[]>([]);
  const toggleSelectOpen = () => setIsSelectOpen(!isSelectOpen);

  const template3DivRef = useRef<HTMLDivElement>(null);
  const template4DivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedTemplate == "2" || selectedTemplate == "4") {
      if (!initialLat || !initialLong) {
        setIsPopupOpen(true);
      }
    }
  }, [selectedTemplate]);

  const handleTemplateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    let templateType: "DEFAULT" | "MAP" | "IMAGE" | "MAP-IMAGE";

    // Map radio button values to your template type state values
    switch (value) {
      case "1":
        templateType = "DEFAULT";
        break;
      case "2":
        templateType = "MAP";
        break;
      case "3":
        templateType = "IMAGE";
        break;
      case "4":
        templateType = "MAP-IMAGE";
        break;
      default:
        templateType = "DEFAULT"; // Fallback to default
    }

    // Update the parent form's state
    onTemplateTypeChange(templateType);

    // Update local state if necessary
    setSelectedTemplate(templateType);
    setSelectedTemplate(event.target.value);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption =
      event.target.options[event.target.selectedIndex].text;
    setSelectedImage(selectedOption); // Update the state with the selected option
  };
  const handleSelectOption = (option: option) => {
    setSelectedOption(option);
    setIsSelectOpen(false); // Close the dropdown
    onImageSelect(Number(option.value));
  };
  const handleAddLocationClick = (): void => {
    setIsPopupVisable(true);
  };
  const handleClosePopup = (): void => {
    setIsPopupVisable(false);
  };

  const fetchAddImage = async () => {
    try {
      const response = await customeAxios.authRequest({
        type: "get",
        endpoint: "companies/advert/get-company-files?type=image",
        payload: {},
      });
      if (response.data && response.data.files) {
        setAddImage(
          response.data.files.map((file: FileItem) => ({
            label: file.title,
            value: file.id.toString(),
          }))
        );
      } else return;
    } catch (error) {
      console.error("Failed to fetch images:", error);
    }
  };

  useEffect(() => {
    fetchAddImage();
  }, []);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        template4DivRef.current &&
        !template4DivRef.current.contains(event.target as Node)
      ) {
        setIsSelectOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [template4DivRef]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        template3DivRef.current &&
        !template3DivRef.current.contains(event.target as Node)
      ) {
        setIsSelectOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [template3DivRef]);

  return (
    <div className="w-full mb-1 z-10">
      <div
        className="hidden md:flex items-center justify-between w-full p-4 mb-2 bg-white rounded-md shadow-md cursor-pointer"
        onClick={toggleOpen}
      >
        <div className="flex flex-row items-center gap-1">
          <h2 className="font-medium text-[#2253AF] md:text-2xl pl-6">
            İlan Şablon Ayarları
          </h2>
          <label className="text-gray-600">(Zorunlu Alan Değildir)</label>
        </div>
        <MdExpandMore
          className={`transition-transform duration-500 text-[#69737f] ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          size={30}
        />
      </div>
      {/* TODO: isOpen başlangıç */}
      <div
        className={`w-full rounded py-10 px-2 z-10 md:py-10 md:px-10 drop-shadow-md bg-white ${
          isOpen ? "flex" : "hidden"
        } flex-col transition-all`}
        id="sablonAyarDropdown"
      >
        <div className="flex flex-col w-full mt-1">
          <div className="w-full mx-0 md:mx-2 group">
            <label className="mb-3 text-md font-medium text-[#69737f]">
              Şablon Türü
            </label>
            <div className="flex flex-row justify-between mt-6 md:flex-row">
              {/* Görsel ve harita olmadan */}
              <div className="flex flex-col items-center mx-0 md:mx-2 mb-4 sm:mb-0">
                <div className="flex flex-shrink-0">
                  <div className="flex flex-col-reverse md:flex-row items-center md:items-start gap-1 md:gap-0">
                    <input
                      className="w-4 h-4 mr-0 md:mr-3 md:scale-150"
                      id="sablonSoru1"
                      name="template_type"
                      type="radio"
                      defaultValue={1}
                      onChange={handleTemplateChange}
                      style={{}}
                      checked={selectedTemplate === "1"}
                    />
                    <label htmlFor="sablonSoru1">
                      <img
                        src="https://isbul.net/frontend/images/default/advert/blank.png"
                        alt=""
                        className="w-16 hidden lg:flex flex-shrink-0"
                      />
                      <svg
                        className="flex lg:hidden"
                        width="50"
                        height="58"
                        viewBox="0 0 50 58"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M44.084 1H5.46753C2.70611 1 0.467529 3.23857 0.467529 6V52.0959C0.467529 54.8573 2.7061 57.0959 5.46753 57.0959H44.084C46.8454 57.0959 49.084 54.8573 49.084 52.0959V6C49.084 3.23858 46.8454 1 44.084 1Z"
                          stroke="#D6D6D6"
                          stroke-width="0.934932"
                        ></path>
                        <path
                          d="M33.1902 1V57.0959"
                          stroke="#D6D6D6"
                          stroke-width="0.934932"
                        ></path>
                      </svg>
                    </label>
                  </div>
                </div>
                <label
                  htmlFor="sablonSoru1"
                  className="font-medium hidden md:block text-[13px] text-center md:text-start mt-1 p-label-radio"
                >
                  Görsel ve Harita Olmadan
                </label>
                <label
                  htmlFor="sablonSoru1"
                  className="font-medium text-[12px] block md:hidden text-center md:text-start mt-1 p-label-radio"
                >
                  Standart
                </label>
              </div>

              {/* Harita İle */}
              <div className="flex flex-col items-center mx-0 md:mx-2 mb-4 sm:mb-0">
                <div className="flex flex-col flex-shrink-0">
                  <div className="flex flex-col-reverse md:flex-row items-center md:items-start gap-1 md:gap-0">
                    <input
                      className="w-4 h-4 mr-0 md:mr-3 md:scale-150"
                      id="sablonSoru2"
                      name="template_type"
                      type="radio"
                      defaultValue={2}
                      onChange={handleTemplateChange}
                      checked={selectedTemplate === "2"}
                    />
                    <label htmlFor="sablonSoru2">
                      <img
                        src="https://isbul.net/frontend/images/default/advert/only-map.png"
                        alt=""
                        className="w-16 hidden lg:flex flex-shrink-0"
                      />
                      <svg
                        className="flex lg:hidden"
                        width="51"
                        height="58"
                        viewBox="0 0 51 58"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M44.6164 1H6C3.23858 1 1 3.23857 1 6V52.0959C1 54.8573 3.23858 57.0959 6 57.0959H44.6164C47.3779 57.0959 49.6164 54.8573 49.6164 52.0959V6C49.6164 3.23858 47.3779 1 44.6164 1Z"
                          stroke="#D6D6D6"
                          stroke-width="0.934932"
                        ></path>
                        <path
                          d="M33.7227 1V57.0959"
                          stroke="#D6D6D6"
                          stroke-width="0.934932"
                        ></path>
                        <path
                          d="M1 6C1 3.23858 3.23858 1 6 1H33V11C33 13.7614 30.7614 16 28 16H6C3.23858 16 1 13.7614 1 11V6Z"
                          fill="#2253AF"
                        ></path>
                      </svg>
                    </label>
                  </div>
                </div>
                <label
                  htmlFor="sablonSoru2"
                  className="font-medium hidden md:block text-[13px] text-center md:text-start mt-1 p-label-radio"
                >
                  Harita İle
                </label>
                <label
                  htmlFor="sablonSoru2"
                  className="font-medium block md:hidden text-[12px] text-center md:text-start mt-1 p-label-radio"
                >
                  Harita İle
                </label>
              </div>

              {/* Görsel ile */}
              <div className="flex flex-col items-center mx-0 md:mx-2 mb-4 sm:mb-0">
                <div className="flex flex-shrink-0">
                  <div className="flex flex-col-reverse md:flex-row items-center md:items-start gap-1 md:gap-0">
                    <input
                      className="w-4 h-4 mr-0 md:mr-3 md:scale-150"
                      id="sablonSoru3"
                      name="template_type"
                      type="radio"
                      defaultValue={3}
                      onChange={handleTemplateChange}
                      style={{}}
                      checked={selectedTemplate === "3"}
                    />
                    <label htmlFor="sablonSoru3">
                      <img
                        src="https://isbul.net/frontend/images/default/advert/maps-and-banner.png"
                        alt=""
                        className="w-16 hidden lg:flex flex-shrink-0"
                      />
                      <svg
                        className="flex lg:hidden"
                        width="51"
                        height="58"
                        viewBox="0 0 51 58"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M44.6164 1H6C3.23858 1 1 3.23857 1 6V52.0959C1 54.8573 3.23858 57.0959 6 57.0959H44.6164C47.3779 57.0959 49.6164 54.8573 49.6164 52.0959V6C49.6164 3.23858 47.3779 1 44.6164 1Z"
                          stroke="#D6D6D6"
                          stroke-width="0.934932"
                        ></path>
                        <path
                          d="M33.7227 1V57.0959"
                          stroke="#D6D6D6"
                          stroke-width="0.934932"
                        ></path>
                        <path
                          d="M1 6C1 3.23858 3.23858 1 6 1H33V11C33 13.7614 30.7614 16 28 16H6C3.23858 16 1 13.7614 1 11V6Z"
                          fill="#2253AF"
                        ></path>
                      </svg>
                    </label>
                  </div>
                </div>
                <label
                  htmlFor="sablonSoru3"
                  className="font-medium hidden md:block text-[13px] text-center md:text-start mt-1 p-label-radio"
                >
                  Görsel İle
                </label>
                <label
                  htmlFor="sablonSoru3"
                  className="font-medium block md:hidden text-[12px] text-center md:text-start mt-1 p-label-radio"
                >
                  Görsel İle
                </label>
              </div>

              {/* Görsel ve harita ile */}
              <div className="flex flex-col items-center mx-0 md:mx-2 mb-4 sm:mb-0">
                <div className="flex flex-shrink-0">
                  <div className="flex flex-col-reverse md:flex-row items-center md:items-start gap-1 md:gap-0">
                    <input
                      className="w-4 h-4 mr-0 md:mr-3 md:scale-150"
                      id="sablonSoru5"
                      name="template_type"
                      type="radio"
                      defaultValue={4}
                      onChange={handleTemplateChange}
                      style={{}}
                      checked={selectedTemplate === "4"}
                    />
                    <label htmlFor="sablonSoru5">
                      <img
                        src="https://isbul.net/frontend/images/default/advert/maps-and-banner.png"
                        alt=""
                        className="w-16 hidden lg:flex flex-shrink-0"
                      />
                      <svg
                        className="flex lg:hidden"
                        width="50"
                        height="58"
                        viewBox="0 0 50 58"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M44.084 0.934937H5.46753C2.70611 0.934937 0.467529 3.17351 0.467529 5.93493V52.0308C0.467529 54.7923 2.7061 57.0308 5.46753 57.0308H44.084C46.8454 57.0308 49.084 54.7923 49.084 52.0308V5.93494C49.084 3.17351 46.8454 0.934937 44.084 0.934937Z"
                          stroke="#D6D6D6"
                          stroke-width="0.934932"
                        ></path>
                        <path
                          d="M33.1902 0.934937V57.0308"
                          stroke="#D6D6D6"
                          stroke-width="0.934932"
                        ></path>
                        <path
                          d="M1 6C1 3.23858 3.23858 1 6 1H33V43C33 45.7614 30.7614 48 28 48H6C3.23858 48 1 45.7614 1 43V6Z"
                          fill="#2253AF"
                        ></path>
                      </svg>
                    </label>
                  </div>
                </div>
                <label
                  htmlFor="sablonSoru5"
                  className="font-medium hidden md:block text-[13px] text-center md:text-start mt-1 p-label-radio"
                >
                  Görsel ve Harita İle
                </label>
                <label
                  htmlFor="sablonSoru5"
                  className="font-medium block md:hidden text-[12px] text-center md:text-start mt-1 p-label-radio"
                >
                  Görsel, Harita
                </label>
              </div>
            </div>
          </div>
          {selectedTemplate === "4" && (
            <>
              <div className="flex flex-col items-center justify-start md:flex-row mt-6">
                <div className="mr-2 w-full md:w-[40%]">
                  <label
                    className="block mb-1 text-[#69737f] font-medium"
                    htmlFor="image-list-dropdown"
                  >
                    Kayıtlı Görseller
                  </label>
                  <div className="relative" ref={template4DivRef}>
                    <button
                      id="image-list-dropdown"
                      onClick={() => {
                        if (addImages.length == 0) {
                          Swal.fire({
                            title: "Uyarı",
                            text: "Hiç kayıtlı görseliniz bulunmamaktadır.",
                            icon: "error",
                            confirmButtonText: "Tamam",
                          });
                          return;
                        }
                        toggleSelectOpen();
                      }}
                      className="w-full py-2 pl-4 text-[#2253AF] flex justify-between items-center border border-gray-400 rounded-md focus:border-blue-700/50 focus:border-opacity-50"
                    >
                      {selectedOption ? selectedOption.label : "Seçiniz"}
                      <IoIosArrowDown className="mr-4" />
                    </button>
                    {isSelectOpen && (
                      <div className="absolute z-50 w-full max-h-[300px] overflow-auto bg-white border border-[#69737f] rounded-md mt-1">
                        {addImages.map((addImage) => (
                          <div
                            key={addImage.value}
                            onClick={() => handleSelectOption(addImage)}
                            className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                          >
                            <span className="inline-block w-4 h-4 mr-2 border-2 border-blue-500 rounded-full"></span>
                            <span className="pl-2">{addImage.label}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <button
                  onClick={handleAddLocationClick}
                  className="rounded-lg shadow bg-[#2253AF] text-white font-medium text-[15px] h-[39px] px-4 w-full md:w-[20%] mt-7"
                  type="button"
                >
                  + Yeni Görsel Ekle
                </button>
              </div>
              <div className="mt-4">
                {/* <p className='text-[#69737f]'>Seçilmiş Resim: <span className='text-blue'>{selectedOption ? selectedOption.label : 'Yüklendi'}</span></p> */}
              </div>
            </>
          )}
          {selectedTemplate === "3" && (
            <>
              <div className="flex flex-col items-center justify-start md:flex-row mt-6">
                <div className="mr-2 w-full md:w-[40%]">
                  <label
                    className="block mb-1 text-[#69737f] font-medium"
                    htmlFor="image-list-dropdown"
                  >
                    Kayıtlı Görseller
                  </label>
                  <div className="relative" ref={template3DivRef}>
                    <button
                      id="image-list-dropdown"
                      onClick={() => {
                        if (addImages.length == 0) {
                          Swal.fire({
                            title: "Uyarı",
                            text: "Hiç kayıtlı görseliniz bulunmamaktadır.",
                            icon: "error",
                            confirmButtonText: "Tamam",
                          });
                          return;
                        }
                        setIsArrowChange(!isArrowChange);
                        toggleSelectOpen();
                      }}
                      className="w-full py-2 pl-4  text-[#2253AF] flex justify-between items-center border border-gray-400 rounded-md focus:border-blue-700/50 focus:border-opacity-50"
                    >
                      {selectedOption ? selectedOption.label : "Seçiniz"}
                      {isArrowChange ? (
                        <IoIosArrowUp className="mr-4" />
                      ) : (
                        <IoIosArrowDown className="mr-4" />
                      )}
                    </button>
                    {isSelectOpen && (
                      <div className="absolute !z-[100] w-full max-h-[300px] overflow-auto bg-white border border-[#69737f] rounded-md mt-1">
                        {addImages.map((addImage) => (
                          <div
                            key={addImage.value}
                            onClick={() => handleSelectOption(addImage)}
                            className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                          >
                            <span className="pl-2">{addImage.label}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <button
                  onClick={handleAddLocationClick}
                  className="rounded-lg shadow bg-[#2253AF] text-white font-medium text-[15px] h-[39px] px-4 w-full md:w-[20%] mt-7"
                  type="button"
                >
                  + Yeni Görsel Ekle
                </button>
              </div>
              <div className="mt-4">
                {/* <p className='text-[#69737f]'>Seçilmiş Resim: <span className='text-blue'>{selectedOption ? selectedOption.label : 'Yüklendi'}</span></p> */}
              </div>
            </>
          )}
        </div>
      </div>
      {/* TODO: isOpen bitiş */}
      {isPopupOpen && (
        <MapLocationSelect
          isPopupOpen={isPopupOpen}
          setIsPopupOpen={setIsPopupOpen}
          selectedMapLocation={selectedMapLocation}
          setSelectedMapLocation={setSelectedMapLocation}
          updateMapLocation={updateMapLocation || nullFunction}
        />
      )}

      {isPopupVisible && (
        <AddingNewPhotoPopup
          onClose={handleClosePopup}
          getAllImages={fetchAddImage}
        />
      )}
    </div>
  );

  function nullFunction() {
    //FIXME: advert güncelleme sayfasında prop sıkıntısı halledilince güncellenecek
  }
};

export default TemplateSetting;
