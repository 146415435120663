import * as React from "react";
import ContactPageContainer from '../../containers/contact-page-container'
function ContactPage(): React.ReactElement {
  return (
   <>
   <ContactPageContainer/>
   </>
  );
}

export { ContactPage };