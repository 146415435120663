import {
  Button,
  InputArea,
  SelectDropdown,
  SelectSearchDropdown,
} from "../../../../../../components/isbul-ui";
import { getCities } from "../../../../../../customHooks/getCities";
import { Checkbox } from "../../../../../../components/isbul-ui/checkbox";
import { useIsMobile } from "../../../../../../hooks/useIsMobile";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  cdnCityType,
  cdnCountryType,
  cdnEducationLevelType,
  cdnGeneralLanguageType,
  cdnHighSchoolBranchType,
  cdnHighSchoolTypeType,
  cdnUniversitiesType,
  cdnUniversityBranchType,
  dbUserEducationType,
} from "../../../../../../types";
import { useYears } from "../../../../../../customHooks/useYears";
import { getCountries } from "../../../../../../customHooks/getCountries";
import { getGeneralLanguages } from "../../../../../../customHooks/getGeneralLanguages";
import { getHighSchoolBranches } from "../../../../../../customHooks/getHighSchoolBranches";
import { getUniversities } from "../../../../../../customHooks/getUniversities";
import { getUniversityBranches } from "../../../../../../customHooks/getUniversityBranches";
import { SubmitHandler, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Axios from "../../../../../../services/axios";
import { getEducationLevels } from "../../../../../../customHooks/getEducationLevels";
import { getHighSchoolTypes } from "../../../../../../customHooks/getHighSchoolTypes";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

type payloadDataType = {
  cv_id: number;
  education_level_id: number;
  country_id: number | null;
  city_id: number | null;
  education_type_id: number | null;
  university_id: number | null;
  university_branch_id: number | null;
  high_school_name: string | null;
  high_school_branch_id: number | null;
  high_school_type_id: number | null;
  general_language_id: number | null;
  note_system_id: number | null;
  note: number | null;
  graduation_year: number | null;
  is_continue: boolean;
};

type educationTypeType = {
  id: number;
  name: string;
  name_en: string;
};

type noteSystemType = {
  id: number;
  value: string;
};

const noteSystem: noteSystemType[] = [
  { id: 1, value: "0-100" },
  { id: 2, value: "0-4" },
  { id: 3, value: "0-5" },
];

const educationType: educationTypeType[] = [
  { id: 1, name: "Örgün Öğretim", name_en: "Formal Education" },
  { id: 2, name: "Açık Öğretim", name_en: "Distance Education" },
  { id: 3, name: "İkinci Öğretim", name_en: "Evening Education" },
  { id: 4, name: "Uzaktan Öğretim", name_en: "Remote Education" },
];

const axios = new Axios();

type educationAddOrUpdateFormPropsType = {
  education?: dbUserEducationType | null;
  getAllDatas?: () => void;
  educationsLength?: number;
  isAddClicked?: boolean;
  setIsAddClicked?: Dispatch<SetStateAction<boolean>>;
  isEnglish?: boolean;
  cvId?: string;
  showCvScore?: () => void;
  setIsModalOpen?: Dispatch<SetStateAction<boolean>>;
  setIsPageOpen?: Dispatch<SetStateAction<boolean>>;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
  isLoading?: boolean;
  isEditOpen?: boolean;
  setIsEditOpen?: Dispatch<SetStateAction<boolean>>;
  inPopup?: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
  onReady?: () => void;
  isModalOpen?: boolean
};
const EducationAddOrUpdateForm = ({
  education,
  getAllDatas,
  educationsLength,
  isAddClicked,
  setIsAddClicked,
  isEnglish,
  cvId,
  showCvScore,
  setIsModalOpen,
  setIsPageOpen,
  setIsLoading,
  isLoading,
  isEditOpen,
  setIsEditOpen,
  inPopup = false,
  onSuccess = () => {},
  onCancel = () => {},
  onReady = () => {},
  isModalOpen
}: educationAddOrUpdateFormPropsType) => {
  const isMobile = useIsMobile();

  const [initialEducationLevels, setInitialEducationLevels] = useState<
    cdnEducationLevelType[]
  >([]);
  const [initialHighSchoolTypes, setInitialHighSchoolTypes] = useState<
    cdnHighSchoolTypeType[]
  >([]);
  const [initialCities, setInitialCities] = useState<cdnCityType[]>([]);
  const [initialCountries, setInitialCountries] = useState<cdnCountryType[]>(
    []
  );
  const [initialHighSchoolBranches, setInitialHighSchoolBranches] = useState<
    cdnHighSchoolBranchType[]
  >([]);
  const [initialUniversities, setInitialUniversities] = useState<
    cdnUniversitiesType[]
  >([]);
  const [initialUniversityBranches, setInitialUniversityBranches] = useState<
    cdnUniversityBranchType[]
  >([]);
  const [initialLanguages, setInitialLanguages] = useState<
    cdnGeneralLanguageType[]
  >([]);

  // General states
  const [educationLevels, setEducationLevels] = useState<
    cdnEducationLevelType[] | undefined
  >(initialEducationLevels);
  const [highSchoolTypes, setHighSchoolTypes] = useState<
    cdnHighSchoolTypeType[] | undefined
  >(initialHighSchoolTypes);
  const [cities, setCities] = useState<cdnCityType[] | undefined>(
    initialCities
  );
  const [filteredCities, setFilteredCities] = useState<
    cdnCityType[] | undefined
  >(initialCities);
  const [countries, setCountries] = useState<cdnCountryType[] | undefined>(
    initialCountries
  );
  const [highSchoolBranches, setHighSchoolBranches] = useState<
    cdnHighSchoolBranchType[] | undefined
  >(initialHighSchoolBranches);
  const [universities, setUniversities] = useState<
    cdnUniversitiesType[] | undefined
  >(initialUniversities);
  const [universityBranches, setUniversityBranches] = useState<
    cdnUniversityBranchType[] | undefined
  >(initialUniversityBranches);
  const [languages, setLanguages] = useState<
    cdnGeneralLanguageType[] | undefined
  >(initialLanguages);
  const years = useYears({
    startYear: new Date().getFullYear(),
    endYear: 1974,
  });
  const [searchCountry, setSearchCountry] = useState<string>("");
  const [searchCity, setSearchCity] = useState<string>("");
  const [searchUniversity, setSearchUniversity] = useState<string>("");
  const [searchUniversityBranch, setSearchUniversityBranch] =
    useState<string>("");
  const [searchLanguage, setSearchLanguage] = useState<string>("");
  const [searchHighSchoolBranch, setSearchHighSchoolBranch] =
    useState<string>("");

  // Page header add click catcher
  useEffect(() => {
    if (
      isAddClicked != undefined &&
      setIsAddClicked != undefined &&
      !education
    ) {
      if (isAddClicked) {
        setIsModalOpen && setIsModalOpen(true);
        setIsAddClicked(false);
      }
    }
  }, [isAddClicked]);

  useEffect(() => {
    if (!education) {
      /* Education not exist set default selected country */
      setSelectedCountry({
        id: 1,
        name_tr: "Türkiye",
        name_en: "Turkey",
        iso: "TR",
        iso3: "TUR",
        lcole: 792,
        phone_code: 90,
        updated_at: "",
      });

      /* Education not exist set default selected language */
      setSelectedLanguage({
        id: 9,
        name: "Türkçe",
        name_en: "Turkish",
        order: 1,
        created_at: "",
        updated_at: "",
      });

      /* Education not exist set default get cities form turkey */
      getCities({ country_id: 1 }).then((data) => {
        setCities(data);
      });
    } else {
      //setSelectedValue(education)
      setSelectedCountry(education.country_level_list || null)
      setSelectedCity(education.city_level_list || null)
    }
  }, [education]);

  async function getAllCdns() {
    setIsLoading && setIsLoading(true);
    const [
      cdnEducationLevels,
      cdnCountries,
      cdnUniversities,
      cdnUniversityBranches,
      cdnHighSchoolTypes,
      cdnHighSchollBranches,
      cdnLanguages,
    ] = await Promise.all([
      getEducationLevels({}),
      getCountries({}),
      getUniversities({}),
      getUniversityBranches({}),
      getHighSchoolTypes({ limit: 30 }),
      getHighSchoolBranches({}),
      getGeneralLanguages({}),
    ]);

    setInitialEducationLevels(cdnEducationLevels);
    setInitialHighSchoolTypes(cdnHighSchoolTypes);
    //setInitialCities(cdnUniversities);
    setInitialCountries(cdnCountries);
    setInitialHighSchoolBranches(cdnHighSchollBranches);
    setInitialUniversities(cdnUniversities);
    setInitialUniversityBranches(cdnUniversityBranches);

    onReady && onReady();
    setIsLoading && setIsLoading(false);
  }

  useEffect(() => {
    getAllCdns();
  }, []);

  // Initial props change

  useEffect(() => {
    setEducationLevels(initialEducationLevels);
  }, [initialEducationLevels]);

  useEffect(() => {
    setHighSchoolTypes(initialHighSchoolTypes);
  }, [initialHighSchoolTypes]);

  useEffect(() => {
    setHighSchoolBranches(initialHighSchoolBranches);
  }, [initialHighSchoolBranches]);

  // Cities
  useEffect(() => {
    setCities(initialCities);
  }, [initialCities]);

  // Countries
  useEffect(() => {
    setCountries(initialCountries);
  }, [initialCountries]);

  // High school branches
  useEffect(() => {
    setHighSchoolBranches(initialHighSchoolBranches);
  }, [initialHighSchoolBranches]);

  // Universities
  useEffect(() => {
    setUniversities(initialUniversities);
  }, [initialUniversities]);

  // University branches
  useEffect(() => {
    setUniversityBranches(initialUniversityBranches);
  }, [initialUniversityBranches]);

  // Languages
  useEffect(() => {
    setLanguages(initialLanguages);
  }, [initialLanguages]);

  // Form states
  const [selectedEducationLevel, setSelectedEducationLevel] =
    useState<cdnEducationLevelType | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<cdnCountryType | null>(
    null
  );
  const [selectedCity, setSelectedCity] = useState<cdnCityType | null>(null);
  const [selectedHighSchoolType, setSelectedHighSchoolType] =
    useState<cdnHighSchoolTypeType | null>(null);
  const [selectedHighSchoolBranch, setSelectedHighSchoolBranch] =
    useState<cdnHighSchoolBranchType | null>(null);
  const [selectedUniversityEducationType, setSelectedUniversityEducationType] =
    useState<educationTypeType | null>(null);
  const [selectedUniversity, setSelectedUniversity] =
    useState<cdnUniversitiesType | null>(null);
  const [selectedUniversityBranch, setSelectedUniversityBranch] =
    useState<cdnUniversityBranchType | null>(null);
  const [selectedLanguage, setSelectedLanguage] =
    useState<cdnGeneralLanguageType | null>(null);
  const [selectedNoteSystem, setSelectedNoteSystem] =
    useState<noteSystemType | null>(null);
  const [selectedGraduateYear, setSelectedGraduateYear] = useState<
    number | null
  >(null);
  const [isContinue, setIsCountinue] = useState<boolean>(false);

  // Is open change
  useEffect(() => {
    if (isEditOpen) {
      //setSelectedCity(education?.city_level_list || null);
    }
  }, [isEditOpen]);


  // Selected Values Set

  async function setSelectedValue(education: dbUserEducationType) {
    // Education Level
    setSelectedEducationLevel(education.education_level_list || null);

    // Country
    setSelectedCountry(education.country_level_list || null);

    // City
    setSelectedCity(education.city_level_list || null);

    // Language
    setSelectedLanguage(education.general_language_list || null);

    // High school type
    if (education.high_school_type_id) {
      setSelectedHighSchoolType(education.high_school_type_list || null);
    }

    // High school branch
    if (education.high_school_branch_id) {
      setSelectedHighSchoolBranch(education.high_school_branch_list || null);
    }

    // Education type
    if (education.education_type_id) {
      const educationTypeData = educationType.find(
        (e) => e.id == education.education_type_id
      );
      setSelectedUniversityEducationType(educationTypeData ?? null);
    }

    // Univesity
    if (education.university_id) {
      setSelectedUniversity(education.university_id || null);
    }

    // University branch
    if (education.university_branch_id) {
      setSelectedUniversityBranch(education.university_branch_list || null);
    }

    // Note system
    if (education.note_system_id) {
      const data = noteSystem.find((n) => n.id == education.note_system_id);
      setSelectedNoteSystem(data ?? null);
    }

    // Graduate year
    if (education.graduation_year) {
      setSelectedGraduateYear(education.graduation_year);
    }

    // School name
    if (education.high_school_name) {
      setValue("high_school_name", education.high_school_name);
    }

    // Note
    if (education.note) {
      setValue("note", education.note);
    }

    // Continue
    setIsCountinue(education.is_continue == 1 ? true : false);
  }
  useEffect(() => {
    if (education) {
      setSelectedValue(education);
    }
  }, [education]);

  // Search actions
  // Country
  useEffect(() => {
    async function search() {
      if (searchCountry.trim().length > 0) {
        const filter = await getCountries({ search: searchCountry });
        setCountries(filter);
      } else {
        setCountries(initialCountries);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchCountry]);

  // City
  useEffect(() => {
    async function search() {
      if (searchCity.trim().length > 0 && selectedCountry) {
        const filter = await getCities({
          search: searchCity,
          country_id: selectedCountry.id,
          except_ids: [83, 7795],
        });
        setCities(filter);
        setFilteredCities(filter);
      } else {
        setFilteredCities(cities);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchCity]);

  // Languages
  useEffect(() => {
    async function search() {
      if (searchLanguage.trim().length > 0) {
        const filter = await getGeneralLanguages({ search: searchLanguage });
        setLanguages(filter);
      } else {
        setLanguages(initialLanguages);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchLanguage]);

  // High school branch
  useEffect(() => {
    async function search() {
      if (searchHighSchoolBranch.trim().length > 0) {
        const filter = await getHighSchoolBranches({
          search: searchHighSchoolBranch,
        });
        setHighSchoolBranches(filter);
      } else {
        setHighSchoolBranches(initialHighSchoolBranches);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchHighSchoolBranch]);

  // University
  useEffect(() => {
    async function search() {
      if (searchUniversity.trim().length > 0) {
        const filter = await getUniversities({ search: searchUniversity });
        setUniversities(filter);
      } else {
        setUniversities(initialUniversities);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchUniversity]);

  // University branch
  useEffect(() => {
    async function search() {
      if (searchUniversityBranch.trim().length > 0) {
        const filter = await getUniversityBranches({
          search: searchUniversityBranch,
        });
        setUniversityBranches(filter);
      } else {
        setUniversityBranches(initialUniversityBranches);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchUniversityBranch]);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm<payloadDataType>();

  const onSubmit: SubmitHandler<payloadDataType> = (data) => {
    if (!education) {
      addEducation(data);
    } else {
      saveEducation(data);
    }
  };

  // Form state change value set
  // Education level
  useEffect(() => {
    if (selectedEducationLevel) {
      setValue("education_level_id", selectedEducationLevel.id);
      clearErrors("education_level_id");
    }
  }, [selectedEducationLevel]);

  // Country
  useEffect(() => {
    if (selectedCountry) {
      // TODO: hatalı olabilir tekrar bakılacak
      if (selectedCountry.id != education?.country_id) {
        setSelectedCity(null);
        setValue("city_id", null);
      }
      setValue("country_id", selectedCountry.id);
      getCities({
        country_id: selectedCountry.id,
        except_ids: [83, 7795],
      }).then((data) => {
        setCities(data);
        setFilteredCities(data);
      });
      clearErrors("country_id");
    } else {
      setCities([]);
      setFilteredCities([]);
      setValue("city_id", null);
      setValue("country_id", null);
    }
  }, [selectedCountry]);

  // City
  useEffect(() => {
    setValue("city_id", selectedCity?.id ?? null);
    clearErrors("city_id");
  }, [selectedCity]);

  // High school type
  useEffect(() => {
    setValue("high_school_type_id", selectedHighSchoolType?.id ?? null);
    clearErrors("high_school_type_id");
  }, [selectedHighSchoolType]);

  // High school branch
  useEffect(() => {
    setValue("high_school_branch_id", selectedHighSchoolBranch?.id ?? null);
    clearErrors("high_school_branch_id");
  }, [selectedHighSchoolBranch]);

  // University type
  useEffect(() => {
    setValue("education_type_id", selectedUniversityEducationType?.id ?? null);
    clearErrors("education_type_id");
  }, [selectedUniversityEducationType]);

  // University
  useEffect(() => {
    setValue("university_id", selectedUniversity?.id ?? null);
    clearErrors("university_id");
  }, [selectedUniversity]);

  // University branch
  useEffect(() => {
    setValue("university_branch_id", selectedUniversityBranch?.id ?? null);
    clearErrors("university_branch_id");
  }, [selectedUniversityBranch]);

  // Note system
  useEffect(() => {
    setValue("note_system_id", selectedNoteSystem?.id ?? null);
    clearErrors("note_system_id");
  }, [selectedNoteSystem]);

  // Graduate year
  useEffect(() => {
    setValue("graduation_year", selectedGraduateYear);
    clearErrors("graduation_year");
  }, [selectedGraduateYear]);

  // Is continue
  useEffect(() => {
    setValue("is_continue", isContinue);
  }, [isContinue]);

  useEffect(() => {
    setValue("general_language_id", selectedLanguage?.id ?? null);
    clearErrors("general_language_id");
  }, [selectedLanguage]);

  // Custom form registers
  // Education level
  register("education_level_id", {
    required: "Eğitim seviyesi",
  });

  // Country
  register("country_id", {
    required: "Ülke / Bölge",
  });

  // City
  register("city_id", {
    required: "Şehir",
  });

  // School name

  register("high_school_name", {
    required:
      selectedEducationLevel?.id == 2 || selectedEducationLevel?.id == 1
        ? "Okul Adı"
        : false,
  });

  // High school type
  register("high_school_type_id", {
    required: selectedEducationLevel?.id == 2 ? "Lise Türü" : false,
  });

  // High school branch
  register("high_school_branch_id", {
    required: selectedEducationLevel?.id == 2 ? "Lise Bölümü" : false,
  });

  // University type
  register("education_type_id", {
    required:
      selectedEducationLevel && selectedEducationLevel.id > 2
        ? "Öğrenim Türü"
        : false,
  });

  //University
  register("university_id", {
    required:
      selectedEducationLevel && selectedEducationLevel.id > 2
        ? "Üniversite"
        : false,
  });

  // University branch
  register("university_branch_id", {
    required:
      selectedEducationLevel && selectedEducationLevel.id > 2
        ? "Üniversite Bölümü"
        : false,
  });

  // General language
  register("general_language_id", {
    required:
      selectedEducationLevel && selectedEducationLevel.id > 1
        ? "Öğretim Dili"
        : false,
  });

  // Note system
  register("note_system_id", {
    required: !isContinue ? "Not Sistemi" : false,
  });

  // graduate year
  register("graduation_year", {
    required: !isContinue ? "Mezuniyet Yılı" : false,
  });

  // Custom form values
  const schoolName = watch("high_school_name") || "";
  const note = watch("note") || "";

  //console.log("cities", education);

  const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
  return (
    <form
      className="flex flex-col gap-y-6 px-6 pt-2 pb-20 lg:pb-4"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
    >
      <label className="text-mains font-semibold">
        {education
          ? `${
              isEnglish
                ? "Edit Education Information"
                : "Eğitim Bilgisini Düzenle"
            }`
          : `${
              isEnglish ? "Add Education Information" : "Eğitim Bilgisi Ekle"
            }`}
      </label>
      <hr className="border-mains" />
      <div className="flex flex-col gap-y-6 lg:flex-row">
        {/* Education level select */}
        <div className="flex basis-1/3 px-2">
          <SelectDropdown
            className="w-full"
            required
            mode={selectedEducationLevel ? "dirty" : "empty"}
            error={errors.education_level_id?.message ? true : false}
          >
            <SelectDropdown.Label>
              {isEnglish ? "Education Level" : "Eğitim Seviyesi"}
            </SelectDropdown.Label>
            <SelectDropdown.Trigger>
              {selectedEducationLevel
                ? `${
                    isEnglish
                      ? `${selectedEducationLevel.title_en}`
                      : `${selectedEducationLevel.title}`
                  }`
                : `${isEnglish ? "Select" : "Seçiniz"}`}
            </SelectDropdown.Trigger>
            <SelectDropdown.Items>
              {educationLevels &&
                educationLevels.map((el) => {
                  return (
                    <SelectDropdown.Item
                      key={`el${el.id}`}
                      onClick={() => setSelectedEducationLevel(el)}
                    >
                      {isEnglish ? el.title_en : el.title}
                    </SelectDropdown.Item>
                  );
                })}
            </SelectDropdown.Items>
          </SelectDropdown>
        </div>

        {/* Country select */}
        <div className="flex basis-1/3 px-2">
          <SelectSearchDropdown
            className="w-full"
            required
            mode={selectedCountry ? "dirty" : "empty"}
            error={errors.country_id?.message ? true : false}
          >
            <SelectSearchDropdown.Label>
              {isEnglish ? "Country / Region" : "Ülke / Bölge"}
            </SelectSearchDropdown.Label>
            <SelectSearchDropdown.Trigger
            onClick={async  () => {
              if (searchCountry.length == 0 && countries?.length == 0) {
                setIsLoadingData(true)
                const res = await getCountries({})
                setCountries(res)
                setIsLoadingData(false)
              }
            }}
            >
              <SelectSearchDropdown.Value>
                {selectedCountry
                  ? `${
                      isEnglish
                        ? `${selectedCountry.name_en}`
                        : `${selectedCountry.name_tr}`
                    }`
                  : `${isEnglish ? "Select" : "Seçiniz"}`}
              </SelectSearchDropdown.Value>
              <SelectSearchDropdown.Input
                placeholder={`${isEnglish ? "Search" : "Arama Yap"}`}
                value={searchCountry || ""}
                onChange={(e) => setSearchCountry(e.target.value)}
              />
            </SelectSearchDropdown.Trigger>
            <SelectSearchDropdown.Items>
              {isLoadingData && (
                <SelectSearchDropdown.Item>
                  <AiOutlineLoading3Quarters className="animate-spin mx-auto"/>
                </SelectSearchDropdown.Item>
              )}
              {countries &&
                countries.map((c) => {
                  return (
                    <SelectSearchDropdown.Item
                      key={`cs${c.id}`}
                      onClick={() => setSelectedCountry(c)}
                    >
                      {isEnglish ? c.name_en : c.name_tr}
                    </SelectSearchDropdown.Item>
                  );
                })}
            </SelectSearchDropdown.Items>
          </SelectSearchDropdown>
        </div>

        {/* City select */}
        <div className="flex basis-1/3 px-2">
          <SelectSearchDropdown
            className="w-full"
            required
            mode={selectedCity ? "dirty" : "empty"}
            error={errors.city_id?.message ? true : false}
          >
            <SelectSearchDropdown.Label>
              {isEnglish ? "City" : "Şehir"}
            </SelectSearchDropdown.Label>
            <SelectSearchDropdown.Trigger
              onClick={async () => {
                if (
                  searchCity.trim().length == 0 &&
                  filteredCities?.length == 0
                ) {
                  setIsLoadingData(true)
                  const res = await getCities({
                    country_id: selectedCountry?.id || 1,
                  });
                  setFilteredCities(res);
                  setIsLoadingData(false)
                }
              }}
            >
              <SelectSearchDropdown.Value>
                {selectedCity
                  ? `${
                      isEnglish
                        ? `${selectedCity.name_en ?? selectedCity.name}`
                        : `${selectedCity.name}`
                    }`
                  : `${isEnglish ? "Select" : "Seçiniz"}`}
              </SelectSearchDropdown.Value>
              <SelectSearchDropdown.Input
                placeholder={`${isEnglish ? "Search" : "Arama Yap"}`}
                value={searchCity}
                onChange={(e) => setSearchCity(e.target.value)}
              />
            </SelectSearchDropdown.Trigger>
            <SelectSearchDropdown.Items>
              {isLoadingData && (
                <SelectSearchDropdown.Item>
                  <AiOutlineLoading3Quarters className="mx-auto animate-spin"/>
                </SelectSearchDropdown.Item>
              )}
              {filteredCities &&
                filteredCities.map((city) => {
                  return (
                    <SelectSearchDropdown.Item
                      key={`city${city.id}`}
                      onClick={() => setSelectedCity(city)}
                    >
                      {isEnglish ? city.name_en ?? city.name : city.name}
                    </SelectSearchDropdown.Item>
                  );
                })}
            </SelectSearchDropdown.Items>
          </SelectSearchDropdown>
        </div>
      </div>

      {/* Primary school sections */}
      {selectedEducationLevel && selectedEducationLevel?.id == 1 && (
        <div className="flex flex-col gap-y-6 my-2 lg:my-0 lg:flex-row">
          <div className="flex basis-1/3 px-2">
            <InputArea
              required
              className="w-full"
              mode={schoolName.length == 0 ? "empty" : "dirty"}
              error={errors.high_school_name?.message ? true : false}
              {...register("high_school_name", {
                required:
                  selectedEducationLevel && selectedEducationLevel.id <= 2
                    ? "Okul Adı"
                    : false,
              })}
            >
              <InputArea.Label>
                {isEnglish ? "School Name" : "Okul Adı"}
              </InputArea.Label>
              <InputArea.Input
                value={schoolName}
                onChange={(e) => {
                  setValue("high_school_name", e.target.value);
                  clearErrors("high_school_name");
                }}
              />
            </InputArea>
          </div>
        </div>
      )}

      {/* High school sections */}
      {selectedEducationLevel && selectedEducationLevel?.id == 2 && (
        <div className="flex flex-col gap-y-6 lg:flex-row">
          {/* School type */}
          <div className="flex basis-1/3 px-2">
            <SelectDropdown
              className="w-full"
              required
              error={errors.high_school_type_id?.message ? true : false}
              mode={selectedHighSchoolType ? "dirty" : "empty"}
            >
              <SelectDropdown.Label>
                {isEnglish ? "High School Type" : "Lise Türü"}
              </SelectDropdown.Label>
              <SelectDropdown.Trigger>
                {selectedHighSchoolType
                  ? `${
                      isEnglish
                        ? `${selectedHighSchoolType.title_en}`
                        : `${selectedHighSchoolType.title}`
                    }`
                  : `${isEnglish ? "Select" : "Seçiniz"}`}
              </SelectDropdown.Trigger>
              <SelectDropdown.Items>
                {highSchoolTypes &&
                  highSchoolTypes.map((school) => {
                    return (
                      <SelectDropdown.Item
                        key={`hsselect${school.id}`}
                        onClick={() => setSelectedHighSchoolType(school)}
                      >
                        {isEnglish ? school.title_en : school.title}
                      </SelectDropdown.Item>
                    );
                  })}
              </SelectDropdown.Items>
            </SelectDropdown>
          </div>

          {/* School name input*/}
          <div className="flex basis-1/3 px-2">
            <InputArea
              required
              mode={schoolName.length == 0 ? "empty" : "dirty"}
              error={errors.high_school_name?.message ? true : false}
              className="w-full"
            >
              <InputArea.Label>
                {isEnglish ? "School Name" : "Okul Adı"}
              </InputArea.Label>
              <InputArea.Input
                value={schoolName || ""}
                onChange={(e) => {
                  setValue("high_school_name", e.target.value);
                  clearErrors("high_school_name");
                }}
              />
            </InputArea>
          </div>

          {/* Section select */}
          <div className="flex basis-1/3 px-2">
            <SelectSearchDropdown
              className="w-full"
              required
              error={errors.high_school_branch_id?.message ? true : false}
              mode={selectedHighSchoolBranch ? "dirty" : "empty"}
            >
              <SelectSearchDropdown.Label>
                {isEnglish ? "Department" : "Bölüm"}
              </SelectSearchDropdown.Label>
              <SelectSearchDropdown.Trigger
              onClick={async () => {
                if (searchHighSchoolBranch.length == 0 && highSchoolBranches?.length == 0) {
                  setIsLoadingData(true)
                  const res = await getHighSchoolBranches({})
                  setHighSchoolBranches(res)
                  setIsLoadingData(false)
                }
              }}
              >
                <SelectSearchDropdown.Value>
                  {selectedHighSchoolBranch
                    ? `${
                        isEnglish
                          ? `${selectedHighSchoolBranch.title_en}`
                          : `${selectedHighSchoolBranch.title}`
                      }`
                    : `${isEnglish ? "Select" : "Seçiniz"}`}
                </SelectSearchDropdown.Value>
                <SelectSearchDropdown.Input
                  value={searchHighSchoolBranch || ""}
                  onChange={(e) => setSearchHighSchoolBranch(e.target.value)}
                />
              </SelectSearchDropdown.Trigger>
              <SelectSearchDropdown.Items>
                {isLoadingData && (
                  <SelectSearchDropdown.Item>
                    <AiOutlineLoading3Quarters className="mx-auto animate-spin"/>
                  </SelectSearchDropdown.Item>
                )}
                {highSchoolBranches &&
                  highSchoolBranches.map((branch) => {
                    return (
                      <SelectSearchDropdown.Item
                        key={`hsBranch${branch.id}`}
                        onClick={() => setSelectedHighSchoolBranch(branch)}
                      >
                        {isEnglish ? branch.title_en : branch.title}
                      </SelectSearchDropdown.Item>
                    );
                  })}
              </SelectSearchDropdown.Items>
            </SelectSearchDropdown>
          </div>
        </div>
      )}
      {/* University sections */}
      {selectedEducationLevel && selectedEducationLevel.id >= 3 && (
        <div className="flex flex-col gap-y-6 lg:flex-row">
          {/* Education type select */}
          <div className="flex basis-1/3 px-2 flex-shrink-0">
            <SelectDropdown
              className="w-full"
              required
              error={errors.education_type_id?.message ? true : false}
              mode={selectedUniversityEducationType ? "dirty" : "empty"}
            >
              <SelectDropdown.Label>
                {isEnglish ? "Education Type" : "Öğrenim Türü"}
              </SelectDropdown.Label>
              <SelectDropdown.Trigger>
                {selectedUniversityEducationType
                  ? `${
                      isEnglish
                        ? `${selectedUniversityEducationType.name_en}`
                        : `${selectedUniversityEducationType.name}`
                    }`
                  : `${isEnglish ? "Select" : "Seçiniz"}`}
              </SelectDropdown.Trigger>
              <SelectDropdown.Items>
                {educationType.map((e) => {
                  return (
                    <SelectDropdown.Item
                      key={`edutype${e.id}`}
                      onClick={() => setSelectedUniversityEducationType(e)}
                    >
                      {isEnglish ? e.name_en : e.name}
                    </SelectDropdown.Item>
                  );
                })}
              </SelectDropdown.Items>
            </SelectDropdown>
          </div>

          {/* University name select*/}
          <div className="flex basis-1/3 px-2 flex-shrink-0 lg:max-w-[33%]">
            <SelectSearchDropdown
              className="w-full"
              required
              error={errors.university_id?.message ? true : false}
              mode={selectedUniversity ? "dirty" : "empty"}
            >
              <SelectSearchDropdown.Label>
                {isEnglish ? "University Name" : "Üniversite Adı"}
              </SelectSearchDropdown.Label>
              <SelectSearchDropdown.Trigger
              onClick={async () => {
                if (searchUniversity.length == 0 && universities?.length == 0) {
                  setIsLoadingData(true)
                  const res = await getUniversities({})
                  setUniversities(res)
                  setIsLoadingData(false)
                }
              }}
              >
                <SelectSearchDropdown.Value>
                  {selectedUniversity
                    ? `${
                        isEnglish
                          ? `${selectedUniversity.title_en}`
                          : `${selectedUniversity.title}`
                      }`
                    : `${isEnglish ? "Select" : "Seçiniz"}`}
                </SelectSearchDropdown.Value>
                <SelectSearchDropdown.Input
                  value={searchUniversity || ""}
                  onChange={(e) => setSearchUniversity(e.target.value)}
                />
              </SelectSearchDropdown.Trigger>
              <SelectSearchDropdown.Items>
                {isLoadingData && (
                  <SelectSearchDropdown.Item>
                    <AiOutlineLoading3Quarters className="animate-spin mx-auto"/>
                  </SelectSearchDropdown.Item>
                )}
                {universities &&
                  universities.map((university) => {
                    return (
                      <SelectSearchDropdown.Item
                        key={`uSelect${university.id}`}
                        onClick={() => setSelectedUniversity(university)}
                      >
                        {isEnglish ? university.title_en : university.title}
                      </SelectSearchDropdown.Item>
                    );
                  })}
              </SelectSearchDropdown.Items>
            </SelectSearchDropdown>
          </div>

          {/* Section select */}
          <div className="flex basis-1/3 px-2 flex-shrink-0">
            <SelectSearchDropdown
              className="w-full"
              required
              error={errors.university_branch_id?.message ? true : false}
              mode={selectedUniversityBranch ? "dirty" : "empty"}
            >
              <SelectSearchDropdown.Label>
                {isEnglish ? "Department" : "Üniversite Bölümü"}
              </SelectSearchDropdown.Label>
              <SelectSearchDropdown.Trigger
              onClick={async () => {
                if (searchUniversityBranch.length == 0 && universityBranches?.length == 0) {
                  setIsLoadingData(true)
                  const res = await getUniversityBranches({})
                  setUniversityBranches(res)
                  setIsLoadingData(false)
                }
              }}
              >
                <SelectSearchDropdown.Value>
                  {selectedUniversityBranch
                    ? `${
                        isEnglish
                          ? `${selectedUniversityBranch.title_en}`
                          : `${selectedUniversityBranch.title}`
                      }`
                    : `${isEnglish ? "Select" : "Seçiniz"}`}
                </SelectSearchDropdown.Value>
                <SelectSearchDropdown.Input
                  value={searchUniversityBranch || ""}
                  onChange={(e) => setSearchUniversityBranch(e.target.value)}
                />
              </SelectSearchDropdown.Trigger>
              <SelectSearchDropdown.Items>
                {isLoadingData && (
                  <SelectSearchDropdown.Item>
                    <AiOutlineLoading3Quarters className="mx-auto animate-spin"/>
                  </SelectSearchDropdown.Item>
                )}
                {universityBranches &&
                  universityBranches.map((branch) => {
                    return (
                      <SelectSearchDropdown.Item
                        key={`uBranch${branch.id}`}
                        onClick={() => setSelectedUniversityBranch(branch)}
                      >
                        {isEnglish ? branch.title_en : branch.title}
                      </SelectSearchDropdown.Item>
                    );
                  })}
              </SelectSearchDropdown.Items>
            </SelectSearchDropdown>
          </div>
        </div>
      )}

      {selectedEducationLevel && selectedEducationLevel.id > 1 && (
        <div className="flex flex-col lg:flex-row">
          {/* Language select*/}
          <div className="flex basis-1/3 px-2">
            <SelectSearchDropdown
              className="w-full"
              required
              error={errors.general_language_id?.message ? true : false}
              mode={selectedLanguage ? "dirty" : "empty"}
            >
              <SelectSearchDropdown.Label>
                {isEnglish ? "Education Language" : "Öğretim Dili"}
              </SelectSearchDropdown.Label>
              <SelectSearchDropdown.Trigger
              onClick={async () => {
                if (searchLanguage.length == 0 && languages?.length == 0) {
                  const res = await getGeneralLanguages({})
                  setLanguages(res)
                }
              }}
              >
                <SelectSearchDropdown.Value>
                  {selectedLanguage
                    ? `${
                        isEnglish
                          ? `${selectedLanguage.name_en}`
                          : `${selectedLanguage.name}`
                      }`
                    : `${isEnglish ? "Select" : "Seçiniz"}`}
                </SelectSearchDropdown.Value>
                <SelectSearchDropdown.Input
                  value={searchLanguage || ""}
                  onChange={(e) => setSearchLanguage(e.target.value)}
                />
              </SelectSearchDropdown.Trigger>
              <SelectSearchDropdown.Items>
                {languages &&
                  languages.map((language) => {
                    return (
                      <SelectSearchDropdown.Item
                        key={`lSelect${language.id}`}
                        onClick={() => setSelectedLanguage(language)}
                      >
                        {isEnglish ? language.name_en : language.name}
                      </SelectSearchDropdown.Item>
                    );
                  })}
              </SelectSearchDropdown.Items>
            </SelectSearchDropdown>
          </div>
        </div>
      )}

      {!isContinue && (
        <div className="flex flex-col gap-y-6 lg:flex-row">
          {/* Note system select */}
          <div className="flex basis-1/3 px-2">
            <SelectDropdown
              className="w-full"
              required
              error={errors.note_system_id?.message ? true : false}
              mode={selectedNoteSystem ? "dirty" : "empty"}
            >
              <SelectDropdown.Label>
                {isEnglish ? `Grading System` : `Not Sistemi`}
              </SelectDropdown.Label>
              <SelectDropdown.Trigger>
                {selectedNoteSystem?.value ??
                  `${isEnglish ? "Select" : "Seçiniz"}`}
              </SelectDropdown.Trigger>
              <SelectDropdown.Items className="max-h-[147px]">
                {noteSystem.map((n) => {
                  return (
                    <SelectDropdown.Item
                      key={`es${n.id}`}
                      onClick={() => {
                        setSelectedNoteSystem(n);

                        /* If note bigger than note system limits clear input */
                        if (!isNaN(parseInt(note.toString()))) {
                          const maxValue =
                            n.id == 1
                              ? 100
                              : n.id == 2
                              ? 4
                              : n.id == 3
                              ? 5
                              : 100;
                          if (parseInt(note.toString()) > maxValue) {
                            setValue("note", null);
                          }
                        }
                      }}
                    >
                      {n.value}
                    </SelectDropdown.Item>
                  );
                })}
              </SelectDropdown.Items>
            </SelectDropdown>
          </div>

          {/* Note input */}
          <div className="flex basis-1/3 px-2">
            <InputArea
              className="w-full"
              mode={note.toString().length == 0 ? "empty" : "dirty"}
            >
              <InputArea.Label>
                {isEnglish ? "Grade Average" : "Diploma Notu"}
              </InputArea.Label>
              <InputArea.Input
                type="number"
                value={note || ""}
                max={
                  selectedNoteSystem?.id == 1
                    ? 100
                    : selectedNoteSystem?.id == 2
                    ? 4
                    : selectedNoteSystem?.id == 3
                    ? 5
                    : 100
                }
                onChange={(e) => {
                  const maxValue =
                    selectedNoteSystem?.id == 1
                      ? 100
                      : selectedNoteSystem?.id == 2
                      ? 4
                      : selectedNoteSystem?.id == 3
                      ? 5
                      : 100;
                  if (!isNaN(parseInt(e.target.value))) {
                    if (parseInt(e.target.value) > maxValue) {
                      return;
                    }
                  }
                  setValue("note", Number(e.target.value));
                  clearErrors("note");
                }}
              />
            </InputArea>
          </div>

          {/* Graduate year select */}
          <div className="flex basis-1/3 px-2">
            <SelectDropdown
              className="w-full"
              required
              error={errors.graduation_year?.message ? true : false}
              mode={selectedGraduateYear ? "dirty" : "empty"}
            >
              <SelectDropdown.Label>
                {isEnglish ? "Graduation Year" : "Mezuniyet Yılı"}
              </SelectDropdown.Label>
              <SelectDropdown.Trigger>
                {selectedGraduateYear ?? `${isEnglish ? "Select" : "Seçiniz"}`}
              </SelectDropdown.Trigger>
              <SelectDropdown.Items className="h-[150px]">
                {years.map((year) => {
                  return (
                    <SelectDropdown.Item
                      key={`yearSelect${year}`}
                      onClick={() => setSelectedGraduateYear(year)}
                    >
                      {year}
                    </SelectDropdown.Item>
                  );
                })}
              </SelectDropdown.Items>
            </SelectDropdown>
          </div>
        </div>
      )}

      <div className="flex flex-col lg:flex-row px-2">
        {/* Continues checkbox */}
        <Checkbox
          className="flex !flex-row items-center gap-2"
          checked={isContinue}
          setChecked={setIsCountinue}
        >
          <Checkbox.Trigger id="continuesCheckbox" />
          <Checkbox.Label
            className="select-none"
            onClick={() => setIsCountinue((prev) => !prev)}
          >
            {isEnglish ? "Currently Enrolled" : "Devam Ediyor"}
          </Checkbox.Label>
        </Checkbox>
      </div>

      {!education && (
        <div className="flex flex-row gap-2 ml-auto items-center mt-10 lg:mt-6">
          <Button
            onClick={() => {
              onCancel && onCancel();
              setIsModalOpen && setIsModalOpen(false);
            }}
            className="flex"
            type="button"
            variant="secondary"
            size="sm"
          >
            {isEnglish ? "Cancel" : "Vazgeç"}
          </Button>
          <Button type="submit" size="sm">
            {isEnglish ? "Save" : "Kaydet"}
          </Button>
        </div>
      )}

      {/* Save and cancel buttons */}
      {education && (
        <div className="flex flex-row items-center justify-between mt-6">
          <div className="flex ml-auto flex-row items-center gap-2">
            <Button
              type="button"
              variant="secondary"
              size="sm"
              onClick={() => {
                onCancel && onCancel();
                //setIsModalOpen && setIsModalOpen(false);
                setIsEditOpen && setIsEditOpen(false);
              }}
            >
              {isEnglish ? "Cancel" : "Vazgeç"}
            </Button>
            <Button type="submit" size="sm">
              {isEnglish ? "Save" : "Kaydet"}
            </Button>
          </div>
        </div>
      )}
    </form>
  );

  async function addEducation({ cv_id, ...others }: payloadDataType) {
    if (isLoading) return;

    setIsLoading && setIsLoading(true);

    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/edu/create`,
        type: "post",
        payload: {
          cv_id: cvId,
          ...others,
        },
      });

      if (response && response.status == 200) {
        if (getAllDatas) {
          getAllDatas();
        }

        //clear form values
        setSelectedEducationLevel(null);
        setSelectedCountry(null);
        setSelectedCity(null);
        setValue("high_school_name", "");
        setSelectedHighSchoolType(null);
        setSelectedHighSchoolBranch(null);
        setSelectedLanguage(null);
        setSelectedUniversityEducationType(null);
        setSelectedUniversity(null);
        setSelectedUniversityBranch(null);
        setSelectedNoteSystem(null);
        setValue("note", null);
        setSelectedGraduateYear(null);
        setIsCountinue(false);

        /* if (isMobile.isMobile) {
                            setIsPageOpen(false)
                        } */

        setIsPageOpen && setIsPageOpen(false);
        setIsModalOpen && setIsModalOpen(false);
        onSuccess && onSuccess();
        showCvScore && showCvScore();
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Eğitim bilgisi eklenirken bir hata meydana geldi",
        });
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Eğitim bilgisi eklenirken beklendik bir hata meydana geldi",
      });
    }

    setIsLoading && setIsLoading(false);
  }

  async function saveEducation({ cv_id, ...others }: payloadDataType) {
    if (isLoading) return;

    setIsLoading && setIsLoading(true);

    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/edu/update/${education?.id}`,
        type: "put",
        payload: {
          cv_id: cvId,
          ...others,
        },
      });

      if (response && response.status == 200) {
        if (getAllDatas) {
          getAllDatas();
        }

        setIsEditOpen && setIsEditOpen(false);

        showCvScore && showCvScore();
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Eğitim bilgisi güncellenirken bir hata meydana geldi",
        });
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Eğitim bilgisi güncellenirken beklenmedik bir hata meydana geldi",
      });
    }

    setIsLoading && setIsLoading(false);
  }

  async function deleteEducation() {
    if (isLoading) return;

    setIsLoading && setIsLoading(true);

    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/edu/delete/${education?.id}?cv_id=${cvId}`,
        type: "delete",
        payload: {
          cv_id: cvId,
        },
      });

      if (response && response.status == 200) {
        if (getAllDatas) {
          getAllDatas();
        }

        showCvScore && showCvScore();
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Eğitim bilgisi silinirken bir hata meydana geldi",
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Eğitim bilgisi silinirken beklenmedik bir hata meydana geldi",
      });
    }

    setIsLoading && setIsLoading(false);
  }
};

export default EducationAddOrUpdateForm;
