import React, { useState, useRef, useEffect } from "react";
import AccordionItem from "./contents/index";
import Axios from "../../../../../services/axios/index";
import { position } from "html2canvas/dist/types/css/property-descriptors/position";

interface LinkItem {
  text: string;
  url: string;
}

interface ApiResponse {
  data: {
    status: boolean;
    status_code: number;
    data: Record<string, Position[]>;
  };
}

interface Position {
  id: number;
  title: string;
  slug: string;
}

const AlphabetCollapse: React.FC = () => {
  const [openItem, setOpenItem] = useState<string | null>(null);
  const [positions, setPositions] = useState<{ [key: string]: LinkItem[][] }>(
    {}
  );
  const itemRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>(
    {}
  );
  const allLetters = "ABCÇDEFGHIJKLMNOÖPRSŞTUVYZ3W".split("");

  useEffect(() => {
    const axios = new Axios();
    axios
      .request({ type: "get", endpoint: "home/positions", payload: {} })
      .then((response: ApiResponse) => {
        // Typing the response
        if (response.data.status && response.data.status_code === 200) {
          const fetchedPositions = preparePositionsData(response.data.data);
          setPositions(fetchedPositions);
        }
      })
      .catch((error: any) => {
        console.error("Failed to fetch positions", error);
      });
  }, []);


 
  const preparePositionsData = (data: Record<string, Position[]>) => {
    const preparedData: { [key: string]: LinkItem[][] } = {};
    Object.keys(data).forEach((letter) => {
      // Map each position to a LinkItem
      preparedData[letter] = [
        data[letter].map((position) => ({
          text: position.title,
          url: `/pozisyon/${position.slug}`,
        })),
      ];
    });
    return preparedData;
  };
  

  allLetters.forEach((letter) => {
    itemRefs.current[letter] =
      itemRefs.current[letter] || React.createRef<HTMLDivElement>();
  });

  const toggleLetter = (letter: string) => {
    const isSameItem = openItem === letter;
    setOpenItem(isSameItem ? null : letter);
    if (!isSameItem) {
      setTimeout(() => {
        const element = itemRefs.current[letter]?.current;
        if (element) {
          scrollToElement(element);
        }
      }, 300);
    }
  };

  const scrollToElement = (element: HTMLElement) => {
    const offsetTop = element.getBoundingClientRect().top + window.scrollY - 20;
    window.scrollTo({
      top: offsetTop,
      behavior: "smooth",
    });
  };
  return (
    <div className="font-poppins mb-8">
      <div className="bg-white shadow-lg md:block hidden">
        <div className="flex flex-col items-center justify-center md:w-3/4 w-full mx-auto">
          <div className="flex flex-wrap justify-center gap-2 my-4 px-4">
            {allLetters.map((letter) => (
              <button
                key={letter}
                onClick={() => toggleLetter(letter)}
                className={`w-10 h-10 flex justify-center items-center border-2 border-blue-600 cursor-pointer font-semibold ${
                  openItem === letter
                    ? "bg-blue-600 text-white"
                    : "bg-white text-blue-600"
                }`}
              >
                {letter}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="divide-y divide-gray-200 mt-8">
        {allLetters.map((letter) => (
          <AccordionItem
            key={letter}
            ref={itemRefs.current[letter]}
            title={letter}
            content={positions[letter] || [[]]}
            isOpen={true}
            onToggle={() => toggleLetter(letter)}
          />
        ))}
      </div>
    </div>
  );
};

export default AlphabetCollapse;
