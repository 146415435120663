import React, { useEffect, useRef, useState } from "react";
import { CiSearch } from "react-icons/ci";
import services from "../../../../../services/profile-services";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GoTrash } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { PiWarningCircle } from "react-icons/pi";
import Axios from "../../../../../services/axios";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { Button, Checkbox, Modal, Select } from "antd";

interface CompanyItem {
  id: number;
  company_id: number;
  name: string;
  slug: string;
  logo: string;
  send_emails: number;
  follow_date: string;
  is_have_not_viewed_adverts: boolean;
  not_viewed_advert_count: number;
  period:string;
}


const FollowedCompanies: React.FC = () => {
  const axios = new Axios();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const [mobilButtons, setMobilButtons] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [data, setData] = useState<CompanyItem[]>([]);
  const [filteredData, setFilteredData] = useState<CompanyItem[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
  const [send_emails, setIsSendEmailsChecked] = useState<number>(0);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getFollowedCompanies = async () => {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/follow/get-follow-companies`,
        type: "get",
        payload: {},
      });
      setData(response?.data?.data);
      setFilteredData(response?.data?.data);
    } catch (error) {
      console.error(
        "Takip edilen şirketler getirilirken bir hata oluştu:",
        error
      );
    }
  };

  useEffect(() => {
    getFollowedCompanies();
  }, []);

  const removeCompany = async (id: number) => {
    try {
      await axios.userAuthRequest({
        endpoint: `user/profilim/follow/delete/${id}`,
        type: "delete",
        payload: {},
      });
      getFollowedCompanies();
      setMobilButtons(false);
    } catch (error) {
      console.error("Şirket silinirken bir hata oluştu:", error);
    }
  };

  const handleSearchChange = (name: string) => {
    setSearchTerm(name);
  };

  useEffect(() => {
    filterCompanies();
    // eslint-disable-next-line
  }, [searchTerm, data]);

  const normalizeString = (str: string) => {
    return str
      .replace(/ğ/g, "g")
      .replace(/Ğ/g, "G")
      .replace(/ü/g, "u")
      .replace(/Ü/g, "U")
      .replace(/ş/g, "s")
      .replace(/Ş/g, "S")
      .replace(/ı/g, "i")
      .replace(/İ/g, "I")
      .replace(/ö/g, "o")
      .replace(/Ö/g, "O")
      .replace(/ç/g, "c")
      .replace(/Ç/g, "C");
  };

  const filterCompanies = () => {
    const normalizedSearchTerm = normalizeString(searchTerm.toLowerCase());

    const newCompanies =
      data && data.length > 0
        ? data.filter((item: any) =>
            normalizeString(item.name?.toLowerCase() || "").includes(
              normalizedSearchTerm
            )
          )
        : [];

    setFilteredData(newCompanies);
  };

  function showMobilButtons() {
    setMobilButtons(true);
  }
  const hideMobilButtons = () => {
    setMobilButtons(false);
    setSelectedItem(null);
  };

  const truncate = (str: string, n: number) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const handleSweetAlert = async () => {
    Swal.fire({
      icon: "success",
      text: "E-posta tercihiniz başarıyla güncellendi!",
      confirmButtonText: "Tamam",
      customClass: {
        confirmButton: "sweet-alert-button",
      },
    });

    handleCancel();
  };

  const handleCheckboxChange = (e: any) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const noFollowedCompanies = data.length === 0;
  const noSearchResults = filteredData.length === 0 && searchTerm !== "";

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center justify-between w-full py-5 mb-5 bg-white border-b rounded-md shadow-lg px-3 md:px-7">
        <span className="text-mains text-lg md:text-[19px] poppins-medium">
          TAKİP ETTİĞİM ŞİRKETLER
        </span>
        {/* <button
          className="focus:outline-none"
          onClick={() => {
            Swal.fire({
              icon: "warning",
              title: "İşlemi onaylayın",
              text: "Tüm takip edilen şirketleri silmek istediğinize emin misiniz?",
              confirmButtonColor: "#2253af",
              confirmButtonText: "Evet",
              cancelButtonColor: "#C62E2E",
              cancelButtonText: "İptal",
              showCancelButton: true,
              showCloseButton: true,
            });
          }}
        >
          {data.length > 0 && (
            <span
              className="text-[#ff0000] poppins-medium leading-[21px] text-xs md:text-sm"
              style={{ fontWeight: "400" }}
            >
              Tümünü Sil
            </span>
          )}
        </button> */}
      </div>

      {!noFollowedCompanies && (
        <>
          <div className="flex items-center gap-4 mb-4 bg-white rounded-lg">
            <div
              onClick={() => inputRef.current?.focus()}
              className="flex items-center border-[1px] border-[#b2b6b9] w-full py-[11px] px-5 gap-2 rounded-lg cursor-text"
            >
              <CiSearch size={20} />
              <input
                ref={inputRef}
                type="text"
                placeholder="Takip Ettiğim Şirketlerde Ara"
                className="items-center w-full text-xs outline-none text-title poppins-regular"
                value={searchTerm}
                onChange={(e) => handleSearchChange(e.target.value)}
              />
            </div>
          </div>
        </>
      )}

      <div className="flex flex-col w-full gap-2">
        {noFollowedCompanies ? (
          <div className="flex flex-row items-center gap-4 rounded-md bg-white">
            <div className="bg-red-600 rounded-l-md py-3 px-1">
              <PiWarningCircle className="text-4xl text-white" />
            </div>
            <div>
              <label className="font-poppins text-gray-800 flex flex-col md:flex-row ">
                Henüz takip ettiğiniz firma bulunamadı.
                <a href="/is-ilanlari" className="underline text-mains ml-1">
                  İş İlanlarını İncele
                </a>
              </label>
            </div>
          </div>
        ) : noSearchResults ? (
          <div className="flex flex-col items-center gap-4 rounded-md"></div>
        ) : (
          filteredData.map((item, index) => (
            <div key={index}>
              <div className="md:flex hidden items-center justify-between w-full p-2 bg-white rounded-lg card-shadow">
                <div className="flex items-center gap-4 w-11/12">
                  {item.logo ===
                  "https://isbull.s3.eu-north-1.amazonaws.com/" ? (
                    <>
                      {" "}
                      <img
                        src={
                          "https://isbull.s3.eu-north-1.amazonaws.com/default-images/company-logo.png"
                        }
                        alt="company-logo"
                        className="w-16 h-16 rounded-full lg:rounded-none"
                      />
                    </>
                  ) : (
                    <>
                      <img
                        src={item.logo}
                        alt="company-logo"
                        className="w-16 h-16 rounded-full lg:rounded-none"
                      />
                    </>
                  )}

                  <div className="flex  gap-2 w-full items-center">
                    <a
                      href={`/firma/${item?.company_id}`}
                      className="text-lg font-bold truncate text-mains w-full"
                    >
                      {truncate(item.name, 20)}
                    </a>
                    <p className="w-full flex items-center">
                      {new Date(item.follow_date).toLocaleDateString()}
                    </p>

                    <span
                      onClick={showModal}
                      className="text-gray-600 font-poppins text-xs underline flex justify-center items-center gap-1 cursor-pointer w-full whitespace-nowrap"
                    >
                      <MdOutlineNotificationsActive className="text-gray-600 text-sm" />
                      {item.send_emails === 0 ? (
                        "İş E-postası almak istemiyorum"
                      ) : (
                        item.period === "WEEKLY" ? "Haftalık almak istiyorum" : "Günlük almak istiyorum"
                      )}
                    </span>
                  </div>
                </div>

                <div
                  className="hidden lg:block w-1/12"
                  onMouseEnter={(e) => {
                    showMobilButtons();
                    setSelectedItem(index);
                  }}
                  onMouseLeave={(e) => {
                    hideMobilButtons();
                  }}
                  style={{ position: "relative" }}
                >
                  <button className="peer w-full flex justify-end">
                    <BsThreeDotsVertical
                      className="text-[#1f4ca1] text-[30px]"
                      style={{ transform: "scaley(1.2)" }}
                    />
                  </button>
                  {mobilButtons && index === selectedItem && (
                    <div
                      className="bg-[white] w-[200px] py-3 px-3 shadow-md"
                      style={{
                        position: "absolute",
                        right: "-8px",
                        top: "30px",
                        zIndex: "999",
                      }}
                    >
                      <div
                        className="bg-[white] cursor-pointer hover:bg-[#2253af] border border-[#f1f1f1] rounded-lg flex items-center gap-3 text-sm text-[#2253af] hover:text-[white] h-[31px] w-[180px] ps-3"
                        onClick={() => {
                          Swal.fire({
                            icon: "warning",
                            title: "İşlemi onaylayın",
                            text: "Takip ettiğiniz firmalar listesinden kaldırılacaktır emin misiniz?",
                            confirmButtonColor: "#2253af",
                            confirmButtonText: "Evet",
                            cancelButtonColor: "#C62E2E",
                            cancelButtonText: "İptal",
                            showCancelButton: true,
                            showCloseButton: true,
                            preConfirm: async () => {
                              await removeCompany(item.id);
                            },
                          });
                        }}
                      >
                        <p>Takipten Çıkar</p>
                      </div>
                    </div>
                  )}
                  <div className="flex lg:hidden items-center justify-end gap-2 relative bottom-[10px] right-[10px]">
                    <GoTrash
                      className="text-[red] cursor-pointer"
                      onClick={() => {
                        Swal.fire({
                          icon: "warning",
                          title: "İşlemi onaylayın",
                          text: "Takip ettiğiniz firmalar listesinden kaldırılacaktır emin misiniz?",
                          confirmButtonColor: "#2253af",
                          confirmButtonText: "Evet",
                          cancelButtonColor: "#C62E2E",
                          cancelButtonText: "İptal",
                          showCancelButton: true,
                          showCloseButton: true,
                          preConfirm: async () => {
                            await removeCompany(item.id);
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col md:hidden items-center justify-between w-full p-2 bg-white rounded-lg card-shadow ">
                <div className="flex items-center justify-start w-full gap-4">
                  <img
                    src={
                      item.logo ||
                      "https://isbull.s3.eu-north-1.amazonaws.com/default-images/company-logo.png"
                    }
                    alt="company-logo"
                    className="w-12 h-12 rounded-full lg:rounded-none"
                  />
                  <div className="flex flex-col">
                    <a
                      href={`/firma/${item?.company_id}`}
                      className="text-sm font-bold truncate text-mains font-poppins poppins-semibold"
                    >
                      {truncate(item.name, 20)}
                    </a>

                    <p className="ont-poppins poppins-medium text-xs">
                      {new Date(item.follow_date).toLocaleDateString()}
                    </p>
                  </div>
                </div>

                <div
                  className="flex items-center justify-end w-full"
                  onMouseEnter={(e) => {
                    showMobilButtons();
                    setSelectedItem(index);
                  }}
                  onMouseLeave={(e) => {
                    hideMobilButtons();
                  }}
                  style={{ position: "relative" }}
                >
                  <div className="flex lg:hidden items-center justify-end gap-2 relative bottom-[10px] right-[10px]">
                  <span
                    onClick={showModal}
                    className="text-gray-600 font-poppins text-xs underline flex justify-center items-center gap-1 cursor-pointer w-full whitespace-nowrap"
                  >
                    <MdOutlineNotificationsActive className="text-gray-600 text-sm" />
                    {item.send_emails === 0 ? (
                      "İş E-postası almak istemiyorum"
                    ) : (
                      item.period === "WEEKLY" ? "Haftalık almak istiyorum" : "Günlük almak istiyorum"
                    )}
                  </span>
                    <GoTrash
                      className="text-black cursor-pointer mt-4"
                      onClick={() => {
                        Swal.fire({
                          icon: "warning",
                          title: "İşlemi onaylayın",
                          text: "Takip ettiğiniz firmalar listesinden kaldırılacaktır emin misiniz?",
                          confirmButtonColor: "#2253af",
                          confirmButtonText: "Evet",
                          cancelButtonColor: "#C62E2E",
                          cancelButtonText: "İptal",
                          showCancelButton: true,
                          showCloseButton: true,
                          preConfirm: async () => {
                            await removeCompany(item.id);
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      <Modal
        title={
          <span style={{ textTransform: "none" }}>
            E-posta Tercihini Güncelle
          </span>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div className="w-full flex justify-end border-t border-color-[#dee2e6] px-[24px] py-[12px] gap-4">
            <Button
              className="bg-gray-200 text-black font-poppins text-sm"
              key="Kapat"
              onClick={handleCancel}
            >
              Kapat
            </Button>
            <Button
              className="bg-[#2253af] text-white font-poppins text-sm"
              key="Kaydet"
              onClick={() => handleSweetAlert()}
            >
              Kaydet
            </Button>
          </div>,
        ]}
      >
        <div className="space-y-4">
          <div>
            <p className="text-sm font-poppins font-normal text-black">
              İlgili firmalara ait yeni iş ilanlarını e-posta ile alabilirsiniz.
            </p>
          </div>

          <div>
            <Checkbox onChange={handleCheckboxChange}>
              Uygun iş ilanlarını e-posta olarak gönder.
            </Checkbox>
          </div>

          {isCheckboxChecked && (
            <div>
              <p className="text-gray-400 font-poppins text-sm mb-[2px]">
                E-posta Gönderim Sıklığı <span className="text-red-500">*</span>
              </p>
              <Select
                defaultValue="Günlük E-posta Gönder"
                className="w-full"
                style={{ height: "40px" }}
                options={[
                  {
                    value: "Günlük E-posta Gönder",
                    label: "Günlük E-posta Gönder",
                  },
                  {
                    value: "Haftalık E-posta Gönder",
                    label: "Haftalık E-posta Gönder",
                  },
                  {
                    value: "Aylık E-posta Gönder",
                    label: "Aylık E-posta Gönder",
                  },
                ]}
              />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default FollowedCompanies;
