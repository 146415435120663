import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import * as React from "react";
import { IoSearchSharp } from "react-icons/io5";

interface LDatas {
  id: number;
  value: string;
}

interface CustomComboboxProps {
  ListDatas?: LDatas[];
  onSelectionChange?: (selectedOptions: LDatas[] | any) => void;
  onChangeValue?: (val: string | number) => void;
  innerValue?: string | number;
  nowMode?: boolean;
  width?: string;
  FindClick?: () => void;
  placeholder?: string;
}

export const CustomCombobox: React.FC<CustomComboboxProps> = ({
  ListDatas = [{ id: 0, value: "" }], // Data to search
  onSelectionChange, // Callback when a selection is made
  onChangeValue = () => {}, // Callback when the input value changes
  innerValue, // Initial value for the input
  nowMode = false, // Mode flag
  width = "full", // Width of the input
  FindClick, // Callback for the search button click
  placeholder = "İlan Ara", // Placeholder for the input
}) => {
  const [query, setQuery] = React.useState<string>(
    innerValue?.toString() || ""
  );

  const FilteredData =
    query === ""
      ? ListDatas
      : ListDatas.filter((ldata) =>
          ldata.value
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  React.useEffect(() => {
    if (innerValue !== undefined) {
      setQuery(innerValue.toString());
    }
  }, [innerValue]);

  return (
    <div className="w-full">
      <Combobox
        value={query}
        onChange={(val) => {
          setQuery(val);
          onSelectionChange?.(val);
        }}
      >
        <div className="relative">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <Combobox.Input
              className={`w-${width} py-2 pl-8 text-sm leading-5 text-gray-900 focus:ring-0 border border-gray-300 rounded-lg focus:outline-none bg-gray-100`}
              displayValue={(e: string) => e}
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                onChangeValue(e.target.value);
              }}
              placeholder={placeholder}
            />
            <Combobox.Button
              onClick={FindClick}
              className="absolute inset-y-0 left-0 flex items-center pl-2"
            >
              <IoSearchSharp className="h-5 w-5 text-gray-700" />
            </Combobox.Button>
          </div>
          {FilteredData.length > 0 && (
            <Transition
              as={React.Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md ring-1 ring-black/5 focus:outline-none sm:text-sm z-50">
                {FilteredData.map((data) => (
                  <Combobox.Option
                    key={data.id}
                    value={data.value}
                  ></Combobox.Option>
                ))}
              </Combobox.Options>
            </Transition>
          )}
        </div>
      </Combobox>
    </div>
  );
};
