import React, { useState, useEffect, useRef } from "react";
import { IoIosClose } from "react-icons/io";
import Axios from "../../../../../services/axios";
import Swal from "sweetalert2";
import Button from "../../../../form-components/button";
import { IoAdd } from "react-icons/io5";

interface AddingNewPhotoPopupProps {
	onClose: () => void;
	getAllImages?: () => void;
}

const AddingNewPhotoPopup: React.FC<AddingNewPhotoPopupProps> = ({
	onClose,
	getAllImages
}) => {
	const [isVisible, setIsVisible] = useState(false);
	const fileInputRef = useRef<HTMLInputElement>(null); // Reference to the hidden file input
	const [title, setTitle] = useState("");
	const [file, setFile] = useState<File | null>(null);
	const [titleError, setTitleError] = useState(false);
	const [fileError, setFileError] = useState(false);

	useEffect(() => {
		const timeout = setTimeout(() => setIsVisible(true), 10);
		return () => clearTimeout(timeout);
	}, []);

	const handleCloseClick = () => {
		setIsVisible(false);
		setTimeout(() => {
			onClose();
		}, 500);
	};

	// Function to trigger file input click
	const triggerFileInputClick = () => {
		fileInputRef.current?.click();
	};

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const files = e.target.files;
		if (files && files[0]) {
			setFile(files[0]);
			setFileError(false); // Reset error state when file is selected
		}
	};

	const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setTitle(e.target.value);
		setTitleError(false); // Reset error state when title is changed
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault(); // Corrected to prevent default form behavior

		const formData = new FormData();
		const image = fileInputRef.current?.files?.[0];

		let hasErrors = false;

		if (title.trim().length === 0) {
			setTitleError(true);
			hasErrors = true;
		}

		if (!image) {
			setFileError(true);
			hasErrors = true;
		}

		if (hasErrors) {
			Swal.fire({
				icon: 'warning',
				title: 'Uyarı',
				html: `
				<div>
				Eksik Alanlar: <br/>
				<p class="text-red-600">
				${title.trim().length === 0 ? 'Görsel adı <br/>' : ''}
				${!image ? 'Görsel' : ''}
				</p>
				</div>
				`
			});
			return;
		}

		// Reset error states
		setTitleError(false);
		setFileError(false);

		if (image) {
			// Ensure file is selected
			formData.append("title", title);
			formData.append("image", image);

			const axios = new Axios();

			try {
				const response = await axios.authRequest({
					type: "post",
					endpoint: "companies/advert/upload-file",
					payload: formData,
				});
				onClose();
				if (getAllImages) {
					getAllImages();
				}
			} catch (error) {
				console.error("Upload failed", error);
				Swal.fire({
					icon: 'error',
					title: 'Hata',
					text: 'Yükleme başarısız oldu.',
					confirmButtonText: 'Tamam'
				});
			}
		}
	};

	return (
		<div className='fixed inset-0 z-50 flex items-center justify-center'>
			<div
				className='absolute inset-0 bg-black opacity-50'
				onClick={handleCloseClick}
			></div>
			<div
				className={`bg-white p-6 rounded-lg shadow-lg relative max-w-[calc(100vw-20px)] lg:max-w-4xl w-full transition-all duration-500 ease-in-out transform ${
					isVisible ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-10"
				}`}
			>
				<div className='flex items-start justify-between'>
					<h2 className='text-xl font-semibold text-[#2253af] mb-6'>
						GÖRSEL EKLE
					</h2>
					<button
						onClick={handleCloseClick}
						className='text-gray-600 hover:text-gray-800'
					>
						<IoIosClose size={35} />
					</button>
				</div>
				<div className='flex gap-6'>
					<form className="flex flex-col-reverse lg:flex-row gap-2" onSubmit={handleSubmit}>
						{/* First Section */}
						<div className='flex flex-col basis-1/2'>
							<div className='mb-4'>
								<label
									htmlFor='photoName'
									className='font-medium text-[#79797d]'
								>
									Görsel Adı <span className='text-red-500'>*</span>
								</label>
								<input
									type='text'
									id='photoName'
									placeholder='Görsel adını giriniz'
									className={`block w-full py-3 pl-3 mt-1 border ${titleError ? "border-red-500 bg-red-100 text-red-700" : "border-gray-300"} rounded-md shadow-sm`}
									onChange={handleTitleChange}
								/>
							</div>
							<div className='mb-4'>
								<label
									htmlFor='fileInput'
									className='font-medium text-[#79797d]'
								>
									Görseli Seçiniz <span className='ml-2 text-red-500'>*</span>
								</label>

								<div
									className={`flex items-center justify-between p-2 overflow-hidden border ${fileError ? "border-red-500 bg-red-100 text-red-700" : "border-gray-300"} rounded-lg cursor-pointer`}
									onClick={() => fileInputRef.current?.click()}
								>
									{/* Invisible file input */}
									<input
										id='fileInput'
										ref={fileInputRef}
										type='file'
										style={{ display: "none" }}
										onChange={handleFileChange}
										accept=".jpg, .jpeg, .png"
									/>
									<span className='cursor-pointer text-[#2253af] font-medium overflow-hidden max-w-[200px] lg:max-w-[350px] whitespace-nowrap text-ellipsis'>
										{file == null ? `Görsel Seç` : `${file.name}`}
									</span>
									<Button type="button" size="sm" className="flex flex-shrink-0">
										+ Seçim
									</Button>
								</div>
							</div>
						</div>

						{/* Second Section */}
						<div className='flex flex-col pl-2 basis-1/2'>
							<span className='text-[#79797d] font-medium'>
								Görsel Ekleme Hakkında Bilgiler
							</span>
							<ul className='mt-4 space-y-2 text-xs text-blue-600 list-disc list-inside marker:text-xl marker:text-mains'>
								<li className='text-[#79797d]'>
									Görsel boyutu 5 mb’dan fazla olmamalıdır.
								</li>
								<li className='text-[#79797d]'>
									Görsel türleri .jpg, .jpeg veya .png olmalıdır.
								</li>
								<li className='text-[#79797d]'>
									Görselin düzgün durması için boyutun 780x240 px olması
									gerekmektedir.
								</li>
							</ul>
						</div>
					</form>
				</div>
				{/* Buttons Section */}
				<div className='flex justify-between mt-4'>
					<Button type="button" variant="secondary" size="sm" onClick={handleCloseClick}>
						Kapat
					</Button>
					<Button type="submit" size="sm" onClick={handleSubmit}>
						Yükle
					</Button>
				</div>
			</div>
		</div>
	);
};

export default AddingNewPhotoPopup;
