import React from 'react';
import { FaCheckCircle } from 'react-icons/fa'; // Importing the checkmark icon
import { FaRegCheckCircle } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
const Body: React.FC = () => {
  const location = useLocation();
  const { advertId } = useParams();
  const { companyEmail } = location.state || {}; 
  return (
    <div className="text-center p-5 bg-white rounded-lg shadow-xl mb-24 font-poppins text-lg md:pt-24 pb-24"> {/* Centering content and adding some padding */}
      <div>
        <FaRegCheckCircle className="mx-auto" size="100" color="#4ACA77" /> {/* Big tick with green circle border, centered */}
      </div>
      <div className="mt-5 mb-5 w-[90%] mx-auto">
       <span>
          İlanınız editör onayından geçtikten sonra yayına alınacaktır. İlan durumunuz hakkında e-posta ile bilgilendirileceksiniz. <br></br>
          E-posta: <br className='block lg:hidden'/> {companyEmail}
        </span>
      </div>
      <div className="mb-5 mt-10">
       <span className='text-[#2253AF] font-medium text-xl'>
         İlan Numarası
        </span>
        <br />
        <span className='text-[#79797D] font-bold text-xl'>
        #{advertId}
        </span>
      </div>
      <div className="flex flex-col items-center space-y-2.5"> {/* Aligning buttons vertically with spacing */}
        <a className="bg-blue-500 text-white font-bold px-4 rounded w-full md:w-[50%] mb-4 py-3" href='/isveren/ilanlar/olustur/ilan-bilgileri'>
        Yeni İlan Ver
        </a>
        <a href='/isveren' className="bg-blue-500 text-white font-bold px-4 rounded w-full md:w-[50%] py-3">
        Ana Sayfaya Geri Dön
        </a>
      </div>
    </div>
  );
};

export default Body;
