import { IconChevronRight } from "@tabler/icons-react";
import React, { useEffect, useState, useRef } from "react";
import { useCookie } from "../../../../../../hooks/useCookie";
import { ICompanyUser } from "../../../../../../types/ICompanyUser";
import { useAppDispatch } from "../../../../../../store/store";
import { clearCompanyUser } from "../../../../../../store/features/companyUserSlice";
import { CookieName } from "../../../../../../constants/enums";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { clearCurrentUser } from "../../../../../../store/features/userSlice";
import Cookies from "js-cookie";
import Axios from "../../../../../../services/axios";

interface IProfileDownMenu {
  readonly companyUser?: ICompanyUser;
  readonly user?: {};
  readonly userType?: string;
  isSideMenuOpen?: boolean;
  setIsSideMenuOpen?: (isOpen: boolean) => void; // Add this prop
}

function ProfileDownMenu(props: IProfileDownMenu) {
  const axios = new Axios();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { removeCookie } = useCookie();
  const menuRef = useRef<HTMLDivElement>(null);

  const removeCompanyToken = () => {
    removeCookie(CookieName.CompanyUserToken);
    removeCookie(CookieName.CompanyUserData);
    dispatch(clearCompanyUser());
    navigate("/isveren/giris-yap");
  };

  const removeUserToken = () => {
    removeCookie(CookieName.UserToken);
    removeCookie(CookieName.UserData);
    dispatch(clearCurrentUser());
    navigate("/profilim/giris-yap");
  };

  const emptyAvatar =
    props.userType === "company"
      ? "https://isbull.s3.eu-north-1.amazonaws.com/default-images/company-logo.png?v=2.9.90"
      : "https://isbull.s3.eu-north-1.amazonaws.com/default-images/user-profile.png";

  const { pathname } = useLocation();
  let Items = [];

  if (props.userType === "company") {
    Items = [
      { title: "Ana Sayfa", path: "/" },
      { title: "Aday Havuzu", path: "/isveren/aday-havuzu" },
      { title: "Mesajlar", path: "/isveren/mesajlar" },
      { title: "İlanlar / Başvurular", path: "/isveren" },
      { title: "Ürünler", path: "/isveren/ürünler" },
      { title: "Bildirimler", path: "/isveren/bildirimler" },
      { title: "Mülakatlar", path: "/isveren/mulakat-listesi" },
      { title: "Kayıtlı Aramalarım", path: "/isveren/kayitli-aramalar" },
      { title: "Referans Davetlerim", path: "/isveren/referanslar" },
      { title: "Klasörlerim", path: "/isveren/klasorler" },
      { title: "Hesap Ayarlarım", path: "isveren/ayarlar" },
      { title: "Görseller", path: "isveren/dosyalar" },
      { title: "Yardım", path: "yardim" },
      { title: "Çıkış", path: pathname },
    ];
  } else {
    Items = [
      { title: "Profil", path: "/profilim" },
      { title: "Özgeçmişler", path: "/profilim/cv" },
      { title: "Dosyalar & Önyazılar", path: "/profilim/dosyalar-onyazilar" },
      { title: "Bana Uygun İlanlar", path: "/is-ilanlari" },
      { title: "Başvurularım", path: "/profilim/başvurular" },
      { title: "Favorilerim", path: "/profilim/favoriler" },
      { title: "Bildirimlerim", path: "/profilim/bildirimler" },
      { title: "İş Alarmları", path: "/profilim/is-alarmlari" },
      { title: "Mesajlar", path: "/profilim/mesajlar" },
      { title: "Mülakatlarım", path: "/profilim/mulakat-listesi" },
      { title: "Ayarlar", path: "/profilim/ayarlar" },
      { title: "Yardım", path: "/yardim" },
      { title: "Çıkış", path: pathname },
    ];
  }

  const userToken = Cookies.get("userToken");
  const { getCookie } = useCookie();
  const companyUserToken = getCookie(CookieName.CompanyUserToken);
  const [cvAvatar, setCvAvatar] = useState<string | null>(null);
  const [companyCvAvatar, setCompanyCvAvatar] = useState<string | null>(null);

  useEffect(() => {
    if (userToken) {
      const fetchCv = async () => {
        try {
          const response = await axios.request({
            endpoint: `user/profilim/cv`,
            type: "get",
            payload: {},
          });

          if (response?.data?.data) {
            setCvAvatar(response.data.user.profile);
          }
        } catch (error) {
          console.log("Error fetching data:", error);
        }
      };

      fetchCv();
    }

    if (companyUserToken) {
      const fetchCompanyCv = async () => {
        try {
          const response = await axios.authRequest({
            endpoint: `companies/account-settings/get-setting-info`,
            type: "get",
            payload: {},
          });

          if (response?.data?.company) {
            setCompanyCvAvatar(response.data.company.logo);
          }
        } catch (error) {
          console.log("Error fetching data:", error);
        }
      };

      fetchCompanyCv();
    }
  }, [userToken, companyUserToken]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        props.setIsSideMenuOpen?.(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef, props]);

  return (
    <>
      {props.isSideMenuOpen ? (
        <div
          ref={menuRef}
          style={{ zIndex: 9999999 }}
          className="fixed top-0 right-0 h-screen p-4 overflow-y-scroll bg-white shadow-2xl w-96 rounded-l-xl"
        >
          <Link
            to={props.userType === "company" ? "/isveren/ayarlar" : "/profilim"}
            className="flex items-center w-full rounded-md h-28 bg-mains"
          >
            <div className="h-full p-5 aspect-square">
              <img
                className="object-contain w-full h-full rounded-full"
                src={cvAvatar ?? companyCvAvatar ?? emptyAvatar}
                alt="avatar"
              />
            </div>
            <div className="w-full">
              <div className="text-xl font-semibold text-white">
                {props.companyUser?.name + " " + props.companyUser?.surname}
              </div>
              {props.userType === "company" ? (
                <div className="text-white">Firma Hesabım</div>
              ) : null}
            </div>
          </Link>

          <div className="flex flex-col w-full px-4 mt-4 gap-y-1">
            {Items.map((item, index) => (
              <div key={index}>
                <ProfileDownMenuItem path={item.path}>
                  {item.title}
                </ProfileDownMenuItem>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
}

const ProfileDownMenuItem: React.FC<{ children: string; path: string }> = ({
  children,
  path,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { removeCookie } = useCookie();
  const dispatch = useAppDispatch();

  const removeCompanyToken = () => {
    removeCookie(CookieName.CompanyUserToken);
    removeCookie(CookieName.CompanyUserData);
    dispatch(clearCompanyUser());
    window.location.reload();
  };

  const removeUserToken = () => {
    removeCookie(CookieName.UserData);
    removeCookie(CookieName.UserData);
    removeCookie(CookieName.UserToken);
    dispatch(clearCurrentUser());
    window.location.reload();
  };

  const handleClick = () => {
    if (children === "Çıkış") {
      removeCompanyToken();
      removeUserToken();
      return;
    }

    /* if (pathname.startsWith('/profilim/cv/goruntule/')) {
      navigate(path)
    } */
  };

  return (
    <Link
      to={path}
      className="flex items-center justify-between w-full h-10 px-4 transition-all shadow-md group/item hover:bg-mains hover:ml-4 rounded-b-md"
      onClick={handleClick}
    >
      <div className="text-sm text-gray-700 transition-all poppins-regular group-hover/item:text-white">
        {children}
      </div>
      <IconChevronRight
        className="text-gray-500 transition-all group-hover/item:text-white"
        size={22}
      />
    </Link>
  );
};

export default ProfileDownMenu;
