import axios from "axios";
import Cookies from "js-cookie";

import { toast } from "react-toastify";

export default class Axios {
  baseURL: string;
  axiosInstance: any;
  token: string | null | undefined;

  constructor() {
    this.baseURL = "https://testapi.isbul.net/api/v1/";

    this.axiosInstance = axios.create({
      baseURL: this.baseURL,
      withCredentials: false,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
    this.setInterceptors();
  }

  getToken() {
    this.token = Cookies.get("userToken");
  }
  getCompanyToken() {
    this.token = Cookies.get("companyUserToken");
  }

  clearToken() {
    this.token = null;
    Cookies.remove("userToken");
    Cookies.remove("companyUserToken");
  }

  request(credentials: { type: any; endpoint: any; payload?: any }) {
    this.getToken();
    return this.axiosInstance[credentials.type](
      `/${credentials.endpoint}`,
      credentials.payload
    );
  }
  //Formik ile dosya eklerken multipart data olarak gönderiyor
  requestWithMultipartFormData(credentials: {
    type: any;
    endpoint: any;
    payload?: any;
  }) {
    const token = this.getToken();
    const isFormData = credentials.payload instanceof FormData;
    if (isFormData) {
      this.axiosInstance.defaults.headers.common["Content-Type"] = undefined;
    } else {
      this.axiosInstance.defaults.headers.common["Content-Type"] =
        "application/json";
    }
    return this.axiosInstance[credentials.type](
      `/${credentials.endpoint}`,
      credentials.payload,
      isFormData ? { headers: { "Content-Type": "multipart/form-data" } } : {}
    );
  }

  authRequest(credentials: { type: any; endpoint: any; payload: any }) {
    this.getCompanyToken();
    const isFormData = credentials.payload instanceof FormData;
    if (isFormData) {
      this.axiosInstance.defaults.headers.common["Content-Type"] = undefined;
    } else {
      this.axiosInstance.defaults.headers.common["Content-Type"] =
        "application/json";
    }
    return this.axiosInstance[credentials.type](
      `/${credentials.endpoint}`,
      credentials.payload,
      isFormData ? { headers: { "Content-Type": "multipart/form-data" } } : {}
    );
  }
  userAuthRequest(credentials: { type: any; endpoint: any; payload: any }) {
    this.getToken();
    const isFormData = credentials.payload instanceof FormData;
    if (isFormData) {
      this.axiosInstance.defaults.headers.common["Content-Type"] = undefined;
    } else {
      this.axiosInstance.defaults.headers.common["Content-Type"] =
        "application/json";
    }
    return this.axiosInstance[credentials.type](
      `/${credentials.endpoint}`,
      credentials.payload,
      isFormData ? { headers: { "Content-Type": "multipart/form-data" } } : {}
    );
  }

  async saveFilter(title: string, params: any, token: string | undefined) {
    try {
      const response = await this.axiosInstance.post(
        "companies/search/save-filter",
        {
          title: title,
          params: params,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Optionally, you can return any specific data you need from the response
    } catch (error) {
      throw new Error("Error saving filter: " + error);
    }
  }

  async updateFilter(
    id: number,
    title: string,
    params: any,
    token: string | undefined
  ) {
    try {
      const response = await this.axiosInstance.put(
        `companies/search/update-filter`,
        {
          filter_id: id,
          title: title,
          params: params,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Optionally, you can return any specific data you need from the response
    } catch (error) {
      throw new Error("Error updating filter: " + error);
    }
  }

  async updateMultiCvStatus(
    id: number,
    folder: number,
    cvIds: number[],
    token: string | undefined
  ) {
    try {
      const response = await this.axiosInstance.post(
        `companies/folder/${folder}/multi-cv-to-folder`,
        {
          company_candidate_status_id: id,
          cv_ids: cvIds,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Optionally, you can return any specific data you need from the response
    } catch (error) {
      throw new Error("Error updating filter: " + error);
    }
  }

  async getCvFolders(token: string | undefined) {
    try {
      const response = await this.axiosInstance.get(
        `companies/folder/get-folders`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Optionally, you can return any specific data you need from the response
    } catch (error) {
      throw new Error("Error updating filter: " + error);
    }
  }

  async createCvFolder(title: string, token: string | undefined) {
    try {
      const response = await this.axiosInstance.post(
        `companies/folder/store`,
        {
          title: title,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Optionally, you can return any specific data you need from the response
    } catch (error) {
      throw new Error("Error updating filter: " + error);
    }
  }

  setInterceptors() {
    this.axiosInstance.interceptors.request.use(
      (config: { headers: { [x: string]: string } }) => {
        if (this.token) {
          config.headers["Authorization"] = `Bearer ${this.token}`;
        }
        return config;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );

    this.axiosInstance.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: { response: { status: any } }) => {
        switch (error.response.status) {
          case 401:
            this.clearToken();
            return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );

    // redirect to home if invalid tokens
    this.axiosInstance.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: { response: any }) => {
        // if (error.response.data.error) {
        //   toast.error(error.response.data.error);
        // } else if (error.response.data.errors) {
        //   error.response.data.errors.forEach((err: { message: any }) => {
        //     toast.error(err.message);
        //   });
        // }
        if (error.response.data.status_code === 401) {
          this.clearToken();
          window.location.href = "/";
          console.log(error);
        }
        return Promise.reject(error);
      }
    );
  }
}
