import React, { useState, useEffect, useRef } from "react";
import { CalendarUpIcon } from "../../../theme/icons";
import { CustomButton } from "../../../components/common/login/custom-button";
import { CustomCombobox } from "../../../components/common/custom-combobox";
import { CustomDropdown } from "../../../components/common/add-create-form/addDetail/content/custom-dropdown";
import { initialDate } from "../../profile-page-container/cv-edit/contents/achievement-award";
import { useFormik } from "formik";
import Axios from "../../../services/axios";
import { CookieName } from "../../../constants/enums";
import { useCookie } from "../../../hooks/useCookie";

interface City {
  readonly id: number;
  readonly name: string;
}

interface Ad {
  readonly id: number;
  readonly title: string;
  readonly cities: City[];
  readonly created_at: string;
  readonly updated_at: string;
  readonly publish_date: string;
  readonly end_date: string;
  readonly status: string;
  readonly is_active: number;
  readonly advert_applies_count: number;
  readonly today_advert_applies_count: number;
  readonly slug: string;
  readonly is_use_doping: number;
  readonly is_draft: number;
  readonly doping_end_date: string;
}

type FilterSectionType = {
  onFilterChange: (filters: any) => void;
  FindClick?: () => void;
  onChangeFindValue?: (val: string | number) => void;
  adsData: Ad[];
  setAdsData: React.Dispatch<React.SetStateAction<Ad[]>>;
  paginationData: any;
  setPaginationData: any;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  fetchAdsData?: (page: number, status?: string) => void;
  setLastPage: any;
  setSelectedStatus: React.Dispatch<React.SetStateAction<string>>;
};

const FilterSection: React.FC<FilterSectionType> = ({
  onFilterChange,
  FindClick,
  onChangeFindValue,
  adsData,
  setAdsData,
  paginationData,
  setPaginationData,
  currentPage,
  setCurrentPage,
  fetchAdsData,
  setLastPage,
  setSelectedStatus,
}) => {
  const axios = new Axios();
  const initialAdsDataRef = useRef<Ad[]>([]); // Store the initial data to reset filters
  const { getCookie } = useCookie();
  const companyUserToken = getCookie(CookieName.CompanyUserToken);

  useEffect(() => {
    if (adsData.length > 0 && initialAdsDataRef.current.length === 0) {
      initialAdsDataRef.current = [...adsData]; // Set the initial ads data once
    }
  }, [adsData]);

  const initialAdsData = initialAdsDataRef.current;

  const AdvertsData = [
    { label: "Tüm İlanlar", value: "Tüm İlanlar" },
    { label: "Aktif İlanlar", value: "Aktif İlanlar" },
    { label: "Pasif İlanlar", value: "Pasif İlanlar" },
    { label: "Onay Bekleyenler", value: "Onay Bekleyenler" },
    { label: "Taslak", value: "Taslak" },
    { label: "Arşiv", value: "Arşiv" },
    { label: "Reddedildi", value: "Reddedildi" },
  ];

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [daysInMonth, setDaysInMonth] = useState([]);
  const [SelectedAdvertType, setSelectedAdvertType] = useState<{
    label: string | number;
    value: string | number;
  }>(AdvertsData[0]);
  const [AdvertFindText, setAdvertFindText] = useState<string | number>("");

  const [filterStatus, setFilterStatus] = useState({
    position: "",
    city: "",
    start_day: "",
    start_month: "",
    start_year: "",
    end_day: "",
    end_month: "",
    end_year: "",
  });

  const formik = useFormik({
    initialValues: {
      year: "",
      month: "",
      day: "",
      endYear: "",
      endMonth: "",
      endDay: "",
    },
    onSubmit: (e) => {
      console.log(e);
    },
  });

  // Handle month change to update days in month dropdown

  const handleMonthChange = (selectedMonth: string) => {
    const month = parseInt(selectedMonth, 10);
    let days = 31;

    if (month === 2) {
      const year = parseInt(formik.values.year, 10);
      if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        days = 29;
      } else {
        days = 28;
      }
    } else if ([4, 6, 9, 11].includes(month)) {
      days = 30;
    }

    const updatedDaysInMonth = Array.from(
      { length: days },
      (_, index) => index + 1
    ).map((day) => ({
      value: `${day}`,
      label: `${day}`,
    }));
    setDaysInMonth(updatedDaysInMonth as any);
  };

  useEffect(() => {
    handleMonthChange(formik.values.month || "31");
  }, [formik.values.month]);

  // Set initial days and years for dropdowns

  useEffect(() => {
    const date = new Date();
    for (let i = 1; i <= 30; i++) {
      initialDate.day.push({ value: `${i}`, label: `${i}` });
    }

    for (let i = date.getFullYear(); i >= date.getFullYear() - 10; i--) {
      initialDate.years.push({ value: `${i}`, label: `${i}` });
    }
  }, []);

  // Fetch ads data based on selected status

  const handleFilter = () => {
    let status = "";
    switch (SelectedAdvertType?.label) {
      case "Aktif İlanlar":
        status = "ACTIVE";
        break;
      case "Pasif İlanlar":
        status = "PASSIVE";
        break;
      case "Onay Bekleyenler":
        status = "PENDING";
        break;
      case "Taslak":
        status = "DRAFT";
        break;
      case "Arşiv":
        status = "ARCHIVED";
        break;
      case "Reddedildi":
        status = "REJECTED";
        break;
      default:
        status = "";
        setAdsData(initialAdsData);
        break;
    }

    const filters = {
      position: filterStatus.position ? filterStatus.position : "",
      city: filterStatus.city ? filterStatus.city : "",
      start_date_day: filterStatus.start_day ? filterStatus.start_day : "",
      start_date_month: filterStatus.start_month
        ? filterStatus.start_month
        : "",
      start_date_year: filterStatus.start_year ? filterStatus.start_year : "",
      end_date_day: filterStatus.end_day ? filterStatus.end_day : "",
      end_date_month: filterStatus.end_month ? filterStatus.end_month : "",
      end_date_year: filterStatus.end_year ? filterStatus.end_year : "",
      status: status ? status : "",
      search: AdvertFindText ? AdvertFindText : "",
    };

    onFilterChange(filters);
  };

  // Fetch ads data based on selected status

  useEffect(() => {
    let status = "";

    switch (SelectedAdvertType?.label) {
      case "Aktif İlanlar":
        status = "ACTIVE";
        break;
      case "Pasif İlanlar":
        status = "PASSIVE";
        break;
      case "Onay Bekleyenler":
        status = "PENDING";
        break;
      case "Taslak":
        status = "DRAFT";
        break;
      case "Arşiv":
        status = "ARCHIVED";
        break;
      case "Reddedildi":
        status = "REJECTED";
        break;
      default:
        status = "";
        setAdsData(initialAdsData);
        break;
    }

    setSelectedStatus(status);

    const filterAds = async () => {
      const response = await axios.request({
        type: "get",
        endpoint: `companies/home/get-adverts?status=${status}&not_show_archive=1`,
        payload: {
          headers: {
            Authorization: `Bearer ${companyUserToken}`,
          },
        },
      });


      console.log(response.data.data.adverts)

      setAdsData(response.data.data.adverts.data);
      setPaginationData(response.data.data.adverts);

      setLastPage(response.data.data.adverts.last_page);
    };

    filterAds();
  }, [SelectedAdvertType, setAdsData, setSelectedStatus]);

  return (
    <div className="flex flex-col">
      <div className="lg:flex hidden flex-col items-center justify-between bg-white shadow-lg p-4 rounded-lg lg:flex-row lg:items-center gap-y-4 z-10">
        <div className="flex flex-row gap-x-4 items-center">
          <div className="animate-bounce">
            <img src="/icons/company/doping.svg" alt="icon" />
          </div>
          <CalendarUpIcon size={28} color="black" />
          <span className="text-sm poppins-semibold text-gray-500 w-3/4">
            Yayında olan ilanlarınızı 2 günde bir güncelleyerek üst sıralara
            taşıyabilirsiniz.
          </span>
        </div>

        <div className="flex flex-col gap-y-4 lg:flex-row lg:gap-x-4 lg:items-center">
          <CustomButton
            text="Filtrele"
            className="text-white poppins-medium bg-company-card py-2 px-8 rounded-lg h-10 hover:bg-company-card/80"
            onClick={() => setIsFilterOpen(!isFilterOpen)}
          />

          <CustomDropdown
            minwidth="180px"
            shadow
            bgcolor="bg-gray-100"
            fcolor={"text-mains"}
            bcolor={"bg-gray-100"}
            fcolorhex={"#000000"}
            noRequired
            isSingle
            options={AdvertsData}
            placeholder={SelectedAdvertType?.label.toString()}
            id="syear"
            onSelectionChange={(e) => {
              setSelectedAdvertType({ label: e, value: e });
            }}
          />
          <CustomCombobox
            width="400"
            FindClick={FindClick}
            onChangeValue={(e) => {
              setAdvertFindText(e);
              onChangeFindValue && onChangeFindValue(e);
            }}
          />
        </div>
      </div>

      {isFilterOpen && (
        <div className="flex flex-col items-center justify-between bg-white shadow-lg p-10 rounded-b-lg w-full">
          <div className="flex flex-col gap-y-4 lg:flex-row lg:gap-x-4 lg:items-center w-full">
            <div className="w-full lg:w-2/4 flex flex-col gap-y-1">
              <p className="text-[15px] poppins-semibold text-gray-500">
                Anahtar kelimeler ile arama...
              </p>
              <CustomCombobox
                placeholder="İlan başlığı ya da pozsiyona göre arayabilirsiniz"
                onChangeValue={(e: number | string) => setAdvertFindText(e)}
              />
            </div>
            <div className="w-full lg:w-2/4 flex flex-col lg:flex-row gap-y-4 lg:gap-x-4">
              <div className="w-full">
                <CustomDropdown
                  fcolor={"text-mains"}
                  bcolor={"border-mains"}
                  noRequired
                  isSingle
                  options={[]}
                  placeholder="Seçiniz"
                  label="Pozisyon"
                  id="positionName"
                  onSelectionChange={(selectedOptions) => {
                    setFilterStatus({
                      ...filterStatus,
                      position: selectedOptions,
                    });
                  }}
                  searchEndpoint="cdn/get-positions"
                  searchKey="positions"
                />
              </div>
              <div className="w-full">
                <CustomDropdown
                  fcolor={"text-mains"}
                  bcolor={"border-mains"}
                  noRequired
                  isSingle
                  options={[]}
                  placeholder="Seçiniz"
                  label="İl"
                  id="name"
                  onSelectionChange={(selectedOptions) => {
                    setFilterStatus({ ...filterStatus, city: selectedOptions });
                  }}
                  searchEndpoint="cdn/get-cities"
                  searchKey="cities"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row lg:gap-x-4 lg:items-center w-full mt-5">
            <div className="flex flex-col gap-y-1 w-full">
              <p className="text-base poppins-semibold text-gray-500">
                Başlangıç Tarihi
              </p>
              <div className="flex flex-row gap-x-4 items-center w-full flex-wrap md:flex-nowrap gap-4 mb-2">
                <div className="w-full h-full">
                  <CustomDropdown
                    fcolor={"text-mains"}
                    bcolor={"border-mains"}
                    fcolorhex={"#000000"}
                    placeholder="Gün"
                    noRequired
                    isSingle
                    options={daysInMonth}
                    id="sday"
                    onSelectionChange={(selectedOptions) => {
                      setFilterStatus({
                        ...filterStatus,
                        start_day: selectedOptions,
                      });
                    }}
                  />
                </div>
                <div className="w-full h-full">
                  <CustomDropdown
                    fcolor={"text-mains"}
                    bcolor={"border-mains"}
                    fcolorhex={"#000000"}
                    noRequired
                    isSingle
                    options={initialDate.month}
                    placeholder="Ay"
                    id="smonth"
                    onSelectionChange={(selectedOptions) => {
                      setFilterStatus({
                        ...filterStatus,
                        start_month: selectedOptions,
                      });
                    }}
                  />
                </div>
                <CustomDropdown
                  fcolor={"text-mains"}
                  bcolor={"border-mains"}
                  fcolorhex={"#000000"}
                  noRequired
                  isSingle
                  options={initialDate.years.sort((a: any, b: any) => b - a)}
                  placeholder="Yıl"
                  id="syear"
                  onSelectionChange={(selectedOptions) => {
                    setFilterStatus({
                      ...filterStatus,
                      start_year: selectedOptions,
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col gap-y-1 w-full">
              <p className="text-base poppins-semibold text-gray-500">
                Bitiş Tarihi
              </p>
              <div className="flex flex-row gap-x-4 items-center w-full flex-wrap md:flex-nowrap gap-4 mb-2">
                <div className="w-full h-full">
                  <CustomDropdown
                    fcolor={"text-mains"}
                    bcolor={"border-mains"}
                    fcolorhex={"#000000"}
                    noRequired
                    isSingle
                    options={daysInMonth}
                    placeholder="Gün"
                    id="eday"
                    onSelectionChange={(selectedOptions) => {
                      setFilterStatus({
                        ...filterStatus,
                        end_day: selectedOptions,
                      });
                    }}
                  />
                </div>
                <div className="w-full h-full">
                  <CustomDropdown
                    fcolor={"text-mains"}
                    bcolor={"border-mains"}
                    fcolorhex={"#000000"}
                    noRequired
                    isSingle
                    options={initialDate.month}
                    placeholder="Ay"
                    id="emonth"
                    onSelectionChange={(selectedOptions) => {
                      setFilterStatus({
                        ...filterStatus,
                        end_month: selectedOptions,
                      });
                    }}
                  />
                </div>
                <CustomDropdown
                  fcolor={"text-mains"}
                  bcolor={"border-mains"}
                  fcolorhex={"#000000"}
                  noRequired
                  isSingle
                  options={initialDate.years.sort((a: any, b: any) => b - a)}
                  placeholder="Yıl"
                  id="eyear"
                  onSelectionChange={(selectedOptions) => {
                    setFilterStatus({
                      ...filterStatus,
                      end_year: selectedOptions,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-start w-full gap-x-4 pt-4">
            <CustomButton
              text="Filtreyi Temizle"
              className="text-mains poppins-medium bg-white py-2 px-2 md:px-8 rounded-lg border-2 md:h-10 border-mains hover:bg-gray-100"
              onClick={() => {
                setFilterStatus({
                  position: "",
                  city: "",
                  start_day: "",
                  start_month: "",
                  start_year: "",
                  end_day: "",
                  end_month: "",
                  end_year: "",
                });
                setAdvertFindText("");
                setAdsData(initialAdsData);
                setIsFilterOpen(false);
              }}
            />
            <CustomButton
              text="Filtrele"
              className="text-white poppins-medium bg-mains py-2 px-8 rounded-lg h-10 hover:bg-mains/80"
              onClick={handleFilter}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { FilterSection };
