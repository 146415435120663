import React, { useState, useRef, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { MdVerified } from "react-icons/md";
import { RiTimeLine } from "react-icons/ri";
import { TiMediaPlay } from "react-icons/ti";
import { Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../../../../store/store";
import {
  sendUserVerifySms,
  confirmUserVerifySms,
  getUserInfo,
} from "../../../../../../store/content-reducer/personInfoSlice";
import { toast } from "react-toastify";
import { LuLoader2 } from "react-icons/lu";

const NumberVerification: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [refresh, setRefresh] = useState(false);
  const [buttonRef, setButtonRef] = useState(false);
  const [inputs, setInputs] = useState<string[]>(["", "", "", ""]);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);
  const [verifying, setVerifying] = useState<boolean>(false);
  // User Info
  const userInfo = useAppSelector((state) => state.userInfo);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (refresh) {
      setInputs(["", "", "", ""]); // Inputları temizle
      setMinutes(1); // Dakikaları sıfırla
      setSeconds(0); // Saniyeleri sıfırla
      setRefresh(false); // Yeniden başlatma işlemini tamamladık, refresh durumunu false yap
      setButtonRef(false);
    }
  }, [refresh]);

  const confirmCode = inputs.join("");

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(timer); // Zamanlayıcıyı durdur
          setButtonRef(true); // Yeniden başlatma işlemi
        } else {
          setMinutes((prevMinutes) => prevMinutes - 1);
          setSeconds(59);
        }
      } else {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(timer); // Komponent kaldırıldığında zamanlayıcıyı temizle
  }, [minutes, seconds, refresh]);

  // Refs oluşturulduğunda bunları doldur
  if (inputRefs.current.length !== 4) {
    inputRefs.current = Array(4)
      .fill(null)
      .map((_, index) => inputRefs.current[index] || null);
  }

  const handleChange = (index: number, value: string) => {
    // Sadece bir karakter girişine izin vermek için kontrol yapalım
    if (value.length > 1) {
      return;
    }

    // Girilen değerlerin toplamını hesaplayalım
    const updatedInputs = [...inputs];
    updatedInputs[index] = value;
    const concatenatedValue = updatedInputs.join("");
    // Girilen değerlerin toplamı 4 karakteri geçmemeli
    if (concatenatedValue.length > 4) {
      return;
    }

    // Son input alanında ise işlemi sonlandır
    if (index === 4 && value !== "") {
      return;
    }

    setInputs(updatedInputs);

    // Sonraki inputa geç
    if (value !== "" && index < 3) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && index > 0 && inputs[index] === "") {
      // Backspace ile önceki input alanına git
      inputRefs.current[index - 1]?.focus();
    }
  };
  const isAllInputsFilled = inputs.every((input) => input.trim() !== "");

  const formatPhoneNumber = (phoneNumber: string, phoneCode: string) => {
    // Remove non-numeric characters
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    // Match and format the number
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `0 (${match[1]}) ${match[2]} ${match[3]} ${match[4]}`;
    }
    return phoneNumber;
  };

  return (
    <div className="flex w-full items-center justify-center  h-screen  bg-gray-800 bg-opacity-50 fixed top-0 left-0">
      <div className="bg-white rounded-md p-4 w-96   h-auto text-black">
        <div className="flex flex-col w-full h-auto gap-y-2">
          <button
            onClick={onClose}
            className="w-full flex items-center justify-end"
          >
            <IoClose className="text-lg" />
          </button>
          <div className="w-full items-center justify-center flex">
            <MdVerified className="text-8xl text-green-500" />
          </div>
          <div className="w-full items-center justify-center flex flex-col gap-y-3">
            <span className="text-xl poppins-bold text-black">
              Doğrulama Kodunu gir
            </span>
            <span className="text-sm poppins-medium">
              Kod gönderdiğimiz telefon numarası:
            </span>
            <span className="text-md poppins-semibold">
              {formatPhoneNumber(userInfo?.phone || "", "")}
            </span>
            <div className="w-full flex gap-x-2 items-center justify-center">
              {inputs.map((value, index) => (
                <input
                  key={index}
                  ref={(ref) => (inputRefs.current[index] = ref)}
                  type="text"
                  pattern="[0-9]*"
                  inputMode="numeric"
                  className="w-10  h-10 text-center outline-none border border-gray-400 focus:border-mains hover:border-purple-500 transition-all duration-300 eays-in rounded-xl"
                  value={value}
                  onChange={(e) => {
                    const filteredValue = e.target.value.replace(/[^\d]/g, "");
                    handleChange(index, filteredValue);
                  }}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                />
              ))}
            </div>

            <button
              className={`px-16 py-3 mt-3 rounded-2xl ${
                isAllInputsFilled
                  ? "bg-green-500 hover:bg-green-600 transition-all duration-300 easy-out"
                  : "bg-gray-200"
              }`}
              onClick={() => {
                if (verifying) return;
                setVerifying(true);
                dispatch(
                  confirmUserVerifySms({
                    code: confirmCode,
                  })
                ).then((result: any) => {
                  setVerifying(false);
                  if (result.payload) {
                    if (result.payload.status_code === 200) {
                      toast.success("Telefon Doğrulama Başarılı");
                      onClose && onClose();
                      dispatch(getUserInfo());
                    }
                  }
                });
              }}
            >
              <span
                className={`text-xs poppins-semibold flex h-6 justify-center items-center  ${
                  isAllInputsFilled ? "text-white " : "text-gray-500"
                }`}
              >
                {verifying ? (
                  <LuLoader2 className={"animate-spin"} />
                ) : (
                  "Doğrula"
                )}
              </span>
            </button>
            <div className="flex gap-x-1 items-center">
              <RiTimeLine />
              {minutes.toString().padStart(2, "0")}:
              {seconds.toString().padStart(2, "0")}
            </div>
            {buttonRef && (
              <button
                className="text-xs poppins-semibold text-gray-800 py-2"
                onClick={() => {
                  setRefresh(true);
                  dispatch(sendUserVerifySms({ phone: userInfo?.phone }));
                }}
              >
                Tekrar Gönder
              </button>
            )}
            <Link className="mb-2" to={"ayarlar"}>
              <span className="text-xs poppins-medium underline">
                Hatalı Telefon Numarası Girdim
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NumberVerification;
