import React, { useContext, useState } from "react";
import { CardTemplate } from "./components/card-template";
import { IApplicantsData } from "../../types";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Fragment } from "react";
import { RiErrorWarningLine } from "react-icons/ri";
import { FaSquare } from "react-icons/fa";
import { FaCircle } from "react-icons/fa6";
import { CandidatePoolContext } from "../../index";
import PaginationWeb from "../../../../job-page-container/main/advert-section-body/components/pagination";
import PaginationMobile from "../../../../job-page-container/main/advert-section-body/components/mobile-pagination";

function CardsSection({
  cardCheckedStatus,
  onCardCheckboxToggle,
  data,
  pagesCount,
  currentPage,
  setCurrentPage,
}: {
  cardCheckedStatus: { [key: number]: boolean };
  onCardCheckboxToggle: (id: number) => void;
  data: IApplicantsData[];
  pagesCount: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}) {
  const { scrollRef, pagination } = useContext(CandidatePoolContext);

  const currentPageData = data;

  return (
    <div className="flex flex-col md:gap-4 gap-[1px] mb-4 ">
      {currentPageData.length <= 0 ? (
        <div className="border flex  mx-5 my-5 rounded-xl shadow h-auto bg-white">
          <div className="bg-red-600 w-12 rounded-l-xl flex items-center justify-center  text-white">
            <RiErrorWarningLine className="w-10 h-10" />
          </div>
          <div className="flex-1 px-5 py-10 ">
            <h3 className="font-bold text-xl mb-3">
              Aradığınız kritere uygun aday bulunamadı.
            </h3>
            <h3 className="font-bold text-xl mb-6">
              Doğru sonuçlara ulaşmak için şunlara dikkat etmelisin:
            </h3>
            <div className="flex flex-col gap-3">
              <p className="flex items-start justify-start text-lg gap-3 text-gray-500">
                <div className={""}>
                  <FaCircle className="text-black w-2 h-2 mt-3" />
                </div>
                <div>Yazım yanlışı yapmadığından emin olmalısın.</div>
              </p>{" "}
              <p className="flex items-start justify-start text-lg gap-3 text-gray-500">
                <div>
                  <FaCircle className="text-black w-2 h-2 mt-3" />
                </div>
                <div>
                  Daha genel kullanıma sahip kelimeler kullanabilirsin. (Örn:
                  "HR" yerine "İK" ya da "İnsan Kaynakları")
                </div>
              </p>{" "}
              <p className="flex items-start justify-start text-lg gap-3 text-gray-500">
                <div>
                  <FaCircle className="text-black w-2 h-2 mt-3" />
                </div>
                <div>
                  Daha farklı kelimeler kullanabilirsin. (Örn: "Yazılımcı"
                  yerine "Backend Yazılım Uzmanı")
                </div>
              </p>
              <p className="flex items-start justify-start text-lg gap-3 text-gray-500">
                <div>
                  <FaCircle className="text-black w-2 h-2 mt-3" />
                </div>
                <div>
                  Kısaltma kullanmak yerine, kelimeleri açık şekilde
                  yazabilirsin. (Örn: "Müh" yerine "Mühendis")
                </div>
              </p>
            </div>
          </div>
        </div>
      ) : (
        currentPageData?.map((elm: IApplicantsData) => (
          <div key={elm.cv.cv_id}>
            <CardTemplate
              data={elm}
              isChecked={cardCheckedStatus[elm.cv.cv_id] || false}
              onCheckboxToggle={() => onCardCheckboxToggle(elm.cv.cv_id)}
            />
          </div>
        ))
      )}

      {/* Desktop pagination */}
      {(pagination
        ? pagination.last_page
          ? pagination.last_page > 1
          : false
        : false) && (
        <div className="hidden xl:block h-12 w-full">
          <PaginationWeb
            pagination={pagination}
            onChange={() => {
              scrollRef?.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }}
          />
        </div>
      )}

      {/* Mobile pagination */}
      {(pagination
        ? pagination.last_page
          ? pagination.last_page > 1
          : false
        : false) && (
        <div className="block xl:hidden w-full">
          <PaginationMobile
            pagination={pagination}
            onChange={() => {
              scrollRef?.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }}
          />
        </div>
      )}
    </div>
  );
}

export { CardsSection };
