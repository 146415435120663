import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICompanyUser } from "../../types/ICompanyUser";
import Axios from "../../services/axios";
import { toast } from "react-toastify";

const axios = new Axios();
interface IAuthState {
  readonly companyUser: ICompanyUser | null;
}

interface User {
  email: string;
  password: string;
}
const initialState: IAuthState = {
  companyUser: null,
};

export const getCompanyUser: any =
  () =>
  async (
    dispatch: (arg0: {
      payload: User[];
      type: "address/getCompanyUserSuccess";
    }) => void
  ) => {
    try {
      const response = await axios.authRequest({
        endpoint: "companies/account-settings/get-setting-info",
        type: "get",
        payload: {},
      });
      console.log("response from store request", response.data.company);
      dispatch({
        payload: response.data.company,
        type: "address/getCompanyUserSuccess",
      });
    } catch (error) {
      console.log(error);
    }
  };

// SEND VERIFY EMAIL
export const sendUserVerifyEmail = createAsyncThunk(
  "userEmail/sendUserVerifyEmail",
  async (email: any) => {
    try {
      const response = await axios.authRequest({
        type: "post",
        endpoint: "companies/account-settings/send-verify-email",
        payload: email,
      });
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      console.log("Error invalid mail adress", error);
      throw error;
    }
  }
);

// SEND CONFIRM EMAIL
export const sendUserConfirmEmail = createAsyncThunk(
  "userEmail/sendUserConfirmEmail",
  async (code: any) => {
    try {
      const response = await axios.authRequest({
        type: "post",
        endpoint: "companies/account-settings/confirm-verify-email",
        payload: code,
      });
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      console.log("Error invalid confirm code", error);
      throw error;
    }
  }
);

// SEND VERIFY SMS
export const sendUserVerifySms = createAsyncThunk(
  "userPhone/sendUserVerifySms",
  async (phone: any) => {
    try {
      const response = await axios.authRequest({
        type: "post",
        endpoint: "companies/account-settings/send-verify-sms",
        payload: phone,
      });

      return response.data;
    } catch (error) {
      console.log("Error invalid number", error);
      throw error;
    }
  }
);

export const companyUserSlice = createSlice({
  name: "companyUser",
  initialState,
  reducers: {
    setCompanyUser: (state, action: PayloadAction<ICompanyUser>) => {
      state.companyUser = action.payload;
    },
    clearCompanyUser: (state) => {
      state.companyUser = null;
    },
    getCompanyUser: (state, action) => {
      state.companyUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("address/getCompanyUserSuccess", (state, action: any) => {
      state.companyUser = action.payload;
    });
  },
});

export const { setCompanyUser, clearCompanyUser } = companyUserSlice.actions;
export default companyUserSlice.reducer;
