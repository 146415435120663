import { FaAngleUp } from "react-icons/fa";

interface DetailsButtonsProps {
  active: string;
  setActive: (value: string) => void;
}

const Data = [
  "Kişisel Bilgiler",
  "İş Deneyimleri",
  "Yabancı Dil",
  "Eğitim",
  "Özel Durumlar",
  "Diğer Seçenekler",
];

function ButtonsComponent({ active, setActive }: DetailsButtonsProps) {
  return (
    <div className=" overflow-x-auto flex flex-row gap-12 md:gap-3 my-5 md:mx-3 ">
      {Data.map((item) => (
        <button
          type="button"
          className={
            active === item
              ? "bg-primary200 flex px-2 items-center justify-between text-white rounded border border-gray-600 min-w-[150px] py-2"
              : "bg-lightgry200 flex px-2 items-center justify-between text-stone-600 rounded border border-gray-600 min-w-[150px]"
          }
          onClick={() => setActive(item)}
          key={item}
        >
          <span className="font-poppins poppins-medium whitespace-nowrap text-[15px]">
            {item}
          </span>

          <FaAngleUp
            className={`ml-2 transition-transform duration-300 transform ${
              active !== item ? "rotate-180" : ""
            }`}
          />
        </button>
      ))}
    </div>
  );
}

export default ButtonsComponent;
