import { HTMLAttributes } from "react";
import { useContext } from "react";
import { TooltipContext, tooltipContextType } from ".";

type contentPropsType = HTMLAttributes<HTMLDivElement> & {};

export default function TooltipContent({
  className,
  ...props
}: contentPropsType) {
  const { visible, content }: tooltipContextType = useContext(TooltipContext);

  if (!visible) return null;

  return (
    <div
      className={`${className} absolute bg-gray-700 text-white p-2 rounded shadow-md`}
      {...props}
    >
      {content}
    </div>
  );
}