import React, { useEffect, useRef, useState } from "react";
import { CiSearch } from "react-icons/ci";
import services from "../../../../../services/profile-services";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GoTrash } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { FaHeart } from "react-icons/fa6";

interface DataItem {
  user_id: number;
  favorite_id: number;
  advert_id: number;
  advert_title: string;
  advert_slug: string;
  company_logo: string;
  advert_status: string;
  is_apply: boolean;
  company_detail: {
    company_name: string;
  };
}

const Body: React.FC = () => {
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const [mobilButtons, setMobilButtons] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [data, setData] = useState<DataItem[]>([]);
  const [filteredData, setFilteredData] = useState<DataItem[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const getFavoriteJobs = async () => {
    try {
      const response = await services?.forMeJob();
      setData(response.data.data);
      setFilteredData(response.data.data); // Başlangıçta tüm verileri göster
    } catch (error) {
      console.error(
        "Kullanıcı erişim yetkisi kontrol edilirken bir hata oluştu:",
        error
      );
    }
  };

  useEffect(() => {
    getFavoriteJobs();
  }, []);

  const removeJob = async (id: number) => {
    try {
      await services?.removeSingleFavoriteJob(id);
      getFavoriteJobs();
      setMobilButtons(false);
    } catch (error) {
      console.error(
        "Kullanıcı erişim yetkisi kontrol edilirken bir hata oluştu:",
        error
      );
    }
  };

  const removeAllFavoriteJobs = async () => {
    try {
      await services?.removeAllFavoriteJobs();
      getFavoriteJobs();
    } catch (error) {
      console.error("Bir hata oluştu", error);
    }
  };

  const handleSearchChange = (title: string) => {
    setSearchTerm(title);
  };

  useEffect(() => {
    filterJobs();
    // eslint-disable-next-line
  }, [searchTerm, data]);

  const normalizeString = (str: string) => {
    return str
      .replace(/ğ/g, "g")
      .replace(/Ğ/g, "G")
      .replace(/ü/g, "u")
      .replace(/Ü/g, "U")
      .replace(/ş/g, "s")
      .replace(/Ş/g, "S")
      .replace(/ı/g, "i")
      .replace(/İ/g, "I")
      .replace(/ö/g, "o")
      .replace(/Ö/g, "O")
      .replace(/ç/g, "c")
      .replace(/Ç/g, "C");
  };

  const filterJobs = () => {
    const normalizedSearchTerm = normalizeString(searchTerm.toLowerCase());

    const newAdds =
      data && data.length > 0
        ? data.filter((item: any) =>
            normalizeString(item.advert_title?.toLowerCase() || "").includes(
              normalizedSearchTerm
            )
          )
        : [];

    setFilteredData(newAdds);
  };

  function showMobilButtons() {
    setMobilButtons(true);
  }
  const hideMobilButtons = () => {
    setMobilButtons(false);
    setSelectedItem(null);
  };

  const truncate = (str: string, n: number) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const noFavoriteJobs = data.length === 0;
  const noSearchResults = filteredData.length === 0 && searchTerm !== "";

  console.log(data, "data");
  console.log(filteredData, "filteredData");

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center justify-between w-full py-5 mb-5 bg-white border-b rounded-md shadow-lg px-7">
        <span className="text-mains text-[19px] poppins-medium">
          KAYITLI İLANLARIM
        </span>
        <button
          className="focus:outline-none"
          onClick={() => {
            Swal.fire({
              icon: "warning",
              title: "İşlemi onaylayın",
              text: "Kayıtlı ilanları silmek istediğinize emin misiniz?",
              confirmButtonColor: "#2253af",
              confirmButtonText: "Evet",
              cancelButtonColor: "#C62E2E",
              cancelButtonText: "İptal",
              showCancelButton: true,
              showCloseButton: true,
              preConfirm: async () => {
                await removeAllFavoriteJobs();
              },
            });
          }}
        >
          {data.length > 0 && (
            <span
              className="text-[#ff0000] poppins-medium leading-[21px] text-[11px]"
              style={{ fontWeight: "400" }}
            >
              Tümünü Sil
            </span>
          )}
        </button>
      </div>

      {!noFavoriteJobs && (
        <>
          <div className="flex items-center gap-4 mb-4 bg-white rounded-lg">
            <div
              onClick={() => inputRef.current?.focus()}
              className="flex items-center border-[1px] border-[#b2b6b9] w-full py-[11px] px-5 gap-2 rounded-lg cursor-text"
            >
              <CiSearch size={20} />
              <input
                ref={inputRef}
                type="text"
                placeholder="Kayıtlı İlanlarımda Ara"
                className="items-center w-full text-xs outline-none text-title poppins-regular"
                value={searchTerm}
                onChange={(e) => handleSearchChange(e.target.value)}
              />
            </div>
          </div>
        </>
      )}

      <div className="flex flex-col w-full gap-2">
        {noFavoriteJobs ? (
          <div className="flex flex-col items-center gap-4 rounded-md">
            <div className="rounded-l-md py-3 px-1">
              <FaHeart className="text-9xl text-red-500" />
            </div>

            <div className="font-poppins poppins-semibold text-xl">
              Kayıtlı İlan Bulunamadı
            </div>

            <div>
              <label className="font-poppins text-gray-800 flex flex-col items-center justify-center gap-6">
                <span className="font-poppins poppins-medium text-center">
                  İstediğiniz ilanları kaydederek ekleyebilir ve daha sonra
                  burada listeleyebilirsiniz.
                </span>
                <a
                  href="/is-ilanlari"
                  className="text-white bg-mains px-6 py-2 rounded-md font-poppins poppins-medium text-base flex justify-center items-center whitespace-nowrap"
                >
                  İlanları İncele
                </a>
              </label>
            </div>
          </div>
        ) : noSearchResults ? (
          <div className="flex flex-col items-center gap-4 rounded-md"></div>
        ) : (
          filteredData.map((item, index) => (
            <div key={index}>
              <div className="md:flex hidden items-center justify-between w-full p-2 bg-white rounded-lg card-shadow">
                <div className="flex items-center gap-4">
                  <img
                    src={
                      item.company_logo ||
                      "https://isbull.s3.eu-north-1.amazonaws.com/default-images/company-logo.png"
                    }
                    alt="company-logo"
                    className="w-16 h-16 rounded-full lg:rounded-none"
                  />
                  <div className="flex flex-col gap-2">
                    <a
                      href={`/is-ilanlari/${item.advert_slug}`}
                      className="text-lg font-bold truncate text-mains"
                    >
                      {item.advert_title}{" "}
                      <span>
                        {" "}
                        {item.advert_status === "ACTIVE"
                          ? ""
                          : "Pasif İlan"}{" "}
                      </span>
                    </a>
                    <p>{item.company_detail.company_name}</p>
                  </div>
                </div>

                <div
                  className="hidden lg:block"
                  onMouseEnter={() => {
                    showMobilButtons();
                    setSelectedItem(index);
                  }}
                  onMouseLeave={() => {
                    hideMobilButtons();
                  }}
                  style={{ position: "relative" }}
                >
                  <button className="peer">
                    <BsThreeDotsVertical
                      className="text-[#1f4ca1] text-[30px]"
                      style={{ transform: "scaley(1.2)" }}
                    />
                  </button>
                  {mobilButtons && index === selectedItem && (
                    <div
                      className="bg-[white] w-[200px] py-3 px-3 shadow-md"
                      style={{
                        position: "absolute",
                        right: "-8px",
                        top: "30px",
                        zIndex: "999",
                      }}
                    >
                      <div
                        className="bg-[white] cursor-pointer hover:bg-[#2253af] border border-[#f1f1f1] rounded-lg flex items-center gap-3 text-sm text-[#2253af] hover:text-[white] h-[31px] w-[180px] ps-3"
                        onClick={() => {
                          Swal.fire({
                            icon: "warning",
                            title: "İşlemi Onaylayın",
                            text: "Kayıtlı ilanlar listesinden çıkarmak istediğinize emin misiniz?",
                            confirmButtonColor: "#2253af",
                            confirmButtonText: "Evet",
                            cancelButtonColor: "#C62E2E",
                            cancelButtonText: "İptal",
                            showCancelButton: true,
                            showCloseButton: true,
                            preConfirm: async () => {
                              await removeJob(item.favorite_id);
                            },
                          });
                        }}
                      >
                        <p>Kaldır</p>
                      </div>

                      {item.is_apply ? (
                        <div
                          onClick={() =>
                            navigate(`/is-ilanlari/${item.advert_slug}`)
                          }
                          className="bg-[white] hover:bg-[#2253af] border border-[#f1f1f1] rounded-lg flex items-center gap-3 text-sm text-[#2253af] hover:text-[white] h-[31px] w-[180px] ps-3 cursor-pointer"
                        >
                          <p>Başvuruldu</p>
                        </div>
                      ) : (
                        <div
                          className="bg-[white] hover:bg-[#2253af] border border-[#f1f1f1] rounded-lg flex items-center gap-3 text-sm text-[#2253af] hover:text-[white] h-[31px] w-[180px] ps-3 cursor-pointer"
                          onClick={() =>
                            navigate(`/is-ilanlari/${item.advert_slug}`)
                          }
                        >
                          <p>İlana Başvur</p>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="flex lg:hidden items-center justify-end gap-2 relative bottom-[10px] right-[10px]">
                    <button
                      className="bg-[#2553AA] px-[20px] py-[7px] flex items-center rounded-[10.5px]"
                      onClick={() =>
                        navigate(`/is-ilanlari/${item.advert_slug}`)
                      }
                    >
                      <span className="text-white poppins-medium text-[10px] leading-[21px]">
                        Başvur
                      </span>
                    </button>

                    <GoTrash
                      className="text-[red] cursor-pointer"
                      onClick={() => {
                        Swal.fire({
                          icon: "warning",
                          title: "İşlemi Onaylayın",
                          text: "Kayıtlı ilanlar listesinden çıkarmak istediğinize emin misiniz?",
                          confirmButtonColor: "#2253af",
                          confirmButtonText: "Evet",
                          cancelButtonColor: "#C62E2E",
                          cancelButtonText: "İptal",
                          showCancelButton: true,
                          showCloseButton: true,
                          preConfirm: async () => {
                            await removeJob(item.favorite_id);
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-col md:hidden items-center justify-between w-full p-2 bg-white rounded-lg card-shadow">
                <div className="flex items-center justify-start w-full gap-4">
                  <img
                    src={
                      item.company_logo ||
                      "https://isbull.s3.eu-north-1.amazonaws.com/default-images/company-logo.png"
                    }
                    alt="company-logo"
                    className="w-12 h-12 rounded-full lg:rounded-none"
                  />
                  <div className="flex flex-col">
                    <a
                      href={`/is-ilanlari/${item.advert_slug}`}
                      className="text-sm font-bold truncate text-mains font-poppins poppins-semibold"
                    >
                      {truncate(item.advert_title, 20)}
                    </a>
                    <p className="text-xs font-poppins poppins-medium text-gray-600">
                      {truncate(item.company_detail.company_name, 20)}
                    </p>
                  </div>
                </div>

                <div
                  className="flex items-center justify-end w-full"
                  onMouseEnter={() => {
                    showMobilButtons();
                    setSelectedItem(index);
                  }}
                  onMouseLeave={() => {
                    hideMobilButtons();
                  }}
                  style={{ position: "relative" }}
                >
                  {mobilButtons && index === selectedItem && (
                    <div
                      className="bg-[white] w-[200px] py-3 px-3 shadow-md"
                      style={{
                        position: "absolute",
                        right: "-8px",
                        top: "30px",
                        zIndex: "999",
                      }}
                    >
                      <div
                        className="bg-[white] cursor-pointer hover:bg-[#2253af] border border-[#f1f1f1] rounded-lg flex items-center gap-3 text-sm text-[#2253af] hover:text-[white] h-[31px] w-[180px] ps-3"
                        onClick={() => {
                          Swal.fire({
                            icon: "warning",
                            title: "İşlemi Onaylayın",
                            text: "Kayıtlı ilanlar listesinden çıkarmak istediğinize emin misiniz?",
                            confirmButtonColor: "#2253af",
                            confirmButtonText: "Evet",
                            cancelButtonColor: "#C62E2E",
                            cancelButtonText: "İptal",
                            showCancelButton: true,
                            showCloseButton: true,
                            preConfirm: async () => {
                              await removeJob(item.favorite_id);
                            },
                          });
                        }}
                      >
                        <p>Kaldır</p>
                      </div>
                      <div
                        className="bg-[white] hover:bg-[#2253af] border border-[#f1f1f1] rounded-lg flex items-center gap-3 text-sm text-[#2253af] hover:text-[white] h-[31px] w-[180px] ps-3"
                        onClick={() =>
                          navigate(`/is-ilanlari/${item.advert_slug}`)
                        }
                      >
                        <p>İlana Başvur</p>
                      </div>
                    </div>
                  )}
                  <div className="flex lg:hidden items-center justify-end gap-2 relative bottom-[10px] right-[10px]">
                    {item.is_apply ? (
                      <button
                        className="bg-[#2253af] px-[20px] py-[7px] flex items-center rounded-[10.5px]"
                        onClick={() =>
                          navigate(`/is-ilanlari/${item.advert_slug}`)
                        }
                      >
                        <span className="text-white poppins-medium text-[10px] leading-[21px]">
                          Başvuruldu
                        </span>
                      </button>
                    ) : (
                      <button
                        className="bg-[#2553AA] px-[20px] py-[7px] flex items-center rounded-[10.5px]"
                        onClick={() =>
                          navigate(`/is-ilanlari/${item.advert_slug}`)
                        }
                      >
                        <span className="text-white poppins-medium text-[10px] leading-[21px]">
                          Başvur
                        </span>
                      </button>
                    )}

                    <GoTrash
                      className="text-[red] cursor-pointer"
                      onClick={() => {
                        Swal.fire({
                          icon: "warning",
                          title: "İşlemi Onaylayın",
                          text: "Kayıtlı ilanlar listesinden çıkarmak istediğinize emin misiniz?",
                          confirmButtonColor: "#2253af",
                          confirmButtonText: "Evet",
                          cancelButtonColor: "#C62E2E",
                          cancelButtonText: "İptal",
                          showCancelButton: true,
                          showCloseButton: true,
                          preConfirm: async () => {
                            await removeJob(item.favorite_id);
                          },
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Body;
