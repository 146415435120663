import React, { useState, useRef, useEffect, KeyboardEvent } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Tooltip } from "antd";
import { IoIosInformationCircleOutline } from "react-icons/io";
import Swal from "sweetalert2";
import Axios from "../../../../../../services/axios";
import { FaCircle } from "react-icons/fa6";
import usePartialBold from "../../../../../../customHooks/usePartialBold"; // Adjust the path as needed

interface Option {
  value: string;
  label: string;
}

interface CustomDropdownProps {
  options: Option[];
  placeholder?: string;
  label?: string;
  id: string;
  onSelectionChange: (selectedOptions: Option[] | any) => void;
  width?: string | null;
  isError?: boolean;
  isSingle?: boolean;
  noRequired?: boolean;
  info?: string;
  searchEndpoint?: string;
  searchKey?: string;
  maxSelectedCount?: number;
  contextSelectedOptions?: Option[];
  fcolor?: string;
  bcolor?: string;
  bgcolor?: string;
  fcolorhex?: string;
  defaultVal?: string;
  shadow?: boolean;
  minwidth?: string;
}

const axios = new Axios();

export const CustomDropdown: React.FC<CustomDropdownProps> = ({
  options,
  placeholder = "Select...",
  label,
  onSelectionChange,
  id,
  width = "45%",
  isSingle,
  isError = false,
  noRequired,
  info,
  searchEndpoint,
  searchKey,
  maxSelectedCount,
  contextSelectedOptions,
  fcolor = "text-mains",
  bcolor = "border-mains",
  bgcolor = "bg-white",
  fcolorhex = "#3B82F6",
  defaultVal = "",
  shadow = false,
  minwidth = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState(defaultVal);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(
    contextSelectedOptions ? contextSelectedOptions : []
  );
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const containerRef = useRef<HTMLDivElement | null>(null);

  async function searchByTerm() {
    if (searchEndpoint) {
      if (search.trim().length > 0) {
        if (!isOpen) setIsOpen(true);
        try {
          const response = await axios.request({
            endpoint: `${searchEndpoint}?search=${search}&limit=100`,
            type: "get",
          });

          setFilteredOptions(
            response.data[searchKey || ""].map((p: any) => {
              return {
                value: p.id.toString(),
                label: p.title ? p.title : p.name ? p.name : "",
              };
            })
          );
        } catch (error) {
          console.log(`Positions fetch failed. Error:${error}`);
          setFilteredOptions([]);
        }
      } else {
        setFilteredOptions(options);
      }
    } else {
      setFilteredOptions(options);
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setSearch("");
    }

    async function getListItems() {
      const response = await axios.request({
        endpoint: `${searchEndpoint}`,
        type: "get",
      });

      setFilteredOptions(
        response.data[searchKey || "A"].map((m: any) => {
          return {
            value: m.id.toString(),
            label: m.title ? m.title : m.name ? m.name : "",
          };
        })
      );
    }

    if (
      isOpen &&
      searchEndpoint &&
      search.trim().length == 0 &&
      filteredOptions.length == 0
    ) {
      getListItems();
    }
  }, [isOpen]);

  useEffect(() => {
    const timeOut = setTimeout(() => searchByTerm(), 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [search]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setSearch("");
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleSelectOption = (option: Option, maxSelectedCount?: number) => {
    const isOptionSelected = selectedOptions.some(
      (selectedOption) => selectedOption.value === option.value
    );

    // Max seçim için sınırlama
    if (!isOptionSelected && maxSelectedCount) {
      if (selectedOptions.length >= maxSelectedCount) {
        Swal.fire({
          icon: "error",
          title: `Hata`,
          text: `En fazla ${maxSelectedCount} seçenek işaretleyebilirsiniz.`,
          confirmButtonText: "Tamam",
        });
        return;
      }
    }

    const newSelectedOptions = isOptionSelected
      ? selectedOptions.filter(
          (selectedOption) => selectedOption.value !== option.value
        )
      : [...selectedOptions, option];
    setSelectedOptions(newSelectedOptions);
    if (isSingle) {
      onSelectionChange(option.value);
      setSelectedOptions([{ label: option.label, value: option.value }]);
      setIsOpen(false);
    } else {
      setSelectedOptions(newSelectedOptions);
      onSelectionChange(newSelectedOptions);
    }
  };

  const getSelectedOptionsDisplay = (): JSX.Element | any => {
    if (selectedOptions.length > 1) {
      const firstLabel = selectedOptions[0].label;
      const additionalCount = selectedOptions.length - 1;
      return (
        <span className={`${fcolor}`}>
          {firstLabel}{" "}
          <span className={`${fcolor}`}> (+{additionalCount})</span>
        </span>
      );
    }
    return (
      <span>
        {selectedOptions.map((option) => option.label).join(", ") ||
          placeholder}
      </span>
    );
  };

  function searchInputKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (search.length == 0 && e.key == "Backspace") {
      e.preventDefault();
      setSelectedOptions((prev) => [...prev.slice(0, -1)]);
    }
  }

  return (
    <div
      ref={containerRef}
      className={`min-w-[${minwidth}] w-full md:w-${width} justify-center flex flex-col mb-3 lg:mb-0 mr-3`}
    >
      {label && (
        <label
          onClick={() => setIsOpen((prev) => !prev)}
          htmlFor={id}
          className="font-poppins poppins-semibold  text-sm m-1 lg:m-2 ml-0 flex justify-start items-center gap-1 !text-gray-500"
        >
          {label}{" "}
          {!noRequired && (
            <>
              <span className="text-rose-500 text-l">*</span>{" "}
              <Tooltip title={info}>
                <IoIosInformationCircleOutline className="text-[#6B7280] text-lg " />
              </Tooltip>
            </>
          )}
        </label>
      )}
      <div className="relative">
        <div
          className="flex relative flex-row items-center"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {selectedOptions.length > 0 &&
            isOpen &&
            search.trim().length == 0 && (
              <label
                className={`whitespace-nowrap absolute top-1/2 left-3  ${fcolor} font-poppins text-[14px] md:text-[15px] -translate-y-1/2`}
              >
                {selectedOptions[0].label}
                {selectedOptions.length > 1 &&
                  ` (+${selectedOptions.length - 1})`}
              </label>
            )}

          <input
            value={search}
            onChange={(e) => {
              if (!isOpen) setIsOpen(true);
              setSearch(e.target.value);
            }}
            //onFocus={() => setIsOpen(true)}
            onKeyDown={(e) => searchInputKeyDown(e)}
            className={`${bgcolor} ${
              shadow && "shadow"
            } w-full indent-2 border rounded-md text-gray-600 !outline-none form-input py-2 flex justify-between items-center ${
              selectedOptions.length > 0
                ? `${bcolor} ${fcolor}`
                : "border-gray-400"
            } ${
              isError
                ? `${
                    selectedOptions.length > 0
                      ? `${bcolor}`
                      : "!bg-red-100 border-red-600"
                  }  text-red-600`
                : ""
            }`}
          />
          <div
            className="absolute left-3 top-1/2 -translate-y-1/2 font-poppins text-[14px] md:text-[15px]"
            style={{
              display: isOpen ? "none" : "block",
              color:
                getSelectedOptionsDisplay()?.props?.children === "Seçiniz"
                  ? ""
                  : fcolorhex,
            }}
          >
            {" "}
            {getSelectedOptionsDisplay()}
          </div>
          <div className="absolute end-0 top-3">
            {isOpen ? (
              <IoIosArrowUp
                className={`ml-2 mr-3 ${
                  selectedOptions.length > 0 ? `${fcolor}` : "text-gray-400"
                }`}
              />
            ) : (
              <IoIosArrowDown
                className={`ml-2 mr-3 ${
                  selectedOptions.length > 0 ? `${fcolor}` : "text-gray-400"
                }`}
              />
            )}
          </div>
        </div>

        {isOpen && (
          <div className="absolute z-[900] w-full bg-white border border-gray-400 rounded-md mt-1 overflow-y-auto max-h-60">
            {filteredOptions.length == 0 && search.length > 0 && (
              <label className="text-gray-400 text-xs p-2">
                Sonuç bulunamadı
              </label>
            )}

            {selectedOptions.map((option) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const formattedLabel = usePartialBold({
                boldString: search,
                fullString: option.label,
                returnCase: "NormalCase", // Adjust returnCase as needed
              });

              return (
                <div
                  key={`selectedOption-${option.value}`}
                  onClick={() => {
                    handleSelectOption(option, maxSelectedCount);
                  }}
                  className={`py-2 px-4 hover:bg-gray-100 flex items-center cursor-pointer ${
                    selectedOptions.find(
                      (selectedOption) => selectedOption.value === option.value
                    )
                      ? "text-blue-500"
                      : ""
                  }`}
                >
                  {!isSingle && (
                    <span
                      className={`h-4 w-4 mr-2 flex items-center justify-center rounded-full border-2 ${
                        selectedOptions.find(
                          (selectedOption) =>
                            selectedOption.value === option.value
                        )
                          ? `${fcolor} border-blue-500`
                          : `${fcolor} border-gray-400`
                      }`}
                    >
                      {!isSingle &&
                        selectedOptions.find(
                          (selectedOption) =>
                            selectedOption.value == option.value
                        ) && <FaCircle className={`${fcolor} text-[8px]`} />}
                    </span>
                  )}
                  <span dangerouslySetInnerHTML={{ __html: formattedLabel }} />
                </div>
              );
            })}

            {filteredOptions.map((option) => {
              if (
                selectedOptions.find((s) => s.value == option.value) ==
                undefined
              ) {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const formattedLabel = usePartialBold({
                  boldString: search,
                  fullString: option.label,
                  returnCase: "NormalCase", // Adjust returnCase as needed
                });

                return (
                  <div
                    key={`${option.value}`}
                    onClick={() => {
                      handleSelectOption(option, maxSelectedCount);
                    }}
                    className="py-2 px-4 hover:bg-gray-100 flex items-center cursor-pointer"
                  >
                    {!isSingle && (
                      <span
                        className={`h-4 w-4 mr-2 flex items-center justify-center rounded-full border-2 ${
                          selectedOptions.find(
                            (selectedOption) =>
                              selectedOption.value === option.value
                          )
                            ? `${fcolor} border-blue-500`
                            : `${fcolor} border-gray-400`
                        }`}
                      >
                        {!isSingle &&
                          selectedOptions.find(
                            (selectedOption) =>
                              selectedOption.value == option.value
                          ) && <FaCircle className={`${fcolor} text-[8px]`} />}
                      </span>
                    )}
                    <span
                      dangerouslySetInnerHTML={{ __html: formattedLabel }}
                    />
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomDropdown;
