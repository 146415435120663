import * as React from "react";

interface IMediaQueryProps {
  readonly isMobile: boolean;
  readonly isTablet: boolean;
}
//Tablet view ekledim
function useIsMobile(): IMediaQueryProps {
  const [isMobile, setIsMobile] = React.useState<boolean>(false);
  const [isTablet, setIsTablet] = React.useState<boolean>(false);

  React.useEffect(() => {
    const mediaQueryMobile = window.matchMedia("(max-width: 768px)");
    const mediaQueryTablet = window.matchMedia(
      "(min-width: 769px) and (max-width: 1024px)"
    );

    setIsMobile(mediaQueryMobile.matches);
    setIsTablet(mediaQueryTablet.matches);

    const listenerMobile = (event: MediaQueryListEvent): void => {
      setIsMobile(event.matches);
    };

    const listenerTablet = (event: MediaQueryListEvent): void => {
      setIsTablet(event.matches);
    };

    mediaQueryMobile.addEventListener("change", listenerMobile);
    mediaQueryTablet.addEventListener("change", listenerTablet);

    return () => {
      mediaQueryMobile.removeEventListener("change", listenerMobile);
      mediaQueryTablet.removeEventListener("change", listenerTablet);
    };
  }, []);

  const data = React.useMemo(() => {
    return { isMobile, isTablet };
  }, [isMobile, isTablet]);

  return data;
}

export { useIsMobile };
