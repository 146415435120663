import { CiUser } from "react-icons/ci";
import { FaPhoneAlt } from "react-icons/fa";
import { LiaFaxSolid } from "react-icons/lia";
import { Position } from "../index";
import { PositionLevel } from "../index";
import { Sector } from "../index";
import { WorkingLocation } from "../index";
import { WorkingType } from "../index";
import { Location } from "../index";
import { EducationLevels } from "../index";
import { ExperienceLevel } from "../index";
import { MdOutlineElectricBolt } from "react-icons/md";
import { RiMarkPenLine } from "react-icons/ri";

import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Section } from "../index";
import { CompanyDetail } from "../index";
import { Link, useNavigate } from "react-router-dom";
import { MilitaryStatuses } from "../index";
import classNames from "classnames";
import Swal from "sweetalert2";
import {
  cdnDrivingLicenseSrcType,
  cdnDrivingLicesesType,
  cdnGeneralLanguageType,
} from "../../../../types";
import { IoPersonOutline } from "react-icons/io5";
import { BsTelephone } from "react-icons/bs";

interface MainInfoProps {
  companyFullName: string;
  land_phone: string;
  companyPhone: string;
  positions: Position[] | undefined;
  sections: Section[] | undefined;
  positionLevels: PositionLevel[] | undefined;
  sectors: Sector[] | undefined;
  workingTypes: WorkingType[] | undefined;
  workingLocations: WorkingLocation[] | undefined;
  locations: Location[] | undefined;
  educationLevels: EducationLevels[] | undefined;
  experienceLevel: ExperienceLevel | undefined;
  companyDetail: CompanyDetail | undefined;
  militaryStatuses: MilitaryStatuses[] | undefined;
  minAge: number;
  maxAge: number;
  dictionary: any;
  jobBenefits?: {
    id: number;
    name: string;
    name_en: string;
    created_at: string;
    updated_at: string;
  }[];
  languages: cdnGeneralLanguageType[];
  hide_contact_info: string;
  hide_employee_info: number;
  hide_company_info: number;
  employee_name: string;
  drivingLicenceClasses: {
    driving_licence_class: cdnDrivingLicesesType;
  }[];
  drivingLicenceSrcs: {
    driving_licence_src: cdnDrivingLicenseSrcType;
  }[];
  positionDictionary: {
    id: number;
    title: string;
    slug: string;
    min_salary: string;
    max_salary: string;
    avg_salary: string;
  } | null;
}

// * tecrübe
// eğitim seviyesi
// * pozisyon
// pozisyon seviyesi
// * sektör
// bölüm
// çalışma yeri
// * çalışma şekli
// yaş aralığı
// aranılan Personel
// Çalışma tercihi

const MainInfo: React.FC<MainInfoProps> = ({
  companyFullName,
  positions,
  sectors,
  workingTypes,
  positionLevels,
  workingLocations,
  locations,
  companyPhone,
  land_phone,
  educationLevels,
  experienceLevel,
  sections,
  companyDetail,
  militaryStatuses,
  minAge,
  maxAge,
  dictionary,
  jobBenefits,
  languages,
  hide_contact_info,
  hide_employee_info,
  hide_company_info,
  employee_name,
  drivingLicenceClasses,
  drivingLicenceSrcs,
  positionDictionary,
}) => {
  const isEnglish = false;

  const [isPositionsModalOpen, setIsPositionsModalOpen] =
    useState<boolean>(false);
  const [isEducationLevelsModalOpen, setIsEducationLevelsModalOpen] =
    useState<boolean>(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isPositionLevelsModalOpen, setIsPositionLevelsModalOpen] =
    useState<boolean>(false);
  const [isSectorModalOpen, setIsSectorModalOpen] = useState<boolean>(false);
  const [isSectionModalOpen, setIsSectionModalOpen] = useState<boolean>(false);
  const [isWorkingTypesModalOpen, setIsWorkingTypesModalOpen] =
    useState<boolean>(false);
  const [isWorkingLocationsModalOpen, setIsWorkingLocationsModalOpen] =
    useState<boolean>(false);
  const [isLanguagesModalOpen, setIsLanguagesModalOpen] =
    useState<boolean>(false);
  const [isLocationsModalOpen, setIsLocationsModalOpen] =
    useState<boolean>(false);
  const [isJobBenefitsModalOpen, setIsJobBenefitsModalOpen] =
    useState<boolean>(false);
  const [isSrcsModalOpen, setIsSrcsModalOpen] = useState<boolean>(false);
  const [isClassesModalOpen, setIsClassesModalOpen] = useState<boolean>(false);

  //console.log('position', positions)
  const navigate = useNavigate();
  function formatPhoneNumber(phoneNumber: string) {
    console.log("phoneNumber", phoneNumber);
    if (phoneNumber.length === 11) {
      // Turkish mobile and landline numbers
      // Formats to 0(XXX) XXX XX XX
      return `0(${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(
        4,
        7
      )} ${phoneNumber.slice(7, 9)} ${phoneNumber.slice(9)}`;
    } else if (phoneNumber.length === 10) {
      // Turkish mobile numbers without the leading 0
      // Formats to 0(XXX) XXX XX XX
      return `0(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
        3,
        6
      )} ${phoneNumber.slice(6, 8)} ${phoneNumber.slice(8)}`;
    }

    console.log("phoneNumber", phoneNumber);
    return phoneNumber; // Return original if it doesn't match expected length
  }

  function formatLandPhoneNumber(phoneNumber: string): string {
    if (phoneNumber.length === 12) {
      // Turkish mobile and landline numbers
      // Formats to 0(XXX) XXX XX XX
      return `0(${phoneNumber.slice(2, 5)}) ${phoneNumber.slice(
        5,
        8
      )} ${phoneNumber.slice(8, 10)} ${phoneNumber.slice(10)}`;
    }
    return phoneNumber; // Return original if it doesn't match expected length
  }

  function extractPhoneNumberPrefix(phoneNumber: string) {
    if (phoneNumber.length === 11) {
      // Turkish mobile and landline numbers
      // Formats to 0(XXX) XXX XX XX
      return `0(${phoneNumber.slice(1, 4)}) *** ** **`;
    }
  }

  function extractLandPhoneNumberPrefix(phoneNumber: string) {
    if (phoneNumber.length === 12 || phoneNumber.length === 16) {
      // Turkish mobile and landline numbers
      // Formats to 0(XXX) XXX XX XX
      return `0(${phoneNumber.slice(2, 5)}) *** ** **`;
    }
  }

  const formattedCompanyPhone = formatPhoneNumber(companyPhone);
  const formattedLandPhone = formatPhoneNumber(land_phone);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [isModalOpen5, setIsModalOpen5] = useState(false);
  const userToken = Cookies.get("userToken") || Cookies.get("companyUserToken");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleOk2 = () => {
    setIsModalOpen2(false);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const showModal3 = () => {
    setIsModalOpen3(true);
  };

  const handleOk3 = () => {
    setIsModalOpen3(false);
  };

  const handleCancel3 = () => {
    setIsModalOpen3(false);
  };

  const showModal4 = () => {
    setIsModalOpen4(true);
  };

  const handleOk4 = () => {
    setIsModalOpen4(false);
  };

  const handleCancel4 = () => {
    setIsModalOpen4(false);
  };

  const showModal5 = () => {
    setIsModalOpen5(true);
  };

  const handleOk5 = () => {
    setIsModalOpen5(false);
  };

  const handleCancel5 = () => {
    setIsModalOpen5(false);
  };

  const handleUpdateCv = () => {
    Swal.fire({
      title: "Başarılı",
      text: "CV Tarihi başarıyla güncellendi.",
      icon: "success",
      confirmButtonText: "Tamam",
    });
  };

  const dictionaryKeys = dictionary ? Object.keys(dictionary) : [];
  const dictArray = dictionaryKeys.filter((key: any) => {
    return !!dictionary[key];
  });
  const isAnyDictionary = dictArray.length > 0;
  const firstDictionary: any = isAnyDictionary ? dictionary[dictArray[0]] : {};
  const dictPositions: any = positions
    ? positions.filter((e: any) => firstDictionary.title === e.title)[0]
    : {};

  const firstLocationName =
    locations && locations[0]
      ? `${locations[0].city?.name} - ${
          !locations[0].district
            ? `${isEnglish ? "All Districts" : "Tüm İlçeler"}`
            : `${
                locations[0].district?.name == "Tüm İlçeler"
                  ? `${isEnglish ? "All Districts" : "Tüm İlçeler"}`
                  : `${locations[0].district?.name}`
              }`
        }`
      : ``;

  return (
    <div className="font-poppins">
      <h2 className=" py-2 pl-3 font-medium md:text-lg  shadow-sm border-b border-[#FFAC15] md:border-gray-200  bg-white mt-4">
        EK BİLGİLER
      </h2>
      <div className="bg-white py-3 pl-3 shadow-lg">
        <div className=" text-sm">
          {/* Mobile section */}
          <div className="block md:hidden ">
            <div className="flex flex-row flex-wrap py-2 px-0 max-w-[600px]">
              {/* Positions */}
              <div className="flex flex-col basis-1/2 px-1">
                <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
                  {isEnglish ? "Position" : "Pozisyon"}
                </label>
                <div className="flex flex-row items-start flex-wrap">
                  <Link
                    to={`/is-ilanlari?${
                      positions ? `positions=[${positions[0].id}]` : ""
                    }`}
                    className="text-xs lg:text-sm text-gray-600 font-poppins"
                  >
                    {positions &&
                      positions[0] &&
                      `${
                        isEnglish
                          ? `${positions[0]?.title_en?.slice(0, 17)}`
                          : `${positions[0]?.title?.slice(0, 17)}`
                      }${positions[0]?.title.length > 17 ? "..." : ""}`}
                  </Link>
                  {positions && positions.length > 1 && (
                    <button
                      type="button"
                      className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                      onClick={() => {
                        setIsPositionsModalOpen(true);
                      }}
                    >
                      (+{positions.length - 1})
                    </button>
                  )}
                </div>
              </div>

              {/* Position Levels */}
              {positionLevels && positionLevels.length > 0 && (
                <div className="flex flex-col basis-1/2 px-1">
                  <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
                    {isEnglish ? "Position Level" : "Pozisyon Seviyesi"}
                  </label>
                  <div className="flex flex-row items-start flex-wrap">
                    <Link
                      to={`/is-ilanlari?position_levels=[${
                        positionLevels && positionLevels[0]
                          ? positionLevels[0]?.position_level_id
                          : ""
                      }]`}
                      className="text-xs lg:text-sm text-gray-600 font-poppins"
                    >
                      {positionLevels &&
                        positionLevels[0] &&
                        `${
                          isEnglish
                            ? `${positionLevels[0]?.position_level_name_en.slice(
                                0,
                                15
                              )}`
                            : `${positionLevels[0]?.position_level_name?.slice(
                                0,
                                15
                              )}`
                        }${
                          positionLevels[0]?.position_level_name?.length > 15
                            ? "..."
                            : ""
                        }`}
                    </Link>
                    {positionLevels && positionLevels.length > 1 && (
                      <button
                        type="button"
                        className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                        onClick={() => {
                          setIsPositionLevelsModalOpen(true);
                        }}
                      >
                        (+{positionLevels.length - 1})
                      </button>
                    )}
                  </div>
                </div>
              )}

              {/* Experience Level */}
              <div className="flex flex-col basis-1/2 px-1">
                <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
                  {!isEnglish
                    ? "Tecrübe"
                    : !isEnglish
                    ? "Experience"
                    : "Tecrübe"}
                </label>
                <div className="flex flex-row items-start flex-wrap">
                  <label className="text-xs lg:text-sm text-gray-600 font-poppins">
                    {experienceLevel?.experience_level_min?.value &&
                    experienceLevel?.experience_level_max?.value
                      ? `${experienceLevel.experience_level_min.value} - ${
                          experienceLevel.experience_level_max.value
                        } ${isEnglish ? "Years" : "Yıl"}`
                      : `${isEnglish ? "Doesn't Matter" : "Fark Etmez"}`}
                  </label>
                </div>
              </div>

              {/* Education Level */}
              <div className="flex flex-col basis-1/2 px-1">
                <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
                  {isEnglish ? "Education Level" : "Eğitim Seviyesi"}
                </label>
                <div className="flex flex-row items-start flex-wrap">
                  <span className="text-xs lg:text-sm text-gray-600 font-poppins">
                    {educationLevels &&
                      educationLevels[0] &&
                      `${
                        isEnglish
                          ? `${educationLevels[0]?.education_level.title_en.slice(
                              0,
                              15
                            )}`
                          : `${educationLevels[0]?.education_level.title.slice(
                              0,
                              15
                            )}`
                      }${
                        educationLevels[0]?.education_level.title.length > 15
                          ? "..."
                          : ""
                      }`}
                  </span>
                  {educationLevels && educationLevels.length > 1 && (
                    <button
                      type="button"
                      className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                      onClick={() => {
                        setIsEducationLevelsModalOpen(true);
                      }}
                    >
                      (+{educationLevels.length - 1})
                    </button>
                  )}
                </div>
              </div>

              {/* Sectors */}
              <div className="flex flex-col basis-1/2 px-1">
                <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
                  {isEnglish ? "Sector" : "Sektör"}
                </label>
                <div className="flex flex-row items-start flex-wrap">
                  <Link
                    to={`/is-ilanlari?sectors=[${
                      sectors && sectors[0] ? `${sectors[0]?.id}` : ""
                    }]`}
                    className="text-xs lg:text-sm text-gray-600 font-poppins"
                  >
                    {sectors &&
                      sectors[0] &&
                      `${
                        isEnglish
                          ? `${sectors[0]?.title_en?.slice(0, 17)}`
                          : `${sectors[0]?.title.slice(0, 17)}`
                      }${sectors[0]?.title.length > 17 ? "..." : ""}`}
                  </Link>
                  {sectors && sectors.length > 1 && (
                    <button
                      type="button"
                      className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                      onClick={() => {
                        setIsSectorModalOpen(true);
                      }}
                    >
                      (+{sectors.length - 1})
                    </button>
                  )}
                </div>
              </div>

              {/* Sections */}
              <div className="flex flex-col basis-1/2 px-1">
                <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
                  {isEnglish ? "Section" : "Bölüm"}
                </label>
                <div className="flex flex-row items-start flex-wrap">
                  <Link
                    to={`/is-ilanlari?sections=[${
                      sections && sections[0] ? sections[0]?.id : ""
                    }]`}
                    className="text-xs lg:text-sm text-gray-600 font-poppins"
                  >
                    {sections &&
                      sections[0] &&
                      `${
                        isEnglish
                          ? `${sections[0]?.title_en.slice(0, 14)}`
                          : `${sections[0]?.title.slice(0, 14)}`
                      }${sections[0]?.title.length > 14 ? "..." : ""}`}
                  </Link>
                  {sections && sections.length > 1 && (
                    <button
                      type="button"
                      className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                      onClick={() => {
                        setIsSectionModalOpen(true);
                      }}
                    >
                      (+{sections.length - 1})
                    </button>
                  )}
                </div>
              </div>

              {/* Working types */}
              <div className="flex flex-col basis-1/2 px-1">
                <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
                  {isEnglish ? "Work Preference" : "Çalışma Tercihi"}
                </label>
                <div className="flex flex-row items-start flex-wrap">
                  <Link
                    to={`/is-ilanlari?working_locations=[${
                      workingLocations && workingLocations[0]
                        ? workingLocations[0]?.id
                        : ""
                    }]`}
                    className="text-xs lg:text-sm text-gray-600 font-poppins"
                  >
                    {workingLocations &&
                      workingLocations[0] &&
                      `${
                        isEnglish
                          ? `${workingLocations[0]?.title_en.slice(0, 15)}`
                          : `${workingLocations[0]?.title.slice(0, 15)}`
                      }${workingLocations[0]?.title.length > 15 ? "..." : ""}`}
                  </Link>
                  {workingLocations && workingLocations.length > 1 && (
                    <button
                      type="button"
                      className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                      onClick={() => {
                        setIsWorkingLocationsModalOpen(true);
                      }}
                    >
                      (+{workingLocations.length - 1})
                    </button>
                  )}
                </div>
              </div>

              {/* Working types 2 */}
              <div className="flex flex-col basis-1/2 px-1">
                <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
                  {isEnglish ? "Work Preference" : "Çalışma Şekli"}
                </label>
                <div className="flex flex-row items-start flex-wrap">
                  <Link
                    to={`/is-ilanlari?working_types=[${
                      workingTypes && workingTypes[0] ? workingTypes[0]?.id : ""
                    }]`}
                    className="text-xs lg:text-sm text-gray-600 font-poppins"
                  >
                    {workingTypes &&
                      workingTypes[0] &&
                      `${workingTypes[0]?.title}`}
                  </Link>
                  {workingTypes && workingTypes.length > 1 && (
                    <button
                      type="button"
                      className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                      onClick={() => {
                        setIsWorkingTypesModalOpen(true);
                      }}
                    >
                      (+{workingTypes.length - 1})
                    </button>
                  )}
                </div>
              </div>

              {/* Locations */}
              <div className="flex flex-col basis-1/2 px-1">
                <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
                  {isEnglish ? "Workplace" : "Çalışma Yeri"}
                </label>
                <div className="flex flex-row items-start flex-wrap">
                  <Link
                    to={`/is-ilanlari?${
                      locations && locations[0]
                        ? locations[0].city?.id
                          ? `cities=[${locations[0].city?.id}]`
                          : ``
                        : ""
                    }${
                      locations && locations[0]
                        ? locations[0].district?.id
                          ? `&districts=[${locations[0].district?.id}]`
                          : ``
                        : ""
                    }`}
                    className="text-xs lg:text-sm text-gray-600 font-poppins"
                  >
                    {locations &&
                      locations[0] &&
                      `${firstLocationName.slice(0, 15)}${
                        firstLocationName.length > 15 ? "..." : ""
                      }`}
                  </Link>
                  {locations && locations.length > 1 && (
                    <button
                      type="button"
                      className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                      onClick={() => {
                        setIsLocationsModalOpen(true);
                      }}
                    >
                      (+{locations.length - 1})
                    </button>
                  )}
                </div>
              </div>

              {/* Languages */}
              {languages && languages.length > 0 && (
                <div className="flex flex-col basis-1/2 px-1">
                  <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
                    {isEnglish ? "Foreign Language" : "Yabancı Dil"}
                  </label>
                  <div className="flex flex-row items-start flex-wrap">
                    <span className="text-xs lg:text-sm text-gray-600 font-poppins">
                      {languages &&
                        languages[0] &&
                        `${
                          isEnglish
                            ? `${languages[0]?.name_en.slice(0, 15)}`
                            : `${languages[0]?.name.slice(0, 15)}`
                        }${languages[0]?.name.length > 15 ? "..." : ""}`}
                    </span>
                    {languages && languages.length > 1 && (
                      <button
                        type="button"
                        className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                        onClick={() => {
                          setIsLanguagesModalOpen(true);
                        }}
                      >
                        (+{languages.length - 1})
                      </button>
                    )}
                  </div>
                </div>
              )}

              {/* Job Benefits */}
              {jobBenefits && jobBenefits.length > 0 && (
                <div className="flex flex-col basis-1/2 px-1">
                  <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
                    {isEnglish ? "Job Benefits" : "Yan Haklar"}
                  </label>
                  <div className="flex flex-row items-start flex-wrap">
                    <span className="text-xs lg:text-sm text-gray-600 font-poppins">
                      {jobBenefits &&
                        jobBenefits[0] &&
                        `${
                          isEnglish
                            ? `${jobBenefits[0]?.name_en.slice(0, 15)}`
                            : `${jobBenefits[0]?.name.slice(0, 15)}`
                        }${jobBenefits[0]?.name.length > 17 ? "..." : ""}`}
                    </span>
                    {jobBenefits && jobBenefits.length > 1 && (
                      <button
                        type="button"
                        className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                        onClick={() => {
                          setIsJobBenefitsModalOpen(true);
                        }}
                      >
                        (+{jobBenefits.length - 1})
                      </button>
                    )}
                  </div>
                </div>
              )}

              {/* SRC Certificates */}
              {drivingLicenceSrcs && drivingLicenceSrcs.length > 0 && (
                <div className="flex flex-col basis-1/2 px-1">
                  <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
                    {isEnglish ? "SRC Certificates" : "SRC Belgeleri"}
                  </label>
                  <div className="flex flex-row items-start flex-wrap">
                    <span className="text-xs lg:text-sm text-gray-600 font-poppins">
                      {drivingLicenceSrcs &&
                        drivingLicenceSrcs[0] &&
                        `${drivingLicenceSrcs[0].driving_licence_src?.name}`}
                    </span>
                    {drivingLicenceSrcs && drivingLicenceSrcs.length > 1 && (
                      <button
                        type="button"
                        className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                        onClick={() => {
                          setIsSrcsModalOpen(true);
                        }}
                      >
                        (+{drivingLicenceSrcs.length - 1})
                      </button>
                    )}
                  </div>
                </div>
              )}

              {/* Driving Licence classes */}
              {drivingLicenceClasses && drivingLicenceClasses.length > 0 && (
                <div className="flex flex-col basis-1/2 px-1">
                  <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
                    {isEnglish ? "Driver's Licenses" : "Sürücü Lisansları"}
                  </label>
                  <div className="flex flex-row items-start flex-wrap">
                    <span className="text-xs lg:text-sm text-gray-600 font-poppins">
                      {drivingLicenceClasses &&
                        drivingLicenceClasses[0] &&
                        `${drivingLicenceClasses[0].driving_licence_class.name}`}
                    </span>
                    {drivingLicenceClasses &&
                      drivingLicenceClasses.length > 1 && (
                        <button
                          type="button"
                          className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                          onClick={() => {
                            setIsClassesModalOpen(true);
                          }}
                        >
                          (+{drivingLicenceClasses.length - 1})
                        </button>
                      )}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Desktop View */}
          <ul className="hidden md:block">
            {/* Pozisyonlar */}
            <li className="flex items-center space-x-4 md:mb-2 mb-4 ">
              <span className="w-[38%] md:w-[20%] text-[#4e4e4e]">
                Pozisyon
              </span>

              {/* Positions */}
              {positions && positions.length > 0 && (
                <>
                  <Link to={`/is-ilanlari?positions=[${positions[0].id}]`}>
                    <div className="text-[#2253af] block md:hidden">
                      : {positions[0]?.title}{" "}
                    </div>
                    <div className="text-[#2253af] hidden md:block">
                      : {positions[0]?.title}
                    </div>
                  </Link>
                  {positions?.length > 1 && (
                    <div
                      onClick={() => {
                        setIsPositionsModalOpen(true);
                      }}
                      className="text-[#2253af] !ml-1 text-xs cursor-pointer"
                    >
                      {`(+${positions?.length - 1})`}
                    </div>
                  )}
                </>
              )}
            </li>

            {/* Position Levels */}
            <li className="flex items-center space-x-4 md:mb-2 mb-4 ">
              <span className="w-[38%] md:w-[20%] text-[#4e4e4e]">
                Pozisyon Seviyesi
              </span>

              {positionLevels && positionLevels.length > 0 && (
                <>
                  <Link
                    to={`/is-ilanlari?position_levels=[${positionLevels[0]?.position_level_id}]`}
                  >
                    <div className="text-[#2253af] block md:hidden">
                      :{" "}
                      {positionLevels[0]?.name ||
                        positionLevels[0]?.position_level_name}{" "}
                    </div>
                    <div className="text-[#2253af] hidden md:block">
                      :{" "}
                      {positionLevels[0]?.name ||
                        positionLevels[0]?.position_level_name}
                    </div>
                  </Link>
                  {positionLevels?.length > 1 && (
                    <div
                      onClick={() => {
                        setIsPositionLevelsModalOpen(true);
                      }}
                      className="text-[#2253af] !ml-1 text-xs cursor-pointer"
                    >
                      {`(+${positionLevels?.length - 1})`}
                    </div>
                  )}
                </>
              )}
            </li>

            {/* Education levels */}
            <li className="flex items-center space-x-4 md:mb-2 mb-4 ">
              <span className="w-[38%] md:w-[20%] text-[#4e4e4e]">
                Eğitim Seviyesi
              </span>

              {educationLevels && educationLevels.length > 0 && (
                <>
                  <div className="text-[#2253af] block md:hidden">
                    : {educationLevels[0]?.education_level.title}{" "}
                  </div>
                  <div className="text-[#2253af] hidden md:block">
                    : {educationLevels[0]?.education_level.title}
                  </div>
                  {educationLevels?.length > 1 && (
                    <div
                      onClick={() => {
                        setIsEducationLevelsModalOpen(true);
                      }}
                      className="text-[#2253af] !ml-1 text-xs cursor-pointer"
                    >
                      {`(+${educationLevels?.length - 1})`}
                    </div>
                  )}
                </>
              )}
            </li>

            {/* Experience levels */}
            {experienceLevel?.experience_level_min?.value !== 0 &&
              experienceLevel && (
                <li className="flex items-center space-x-4 md:mb-2 mb-4">
                  <span className="w-[38%] md:w-[20%] text-[#4e4e4e]">
                    Deneyim Seviyesi
                  </span>
                  <div className="text-[#2253af]">
                    : {experienceLevel?.experience_level_min?.value}{" "}
                    <span>Yıl</span> - <span> </span>
                    {experienceLevel?.experience_level_max?.title}
                  </div>
                </li>
              )}

            {/* Sectors */}
            <li className="flex items-center space-x-4 md:mb-2 mb-4">
              <span className="w-[38%] md:w-[20%] text-[#4e4e4e]">Sektör</span>

              {sectors && sectors.length > 0 && (
                <>
                  <Link
                    className="text-[#2253af]"
                    to={`/is-ilanlari?sectors=[${sectors[0]?.id}]`}
                  >
                    <div className="text-[#2253af]">: {sectors[0]?.title}</div>
                  </Link>
                  {sectors?.length > 1 && (
                    <div
                      onClick={() => {
                        setIsSectorModalOpen(true);
                      }}
                      className="text-[#2253af] !ml-1 text-xs cursor-pointer"
                    >
                      {`(+${sectors?.length - 1})`}
                    </div>
                  )}
                </>
              )}
            </li>

            {/* Working locations */}
            {workingLocations && workingLocations?.length > 0 && (
              <li className="flex items-center space-x-4 md:mb-2 mb-4">
                <span className="w-[38%] md:w-[20%] text-[#4e4e4e]">
                  Çalışma Tercihi
                </span>

                <Link
                  className="text-[#2253af]"
                  rel="noopener noreferrer"
                  to={`/is-ilanlari?working_locations=[${workingLocations[0]?.id}]`}
                >
                  : {workingLocations[0]?.title || "Yok"}
                </Link>

                {workingLocations?.length > 1 && (
                  <div
                    onClick={() => {
                      setIsWorkingLocationsModalOpen(true);
                    }}
                    className="text-[#2253af] !ml-1 cursor-pointer"
                  >
                    {`(+${workingLocations?.length - 1})`}
                  </div>
                )}
              </li>
            )}

            {/* Working types */}
            <li className="flex items-center space-x-4 md:mb-2 mb-4">
              <span className="w-[38%] md:w-[20%] text-[#4e4e4e]">
                Çalışma Şekli
              </span>

              {workingTypes && (
                <>
                  <Link
                    to={`/is-ilanlari?working_types=[${workingTypes[0]?.id}]`}
                  >
                    <div className="text-[#2253af]">
                      : {workingTypes[0]?.title}
                    </div>
                  </Link>
                  {workingTypes.length > 1 && (
                    <div
                      onClick={() => {
                        setIsWorkingTypesModalOpen(true);
                      }}
                      className="text-[#2253af] !ml-1 cursor-pointer"
                    >
                      {`(+${workingTypes.length - 1})`}
                    </div>
                  )}
                </>
              )}
            </li>

            {/* Locations */}
            <li className="flex items-center space-x-4 md:mb-2 mb-4">
              <span className="w-[38%] md:w-[20%] text-[#4e4e4e] flex-shrink-0">
                {isEnglish ? "Workplace" : "Çalışma Yeri"}
              </span>
              {locations && locations[0] && (
                <div className="flex flex-row items-center">
                  <Link
                    to={`/is-ilanlari?${
                      locations[0].city?.id
                        ? `cities=[${locations[0].city?.id}]`
                        : ``
                    }${
                      locations[0].district?.id
                        ? `&districts=[${locations[0].district?.id}]`
                        : ``
                    }`}
                  >
                    <div className="text-[#2253af]">
                      : {locations[0].city?.name} -{" "}
                      {locations[0].district
                        ? `${
                            locations[0].district?.name == "Tüm İlçeler" &&
                            isEnglish
                              ? `All Districts`
                              : `${locations[0].district?.name}`
                          }`
                        : `${isEnglish ? "All Districts" : "Tüm İlçeler"}`}
                    </div>
                  </Link>
                  {locations && locations.length > 1 && (
                    <button
                      type="button"
                      onClick={() => {
                        setIsLocationsModalOpen(true);
                      }}
                      className="text-[#2253af] !ml-2 font-semibold text-xs cursor-pointer"
                    >
                      (+{locations.length - 1})
                    </button>
                  )}
                </div>
              )}
            </li>

            {/* Sections */}
            {sections && sections?.length > 0 && (
              <li key={0} className="flex items-center space-x-4 md:mb-2 mb-4">
                <span className="w-[38%] md:w-[20%] text-[#4e4e4e]">Bölüm</span>
                <Link to={`/is-ilanlari?sections=[${sections[0]?.id}]`}>
                  <div className="text-[#2253af]">: {sections[0]?.title}</div>
                </Link>
                {sections?.length > 1 && (
                  <div
                    onClick={() => {
                      setIsSectionModalOpen(true);
                    }}
                    className="text-[#2253af] !ml-1 cursor-pointer"
                  >
                    {`(+${sections?.length - 1})`}
                  </div>
                )}
              </li>
            )}

            {/* Job benefits */}
            {jobBenefits && jobBenefits.length > 0 && (
              <li className="flex items-center space-x-4 md:mb-2 mb-4">
                <span className="w-[38%] md:w-[20%] text-[#4e4e4e]">
                  Yan Haklar
                </span>
                <label>
                  <div className="text-[#2253af]">: {jobBenefits[0]?.name}</div>
                </label>
                {jobBenefits?.length > 1 && (
                  <div
                    onClick={() => {
                      setIsJobBenefitsModalOpen(true);
                    }}
                    className="text-[#2253af] !ml-1 cursor-pointer"
                  >
                    {`(+${jobBenefits?.length - 1})`}
                  </div>
                )}
              </li>
            )}

            {/* Driving Licences */}
            <li className="flex items-center space-x-4 md:mb-2 mb-4">
              {drivingLicenceClasses && drivingLicenceClasses.length > 0 && (
                <>

                <span className="w-[38%] md:w-[20%] text-[#4e4e4e]">
                   Sürücü Belgeleri
                 </span>
                  <Link
                    className="text-[#2253af]"
                    to={`/is-ilanlari?sectors=[${drivingLicenceClasses[0]?.driving_licence_class.id}]`}
                  >
                    <div className="text-[#2253af]">
                      : {drivingLicenceClasses[0]?.driving_licence_class.name}
                    </div>
                  </Link>

                  {drivingLicenceClasses?.length > 1 && (
                    <div
                      onClick={() => {
                        setIsClassesModalOpen(true);
                      }}
                      className="text-[#2253af] !ml-1 text-xs cursor-pointer"
                    >
                      {`(+${drivingLicenceClasses?.length - 1})`}
                    </div>
                  )}
                </>
              )}
            </li>

            {/* SRC certificates */}
            <li className="flex items-center space-x-4 md:mb-2 mb-4">
      

              {drivingLicenceSrcs && drivingLicenceSrcs.length > 0 && (
                <>

                  <span className="w-[38%] md:w-[20%] text-[#4e4e4e]">
                    SRC Sertifikaları
                  </span>
                  <Link
                    className="text-[#2253af]"
                    to={`/is-ilanlari?sectors=[${drivingLicenceSrcs[0]?.driving_licence_src.id}]`}
                  >
                    <div className="text-[#2253af]">
                      : {drivingLicenceSrcs[0]?.driving_licence_src.name}
                    </div>
                  </Link>

                  {drivingLicenceSrcs?.length > 1 && (
                    <div
                      onClick={() => {
                        setIsSrcsModalOpen(true);
                      }}
                      className="text-[#2253af] !ml-1 text-xs cursor-pointer"
                    >
                      {`(+${drivingLicenceSrcs?.length - 1})`}
                    </div>
                  )}
                </>
              )}
            </li>

            {languages && languages.length > 0 && (
              <li className="flex items-center space-x-4 md:mb-2 mb-4">
                <span className="w-[38%] md:w-[20%] text-[#4e4e4e]">
                  Yabancı Dil
                </span>

                {languages && languages.length > 0 && (
                  <>
                    <div className="text-[#2253af]">: {languages[0]?.name}</div>
                    {languages?.length > 1 && (
                      <div
                        onClick={() => {
                          setIsLanguagesModalOpen(true);
                        }}
                        className="text-[#2253af] !ml-1 text-xs cursor-pointer"
                      >
                        {`(+${languages?.length - 1})`}
                      </div>
                    )}
                  </>
                )}
              </li>
            )}

            {/* Military statuses */}
            {/* {militaryStatuses && (
              <>
                {militaryStatuses?.length > 0 && (
                  <li className="flex items-center space-x-4 md:mb-2 mb-4">
                    <span className="w-[38%] md:w-[20%] text-[#4e4e4e]">
                      Askerlik Durumu
                    </span>

                    {militaryStatuses
                      .slice(0, 1)
                      .map((militaryStatus, index) => (
                        <Link
                          key={index}
                          className="text-[#2253af]"
                          rel="noopener noreferrer"
                          to={`/is-ilanlari`}
                        >
                          : {militaryStatus?.name}
                        </Link>
                      ))}
                  </li>
                )}
              </>
            )} */}

            {/* {minAge && maxAge && (
              <li className="flex items-center space-x-4 md:mb-2 mb-4">
                <span className="w-[38%] md:w-[20%] text-[#4e4e4e]">
                  Yaş Aralığı
                </span>
                <div className="text-[#2253af]">
                  : {minAge} - {maxAge}
                </div>
              </li>
            )} */}

            {/* {companyDetail?.employee_count && (
              <li className="flex items-center space-x-4 md:mb-2 mb-4">
                <span className="w-[38%] md:w-[20%] text-[#4e4e4e]">
                  Çalışan Sayısı
                </span>
                <div className="text-[#2253af]">
                  : {companyDetail?.employee_count}
                </div>
              </li>
            )} */}
          </ul>
        </div>

        {/* Pozisyon */}
        <Modal
          title={isEnglish ? "POSITION" : "POZİSYON"}
          open={isPositionsModalOpen}
          footer={null}
          onOk={() => setIsPositionsModalOpen(false)}
          onCancel={() => setIsPositionsModalOpen(false)}
        >
          {positions &&
            positions.map((position, index) => (
              <div key={index} className="flex items-center mb-1">
                <div className=" text-gray-700 text-sm capitalize">
                  {isEnglish ? position?.title_en : position?.title}
                </div>
              </div>
            ))}
        </Modal>

        {/* Pozisyon seviyesi */}
        <Modal
          title={isEnglish ? "POSITION LEVEL" : "POZİSYON SEVİYESİ"}
          open={isPositionLevelsModalOpen}
          onOk={() => setIsPositionLevelsModalOpen(false)}
          onCancel={() => setIsPositionLevelsModalOpen(false)}
          footer={null}
        >
          {positionLevels &&
            positionLevels.map((positionLevel) => {
              return (
                <div
                  key={`positionLevels${positionLevel.position_level?.id}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {isEnglish
                      ? positionLevel.position_level_name_en
                      : positionLevel.position_level_name}
                  </div>
                </div>
              );
            })}
        </Modal>

        {/* Eğitim seviyesi */}
        <Modal
          title={isEnglish ? "EDUCATION LEVEL" : "EĞİTİM SEVİYESİ"}
          open={isEducationLevelsModalOpen}
          footer={null}
          onOk={() => setIsEducationLevelsModalOpen(false)}
          onCancel={() => setIsEducationLevelsModalOpen(false)}
        >
          {educationLevels &&
            educationLevels.map((educationLevel, index) => (
              <div key={index} className="flex items-center mb-1">
                <div className=" text-gray-700 text-sm capitalize">
                  {isEnglish
                    ? educationLevel?.education_level?.title_en
                    : educationLevel?.education_level?.title}
                </div>
              </div>
            ))}
        </Modal>

        {/* Sektör */}
        <Modal
          title={isEnglish ? "SECTOR" : "SEKTÖR"}
          open={isSectorModalOpen}
          onOk={() => setIsSectorModalOpen(false)}
          onCancel={() => setIsSectorModalOpen(false)}
          footer={null}
        >
          {sectors &&
            sectors.map((sector) => {
              return (
                <div
                  key={`sectorDiv${sector?.id}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {isEnglish ? sector?.title_en : sector?.title}
                  </div>
                </div>
              );
            })}
        </Modal>

        {/* Bölüm */}
        <Modal
          title={isEnglish ? "SECTION" : "BÖLÜM"}
          open={isSectionModalOpen}
          onOk={() => setIsSectionModalOpen(false)}
          onCancel={() => setIsSectionModalOpen(false)}
          footer={null}
        >
          {sections &&
            sections.map((section) => {
              return (
                <div
                  key={`sectionDiv${section.section?.id}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {isEnglish ? section?.title_en : section?.title}
                  </div>
                </div>
              );
            })}
        </Modal>

        {/* Çalışma şekli */}
        <Modal
          title={isEnglish ? "WORKING TYPE" : "ÇALIŞMA ŞEKLİ"}
          open={isWorkingTypesModalOpen}
          onOk={() => setIsWorkingTypesModalOpen(false)}
          onCancel={() => setIsWorkingTypesModalOpen(false)}
          footer={null}
        >
          {workingTypes &&
            workingTypes.map((workingType) => {
              return (
                <div
                  key={`positionLevelsDiv${workingType?.id}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {isEnglish ? workingType?.title : workingType?.title}
                  </div>
                </div>
              );
            })}
        </Modal>

        {/* Çalışma tercihi */}
        <Modal
          title={isEnglish ? "WORK PREFERENCE" : "ÇALIŞMA TERCİHİ"}
          open={isWorkingLocationsModalOpen}
          onOk={() => setIsWorkingLocationsModalOpen(false)}
          onCancel={() => setIsWorkingLocationsModalOpen(false)}
          footer={null}
        >
          {workingLocations &&
            workingLocations.map((workingLocation) => {
              return (
                <div
                  key={`workingLocationDiv${workingLocation?.id}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {isEnglish
                      ? workingLocation?.title_en
                      : workingLocation?.title}
                  </div>
                </div>
              );
            })}
        </Modal>

        {/* Yabancı dil */}
        <Modal
          title={isEnglish ? "FOREIGN LANGUAGES" : "YABANCI DİL"}
          open={isLanguagesModalOpen}
          onOk={() => setIsLanguagesModalOpen(false)}
          onCancel={() => setIsLanguagesModalOpen(false)}
          footer={null}
        >
          {languages &&
            languages.map((language) => {
              return (
                <div
                  key={`positionLevelsDiv${language?.id}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {isEnglish ? language?.name_en : language?.name}
                  </div>
                </div>
              );
            })}
        </Modal>

        {/* Çalışma yeri */}
        <Modal
          title={isEnglish ? "WORKPLACE" : "ÇALIŞMA YERİ"}
          open={isLocationsModalOpen}
          onOk={() => setIsLocationsModalOpen(false)}
          onCancel={() => setIsLocationsModalOpen(false)}
          footer={null}
        >
          {locations &&
            locations.map((location, i) => {
              return (
                <div
                  key={`positionLevelsDiv${location?.id}-${i}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {location.city?.name} -{" "}
                    {location.district
                      ? `${
                          isEnglish && location.district?.name == "Tüm İlçeler"
                            ? `All Districts`
                            : `${location.district?.name}`
                        }`
                      : `${isEnglish ? "All Districts" : "Tüm İlçeler"}`}
                  </div>
                </div>
              );
            })}
        </Modal>

        {/* Yan haklar */}
        <Modal
          title={isEnglish ? "JOB BENEFITS" : "YAN HAKLAR"}
          open={isJobBenefitsModalOpen}
          onOk={() => setIsJobBenefitsModalOpen(false)}
          onCancel={() => setIsJobBenefitsModalOpen(false)}
          footer={null}
        >
          {jobBenefits &&
            jobBenefits.map((jobBenefit) => {
              return (
                <div
                  key={`positionLevelsDiv${jobBenefit?.id}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {isEnglish ? jobBenefit?.name_en : jobBenefit?.name}
                  </div>
                </div>
              );
            })}
        </Modal>

        {/* Src belgeleri */}
        <Modal
          title={isEnglish ? "SRC CERTIFICATES" : "SRC BELGELERİ"}
          open={isSrcsModalOpen}
          onOk={() => setIsSrcsModalOpen(false)}
          onCancel={() => setIsSrcsModalOpen(false)}
          footer={null}
        >
          {drivingLicenceSrcs &&
            drivingLicenceSrcs.map((src) => {
              return (
                <div
                  key={`positionLevelsDiv${src.driving_licence_src?.id}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {src.driving_licence_src?.name}
                  </div>
                </div>
              );
            })}
        </Modal>

        {/* Sürücü belgeleri */}
        <Modal
          title={isEnglish ? "DRIVER'S LICENSES" : "SÜRÜCÜ LİSANSLARI"}
          open={isClassesModalOpen}
          onOk={() => setIsClassesModalOpen(false)}
          onCancel={() => setIsClassesModalOpen(false)}
          footer={null}
        >
          {drivingLicenceClasses &&
            drivingLicenceClasses.map((src) => {
              return (
                <div
                  key={`positionLevelsDiv${src.driving_licence_class?.id}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {src.driving_licence_class?.name}
                  </div>
                </div>
              );
            })}
        </Modal>

        {/* <Modal
          title="ÇALIŞMA ŞEKLİ"
          open={isModalOpen}
          footer={null}
          onOk={handleOk}
          onCancel={handleCancel}
          className="top-56"
        >
          {workingTypes &&
            workingTypes?.map((workingType, index) => (
              <div key={index} className="flex items-center mb-1">
                <div className=" text-gray-700 text-sm capitalize">
                  {workingType?.title}
                </div>
              </div>
            ))}
        </Modal>

        <Modal
          title="Sektör"
          open={isModalOpen2}
          footer={null}
          onOk={handleOk2}
          onCancel={handleCancel2}
          className="top-56"
        >
          {sectors &&
            sectors?.map((sector, index) => (
              <div key={index} className="flex items-center mb-1">
                <div className=" text-gray-700 text-sm capitalize">
                  {sector?.title}
                </div>
              </div>
            ))}
        </Modal>

        <Modal
          title="Pozisyon"
          open={isModalOpen3}
          footer={null}
          onOk={handleOk3}
          onCancel={handleCancel3}
          className="top-56"
        >
          {positions &&
            positions?.map((position, index) => (
              <div key={index} className="flex items-center mb-1">
                <div className=" text-gray-700 text-sm capitalize">
                  {position?.title}
                </div>
              </div>
            ))}
        </Modal>

        <Modal
          title="Çalışma ŞEKLİ"
          open={isModalOpen4}
          footer={null}
          onOk={handleOk4}
          onCancel={handleCancel4}
          className="top-56"
        >
          {workingLocations &&
            workingLocations.map((workingLocation, index) => (
              <div key={index} className="flex items-center mb-1">
                <div className=" text-gray-700 text-sm capitalize">
                  {workingLocation.title}
                </div>
              </div>
            ))}
        </Modal>

        <Modal
          title="BÖLÜM"
          open={isModalOpen5}
          footer={null}
          onOk={handleOk5}
          onCancel={handleCancel5}
          className="top-56"
        >
          {sections &&
            sections?.map((section, index) => (
              <div key={index} className="flex items-center mb-1">
                <div className=" text-gray-700 text-sm capitalize">
                  {section?.title}
                </div>
              </div>
            ))}
        </Modal> */}

        {/* <div className="pl-3">
          <h2 className="text-base font-bold mt-8 mb-4">İletişim Bilgileri</h2>
          <ul>
            <li className="flex items-center justify-start mb-2 space-x-3">
              <CiUser size={20} className="text-gray-600" />
              <span className="text-[#4e4e4e]">{companyFullName}</span>
            </li>

            {userToken ? (
              <li className="flex items-center justify-start mb-2 gap-3">
                <FaPhoneAlt size={19} className="text-gray-600" />
                <a
                  href={`tel:${formattedCompanyPhone}`}
                  className="text-[#4e4e4e]"
                >
                  {formattedCompanyPhone}
                </a>
              </li>
            ) : (
              <li className="flex items-center justify-start mb-2 gap-2">
                <FaPhoneAlt size={19} className="text-gray-600 w-[22px]" />
                <span className="text-[#4e4e4e]">
                  {extractPhoneNumberPrefix(companyPhone)}
                </span>
              </li>
            )}

            {formattedLandPhone && (
              <>
                {userToken ? (
                  <>
                    {land_phone?.trim() !== "" && (
                      <li className="flex items-center justify-start mb-2 gap-2">
                        <LiaFaxSolid size={24} className="text-gray-600" />
                        <a
                          href={`tel:${formattedLandPhone}`}
                          className="text-[#4e4e4e]"
                        >
                          {formatLandPhoneNumber(formattedLandPhone)}
                        </a>
                      </li>
                    )}
                  </>
                ) : (
                  <li className="flex items-center justify-start mb-2 gap-2">
                    <LiaFaxSolid size={24} className="text-gray-600" />
                    <span className="text-[#4e4e4e]">
                      {extractLandPhoneNumberPrefix(formattedLandPhone)}{" "}
                    </span>
                  </li>
                )}
              </>
            )}
          </ul>
        </div> */}

        {/* Contact Info */}
        <div className="pl-3">
          <h2 className="text-base font-bold mt-8 mb-4">İletişim Bilgileri</h2>
          {hide_contact_info == "show_all" || !hide_contact_info ? (
            <ul>
              {!hide_employee_info && (
                <li className="flex items-center mb-2 space-x-4">
                  <IoPersonOutline className="text-gray-600 text-xl" />
                  <span className="text-[#4e4e4e]">{employee_name}</span>
                </li>
              )}
              {userToken ? (
                <>
                  <li className="flex items-center mb-2 space-x-4">
                    <LiaFaxSolid className="text-gray-600 text-xl" />
                    <a
                      href={`tel:${formattedLandPhone}`}
                      className="text-[#4e4e4e]"
                    >
                      {formattedLandPhone}
                    </a>
                  </li>
                  <li className="flex items-center mb-2 space-x-4">
                    <BsTelephone className="text-gray-600 text-xl" />
                    <a
                      href={`tel:${formattedCompanyPhone}`}
                      className="text-[#4e4e4e]"
                    >
                      {formattedCompanyPhone}
                    </a>
                  </li>
                </>
              ) : (
                <>
                  <li className="flex items-center mb-2 space-x-4">
                    <LiaFaxSolid className="text-gray-600 text-xl" />
                    <span className="text-[#4e4e4e]">
                      {formattedLandPhone.substring(0, 6)} *** ** **
                    </span>
                  </li>
                  <li className="flex items-center mb-2 space-x-4">
                    <BsTelephone className="text-gray-600 text-xl" />
                    <span className="text-[#4e4e4e]">
                      {formattedCompanyPhone.substring(0, 6)} *** ** **
                    </span>
                  </li>
                </>
              )}
            </ul>
          ) : hide_contact_info == "show_phone" ? (
            <ul>
              {!hide_company_info && (
                <li className="flex items-center mb-2 space-x-4">
                  <CiUser className="text-gray-600 text-xl" />
                  <span className="text-[#4e4e4e]">{employee_name}</span>
                </li>
              )}
              {userToken ? (
                <li className="flex items-center mb-2 space-x-4">
                  <LiaFaxSolid className="text-gray-600 text-xl" />
                  <span className="text-[#4e4e4e]">{formattedLandPhone}</span>
                </li>
              ) : (
                <li className="flex items-center mb-2 space-x-4">
                  <LiaFaxSolid className="text-gray-600 text-xl" />
                  <span className="text-[#4e4e4e]">
                    {formattedLandPhone.substring(0, 6)} *** ** **
                  </span>
                </li>
              )}
            </ul>
          ) : hide_contact_info == "show_gsm" ? (
            <ul>
              {!hide_company_info && (
                <li className="flex items-center mb-2 space-x-4">
                  <CiUser className="text-gray-600 text-xl" />
                  <span className="text-[#4e4e4e]">{employee_name}</span>
                </li>
              )}
              {userToken ? (
                <li className="flex items-center mb-2 space-x-4">
                  <FaPhoneAlt className="text-gray-600 text-xl" />
                  <span className="text-[#4e4e4e]">
                    {formattedCompanyPhone}
                  </span>
                </li>
              ) : (
                <li className="flex items-center mb-2 space-x-4">
                  <FaPhoneAlt className="text-gray-600 text-xl" />
                  <span className="text-[#4e4e4e]">
                    {formattedCompanyPhone.substring(0, 6)} *** ** **
                  </span>
                </li>
              )}
            </ul>
          ) : hide_contact_info == "hide_all" ? (
            <ul>
              {!hide_employee_info && (
                <li className="flex items-center mb-2 space-x-4">
                  <CiUser className="text-gray-600 text-xl" />
                  <span className="text-[#4e4e4e]">{employee_name}</span>
                </li>
              )}
            </ul>
          ) : null}
        </div>
      </div>

      {userToken ? (
        <div className="flex justify-center items-center py-3 text-sm gap-2 lg:hidden">
          <MdOutlineElectricBolt />
          <a onClick={() => handleUpdateCv()} href="#">
            Özgeçmiş Tarihini Güncelle
          </a>
        </div>
      ) : (
        <div
          onClick={() => {
            navigate("/profilim/giris-yap");
          }}
          className="flex justify-center items-center py-3 text-sm gap-2 lg:hidden"
        >
          <RiMarkPenLine />
          <a href="#">Hızlı Özgeçmiş Oluştur</a>
        </div>
      )}

      {positionDictionary && (
        <div className="p-2 rounded w-full lg:hidden">
          <hr />
          <p className="mt-4 font-poppins poppins-regular text-xs">
            {positionDictionary.title} pozisyonu hakkında daha detaylı bilgi
            edinmek için aşağıdaki bağlantılara göz atabilirsiniz.
          </p>
          <ul className="mt-4 font-medium">
            <li className="flex font-poppins poppins-medium text-xs items-center text-[#2253af] mb-2">
              <Link to={`/pozisyon/${positionDictionary.id}`}>
                {positionDictionary.title}
              </Link>{" "}
            </li>
            <li className="flex font-poppins poppins-medium text-xs  items-center text-[#2253af] mb-2">
              <Link to={`/pozisyon/${positionDictionary.id}`}>
                {positionDictionary.title} Maaşları
              </Link>{" "}
            </li>
            <li className="flex font-poppins poppins-medium text-xs  items-center text-[#2253af] mb-2">
              <Link to={`/pozisyon/${positionDictionary.id}`}>
                {positionDictionary.title} Nedir?
              </Link>{" "}
            </li>
            <li className="flex font-poppins poppins-medium text-xs  items-center text-[#2253af] mb-2">
              <Link to={`/is-ilanlari?positions=[${positionDictionary.id}]`}>
                {positionDictionary.title} İş İlanları?
              </Link>{" "}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
export default MainInfo;
