import * as React from "react";
import { useEffect } from "react"; 
import { HeaderCard } from "../creat-ads-page/header-card";
import { StepsPointsCard } from './steps-points-card/index'
import SimpleHeader from "./simple-header/index"
import Body from "./body"
function DopingAdd(): React.ReactElement {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mx-auto flex flex-col ">
      {/* <div className="container mx-auto">
        <HeaderCard />
      </div> */}
      <div className="container mx-auto">
        <StepsPointsCard/>
      </div>
      <Body/>
    </div>
  );
}
export { DopingAdd };
