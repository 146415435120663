import { useEffect, useState, useRef, useContext } from "react";
import { cdnCityType, getCitiesResponseType } from "../../../../../../types";
import { FaChevronDown } from "react-icons/fa6";
import Axios from "../../../../../../services/axios";
import { workingLocationContextType, WorkingLocationsContext } from ".";
import { locationRowContextType, LocationRowContext } from "./LocationRow";
import Swal from "sweetalert2";
import { getCities } from "../../../../../../customHooks/getCities";

const axios = new Axios();

export default function CitySelect() {
  const {
    cities,
    isCityError,
    isDistrictError,
    workingLocations,
  }: workingLocationContextType = useContext(WorkingLocationsContext);

  const { selectedCity, setSelectedCity }: locationRowContextType =
    useContext(LocationRowContext);

  const divRef = useRef<HTMLDivElement>(null);

  const [filteredCities, setFilteredCities] = useState<cdnCityType[]>(cities);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  async function searchByTerm() {
    if (searchTerm.trim().length > 0) {
      try {
        const response: getCitiesResponseType = await axios.request({
          endpoint: `cdn/get-cities?search=${searchTerm}&limit=300`,
          type: "get",
        });

        setFilteredCities(response.data.cities);
      } catch (error) {
        console.log(`Cities fetch failed. Error:${error}`);
        setFilteredCities([]);
      }
    } else {
      setFilteredCities(cities);
    }
  }

  useEffect(() => {
    const timeOut = setTimeout(() => searchByTerm(), 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchTerm]);

  useEffect(() => {
    setFilteredCities(cities);
  }, [cities]);

  //console.log('city Error', isCityError)
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        if (selectedCity) {
          setSearchTerm(selectedCity.name);
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [divRef]);
  return (
    <div ref={divRef} className={`flex relative w-full`}>
      <div
        className={`border ${
          selectedCity ? "border-blue-500" : "border-gray-400"
        } ${
          isCityError && !selectedCity ? "bg-red-50 border-red-600" : "bg-white"
        } w-full ${
          isOpen ? "shadow-form-glow" : "shadow-none"
        } rounded-md p-2 `}
        onClick={async () => {
          if (filteredCities.length == 0 && searchTerm.length == 0) {
            const res = await getCities({});
            setFilteredCities(res);
          }
          setIsOpen(true);
        }}
      >
        {isOpen ? (
          <input
            className={`outline-none bg-transparent w-full font-poppins text-[13px]`}
            placeholder="Şehir Seçiniz"
            value={searchTerm || ""}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        ) : (
          <label
            className={`font-poppins text-[13px] ${
              selectedCity ? "text-blue-500" : "text-gray-500"
            } ${isCityError && !selectedCity ? "text-red-600" : ""}`}
          >
            {selectedCity ? `${selectedCity.name}` : `Şehir Seçiniz`}
          </label>
        )}

        <div className={`absolute right-3 top-4`}>
          <FaChevronDown
            className={`${selectedCity ? "text-blue-500" : "text-gray-600"} ${
              isOpen ? "rotate-180" : "rotate-0"
            } text-xs transition-all`}
          />
        </div>
      </div>
      {isOpen && (
        <div className="absolute bottom-0 left-0 translate-y-full w-full bg-white border border-[#9ca3af] rounded-md flex flex-col gap-2 p-2 shadow max-h-[200px] overflow-auto z-[1]">
          {filteredCities.map((city) => {
            return (
              <button
                key={`citySelect${city.id}`}
                type="button"
                className="hover:bg-gray-100 transition-all text-start p-2"
                onClick={() => {
                  if (city.id == 7795) {
                    if (
                      workingLocations.find((w) => w.cityId == city.id) !=
                      undefined
                    ) {
                      Swal.fire({
                        icon: "error",
                        title: "Uyarı",
                        text: "Türkiye Geneli seçeneğini 2 kez seçemezsiniz.",
                      });

                      return;
                    }
                  }
                  setSelectedCity(city);
                  setIsOpen(false);
                }}
              >
                {city.name}
              </button>
            );
          })}
          {filteredCities.length == 0 && (
            <div className="text-center text-gray-500">Sonuç Bulunamadı</div>
          )}
        </div>
      )}
    </div>
  );
}
