import React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { UserPrivateRoutes } from "./middleware/user-middleware";
import { Home } from "./pages/home-page";
import { SiteMapPage } from "./pages/sitemap-page";
import { SectionsPage } from "./pages/sections-page/index";
import { ProvincesPage } from "./pages/provinces-page/intex";
import { ProfileL, ProfileR, CompanyL, CompanyR } from "./pages/login-page";
import { PositionsPage } from "./pages/positions-page";
import { CompanyPage } from "./pages/company-page";
import { CompanyPrivateRoutes } from "./middleware/company-middleware";
import { CorporatePage } from "./pages/corporate-page";
import { ContactPage } from "./pages/contact-page";
import { CreatAdd } from "./containers/company-page-container/creat-ads-page/index";
import { FirstShowAd } from "./containers/company-page-container/first-show-ad/index";
import { SuccesedCreaetAdPage } from "./containers/company-page-container/succesd-create-ad-page/index";
import { JobPage } from "./pages/job-page";
import SettingPage from "./containers/company-page-container/setting-page";
import VerifyPhonePage from "./containers/general-pages-container/verify-phone-page";
import VerifyEmailPage from "./containers/general-pages-container/verify-email-page";
import SavedSearches from "./containers/company-page-container/saved-search-page";
import FoldersPage from "./containers/company-page-container/folders-page";
import { EditAd } from "./containers/company-page-container/edit-ad-page/index";
import CompanyDetails from "./containers/company-global-page-container/index";
import { Layout } from "./pages/layout-page";
import { CandidatePoolPage } from "./containers/company-page-container/candidate-pool-page";
import Dashboard from "./containers/profile-page-container";
import Settings from "./containers/profile-page-container/settings";
import FolderShowPage from "./containers/company-page-container/folders-page/folder-show-page";
import { AdvertAppliesPage } from "./containers/company-page-container/advert-applies-page";
import Detials from "./containers/job-page-container/detials/index";
import ReferencesPage from "./containers/company-page-container/reference-invitations-page";
import GalleryPage from "./containers/company-page-container/gallery-page";
import DashboardLayout from "./containers/profile-page-container/layouts";
import CvDetail from "./containers/profile-page-container/cv-detail";
import CvDetailCompany from "./containers/company-page-container/cv-detail";
import HomePage from "./containers/profile-page-container/dashboard";
import CvEdit from "./containers/profile-page-container/cv-edit";
import Files from "./containers/profile-page-container/files";
import Application from "./containers/profile-page-container/application";
import Favorite from "./containers/profile-page-container/favorite";
import Follow from "./containers/profile-page-container/favorite/followed-companies";
import SavedFilters from "./containers/profile-page-container/favorite/saved-filters";
import LastViewedAds from "./containers/profile-page-container/favorite/last-viewed-ads";
import Notification from "./containers/profile-page-container/notification";
import WorkAlarm from "./containers/profile-page-container/work-alarm";
import Message from "./containers/profile-page-container/message";
import Interview from "./containers/profile-page-container/interview-list";
import CvDefault from "./containers/profile-page-container/cv";

import "./components/common/Antd/_antd.scss";
import { ProductsPage } from "./pages/products-page";
import FreeAdvertPage from "./pages/free-advert-page";
import { DopingAdd } from "./containers/company-page-container/doping-ads-page";
import ForgotPasswordEmail from "./components/common/login/login-content/right-side/forgot-password-email";
import ForgotPasswordSMS from "./components/common/login/login-content/right-side/forgot-password-phone";
import TestContainer from "./components/mert-ui/TestContainer";
import InterviewsPageContainer from "./containers/company-page-container/interviews-page";
import CvSablonPageContainer from "./containers/cv-sablon-page-container";
import Messageİnner from "./containers/profile-page-container/message-inner";
import AddOnYazı from "./containers/profile-page-container/addOnYazı";
import CvViewer from "./containers/profile-page-container/cv-viewer";
import PacketPageContainer from "./containers/company-page-container/packet-page";
import BuyPacketPageContainer from "./containers/company-page-container/buy-packet";
import ReferanceCreatePage from "./containers/company-page-container/referance-create-page/index";
import CompanyNotification from "./containers/company-page-container/notification/contents";
import { HelpPage } from "./pages/help-page";
import VerifyPage from "./pages/verify/VerifyPage";
import ForgotPasswordPage from "./pages/forgot-password-page";
import PersonalInformation from "./containers/profile-page-container/welcome/contents/personel-information";
import WelcomePage from "./pages/profile-page/welcome-page";
import WelcomePersonalInformation from "./pages/profile-page/welcome-page/WelcomePersonalInformation";
import JobPreferencesInformation from "./pages/profile-page/welcome-page/JobPereferencesInformation";
import CvShowPage from "./pages/cv-show-page";
import ForgotPasswordCompany from "./pages/forgot-password-page/company-page";
import ForgotPasswordUser from "./pages/forgot-password-page/user-page";
import BlogPage from "./containers/blog-page-container";

const router = createBrowserRouter([
  {
    path: "",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/kariyer-rehberi/*",
        element: <BlogPage />,
      },
      //env("FRONT_URL") . "/email/verify?token=$token"
      {
        path: "/email/verify",
        element: <VerifyPage />,
      },
      {
        path: "/is-ilanlari/*",
        element: <JobPage />,
      },
      /* TODO: Component testleri için eklendi silinecek */
      {
        path: "/test",
        element: <TestContainer />,
      },
      {
        path: "/mert-test",
        element: <TestContainer />,
      },
      {
        path: "/is-ilanlari/:slug",
        element: <Detials />,
      },
      {
        path: "/ozgecmis-goruntule/:slug",
        element: <CvShowPage />,
      },
      {
        path: "/cv-sablonlari",
        element: <CvSablonPageContainer />,
      },
      {
        path: "/firma/*",
        element: <CompanyDetails />,
      },
      {
        path: "/iller",
        element: <ProvincesPage />,
      },
      {
        path: "/site-haritasi",
        element: <SiteMapPage />,
      },

      {
        path: "/bolumler/*",
        element: <SectionsPage />,
      },
      {
        path: "/iletisim/*",
        element: <ContactPage />,
      },
      {
        path: "/pozisyon/*",
        element: <PositionsPage />,
      },
      {
        path: "/pozisyonlar/*",
        element: <PositionsPage />,
      },
      {
        path: "/kurumsal/*",
        element: <CorporatePage />,
      },
      {
        path: "/yardim",
        element: <HelpPage />,
      },
      {
        path: "/ucretsiz-ilan-ver",
        element: <FreeAdvertPage />,
      },
      {
        path: "/sifremi-unuttum",
        element: <ForgotPasswordPage />,
      },
      {
        path: "/sifremi-unuttum-email",
        element: <ForgotPasswordEmail />,
      },
      {
        path: "/sifremi-unuttum-sms",
        element: <ForgotPasswordSMS />,
      },
      {
        path: "/sifremi-unuttum/isveren",
        element: <ForgotPasswordCompany />,
      },
      {
        path: "/sifremi-unuttum/is-arayan",
        element: <ForgotPasswordUser />,
      },
      {
        path: "/isveren",
        children: [
          {
            path: "giris-yap",
            element: <CompanyL />,
          },
          {
            path: "kayit-ol",
            element: <CompanyR />,
          },
          {
            path: "*",
            element: <CompanyPrivateRoutes />,
            children: [
              {
                path: "",
                element: <CompanyPage />,
              },
              {
                path: "aday-havuzu",
                element: <CandidatePoolPage />,
              },
              {
                path: "cv/:slug",
                element: <CvDetailCompany />,
              },
              { path: "mesajlar", element: <Message /> },
              { path: "mesajlar/:id", element: <Messageİnner /> },
              {
                path: "referanslar",
                element: <ReferencesPage />,
              },
              {
                path: "referanslar/olustur",
                element: <ReferanceCreatePage />,
              },
              {
                path: "dosyalar",
                element: <GalleryPage />,
              },
              {
                path: "ilanlar/olustur/ilan-bilgileri",
                element: <CreatAdd />,
              },
              {
                path: "ilanlar/ilan/:advertId/guncelle",
                element: <EditAd />,
              },
              {
                path: "ilanlar/ilan/:advertId/onizleme",
                element: <FirstShowAd />,
              },
              {
                path: "ilanlar/ilan/:advertId/doping",
                element: <DopingAdd />,
              },
              {
                path: "ilanlar/ilan/:advertId/basarili",
                element: <SuccesedCreaetAdPage />,
              },
              {
                path: "ayarlar",
                element: <SettingPage />,
              },
              { path: "bildirimler", element: <CompanyNotification /> },
              {
                path: "klasorler",
                element: <FoldersPage />,
              },
              {
                path: "klasorler/:folderId/cv-listesi",
                element: <FolderShowPage />,
              },
              {
                path: "kayitli-aramalar",
                element: <SavedSearches />,
              },
              {
                path: "basvurular/:id",
                element: <AdvertAppliesPage />,
              },
              {
                path: "ürünler",
                element: <ProductsPage />,
              },
              {
                path: "mulakat-listesi",
                element: <InterviewsPageContainer />,
              },
              {
                path: "paket",
                element: <PacketPageContainer />,
              },
              {
                path: "paket/:slug/satin-al",
                element: <BuyPacketPageContainer />,
              },
            ],
          },
        ],
      },

      {
        path: "/profilim",
        children: [
          {
            path: "giris-yap",
            element: <ProfileL />,
          },
          {
            path: "kayit-ol",
            element: <ProfileR />,
          },
          {
            path: "*",
            element: <UserPrivateRoutes />,
            children: [
              {
                path: "",
                element: <DashboardLayout />,
                children: [
                  {
                    path: "",
                    element: <HomePage />,
                  },
                  {
                    path: "cv",
                    element: <CvDefault />,
                  },
                  {
                    path: "cv/goruntule/:slug",
                    element: <CvDetail />,
                  },
                  {
                    path: "dosyalar-onyazilar",
                    element: <Files />,
                  },
                  {
                    path: "başvurular",
                    element: <Application />,
                  },
                  {
                    path: "favoriler",
                    element: <Favorite />,
                  },
                  {
                    path: "follow",
                    element: <Follow />,
                  },

                  {
                    path: "kayitli-aramalar",
                    element: <SavedFilters />,
                  },

                  {
                    path: "son-incelenen-ilanlar",
                    element: <LastViewedAds />,
                  },

                  { path: "bildirimler", element: <Notification /> },
                  { path: "is-alarmlari", element: <WorkAlarm /> },
                  { path: "mesajlar", element: <Message /> },
                  { path: "mesajlar/:id", element: <Messageİnner /> },
                  {
                    path: "basvurular/:id/on-yazi-ekle",
                    element: <AddOnYazı />,
                  },
                  {
                    path: "cv/:id/goruntuleyen-firmalar",
                    element: <CvViewer />,
                  },
                  { path: "mulakat-listesi", element: <Interview /> },
                  { path: "ayarlar", element: <Settings /> },
                  { path: "hosgeldin", element: <WelcomePage /> },
                  {
                    path: "hosgeldin/kisisel-bilgiler",
                    element: <WelcomePersonalInformation />,
                  },
                  {
                    path: "hosgeldin/is-tercihleri",
                    element: <JobPreferencesInformation />,
                  },
                  /* {
                    path: "hosgeldin/istercihleri",
                    element: <Navigate to="/profilim" />,
                  }, */
                ],
              },
              {
                path: "profilim/cv",
                element: <CvDefault />,
              },
              {
                path: "cv/duzenle/:slug/*",
                element: <CvEdit />,
              },
              {
                path: "*",
                element: <Dashboard />,
                children: [
                  {
                    path: "ayarlar",
                    element: <Settings />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/verify",
        children: [
          {
            path: "email",
            element: <VerifyEmailPage userType="company" />,
          },
          {
            path: "phone",
            element: <VerifyPhonePage />,
          },
        ],
      },
      {
        path: "*",
        element: <Navigate to="/" />,
      },
    ],
  },
]);

const App: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default App;
