import Banner from "./components/Banner";
import Cv from "./components/Cv";
import Info from "./components/Info";
import { IconChevronRight } from "@tabler/icons-react";

export default function CvSablonPageContainer() {
  return (
    <>
      <div className="w-full bg-white py-5 ">
        <div className="flex items-center  container mx-auto ">
          <a href="/" aria-label="Anasayfa" className="poppins-regular text-sm">
            Ana Sayfa
          </a>

          <IconChevronRight className="text-blue-700 mx-1" size={18} />

          <a
            href="/cv-sablonlari"
            aria-label="İşilanları"
            className="poppins-regular text-sm"
          >
            Cv Şablonları
          </a>
        </div>
      </div>
      <Banner />
      <Cv />
      <Info />
    </>
  );
}
