import React from "react";
import HeaderButton from "../../../components/header-button";
import { CookieName } from "../../../../../../constants/enums";
import { useCookie } from "../../../../../../hooks/useCookie";

interface IHeaderLeftButtonGroup {
  type: "base" | "fixed";
}
const HeaderLeftButtonGroup: React.FC<IHeaderLeftButtonGroup> = (
  props: IHeaderLeftButtonGroup
) => {
  const textColor = props.type === "base" ? "text-white" : "text-title";

  const { getCookie } = useCookie();
  const userTokenCookie = getCookie(CookieName.UserToken);
  const userToken = userTokenCookie ? userTokenCookie : "";
  // TODO: add userData to the redux store
  const companyUserTokenCookie = getCookie(CookieName.CompanyUserToken);
  const companyUserToken = companyUserTokenCookie ? companyUserTokenCookie : "";

  return (
    <>
      {companyUserToken && (
        <>
          <div className="mr-[10px]  h-full hidden xl:block">
            <HeaderButton
              href="/"
              variant="text"
              text="İş Arayan"
              isLoginRequire={false}
              textColor={textColor}
            />
          </div>
          <div className="mr-[10px]  hidden xl:block">
            <HeaderButton
              href="/isveren"
              variant="text"
              text="İlanlarım"
              isLoginRequire={false}
              textColor={textColor}
            />
          </div>
          <div className="mr-[10px] hidden xl:block">
            <HeaderButton
              href="/isveren/ürünler"
              variant="text"
              text="Ürünler"
              isLoginRequire={false}
              textColor={textColor}
            />
          </div>
        </>
      )}

      {userToken && (
        <>
          <div className="mr-3 h-full hidden xl:block">
            <HeaderButton
              href="/"
              variant="text"
              text="Anasayfa"
              isLoginRequire={false}
              textColor={textColor}
            />
          </div>
          <div className="mr-3 hidden xl:block">
            <HeaderButton
              href="is-ilanlari"
              variant="text"
              text="İş İlanları"
              isLoginRequire={false}
              textColor={textColor}
            />
          </div>
        </>
      )}

      {props.type === "fixed" && (
        <div className="hidden xl:block">
          <HeaderButton
            variant="text"
            text="Ürünler"
            isLoginRequire={false}
            textColor={textColor}
          />
        </div>
      )}
    </>
  );
};

export default HeaderLeftButtonGroup;
