import React, { useEffect, useState } from "react";
import Header from "../../components/layout/header";
import Footer from "../../components/layout/footer";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AdvertService } from "../../services/advert-services";
import { setAdverts as setAdvertsForStore } from "../../store/features/advertReducer";
import { MobileFooter, MobileMenu } from "../../components";
import { addOrUpdateQueryParam } from "../../hooks/useAddOrUpdateQueryParam";

import Cookies from "js-cookie";
import CookieComponent from "./components/CookieComponent";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

function Layout() {
  const { height, width } = useWindowDimensions();

  const [isOpen, setIsOpen] = useState<Boolean>(false);
  const location = useLocation();

  const [adverts, setAdverts] = React.useState([]);
  const [totalPages, setTotalPages] = React.useState<number>(0);
  const [cookieAccepted, setCookieAccepted] = useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const advertsList = useSelector((state: any) => state.advert.adverts);
  const currentUrl = window.location.href;

  // URL'yi parse edelim
  const url = new URL(currentUrl);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  // Slug'ı al
  const slug = url.pathname;
  // React.useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //
  //   const page = searchParams.get("page") || "1";
  //   const pageSort = searchParams.get("page_sort") || "update";
  //   const pageLimit = searchParams.get("page_limit") || "35";
  //   const positions = searchParams.get("positions") || "[]";
  //   const cities = searchParams.get("cities") || "[]";
  //   const workTypes = searchParams.get("work_types") || "[]";
  //   const workingLocations = searchParams.get("working_locations") || "[]";
  //   const sections = searchParams.get("sections") || "[]";
  //   const sectors = searchParams.get("sectors") || "[]";
  //   const advertDate = searchParams.get("advert_date") || "";
  //   const minExp = searchParams.get("experience_level_min") || "";
  //   const maxExp = searchParams.get("experience_level_max") || "";
  //   const disabled = searchParams.get("disabled") || "";
  //   const total = searchParams.get("total") || "0";
  //
  //   const notTurkeyGeneral = searchParams.get("not_turkey_general") || "";
  //   const hiddenCompany =
  //     searchParams.get("not_show_hidden_company_title") || "";
  //   const positionLevels = searchParams.get("position_levels") || "";
  //   if (
  //     slug.startsWith("/is-ilanlari") &&
  //     slug.startsWith("/is-ilanlari/") === false
  //   ) {
  //     setTotalPages(parseInt(total) || 0);
  //     getAdverts(
  //       page,
  //       pageLimit,
  //       positions,
  //       cities,
  //       workTypes,
  //       workingLocations,
  //       sections,
  //       sectors,
  //       advertDate,
  //       minExp,
  //       maxExp,
  //       disabled,
  //       notTurkeyGeneral,
  //       hiddenCompany,
  //       positionLevels,
  //       pageSort
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.search]);

  const getAdverts = async (
    page: string,
    pageLimit: string,
    positions: string,
    cities: string,
    workTypes: string,
    workingLocations: string,
    sections: string,
    sectors: string,
    advertDate: string,
    minExp: string,
    maxExp: string,
    disabled: string,
    notTurkeyGeneral: string,
    hiddenCompany: string,
    positionLevels: string,
    pageSort: string
  ) => {
    const adverts = await AdvertService.getAdvertByFilter(
      `?page=${page}&page_sort=${pageSort}&page_limit=${pageLimit}&positions=${positions}&cities=${cities}&working_types=${workTypes}&working_locations=${workingLocations}&sections=${sections}&sectors=${sectors}&advert_date=${advertDate}&experience_level_min=${minExp}&experience_level_max=${maxExp}&disabled=${disabled}&not_turkey_general=${notTurkeyGeneral}&not_show_hidden_company_title=${hiddenCompany}&position_levels=${positionLevels}`
    );

    setAdverts(
      adverts.data.adverts.map((el: any) => ({
        advertId: el.advert.id || "",
        advertslug: el.advert.slug || "",
        advertTitle: el.advert.title || "",
        companyName: el.company_detail?.company_name || "",
        workingTypes: el?.working_types || [],
        workingType: el?.working_types[0]?.title || "",
        cities: el?.cities || [],
        city: el?.cities[0]?.name || "",
        companyLogo: el.company_detail?.logo,
        createdAt: el.advert.created_at || "",
        is_disabled_employee: el.advert.is_disabled_employee || 1,
        isSponsored:
          Date.now() < new Date(el.advert.doping_end_date).getTime()
            ? true
            : false,
        is_auth: el.is_auth || false,
        is_view: el.is_view || false,
        is_favorite: el.is_favorite || false,
      }))
    );
    navigate(
      "/is-ilanlari" +
        addOrUpdateQueryParam(location, [
          {
            key: "total",
            value: JSON.stringify(adverts.data.pagination.total),
          },
          {
            key: "last_page",
            value: JSON.parse(adverts.data.pagination.last_page),
          },
        ])
    );
  };
  React.useEffect(() => {
    dispatch(setAdvertsForStore(adverts));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adverts]);

  function checkCookieStatus() {
    const cookieAccept = Cookies.get("accept-cookie");

    if (!cookieAccept) {
      setCookieAccepted(false);
    }
  }

  function acceptCookie() {
    Cookies.set("accept-cookie", "true", {
      expires: new Date(
        new Date().setFullYear(new Date().getFullYear() + 1)
      ) /* 1 yıl */,
    });
    setCookieAccepted(true);
  }

  useEffect(() => {
    const timeOut = setTimeout(checkCookieStatus, 5 * 1000 /* 5 saniye */);

    return () => {
      clearTimeout(timeOut);
    };
  }, []);

  // İŞ İLANI BOŞ SAYFA GELME KISMI
  //console.log(!slug.startsWith("/is-ilanlari/"), "url");
  if (slug.startsWith("/is-ilanlari") && !slug.startsWith("/is-ilanlari/")) {
    // Burada "/is-ilanlari" slug'ı bulunduğunda yapılacak işlemler
    // if (advertsList.length === 0) {
    // 	return <div></div>;
    // } else {
    // 	dispatch(setAdvertsForStore(adverts));
    // }
  }
  return (
    <div className="flex flex-col md:justify-between min-h-screen bg-gray-100">
      <MobileMenu
        open={isOpen}
        handleActionMobileMenu={() => setIsOpen(false)}
      />
      <Header />
      <Outlet />

      <Footer />

      <MobileFooter handleActionMobileMenu={() => setIsOpen(true)} />

      {!cookieAccepted && <CookieComponent acceptCookie={acceptCookie} />}
      <ToastContainer />
    </div>
  );
}

export { Layout };
