import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { Button } from "../../../../../../components/isbul-ui";
import SaveFile from "../../../../files/contents/save-file";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { CvLayoutContext, cvLayoutContextType } from "../..";











export default function FilePageComponent() {

    const { isEnglish }: cvLayoutContextType = useContext(CvLayoutContext)

    const params = useParams()
    const cvId = params.slug

    const navigate = useNavigate()
    return(
        <div className="flex flex-col bg-white">
            <span className="poppins-semibold text-sm text-mains py-4 border-b border-mains px-4">
                {isEnglish? 'Files': 'Dosyalarım'}
            </span>
            <SaveFile isCvPage={true} isEnglish={isEnglish}/>

            <div className="flex flex-row items-center justify-between px-4 py-2 my-5">
                <Button
                type="button"
                variant="secondary"
                size="sm"
                onClick={() => {
                    navigate(`/profilim/cv/duzenle/${cvId}/sinavlar-sertifikalar`)
                }}
                >
                    <Button.Icon>
                        <IoChevronBack/>
                    </Button.Icon>
                    {isEnglish? 'Go Back': 'Geri Dön'}
                </Button>
                <Button
                type="button"
                size="sm"
                onClick={() => {
                    navigate(`/profilim/cv/duzenle/${cvId}/is-deneyimleri`)
                }}
                >
                    {isEnglish? 'Continue': 'Devam Et'}
                    <Button.Icon>
                        <IoChevronForward/>
                    </Button.Icon>
                </Button>
            </div>
        </div>
    )
}