import { useEffect, useState } from "react";
import CustomModal from "../../../../components/common/custom-modal";
import FormField from "../../../../components/common/form-field";
import { useFormik } from "formik";
import { PiPlus } from "react-icons/pi";
import * as Yup from "yup";
import Axios from "../../../../services/axios";
import { useDispatch } from "react-redux";
import { getFolders } from "../../../../store/features/companyFoldersSlice";
import { CgSpinner } from "react-icons/cg";
import Swal from "sweetalert2";
type Props = {};

const axios = new Axios();

const CreateModal = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch<any>();
  const formik = useFormik({
    initialValues: {
      title: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Klasör adını giriniz"),
    }),
    onSubmit: async (values) => {
      setSubmitting(true);
      try {
        if (submitting) return;
        await axios.authRequest({
          endpoint: "companies/folder/store",
          type: "post",
          payload: {
            title: values.title,
          },
        });
        await dispatch(getFolders());
        setOpen(false);
        // add swal success "Klasör Eklendi!" 3s timer
        Swal.fire({
          icon: "success",
          title: "Klasör Eklendi!",
          timer: 3000,
        });
      } catch (error: any) {
        console.log({ error });

        Swal.fire({
          icon: "error",
          title: error.response.data.error || "Klasör Eklenemedi!",
          timer: 3000,
        });
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <>
      <button
        className="bg-title rounded-md p-2 text-white flex justify-center items-center gap-1 text-xs mx-2"
        onClick={() => setOpen(true)}
      >
        Yeni Klasör Ekle
        <PiPlus />
      </button>
      <CustomModal
        open={open}
        onClose={() => setOpen(false)}
        title="Klasör Oluştur"
        acceptLabel={
          formik.isSubmitting ? (
            <CgSpinner className="animate-spin text-lg" />
          ) : (
            "Oluştur"
          )
        }
        closeLabel="İptal"
        onAccept={formik.handleSubmit}
        content={
          <form onSubmit={formik.handleSubmit}>
            <div>
              <FormField
                type="text"
                name="title"
                value={formik.values.title}
                label="Klasör Adı"
                required
                onChange={formik.handleChange}
                invalid={formik.errors.title?.length}
              />
            </div>
          </form>
        }
      />
    </>
  );
};

export default CreateModal;
