import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useEffect, useRef } from 'react';

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
  className?: string,
  options?: any,
  report: Array<{ [key: string]: any }>
}

export function ReportPieChart(props: Props) {
  const chartRef = useRef<any>(null);

  const labels = props.report.map((val: any) => val[Object.keys(val)[0]]);
  const data = props.report.map((val: any) => val[Object.keys(val)[1]]);

  const chartData = {
    labels,
    datasets: [
      {
        data,
        backgroundColor: data.map(() => `hsl(${Math.random() * 360}, 100%, 50%)`),
        hoverOffset: 13,
        borderWidth: 2
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
        labels: {
          usePointStyle: true,
          pointStyle: 'rounded',
          generateLabels: function(this: any, chart: any) {
            const original = ChartJS.overrides.pie.plugins.legend.labels.generateLabels;
            const labelsOriginal = original.call(this, chart);
            return labelsOriginal.map((label: any) => {
              label.textDecoration = 'none'; 
              return label;
            });
          },
        },
        onClick: (e: MouseEvent, legendItem: any, legend: any) => {
          const index = legend.legendItems.indexOf(legendItem);
          const meta = legend.chart.getDatasetMeta(0);
          if (meta.data[index]) {
            meta.data[index].hidden = !meta.data[index].hidden; 
            legend.chart.update();
          }
        }
      },
    },
    hover: {
      mode: 'index'
    },
    animation: {
      animateRotate: false,
      animateScale: true
    },
    interaction: {
      mode: 'nearest',
      intersect: true,
    },
    ...props.options
  };

  useEffect(() => {
    const chartInstance = chartRef.current?.chartInstance;
    const canvas = chartInstance?.canvas;

    if (canvas) {
      canvas.addEventListener('contextmenu', (e: MouseEvent) => {
        e.preventDefault();
        chartInstance.destroy();
      });
    }

    return () => {
      if (canvas) {
        canvas.removeEventListener('contextmenu', (e: MouseEvent) => {
          e.preventDefault();
          chartInstance.destroy();
        });
      }
    };
  }, []);

  return (
    <div className={props.className}>
      <Pie ref={chartRef} data={chartData} options={chartOptions} />
    </div>
  );
}
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
// import { Pie } from 'react-chartjs-2';
// import { useEffect, useRef } from 'react';

// ChartJS.register(ArcElement, Tooltip, Legend);

// type Props = {
//   className?: string,
//   options?: any,
//   report: Array<{ [key: string]: any }>
// }

// export function ReportPieChart(props: Props) {
//   console.log(props);

//   const chartRef = useRef<any>(null);

//   const labels = props.report.map((val: any) => val[Object.keys(val)[0]]);
//   const data = props.report.map((val: any) => val[Object.keys(val)[1]]);

//   const chartData = {
//     labels,
//     datasets: [
//       {
//         data,
//         backgroundColor: data.map(() => `hsl(${Math.random() * 360}, 100%, 50%)`),
//         hoverOffset: 13,
//         borderWidth: 2
//       },
//     ],
//   };

//   const chartOptions = {
//     plugins: {
//       legend: {
//         display: true,
//         position: 'right',
//         labels: {
//           usePointStyle: true,
//           pointStyle: 'rounded',
//           generateLabels: function(this: any, chart: any) {
//             const original = ChartJS.overrides.pie.plugins.legend.labels.generateLabels;
//             const labelsOriginal = original.call(this, chart);
//             return labelsOriginal.map((label: any) => {
//               label.hidden = false; // Show all legends initially
//               label.textDecoration = 'none'; // Remove underline
//               return label;
//             });
//           },
//         },
//         onClick: (e: MouseEvent, legendItem: any) => {
//           if (!legendItem.chart) return;
//           const index = legendItem.index;
//           const ci = legendItem.chart;
          
//           const meta = ci.getDatasetMeta(0);
//           if (!meta || !meta.data[index]) return;
          
//           meta.data[index].hidden = !meta.data[index].hidden;
//           ci.update();
//         }
//       },
//     },
//     hover: {
//       mode: 'index'
//     },
//     animation: {
//       animateRotate: false,
//       animateScale: true
//     },
//     interaction: {
//       mode: 'nearest',
//       intersect: true,
//     },
//     onClick: (e: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
//       if (e.button === 2) {
//         e.preventDefault();
//         chartRef.current?.chartInstance.destroy();
//       }
//     },
//     ...props.options
//   };

//   useEffect(() => {
//     const chartInstance = chartRef.current?.chartInstance;
//     const canvas = chartInstance?.canvas;

//     const handleContextMenu = (e: MouseEvent) => {
//       e.preventDefault();
//       chartInstance?.destroy();
//     };

//     if (canvas) {
//       canvas.addEventListener('contextmenu', handleContextMenu);
//     }

//     return () => {
//       if (canvas) {
//         canvas.removeEventListener('contextmenu', handleContextMenu);
//       }
//     };
//   }, []);

//   return (
//     <div className={props.className}>
//       <Pie ref={chartRef} data={chartData} options={chartOptions} />
//     </div>
//   );
// }
