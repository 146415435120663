import React from "react";
import SearchBar from "./search-bar";
import MainTitle from "./main-title";

const Main: React.FC = () => {
	return (
		<>
			<div className='flex flex-col items-center w-full py-3 bg-white rounded-md gap-y-2'>
				{/* <SearchBar /> */}
				<MainTitle />
			</div>
		</>
	);
};

export default Main;
