import React, { useContext, useState } from "react";
import { CardTemplate } from "./components/card-template";
import { IApplicantsData } from "../../types";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Fragment } from "react";
import PaginationWeb from "../../../../job-page-container/main/advert-section-body/components/pagination";
import PaginationMobile from "../../../../job-page-container/main/advert-section-body/components/mobile-pagination";
import { ApplyPoolContext } from "../../index";
import { FiAlertCircle } from "react-icons/fi";
import { useParams } from "react-router-dom";

function CardsSection({
  cardCheckedStatus,
  onCardCheckboxToggle,
  data,
  pagesCount,
  currentPage,
  setCurrentPage,
  isLoading,
}: {
  cardCheckedStatus: any;
  onCardCheckboxToggle: (a: any) => void;
  data: IApplicantsData[];
  pagesCount: number;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
}) {
  const { scrollRef, pagination } = useContext(ApplyPoolContext);

  const maxVisiblePages = 3;

  const currentPageData = data;

  const totalPages = pagesCount;

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  const handleNext = () => {
    setCurrentPage((prevCurrentPage) =>
      Math.min(prevCurrentPage + 1, totalPages - 1)
    );
  };

  const handlePrevious = () => {
    setCurrentPage((prevCurrentPage) => Math.max(prevCurrentPage - 1, 0));
  };

  // Dynamic calculation for visible page numbers
  const getVisiblePages = (currentPage: number, totalPages: number) => {
    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 0);
    let endPage = startPage + maxVisiblePages - 1;

    if (endPage >= totalPages) {
      endPage = totalPages - 1;
      startPage = Math.max(endPage - maxVisiblePages + 1, 0);
    }

    const pages = [];
    for (let page = startPage; page <= endPage; page++) {
      pages.push(page);
    }
    return pages;
  };

  const visiblePages = getVisiblePages(currentPage, totalPages);

  return (
    <div className="flex flex-col md:gap-4 gap-[1px] mb-4 ">
      {!isLoading && currentPageData.length === 0 && (
        <div className="mx-10">
          <div className="flex items-stretch mt-4 mb-9 border border-gray-300 bg-white rounded-md w-full">
            <span className="flex items-center justify-center bg-red-500 text-white p-3 rounded-l-md">
              <FiAlertCircle size={35} />
            </span>
            <p className="flex flex-col sm:flex-row justify-start items-center font-poppins text-sm poppins-medium p-4 whitespace-nowrap">
              Başvuru bulunamadı!
            </p>
          </div>
        </div>
      )}
      {currentPageData?.map((data: IApplicantsData) => {
        return (
          <div key={data.user.id}>
            <CardTemplate
              data={data}
              isChecked={cardCheckedStatus[data.cv.id]?.checked || false}
              onCheckboxToggle={() => onCardCheckboxToggle(data)}
            />
          </div>
        );
      })}
      <div className="flex items-center justify-center">
        <div className="flex justify-center gap-1 mt-4 items-center mx-20 md:mx-[40vw] md:gap-[30px] ">
          {/* Desktop pagination */}
          {(pagination
            ? pagination.last_page
              ? pagination.last_page > 1
              : false
            : false) && (
            <div className="hidden xl:block h-12 w-full">
              <PaginationWeb
                pagination={pagination}
                onChange={() => {
                  scrollRef?.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }}
              />
            </div>
          )}

          {/* Mobile pagination */}
          {(pagination
            ? pagination.last_page
              ? pagination.last_page > 1
              : false
            : false) && (
            <div className="block xl:hidden w-full">
              <PaginationMobile
                pagination={pagination}
                onChange={() => {
                  scrollRef?.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export { CardsSection };
