import React, { useEffect, useRef, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { BsBuildings } from "react-icons/bs";
import { PiDotsThreeVertical } from "react-icons/pi";
import services from "../../../../../../services/profile-services";
import Swal from "sweetalert2";
import { Button, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import Axios from "../../../../../../services/axios";
import { PiWarningCircle } from "react-icons/pi";
import { CiSearch } from "react-icons/ci";
import SendMessageToCompany from "../../../components/SendMessageToCompany";

interface ListProps {
  type: "b1" | "b2";
}

interface LocationDetails {
  id: number;
  city: {
    id: number;
    name: string;
  };
  district: {
    id: number;
    name: string;
  };
  content: string;
}

export interface ApplyData {
  id: number;
  company_id: number;
  company_name: string;
  company_logo: string;
  is_read: number;
  apply_date: string;
  cv_title: string;
  cover_letter: string;
  advert_status: string;
  advert_apply_count: number;
  advert_location: LocationDetails[];
  advert_positions: Position[];
  advert_slug: string;
  apply_status: string;
  advert_title: string;
  chat: {
    id: number;
    user_is_delete: number;
  } | null;
}

interface Position {
  id: number;
  position: {
    id: number;
    title: string;
  };
  description?: string;
}

const List: React.FC<ListProps> = ({ type }) => {
  const axios = new Axios();
  const navigate = useNavigate();
  const [appliedJobs, setAppliedJobs] = useState<ApplyData[]>([]);
  const [archivedJobs, setArchivedJobs] = useState<ApplyData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [expandedIndexDesktop, setExpandedIndexDesktop] = useState<
    number | null
  >(null);
  const [expandedIndexMobile, setExpandedIndexMobile] = useState<number | null>(
    null
  );
  const expandedRefsDesktop = useRef<(HTMLDivElement | null)[]>([]);
  const expandedRefsMobile = useRef<(HTMLDivElement | null)[]>([]);
  const [popupIndexDesktop, setPopupIndexDesktop] = useState<number | null>(
    null
  );
  const [popupIndexMobile, setPopupIndexMobile] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCoverLetter, setSelectedCoverLetter] = useState<string>("");
  const [cvId, setCvId] = useState<number>(0);
  const [remainingLocations, setRemainingLocations] = useState<
    LocationDetails[]
  >([]);
  const [searchText, setSearchText] = useState<string>("");
  const [filteredJobs, setFilteredJobs] = useState<ApplyData[]>([]);
  const popupRefDesktop = useRef<HTMLDivElement | null>(null);
  const popupRefMobile = useRef<HTMLDivElement | null>(null);
  const [isMessagePopupOpen, setIsMessagePopupOpen] = useState<boolean>(false);

  console.log(appliedJobs);
  useEffect(() => {
    const fetchCv = async () => {
      try {
        const response = await axios.request({
          endpoint: `user/profilim/cv`,
          type: "get",
          payload: {},
        });

        if (response?.data?.data) {
          setCvId(response.data.data.id);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchCv();
  }, []);

  const showModal = (coverLetter: string) => {
    setSelectedCoverLetter(coverLetter);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showLocationsModal = (locations: LocationDetails[]) => {
    setRemainingLocations(locations);
    setIsModalOpen(true);
  };

  useEffect(() => {
    setIsLoading(true);
    const getAppliedJobs = async () => {
      const response = await services.getAppliedJobs();
      if (response.status) {
        const data = Array.isArray(response.data.applies)
          ? response.data.applies
          : [response.data.applies];
        setAppliedJobs(data);
      }
      setIsLoading(false);
    };
    getAppliedJobs();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const getArchivedJobs = async () => {
      const response = await services.getArchivedAppliedJobs();

      setArchivedJobs(response.data.applies);

      setIsLoading(false);
    };
    getArchivedJobs();
  }, []);

  useEffect(() => {
    filterJobs();
    // eslint-disable-next-line
  }, [searchText, appliedJobs, archivedJobs]);

  const handleArchiveJob = async (id: number) => {
    Swal.fire({
      title: "İşlemi Onaylayın?",
      text: "Başvurunuzu arşive taşımak istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
      cancelButtonText: "İptal",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await services.archiveAppliedJob(id, "ARCHIVED");
        if (response.status === 200) {
          setIsLoading(true);
          const jobToArchive = appliedJobs.find((job) => job.id === id);
          if (jobToArchive) {
            setArchivedJobs([...archivedJobs, jobToArchive]);
          }
          setAppliedJobs(appliedJobs.filter((job) => job.id !== id));
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: "Başarılı!",
            text: "Başvuru arşivlendi.",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    });
  };

  const handleUnarchiveJob = async (id: number) => {
    const result = await Swal.fire({
      title: "Emin misiniz?",
      text: "Bu başvuruyu arşivden çıkarmak istiyor musunuz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
      cancelButtonText: "İptal",
    });

    if (result.isConfirmed) {
      const response = await services.archiveAppliedJob(id, "ACTIVE");
      if (response.status === 200) {
        setIsLoading(true);
        const jobToUnarchive = archivedJobs.find((job) => job.id === id);
        if (jobToUnarchive) {
          setAppliedJobs([...appliedJobs, jobToUnarchive]);
        }
        setArchivedJobs(archivedJobs.filter((job) => job.id !== id));
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Başarılı!",
          text: "Başvuru arşivden çıkarıldı.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const handleUnApplyJob = async (id: number) => {
    const result = await Swal.fire({
      title: "İşlemi Onaylayın?",
      text: "Başvuruyu geri çekmek istediğinizden emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
      cancelButtonText: "İptal",
    });

    if (result.isConfirmed) {
      const response = await services.archiveAppliedJob(id, "ROLLBACK");
      if (response.status === 200) {
        setAppliedJobs((prevAppliedJobs) =>
          prevAppliedJobs.filter((job) => job.id !== id)
        );
        setArchivedJobs((prevArchivedJobs) =>
          prevArchivedJobs.filter((job) => job.id !== id)
        );

        Swal.fire({
          icon: "success",
          title: "Başarılı!",
          text: "Başvuru geri alındı.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };

  const formatDate = (date: string) => {
    const newDate = new Date(date);
    const cleanDate = newDate.toLocaleDateString("tr-TR").replace(/\//g, ".");
    return cleanDate;
  };

  const handleToggleExpandDesktop = (index: number) => {
    setExpandedIndexDesktop(expandedIndexDesktop === index ? null : index);
  };

  const handleToggleExpandMobile = (index: number) => {
    setExpandedIndexMobile(expandedIndexMobile === index ? null : index);
  };

  const handleTogglePopupDesktop = (index: number) => {
    setPopupIndexDesktop(popupIndexDesktop === index ? null : index);
  };

  const handleTogglePopupMobile = (index: number) => {
    setPopupIndexMobile(popupIndexMobile === index ? null : index);
  };

  const normalizeString = (str: string) => {
    return str
      .replace(/ğ/g, "g")
      .replace(/Ğ/g, "G")
      .replace(/ü/g, "u")
      .replace(/Ü/g, "U")
      .replace(/ş/g, "s")
      .replace(/Ş/g, "S")
      .replace(/ı/g, "i")
      .replace(/İ/g, "I")
      .replace(/ö/g, "o")
      .replace(/Ö/g, "O")
      .replace(/ç/g, "c")
      .replace(/Ç/g, "C");
  };

  const filterJobs = () => {
    const normalizedSearchTerm = normalizeString(searchText.toLowerCase());

    const newJobs =
      type === "b1"
        ? appliedJobs.filter((job) =>
            normalizeString(
              job.advert_positions[0]?.position?.title.toLowerCase() || ""
            ).includes(normalizedSearchTerm)
          )
        : archivedJobs.filter((job) =>
            normalizeString(
              job.advert_positions[0]?.position?.title.toLowerCase() || ""
            ).includes(normalizedSearchTerm)
          );

    setFilteredJobs(newJobs);
  };

  useEffect(() => {
    function handleClickOutsideDesktop(event: MouseEvent) {
      if (
        popupRefDesktop.current &&
        !popupRefDesktop.current.contains(event.target as Node)
      ) {
        setPopupIndexDesktop(null);
      }
    }

    function handleClickOutsideMobile(event: MouseEvent) {
      if (
        popupRefMobile.current &&
        !popupRefMobile.current.contains(event.target as Node)
      ) {
        setPopupIndexMobile(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutsideDesktop);
    document.addEventListener("mousedown", handleClickOutsideMobile);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideDesktop);
      document.removeEventListener("mousedown", handleClickOutsideMobile);
    };
  }, [popupRefDesktop, popupRefMobile]);

  if (isLoading) {
    return (
      <div className="flex items-center p-4 font-bold border">
        Yükleniyor...
      </div>
    );
  }

  const truncate = (str: string, n: number) => {
    return str?.length > n ? str?.substr(0, n - 1) + "..." : str;
  };

  return (
    <>
      {appliedJobs.length > 1 && (
        <div className="flex justify-start w-full h-10 px-4 item-center mb-6">
          <div className="flex items-center min-w-[175px] w-4/12 h-full px-2 border border-gray-400 rounded-md gap-x-1">
            <CiSearch className="text-lg text-gray-500" />

            <input
              placeholder="Başvurularda ara"
              className="w-full pr-2 text-xs text-gray-500 placeholder-gray-400 outline-none poppins-medium"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
      )}

      <div className="hidden md:flex flex-col w-full px-2 md:px-4 pb-4 gap-y-3 font-poppins">
        {filteredJobs?.length > 0 ? (
          filteredJobs?.map((job, index) => (
            <React.Fragment key={job?.id}>
              <div className="relative flex w-full h-auto px-2 md:px-4 py-4 transition-all duration-300 ease-in border border-gray-300 rounded-md bg-gray-50 gap-x-4 hover:border-mains">
                <div className="flex items-center justify-center w-2/12 lg:w-1/12">
                  <img
                    className="object-cover w-full"
                    src={
                      job.company_logo
                        ? job.company_logo
                        : "https://isbull.s3.eu-north-1.amazonaws.com/default-images/company-logo.png"
                    }
                    alt="Company Logo"
                  />
                </div>
                <div className="w-10/12 h-full lg:w-11/12">
                  <div className="flex flex-col items-center justify-center h-full gap-y-3">
                    <div className="flex justify-between w-full">
                      <div className="flex flex-col w-full md:w-2/4 gap-y-1">
                        <div className="flex items-center w-full pr-2 gap-x-1 ">
                          {job?.advert_positions && (
                            <>
                              <a
                                href={`/is-ilanlari/${job.advert_slug}`}
                                className="truncate text-xs md:text-base poppins-semibold text-mains"
                              >
                                {job?.advert_title}
                              </a>

                              {job?.apply_status !== "ARCHIVED" && (
                                <span
                                  className={`text-xs poppins-semibold ${
                                    job.advert_status === "PASSIVE"
                                      ? "text-red-500"
                                      : "text-green-500"
                                  } `}
                                >
                                  (
                                  {job.advert_status === "PASSIVE"
                                    ? "Pasif"
                                    : "Aktif"}
                                  )
                                </span>
                              )}
                            </>
                          )}
                        </div>
                        <div className="flex items-center w-full">
                          <div className="flex gap-x-2">
                            <BsBuildings className="text-lg text-mains" />
                            <a
                              href={`/firma/${job.company_id}`}
                              className="text-xs text-gray-500 truncate poppins-light max-w-[120px] md:max-w-[200px] lg:max-w-[250px]"
                            >
                              {job?.company_name ? job.company_name : "Firma bilgisi gizli"}
                            </a>
                          </div>
                        </div>
                        <div className="flex items-center w-full">
                          <div className="flex gap-x-2">
                            <span className="text-xs text-gray-500 truncate poppins-light">
                              Başvuru Tarihi
                            </span>
                            <span className="text-xs text-gray-500 truncate poppins-light">
                              {formatDate(job?.apply_date)}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="relative flex items-center justify-end w-auto lg:gap-x-3 gap-x-1">
                        {job.is_read === 0 ? (
                          <div className="flex items-center">
                            <span className="lg:text-sm text-[9px] text-gray-700">
                              Başvuru İletildi
                            </span>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <span className="lg:text-sm text-[9px] text-gray-700">
                              Başvuru Görüntülendi
                            </span>
                          </div>
                        )}
                        <span
                          className="text-2xl cursor-pointer lg:text-3xl "
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTogglePopupDesktop(index);
                          }}
                        >
                          <PiDotsThreeVertical className="text-gray-900" />
                        </span>
                        {popupIndexDesktop === index && (
                          <div
                            ref={popupRefDesktop}
                            className="absolute right-1 top-11 z-10 flex flex-col gap-1 h-auto p-2 mt-2 bg-white border rounded-md shadow-lg w-max font-[600]"
                            onClick={(e) => e.stopPropagation()}
                          >
                            {job.advert_status === "ACTIVE" && (
                              <>
                                <a
                                  className="p-2 text-[13px] text-left border transition-all duration-300 ease-in-out bg-white rounded-md hover:bg-mains text-mains hover:text-gray-100"
                                  href={`/profilim/basvurular/${job.id}/on-yazi-ekle`}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  {job.cover_letter
                                    ? "Ön Yazıyı Güncelle"
                                    : "Ön Yazı Ekle"}
                                </a>
                                <button
                                  className="p-2 text-[13px] text-left border transition-all duration-300 ease-in-out bg-white rounded-md hover:bg-mains text-mains hover:text-gray-100"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleUnApplyJob(job.id);
                                  }}
                                >
                                  Başvuruyu Geri Al
                                </button>
                              </>
                            )}

                            {type === "b1" && (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleArchiveJob(job.id);
                                }}
                                className="p-2 text-[13px] text-left border transition-all duration-300 ease-in-out bg-white rounded-md hover:bg-mains text-mains hover:text-gray-100"
                              >
                                Arşive Ekle
                              </button>
                            )}
                            {type === "b2" && (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleUnarchiveJob(job.id);
                                }}
                                className="p-2 text-[13px] text-left border transition-all duration-300 ease-in-out bg-white rounded-md hover:bg-mains text-mains hover:text-gray-100"
                              >
                                Arşivden Çıkar
                              </button>
                            )}
                            {job.advert_status === "ACTIVE" && (
                              <button
                                className="p-2 text-[13px] text-left border transition-all duration-300 ease-in-out bg-white rounded-md hover:bg-mains text-mains hover:text-gray-100"
                                onClick={(e) => {
                                  if (
                                    job?.chat &&
                                    job?.chat?.user_is_delete === 0
                                  ) {
                                    navigate(
                                      `/profilim/mesajlar/${job.chat.id}`
                                    );
                                  } else {
                                    e.stopPropagation();
                                    setIsMessagePopupOpen(true);
                                  }
                                }}
                              >
                                Mesaj Gönder
                              </button>
                            )}

                            {isMessagePopupOpen && (
                              <SendMessageToCompany
                                companyId={job.company_id}
                                setIsOpen={setIsMessagePopupOpen}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="absolute flex items-center transition-all duration-300 ease-in-out bg-white border rounded-md cursor-pointer -bottom-2 border-mains right-1/2 hover:bg-gray-200"
                  onClick={() => handleToggleExpandDesktop(index)}
                >
                  <ChevronDownIcon
                    className={`h-5 w-7 lg:w-8 lg:h-6 transition-all duration-300 ${
                      expandedIndexDesktop === index ? "rotate-180" : ""
                    }`}
                  />
                </div>
              </div>
              <div
                ref={(el) => (expandedRefsDesktop.current[index] = el)}
                className={`flex justify-center w-full transition-all duration-300 ease-in-out overflow-hidden`}
                style={{
                  maxHeight:
                    expandedIndexDesktop === index
                      ? expandedRefsDesktop.current[index]?.scrollHeight
                      : 0,
                }}
              >
                <div className="flex justify-between w-full px-2 md:px-4 py-4 border border-gray-300 rounded-md h-max bg-gray-50">
                  <div className="flex gap-2">
                    <ul className="flex flex-col items-start justify-center gap-3 md:gap-2 text-xs text-black font-[600]">
                      <li>
                        {job?.advert_location?.length > 0 ? "Konum" : ""}

                        <span></span>
                      </li>
                      <li>
                        Özgeçmişin <span></span>
                      </li>
                      <li>
                        Ön Yazın <span></span>
                      </li>
                      <li>
                        İlan Durumu <span></span>
                      </li>
                    </ul>
                    <ul className="flex flex-col items-start justify-center gap-3 md:gap-2 text-xs">
                      <li className="flex items-center">
                        {job?.advert_location?.length > 0 ? ":" : ""} {""}
                        <span className="truncate max-w-[100px] md:max-w-[250px] flex-grow ml-1">
                          {job?.advert_location?.length > 0 &&
                            `${job?.advert_location[0]?.city?.name} / ${
                              job?.advert_location[0]?.district?.name ??
                              "Tüm İlçeler"
                            }`}
                        </span>
                        {job?.advert_location?.length > 1 && (
                          <span
                            className="text-mains cursor-pointer whitespace-nowrap ml-[2px]"
                            onClick={() =>
                              showLocationsModal(job?.advert_location.slice(1))
                            }
                          >
                            {` (+${job?.advert_location.length - 1})`}
                          </span>
                        )}
                      </li>
                      <li>
                        :{" "}
                        <a
                          className="text-mains"
                          href={`/profilim/cv/goruntule/${cvId}`}
                        >
                          {job.cv_title}
                        </a>
                      </li>
                      <li>
                        :{" "}
                        {job.cover_letter ? (
                          <span
                            className="text-mains cursor-pointer"
                            onClick={() => showModal(job.cover_letter)}
                          >
                            Görüntüle
                          </span>
                        ) : (
                          <span
                            className="text-mains cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/profilim/basvurular/${job.id}/on-yazi-ekle`
                              )
                            }
                          >
                            Ön Yazı Ekle
                          </span>
                        )}
                      </li>
                      <li>
                        :{" "}
                        <span
                          className={`rounded-md text-[11px] font-[600] py-1 px-2 text-white ${
                            job.advert_status === "PASSIVE"
                              ? " bg-red-500 text-white"
                              : "bg-green-500"
                          }`}
                        >
                          {job.advert_status === "PASSIVE" ? " Pasif" : "Aktif"}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-1 lg:gap-4 lg:flex-row">
                    <div className="flex items-center justify-center h-10 bg-gray-200 text-mains rounded-md text-[10px] lg:text-sm lg:w-36 w-24 font-[600]">
                      {job.advert_apply_count} KİŞİ BAŞVURDU
                    </div>
                    <a
                      href={`/is-ilanlari/${job.advert_slug}`}
                      className="flex items-center justify-center w-24 h-10 text-sm text-white rounded-md cursor-pointer lg:text-base lg:w-36 bg-mains hover:bg-[#267ec1]"
                    >
                      İLANA GİT
                    </a>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))
        ) : appliedJobs.length === 0 || archivedJobs.length === 0 ? (
          <div className="flex flex-row items-center gap-4 rounded-md bg-white shadow-lg border mt-4">
            <div className="bg-red-600 rounded-l-md py-3 px-1">
              <PiWarningCircle className="text-4xl text-white" />
            </div>
            <div>
              <label className="font-poppins text-gray-800">
                Başvuru bulunamadı
                <a href="/is-ilanlari" className="underline text-mains ml-1">
                  İş İlanlarını İncele
                </a>
              </label>
            </div>
          </div>
        ) : null}

        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          closable={false}
          footer={[
            <div className="flex justify-center items-center pb-4">
              <Button type="primary" key="back" onClick={handleCancel}>
                OK
              </Button>
            </div>,
          ]}
        >
          {remainingLocations.length > 0 ? (
            <div className="min-h-20 flex justify-center items-center">
              <ul className="list-disc">
                {remainingLocations.map((loc, idx) => (
                  <li key={idx}>
                    {loc.city.name} / {loc.district.name}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="min-h-20 flex justify-center items-center">
              <p className="text-sm text-black font-poppins poppins-regular">
                {selectedCoverLetter}
              </p>
            </div>
          )}
        </Modal>
      </div>

      <div className="flex md:hidden flex-col w-full px-2 md:px-4 pb-4 gap-y-3 font-poppins">
        {filteredJobs?.length > 0 ? (
          filteredJobs?.map((job, index) => (
            <React.Fragment key={job?.id}>
              <div className="relative flex w-full h-auto px-2 md:px-4 py-4 transition-all duration-300 ease-in border border-gray-300 rounded-md bg-gray-50 gap-x-4 hover:border-mains">
                <div className="flex items-center justify-center w-2/12 lg:w-1/12">
                  <img
                    className="object-cover w-full"
                    src={
                      job?.company_logo
                        ? job?.company_logo
                        : "https://isbull.s3.eu-north-1.amazonaws.com/default-images/company-logo.png"
                    }
                    alt="Company Logo"
                  />
                </div>
                <div className="w-10/12 h-full lg:w-11/12">
                  <div className="flex flex-col items-center justify-center h-full gap-y-3">
                    <div className="flex justify-between w-full">
                      <div className="flex flex-col w-full md:w-2/4 gap-y-1">
                        <div className="flex items-center w-full pr-2 gap-x-1 ">
                          {job?.advert_positions && (
                            <>
                              <a
                                href={`/is-ilanlari/${job?.advert_slug}`}
                                className="truncate text-xs md:text-base poppins-semibold text-mains"
                              >
                                {truncate(job?.advert_title, 20)}
                              </a>
                            </>
                          )}
                        </div>
                        <div className="flex items-center w-full">
                          <div className="flex gap-x-2">
                            <BsBuildings className="text-lg text-mains" />
                            <a
                              href={`/firma/${job?.company_id}`}
                              className="text-xs text-gray-500 truncate poppins-light max-w-[120px] md:max-w-[200px] lg:max-w-[250px]"
                            >
                              {job?.company_name ? job.company_name : "Firma bilgisi gizli"}
                            </a>
                          </div>
                        </div>
                        <div className="flex items-center w-full">
                          <div className="flex gap-x-2">
                            <span className="text-xs text-gray-500 truncate poppins-light">
                              Başvuru Tarihi
                            </span>
                            <span className="text-xs text-gray-500 truncate poppins-light">
                              {formatDate(job?.apply_date)}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="relative flex items-center justify-end w-auto lg:gap-x-3 gap-x-1">
                        {job?.is_read === 0 ? (
                          <div className="flex items-center">
                            <span className="lg:text-sm text-[9px] text-gray-700">
                              Başvuru İletildi
                            </span>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <span className="lg:text-sm text-[9px] text-gray-700">
                              Başvuru Görüntülendi
                            </span>
                          </div>
                        )}
                        <span
                          className="text-2xl cursor-pointer lg:text-3xl "
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTogglePopupMobile(index);
                          }}
                        >
                          <PiDotsThreeVertical className="text-gray-900" />
                        </span>
                        {popupIndexMobile === index && (
                          <div
                            ref={popupRefMobile}
                            className="absolute right-1 top-11 z-10 flex flex-col gap-1 h-auto p-2 mt-2 bg-white border rounded-md shadow-lg w-max font-[600]"
                            onClick={(e) => e.stopPropagation()}
                          >
                            {job?.advert_status === "ACTIVE" && (
                              <>
                                <a
                                  className="p-2 text-[13px] text-left border transition-all duration-300 ease-in-out bg-white rounded-md hover:bg-mains text-mains hover:text-gray-100"
                                  href={`/profilim/basvurular/${job.id}/on-yazi-ekle`}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  {job?.cover_letter
                                    ? "Ön Yazıyı Güncelle"
                                    : "Ön Yazı Ekle"}
                                </a>
                                <button
                                  className="p-2 text-[13px] text-left border transition-all duration-300 ease-in-out bg-white rounded-md hover:bg-mains text-mains hover:text-gray-100"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleUnApplyJob(job.id);
                                  }}
                                >
                                  Başvuruyu Geri Al
                                </button>
                              </>
                            )}

                            {type === "b1" && (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleArchiveJob(job?.id);
                                }}
                                className="p-2 text-[13px] text-left border transition-all duration-300 ease-in-out bg-white rounded-md hover:bg-mains text-mains hover:text-gray-100"
                              >
                                Arşive Ekle
                              </button>
                            )}
                            {type === "b2" && (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleUnarchiveJob(job?.id);
                                }}
                                className="p-2 text-[13px] text-left border transition-all duration-300 ease-in-out bg-white rounded-md hover:bg-mains text-mains hover:text-gray-100"
                              >
                                Arşivden Çıkar
                              </button>
                            )}
                            {job?.advert_status === "ACTIVE" && (
                              <button
                                className="p-2 text-[13px] text-left border transition-all duration-300 ease-in-out bg-white rounded-md hover:bg-mains text-mains hover:text-gray-100"
                                onClick={(e) => {
                                  if (
                                    job?.chat &&
                                    job?.chat?.user_is_delete === 0
                                  ) {
                                    navigate(
                                      `/profilim/mesajlar/${job.chat.id}`
                                    );
                                  } else {
                                    e.stopPropagation();
                                    setIsMessagePopupOpen(true);
                                  }
                                }}
                              >
                                Mesaj Gönder
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="absolute flex items-center transition-all duration-300 ease-in-out bg-white border rounded-md cursor-pointer -bottom-2 border-mains right-1/2 hover:bg-gray-200"
                  onClick={() => handleToggleExpandMobile(index)}
                >
                  <ChevronDownIcon
                    className={`h-5 w-7 lg:w-8 lg:h-6 transition-all duration-300 ${
                      expandedIndexMobile === index ? "rotate-180" : ""
                    }`}
                  />
                </div>
              </div>
              <div
                ref={(el) => (expandedRefsMobile.current[index] = el)}
                className={`flex justify-center w-full transition-all duration-300 ease-in-out overflow-hidden`}
                style={{
                  maxHeight:
                    expandedIndexMobile === index
                      ? expandedRefsMobile.current[index]?.scrollHeight
                      : 0,
                }}
              >
                <div className="flex justify-between w-full px-2 md:px-4 py-4 border border-gray-300 rounded-md h-max bg-gray-50">
                  <div className="flex gap-2 w-full justify-between">
                    <ul className="flex flex-col items-start justify-center gap-3 md:gap-2 text-xs w-full">
                      <div className="flex items-center justify-center text-sm lg:text-base font-[600]">
                        {job?.advert_apply_count} Kişi Başvurdu
                      </div>

                      <li className="flex  justify-between w-full items-center border-b pb-2">
                        <p className=" font-poppins poppins-regular">
                          {" "}
                          {job?.advert_location?.length > 0 ? "Konum" : ""}
                        </p>

                        <span>
                          <span className="truncate max-w-[100px] md:max-w-[250px] flex-grow ml-1">
                            {job?.advert_location?.length > 0 &&
                              `${job.advert_location[0]?.city?.name} / ${
                                job.advert_location[0]?.district?.name ??
                                "Tüm İlçeler"
                              }`}
                          </span>
                          {job?.advert_location?.length > 1 && (
                            <span
                              className="font-poppins poppins-regular cursor-pointer whitespace-nowrap ml-[4px] font-[600]"
                              onClick={() =>
                                showLocationsModal(
                                  job?.advert_location.slice(1)
                                )
                              }
                            >
                              {` (+${job?.advert_location.length - 1})`}
                            </span>
                          )}
                        </span>
                      </li>

                      <li className="flex  justify-between w-full items-center border-b pb-2">
                        <p className=" font-poppins poppins-regular">
                          Özgeçmişin
                        </p>
                        <span>
                          <a
                            className="font-poppins poppins-regular underline"
                            href={`/profilim/cv/${cvId}/goruntule`}
                            style={{
                              textDecoration: "underline",
                              textUnderlineOffset: "4px",
                            }}
                          >
                            {job?.cv_title}
                          </a>
                        </span>
                      </li>

                      <li className="flex  justify-between w-full items-center border-b pb-2">
                        <p className=" font-poppins poppins-regular">
                          Ön Yazın
                        </p>
                        <span>
                          {job?.cover_letter ? (
                            <span
                              className="font-poppins poppins-regular cursor-pointer underline"
                              onClick={() => showModal(job?.cover_letter)}
                            >
                              Görüntüle
                            </span>
                          ) : (
                            <span
                              className="font-poppins poppins-regular cursor-pointer underline"
                              onClick={() =>
                                navigate(
                                  `/profilim/basvurular/${job?.id}/on-yazi-ekle`
                                )
                              }
                            >
                              Ön Yazı Ekle
                            </span>
                          )}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))
        ) : appliedJobs.length === 0 && archivedJobs.length === 0 ? (
          <div className="flex flex-row items-center gap-4 rounded-md bg-white shadow-lg border mt-4">
            <div className="bg-red-600 rounded-l-md py-3 px-1">
              <PiWarningCircle className="text-4xl text-white" />
            </div>
            <div>
              <label className="font-poppins text-gray-800">
                Başvuru bulunamadı
                <a href="/is-ilanlari" className="underline text-mains ml-1">
                  İş İlanlarını İncele
                </a>
              </label>
            </div>
          </div>
        ) : null}

        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          closable={false}
          footer={[
            <div className="flex justify-center items-center pb-4">
              <Button type="primary" key="back" onClick={handleCancel}>
                OK
              </Button>
            </div>,
          ]}
        >
          <div className="flex flex-col gap-2">
            <label className="font-poppins text-base font-semibold text-mains">
              ÇALIŞMA YERİ
            </label>
            <hr />
            {remainingLocations.length > 0 ? (
              <div className="min-h-20 flex justify-center items-center">
                <ul className="list-disc">
                  {remainingLocations.map((loc, idx) => (
                    <li key={idx}>
                      {loc?.city.name} / {loc?.district.name}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className="min-h-20 flex justify-center items-center">
                <p className="text-sm text-black font-poppins poppins-regular">
                  {selectedCoverLetter}
                </p>
              </div>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default List;
