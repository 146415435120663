import { useState, ReactElement, ComponentType } from "react";

interface IWhyItem {
  readonly title: string;
  readonly description: string;
  readonly img: ComponentType<{ size: number }>;
}

function WhyItem(props: IWhyItem): ReactElement {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = (): void => {
    setHovered(true);
  };

  return (
    <div
      className="bg-[#f5f8ff] p-5 rounded-2xl flex flex-col gap-y-5 transition-all duration-300 shadow-sm"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="flex items-center gap-5">
        <div
          className={`flex items-center justify-center ${
            hovered ? "scale-110 transition-all duration-300" : ""
          }`}
        >
          <props.img size={44} />
        </div>
        <div className="flex flex-col gap-2">
          <h2 className="poppins-semibold text-[#2253af]">{props.title}</h2>
          <h6 className="text-gray-400 poppins-medium">{props.description}</h6>
        </div>
      </div>
    </div>
  );
}

export { WhyItem };
