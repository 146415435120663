import React from "react";
import { FiSearch } from "react-icons/fi";
import { cdnUniversityBranchType } from "../../../../../types";
import { Link } from "react-router-dom";
import { getSectionDictionaries } from "../../../../../customHooks/getSectionDictionaries";

const SearchSection: React.FC = () => {
  const [searchResults, setSearchResults] = React.useState<
    cdnUniversityBranchType[]
  >([]);

  const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;
    if (search.length < 3) {
      setSearchResults([]);
      return;
    }
    const limit = 5;
    const result = await getSectionDictionaries({ search, limit });
    console.log(result);
    setSearchResults(result);
  };
  return (
    <div className="flex justify-center section-search-section">
      <div className="flex flex-wrap w-full lg:w-3/4">
        {/* Left Column */}
        <div
          className="w-full px-4 mb-4 space-y-4 lg:w-3/5 lg:px-0"
          style={{ paddingTop: "60px" }}
        >
          <h1 className="text-2xl font-bold text-title font-poppins">
            Bölümler
          </h1>
          <p className="text-paragraph font-poppins">
            380 üniversite bölümü hakkında detaylar burada! Mezunlarının yaptığı
            işlerden eğitim süresine, kariyer fırsatlarından yurt dışı iş
            imkanlarına kadar merak ettiklerini hemen incelemeye başla!.
          </p>
          <div className="relative w-full mb-4 lg:w-3/4 lg:mb-0">
            <input
              type="text"
              placeholder="Aramak için Kelime girin"
              className="w-full px-4 py-3 border border-gray-300 rounded-full shadow focus:outline-none focus:ring-2 focus:ring-blue-500 "
              onChange={handleSearchChange}
            />
            <FiSearch className="absolute text-lg text-gray-400 transform -translate-y-1/2 right-3 top-1/2" />
          </div>
          <div className="w-full bg-white rounded-lg shadow-lg">
            {searchResults.length > 0 && (
              <ul className="w-full py-2 space-y-2">
                {searchResults.map((result) => (
                  <Link
                    key={result.id}
                    className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer block"
                    to={`/bolumler/${result.slug}`}
                  >
                    {result.title}
                  </Link>
                ))}
              </ul>
            )}
          </div>
        </div>

        {/* Right Column */}
        <div className="flex items-center justify-center hidden w-2/5 lg:flex">
          <img
            src="/assets/images/PositionsHeader.png"
            alt="Description"
            className="h-auto max-w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default SearchSection;
