import React from "react";
import Breadcrump from "../../../../components/common/profile/breadcrump";
import Main from "./main";
import { useLocation } from "react-router-dom";

const Body: React.FC = () => {
  const location = useLocation();

  console.log(location.pathname);

  return (
    <div
      className={`'w-full flex flex-col gap-y-6 ${
        location.pathname === "/profilim/mesajlar"
          ? ""
          : "container mx-auto mt-2 pb-10"
      } '`}
    >
      <Breadcrump type="page" pageName="Mesajlar" />
      <Main />
    </div>
  );
};

export default Body;
