import { Children, HTMLAttributes, createElement, useContext } from "react"
import { MultiSelectSearchDropdownContext, multiSelectSearchDropdownContextType } from "."
import classNames from "classnames"


type itemsPropsType = HTMLAttributes<HTMLDivElement> & {

}







export default function Items({
    className,
    children,
    ...props
}: itemsPropsType) {

    const { isOpen }: multiSelectSearchDropdownContextType = useContext(MultiSelectSearchDropdownContext)

    const cn = classNames({
        'absolute -bottom-px left-0 border border-gray-400 rounded-md translate-y-full p-2 flex flex-col gap-2 w-full bg-white max-h-[300px] overflow-auto z-10': true
    })

    const childrenArray = Children.toArray(children)
    
    if (isOpen) {
        return createElement('div', {
            className: `${cn} ${className}`,
            ...props
        }, childrenArray.length > 0? children: <label className="text-gray-500 text-xs p-2">Sonuç bulunamadı</label>)
    } else {
        return null
    }
}