import { IconChevronRight } from "@tabler/icons-react";
import { IoSearch } from "react-icons/io5";
import React, { useState } from "react";
import { Collapse, Tabs } from "antd";
import type { TabsProps } from "antd";
import InputMask from "react-input-mask";
import Swal from "sweetalert2";
import Axios from "../../../services/axios";

const { Panel } = Collapse;

const generalFaqItems = [
  {
    header: "isbul.net’e üye olmak ücretli mi?",
    content: "isbul.net’e üye olmak tamamen ücretsizdir.",
  },
  {
    header: "isbul.net’e üye olmadan uygulamayı kullanabilir miyim?",
    content:
      "Uygulamanın bütün özelliklerini kullanmak için üyelik yapmanız gerekir. Ama üye olmadan da uygulamamızı kullanabilirsiniz.",
  },
  {
    header: "Hesabımı siler ve üyeliğimi iptal edersem ne olur?",
    content:
      "Hesabınızı istediğiniz zaman silebilir ve üyeliğinizi iptal edebilirsiniz. Sonrasında dilediğiniz zaman yeniden üye olmanız gerekir.",
  },
  {
    header: "Mail adresimi ve şifremi değiştirebilir miyim?",
    content:
      "Evet mail adresinizi ve şifrenizi hesabınızın ayarlar sayfasından değiştirebilirsiniz.",
  },
  {
    header:
      "isbul.net’ten herhangi bir e-posta almak istemiyorum ne yapamam gerekiyor?",
    content:
      "Hesap ayarlarım veya ayarlarım sayfasından görmek istemediğiniz bildirimleri kapatabilirsiniz.",
  },
  {
    header: "Telefon doğrulaması neden gereklidir?",
    content:
      "İşverenlerin veya iş arayan adayların size güvenli bir şekilde ulaşabilmesi için telefon doğrulaması gereklidir.",
  },
  {
    header: "Şifre sıfırlama maili gelmiyor ne yapmam gerekir?",
    content:
      "Spam kutunuzu kontrol edebilir veya yardım formumuzu doldurarak bize ulaşabilirsiniz.",
  },
  {
    header: "isbul.net güvenilir mi?",
    content:
      "Sitemizde yazılımsal güvenlik kurallarına uyulmaktadir. KVKK kuralları gereği tüm bilgileriniz saklanmaktadır.",
  },
];

const corporateFaqItems = [
  {
    header: "isbul.net kurumsal üyelik ücretli mi?",
    content: "Sitemizden ücretsiz şekilde kurumsal üyelik oluşturabilirsiniz.",
  },
  {
    header: "Ücretsiz ilan hakkı ne zaman tanımlanır ?",
    content:
      "Kurumsal üyelerimize her ay 1 adet ücretsiz ilan hakkı tanımlanır.",
  },
  {
    header: "İlanım yayınlanmadı ne yapmam gerekiyor?",
    content:
      "İlanlar editörlerimiz tarafından kontrol edildikten sonra onaylanır. İçerik ile ilgili sakıncalı bir durum varsa sizlere mail yoluyla bilgi verilir.",
  },
  {
    header: "İlanlar neden onay işleminden geçiyor?",
    content:
      "İlanların güvenilirliğini ve ilandaki hataları tespit edebilmemiz için bütün ilanlar editörlerimizin onayından geçmektedir.",
  },
  {
    header: "İlan nasıl verilir?",
    content:
      "isbul.net’te ilan vermek ücretsizdir. Hemen kurumsal isbul.net sayfasına girip üyelik işlemlerinizi tamamladıktan sonra, “İlan Yayınla” başlığı adı altında dilediğiniz ilanı ücretsiz olarak yayınlayabilirsiniz. İlanınızı yayına alırken, İlan Bilgilerini, ve tercihen Başvuru Soruları alanlarını doldurmanız yeterlidir.",
  },
  {
    header: "İlan yayınlanma süresi ne kadardır?",
    content:
      "İlanınızın yayında kalma süresi 30 gündür. İstediğiniz takdirde ilanınızı daha erken pasif hale getirebilirsiniz.",
  },
  {
    header: "Engelli iş ilanı nasıl verilir?",
    content:
      "İlan yayınlama “İlan Bilgileri” aşamasında, “Engelli Personel” kutucuğunu işaretleyebilirsiniz. Bu sayede engelli iş ilanları arasında ilanınız listelenir.",
  },
  {
    header: "İlan tarihini nasıl güncelleyebilirim?",
    content:
      "Firma anasayfasında yer alan ilan veya ilanlarınızın tarihini tek tek sağ taraflarında bulunan takvim işaretine tıklayarak iki günde bir güncelleyebilirsiniz. Ayrıca birden fazla ilanınızın tarihini güncellemek istiyorsanız ilan listenizin en altında bulunan “Tüm İlan Tarihlerini Güncelle” sekmesine tıklayarak gerçekleştirebilirsiniz.",
  },
  {
    header: "Şirket bilgileri nasıl gizlenir?",
    content:
      "İlan yayınlama “İlan Bilgileri” aşamasında, “Şirket Adı ve Bilgilerini Gizle” kutucuğunu işaretleyebilirsiniz. Bu seçeneği tercih ettiğinizde ilanınızda firma unvanınız ve logonuz yer almayacaktır.",
  },
  {
    header: "Şifremi unuttum?",
    content:
      "Şifre işlemleriniz için, işveren giriş sayfasında bulunan “Şifremi Unuttum” butonuna tıklayarak yeni şifrenizi talep edebilirsiniz. Mailinize gelen e-postada yer alan 'Şifremi Sıfırla' butonuna tıklayarak açılan sayfada yeni şifrenizi hemen belirleyebilirsiniz.",
  },
  {
    header: "Firma telefon ve mail doğrulaması nasıl yapılır?",
    content:
      "isbul.net adresinden 'Üyelik Formu' alanını eksiksiz bir şekilde doldurup, üyeliğinizi gerçekleştirdikten sonra hesap ayarlarım kısmından, cep telefonunuza ve mail adresinize doğrulama kodu gönderip doğrulama yapabilirsiniz.",
  },
];

const individualFaqItems = [
  {
    header: "Özgeçmiş ne demek?",
    content:
      "Özgeçmiş, bir kişinin eğitim, iş deneyimi, beceriler, ilgi alanları ve kişisel bilgiler gibi önemli bilgilerini içeren bir belge veya dokümandır. Bu belge, genellikle bir iş başvurusu veya akademik başvuru sırasında sunulur ve kişinin yeteneklerini, deneyimlerini ve niteliklerini potansiyel işverenlere veya eğitim kurumlarına tanıtmak amacıyla kullanılır.",
  },
  {
    header: "İşverenler özgeçmiş adını görebilir mi?",
    content:
      "Hayır, işverenler gönderdiğiniz özgeçmişlere verdiğiniz isimleri göremezler.",
  },
  {
    header: "Kaç tane özgeçmiş oluşturabilirim?",
    content:
      "Türkçe ve İngilizce olmak üzere sitemizden iki adet CV oluşturabilirsiniz.",
  },
  {
    header: "Özgeçmişim olmadan iş başvurusu yapabilir miyim?",
    content:
      "Sitemizden özgeçmiş olmadan başvuru yapamazsınız. Başvuru yapmadan önce ücretsiz ve hızlı bir şekilde özgeçmiş oluşturabilirsiniz.",
  },
  {
    header: "Önyazı nedir?",
    content:
      "İş başvurusu veya başka bir türdeki başvurunun ekinde sunulan yazılı bir mektuptur. Özgeçmişin yanında sunulan bu mektup, başvuru sahibinin işverene veya ilgili kuruluşa daha detaylı bir şekilde kendini tanıttığı ve neden bu pozisyon veya fırsat için uygun olduğunu açıkladığı bir belgedir.",
  },
  {
    header: "Cv nasıl indiriliyor?",
    content:
      "Cv indirmek için özgeçmişlerim sayfasındaki işlemler menüsünden cv indir seçeneğine tıklayıp cvnizi kolayca indirebilirsiniz.",
  },
  {
    header: "Referans eklemek neden önemlidir?",
    content:
      "Özgeçmişe referans eklemek, iş başvurularında veya diğer profesyonel fırsatlarda başvuru sahibinin yeteneklerini ve geçmiş performansını doğrulamak amacıyla önemlidir. Referanslar, başvuru sahibinin daha önceki işverenleri, yöneticileri veya akademik danışmanları gibi kişilerden gelen olumlu geri bildirimleri içerebilir.",
  },
  {
    header: "Stajlarımı özgeçmişime nasıl ekleyebilirim?",
    content:
      "İş deneyimleri bölümden ekleyip pozisyonlar seçenekleri arasında stajyer pozisyonunu seçebilirsiniz.",
  },
  {
    header: "Engellilere yönelik ilanlara nasıl ulaşabilirim?",
    content:
      "Filtreleme seçeneklerimizden engelli ilanı filtreleme seçeneğine gelip “sadece engelli ilanlarını göster” filtresini seçebilirsiniz.",
  },
  {
    header: "Geçersiz ilanları nereye bildirebilirim?",
    content:
      "İlanın içindeki şikayet et butonundan ekibimiz ile şikayetinizi ve sebebini paylaşabilirsiniz.",
  },
  {
    header: "Şikayet ettiğim firma kim olduğumu bilecek mi?",
    content:
      "Şikayet ettiğiniz ilanlar veya firmalarda işveren ile adınız paylaşılmaz.",
  },
  {
    header: "Neden özgeçmiş tarihimi güncellemeliyim?",
    content:
      "Özgeçmiş tarihini sıkça güncellemeniz sitemizin aday veri tabanında pozisyon, yaş, aranılan özellikler çerçevesinde arama yapan firmaların listelerinde yer almanızı sağlayacaktır. Bu sayede siz başvurmasanız bile firmalar size ulaşabilecektir. Firmalar genel olarak aday veri tabanında arama yaparken yakın tarihlerde güncellenmiş özgeçmişlere erişmek isterler. Sıkça özgeçmişinizi güncelleyerek iş bulma şansınızı daha da artırabilirsiniz.",
  },
  {
    header: "İş ilanına başvuru nasıl yapılır?",
    content:
      "İş ilanları sekmesinden yeni ve güncel ilanların sıralamasına ulaşabilirsiniz. İlanların üzerine tıklayarak ilan detaylarına gidebilirsiniz. İlana başvurmak için ilanın yanında bulunan “Başvur” butonuna tıklamanız yeterlidir.",
  },
];

const mobileFaqItems = [
  {
    header: "Uygulamayı nasıl indirebilirim?",
    content:
      "Uygulamamızı Google Play Store veya App Store'dan kolayca indirebilirsiniz.",
  },
  {
    header: "Uygulamadan özgeçmiş oluşturabilir miyim?",
    content:
      "Evet, isbul.net uygulamamızdan da kolaylıkla özgeçmiş oluşturabilir ve başvuru gerçekleştirebilirsiniz.",
  },
  {
    header: "Uygulamayı kullanarak ilan verebilir miyim?",
    content: "Evet, uygulamamız ile kolayca ilan oluşturabilirsiniz.",
  },
  {
    header: "Uygulama ücretsiz mi?",
    content: "Uygulamamız tamamen ücretsizdir.",
  },
];

const faqItems: { [key: string]: { header: string; content: string }[] } = {
  1: generalFaqItems,
  2: corporateFaqItems,
  3: individualFaqItems,
  4: mobileFaqItems,
};

const Body: React.FC = () => {
  const axios = new Axios();
  const [activeTab, setActiveTab] = useState<string>("1");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const maxMessageLength = 1000;

  // State for form inputs and error handling
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    fullName: false,
    email: false,
    phone: false,
    message: false,
  });

  const handleTabChange = (key: string) => {
    setActiveTab(key);
    setSearchQuery(""); // reset search query when tab changes
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value.length > maxMessageLength) return;
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
    setErrors((prev) => ({ ...prev, [id]: false }));
  };

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const formatPhoneNumber = (phone: string) => {
    // Remove all non-numeric characters
    return phone.replace(/\D/g, "");
  };

  const handleSubmit = async () => {
    const newErrors = {
      fullName: formData.fullName.trim() === "",
      email: !validateEmail(formData.email),
      phone: formData.phone.trim() === "",
      message: formData.message.trim() === "",
    };
    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error);

    if (hasErrors) {
      let errorMessage = "<span>Eksik Alanlar:</span><br>";
      if (newErrors.fullName)
        errorMessage += "<span style='color: red;'>Ad Soyad</span><br>";
      if (newErrors.email)
        errorMessage += "<span style='color: red;'>E-Posta Adresi</span><br>";
      if (newErrors.phone)
        errorMessage += "<span style='color: red;'>Telefon Numarası</span><br>";
      if (newErrors.message)
        errorMessage += "<span style='color: red;'>Mesajınız</span><br>";
      Swal.fire("Hata", errorMessage, "error");
      return;
    }

    const type = activeTab === "1" ? "question" : "suggestion";

    try {
      await axios.request({
        type: "post",
        endpoint: "help/send-message",
        payload: {
          name: formData.fullName,
          email: formData.email,
          phone_code: "+90",
          phone: formatPhoneNumber(formData.phone),
          message: formData.message,
          type: type,
        },
      });
      Swal.fire("Başarılı", "Mesaj başarıyla iletildi", "success");
      setFormData({
        fullName: "",
        email: "",
        phone: "",
        message: "",
      });
    } catch (error: any) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        Array.isArray(error.response.data.errors.message)
      ) {
        const errorMessage = error.response.data.errors.message.join(", ");
        console.log(errorMessage);
        Swal.fire("Hata", errorMessage, "error");
      } else {
        console.log(error);
        Swal.fire("Hata", "Bilinmeyen bir hata oluştu.", "error");
      }
    }
  };

  const filteredItems = faqItems[activeTab].filter(
    (item) =>
      item.header.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.content.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const renderFaqItems = (items: { header: string; content: string }[]) => (
    <Collapse
      expandIconPosition="right"
      bordered={false}
      accordion
      className="custom-collapse custom-collapse-help"
    >
      {items.map((item, index) => (
        <Panel header={item.header} key={index}>
          <p>{item.content}</p>
        </Panel>
      ))}
    </Collapse>
  );

  const tabItems: TabsProps["items"] = [
    {
      label: "Genel",
      key: "1",
      children: <div className="mt-4">{renderFaqItems(filteredItems)}</div>,
    },
    {
      label: "Kurumsal",
      key: "2",
      children: <div className="mt-4">{renderFaqItems(filteredItems)}</div>,
    },
    {
      label: "Bireysel",
      key: "3",
      children: <div className="mt-4">{renderFaqItems(filteredItems)}</div>,
    },
    {
      label: "Mobil",
      key: "4",
      children: <div className="mt-4">{renderFaqItems(filteredItems)}</div>,
    },
  ];

  const contactTabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Problem / Soru",
      children: (
        <div className="flex justify-center items-center w-full mt-4">
          <div className="w-full lg:w-3/4 px-4 mb-6 lg:mb-0">
            <div className="mb-6">
              <label
                className="block mb-2 text-[#63636c] text-[17px]"
                htmlFor="fullName"
              >
                Ad Soyad <span className="text-red-500">*</span>
              </label>
              <input
                className={`w-full px-3 py-3 border ${
                  errors.fullName
                    ? "border-red-500 bg-red-100"
                    : "border-[#2253af] bg-gray-50"
                } rounded-md`}
                id="fullName"
                type="text"
                value={formData.fullName}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-6">
              <label
                className="block mb-2 text-[#63636c] text-[17px]"
                htmlFor="email"
              >
                E-Posta Adresiniz <span className="text-red-500">*</span>
              </label>
              <input
                className={`w-full px-3 py-3 border ${
                  errors.email
                    ? "border-red-500 bg-red-100"
                    : "border-[#2253af] bg-gray-50"
                } rounded-md`}
                id="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-6">
              <label
                className="block mb-2 text-[#63636c] text-[17px]"
                htmlFor="phone"
              >
                Telefon Numaranız <span className="text-red-500">*</span>
              </label>
              <div className="flex justify-center items-center gap-4">
                <input
                  className={`w-full px-3 py-3 border placeholder:text-blue-700 text-blue-700 border-[#2253af] bg-gray-50 rounded-md`}
                  id="phoneCode"
                  type="tel"
                  value="+90 Türkiye"
                  readOnly
                />
                <InputMask
                  mask="0(599) 999 99 99"
                  className={`w-full px-3 py-3 border ${
                    errors.phone
                      ? "border-red-500 bg-red-100"
                      : "border-[#2253af] bg-gray-50"
                  } rounded-md`}
                  id="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="mb-6">
              <label
                className="block mb-2 text-[#63636c] text-[17px]"
                htmlFor="message"
              >
                Mesajınız <span className="text-red-500">*</span>
              </label>
              <textarea
                className={`w-full px-3 py-2 border ${
                  errors.message
                    ? "border-red-500 bg-red-100"
                    : "border-[#2253af] bg-gray-50"
                } rounded-md`}
                id="message"
                rows={4}
                value={formData.message}
                onChange={handleInputChange}
              ></textarea>
              <div className="ml-1 font-poppins poppins-medium text-sm">
                {maxMessageLength - formData.message.length}
              </div>
            </div>
            <div className="w-full mx-auto mb-4">
              <button
                className="flex justify-center font-poppins poppins-medium text-base items-center w-full text-center text-white bg-[#2253af] rounded-full py-2 min-h-14"
                onClick={handleSubmit}
              >
                Gönder
              </button>
            </div>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Önerim Var",
      children: (
        <div className="flex justify-center items-center w-full mt-4">
          <div className="w-full lg:w-3/4 px-4 mb-6 lg:mb-0">
            <div className="mb-6">
              <label
                className="block mb-2 text-[#63636c] text-[17px]"
                htmlFor="fullName"
              >
                Ad Soyad <span className="text-red-500">*</span>
              </label>
              <input
                className={`w-full px-3 py-3 border ${
                  errors.fullName
                    ? "border-red-500 bg-red-100"
                    : "border-[#2253af] bg-gray-50"
                } rounded-md`}
                id="fullName"
                type="text"
                value={formData.fullName}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-6">
              <label
                className="block mb-2 text-[#63636c] text-[17px]"
                htmlFor="email"
              >
                E-Posta Adresiniz <span className="text-red-500">*</span>
              </label>
              <input
                className={`w-full px-3 py-3 border ${
                  errors.email
                    ? "border-red-500 bg-red-100"
                    : "border-[#2253af] bg-gray-50"
                } rounded-md`}
                id="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-6">
              <label
                className="block mb-2 text-[#63636c] text-[17px]"
                htmlFor="phone"
              >
                Telefon Numaranız <span className="text-red-500">*</span>
              </label>
              <div className="flex justify-center items-center gap-4">
                <input
                  className={`w-full px-3 py-3 border placeholder:text-blue-700 text-blue-700 border-[#2253af] bg-gray-50 rounded-md`}
                  id="phoneCode"
                  type="tel"
                  value="+90 Türkiye"
                  readOnly
                />
                <InputMask
                  mask="0(599) 999 99 99"
                  className={`w-full px-3 py-3 border ${
                    errors.phone
                      ? "border-red-500 bg-red-100"
                      : "border-[#2253af] bg-gray-50"
                  } rounded-md`}
                  id="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="mb-6">
              <label
                className="block mb-2 text-[#63636c] text-[17px]"
                htmlFor="message"
              >
                Mesajınız <span className="text-red-500">*</span>
              </label>
              <textarea
                className={`w-full px-3 py-2 border ${
                  errors.message
                    ? "border-red-500 bg-red-100"
                    : "border-[#2253af] bg-gray-50"
                } rounded-md`}
                id="message"
                rows={4}
                value={formData.message}
                onChange={handleInputChange}
              ></textarea>
              <div className="ml-1 font-poppins poppins-medium text-sm">
                {maxMessageLength - formData.message.length}
              </div>
            </div>
            <div className="w-full mx-auto mb-4">
              <button
                className="flex justify-center font-poppins poppins-medium text-base items-center w-full text-center text-white bg-[#2253af] rounded-full py-2 min-h-14"
                onClick={handleSubmit}
              >
                Gönder
              </button>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="bg-white border-b lg:block hidden">
        <div className="mx-auto container">
          <div
            aria-label="Breadcrumb"
            className="flex items-center justify-start py-4"
          >
            <a
              href="/"
              aria-label="Anasayfa"
              className="poppins-regular text-sm"
            >
              Ana Sayfa
            </a>
            <IconChevronRight className="text-blue-700 mx-1" size={18} />
            <span className="poppins-regular text-sm">S.S.S</span>
          </div>
        </div>
      </div>

      <div className="bg-gray-50 border-b">
        <div className="mx-auto container">
          <div className="flex flex-col justify-center items-center w-full py-10 font-poppins text-center">
            <h1 className="text-mains poppins-medium text-2xl">
              Merhaba! Sana nasıl yardımcı olabiliriz?
            </h1>

            <p className="poppins-regular text-gray-500 text-base mt-1 mb-4 text-center">
              isbul.net ile ilgili aklına takılan soruların cevaplarını bu
              sayfada bulabilirsin.
            </p>

            <div className="flex justify-center items-center relative">
              <input
                className="rounded-full min-w-[350px]  lg:min-w-[615px] min-h-[50px] pl-6 font-poppins poppins-regular shadow-md outline-none border"
                placeholder="Aramak için kelime girin"
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <button className="absolute right-6">
                <IoSearch className="text-mains" size={24} />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 py-6">
        <div className="container mx-auto">
          <div className="flex justify-start items-center">
            <h2 className="font-poppins poppins-medium text-2xl text-mains px-4 md:px-0">
              Yardım
            </h2>
          </div>

          <div className="help mt-4 px-4 md:px-0">
            <Tabs
              tabPosition="left"
              items={tabItems}
              onChange={handleTabChange}
            />
          </div>
        </div>
      </div>

      <div className="bg-white py-6">
        <div className="container mx-auto">
          <div className="flex justify-center items-center w-full">
            <h2 className="font-poppins poppins-medium text-3xl text-mains">
              Bize Ulaşın
            </h2>
          </div>

          <div className="mt-8">
            <Tabs
              className="help-2"
              defaultActiveKey="1"
              items={contactTabItems}
              onChange={handleTabChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Body;
