import { LabelHTMLAttributes, useContext } from "react";
import { RadioButtonContext } from ".";

/* 
FIXME: Hata mesajı kişiselleştirme seçeneğinin olmaması sebebiyle düzenleme yapıldı
const Error = ({ className }: { className?: string }) => {
	const { error } = useContext(RadioButtonContext);

	return error ? (
		<div className={`${className} text-red-500 text-sm`}>Bir seçim yapınız</div>
	) : null;
};

export default Error; */

type errorPropsType = LabelHTMLAttributes<HTMLLabelElement>

export default function Error({
	className,
	...props
}: errorPropsType) {
	
	const { error } = useContext(RadioButtonContext);

	if (error) {
		return(
			<label className={`${className} text-red-600 text-xs`} {...props}></label>
		)
	} else {
		return null
	}
	
}