import React, { useState, useEffect, useRef } from "react";
import { PiDotsThreeVertical, PiWarningCircle } from "react-icons/pi";
import { useAppSelector } from "../../../../../../store/store";
import Axios from "../../../../../../services/axios";
import {
  dbUserInterviewType,
  getUserInterviewsResponseType,
} from "../../../../../../types";
import InterviewListItem from "./components/InterviewListItem";
import { CiSearch } from "react-icons/ci";

const axios = new Axios();
interface Interview {
  name: string;
  type: "Online" | "Local";
  status: "accept" | "PENDING" | "cancel";
}

const fakeInterviewData: Interview[] = [
  {
    name: "Ethem",
    type: "Online",
    status: "PENDING",
  },
  {
    name: "Ahmet",
    type: "Local",
    status: "accept",
  },
  {
    name: "Ömer",
    type: "Online",
    status: "cancel",
  },
  // Diğer örnekler buraya eklenebilir
];

const getStatusColor = (status: "accept" | "PENDING" | "cancel") => {
  switch (status) {
    case "accept":
      return "text-green-600";
    case "PENDING":
      return "text-blue-600";
    case "cancel":
      return "text-red-600";
    default:
      return "";
  }
};

const List: React.FC = () => {
  const cvId = useAppSelector((state) => state.cvDetail);
  //console.log("id", cvId);
  const [data, setData] = useState<any[]>([cvId]);
  const [interviews, setInterviews] = useState<dbUserInterviewType[]>([]);

  const divRef = useRef<HTMLDivElement>(null);
  const cvRef = useRef<HTMLDivElement>(null);

  const [isPopupOpen, setIsPopupOpen] = useState<boolean[]>(
    fakeInterviewData.map(() => false)
  );

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setIsPopupOpen((prev) => prev.map(() => false));
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  async function getInterviews() {
    try {
      const response: getUserInterviewsResponseType =
        await axios.userAuthRequest({
          endpoint: "user/profilim/interview/get-interviews",
          type: "get",
          payload: {},
        });

      if (response.data && response.data.interviews) {
        setInterviews(response.data.interviews);
      } else {
        setInterviews([]);
      }
    } catch (error) {
      console.log(`Interviews fetch failed. Error:${error}`);
    }
  }

  /* useEffect(() => {
    console.log('interviews', interviews)
  }, [interviews]) */

  useEffect(() => {
    getInterviews();
  }, []);

  return (
    <>
      <div className="w-full flex flex-col item-center px-4 h-auto">
        {interviews.length > 0 ? (
          <div className="w-full flex item-center justify-end h-10 mb-4">
            <div className="w-full  h-full border items-center px-1 gap-x-1 flex rounded-md border-gray-300">
              <CiSearch className="text-gray-500 text-lg" />
              <input
                placeholder="Mülakatlarımda Ara"
                className="w-full pr-2 placeholder-gray-400 text-gray-500 outline-none text-xs poppins-medium"
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        {interviews.length > 0 ? (
          <div className="md:flex hidden  justify-between items-center w-full border-b py-2">
            <div className="flex md:flex-row flex-col justify-between items-center w-full">
              <div className="flex  items-center w-full">
                <span className="text-xs poppins-semibold">Firma</span>
              </div>

              <div className="flex items-center justify-start w-full">
                <span className="text-xs poppins-semibold">Mülakat Yeri</span>
              </div>
            </div>

            <div className="flex items-center justify-end w-full">
              <span className="text-xs poppins-semibold">Durum</span>
            </div>

            <div className="flex items-center justify-end w-full">
              <span className="text-xs poppins-semibold">Mesaj</span>
            </div>
            <div className="flex items-center justify-end w-full">
              <span className="text-xs poppins-semibold">İşlemler</span>
            </div>
          </div>
        ) : (
          <div className="flex flex-row items-center bg-white border border-200 rounded-md">
            <span className="flex h-full p-4 bg-red-600 rounded-l-md">
              <PiWarningCircle className="text-3xl text-white" />
            </span>
            <span className="flex flex-row items-center text-sm text-gray-700 h-full pl-4 font-poppins poppins-medium">
              Firmaların size gönderdiği mülakat taleplerini bu alandan
              inceleyebilirsiniz.<br>
              </br> Şu anda oluşturulmuş mülakat talebiniz
              bulunmamaktadır.
            </span>
        
          </div>
        )}

        {/* <div className="flex flex-col gap-y-1 w-full justify-between items-center">
          {fakeInterviewData.map((interview, index) => (
            <div
              key={index}
              className="flex justify-between items-center  w-full border-b py-2 mt-2"
            >
              <div className="flex md:flex-row flex-col justify-between items-center w-full gap-1 md:gap-0 basis-2/5">
                <div className="flex items-center justify-start basis-2/4 w-full flex-col md:flex-row">
                  <a className="w-full text-start" href="/">
                    <span className="text-sm md:text-xs poppins-semibold text-mains underline">
                      {interview.name}
                    </span>
                  </a>

                  <div className="flex  md:hidden items-center justify-start md:justify-center w-full basis-1/5">
                    <span
                      className={`text-xs poppins-semibold mr-4 ${getStatusColor(
                        interview.status
                      )}`}
                    >
                      {interview.status === "cancel"
                        ? "İptal Edildi"
                        : interview.status === "accept"
                        ? "Kabul Edildi"
                        : "Bekleniyor"}
                    </span>
                  </div>
                </div>

                <div className="hidden md:flex items-center justify-start w-full">
                  <span className="text-xs poppins-semibold text-gray-600">
                    <p>{interview.type}</p>
                    <p>
                      {interview.type == "Local" && "Adres: "}
                      {interview.type === "Local" && (
                        <>
                          <span className="text-gray-500">Neosho 995 Tail</span>
                        </>
                      )}
                    </p>
                    <p className="whitespace-nowrap text-xs text-gray-500 font-poppins basis-3/4 w-full flex justify-start items-center">
                      27.07.2024 10:30
                    </p>
                  </span>
                </div>
              </div>

              <div className="hidden md:flex items-center justify-start md:justify-center w-full basis-1/5">
                <span
                  className={`text-xs poppins-semibold mr-4 ${getStatusColor(
                    interview.status
                  )}`}
                >
                  {interview.status === "cancel"
                    ? "İptal Edildi"
                    : interview.status === "accept"
                    ? "Kabul Edildi"
                    : "Bekleniyor"}
                </span>
              </div>

              <div className="hidden md:flex items-center justify-center w-full basis-1/5">
                <span className="text-xs poppins-semibold text-gray-600 ml-6 xl:ml-12">
                  Ss
                </span>
              </div>

              <div className="text-sm relative  flex justify-end items-center md:w-full basis-1/5">
                <p className="whitespace-nowrap text-xs text-gray-500 font-poppins basis-3/4 w-full flex justify-end items-center">
                  27.07.2024 10:30
                </p>

                <button
                  className="flex justify-end text-end basis-1/4"
                  type="button"
                  onClick={() =>
                    setIsPopupOpen((prev) =>
                      prev.map((value, idx) => (idx === index ? !value : false))
                    )
                  }
                >
                  <PiDotsThreeVertical className="text-gray-900 text-xl" />
                </button>
                {isPopupOpen[index] && (
                  <div
                    ref={divRef}
                    className="absolute -bottom-4 right-0 translate-y-full z-40"
                  >
                    <div className="relative shadow-[0_-2px_30px_5px_rgba(26,26,26,0.3)] rounded-b-lg rounded-tl-lg">
                      <div className="triangle-white top-0 -translate-y-full absolute right-0" />
                      <div className="bg-white p-4 flex flex-col gap-2 rounded-b-lg rounded-tl-lg">
                        <a
                          href="#"
                          className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap"
                        >
                          Katılacağım
                        </a>
                        <hr />
                        <a
                          href="#"
                          className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap"
                        >
                          Katılmayacağım
                        </a>
                        <hr />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div> */}
        <div className="flex flex-col gap-y-1 w-full justify-between items-center">
          {interviews.map((interview) => {
            return (
              <InterviewListItem
                getInterviews={getInterviews}
                key={`interviewListItem${interview.id}`}
                interview={interview}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default List;
