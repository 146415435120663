import * as React from "react";
import DashboardHeader from "./dashboard/index";
import HeaderMain from "./main/index";
import { useAppSelector } from "../../../store/store";
import { ICompanyUser } from "../../../types/ICompanyUser";
import { useCookie } from "../../../hooks/useCookie";
import { CookieName } from "../../../constants/enums";

// !! DRAFT: for user adjustments still need to be done.

function Header() {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const { getCookie } = useCookie();
  const userTokenCookie = getCookie(CookieName.UserToken);
  const userToken = userTokenCookie ? userTokenCookie : "";
  // TODO: add userData to the redux store
  const companyUserTokenCookie = getCookie(CookieName.CompanyUserToken);
  const companyUserToken = companyUserTokenCookie ? companyUserTokenCookie : "";
  const companyUser = useAppSelector(
    (state) => state.companyUser.companyUser as ICompanyUser
  );

  React.useEffect(() => {
    if (userToken || companyUserToken || companyUser) {
      setIsAuthenticated(true);
    }
  }, [userToken, companyUserToken, companyUser]);

  return <>{isAuthenticated ? <DashboardHeader /> : <HeaderMain />}</>;
}

export default Header;
