import React from "react";

interface IHamburgerIcon {
  state: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  iconColor?: string
}

const HamburgerIcon: React.FC<IHamburgerIcon> = (props: IHamburgerIcon) => {
  return (
    <div
      id="hamburgerMenuIcon"
      className="relative lg:hidden"
      onClick={() => props.setState(!props.state)}
    >
      <div
        className={`h-0.5 w-6 my-1 rounded-full ${props.iconColor? props.iconColor: 'bg-black'} transition ease transform duration-300 ${
          props.state ? "mb-[7.75px]" : ""
        } ${props.state ? "rotate-45 translate-y-2.5" : ""}`}
      />
      <div
        className={`h-0.5 w-6 my-1 rounded-full ${props.iconColor? props.iconColor: 'bg-black'} transition ease transform duration-300 ${
          props.state ? "opacity-0" : "opacity-100"
        }`}
      />
      <div
        className={`h-0.5 w-6 my-1 rounded-full ${props.iconColor? props.iconColor: 'bg-black'} transition ease transform duration-300 ${
          props.state ? "mt-[7.75px]" : ""
        } ${props.state ? "-rotate-45 -translate-y-2.5" : ""}`}
      />
    </div>
  );
};

export default HamburgerIcon;
