import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  MdOutlineSearch,
  MdOutlineManageSearch,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { IconMapPin, IconSearch, IconChevronDown } from "@tabler/icons-react";
import { Link, useNavigate } from "react-router-dom";
import { TiArrowSortedUp } from "react-icons/ti";
import usePartialBold from "../../../../../customHooks/usePartialBold";
import {
  jobsPageContextType,
  JobsPageContext,
} from "../../../../../containers/job-page-container/main/index";
import { useContext } from "react";
import cdnServices from "../../../../../services/cdn/index";
import { addOrUpdateQueryParam } from "../../../../../hooks/useAddOrUpdateQueryParam";
import { useLocation } from "react-router-dom";
import { LuLoader2 } from "react-icons/lu";
import PositionSearch from "../../components/PositionSearch";
import CitySearch from "../../components/CitySearch";
import Cookies from "js-cookie";
import { IItem } from "../../../../job-page-container/main/filter-section-content/FilterSectionContentCopy";
import Swal from "sweetalert2";
import Axios from "../../../../../services/axios";
import services from "../../../../../services/login-services";
import { BiSolidMapPin } from "react-icons/bi";

const categories = [
  {
    id: "1",
    name: "Satış Danışmanı",
    link: "/is-ilanlari?positions=[92]",
  },
  {
    id: "2",
    name: "Kasiyer",
    link: "/is-ilanlari?positions=[3898]",
  },
  {
    id: "3",
    name: "Muhasebe",
    link: "/is-ilanlari?positions=[5055]",
  },
  {
    id: "4",
    name: "Proje Yöneticisi",
    link: "/is-ilanlari?positions=[8557]",
  },
  {
    id: "5",
    name: "Mağaza Elemanı",
    link: "/is-ilanlari?positions=[4590]",
  },
  // {
  //   id: "6",
  //   name: "Garson ",
  //   link: "/is-ilanlari?positions=[2440]",
  // },
];

export type positionStateTypes = {
  positionId: number | null;
  companyId: number | null;
  searchTerm: string;
  isSearching: boolean;
};

const SearchBar: React.FC = () => {
  const navigate = useNavigate();
  const [cityId, setCityId] = useState<number | null>(null);
  const [cityText, setCityText] = useState<string>("");
  const [isDistrict, setIsDistrict] = useState<boolean>(false);
  const [position, setPosition] = useState<positionStateTypes>({
    positionId: null,
    companyId: null,
    searchTerm: "",
    isSearching: false,
  });

  const userToken = Cookies.get("userToken");
  const companyToken = Cookies.get("companyUserToken");

  const handleSearch = () => {
    let paramsArr = [];
    if (position.positionId) {
      paramsArr.push(
        `positions=[${position.positionId}]&pht=${position.searchTerm}`
      );
    }
    if (position.companyId) {
      paramsArr.push(`company_ids=[${position.companyId}]`);
    }
    if (position.isSearching) {
      paramsArr.push(
        `search=${position.searchTerm}&pht=${position.searchTerm}`
      );
    }

    if (cityId) {
      if (isDistrict) {
        paramsArr.push(`districts=[${cityId}]&lht=${cityText}`);
      } else {
        paramsArr.push(`cities=[${cityId}]&lht=${cityText}`);
      }
    }
    navigate("/is-ilanlari?" + paramsArr.join("&"));
  };

  const handlePositionChange = (arg: positionStateTypes) => {
    setPosition(arg);
  };

  const handleCityChange = (arg: any) => {
    if (arg.id === null) {
      setCityId(null);
    } else {
      setCityId(arg.id);
      setCityText(arg.title);
      if (arg.type === "city") {
        setIsDistrict(false);
      } else {
        setIsDistrict(true);
      }
    }
  };

  const axios = new Axios();
  const fireNearbyError = (isData = false, isCompany = false) => {
    if (isData) {
      if (isCompany) {
        Swal.fire({
          title: "Hata",
          text: "Hesap ayarlarınızdan firma adres bilgilerinizi güncelleyerek yakınımdaki ilanlar özelliğinden yararlanabilirsiniz",
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Hata",
          text: "Yakınınızdaki iş ilanlarını görebilmek için şehir ve ilçe bilgisini giriniz",
          icon: "error",
        });
      }
    } else {
      Swal.fire({
        title: "Hata",
        text: "Lokasyon verisi alınırken bir hata oluştu",
        icon: "error",
      });
    }
  };

  const handleNewrbyAdverts = () => {
    if (userToken) {
      axios
        .userAuthRequest({
          type: "get",
          endpoint: "user/profilim/cv",
          payload: {},
        })
        .then((response: any) => {
          axios
            .userAuthRequest({
              type: "get",
              endpoint:
                "user/profilim/cv/contact/index?cv_id=" + response.data.data.id,
              payload: {},
            })
            .then((response: any) => {
              if (
                response.data.data.city_list &&
                response.data.data.district_list
              ) {
                navigate(
                  `is-ilanlari?cities=[${response.data.data.city_list.id}]&districts=[${response.data.data.district_list.id}]`
                );
              } else {
                fireNearbyError(true);
              }
            })
            .catch(() => {
              fireNearbyError();
            });
        })
        .catch(() => {
          fireNearbyError();
        });
    } else if (companyToken) {
      services
        .checkTokenCompany()
        .then((response: any) => {
          if (
            response.data.company.company_detail.city_id &&
            response.data.company.company_detail.district_id
          ) {
            navigate(
              `is-ilanlari?cities=[${response.data.company.company_detail.city_id}]&districts=[${response.data.company.company_detail.district_id}]`
            );
          } else {
            fireNearbyError(true, true);
          }
        })
        .catch(() => {
          fireNearbyError();
        });
    } else {
      fireNearbyError();
    }
  };

  return (
    <div className="relative flex py-10 shadow-lg lg:h-auto lg:justify-center lg:items-center md:py-40 bg-mains md:h-auto">
      <img
        className="hero-bg lg:hidden block opacity-5"
        src="/assets/images/slider.png"
        alt="bg"
      />
      <img
        className="hero-bg hidden lg:block"
        src="/assets/images/home-bg-default.png"
        alt="bg"
      />
      <img
        className="absolute -right-[210px] max-h-[525px] bottom-0  hidden 2xl:block people-img"
        src="/assets/images/home-people.png"
        alt=""
      />
      <div className="container w-full h-auto mx-auto">
        <div className="flex gap-x-20">
          <div className="flex flex-col w-full px-4 py-2 gap-y-4 2xl:w-4/5">
            <div className="flex flex-col items-start gap-y-1 lg:items-start z-50">
              <div className="h-auto">
                <h1 className="text-white poppins-semibold text-md md:text-xl lg:text-2xl">
                  İş ilanları isbul.net'te!
                </h1>
              </div>
            </div>

            <div className="block">
              <div className="flex lg:flex-row flex-col gap-x-0.5 max-lg:gap-y-1 lg:h-14 ">
                <div className={"lg:w-2/5 max-md:h-10 h-14"}>
                  <PositionSearch
                    onChange={(arg) => {
                      handlePositionChange(arg);
                    }}
                  />
                </div>
                <div className={"flex lg:w-3/5 max-md:mt-3"}>
                  <div className={"w-2/3 max-md:h-10  h-14"}>
                    <CitySearch
                      onChange={(arg) => {
                        handleCityChange(arg);
                      }}
                    />
                  </div>
                  <div className="w-1/3 max-md:h-10  h-14 z-10">
                    <button
                      onClick={() => {
                        handleSearch();
                      }}
                      className="flex items-center justify-center w-full h-full max-md:bg-[#DE5B2B] bg-zinc-800 gap-x-2 rounded-r-md"
                    >
                      <IconSearch className="text-gray-50" />
                      <span className="text-lg uppercase poppins-light text-gray-50">
                        İş Bul
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <Link
                to="is-ilanlari"
                className="flex items-center justify-end py-2 text-white gap-x-2"
              >
                <MdOutlineManageSearch size={25} className="z-50" />
                <span className="text-sm poppins-regular  z-50">
                  Detaylı Ara
                </span>
              </Link>
            </div>

            <div className="hidden lg:block xl:block ">
              <div className="flex flex-col px-4 gap-y-8 lg:px-0 ">
                <div className="grid md:grid-cols-4 lg:grid-cols-6 xl:grid-col-8  gap-x-2 gap-y-2 w-full max-w-[920px] h-auto z-10">
                  {(userToken || companyToken) && (
                    <div
                      onClick={() => {
                        handleNewrbyAdverts();
                      }}
                      className="cursor-pointer relative py-1.5 bg-transparent items-center flex justify-center border border-white rounded-2xl mr-2 mb-2 transition duration-300 pl-1 pr-1 overflow-hidden group"
                    >
                      <span className="flex items-center text-white font-poppins poppins-medium text-md text-[12px] relative z-10">

                        <  BiSolidMapPin className=" lg:pl-2"  /> <span className="pl-1" > Yakındaki İlanlar </span> 

                      </span>
                      <span className="absolute inset-0 bg-orange-500 w-0 h-full group-hover:w-full transition-all duration-500"></span>
                    </div>
                  )}
                  {categories.map((category, index) => (
                    <Link
                      key={category.id}
                      to={category.link}
                      className="relative py-1.5 bg-transparent items-center flex justify-center border border-white rounded-2xl mr-2 mb-2 transition duration-300 pl-1 pr-1 overflow-hidden group"
                    >
                      <span className="text-white font-poppins poppins-medium text-md text-[12px] relative z-10">
                        {category.name}
                      </span>
                      <span className="absolute inset-0 bg-orange-500 w-0 h-full group-hover:w-full transition-all duration-500"></span>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
