import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "../../../../../services/axios";
import ReferenceForm from "./components/ReferenceForm";
import { dbUserReferenceType } from "../../../../../types";
import { Button } from "../../../../../components/isbul-ui";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { PiWarningCircle } from "react-icons/pi";
import { useContext } from "react";
import { CvLayoutContext, cvLayoutContextType } from "..";

const axios = new Axios();

export default function Reference() {
  const { isEnglish }: cvLayoutContextType = useContext(CvLayoutContext);

  const navigate = useNavigate();

  const params = useParams();

  const cvId = params.slug;

  const [references, setReferences] = useState<dbUserReferenceType[]>([]);
  const [referencesLength, setReferencesLength] = useState<number>(1);
  const [isAddClicked, setIsAddClicked] = useState<boolean>(false);

  async function getAllReferences() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/ref/index?cv_id=${cvId}`,
        type: "get",
        payload: {},
      });

      if (response && response.data && response.data.references) {
        setReferences(response.data.references);
        setReferencesLength(response.data.references.length);
      }
    } catch (error) {
      console.log(`References fetch failed. Error: ${error}`);
    }
  }

  //Initial scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  useEffect(() => {
    getAllReferences();
  }, []);
  return (
    <div className="flex flex-col w-full gap-y-4 lg:gap-y-0 rounded-md bg-white pb-20 pt-2 lg:pb-5">
      {/* <span className="hidden lg:flex poppins-semibold text-sm text-mains py-4 border-b border-mains px-4">
      Referanslar
      </span> */}

      {/* Empty reference form */}
      <ReferenceForm
        reference={null}
        getAllReferences={getAllReferences}
        referencesLength={referencesLength}
        isAddClicked={isAddClicked}
        setIsAddClicked={setIsAddClicked}
      />

      {/* No reference warning */}
      {references.length == 0 && (
        <div className="flex flex-row gap-4 m-4 border border-gray-200 rounded-md">
          <div className="flex p-4 bg-red-600 rounded-l-md items-center justify-center">
            <PiWarningCircle className="text-3xl text-white" />
          </div>
          <div className="flex flex-col gap-1 py-2 pr-1">
            <p className="font-poppins font-semibold text-gray-700 text-sm">
              {isEnglish
                ? "You don't have any references added yet."
                : "Henüz eklenmiş bir referansınız yok."}
            </p>
            {isEnglish ? (
              <p className="font-poppins text-sm text-gray-700 font-semibold">
                You can add new reference with{" "}
                <button
                  className="text-mains underline"
                  onClick={() => setIsAddClicked(true)}
                >
                  Add Reference
                </button>{" "}
                button.
              </p>
            ) : (
              <p className="font-poppins text-sm text-gray-700 font-semibold">
                <button
                  className="text-mains underline"
                  onClick={() => setIsAddClicked(true)}
                >
                  Referans Ekle
                </button>{" "}
                butonuna tıklayarak hemen yeni bir referans ekleyebilirsiniz.
              </p>
            )}
          </div>
        </div>
      )}

      {/* References mapping */}
      {references.map((reference) => {
        return (
          <div key={`referenceForm${reference.id}`}>
            <hr />
            <ReferenceForm
              reference={reference}
              getAllReferences={getAllReferences}
            />
          </div>
        );
      })}

      <div className="px-0 lg:px-4 my-2">
        <hr />
      </div>

      {/* Prev-Next buttons */}
      <div className="flex flex-row items-center justify-between px-4">
        <Button
          type="button"
          variant="secondary"
          size="sm"
          onClick={() => navigate(`/profilim/cv/duzenle/${cvId}/is-tercihleri`)}
        >
          <Button.Icon>
            <IoChevronBack />
          </Button.Icon>
          {isEnglish ? "Go Back" : "Geri Dön"}
        </Button>

        <Button
          type="button"
          size="sm"
          onClick={() =>
            navigate(`/profilim/cv/duzenle/${cvId}/sinavlar-sertifikalar`)
          }
        >
          {isEnglish ? "Continue" : "Devam Et"}
          <Button.Icon>
            <IoChevronForward />
          </Button.Icon>
        </Button>
      </div>
    </div>
  );
}
