import Card from "../content/Card";
import { ReportPieChart } from "../content/ReportPieChart";
import Axios from "../../../../services/axios";
import { useEffect, useState } from "react";
import { FaBan } from "react-icons/fa";

type Props = {};

interface Report {
  [key: string]: any[]; // Add a string index signature
  city: any[];
  age: any[];
  experience: any[];
  education_level: any[];
}

const axios = new Axios();

const ReportPage = (props: Props) => {
  // reports state
  // 'city', 'age', 'experience', 'education-level'
  const reportsTypes = [
    {
      title: "İl Bazlı Başvuru Grafiği",
      type: "city",
    },
    {
      title: "Yaş Aralığı Başvuru Grafiği",
      type: "age",
    },
    {
      title: "Deneyim Seviyesine Göre Başvuru Grafiği",
      type: "experience",
    },
    {
      title: "Eğitim Seviyesine Göre Başvuru Grafiği",
      type: "education-level",
    },
  ];
  const [reports, setReports] = useState<Report>({
    city: [],
    age: [],
    experience: [],
    education_level: [],
  });

  const getReport = async (reportType: string) => {
    console.log(reportType);
    try {
      const response = await axios.authRequest({
        endpoint: `companies/report/get-${reportType}-apply-report`,
        type: "get",
        payload: {},
      });
      const reportKey = reportType.replace("-", "_") + "_apply_report";
      setReports((prev: Report) => ({
        ...prev,
        [reportType.replace("-", "_")]: response.data[reportKey] || [],
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    reportsTypes.forEach((reportType) => {
      getReport(reportType.type);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-3">
      {reportsTypes.map((report, index) => (
        <Card className="flex flex-col justify-center items-center" key={index}>
          <h2 className="w-full text-xl font-bold">{report.title}</h2>
          {reports[report.type.replace("-", "_")].length > 0 ? (
            <div className="max-w-96">
              <ReportPieChart report={reports[report.type.replace("-", "_")]} />
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center gap-2">
              <FaBan className="text-6xl text-gray-500 mt-4" />
              <span className="text-sm text-gray-500 font-poppins poppins-medium">
                Listede kayıtlı rapor bulunmamaktadır.
              </span>
            </div>
          )}
        </Card>
      ))}
    </div>
  );
};

export default ReportPage;
