import React from "react";
import { FiMapPin } from "react-icons/fi";
import {
  FaFacebookF,
  FaWhatsapp,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";


// type CompanyDataType = {
//   logo?:string;
//   company_name?:string;
//   facebook?:string;
//   instgram?:string;
//   twitter?:string;
//   whatsapp?:string;
//   linkedin?:string;
// }


const Header: React.FC<any> = ({companyData}) => {

  return (
    <div className="bg-[#F9FBE7] pt-8 pb-8">
      <div className=" container mx-auto flex flex-col md:flex-row items-center justify-center md:justify-start gap-y-5">
        <img
          src={companyData?.logo}
          className="bg-[#eee] bg-opacity-80 w-24 h-24 md:w-[100px] md:h-[100px] rounded"
        />
        <div>
          <h1 className="text-2xl mb-4 md:ml-4 text-center md:text-left">
            {companyData?.company_name}
          </h1>
          {companyData?.city?.name && companyData?.district?.name && (
            <span className="flex items-center text-gray-400 justify-center md:justify-start">
              <FiMapPin className="ml-4" size={20} />
              <h2 className="ml-2 text-md">{companyData.city.name} / {companyData.district.name}</h2>
            </span>
          )}
          <div className="flex flex-wrap justify-center gap-x-2 ml-4 mt-4">
            {companyData?.facebook && (
              <a 
              href={`https://www.facebook.com/${companyData?.facebook}`} 
              className="flex items-center gap-x-2 bg-[#3B5998] text-white px-2 py-1 rounded m-2"
              target="_blank"
              >
                <FaFacebookF size={20} />
                Facebook
              </a>
            )}
            {companyData?.whatsapp && (
              <a
              className="flex items-center gap-x-2 bg-green-500 text-white px-2 py-1 rounded m-2"
              href={`https://wa.me/90${companyData?.whatsapp}`}
              target="_blank"
              >
                <FaWhatsapp size={20} />
                Whatsapp
              </a>
            )}
            {companyData?.twitter && (
              <a 
              className="flex items-center gap-x-2 bg-blue-400 text-white px-2 py-1 rounded m-2"
              href={`https://x.com/${companyData?.twitter}`}
              >
                <FaTwitter size={20} />
                Twitter
              </a>

            )}
            {companyData?.linkedin && (
              <a 
              href={`https://www.linkedin.com/company/${companyData?.linkedin}`}
              target="_blank"
              className="flex items-center gap-x-2 bg-[#0077B5] text-white px-2 py-1 rounded  m-2">
                <FaLinkedinIn size={20} />
                LinkedIn
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
