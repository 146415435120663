import { useState, useEffect, useRef } from "react";
import { FaAngleUp } from "react-icons/fa";
import { IStatus } from "../../../types";
import { CustomModal } from "../modal";

interface SelectProps {
  data: IStatus[];
  label: string;
  placeholder?: string;
  blue?: boolean;
  onChange?: (value: number) => void;
  selectedvalue?: any | undefined;
  callBack?: () => void;
}

function SelectComponent({
  data,
  label,
  blue = false,
  placeholder = "Seçiniz",
  onChange,
  selectedvalue,
  callBack,
}: SelectProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selected, setSelected] = useState<IStatus>({
    id: 0,
    value: "",
    name: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (value: IStatus) => {
    if (value.name === "Yeni Klasör ekle") {
      setIsModalOpen(true);
      return;
    }
    setSelected(value);
    setIsOpen(false);
    onChange && onChange(value.id);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex-1 w-full relative" ref={containerRef}>
      <p className="text-gray-600">{label}</p>
      <button
        className={`${
          blue
            ? "border-primary200 text-primary200"
            : "border-gray-400 text-gray-500"
        } bg-white border rounded-md shadow-sm px-4 py-2 w-full flex justify-between items-center text-left`}
        onClick={toggleDropdown}
        type="button"
      >
        {selectedvalue ? selected.name || placeholder : placeholder}
        <FaAngleUp
          className={`ml-2 transition-transform duration-300 transform ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>
      {isOpen && (
        <div className="absolute z-10 mt-[1px] flex-1  bg-white  border-gray-300 w-full shadow-lg text-start ">
          <div className="py-1 overflow-y-auto max-h-40">
            {data.map((item: IStatus) => (
              <div
                key={item.id}
                className="text-gray-700 hover:bg-primary200 hover:text-white px-4 py-2 cursor-pointer"
                onClick={() => handleSelect(item)}
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>
      )}
      <CustomModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          if (typeof callBack === "function") {
            callBack();
          }
        }}
      />
    </div>
  );
}

export { SelectComponent };
