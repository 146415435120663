import React, { MouseEventHandler } from "react";
import { IconType } from "react-icons";

interface IButtonProps {
  title: string;
  btnType?: "submit" | "button";
  designs?: string;
  isDisabled?: boolean;
  btnSize?: string;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  icon?: IconType;
}

const CustomButton = ({
  title,
  btnType,
  icon: Icon,
  designs,
  isDisabled,
  btnSize,
  handleClick,
}: IButtonProps) => {
  return (
    <div className={`w-full ${btnSize} justify-center items-center py-1 flex`}>
      <button
        onClick={handleClick}
        type={btnType}
        className={`flex items-center justify-center  bg-blue-900 text-white font-bold text-md hover:bg-blue-800 rounded-sm ${designs}`}
        disabled={isDisabled}
      >
        {Icon && <Icon size={20} />}
        {title}
      </button>
    </div>
  );
};

export default CustomButton;
