import { LabelHTMLAttributes } from "react";
import { useContext } from "react";
import { CheckboxContext, checkboxContextType } from ".";

type labelPropsType = LabelHTMLAttributes<HTMLLabelElement> & {};

export default function Label({
  className,
  children,
  ...props
}: labelPropsType) {
  const { required }: checkboxContextType = useContext(CheckboxContext);

  return (
    <label
      className={`${className} text-sm text-gray-500 font-poppins`}
      {...props}
    >
      {children} {required ? <b className="text-red-600">*</b> : null}
    </label>
  );
}
