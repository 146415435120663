import React, { useState } from "react";
import { Checkbox } from "./checkbox/index";
import { RadioGroup } from "./radio/index";
import { Tooltip } from "./tooltip/index";
import { Hovercard } from "./hoverCard";
import { Breadcrumb } from "./breadcrumb";
import { BreadcrumbItem } from "./breadcrumb/Item";
import { Tabs } from "./tabs/index";
import { TabList } from "./tabs/List";
import { Tab } from "./tabs/Tab";
import { TabPanel } from "./tabs/Panel";
import Accordion from "./accordion/Accordion";

export default function Example() {
  const [isChecked, setIsChecked] = useState(false);
  const [selected, setSelected] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-col justify-center items-center gap-2">
      <h1>Hook Test</h1>

      <Checkbox
        checked={isChecked}
        setChecked={setIsChecked}
        required={true}
        error={!isChecked}
      >
        <Checkbox.Label>Checkbox Label</Checkbox.Label>
        <Checkbox.Trigger />
        <Checkbox.Error>Error message here</Checkbox.Error>
      </Checkbox>

      <RadioGroup
        selectedValue={selected}
        setSelectedValue={setSelected}
        required={true}
        error={!selected}
      >
        <RadioGroup.Label>Radio Button Group</RadioGroup.Label>
        <div>
          <RadioGroup.Trigger value="option1" />
          <span>Option 1</span>
        </div>
        <div>
          <RadioGroup.Trigger value="option2" />
          <span>Option 2</span>
        </div>
        <RadioGroup.Error>Error message here</RadioGroup.Error>
      </RadioGroup>

      <Tooltip content="This is a tooltip message">
        <Tooltip.Trigger>
          <button className="p-2 bg-blue-500 text-white">Hover me</button>
        </Tooltip.Trigger>
        <Tooltip.Content />
      </Tooltip>

      <Hovercard
        content={
          <div>
            <strong>More Info</strong>
            <p>This is a hovercard with more details.</p>
          </div>
        }
      >
        <Hovercard.Trigger>
          <button className="p-2 bg-blue-500 text-white">Hover over me</button>
        </Hovercard.Trigger>
        <Hovercard.Content />
      </Hovercard>

      <Breadcrumb separator=">">
        <BreadcrumbItem href="/">Home</BreadcrumbItem>
        <BreadcrumbItem href="/category">Category</BreadcrumbItem>
        <BreadcrumbItem>Current Page</BreadcrumbItem>
      </Breadcrumb>

      <Tabs defaultTab="tab1">
        <TabList>
          <Tab tabId="tab1">Tab 1</Tab>
          <Tab tabId="tab2">Tab 2</Tab>
          <Tab tabId="tab3">Tab 3</Tab>
        </TabList>
        <TabPanel tabId="tab1">
          <div>Content for Tab 1</div>
        </TabPanel>
        <TabPanel tabId="tab2">
          <div>Content for Tab 2</div>
        </TabPanel>
        <TabPanel tabId="tab3">
          <div>Content for Tab 3</div>
        </TabPanel>
      </Tabs>

      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <main className="p-4">
          <Accordion initialOpenIndex={0}>
            <Accordion.Item index={0}>
              <Accordion.Item.Trigger index={0}>
                Click to toggle 1
              </Accordion.Item.Trigger>
              <Accordion.Item.Content index={0}>
                This is the content that is shown or hidden for item 1.
              </Accordion.Item.Content>
            </Accordion.Item>
            <Accordion.Item index={1}>
              <Accordion.Item.Trigger index={1}>
                Click to toggle 2
              </Accordion.Item.Trigger>
              <Accordion.Item.Content index={1}>
                This is the content that is shown or hidden for item 2.
              </Accordion.Item.Content>
            </Accordion.Item>
            <Accordion.Item index={2}>
              <Accordion.Item.Trigger index={2}>
                Click to toggle 3
              </Accordion.Item.Trigger>
              <Accordion.Item.Content index={2}>
                This is the content that is shown or hidden for item 3.
              </Accordion.Item.Content>
            </Accordion.Item>
          </Accordion>
        </main>
      </div>
    </div>
  );
}
