import React, {
  useState,
  useEffect,
  useRef,
  KeyboardEventHandler,
} from "react";
import { set } from "react-hook-form";
import { FaAngleUp, FaTimes } from "react-icons/fa";
import { IData } from "../../../../types";
import { LuLoader2 } from "react-icons/lu";

interface SelectProps {
  data: any;
  label: string;
  placeholder?: string;
  blue?: boolean;
  onChange?: (value: number) => void;
  selectedvalue?: any | undefined;
  setSearchData?: (value: string) => void;
  searchData?: string;
  disabled?: boolean;
  characterDeletion?: any;
  isFetching?: boolean;
  keyString: string;
}

/* HERE: Search component burada bunu eğitim dormunda kullan */
function SearchComponent({
  disabled,
  data,
  label,
  blue = false,
  placeholder = "Seçiniz",
  onChange,
  selectedvalue,
  setSearchData,
  searchData,
  isFetching,
  keyString,
  characterDeletion,
}: SelectProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isInputFocus, setIsInputFocus] = useState(false);

  const openDropdown = () => {
    setIsOpen(true);
    if (inputRef.current) {
      // inputRef.current.value="";
      inputRef.current.focus();
    }
  };

  const toggleDropdown = () => {
    if (inputRef.current && !isOpen) {
      inputRef.current.focus();
    }
    setIsOpen(!isOpen);
  };

  const setSearchDataToSelected = () => {
    let title = data.filter((item: any) => item.id === selectedvalue);
    if (title.length < 1) {
      setSearchData && setSearchData("");
      return;
    }
    setSearchData && setSearchData(title[0][keyString]);
  };

  useEffect(() => {
    if (!isInputFocus) {
      if (selectedvalue == 0) {
        setSearchData && setSearchData("");
        return;
      }
      setSearchDataToSelected();
    }
  }, [selectedvalue, data]);

  const handleSelect = (value: any) => {
    if (value.id !== 0) {
      setSearchData && setSearchData(value[keyString]);
    } else {
      setSearchData && setSearchData("");
    }
    onChange && onChange(value.id);
    setIsOpen(false);
  };
  const handleClearButton = () => {
    setSearchData && setSearchData("");
    onChange && onChange(0);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchData && setSearchData(e.target.value);
  };

  const styleProps: any = {
    opacity: "1",
    pointerEvents: "none",
  };

  const handleBackspace = () => {
    onChange && onChange(0);
  };

  return (
    <div
      className={"flex-1 w-full relative"}
      ref={containerRef}
      style={disabled === true ? styleProps : {}}
    >
      <p className="text-gray-600 text-sm md:text-base">{label}</p>
      <div
        className={`${
          blue || (isOpen && !disabled)
            ? "border-primary200 text-primary200"
            : "border-gray-400"
        }
				${
          selectedvalue !== undefined
            ? "text-primary200  border-mains"
            : "text-gray-600"
        }
				bg-white border rounded-md text-sm md:text-base shadow-sm px-4 py-2 w-full flex justify-between items-center text-left`}
        onClick={openDropdown}
      >
        <input
          ref={inputRef}
          value={searchData}
          placeholder={placeholder}
          type="text"
          className={`${
            blue ? "placeholder-primary200" : "placeholder-gray-600"
          }   text-primary200 w-full h-full outline-none poppins-regular text-ellipsis `}
          onChange={handleInputChange}
          onBlur={() => {
            setIsInputFocus(false);
            //setSearchDataToSelected();
          }}
          onFocus={() => {
            setIsInputFocus(true);
          }}
          onKeyDown={(event) => {
            console.log(event);
            if (event.nativeEvent.key === "Backspace") {
              handleBackspace();
            }
          }}
        />
        {isFetching && <LuLoader2 className={"animate-spin"}></LuLoader2>}
        <div
          className={`flex transition-all ${
            searchData !== "" || selectedvalue
              ? "w-8 justify-between"
              : "w-4 justify-end"
          }`}
        >
          <FaTimes
            onClick={(e) => {
              e.stopPropagation();
              handleClearButton();
            }}
            className={`transition-transform fade duration-300 transform cursor-pointer  ${
              searchData !== "" || selectedvalue ? "flex" : "hidden"
            }`}
          />

          <FaAngleUp
            onClick={(e) => {
              e.stopPropagation();
              toggleDropdown();
            }}
            className={`transition-transform duration-300 transform  ${
              !isOpen ? "rotate-180" : ""
            }`}
          />
        </div>
      </div>

      {isOpen && (
        <div className="absolute z-50 mt-[1px] flex-1  bg-white  border-gray-300 w-full shadow-lg ">
          <div className="py-1 overflow-y-auto max-h-40 border-2 rounded-md shadow">
            <div className="text-gray-700  px-4 py-2">
              {data.length <= 0 && "Sonuç Bulunamadı"}
            </div>
            {data
              .filter((el: any) => (searchData !== "" ? el.id !== 0 : true))
              .map((item: any) => (
                <div
                  key={item.id}
                  className="text-gray-700 hover:bg-primary200 hover:text-white px-4 py-2 cursor-pointer"
                  onClick={() => handleSelect(item)}
                >
                  {item[keyString]}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

const handleSearchChange = (
  name: string,
  value: string | undefined,
  arr: any[],
  selectedValues: any[],
  setSelectedValues: (value: any) => void
) => {
  let findId = arr.find((element) => element.label === value)?.value;

  if (name === "sectors" || name === "sections") {
    findId = `[${findId}]`;
  } else {
    findId = `${findId}`;
  }

  const existingIndex = selectedValues.findIndex(
    (element: any) => name in element
  );

  if (existingIndex !== -1) {
    // If the attribute already exists, update its value
    const updatedValues = [...selectedValues];
    updatedValues[existingIndex] = {
      ...updatedValues[existingIndex],
      [name]: findId,
    };

    setSelectedValues(updatedValues);
  } else {
    // If the attribute doesn't exist, add a new entry
    setSelectedValues([...selectedValues, { [name]: findId }]);
  }
};

export { SearchComponent, handleSearchChange };
