import React, { useState } from "react";
import HamburgerContainer from "../hamburger-container";
import HamburgerList from "../hamburger-list";
import {
  IconBuilding,
  IconClipboardList,
  IconCoinOff,
  IconEdit,
  IconFilePlus,
  IconFolder,
  IconLicense,
  IconList,
  IconMail,
  IconSettings,
  IconUser,
} from "@tabler/icons-react";
import { IoExitOutline } from "react-icons/io5";
import { IoPeopleOutline } from "react-icons/io5";
import { useCookie } from "../../../../../../hooks/useCookie";
import { CookieName } from "../../../../../../constants/enums";
import { useAppDispatch } from "../../../../../../store/store";
import { clearCompanyUser } from "../../../../../../store/features/companyUserSlice";
import { useNavigate } from "react-router-dom";
import { ICompanyUser } from "../../../../../../types/ICompanyUser";
import { CiShoppingBasket } from "react-icons/ci";
import { LuHeadphones } from "react-icons/lu";
import { CiStar } from "react-icons/ci";
import { IoIosNotificationsOutline } from "react-icons/io";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Axios from "../../../../../../services/axios";

interface IHamburgerContent {
  readonly isLogined: boolean;
  readonly isHamburger: boolean;
  readonly companyUser: ICompanyUser;
  readonly userType: string;
  setIsHamburger: (isHamburger: boolean) => void;
}

export const HamburgerContent: React.FC<IHamburgerContent> = (
  props: IHamburgerContent
) => {
  const emptyAvatar =
    "https://isbull.s3.eu-north-1.amazonaws.com/default-images/company-logo.png?v=2.9.90";
  const { removeCookie } = useCookie();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const axios = new Axios();

  const { getCookie } = useCookie();
  const userTokenCookie = getCookie(CookieName.UserToken);
  const userToken = userTokenCookie ? userTokenCookie : "";
  // TODO: add userData to the redux store
  const companyUserTokenCookie = getCookie(CookieName.CompanyUserToken);
  const companyUserToken = companyUserTokenCookie ? companyUserTokenCookie : "";

  //console.log("userToken", userToken, companyUserToken);

  const removeCompanyToken = () => {
    removeCookie(CookieName.CompanyUserToken);
    removeCookie(CookieName.CompanyUserData);
    dispatch(clearCompanyUser());
    navigate("/isveren/giris-yap");
  };
  const removeUserToken = () => {
    removeCookie(CookieName.UserToken);
    removeCookie(CookieName.UserData);
    navigate("/profilim/giris-yap");
  };

  const [cvId, setCvId] = useState<number>(0);
  const [cvName, setCvName] = useState<string>("");
  const [cvSurname, setCvSurname] = useState<string>("");
  const [cvAvatar, setCvAvatar] = useState<string>(emptyAvatar);
  const [companyCvAvatar, setCompanyCvAvatar] = useState<string>(emptyAvatar);

  useEffect(() => {
    if (props.isHamburger) {
      if (userToken) {
        const fetchCv = async () => {
          try {
            const response = await axios.request({
              endpoint: `user/profilim/cv`,
              type: "get",
              payload: {},
            });

            if (response?.data?.data) {
              setCvId(response.data.data.id);
              setCvName(response.data.user.name);
              setCvSurname(response.data.user.surname);
              setCvAvatar(response.data.user.profile);
            }
          } catch (error) {
            console.log("Error fetching data:", error);
          }
        };

        fetchCv();
      }

      if (companyUserToken) {
        const fetchCompanyCv = async () => {
          try {
            const response = await axios.authRequest({
              endpoint: `companies/account-settings/get-setting-info`,
              type: "get",
              payload: {},
            });

            if (response?.data?.company) {
              setCompanyCvAvatar(response.data.company.logo);
            }
          } catch (error) {
            console.log("Error fetching data:", error);
          }
        };

        fetchCompanyCv();
      }
    }
  }, [props.isHamburger, userToken, companyUserToken, axios]);

  let Items = [];
  if (props.userType === "company") {
    Items = [
      {
        title: "Ana Sayfa",
        path: "/",
        icon: <IconUser className="text-white" size={26} />,
      },
      {
        title: "Aday Havuzu",
        path: "isveren/aday-havuzu",
        icon: <IoPeopleOutline className="text-white" size={26} />,
      },
      {
        title: "Mesajlar",
        path: "isveren/mesajlar",
        icon: <IconMail className="text-white" size={26} />,
      },
      {
        title: "İlanlar / Başvurular",
        path: "#",
        icon: <IconClipboardList className="text-white" size={26} />,
        children: [
          {
            title: "Yeni İş İlanı Ekle",
            path: "isveren/ilanlar/olustur/ilan-bilgileri",
          },
          {
            title: "Tüm İlanlar / Başvurular",
            path: "is-ilanlari",
          },
          {
            title: "Arşivdeki İlanlar",
            path: "/isveren?active=archived",
          },
          {
            title: "Son İncelenenler",
            path: "#",
          },
          {
            title: "Engellenen Adaylar",
            path: "isveren/ayarlar?active=blacklist",
          },
        ],
      },
      {
        title: "Ürünler",
        path: "isveren/ürünler",
        icon: <CiShoppingBasket className="text-white" size={26} />,
      },
      {
        title: "Mülakatlar",
        path: "isveren/mulakat-listesi",
        icon: <IconEdit className="text-white" size={26} />,
      },
      {
        title: "Bildirimlerim",
        path: "isveren/bildirimler",
        icon: <IoIosNotificationsOutline className="text-white" size={26} />,
      },
      {
        title: "Kayıtlı Aramalarım",
        path: "isveren/kayitli-aramalar",
        icon: <IconList className="text-white" size={26} />,
      },
      {
        title: "Referans Davetlerim",
        path: "isveren/referanslar",
        icon: <IconList className="text-white" size={26} />,
      },
      {
        title: "Klasörlerim",
        path: "isveren/klasorler",
        icon: <IconFolder className="text-white" size={26} />,
      },
      {
        title: "Hesap Ayarları",
        path: "isveren/ayarlar",
        icon: <IconSettings className="text-white" size={26} />,
      },
      {
        title: "Görseller",
        path: "isveren/dosyalar",
        icon: <IconFolder className="text-white" size={26} />,
      },
      {
        title: "Yardım",
        path: "/yardim",
        icon: <LuHeadphones className="text-white" size={26} />,
      },

      {
        title: "Çıkış",
        onClick: removeCompanyToken,
        icon: <IoExitOutline className="text-white" size={26} />,
      },
    ];
  } else {
    Items = [
      {
        title: "Profil",
        path: "/profilim",
        icon: <IconUser className="text-white" size={26} />,
      },
      {
        title: "Özgeçmişler",
        path: "#",
        icon: <IconFilePlus className="text-white" size={26} />,
        children: [
          {
            title: "Tüm Özgeçmişlerim",
            path: "profilim/cv",
          },
          {
            title: "Özgeçmişimi Göster",
            path: `profilim/cv/goruntule/${cvId}/`,
          },
          {
            title: "Özgeçmişimi İndir",
            path: `profilim/cv/goruntule/${cvId}?action=download`,
          },
          {
            title: "Özgeçmişimi Düzenle",
            path: `profilim/cv/duzenle/${cvId}`,
          },
          {
            title: "Özgeçmişimi Güncelle",
            path: ``,
          },
          {
            title: "Özgeçmişimi Gönder",
            path: `profilim/cv/goruntule/${cvId}?action=share`,
          },
          {
            title: "Özgeçmişimi Yazdır",
            path: `profilim/cv/goruntule/${cvId}?action=print`,
          },
        ],
      },
      {
        title: "Dosyalar & Önyazılar",
        path: "/profilim/dosyalar-onyazilar",
        icon: <IconFolder className="text-white" size={26} />,
      },
      {
        title: "Bana Uygun İlanlar",
        path: "/is-ilanlari?for_me_adverts=1",
        icon: <IconFolder className="text-white" size={26} />,
      },
      {
        title: "Başvurularım",
        path: "/profilim/başvurular",
        icon: <IconClipboardList className="text-white" size={26} />,
      },
      {
        title: "Favorilerim",
        path: "/profilim/favoriler",
        icon: <CiStar className="text-white" size={26} />,
      },
      {
        title: "Bildirimlerim",
        path: "/profilim/bildirimler",
        icon: <IoIosNotificationsOutline className="text-white" size={26} />,
      },
      {
        title: "İş Alarmları",
        path: "/profilim/is-alarmlari",
        icon: <IconList className="text-white" size={26} />,
      },
      {
        title: "Mesajlarım",
        path: "/profilim/mesajlar",
        icon: <IconMail className="text-white" size={26} />,
      },
      {
        title: "Mülakatlarım",
        path: "/profilim/mulakat-listesi",
        icon: <IconEdit className="text-white" size={26} />,
      },
      {
        title: "Ayarlar",
        path: "/profilim/ayarlar",
        icon: <IconSettings className="text-white" size={26} />,
      },
      {
        title: "Yardım",
        path: "/yardim",
        icon: <LuHeadphones className="text-white" size={26} />,
      },

      {
        title: "Çıkış",
        onClick: removeUserToken,
        icon: <IoExitOutline className="text-white" size={26} />,
      },
    ];
  }

  useEffect(() => {
    if (!companyUserToken || !userToken) {
      if (location.pathname.includes("profilim/giris-yap")) {
        props.setIsHamburger(false);
      }

      if (location.pathname.includes("isveren/giris-yap")) {
        props.setIsHamburger(false);
      }

      if (location.pathname.includes("profilim/kayit-ol")) {
        props.setIsHamburger(false);
      }

      if (location.pathname.includes("isveren/kayit-ol")) {
        props.setIsHamburger(false);
      }

      if (location.pathname.includes("is-ilanlari?total=413&last_page=12")) {
        props.setIsHamburger(false);
      }

      if (location.pathname.includes("iletisim")) {
        props.setIsHamburger(false);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!companyUserToken || userToken) {
      if (location.pathname.includes("profilim")) {
        props.setIsHamburger(false);
      }

      if (location.pathname.includes("is-ilanlari")) {
        props.setIsHamburger(false);
      }

      if (location.pathname.includes("yardim")) {
        props.setIsHamburger(false);
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    if (companyUserToken || !userToken) {
      if (location.pathname.includes("/")) {
        props.setIsHamburger(false);
      }

      if (location.pathname.includes("isveren")) {
        props.setIsHamburger(false);
      }

      if (location.pathname.includes("yardim")) {
        props.setIsHamburger(false);
      }
    }
  }, [location.pathname]);

  return (
    <HamburgerContainer isHamburger={props.isHamburger}>
      {props?.companyUser || props?.companyUser === null ? (
        <div className="flex flex-col items-center justify-around max-h-screen gap-4">
          {props.userType === "user" ? (
            <>
              {" "}
              <img
                src={cvAvatar ? cvAvatar : emptyAvatar}
                alt=""
                className="object-cover w-20 h-20 rounded-full"
              />
            </>
          ) : (
            <img
              src={companyCvAvatar ? companyCvAvatar : emptyAvatar}
              alt=""
              className="object-cover w-20 h-20  rounded-full"
            />
          )}

          <div>
            <div
              className={
                "text-white font-bold text-lg border-b border-gray-500"
              }
            >
              {props?.companyUser?.name === undefined
                ? cvName + " " + cvSurname
                : props?.companyUser?.name + " " + props?.companyUser?.surname}
            </div>
          </div>

          <div>
            {Items.map((item, index) => (
              <HamburgerList
                icon={item.icon}
                title={item.title}
                isMenu={false}
                link={item.path} // Eğer path varsa link propunu atar
                onClick={item.onClick ? item.onClick : undefined} // Eğer onClick varsa onClick propunu atar
                key={index}
                children={item.children}
              />
            ))}
          </div>
        </div>
      ) : (
        <>
          <HamburgerList
            icon={<IconUser className="text-white" size={32} />}
            title="İş Arayan"
            isMenu={true}
            menu={
              <>
                <HamburgerList
                  title="Giriş Yap"
                  isMenu={false}
                  link="profilim/giris-yap"
                />
                <HamburgerList
                  title="Üye Ol"
                  isMenu={false}
                  link="profilim/kayit-ol"
                />
              </>
            }
            isOpen={true}
          />
          <HamburgerList
            icon={<IconBuilding className="text-white" size={32} />}
            title="İşveren"
            isMenu={true}
            menu={
              <>
                <HamburgerList
                  title="Giriş Yap"
                  isMenu={false}
                  link="isveren/giris-yap"
                />
                <HamburgerList
                  title="Üye Ol"
                  isMenu={false}
                  link="isveren/kayit-ol"
                />
              </>
            }
            isOpen={true}
          />
          <HamburgerList
            icon={<IconList className="text-white" size={32} />}
            title="İş İlanları"
            isMenu={false}
            link="/is-ilanlari?total=413&last_page=12"
          />
          <HamburgerList
            icon={<IconFilePlus className="text-white" size={32} />}
            title="CV Oluştur"
            isMenu={false}
            link="profilim/kayit-ol"
          />
          <HamburgerList
            icon={<IconCoinOff className="text-white" size={32} />}
            title="Ücretsiz İlan Ver"
            isMenu={false}
            link="isveren/ilan-ver"
          />
          <HamburgerList
            icon={<IconLicense className="text-white" size={32} />}
            title="Kurumsal"
            isMenu={true}
            menu={
              <>
                <HamburgerList
                  title="Kullanım Koşulları"
                  isMenu={false}
                  link="kurumsal/kullanim-kosullari"
                />
                <HamburgerList
                  title="Kredi Karti Saklama Koşulları"
                  isMenu={false}
                  link="kurumsal/kredi-karti-saklama-kosullari"
                />

                <HamburgerList
                  title="Gizlilik Sözleşmesi"
                  isMenu={false}
                  link="kurumsal/gizlikik-sozlesmesi-4"
                />

                <HamburgerList
                  title="Üyelik Sözleşmesi"
                  isMenu={false}
                  link="kurumsal/uyelik-sozlesmesi-5"
                />

                <HamburgerList
                  title="Çerezlerin Kullanımı"
                  isMenu={false}
                  link="kurumsal/cerezlerin-kullanimi-6"
                />

                <HamburgerList
                  title="Kalite Politikası"
                  isMenu={false}
                  link="kurumsal/kalite-politikasi-7"
                />

                <HamburgerList
                  title="KVKK Metni"
                  isMenu={false}
                  link="kurumsal/kvkk-metni-8"
                />

                <HamburgerList
                  title="Kullanim Koşulları"
                  isMenu={false}
                  link="kurumsal/kullanim-kosullari-9"
                />

                <HamburgerList
                  title="Ön Bilgilendirme Formu"
                  isMenu={false}
                  link="kurumsal/on-bilgilendirme-formu-10"
                />

                <HamburgerList
                  title="Mesafeli Satış Sözleşmesi"
                  isMenu={false}
                  link="kurumsal/mesafeli-satis-sozlesmesi-11"
                />

                <HamburgerList
                  title="Kurumsal Üyelik Sözleşmesi"
                  isMenu={false}
                  link="kurumsal/kurumsal-uyelik-sozlesmesi"
                />
              </>
            }
          />
          <HamburgerList
            icon={<IconMail className="text-white" size={32} />}
            title="İletişim"
            isMenu={false}
            link="iletisim"
          />
        </>
      )}
    </HamburgerContainer>
  );
};
