import React, { useEffect, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { CgArrowsExchangeAlt } from "react-icons/cg";
import { TbEditCircle, TbEyeEdit, TbWorld } from "react-icons/tb";
import { Link, useParams } from "react-router-dom";
import Axios from "../../../../../services/axios";
import { dbUserCvType } from "../../../../../types";
import FormModal from "../../../../../components/isbul-ui/form-modal";
import { Button, InputArea } from "../../../../../components/isbul-ui";
import { IoCheckmarkCircleOutline, IoClose, IoDocument, IoTrashBinOutline } from "react-icons/io5";
import { FaEye, FaRegPaperPlane } from "react-icons/fa6";
import { useContext } from "react";
import { CvLayoutContext, cvLayoutContextType } from "..";
import { FaCalendar, FaLock, FaPrint, FaRegBuilding } from "react-icons/fa";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";


const axios = new Axios()



const monthNames: string[] = [
  'Ocak',
  'Şubat',
  'Mart',
  'Nisan',
  'Mayıs',
  'Haziran',
  'Temmuz',
  'Ağustos',
  'Eylül',
  'Ekim',
  'Kasım',
  'Aralık'
]

const monthNamesEn: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]




const DefaultCv: React.FC = () => {

  const navigate = useNavigate()

  const { isEnglish, visibility, getCvById }: cvLayoutContextType = useContext(CvLayoutContext)

  const params = useParams()
  const cvId = params.slug

  const cvSwitchDivRef = useRef<HTMLDivElement>(null)
  const moreDivRef = useRef<HTMLDivElement>(null)
  const privacyDivRef = useRef<HTMLDivElement>(null)

  // General states
  const [allCvs, setAllCvs] = useState<dbUserCvType[]>([])
  const [selectedCv, setSelectedCv] = useState<dbUserCvType | null>(null)
  const [lastUpdateDate, setLastUpdateDate] = useState<string>('')
  const [headerFormOpen, setHeaderFormOpen] = useState<boolean>(false)
  const [cvSwitchPopupOpen, setCvSwitchPopupOpen] = useState<boolean>(false)
  const [cvTitle, setCvTitle] = useState<string>('')
  const [titleUpdateError, setTitleUpdateError] = useState<string | null>(null)
  const [isMorePopupOpen, setIsMorePopupOpen] = useState<boolean>(false)
  const [privacyPopupOpen, setPrivacyPopupOpen] = useState<boolean>(false)

  async function getCvDatas() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/all`,
        type: 'get',
        payload: {}
      })

      if (response && response.data && response.data.data) {
        setAllCvs(response.data.data)
      }
    } catch (error) {
      console.log(`CV fetch failed. Error: ${error}`)
    }
  }

  useEffect(() => {
    getCvDatas()
  }, [])

  // Set selected cv
  useEffect(() => {
    if (allCvs.length > 0) {
      const cv = allCvs.find((c) => c.id.toString() == cvId)
      if (cv) {
        setSelectedCv(cv)
      }
    }
  }, [allCvs])

  useEffect(() => {

    
    if (selectedCv) {
      // Date converting to text
      const date = new Date(selectedCv.updated_at)
      const dateString = `${date.getDate()} ${isEnglish? `${monthNamesEn[date.getMonth()]?? ''}`: `${monthNames[date.getMonth()]?? ''}`} ${date.getFullYear()}`
      setLastUpdateDate(dateString)

      // Cv title
      setCvTitle(selectedCv.title)
    }
  }, [selectedCv, isEnglish, allCvs])

  // Cv switch click outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (cvSwitchDivRef.current && !cvSwitchDivRef.current.contains(event.target as Node)) {
        setCvSwitchPopupOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [cvSwitchDivRef])
  
  // More
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (moreDivRef.current && !moreDivRef.current.contains(event.target as Node)) {
        setIsMorePopupOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [moreDivRef])

  // Privacy
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (privacyDivRef.current && !privacyDivRef.current.contains(event.target as Node)) {
        setPrivacyPopupOpen(false)
      }
    }
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [privacyDivRef])
  return (
    <div className="flex justify-between mx-auto w-full py-7 px-4 rounded-md bg-white shadow-md">
      <div className="flex gap-x-10 items-center">
        <div className="flex gap-x-2 items-center relative">
          <button onClick={() => setCvSwitchPopupOpen(true)}>
            <CgArrowsExchangeAlt className="text-4xl text-mains" size={40} />
          </button>
          <label className="text-xl poppins-semibold text-blue-900 text-ellipsis overflow-hidden whitespace-nowrap max-w-[200px]">
            {selectedCv?.title}
          </label>
          {cvSwitchPopupOpen && (
            <div ref={cvSwitchDivRef} className="flex flex-col gap-2 absolute bottom-0 min-w-[200px] max-w-[250px] left-0 translate-y-full p-2 bg-white border border-gray-200 rounded-md shadow-md z-10">
              {allCvs.map((cv) => {
                return(
                  <a
                  key={`cvSwitch${cv.id}`}
                  className="text-start p-2 bg-white text-black hover:bg-mains hover:text-white transition-all whitespace-nowrap overflow-hidden text-ellipsis rounded-md "
                  href={`/profilim/cv/duzenle/${cv.id}`}
                  >
                    {cv.title}
                  </a>
                )
              })}
            </div>
          )}
        </div>

        <div className="flex flex-shrink-0 mx-2">
          <div className="flex gap-x-4 flex-col lg:flex-row">
            <div className="flex items-center gap-x-2">
              <span className="text-xs poppins-medium">{isEnglish? 'Last Update': 'Son Güncelleme'} :</span>
              <span className="text-xs poppins-semibold">{lastUpdateDate}</span>
            </div>

            {/* CV title edit section */}
            <FormModal
            isOpen={headerFormOpen}
            setIsOpen={setHeaderFormOpen}
            >
              <FormModal.Trigger>
                <button onClick={() => setHeaderFormOpen(true)}>
                  <TbEditCircle className="text-mains text-2xl"/>
                </button>
              </FormModal.Trigger>
              <FormModal.Content className="!max-w-[500px]">
                <div className="flex flex-col gap-4 p-2">
                  <div className="flex flex-row items-center justify-between">
                    <label className="text-mains text-xl font-poppins">{isEnglish? 'Update Resume': 'Özgeçmiş Güncelle'}</label>
                    <button onClick={() => setHeaderFormOpen(false)}>
                      <IoClose className="text-2xl text-gray-600"/>
                    </button>
                  </div>
                  <hr/>
                  <div className="flex flex-col gap-4">
                    <InputArea
                    required
                    mode={cvTitle.length == 0? 'empty': 'dirty'}
                    error={cvTitle.trim().length == 0}
                    >
                      <InputArea.Label>{isEnglish? 'Resume Title': 'Özgeçmiş Başlığı'}</InputArea.Label>
                      <InputArea.Input
                      value={cvTitle || ''}
                      onChange={((e) => setCvTitle(e.target.value))}
                      />
                    </InputArea>
                  </div>
                  <p className="font-poppins text-xs">
                    <i>{isEnglish? 'Information: The name you give on your resume will not be displayed by companies.': 'Bilgi: Özgeçmişine verdiğin isim, şirketler tarafından görüntülenmeyecektir.'}</i><br/>
                    <i>{isEnglish? 'Information: Since you have a Turkish CV, your next CV will be created in English.': 'Bilgi: Türkçe özgeçmişiniz bulunduğu için bir sonraki özgeçmişiniz İngilizce oluşturulacaktır.'}</i>
                  </p>
                  {titleUpdateError && (
                    <p className="font-poppins text-xs text-red-600">
                      {titleUpdateError}
                    </p>
                  )}
                  <hr/>
                  <div className="flex ml-auto">
                    <Button type="button" size="sm" onClick={() => updateCvTitle()}>
                      {isEnglish? 'Update': 'Güncelle'}
                      <Button.Icon>
                        <FaRegPaperPlane/>
                      </Button.Icon>
                    </Button>
                  </div>
                </div>
              </FormModal.Content>
            </FormModal>
            <div className="flex gap-x-1 items-center">
              <span className="text-xs poppins-medium">{isEnglish? 'Selected Language': 'Seçilen Dil'} :</span>
              <span className="text-xs poppins-semibold">{selectedCv?.lang == 'tr'? 'Türkçe': selectedCv?.lang == 'en'? 'English': ''}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-row items-center gap-2">
        <div ref={privacyDivRef} className="flex gap-x-2 relative justify-between items-center">
          <Button
          className="!rounded-full"
          variant="ghost"
          size="sm"
          onClick={() => setPrivacyPopupOpen((prev) => !prev)}
          >
            <Button.Icon>
              <TbWorld/>
            </Button.Icon>
            {isEnglish? 'CV Privacy Settings': 'Özgeçmiş Gizlilik Ayarları'}
          </Button>
          {privacyPopupOpen && (
            <div className="flex flex-col gap-2 absolute min-w-[300px] bottom-0 right-0 translate-y-full p-2 border border-gray-200 shadow-md rounded-md bg-white z-50">
              {/* CV visibility all button */}
              <button 
              className={`flex flex-row items-center gap-2 p-2 border border-gray-200 ${visibility == 'ALL'? 'bg-mains text-white': 'bg-white text-gray-700'}  hover:bg-mains hover:text-white rounded-md transition-all`}
              onClick={async () => {
                try {
                  const response = await axios.userAuthRequest({
                    endpoint: `user/profilim/cv/visibility/${cvId}`,
                    type: 'post',
                    payload: {
                      visibility: 'ALL'
                    }
                  })

                  if (response && response.status) {
                    Swal.fire({
                      icon: 'success',
                      title: 'Başarılı'
                    })

                    getCvById()
                  } else {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Başarısız',
                      text: 'CV gizliliği güncellenirken bir hata meydana geldi.'
                    })
                  }
                } catch (error) {
                  console.log(`Error: ${error}`)
                  Swal.fire({
                    icon: 'warning',
                    title: 'Başarısız',
                    text: 'CV gizliliği güncellenirken beklenmedik bir hata meydana geldi.'
                  })
                }
              }}
              >
                <FaRegBuilding/>
                <div className="flex flex-col gap-2">
                  <label className="cursor-pointer text-sm text-start">
                    {isEnglish? 'All Companies': 'Tüm Firmalar'}
                  </label>
                  <label className="cursor-pointer text-xs text-start">
                    {isEnglish? 'Your CV is visible to all companies on isbul.net': "Özgeçmişin isbul.net'teki tüm firmalar tarafından görüntülenir."}
                  </label>
                </div>
              </button>
              
              {/* CV visibility company button */}
              <button 
              className={`flex flex-row items-center gap-2 p-2 border border-gray-200 ${visibility == 'APPLY_COMPANY'? 'bg-mains text-white': 'bg-white text-gray-700'} hover:bg-mains hover:text-white rounded-md transition-all`}
              onClick={async () => {
                try {
                  const response = await axios.userAuthRequest({
                    endpoint: `user/profilim/cv/visibility/${cvId}`,
                    type: 'post',
                    payload: {
                      visibility: 'APPLY_COMPANY'
                    }
                  })

                  if (response && response.status) {
                    Swal.fire({
                      icon: 'success',
                      title: 'Başarılı'
                    })

                    getCvById()
                  } else {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Başarısız',
                      text: 'CV gizliliği güncellenirken bir hata meydana geldi.'
                    })
                  }
                } catch (error) {
                  console.log(`Error: ${error}`)
                  Swal.fire({
                    icon: 'warning',
                    title: 'Başarısız',
                    text: 'CV gizliliği güncellenirken beklenmedik bir hata meydana geldi.'
                  })
                }
              }}
              >
                <FaLock/>
                <div className="flex flex-col gap-2">
                  <label className="cursor-pointer text-sm text-start">
                    {isEnglish? 'Applied Companies': 'Başvurduğum Firmalar'}
                  </label>
                  <label className="cursor-pointer text-xs text-start">
                    {isEnglish? 'Your CV is only visible to the company you applied to': 'Özgeçmişin sadece başvuru yaptığın firma tarafından görüntülenir.'}
                  </label>
                </div>
              </button>
            </div>
          )}
        </div>

        <div ref={moreDivRef} className="flex gap-x-2 relative justify-between items-center">
          <button className="p-2 rounded-full aspect-square border border-mains bg-white text-mains" onClick={() => setIsMorePopupOpen((prev) => !prev)}>
            <BsThreeDotsVertical className="text-xl"/>
          </button>
          {isMorePopupOpen && (
            <div className="flex flex-col gap-2 absolute bottom-0 translate-y-full right-0 p-2 bg-white border border-gray-200 rounded-md shadow-md z-50">
              {selectedCv?.is_default == 0 && (
                <button 
                className="flex flex-row items-center gap-2 font-poppins text-sm bg-white text-gray-700 hover:text-white hover:bg-mains py-1 px-2 border border-gray-200 whitespace-nowrap transition-all"
                onClick={async () => {
                  try {
                    const response = await axios.userAuthRequest({
                      endpoint: `user/profilim/cv/change-default-cv`,
                      type: 'post',
                      payload: {
                        cv_id: cvId
                      }
                    })

                    if (response && response.status == 200) {
                      getCvDatas()
                      Swal.fire({
                        icon: 'success',
                        title: `${isEnglish? 'Success': 'Başarılı'}`,
                      })
                    } else {
                      Swal.fire({
                        icon: 'error',
                        title: `${isEnglish? 'Error': 'Hata'}`,
                        text: `${isEnglish? 'An error has occured': 'CV varsayılan yapılırken bir hata meydana geldi.'}`
                      })
                    }
                  } catch (error) {
                    console.log(`Error: ${error}`)
                    Swal.fire({
                      icon: 'error',
                      title: `${isEnglish? 'Error': 'Hata'}`,
                      text: `${isEnglish? 'An error has occured': 'CV varsayılan yapılırken beklenmedik bir hata meydana geldi.'}`
                    })
                  }
                }}
                >
                  <IoCheckmarkCircleOutline/>
                  {isEnglish? 'Set as Default': 'Varsayılan Yap'}
                </button>
              )}
              <button className="flex flex-row items-center gap-2 font-poppins text-sm bg-white text-gray-700 hover:text-white hover:bg-mains py-1 px-2 border border-gray-200 whitespace-nowrap transition-all" onClick={() => setHeaderFormOpen(true)}>
                <TbEditCircle/>
                {isEnglish? 'Update Title': 'Başlığı Güncelle'}
              </button>
              
              <button
              className="flex flex-row items-center gap-2 font-poppins text-sm bg-white text-gray-700 hover:text-white hover:bg-mains py-1 px-2 border border-gray-200 whitespace-nowrap transition-all"
              onClick={async () => {
                try {
                  const response = await axios.userAuthRequest({
                    endpoint: `user/profilim/cv/${cvId}/update-date`,
                    type: 'put',
                    payload: {}
                  })

                  if (response && response.status == 200) {

                    getCvDatas()
                    setIsMorePopupOpen(false)
                    Swal.fire({
                      icon: 'success',
                      title: 'Başarılı'
                    })
                  } else {
                    Swal.fire({
                      icon: 'error',
                      title: 'Hata',
                      text: 'Tarih güncellenirken bir hata meydana geldi.'
                    })
                    setIsMorePopupOpen(false)
                  }
                } catch (error) {
                  console.log(`Error: ${error}`)
                  Swal.fire({
                    icon: 'error',
                    title: 'Hata',
                    text: 'Tarih güncellenirken beklenmedik bir hata meydana geldi.'
                  })
                }
              }}
              >
                <FaCalendar/>
                {isEnglish? 'Update Date': 'Tarihi Güncelle'}
              </button>
              <Link
              className="flex flex-row items-center gap-2 font-poppins text-sm bg-white text-gray-700 hover:text-white hover:bg-mains py-1 px-2 border border-gray-200 whitespace-nowrap transition-all"
              to={`/profilim/cv/goruntule/${cvId}`}
              >
                <FaEye/>
                {isEnglish? 'View': 'Görüntüle'}
              </Link>
              <Link
              to={`/profilim/cv/goruntule/${cvId}?action=share`}
              className="flex flex-row items-center gap-2 font-poppins text-sm bg-white text-gray-700 hover:text-white hover:bg-mains py-1 px-2 border border-gray-200 whitespace-nowrap transition-all" 
              >
                <IoDocument/>
                {isEnglish? 'Share': 'Paylaş'}
              </Link>
              <Link
              to={`/profilim/cv/goruntule/${cvId}?action=print`}
              className="flex flex-row items-center gap-2 font-poppins text-sm bg-white text-gray-700 hover:text-white hover:bg-mains py-1 px-2 border border-gray-200 whitespace-nowrap transition-all" >
                <FaPrint/>
                {isEnglish? 'Print': 'Yazdır'}
              </Link>
              <Link
              to={`/profilim/cv/goruntule/${cvId}?action=download`}
              className="flex flex-row items-center gap-2 font-poppins text-sm bg-white text-gray-700 hover:text-white hover:bg-mains py-1 px-2 border border-gray-200 whitespace-nowrap transition-all" >
                <IoDocument/>
                {isEnglish? 'Download PDF': 'PDF Olarak İndir'}
              </Link>
              {selectedCv?.is_default != 1 && (
                <button 
                className="flex flex-row items-center gap-2 font-poppins text-sm bg-white text-gray-700 hover:text-white hover:bg-mains py-1 px-2 border border-gray-200 whitespace-nowrap transition-all"
                onClick={() => {
                  Swal.fire({
                    icon: 'warning',
                    title: `${isEnglish? '': 'İşlemi Onaylayın'}`,
                    text: `${isEnglish? 'Are you sure to delete this CV?': "Bu özgeçmişi silmek istediğinizden emin misiniz?"}`,
                    preConfirm: () => {
                      deleteCv()
                    },
                    confirmButtonText: `${isEnglish? 'Yes': 'Evet'}`,
                    cancelButtonText: `${isEnglish? 'No': 'Hayır'}`,
                    showCancelButton: true
                  })
                }}>
                  <IoTrashBinOutline/>
                  {isEnglish? 'Delete': 'Sil'}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      
    </div>
  )

  async function updateCvTitle() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/${cvId}/update-cv-title`,
        type: 'put',
        payload: {
          title: cvTitle
        }
      })

      if (response && response.status == 200) {
        getCvDatas()
        setHeaderFormOpen(false)
      } else {
        setTitleUpdateError('CV başlığı güncellenirken bir hata meydana geldi.')
      }
    } catch (error) {
      console.log(`Error: ${error}`)
      setTitleUpdateError('CV başlığı güncellenirken beklenmedik bir hata meydana geldi.')
    }
  }

  async function deleteCv() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/delete`,
        type: 'delete',
        payload: {}
      })

      if (response && response.status) {
        navigate('/profilim/cv')
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Hata',
          text: 'CV silinirken bir hata meydana geldi.'
        })
      }
    } catch (error) {
      console.log(`Error: ${error}`)
      Swal.fire({
        icon: 'error',
        title: 'Hata',
        text: 'CV silinirken beklenmedik bir hata meydana geldi.'
      })
    }
  }
};

export default DefaultCv;
