import React, { useState } from "react";
import {
  IconBuilding,
  IconMapPin,
  IconBriefcase,
  IconCalendarMonth,
} from "@tabler/icons-react";
import ApplicationPopup from "../application-card-for-ad-details/components/application-popup";
import { FaRegBuilding, FaHeart, FaRegHeart } from "react-icons/fa6";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import Axios from "../../../services/axios";
import {
  IoBriefcaseOutline,
  IoCalendarOutline,
  IoLocationOutline,
} from "react-icons/io5";

interface AdvertDetails {
  id: number;
  created_at: string;
  advert: any;
  title: string;
  companyLogo: string;
  company_detail: any;
  company_name: string; // Assuming this exists at the advert level
  working_types: any[]; // Replace `any` with more specific type if possible
  cities: any[];
  is_favorite: boolean; // Add the is_favorite property
  // Add other necessary fields
}

interface IApplicationCardProps {
  readonly data: AdvertDetails;
  readonly isSponsored: boolean;
  readonly setAdverts?: React.Dispatch<React.SetStateAction<any[]>>; // Properly type setAdverts
}

const ApplicationCard: React.FC<IApplicationCardProps> = (
  props: IApplicationCardProps
) => {
  const axios = new Axios();
  const [isPopup, setIsPopup] = useState<boolean>(false);
  const { data, isSponsored, setAdverts } = props;

  const backgroundColor = isSponsored ? "bg-[#FFF5DE]" : "bg-gray-200";
  let adUrl = "/is-ilanlari/" + data.advert?.slug;

  const truncate = (text: string, length: number) => {
    return text?.length > length ? text?.slice(0, length) + "..." : text;
  };

  const dateFormat = (date: string) => {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString("tr-TR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const userToken = Cookies.get("userToken");

  const [isFavorited, setIsFavorited] = useState<boolean>(data.is_favorite);
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleisFavorite = async (
    id: number,
    isCurrentlyFavorited: boolean
  ) => {
    if (isLoading) return

    setIsLoading(true)
    if (userToken) {
      try {
        if (isCurrentlyFavorited) {
          await axios.request({
            type: "delete",
            endpoint: `adverts/${id}/remove-favorite`,
            payload: {},
          });
          Swal.fire({
            icon: "success",
            title: "Favorilerden Kaldırıldı",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          await axios.request({
            type: "post",
            endpoint: `adverts/${id}/add-favorite`,
            payload: {},
          });
          Swal.fire({
            icon: "success",
            title: "Favorilere Eklendi",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        // Update the data state to reflect the favorite change
        if (setAdverts) {
          setAdverts((prevState: any) => {
            return prevState.map((el: any) => {
              if (el.advertId === id) {
                return {
                  ...el,
                  is_favorite: !el.is_favorite,
                };
              }
              return el;
            });
          });
        }
        setIsFavorited(!isFavorited);
      } catch (err) {
        console.log("Error:", err);
        Swal.fire({
          icon: "error",
          title: "Bir hata oluştu",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
    setIsLoading(false)
  };

  const convertDateFormat = (createdAt: string): string => {
    const date: Date = new Date(createdAt);
    const today: Date = new Date();

    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return "Bugün";
    } else {
      const options: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      };
      return date.toLocaleDateString("tr-TR", options);
    }
  };

  return (
    <div
      key={data?.advert?.id}
      className={`${backgroundColor} relative w-full rounded-md lg:rounded-none !border !border-gray-300 lg:!border-transparent h-[96px] lg:h-32 px-2 md:px-4 md:py-2 gap-x-2 md:gap-x-5 flex hover-border-animation lg:shadow-md`}
    >
      <div className="flex-shrink-0 w-1/5 lg:w-1/6 flex justify-center items-center ">
        <a href={adUrl}>
          <img
            className="object-contain h-full max-w-[55px] md:max-w-[81px] max-h-[55px] md:max-h-[81px] "
            src={data.company_detail?.logo || "https://via.placeholder.com/150"}
            alt="Company Logo"
          />
        </a>
      </div>

      <div
        onMouseEnter={() => setIsPopup(true)}
        onMouseLeave={() => setIsPopup(false)}
        className="flex flex-col items-start justify-center w-5/6 group md:gap-2"
      >
        <div className="hidden xl:block">
          <ApplicationPopup
            advertId={data?.advert?.id || 2}
            isPopup={isPopup}
          />
        </div>

        {isSponsored && (
          <label className="text-[#b29709] font-poppins poppins-bold text-[8px] block lg:hidden">
            SPONSORLU
          </label>
        )}

        <div className="flex items-center justify-start w-full h-auto">
          <div className="flex items-center justify-between w-full">
            <h3>
              <a
                target="blank"
                href={adUrl}
                className="text-main text-xs lg:text-lg poppins-semibold md:poppins-medium  text-[#2253af] w-[100%] line-clamp-2"
              >
                <span>{data?.advert?.title}</span>
              </a>
            </h3>

            {isSponsored && (
              <span className="text-sm text-[#b29709] hidden lg:block">
                Sponsorlu
              </span>
            )}
          </div>
        </div>

        <div className="flex items-center justify-between w-full h-auto lg:items-center gap-x-1">
          <FaRegBuilding className="hidden text-xs text-gray-600 lg:flex lg:text-base" />
          <span className="md:poppins-regular poppins-medium text-[10px] lg:text-xs text-gray-500 w-full line-clamp-1">
            {truncate(data?.company_detail?.company_name, 15)}
          </span>
          <div onClick={async () => {
            if (isLoading) return
            handleisFavorite(data.advert.id, isFavorited)
          }}>
            {userToken && (
              <>
                {isFavorited ? (
                  <FaHeart className="text-sm text-red-500" />
                ) : (
                  <FaRegHeart className="text-sm text-red-500" />
                )}
              </>
            )}
          </div>
        </div>

        <div className="flex flex-row items-end justify-between w-full h-auto">
          <div className="flex flex-col justify-between w-1/2 lg:flex-row ">
            <div className="flex items-center justify-start w-full h-full gap-1 lg:gap-x-1">
              <IoBriefcaseOutline className="text-xs text-gray-600 lg:text-base" />
              <span className="poppins-light text-[8px] lg:text-[10px] text-gray-600 w-full truncate">
                {data.working_types?.slice(-1).map((working_type) => {
                  return (
                    <div key={working_type.id}>
                      {truncate(working_type.title, 12)}
                    </div>
                  );
                })}
              </span>
            </div>
            <div className="flex items-center justify-start w-full h-full gap-1 lg:gap-x-1">
              <IoLocationOutline className="flex-shrink-0 text-xs text-gray-600 lg:text-base" />
              <span className="poppins-light text-[8px] lg:text-[10px] text-gray-600 w-full truncate">
                {data.cities?.map((city) => city.name).join(", ")}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-start gap-1 ml-auto lg:gap-x-1">
            <IoCalendarOutline className="text-xs text-gray-600 lg:text-base" />
            <span className="poppins-light text-[8px] lg:text-[10px] text-gray-600 w-full truncate">
              {convertDateFormat(data.advert.created_at)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationCard;
