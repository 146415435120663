import { createContext, ReactNode, useContext } from "react";
import classNames from "classnames";

// Breadcrumb Context
type breadcrumbContextType = {
  separator: ReactNode;
};

export const BreadcrumbContext = createContext<breadcrumbContextType>({
  separator: "/",
});

type breadcrumbPropsType = {
  children: ReactNode;
  separator?: ReactNode;
  className?: string;
};

export function Breadcrumb({
  children,
  separator = "/",
  className,
}: breadcrumbPropsType) {
  const cn = classNames({
    "flex items-center": true,
    [className as string]: !!className,
  });

  return (
    <BreadcrumbContext.Provider value={{ separator }}>
      <nav className={cn}>{children}</nav>
    </BreadcrumbContext.Provider>
  );
}
