/* eslint-disable react-hooks/exhaustive-deps */
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import React, { memo, useEffect, useState } from "react";
import services from "../../../../services/search-services";
import { IItem } from "../filter-section-content";
import { JobsPageContext, jobsPageContextType } from "..";
import { useContext } from "react";
import { Checkbox } from "antd";
import { FaRegTrashAlt } from "react-icons/fa";
import Axios from "../../../../services/axios";
import Swal from "sweetalert2";

interface IFilterCategoryAccordion {
  maxSelect?: number;
  name: string;
  initialSelectedItems?: IItem[];
  initialNewSelectedItems?: IItem[];
  placeHolder?: string;
  api?: {
    endoint: string;
    apiWay: string;
    propertyName: string;
  };
  items?: IItem[];
  isOpen?: boolean;
  onChange?: (e: IItem[]) => void;
  deleteItem?: boolean;
}

const FilterCategoryAccordion: React.FC<IFilterCategoryAccordion> = (
  props: IFilterCategoryAccordion
) => {
  const [selectedItems, setSelectedItems] = useState<IItem[]>([]);
  const [newSelected, setNewSelected] = useState<IItem[]>([]);
  const [isActive, setIsActive] = useState(props.isOpen || false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState<IItem[]>(
    props.items ? props.items : []
  );
  const { selectedValues, newSelectedValues }: jobsPageContextType =
    useContext(JobsPageContext);
  const axios = new Axios();

  const selectedValuesLength =
    selectedValues.advertDate.length +
    selectedValues.cities.length +
    selectedValues.districts.length +
    selectedValues.disabled.length +
    selectedValues.experience.length +
    selectedValues.other.length +
    selectedValues.positionLevels.length +
    selectedValues.positions.length +
    selectedValues.sections.length +
    selectedValues.sectors.length +
    selectedValues.workingLocations.length +
    selectedValues.company.length +
    selectedValues.search.length +
    selectedValues.workingTypes.length;

  // Update filteredItems when props.items changes
  useEffect(() => {
    //console.log(props.items);
    setFilteredItems(props.items ? props.items : []);
  }, [props.items]);

  //FIXME: Bu kısım sürekli döngüye sebep oluyor bu yüzden kaldırıldı buglar tespit edilip düzeltilecek
  useEffect(() => {
    setSelectedItems(props.initialSelectedItems || []);
  }, [props.initialSelectedItems]);

  useEffect(() => {
    setNewSelected(props.initialNewSelectedItems || []);
  }, [props.initialNewSelectedItems]);

  useEffect(() => {
    const delay = setTimeout(() => {
      search();
    }, 500);

    return () => {
      clearTimeout(delay);
    };
  }, [searchTerm, selectedItems]);

  useEffect(() => {
    isActive && search();
  }, [isActive]);

  const search = async () => {
    if (props.items) {
      setFilteredItems(
        props.items?.filter((d) =>
          d.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
        )
      );
      return;
    }

    if (props.api && isActive) {
      const options = await services.searchServicesCustom(
        props.api.endoint,
        searchTerm,
        "[" + newSelected.map((el) => el.id).join(",") + "]",
        10 + newSelected.length
      );

      setFilteredItems(
        options.data[props.api.apiWay].map((el: any) => ({
          [props.api!.propertyName]: el[props.api!.propertyName],
          id: el.id,
          slug: el.slug,
        }))
      );
    }
  };

  const isChecked = (id: number) => {
    return newSelected.some((item) => item.id === id);
  };

  const addOrRemoveItem = (el: any) => {
    const type = isChecked(el.id) ? "remove" : "add";
    let newSelectedItems: any;

    if (type === "add") {
      if (props.maxSelect && newSelected.length === props.maxSelect) {
        newSelectedItems = [el];
      } else {
        newSelectedItems = [...newSelected, el];
      }
    } else {
      newSelectedItems = newSelected.filter(
        (item: any) =>
          item[props.api?.propertyName || "title"] !==
          el[props.api?.propertyName || "title"]
      );
    }

    setTimeout(() => setNewSelected(newSelectedItems), 30)
    //setNewSelected(newSelectedItems);
  };

  useEffect(() => {
    //FIXME: Bu kısım yukarıdaki props.initialSelectedItemsa bağlı useEffectin sürekli triggerlanmasına sebep oluyor

    if (
      JSON.stringify(props.initialNewSelectedItems) !==
      JSON.stringify(newSelected)
    ) {
      props.onChange && props.onChange(newSelected);
    }
  }, [newSelected]);

  const RenderTitle = (title: string) => {
    const searchTermLocale = searchTerm.toLocaleLowerCase("tr-TR");
    const titleLocale = title.toLocaleLowerCase("tr-TR");

    const parts = titleLocale.split(searchTermLocale);

    if (parts.length <= 1) return title; // No match found

    let reconstructedTitle = "";
    let currentIndex = 0;

    parts.forEach((part, index) => {
      if (index > 0) {
        const matchStart = titleLocale.indexOf(searchTermLocale, currentIndex);
        const originalMatch = title.substring(
          matchStart,
          matchStart + searchTerm.length
        );
        reconstructedTitle += `<span style="font-weight: 600;"><u>${originalMatch}</u></span>`;
        currentIndex = matchStart + searchTerm.length;
      }
      reconstructedTitle += title.substring(
        currentIndex,
        currentIndex + part.length
      );
      currentIndex += part.length;
    });

    return reconstructedTitle;
  };

  const handleDeleteItem = async (item: IItem) => {
    Swal.fire({
      title: "İşlemi Onaylayın",
      text: "Kayıtlı filtrelemeyi silmek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
      cancelButtonText: "İptal",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const params = {
            create_alarm: "on",
            period: "MONTHLY",
            city_id: "83",
            search_type: "all",
            position: ["8454"],
            advert_date: "all",
            country: ["1"],
            disabled: "all",
          };

          const response = await axios.userAuthRequest({
            type: "delete",
            endpoint: `user/profilim/kayitli-aramalar/delete/${item.id}`,
            payload: {
              data: {
                title: item.title,
                params: JSON.stringify(params),
                send_emails: 1,
                period: "DAILY",
              },
            },
          });

          console.log(response, "response");
          setFilteredItems(filteredItems.filter((i) => i.id !== item.id));
        } catch (error) {
          console.error("Error fetching data:", error);
        }

        Swal.fire({
          title: "Başarılı!",
          text: "Filtreleme başarıyla silindi.",
          icon: "success",
          confirmButtonText: "Tamam",
        });
      }
    });
  };

  return (
    <div className="w-full bg-gray-200 border border-gray-400 rounded select-none">
      {/** Accordion Header */}
      <div
        onClick={() => {
          setIsActive(!isActive);
        }}
        className="flex items-center justify-between w-full p-3 cursor-pointer"
      >
        <div className="text-sm text-gray-500 poppins-semibold">
          {props.name}
        </div>
        <IconChevronDown
          className={`${isActive && "rotate-180"} transition-all`}
        />
      </div>
      {/** Accordion Body */}
      <div className={`transition-all ${isActive ? "h-42" : "h-0"}`}>
        {isActive && (
          <div className="w-full">
            {props.placeHolder && (
              <div className="flex items-center w-11/12 px-2 py-2 mx-auto mb-2 bg-white border border-black rounded gap-x-2">
                <IconSearch size={18} />
                <input
                  className="h-6 text-sm bg-transparent outline-none poppins-medium"
                  placeholder={props.placeHolder}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            )}

            {selectedValuesLength > 0 && (
              <div className="flex flex-col gap-y-2">
                {selectedItems.map((item: any, i) => {
                  return (
                    <div key={i} className="flex items-center px-4 gap-x-2">
                      {props.maxSelect === 1 ? (
                        <input
        
                          type="radio"
                          checked={isChecked(item.id)}
                          className="radio"
                          onChange={(e) => {
                            addOrRemoveItem(item);
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          checked={isChecked(item.id)}
                          className="test"
                          onChange={(e) => {
                            addOrRemoveItem(item);
                          }}
                        />
                      )}

                      <div
                        onClick={() => {
                          addOrRemoveItem(item);
                        }}
                        className="text-sm text-gray-600 poppins-medium"
                        dangerouslySetInnerHTML={{
                          __html: RenderTitle(
                            item[
                              props.api?.propertyName
                                ? props.api?.propertyName
                                : "title"
                            ]
                          ),
                        }}
                      />
                    </div>
                  );
                })}

                <div className="w-full bg-gray-300 p-[1px] my-1"></div>
              </div>
            )}

            <div className="flex flex-col mb-2 overflow-auto gap-y-3 lg:gap-y-[10px] max-h-36 mt-3">
              {filteredItems
                .filter(
                  (el) =>
                    el.id !==
                    selectedItems.find((compare) => compare.id === el.id)?.id
                )
                .map((item: any, i) => {
                  return (
                    <div key={i} className="flex items-center px-4 gap-x-2">
                      <>
                        {props.maxSelect === 1 ? (
                          <input
                            type="radio"
                            checked={isChecked(item.id)}
                            className="radio"
                            onChange={(e) => {
                              addOrRemoveItem(item);
                            }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            checked={isChecked(item.id)}
                            className="test"
                            onChange={(e) => {
                              addOrRemoveItem(item);
                            }}
                          />
                        )}

                        <div
                          onClick={() => {
                            addOrRemoveItem(item);
                          }}
                          className="text-sm text-gray-600 poppins-medium"
                          dangerouslySetInnerHTML={{
                            __html: RenderTitle(
                              item[
                                props.api?.propertyName
                                  ? props.api?.propertyName
                                  : "title"
                              ]
                            ),
                          }}
                        />
                        {props.deleteItem === true && (
                          <FaRegTrashAlt
                            className="text-red-500 cursor-pointer"
                            onClick={() => {
                              handleDeleteItem(item);
                            }}
                          />
                        )}
                      </>
                    </div>
                  );
                })}

              {/*{selectedItems.slice(selectedValuesLength).map((item, i) => {*/}
              {/*  return (*/}
              {/*    <div key={i} className="flex items-center px-4 gap-x-2">*/}
              {/*      <>*/}
              {/*        <input*/}
              {/*          type="checkbox"*/}
              {/*          checked={isChecked(item.title)}*/}
              {/*          className="test"*/}
              {/*          onChange={(e) => {*/}
              {/*            addOrRemoveItem(item);*/}
              {/*          }}*/}
              {/*        />*/}
              {/*        <div*/}
              {/*          onClick={() => {*/}
              {/*            addOrRemoveItem(item);*/}
              {/*          }}*/}
              {/*          className="text-sm text-gray-600 poppins-medium"*/}
              {/*          dangerouslySetInnerHTML={{*/}
              {/*            __html: RenderTitle(item.title + " asd2"),*/}
              {/*          }}*/}
              {/*        />*/}
              {/*      </>*/}
              {/*    </div>*/}
              {/*  );*/}
              {/*})}*/}

              {/*{filteredItems?.map((item, i) => {*/}
              {/*  if (*/}
              {/*    selectedItems.find(*/}
              {/*      (selectedItem) => selectedItem.id === item.id*/}
              {/*    )*/}
              {/*  ) {*/}
              {/*    return null;*/}
              {/*  } else {*/}
              {/*    return (*/}
              {/*      <div key={i} className="flex items-center px-4 gap-x-2">*/}
              {/*        <>*/}
              {/*          {props.deleteItem !== true && (*/}
              {/*            <input*/}
              {/*              type="checkbox"*/}
              {/*              checked={isChecked(item.title)}*/}
              {/*              className="test"*/}
              {/*              onChange={(e) => {*/}
              {/*                addOrRemoveItem(item);*/}
              {/*              }}*/}
              {/*            />*/}
              {/*          )}*/}

              {/*          <div*/}
              {/*            onClick={() => {*/}
              {/*              addOrRemoveItem(item);*/}
              {/*            }}*/}
              {/*            className={`text-sm text-gray-600 poppins-medium  ${*/}
              {/*              props.deleteItem === true ? "w-full" : ""*/}
              {/*            } `}*/}
              {/*            dangerouslySetInnerHTML={{*/}
              {/*              __html: RenderTitle(item.title),*/}
              {/*            }}*/}
              {/*          />*/}
              {/*          {props.deleteItem === true && (*/}
              {/*            <FaRegTrashAlt*/}
              {/*              className="text-red-500 cursor-pointer"*/}
              {/*              onClick={() => {*/}
              {/*                handleDeleteItem(item);*/}
              {/*              }}*/}
              {/*            />*/}
              {/*          )}*/}
              {/*        </>*/}
              {/*      </div>*/}
              {/*    );*/}
              {/*  }*/}
              {/*})}*/}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(FilterCategoryAccordion);
