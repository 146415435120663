import React from "react";
import ProfileForm from "../company-info-form";
import SocialInfoForm from "../social-info-form";
import CompanyChangePassword from "../change-password-form";
import CompanyEmailNotification from "../email-notification-form";
import CompanySmsNotification from "../sms-notification-form";
import { useState } from "react";
import { Modal, Input, Button } from "antd";
import Axios from "../../../../services/axios";
import Swal from "sweetalert2";
import { isAxiosError } from "axios";

type Props = {};

const axios = new Axios();

const ProfileTabSection = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [password, setPassword] = useState<string>("");
  return (
    <>
      <ProfileForm />
      <SocialInfoForm />
      <CompanyChangePassword />
      <CompanyEmailNotification />
      <CompanySmsNotification />
      <div className="text-start">
        <span onClick={showModal} className="text-gray-500 cursor-pointer underline underline-offset-4 ">
          Üyelikten Ayrılmak İstiyorum
        </span>
      </div>

      <Modal
        title={
          <div style={{ textAlign: "center", width: "100%" }}>
            <span>UYARI</span>
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div className="w-full flex justify-end border-t border-color-[#dee2e6] px-[24px] py-[12px] gap-4">
            <Button
              className="bg-gray-200 text-black font-poppins text-sm px-5 py-4 flex justify-center items-center"
              key="Hayır"
              onClick={handleCancel}
            >
              Hayır
            </Button>

            <Button
              onClick={deleteAccount}
              className="bg-red-500 text-white font-poppins text-sm px-5 py-4 flex justify-center items-center"
              key="Evet"
            >
              Evet
            </Button>
          </div>,
        ]}
      >
        <div>
          <p className="text-red-500 font-poppins text-sm pb-4 border-b">
            Firma hesabınız silindiğinde, Oluşturulmuş ilanlar, gelen başvurular
            ve <span className="poppins-semibold">satın aldığınız haklar</span>{" "}
            silinecektir. E-posta ve şifre geçerliliğini yitireceğinden tekrar
            aktif edilemez. Firma hesabınızı silme işlemine devam etmek istiyor
            musunuz?
          </p>

          <Input.Password
            placeholder="Parolanız"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            style={{ height: "40px" }}
          />
        </div>
      </Modal>
    </>
  );

  async function deleteAccount() {
    try {
      const response = await axios.authRequest({
        endpoint: `companies/account-settings/delete-account?password=${password}`,
        type: "delete",
        payload: {},
      });

      if (response && response.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Başarılı.",
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Hesap silinirken bir hata meydana geldi.",
        });
      }
    } catch (error: any) {
      const defaultErrorMessage = "Hesap silinirken bir hata meydana geldi.";

      if (
        isAxiosError(error) &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        Swal.fire({
          icon: "warning",
          title: error.response.data.error || defaultErrorMessage,
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: error.response.data.error || defaultErrorMessage,
        });
      }
    }
  }
};

export default ProfileTabSection;
