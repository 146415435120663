import { useEffect, useState, useRef } from "react";
import { CorporateService } from "../../../services/corporate-services";
import { useQuery, useQueryClient } from "@tanstack/react-query";

function useCorporateContent({ slug }: { slug: string }) {
  const [content, setContent] = useState({ title: "", detail: "" });
  const fetchedSlugs = useRef(new Set());
  const queryClient = useQueryClient();

  useEffect(() => {
    const fetchContentIfNeeded = async () => {
      if (!fetchedSlugs.current.has(slug)) {
        fetchedSlugs.current.add(slug);
        await queryClient.fetchQuery({
          queryKey: ["corporateContent", slug],
          queryFn: () => CorporateService.getCorporateContent(slug),
        });
      }
    };

    fetchContentIfNeeded();
  }, [slug, queryClient]);

  const { data, isLoading } = useQuery({
    queryKey: ["corporateContent", slug],
    queryFn: () => CorporateService.getCorporateContent(slug),
    staleTime: Infinity,
  });

  useEffect(() => {
    if (data) {
      console.log("data", data.data.data.content);
      setContent({
        title: data.data.data.title ?? "",
        detail: data.data.data.content ?? "",
      });
    }
  }, [data]);

  return {
    title: content.title,
    detail: content.detail,
    loading: isLoading,
    content:content
  };
}

export { useCorporateContent };
