import { IoInformationCircleOutline } from "react-icons/io5";
import { FaRegCheckCircle } from "react-icons/fa";
import { PiUserCirclePlusBold } from "react-icons/pi";
import { CiCircleChevUp } from "react-icons/ci";
import React, { useContext, useEffect, useState } from "react";
import useFetchCdnQuery from "../../../../../hooks/useFetchCdnQuery";
import Axios from "../../../../../services/axios";
import { ApplyPoolContext, CandidatePoolFilterProps } from "../../index";
import { SelectComponent } from "../components/components/select-component-new";
import { IoIosSearch } from "react-icons/io";

const axios = new Axios();

const TriggerSection: React.FC = () => {
  const {
    newSelectedValues,
    setNewSelectedValues,
    detailFiltersOpen,
    setDetailFiltersOpen,
    setSyncCounter,
    setAdvertStatus,
    isInit,
    advertdata,
    setAdvertStatusData,
    applyInfo,
  } = useContext(ApplyPoolContext);

  const [search, setSearch] = useState<string>(newSelectedValues.search || "");
  useEffect(() => {
    const debouncedSearch = () => {
      if (!isInit) return;
      if (search.length < 3) {
        let newValues: CandidatePoolFilterProps = {
          ...newSelectedValues,
        };
        delete newValues.search;
        setNewSelectedValues(newValues);
        setSyncCounter((prev) => prev + 1);
      } else {
        let newValues: CandidatePoolFilterProps = {
          ...newSelectedValues,
          search: search,
        };
        setNewSelectedValues(newValues);
        setSyncCounter((prev) => prev + 1);
      }
    };
    const timer = setTimeout(() => {
      debouncedSearch();
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  const [applicationStatusData, setApplicationStatusData] = useState<any>([]);
  const applicationStatusQuery = useFetchCdnQuery(
    "application-status",
    "get-advert-apply-statuses",
    "advert_apply_statuses"
  );
  useEffect(() => {
    if (applicationStatusQuery.data !== undefined) {
      setApplicationStatusData([
        { id: 100, name: "", name_tr_company: "Tüm Başvurular", order: 0 },
        ...applicationStatusQuery?.data,
      ]);
      setAdvertStatusData(applicationStatusQuery?.data);
      let status: any = {};
      applicationStatusQuery.data.forEach((el: any) => {
        status[el.name] = el.name_tr_company;
      });
      setAdvertStatus(status);
    }
  }, [applicationStatusQuery.data, applicationStatusQuery.fetchStatus]);

  return (
    <section className="flex flex-col gap-2 mt-2  ">
      <div className=" mt-1 rounded shadow border py-5  mx-3 md:mx-0">
        <h1 className="md:text-xl text-lg text-primary200 ml-5 ">
          BAŞVURULAR / {advertdata?.advert?.title}
        </h1>
      </div>
      <div className="flex flex-col">
        <div className="flex shadow flex-row overflow-x-auto">
          <div className="flex-1 md:h-36 py-3 border flex items-center flex-col justify-center min-w-56">
            <IoInformationCircleOutline className="w-10 h-10  " />
            <p className="text-gray-600 text-lg">Toplam Başvuru</p>
            <p className="text-black text-2xl font-bold">
              {applyInfo?.total_apply}
            </p>
          </div>
          <div className="flex-1 h-36  md:h-36 py-3 border flex items-center flex-col justify-center min-w-56">
            <FaRegCheckCircle className="w-8 h-8  " />
            <p className="text-gray-600 text-lg">Yeni Başvuru</p>
            <p className="text-black text-2xl font-bold">
              {applyInfo?.total_new_apply}
            </p>
          </div>
          <div className="flex-1 h-36  md:h-36 py-3 border flex items-center flex-col justify-center min-w-56">
            <PiUserCirclePlusBold className="w-8 h-8  " />
            <p className="text-gray-600 text-lg">Seçilen Başvuru</p>
            <p className="text-black text-2xl font-bold">
              {applyInfo?.total_suitable_apply}
            </p>
          </div>
          <div className="flex-1 h-36  md:h-36 py-3 border flex items-center flex-col justify-center text-center min-w-56">
            <CiCircleChevUp className="w-8 h-8  " />
            <p className="text-gray-600 text-lg">İlan Görüntüleme Sayısı</p>
            <p className="text-black text-2xl font-bold">
              {applyInfo?.total_user_advert_view}
            </p>
          </div>
        </div>
        <div className="flex-1 hidden  md:flex justify-end items-center gap-2 pr-5 shadow border-b py-3 rounded-t-md  ">
          <button
            type="button"
            className=" px-8 py-[.35rem] bg-orng100 text-white rounded  text-left text-lg font-semibold  "
            onClick={() => {
              setDetailFiltersOpen(!detailFiltersOpen);
            }}
          >
            Filtrele
          </button>
          <div className="w-48">
            <SelectComponent
              keyString={"name_tr_company"}
              data={applicationStatusData.filter((el: any) => {
                return el.name !== "ROLLBACK";
              })}
              label=""
              onChange={(value) => {
                let selected = applicationStatusData.filter(
                  (el: any) => el.id === value
                );
                let newValues: CandidatePoolFilterProps = {
                  ...newSelectedValues,
                  status: selected.length > 0 ? selected[0].name : "",
                };
                if (value === 100 || selected.length === 0) {
                  delete newValues.status;
                }
                setNewSelectedValues(newValues);
                setSyncCounter((prev) => prev + 1);
              }}
              selectedvalue={
                newSelectedValues.status
                  ? applicationStatusData.filter(
                      (el: any) => el.name === newSelectedValues.status
                    )[0]?.id
                  : 100
              }
            />
          </div>

          <div className="w-60">
            <div className="flex-1 w-full relative">
              <div
                className={`${
                  true ? "border-primary200 text-primary200" : "border-gray-400"
                } bg-white border  text-sm md:text-base shadow-sm px-4 py-2 w-full flex justify-between items-center text-left rounded`}
              >
                <input
                  value={search}
                  placeholder={"Ara"}
                  type="text"
                  className={`${
                    true ? "placeholder-primary200" : "placeholder-gray-600"
                  }   text-primary200 w-full h-full outline-none poppins-regular `}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                <IoIosSearch className="w-6 h-6" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { TriggerSection };
