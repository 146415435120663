import React from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";

export default function Comments() {
  const [emblaRef] = useEmblaCarousel(
    {
      loop: true,
    },
    [
      /* Autoplay({
            delay: 3000
        }) */
    ]
  );
  return (
    <div className="mt-10">
      <div className="embla" ref={emblaRef}>
        <div className="embla__container">
          <div className="embla__slide">
            <div className="p-12 relative text-[#555555] bg-white text-lg rounded-3xl">
              Kısa sürede aradığımız birçok elemanı bulabildik, isbul.net
              alanında en iyi firmalardan biri. Ücretsiz hakları ile orta
              ölçekli işverenlere destek olan isbul.net'e çok teşekkür ederiz.
            </div>
            <div className="triangle-white-bigger rotate-180 ml-20" />
            <div className="flex flex-col gap-2 ml-20">
              <label className="text-title text-xl font-semibold">
                Gencay KETE
              </label>
              <label className="text-gray-600 text-sm">
                Bilgisayar Mühendisi
              </label>
            </div>
          </div>
          <div className="embla__slide">
            <div className="p-12 relative text-[#555555] bg-white text-lg rounded-3xl">
              Mavi yaka yada beyaz yaka fark etmeksizin birçok farklı
              pozisyondaki personel ihtiyacımızı kısa sürede giderdik. Ücretsiz
              üyelik ve her ay ücretsiz ilan yayınlanabilirliği ile bizim gibi
              kobilere büyük yardım sağlıyorlar.
            </div>
            <div className="triangle-white-bigger rotate-180 ml-20" />
            <div className="flex flex-col gap-2 ml-20">
              <label className="text-title text-xl font-semibold">
                Mehmet YILDIZ
              </label>
              <label className="text-gray-600 text-sm">
                Akbank A.Ş. Personel Daire Bşk.
              </label>
            </div>
          </div>
          <div className="embla__slide">
            <div className="p-12 relative text-[#555555] bg-white text-lg rounded-3xl">
              Şirketimizin personel arayışı için uzun yıllardır isbul.net ile
              çalışıyoruz. Ücretsiz üyelik ve uygun fiyatlı ilan, doping
              paketleri ile hem ekonomik bir kullanım sağlıyor hemde aradığımız
              personeli gelen başvurular ile hemen bulabiliyoruz.
            </div>
            <div className="triangle-white-bigger rotate-180 ml-20" />
            <div className="flex flex-col gap-2 ml-20">
              <label className="text-title text-xl font-semibold">
                Mehmet YILDIZ
              </label>
              <label className="text-gray-600 text-sm">
                Akbank A.Ş. Personel Daire Bşk.
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
