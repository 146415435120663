import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Axios from "../../../../services/axios";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const Body: React.FC = () => {
  const axios = new Axios();
  const location = useLocation();
  const navigate = useNavigate();
  const [editorData, setEditorData] = useState<string>("");

  const handleEditorChange = (event: any, editor: any) => {
    const data = editor.getData() || "";
    setEditorData(data);
  };

  const path = location.pathname; // '/profilim/basvurular/993/on-yazi-ekle'
  const segments = path.split("/"); // ['','profilim','basvurular','993','on-yazi-ekle']
  const id = segments[3]; // '993'

  useEffect(() => {
    const fetchOnYazi = async () => {
      try {
        const response = await axios.request({
          endpoint: `user/profilim/applies/get-applies`,
          type: "get",
          payload: {},
        });

        if (response?.data?.applies) {
          const apply = response.data.applies.find(
            (item: any) => item.id === parseInt(id)
          );
          if (apply) {
            setEditorData(apply.cover_letter || "");
          }
        }

        console.log(response);
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchOnYazi();
  }, [id]);

  const stripHtmlTags = (html: string) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const handleChangeOnYazi = async () => {
    console.log('change text')
    const plainText = stripHtmlTags(editorData);

    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/applies/update/${id}`,
        type: "put",
        payload: {
          text: plainText,
        },
      });


      console.log(response);

      if (response.status === 200) {
        navigate("/profilim/başvurular");
        Swal.fire({
          icon: "success",
          title: "Başarılı",
          text: "Ön yazı başarıyla Eklendi",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full flex flex-col">
      <div className="mt-4">
        <CKEditor
          editor={ClassicEditor}
          data={editorData}
          onChange={handleEditorChange}
        />
      </div>

      <div onClick={handleChangeOnYazi}>
        <button className="bg-mains text-white text-xl font-poppins poppins-medium px-4 py-2 rounded-md mt-4">
          Kaydet
        </button>
      </div>
    </div>
  );
};

export default Body;
