import React, { useState, useEffect } from "react";
import "leaflet/dist/leaflet.css";
import PersonalInformation from "../../../../components/pages/MyResumes/CreateCV/PersonalInformation";
import { FaTimes } from "react-icons/fa";
import { LuLoader2 } from "react-icons/lu";
import { useCookie } from "../../../../hooks/useCookie";
import { CookieName } from "../../../../constants/enums";
import Axios from "../../../../services/axios";
import CvSelect from "./components/CvSelect";
import EducationAddOrUpdateForm from "../../../profile-page-container/cv-edit/contents/education-information/components/EducationAddOrUpdateForm";
import AdvertApply from "./components/AdvertApply";
import WorkExperienceForm from "../../../profile-page-container/cv-edit/contents/work-experiences/components/WorkExperienceForm";
import { HiOutlineExclamationCircle } from "react-icons/hi2";
import AppliedSuccess from "./components/AppliedSuccess";
import WorkExperienceFormComponent from "../../../profile-page-container/cv-edit/contents/work-experiences/components/WorkExperienceFormComponent";

const axios = new Axios();
interface WorkingLocationPopupProps {
  onClose: () => void;
  onSuccess?: () => void;
  advertId: number;
  adData: any;
}

const AdvertApplyPopup: React.FC<WorkingLocationPopupProps> = ({
  adData,
  onClose,
  onSuccess,
  advertId,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cvs, setCvs] = useState([]);
  const [error, setError] = useState(false);
  const [inSteps, setInSteps] = useState<boolean>(false);
  const [step, setStep] = useState(0);
  const [selectedCvsId, setSelectedCvsId] = useState<number>(0);
  const [selectedCvs, setSelectedCvs] = useState<any | null>(null);

  const [isPer, setIsPer] = useState<boolean>(false);
  const [isEdu, setIsEdu] = useState<boolean>(false);
  const [isExp, setIsExp] = useState<boolean>(false);

  const { getCookie } = useCookie();
  const companyToken = getCookie(CookieName.CompanyUserToken);
  const userToken = getCookie(CookieName.UserToken);
  const TOKEN = userToken ? userToken : companyToken;

  const handleCvSelectStep = (cvId: any = undefined) => {
    Promise.all([
      axios.userAuthRequest({
        endpoint: "user/profilim/cv/miss-info/" + (cvId ? cvId : selectedCvsId),
        type: "get",
        payload: {},
      }),
      axios.userAuthRequest({
        endpoint:
          "user/profilim/cv/edu/index?cv_id=" + (cvId ? cvId : selectedCvsId),
        type: "get",
        payload: {},
      }),
      axios.userAuthRequest({
        endpoint:
          "user/profilim/cv/exp/index?cv_id=" + (cvId ? cvId : selectedCvsId),
        type: "get",
        payload: {},
      }),
    ])
      .then((response) => {
        let pers, edu, exp;

        pers =
          response[0].data.data.filter(
            (el: any) => el.name === "Kişisel Bilgiler"
          ).length > 0;
        edu = response[1].data.educations.length > 0;
        exp = response[2].data.job_experiences.length > 0;
        setIsPer(pers);
        setIsEdu(!edu);
        setIsExp(!exp);

        let step_to_jump = 4;
        if (!exp) step_to_jump = 3;
        if (!edu) step_to_jump = 2;
        if (pers) step_to_jump = 1;
        setStep(step_to_jump);
        setLoading(false);
        setInSteps(true);
      })
      .catch(() => {
        setError(true);
      });
  };

  const STEPS: any[] = [
    {
      component: (
        <CvSelect
          setSelectedCvsId={setSelectedCvsId}
          setSelectedCvs={setSelectedCvs}
          adData={adData}
          cvs={cvs}
          inPopup={true}
          onSuccess={(id: any) => {
            handleCvSelectStep(id);
          }}
          onReady={() => {
            setLoading(false);
          }}
        />
      ),
    },
    {
      component: (
        <div>
          <div className={"p-5"}>
            <div className={"flex rounded-xl overflow-hidden shadow-xl"}>
              <div
                className={
                  "flex justify-center items-center bg-[#ff7f1d] text-white px-3 text-xl"
                }
              >
                <HiOutlineExclamationCircle />
              </div>
              <div className={"p-5"}>
                <div className={"text-lg font-bold"}>
                  Kişisel Bilgileri Eksik
                </div>
                <div>
                  Başvurularda ön plana çıkabilmek için Kişisel bilgilerini
                  özgeçmişine eklemen gerekiyor.
                </div>
              </div>
            </div>
          </div>
          <PersonalInformation
            inPopup={true}
            onSuccess={() => {
              setLoading(true);
              if (isEdu) {
                setStep(2);
              } else if (isExp) {
                setStep(3);
              } else {
                setStep(4);
              }
            }}
            onReady={() => {
              setLoading(false);
            }}
          />
        </div>
      ),
    },
    {
      component: (
        <div>
          <div className={"p-5"}>
            <div className={"flex rounded-xl overflow-hidden shadow-xl"}>
              <div
                className={
                  "flex justify-center items-center bg-[#ff7f1d] text-white px-3 text-xl"
                }
              >
                <HiOutlineExclamationCircle />
              </div>
              <div className={"p-5"}>
                <div className={"text-lg font-bold"}>
                  Eğitim Bilgileri Eksik
                </div>
                <div>
                  Başvurularda ön plana çıkabilmek için Eğitim Bilgilerini
                  özgeçmişine eklemen gerekiyor.
                </div>
              </div>
            </div>
          </div>
          <EducationAddOrUpdateForm
            isEnglish={selectedCvs ? selectedCvs.lang === "en" : false}
            inPopup={true}
            cvId={selectedCvsId + ""}
            onCancel={() => {
              onClose && onClose();
            }}
            onSuccess={() => {
              setLoading(true);
              if (isExp) {
                setStep(3);
              } else {
                setStep(4);
              }
            }}
            onReady={() => {
              setLoading(false);
            }}
          />
        </div>
      ),
    },
    {
      component: (
        <div>
          <div className={"p-5"}>
            <div className={"flex rounded-xl overflow-hidden shadow-xl"}>
              <div
                className={
                  "flex justify-center items-center bg-[#ff7f1d] text-white px-3 text-xl"
                }
              >
                <HiOutlineExclamationCircle />
              </div>
              <div className={"p-5"}>
                <div className={"text-lg font-bold"}>
                  İş Deneyimi Bilgileri Eksik
                </div>
                <div>
                  Başvurularda ön plana çıkabilmek için İş Deneyimi bilgilerini
                  özgeçmişine eklemen gerekiyor.{" "}
                  <span
                    onClick={() => {
                      setStep(4);
                    }}
                    className={"text-mains underline cursor-pointer ml-2"}
                  >
                    ATLA
                  </span>
                </div>
              </div>
            </div>
          </div>
          <WorkExperienceFormComponent
            isEnglish={selectedCvs ? selectedCvs.lang === "en" : false}
            cvId={selectedCvsId + ""}
            onCancel={() => {
              onClose && onClose();
            }}
            onSuccess={() => {
              setStep(4);
            }}
            onReady={() => {
              setLoading(false);
            }}
          />
        </div>
      ),
    },
    {
      component: (
        <AdvertApply
          adData={adData}
          cvs={selectedCvs}
          inPopup={true}
          onSuccess={() => {
            onSuccess && onSuccess();
            setStep(5);
          }}
          onReady={() => {
            setLoading(false);
          }}
        />
      ),
    },
    {
      component: (
        <AppliedSuccess
          adData={adData}
          cvs={selectedCvs}
          inPopup={true}
          onCancel={() => {
            handleCloseClick();
          }}
          onReady={() => {
            setLoading(false);
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    let config = {
      type: "get",
      endpoint: "user/profilim/cv/all",
      payload: {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      },
    };

    axios
      .request(config)
      .then((response: any) => {
        if (response.data.data) {
          if (response.data.data.length > 1) {
            // birden fazla cv varsa
            setCvs(response.data.data);
            setSelectedCvsId(
              response.data.data[0] !== undefined ? response.data.data[0].id : 0
            );
            setSelectedCvs(
              response.data.data[0] !== undefined ? response.data.data[0] : {}
            );
            setStep(0);
            setInSteps(true);
          } else {
            setCvs(response.data.data);
            setSelectedCvsId(
              response.data.data[0] !== undefined ? response.data.data[0].id : 0
            );
            setSelectedCvs(
              response.data.data[0] !== undefined ? response.data.data[0] : {}
            );
            handleCvSelectStep(
              response.data.data[0] !== undefined ? response.data.data[0].id : 0
            );
          }
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      document.body.classList.add("overflow-hidden", "h-auto");
      setIsVisible(true);
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  const handleCloseClick = (isSucces = false) => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
      document.body.classList.remove("overflow-hidden", "h-auto");
      if (isSucces && onSuccess) {
        onSuccess();
      }
    }, 500);
  };

  return (
    <div className="fixed inset-0 z-[1050] flex items-start justify-center overflow-y-auto ">
      <div
        className="fixed inset-0 bg-black opacity-50 h-auto "
        onClick={() => handleCloseClick()}
      ></div>

      <div
        className={`bg-white my-10 max-md:my-0 max-md:fixed max-md:top-0 max-md:left-0 max-md:w-screen max-md:h-screen max-md:overflow-y-auto md:rounded-lg md:shadow-lg  md:relative ${
          loading
            ? ""
            : step === 2 || step === 3
            ? "md:min-w-[80%]"
            : step === 0 || step === 4 || step === 5
            ? "md:min-w-[40%]"
            : ""
        } ${
          step === 3 || step === 4 || step === 5 ? "md:max-w-[40%]" : ""
        } transition-all duration-500 ease-in-out transform ${
          isVisible ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-10"
        }`}
      >
        <div
          className={`grid ${
            step !== 5 ? "grid-cols-3" : "grid-cols-2"
          } items-center border-b-2 py-5 px-5`}
        >
          {step !== 5 && <div></div>}
          <div
            className={`text-mains text-nowrap ${
              step !== 5 ? "justify-self-center" : "justify-self-start"
            } `}
          >
            {step === 5 ? "BAŞVURUNUZ GÖNDERİLDİ" : "BAŞVURU"}
          </div>
          <div
            onClick={() => handleCloseClick()}
            className={
              "flex justify-self-end justify-center items-center w-5 h-5 "
            }
          >
            <FaTimes className={"text-gray-400"} />
          </div>
        </div>

        {loading && (
          <div
            className={"flex p-10 w-full h-full justify-center items-center"}
          >
            <LuLoader2 className={"w-14 h-14 text-gray-400 animate-spin"} />
          </div>
        )}

        {!error && inSteps && (
          <div className={`${loading ? "hidden" : ""}`}>
            {STEPS[step].component}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdvertApplyPopup;
