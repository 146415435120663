const Why: React.FC = () => {
    return (
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="p-6">
          <h1 className="text-2xl">Yazılımcı Nedir?, Nasıl Olunur?</h1>
          <p className='text-[#63636c] mt-4'>
            Ağ Yöneticisi nedir, ne iş yapar, nasıl olunur? Ağ Yöneticisi olmak için gereken eğitimler,
            bireysel özellikler nelerdir? Ağ Yöneticisi nerelerde çalışabilir?
            Ağ Yöneticisi arayan firmalar hangileridir? Ağ Yöneticisi
            mesleğiyle ilgili tüm soruların cevaplarını isbul.net Meslek Rehberi’nde bulabilirsiniz.
          </p>
        </div>
      </div>
    );
  };
  
  export default Why;
  