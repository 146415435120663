import { LuLoader2 } from "react-icons/lu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Axios from "../../services/axios";
import { VscError } from "react-icons/vsc";
import { FaRegCheckCircle } from "react-icons/fa";

const axios = new Axios();
const VerifyPage = () => {
  // page states 0-validating,1-validate success,2 validate fail
  const [pageState, setPageState] = useState<number>(0);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search).get("token");

  useEffect(() => {
    if (searchParams && searchParams !== "") {
      console.log("doing validation");
      // confirm-verify-email?token=xswwntugnxattNOf9koxqzbusJsfOqLCaJARE0ROw9DxRifBWFd1Ajj8vIrb93kt
      axios
        .request({
          type: "get",
          endpoint: `confirm-verify-email?token=${searchParams}`,
          payload: {},
        })
        .then((response: any) => {
          if (response.data.status_code === 200) {
            setPageState(1);
            setTimeout(() => {
              navigate("/");
            }, 5000);
          } else {
            setPageState(2);
          }
        })
        .catch((error: any) => {
          setPageState(2);
        });
    } else {
      setPageState(2);
    }
  }, []);
  console.log(searchParams);
  return (
    <div className={"min-h-0.5 flex justify-center items-center"}>
      <div className={"container"}>
        <div className={"flex justify-center p-10"}>
          {pageState === 0 && (
            <div className={"h-48 w-48"}>
              <LuLoader2
                className={"animate-spin h-full w-full text-gray-400"}
              />
            </div>
          )}
          {pageState === 1 && (
            <div
              className={
                "flex flex-col justify-center items-center text-center "
              }
            >
              <div
                className={
                  "text-2xl font-bold text-gray-700 py-5 border-b-2 mb-10"
                }
              >
                E-mail adresinizi doğrulandı.
              </div>
              <div className={"w-36 h-36"}>
                <FaRegCheckCircle className={"h-full w-full text-green-500 "} />
              </div>
              <div className={"text-gray-700 my-10"}>
                E-mail adresiniz başarılı bir şekilde doğrulandı. Birazdan sizi
                Ana sayfaya yönlendireceğiz. yönlendirme çalışmazsa{" "}
                <Link
                  to={"/"}
                  className={"cursor-pointer text-mains underline"}
                >
                  buraya
                </Link>{" "}
                tıklayarak Ana sayfaya gidebilirsiniz.
              </div>
            </div>
          )}
          {pageState === 2 && (
            <div
              className={
                "flex flex-col justify-center items-center text-center "
              }
            >
              <div
                className={
                  "text-2xl font-bold text-gray-700 py-5 border-b-2 mb-10"
                }
              >
                Üzgünüz! E-mail adresinizi doğrulayamadık.
              </div>
              <div className={"w-36 h-36"}>
                <VscError className={"h-full w-full text-red-500 "} />
              </div>
              <div className={"text-gray-700 py-10"}>
                Geçerli bir Url girdiğinizden ve mail ile size gönderilen Url yi
                eksiksiz şekilde tarayıcınıza kopyaladığınızdan emin olun
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyPage;
