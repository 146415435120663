import React, {
  useState,
  ChangeEvent,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import AddDetail from "../../../../components/common/add-create-form/addDetail";
import CompanyDetailAndDescription from "../../../../components/common/add-create-form/company-detail-and-description";
import ExtraInformation from "./../../../../components/common/add-create-form/extra-information";
import TemplateSetting from "././../../../../components/common/add-create-form/template-setting";
import AddQuestion from "./././../../../../components/common/add-create-form/add-question";
import Conditions from "../../../../components/common/add-create-form/conditions";
import SaveAndNextuton from "../../../../components/common/add-create-form/save-and-next-button";
import Axios from "../../../../services/axios";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useData } from "../../../../store/contextApi/ad-api-context/get-ad-data";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Button from "../../../../components/form-components/button";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
export interface MapLocation {
  lat: number;
  longitude: number;
}
interface Option {
  value: string;
  label: string;
}
interface Location {
  city: number;
  district: number;
}

export interface Question {
  id: number;
  title: string;
  is_required: boolean;
}
export interface QuestionToAdd {
  question: number;
  is_required: boolean;
}

export interface AdvertPayload {
  [key: string]: any;
  title: string;
  map_location: MapLocation | null;
  sections: number[]; // Array of section IDs
  sectors: number[]; // Array of sector IDs
  positions: number[]; // Array of position IDs
  working_types: number[]; // Array of working type IDs
  working_locations: number[]; // Array of working location IDs
  position_levels: number[]; // Array of position level IDs
  experience_level_type: "not-matter" | "experienced" | "inexperienced";
  experience_level_min: number;
  experience_level_max: number;
  education_levels: number[]; // Array of education level IDs
  employee_count: number;
  min_age: number;
  max_age: number;
  gender: number; // Gender ID
  language: number; // Primary language ID
  languages: number[]; // Array of language IDs
  country: number; // Country ID
  keywords: string[]; // Array of keywords
  detail: string; // Detailed description
  hide_contact_info: "show_all" | "show_gsm" | "show_phone" | "hide_all";
  hide_employee_info: boolean;
  hide_company_info: boolean;
  locations: Location[]; // Array of locations (city and district IDs)
  job_benefits: number[]; // Array of job benefit IDs
  driving_licence_srcs: number[]; // Array of driving license source IDs
  is_send_message_users: boolean;
  is_disabled_employee: boolean;
  template_type: "DEFAULT" | "MAP" | "IMAGE" | "MAP-IMAGE";
  questions: { question: number; is_required: boolean }[];
  terms: number;
  advert_file: number;
}
const languageIdMap: { [key: string]: number } = {
  English: 1,
  Türkçe: 2,
  // other languages...
};
function AdvertCreateForm({
  mobileActivePage,
  setMobileActivePage,
}: {
  mobileActivePage?: number;
  setMobileActivePage?: Dispatch<SetStateAction<number>>;
}) {
  const { advertId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [initialFormData, setInitialFormData] = useState<AdvertPayload | null>(
    null
  );
  const [formData, setFormData] = useState<AdvertPayload>({
    title: "",
    locations: [{ city: 0, district: 0 }], // For Cities Districts select input
    map_location: null, // For map input
    positions: [],
    position_levels: [],
    sections: [],
    sectors: [],
    working_types: [],
    working_locations: [], // For /Uzakta/Hibert....
    languages: [],
    education_levels: [],
    experience_level_type: "not-matter", // Default value
    language: 9,
    experience_level_min: 0,
    experience_level_max: 0,
    keywords: [],
    detail: "",
    hide_employee_info: false,
    hide_company_info: false,
    hide_contact_info: "show_all", // Default value
    driving_licence_srcs: [],
    job_benefits: [],
    is_send_message_users: false,
    is_disabled_employee: false,
    template_type: "DEFAULT", // Default value
    questions: [], // Default empty question

    min_age: 18, // Default minimum age
    max_age: 40, // Default maximum age
    gender: 0,
    country: 0,

    employee_count: 0,
    terms: 0,
    advert_file: 0,
  });
  //const { data, isLoading, error } = useData();

  const contextData = useData();

  const { data, isLoading, error } = contextData;

  //console.log('context data', contextData)
  console.log("formData", formData);
  useEffect(() => {
    if (!isLoading && !error && data) {
     //console.log("form data context", data, isLoading, error);

      const contextInitialFormData: AdvertPayload = {
        title: data?.advert?.title || "",
        // Provide default values or fetched data for all required fields
        map_location: data?.map_location || null,
        sections:
          data?.sections?.map((s: any) => {
            return s?.section?.id;
          }) || [],
        sectors:
          data?.sectors?.map((s: any) => {
            return s?.sector?.id;
          }) || [],
        positions:
          data?.positions?.map((p: any) => {
            return p?.position?.id;
          }) || [],
        working_types:
          data?.working_types?.map((w: any) => {
            return w?.working_type?.id;
          }) || [],
        working_locations:
          data?.working_locations?.map((w: any) => {
            return w?.working_location?.id;
          }) || [],
        position_levels:
          data?.position_levels?.map((p: any) => {
            return p?.position_level?.id;
          }) || [],
        //TODO: experience level servisten null döndü tekrar form oluşturup denenecek
        experience_level_type:
          data?.experience_level == null
            ? "not-matter"
            : experienceLevelTypeConverter(
                data?.experience_level?.experience_level_min.id ?? null
              ),
        //TODO: değer olarak value girildi test edilecek
        experience_level_min:
          data?.experience_level?.experience_level_min?.value,
        //TODO: değer olarak value girildi test edilecek
        experience_level_max:
          data?.experience_level?.experience_level_max?.value,
        education_levels:
          data?.education_levels?.map((e: any) => {
            return e?.education_level?.id;
          }) || [],
        //TODO: Eklendi test edilip gözlemlenecek
        employee_count: !isNaN(
          parseInt(data?.company_detail.employee_count || "0")
        )
          ? parseInt(data?.company_detail.employee_count || "0")
          : 0,
        //TODO: Min age formda veri olarak gönderilmedi ama tiplere geçilmiş
        min_age: data?.advert?.min_age,
        //TODO: Max age formda veri olarak gönderilmedi ama tiplere geçilmiş
        max_age: data?.advert?.max_age,
        gender: data?.advert?.gender,
        language: data?.advert.general_language_id || 9,
        languages: data?.languages?.map((l) => {
          return l?.language?.id;
        }),
        country: data.advert.country_id,
        keywords: data.keywords.map((k: any) => {
          return k.keyword;
        }),
        detail: data.advert.detail,
        hide_contact_info: advertContactInfoTypeConverter(
          data.advert.hide_contact_info
        ),
        hide_employee_info: data.advert.hide_employee_info,
        hide_company_info: data.advert.hide_company_info,
        locations:
          data?.locations?.map((l: any) => {
            return { city: l?.city?.id, district: l?.district?.id };
          }) || [],
        job_benefits: data?.job_benefits?.map((j) => {
          return j?.job_benefit?.id;
        }),
        //TODO: Normalde tipler aşağıdaki gibi girilmiş ama hatalı düzeltilecek
        /* driving_licence_srcs: data?.driving_licence_srcs?.map((s) => {
					return s.id
				}) || [], */
        driving_licence_srcs: data?.driving_licence_srcs?.map((d) => {
          return d?.driving_licence_src?.id;
        }),
        driving_licence_classes: data?.driving_licence_classes?.map((d) => {
          return d?.driving_licence_class?.id
        }),
        is_send_message_users: data?.advert?.is_send_message_users,
        is_disabled_employee: data?.advert?.is_disabled_employee,
        template_type: advertTemplateTypeConverter(data?.advert?.template_type),
        questions: data?.questions.map((q: any) => {
          return {
            is_required: q.is_required,
            question: q.company_question.id,
          };
        }),
        //TODO: veri olarak gelmemiş kontrol edilecek
        terms: 0,
        //TODO: Formda dosya gönderilip denenecek belki hatalı olabilir
        advert_file: data.advert_file.company_file_id,
      };

      //console.log('initial form data', contextInitialFormData)
      setInitialFormData(contextInitialFormData);
      setFormData(contextInitialFormData);
    }
  }, [data, isLoading, error]);

  type templateTypeType = "DEFAULT" | "MAP" | "IMAGE" | "MAP-IMAGE";

  function advertTemplateTypeConverter(
    templateType?: string
  ): templateTypeType {
    if (templateType == "DEFAULT") return "DEFAULT";
    if (templateType == "MAP") return "MAP";
    if (templateType == "IMAGE") return "IMAGE";
    if (templateType == "MAP-IMAGE") return "MAP-IMAGE";
    return "DEFAULT";
  }

  type contactInfoType = "show_all" | "show_gsm" | "show_phone" | "hide_all";

  function advertContactInfoTypeConverter(
    contactInfo?: string
  ): contactInfoType {
    if (contactInfo == "show_all") return "show_all";
    if (contactInfo == "show_gsm") return "show_gsm";
    if (contactInfo == "show_phone") return "show_phone";
    if (contactInfo == "hide_all") return "hide_all";
    return "show_all";
  }

  type experienceLevelType = "not-matter" | "experienced" | "inexperienced";

  function experienceLevelTypeConverter(
    experienceLevelMin?: number | null
  ): experienceLevelType {
    if (!experienceLevelMin || experienceLevelMin == null) return "not-matter";
    if (experienceLevelMin == 41) return "inexperienced";
    if (experienceLevelMin > 41) return "experienced";
    return "not-matter";
  }

  const [fieldErrors, setFieldErrors] = useState({
    title: false, // İlan Başlığı
    positions: false, // Pozisyon Adı
    position_levels: false, // Pozisyon Seviyesi
    sections: false, // Bölüm
    sectors: false, // Sektor
    working_types: false, // Çalışma Şekli
    working_locations: false, // Çalışma Tercihi
    languages: false, // Yabancı Dil
    education_levels: false, // Eğitim Seviyesi
  });

  /* useEffect(() => {
		console.log('use effect initial form data', initialFormData)
		
	}, [initialFormData]) */

  const validateForm = () => {
    const errors = {
      title: false,
      city: false,
      district: false,
      positions: false,
      position_levels: false,
      sections: false,
      sectors: false,
      working_types: false,
      working_locations: false,
      languages: false,
      education_levels: false,
    };

    errors.title = !formData.title.trim();
    for (const location of formData.locations) {
			if (location.city == 0) {
				errors.city = true
			}
			if (location.district == 0) {
				errors.district = true
			}
		}
    errors.positions = formData.positions.length === 0;
    //errors.position_levels = formData.position_levels.length === 0;
    errors.sections = formData.sections.length === 0;
    errors.sectors = formData.sectors.length === 0;
    errors.working_types = formData.working_types.length === 0;
    errors.working_locations = formData.working_locations.length === 0;
    //errors.languages = formData.languages.length === 0;
    errors.education_levels = formData.education_levels.length === 0;

    setFieldErrors(errors);
    console.log("Updated fieldErrors:", errors);
    return Object.values(errors).every((value) => !value);
  };
  /* Fetcihng ad Data to show it befor doing the edit  */

  //console.log(data, "company22");
  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const { companyEmail } = location.state || {};
  const handleCheckboxArrayChange = (value: number, key: string) => {
    setFormData((prevState) => {
      // Assuming the array contains numbers based on your interface definitions
      const array = (prevState[key] as number[]) || [];
      const newArray = array.includes(value)
        ? array.filter((item: number) => item !== value)
        : [...array, value];
      return { ...prevState, [key]: newArray };
    });
  };

  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    isNested: boolean = false,
    nestedKey?: string
  ) => {
    const target = event.target as HTMLInputElement; // Assumption made here for simplification
    const { name, value, type } = target;
    const checked = type === "checkbox" ? target.checked : undefined;

    if (isNested && nestedKey) {
      // Handle nested objects like map_location
      setFormData((prevState) => ({
        ...prevState,
        [nestedKey]: {
          ...prevState[nestedKey],
          [name]: type === "checkbox" ? checked : value,
        },
      }));
    } else if (type === "checkbox") {
      // Handle checkboxes
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked,
      }));
    } else {
      //console.log(`Field: ${name}, Value: ${value}`);
      // Handle regular inputs
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handleChangeCheckBox = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    isNested: boolean = false,
    nestedKey?: string
  ) => {
    // Assert event.target as HTMLInputElement for checkbox handling
    const target = event.target as HTMLInputElement;
    const { name, value, type, checked } = target;

    if (type === "checkbox" && name === "terms") {
      // Special handling for the 'terms' checkbox
      setFormData((prevState) => ({
        ...prevState,
        [name]: checked ? 1 : 0,
      }));
    } else if (type === "checkbox") {
      // Handle other checkboxes, if any
    } else {
      // Handle other types of inputs
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const handleDropdownChange = (id: string, selectedOptions: Option[]) => {
    setFormData((prevState) => ({
      ...prevState,
      [id]: selectedOptions.map((option) => option.value), // Update the appropriate field based on the dropdown's ID
    }));
  };
  const handleLanguageChange = (languageId: number) => {
    setFormData((prevState) => ({
      ...prevState,
      language: languageId,
    }));
  };
  const handleExperienceLevelChange = (values: [number, number]) => {
    setFormData((prevState) => ({
      ...prevState,
      experience_level_min: values[0],
      experience_level_max: values[1],
    }));
  };
  const handleKeywordsChange = (newKeywords: string[]) => {
    setFormData((prevState) => ({
      ...prevState,
      keywords: newKeywords,
    }));
  };
  const handleDetailChange = (detail: string) => {
    setFormData((prevState) => ({
      ...prevState,
      detail: detail,
    }));
  };

  const handleHideContactInfoChange = (
    value: "show_all" | "show_gsm" | "show_phone" | "hide_all"
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      hide_contact_info: value,
    }));
  };

  const handleHideCompanyInfoChange = (value: boolean) => {
    setFormData((prevState) => ({
      ...prevState,
      hide_company_info: value,
    }));
  };

  const handleHideEmployeeInfoChange = (value: boolean) => {
    setFormData((prevState) => ({
      ...prevState,
      hide_employee_info: value,
    }));
  };
  const handleYanHaklarChange = (selectedOptions: Option[]) => {
    const jobBenefits = selectedOptions.map((option) => {
      //console.log("Converting option value to number:", option.value); // Debug log
      return Number(option.value);
    });
    //console.log("Updated job benefits:", jobBenefits); // Check the results
    setFormData((prevState) => ({
      ...prevState,
      job_benefits: jobBenefits.filter((value) => !isNaN(value)), // Ensure only valid numbers are included
    }));
  };

  // Handler for Sürücü Lisansları selection
	const handleSurucuLisanslariChange = (selectedOptions: Option[]) => {
		const drivingLicenceSrcs = selectedOptions.map((option) =>
			Number(option.value)
		); // Assuming the values are IDs
		setFormData((prevState) => ({
			...prevState,
			driving_licence_classes: drivingLicenceSrcs,
		}));
	};


	const handleSrcLisanslariChange = (selectedOptions: Option[]) => {
		const drivingLicenceSrcs = selectedOptions.map((option) =>
			Number(option.value)
		); // Assuming the values are IDs
		setFormData((prevState) => ({
			...prevState,
			driving_licence_srcs: drivingLicenceSrcs,
		}));
	};

  const handleIsSendMessageUsersChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      is_send_message_users: event.target.value === "1", // Assuming '1' represents true/yes
    }));
  };

  const handleIsDisabledEmployeeChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      is_disabled_employee: event.target.checked,
    }));
  };
  const handleTemplateTypeChange = (
    templateType: "DEFAULT" | "MAP" | "IMAGE" | "MAP-IMAGE"
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      template_type: templateType,
    }));
  };
  const handleLocationChange = (newLocations: Location[]) => {
    setFormData((prevState) => ({
      ...prevState,
      locations: newLocations,
    }));
  };

  const clearQuestionsForm = () => {
    setFormData((prev) => ({
      ...prev,
      questions: [],
    }));
  };
  const addQuestionToForm = (newQuestion: {
    id: number;
    is_required: boolean;
  }) => {
    setFormData((prevState) => ({
      ...prevState,
      questions: [
        ...prevState.questions,
        { question: newQuestion.id, is_required: newQuestion.is_required },
      ],
    }));
  };
  const updateMapLocation = (newLocation: MapLocation | null) => {
    setFormData({ ...formData, map_location: newLocation });
  };
  const handleImageSelect = (imageId: number) => {
    setFormData((prevState) => ({
      ...prevState,
      advert_file: imageId,
    }));
  };
  const handleSubmit = async () => {

    const isValid = validateForm()
    
    if (!isValid) {
      const errors = {
				title: !formData.title.trim(),
				city: false,
				district: false,
				positions: false,
				position_levels: false,
				sections: false,
				sectors: false,
				working_types: false,
				working_locations: false,
				education_levels: false,
				detail: false,
			};
	
			errors.title = !formData.title.trim();
			for (const location of formData.locations) {
				if (location.city == 0) {
					errors.city = true
				}
				if (location.district == 0) {
					errors.district = true
				}
			}
			errors.positions = formData.positions.length === 0;
			//errors.position_levels = formData.position_levels.length === 0
			errors.sections = formData.sections.length === 0;
			errors.sectors = formData.sectors.length === 0;
			errors.working_types = formData.working_types.length === 0;
			errors.working_locations = formData.working_locations.length === 0;
			errors.education_levels = formData.education_levels.length === 0;
			errors.detail = formData.detail.length < 140;

      const missingFieldsHtml = `
			<div style="color: red;">
			${errors.title? 'İlan Başlığı <br/>': ''}
			${errors.city? 'Şehir <br/>': ''}
			${errors.district? 'İlçe <br/>': ''}
			${errors.positions? 'Pozisyon Adı <br/>': ''}
			${errors.position_levels? 'Pozisyon Seviyesi <br/>': ''}
			${errors.sections? 'Bölüm <br/>': ''}
			${errors.sectors? 'Sektör <br/>': ''}
			${errors.working_types? 'Çalışma Şekli <br/>': ''}
			${errors.working_locations? 'Çalışma Tercihi <br/>': ''}
			${errors.education_levels? 'Eğitim Seviyesi <br/>': ''}
			${errors.detail? 'Şirket / İş Tanımı / Aranan Nitelikler <br/>': ''}
			</div>
			`

			Swal.fire({
				icon: "error",
				title: "Uyarı:",
				html: `Lütfen aşağıdaki alanları doldurun: ${missingFieldsHtml}`,
				confirmButtonText: "OK",
				customClass: {
					confirmButton: "sweet-alert-button", // Optionally add styles with TailwindCSS
				},
			});
			console.log("Validation failed, errors:", fieldErrors);
			return; // Stop the submission if validation fails
    }
    
    if (formData.terms !== 1) {
      Swal.fire({
        icon: "error",
        title: "Uyarı:",
        html: `Gizlilik sözleşmesi alanı zorunludur.`,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "sweet-alert-button", // Optionally add styles with TailwindCSS
        },
      });
      return;
    }
    //console.log(formData, "formData");
    const axiosInstance = new Axios();
    try {
      const response = await axiosInstance.authRequest({
        type: "put",
        endpoint: `companies/advert/update/${advertId}`,
        payload: formData,
      });
      //console.log(response);
      if (response.status) {
        //console.log("Entere");
        navigate(`/isveren/ilanlar/ilan/${advertId}/onizleme`);
      } else {
        // Handle non-successful responses
        console.error("Submission failed with status:", response.status);
        // Optionally, show an error message to the user
      }
      // Handle success (e.g., show a success message or redirect)
    } catch (error) {
      console.error(error);
      // Handle error (e.g., show an error message)
    }
  };

  //console.log('formData', formData)

  // Mobile page next
  // Page 1
  function mobilePage1Confirm() {
    if (setMobileActivePage) {
			validateForm()
			
			const errors = {
				title: !formData.title.trim(),
				city: false,
				district: false,
				positions: false,
				position_levels: false,
				sections: false,
				sectors: false,
				working_types: false,
				working_locations: false,
				education_levels: false,
				detail: false,
			};
	
			errors.title = !formData.title.trim();
			for (const location of formData.locations) {
				if (location.city == 0) {
					errors.city = true
				}
				if (location.district == 0) {
					errors.district = true
				}
			}

			errors.positions = formData.positions.length === 0;
			//errors.position_levels = formData.position_levels.length === 0
			errors.sections = formData.sections.length === 0;
			errors.sectors = formData.sectors.length === 0;
			errors.working_types = formData.working_types.length === 0;
			errors.working_locations = formData.working_locations.length === 0;
			errors.education_levels = formData.education_levels.length === 0;

			let isError: boolean = Object.values(errors).some((value) => value == true)

			if (isError) {
				const missingFieldsHtml = `
				<div style="color: red;">
				${errors.title? 'İlan Başlığı <br/>': ''}
				${errors.city? 'Şehir <br/>': ''}
				${errors.district? 'İlçe <br/>': ''}
				${errors.positions? 'Pozisyon Adı <br/>': ''}
				${errors.position_levels? 'Pozisyon Seviyesi <br/>': ''}
				${errors.sections? 'Bölüm <br/>': ''}
				${errors.sectors? 'Sektör <br/>': ''}
				${errors.working_types? 'Çalışma Şekli <br/>': ''}
				${errors.working_locations? 'Çalışma Tercihi <br/>': ''}
				${errors.education_levels? 'Eğitim Seviyesi <br/>': ''}
				</div>
				`
				Swal.fire({
					icon: 'error',
					title: 'Uyarı',
					html: missingFieldsHtml
				})
			} else {
				setMobileActivePage((prev) => prev + 1)
				window.scrollTo({ top:0, behavior: 'smooth'})
			}
			
		}
  }

  // Page2
	function mobilePage2Confirm() {
		if (setMobileActivePage) {

			const errors = {
				title: !formData.title.trim(),
				city: false,
				district: false,
				positions: false,
				position_levels: false,
				sections: false,
				sectors: false,
				working_types: false,
				working_locations: false,
				education_levels: false,
				detail: false,
			};
			errors.title = !formData.title.trim();
			for (const location of formData.locations) {
				if (location.city == 0) {
					errors.city = true
				}
				if (location.district == 0) {
					errors.district = true
				}
			}

			errors.positions = formData.positions.length === 0;
			//errors.position_levels = formData.position_levels.length === 0
			errors.sections = formData.sections.length === 0;
			errors.sectors = formData.sectors.length === 0;
			errors.working_types = formData.working_types.length === 0;
			errors.working_locations = formData.working_locations.length === 0;
			errors.education_levels = formData.education_levels.length === 0;
			errors.detail = formData.detail.length < 140 ;
			
			let isError: boolean = Object.values(errors).some((value) => value == true)

			if (isError) {
				const missingFieldsHtml = `
				<div style="color: red;">
				${errors.title? 'İlan Başlığı <br/>': ''}
				${errors.city? 'Şehir <br/>': ''}
				${errors.district? 'İlçe <br/>': ''}
				${errors.positions? 'Pozisyon Adı <br/>': ''}
				${errors.position_levels? 'Pozisyon Seviyesi <br/>': ''}
				${errors.sections? 'Bölüm <br/>': ''}
				${errors.sectors? 'Sektör <br/>': ''}
				${errors.working_types? 'Çalışma Şekli <br/>': ''}
				${errors.working_locations? 'Çalışma Tercihi <br/>': ''}
				${errors.education_levels? 'Eğitim Seviyesi <br/>': ''}
				${errors.detail? 'Şirket / İş Tanımı/ Aranan Nitelikler <br/>': ''}
				</div>
				`
				Swal.fire({
					icon: 'error',
					title: 'Uyarı',
					html: missingFieldsHtml
				})
			} else {
				setMobileActivePage((prev) => prev + 1)
				window.scrollTo({ top: 0, behavior: 'smooth' })

			}
		}
	}
  return (
    <div className="w-full shadow-lg font-poppins">
      <div
        className={`${
          mobileActivePage == 1 ? "block" : "hidden md:block"
        } w-full`}
      >
        <AddDetail
          fieldErrors={fieldErrors}
          companyEmail={companyEmail}
          onDropdownChange={handleDropdownChange}
          handleChange={handleChange}
          handleLanguageChange={handleLanguageChange}
          onExperienceLevelChange={handleExperienceLevelChange}
          onKeywordsChange={handleKeywordsChange}
          onLocationChange={handleLocationChange}
          updateMapLocation={updateMapLocation}
          formData={initialFormData}
          setFormData={setFormData}
          contextData={contextData}
          lat={formData.map_location?.lat}
          long={formData.map_location?.longitude}
        />
        <Button
          className="block md:hidden w-full items-center justify-center"
          size="sm"
          onClick={() => {
            mobilePage1Confirm()
          }}
        >
          Sonraki
          <Button.Icon>
            <IoChevronForward />
          </Button.Icon>
        </Button>
      </div>
      <div className="flex flex-col justify-center w-full ">
        <div
          className={`${
            mobileActivePage == 2 ? "block" : "hidden md:block"
          } w-full`}
        >
          <CompanyDetailAndDescription
            fieldErrors={fieldErrors}
            onDetailChange={handleDetailChange}
            onHideContactInfoChange={handleHideContactInfoChange}
            onHideCompanyInfoChange={handleHideCompanyInfoChange}
            onHideEmployeeInfoChange={handleHideEmployeeInfoChange}
            contextData={contextData}
          />
          <div className="flex md:hidden flex-row items-center gap-2 mt-2">
            <Button
              className="flex w-full items-center justify-center"
              variant="ghost"
              size="sm"
              onClick={() => {
                if (setMobileActivePage) {
                  setMobileActivePage((prev) => prev - 1);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }
              }}
            >
              <Button.Icon>
                <IoChevronBack />
              </Button.Icon>
              Önceki
            </Button>
            <Button
              className="flex w-full items-center justify-center"
              size="sm"
              onClick={() => {
                mobilePage2Confirm()
              }}
            >
              Sonraki
              <Button.Icon>
                <IoChevronForward />
              </Button.Icon>
            </Button>
          </div>
        </div>
        <div
          className={`${
            mobileActivePage == 3 ? "flex" : "hidden md:flex"
          }  flex-col justify-center w-full`}
        >
          <ExtraInformation
            onYanHaklarChange={handleYanHaklarChange}
            onSurucuLisanslariChange={handleSurucuLisanslariChange}
            onSrcLisanslariChange={handleSrcLisanslariChange}
            handleIsSendMessageUsersChange={handleIsSendMessageUsersChange}
            handleIsDisabledEmployeeChange={handleIsDisabledEmployeeChange}
            contextData={contextData}
          />
          <TemplateSetting
            onTemplateTypeChange={handleTemplateTypeChange}
            onImageSelect={handleImageSelect}
            contextData={contextData}
            updateMapLocation={updateMapLocation}

            /* FIXME: Burada belki problem olabilir genel state içerisinde geç */
            /* initialLat={
							contextData?
							contextData.data?.map_location.lat? contextData.data.map_location.lat: null:
							formData.map_location?.lat || null
						}
						initialLong={
							contextData?
							contextData.data?.map_location.longitude:
							formData.map_location?.longitude || null
						} */
          />
          {/* 
          TODO: Sorular silindi gerekli kalıcı olursa kodlar silinecek
          <AddQuestion
            addQuestion={addQuestionToForm}
            initialQuestions={formData.questions}
            formQuestions={formData.questions}
            clearQuestions={clearQuestionsForm}
          /> */}
          {/* <div>
						{formData.questions
							.filter((question) => question.question !== 0)
							.map((question, index) => (
								<div
									className='pl-3 mb-2 bg-white border border-gray-300 rounded shadow-lg'
									key={index}>
									<p className='text-gray-500'>Soru: {question.question}</p>
									<p className='text-gray-400'>
										Başvuru Soruları Zorunlu:{" "}
										{question.is_required ? "Evet" : "Haıyr"}
									</p>
								</div>
							))}
						<div></div>
					</div> */}
          <Conditions
            onChange={handleChangeCheckBox}
            isChecked={formData.terms === 1}
          />

          <div className="flex flex-row items-center justify-between gap-2 w-full my-6">
            <Button
              variant="ghost"
              size="sm"
              className="flex md:hidden"
              onClick={() => {
                if (setMobileActivePage) {
                  setMobileActivePage((prev) => prev - 1);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }
              }}
            >
              <Button.Icon>
                <IoChevronBack />
              </Button.Icon>
              Geri
            </Button>

            <Button className="md:ml-auto" size="sm" onClick={handleSubmit}>
              Kaydet ve Devam Et
              <Button.Icon>
                <IoChevronForward />
              </Button.Icon>
            </Button>
          </div>

          {/* <SaveAndNextuton onSubmit={handleSubmit} /> */}
        </div>
      </div>
    </div>
  );
}

export default AdvertCreateForm;
