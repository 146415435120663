import { SearchComponent } from "../components/search-component-new";
import { useState, useEffect, useContext } from "react";
import services from "../../../../../../services/search-services";
import useFetchCdnQuery from "../../../../../../hooks/useFetchCdnQuery";
import { ApplyPoolContext, CandidatePoolFilterProps } from "../../../index";

interface PageProps {
  isApplyPage?: boolean;
}

const BasicsSection: React.FC<PageProps> = ({
  isApplyPage = false,
}: PageProps) => {
  const { newSelectedValues, setNewSelectedValues } =
    useContext(ApplyPoolContext);

  //CITIES SELECT DATA
  const [cityData, setCityData] = useState<any>([]);
  const [citySearchTerm, setCitySearchTerm] = useState("");
  const citiesQuery = useFetchCdnQuery(
    "cities",
    "get-cities",
    "cities",
    citySearchTerm,
    "81"
  );
  useEffect(() => {
    if (citiesQuery.data !== undefined) {
      setCityData([...citiesQuery?.data]);
    }
  }, [citiesQuery.data, citiesQuery.fetchStatus]);

  //fetch positions
  const [positionsData, setPositionsData] = useState<any>([]);
  const [positionSearchTerm, setPositionSearchTerm] = useState("");
  const positionsQuery = useFetchCdnQuery(
    "positions",
    "get-positions",
    "positions",
    positionSearchTerm,
    "20",
    newSelectedValues.position
      ? `&include_ids=[${newSelectedValues.position}]`
      : ""
  );
  useEffect(() => {
    if (positionsQuery.data !== undefined) {
      setPositionsData([...positionsQuery?.data]);
    }
  }, [positionsQuery.data, positionsQuery.fetchStatus]);

  //Districts
  const [districtsData, setDistrictsData] = useState<any>([]);
  const [districtSearchTerm, setDistrictSearchTerm] = useState("");

  useEffect(() => {
    // Define an async function inside the effect
    const fetchData = async () => {
      try {
        if (
          newSelectedValues.city === 0 ||
          newSelectedValues.city === undefined
        ) {
          setDistrictsData([]);
          return;
        }
        // Now it's safe to use await since we're inside an async function
        const response = await services.searchServicesDistricts(
          newSelectedValues.city + "",
          districtSearchTerm ?? ""
        );
        setDistrictsData([...response.data.districts]);
      } catch (error) {
        console.error("Error fetching districts", error);
      }
    };
    // Call the async function
    fetchData();
  }, [newSelectedValues.city, districtSearchTerm]);

  return (
    <section className=" text-gray-600 mx-2 md:mx-5 my-5 z-999">
      <div>
        <SearchComponent
          isFetching={positionsQuery.isFetching}
          keyString={"title"}
          data={positionsData}
          label="Pozisyon"
          onChange={(value) => {
            let newValues: CandidatePoolFilterProps = {
              ...newSelectedValues,
              position: value,
            };
            if (value === 0) {
              delete newValues.position;
            }
            setNewSelectedValues(newValues);
          }}
          selectedvalue={newSelectedValues.position}
          setSearchData={setPositionSearchTerm}
          searchData={positionSearchTerm}
        />
      </div>
      <div className=" flex flex-col  md:flex-row items-center justify-between gap-5 my-3 z-999">
        <SearchComponent
          isFetching={citiesQuery.isFetching}
          keyString={"name"}
          data={cityData}
          label="Şehir"
          onChange={(value) => {
            let newValues: CandidatePoolFilterProps = {
              ...newSelectedValues,
              city: value,
            };
            if (value === 0) {
              delete newValues.city;
              delete newValues.district;
            }
            setNewSelectedValues(newValues);
          }}
          selectedvalue={newSelectedValues.city}
          setSearchData={setCitySearchTerm}
          searchData={citySearchTerm}
        />
        <SearchComponent
          keyString={"name"}
          data={districtsData}
          disabled={districtsData.length <= 0 ? true : false}
          label="İlçe"
          placeholder={
            districtsData.length <= 0
              ? "Önce İl Seçiniz"
              : `${!newSelectedValues.district ? "Seçiniz" : ""}`
          }
          onChange={(value) => {
            let newValues: CandidatePoolFilterProps = {
              ...newSelectedValues,
              district: value,
            };
            if (value === 0) {
              delete newValues.district;
            }
            setNewSelectedValues(newValues);
          }}
          selectedvalue={newSelectedValues.district}
          setSearchData={setDistrictSearchTerm}
          searchData={districtSearchTerm}
        />
      </div>
    </section>
  );
};

export { BasicsSection };
