import React, { useEffect } from "react";
import Exams from "./Exams";
import Certificates from "./Certificates";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Button } from "../../../../../components/isbul-ui";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { useContext } from "react";
import { CvLayoutContext, cvLayoutContextType } from "..";


function ExamsCertificates() {

  const { isEnglish }: cvLayoutContextType = useContext(CvLayoutContext)

  const params = useParams()
  const cvId = params.slug

  const navigate = useNavigate()

  // Initial scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])


  return (
    <div className="mb-20 lg:mb-0 flex flex-col bg-gray-100 lg:bg-gray-100 gap-2 p-2 lg:p-0">

      {/* Exams sections */}
      <Exams />

      {/* Certificates section */}
      <Certificates />

      <div className="px-4">
        <hr/>
      </div>

      {/* Prev and next buttons */}
      <div className="flex flex-row p-4 items-center justify-between bg-white">
        <Button
        variant="secondary"
        size="sm"
        onClick={() => navigate(`/profilim/cv/duzenle/${cvId}/referanslar`)}
        >
          <Button.Icon>
            <IoChevronBack/>
          </Button.Icon>
          {isEnglish? 'Go Back': 'Geri Dön'}
        </Button>

        <Button
        size="sm"
        onClick={() => navigate(`/profilim/cv/duzenle/${cvId}/dosyalar`)}
        >
          {isEnglish? 'Continue': 'Devam Et'}
          <Button.Icon>
            <IoChevronForward/>
          </Button.Icon>
        </Button>
      </div>
    </div>
  );
}

export default ExamsCertificates;
