import * as React from "react";
import { PackagesContents } from "./packages-contents";
import { PackageService } from "../../../../../services/package-services";
import { ICustomCard } from "../types/ICustomCard";

function GeneralPackages({
  freeNavigate,
}: {
  freeNavigate?: () => void;
}): React.ReactElement {
  const [packages, setPackages] = React.useState<ICustomCard[]>([]);

  const getAllPackages = async () => {
    const response = await PackageService.getPackage();
    setPackages(response.data.packets);
  };

  React.useEffect(() => {
    getAllPackages();
  }, []);

  return (
    <div className="w-full">
      <PackagesContents packages={packages} freeNavigate={freeNavigate} />
    </div>
  );
}
export { GeneralPackages };
