import { LabelHTMLAttributes } from "react"
import { useContext } from "react"
import { SelectContext, selectContextType } from "."




type labelPropsType = LabelHTMLAttributes<HTMLLabelElement>







export default function Label({
    className,
    children,
    ...props
}: labelPropsType) {

    const { required }: selectContextType = useContext(SelectContext)
    return(
        <label {...props} className={`${className} text-[14px] font-poppins text-[#69737F]`}>
            {children} {required && (
                <b className="text-red-600">*</b>
            )}
        </label>
    )
}