import classNames from "classnames";
import { Dispatch, HTMLAttributes, InputHTMLAttributes, SetStateAction, createContext, useState } from "react";
import Input from "./Input";
import Label from "./Label";
import Error from "./Error";





type modeType = 'dirty' | 'empty'


// Component props type
type componentPropsType = HTMLAttributes<HTMLDivElement> & {
    required?: boolean,
    error?: boolean,
    mode?: modeType,
    value?: string | number,
    setValue?: Dispatch<SetStateAction<string | number>>
}


// Component context type

export type inputAreaContextType = {
    required?: boolean,
    error?: boolean,
    mode: modeType,
    value: string | number,
    setValue: Dispatch<SetStateAction<string | number>>
}

export const InputAreaContext = createContext<inputAreaContextType>({
    error: false,
    mode: 'empty',
    required: false,
    value: '',
    setValue: () => {}
})


export function InputArea ({
    value: initialValue,
    setValue: initialSetValue,
    error = false,
    required = false,
    mode = 'empty',
    className,
    ...props
}: componentPropsType) {

    const [value, setValue] = useState<string | number>(initialValue? initialValue: '')

    const contextValue: inputAreaContextType = {
        mode,
        required,
        error,
        value: initialValue != undefined? initialValue: value,
        setValue: initialSetValue != undefined? initialSetValue: setValue
    }

    const cn = classNames({
        'flex flex-col gap-1': true
    })
    return(
        <InputAreaContext.Provider value={contextValue}>
            <div className={`${cn} ${className}`} {...props}>

            </div>
        </InputAreaContext.Provider>
    )
}


InputArea.Input = Input
InputArea.Label = Label
InputArea.Error = Error