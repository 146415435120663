import { IconChevronDown } from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

interface IButton {
  variant: "text" | "default";
  text: string;
  href?: string;
  isLoginRequire: boolean;
  textColor?: string;
}

const HeaderButton: React.FC<IButton> = (props: IButton) => {
  const [buttonHeight, setButtonHeight] = useState<number>(100);
  const buttonRef = useRef<HTMLAnchorElement>(null);

  const getHoverColor = () => {
    if (props.variant === "text") {
      return "";
    }
    if (props.isLoginRequire) {
      return "hover:bg-[#313040]";
    } else {
      return "hover:bg-[#3864b7]";
    }
  };

  const bgColor = props.variant === "text" ? "bg-transparent" : "bg-[#2253af]";
  const hoverColor = getHoverColor();
  const textMarginRight = props.isLoginRequire ? "mr-[6px]" : "";
  const textColor = props.textColor || "text-white";

  useEffect(() => {
    setButtonHeight(buttonRef.current?.clientHeight || 40);
  }, [buttonRef]);

  return (
    <Link
      to={props.href ? props.href : ""}
      ref={buttonRef}
      className={`relative h-full text-sm flex justify-center font-poppins items-center ${bgColor} group px-4 ${hoverColor} cursor-pointer rounded-md`}
    >
      <span className="relative flex items-center">
        <span
          className={`${textMarginRight} ${textColor} font-medium font-poppins`}
        >
          {props.text}
        </span>
        {props.isLoginRequire && (
          <IconChevronDown
            className={`text-white group-hover:rotate-180 transition-all`}
            size={16}
          />
        )}
        <span className="absolute bottom-0 left-0 h-[2px] w-0 bg-white group-hover:w-full transition-all duration-300"></span>
      </span>
      {props.isLoginRequire && (
        <div
          style={{ top: buttonHeight, transformOrigin: "top left" }}
          className={`absolute w-full bg-white z-10 shadow-2xl border-gray-400 opacity-0 group-hover:opacity-100 transition-all duration-300 transform scale-0 group-hover:scale-100`}
          // Animasyonun köşeden başlaması için transform-origin kullanıyoruz
        >
          <Link
            to={`${props.href}/giris-yap`}
            className="flex justify-center items-center text-black text-center font-normal h-8 hover:bg-[#2253af] hover:text-white"
          >
            Giriş Yap
          </Link>
          <Link
            to={`${props.href}/kayit-ol`}
            className="flex justify-center items-center text-black text-center font-normal h-8 hover:bg-[#2253af] hover:text-white"
          >
            Üye Ol
          </Link>
        </div>
      )}
    </Link>
  );
};

export default HeaderButton;
