interface Position {
  title: string;
  slug: string;
  min_salary: number;
  max_salary: number;
  detail: string;
  avg_salary: number;
  calculate_salary: number;
  calculate_university: number;
  created_at: string;
  order: number;
}

interface GeneralInfoProps {
  positionData: Position[];
}

const Salaries: React.FC<GeneralInfoProps> = ({ positionData }) => {
  console.log(positionData);

  function formatAmount(amount: number): string {
    // Miktarı TL cinsine biçimlendirme
    const formattedAmount: string = new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
      minimumFractionDigits: 0, // Minimum ondalık hane sayısını 0 olarak ayarla
      maximumFractionDigits: 0, // Maksimum ondalık hane sayısını 0 olarak ayarla
    }).format(amount);

    // TL sembolünü ve virgülü kaldırarak formatı düzenleme
    const formattedResult = formattedAmount.replace(/^(\d+)\D*/, "$1");
    return formattedResult;
  }

  return (
    <div className="bg-white p-4 shadow rounded-lg pb-12">
      <h2 className="font-medium text-2xl p-2">
        {positionData[0]?.title} Maaşları
      </h2>
      <div className="mt-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <div className="bg-white p-4 shadow-xl rounded-lg ">
            <h3 className="text-center text-[#217fe7] font-medium mb-4">
              En Düşük Maaş
            </h3>
            <h4 className="text-center text-[#636363] text-2xl mb-4">
              {formatAmount(positionData[0]?.min_salary)}
            </h4>
            <p className="text-center text-[#636363]">
              {positionData[0]?.title} Maaşı
            </p>
          </div>
          <div className="bg-gray-100 p-4 shadow-xl rounded-lg mb-2">
            <h3 className="text-center text-[#217fe7] text-[green] font-medium mb-4">
              Ortalama
            </h3>
            <h4 className="text-center text-[#636363] text-2xl font-bold mb-4">
              {formatAmount(positionData[0]?.avg_salary)}
            </h4>
            <p className="text-center text-[#636363]">
              {" "}
              {positionData[0]?.title} Maaşı
            </p>
          </div>
          <div className="bg-white p-4 shadow-xl rounded-lg">
            <h3 className="text-center text-[#217fe7] font-medium mb-4">
              En Yüksek Maaş
            </h3>
            <h4 className="text-center text-[#636363] text-2xl mb-4">
              {formatAmount(positionData[0]?.max_salary)}
            </h4>
            <p className="text-center text-[#636363]">
              {" "}
              {positionData[0]?.title} Maaşı
            </p>
          </div>
        </div>
        <div className="relative mt-8 lg:mt-5">
          <div
            className="w-full h-8 rounded-xl"
            style={{
              background:
                "linear-gradient(90deg, rgba(14,14,246,1) 8%, rgba(1,79,67,1) 87%)",
            }}
          ></div>
          {/* Vertical lines with border, padding, and text */}
          <div
            className="absolute flex flex-col items-center"
            style={{ top: "-1rem", left: "calc(12% - 0.5rem)" }}
          >
            <div className="w-3 h-14 bg-gray-400 rounded flex items-center justify-center">
              <div className="w-1 h-12 bg-gray-200 rounded"></div>
            </div>
            <p className="mt-1 text-[#808080] text-md font-bold">
              {" "}
              {formatAmount(positionData[0]?.min_salary)}
            </p>
          </div>
          <div
            className="absolute flex flex-col items-center"
            style={{ top: "-1rem", left: "calc(49% - 1.5rem)" }}
          >
            <div className="w-3 h-14 bg-gray-400 rounded flex items-center justify-center">
              <div className="w-1 h-12 bg-gray-200 rounded"></div>
            </div>
            <p className="mt-1 text-[#808080] text-md font-bold">
              {" "}
              {formatAmount(positionData[0]?.avg_salary)}
            </p>
          </div>
          <div
            className="absolute flex flex-col items-center"
            style={{ top: "-1rem", left: "calc(85% - 2.5rem)" }}
          >
            <div className="w-3 h-14 bg-gray-400 rounded flex items-center justify-center">
              <div className="w-1 h-12 bg-gray-200 rounded"></div>
            </div>
            <p className="mt-1 text-[#808080] text-md font-bold">
              {formatAmount(positionData[0]?.max_salary)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Salaries;
