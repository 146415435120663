interface Position {
  title: string;
  slug: string;
  min_salary: number;
  max_salary: number;
  detail: string;
  avg_salary: number;
  calculate_salary: number;
  calculate_university: number;
  created_at: string;
  order: number;
}

interface GeneralInfoProps {
  positionData: Position[];
}

const GeneralInfo: React.FC<GeneralInfoProps> = ({ positionData }) => {
  const plainText = new DOMParser().parseFromString(
    positionData[0]?.detail,
    "text/html"
  ).body.textContent;

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      <div className="max-h-[450px] overflow-hidden ">
        {/* change the alt with dynamic data */}
        <img
          alt="header"
          src="https://contents.newspicks.com/images/news/4385516?updatedAt=20191114220236"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="p-6">
        <p className="text-[#63636c] mt-4 tracking-wide text-sm font-poppins">
          {plainText}
        </p>
      </div>
    </div>
  );
};

export default GeneralInfo;
