import { Outlet, Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useCookie } from "../../hooks/useCookie";
import { CookieName } from "../../constants/enums";

function PrivateRoutes() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getCookie } = useCookie();
  const companyUserTokenCookie = getCookie(CookieName.CompanyUserToken);
  const companyUserToken = companyUserTokenCookie
    ? companyUserTokenCookie
    : null;
  useEffect(() => {
    const checkUser = async () => {
      if (companyUserToken) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    };
    checkUser();
  }, [companyUserToken]);

  if (isLoading) {
    return <div>Yükleniyor...</div>;
  }

  return companyUserToken ? <Outlet /> : <Navigate to="/isveren/giris-yap" />;
}

const CompanyPrivateRoutes = React.memo(PrivateRoutes);

export { CompanyPrivateRoutes };
