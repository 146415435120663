import React, { useEffect, useState } from "react";
import { IoInformation, IoInformationCircleOutline } from "react-icons/io5";
import services from "../../../../../services/profile-services";

const İncomplete: React.FC = () => {
  const [open, setOpen] = React.useState(true);
  const [userData, setUserData] = useState<any[]>([]);
  const [defaultCvId, setDefaultCvId] = useState<string>('')

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchIncompleteDetail = async () => {
      try {
        const defaultCvRequest = await services.getDefaultCv();
        //console.log(defaultCvRequest.data);
        const { id } = defaultCvRequest.data.data;
        setDefaultCvId(id.toString())
        const data = await services.incomplateDetail(id);
        setUserData(data.data.data);
      } catch (error: any) {
        setUserData([])
        //setError(error.message || "Bir Hata Oluştu checkUser Fetch");
      } finally {
        //setIsLoading(false);
      }
    };

    fetchIncompleteDetail();
  }, []);

  return (
    <div
      className={`${userData.length == 0 ? 'hidden' : 'flex'} w-full items-center shadow-lg border-2 h-[100px] md:h-32 border-red-600 bg-white rounded-md ${
        open === true ? "flex" : "hidden"
      }`}
    >
      <div className="bg-red-600 w-2/12 lg:w-1/12 h-full items-center justify-center flex">
        <IoInformationCircleOutline className="text-white text-4xl md:text-5xl" />
      </div>

      <div
        className={`flex w-10/12 lg:w-11/12 flex-col h-full px-3 md:px-4 py-2 justify-between   `}
      >
        <div className="w-full truncate flex flex-col">
          <span className="text-black poppins-medium text-xs md:text-lg">
            Özgeçmişinizde eksik alanlar bulunmaktadır.
          </span>

          <a className="w-full truncate">
            <span className="text-mains poppins-semibold text-[13px] md:text-md">
              +{userData.length} Alan eksik
            </span>
          </a>
        </div>

        <div className="flex gap-x-2 justify-end  md:justify-start">
          <div
            onClick={() => handleClose()}
            className="md:px-8 px-3 py-1 md:py-2 border border-mains rounded-md cursor-pointer"
          >
            <span className="md:text-sm text-xs  poppins-semibold text-mains rounded-md">
              Sonra Hatırlat
            </span>
          </div>
          <div className="px-3 md:px-4 py-1 md:py-2  bg-mains rounded-md cursor-pointer">
            <a
              href={`/profilim/cv/duzenle/${defaultCvId}`}
              className="text-xs md:text-sm poppins-semibold text-white rounded-md"
            >
              Güncelle
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default İncomplete;
