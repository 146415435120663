import React, { useState } from "react";
import { Range, getTrackBackground } from "react-range";

interface DualThumbSliderProps {
  onExperienceLevelChange: (values: [number, number]) => void;
  contextMin?: number,
  contextMax?: number
}

const DualThumbSlider: React.FC<DualThumbSliderProps> = ({
  onExperienceLevelChange,
  contextMin,
  contextMax
}) => {
  const STEP = 1;
  const MIN = 1;
  const MAX = 25;
  const [values, setValues] = useState(
    contextMin && contextMax?
    [contextMin, contextMax]:
    [1, 15]
  ); // Initial range values

  const handleChange = (newValues: number[]) => {
    setValues(newValues);
    onExperienceLevelChange(newValues as [number, number]);
  };

  return (
    <div className="md:w-[55%] w-full mt-8 mb-4 md:mb-0 md:mt-2">
      <Range
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={handleChange}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "3px",
                width: "100%",
                borderRadius: "4px",
                background: getTrackBackground({
                  values: values,
                  colors: ["#ccc", "#418CF7", "#ccc"],
                  min: MIN,
                  max: MAX,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, index }) => (
          <div
          className="relative"
            {...props}
            style={{
              ...props.style,
              height: "20px",
              width: "20px",
              borderRadius: "50%",
              backgroundColor: "#418CF7",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "0px 2px 6px #AAA",
              boxSizing: "border-box",
              zIndex: 1,
            }}
          >
            <div
            style={{
              position: "relative",
              bottom: "0",
              color: "#FFF",
              fontWeight: "normal",
              fontSize: "12px",
            }}
            >
              {/* {values[index]} */}
            </div>
            <div className={`absolute ${index == 0? '-top-1 -translate-y-full': '-bottom-1 translate-y-full'} left-1/2 -translate-x-1/2 text-xs bg-blue-500 rounded-md whitespace-nowrap text-white p-1`}>
              {values[index]} Yıl
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default DualThumbSlider;
