import { ButtonHTMLAttributes, useContext } from "react"
import classNames from "classnames"
import { paginationContextType, PaginationContext } from "."



type pageButtonPropsType = ButtonHTMLAttributes<HTMLButtonElement> & {
    pageNumber: number
}




export default function PageButton({
    pageNumber,
    className,
    ...props
}: pageButtonPropsType) {

    const { currentPage }: paginationContextType = useContext(PaginationContext)

    const cn = classNames({
        'flex flex-shrink-0 p-2 min-w-10 h-10 items-center justify-center text-mains rounded-md hover:bg-mains hover:text-white': true,
        'bg-mains text-white': pageNumber == currentPage
    })
    return(
        <button className={`${cn} ${className}`} {...props}>

        </button>
    )
}