import React, { useState, useEffect, useContext } from "react";
import { HeaderSection } from "./header-section";
import { CardsSection } from "./cards-section";
import { UpdateSection } from "./update-section";
import { IApplicantsData } from "../types";

import { QueryFunction, useQuery, UseQueryResult } from "@tanstack/react-query";
import Axios from "../../../../services/axios";
import { useCookie } from "../../../../hooks/useCookie";
import { CookieName } from "../../../../constants/enums";
import SkeletonContainer from "./components/loader-skeleton";
import { useParams } from "react-router-dom";
import { ApplyPoolContext } from "../index";
import { AccordionContentMobile } from "../filter-section/content-section-mobile";
import { useIsMobile } from "../../../../hooks/useIsMobile";

const axios = new Axios();

function InfoSection({
  queryParams,
}: {
  queryParams: string;
}): React.ReactElement {
  const { id } = useParams<{ id: string }>();
  const idArray = id?.split("-");
  const fetchId = idArray && idArray[idArray?.length - 1];

  const { setPagination, checkedData, setCheckedData, detailFiltersOpen } =
    useContext(ApplyPoolContext);
  const { isMobile } = useIsMobile();

  const [page, setPage] = useState(1);
  //start fetch cards data

  const { getCookie } = useCookie();
  const companyUserToken = getCookie(CookieName.CompanyUserToken);

  const [pagesCount, setPagesCount] = useState(1);
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  //fetch cards data
  const fetchCards = async () => {
    try {
      setLoading(true);
      const response = await axios.request({
        type: "get",
        endpoint: `companies/advert-applies/${fetchId}/get-applies${
          queryParams && queryParams
        }`,
        payload: {
          headers: {
            Authorization: `Bearer ${companyUserToken}`,
          },
        },
      });
      if (response) {
        setCardCheckedStatus({});
        setPagination(response.data.pagination);
        setData(response.data.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw new Error("Error fetching header card items: " + error);
    }
  };
  useEffect(() => {
    setPage(1);
    setCardCheckedStatus({});
    fetchCards();
  }, [queryParams]);

  //end

  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const [cardCheckedStatus, setCardCheckedStatus] = useState<any>({});

  const handleCardCheckboxToggle = (obje: any) => {
    setCardCheckedStatus((prevStatus: any) => ({
      ...prevStatus,
      [obje.cv.id]: {
        checked:
          prevStatus[obje.cv.id] === undefined
            ? true
            : !prevStatus[obje.cv.id].checked,
        cv_id: obje.cv.id,
        apply_id: obje.advert_apply.id,
      },
    }));
  };

  const handleAllCardsCheckboxToggle = () => {
    setIsCheckedAll((prevIsCheckedAll) => !prevIsCheckedAll);
    const newCheckedStatus = {} as any;
    data?.forEach((data: IApplicantsData) => {
      newCheckedStatus[data.cv.id] = {
        checked: !isCheckedAll,
        cv_id: data.cv.id,
        apply_id: data.advert_apply.id,
      };
    });
    setCardCheckedStatus(newCheckedStatus);
  };

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    //console log checked cards
    const checkedIds = Object.keys(cardCheckedStatus)
      .filter((key) => {
        return cardCheckedStatus[key].checked;
      })
      .map((key) => {
        let temp = {
          checked: cardCheckedStatus[key].checked,
          cv_id: cardCheckedStatus[key].cv_id,
          apply_id: cardCheckedStatus[key].apply_id,
        };
        return temp;
      });
    setCheckedData(checkedIds);

    // Determine isChecked
    const newIsChecked = checkedIds.length > 0;
    setIsChecked(newIsChecked);
  }, [cardCheckedStatus]);

  return (
    <section>
      <HeaderSection
        onAllCheckboxToggle={handleAllCardsCheckboxToggle}
        isCheckedAll={isCheckedAll}
      />
      <div className={`${isMobile && detailFiltersOpen ? "" : "hidden"}`}>
        <AccordionContentMobile isApplyPage={true} />
      </div>
      {loading && <SkeletonContainer />}
      <CardsSection
        isLoading={loading}
        cardCheckedStatus={cardCheckedStatus}
        onCardCheckboxToggle={handleCardCheckboxToggle}
        data={data}
        pagesCount={pagesCount}
        currentPage={page}
        setCurrentPage={setPage}
      />

      {isChecked && (
        <UpdateSection checkedData={checkedData} fetchCards={fetchCards} />
      )}
    </section>
  );
}

export { InfoSection };
