import React, { useEffect, useState } from "react";
import Axios from "../../../../../services/axios";

const Footer: React.FC = () => {
  const axios = new Axios();
  const [cvId, setCvId] = useState<number>(0);
  const [cvViewsCount, setCvViewsCount] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cvRequest = axios.request({
          endpoint: `user/profilim/cv`,
          type: "get",
          payload: {},
        });
        const viewRequest = axios.userAuthRequest({
          endpoint: "user/profilim/cv-views/get-cv-views",
          type: "get",
          payload: {},
        });

        const [cvResponse, viewResponse] = await Promise.all([
          cvRequest,
          viewRequest,
        ]);

        if (cvResponse?.data?.data) setCvId(cvResponse.data.data.id);
        if (viewResponse.data.data.length > 0) {
          let totalViews = 0;
          viewResponse.data.data.forEach((item: any) => {
            totalViews += item.cv_view_count;
          });
          setCvViewsCount(totalViews);
        }
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex shadow-lg bg-white rounded-md py-3 px-3 flex-col h-auto ">
      <div className="w-full items-center justify-center flex">
        <div className="w-14 h-14 bg-mains rounded-full items-center justify-center flex">
          <span className="poppins-bold text-lg text-white">
            {cvViewsCount}
          </span>
        </div>
      </div>

      <div className="w-full items-center justify-center flex">
        <span className="text-[13px] font-medium text-black text-nowrap">
          Özgeçmiş Görüntülenme Sayısı
        </span>
      </div>
      <a
        href={`/profilim/cv/${cvId}/goruntuleyen-firmalar`}
        className="w-full items-center justify-center flex text-nowrap"
      >
        <span className="text-mains text-sm underline font-medium text-nowrap underline-offset-4">
          Görüntüleyen Firmaları Göster
        </span>
      </a>
    </div>
  );
};

export default Footer;
