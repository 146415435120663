import { IoAdd, IoClose } from "react-icons/io5";
import { SelectDropdown } from "../../../../../../components/isbul-ui";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { FaCircle } from "react-icons/fa6";
import { cvType, fileType } from "..";
import Swal from "sweetalert2";
import Axios from "../../../../../../services/axios";
import { isAxiosError } from "axios";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

type fieldErrorsType = {
  fileName: boolean;
  file: boolean;
  cv: boolean;
  description: boolean;
};

const axios = new Axios();

export default function FileAddModal({
  initialData,
  getAllDatas,
  cvs,
  setIsOpen,
  isEnglish = false,
}: {
  initialData?: fileType;
  getAllDatas: () => void;
  cvs: cvType[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isEnglish?: boolean;
}) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  // General states
  const [fieldErrors, setFieldErrors] = useState<fieldErrorsType>({
    cv: false,
    description: false,
    file: false,
    fileName: false,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Form States
  const [fileName, setFileName] = useState<string>(
    initialData ? initialData.filename : ""
  );
  const [file, setFile] = useState<File | null>(null);
  const [selectedCv, setSelectedCv] = useState<cvType | null | undefined>(
    initialData && cvs.find((c) => c.id == initialData.cv_id) != undefined
      ? cvs.find((c) => c.id == initialData.cv_id)
      : null
  );
  const [description, setDescription] = useState<string>(
    initialData ? initialData.description : ""
  );

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef]);

  return (
    <div className="fixed top-0 left-0 flex items-center justify-center bg-black bg-opacity-30 h-screen w-screen z-[100]">
      <div
        ref={divRef}
        className="flex flex-col gap-4 p-4 bg-white rounded-md max-h-[calc(100vh-50px)] max-w-[600px] w-full overflow-auto"
      >
        <div className="flex flex-row items-center justify-between">
          <h3 className="font-poppins text-xl font-semibold text-mains">
            {isEnglish
              ? initialData
                ? "EDIT FILE"
                : "ADD FILE"
              : initialData
              ? "DOSYA DÜZENLE"
              : "DOSYA EKLE"}
          </h3>
          <button type="button" onClick={() => setIsOpen(false)}>
            <IoClose className="text-2xl text-gray-600" />
          </button>
        </div>
        <hr />

        {/* File Name */}
        <div className="flex flex-col gap-1">
          <label className="text-[13px] text-gray-700 font-semibold font-poppins">
            {isEnglish ? "File Name" : "Dosya Adı"}{" "}
            <b className="text-red-600">*</b>
          </label>
          <div
            className={`flex flex-row border items-center ${
              fileName.trim().length > 0 ? "border-blue-600" : "border-gray-400"
            } ${
              fieldErrors.fileName ? "!border-red-600 bg-red-50" : ""
            } rounded-md p-1`}
          >
            <input
              className={`w-full outline-none px-2 ${
                fileName.trim().length > 0 ? "text-blue-600" : "text-gray-600"
              } bg-transparent`}
              value={fileName}
              onChange={(e) => {
                setFileName(e.target.value);
                setFieldErrors(({ fileName: name, ...others }) => ({
                  fileName: false,
                  ...others,
                }));
              }}
            />
          </div>
        </div>

        {/* File */}
        <div className="flex flex-col gap-1">
          <label className="text-[13px] text-gray-700 font-semibold font-poppins">
            {isEnglish ? "File" : "Dosya"} <b className="text-red-600">*</b>
          </label>
          <div
            className={`flex flex-row border items-center ${
              file ? "border-blue-600" : "border-gray-400"
            } ${
              fieldErrors.file ? "!border-red-600 !bg-red-50" : ""
            } rounded-md p-1 gap-2`}
            onClick={() => {
              if (fileInputRef && fileInputRef.current) {
                fileInputRef.current.click();
              }
            }}
          >
            <label
              className={`${file ? "text-blue-600" : "text-gray-400"} ${
                fieldErrors.file ? "!text-red-600" : ""
              } font-poppins text-[14px] ml-2 transition-all whitespace-nowrap overflow-hidden text-ellipsis`}
            >
              {file
                ? `${file.name}`
                : initialData
                ? initialData.original_name
                : `${isEnglish ? "Select File." : "Dosya Seçiniz."}`}
            </label>
            <button
              type="button"
              className="flex flex-row items-center gap-2 bg-mains px-2 py-1 rounded-md ml-auto text-[14px] text-white"
            >
              <IoAdd className="text-xl" /> {isEnglish ? "Select" : "Seçim"}
            </button>
            <input
              ref={fileInputRef}
              className="hidden"
              type="file"
              accept=".doc, .docx, .pdf, .ppt, .jpg"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  setFile(e.target.files[0]);
                  setFieldErrors(({ file, ...others }) => ({
                    file: false,
                    ...others,
                  }));
                }
              }}
            />
          </div>
        </div>
        <SelectDropdown
          required
          mode={selectedCv ? "dirty" : "empty"}
          error={fieldErrors.cv}
        >
          <SelectDropdown.Label>
            {isEnglish
              ? "Resume You Want To Add"
              : "Eklemek İstediğiniz Özgeçmiş"}
          </SelectDropdown.Label>
          <SelectDropdown.Trigger>
            {selectedCv
              ? selectedCv.title
              : `${isEnglish ? "Select CV" : "CV Seçiniz"}`}
          </SelectDropdown.Trigger>
          <SelectDropdown.Items>
            {cvs.map((cv) => {
              return (
                <SelectDropdown.Item
                  key={`cvSelect${cv.id}`}
                  onClick={() => {
                    setSelectedCv(cv);
                    setFieldErrors(({ cv, ...others }) => ({
                      cv: false,
                      ...others,
                    }));
                  }}
                >
                  {cv.title}
                </SelectDropdown.Item>
              );
            })}
          </SelectDropdown.Items>
        </SelectDropdown>
        <div className="flex flex-col gap-1">
          <label className="text-[13px] text-gray-700 font-semibold font-poppins">
            {isEnglish ? "Description" : "Açıklama"}{" "}
            <b className="text-red-600">*</b>
          </label>
          <div
            className={`flex flex-row border items-center ${
              description.trim().length > 0
                ? "border-blue-600"
                : "border-gray-400"
            } ${
              fieldErrors.description ? "border-red-600 bg-red-50" : ""
            } rounded-md p-1`}
          >
            <textarea
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                setFieldErrors(({ description, ...others }) => ({
                  description: false,
                  ...others,
                }));
              }}
              className={`h-[75px] w-full outline-none px-2 ${
                description.trim().length > 0
                  ? "text-blue-600"
                  : "text-gray-600"
              } bg-transparent`}
            />
          </div>
        </div>
        <div className="flex flex-col gap-1 mt-3">
          <label className="font-poppins text-gray-600 text-sm">
            {isEnglish
              ? "Information About Attaching Files"
              : "Dosya Ekleme Hakkında Bilgiler"}
          </label>
          <label className="flex flex-row items-center gap-2 text-xs text-gray-500">
            <FaCircle className="text-[6px] text-mains" />
            {isEnglish
              ? "The file size should not be more than 5 MB."
              : "Dosya boyutu 2 mb'dan fazla olmamalıdır."}
          </label>
          <label className="flex flex-row items-center gap-2 text-xs text-gray-500">
            <FaCircle className="text-[6px] text-mains" />
            {isEnglish
              ? "File types should be doc, docx, pdf, ppt, jpg."
              : "Dosya türleri doc, docx, pdf, ppt, jpg olmalıdır."}
          </label>
        </div>
        <hr />
        <div className="flex flex-row items-center justify-between">
          <button
            type="button"
            className="bg-gray-200 text-gray-800 rounded-md py-2 px-4 font-poppins text-[14px]"
            onClick={() => setIsOpen(false)}
          >
            {isEnglish ? "Close" : "Kapat"}
          </button>
          <button
            className="bg-mains text-white rounded-md py-2 px-4 font-poppins text-[14px]"
            onClick={() => {
              if (initialData) {
                updateFile();
              } else {
                uploadFile();
              }
            }}
          >
            {isLoading ? (
              <AiOutlineLoading3Quarters className="mx-auto animate-spin text-xl" />
            ) : (
              `${
                initialData
                  ? `${isEnglish ? "Save" : "Kaydet"}`
                  : `${isEnglish ? "Add" : "Ekle"}`
              }`
            )}
          </button>
        </div>
      </div>
    </div>
  );

  async function uploadFile() {
    if (isLoading) return;

    if (fileName.trim().length == 0) {
      setFieldErrors(({ fileName: name, ...others }) => ({
        fileName: true,
        ...others,
      }));
    }

    if (!file) {
      setFieldErrors(({ file, ...others }) => ({
        file: true,
        ...others,
      }));
    }

    if (!selectedCv) {
      setFieldErrors(({ cv, ...others }) => ({
        cv: true,
        ...others,
      }));
    }

    if (description.trim().length == 0) {
      setFieldErrors(({ description, ...others }) => ({
        description: true,
        ...others,
      }));
    }

    if (fileName.trim().length == 0) return;

    if (!file) return;

    if (!selectedCv) return;

    if (description.trim().length == 0) return;

    const formData = new FormData();

    formData.append("cv_id", selectedCv.id.toString());
    formData.append("file_name", fileName);
    formData.append("description", description);
    formData.append("file", file);

    console.log("file size", file.size / (1024 * 1024));

    if (file.size / (1024 * 1024) > 3) {
      Swal.fire({
        icon: "warning",
        title: "Dosya boyutu en fazla 2 mb olabilir.",
      });
      return;
    }

    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/files/create`,
        type: "post",
        payload: formData,
      });

      if (response?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Başarılı",
          text: "Dosya başarıyla yüklendi.",
          confirmButtonText: "Tamam",
        });
        getAllDatas();
        setIsOpen(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Dosya yüklenirken bir hata meydana geldi.",
          confirmButtonText: "Tamam",
        });
      }
    } catch (error) {
      console.log(`File upload failed. Error: ${error}`);

      if (isAxiosError(error)) {
        // Handle Axios error here
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Dosya yüklenirken beklenmedik bir hata meydana geldi.",
          confirmButtonText: "Tamam",
        });
      }
    }

    setIsLoading(false);
  }

  async function updateFile() {
    if (!initialData) return;

    if (fileName.trim().length == 0) {
      setFieldErrors(({ fileName, ...others }) => ({
        fileName: true,
        ...others,
      }));
    }

    if (!selectedCv) {
      setFieldErrors(({ cv, ...others }) => ({
        cv: true,
        ...others,
      }));
    }

    if (description.trim().length == 0) {
      setFieldErrors(({ description, ...others }) => ({
        description: true,
        ...others,
      }));
    }

    const formData = new FormData();

    formData.append("cv_id", selectedCv?.id.toString() || "");
    formData.append("file_name", fileName);
    formData.append("description", description);
    if (file) {
      formData.append("file", file);
    }

    if (fileName.trim().length == 0) return;

    if (!file) return;

    if (!selectedCv) return;

    if (description.trim().length == 0) return;

    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/files/update/${initialData?.id}`,
        type: "post",
        payload: formData,
      });

      if (response?.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Başarılı",
          text: "Dosya başarıyla güncellendi.",
          confirmButtonText: "Tamam",
        });

        getAllDatas();
        setIsOpen(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Dosya güncellenirken bir hata meydana geldi.",
          confirmButtonText: "Tamam",
        });
      }
    } catch (error) {
      console.log(`File update failed. Error: ${error}`);
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Dosya güncellenirken beklenmedik bir hata meydana geldi.",
        confirmButtonText: "Tamam",
      });
    }
    setIsLoading(false);
  }
}
