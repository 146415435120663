import React, { ChangeEvent } from "react";
interface ConditionsProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
}

const Conditions: React.FC<ConditionsProps> = ({ isChecked, onChange }) => {
  return (
    <div className="my-3 flex w-full items-center">
      <div className="flex items-center">
        <input
          className="form-checkbox w-4 h-4 text-blue-600 scale-150 ml-3"
          id="terms"
          name="terms"
          type="checkbox"
          checked={isChecked}
          onChange={onChange}
          required
        />
        <label
          className="ml-6 text-sm font-medium text-[#69737f] md:text-lg text-start leading-6"
          htmlFor="terms"
        >
          <a
            className="underline text-[#267EC1] underline-offset-[5px]  md:text-lg"
            href="https://isbul.net/kurumsal/gizlilik-sozlesmesi-4"
            target="_blank"
            rel="noopener noreferrer"
          >
            Gizlilik Sözleşmesi,
          </a>{" "}
          <a
            className="underline text-[#267EC1] underline-offset-[5px]  md:text-lg"
            href="https://isbul.net/kurumsal/uyelik-sozlesmesi-5"
            target="_blank"
            rel="noopener noreferrer"
          >
            Üyelik sözleşmesi
          </a>{" "}
          ve{" "}
          <a
            className="underline text-[#267EC1] underline-offset-[5px]  md:text-lg"
            href="https://isbul.net/kurumsal/kvkk-metni-8"
            target="_blank"
            rel="noopener noreferrer"
          >
            Kişisel Verilerin Korunması Bilgilendirmesi&apos;ni
          </a>{" "}
          okudum, anladım
        </label>
      </div>
    </div>
  );
};

export default Conditions;
