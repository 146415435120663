
import { workingLocationType } from ".";
import { cdnCityType, cdnDistrictType } from "../../../../../../types";
import CitySelect from "./CitySelect";
import DistrictSelect from "./DistrictSelect";
import { Dispatch, SetStateAction, createContext, useEffect, useState, useContext } from "react";
import { WorkingLocationsContext, workingLocationContextType } from ".";
import { BsFillTrash3Fill, BsTrash3Fill } from "react-icons/bs";
import Swal from "sweetalert2";
import { fieldErrorType } from "../../../../../../containers/company-page-container/creat-ads-page/advert-create-form";




export const LocationRowContext = createContext<locationRowContextType>({
    selectedCity: null,
    setSelectedCity: () => {},
    selectedDistrict: null,
    setSelectedDistrict: () => {}
})


export type locationRowContextType = {
    selectedCity: cdnCityType | null,
    setSelectedCity: Dispatch<SetStateAction<cdnCityType | null>>,
    selectedDistrict: cdnDistrictType | null,
    setSelectedDistrict: Dispatch<SetStateAction<cdnDistrictType | null>>
}




export default function LocationRow({
    workingLocation,
    index,
    setFieldErrors
}: {
    workingLocation: workingLocationType,
    index: number,
    setFieldErrors?: Dispatch<SetStateAction<fieldErrorType>>
}) {


    const { workingLocations, setWorkingLocations, contextLocations }: workingLocationContextType = useContext(WorkingLocationsContext)
    
    const [selectedCity, setSelectedCity] = useState<cdnCityType | null>(
        contextLocations && contextLocations[index]?
        {
            id: contextLocations[index].city.id,
            country_id: contextLocations[index].city.country_id,
            created_at: contextLocations[index].city.created_at,
            feature: contextLocations[index].city.featured,
            name: contextLocations[index].city.name,
            slug: contextLocations[index].city.slug.toString(),
            updated_at: contextLocations[index].city.updated_at
        }: null
    )
    const [selectedDistrict, setSelectedDistrict] = useState<cdnDistrictType | null>(
        contextLocations && contextLocations[index] && contextLocations[index].district != null?
        {
            id: contextLocations[index].district.id ?? -1,
            city_id: contextLocations[index].district.city_id?? -1,
            created_at: contextLocations[index].district.created_at?? '',
            featured: contextLocations[index].district.featured?? 0,
            name: contextLocations[index].district.name?? 'Tüm İlçeler',
            slug: contextLocations[index].district.slug?? 'tum_ilceler',
            updated_at: contextLocations[index].district.updated_at?? ''
        }: null
    )

    const value: locationRowContextType = {
        selectedCity,
        setSelectedCity,
        selectedDistrict,
        setSelectedDistrict
    }

    useEffect(() => {

        // Türkiye geneli seçimi için özel işlem yapılıyor
        if (selectedCity?.id == 7795) {

            setSelectedDistrict({
                id: -1,
                city_id: 7795,
                created_at: '',
                featured: 0,
                name: 'Tüm İlçeler',
                slug: 'tum_ilceler',
                updated_at: ''
            })

        }

        // form güncelleme sayfasından gelen context bilgisine göre eğer şehir değiştirilmemişse intial olarak selected district verisinin silinmemesi için yapıldı
        if (contextLocations && contextLocations[index]) {
            if (selectedCity?.id == contextLocations[index].city.id) {
                return
            }
        }
        setSelectedDistrict(null)
        
        const newWorkingLocations: workingLocationType[] = workingLocations.map((prev) => {

            const { id, cityId, ...others } = prev
            if (id != workingLocation.id) {
                return prev
            } else {
                return {
                    id,
                    cityId: selectedCity? selectedCity.id: null,
                    ...others
                }
            }
        })

        setWorkingLocations(newWorkingLocations)
    }, [selectedCity])

    useEffect(() => {

        const newWorkingLocations: workingLocationType[] = workingLocations.map((prev) => {
            const { id, ditrictId, ...other } = prev

            if (id != workingLocation.id) {
                return prev
            } else {
                return {
                    id,
                    ditrictId: selectedDistrict? selectedDistrict.id: null,
                    ...other
                }
            }
        })

        setWorkingLocations(newWorkingLocations)
    }, [selectedDistrict])
    return(
        <LocationRowContext.Provider value={value}>
            <div className="flex flex-col lg:flex-row w-full gap-1 lg:gap-2">
                <div className="flex flex-col w-full gap-2">
                    
                    {/* <label className="text-[#4b5563] text-[14px] font-poppins">
                        Şehir <label className="text-[red]">*</label>
                    </label> */}
                    <CitySelect/>
                </div>
                <div className="flex flex-row w-full gap-1 items-center">
                    <div className="flex flex-col w-full gap-2">
                        {/* <label className="text-[#4b5563] text-[14px] font-poppins">
                            İlçe <label className="text-[red]">*</label>
                        </label> */}
                        <DistrictSelect
                        setFieldErrors={setFieldErrors}
                        />
                    </div>
                    <div className={`${workingLocations.length > 1? 'flex': 'hidden'} flex-col gap-2 w-20 h-full items-center mt-0 justify-center`}>
                        {workingLocations.length > 1 && (
                            <button type="button" className="flex" onClick={removeWorkingLocation}>
                                <BsTrash3Fill className="text-red-600 text-xl"/>
                            </button>
                        )}
                    </div>
                </div>
                
            </div>
        </LocationRowContext.Provider>
    )

    function removeWorkingLocation() {
        if (workingLocations.length == 1) {
            Swal.fire({
                title: 'Hata',
                text: 'En az 1 adet çalışma yeriniz bulunmak zorundadır.',
                confirmButtonText: 'Tamam',
                icon: 'error'
            })

            return
        }

        setWorkingLocations((prev) => [...prev.filter((p) => p.id != workingLocation.id)])
    }
}