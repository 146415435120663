import { HTMLAttributes } from "react"




export type triggerPropsType = HTMLAttributes<HTMLDivElement> & {

}







export default function Trigger({
    ...props
}: triggerPropsType) {
    return(
        <div
        {...props}
        >
            
        </div>
    )
}