import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import Axios from "../../../services/axios";
import Card from "../setting-page/content/Card";
import { FaArrowLeft, FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import MaskedInput from "react-text-mask";
import { GoDotFill } from "react-icons/go";

const ReferanceCreatePage = () => {
  const navigate = useNavigate();
  const axios = new Axios();

  const formik = useFormik({
    initialValues: {
      email: "",
      companyName: "",
      phone: "",
      fullName: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Geçerli bir e-posta giriniz")
        .required("E-posta"),
      companyName: Yup.string().required("Firma Unvanı"),
      phone: Yup.string()
        .required("Telefon Numaranız")
        .test(
          "len",
          "Telefon numarası 10 haneli olmalıdır",
          (val: any) => val && val.replace(/\D/g, "").length >= 10
        ),
      fullName: Yup.string().required("Ad Soyad"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const formattedPhone = values.phone
          .replace(/\D/g, "")
          .replace(/^0/, "");
        const response = await axios.authRequest({
          type: "post",
          endpoint: `companies/reference/create-reference`,
          payload: {
            name: values.fullName,
            email: values.email,
            phone: formattedPhone,
            company_name: values.companyName,
          },
        });

        if (response.status === 200) {
          navigate("/isveren/referanslar");
          Swal.fire({
            icon: "success",
            title: "Başarılı",
            text: "Form başarıyla gönderildi",
          });
          resetForm();
        }
      } catch (error: any) {
        const errorMessages: string[] = [];
        if (error.response.data.errors.phone) {
          errorMessages.push(error.response.data.errors.phone[0]);
        }
        if (error.response.data.errors.email) {
          errorMessages.push(error.response.data.errors.email[0]);
        }
        Swal.fire({
          icon: "error",
          title: "Hata",
          html: errorMessages.join("<br>"),
        });
      }
    },
  });

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors = await formik.validateForm();
    formik.setTouched({
      email: true,
      companyName: true,
      phone: true,
      fullName: true,
    });
    if (Object.keys(errors).length > 0) {
      let errorMessage = "Eksik Alanlar: ";
      for (const key in errors) {
        if (errors[key as keyof typeof errors]) {
          errorMessage += `<div style="color: red;">${
            errors[key as keyof typeof errors]
          }</div>`;
        }
      }
      errorMessage = errorMessage.slice(0, -2);
      Swal.fire({
        icon: "error",
        title: "Hata",
        html: errorMessage,
      });
    } else {
      formik.handleSubmit();
    }
  };

  return (
    <div className="container mx-auto mt-2">
      <Card>
        <div className="flex justify-between">
          <h1 className="text-xl font-bold text-title font-poppins poppins-medium">
            SEN ÖDEME ARKADAŞIN ÖDESİN!
          </h1>

          <a
            href="/isveren/referanslar"
            className="bg-title rounded-md md:flex hidden py-2 px-4 text-white justify-center items-center gap-1 text-sm mx-2 font-poppins poppins-medium"
          >
            <FaArrowLeft />
            Tüm Davetiyelerim
          </a>
        </div>
      </Card>

      <div className="flex md:flex-row flex-col justify-start items-start my-4 gap-6">
        <div className="flex flex-col justify-start items-center h-full p-4 w-full md:w-4/6 bg-white">
          <p className="font-poppins poppins-regular text-[15px] pb-4 border-b">
            Tanıdığınız firmaları ve iş ortaklarınızı isbul.net'e davet edin,
            aldıkları ilk ürünün aynısını size hediye edelim.
          </p>

          <div className="flex justify-start items-center w-full mt-4">
            <p className="font-poppins poppins-medium text-base">
              Davetiye Göndereceğiniz Firma Bilgileri
            </p>
          </div>

          <form className="w-full" onSubmit={handleFormSubmit}>
            <div className="flex flex-col lg:flex-row justify-center items-start lg:items-center gap-1 w-full mt-4">
              <label className="flex justify-start items-center whitespace-nowrap w-1/4 font-poppins poppins-medium">
                E-Posta <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="email"
                placeholder="Firma E-Postası"
                className={`w-full md:w-3/4 h-10 border rounded-md p-2 ${
                  formik.touched.email && formik.errors.email
                    ? "bg-red-100 border-red-500"
                    : "border-gray-300"
                }`}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            <div className="flex flex-col lg:flex-row justify-center items-start lg:items-center gap-1 w-full mt-4">
              <label className="flex justify-start items-center whitespace-nowrap w-1/4 font-poppins poppins-medium">
                Firma Unvanı <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="companyName"
                placeholder="Firma Unvanı"
                className={`w-full md:w-3/4 h-10 border rounded-md p-2 ${
                  formik.touched.companyName && formik.errors.companyName
                    ? "bg-red-100 border-red-500"
                    : "border-gray-300"
                }`}
                value={formik.values.companyName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            <div className="flex flex-col lg:flex-row justify-center items-start lg:items-center gap-1 w-full mt-4">
              <label className="flex justify-start items-center whitespace-nowrap w-1/4 font-poppins poppins-medium">
                Telefon <span className="text-red-500">*</span>
              </label>
              <MaskedInput
                className={`w-full md:w-3/4 h-10 border rounded-md p-2 ${
                  formik.touched.phone && formik.errors.phone
                    ? "bg-red-100 border-red-500"
                    : "border-gray-300"
                }`}
                mask={[
                  "0",
                  "(",
                  /\d/,
                  /\d/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  " ",
                  /\d/,
                  /\d/,
                  " ",
                  /\d/,
                  /\d/,
                ]}
                placeholder="0(___) ___ __ __"
                value={formik.values.phone}
                onChange={(e) => {
                  formik.setFieldValue("phone", e.target.value);
                }}
                onBlur={formik.handleBlur}
              />
            </div>

            <div className="flex flex-col lg:flex-row justify-center items-start lg:items-center gap-1 w-full mt-4">
              <label className="flex justify-start items-center whitespace-nowrap w-1/4 font-poppins poppins-medium">
                Ad Soyad <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="fullName"
                placeholder="Ad Soyad"
                className={`w-full md:w-3/4 h-10 border rounded-md p-2 ${
                  formik.touched.fullName && formik.errors.fullName
                    ? "bg-red-100 border-red-500"
                    : "border-gray-300"
                }`}
                value={formik.values.fullName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

            <div className="flex justify-end items-center gap-4 w-full mt-4">
              <button
                type="submit"
                className="bg-mains text-white px-2 py-[10px] rounded-md flex justify-center items-center gap-2 font-poppins poppins-medium text-sm"
              >
                <FaCheck className="text-base" />
                Davet Gönder
              </button>
            </div>
          </form>
        </div>

        <div className="flex justify-start items-center h-full w-full md:w-2/6 flex-col bg-white">
          <p className="font-poppins poppins-medium text-[15px] flex justify-start items-center w-full p-4">
            Kampanya Koşulları;
          </p>

          <ul className="flex flex-col justify-start items-start gap-2 mt-2 pb-2">
            <li className="listItem flex justify-center items-start font-poppins poppins-regular text-xs text-gray-500 px-4 gap-1">
              <span>
                <GoDotFill className="text-mains text-sm" />
              </span>
              <span>
                Firma referans sistemi üzerinden yapılan davetler ve bu davet
                üzerinden yapılan üyeliklerde geçerli olur.
              </span>
            </li>
            <li className="listItem flex justify-center items-start font-poppins poppins-regular text-xs text-gray-500 px-4 gap-1">
              <span>
                <GoDotFill className="text-mains text-sm" />
              </span>
              <span>
                Davet edilen firmanın isbul.net'te daha önceden üyeliğinin
                bulunmaması gereklidir.
              </span>
            </li>
            <li className="listItem flex justify-center items-start font-poppins poppins-regular text-xs text-gray-500 px-4 gap-1">
              <span>
                <GoDotFill className="text-mains text-sm" />
              </span>
              <span>
                Kampanya sadece ilan ürünlerinde geçerlidir. (Tek İlan, 3 İlan,
                6 İlan, 12 İlan, 24 İlan)
              </span>
            </li>
            <li className="listItem flex justify-center items-start font-poppins poppins-regular text-xs text-gray-500 px-4 gap-1">
              <span>
                <GoDotFill className="text-mains text-sm" />
              </span>
              <span>Sadece KOBİ firmalar için geçerlidir.</span>
            </li>
            <li className="listItem flex justify-center items-start font-poppins poppins-regular text-xs text-gray-500 px-4 gap-1">
              <span>
                <GoDotFill className="text-mains text-sm" />
              </span>
              <span>Günlük davet gönderimleri sınırlıdır.</span>
            </li>
            <li className="listItem flex justify-center items-start font-poppins poppins-regular text-xs text-gray-500 px-4 gap-1">
              <span>
                <GoDotFill className="text-mains text-sm" />
              </span>
              <span>isbul.net kampanya koşullarında değişiklik yapabilir.</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ReferanceCreatePage;
