export const createQueryParamsArrayFromSelectedValues = (
  selectedValues: any
) => {
  // seçilmiş filtreleri search parametresine çeviriyor
  const positionIds = selectedValues.positions.map((item: any) => item.id);
  const cityIds = selectedValues.cities.map((item: any) => item.id);
  const companyId = selectedValues.company.map((item: any) => item.id);
  const districtIds = selectedValues.districts.map((item: any) => item.id);
  const searchIds = selectedValues.search.map((item: any) => item.id);
  const workingTypeIds = selectedValues.workingTypes.map(
    (item: any) => item.id
  );
  const workingLocationsIds = selectedValues.workingLocations.map(
    (item: any) => item.id
  );
  const sectionsIds = selectedValues.sections.map((item: any) => item.id);
  const sectorIds = selectedValues.sectors.map((item: any) => item.id);
  let advertDate;

  let minExp, maxExp;

  if (selectedValues.experience[0]?.id === 1) {
    // Kullanıcı deneyimli
    minExp = 0;
    maxExp = 25;
  } else if (selectedValues.experience[0]?.id === 2) {
    // Kullanıcı deneyimsiz
    minExp = 0;
    maxExp = 0;
  } else if (selectedValues.experience[0]?.id === 0) {
    // Kullanıcı farketmez seçmiş
    minExp = 0;
    maxExp = 26;
  } else {
    // Varsayılan değerler (eğer başka bir id gelirse)
    minExp = 0;
    maxExp = 26;
  }

  let disabled;
  switch (selectedValues.advertDate[0]?.id || 0) {
    case 1:
      advertDate = "all";
      break;
    case 2:
      advertDate = "new";
      break;
    case 3:
      advertDate = "week";
      break;
    case 4:
      advertDate = "month";
      break;
    default:
      advertDate = "";
  }

  switch (selectedValues.disabled[0]?.id || 0) {
    case 1:
      disabled = "all";
      break;
    case 2:
      disabled = "disabled";
      break;
    case 3:
      disabled = "non_disabled";
      break;
    default:
      disabled = "";
  }
  const notTurkeyGeneral = selectedValues.other.some((d: any) => d.id === 0);
  const hiddenCompany = selectedValues.other.some((d: any) => d.id === 1);
  const notShowWieved = selectedValues.other.some((d: any) => d.id === 2);
  const followCompanyAdverts = selectedValues.other.some(
    (d: any) => d.id === 3
  );
  const forMeAdverts = selectedValues.other.some((d: any) => d.id === 4);
  const notShowApplied = selectedValues.other.some((d: any) => d.id === 5);

  const positionLevelsIds = selectedValues.positionLevels.map(
    (item: any) => item.id
  );

  const queryParams: any = {};

  if (notTurkeyGeneral) {
    queryParams["not_turkey_general"] = "1";
  }
  if (hiddenCompany) {
    queryParams["not_show_hidden_company_title"] = "1";
  }
  if (notShowWieved) {
    queryParams["not_show_viewed"] = "1";
  }
  if (followCompanyAdverts) {
    queryParams["followed_company_adverts"] = "1";
  }
  if (forMeAdverts) {
    queryParams["for_me_adverts"] = "1";
  }
  if (notShowApplied) {
    queryParams["not_show_applied"] = "1";
  }

  if (disabled !== "") {
    queryParams["disabled"] = disabled;
  }

  if (maxExp !== 26) {
    queryParams["experience_level_min"] = minExp.toString();
    queryParams["experience_level_max"] = maxExp.toString();
  }

  if (advertDate !== "") {
    queryParams["advert_date"] = advertDate;
  }

  if (positionIds.length > 0) {
    queryParams["positions"] = positionIds;
  }
  if (cityIds.length > 0) {
    queryParams["cities"] = cityIds;
  }
  if (districtIds.length > 0) {
    queryParams["districts"] = districtIds;
  }

  if (positionLevelsIds.length > 0) {
    queryParams["position_levels"] = positionLevelsIds;
  }
  if (workingTypeIds.length > 0) {
    queryParams["working_types"] = workingTypeIds;
  }
  if (workingLocationsIds.length > 0) {
    queryParams["working_locations"] = workingLocationsIds;
  }
  if (sectionsIds.length > 0) {
    queryParams["sections"] = sectionsIds;
  }
  if (sectorIds.length > 0) {
    queryParams["sectors"] = sectorIds;
  }
  if (companyId.length > 0) {
    queryParams["company_ids"] = companyId;
  }
  if (searchIds.length > 0) {
    queryParams["search"] = selectedValues.search[0].title;
  }

  return queryParams;
};

export const createQueryParamsFromSelectedValues = (newSelectedValues: any) => {
  // seçilmiş filtreleri search parametresine çeviriyor
  const positionIds = newSelectedValues.positions.map((item: any) => item.id);
  const cityIds = newSelectedValues.cities.map((item: any) => item.id);
  const companyId = newSelectedValues.company.map((item: any) => item.id);
  const districtIds = newSelectedValues.districts.map((item: any) => item.id);
  const searchIds = newSelectedValues.search.map((item: any) => item.id);

  const workingTypeIds = newSelectedValues.workingTypes.map(
    (item: any) => item.id
  );

  const workingLocationsIds = newSelectedValues.workingLocations.map(
    (item: any) => item.id
  );

  const sectionsIds = newSelectedValues.sections.map((item: any) => item.id);
  const sectorIds = newSelectedValues.sectors.map((item: any) => item.id);
  let advertDate;

  let minExp, maxExp;

  if (newSelectedValues.experience[0]?.id === 1) {
    // Kullanıcı deneyimli
    minExp = 0;
    maxExp = 25;
  } else if (newSelectedValues.experience[0]?.id === 2) {
    // Kullanıcı deneyimsiz
    minExp = 0;
    maxExp = 0;
  } else if (newSelectedValues.experience[0]?.id === 0) {
    // Kullanıcı farketmez seçmiş
    minExp = 0;
    maxExp = 26;
  } else {
    // Varsayılan değerler (eğer başka bir id gelirse)
    minExp = 0;
    maxExp = 26;
  }

  let disabled;
  switch (newSelectedValues.advertDate[0]?.id || 0) {
    case 1:
      advertDate = "all";
      break;
    case 2:
      advertDate = "new";
      break;
    case 3:
      advertDate = "week";
      break;
    case 4:
      advertDate = "month";
      break;
    default:
      advertDate = "";
  }

  switch (newSelectedValues.disabled[0]?.id || 0) {
    case 1:
      disabled = "all";
      break;
    case 2:
      disabled = "disabled";
      break;
    case 3:
      disabled = "non_disabled";
      break;
    default:
      disabled = "";
  }
  const notTurkeyGeneral = newSelectedValues.other.some((d: any) => d.id === 0);
  const hiddenCompany = newSelectedValues.other.some((d: any) => d.id === 1);
  const notShowWieved = newSelectedValues.other.some((d: any) => d.id === 2);
  const followCompanyAdverts = newSelectedValues.other.some(
    (d: any) => d.id === 3
  );
  const forMeAdverts = newSelectedValues.other.some((d: any) => d.id === 4);
  const notShowApplied = newSelectedValues.other.some((d: any) => d.id === 5);

  const positionLevelsIds = newSelectedValues.positionLevels.map(
    (item: any) => item.id
  );

  const queryParams = [];

  if (notTurkeyGeneral) {
    queryParams.push({ key: "not_turkey_general", value: "1" });
  }
  if (hiddenCompany) {
    queryParams.push({ key: "not_show_hidden_company_title", value: "1" });
  }
  if (notShowWieved) {
    queryParams.push({ key: "not_show_viewed", value: "1" });
  }
  if (followCompanyAdverts) {
    queryParams.push({ key: "followed_company_adverts", value: "1" });
  }
  if (forMeAdverts) {
    queryParams.push({ key: "for_me_adverts", value: "1" });
  }
  if (notShowApplied) {
    queryParams.push({ key: "not_show_applied", value: "1" });
  }

  if (disabled !== "") {
    queryParams.push({ key: "disabled", value: disabled });
  }

  if (maxExp !== 26) {
    queryParams.push({
      key: "experience_level_min",
      value: minExp.toString(),
    });
    queryParams.push({
      key: "experience_level_max",
      value: maxExp.toString(),
    });
  }

  if (advertDate !== "") {
    queryParams.push({ key: "advert_date", value: advertDate });
  }

  if (positionIds.length > 0) {
    queryParams.push({
      key: "positions",
      value: JSON.stringify(positionIds),
    });
  }
  if (cityIds.length > 0) {
    queryParams.push({ key: "cities", value: JSON.stringify(cityIds) });
  }
  if (districtIds.length > 0) {
    queryParams.push({
      key: "districts",
      value: JSON.stringify(districtIds),
    });
  }

  if (positionLevelsIds.length > 0) {
    queryParams.push({
      key: "position_levels",
      value: JSON.stringify(positionLevelsIds),
    });
  }
  if (workingTypeIds.length > 0) {
    queryParams.push({
      key: "working_types",
      value: JSON.stringify(workingTypeIds),
    });
  }
  if (workingLocationsIds.length > 0) {
    queryParams.push({
      key: "working_locations",
      value: JSON.stringify(workingLocationsIds),
    });
  }
  if (sectionsIds.length > 0) {
    queryParams.push({ key: "sections", value: JSON.stringify(sectionsIds) });
  }
  if (sectorIds.length > 0) {
    queryParams.push({ key: "sectors", value: JSON.stringify(sectorIds) });
  }
  if (companyId.length > 0) {
    queryParams.push({
      key: "company_ids",
      value: JSON.stringify(companyId),
    });
  }
  if (searchIds.length > 0) {
    queryParams.push({
      key: "search",
      value: newSelectedValues.search[0].title,
    });
  }

  return queryParams;
};
