import { HTMLAttributes, LabelHTMLAttributes } from "react"



type viewProps = HTMLAttributes<HTMLDivElement> & {}

type titleProps = LabelHTMLAttributes<HTMLLabelElement> & {}

type detailProps = HTMLAttributes<HTMLDivElement> & {}





export default function DetailView({
    className,
    ...props
}: viewProps) {
    return(
        <div className={`flex flex-col gap-4 ${className}`} {...props}>

        </div>
    )
}



function DetailTitle({
    className,
    ...props
}: titleProps) {
    return(
        <label className={`text-xs sm:text-2xl text-mains mt-5 font-semibold ${className}`} {...props}>

        </label>
    )
}

function DetailContent({
    className,
    ...props
}: detailProps) {
    return(
        <div className={`flex flex-col gap-2 ${className}`} {...props}>

        </div>
    )
}


DetailView.Title = DetailTitle
DetailView.Content = DetailContent