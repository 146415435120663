import React, { useState, useRef, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { MdVerified } from "react-icons/md";
import { RiTimeLine } from "react-icons/ri";
import { TiMediaPlay } from "react-icons/ti";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../../store/store";
import {
  sendUserVerifyEmail,
  sendUserConfirmEmail,
} from "../../../../../../store/content-reducer/personInfoSlice";

const EmailVerification: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [refresh, setRefresh] = useState(false);
  const [buttonRef, setButtonRef] = useState(false);
  const [inputs, setInputs] = useState<string[]>(["", "", "", ""]);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);

  // userInfo
  const userInfo = useAppSelector((state) => state.userInfo);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (refresh) {
      setInputs(["", "", "", ""]); // Inputları temizle
      setMinutes(1); // Dakikaları sıfırla
      setSeconds(0); // Saniyeleri sıfırla
      setRefresh(false); // Yeniden başlatma işlemini tamamladık, refresh durumunu false yap
      setButtonRef(false);
    }
  }, [refresh]);

  // email confirm code
  const confirmCode = inputs.join("");

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(timer); // Zamanlayıcıyı durdur
          setButtonRef(true); // Yeniden başlatma işlemi
        } else {
          setMinutes((prevMinutes) => prevMinutes - 1);
          setSeconds(59);
        }
      } else {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);

    return () => clearInterval(timer); // Komponent kaldırıldığında zamanlayıcıyı temizle
  }, [minutes, seconds, refresh]);

  // Refs oluşturulduğunda bunları doldur
  if (inputRefs.current.length !== 4) {
    inputRefs.current = Array(4)
      .fill(null)
      .map((_, index) => inputRefs.current[index] || null);
  }

  return (
    <div
      onClick={onClose}
      className="flex w-full items-center justify-center  h-screen  bg-gray-800 bg-opacity-50 fixed top-0 left-0"
    >
      <div className="bg-white rounded-md p-4 w-[300px] md:w-[500px] h-auto">
        <div className="flex flex-col w-full h-auto gap-y-2">
          <button
            onClick={onClose}
            className="w-full flex items-center justify-end"
          >
            <IoClose className="text-lg text-black" />
          </button>
          <div className="w-full items-center justify-center flex">
            <MdVerified className="text-8xl text-green-500" />
          </div>
          <div className="w-full items-center justify-center text-gray-600 flex flex-col gap-y-3 pb-4">
            <span className="text-sm poppins-medium">
              Doğrulama Linki,{" "}
              <span className="text-md poppins-semibold text-black">
                {userInfo?.email}
              </span>{" "}
              mail adresine gönderildi. Mail ile gelen talimatları takip ederek
              doğrulama işleminizi tamamlayabilirsiniz.
            </span>
          </div>

          <div
            onClick={onClose}
            className="w-full flex justify-center items-center"
          >
            <button className="bg-mains px-4 py-2 mt-2 text-white rounded-md">
              Tamam
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
