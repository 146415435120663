import { useContext } from "react";
import { jobsPageContextType, JobsPageContext } from "..";
import FilterItem from "./FilterItem";

export default function FilteredItemsList() {
  const { selectedValues }: jobsPageContextType = useContext(JobsPageContext);
  return (
    <div className="flex flex-row flex-wrap gap-2">
      {/* search filtreleri */}
      {selectedValues.search.map((search) => {
        return (
          <FilterItem
            key={`searchFilterItem${search.id}`}
            item={search}
            filterGroup="search"
          />
        );
      })}

      {/* company filtreleri */}
      {selectedValues.company.map((company) => {
        return (
          <FilterItem
            key={`comoanyFilterItem${company.id}`}
            item={company}
            filterGroup="company"
          />
        );
      })}

      {/* cities filtreleri */}
      {selectedValues.cities.map((city) => {
        return (
          <FilterItem
            key={`cityFilterItem${city.id}`}
            item={city}
            filterGroup="cities"
          />
        );
      })}
      {/* district filtreleri */}
      {selectedValues.districts.map((city) => {
        return (
          <FilterItem
            key={`districtFilterItem${city.id}`}
            item={city}
            filterGroup="districts"
          />
        );
      })}

      {/* positions filtreleri */}
      {selectedValues.positions.map((position) => {
        return (
          <FilterItem
            key={`positionsFilter${position.id}`}
            item={position}
            filterGroup="positions"
          />
        );
      })}

      {/* workingLocations filtreleri */}
      {selectedValues.workingLocations.map((location) => {
        return (
          <FilterItem
            key={`workingLocations${location.id}`}
            item={location}
            filterGroup="workingLocations"
          />
        );
      })}

      {/* workingTypes filtreleri */}
      {selectedValues.workingTypes.map((workingType) => {
        return (
          <FilterItem
            key={`workingTypeFilter${workingType.id}`}
            item={workingType}
            filterGroup="workingTypes"
          />
        );
      })}

      {/* sections filtreleri */}
      {selectedValues.sections.map((section) => {
        return (
          <FilterItem
            key={`sections${section.id}`}
            item={section}
            filterGroup="sections"
          />
        );
      })}

      {/* sectors filtreleri */}
      {selectedValues.sectors.map((sector) => {
        return (
          <FilterItem
            key={`sectors${sector.id}`}
            item={sector}
            filterGroup="sectors"
          />
        );
      })}

      {/* positionLevels filtreleri */}
      {selectedValues.positionLevels.map((positionLvl) => {
        return (
          <FilterItem
            key={`positionLevels${positionLvl.id}`}
            item={positionLvl}
            filterGroup="positionLevels"
          />
        );
      })}

      {/* advertDate filtreleri */}
      {selectedValues.advertDate.map((advert) => {
        if (advert.id !== 1) {
          return (
            <FilterItem
              key={`adverDate${advert.id}`}
              item={advert}
              filterGroup="advertDate"
            />
          );
        }
      })}

      {/* experience filtreleri */}
      {selectedValues.experience.map((exp) => {
        if (exp.id !== 0) {
          return (
            <FilterItem
              key={`experience${exp.id}`}
              item={exp}
              filterGroup="experience"
            />
          );
        }
      })}

      {/* disabled filtreleri */}
      {selectedValues.disabled.map((dis) => {
        if (dis.id !== 1) {
          return (
            <FilterItem
              key={`disabled${dis.id}`}
              item={dis}
              filterGroup="disabled"
            />
          );
        }
      })}

      {/* other filtreleri */}
      {selectedValues.other.map((o) => {
        return <FilterItem key={`other${o.id}`} item={o} filterGroup="other" />;
      })}
    </div>
  );
}
