import { IoClose } from "react-icons/io5";
import { IItem } from "../filter-section-content";
import { JobsPageContext, jobsPageContextType } from "..";
import { useContext } from "react";

type filterType =
  | "cities"
  | "districts"
  | "workingTypes"
  | "positions"
  | "workingLocations"
  | "sections"
  | "sectors"
  | "advertDate"
  | "experience"
  | "disabled"
  | "other"
  | "positionLevels"
  | "company"
  | "search";

export default function FilterItem({
  item,
  filterGroup,
  explainedText,
}: {
  item: IItem;
  filterGroup: filterType;
  explainedText?: string;
}) {
  const { selectedValues, setNewSelectedValues }: jobsPageContextType =
    useContext(JobsPageContext);

  const removeItem = async () => {
    const filter = selectedValues[filterGroup].filter((c) => c.id != item.id);
    setNewSelectedValues(({ ...others }) => ({
      ...others,
      [filterGroup]: filter,
      isFilterUpdated: true,
    }));
  };

  return (
    <span className="flex flex-row items-center text-sm py-1 px-2 gap-2 rounded-full bg-slate-100">
      <label>
        {explainedText && explainedText}{" "}
        {item.title !== "" && item.title !== undefined ? item.title : item.name}
      </label>
      <button
        className="flex text-white bg-[#284785] rounded-full"
        onClick={removeItem}
      >
        <IoClose className="text-xl" />
      </button>
    </span>
  );
}
