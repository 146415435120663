import { IoAdd, IoDocumentText, IoTrashBinOutline } from "react-icons/io5";
import {
  Button,
  InputArea,
  SelectDropdown,
  Textarea,
} from "../../../../../../components/isbul-ui";
import {
  cdnCertificateType,
  dbUserCertificateType,
} from "../../../../../../types";
import { FaCalendarCheck } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import Axios from "../../../../../../services/axios";
import { useParams } from "react-router-dom";
import { MdSubtitles } from "react-icons/md";
import { FaCity, FaPencilAlt } from "react-icons/fa";
import { CgDetailsMore } from "react-icons/cg";
import Swal from "sweetalert2";
import FormModal from "../../../../../../components/isbul-ui/form-modal";
import { useContext } from "react";
import { CvLayoutContext, cvLayoutContextType } from "../..";

type payloadDataType = {
  cv_id: number;
  certificate_work_area_id: number;
  name: string;
  company: string;
  year: number;
  month: number;
  detail: string | null;
};

type monthType = {
  id: number;
  name: string;
  name_en: string;
};

const months: monthType[] = [
  { id: 1, name: "Ocak", name_en: "January" },
  { id: 2, name: "Şubat", name_en: "February" },
  { id: 3, name: "Mart", name_en: "March" },
  { id: 4, name: "Nisan", name_en: "April" },
  { id: 5, name: "Mayıs", name_en: "May" },
  { id: 6, name: "Haziran", name_en: "June" },
  { id: 7, name: "Temmuz", name_en: "July" },
  { id: 8, name: "Ağustos", name_en: "August" },
  { id: 9, name: "Eylül", name_en: "September" },
  { id: 10, name: "Ekim", name_en: "October" },
  { id: 11, name: "Kasım", name_en: "November" },
  { id: 12, name: "Aralık", name_en: "December" },
];

const currentYear = new Date().getFullYear();

const totalYear = currentYear - 1974 + 1;

const years: number[] = Array.from({ length: totalYear }, (_, i) => i + 1974);

const axios = new Axios();

export default function CertificateForm({
  certificate,
  certificates,
  getAllCertificates,
  isModalOpen,
  setIsModalOpen,
}: {
  certificate: dbUserCertificateType | null;
  certificates: cdnCertificateType[];
  certificatesLength?: number;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getAllCertificates: () => void;
}) {
  const { isEnglish }: cvLayoutContextType = useContext(CvLayoutContext);

  const params = useParams();
  const cvId = params.slug;

  /* Page states */
  const [isEditOpen, setIsEditOpen] = useState<boolean>(
    certificate ? false : true
  );
  const [isPageOpen, setIsPageOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /* Form states */
  const [selectedCertificate, setSelectedCertificate] =
    useState<cdnCertificateType | null>(
      certificate
        ? certificates.find(
            (c) => c.id == certificate.certificate_work_area_id
          ) || null
        : null
    );
  const [selectedMonth, setSelectedMonth] = useState<monthType | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm<payloadDataType>({
    defaultValues: {
      detail: null,
    },
  });

  /* Custom values */
  const name = watch("name") || "";
  const company = watch("company") || "";
  const detail = watch("detail") || "";

  // Certificate type register
  register("certificate_work_area_id", {
    required: `${
      isEnglish ? "Certificate Document Type" : "Sertifika Belge Türü"
    }`,
  });

  // Month register
  register("month", {
    required: `${isEnglish ? "Month" : "Ay"}`,
  });

  // Year register
  register("year", {
    required: `${isEnglish ? "Year" : "Yıl"}`,
  });

  const onSubmit: SubmitHandler<payloadDataType> = (data) => {
    if (certificate) {
      updateCertificate(data);
    } else {
      addCertificate(data);
    }
  };

  async function addCertificate({ cv_id, detail, ...others }: payloadDataType) {
    if (isLoading) return;

    setIsLoading(true);

    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/cert/create`,
        type: "post",
        payload: {
          cv_id: cvId,
          detail: detail ? detail : null,
          ...others,
        },
      });

      if (response && response.status == 200) {
        if (getAllCertificates) {
          getAllCertificates();
          Swal.fire({
            icon: "success",
            title: "Başarılı",
            text: "Sertifika eklendi.",
            timer: 2000,
          });
        }

        if (!certificate) {
          setValue("company", "");
          setValue("detail", "");
          setValue("name", "");
          setSelectedMonth(null);
          setSelectedYear(null);
          setSelectedCertificate(null);
        }

        setIsPageOpen(false);
        setIsModalOpen(false);
        /* if (isMobile.isMobile) {
                    setIsPageOpen(false)
                } */
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Sertifika güncellenirken bir hata meydana geldi.",
        });
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Sertifika güncellenirken beklenmedik bir hata meydana geldi.",
      });
    }

    setIsLoading(false);
  }

  async function updateCertificate({
    cv_id,
    detail,
    ...others
  }: payloadDataType) {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/cert/update/${certificate?.id}`,
        type: "put",
        payload: {
          cv_id: cvId,
          detail: detail ? detail : null,
          ...others,
        },
      });

      if (response && response.status == 200) {
        if (getAllCertificates) {
          getAllCertificates();
          Swal.fire({
            icon: "success",
            title: "Başarılı",
            text: "Sertifika güncellendi.",
            timer: 2000,
          });
        }

        setIsEditOpen(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Sertifika güncellenirken bir hata meydana geldi.",
          confirmButtonText: "Tamam",
        });
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Sertifika güncellenirken beklenmedik bir hata meydana geldi.",
        confirmButtonText: "Tamam",
      });
    }
  }

  async function deleteCertificate() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/cert/delete/${certificate?.id}?cv_id=${cvId}`,
        type: "delete",
        payload: {},
      });

      if (response && response.status == 200) {
        if (getAllCertificates) {
          getAllCertificates();
          Swal.fire({
            icon: "success",
            title: "Başarılı",
            text: "Sertifika silindi.",
            timer: 2000,
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Sertifika silinirken bir hata meydana geldi.",
          confirmButtonText: "Tamam",
        });
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Sertifika silinirken beklenmedik bir hata meydana geldi.",
        confirmButtonText: "Tamam",
      });
    }
  }

  // Certificate type
  useEffect(() => {
    if (selectedCertificate) {
      setValue("certificate_work_area_id", selectedCertificate.id);
      clearErrors("certificate_work_area_id");
    }
  }, [selectedCertificate]);

  // Month
  useEffect(() => {
    if (selectedMonth) {
      setValue("month", selectedMonth.id);
      clearErrors("month");
    }
  }, [selectedMonth]);

  // Year
  useEffect(() => {
    if (selectedYear) {
      setValue("year", selectedYear);
      clearErrors("year");
    }
  }, [selectedYear]);

  /* Form fill if exist */
  useEffect(() => {
    if (certificate) {
      setValue("company", certificate.company);
      setValue("detail", certificate.detail);
      setValue("name", certificate.name);
      setSelectedCertificate(
        certificates.find(
          (c) => c.id == certificate.certificate_work_area_id
        ) || null
      );
      const date = new Date(certificate.date);
      setSelectedYear(date.getFullYear());
      setSelectedMonth(months.find((m) => m.id == date.getMonth() + 1) || null);
      return;
    }
    setValue("company", "");
    setValue("detail", "");
    setValue("name", "");
    setSelectedYear(null);
    setSelectedMonth(null);
    setSelectedCertificate(null);
    reset();
  }, [isModalOpen]);

  if (isEditOpen) {
    return (
      <div className="flex flex-col gap-2">
        {/* Form modal if certificate not exist */}
        {!certificate && (
          <div className={`flex flex-row items-center justify-between px-4`}>
            <Button
              size="sm"
              onClick={() => {
                setIsModalOpen(true);
                setIsEditOpen(true);
              }}
            >
              <Button.Icon>
                <IoAdd className="text-base" />
              </Button.Icon>
              {isEnglish ? "Add Certificate" : "Sertifika Ekle"}
            </Button>
          </div>
        )}

        {!certificate && <hr className="border-mains" />}

        {/* Form modal if certificate exist */}
        <FormModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          onClosing={() => {
            setIsModalOpen(false);
            if (certificate) {
              setIsEditOpen(false);
            } else {
              setIsEditOpen(true);
            }
          }}
        >
          <FormModal.Content>
            <form
              className="flex flex-col gap-y-4 px-6 py-2"
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <label className="text-mains font-semibold">
                {certificate
                  ? `${isEnglish ? "Edit Certificate" : "Sertifikayı Düzenle"}`
                  : `${isEnglish ? "Add Certificate" : "Sertifika Ekle"}`}
              </label>
              <hr className="border-mains" />
              <div className="flex flex-col gap-y-4 lg:flex-row">
                {/* Certificate type select*/}
                <div className="flex basis-1/3 px-1">
                  <SelectDropdown
                    className="w-full"
                    required
                    mode={selectedCertificate ? "dirty" : "empty"}
                    error={
                      errors.certificate_work_area_id?.message ? true : false
                    }
                  >
                    <SelectDropdown.Label>
                      {isEnglish
                        ? "Certificate Document Type"
                        : "Sertifika Belge Türü"}
                    </SelectDropdown.Label>
                    <SelectDropdown.Trigger>
                      {selectedCertificate
                        ? `${
                            isEnglish
                              ? `${selectedCertificate.title_en}`
                              : `${selectedCertificate.title}`
                          }`
                        : `${isEnglish ? "Select" : "Seçiniz"}`}
                    </SelectDropdown.Trigger>
                    <SelectDropdown.Items>
                      {certificates.map((certificate) => {
                        return (
                          <SelectDropdown.Item
                            key={`certificateSelectItem${certificate.id}`}
                            onClick={() => setSelectedCertificate(certificate)}
                          >
                            {isEnglish
                              ? certificate.title_en
                              : certificate.title}
                          </SelectDropdown.Item>
                        );
                      })}
                    </SelectDropdown.Items>
                  </SelectDropdown>
                </div>

                {/* Certificate name input */}
                <div className="flex basis-1/3 px-1">
                  <InputArea
                    className="w-full"
                    required
                    mode={name.length > 0 ? "dirty" : "empty"}
                    error={errors.name?.message ? true : false}
                  >
                    <InputArea.Label>
                      {isEnglish
                        ? "Certificate Document Name"
                        : "Sertifika Belge Adı"}
                    </InputArea.Label>
                    <InputArea.Input
                      {...register("name", {
                        required: "Sertifika Belge Adı",
                      })}
                      value={name || ""}
                      onChange={(e) => {
                        setValue("name", e.target.value);
                        clearErrors("name");
                      }}
                    />
                  </InputArea>
                </div>

                {/* Company name input */}
                <div className="flex basis-1/3 px-1">
                  <InputArea
                    className="w-full"
                    required
                    mode={company.length > 0 ? "dirty" : "empty"}
                    error={errors.company?.message ? true : false}
                  >
                    <InputArea.Label>
                      {isEnglish
                        ? "Issuing Institution / Company"
                        : "Alınan Kurum / Firma"}
                    </InputArea.Label>
                    <InputArea.Input
                      {...register("company", {
                        required: "Alınan Kurum / Firma",
                      })}
                      value={company || ""}
                      onChange={(e) => {
                        setValue("company", e.target.value);
                        clearErrors("company");
                      }}
                    />
                  </InputArea>
                </div>
              </div>
              <div className="flex w-full px-1">
                {/* Detail textarea */}
                <Textarea
                  className="w-full"
                  mode={detail.length > 0 ? "dirty" : "empty"}
                >
                  <Textarea.Label>
                    {isEnglish ? "Description" : "Açıklama"}
                  </Textarea.Label>
                  <Textarea.Field
                    placeholder={`${
                      isEnglish
                        ? "Adding certificates and exams makes your resume look more professional"
                        : "Sertifika bilgilerini eklemen özgeçmişini daha profesyonel gösterir."
                    }`}
                    className="min-h-[200px]"
                    {...register("detail")}
                    value={detail || ""}
                    onChange={(e) => setValue("detail", e.target.value || null)}
                  />
                </Textarea>
              </div>
              <div className="flex w-full lg:w-1/2">
                {/* Month select */}
                <div className="flex basis-full lg:basis-1/2 px-1">
                  <SelectDropdown
                    className="w-full"
                    required
                    mode={selectedMonth ? "dirty" : "empty"}
                    error={errors.month?.message ? true : false}
                  >
                    <SelectDropdown.Label>
                      {isEnglish ? "Month" : "Ay"}
                    </SelectDropdown.Label>
                    <SelectDropdown.Trigger>
                      {selectedMonth
                        ? `${
                            isEnglish
                              ? `${selectedMonth.name_en}`
                              : `${selectedMonth.name}`
                          }`
                        : `${isEnglish ? "Month" : "Ay"}`}
                    </SelectDropdown.Trigger>
                    <SelectDropdown.Items className="!top-5 !-translate-y-full min-h-[150px]">
                      {months.map((month) => {
                        return (
                          <SelectDropdown.Item
                            key={`monthSelect${month.id}`}
                            onClick={() => setSelectedMonth(month)}
                          >
                            {isEnglish ? month.name_en : month.name}
                          </SelectDropdown.Item>
                        );
                      })}
                    </SelectDropdown.Items>
                  </SelectDropdown>
                </div>

                {/* Year select */}
                <div className="flex basis-full lg:basis-1/2 px-1">
                  <SelectDropdown
                    className="w-full"
                    required
                    mode={selectedYear ? "dirty" : "empty"}
                    error={errors.year?.message ? true : false}
                  >
                    <SelectDropdown.Label>
                      {isEnglish ? "Year" : "Yıl"}
                    </SelectDropdown.Label>
                    <SelectDropdown.Trigger>
                      {selectedYear
                        ? selectedYear
                        : `${isEnglish ? "Year" : "Yıl"}`}
                    </SelectDropdown.Trigger>
                    <SelectDropdown.Items className="!top-5 !-translate-y-full min-h-[150px]">
                      {years
                        .sort((a, b) => b - a)
                        .map((year) => {
                          return (
                            <SelectDropdown.Item
                              key={`yearSelectItem${year}`}
                              onClick={() => setSelectedYear(year)}
                            >
                              {year}
                            </SelectDropdown.Item>
                          );
                        })}
                    </SelectDropdown.Items>
                  </SelectDropdown>
                </div>
              </div>

              {/* Save and close buton */}
              {!certificate && (
                <div className="flex flex-row gap-2 ml-auto items-center justify-between mt-6">
                  <Button
                    type="button"
                    className="flex"
                    variant="secondary"
                    size="sm"
                    onClick={() => setIsModalOpen(false)}
                  >
                    {isEnglish ? "Cancel" : "Vazgeç"}
                  </Button>
                  <Button type="submit" size="sm">
                    {isEnglish ? "Save" : "Kaydet"}
                  </Button>
                </div>
              )}
              {certificate && (
                <div className="flex flex-row items-center gap-2 justify-between mt-6">
                  <div className="flex ml-auto flex-row items-center gap-2">
                    <Button
                      type="button"
                      variant="secondary"
                      size="sm"
                      onClick={() => setIsEditOpen(false)}
                    >
                      {isEnglish ? "Cancel" : "Vazgeç"}
                    </Button>
                    <Button type="submit" size="sm">
                      {isEnglish ? "Save" : "Kaydet"}
                    </Button>
                  </div>
                </div>
              )}
            </form>
          </FormModal.Content>
        </FormModal>
      </div>
    );
  } else {
    /* Month name and year converter for fields */
    const date = new Date(certificate?.date || 0);
    const monthNumber = date.getMonth() + 1;
    const month = isEnglish
      ? months.find((m) => m.id == monthNumber)?.name_en
      : months.find((m) => m.id == monthNumber)?.name;
    const year = date.getFullYear();
    return (
      <>
        {/* Desktop section */}
        <div className="hidden lg:flex flex-row relative px-1 py-2 gap-4 border-transparent hover:border-gray-300 border-dashed border-2 rounded-md transition-all">
          <FormModal.Trigger>
            <div className="flex flex-col gap-2 absolute -top-2 right-2 lg:top-4 lg:right-4 z-10">
              <Button
                variant="ghost"
                size="xs"
                className="aspect-square !rounded-full z-10 !border-none"
                onClick={() => {
                  setIsEditOpen(true);
                  //setIsPageOpen(true)
                  setIsModalOpen(true);
                }}
              >
                <Button.Icon>
                  <FaPencilAlt className="text-xl" />
                </Button.Icon>
              </Button>
              <Button
                variant="ghost"
                size="xs"
                className="aspect-square !rounded-full !border-none"
                onClick={() => {
                  Swal.fire({
                    icon: "warning",
                    title: `${
                      isEnglish
                        ? "Are you sure to delete certificate?"
                        : "Sertifikayı silmek istediğinizden emin misiniz?"
                    }`,
                    confirmButtonText: `${isEnglish ? "Yes" : "Evet"}`,
                    cancelButtonText: `${isEnglish ? "No" : "Hayır"}`,
                    preConfirm: () => {
                      deleteCertificate();
                    },
                    showCancelButton: true,
                  });
                }}
              >
                <Button.Icon className="text-xl">
                  <IoTrashBinOutline />
                </Button.Icon>
              </Button>
            </div>
          </FormModal.Trigger>
          <div className="hidden lg:flex flex-col border-r pr-4">
            <IoDocumentText className="text-9xl text-mains/40" />
            <label className="text-center font-poppins font-semibold text-mains">
              {isEnglish ? "Certificate" : "Sertifika"}
            </label>
          </div>
          <div className="flex flex-col w-full">
            <div className="flex flex-row items-center h-full w-full">
              <div className="flex flex-col basis-1/2 gap-3 justify-center items-start h-full">
                {/* Certificate type section */}
                <div className="flex flex-col">
                  <label className="hidden lg:flex items-center gap-1 text-xs font-poppins font-semibold text-gary-700">
                    <IoDocumentText className="text-xs" />
                    {isEnglish
                      ? "Certificate Document Type"
                      : "Sertifika Belge Türü"}
                  </label>
                  <label className="font-poppins text-gray-600 text-sm">
                    {
                      certificates.find(
                        (c) => c.id == certificate?.certificate_work_area_id
                      )?.title
                    }
                  </label>
                </div>

                {/* Certificate name section */}
                <div className="flex flex-col">
                  <label className="hidden lg:flex items-center gap-1 text-xs font-poppins font-semibold text-gary-700">
                    <MdSubtitles className="text-xs" />
                    {isEnglish
                      ? "Certificate Document Name"
                      : "Sertifika Belge Adı"}
                  </label>
                  <label className="font-poppins text-gray-600 text-sm">
                    {certificate?.name}
                  </label>
                </div>
              </div>
              <div className="flex flex-col basis-1/2 gap-3 justify-center items-start h-full">
                {/* Company name section */}
                <div className="flex flex-col">
                  <label className="hidden lg:flex items-center gap-1 text-xs font-poppins font-semibold text-gary-700">
                    <FaCity className="text-xs" />
                    {isEnglish
                      ? "Issuing Institution / Company"
                      : "Alınan Kurum / Firma"}
                  </label>
                  <label className="font-poppins text-gray-600 text-sm">
                    {certificate?.company}
                  </label>
                </div>
                {/* Date section */}
                <div className="flex flex-col">
                  <label className="hidden lg:flex items-center gap-1 text-xs font-poppins font-semibold text-gary-700">
                    <FaCalendarCheck className="text-xs" />
                    {isEnglish ? "Date" : "Tarih"}
                  </label>
                  <label className="font-poppins text-gray-600 text-sm">
                    {month} - {year}
                  </label>
                </div>
              </div>
            </div>

            {/* Detail section if exist */}
            {certificate?.detail && (
              <div className="flex flex-col w-full">
                <label className="hidden lg:flex items-center gap-1 text-xs font-poppins font-semibold text-gary-700">
                  <CgDetailsMore className="text-xs" />
                  {isEnglish ? "Description" : "Açıklama"}
                </label>
                <label className="font-poppins text-gray-600 text-sm line-clamp-3">
                  {certificate?.detail}
                </label>
              </div>
            )}
          </div>
        </div>

        {/* Mobile section */}
        <div className="flex flex-row lg:hidden p-4">
          <div className="flex flex-col gap-2 flex-1">
            {/* Certificate type section */}
            <div className="flex flex-col">
              <label className="font-poppins text-[13px] font-semibold text-gray-900">
                {isEnglish
                  ? "Certificate Document Type"
                  : "Sertifika Belge Türü"}
              </label>
              <label className="font-poppins text-[13px] text-gray-800">
                {
                  certificates.find(
                    (c) => c.id == certificate?.certificate_work_area_id
                  )?.title
                }
              </label>
            </div>

            {/* Certificate name section */}
            <div className="flex flex-col">
              <label className="font-poppins text-[13px] font-semibold text-gray-900">
                {`${
                  isEnglish
                    ? "Certificate Document Name"
                    : "Sertifika Belge Adı"
                }`}
              </label>
              <label className="font-poppins text-[13px] text-gray-800">
                {certificate?.name}
              </label>
            </div>

            {/* Company name section */}
            <div className="flex flex-col">
              <label className="font-poppins text-[13px] font-semibold text-gray-900">
                {`${
                  isEnglish
                    ? "Issuing Institution / Company"
                    : "Alınan Kurum / Firma"
                }`}
              </label>
              <label className="font-poppins text-[13px] text-gray-800">
                {certificate?.company}
              </label>
            </div>

            {/* Date section */}
            <div className="flex flex-col">
              <label className="font-poppins text-[13px] font-semibold text-gray-900">
                {isEnglish ? "Date" : "Tarih"}
              </label>
              <label className="font-poppins text-[13px] text-gray-800">
                {month} - {year}
              </label>
            </div>
          </div>

          {/* Edit and delete buttons */}
          <div className="flex flex-col gap-2 items-center">
            <button
              className="flex flex-col gap-1 items-center text-mains font-poppins text-xs"
              onClick={() => {
                setIsEditOpen(true);
                setIsPageOpen(true);
                setIsModalOpen(true);
              }}
            >
              <FaPencilAlt className="text-mains text-base" />
              {isEnglish ? "Edit" : "Düzenle"}
            </button>
            <button
              className="flex flex-col gap-1 items-center text-mains font-poppins text-xs"
              onClick={() => {
                Swal.fire({
                  icon: "warning",
                  titleText: `${
                    isEnglish
                      ? "Are you sure to delete certificate?"
                      : "Sertifikayı silmek istediğinizden emin misiniz?"
                  }`,
                  confirmButtonText: `${isEnglish ? "Yes" : "Evet"}`,
                  cancelButtonText: `${isEnglish ? "No" : "Hayır"}`,
                  preConfirm: () => {
                    deleteCertificate();
                  },
                  showCancelButton: true,
                });
              }}
            >
              <IoTrashBinOutline className="text-base" />
            </button>
          </div>
        </div>
      </>
    );
  }
}
