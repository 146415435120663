import * as React from "react";
import ProductsPageContainer from "../../containers/products-page-container";

function ProductsPage(): React.ReactElement {
  return (
    <>
      <ProductsPageContainer />
    </>
  );
}

export { ProductsPage };
