import { StepsPointsCard } from "./steps-points-card";
import { HeaderCard } from "./header-card";
import Body from "./body"
const SuccesedCreaetAdPage : React.FC = () => {
    return (
        <div className="container mx-auto flex flex-col gap-y-5 mb-10 lg:mb-0">
        <HeaderCard/>
        <StepsPointsCard/>
        <Body />
        </div>
    );
}
export { SuccesedCreaetAdPage };
