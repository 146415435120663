import React, { useState, useEffect, useContext } from "react";
import "leaflet/dist/leaflet.css";
import { FaTimes } from "react-icons/fa";
import { useCookie } from "../../../../hooks/useCookie";
import { CookieName } from "../../../../constants/enums";
import Axios from "../../../../services/axios";
import { CVPreviewContext } from "../contents";
import { useParams } from "react-router-dom";
import { LuLoader2 } from "react-icons/lu";
import Swal from "sweetalert2";

const axios = new Axios();
interface WorkingLocationPopupProps {
  onClose: () => void;
  onSuccess?: () => void;
}

const OpenCvPopup: React.FC<WorkingLocationPopupProps> = ({ onClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { cvViewCount, fetchIncompleteDetail } = useContext(CVPreviewContext);
  const { slug } = useParams();

  useEffect(() => {
    const timeout = setTimeout(() => {
      document.body.classList.add("overflow-hidden", "h-auto");
      setIsVisible(true);
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  const handleCloseClick = () => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
      document.body.classList.remove("overflow-hidden", "h-auto");
    }, 500);
  };

  const handleOpen = () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    axios
      .authRequest({
        type: "post",
        endpoint: `companies/cv/${slug}/open-cv-visibility`,
        payload: {},
      })
      .then((response: any) => {
        fetchIncompleteDetail();
        Swal.fire({
          icon: "success",
          title: "Cv İletişim Bilgileri Açıldı",
          confirmButtonText: "Tamam",
        });
        handleCloseClick();
      })
      .catch((err: any) => {
        Swal.fire({
          title: "Hata!",
          text: err.response.data.error,
          icon: "error",
        });
        console.log();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="fixed inset-0 z-[1050] flex items-start justify-center overflow-y-auto ">
      <div
        className="fixed inset-0 bg-black opacity-50 h-auto "
        onClick={handleCloseClick}
      ></div>

      <div
        className={`bg-white my-10 max-md:my-0 max-md:fixed max-md:top-0 max-md:left-0 max-md:w-screen max-md:h-screen max-md:overflow-y-auto md:rounded-lg md:shadow-lg  md:relative md:min-w-[40%] transition-all duration-500 ease-in-out transform ${
          isVisible ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-10"
        }`}
      >
        <div className={"flex justify-between border-b-2 py-5 px-5"}>
          <div className={"text-mains font-bold text-lg"}>Uyarı</div>
          <div
            onClick={handleCloseClick}
            className={
              "flex justify-self-end justify-center items-center w-5 h-5 "
            }
          >
            <FaTimes className={"text-gray-400"} />
          </div>
        </div>

        <div className={`p-5`}>
          İletişim bilgilerini açtığınızda 1 adet kredi hesabınızdan düşecektir.
          Onaylıyor musunuz?
        </div>
        <div className={"text-xl font-bold text-center p-5"}>
          Mevcut Kredi: {cvViewCount}
        </div>

        <div className={"flex justify-between border-t-2 py-5 px-5"}>
          <div
            onClick={handleCloseClick}
            className={
              "p-3 bg-gray-200 hover:bg-[#ff7f1d] hover:text-white transition-all rounded cursor-pointer font-bold"
            }
          >
            Hayır
          </div>
          <div
            onClick={() => {
              handleOpen();
            }}
            className={
              "p-3 bg-mains text-white justify-self-center rounded cursor-pointer font-bold"
            }
          >
            Evet
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenCvPopup;
