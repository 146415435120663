import Axios from "../axios";

const CorporateService = {
  getCorporateContent: (slug: string) => {
    const axios = new Axios();
    return axios.request({
      type: "get",
      endpoint: `home/kurumsal/${slug}`,
      payload: {},
    });
  },
};

export { CorporateService };
