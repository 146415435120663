import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { IoAdd, IoClose } from "react-icons/io5";
import { useFilePicker } from "use-file-picker";
import Button from "../../../../components/form-components/button";
import { FileSizeValidator } from "use-file-picker/validators";
import { FaCircle } from "react-icons/fa6";
import Swal from "sweetalert2";
import Axios from "../../../../services/axios";
import { isAxiosError } from "axios";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Photo } from "..";








const axios = new Axios()




export default function FilePickerModal({
    selectedPhoto,
    getPhoto,
    setIsOpen
}: {
    selectedPhoto: Photo | null;
    getPhoto: () => void;
    setIsOpen: Dispatch<SetStateAction<boolean>>
}) {

    const divRef = useRef<HTMLDivElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)

    const [imageName, setImageName] = useState<string>('')
    const [imageFile, setImageFile] = useState<File | null>(null)
    

    const { filesContent, openFilePicker } = useFilePicker({
        accept: ['.jpg', '.png', 'webp'],
        validators: [
            new FileSizeValidator({ maxFileSize: 5 * 1024 * 1024 /* 5 MB */ })
        ]
    })

    useEffect(() => {
        if (selectedPhoto) {
            setImageName(selectedPhoto.title)
        }
    }, [selectedPhoto])

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if ( divRef.current && !divRef.current.contains(event.target as Node)) {
                setIsOpen(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => document.removeEventListener("mousedown", handleClickOutside)
    }, [])
    return(
        <div className="bg-black bg-opacity-20 fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-50">
            <div ref={divRef} className="flex flex-col gap-2 bg-white py-4 px-6 max-w-[800px] w-full rounded-md font-poppins">
                <div className="flex flex-row items-center justify-between">
                    <label className="text-mains text-xl font-poppins">
                        {selectedPhoto ? 'GÖRSELİ DÜZENLE' : 'GÖRSEL EKLE'}
                    </label>
                    <button type="button" onClick={() => setIsOpen(false)}>
                        <IoClose className="text-2xl text-gray-600"/>
                    </button>
                </div>
                <hr/>
                <div className="flex flex-col lg:flex-row gap-4 my-4">
                    <div className="flex flex-col gap-4 basis-1/2 flex-shrink-0 lg:max-w-[50%]">
                        <div className="flex flex-col gap-1">
                            <label className="text-[14px] text-gray-600">
                                Görsel Adı
                                <b className="text-red-600">*</b>
                            </label>
                            <input
                            value={imageName || ''}
                            onChange={((e) => setImageName(e.target.value))}
                            className={`outline-none bg-transparent border ${imageName.trim().length > 0? 'border-mains text-mains': 'border-gray-400 text-gray-600'} rounded-md text-sm placeholder:text-gray-400 text-gray-600 p-2 w-full transition-all`}
                            />
                        </div>
                        <div className="flex flex-col gap-1">
                            <label className="text-[14px] text-gray-600">
                                Görsel
                                <b className="text-red-600">*</b>
                                {' '}
                                {selectedPhoto && (
                                    <a
                                    href={selectedPhoto?.file}
                                    className="text-mains font-semibold"
                                    target="_blank"
                                    >
                                        (Görseli Görüntüle)
                                    </a>
                                )}

                            </label>
                            <div className={`p-2 border ${imageFile? 'border-mains': 'border-gray-400'} rounded-md flex flex-row items-center justify-between gap-2`}>
                                <label className="text-mains text-sm overflow-hidden whitespace-nowrap text-ellipsis" onClick={() => { inputRef.current?.click() }}>
                                    {imageFile? `${imageFile.name}`: 'Görsel Seçiniz'}
                                </label>
                                <Button size="xs" onClick={() => {
                                    inputRef.current?.click()
                                }}>
                                    <Button.Icon>
                                        <IoAdd/>
                                    </Button.Icon>
                                    Seçim
                                </Button>
                            </div>
                        </div>
                        <input
                        ref={inputRef}
                        className="hidden"
                        placeholder="file picker"
                        type="file"
                        accept=".png,.jpg,.jpeg"
                        onChange={((e) => {
                            const files = e.target.files
                            if (files && files[0]) {
                                const size = (files[0].size / (1024 * 1024))
                                if (size > 2) {
                                    setErrorMessage('Lütfen dosya boyutu daha küçük bir görsel seçiniz.')
                                } else {
                                    setImageFile(files[0])
                                    setErrorMessage(null)
                                }
                            }
                        })}
                        />
                        {errorMessage && (
                            <label className="text-red-600 font-poppins text-sm">
                                {errorMessage}
                            </label>
                        )}
                    </div>
                    <div className="flex flex-col gap-2 basis-1/2 flex-shrink-0">
                        <label className="text-sm text-gray-600">
                            Görsel Ekleme Hakkında Bilgiler
                        </label>
                        <label className="flex flex-row items-start text-xs text-gray-600 gap-2">
                            <FaCircle className="text-[6px] text-mains flex-shrink-0 flex mt-1"/>
                            Görsel boyutu 2 MB&apos;dan fazla olmamalıdır.
                        </label>
                        <label className="flex flex-row items-start text-xs text-gray-600 gap-2">
                            <FaCircle className="text-[6px] text-mains flex-shrink-0 flex mt-1"/>
                            Görsel türleri png, jpg, webp olmalıdır.
                        </label>
                        <label className="flex flex-row items-start text-xs text-gray-600 gap-2">
                            <FaCircle className="text-[6px] text-mains flex-shrink-0 flex mt-1"/>
                            Görselin düzgün durması için boyutun 780x240 px olması gerekmektedir.
                        </label>
                    </div>
                </div>
                <hr/>
                <div className="flex flex-row items-center justify-between">
                    <Button type="button" size="sm" variant="secondary" onClick={() => setIsOpen(false)}>
                        Kapat
                    </Button>
                    <Button type="button" size="sm" onClick={sendImage} disabled={isLoading}>
                        {isLoading ? (
                            <AiOutlineLoading3Quarters className="mx-auto animate-spin"/>
                        ): `${selectedPhoto ? 'Kaydet' : 'Ekle'}`}
                    </Button>
                </div>
            </div>
        </div>
    )

    async function sendImage() {

        if (imageName.trim().length == 0 && !imageFile) {
            setErrorMessage('Lütfen görsel adı giriniz ve görsel seçiniz.')
            return
        }

        if (imageName.trim().length == 0) {
            /* Swal.fire({
                title: 'Uyarı',
                text: 'Lütfen görsel adı giriniz.',
                icon: 'warning',
                confirmButtonText: 'Tamam'
            }) */
            setErrorMessage('Lütfen görsel adı giriniz.')
            return
        }

        if (!imageFile) {
            /* Swal.fire({
                title: 'Uyarı',
                text: 'Lütfen görsel seçiniz.',
                icon: 'warning',
                confirmButtonText: 'Tamam'
            }) */
            setErrorMessage('Lütfen görsel seçiniz.')
            return
        }
        
        const formData = new FormData()

        formData.append('title', imageName)
        formData.append('image', imageFile)

        setIsLoading(true)

        if (selectedPhoto) {
            formData.append('file_id', selectedPhoto.id.toString())
            try {
                const response = await axios.authRequest({
                    endpoint: `companies/advert/update-file`,
                    type: 'post',
                    payload: formData
                })
    
                console.log('response', response)
    
                getPhoto()
            } catch (error) {
                console.log(`Error: ${error}`)
            }
            
        } else {
            try {
                const response = await axios.authRequest({
                    endpoint: 'companies/advert/upload-file',
                    type: 'post',
                    payload: formData
                })
    
                if (response.status == 200) {
                    getPhoto()
                    setIsOpen(false)
                } else {
                    Swal.fire({
                        title: 'Hata',
                        text: 'Görsel yüklenirken bir hata meydana geldi.',
                        icon: 'error',
                        confirmButtonText: 'Tamam'
                    })
                }
            } catch (error) {
                console.log(`File upload failed. Error: ${error}`)
                if (isAxiosError(error) && error.response && error.response.data && error.response.data.errors) {
                    Swal.fire({
                        title: 'Hata',
                        icon: 'error',
                        text: error.response.data.errors.image?? 'Görsel yüklenirken bir hata meydana geldi.',
                        confirmButtonText: 'Tamam'
                    })
                } else {
                    Swal.fire({
                        title: 'Hata',
                        icon: 'error',
                        text: 'Görsel yüklenirken beklenmedik bir hata meydana geldi.',
                        confirmButtonText: 'Tamam'
                    })
    
                }
            }
        }
        

        setIsLoading(false)
    }
}