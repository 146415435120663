import React, { useState, useEffect, useRef, ChangeEvent, FormEvent } from "react";
import Axios from "../../../../services/axios";
import { IoIosClose, IoMdSearch } from "react-icons/io";
import iconUrl from "leaflet/dist/images/marker-icon.png";
import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png";
import shadowUrl from "leaflet/dist/images/marker-shadow.png";
import { MapContainer, TileLayer, Marker, useMap, useMapEvents } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import CustomApplicationDropdown from "../application-select-input";
import Positions from "./Database/Positions";
import PositionLevel from "./Database/PositionLevel";
import Sectors from "./Database/Sectors";
import Sections from "./Database/Sections";
import TypeWork from "./Database/TypeWork";
import Swal from "sweetalert2";
import { SelectDropdown } from "../../../../components/isbul-ui";
import { useCookie } from "../../../../hooks/useCookie";
import { CookieName } from "../../../../constants/enums";
// Define the default marker icon configuration
const defaultIcon = new L.Icon({
  iconUrl,
  iconRetinaUrl,
  shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

// Adjust the LocationMarker component to manage marker position
const LocationMarker: React.FC<{
  onLocationSelect: (location: L.LatLng) => void;
}> = ({ onLocationSelect }) => {
  useMapEvents({
    click(e) {
      onLocationSelect(e.latlng);
    },
  });

  return null; // No marker is rendered directly by this component
};

type SelectorType = {
  data: any[];
  selectValue: string | number;
  onSelect?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  label: string;
};
const Selector: React.FC<SelectorType> = ({ data, selectValue, onSelect, label }) => {
  return (
    <div className="w-full">
      <SelectDropdown required mode={"dirty"}>
        <SelectDropdown.Label>{label}</SelectDropdown.Label>
        <SelectDropdown.Trigger>{selectValue}</SelectDropdown.Trigger>
        <SelectDropdown.Items>
          {data?.map((l: any, i: number) => {
            return (
              <SelectDropdown.Item key={`dd1-${i}`} onClick={onSelect}>
                {l.label}
              </SelectDropdown.Item>
            );
          })}
        </SelectDropdown.Items>
      </SelectDropdown>
    </div>
  );
};

interface IDate {
  year: number;
  month: number;
}
interface Option {
  value: string;
  label: string;
}
interface IFormData {
  company_name: string;
  position: string;
  position_level: string;
  sector_id: number;
  section_id: number;
  city_id: number;
  country_id: number;
  is_abroad: string;
  working_type_id: number;
  start_date: {
    year: number;
    month: number;
  };
  end_date: {
    year: number;
    month: number;
  };
  leaving_reason: string;
  work_info: string;
  now_working: boolean;
  position_id?: number;
  position_Seviyesi?: number;
  Sektör?: number;
  Bölüm?: number;
  Şehir?: number;
  typework?: number;
}

interface WorkingLocationPopupProps {
  onClose: () => void;
}

const ReportPopup: React.FC<WorkingLocationPopupProps> = ({ onClose }) => {
  const [formData, setFormData] = useState<IFormData>({
    company_name: "",
    position: "",
    position_level: "",
    sector_id: 0,
    section_id: 0,
    city_id: 0,
    country_id: 0,
    is_abroad: "0",
    working_type_id: 0,
    start_date: {
      year: 0,
      month: 0,
    },
    end_date: {
      year: 0,
      month: 0,
    },
    leaving_reason: "",
    work_info: "",
    now_working: false,
  });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsVisible(true), 10);
    return () => clearTimeout(timeout);
  }, []);

  const handleCloseClick = () => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
    }, 500);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const target = e.target as HTMLInputElement; // Casting to HTMLInputElement is okay for value and checked access
    const { name, type, value } = target;

    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        [name]: target.checked, // Use the boolean value directly
      }));
    } else if (name.includes("year") || name.includes("month")) {
      const [field, key] = name.split("_") as ["start_date" | "end_date", "year" | "month"];
      setFormData((prev) => ({
        ...prev,
        [field]: {
          ...prev[field],
          [key]: parseInt(value, 10),
        },
      }));
    } else {
      // This block handles inputs like textareas
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleDropdownChange = (option: Option, field: keyof IFormData) => {
    setFormData((prev) => ({
      ...prev,
      [field]: parseInt(option.value),
    }));
  };

  const { getCookie } = useCookie();
  const companyUserToken = getCookie(CookieName.CompanyUserToken);

  const userToken = getCookie(CookieName.UserToken);

  const axios = new Axios();

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    console.log(companyUserToken);

    try {
      const response = await axios.request({
        type: "post",
        endpoint: "user/profilim/cv/exp/create",
        payload: formData,
      });

      Swal.fire({
        icon: "success",
        title: "Başvuru yapıldı!",
        timer: 3000,
      });
      handleCloseClick();
    } catch (error: any) {
      Swal.fire({
        icon: "error",
        title: "Alanları Doldurunuz...",
        text: error.response.data?.message,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "sweet-alert-button", // TailwindCSS classes
        },
      });
      handleCloseClick();
    }
  };

  const years = Array.from({ length: 2024 - 1999 + 1 }, (_, i) => 1999 + i);
  const months = Array.from({ length: 12 }, (_, i) => ({
    value: (i + 1).toString(), // store month as string '1' to '12'
    label: (i + 1).toString().padStart(2, "0"), // display '01' to '12'
  }));

  const handleChangeDate = (value: string, field: "start_date" | "end_date", key: "year" | "month") => {
    setFormData((prev) => ({
      ...prev,
      [field]: {
        ...prev[field],
        [key]: parseInt(value, 10),
      },
    }));
  };

  return (
    <div className="fixed inset-0 z-[100000] flex items-center justify-center overflow-auto">
      <div className="absolute inset-0 bg-black opacity-50" onClick={handleCloseClick}></div>

      <div
        className={`bg-white p-5 rounded-lg shadow-lg relative w-11/12 md:w-1/2 transition-all duration-500 ease-in-out transform ${
          isVisible ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-10"
        }`}
      >
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold text-[#2253af]">Başvuru</h2>
          <button
            type="button"
            title="close-button"
            onClick={handleCloseClick}
            className="text-gray-600 hover:text-gray-800"
          >
            <IoIosClose size={24} />
          </button>
        </div>
        <div className="mt-6">
          <div className="md:flex md:items-center mb-8 space-y-2">
            {/* <Selector 
            label="Pozisyon Ara" 
            selectValue={formData.position?formData.position:"Seçiniz"}
            data={Positions}
            onSelect={(e:any)=>console.log(e.target.textContent)}
             />
        */}
            <CustomApplicationDropdown
              onChange={(option) => handleDropdownChange(option, "position_id")}
              options={Positions}
              placeholder="Seçiniz"
              label="Pozisyon Adı"
              id="positionName"
            />
            <CustomApplicationDropdown
              onChange={(option) => handleDropdownChange(option, "position_Seviyesi")}
              options={PositionLevel}
              placeholder="Seçiniz"
              label="Pozisyon Seviyesi "
              id="positionLevel"
            />
            <CustomApplicationDropdown
              onChange={(option) => handleDropdownChange(option, "Sektör")}
              options={Sectors}
              placeholder="Seçiniz"
              label="Sektör"
              id="Sektör"
            />
          </div>

          <div className="md:flex md:items-center mb-8 space-y-2">
            <CustomApplicationDropdown
              onChange={(option) => handleDropdownChange(option, "Bölüm")}
              options={Sections}
              placeholder="Seçiniz"
              label="Bölüm"
              id="Bölüm"
            />
            <CustomApplicationDropdown
              onChange={(option) => handleDropdownChange(option, "Şehir")}
              options={Positions}
              placeholder="Seçiniz"
              label="Şehir"
              id="Şehir"
            />
            <CustomApplicationDropdown
              onChange={(option) => handleDropdownChange(option, "typework")}
              options={TypeWork}
              placeholder="Seçiniz"
              label="Çalışma Şekli "
              id="typework"
            />
          </div>

          <div className="mt-4 mb-4 flex items-center">
            <label>
              <input
                type="checkbox"
                name="is_abroad"
                checked={formData.is_abroad === "1"} // Assuming '1' represents true
                onChange={handleChange}
                className="scale-150"
              />
              <span className="ml-3"> Yurt Dışı</span>
            </label>
          </div>

          <div className="flex items-center gap-2">
            <div className="w-[50%]">
              <p className="mb-1">
                İşe Başlama Tarihi <span className="text-red-500">*</span>
              </p>
              <div className="flex items-center">
                <CustomApplicationDropdown
                  options={months}
                  placeholder="Ay" // Month
                  label="Ay"
                  id="startMonth"
                  onChange={(option) => handleChangeDate(option.value, "start_date", "month")}
                />
                <CustomApplicationDropdown
                  options={years.map((year) => ({
                    value: year.toString(),
                    label: year.toString(),
                  }))}
                  placeholder="Yıl" // Year
                  label="Yıl"
                  id="startYear"
                  onChange={(option) => handleChangeDate(option.value, "start_date", "year")}
                />
              </div>
            </div>

            <div className="w-[50%]">
              <p className="mb-1">
                İşten Ayrılma Tarihi <span className="text-red-500">*</span>
              </p>
              <div className="flex items-center">
                <CustomApplicationDropdown
                  options={months}
                  placeholder="Ay"
                  label="Ay"
                  id="endMonth"
                  onChange={(option) => handleChangeDate(option.value, "end_date", "month")}
                />
                <CustomApplicationDropdown
                  options={years.map((year) => ({
                    value: year.toString(),
                    label: year.toString(),
                  }))}
                  placeholder="Yıl"
                  label="Yıl"
                  id="endYear"
                  onChange={(option) => handleChangeDate(option.value, "end_date", "year")}
                />
              </div>
            </div>
          </div>

          <div className="mt-4 flex items-center">
            <label>
              <input
                type="checkbox"
                name="now_working"
                checked={formData.now_working} // Directly use the boolean value
                onChange={handleChange}
                className="scale-150"
              />
              <span className="ml-3"> Halen Çalışıyorum</span>
            </label>
          </div>

          <div className="mt-4">
            <label htmlFor="jobDescription">İş Tanımı</label>
            <textarea
              id="jobDescription"
              name="work_info"
              className="w-full p-2 border rounded"
              placeholder="İş tanımınızı yazınız"
              value={formData.work_info}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="mt-4 flex justify-end">
          <button
            onClick={handleSubmit}
            className="bg-[#2b54ab] hover:bg-blue-700 text-white text-xl md:text-xl font-semibold py-4 px-6 rounded"
          >
            Kaydet
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportPopup;
