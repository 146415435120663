import Axios from "../services/axios"
import { cdnHighSchoolTypeType } from "../types"







type getHighSchoolTypesProps = {
    search?: string,
    limit?: number,
    only_ids?: number[]
}




const axios = new Axios()


export async function getHighSchoolTypes({
    limit,
    search,
    only_ids
}: getHighSchoolTypesProps): Promise<cdnHighSchoolTypeType[]> {
    try {
        const response = await axios.request({
            endpoint: `cdn/get-high-school-types?
            ${search? `search=${search}&`: ''}
            ${limit? `limit=${limit}&`: ''}
            ${only_ids? `only_ids=${JSON.stringify(only_ids)}`: ''}
            `,
            type: 'get'
        })

        if (response && response.data && response.data.high_school_types) {
            return JSON.parse(JSON.stringify(response.data.high_school_types))
        } else {
            return []
        }
    } catch (error) {
        console.log(`High school types fetch failed. Error: ${error}`)
        return []
    }
}