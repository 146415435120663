import React, { useState, useRef, useEffect } from "react";
import { FcApproval } from "react-icons/fc";
import CustomButton from "../contents2/custom-button";
import CustomInput from "../contents2/custom-input";
import { IoMdTime } from "react-icons/io";
import { useAppSelector, useAppDispatch } from "../../../../store/store";
import {
  sendUserVerifySms,
  confirmUserVerifySms,
} from "../../../../store/content-reducer/personInfoSlice";
import { toast } from "react-toastify";
import { IoArrowBackOutline } from "react-icons/io5";
import { hideSmsContent } from "../../../../store/content-reducer/smsPageSlice";

const ConfirmSms = () => {
  const userPhone = useAppSelector((store) => store.userInfo.phone);

  useEffect(() => {
    console.log("Updated phone number:", userPhone);
  }, [userPhone]);

  const dispatch = useAppDispatch();
  const [remainingTime, setRemainingTime] = useState(180);
  const [verificationCode, setVerificationCode] = useState(["", "", "", ""]);
  const [canResend, setCanResend] = useState(true);
  const [hasInitiallyPressed, setHasInitiallyPressed] = useState(false);
  const [isCodeInvalid, setIsCodeInvalid] = useState(false);

  const inputRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!canResend && hasInitiallyPressed) {
      timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime === 1) {
            setCanResend(true);
            toast.dismiss("countdown-toast");
          }
          return prevTime > 0 ? prevTime - 1 : 0;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timer);
      toast.dismiss("countdown-toast");
    };
  }, [canResend, hasInitiallyPressed]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const code = verificationCode.join("");
    console.log("doğrulama kodu:", code);
    const data = { code: code, phone: userPhone };
    dispatch(confirmUserVerifySms(data))
      .unwrap()
      .then(() => {
        setIsCodeInvalid(false);
      })
      .catch(() => {
        setIsCodeInvalid(true);
      });
  };

  const handleChange = (index: number, value: string) => {
    const updatedCode = [...verificationCode];
    updatedCode[index] = value;
    setVerificationCode(updatedCode);
    if (value && index < inputRefs.length - 1) {
      inputRefs[index + 1].current?.focus();
    } else if (!value && index > 0) {
      inputRefs[index - 1].current?.focus();
    }
  };

  const handleResend = () => {
    if (canResend) {
      setRemainingTime(180);
      setCanResend(false);
      setHasInitiallyPressed(true);
      const phone = { phone: userPhone };
      dispatch(sendUserVerifySms(phone));
      setVerificationCode(["", "", "", ""]);
      toast.success("Doğrulama sms'i telefonunuza gönderildi!");
    } else {
      toast.info(
        `Doğrulama kodunu tekrar göndermek için ${remainingTime} saniye kaldı`,
        {
          toastId: "countdown-toast",
        }
      );
    }
  };

  const handleGoBack = () => {
    dispatch(hideSmsContent());
  };

  const isVerificationCodeEmpty = verificationCode.some((code) => code === "");

  const formatPhoneNumber = (phoneNumber: string, phoneCode: string) => {
    // Remove non-numeric characters
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    // Match and format the number
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `0 (${match[1]}) ${match[2]} ${match[3]} ${match[4]}`;
    }
    return phoneNumber;
  };

  return (
    <div className="flex flex-col w-full bg-white rounded-xs shadow-xs justify-center items-center gap-3 py-20 relative">
      <div
        onClick={handleGoBack}
        className="absolute right-0 top-0 p-4 cursor-pointer"
      >
        <IoArrowBackOutline
          size={30}
          className="text-blue-800 hover:text-blue-700"
        />
      </div>
      <div className="flex flex-col gap-4 justify-center items-center">
        <FcApproval size={150} />
        <p className="font-extrabold text-xl tracking-wide">
          Doğrulama Kodunu Gir
        </p>
      </div>
      <div className="flex flex-col justify-center items-center my-4">
        <p>Kod gönderdiğimiz telefon numarası:</p>
        <p className="font-extrabold tracking-wide">
          {formatPhoneNumber(userPhone, "")}
        </p>
      </div>
      <form onSubmit={handleSubmit} className="flex flex-col gap-7">
        <div className="flex gap-1 justify-center items-center mt-4">
          {[0, 1, 2, 3].map((index) => (
            <CustomInput
              key={index}
              title="confirm"
              design={`w-10 h-10 rounded-xl pl-[14px] custom-input ${
                isCodeInvalid ? "border-red-500 bg-red-100" : ""
              }`}
              defaultValue={verificationCode[index] || ""}
              onChange={(value) => handleChange(index, value)}
              maxLength={1}
              inputRef={inputRefs[index]}
              type="number"
            />
          ))}
        </div>
        <CustomButton
          title="Doğrula"
          designs={`rounded-xl w-52 py-3 transition hover:text-gray-700 ${
            isVerificationCodeEmpty ? "bg-gray-300" : "bg-green-500"
          }`}
          btnType="submit"
          isDisabled={isVerificationCodeEmpty}
        />
      </form>
      <div className="flex items-center justify-center gap-2">
        <IoMdTime size={20} />
        <div>{formatTime(remainingTime)}</div>
      </div>
      <div
        onClick={handleResend}
        className={`underline underline-offset-1 text-sm font-bold text-gray-600 cursor-pointer ${
          canResend ? "hover:text-gray-800" : "hover:text-gray-800"
        }`}
      >
        Tekrar Gönder
      </div>
      <div className="underline underline-offset-1 text-sm font-bold text-gray-600 mt-[35px] hover: cursor-pointer">
        <a href="">Hatalı Telefon Numarası Girdim</a>
      </div>
    </div>
  );
};

export default ConfirmSms;
