import classNames from "classnames"
import { Dispatch, HTMLAttributes, SetStateAction, createContext, useContext, useEffect, useRef, useState } from "react"
import Input from "./Input"
import Label from "./Label"
import Items from "./Items"
import Item from "./Item"
import Trigger from "./Trigger"
import Value from "./Value"
import Error from "./Error"


type modeType = 'empty' | 'dirty'

// Component Type
type selectSearchDropdownPropsType = HTMLAttributes<HTMLDivElement> & {
    isOpen?: boolean,
    setIsOpen?: Dispatch<SetStateAction<boolean>>,
    required?: boolean,
    error?: boolean,
    mode?: modeType
}


// Context Type
export type selectSearchDropdownContextType = {
    required?: boolean,
    mode?: modeType,
    error?: boolean,
    isOpen: boolean,
    setIsOpen: Dispatch<SetStateAction<boolean>>
}

// Context Provider
export const SelectSearchDropdownContext = createContext<selectSearchDropdownContextType>({
    required: false,
    mode: 'empty',
    error: false,
    isOpen: false,
    setIsOpen: () => {}
})

export function SelectSearchDropdown({
    className,
    isOpen: initialIsOpen,
    setIsOpen: initialSetIsOpen,
    children,
    required,
    error,
    mode = 'empty',
    ...props
}: selectSearchDropdownPropsType) {

    const divRef = useRef<HTMLDivElement>(null)

    const cn = classNames({
        'flex flex-col gap-1 relative': true
    })

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const value: selectSearchDropdownContextType = {
        isOpen: initialIsOpen != undefined? initialIsOpen: isOpen,
        setIsOpen: initialSetIsOpen != undefined? initialSetIsOpen: setIsOpen,
        error,
        mode,
        required
    }

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if ( divRef.current && !divRef.current.contains(event.target as Node)) {
                if (initialSetIsOpen) {
                    initialSetIsOpen(false)
                } else {
                    setIsOpen(false)
                }
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => document.removeEventListener("mousedown", handleClickOutside)
    }, [])
    return(
        <SelectSearchDropdownContext.Provider value={value}>
            <div ref={divRef} className={`${cn} ${className}`} {...props}>
                {children}
            </div>
        </SelectSearchDropdownContext.Provider>
    )
}




SelectSearchDropdown.Label = Label
SelectSearchDropdown.Trigger = Trigger
SelectSearchDropdown.Value = Value
SelectSearchDropdown.Input = Input
SelectSearchDropdown.Items = Items
SelectSearchDropdown.Item = Item
SelectSearchDropdown.Error = Error