import { useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { IoPerson } from "react-icons/io5";
import Axios from "../../../../../services/axios";
import Swal from "sweetalert2";
import { Button, Modal, Input, Select } from "antd";
import { TiArrowSortedUp } from "react-icons/ti";
const { TextArea } = Input;

interface InterviewUser {
  id: number;
  name: string;
  surname: string;
  email: string;
  profile: string;
  // Other properties can be added as needed
}

interface InterviewLocation {
  id: number;
  name: string;
  // Other properties can be added as needed
}

interface Interview {
  id: number;
  company_id: number;
  user_id: number;
  day: number;
  month: number;
  year: number;
  hour: string;
  interview_location_id: number;
  address: string;
  status: string;
  user_status: string;
  notes: string;
  message: string;
  is_passed: boolean;
  deleted_by_type: string | null;
  deleted_by: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  user: InterviewUser;
  interview_location: InterviewLocation;
}

interface InterviewListRowProps {
  interview: Interview;
  setInterviews: React.Dispatch<React.SetStateAction<Interview[]>>;
}

export default function InterviewListRow({
  interview,
  setInterviews,
}: InterviewListRowProps) {
  const axios = new Axios();
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editForm, setEditForm] = useState({
    day: interview.day,
    month: interview.month,
    year: interview.year,
    hour: interview.hour,
    interview_location_id: interview.interview_location_id,
    address: interview.address,
    notes: interview.notes,
    message: interview.message,
  });

  const showDetailModal = () => {
    setIsDetailModalOpen(true);
  };

  const handleOk = () => {
    setIsDetailModalOpen(false);
  };

  const handleCancel = () => {
    setIsDetailModalOpen(false);
  };

  const showEditModal = () => {
    setIsEditModalOpen(true);
  };

  const handleOk2 = async () => {
    try {
      const response = await axios.authRequest({
        type: "put",
        endpoint: `companies/interview/${interview.id}/update`,
        payload: editForm,
      });
      if (response.status === 200) {
        // Update the interviews state after successful update
        setInterviews((prev) =>
          prev.map((int) =>
            int.id === interview.id ? { ...int, ...editForm } : int
          )
        );
        Swal.fire("Başarılı!", "Mülakat başarıyla güncellendi.", "success");
      }
    } catch (error) {
      console.log(error);
      Swal.fire("Hata!", "Mülakatı güncellerken bir hata oluştu.", "error");
    }
    setIsEditModalOpen(false);
  };

  const handleCancel2 = () => {
    setIsEditModalOpen(false);
  };

  const handleChange = (value: string, key: string) => {
    setEditForm((prev) => ({ ...prev, [key]: value }));
  };

  const interviewDate = `${interview.day}.${interview.month}.${interview.year} ${interview.hour}`;
  const interviewDate2 = `${interview.day}.${interview.month}.${interview.year}`;
  const user = interview.user;
  const status =
    interview.user_status === "JOIN" ? "Katılacağım" : "Katılmayacağım";

  const handleCancelInt = async (id: number) => {
    const result = await Swal.fire({
      title: "Emin misiniz?",
      text: "Mülakatı iptal etmek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
      cancelButtonText: "İptal",
    });

    if (result.isConfirmed) {
      try {
        await axios.authRequest({
          type: "delete",
          endpoint: `companies/interview/${id}/delete`,
          payload: {},
        });
        // Update the interviews state after deletion
        setInterviews((prev) =>
          prev.filter((interview) => interview.id !== id)
        );
        Swal.fire("Başarılı!", "Mülakat başarıyla iptal edildi.", "success");
      } catch (error) {
        console.log(error);
        Swal.fire("Hata!", "Mülakatı iptal ederken bir hata oluştu.", "error");
      }
    }
  };

  const generateHourOptions = () => {
    const hours = [];
    for (let h = 8; h <= 22; h++) {
      const hour = h < 10 ? `0${h}` : h;
      hours.push({ value: `${hour}:00`, label: `${hour}:00` });
      if (h !== 22) {
        hours.push({ value: `${hour}:30`, label: `${hour}:30` });
      }
    }
    return hours;
  };

  const dayOptions = Array.from({ length: 31 }, (_, i) => ({
    value: (i + 1).toString(), // Convert to string
    label: (i + 1).toString(),
  }));

  const monthOptions = [
    { value: "1", label: "Ocak" },
    { value: "2", label: "Şubat" },
    { value: "3", label: "Mart" },
    { value: "4", label: "Nisan" },
    { value: "5", label: "Mayıs" },
    { value: "6", label: "Haziran" },
    { value: "7", label: "Temmuz" },
    { value: "8", label: "Ağustos" },
    { value: "9", label: "Eylül" },
    { value: "10", label: "Ekim" },
    { value: "11", label: "Kasım" },
    { value: "12", label: "Aralık" },
  ];

  const baseURL = "https://isbull.s3.eu-north-1.amazonaws.com/";

  return (
    <>
      <tr className="bg-[#fcfcfc] border-y-8 border-gray-100">
        <td className=" p-1 md:p-3">
          <a
            href="/isveren/mulakat-listesi"
            className="flex flex-row items-center gap-3"
          >
            <div className="w-20 h-20 flex items-center justify-center bg-gray-100 aspect-square flex-shrink-0 rounded-full">
              <img
                className="object-cover rounded-full"
                src={`${interview.user.profile}`}
                alt=""
              />
            </div>
            <label className="text-[14px] text-gray-800 font-poppins cursor-pointer">
              {user.name} {user.surname}
            </label>
          </a>
        </td>
        <td className=" p-1 md:p-3">
          <label className="text-xs text-gray-800 font-poppins">
            {interviewDate}
          </label>
        </td>
        <td className=" p-1 md:p-3">
          <label className={`text-xs ${status == 'Katılacağım' ? 'text-[#198754]' : 'text-red-600'} font-poppins`}>
            {status}
          </label>
        </td>
        <td className=" p-1 md:p-3">
          <div className="flex items-end relative">
            <div className="ml-auto peer">
              <BsThreeDotsVertical className="text-2xl text-gray-600" />
            </div>
            <div className="hidden peer-hover:flex hover:flex absolute top-10 w-full right-0 flex-col gap-1 py-6 px-2 bg-white shadow-sm shadow-gray-700 z-40">
              <TiArrowSortedUp className="text-mains absolute text-4xl -top-[22px] -right-[6px]" />

              <button
                className="p-1 bg-white text-sm text-start w-full border border-gray-200 rounded-sm text-mains hover:text-white hover:bg-mains transition-all"
                onClick={showDetailModal}
              >
                Detaylar
              </button>
              <button
                className="p-1 bg-white text-sm text-start w-full border border-gray-200 rounded-sm text-mains hover:text-white hover:bg-mains transition-all"
                onClick={showEditModal}
              >
                Düzenle
              </button>
              <button
                onClick={() => handleCancelInt(interview.id)}
                className="p-1 bg-white text-sm text-start w-full border border-gray-200 rounded-sm text-mains hover:text-white hover:bg-mains transition-all"
              >
                İptal Et
              </button>
            </div>
          </div>
        </td>
      </tr>

      <Modal
        title="MÜLAKAT DETAYLARI"
        open={isDetailModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="flex flex-col items-start justify-center w-full gap-4">
          <div className="flex flex-col justify-center items-start w-full gap-[2px]">
            <label className="font-poppins font-regular text-xs">Aday</label>
            <label className="font-poppins poppins-medium text-base">
              {interview.user.name}
            </label>
          </div>

          <div className="flex justify-start items-center w-full gap-8">
            <div className="flex flex-col justify-start items-start gap-[2px]">
              <label className="font-poppins font-regular text-xs text-left">
                Mülakat Tarihi
              </label>
              <label className="font-poppins poppins-medium text-base text-left">
                {interviewDate2}
              </label>
            </div>

            <div className="flex flex-col justify-start items-start gap-[2px]">
              <label className="font-poppins font-regular text-xs text-left">
                Mülakat Saati
              </label>
              <label className="font-poppins poppins-medium text-base text-left">
                {interview.hour}
              </label>
            </div>
          </div>

          <div className="flex justify-center items-start w-full flex-col gap-[2px]">
            <label className="font-poppins font-regular text-sm">Notlar</label>
            <TextArea
              defaultValue={interview.notes}
              rows={4}
              placeholder="Notunuzu giriniz"
              maxLength={100}
              readOnly
            />
          </div>

          <div className="flex justify-center items-start w-full flex-col gap-[2px]">
            <label className="font-poppins font-regular text-sm">Mesaj</label>
            <TextArea
              defaultValue={interview.message}
              rows={4}
              placeholder="Mesajınızı giriniz"
              maxLength={200}
              readOnly
            />
          </div>
        </div>
      </Modal>

      <Modal
        title="MÜLAKAT GÜNCELLE"
        open={isEditModalOpen}
        onOk={handleOk2}
        onCancel={handleCancel2}
        footer={[
          <div className="flex justify-end items-center w-full px-4 pb-4">
            <Button key="submit" type="primary" onClick={handleOk2}>
              Güncelle
            </Button>
          </div>,
        ]}
      >
        <div className="flex flex-col items-start justify-center w-full gap-4">
          <div className="flex flex-col justify-center items-start w-full gap-[2px]">
            <label className="font-poppins font-regular text-xs mb-1">
              Mülakat Tarihi <span className="text-red-500">*</span>
            </label>
            {/* tarih için select eklenicek */}

            <div className="w-full flex justify-start items-center gap-4">
              <Select
                defaultValue={editForm.day.toString()} // Convert to string
                style={{ width: 120 }}
                onChange={(value) => handleChange(value, "day")}
                options={dayOptions}
              />
              <Select
                defaultValue={editForm.month.toString()} // Convert to string
                style={{ width: 120 }}
                onChange={(value) => handleChange(value, "month")}
                options={monthOptions}
              />
              <Select
                defaultValue={editForm.hour}
                style={{ width: 120 }}
                onChange={(value) => handleChange(value, "hour")}
                options={generateHourOptions()}
              />
            </div>
          </div>

          <div className="flex justify-start items-center w-full gap-8">
            <div className="flex flex-col justify-start items-start gap-[2px]">
              <label className="font-poppins font-regular text-xs text-left mb-1">
                Mülakat Yeri <span className="text-red-500">*</span>
              </label>

              {/* Mülakat için select eklenicek */}
              <div className="flex justify-start items-center w-full">
                <Select
                  defaultValue={editForm.interview_location_id.toString()}
                  style={{ width: 150 }}
                  onChange={(value) =>
                    handleChange(value, "interview_location_id")
                  }
                  options={[
                    { value: "1", label: "Online Mülakat" }, // Convert to string
                    { value: "2", label: "Ofisten Yüz Yüze" }, // Convert to string
                  ]}
                />
              </div>
            </div>
          </div>

          <div className="flex justify-center items-start w-full flex-col gap-[2px]">
            <label className="font-poppins font-regular text-sm">Notlar</label>
            <TextArea
              value={editForm.notes}
              onChange={(e) => handleChange(e.target.value, "notes")}
              rows={4}
              placeholder="Notunuzu giriniz"
              maxLength={100}
            />
          </div>

          <div className="flex justify-center items-start w-full flex-col gap-[2px]">
            <label className="font-poppins font-regular text-sm">Mesaj</label>
            <TextArea
              value={editForm.message}
              onChange={(e) => handleChange(e.target.value, "message")}
              rows={4}
              placeholder="Mesajınızı giriniz"
              maxLength={200}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
