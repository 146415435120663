import { FaRegStar } from "react-icons/fa";
import { MdMonitor } from "react-icons/md";
import { Ri24HoursFill } from "react-icons/ri";
















export default function ModernSection() {
    return(
        <div className="bg-[#298edd] py-4">
            <div className="flex flex-col container mx-auto gap-6">
                <h2 className="text-3xl text-white text-center">
                    Modern Özellikler
                </h2>
                <div className="flex flex-col justify-center items-center gap-4 text-white">
                    <div className="flex flex-row gap-2 items-center text-lg font-[500]">
                        <MdMonitor className="text-2xl"/>
                        KOLAY KULLANIM
                    </div>
                    <div className="flex flex-row gap-2 items-center text-lg font-[500]">
                        <Ri24HoursFill className="text-2xl"/>
                        7/24 ULAŞILABİLİRLİK
                    </div>
                    <div className="flex flex-row gap-2 items-center text-lg font-[500]">
                        <FaRegStar className="text-2xl"/>
                        SINIRSIZ ÖZELLİK
                    </div>
                </div>
            </div>
        </div>
    )
}