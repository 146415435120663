import React, { useEffect } from "react";
import CustomAccordion from "../../../../components/common/custom-accordion";
import { useFormik } from "formik";
import { CgSpinner } from "react-icons/cg";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import FormField from "../../../../components/common/form-field";
import Axios from "../../../../services/axios";
import { CustomButton } from "../../../../components/common/custom-button";

type formField = {
  label: string;
  name: string;
  required?: boolean;
  type?: string;
  emailVerified?: boolean;
  [key: string]: any; //
};
const axios = new Axios();
function CompanyEmailNotification() {
  const updateNotification = async (values: { [key: string]: string }) => {
    try {
      await axios.authRequest({
        type: "put",
        endpoint: "companies/account-settings/email-notification-update",
        payload: {
          _method: "PUT",
          ...values,
        },
      });
      toast.success("E-Posta bildirim seçimleriniz başarıyla güncellendi.");
    } catch (error: any) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.errors) {
        // Check if errors exist in the response
        const apiErrors = error.response.data.errors;
        // Iterate through the errors and display them using toast
        Object.keys(apiErrors).forEach((key) => {
          apiErrors[key].forEach((errorMessage: string) => {
            toast.error(errorMessage);
          });
        });
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        // If there is a single error message
        toast.error(error.response.data.error);
      } else {
        // If no specific error message received
        toast.error("An error occurred while changing password");
      }

      // Redirect to login if token is invalid
      if (
        error.response &&
        error.response.data &&
        error.response.data.error === "Token is invalid"
      ) {
        window.location.href = "/company/login";
      }
    }
  };
  const { companyUser } = useSelector((state: any) => state.companyUser);

  const formFields: formField[] = [
    {
      label: "E-Posta bilgilendirmelerini almak istiyor musunuz?",
      name: "email_general_notification",
      type: "checkbox",
    },
    {
      label: "Yeni ilan başvurusu geldiğinde bilgilendirilmek istiyorum.",
      name: "email_new_advert_application",
      type: "checkbox",
    },
    {
      label:
        "İlanların kapanma tarihleri bitimi yaklaştığına dair bilgilendirmelerini istiyorum.",
      name: "email_advert_close_before",
      type: "checkbox",
    },
    {
      label: "Haftalık İlan başvuru özeti gönderimi istiyorum.",
      name: "email_weekly_advertisement_summary",
      type: "checkbox",
    },
    {
      label:
        "Seminer – Etkinlik Duyuruları hakkında bilgilendirmelerini istiyorum.",
      name: "email_seminar_event",
      type: "checkbox",
    },
    {
      label:
        "İsbul.net ürünleri ile ilgili tanıtımlar hakkında bilgilendirmelerini istiyorum.",
      name: "email_isbul_product",
      type: "checkbox",
    },
    {
      label:
        "İsbul.net iş ortaklarının kampanya ve tanıtım duyuruları hakkında bilgilendirmelerini istiyorum.",
      name: "email_campaign_promotion",
      type: "checkbox",
    },
    {
      label: "Görmediğim mesajların bilgilendirmelerini istiyorum.",
      name: "email_message_dont_see",
      type: "checkbox",
    },
  ];

  // initialValues default 0
  const initialValues = formFields.reduce((acc, field) => {
    acc[field.name] = 0;
    return acc;
  });

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, { setSubmitting }) => {
      await updateNotification(values);
      setSubmitting(false);
    },
  });
  const showError = () => {
    if (formik.errors && Object.keys(formik.errors).length > 0) {
      Object.keys(formik.errors).forEach((key) => {
        toast.error(formik.errors[key]?.toString());
      });
    }
  };

  useEffect(() => {
    if (companyUser) {
      formFields.forEach((field) => {
        if (companyUser[field.name] === 1) {
          formik.setFieldValue(field.name, 1);
        }
      });
    }
  }, [companyUser]);

  return (
    <CustomAccordion
      title="E-posta Bildirimleri"
      subtitle="İsbul.net’ten bilgilendirme e-postaları alma konusunda tercihini belirleyebilirsin."
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-1 gap-4 max-w-[75%] m-auto">
          {formFields.map((field, index) => (
            <>
              {field.name === "email_new_advert_application" && (
                <span className="border border-blue-700 rounded mb-3 py-2 px-5  text-blue-700">
                  İLAN BİLGİLENDİRMELERİ
                </span>
              )}
              {field.name === "email_seminar_event" && (
                <span className="border border-blue-700 rounded mb-3 py-2 px-5  text-blue-700">
                  KAMPANYA VE PROMOSYONLAR BİLGİLENDİRMELERİ
                </span>
              )}
              {field.name === "email_message_dont_see" && (
                <span className="border border-blue-700 rounded mb-3 py-2 px-5  text-blue-700">
                  MESAJ BİLGİLENDİRMELERİ
                </span>
              )}
              <FormField
                onChange={(e) => {
                  formik.setFieldValue(field.name, e ? 1 : 0);
                }}
                key={index}
                label={field.label}
                name={field.name}
                type={field.type}
                checked={formik.values[field.name]}
              />
            </>
          ))}
          <div className="flex justify-end">
            <CustomButton
              onClick={() => showError()}
              className="bg-blue-700 px-5 py-2 mt-4 text text-white rounded "
              type="submit"
              isSubmitting={formik.isSubmitting}
            >
              Kaydet
            </CustomButton>
          </div>
        </div>
      </form>
    </CustomAccordion>
  );
}

export default CompanyEmailNotification;
