import React, { useState, useEffect } from "react";
import ApplicationPopup from "./components/application-popup";
import { FaRegBuilding } from "react-icons/fa6";
import {
  IoBriefcaseOutline,
  IoCalendarOutline,
  IoLocationOutline,
} from "react-icons/io5";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { TbDisabled } from "react-icons/tb";
import Axios from "../../../services/axios";

interface AdvertDetails {
  id: number;
  advertId: number;
  created_at: string;
  advert: any;
  title: string;
  companyLogo: string;
  company_detail: any;
  company_name: string;
  working_types: any[];
  cities: any[];
  workingTypes: any[];
  advertTitle: string;
  companyName: string;
  workingType: string;
  createdAt: string;
  city: string;
  advertslug: string;
  is_disabled_employee: number;
  is_auth: boolean;
  is_view: boolean;
  is_favorite: boolean;
}

interface IApplicationCardProps {
  readonly data: AdvertDetails;
  readonly isSponsored: boolean;
}

const ApplicationCard: React.FC<IApplicationCardProps> = (
  props: IApplicationCardProps
) => {
  const [isPopup, setIsPopup] = useState<boolean>(false);
  const axios = new Axios();
  const { data, isSponsored } = props;
  const userToken = Cookies.get("userToken");
  const [isFavorited, setIsFavorited] = useState<boolean>(data.is_favorite);

  useEffect(() => {
    setIsFavorited(data.is_favorite);
  }, [data.is_favorite]);

  const backgroundColor = isSponsored
    ? "bg-[#FFF5DE]"
    : "bg-white lg:bg-[#efefef] ";
  let adUrl = "/is-ilanlari/" + data?.advertslug;

  const convertDateFormat = (createdAt: string): string => {
    const date: Date = new Date(createdAt);
    const today: Date = new Date();

    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return "Bugün";
    } else {
      const options: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      };
      return date.toLocaleDateString("tr-TR", options);
    }
  };

  const truncate = (text: string, length: number) => {
    return text?.length > length ? text?.slice(0, length) + "..." : text;
  };

  const handleisFavorite = async (id: number) => {
    if (userToken) {
      setIsFavorited(!isFavorited); // Update state immediately for UI feedback
      try {
        if (isFavorited) {
          await axios.request({
            type: "delete",
            endpoint: `adverts/${id}/remove-favorite`,
            payload: {},
          });
          Swal.fire({
            icon: "success",
            title: "Favorilerden Kaldırıldı",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          await axios.request({
            type: "post",
            endpoint: `adverts/${id}/add-favorite`,
            payload: {},
          });
          Swal.fire({
            icon: "success",
            title: "Favorilere Eklendi",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (err) {
        console.log("Error:", err);
        Swal.fire({
          icon: "error",
          title: "Bir hata oluştu",
          showConfirmButton: false,
          timer: 1500,
        });
        setIsFavorited((prev) => !prev); // Revert state in case of error
      }
    }
  };

  const handleClick = () => {
    window.location.href = adUrl;
  };

  return (
    <div
      key={data?.advert?.id}
      className={`${backgroundColor} relative w-full rounded-md lg:rounded-none !border !border-gray-300 lg:!border-transparent h-[96px] lg:h-32 px-2 md:px-4 md:py-2 gap-x-2 md:gap-x-5 flex hover-border-animation lg:shadow-md`}
    >
      <div className="flex-shrink-0 w-1/5 lg:w-1/6 flex justify-center items-center">
        <img
          onClick={handleClick}
          className="object-contain h-full max-w-[55px] md:max-w-[81px] max-h-[55px] md:max-h-[81px] cursor-pointer"
          src={data?.companyLogo || "https://via.placeholder.com/150"}
          alt="Company Logo"
        />
      </div>

      <div
        onMouseEnter={() => setIsPopup(true)}
        className="flex flex-col items-start justify-center w-5/6 group md:gap-2"
      >
        <div className="hidden xl:block">
          <ApplicationPopup advertId={data?.advertId || 2} isPopup={isPopup} />
        </div>

        {isSponsored && (
          <label className="text-[#b89e18] text-[8px] block lg:hidden">
            SPONSORLU
          </label>
        )}

        <div className="flex items-center justify-start w-full h-auto">
          <div className="flex items-center justify-between w-full gap-[6px]">
            <h3 className=" min-w-[90px] lg:min-w-[250px]">
              <a
                target="blank"
                href={adUrl}
                className="text-main text-xs lg:text-lg poppins-semibold md:poppins-medium  text-[#2253af] w-[100%] line-clamp-2 whitespace-nowrap"
              >
                <span className="sm:block hidden">
                  {truncate(data?.advertTitle, 40)}{" "}
                </span>
                <span className="block sm:hidden">
                  {truncate(data?.advertTitle, 25)}
                </span>
              </a>
            </h3>

            {data?.is_disabled_employee === 2 && (
              <TbDisabled className="text-mains text-base md:text-lg" />
            )}

            {data?.is_auth && (
              <div className="text-[#2253af] text-xs lg:hidden poppins-light text-center justify-center items-center">
                {data?.is_view ? "İncelendi" : ""}
              </div>
            )}

            {isSponsored && (
              <span className="text-sm text-[#b89e18] hidden lg:block">
                Sponsorlu
              </span>
            )}
          </div>
        </div>

        <div className="flex items-center justify-between w-full h-auto lg:items-center gap-x-1">
          <FaRegBuilding className="hidden text-xs text-gray-600 lg:flex lg:text-base" />
          <span className="md:poppins-regular poppins-medium  text-[10px] lg:text-xs text-gray-500 w-full line-clamp-1">
            {data.companyName
              ? truncate(data.companyName, 30)
              : "Firma Bilgisi Gizli"}
          </span>
          <div onClick={() => handleisFavorite(data.advertId)}>
            {userToken && (
              <>
                {isFavorited ? (
                  <FaHeart className="text-sm text-red-500" />
                ) : (
                  <FaRegHeart className="text-sm text-red-500" />
                )}
              </>
            )}
          </div>
        </div>

        <div className="flex flex-row items-end justify-between w-full h-auto">
          <div className="flex flex-col justify-between w-1/2 lg:flex-row ">
            <div className="flex items-center justify-start w-full h-full gap-1 lg:gap-x-1 max-lg:mt-1">
              <IoLocationOutline className="flex-shrink-0 text-xs text-gray-600 lg:text-base" />
              {data.city ? (
                <span className="poppins-light text-[8px] lg:text-[10px] text-gray-600 w-full truncate">
                  {data.city}
                  {data.cities.length > 1 && ` (+${data.cities.length - 1})`}
                </span>
              ) : (
                <span className="poppins-light text-[8px] lg:text-[10px] text-gray-600 w-full truncate">
                  Konum Gizlenmiştir
                </span>
              )}
            </div>
          </div>
          <div className="flex items-center justify-start gap-1 ml-auto lg:gap-x-1 ">
            <IoCalendarOutline className="text-xs text-gray-600 lg:text-base" />
            <span className="poppins-light text-[8px] lg:text-[10px] text-gray-600 w-full truncate">
              {convertDateFormat(data.createdAt)}
            </span>
          </div>
        </div>

        <div className="flex flex-row items-end justify-between w-full h-auto">
          {data?.is_auth && (
            <div className="text-[#2253af] text-xs hidden lg:flex poppins-light text-center justify-center items-center">
              {data?.is_view ? "İncelendi" : ""}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicationCard;
