import React from "react";
import { useContext } from "react";
import { CvLayoutContext, cvLayoutContextType } from ".";



interface GradientProgressBarProps {
  progress: number;
}

const GradientProgressBar: React.FC<GradientProgressBarProps> = ({
  progress,
}) => {

  const { score }: cvLayoutContextType = useContext(CvLayoutContext)
  const width = `${score}%`;
  const labelPosition = `${score}%`;

  return (
    <div className="relative w-full">
      <span
        className="absolute mt-2 font-poppins text-[0.6rem] z-[1] font-semibold -translate-x-1/2 w-7 h-4 bg-green-500 shadow-[0px_12px_30px_0px_rgba(16,24,40,0.1)] rounded-full px-3.5 py-1 text-white  flex justify-center items-center after:absolute after:bg-green-500 after:flex after:top-[-2px] after:left-1/2 after:-z-10 after:h-2 after:w-2 after:-translate-x-1/2 after:rotate-45"
        style={{ left: labelPosition }}
      >
        {score}%
      </span>
      <div className="relative flex w-full h-1.5 overflow-hidden rounded-3xl bg-gray-100">
        <div
          role="progressbar"
          aria-valuenow={score}
          aria-valuemin={0}
          aria-valuemax={100}
          style={{ width }}
          className="flex h-full items-center justify-center bg-gradient-to-r from-red-500 to-green-500 text-white rounded-3xl"
        ></div>
      </div>
    </div>
  );
};

export default GradientProgressBar;
