import React, { useState } from "react";
import {
  IoBriefcaseOutline,
  IoCalendarOutline,
  IoLocationOutline,
} from "react-icons/io5";
import { FaRegBuilding } from "react-icons/fa";
import ApplicationPopup from "./components/application-popup";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import Swal from "sweetalert2";
import Axios from "../../../services/axios";
import Cookies from "js-cookie";

interface AdvertDetails {
  id: number;
  created_at: string;
  advert: any;
  title: string;
  companyLogo: string;
  company_detail: any;
  company_name: string;
  working_types: any[];
  cities: any[];
  advertTitle: string;
  companyName: string;
  workingType: string;
  createdAt: string;
  city: string;
  advertSlug: string;
}

interface IData {
  readonly advertId: number;
  readonly advertSlug: string;
  readonly advertTitle: string;
  readonly companyName: string;
  readonly workingType: string;
  readonly city: string;
  readonly companyLogo: string;
  readonly createdAt: string;
  readonly is_favorite: boolean;
}

interface IApplicationCardProps {
  readonly data: IData;
  readonly isSponsored: boolean;
  readonly setAdverts: any;
  dontShowPopUp?: boolean; // Add this line
}

const ApplicationCard: React.FC<IApplicationCardProps> = ({
  data,
  isSponsored,
  setAdverts,
  dontShowPopUp,
}) => {
  const axios = new Axios();
  const [isPopup, setIsPopup] = useState<boolean>(false);
  const userToken = Cookies.get("userToken");

  const backgroundColor = isSponsored ? "bg-[#FFF5DE]" : "bg-[#EFEFEF]";
  let adUrl = "/is-ilanlari/" + data?.advertSlug;
  const [favoriteProgress, setFavoriteProgress] = useState<boolean>(false);

  const handleisFavorite = async (
    id: number,
    isCurrentlyFavorited: boolean
  ) => {
    if (favoriteProgress) return;
    setFavoriteProgress(true);
    if (userToken) {
      try {
        if (isCurrentlyFavorited) {
          await axios.request({
            type: "delete",
            endpoint: `adverts/${id}/remove-favorite`,
            payload: {},
          });
          setFavoriteProgress(false);
          Swal.fire({
            icon: "success",
            title: "Favorilerden Kaldırıldı",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          await axios.request({
            type: "post",
            endpoint: `adverts/${id}/add-favorite`,
            payload: {},
          });
          setFavoriteProgress(false);
          Swal.fire({
            icon: "success",
            title: "Favorilere Eklendi",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        setFavoriteProgress(false);
        setAdverts((prevState: any) => {
          return prevState.map((el: any) => {
            if (el.advertId === id) {
              return {
                ...el,
                is_favorite: !el.is_favorite,
              };
            }
            return el;
          });
        });
      } catch (err) {
        console.log("Error:", err);
        setFavoriteProgress(false);
        Swal.fire({
          icon: "error",
          title: "Bir hata oluştu",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      setFavoriteProgress(false);
    }
  };
  console.log(dontShowPopUp);
  const handleMouseEnter = () => {
    if (!dontShowPopUp) {
      setIsPopup(true);
    }
  };

  return (
    <div
      key={data?.advertId}
      className={`${backgroundColor} relative w-full rounded-md lg:rounded-none !border !border-gray-300 lg:!border-transparent h-[96px] lg:h-32 px-2 md:px-4 md:py-2 gap-x-2 md:gap-x-5 flex hover-border-animation lg:shadow-md`}
    >
      <div className="flex-shrink-0 w-1/5 lg:w-1/6 flex justify-center items-center">
        <img
          className="object-contain h-full max-w-[55px] md:max-w-[81px] max-h-[55px] md:max-h-[81px]"
          src={data?.companyLogo}
          alt="Company Logo"
        />
      </div>

      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setIsPopup(false)}
        className="flex flex-col items-start justify-center w-5/6 group md:gap-2"
      >
        <div className="hidden xl:block">
          <ApplicationPopup advertId={data?.advertId} isPopup={isPopup} />
        </div>

        {isSponsored && (
          <label className="text-[#b29709] font-poppins poppins-bold text-[8px] block lg:hidden">
            SPONSORLU
          </label>
        )}

        <div className="flex items-center justify-start w-full h-auto">
          <div className="flex items-center justify-between w-full">
            <h3>
              <a
                target="blank"
                href={adUrl}
                className="text-main text-xs lg:text-lg poppins-semibold md:poppins-medium  text-[#2253af] w-[100%] line-clamp-2"
              >
                <span>{data?.advertTitle}</span>
              </a>
            </h3>

            {isSponsored && (
              <span className="text-sm text-[#b29709] hidden lg:block">
                Sponsorlu
              </span>
            )}
          </div>
        </div>

        <div className="flex items-center justify-between w-full h-auto lg:items-center gap-x-1">
          <FaRegBuilding className="hidden text-xs text-gray-600 lg:flex lg:text-base" />
          <span className="md:poppins-regular poppins-medium text-[10px] lg:text-xs text-gray-500 w-full line-clamp-1">
            {data.companyName}
          </span>

          <div
            onClick={() => handleisFavorite(data.advertId, data.is_favorite)}
          >
            {userToken && (
              <>
                {data?.is_favorite ? (
                  <FaHeart className="text-sm text-red-500" />
                ) : (
                  <FaRegHeart className="text-sm text-red-500" />
                )}
              </>
            )}
          </div>
        </div>

        <div className="flex flex-row items-end justify-between w-full h-auto mt-1">
          <div className="flex flex-col justify-between w-1/2 lg:flex-row">
            <div className="flex items-center justify-start w-full h-full gap-1 lg:gap-x-1">
              <IoBriefcaseOutline className="text-xs text-gray-600 lg:text-base" />
              <span className="poppins-light text-[8px] lg:text-[10px] text-gray-600 w-full truncate">
                {data.workingType}
              </span>
            </div>
            <div className="flex items-center justify-start w-full h-full gap-1 lg:gap-x-1">
              <IoLocationOutline className="flex-shrink-0 text-xs text-gray-600 lg:text-base" />
              <span className="poppins-light text-[8px] lg:text-[10px] text-gray-600 w-full truncate">
                {data?.city}
              </span>
            </div>
          </div>
          <div className="flex items-center justify-start gap-1 ml-auto lg:gap-x-1">
            <IoCalendarOutline className="text-xs text-gray-600 lg:text-base" />
            <span className="poppins-light text-[8px] lg:text-[10px] text-gray-600 w-full truncate">
              {data.createdAt}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationCard;
