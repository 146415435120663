import { Children, Dispatch, HTMLAttributes, SetStateAction, createContext, useEffect, useRef, useState } from "react"
import classNames from "classnames"
import Label from "./Label"
import Trigger from "./Trigger"
import Value from "./Value"
import Input from "./Input"
import Items from "./Items"
import Item from "./Item"





type modeType = 'empty' | 'dirty'

// Component Type
type multiSelectSearchDropdownPropsType = HTMLAttributes<HTMLDivElement> & {
    isOpen?: boolean,
    setIsOpen?: Dispatch<SetStateAction<boolean>>,
    required?: boolean,
    error?: boolean,
    mode?: modeType
}

// Context Type
export type multiSelectSearchDropdownContextType = {
    required?: boolean,
    mode?: modeType,
    error?: boolean,
    isOpen: boolean,
    setIsOpen: Dispatch<SetStateAction<boolean>>
}

// Context Proviver
export const MultiSelectSearchDropdownContext = createContext<multiSelectSearchDropdownContextType>({
    required: false,
    mode: 'empty',
    error: false,
    isOpen: false,
    setIsOpen: () => {}
})




export function MultiSelectSearchDropdown({
    className,
    isOpen: initialIsOpen,
    setIsOpen: initialSetIsOpen,
    children,
    required,
    error,
    mode = 'empty',
    ...props
}: multiSelectSearchDropdownPropsType) {

    const divRef = useRef<HTMLDivElement>(null)

    const cn = classNames({
        'flex flex-col gap-1 relative': true
    })

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const value: multiSelectSearchDropdownContextType = {
        isOpen: initialIsOpen != undefined? initialIsOpen: isOpen,
        setIsOpen: initialSetIsOpen != undefined? initialSetIsOpen: setIsOpen,
        error,
        mode,
        required
    }

    const childrenArray = Children.toArray(children)

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if ( divRef.current && !divRef.current.contains(event.target as Node)) {
                if (initialSetIsOpen) {
                    initialSetIsOpen(false)
                } else {
                    setIsOpen(false)
                }
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => document.removeEventListener("mousedown", handleClickOutside)
    }, [])

    return(
        <MultiSelectSearchDropdownContext.Provider value={value}>
            <div ref={divRef} className={`${cn} ${className}`} {...props}>
                {childrenArray.length > 0? children: <label className="text-gray-500 p-2 text-xs">Sonuç bulunamadı</label>}
            </div>
        </MultiSelectSearchDropdownContext.Provider>
    )
}


MultiSelectSearchDropdown.Label = Label
MultiSelectSearchDropdown.Trigger = Trigger
MultiSelectSearchDropdown.Value = Value
MultiSelectSearchDropdown.Input = Input
MultiSelectSearchDropdown.Items = Items
MultiSelectSearchDropdown.Item = Item