import React from "react";
import Contents from "./contents";

const SavedFilters: React.FC = () => {
  return (
    <>
      <Contents />
    </>
  );
};

export default SavedFilters;
