import Axios from "../services/axios"
import { cdnWOrkingLocationType } from "../types"





type getWorkingLocationsPropsType = {
    search?: string,
    limit?: number
}


const axios = new Axios()


export async function getWorkingLocations({
    search,
    limit
}: getWorkingLocationsPropsType): Promise<cdnWOrkingLocationType[]> {
    try {
        const response = await axios.request({
            endpoint: `cdn/get-working-locations?
            ${search? `search=${search}&`: ``}
            ${limit? `limit=${limit}&`: ``}
            `,
            type: 'get'
        })

        if (response && response.data && response.data.working_locations) {
            return JSON.parse(JSON.stringify(response.data.working_locations))
        } else {
            return []
        }
    } catch (error) {
        console.log(`Working locations fetch failed. Error: ${error}`)
        return []
    }
}