import React from "react";
import Top from "./top";
import Body from "./body";
import Footer from "./footer";

const LeftDashboard: React.FC = () => {
	return (
		<div className='w-full '>
			<Top/>
			<div className='hidden lg:space-y-10 md:hidden lg:block xl:block 2xl:block'>
				<Body />
				<Footer />
			</div>
		</div>
	);
};

export default LeftDashboard;
