import { ChangeEvent, useState, RefObject } from "react";
import InputMask from "react-input-mask";
import { IconType } from "react-icons";

interface IInputProps {
  type?: "password" | "email" | "text" | "number";
  title: string;
  disabled?: boolean;
  design?: string;
  maxLength?: number;
  defaultValue?: string;
  onChange?: (value: string) => void;
  onIconClick?: () => void;
  icon?: IconType;
  iconLeft?: IconType | JSX.Element; // Updated iconLeft type
  inputRef?: RefObject<HTMLInputElement>;
  isError?: boolean;
}

const CustomInput = ({
  type,
  title,
  design,
  defaultValue,
  maxLength,
  onIconClick,
  disabled,
  onChange,
  inputRef,
  isError,
  icon: Icon,
  iconLeft: IconLeft, // New prop for the left icon
}: IInputProps) => {
  const [inputValue, setInputValue] = useState<string>(defaultValue || "");
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isEmptyOnBlur, setIsEmptyOnBlur] = useState<boolean>(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value;

    if (maxLength && newValue.length > maxLength) {
      newValue = newValue.slice(0, maxLength);
    }

    setInputValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setIsEmptyOnBlur(inputValue.trim() === "");
  };

  const isEmpty = inputValue.trim() === "" && !isFocused && isEmptyOnBlur;
  const inputClasses = `${design} input-form h-12 px-2 text-md rounded-md border outline-none border-blue-700 ${
    isError ? "bg-red-100" : ""
  }`;

  return (
    <div className="flex items-center w-full relative">
      <label className="relative flex items-center w-full">
        {IconLeft && (
          <div className="absolute left-[13px] top-[26px] transform -translate-y-1/2">
            {typeof IconLeft === "function" ? <IconLeft /> : IconLeft}
          </div>
        )}
        {title === "Telefon Numarası" ? (
          <InputMask
            id="phone"
            type="text"
            maskChar="_"
            mask="(599) 999 99 99"
            value={inputValue}
            onChange={handleChange}
            disabled={disabled}
            className={`${inputClasses} ${IconLeft ? "pl-[38px]" : ""}`} // Add padding if iconLeft exists
          />
        ) : (
          <input
            ref={inputRef}
            maxLength={maxLength}
            contentEditable
            disabled={disabled}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={inputValue}
            placeholder=""
            type={type}
            className={`${inputClasses} ${IconLeft ? "pl-[38px]" : ""}`} // Add padding if iconLeft exists
          />
        )}
        <div className="text-sm text-black text-opacity-80 absolute left-0 top-3 mx-2 px-2 transition duration-200 input-span flex justify-center items-center whitespace-nowrap">
          {title !== "confirm" && <> {title}</>}

          {title !== "confirm" && (
            <span className="text-red-500 ml-1 text-xl flex justify-center items-start">
              *
            </span>
          )}
        </div>
        {Icon && (
          <Icon
            onClick={onIconClick}
            className="mx-[-30px] text-blue-700"
            size={20}
          />
        )}
      </label>
    </div>
  );
};

export default CustomInput;
