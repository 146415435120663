import React from "react";
import SearchBar from "./search-bar";
import List from "./list";

const Main: React.FC = () => {
  return (
    <>
      <div className="flex flex-col w-full items-center bg-white rounded-md">
        <List />
      </div>
    </>
  );
};

export default Main;
