import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { addOrUpdateQueryParam } from "../../../../../../hooks/useAddOrUpdateQueryParam";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { JobsPageContext } from "../../../index";
import PageLink from "../../../../../../components/isbul-ui/pagination/PageLink";
import { Pagination } from "../../../../../../components/isbul-ui/pagination";
import { PaginationData } from "../../../../../company-page-container";

const PaginationWeb: React.FC<{
  pagination: PaginationData | null;
  onChange?: () => void;
}> = ({ pagination, onChange }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [totalPage, setTotalPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageNumbers, setPageNumbers] = useState<number[]>([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const page = Number(searchParams.get("page")) || 1;
    const lastPage = pagination?.last_page || 1;

    setCurrentPage(page);
    setTotalPage(lastPage);
  }, [pagination, location.search]);

  const changePageNumber = (pageNumber: number) => {
    const searchPath = addOrUpdateQueryParam(location, [
      { key: "page", value: pageNumber.toString() },
    ]);

    navigate(location.pathname + searchPath);
  };

  useEffect(() => {
    const tempPageNumbers: number[] = [];
    /* for (let i = 1; i <= totalPage; i++) {
      // Sadece ilk, son ve mevcut sayfanın etrafındaki sayfaları göster
      if (
        i === 1 ||
        i === totalPage ||
        (i >= currentPage - 2 && i <= currentPage + 2)
      ) {
        pageNumbers.push(i);
      }
    } */

    for (let i = 1; i <= totalPage; i++) {
      if (currentPage - i <= 1 && currentPage - i >= -1) {
        tempPageNumbers.push(i);
      }
    }

    setPageNumbers(tempPageNumbers);
  }, [currentPage, totalPage]);

  return (
    <div className="w-full h-full flex justify-center items-center">
      {/* Önceki sayfa butonu */}

      {pagination && (
        <Pagination
          currentPage={pagination?.current_page}
          firstPageNumber={1}
          lastPageNumber={pagination?.last_page}
        >
          <Pagination.ArrowLeftButton
            onClick={() => {
              changePageNumber(Math.max(pagination?.current_page - 1, 1));
              onChange && onChange();
            }}
          />
          <Pagination.Items>
            {Array.from({ length: pagination!.last_page }, (_, i) => i + 1).map(
              (p: any) => (
                <Pagination.PageLink
                  key={`p-${p}`}
                  pageNumber={p}
                  onClick={() => onChange && onChange()}
                  href={
                    location.pathname +
                    addOrUpdateQueryParam(location, [{ key: "page", value: p }])
                  }
                >
                  {p}
                </Pagination.PageLink>
              )
            )}
          </Pagination.Items>
          <Pagination.ArrowRightButton
            onClick={() => {
              changePageNumber(
                Math.min(pagination?.current_page + 1, pagination!.last_page)
              );
              onChange && onChange();
            }}
          />
        </Pagination>
      )}
    </div>
  );
};

export default PaginationWeb;
