import React from "react";
import { Modal, Button } from "antd";
import services from "../../../../../../services/login-services";
import { CustomInput } from "../../../../../form-components/input";
import { useNavigate } from "react-router-dom";
import { TiMessages } from "react-icons/ti";

const ForgotPasswordEmail = () => {
  const [email, setEmail] = React.useState("");
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const router = useNavigate();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const sendForgotPasswordEmail = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      document.getElementById("emailInput")?.classList.add("bg-red-100");
      return;
    }
    try {
      const response = await services.forgotPasswordEmail(email);
      console.log(response);
      if (response.status === 200) {
        showModal();
      } else {
        Modal.error({
          title: "Hata!",
          content:
            response.error || "Bir hata oluştu. Lütfen tekrar deneyiniz.",
          okText: "Tamam",
        });
      }
    } catch (error: any) {
      console.error(
        "Şifre sıfırlama e-postası gönderilirken bir hata oluştu",
        error
      );
      Modal.error({
        title: "Hata!",
        content: "Girilen bilgilere ait kullanıcı bulunamadı !",
        okText: "Tamam",
      });
    }
  };

  return (
    <div className="flex flex-col gap-6 p-6 mx-auto bg-white border rounded-md shadow-lg lg:my-6">
      <h4 className="poppins-medium text-xl md:text-2xl text-[#2253af]">
        Şifreni mi Unuttun ?
      </h4>
      <form
        className="flex flex-col gap-y-6"
        onSubmit={sendForgotPasswordEmail}
      >
        <div className="max-h-[40px] h-[40px]">
          <CustomInput
            id="emailInput"
            label="E-posta Adresiniz"
            required={true}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full h-full px-2 text-xs text-black border border-gray-300 rounded-md outline-none poppins-medium"
          />
        </div>
        <div className="flex flex-col mt-4">
          <p className="mb-2 font-bold font-poppins">
            Şifre yenileme linki ulaşmazsa
          </p>
          <ul className="space-y-2 text-xs text-gray-700 custom-list font-poppins">
            <li>Spam ve diğer e-posta klasörlerini kontrol et.</li>
            <li>Girdiğin e-posta ve cep telefonunun doğrulunu kontrol et.</li>
            <li>Şifre yenileme e-postası ulaşması 10 dakikayı bulabilir.</li>
          </ul>
        </div>
        <div className="w-full max-h-[50px] h-[50px]">
          <button
            type="submit"
            className="flex items-center justify-center w-full h-full rounded-2xl bg-[#1a5edd]"
          >
            <span className="text-base font-semibold text-white poppins-medium">
              GÖNDER
            </span>
          </button>
        </div>
      </form>
      <div className="flex flex-col items-center justify-center gap-2">
        <p className="text-center">isbul.net'te özgeçmişin yok mu?</p>
        <button
          onClick={() => router("/profilim/kayit-ol")}
          type="button"
          className="flex items-center border-[1px] border-transparent justify-center w-full h-full px-4 py-3 bg-white rounded-lg hover:bg-gray-200 hover:border-[1px] hover:border-title"
        >
          <span className="text-base font-semibold text-title poppins-medium">
            HEMEN ÜYE OL
          </span>
        </button>
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        okText="Tamam"
        footer={null}
      >
        <div className="flex flex-col justify-center items-center gap-2 py-8">
          <div className="flex justify-center items-center mb-4">
            <TiMessages className="text-mains text-6xl" />
          </div>

          <p className="font-poppins poppins-medium text-lg">
            Şifre sıfırlama bağlantısını gönderdik
          </p>
          <p className="font-poppins poppins-regular text-sm text-center">
            E-posta adresine gönderdiğimiz şifre sıfırlama bağlantısına
            tıklayarak yeni şifre oluşturabilirsin.
          </p>
          <p className="font-poppins poppins-medium text-sm">
            E-posta ulaşmadı mı?
          </p>
          <p className="font-poppins poppins-regular text-sm text-center">
            Bu e-posta ile isbul.net’e kaydolduysan spam kutunu kontrol et
            veya <span>tekrar gönder</span>
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default ForgotPasswordEmail;
