import React, { useEffect, useRef, useState } from "react";
import Breadcrump from "../../../../../components/common/profile/breadcrump";
import { CiSearch } from "react-icons/ci";
import { FaRegCheckCircle } from "react-icons/fa";
import { HiOutlineTrash } from "react-icons/hi";
import Axios from "../../../../../services/axios";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FiAlertCircle } from "react-icons/fi";
import Swal from "sweetalert2";
import { GoTrash } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { FaRegTrashAlt } from "react-icons/fa";

interface LastViewedAds {
  id: number;
  advert_history_id: number;
  company_detail: {
    company_name: string;
    logo: string;
    company_id: number;
  };
  advert: {
    is_active: string;
    id: number;
    slug: string;
    title: string;
  };
  is_applied: boolean;
}

const Body: React.FC = () => {
  const navigate = useNavigate();
  const axios = new Axios();
  const inputRef = useRef<HTMLInputElement>(null);
  const [lastViewedAds, setLastViewedAds] = useState<LastViewedAds[]>([]);
  const [filteredAds, setFilteredAds] = useState<LastViewedAds[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    const getLastViewedAds = async () => {
      try {
        const response = await axios.request({
          type: "get",
          endpoint: "user/profilim/last-viewed-adverts/get-all",
        });

        if (response.status === 200) {
          setLastViewedAds(response.data.data);
          setFilteredAds(response.data.data); // Initially display all ads
        } else {
          setLastViewedAds([]);
          setFilteredAds([]);
        }
      } catch (error) {
        console.error("Failed to fetch last viewed ads", error);
        setLastViewedAds([]);
        setFilteredAds([]);
      }
    };

    getLastViewedAds();
  }, []);

  useEffect(() => {
    filterAds();
  }, [searchTerm, lastViewedAds]);

  const handleDeleteLastView = async (id: number) => {
    try {
      const response = await axios.request({
        type: "delete",
        endpoint: `user/profilim/last-viewed-adverts/delete/${id}`,
      });

      if (response.status === 200) {
        setLastViewedAds((prev) =>
          prev.filter((item) => item.advert_history_id !== id)
        );
        filterAds();
      }
    } catch (error) {
      console.error("Failed to delete last viewed ad", error);
    }
  };

  const handleAllDelete = async () => {
    try {
      const result = await Swal.fire({
        title: "İşlemi onaylayın",
        text: "Son incelenen ilanlar listesini silmek istediğinize emin misiniz?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet",
        cancelButtonText: "İptal",
      });

      if (result.isConfirmed) {
        const response = await axios.request({
          type: "delete",
          endpoint: `user/profilim/last-viewed-adverts/delete-all`,
        });

        if (response.status === 200) {
          setLastViewedAds([]);
          setFilteredAds([]);
          Swal.fire(
            "Başarılı!",
            "Son incelenen ilanlar listesi başarıyla silindi.",
            "success"
          );
        }
      }
    } catch (error) {
      console.error("Failed to delete last viewed ads", error);
      Swal.fire(
        "Hata!",
        "Son incelenen ilanlar listesi silinirken bir hata oluştu. Lütfen tekrar deneyin.",
        "error"
      );
    }
  };

  const handleSearchChange = (title: string) => {
    setSearchTerm(title);
  };

  const normalizeString = (str: string) => {
    return str
      .replace(/ğ/g, "g")
      .replace(/Ğ/g, "G")
      .replace(/ü/g, "u")
      .replace(/Ü/g, "U")
      .replace(/ş/g, "s")
      .replace(/Ş/g, "S")
      .replace(/ı/g, "i")
      .replace(/İ/g, "I")
      .replace(/ö/g, "o")
      .replace(/Ö/g, "O")
      .replace(/ç/g, "c")
      .replace(/Ç/g, "C");
  };

  const filterAds = () => {
    const normalizedSearchTerm = normalizeString(searchTerm.toLowerCase());

    const newAds =
      lastViewedAds && lastViewedAds.length > 0
        ? lastViewedAds.filter((item) =>
            normalizeString(
              item.company_detail.company_name?.toLowerCase() || ""
            ).includes(normalizedSearchTerm)
          )
        : [];

    setFilteredAds(newAds);
  };

  const truncate = (str: string, n: number) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <div className="w-full flex flex-col gap-y-6">
      <Breadcrump type="page" pageName="SON İNCELENEN İLANLAR" />

      {lastViewedAds.length > 0 && (
        <div className="w-full md:bg-white flex flex-col md:p-5 rounded-md">
          <div className="flex md:flex-row flex-col items-end justify-end gap-4 mb-5">
            <div
              onClick={() => inputRef.current?.focus()}
              className="flex items-center border-[1px] border-[#b2b6b9] w-full md:w-10/12 py-[10px] px-5 gap-2 rounded-xl cursor-text"
            >
              <CiSearch size={20} />
              <input
                ref={inputRef}
                type="text"
                placeholder="Son İncelenen İlanlarda Ara"
                className="outline-none w-full text-title text-xs poppins-regular items-center bg-transparent"
                value={searchTerm}
                onChange={(e) => handleSearchChange(e.target.value)}
              />
            </div>
            <button
              onClick={() => handleAllDelete()}
              className="border-2 border-[#F00] rounded-lg w-5/12 md:w-3/12 xl:w-2/12 poppins-medium text-[#F00] text-sm py-[8px] transition-colors duration-300 hover:bg-[#F00] ease-in-out whitespace-nowrap flex justify-center items-center gap-2 group"
            >
              <FaRegTrashAlt
                size={14}
                className="text-[#F00] group-hover:text-white"
              />
              <span className="group-hover:text-white ml-2">Tümünü Sil</span>
            </button>
          </div>

          {filteredAds.length > 0 ? (
            <div className="gap-3">
              <div className="md:flex bg-mainBg mb-3 card-shadow p-3 text-[#212529] hidden">
                <p className="poppins-regular w-3/5">İlan Başlığı</p>
                <p className="poppins-regular w-2/5">İlan Durumu</p>
              </div>
              <div>
                {filteredAds.map((item, index) => (
                  <div key={index}>
                  {item?.company_detail?.company_name && (
                    <div className="md:flex hidden bg-mainBg mb-3 card-shadow text-[#212529] items-center p-5">
                      <div className="w-3/5">
                        <a
                          href={`/firma/${item.company_detail.company_id}`}
                          className="poppins-regular text-paragraph underline text-sm"
                        >
                          {truncate(item?.company_detail?.company_name, 20)}
                        </a>
                      </div>
                      <div className="flex w-3/5 justify-between">
                        <span className="md:flex hidden gap-2 items-center ml-4">
                          {parseInt(item?.advert?.is_active, 10) === 1 ? (
                            <FaRegCheckCircle
                              size={14}
                              className="fill-[#49ca77]"
                            />
                          ) : (
                            <IoCloseCircleOutline
                              size={16}
                              className="fill-[#F00] text-red-500"
                            />
                          )}
                          <p className="poppins-regular text-sm">
                            {parseInt(item?.advert?.is_active, 10) === 1
                              ? "Aktif"
                              : "Pasif"}
                          </p>
                        </span>
                        <div className="flex items-center gap-4">
                          {item.is_applied ? (
                            <a
                              href={`/is-ilanlari/${item.advert.slug}`}
                              className="text-[#1F4CA1] px-5 py-2 text-sm poppins-medium rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#1F4CA1] hover:text-white border-[1px] border-[#1F4CA1]"
                            >
                              Başvuruldu
                            </a>
                          ) : (
                            <a
                              href={`/is-ilanlari/${item.advert.slug}`}
                              className="text-[#1F4CA1] px-5 py-2 text-sm poppins-medium rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#1F4CA1] hover:text-white border-[1px] border-[#1F4CA1]"
                            >
                              Başvur
                            </a>
                          )}
                  
                          <button
                            onClick={() => {
                              Swal.fire({
                                icon: "warning",
                                title: "İşlemi onaylayın",
                                text: "Bu ilanı son incelenen ilanlardan çıkartmak istediğinize emin misiniz?",
                                confirmButtonColor: "#2253af",
                                confirmButtonText: "Evet",
                                cancelButtonColor: "#C62E2E",
                                cancelButtonText: "İptal",
                                showCancelButton: true,
                                showCloseButton: true,
                                preConfirm: async () => {
                                  await handleDeleteLastView(
                                    item?.advert_history_id
                                  );
                                },
                              });
                            }}
                          >
                            <HiOutlineTrash size={20} className="text-[#F00]" />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
              

                    <div className="flex flex-col md:hidden items-center justify-between w-full p-2 bg-white rounded-lg card-shadow mb-2">
                      <div className="flex items-center justify-start w-full gap-4 mb-4">
                        <img
                          src={item.company_detail.logo}
                          alt="company-logo"
                          className="w-12 h-12 rounded-full lg:rounded-none"
                        />
                        <div className="flex flex-col">
                          <a
                            href={`/is-ilanlari/${item.advert.slug}`}
                            className="text-sm font-bold truncate text-mains font-poppins poppins-semibold"
                          >
                            {truncate(item.advert.title, 20)}
                          </a>
                          <a
                            href={`/firma/${item.company_detail.company_id}`}
                            className="text-xs font-poppins poppins-medium md:text-gray-600 text-mains"
                          >
                            {truncate(item.company_detail.company_name, 20)}
                          </a>
                        </div>
                      </div>

                      <div
                        className="flex items-center justify-end w-full"
                        style={{ position: "relative" }}
                      >
                        <div className="flex lg:hidden items-center justify-end relative bottom-[10px] right-[10px] gap-6">
                          {item.is_applied ? (
                            <button
                              className="bg-[#2553AA] px-[20px] py-[7px] flex items-center rounded-[10.5px]"
                              onClick={() =>
                                navigate(`/is-ilanlari/${item.advert.slug}`)
                              }
                            >
                              <span className="text-white poppins-medium text-[10px] leading-[21px]">
                                Başvuruldu
                              </span>
                            </button>
                          ) : (
                            <button
                              className="bg-[#2553AA] px-[20px] py-[7px] flex items-center rounded-[10.5px]"
                              onClick={() =>
                                navigate(`/is-ilanlari/${item.advert.slug}`)
                              }
                            >
                              <span className="text-white poppins-medium text-[10px] leading-[21px]">
                                Başvur
                              </span>
                            </button>
                          )}

                          <span
                            className="text-[red] cursor-pointer font-poppins poppins-regular text-xs"
                            onClick={() => {
                              Swal.fire({
                                icon: "warning",
                                title: "İşlemi onaylayın",
                                text: "Bu ilanı son incelenen ilanlardan çıkartmak istediğinize emin misiniz?",
                                confirmButtonColor: "#2253af",
                                confirmButtonText: "Evet",
                                cancelButtonColor: "#C62E2E",
                                cancelButtonText: "İptal",
                                showCancelButton: true,
                                showCloseButton: true,
                                preConfirm: async () => {
                                  await handleDeleteLastView(
                                    item?.advert_history_id
                                  );
                                },
                              });
                            }}
                          >
                            Sil
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            lastViewedAds.length > 0 && <div className=" bg-white"></div>
          )}
        </div>
      )}

      {lastViewedAds.length === 0 && (
        <div className="flex items-center mt-4 mb-12 ml-4 mr-4 shadow-lg border rounded-xl bg-white">
          <span className="bg-[red] border border-blue mr-4 h-full text-white pt-3 pb-3 pl-1 pr-1 rounded-md">
            <FiAlertCircle size={35} />
          </span>

          <p className="font-poppins poppins-semibold text-sm flex flex-col md:flex-row justify-start md:justify-center items-start md:items-center gap-[6px] w-full">
            <span className="whitespace-nowrap">
              Henüz bir ilan incelemediniz!
            </span>
            <a href="/is-ilanlari" className="underline text-blue-400 w-full">
              İş İlanlarını İncele
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

export default Body;
