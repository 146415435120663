import { SelectComponent } from "../../../components/select-component-new";
import {
  SearchComponent,
  handleSearchChange,
} from "../../../components/search-component-new";
import { IData, ITitle, IItem } from "../../../../../types";
import useFetchCdnQuery from "../../../../../../../../hooks/useFetchCdnQuery";
import { useState, useEffect, useContext } from "react";
import {
  CandidatePoolContext,
  CandidatePoolFilterProps,
} from "../../../../../index";

interface EducationSectionProps {
  isVisible: boolean;
}

function EducationSection({ isVisible }: EducationSectionProps) {
  const { newSelectedValues, setNewSelectedValues } =
    useContext(CandidatePoolContext);

  const [branchesSearchTerm, setBranchesSearchTerm] = useState("");
  const [universitySearchTerm, setUniversitySearchTerm] = useState("");

  //EDUCATION LEVEL DATA
  const [educationLevelData, setEducationLevelData] = useState<any>([]);
  const educationQuery = useFetchCdnQuery(
    "educationLevels",
    "get-education-levels",
    "education_levels"
  );
  useEffect(() => {
    if (educationQuery.data !== undefined) {
      setEducationLevelData([...educationQuery?.data]);
    }
  }, [educationQuery.data, educationQuery.fetchStatus]);

  //UNI BRANCH DATA
  const [universityBranchData, setUniversityBranchData] = useState<any>([]);
  const univercityBrancheQuery = useFetchCdnQuery(
    "universityBranches",
    "get-university-branches",
    "university_branches",
    branchesSearchTerm
  );
  useEffect(() => {
    if (univercityBrancheQuery.data !== undefined) {
      setUniversityBranchData([...univercityBrancheQuery?.data]);
    }
  }, [univercityBrancheQuery.data, univercityBrancheQuery.fetchStatus]);

  //HIGH SCHOOL BRANCH DATA
  const [highSchoolBranchData, setHighSchoolBranchData] = useState<any>([]);
  const highSchoolBrancheQuery = useFetchCdnQuery(
    "high_school_branches",
    "get-high-school-branches",
    "high_school_branches",
    branchesSearchTerm
  );
  useEffect(() => {
    if (highSchoolBrancheQuery.data !== undefined) {
      setHighSchoolBranchData([...highSchoolBrancheQuery?.data]);
    }
  }, [highSchoolBrancheQuery.data, highSchoolBrancheQuery.fetchStatus]);

  //UNIVERCITIES DATA
  const [universityData, setUniversityData] = useState<any>([]);
  const univercityQuery = useFetchCdnQuery(
    "universities",
    "get-universities",
    "universities",
    universitySearchTerm
  );
  useEffect(() => {
    if (univercityQuery.data !== undefined) {
      setUniversityData([...univercityQuery?.data]);
    }
  }, [univercityQuery.data, univercityQuery.fetchStatus]);

  useEffect(() => {
    const educationLevel = newSelectedValues.education_level;
    let newValues: CandidatePoolFilterProps = {
      ...newSelectedValues,
    };
    if (educationLevel) {
      if (educationLevel === 2) {
        delete newValues.university_branch;
        delete newValues.university;
      }
      if (educationLevel > 2) {
        delete newValues.high_school_branch;
      }
    } else {
      delete newValues.university_branch;
      delete newValues.high_school_branch;
      delete newValues.university;
    }
    setNewSelectedValues(newValues);
  }, [newSelectedValues.education_level]);

  return (
    <section
      className={`flex flex-col md:grid md:grid-cols-3 gap-5  my-8 text-gray-600 mx-2 ${
        isVisible ? "" : "hidden md:hidden"
      }`}
    >
      <div className="flex  w-full">
        <SelectComponent
          data={educationLevelData}
          keyString={"title"}
          label="Eğitim Seviyesi"
          placeholder="Eğitim Seviyesi"
          onChange={(value) => {
            let newValues: CandidatePoolFilterProps = {
              ...newSelectedValues,
              education_level: value as number,
            };
            if (value === 0) {
              delete newValues.education_level;
            }
            setNewSelectedValues(newValues);
          }}
          selectedvalue={newSelectedValues.education_level}
        />
      </div>

      {newSelectedValues.education_level !== undefined && (
        <>
          {newSelectedValues.education_level !== 1 && (
            <SearchComponent
              isFetching={
                newSelectedValues.education_level === 2
                  ? highSchoolBrancheQuery.isFetching
                  : univercityBrancheQuery.isFetching
              }
              keyString={"title"}
              data={
                newSelectedValues.education_level === 2
                  ? highSchoolBranchData
                  : universityBranchData
              }
              label="Bölüm"
              onChange={(value) => {
                let newValues: CandidatePoolFilterProps = {};
                if (newSelectedValues.education_level === 2) {
                  newValues = {
                    ...newSelectedValues,
                    high_school_branch: value,
                  };
                  if (value === 0) {
                    delete newValues.high_school_branch;
                  }
                } else {
                  newValues = {
                    ...newSelectedValues,
                    university_branch: value,
                  };
                  if (value === 0) {
                    delete newValues.university_branch;
                  }
                }
                setNewSelectedValues(newValues);
              }}
              selectedvalue={
                newSelectedValues.education_level === 2
                  ? newSelectedValues.high_school_branch
                  : newSelectedValues.university_branch
              }
              setSearchData={setBranchesSearchTerm}
              searchData={branchesSearchTerm}
            />
          )}

          {newSelectedValues.education_level !== 2 &&
            newSelectedValues.education_level !== 1 && (
              <SearchComponent
                isFetching={univercityQuery.isFetching}
                keyString={"title"}
                data={universityData}
                label="Üniversite Adı"
                onChange={(value) => {
                  let newValues: CandidatePoolFilterProps = {
                    ...newSelectedValues,
                    university: value,
                  };
                  if (value === 0) {
                    delete newValues.university;
                  }
                  setNewSelectedValues(newValues);
                }}
                selectedvalue={newSelectedValues.university}
                setSearchData={setUniversitySearchTerm}
                searchData={universitySearchTerm}
              />
            )}
        </>
      )}
    </section>
  );
}

export { EducationSection };
