import { FaListOl } from "react-icons/fa6";
import { IoEyeOutline } from "react-icons/io5";
import { LuGraduationCap } from "react-icons/lu";
import { MdCardTravel } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { IApplicantsData } from "../../../../types";
import { Link } from "react-router-dom";
import { Hover } from "../hover";
import { useContext, useState } from "react";
import { CustomModal } from "../modal";
import { CiCalendar } from "react-icons/ci";
import { ApplyPoolContext } from "../../../../index";
import Swal from "sweetalert2";
import moment from "moment";
import { RiArrowDropDownLine } from "react-icons/ri";

interface CardTemplateProps {
  data: IApplicantsData;
  isChecked: boolean;
  onCheckboxToggle: () => void;
}

function CardTemplate({
  data,
  isChecked,
  onCheckboxToggle,
}: CardTemplateProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [appliesOpen, setAppliesOpen] = useState<boolean>(false);
  const { advertStatus } = useContext(ApplyPoolContext);

  const calculateAge = () => {
    const birthdayDate = new Date(data.user.birth_date);
    const ageDifMs = Date.now() - birthdayDate.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };
  const calculateGender = () => {
    if (data.user.gender.id === 1) {
      return "Erkek";
    } else if (data.user.gender.id === 2) {
      return "Kadın";
    }
  };
  function translateDate(jsonDate: string | undefined) {
    if (!jsonDate) {
      return "Bilinmiyor";
    }

    console.log(data);
    // Parse the JSON string into an object
    const dateObj = JSON.parse(jsonDate);

    // Define month names array
    const monthNames = [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ];

    // Extract month and year from the object
    const { month, year } = dateObj;

    // Translate the month number into Turkish month name
    const translatedMonth = monthNames[parseInt(month, 10) - 1]; // Subtract 1 because month indices start from 0

    // Return the translated date string
    return `${translatedMonth} ${year}`;
  }

  return (
    <div
      className=" md:border-2 flex-col flex items-start py-2 md:py-4 justify-between bg-white md:bg-stone-100 border px-1 md:px-5  md:hover:border-primary200  ml-1 mr-1 md:mx-10

     md:shadow-md

    "
    >
      <div
        className={"flex w-full items-center justify-start md:justify-between"}
      >
        <div className="flex gap-2 pl-2 ">
          <div className={"hidden md:flex"}>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={onCheckboxToggle}
            />
          </div>

          <Link to={`/isveren/cv/${data?.cv?.id}`}>
            <img
              src={data.user.profile ? data.user.profile : "/user-profile.png"}
              alt="Profile Picture"
              className="rounded-full border-primary200 border-2 mr-2 w-24 h-24 md:w-16 md:h-16"
            />
          </Link>
        </div>

        {/* Mobile Version */}
        <div className={"flex md:hidden flex-1 "}>
          <div className="flex md:hidden gap-[1.5px] items-start text-[.6rem] mr-auto ml-2 text-gray-800 flex-col   ">
            <div className="text-[.8rem] flex  gap-24">
              <div className="flex ">
                {" "}
                <p>
                  {data.user.name} {data.user.surname}{" "}
                  <span>
                    {" "}
                    {` (${
                      calculateAge() ? calculateAge() : "Yaş Bilinmiyor"
                    }, ${
                      calculateGender()
                        ? calculateGender()
                        : "Cinsiyet Bilinmiyor"
                    })`}
                  </span>
                </p>
              </div>
            </div>
            <div className="flex gap-1 items-center ">
              <LuGraduationCap className="w-4 h-4" />
              {data.education_level?.education_level
                ? data.education_level?.education_level.title
                : "Bilinmiyor"}
            </div>
            <div className="flex gap-1 items-center">
              <MdCardTravel className="w-4 h-4" />{" "}
              {data.cv_job_preference
                ? data.cv_job_preference.work_status === 1
                  ? "Çalışıyor"
                  : "Çalışmıyor"
                : "Çalışma Bilgisi Yok"}
            </div>
            <div className="flex gap-1 items-center">
              <IoLocationOutline className="w-4 h-4" />

              {data.city && data.district
                ? data?.district?.name + " / " + data.city.name
                : "Bilinmiyor"}
            </div>
            <div className="flex gap-1 items-center">
              <CiCalendar className="w-4 h-4" />

              {moment(data.advert_apply.updated_at).calendar({
                sameDay: "HH:mm",
                nextDay: "DD.MM.YYYY",
                nextWeek: "DD.MM.YYYY",
                lastDay: "DD.MM.YYYY",
                lastWeek: "DD.MM.YYYY",
                sameElse: "DD.MM.YYYY",
              })}
            </div>
            <p className="text-[.7rem]">
              Başvuru Durumu:{" "}
              <span className="text-mains">
                {advertStatus[data.advert_apply.status]
                  ? advertStatus[data.advert_apply.status]
                  : data.advert_apply.status}
              </span>
            </p>
          </div>
          <div className={"justify-self-end mr-3"}>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={onCheckboxToggle}
            />
          </div>
        </div>

        {/* Desktop Version */}

        <div className="hidden md:flex flex-col  flex-1">
          <div className={"md:flex items-center flex-1 justify-between "}>
            <div className="flex flex-1 gap-2 items-center ">
              <div>
                <Link to={`/isveren/cv/${data?.cv?.id}`}>
                  <h2 className="text-primary200 text-lg ">
                    {data.user.name} {data.user.surname}
                  </h2>
                </Link>
                <div className="text-gray-600">
                  <p>
                    {data.city && data.district
                      ? data?.district?.name + " / " + data?.city?.name
                      : "Lokasyon Bilinmiyor"}
                  </p>
                  <p>
                    {calculateGender()
                      ? calculateGender()
                      : "Cinsiyet Bilinmiyor"}{" "}
                    / {calculateAge() ? calculateAge() : "Yaş Bilinmiyor"}
                  </p>
                  <p>
                    {" "}
                    {data.cv_job_preference
                      ? data.cv_job_preference.work_status === 1
                        ? "Çalışıyor"
                        : "Çalışmıyor"
                      : "Çalışma Bilgisi Yok"}
                  </p>
                  <p className="text-primary200">
                    {advertStatus[data.advert_apply.status]
                      ? advertStatus[data.advert_apply.status]
                      : data.advert_apply.status}
                  </p>
                </div>
              </div>
            </div>
            <div className="text-gray-600 flex-1">
              {data.education_level ? (
                <div>
                  <p>
                    {data.education_level.education_level_id < 3
                      ? data.education_level.high_school_name
                      : data.education_level.university.title}
                  </p>
                  <p>({data.education_level.education_level.title})</p>
                </div>
              ) : (
                <p>Eğitim Bilgisi Bulunmuyor</p>
              )}
            </div>
            {data.last_job_experience ? (
              <div className="text-gray-600  text-sm flex-1">
                <h3 className="text-primary200 text-lg">
                  {data.last_job_experience?.position}
                </h3>
                <p>{data.last_job_experience?.company_name}</p>
                <p>
                  {translateDate(data.last_job_experience?.start_date)} /{" "}
                  {translateDate(data.last_job_experience?.end_date)}
                </p>
              </div>
            ) : (
              <p className="text-gray-600 text-sm flex-1">
                İş Deneyimi Belirtilmemiş
              </p>
            )}
            <div className="flex-col items-center gap-4 flex text-primary100">
              <Hover
                icon={<FaListOl className="w-5 h-5" />}
                label="Adayin İş Deneyimleri"
                onClick={() => setIsModalOpen(true)}
              />
              <CustomModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                data={data}
              />

              <Link
                to={`/isveren/cv/${data?.cv?.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Hover
                  icon={<IoEyeOutline className="w-5 h-5" />}
                  label="Adayı Görüntüle"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={"ml-5 flex flex-col gap-5 my-3"}>
        {data.advert_apply.text && (
          <div
            onClick={() => {
              Swal.fire({
                html: data.advert_apply.text,
                confirmButtonText: "Tamam",
                confirmButtonColor: "#194599",
              });
            }}
            className={
              "bg-mains px-3 py-1 text-xs font-semibold text-white rounded-full cursor-pointer"
            }
          >
            Ön Yazı
          </div>
        )}
        {data.all_advert_applies.length > 0 && (
          <div
            onClick={() => {
              setAppliesOpen((prev) => !prev);
            }}
            className={
              "bg-mains px-3 py-1 text-xs font-semibold text-white rounded-full cursor-pointer flex items-center"
            }
          >
            {data.all_advert_applies.length} Başvuru{" "}
            <RiArrowDropDownLine
              className={`transition-all text-lg ${
                appliesOpen ? "rotate-180" : ""
              }`}
            />
          </div>
        )}
      </div>

      <div
        className={`w-full grid transition-[grid-template-rows] duration-500 ease-out`}
        style={{
          gridTemplateRows: appliesOpen ? "1fr" : "0fr",
        }}
      >
        <div className={`overflow-hidden w-full flex flex-col gap-3`}>
          {data.all_advert_applies.map((applie, index) => (
            <div
              className={
                "grid grid-cols-2 sm:grid-cols-3 justify-evenly w-full  p-2 py-4 transition-all bg-white border flex-nowrap border-s-8 hover:border-title columns-auto"
              }
            >
              <div className={"flex flex-col"}>
                <div
                  className={"font-semibold text-sm sm:text-lg text-gray-600"}
                >
                  {applie.advert_title}
                </div>
                <div className={"text-mains text-xs sm:text-sm"}>
                  ({applie.is_new === 1 ? "Yeni" : "Eski"})
                </div>
              </div>
              <div className={"flex flex-col"}>
                <div
                  className={"font-semibold text-sm sm:text-lg text-gray-600"}
                >
                  {moment(applie.updated_at).format("DD MMMM YYYY")}
                </div>
                <div className={"text-mains text-xs sm:text-sm"}>
                  {applie.current_status}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* <input type="checkbox" checked={isChecked} onChange={onCheckboxToggle} /> */}
    </div>
  );
}

export { CardTemplate };
