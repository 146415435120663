import { useEffect, useState, useRef } from "react";
import InterviewListRow from "../components/InterviewListRow";
import Axios from "../../../../../services/axios";
import { IoIosSearch } from "react-icons/io";
import { LuPrinter } from "react-icons/lu";
import { FiAlertCircle } from "react-icons/fi";

interface InterviewUser {
  id: number;
  name: string;
  surname: string;
  email: string;
  profile: string;
  // Other properties can be added as needed
}

interface InterviewLocation {
  id: number;
  name: string;
  // Other properties can be added as needed
}

interface Interview {
  id: number;
  company_id: number;
  user_id: number;
  day: number;
  month: number;
  year: number;
  hour: string;
  interview_location_id: number;
  address: string;
  status: string;
  user_status: string;
  notes: string;
  message: string;
  is_passed: boolean;
  deleted_by_type: string | null;
  deleted_by: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  user: InterviewUser;
  interview_location: InterviewLocation;
}

export default function Body() {
  const axios = new Axios();
  const [interviews, setInterviews] = useState<Interview[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const printIframe = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const fetchInterviews = async () => {
      try {
        const response = await axios.authRequest({
          type: "get",
          endpoint: "companies/interview/get-interviews",
          payload: {},
        });

        setInterviews(response.data.interviews);
      } catch (error) {
        console.log(error);
      }
    };

    fetchInterviews();
  }, []);

  const filteredInterviews = interviews.filter(
    (interview) =>
      interview.user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      interview.user.surname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handlePrint = () => {
    if (!printIframe.current) return;

    const printDocument =
      printIframe.current.contentDocument ||
      printIframe.current.contentWindow?.document;
    if (printDocument) {
      // Extract styles from the main document
      const styleLinks = Array.from(
        document.querySelectorAll('link[rel="stylesheet"], style')
      )
        .map((styleSheet) => styleSheet.outerHTML)
        .join("");

      printDocument.open();
      printDocument.write(`
        <html>
          <head>
            <title>Mülakatlar</title>
            ${styleLinks}
            <style>
              table {
                width: 100%;
                border-collapse: collapse;
              }
              th, td {
                padding: 10px;
                border: 1px solid #ddd;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
              }
            </style>
          </head>
          <body>
            ${document.getElementById("printableTable")?.outerHTML}
          </body>
        </html>
      `);
      printDocument.close();
      printIframe.current.contentWindow?.focus();
      printIframe.current.contentWindow?.print();
    }
  };

  return (
    <>
      {interviews.length > 0 && (
        <div className="flex flex-row justify-end items-center gap-2 ml-auto mt-4">
          <div className="flex flex-row items-center gap-2 p-2 rounded-md border bg-white border-gray-400">
            <IoIosSearch className="text-xl text-gray-400" />
            <input
              className="bg-transparent outline-none text-[14px] font-poppins placeholder:text-gray-400"
              placeholder="Mülakatlarda ara"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <button
            type="button"
            className="bg-mains py-2 px-4 rounded-md flex flex-row font-poppins gap-2 items-center text-white text-[14px]"
            onClick={handlePrint}
          >
            <LuPrinter className="text-xl" />
            Yazdır
          </button>
        </div>
      )}

      {filteredInterviews.length > 0 ? (
        <div className="hidden md:block" id="printableTable">
          <table className="w-full border-collapse table-auto ma-1 mt-2 md:mt-4">
            <thead className="bg-gray-200">
              <tr>
                <th className=" p-1 md:p-3 text-start font-medium font-poppins text-mains">
                  Aday
                </th>
                <th className="p-1 md:p-3 text-start font-medium font-poppins text-mains">
                  Mülakat Tarihi
                </th>
                <th className="p-1 md:p-3 text-start font-medium font-poppins text-mains">
                  Durum
                </th>
                <th className="p-1 md:p-3 text-end font-medium font-poppins text-mains">
                  İşlem
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredInterviews.map((interview) => (
                <InterviewListRow
                  key={interview.id}
                  interview={interview}
                  setInterviews={setInterviews}
                />
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex items-stretch mt-8 mb-12 ml-4 mr-4 shadow-lg bg-white rounded-md">
          <span className="flex items-center justify-center bg-red-500  text-white p-3 rounded-l-md">
            <FiAlertCircle size={35} />
          </span>
          <p className="flex items-center font-poppins text-sm poppins-medium p-4">
            Adaylara gönderdiğiniz mülakat taleplerini bu alandan
            inceleyebilirsiniz, şu anda oluşturduğunuz mülakat talebiniz
            bulunmamaktadır.
          </p>
        </div>
      )}

      <iframe ref={printIframe} style={{ display: "none" }}></iframe>
    </>
  );
}
