import React, { useState, useEffect, useContext } from "react";
import Axios from "../../../../../../../services/axios";
import { useCookie } from "../../../../../../../hooks/useCookie";
import { CookieName } from "../../../../../../../constants/enums";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import { IoClose } from "react-icons/io5";
import { InputArea } from "../../../../../../../components/isbul-ui";
import {
  CandidatePoolContext,
  CandidatePoolFilterProps,
} from "../../../../index";

const axios = new Axios();

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IFilter {
  id?: number;
  title: string;
  params: { [key: string]: number };
}

function CustomModal({ isOpen, onClose }: CustomModalProps) {
  const [title, setTitle] = useState("");
  const [errorState, setErrorState] = useState<boolean>(false);

  const { newSelectedValues, queryParams, fetchSearchItems } =
    useContext(CandidatePoolContext);

  const { getCookie } = useCookie();
  const companyUserToken = getCookie(CookieName.CompanyUserToken);

  const handleClose = () => {
    setErrorState(false);
    setTitle("");
    onClose && onClose();
  };

  const handleSubmit = () => {
    const saveNewFilter = async () => {
      const exsistingFilter = cdnData.find((item) => item.title === title);

      // update the filter if it already exists
      if (exsistingFilter) {
        // If the filter already exists, update it

        try {
          await axios.updateFilter(
            exsistingFilter.id as number,
            title,
            queryParams,
            companyUserToken
          );
          toast.success("Filtre başarıyla güncellendi.");
          if (typeof fetchSearchItems == "function") {
            fetchSearchItems();
          }
          onClose();
        } catch (error) {
          toast.error("Filtre Kaydedilemedi.");
          throw new Error("Error updating filter: " + error);
        }
      } else {
        // If the filter does not exist, save it
        try {
          await axios.saveFilter(title, queryParams, companyUserToken);
          toast.success("Filtre başarıyla kaydedildi.");
          if (typeof fetchSearchItems == "function") {
            fetchSearchItems();
          }
          onClose();
        } catch (error) {
          toast.error("Filtre Kaydedilemedi.");
          throw new Error("Error saving filter: " + error);
        }
      }
    };

    if (title.trim() === "") {
      //error state
      setErrorState(true);
    } else {
      setErrorState(false);
      saveNewFilter();
      //onClose();
    }
  };

  //fetch search items
  const fetchSearchItemsBlock: QueryFunction = async ({ queryKey }) => {
    const companyUserToken = queryKey[1];

    try {
      const response = await axios.request({
        type: "get",
        endpoint: `companies/search/get-saved-filter`,
        payload: {
          headers: {
            Authorization: `Bearer ${companyUserToken}`,
          },
        },
      });
      if (response && response.data && response.data.company_searches) {
        const convertedFilters = response.data.company_searches.map(
          (item: { id: number; title: string; params: string }) => ({
            id: item.id,
            title: item.title,
            params: JSON.parse(item.params),
          })
        );

        return convertedFilters;
      }
    } catch (error) {
      throw new Error("Error fetching header card items: " + error);
    }
  };

  const dataQuery = useQuery({
    queryKey: ["datas", companyUserToken],
    queryFn: fetchSearchItemsBlock,
    staleTime: Infinity,
  });
  const cdnData = (dataQuery.data as IFilter[]) ?? [];

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 text-gray-600">
      <div className="fixed inset-0 bg-gray-900 opacity-75"></div>
      <div className="absolute bg-white rounded-lg px-6 py-4 w-11/12 md:w-1/2 lg:w-1/3 ">
        <div className="flex items-center justify-between  mb-5 border-b pb-3">
          <h1 className="text-xl text-primary200  scale-x-105   ">
            Filtre Kaydet
          </h1>
          <span
            className=" text-2xl  text-gray-500 cursor-pointer    "
            onClick={handleClose}
          >
            <IoClose />
          </span>
        </div>

        <div className="w-full  px-2 pb-2 ">
          <InputArea
            error={errorState}
            className="w-full"
            required
            mode={title.length == 0 ? "empty" : "dirty"}
          >
            <InputArea.Label>Başlık</InputArea.Label>
            <InputArea.Input
              value={title || ""}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
            {errorState && (
              <InputArea.Error>Başlık Alanı Girin</InputArea.Error>
            )}
          </InputArea>
        </div>

        <div className="flex items-center justify-end gap-5   border-t mt-3 pt-3">
          <button
            className="bg-gry200/30  text-black px-6 py-2 text-xl  rounded-md mt-2 "
            onClick={handleClose}
          >
            Kapat
          </button>
          <button
            type="button"
            className="bg-primary200 text-white px-6 py-2 text-xl  rounded-md mt-2 "
            onClick={handleSubmit}
          >
            Kaydet
          </button>
        </div>
      </div>
    </div>
  );
}

export { CustomModal };
