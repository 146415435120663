import React, { useEffect } from "react";
import { IoWarningOutline } from "react-icons/io5";
import CustomButton from "../custom-button";
import Swal from "sweetalert2";
import { useState } from "react";
import { Modal, Input, Button } from "antd";
import Axios from "../../../../../services/axios";
import { useNavigate } from "react-router-dom";
import { isAxiosError } from "axios";
import { CustomInput } from "../../../../../components/form-components/input";

const axios = new Axios();
const PermissionComp = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [hide_working_company, set_hide_working_company] = useState(0);
  const [is_job_search, set_is_job_search] = useState(0);
  const [not_show_references, set_not_show_references] = useState(0);
  const [show_candidate_pool, set_show_candidate_pool] = useState(0);
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const showModal = () => {
    setIsModalOpen(true);
    setPassword("");
  };

  const handleOk = () => {
    if (password.trim().length < 8) {
      Swal.fire({
        title: "Hata!",
        text: "Şifrenizi Girin.",
        icon: "warning",
        confirmButtonText: "Tamam",
      });
      return;
    }
    axios
      .userAuthRequest({
        endpoint: `user/profilim/delete-account?password=${password}`,
        type: "delete",
        payload: { password: password },
      })
      .then((response: any) => {
        if (response.data.status_code === 200) {
          Swal.fire({
            title: "Başarılı",
            text: "Hesabınız Başarıyla Silindi.",
            icon: "success",
            confirmButtonText: "Tamam",
          }).then((result) => {
            navigate("/");
          });
        } else {
          Swal.fire({
            title: "Hata!",
            text: "Hesap Silme işleminde bir hata oluştu. hata almaya devam ederseniz sistem yöneticinize başvurunuz.",
            icon: "error",
            confirmButtonText: "Tamam",
          });
        }
      })
      .catch((error: any) => {
        console.error(error);
        if (
          isAxiosError(error) &&
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          Swal.fire({
            title: "Hata!",
            text: error.response.data.errors,
            icon: "warning",
            confirmButtonText: "Tamam",
          });
        } else {
          Swal.fire({
            title: "Hata!",
            text: "Hesap Silme işleminde bir hata oluştu.",
            icon: "warning",
            confirmButtonText: "Tamam",
          });
        }
      });
    //setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setPassword("");
  };

  const handleSaved = () => {
    axios
      .userAuthRequest({
        endpoint: `user/profilim/settings/set-permission`,
        type: "post",
        payload: {
          hide_working_company: hide_working_company,
          is_job_search: is_job_search,
          not_show_references: not_show_references,
          show_candidate_pool: show_candidate_pool,
        },
      })
      .then((response: any) => {
        Swal.fire({
          title: "Başarılı",
          text: "İzinleriniz başarıyla kaydedildi.",
          icon: "success",
          confirmButtonText: "Tamam",
        });
      })
      .catch((error: any) => {
        Swal.fire({
          title: "Hata!",
          text: "İzinleriniz kaydedilemedi.",
          icon: "warning",
          confirmButtonText: "Tamam",
        });
      });
  };

  useEffect(() => {
    axios
      .userAuthRequest({
        endpoint: `user/profilim/settings/get-permission`,
        type: "get",
        payload: {},
      })
      .then((response: any) => {
        set_hide_working_company(response.data.data.hide_working_company);
        set_is_job_search(response.data.data.is_job_search);
        set_not_show_references(response.data.data.not_show_references);
        set_show_candidate_pool(response.data.data.show_candidate_pool);
      });
  }, []);

  return (
    <div className=" mx-[15px]">
      <div className="flex items-center justify-between gap-5 bg-blue-800 px-10 py-4 rounded-lg w-full mt-2  reverse-title">
        <div>
          <IoWarningOutline className="text-white" size={20} />
        </div>
        <h6 className="font-semibold text-white">
          Aşağıdaki seçenekler yalnızca özgeçmiş havuzu içindir. Başvuru
          yaptığınız firmalar özgeçmişinizin tamamını görüntüleyebilir.
        </h6>
      </div>
      <div className="border-2 border-gray-200 w-full flex flex-col gap-3  mt-5 rounded-lg mb-4">
        <div className="flex flex-col gap-4 justify-center item px-4 pt-4">
          <label className="flex gap-2 items-start">
            <input
              type="checkbox"
              className="mt-[5px] md:mt-[4px]"
              checked={show_candidate_pool === 1}
              onChange={(e) => {
                set_show_candidate_pool(e.target.checked ? 1 : 0);
              }}
            />
            <p className="text-gray-500 text-sm">
              Özgeçmiş havuzu aramalarında görüntülenmesine izin ver
            </p>
          </label>
          <label className="flex gap-2">
            <input
              type="checkbox"
              className=""
              checked={is_job_search === 1}
              onChange={(e) => {
                set_is_job_search(e.target.checked ? 1 : 0);
              }}
            />
            <p className="text-gray-500 text-sm">İş arama durumunu aktif et</p>
          </label>
          <label className="flex gap-2  items-start">
            <input
              type="checkbox"
              className="mt-[5px] md:mt-[4px]"
              checked={not_show_references === 1}
              onChange={(e) => {
                set_not_show_references(e.target.checked ? 1 : 0);
              }}
            />
            <p className="text-gray-500 text-sm">
              Referans bilgilerimi işveren firmalara gösterme
            </p>
          </label>
          <label className="flex gap-2">
            <input
              type="checkbox"
              checked={hide_working_company === 1}
              onChange={(e) => {
                set_hide_working_company(e.target.checked ? 1 : 0);
              }}
            />
            <p className="text-gray-500 text-sm">
              Çalışmakta olduğum firmayı gizle
            </p>
          </label>
        </div>
        <div className="flex justify-between mt-2 items-center pb-5 px-4 reverse-title">
          <p
            onClick={showModal}
            className="underline underline-offset-4 text-gray-400 font-extrabold cursor-pointer"
          >
            Üyelikten Ayrılmak İstiyorum
          </p>
          <div onClick={() => handleSaved()}>
            <CustomButton title="KAYDET" designs="py-2 px-3 mt-2 custom-btn" />
          </div>
        </div>
      </div>

      <Modal
        title="UYARI"
        open={isModalOpen}
        footer={[
          <div className="w-full flex justify-end border-t border-color-[#dee2e6] px-[24px] py-[12px] gap-4">
            <Button
              className="bg-gray-200 text-black font-poppins text-sm"
              key="Kapat"
              onClick={handleCancel}
            >
              Hayır
            </Button>
            <Button
              className="bg-[#2253af] text-white font-poppins text-sm"
              key="Kaydet"
              onClick={handleOk}
            >
              Evet
            </Button>
          </div>,
        ]}
        onOk={handleOk}
        onCancel={handleCancel}
        className="top-56"
      >
        <div>
          <p className="text-red-500">
            Üyelik silindiğinde, Oluşturulmuş özgeçmiş(ler) ve şirketlere
            iletilmiş olan tüm başvurular silinecektir. E-posta ve şifre
            geçerliliğini yitireceğinden tekrar aktif edilemez. Üyelikten
            ayrılma işlemine devam etmek istiyor musunuz?
          </p>

          {/* <Input
            type={"password"}
            className="mt-4"
            placeholder="Parolanız"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          /> */}
          <CustomInput
            type="password"
            placeholder="Parolanız"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={`w-full h-full px-2 text-xs text-black outline-none poppins-medium`}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PermissionComp;
