/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/jsx-no-comment-textnodes */
import { FaListOl } from "react-icons/fa6";
import { IoEyeOutline } from "react-icons/io5";
import { LuGraduationCap } from "react-icons/lu";
import { MdCardTravel } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { IApplicantsData } from "../../../../types";
import { Link } from "react-router-dom";
import { Hover } from "../hover";
import { useState } from "react";
import { CustomModal } from "../modal";

interface CardTemplateProps {
  data: IApplicantsData;
  isChecked: boolean;
  onCheckboxToggle: () => void;
}

function CardTemplate({
  data,
  isChecked,
  onCheckboxToggle,
}: CardTemplateProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const calculateAge = () => {
    const birthdayDate = new Date(data.user.birth_date);
    const ageDifMs = Date.now() - birthdayDate.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };
  const calculateGender = () => {
    if (data.user.gender === 1) {
      return "Erkek";
    } else if (data.user.gender === 2) {
      return "Kadın";
    }
  };
  function translateDate(jsonDate: string | undefined) {
    if (!jsonDate || jsonDate === "{}") {
      return "Bilinmiyor";
    }

    // Parse the JSON string into an object
    const dateObj = JSON.parse(jsonDate);

    // Define month names array
    const monthNames = [
      "Ocak",
      "Şubat",
      "Mart",
      "Nisan",
      "Mayıs",
      "Haziran",
      "Temmuz",
      "Ağustos",
      "Eylül",
      "Ekim",
      "Kasım",
      "Aralık",
    ];

    // Extract month and year from the object
    const { month, year } = dateObj;

    // Translate the month number into Turkish month name
    const translatedMonth = monthNames[parseInt(month, 10) - 1]; // Subtract 1 because month indices start from 0

    // Return the translated date string
    return `${translatedMonth} ${year}`;
  }

  return (
    <div
      className="md:border-2 font-poppins text-xs flex items-center py-2 md:py-4 justify-start md:justify-between bg-white
       md:bg-stone-200 border pr-5 md:pl-2 md:pr-10 md:hover:border-primary200  ml-3 mr-1 md:mx-10 md:shadow-md"
    >
      <div className="flex gap-2 pl-2">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={onCheckboxToggle}
        />
        <Link to={`/isveren/cv/${data.cv.cv_id}`}>
          <img
            src={data.user.profile ? data.user.profile : "/user-profile.png"}
            alt="Profile Picture"
            className="rounded-full border-primary200 border-2 mr-2 !w-24 h-24 md:!w-16 md:h-16 object-cover"
          />
        </Link>
      </div>

      {/* Mobile Version */}
      <div className="flex md:hidden gap-[1.5px] items-start text-[.6rem] mr-auto ml-2 text-gray-800 flex-col w-1/2">
        <p className="text-[.8rem] overflow-hidden text-ellipsis">
          <Link
            to={`/isveren/cv/${data.cv.cv_id}`}
            className={"cursor-pointer"}
          >
            {data.user.name.substring(0, 4)} {data.user.surname.substring(0, 4)}
          </Link>
          <span>
            {" "}
            {` ( ${calculateAge() ? calculateAge() : "Yaş Bilinmiyor"}, ${
              calculateGender() ? calculateGender() : " Cinsiyet Bilinmiyor"
            } )`}
          </span>
        </p>
        <div className="flex gap-1 items-center text-ellipsis">
          <LuGraduationCap className="w-4 h-4" />
          {data.education_level?.education_level
            ? data.education_level?.education_level.title
            : "Bilinmiyor"}
        </div>
        <div className="flex gap-1 items-center text-ellipsis">
          <MdCardTravel className="w-4 h-4" />{" "}
          {data.last_job_experience ? "Çalışıyor" : "Çalışmıyor"}
        </div>
        <div className="flex gap-1 items-center text-ellipsis">
          <IoLocationOutline className="w-4 h-4" />

          {data.city
            ? (data.district ? data?.district?.name + " / " : "") +
              data.city.name
            : "Bilinmiyor"}
        </div>
      </div>

      {/* Desktop Version */}

      <div className="hidden md:flex items-center flex-1 justify-between ">
        <div className="flex flex-1 gap-2 items-center ">
          <div>
            <Link
              to={`/isveren/cv/${data.cv.cv_id}`}
              className={"cursor-pointer"}
            >
              <h2 className="text-primary200 text-lg ">
                {data.user.name} {data.user.surname}
              </h2>
            </Link>

            <div className="text-gray-600">
              <p>
                {data.city
                  ? (data.district ? data?.district?.name + " / " : "") +
                    data?.city?.name
                  : "Lokasyon Bilinmiyor"}
              </p>
              <p>
                {calculateGender() ? calculateGender() : "Cinsiyet Bilinmiyor"}{" "}
                / {calculateAge() ? calculateAge() : "Yaş Bilinmiyor"}
              </p>
              <p>
                {" "}
                {data.last_job_experience?.now_working === 1
                  ? "Çalışıyor"
                  : "Çalışmıyor"}
              </p>
              <p className="text-primary200">
                {data.is_viewed ? "İncelendi" : "İncelenmedi"}
              </p>
            </div>
          </div>
        </div>

        <div className="text-gray-600 flex-1">
          {data.education_level ? (
            <div className={"w-5/6"}>
              <p
                className={" w-full overflow-hidden text-ellipsis text-nowrap"}
              >
                {data.education_level.high_school_name !== null
                  ? data.education_level.high_school_name
                  : "Eğitim bilgisi bulunmuyor"}
              </p>
              <p>({data.education_level.education_level.title})</p>
            </div>
          ) : (
            <p>Eğitim Bilgisi Bulunmuyor</p>
          )}
        </div>

        {data.last_job_experience ? (
          <div className="text-gray-600 flex-1">
            <h3 className="text-primary200 text-[15px] mb-1 ">
              {data.last_job_experience?.position}
            </h3>
            <p>{data.last_job_experience?.company_name}</p>
            <p>
              {data.last_job_experience?.start_date
                ? translateDate(data.last_job_experience?.start_date)
                : "Başlangıç Tarihi Bilinmiyor"}{" "}
              /{" "}
              {data.last_job_experience?.now_working === 1
                ? "Halen Çalışıyor"
                : data.last_job_experience?.end_date
                ? translateDate(data.last_job_experience?.end_date)
                : "Bitiş Tarihi Bilinmiyor"}
            </p>
          </div>
        ) : (
          <p className="text-gray-600 flex-1">İş Deneyimi Belirtilmemiş</p>
        )}

        <div className="flex-col items-center gap-4 flex text-primary100">
          <Hover
            icon={<FaListOl className="w-[18px] h-[18px]" />}
            label="Adayin İş Deneyimleri"
            onClick={() => setIsModalOpen(true)}
          />
          <CustomModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            data={data}
          />

          <Link
            to={`/isveren/cv/${data.cv.cv_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Hover
              icon={<IoEyeOutline className="w-5 h-6" />}
              label="Adayı Görüntüle"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export { CardTemplate };
