import * as React from "react";
import PositionsPageContainer from '../../containers/positions-page-container'
function PositionsPage(): React.ReactElement {
  return (
   <>
   <PositionsPageContainer/>
   </>
  );
}

export { PositionsPage };