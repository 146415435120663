import * as React from "react";
import ProvincesPageContainer from "../../containers/provinces-page-container/";

function ProvincesPage(): React.ReactElement {
  return (
    <>
      <ProvincesPageContainer />
    </>
  );
}

export { ProvincesPage };
