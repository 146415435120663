import * as React from "react";

interface ICustomSelectProps {
  readonly name?: string;
  readonly value?: string;
  readonly onClick?: (event: React.MouseEvent<HTMLSelectElement>) => void;
  readonly onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  readonly options?: { value: string; label: string }[];
  readonly placeholder?: string;
  readonly className?: string;
  readonly disabled?: boolean;
  readonly required?: boolean;
}

function CustomSelect(props: ICustomSelectProps): React.ReactElement {
  // const hasValue = props.value !== "" || props.value=="Seçiniz";

  return (
    <div
      className={`relative w-full  ${props.disabled && "pointer-events-none"}`}
    >
      <label
        className={`pointer-events-none text-xs poppins-medium
           items-center flex w-full mb-1
           gap-x-1
           ""}`}
        style={{ color: "#6B7280" }}
      >
        {props.placeholder?.split("*").map((part, index) => (
          <React.Fragment key={index}>
            {index > 0 && <span style={{ color: "red" }}>*</span>}
            {part}
          </React.Fragment>
        ))}
      </label>

      <select
        onClick={props.onClick}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        className={`
        appearance-none text-xs border  poppins-medium 
         px-2 pr-8 w-full bg-white text-gray-400 rounded-md outline-none ${
           props.className || ""
         } h-10 ${
          props.value == "" || props.value == "Seçiniz"
            ? "text-transparent"
            : ""
        }`}
      >
        <option value="">Seçiniz</option>
        {props.options?.map((option) => (
          <option
            key={option.value}
            value={option.value}
            className="text-black"
          >
            {option.label}
          </option>
        ))}
      </select>
      <div className="top-5 pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  );
}

export { CustomSelect };
