import * as React from "react";
import { CorporatePageContainer } from "../../containers/corporate-page-container";

function CorporatePage(): React.ReactElement {
  return (
    <div>
      <CorporatePageContainer />
    </div>
  );
}
export { CorporatePage };
