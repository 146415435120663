import * as React from "react";
import { useEffect } from "react"; 
import { HeaderCard } from "../creat-ads-page/header-card";
import { StepsPointsCard } from './steps-points-card/index'
import SimpleHeader from "./simple-header/index"
import Body from "./body"
function FirstShowAd(): React.ReactElement {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col mb-20 lg:mb-0">
      {/* <div className="flex flex-col container mx-auto">
        <HeaderCard />
      </div> */}
      <div className="flex flex-col container mx-auto">
        <StepsPointsCard/>
        <SimpleHeader/>
        <Body/>
      </div>
    </div>
  );
}
export { FirstShowAd };
