interface TitleProps {
  slug: string;
}
const Title: React.FC<TitleProps> = ({ slug }) => {
  const titleText = slug.toUpperCase().replace(/-/g, " ");
  return (
    <div className="bg-white shadow-lg p-5 mb-1">
      <h1
        className="text-lg md:text-xl text-title font-medium"
        style={{ fontWeight: "500" }}
      >
        {titleText}
      </h1>
    </div>
  );
};
export default Title;
