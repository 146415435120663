import { InputHTMLAttributes, useRef, useContext, useEffect } from "react";
import { SelectContext, selectContextType } from ".";
import { IoChevronDown } from "react-icons/io5";

type inputPropsType = InputHTMLAttributes<HTMLInputElement>;

export default function Input({ className, ...props }: inputPropsType) {
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    isOpen,
    setIsOpen,
    selectedValue,
    error,
    search,
    placeholder,
  }: selectContextType = useContext(SelectContext);

  if (!isOpen && selectedValue) {
    return null;
  }

  return (
    <div
      className={`${className} relative p-2 border ${
        error
          ? "border-red-600 bg-red-100"
          : selectedValue
          ? "border-mains"
          : "border-[#b2b6b9]"
      } min-h-[45px] rounded-md pr-10`}
      onClick={() => setIsOpen(true)}
    >
      {search && (
        <input
          ref={inputRef}
          {...props}
          className={`${className} bg-transparent text-[13px] font-poppins w-full outline-none`}
        />
      )}

      {!search && (
        <label
          className={`${className} text-[#85898c] text-[13px] font-poppins`}
        >
          {placeholder || "Seçiniz"}
        </label>
      )}
      <button
        className="absolute right-4 top-1/2 -translate-y-1/2"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <IoChevronDown
          className={`${isOpen ? "rotate-180" : ""} text-mains opacity-100`}
        />
      </button>
    </div>
  );
}
