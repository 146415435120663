import * as React from "react";
import services from "../../../../../../../services/login-services";
import { Link, useNavigate } from "react-router-dom";
import { useCookie } from "../../../../../../../hooks/useCookie";
import { useAppDispatch } from "../../../../../../../store/store";
import { setCompanyUser } from "../../../../../../../store/features/companyUserSlice";
import { ICompanyUser } from "../../../../../../../types/ICompanyUser";
import { CookieName } from "../../../../../../../constants/enums";
import { CustomInput } from "../../../../../../form-components/input";
import Swal from "sweetalert2";
import ForgotPasswordModal from "../../../ForgotPasswordModal";
import { LuLoader2 } from "react-icons/lu";

interface IRightSideProps {
  readonly showLeftSide: boolean;
}

function Login({ showLeftSide }: IRightSideProps): React.ReactElement {
  const [missingFields, setMissingFields] = React.useState<string[]>([]);
  const [emailOrPhone, setEmailOrPhone] = React.useState("");
  const [isRemember, setIsRemember] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [isLoginError, setIsLoginError] = React.useState<boolean>(false)
  const { setCookie } = useCookie();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] =
    React.useState(false);

  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const handleForgotPasswordModal = () => {
    setForgotPasswordModalOpen(true);
  };

  const login = async (userData: {
    email_or_phone: string;
    password: string;
  }) => {
    setIsLoading(true);
    setError("");
    setIsLoginError(false)

    try {
      const data = await services.companyLogin(userData);
      setCookie(CookieName.CompanyUserToken, data.data.token);
      setCookie(CookieName.CompanyUserData, JSON.stringify(data.data.company));
      dispatch(setCompanyUser(data.data.company as ICompanyUser));
      navigate("/isveren", { replace: true });
    } catch (error: any) {
      setError(error.response?.data?.error);
      Swal.fire({
        title: "Uyarı!",
        text: "Lütfen giriş bilgilerinizi kontrol edin",
        icon: "warning",
      });
      setIsLoginError(true)
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (isLoading) return;
    setIsSubmitted(true);
    const fields: string[] = [];
    if (!emailOrPhone) fields.push("E-posta veya Cep Telefonu");
    if (!password) fields.push("Şifre");

    if (fields.length > 0) {
      setMissingFields(fields);
      Swal.fire({
        title: "Uyarı!",
        html:
          "Eksik alanlar: <br/><strong style='color: red;'>" +
          fields.join(", ") +
          "</strong>",
        icon: "warning",
      });
      return;
    }
    login({ email_or_phone: emailOrPhone, password });
  };

  const handleBlur = (fieldName: string) => {
    if (fieldName === "E-posta veya Cep Telefonu") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailOrPhone) {
        if (!missingFields.includes(fieldName)) {
          setMissingFields((prev) => [...prev, fieldName]);
        }
      } else if (!emailPattern.test(emailOrPhone)) {
        setError("Geçersiz e-posta formatı.");
        if (!missingFields.includes(fieldName)) {
          setMissingFields((prev) => [...prev, fieldName]);
        }
      } else {
        setError(null);
        setMissingFields((prev) => prev.filter((field) => field !== fieldName));
      }
    } else if (fieldName === "Şifre" && !password) {
      if (!missingFields.includes(fieldName)) {
        setMissingFields((prev) => [...prev, fieldName]);
      }
    } else {
      setMissingFields((prev) => prev.filter((field) => field !== fieldName));
    }
  };

  const isFieldError = (fieldName: string) => {
    if (!isSubmitted) return false;
    if (fieldName === "E-posta veya Cep Telefonu") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailOrPhone) return true;
      if (!emailPattern.test(emailOrPhone)) return true;
    }
    if (fieldName === "Şifre" && !password) return true;
    return false;
  };

  const isFieldMissing = (fieldName: string) =>
    missingFields.includes(fieldName);

  return (
    <div
      className={`h-auto items-center justify-center w-full flex font-poppins`}
    >
      <div className="flex flex-col w-[100%] md:w-[470px] min-w-2xl md:h-screen lg:h-auto py-8 px-4 md:px-10 bg-transparent md:bg-white rounded-md shadow-md gap-y-4 lg:my-10">
        <div className="flex flex-col w-full gap-y-2">
          <h4 className="font-poppins font-[400] lg:font-[600] text-black text-xl md:text-2xl lg:text-[#2253af]">
            İşveren <span className="font-[800]">Giriş Yap</span>
          </h4>
          <p className="text-[15px] poppins-semibold text-[#63636c]">
            Üye değil misin?{" "}
            <span
              onClick={() => navigate("/isveren/kayit-ol")}
              className="lg:text-[#ff7f1d] text-black poppins-semibold text-[15px] underline lg:no-underline cursor-pointer underline-offset-4"
            >
              Üye Ol.
            </span>
          </p>
        </div>

        <div className="flex flex-col min-w-full gap-y-3">
          <div className="flex flex-col w-full h-auto gap-y-3">
            <form className="flex flex-col gap-y-4">
              <CustomInput
                required={true}
                label="E-posta veya Cep Telefonu"
                type="email"
                value={emailOrPhone}
                onChange={(e) => {
                  setEmailOrPhone(e.target.value)
                  setIsLoginError(false)
                }}
                onBlur={() => handleBlur("E-posta veya Cep Telefonu")}
                className={`w-full h-full px-2 text-xs text-black outline-none poppins-medium ${
                  isFieldError("E-posta veya Cep Telefonu")
                    ? "border-red-500"
                    : ""
                }`}
                error={isFieldError("E-posta veya Cep Telefonu") || isLoginError}
              />
              <CustomInput
                required={true}
                label="Şifre"
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)
                  setIsLoginError(false)
                }}
                onBlur={() => handleBlur("Şifre")}
                className={`w-full h-full px-2 text-xs text-black outline-none poppins-medium ${
                  isFieldError("Şifre") ? "border-red-500" : ""
                }`}
                error={isFieldError("Şifre") || isLoginError}
              />

              <div className="flex justify-between">
                <div
                  className="flex items-center gap-x-2"
                  onClick={() => setIsRemember(!isRemember)}
                >
                  <input
                    className="w-5 h-5 rounded-md "
                    type="checkbox"
                    checked={isRemember}
                  />
                  <span className="text-xs text-black poppins-medium">
                    Beni Hatırla
                  </span>
                </div>
                <div className="flex">
                  <Link
                  to={'/sifremi-unuttum/isveren'}
                    className="text-xs cursor-pointer hover:underline text-title poppins-medium"
                  >
                    Şifremi Unuttum
                  </Link>
                </div>
              </div>
              <div className="w-full max-h-[50px] h-[50px]">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="w-full h-full bg-[#195edd] flex rounded-sm items-center justify-center"
                >
                  <span className="text-lg text-white poppins-medium">
                    {isLoading ? (
                      <LuLoader2 className={"animate-spin"}></LuLoader2>
                    ) : (
                      "GİRİŞ YAP"
                    )}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* {error &&
          !isFieldError("E-posta veya Cep Telefonu") &&
          !isFieldError("Şifre") && (
            <div className="text-sm text-red-500">{error}</div>
          )} */}

        <div className="flex flex-col items-center w-full gap-y-3">
          <span className="text-sm text-black poppins-medium">
            isbul.net'te kurumsal hesabın yok mu?
          </span>
          <span
            onClick={() => navigate("/isveren/kayit-ol")}
            className="text-base text-[#2253af] poppins-medium text-center cursor-pointer"
          >
            HEMEN ÜYE OL
          </span>
        </div>
      </div>

      {forgotPasswordModalOpen && (
        <ForgotPasswordModal
          open={forgotPasswordModalOpen}
          onClose={() => setForgotPasswordModalOpen(false)}
        />
      )}
    </div>
  );
}

export default Login;
