import { ReactNode } from "react";
import classNames from "classnames";

type tabListPropsType = {
  children: ReactNode;
  className?: string;
};

export function TabList({ children, className }: tabListPropsType) {
  const cn = classNames({
    "flex border-b": true,
    [className as string]: !!className,
  });

  return <div className={cn}>{children}</div>;
}
