import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import WordCount from "@ckeditor/ckeditor5-word-count/src/wordcount";
import services from "../../../../../services/profile-services";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../seminar-course/custom-button/CustomButton";
import {
  getAwards,
  getHobbies,
} from "../../../../../store/cv-reducer/cvEditSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import { Button } from "../../../../../components/isbul-ui";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import Axios from "../../../../../services/axios";
import Swal from "sweetalert2";
import { useContext } from "react";
import { CvLayoutContext, cvLayoutContextType } from "..";


const axios = new Axios()

const Hobbies: React.FC = () => {

  const { showCvScore, isEnglish }: cvLayoutContextType = useContext(CvLayoutContext)


  const params = useParams();
  const cvId = params.slug

  const navigate = useNavigate()


  const dispatch = useAppDispatch();
  const hobbies = useAppSelector((state) => state.cvEdit.hobbies);

  useEffect(() => {
    dispatch(getHobbies());
  }, []);

  const formik = useFormik({
    initialValues: {
      hobbies: "",
    },
    onSubmit: async (values) => {
      if (values.hobbies.length > 5000) {
        Swal.fire({
          icon: 'error',
          title: 'Hata',
          text: 'İçerik en fazla 5000 karakterden oluşabilir'
        })

        return 
      }

      try {
        const response = await axios.userAuthRequest({
          endpoint: `user/profilim/cv/hob/update`,
          type: 'put',
          payload: {
            cv_id: cvId,
            description: values.hobbies
          }
        })
  
        if (response && response.status) {
          showCvScore()
          navigate(`/profilim/cv/goruntule/${cvId}`)
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Hata',
            text: 'Hobilerim ve ilgi alanlarım güncellenirken bir hata meydana geldi.'
          })
        }
      } catch (error) {
        console.log(`Error: ${error}`)
        Swal.fire({
          icon: 'warning',
          title: 'Hata',
          text: 'Hobilerim ve ilgi alanlarım güncellenirken beklenmedik bir hata meydana geldi.'
        })
      }
      
      // Handle form submission here
    },
  });

  const [description, setDescription] = useState<string>('')


  async function getDescription() {
    const response = await axios.userAuthRequest({
      endpoint: `user/profilim/cv/hob/index?cv_id=${cvId}`,
      type: 'get',
      payload: {}
    })

    if (response && response.data && response.data.data) {
      setDescription(response.data.data)
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  useEffect(() => {
    getDescription()
  }, [])

  return (
    <div className="flex flex-col w-full gap-y-2 mt-4 rounded-md bg-white shadow-md">
      {/* <div className="block lg:block border-b border-mains py-4  flex-col gap-x-2 items-start ">
        <span className="font-normal m-0 pl-4 text-base lg:text-[16px] lg:font-medium leading-7 font-poppins lg:poppins-semibold lg:text-sm text-mains lg:pl-4 lg:col text-center flex justify-center lg:justify-start">
          {isEnglish? 'My Hobbies and Interests': 'Hobilerim ve İlgi Alanlarım'}
        </span>
      </div> */}
      <div className="flex lg:flex border-b border-mains py-4  flex-col gap-x-2 items-start ">
        <span className="font-normal m-0 pl-4 text-base lg:text-[16px] lg:font-medium leading-7 font-poppins lg:poppins-semibold lg:text-sm text-mains lg:pl-4 lg:col text-center flex justify-center lg:justify-start">
          {isEnglish? 'My Hobbies and Interests': 'Hobilerim ve İlgi Alanlarım'}
        </span>
      </div>
      <form className="flex flex-col gap-2 p-6" onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-1">
          <CKEditor
            editor={ClassicEditor}
            data={description}
            onChange={(event, editor) => {
              const data = editor.getData();
              formik.setFieldValue("hobbies", data);
            }}
            onBlur={() => formik.setFieldTouched("hobbies")}
          />
          <label className={`text-sm ${5000 - formik.values.hobbies.length < 0? 'text-red-600': 'text-gray-600'} font-poppins`}>{5000 - formik.values.hobbies.length}</label>

        </div>
        <hr/>
        <div className="flex flex-row items-center gap-2 flex-wrap justify-center lg:justify-between">
          <Button
          type="button" 
          size="sm" 
          variant="secondary"
          onClick={() => navigate(`/profilim/cv/duzenle/${cvId}/basari-odul-ve-burslar`)}
          >
            <Button.Icon>
              <IoChevronBack/>
            </Button.Icon>
            {isEnglish? 'Go Back': 'Geri Dön'}
          </Button>
          <Button type="submit" size="sm" >
            {isEnglish? 'Save and Continue': 'Kaydet ve Devam Et'}
            <Button.Icon>
              <IoChevronForward/>
            </Button.Icon>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Hobbies;
