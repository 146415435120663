import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import Axios from '../../../services/axios'; // Assuming this path is correct
import { DataContextType } from './Iget-ad-data'; // Adjust the import path as needed
import { useLocation, useParams } from 'react-router-dom';

const DataContext = createContext<DataContextType | undefined>(undefined);
const axios = new Axios();

interface DataProviderProps {
    children: ReactNode;
}

export const GetAdDataProvider: React.FC<DataProviderProps> = ({ children }) => {
    // Adjusting initial state to match DataContextType

    
    const [data, setData] = useState<DataContextType>({ isLoading: true, data: undefined });

    const { advertId } = useParams()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.authRequest({
                    type: 'get',
                    endpoint: `companies/advert/get-show/${advertId}`,
                    payload: {}
                });
                // Ensure response.data.data aligns with the expected structure
                setData({ data: response.data.data, isLoading: false });
            } catch (error) {
                console.error('Error fetching data:', error);
                setData({ isLoading: false, error: 'Failed to fetch data', data: undefined });
            }
        };

        fetchData();
    }, []);

    return (
        <DataContext.Provider value={data}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error('useData must be used within a DataProvider');
    }
    return context;
};
