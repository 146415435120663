import React, {
  EventHandler,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { useFormik } from "formik";
import { CustomFormField } from "../../../../../components/common/custom-form-field";
import * as Yup from "yup";
import Axios from "../../../../../services/axios";
import {
  cdnCountryType,
  contactResponseDataType,
  getCitiesResponseType,
  getContactInfoResponseType,
  getCountriesResponseType,
  getDistrictsResponseType,
} from "../../../../../types";
import Select from "../../../../../components/form-components/select";
import InputField from "../../../../../components/form-components/input-field";
import MaskedInput from "react-text-mask";
import {
  FaDribbble,
  FaFacebookF,
  FaGithub,
  FaInstagram,
  FaX,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import {
  IoChevronBack,
  IoChevronForward,
  IoTrashBinOutline,
} from "react-icons/io5";
import { FaLinkedin } from "react-icons/fa";
import Swal from "sweetalert2";
import { TfiBackRight } from "react-icons/tfi";
import { RiArrowGoBackFill } from "react-icons/ri";
import { useNavigate, useNavigation } from "react-router-dom";
import {
  Button,
  InputArea,
  SelectDropdown,
  SelectSearchDropdown,
  Textarea,
} from "../../../../../components/isbul-ui";
import { useParams } from "react-router-dom";
import { getCountries as getCountriesCdn } from "../../../../../customHooks/getCountries";
import { getCities } from "../../../../../customHooks/getCities";
import { getDistricts } from "../../../../../customHooks/getDistricts";
import { useContext } from "react";
import { cvLayoutContextType, CvLayoutContext } from "..";
import ReactCountryFlag from "react-country-flag";
import { isAxiosError } from "axios";

const validationSchema = Yup.object({
  name: Yup.string().required("İsim Zorunludur"),
  day: Yup.string().required("Zorunlu*"),
  year: Yup.string().required("Zorunlu*"),
  month: Yup.string().required("Zorunlu*"),
});
interface DateDay {
  value: string;
  label: string;
}

interface DateOption {
  value: string;
  label: string;
}

type itemType = {
  id: number;
  title: string;
  code?: string;
  title_en: string;
};

type socialMediaItemsType = {
  dribbble: string | null;
  instagram: string | null;
  facebook: string | null;
  linkedin: string | null;
  youtube: string | null;
  twitter: string | null;
  github: string | null;
};

type payloadDataType = {
  cv_id: string;
  city_id: number | null;
  country_id: number;
  district_id: number | null;
  address: string | null;
  phone_code: string;
  phone: string;
  second_phone: string | null;
  second_phone_code: string | null;
  email: string;
  website: string | null;
  social_media: {
    dribbble: string | null;
    instagram: string | null;
    facebook: string | null;
    linkedin: string | null;
    youtube: string | null;
    twitter: string | null;
    github: string | null;
  };
};
const axios = new Axios();

const Contact: React.FC = () => {
  // Form state for validation
  /* const formState = {
    country: false,
    city: false,
    district: false,
    email: false,
    phone_code: false,
    phone: false,
    website: false,
  }; */

  type formStateType = {
    country: boolean;
    city: boolean;
    district: boolean;
    email: boolean;
    phone_code: boolean;
    phone: boolean;
    website: boolean;
    second_phone: boolean;
    second_phone_code: boolean;
  };
  const phoneInputRef = useRef<MaskedInput>(null);

  const [formState, setFormState] = useState<formStateType>({
    country: false,
    city: false,
    district: false,
    email: false,
    phone_code: false,
    phone: false,
    website: false,
    second_phone: false,
    second_phone_code: false,
  });

  const { showCvScore, isEnglish }: cvLayoutContextType =
    useContext(CvLayoutContext);

  const params = useParams();
  const cvId = params.slug;

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  /* States */
  const [contactInfo, setContactInfo] = useState<contactResponseDataType>({
    address: null,
    city: null,
    country: null,
    district: null,
    email: null,
    phone: null,
    phone_code: null,
    second_phone: null,
    second_phone_code: null,
    social_media: null,
    website: null,
  });
  const [countries, setCountries] = useState<itemType[]>([]);
  const [filteredCountries, setFilteredCountries] = useState<itemType[]>([]);
  const [cities, setCities] = useState<itemType[]>([]);
  const [filteredCities, setFilteredCities] = useState<itemType[]>([]);
  const [districts, setDistricts] = useState<itemType[]>([]);
  const [filteredDistricts, setFilteredDitricts] = useState<itemType[]>([]);
  const [phoneCodes, setPhoneCodes] = useState<itemType[]>([]);
  const [filteredPhoneCodes, setFilteredPhoneCodes] = useState<itemType[]>([]);
  const [filteredSecondPhoneCodes, setFilteredSecondPhoneCodes] = useState<
    itemType[]
  >([]);
  const [socialMedia, setSocialMedia] = useState<socialMediaItemsType>({
    dribbble: null,
    facebook: null,
    github: null,
    instagram: null,
    linkedin: null,
    twitter: null,
    youtube: null,
  });
  const [socialMediaList, setSocialMediaList] = useState<itemType[]>([
    { id: 1, title: "Dribbble", title_en: "Dribbble" },
    { id: 2, title: "Facebook", title_en: "Facebook" },
    { id: 3, title: "Github", title_en: "Github" },
    { id: 4, title: "Instagram", title_en: "Instagram" },
    { id: 5, title: "Linkedin", title_en: "Linkedin" },
    { id: 6, title: "Twitter", title_en: "Twitter" },
    { id: 7, title: "Youtube", title_en: "Youtube" },
  ]);

  const [loadingPage, setLoadingPage] = useState<boolean>(true);
  const [searchCountry, setSearchCountry] = useState<string>("");
  const [searchCity, setSearchCity] = useState<string>("");
  const [searchDistrict, setSearchDistrict] = useState<string>("");

  /* Form values */
  const [country, setCountry] = useState<itemType | null>(null);
  const [city, setCity] = useState<itemType | null>(null);
  const [district, setDistrict] = useState<itemType | null>(null);
  const [address, setAddress] = useState<string | null>("");
  const [email, setEmail] = useState<string>("");
  const [phone_code, setPhone_code] = useState<itemType | null>(null);
  const [phone, setPhone] = useState<string>("");
  const [maskedPhone, setMaskedPhone] = useState<string>("");
  const [second_phone_code, setSecond_phone_code] = useState<itemType | null>(
    null
  );
  const [second_phone, setSecond_phone] = useState<string>("");
  const [website, setWebsite] = useState<string>("");
  const [social_media, setSocial_media] = useState<string[]>([]);

  // Initial scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  // Search
  // country search
  useEffect(() => {
    async function search() {
      if (searchCountry.trim().length > 0) {
        const res = await getCountriesCdn({ search: searchCountry });
        setFilteredCountries(
          res.map((r) => {
            return {
              id: r.id,
              title: r.name_tr,
              title_en: r.name_en,
            };
          })
        );
      } else {
        setFilteredCountries(countries);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchCountry]);

  // City search
  useEffect(() => {
    async function search() {
      if (searchCity.trim().length > 0) {
        const res = await getCities({
          search: searchCity,
          country_id: country?.id,
          except_ids: [83, 7795],
        });
        setFilteredCities(
          res.map((r) => {
            return {
              id: r.id,
              title: r.name,
              title_en: r.name_en ?? r.name,
            };
          })
        );
      } else {
        setFilteredCities(cities);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchCity]);

  // District search
  useEffect(() => {
    async function search() {
      if (searchDistrict.trim().length > 0) {
        const res = await getDistricts({
          search: searchDistrict,
          city_id: city?.id,
        });
        setFilteredDitricts(
          res.map((r) => {
            return {
              id: r.id,
              title: r.name,
              title_en: r.name,
            };
          })
        );
      } else {
        setFilteredDitricts(districts);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchDistrict]);

  function phoneFormatter(phone?: string): string {
    if (!phone) {
      return "";
    }

    let phoneEdit = phone.replaceAll(" ", "");
    phoneEdit = phoneEdit.replaceAll("(", "");
    phoneEdit = phoneEdit.replaceAll(")", "");
    phoneEdit = phoneEdit.replaceAll("-", "");
    phoneEdit = phoneEdit.replaceAll("_", "");

    return phoneEdit;
  }

  /* State change */
  //Contact info change
  useEffect(() => {
    getInitialContactInfoValues(contactInfo);
  }, [contactInfo]);

  async function getInitialContactInfoValues(
    initialContactInfo: contactResponseDataType
  ) {
    setLoadingPage(true);
    // Location datas
    if (initialContactInfo) {
      const { countryList, phoneCodeList } = await getCountries();
      // Country datas
      if (initialContactInfo.country) {
        // Country data

        const selectedCountry = initialContactInfo?.country_list;
        setCountry(
          selectedCountry
            ? {
                id: initialContactInfo.country_list?.id || -1,
                title: initialContactInfo.country_list?.name_tr || "",
                title_en: initialContactInfo.country_list?.name_en || "",
              }
            : { id: 1, title: `Türkiye`, title_en: "Turkey" }
        );

        // City datas
        if (selectedCountry && initialContactInfo.city) {
          const selectedCity = initialContactInfo.city_list;
          setCity(
            selectedCity
              ? {
                  id: initialContactInfo.city_list?.id || -1,
                  title: initialContactInfo.city_list?.name || "",
                  title_en: initialContactInfo.city_list?.name || "",
                }
              : null
          );

          // District datas
          if (selectedCity && initialContactInfo.district) {
            const selectedDistrict = initialContactInfo.district_list;
            setDistrict(
              selectedDistrict
                ? {
                    id: initialContactInfo.district_list?.id || -1,
                    title: initialContactInfo.district_list?.name || "",
                    title_en: initialContactInfo.district_list?.name || "",
                  }
                : null
            );
          }
        }
      } else {
        setCountry({ id: 1, title: "Türkiye", title_en: "Turkey" });
      }

      // Adres data
      setAddress(initialContactInfo.address);

      // Email data
      setEmail(initialContactInfo.email || "");

      // Phone datas
      // Phone 1
      // Formatting for initial values
      if (initialContactInfo.phone_code && initialContactInfo.phone) {
        const selectedPhoneCode = phoneCodeList.find((p) =>
          p.title.endsWith(
            initialContactInfo.phone_code?.toString() || "undefined"
          )
        );

        setPhone_code(selectedPhoneCode ? selectedPhoneCode : null);

        // Remove the leading zero if it exists
        const formattedPhone = initialContactInfo.phone.replace(/^0\s?/, "");
        if (formattedPhone.length == 10) {
          const fm = formattedPhone;
          let maskedPhone = `(${fm[0]}${fm[1]}${fm[2]}) ${fm[3]}${fm[4]}${fm[5]} ${fm[6]}${fm[7]} ${fm[8]}${fm[9]}`;
          setPhone(maskedPhone);
        } else {
          setPhone(formattedPhone);
        }
      }

      // Phone 2
      // Formattion phone for initial values
      if (
        initialContactInfo.second_phone_code &&
        initialContactInfo.second_phone
      ) {
        const selectedPhoneCode = phoneCodeList.find((p) =>
          p.title.endsWith(
            initialContactInfo.second_phone_code?.toString() || ""
          )
        );

        // TODO: bnuradn devam
        setSecond_phone_code(
          selectedPhoneCode
            ? selectedPhoneCode
            : {
                id: 1,
                title: "Türkiye +90",
                code: "TR",
                title_en: "Turkey +90",
              }
        );

        const formattedSecondPhone = initialContactInfo.second_phone.replace(
          /^0\s?/,
          ""
        );
        if (formattedSecondPhone.length == 10) {
          const fm = formattedSecondPhone;
          let maskedSecondPhone = `(${fm[0]}${fm[1]}${fm[2]}) ${fm[3]}${fm[4]}${fm[5]} ${fm[6]}${fm[7]} ${fm[8]}${fm[9]}`;
          setSecond_phone(maskedSecondPhone);
        } else {
          setSecond_phone(formattedSecondPhone);
        }
      } else {
        setSecond_phone_code({
          id: 1,
          title: "Türkiye +90",
          code: "TR",
          title_en: "Turkey +90",
        });
      }

      // Website
      setWebsite(initialContactInfo.website || "");

      // Social media
      if (
        initialContactInfo.social_media != null &&
        initialContactInfo.social_media.length > 0
      ) {
        const socialMedias = initialContactInfo.social_media;
        const dribbbleUrl = socialMedias.find((s) => s.platform == "Dribbble");
        const instagramUrl = socialMedias.find(
          (s) => s.platform == "Instagram"
        );
        const facebookUrl = socialMedias.find((s) => s.platform == "Facebook");
        const linkedinUrl = socialMedias.find((s) => s.platform == "Linkedin");
        const youtubeUrl = socialMedias.find((s) => s.platform == "Youtube");
        const twitterUrl = socialMedias.find((s) => s.platform == "Twitter");
        const githubUrl = socialMedias.find((s) => s.platform == "Github");

        setSocialMedia({
          dribbble: dribbbleUrl ? dribbbleUrl.url : null,
          facebook: facebookUrl ? facebookUrl.url : null,
          github: githubUrl ? githubUrl.url : null,
          instagram: instagramUrl ? instagramUrl.url : null,
          linkedin: linkedinUrl ? linkedinUrl.url : null,
          twitter: twitterUrl ? twitterUrl.url : null,
          youtube: youtubeUrl ? youtubeUrl.url : null,
        });
      }
    }

    setLoadingPage(false);
  }

  /* Selected values change */
  // Country value change
  useEffect(() => {
    if (country?.id != contactInfo.country_list?.id) {
      setCities([]);
      setCity(null);
      setDistricts([]);
      setDistrict(null);
    }
    if (country) {
      getCities({ country_id: country.id, except_ids: [7795, 83] }).then(
        (data) => {
          setCities(
            data.map((d) => {
              return {
                id: d.id,
                title: d.name,
                title_en: d.name,
              };
            })
          );
          setFilteredCities(
            data.map((d) => {
              return {
                id: d.id,
                title: d.name,
                title_en: d.name_en ?? d.name,
              };
            })
          );
        }
      );
    }
  }, [country]);

  // City value change
  useEffect(() => {
    if (city?.id != contactInfo.city_list?.id) {
      setDistricts([]);
      setDistrict(null);
    }
    if (city) {
      getDistricts({ city_id: city.id }).then((data) => {
        setFilteredDitricts(
          data.map((d) => {
            return {
              id: d.id,
              title: d.name,
              title_en: d.name,
            };
          })
        );
        setDistricts(
          data.map((d) => {
            return {
              id: d.id,
              title: d.name,
              title_en: d.name,
            };
          })
        );
      });
    }
  }, [city]);

  // All phone codes initial load
  useEffect(() => {
    setFilteredPhoneCodes(phoneCodes);
    setFilteredSecondPhoneCodes(phoneCodes);
  }, [phoneCodes]);
  type countryResponseType = {
    countryList: itemType[];
    phoneCodeList: itemType[];
  };

  async function getCountries(): Promise<countryResponseType> {
    try {
      const res = await axios.request({
        endpoint: "cdn/get-countries?only_ids=[1]",
        type: "get",
      });

      const response: getCountriesResponseType = JSON.parse(
        JSON.stringify(res)
      );

      const countryList: itemType[] = response.data.countries.map((c) => {
        return {
          id: c.id,
          title: c.name_tr,
          title_en: c.name_en,
        };
      });

      const phoneCodeList: itemType[] = response.data.countries.map((c) => {
        return {
          id: c.id,
          title: `${c.name_tr} +${c.phone_code.toString()}`,
          title_en: `${c.name_en} +${c.phone_code.toString()}`,
          code: c.iso,
        };
      });

      setCountries(countryList);
      setFilteredCountries(countryList);
      setPhoneCodes(phoneCodeList);

      return { countryList, phoneCodeList };
    } catch (error) {
      console.log(`Countries fetch failed. Error: ${error}`);
      setCountries([]);

      return { countryList: [], phoneCodeList: [] };
    }
  }

  // Get contact information function
  async function getContactInfo() {
    setLoadingPage(true);

    const endpoint = `user/profilim/cv/contact/index?cv_id=${cvId}`;
    const res = await axios.request({
      endpoint,
      type: "get",
      payload: {},
    });

    const response: getContactInfoResponseType = JSON.parse(
      JSON.stringify(res)
    );

    setContactInfo(response.data.data);

    setLoadingPage(false);
  }

  useEffect(() => {
    getContactInfo();
  }, []);

  // Form submit
  async function formSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    // Phone formatter
    let phoneEdit = phone.replaceAll(" ", "");
    phoneEdit = phoneEdit.replaceAll(" ", "");
    phoneEdit = phoneEdit.replaceAll("(", "");
    phoneEdit = phoneEdit.replaceAll(")", "");
    phoneEdit = phoneEdit.replaceAll("-", "");
    phoneEdit = phoneEdit.replaceAll("_", "");

    let secondPhoneEdit = second_phone.replaceAll(" ", "");
    secondPhoneEdit = secondPhoneEdit.replaceAll("(", "");
    secondPhoneEdit = secondPhoneEdit.replaceAll(")", "");
    secondPhoneEdit = secondPhoneEdit.replaceAll("-", "");
    secondPhoneEdit = secondPhoneEdit.replaceAll("_", "");

    // Form field valid states
    const fakeFormState = {
      country: false,
      city: false,
      district: false,
      email: false,
      phone_code: false,
      phone: false,
      website: false,
      second_phone: false,
      second_phone_code: false,
    };

    //Form validation
    // Country
    if (country == null) {
      setFormState(({ country, ...others }) => ({
        country: true,
        ...others,
      }));
      fakeFormState.country = true;
    }

    // City
    if (cities.length > 0 && city == null) {
      setFormState(({ city, ...others }) => ({
        city: true,
        ...others,
      }));

      fakeFormState.city = true;
    }

    // District
    if (districts.length > 0 && district == null) {
      setFormState(({ district, ...others }) => ({
        district: true,
        ...others,
      }));

      fakeFormState.district = true;
    }

    // Email
    if (email.trim().length == 0) {
      setFormState(({ email, ...others }) => ({
        email: true,
        ...others,
      }));
      fakeFormState.email = true;
    }

    // Email validation
    if (!email.includes("@") || !email.includes(".")) {
      setFormState(({ email, ...others }) => ({
        email: true,
        ...others,
      }));

      fakeFormState.email = true;
    }

    const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const emailTest = emailRegex.test(email);

    if (!emailTest) {
      setFormState(({ email, ...others }) => ({
        email: true,
        ...others,
      }));

      fakeFormState.email = true;
    }

    // Phone code
    if (phone_code == null) {
      setFormState(({ phone_code, ...others }) => ({
        phone_code: true,
        ...others,
      }));

      fakeFormState.phone_code = true;
    }

    // Phone
    if (phoneEdit.length < 10) {
      setFormState(({ phone, ...others }) => ({
        phone: true,
        ...others,
      }));

      fakeFormState.phone = true;
    }

    // Second phone
    if (secondPhoneEdit.length > 0 && secondPhoneEdit.length < 10) {
      setFormState(({ second_phone, ...others }) => ({
        second_phone: true,
        ...others,
      }));

      fakeFormState.second_phone = true;
    }

    if (secondPhoneEdit.length > 0 && second_phone == "") {
      setFormState(({ second_phone_code, ...others }) => ({
        second_phone_code: true,
        ...others,
      }));

      fakeFormState.second_phone_code = true;
    }

    if (
      website.length > 0 &&
      !website.startsWith("http://") &&
      !website.startsWith("https://")
    ) {
      setFormState(({ website, ...others }) => ({
        website: true,
        ...others,
      }));

      fakeFormState.website = true;
    }

    let formError = false;

    // All form fields validation
    Object.entries(fakeFormState).map(([type, value]) => {
      if (value == true) {
        formError = true;
      }
    });

    if (formError) {
      Swal.fire({
        icon: "warning",
        title: "Uyarı",
        html: `
        <div>
        ${isEnglish ? "Missing Fields" : "Eksik Alanlar"}: <br/>
        <p class="text-red-600">
        ${
          fakeFormState.country
            ? `${isEnglish ? "Country of Residence" : "Yaşıdığınız Ülke"} <br/>`
            : ""
        }
        ${
          fakeFormState.city
            ? `${isEnglish ? "City of Residence" : "Yaşadığınız Şehir"} <br/>`
            : ""
        }
        ${
          fakeFormState.district
            ? `${
                isEnglish ? "Residence of District" : "Yaşadığınız İlçe"
              } <br/>`
            : ""
        }
        ${
          fakeFormState.email
            ? `${isEnglish ? "E-mail" : "E-posta Adresi"} <br/>`
            : ""
        }
        ${
          fakeFormState.phone_code
            ? `${isEnglish ? "Country Code" : "Ülke Kodu"} <br/>`
            : ""
        }
        ${
          fakeFormState.phone
            ? `${isEnglish ? "Phone Number" : "Telefon Numarası"} <br/>`
            : ""
        }
        ${
          fakeFormState.second_phone
            ? `${
                isEnglish ? "Second Phone Number" : "İkinci Telefon Numarası"
              } <br/>`
            : ""
        }
        ${
          fakeFormState.second_phone_code
            ? `${isEnglish ? "Second Country Code" : "İkinci Ülke Kodu"} <br/>`
            : ""
        }
        ${
          fakeFormState.website
            ? `${
                isEnglish
                  ? "Valid website. For example: https://mywebsite.com"
                  : "Geçerli bir internet sitesi. Örn: https://websitem.com"
              } <br/>`
            : ""
        }
        </p>
        </div>
        `,
      });

      return;
    }

    // Single errors if form validation failed
    // Country
    if (country == null) {
      Swal.fire({
        icon: "error",
        title: "Uyarı",
        html: `
        <p class="text-red-600">
        ${
          isEnglish
            ? "Please select a country"
            : "Lütfen yaşadığınız ülkeyi seçiniz."
        }
        </p>
        `,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "sweet-alert-button", // TailwindCSS classes
        },
      });

      return;
    }

    // Cities
    if (cities.length > 0 && city == null) {
      Swal.fire({
        icon: "error",
        title: "Uyarı",
        html: `
        <p class="text-red-600">
        ${
          isEnglish
            ? "Please select a city"
            : "Lütfen yaşadığınız şehri seçiniz."
        }
        </p>
        `,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "sweet-alert-button", // TailwindCSS classes
        },
      });

      return;
    }

    // Districts
    if (districts.length > 0 && district == null) {
      Swal.fire({
        icon: "error",
        title: "Uyarı",
        html: `
        <p class="text-red-600">
        ${
          isEnglish
            ? "Please select a district"
            : "Lütfen yaşadığınız ilçeyi seçiniz."
        }
        </p>
        `,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "sweet-alert-button", // TailwindCSS classes
        },
      });

      return;
    }

    // Email
    if (email.trim().length == 0) {
      Swal.fire({
        icon: "error",
        title: "Uyarı",
        html: `
        <p class="text-red-600">
        ${
          isEnglish
            ? "Please type a valid e-mail"
            : "Lütfen geçerli bir e-posta adresi giriniz."
        }
        </p>
        `,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "sweet-alert-button", // TailwindCSS classes
        },
      });

      return;
    }

    // Phone code
    if (phone_code == null) {
      Swal.fire({
        icon: "error",
        title: "Uyarı",
        html: `
        <p class="text-red-600">
        ${
          isEnglish
            ? "Please select a country code"
            : "Lütfen ülke kodu seçiniz."
        }
        </p>
        `,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "sweet-alert-button", // TailwindCSS classes
        },
      });

      return;
    }

    // Phone edit
    if (phoneEdit.length < 10) {
      Swal.fire({
        icon: "error",
        title: "Uyarı",
        html: `
        <p class="text-red-600">
        ${
          isEnglish
            ? "Please type a valid phone."
            : "Lütfen geçerli bir cep telefonu giriniz."
        }
        </p>
        `,
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "sweet-alert-button", // TailwindCSS classes
        },
      });

      return;
    }

    /* if (phone.length > 14) {
      Swal.fire({
        icon: 'error',
        title: 'Uyarı',
        text: "Telefon numaranız 14 karakter uzunluğunda olmalıdır.",
        confirmButtonText: 'Tamam',
        customClass: {
          confirmButton: 'sweet-alert-button' // TailwindCSS classes
        }
      })

      return
    } */

    // Website
    if (website.trim().length > 0) {
      if (!website.startsWith("https://") && !website.startsWith("http://")) {
        Swal.fire({
          icon: "error",
          title: "Hata",
          html: `
          <p class="text-red-600">
          ${
            isEnglish
              ? "Website must be a valid url. For example: https://mywebsite.com"
              : "İnternet sitesi geçerli bir url olmalıdır. Örneğin: https://websitem.com"
          }
          </p>
          `,
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "sweet-alert-button", // TailwindCSS classes
          },
        });

        return;
      }
    }

    if (secondPhoneEdit.length > 0 && secondPhoneEdit.length < 10) {
      Swal.fire({
        icon: "warning",
        title: "Uyarı",
        html: `
        <p class="text-red-600">
        ${
          isEnglish
            ? "Please type a valid phone"
            : "Lütfen geçerli bir telefon giriniz"
        }
        </p>
        `,
        confirmButtonText: "Tamam",
      });

      return;
    }

    try {
      const phoneCodeArray = phone_code.title.split("+");
      let phoneCode = phoneCodeArray[1] ? `+${phoneCodeArray[1]}` : null;

      let secondPhoneCode: string | null = null;
      if (second_phone_code) {
        const secondPhoneCodeArray = second_phone_code.title.split("+");
        secondPhoneCode = secondPhoneCodeArray[1]
          ? `+${secondPhoneCodeArray[1]}`
          : null;
      }

      const payloadData: payloadDataType = {
        cv_id: cvId || "",
        city_id: city ? city.id : null,
        country_id: country.id,
        district_id: district != null ? district.id : null,
        address: address ? address : null,
        phone_code: phoneCode || "",
        phone: phoneEdit,
        second_phone_code: secondPhoneCode,
        second_phone: second_phone ? secondPhoneEdit : null,
        email: email,
        website: website ? website : null,
        social_media: socialMedia,
      };
      const request = await axios.request({
        endpoint: "user/profilim/cv/contact/update",
        type: "put",
        payload: payloadData,
      });

      //console.log("response", request);
      if (request && request.status == 200) {
        navigate(`/profilim/cv/duzenle/${cvId}/is-tercihleri`);
        showCvScore();
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "İletişim bilgileri güncellenemedi",
        });
      }
    } catch (error) {
      if (isAxiosError(error) && error.response?.data.errors) {
        const errorMessages = error.response.data.errors;
        const errorsArray = Object.entries(errorMessages).map(
          ([type, message]) => {
            return message;
          }
        );

        Swal.fire({
          icon: "warning",
          title: "Hata",
          html: `
          <div>
          Hatalı Alanlar: <br/>
          <p class="text-red-600">
          ${errorsArray.join("<br/>")}
          </p>
          </div>
          `,
        });
      } else {
        console.log(`Contact information update failed. Error: ${error}`);
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "İletişim bilgileri güncellenirken beklenmedik bir hata meydana geldi",
        });
      }
    }
  }

  if (loadingPage) {
    return <div className="p-2 border border-mains">Yükleniyor...</div>;
  }

  return (
    <div className="flex flex-col w-full  gap-y-8 rounded-md bg-white mb-20 lg:mb-0">
      <span className="poppins-semibold text-sm text-mains py-4 border-b border-mains px-4">
        {isEnglish ? "Contact Information" : "İletişim Bilgileri"}
      </span>

      <form
        className="flex flex-col gap-y-6 p-6"
        onSubmit={(e) => formSubmit(e)}
      >
        {/* Country-city-district select */}
        <div className="flex flex-col lg:flex-row gap-y-6 lg:gap-6">
          <div className="flex flex-col lg:flex-row gap-y-6 lg:gap-y-0 flex-wrap w-full">
            {/* Country select */}
            <div className="flex basis-full lg:basis-1/3">
              <div className="flex w-full">
                <SelectSearchDropdown
                  className="w-full"
                  required
                  error={formState.country}
                  mode={!country ? "empty" : "dirty"}
                >
                  <SelectSearchDropdown.Label>
                    {isEnglish ? "Residence of Country" : "Yaşadığınız Ülke"}
                  </SelectSearchDropdown.Label>
                  <SelectSearchDropdown.Trigger
                    onClick={async () => {
                      if (
                        searchCountry.length == 0 &&
                        filteredCountries.length == 0
                      ) {
                        getCountries();
                      }
                    }}
                  >
                    <SelectSearchDropdown.Value>
                      {country
                        ? `${
                            isEnglish
                              ? `${country.title_en}`
                              : `${country.title}`
                          }`
                        : `${isEnglish ? "Select" : "Seçiniz"}`}
                    </SelectSearchDropdown.Value>
                    <SelectSearchDropdown.Input
                      placeholder={`${isEnglish ? "Search" : "Arama yap"}`}
                      value={searchCountry || ""}
                      onChange={(e) => setSearchCountry(e.target.value)}
                    />
                  </SelectSearchDropdown.Trigger>
                  <SelectSearchDropdown.Items>
                    {filteredCountries.map((c) => {
                      return (
                        <SelectSearchDropdown.Item
                          key={`cslesl${c.id}`}
                          onClick={() => setCountry(c)}
                        >
                          {isEnglish ? c.title_en : c.title}
                        </SelectSearchDropdown.Item>
                      );
                    })}
                  </SelectSearchDropdown.Items>
                </SelectSearchDropdown>
              </div>
            </div>

            {/* City select */}
            <div className="flex px-0 lg:px-4 basis-full lg:basis-1/3">
              <div className="flex w-full">
                <SelectSearchDropdown
                  className="w-full"
                  required
                  error={formState.city}
                  mode={!city ? "empty" : "dirty"}
                >
                  <SelectSearchDropdown.Label>
                    {isEnglish ? "Residence of City" : "Yaşadığınız Şehir"}
                  </SelectSearchDropdown.Label>
                  <SelectSearchDropdown.Trigger>
                    <SelectSearchDropdown.Value>
                      {city
                        ? `${isEnglish ? `${city.title_en}` : `${city.title}`}`
                        : `${isEnglish ? "Select" : "Seçiniz"}`}
                    </SelectSearchDropdown.Value>
                    <SelectSearchDropdown.Input
                      placeholder={`${isEnglish ? "Search" : "Arama yap"}`}
                      value={searchCity || ""}
                      onChange={(e) => {
                        setSearchCity(e.target.value);
                      }}
                    />
                  </SelectSearchDropdown.Trigger>
                  <SelectSearchDropdown.Items>
                    {filteredCities.map((c) => {
                      return (
                        <SelectSearchDropdown.Item
                          key={`cityselectimte${c.id}`}
                          onClick={() => {
                            setCity(c);
                            setFormState(({ city, ...others }) => ({
                              city: false,
                              ...others,
                            }));
                          }}
                        >
                          {isEnglish ? c.title_en ?? c.title : c.title}
                        </SelectSearchDropdown.Item>
                      );
                    })}
                  </SelectSearchDropdown.Items>
                </SelectSearchDropdown>
              </div>
            </div>

            {/* District select */}
            {districts.length > 0 && (
              <div className="flex basis-full lg:basis-1/3">
                <div className="flex w-full">
                  <SelectSearchDropdown
                    className="w-full"
                    required
                    error={formState.district == true}
                    mode={!district ? "empty" : "dirty"}
                  >
                    <SelectSearchDropdown.Label>
                      {isEnglish ? "Residence of District" : "Yaşadığınız İlçe"}
                    </SelectSearchDropdown.Label>
                    <SelectSearchDropdown.Trigger>
                      <SelectSearchDropdown.Value>
                        {district?.title ?? "Seçiniz"}
                      </SelectSearchDropdown.Value>
                      <SelectSearchDropdown.Input
                        placeholder={`${
                          isEnglish ? `${isEnglish}` : "Arama yap"
                        }`}
                        value={searchDistrict || ""}
                        onChange={(e) => {
                          setSearchDistrict(e.target.value);
                        }}
                      />
                    </SelectSearchDropdown.Trigger>
                    <SelectSearchDropdown.Items>
                      {filteredDistricts.map((d) => {
                        return (
                          <SelectSearchDropdown.Item
                            key={`distcselect${d.id}`}
                            onClick={() => {
                              setDistrict(d);
                              setFormState(({ district, ...others }) => ({
                                district: false,
                                ...others,
                              }));
                            }}
                          >
                            {d.title}
                          </SelectSearchDropdown.Item>
                        );
                      })}
                    </SelectSearchDropdown.Items>
                  </SelectSearchDropdown>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Full adress textarea */}
        <div className="flex flex-col gap-1 w-full">
          <Textarea
            className="w-full"
            mode={address && address.length > 0 ? "dirty" : "empty"}
          >
            <Textarea.Label>
              {isEnglish ? "Address" : "Açık Adres"}
            </Textarea.Label>
            <Textarea.Field
              className="min-h-[100px]"
              value={address || ""}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Textarea>
        </div>

        {/* Email input */}
        <div className="flex w-full lg:w-1/2">
          <InputArea
            className="w-full"
            required
            error={formState.email}
            mode={email.length == 0 ? "empty" : "dirty"}
          >
            <InputArea.Label>
              {isEnglish ? "E-mail Address" : "E-posta Adresi"}
            </InputArea.Label>
            <InputArea.Input
              value={email || ""}
              onChange={(e) => {
                setFormState(({ email, ...others }) => ({
                  email: false,
                  ...others,
                }));
                setEmail(e.target.value);
              }}
            />
          </InputArea>
        </div>

        {/* Phones field */}
        <div className="flex flex-col lg:flex-row gap-y-6 lg:gap-6">
          {/* First Phone */}
          <div className="flex flex-row gap-y-6 gap-x-2 basis-full lg:basis-1/2">
            {/* Phone code select */}
            <div className="flex w-full">
              <SelectDropdown
                className="w-full"
                required
                error={formState.phone_code}
                mode={phone_code ? "dirty" : "empty"}
              >
                <SelectDropdown.Label>
                  {isEnglish ? "Phone Number" : "Telefon Numarası"}
                </SelectDropdown.Label>
                <div className="flex flex-row items-center gap-2 max-w-[100%] ">
                  <SelectDropdown.Trigger className="min-w-28 w-full pointer-events-none">
                    {phone_code?.title ? (
                      <div className="flex flex-row items-center gap-1">
                        <ReactCountryFlag
                          countryCode={phone_code.code || ""}
                          className="text-xl"
                          svg
                        />{" "}
                        {phone_code.title.split("+")[1]
                          ? `+${phone_code.title.split("+")[1]}`
                          : ""}
                      </div>
                    ) : (
                      `${isEnglish ? "Select" : "Seçiniz"}`
                    )}
                  </SelectDropdown.Trigger>
                  <InputArea
                    error={formState.phone == true}
                    mode={phone.length == 0 ? "empty" : "dirty"}
                    className="!p-0 !h-full max-w-[50%] w-full"
                  >
                    <InputArea.Input
                      className="!h-full"
                      placeholder={
                        phone_code?.id == 1
                          ? "(5__) ___ __ __"
                          : "(___) ___ __ __"
                      }
                      value={phone || ""}
                      onClick={() => {
                        // Initial write (5 to input
                        if (phone_code?.id == 1) {
                          if (phone == "" || phone == "(" || phone == "(5") {
                            setPhone("(5");
                          }
                        }
                      }}
                      onChange={(e) => {
                        let value = e.target.value;

                        value = value.replace(/\D/g, "");
                        if (phone_code?.id == 1 && !value.startsWith("5")) {
                          value = "5" + value;
                        }

                        // Phone number formatting
                        let formattedValue = "";
                        if (value.length > 0)
                          formattedValue += `(${value.substring(0, 3)}`;
                        if (value.length >= 4)
                          formattedValue += `) ${value.substring(3, 6)}`;
                        if (value.length >= 7)
                          formattedValue += ` ${value.substring(6, 8)}`;
                        if (value.length >= 9)
                          formattedValue += ` ${value.substring(8, 10)}`;

                        setPhone(formattedValue);
                        setFormState(({ phone, ...others }) => ({
                          phone: false,
                          ...others,
                        }));
                      }}
                      onBlur={() => {
                        if (phone_code?.id == 1 && phone == "(5") {
                          setPhone("");
                        }
                      }}
                      onKeyDown={(e) => {
                        if (
                          phone_code?.id == 1 &&
                          phone == "(5" &&
                          e.key == "ArrowLeft"
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </InputArea>
                </div>
                <SelectDropdown.Items>
                  {filteredPhoneCodes.map((p) => {
                    return (
                      <SelectDropdown.Item
                        key={`p1-${p.id}`}
                        onClick={() => setPhone_code(p)}
                      >
                        {isEnglish ? p.title_en : p.title}
                      </SelectDropdown.Item>
                    );
                  })}
                </SelectDropdown.Items>
              </SelectDropdown>
            </div>

            {/* Phone input */}
            {/* TODO: Buradan silindi */}
          </div>

          {/* Second Phone */}
          <div className="flex flex-row gap-x-2 basis-full lg:basis-1/2">
            {/* Phone code select */}
            <div className="flex w-full">
              <SelectDropdown
                className="w-full"
                mode={second_phone_code ? "dirty" : "empty"}
              >
                <SelectDropdown.Label>
                  {isEnglish ? "2. Phone Number" : "2. Telefon Numarası"}
                </SelectDropdown.Label>
                <div className="flex flex-row items-center gap-2">
                  <SelectDropdown.Trigger className="min-w-28 w-full pointer-events-none">
                    {/* {second_phone_code?.title ?? "Seçiniz"} */}
                    {second_phone_code?.title ? (
                      <div className="flex flex-row items-center gap-1">
                        <ReactCountryFlag
                          countryCode={second_phone_code.code || ""}
                          className="text-xl"
                          svg
                        />{" "}
                        {second_phone_code.title.split("+")[1]
                          ? `+${second_phone_code.title.split("+")[1]}`
                          : ""}
                      </div>
                    ) : (
                      `${isEnglish ? "Select" : "Seçiniz"}`
                    )}
                  </SelectDropdown.Trigger>
                  <InputArea
                    mode={second_phone.length == 0 ? "empty" : "dirty"}
                    error={formState.second_phone == true}
                    className="!p-0 !h-full max-w-[50%] w-full"
                  >
                    <InputArea.Input
                      className="!h-full"
                      placeholder={
                        second_phone_code?.id == 1
                          ? "(5__) ___ __ __"
                          : "(___) ___ __ __"
                      }
                      value={second_phone || ""}
                      onClick={() => {
                        // Initial write (5 to input
                        if (second_phone_code?.id == 1) {
                          if (
                            second_phone == "" ||
                            second_phone == "(" ||
                            second_phone == "(5"
                          ) {
                            setSecond_phone("(5");
                          }
                        }
                      }}
                      onChange={(e) => {
                        let value = e.target.value;

                        value = value.replace(/\D/g, "");
                        if (
                          second_phone_code?.id == 1 &&
                          !value.startsWith("5")
                        ) {
                          value = "5" + value;
                        }

                        // Phone number formatting
                        let formattedValue = "";
                        if (value.length > 0)
                          formattedValue += `(${value.substring(0, 3)}`;
                        if (value.length >= 4)
                          formattedValue += `) ${value.substring(3, 6)}`;
                        if (value.length >= 7)
                          formattedValue += ` ${value.substring(6, 8)}`;
                        if (value.length >= 9)
                          formattedValue += ` ${value.substring(8, 10)}`;

                        setFormState(({ second_phone, ...others }) => ({
                          second_phone: false,
                          ...others,
                        }));
                        setSecond_phone(formattedValue);
                      }}
                      onBlur={() => {
                        if (
                          second_phone_code?.id == 1 &&
                          second_phone == "(5"
                        ) {
                          setSecond_phone("");
                        }
                      }}
                      onKeyDown={(e) => {
                        if (
                          second_phone_code?.id == 1 &&
                          second_phone == "(5" &&
                          e.key == "ArrowLeft"
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </InputArea>
                </div>
                <SelectDropdown.Items>
                  {filteredSecondPhoneCodes.map((p) => {
                    return (
                      <SelectDropdown.Item
                        key={`fsp${p.id}`}
                        onClick={() => setSecond_phone_code(p)}
                      >
                        {isEnglish ? p.title_en : p.title}
                      </SelectDropdown.Item>
                    );
                  })}
                </SelectDropdown.Items>
              </SelectDropdown>
            </div>
          </div>
        </div>

        {/* Website input */}
        <div className="flex w-full lg:w-1/2">
          <InputArea
            className="w-full"
            mode={website.length == 0 ? "empty" : "dirty"}
            error={formState.website}
          >
            <InputArea.Label>
              {isEnglish ? "Website (If you have one)" : "Web Siteniz (Varsa)"}
            </InputArea.Label>
            <InputArea.Input
              value={website || ""}
              onChange={(e) => {
                const value = e.target.value.replaceAll(" ", "");
                setWebsite(value);
                setFormState(({ website, ...others }) => ({
                  website: false,
                  ...others,
                }));
              }}
            />
          </InputArea>
        </div>

        {/* Social medias section */}
        <div className="flex flex-col gap-4 w-full lg:w-1/3">
          {/* <InputField.Label>Sosyal Medya Hesapları</InputField.Label> */}
          <InputArea.Label>
            {isEnglish ? "Social Media Accounts" : "Sosyal Medya Hesapları"}
          </InputArea.Label>
          <div className="flex flex-col gap-4">
            {/* Dribbble input */}
            {socialMedia.dribbble != null ? (
              <div className="flex flex-row gap-6">
                <div className="flex flex-row border border-[#b2b6b9] w-full rounded-md items-center">
                  <div className="bg-[#2253af] aspect-square h-full items-center justify-center p-2 rounded-md">
                    <FaDribbble className="text-white text-3xl flex" />
                  </div>
                  <input
                    className="p-3 outline-none w-full rounded-md font-poppins text-[#555] text-[14px] placeholder:text-[#b2b6b9]"
                    placeholder="Dribble"
                    value={socialMedia.dribbble || ""}
                    onChange={(e) => {
                      setSocialMedia(({ dribbble, ...others }) => ({
                        dribbble: e.target.value,
                        ...others,
                      }));
                    }}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    Swal.fire({
                      icon: "warning",
                      title: `${isEnglish ? "Warning" : "Uyarı"}`,
                      text: `${
                        isEnglish
                          ? "Are you sure to delete social media account?"
                          : "Sosyal medya hesabını silmek istediğinizden emin misiniz?"
                      }`,
                      confirmButtonText: `${isEnglish ? "Yes" : "Evet"}`,
                      cancelButtonText: `${isEnglish ? "No" : "Hayır"}`,
                      showCancelButton: true,
                      preConfirm: () => {
                        setSocialMedia(({ dribbble, ...others }) => ({
                          dribbble: null,
                          ...others,
                        }));
                      },
                    });
                  }}
                >
                  <IoTrashBinOutline className="text-[#555]" />
                </button>
              </div>
            ) : null}

            {/* Instagram input */}
            {socialMedia.instagram != null ? (
              <div className="flex flex-row gap-6">
                <div className="flex flex-row border border-[#b2b6b9] w-full rounded-md items-center">
                  <div className="bg-[#2253af] aspect-square h-full items-center justify-center p-2 rounded-md">
                    <FaInstagram className="text-white text-3xl flex" />
                  </div>
                  <input
                    className="p-3 outline-none w-full rounded-md font-poppins text-[#555] text-[14px] placeholder:text-[#b2b6b9]"
                    placeholder="Instagram"
                    value={socialMedia.instagram || ""}
                    onChange={(e) => {
                      setSocialMedia(({ instagram, ...others }) => ({
                        instagram: e.target.value,
                        ...others,
                      }));
                    }}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    Swal.fire({
                      icon: "warning",
                      title: `${isEnglish ? "Warning" : "Uyarı"}`,
                      text: `${
                        isEnglish
                          ? "Are you sure to delete social media account?"
                          : "Sosyal medya hesabını silmek istediğinizden emin misiniz?"
                      }`,
                      confirmButtonText: `${isEnglish ? "Yes" : "Evet"}`,
                      cancelButtonText: `${isEnglish ? "No" : "Hayır"}`,
                      showCancelButton: true,
                      preConfirm: () => {
                        setSocialMedia(({ instagram, ...others }) => ({
                          instagram: null,
                          ...others,
                        }));
                      },
                    });
                  }}
                >
                  <IoTrashBinOutline className="text-[#555]" />
                </button>
              </div>
            ) : null}

            {/* Facebook input */}
            {socialMedia.facebook != null ? (
              <div className="flex flex-row gap-6">
                <div className="flex flex-row border border-[#b2b6b9] w-full rounded-md items-center">
                  <div className="bg-[#2253af] aspect-square h-full items-center justify-center p-2 rounded-md">
                    <FaFacebookF className="text-white text-3xl flex" />
                  </div>
                  <input
                    className="p-3 outline-none w-full rounded-md font-poppins text-[#555] text-[14px] placeholder:text-[#b2b6b9]"
                    placeholder="Facebook"
                    value={socialMedia.facebook || ""}
                    onChange={(e) => {
                      setSocialMedia(({ facebook, ...others }) => ({
                        facebook: e.target.value,
                        ...others,
                      }));
                    }}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    Swal.fire({
                      icon: "warning",
                      title: `${isEnglish ? "Warning" : "Uyarı"}`,
                      text: `${
                        isEnglish
                          ? "Are you sure to delete social media account?"
                          : "Sosyal medya hesabını silmek istediğinizden emin misiniz?"
                      }`,
                      confirmButtonText: `${isEnglish ? "Yes" : "Evet"}`,
                      cancelButtonText: `${isEnglish ? "No" : "Hayır"}`,
                      showCancelButton: true,
                      preConfirm: () => {
                        setSocialMedia(({ facebook, ...others }) => ({
                          facebook: null,
                          ...others,
                        }));
                      },
                    });
                  }}
                >
                  <IoTrashBinOutline className="text-[#555]" />
                </button>
              </div>
            ) : null}

            {/* Linkedin input */}
            {socialMedia.linkedin != null ? (
              <div className="flex flex-row gap-6">
                <div className="flex flex-row border border-[#b2b6b9] w-full rounded-md items-center">
                  <div className="bg-[#2253af] aspect-square h-full items-center justify-center p-2 rounded-md">
                    <FaLinkedin className="text-white text-3xl flex" />
                  </div>
                  <input
                    className="p-3 outline-none w-full rounded-md font-poppins text-[#555] text-[14px] placeholder:text-[#b2b6b9]"
                    placeholder="Linkedin"
                    value={socialMedia.linkedin || ""}
                    onChange={(e) => {
                      setSocialMedia(({ linkedin, ...others }) => ({
                        linkedin: e.target.value,
                        ...others,
                      }));
                    }}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    Swal.fire({
                      icon: "warning",
                      title: `${isEnglish ? "Warning" : "Uyarı"}`,
                      text: `${
                        isEnglish
                          ? "Are you sure to delete social media account?"
                          : "Sosyal medya hesabını silmek istediğinizden emin misiniz?"
                      }`,
                      confirmButtonText: `${isEnglish ? "Yes" : "Evet"}`,
                      cancelButtonText: `${isEnglish ? "No" : "Hayır"}`,
                      showCancelButton: true,
                      preConfirm: () => {
                        setSocialMedia(({ linkedin, ...others }) => ({
                          linkedin: null,
                          ...others,
                        }));
                      },
                    });
                  }}
                >
                  <IoTrashBinOutline className="text-[#555]" />
                </button>
              </div>
            ) : null}

            {/* Youtube input */}
            {socialMedia.youtube != null ? (
              <div className="flex flex-row gap-6">
                <div className="flex flex-row border border-[#b2b6b9] w-full rounded-md items-center">
                  <div className="bg-[#2253af] aspect-square h-full items-center justify-center p-2 rounded-md">
                    <FaYoutube className="text-white text-3xl flex" />
                  </div>
                  <input
                    className="p-3 outline-none w-full rounded-md font-poppins text-[#555] text-[14px] placeholder:text-[#b2b6b9]"
                    placeholder="Youtube"
                    value={socialMedia.youtube || ""}
                    onChange={(e) => {
                      setSocialMedia(({ youtube, ...others }) => ({
                        youtube: e.target.value,
                        ...others,
                      }));
                    }}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    Swal.fire({
                      icon: "warning",
                      title: `${isEnglish ? "Warning" : "Uyarı"}`,
                      text: `${
                        isEnglish
                          ? "Are you sure to delete social media account?"
                          : "Sosyal medya hesabını silmek istediğinizden emin misiniz?"
                      }`,
                      confirmButtonText: `${isEnglish ? "Yes" : "Evet"}`,
                      cancelButtonText: `${isEnglish ? "No" : "Hayır"}`,
                      showCancelButton: true,
                      preConfirm: () => {
                        setSocialMedia(({ youtube, ...others }) => ({
                          youtube: null,
                          ...others,
                        }));
                      },
                    });
                  }}
                >
                  <IoTrashBinOutline className="text-[#555]" />
                </button>
              </div>
            ) : null}

            {/* Twitter input */}
            {socialMedia.twitter != null ? (
              <div className="flex flex-row gap-6">
                <div className="flex flex-row border border-[#b2b6b9] w-full rounded-md items-center">
                  <div className="bg-[#2253af] aspect-square h-full items-center justify-center p-2 rounded-md">
                    <FaXTwitter className="text-white text-3xl flex" />
                  </div>
                  <input
                    className="p-3 outline-none w-full rounded-md font-poppins text-[#555] text-[14px] placeholder:text-[#b2b6b9]"
                    placeholder="Twitter"
                    value={socialMedia.twitter || ""}
                    onChange={(e) => {
                      setSocialMedia(({ twitter, ...others }) => ({
                        twitter: e.target.value,
                        ...others,
                      }));
                    }}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    Swal.fire({
                      icon: "warning",
                      title: `${isEnglish ? "Warning" : "Uyarı"}`,
                      text: `${
                        isEnglish
                          ? "Are you sure to delete social media account?"
                          : "Sosyal medya hesabını silmek istediğinizden emin misiniz?"
                      }`,
                      confirmButtonText: `${isEnglish ? "Yes" : "Evet"}`,
                      cancelButtonText: `${isEnglish ? "No" : "Hayır"}`,
                      showCancelButton: true,
                      preConfirm: () => {
                        setSocialMedia(({ twitter, ...others }) => ({
                          twitter: null,
                          ...others,
                        }));
                      },
                    });
                  }}
                >
                  <IoTrashBinOutline className="text-[#555]" />
                </button>
              </div>
            ) : null}

            {/* Github input */}
            {socialMedia.github != null ? (
              <div className="flex flex-row gap-6">
                <div className="flex flex-row border border-[#b2b6b9] w-full rounded-md items-center">
                  <div className="bg-[#2253af] aspect-square h-full items-center justify-center p-2 rounded-md">
                    <FaGithub className="text-white text-3xl flex" />
                  </div>
                  <input
                    className="p-3 outline-none w-full rounded-md font-poppins text-[#555] text-[14px] placeholder:text-[#b2b6b9]"
                    placeholder="Github"
                    value={socialMedia.github || ""}
                    onChange={(e) => {
                      setSocialMedia(({ github, ...others }) => ({
                        github: e.target.value,
                        ...others,
                      }));
                    }}
                  />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    Swal.fire({
                      icon: "warning",
                      title: `${isEnglish ? "Warning" : "Uyarı"}`,
                      text: `${
                        isEnglish
                          ? "Are you sure to delete social media account?"
                          : "Sosyal medya hesabını silmek istediğinizden emin misiniz?"
                      }`,
                      confirmButtonText: `${isEnglish ? "Yes" : "Evet"}`,
                      cancelButtonText: `${isEnglish ? "No" : "Hayır"}`,
                      showCancelButton: true,
                      preConfirm: () => {
                        setSocialMedia(({ github, ...others }) => ({
                          github: null,
                          ...others,
                        }));
                      },
                    });
                  }}
                >
                  <IoTrashBinOutline className="text-[#555]" />
                </button>
              </div>
            ) : null}
          </div>

          <SelectDropdown mode="dirty">
            <SelectDropdown.Trigger>
              {isEnglish ? "+ New Social Media" : "+ Yeni Sosyal Medya"}
            </SelectDropdown.Trigger>
            <SelectDropdown.Items>
              {/* Dribble select item */}
              <SelectDropdown.Item
                onClick={() => {
                  setSocialMedia(({ dribbble, ...others }) => ({
                    dribbble: dribbble ? dribbble : "",
                    ...others,
                  }));
                }}
              >
                Dribble
              </SelectDropdown.Item>

              {/* Instagram select item */}
              <SelectDropdown.Item
                onClick={() => {
                  setSocialMedia(({ instagram, ...others }) => ({
                    instagram: instagram ? instagram : "",
                    ...others,
                  }));
                }}
              >
                Instagram
              </SelectDropdown.Item>

              {/* Facebook select item */}
              <SelectDropdown.Item
                onClick={() => {
                  setSocialMedia(({ facebook, ...others }) => ({
                    facebook: facebook ? facebook : "",
                    ...others,
                  }));
                }}
              >
                Facebook
              </SelectDropdown.Item>

              {/* Linkedin select item */}
              <SelectDropdown.Item
                onClick={() => {
                  setSocialMedia(({ linkedin, ...others }) => ({
                    linkedin: linkedin ? linkedin : "",
                    ...others,
                  }));
                }}
              >
                Linkedin
              </SelectDropdown.Item>

              {/* Youtube select item */}
              <SelectDropdown.Item
                onClick={() => {
                  setSocialMedia(({ youtube, ...others }) => ({
                    youtube: youtube ? youtube : "",
                    ...others,
                  }));
                }}
              >
                Youtube
              </SelectDropdown.Item>

              {/* Twitter select item */}
              <SelectDropdown.Item
                onClick={() => {
                  setSocialMedia(({ twitter, ...others }) => ({
                    twitter: twitter ? twitter : "",
                    ...others,
                  }));
                }}
              >
                Twitter
              </SelectDropdown.Item>

              {/* Github select item */}
              <SelectDropdown.Item
                onClick={() => {
                  setSocialMedia(({ github, ...others }) => ({
                    github: github ? github : "",
                    ...others,
                  }));
                }}
              >
                Github
              </SelectDropdown.Item>
            </SelectDropdown.Items>
          </SelectDropdown>
        </div>
        <hr />
        <div className="flex items-center justify-center lg:justify-between gap-2 flex-wrap">
          <Button
            type="button"
            variant="secondary"
            size="sm"
            className="lg:!px-6"
            onClick={() => navigate(`/profilim/cv/duzenle/${cvId}`)}
          >
            <Button.Icon>
              <IoChevronBack />
            </Button.Icon>
            {isEnglish ? "Go Back" : "Geri Dön"}
          </Button>
          <Button type="submit" size="sm" className="lg:!px-6">
            {isEnglish ? "Save and Continue" : "Kaydet ve Devam Et"}
            <Button.Icon>
              <IoChevronForward />
            </Button.Icon>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
