import { InputHTMLAttributes, useContext } from "react"
import classNames from "classnames"
import { SelectSearchDropdownContext, selectSearchDropdownContextType } from "."




type inputPropsType = InputHTMLAttributes<HTMLInputElement> & {

}






export default function Input({
    className,
    autoFocus = true,
    ...props
}: inputPropsType) {

    const { isOpen, setIsOpen }: selectSearchDropdownContextType = useContext(SelectSearchDropdownContext)

    const cn = classNames({
        'bg-transparent outline-none w-full placeholder:text-gray-500': true
    })

    if (isOpen) {
        return(
            <input
            className={`${cn} ${className}`}
            autoFocus={autoFocus}
            {...props}
            />
        )
    } else {
        return null
    }
    
}