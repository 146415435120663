import { useState, useEffect } from "react";
import Axios from "../../../../services/axios";
import GeneralBaseInfo from "../general-base-info";
import ShareButtons from "../share-buttons";
import AddDetail from "../ad-detail";
import MainInfo from "../main-info";
import SaveAndNextButon from "../save-and-next-button";
import { useParams } from "react-router-dom";
import { googleMapsApiKey } from "../../../../constants/enums";
import {
  APIProvider,
  ControlPosition,
  Map,
  MapControl,
  Marker,
} from "@vis.gl/react-google-maps";
import Button from "../../../../components/form-components/button";
import { FaLocationDot } from "react-icons/fa6";
import { IoChevronDown } from "react-icons/io5";

interface MapLocation {
  lat: number;
  longitude: number;
}
interface City {
  id: number;
  country_id: number;
  name: string;
  slug: string;
  seo_name: string | null;
  // Add other properties as necessary
}

interface District {
  id: number;
  city_id: number;
  name: string;
  slug: string;
  content: string;
  // Add other properties as necessary
}
export interface Location {
  advert_id: number;
  city: City;
  city_id: number;
  created_at: string;
  district: District;
  district_id: number;
  id: number;
  updated_at: string;
}
interface PositionDetails {
  created_at: string;
  id: number;
  is_individual: number;
  order: number;
  slug: string;
  title: string;
  title_en: string;
  updated_at: string;
}

export interface Position {
  advert_id: number;
  created_at: string;
  id: number;
  position: PositionDetails;
  position_id: number;
  updated_at: string;
}
interface PositionLevelDetails {
  created_at: string;
  id: number;
  name: string;
  name_en: string | null;
  slug: string;
  updated_at: string;
}

export interface PositionLevel {
  advert_id: number;
  created_at: string;
  id: number;
  position_level: PositionLevelDetails;
  position_level_id: number;
  updated_at: string;
}
interface SectorDetails {
  created_at: string;
  id: number;
  is_publish: string;
  order: number;
  slug: string;
  title: string;
  title_en: string | null;
  updated_at: string;
}

export interface Sector {
  advert_id: number;
  created_at: string;
  id: number;
  sector: SectorDetails;
  sector_id: number;
  updated_at: string;
}
interface WorkingLocationDetails {
  created_at: string;
  id: number;
  order: number;
  slug: string;
  title: string;
  title_en: string;
  updated_at: string;
}

export interface WorkingLocation {
  advert_id: number;
  created_at: string;
  id: number;
  updated_at: string;
  working_location: WorkingLocationDetails;
  working_location_id: number;
}

interface DrivingLicenceSrc {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface DrivingLicenceSrcs {
  driving_licence_src: DrivingLicenceSrc;
}

interface JobBenefitsDetails {
  id: number;
  name: string;
  name_en: string;
  created_at: string;
  updated_at: string;
}

export interface JobBenefits {
  job_benefit: JobBenefitsDetails;
}

export interface LanguageDetails {
  id: number;
  name: string;
  name_en: string;
  created_at: string;
  updated_at: string;
}

export interface Languages {
  language: LanguageDetails;
}

interface WorkingTypeDetails {
  created_at: string;
  id: number;
  order: number;
  slug: string;
  title: string;
  title_en: string;
  updated_at: string;
}

export interface WorkingType {
  advert_id: number;
  created_at: string;
  id: number;
  updated_at: string;
  working_type: WorkingTypeDetails;
  working_type_id: number;
}
interface EducationLevelsDetails {
  created_at: string;
  id: number;
  order: number;
  slug: string;
  title: string;
  title_en: string;
  updated_at: string;
}
export interface EducationLevels {
  advert_id: number;
  created_at: string;
  id: number;
  updated_at: string;
  education_level: EducationLevelsDetails;
  education_level_id: number;
}
interface ExperienceLevelDetail {
  created_at: string;
  id: number;
  order: number;
  slug: string;
  title: string;
  title_en: string;
  updated_at: string;
  value: number;
}
export interface ExperienceLevel {
  experience_level_min: ExperienceLevelDetail;
  experience_level_max: ExperienceLevelDetail;
}

type companyFileType = {
  id: number;
  company_id: string;
  title: string;
  file: string;
  createdAt: string;
  updatedAt: string;
};

type advertFileType = {
  company_file: companyFileType | null;
  company_file_id: number | null;
};

export type SectionType = {
  section: {
    id: number;
    title: string;
    title_en: string;
    slug: string;
    is_publish: string;
    order: number;
    created_at: string;
    updated_at: string;
  };
};
interface AdvertPayload {
  [key: string]: any;
  title: string;
  map_location: MapLocation | null;
  //TODO: Section yeni tip eklendi test edilip düzeltilecek
  //sections: number[]; // Array of section IDs
  sections: SectionType[];
  sectors: Sector[]; // Array of sector IDs
  positions: Position[]; // Array of position IDs
  working_types: WorkingType[]; // Array of working type IDs
  working_locations: WorkingLocation[]; // Array of working location IDs
  driving_licence_srcs: DrivingLicenceSrcs[]; // Array of driving license source IDs
  job_benefits: JobBenefits[]; // Array of job benefit IDs
  languages: Languages[]; // Array of language IDs
  position_levels: PositionLevel[]; // Array of position level IDs
  experience_level: ExperienceLevel;
  experience_level_type: "not-matter" | "experienced" | "inexperienced";
  experience_level_min: number;
  experience_level_max: number;
  education_levels: EducationLevels[]; // Array of education level IDs
  employee_count: number;
  min_age: number;
  max_age: number;
  gender: number; // Gender ID
  language: number; // Primary language ID

  country: number; // Country ID
  keywords: string[]; // Array of keywords
  detail: string; // Detailed description
  hide_contact_info: "show_all" | "show_gsm" | "show_phone" | "hide_all";
  hide_employee_info: boolean;
  hide_company_info: boolean;
  locations: Location[]; // Array of locations (city and district IDs)

  is_send_message_users: boolean;
  is_disabled_employee: boolean;
  template_type: "DEFAULT" | "MAP" | "IMAGE" | "MAP-IMAGE";
  questions: { question: number; is_required: boolean }[];
  terms: number;
  advert_file: advertFileType | null;
  company: { name: ""; surname: ""; phone: ""; land_phone: ""; email: "" };
  company_detail: {
    facebook: "";
    logo: "";
    twitter: "";
    whatsapp: "";
    company_name: string;
  };
}

const Body: React.FC = () => {
  const { advertId } = useParams();
  const [adData, setAdData] = useState<AdvertPayload>();
  const adDetail = adData?.advert.detail;
  const advertData = adData?.advert;
  const advertLanguage = advertData?.general_language_id || 9; // Türkçe default dil
  const companyData = adData?.company;
  const companyDetail = adData?.company_detail;
  const companyLogo = adData?.company_detail.logo;
  /* const companyFullName = [companyData?.name, companyData?.surname]
    .filter(Boolean)
    .join(" "); */
  const companyFullName = adData?.company_detail.company_name;
  const companyPhone = companyData?.phone;
  const companyLand_phone = companyData?.land_phone;
  const companyEmail = companyData?.email;
  const title = advertData?.title;
  {
    /*Arrays */
  }
  const positions = adData?.positions;
  const positionLevels = adData?.position_levels;
  const sectors = adData?.sectors;
  const sections = adData?.sections;
  //TODO: Tip uyuşmazlığı düzeltildi test edilecek
  //const sections = adData?.sections
  const locations = adData?.locations;
  const workingLocations = adData?.working_locations;
  const workingTypes = adData?.working_types;
  const languages = adData?.languages;
  const keywords = adData?.keywords;
  const jobBenefits = adData?.job_benefits;
  const drivingLicenceSrcs = adData?.driving_licence_srcs;
  const drivingLicenceClasses = adData?.driving_licence_classes;
  const educationLevels = adData?.education_levels;
  const experienceLevel = adData?.experience_level;
  const isDisabledEmployee = adData?.advert.is_disabled_employee;
  const hiddenCompany = adData?.advert.hidden_company;
  const hide_company_info = adData?.advert.hide_company_info;
  const hide_contact_info = adData?.advert.hide_contact_info;
  const hide_employee_info = adData?.advert.hide_employee_info;
  const template_type = advertData?.template_type || "";
  const employee_count = advertData?.employee_count;
  const min_age = advertData?.min_age;
  const max_age = advertData?.max_age;

  console.log("adData", adData);
  //console.log('employee_count', employee_count)

  const customeAxios = new Axios();

  const [hideImage, setHideImage] = useState<boolean>(false);
  const [showMap, setShowMap] = useState<boolean>(false);
  const [isEnglish, setIsEnglish] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchAdData = async () => {
    if (!advertId) return;
    setIsLoading(true);
    try {
      const respone = await customeAxios.authRequest({
        type: "get",
        endpoint: `companies/advert/get-show/${advertId}`,
        payload: "",
      });
      //console.log(respone, "response");
      if (respone.data) {
        setAdData(respone.data.data);
      }
    } catch (error) {
      console.log(`Error: ${error}`);
    }

    setIsLoading(false);
  };
  useEffect(() => {
    fetchAdData();
  }, [advertId]);

  useEffect(() => {
    if (adData) {
      if (adData?.advert?.general_language_id == 7) {
        setIsEnglish(true);
      } else {
        setIsEnglish(false);
      }
    }
  }, [adData]);

  if (isLoading) {
    return <div className="min-h-screen p-4">Yükleniyor...</div>;
  } else {
    return (
      <div>
        {/* FIXME: Advert template type hatalı düzeltilecek */}
        {(template_type == "MAP-IMAGE" || template_type == "IMAGE") &&
          adData?.advert_file &&
          !hideImage && (
            <div className="p-0 lg:p-2 bg-white">
              <img
                src={adData.advert_file.company_file?.file}
                onError={() => {
                  setHideImage(true);
                }}
              />
            </div>
          )}

        <GeneralBaseInfo
          title={title || "loading.."}
          companyFullName={
            companyFullName == null
              ? `${
                  advertLanguage != 9
                    ? "Company Information is Confidential"
                    : "Firma Bilgisi Gizli"
                }`
              : companyFullName
          }
          locations={locations}
          companyLogo={companyLogo}
          advertData={advertData}
          advertLanguage={advertLanguage}
          isDisabledEmployee={isDisabledEmployee}
        />
        <ShareButtons
          companyDetail={companyDetail}
          email={adData?.company.email}
        />
        <AddDetail adDetail={adDetail || ""} advertLanguage={advertLanguage} />
        <MainInfo
          positions={positions}
          sectors={sectors}
          sections={sections}
          workingTypes={workingTypes}
          positionLevels={positionLevels}
          workingLocations={workingLocations}
          locations={locations}
          experienceLevel={experienceLevel}
          educationLevels={educationLevels}
          employee_name={`${
            adData?.company.name && adData.company.surname
              ? `${adData?.company?.name} ${adData?.company?.surname}`
              : ``
          }`}
          companyFullName={companyFullName || ""}
          companyPhone={companyPhone || ""}
          companyLand_phone={companyLand_phone || ""}
          drivingLicenceSrcs={drivingLicenceSrcs}
          drivingLicenceClasses={drivingLicenceClasses}
          jobBenefits={jobBenefits}
          languages={languages}
          hide_company_info={hide_company_info}
          hide_contact_info={hide_contact_info}
          hide_employee_info={hide_employee_info}
          advertLanguage={advertLanguage}
          employee_count={employee_count}
          min_age={min_age}
          max_age={max_age}
        />
        {(template_type == "MAP" || template_type == "MAP-IMAGE") &&
          adData?.map_location && (
            <div className="p-2 bg-white">
              <div className="py-2 pl-3 font-medium md:text-lg  shadow-sm border-b border-t bg-[#fbfbfb]">
                {isEnglish ? "LOCATION" : "KONUM"}
              </div>
              <Button
                type="button"
                className="my-6 mx-4"
                variant="ghost"
                onClick={() => setShowMap((prev) => !prev)}
              >
                <Button.Icon>
                  <FaLocationDot />
                </Button.Icon>
                {showMap
                  ? `${isEnglish ? "Hide Location" : "Konumu Gizle"}`
                  : `${isEnglish ? "Show Location" : "Konumu Göster"}`}
                <Button.Icon>
                  <IoChevronDown
                    className={`${showMap ? "rotate-180" : ""} transition-all`}
                  />
                </Button.Icon>
              </Button>

              {showMap && (
                <APIProvider apiKey={googleMapsApiKey}>
                  <Map
                    style={{
                      width: "100%",
                      height: "300px",
                    }}
                    defaultZoom={11}
                    defaultCenter={{
                      lat: adData.map_location.lat || 38,
                      lng: adData.map_location.longitude || 33,
                    }}
                    gestureHandling={"greedy"}
                    disableDefaultUI={false}
                  >
                    <Marker
                      position={{
                        lat: adData.map_location.lat || 38,
                        lng: adData.map_location.longitude || 33,
                      }}
                    />
                  </Map>
                </APIProvider>
              )}
            </div>
          )}
        <img src="/ad-870.png" className="mb-5 border-t" />
        <SaveAndNextButon advertId={advertId} companyEmail={companyEmail} />
      </div>
    );
  }
};
export default Body;
