/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from 'react';
import BlackList from './black-list-page';
import { useDispatch } from 'react-redux';
import { getCompanyUser } from '../../../store/features/companyUserSlice';
import ReportPage from './reports-page';
import ProfileTabSection from './profile-tab-section';
import { useLocation } from 'react-router-dom'; 



function SettingPage() {

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const initialActiveTab = searchParams.get('active')

  const [activeTab, setActiveTab] = useState('profile');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCompanyUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialActiveTab) {
      if (initialActiveTab == 'blacklist') {
        setActiveTab('blacklist')
      }
    }
  }, [])
  return (
    <div className="container m-auto py-10">
      <div className="flex flex-col gap-4 mb-10">
        {/* Header Start */}
        <div className="px-5 py-7 bg-white rounded-md shadow-sm">
          <h1 className="text-[19px] font-semibold">HESAP AYARLARI</h1>
        </div>
        {/* Header End */}
        {/* Tabs Start */}
        <div className="flex flex-row rounded-lg overflow-hidden w-full bg-white gap-0.5">
          <button
            onClick={() => setActiveTab('profile')}
            className={`w-full bg-[#545454] h-[60px] flex justify-center items-center text-sm font-medium text-white transition-all ${
              activeTab === 'profile' ? '!bg-[#2253af]' : ''
            }`}>
            Firma Bilgileri
          </button>
          <button
            onClick={() => setActiveTab('blacklist')}
            className={`w-full bg-[#545454] h-[60px] flex justify-center items-center text-sm font-medium text-white transition-all ${
              activeTab === 'blacklist' ? '!bg-[#2253af]' : ''
            }`}>
            Kara Liste
          </button>
          <button
            onClick={() => setActiveTab('reports')}
            className={`w-full bg-[#545454] h-[60px] flex justify-center items-center text-sm font-medium text-white transition-all ${
              activeTab === 'reports' ? '!bg-[#2253af]' : ''
            }`}>
            Raporlar
          </button>
        </div>
        {/* Tabs End */}

        {/* Company Info Start */}
        {activeTab === 'profile' && <ProfileTabSection />}
        {/* Company Info End */}
        {/* Black List Start */}
        {activeTab === 'blacklist' && <BlackList />}

        {/* Black List End */}

        {/* Reports Start */}
        {activeTab === 'reports' && <ReportPage />}
        {/* Reports end */}
      </div>
      
    </div>
  );
}

export default SettingPage;
