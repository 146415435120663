import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IconArrowLeft } from "@tabler/icons-react";
import HeaderRightButtonGroup from "./components/header-right-button-group";
import HeaderLeftButtonGroup from "./components/header-left-button-group";
import HamburgerIcon from "../components/hamburger-icon";
import { HamburgerContent } from "../components/hamburger/hamburger-content";

import { useCookie } from "../../../../hooks/useCookie";
import { CookieName } from "../../../../constants/enums";
import { useHamburgerMenu } from "../../../../hooks/useHamburgerMenu";
import { headerPages } from "./constants/headerPages";
import Axios from "../../../../services/axios";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const { getCookie } = useCookie();

  const location = useLocation();

  //console.log(companyUser);
  const companyUserCookie = getCookie(CookieName.CompanyUserData);
  const companyUser = companyUserCookie ? JSON.parse(companyUserCookie) : null;

  const userCookie = getCookie(CookieName.UserData);
  const user = userCookie ? JSON.parse(userCookie) : "";
  //console.log('user', user)
  
  const baseLogoURL =
    "https://isbull.s3.eu-north-1.amazonaws.com/settings/J3KHCRhMrpXMXwT6aX2VVUAwodZYErFbUQulK6sy.png";
  const fixedLogoURL =
    "https://isbull.s3.eu-north-1.amazonaws.com/logo-mavi.png";

  const fixedTop = isScrolled ? "top-0" : "top-[-80px]";

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 75) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { isHamburger, setIsHamburger, hamburgerRef } = useHamburgerMenu();

  // Bu kısım bazı bölümlerde mavi olan header için hazırlandı. headerPages içerisine başlık ve url eklerseniz o kısımlar maviye dönüşüyor
  //const headerSection = headerPages.find((h) => location.pathname.startsWith(h.url))

  return (
    <div
      className={`w-full shadow-lg lg:shadow-none lg:bg-[#1e4a9e] `}
    >
      <div className="container mx-auto">
        <div
          className={`container flex items-center justify-between w-full mx-auto select-none h-11 lg:h-20 px-5`}
        >
          {/* Mobile LeftArrow Icon */}
          <div className="lg:hidden" onClick={() => navigate(-1)}>
            <IconArrowLeft className={`text-mains`} size={28} />
          </div>

          {/* Mobile Header Logo*/}
          <div className="my-auto w-36 lg:hidden flex justify-center items-center">
            <Link to="/">
              <img
                src={fixedLogoURL}
                alt="logo"
                className="object-contain h-full max-w-[110px]"
              />
            </Link>
          </div>
          <div className="float-right lg:hidden" ref={hamburgerRef}>
            <HamburgerIcon
              state={isHamburger}
              setState={setIsHamburger}
              iconColor={"bg-black"}
            />
            <div>
              {companyUser ? (
                <HamburgerContent
                  isLogined={true}
                  isHamburger={isHamburger}
                  companyUser={companyUser}
                  userType="company"
                  setIsHamburger={setIsHamburger}
                />
              ) : (
                <HamburgerContent
                  isLogined={true}
                  isHamburger={isHamburger}
                  companyUser={user}
                  userType="user"
                  setIsHamburger={setIsHamburger}
                />
              )}
            </div>
          </div>
          <div className="items-center hidden h-full lg:flex">
            <Link to="/" className="h-full py-6 mr-5">
              <img
                src={baseLogoURL}
                className="object-contain h-full m-auto"
                alt="logo"
              />
            </Link>

            <HeaderLeftButtonGroup type="base" />
          </div>
          {companyUser ? (
            <HeaderRightButtonGroup
              type="base"
              companyUser={companyUser}
              userType="company"
            />
          ) : (
            <HeaderRightButtonGroup
              type="base"
              companyUser={user}
              userType="user"
            />
          )}
        </div>
      </div>

      {/* Top Fixed Header */}

      <div
        className={`w-full hidden lg:fixed z-50 transition-all h-16 bg-white shadow-xl ${fixedTop}`}
      >
        <div
          className={`h-full w-full bg-white hidden lg:flex  justify-between items-center shadow-y-2xl container mx-auto`}
        >
          <div className="flex items-center h-full">
            <Link to="/" className="hidden h-full py-3 mr-5 lg:block">
              <img
                src={fixedLogoURL}
                className="object-contain h-full m-auto"
                alt="logo"
              />
            </Link>

            <HeaderLeftButtonGroup type="fixed" />
          </div>
          {companyUser ? (
            <HeaderRightButtonGroup
              type="fixed"
              companyUser={companyUser}
              userType="company"
            />
          ) : (
            <HeaderRightButtonGroup
              type="fixed"
              companyUser={user}
              userType="user"
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default Header;
