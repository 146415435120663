import classNames from "classnames"
import { LabelHTMLAttributes, useContext } from "react"
import { InputAreaContext, inputAreaContextType } from "."




type errorPropsType = LabelHTMLAttributes<HTMLLabelElement> & {

}






export default function Error({
    className,
    ...props
}: errorPropsType) {

    const { error }: inputAreaContextType = useContext(InputAreaContext)

    const cn = classNames({
        'text-red-600 text-xs font-poppins': true
    })

    if (error) {
        return(
            <label
            className={`${cn} ${className}`}
            {...props}
            >
    
            </label>
        )
    } else {
        return null
    }
}