import BuyPacketForm from "../../../components/common/buy-packet-form";










export default function BuyPacketPageContainer() {
    return(
        <div className="container mx-auto my-10">
            <BuyPacketForm/>
        </div>
    )
}