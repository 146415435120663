import { useEffect, useState } from "react";
import Axios from "../../../../../../services/axios";
import { useParams } from "react-router-dom";
import CompetencesForm from "../components/CompetencesForm";
import { dbUserCompetenceType } from "../../../../../../types";
import SkillsForm from "../components/SkillsForm";
import { useContext } from "react";
import { CvLayoutContext, cvLayoutContextType } from "../..";
import { getSkills } from "../../../../../../customHooks/getSkills";





const axios = new Axios()


function UzmanlıkBilgiler() {

  const { isEnglish }: cvLayoutContextType = useContext(CvLayoutContext)

  const params = useParams()
  const cvId = params.slug


  const [competences, setCompetences] = useState<dbUserCompetenceType[]>([])

  async function getAllDatas() {
    try {
      
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/competences/get-competences?cv_id=${cvId}`,
        type: 'get',
        payload: {}
      })

      //console.log('response', response)
      if (response && response.data && response.data.competences) {
        setCompetences(response.data.competences)
      } else {
        setCompetences([])
      }
    } catch (error) {
      console.log(`Error: ${error}`)
      setCompetences([])
    }
  }

  useEffect(() => {
    getAllDatas()

  }, [])
  return (
    <div className="flex flex-col w-full gap-2 rounded-md bg-white lg:shadow-md pb-6">
      <span className="poppins-semibold text-sm text-mains py-4 border-b border-mains px-4">
        {isEnglish? 'Skills': 'Yetenekler'}
      </span>
      
      <SkillsForm
      skills={competences}
      />
    </div>
  );
}

export default UzmanlıkBilgiler;
