import React from "react";

interface IApplicationPopupListItem {
  createdAt: string;
  updatedAt: string;
  endDate: string;
  keys: {
    key: string;
    value: string;
  }[];
}

const ApplicationPopupListItem: React.FC<IApplicationPopupListItem> = (
  props: IApplicationPopupListItem
) => {
  console.log(props, "props");

  return (
    <>
      {props.keys.map((el, i) => (
        <div key={i} className="mt-5 w-full justify-between grid grid-cols-2">
          <div className="font-semibold text-gray-600">{el.key}</div>
          <div className="flex">
            <div className="mr-1">{":"}</div>
            <div className="text-blue-600 underline">{el.value}</div>
          </div>
        </div>
      ))}

      <div className="mt-5  w-full  grid grid-cols-2">
        <div className="font-semibold text-gray-600 line-clamp-1">
          {"İlk Yayınlama Tarihi"}
        </div>
        <div className="flex">
          <div className="mr-1">{":"}</div>
          <div className="text-gray-600">{props.createdAt}</div>
        </div>
      </div>
      <div className="mt-5 w-full  grid grid-cols-2">
        <div className="font-semibold text-gray-600 line-clamp-1">
          {"Güncelleme Tarihi"}
        </div>
        <div className="flex">
          <div className="mr-1">{":"}</div>
          <div className="text-gray-600">{props.updatedAt}</div>
        </div>
      </div>
      <div className="mt-5  w-full  grid grid-cols-2">
        <div className="font-semibold text-gray-600 line-clamp-1">
          {"Son Başvuru Tarihi"}
        </div>
        <div className="flex">
          <div className="mr-1">{":"}</div>
          <div className="text-gray-600">{props.endDate}</div>
        </div>
      </div>
    </>
  );
};

export default ApplicationPopupListItem;
