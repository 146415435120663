interface contentItems {
  title: string;
  description: string;
}

const Content = ({ title, description }: contentItems) => {
  return (
    <div className="relative pb-5 ">
      <div className=" before:content-[''] before:size-2.5 before:bg-blue-primary before:rounded-full before:absolute before:-left-6 before:top-1.5 mb-2 before:z-[999999999]  ">
        <h4 className="font-bold text-[#333] text-[15px] font-poppins ">
          {title}
        </h4>
      </div>
      <div className="before:content-[''] before:size-2.5 before:border-l before:border-[#c4c4c4] before:absolute before:-left-[19.5px] before:h-full before:top-0 before:bottom-0  ">
        <h1 className="text-[#333] text-sm !leading-6 font-poppins">
          {description}
        </h1>
      </div>
    </div>
  );
};

const Info = () => {
  return (
    <div className="container mx-auto px-20  py-10">
      <Content
        title="Püf Noktaları"
        description='Özgeçmişin en fazla 2 sayfa olmalı. (Akademik CV daha uzun olabilir.) Gereksiz bilgiler eklemekten açının.Uzun paragraflardan kaçınmalısın. (CV incelemelerine en fazla 1-3 dakika süre ayrılıyor) Birinci ya da üçüncü tekil kişi ağzıyla yazmalısın.Genellikle "Times New Roman" veya "Arial" gibi kolay okunabilen karakterler kullanmalı, 11 ya da 12 punto ile yazmalısın.Kelime ve cümlelerin altını çizmemelisin. (İnternet adresleri hariç) İmla kurallarına dikkat etmelisin. Başvurduğun pozisyona uygun bir ön yazı hazırlamalısın. Genel başvuru yapıyorsan, ön yazında hangi alanda çalışmak istediğinizi belirtmelisin. Takım elbiseli çekilmiş fotoğraflar eklemelisin.Silik, bulanık, arka planı karışık fotoğraflar eklememelisin. E-posta adresine özen göster, isim soyadından oluşan ciddi, telefonda da kodlaması kolay bir e-posta adresi kullanmalısın.'
      />
      <Content
        title="Kariyer Hedefi"
        description="Başvurduğun pozisyona uygun özelliklerinizi anlatabileceğin, kendini tanıtacağın ve başvuru amacınızı belirteceğin çok kısa bir paragraf yazmalısın."
      />
      <Content
        title="Kariyer Hedefi"
        description="Başvurduğun pozisyona uygun özelliklerinizi anlatabileceğin, kendini tanıtacağın ve başvuru amacınızı belirteceğin çok kısa bir paragraf yazmalısın."
      />
      <Content
        title="Kişisel Bilgiler"
        description="Adını, açık adresini, sana ulaşılabilecek telefon numaralarını ve e-mail adresini özgeçmişinin başına mutlaka yazmalısın. Doğum tarihi, doğum yeri, medeni durum gibi bilgiler mutlaka yazılmalı.Uyruğunu yurt dışındaki işlere başvuru yaparken yazabilirsin."
      />
      <Content
        title="Eğitim Bilgileri"
        description="Mezun olduğun programları ters kronolojik sıra ile yazmalısın.Okurken aldığın dereceleri ya da önemli başarıları ve genel not ortalamanı belirtebilirsin.Yeni mezunsan bu bölüm başta olmalı ama iş deneyimine sahipsen bu bölümü iş deneyimi bölümünün altına yazabilirsin."
      />
      <Content
        title="İş/Staj Deneyimi"
        description="Ters kronolojik sıra ile iş ve staj deneyimlerini yazmalısın.Çalıştığın yerdeki pozisyonunu belirterek her birinin altına sorumluluklarını ve genel olarak yaptığın işleri kısaca listelemelisin.Başarılarını özellikle vurgulamalısın."
      />
      <Content
        title="Beceriler"
        description="Bildiğin yabancı dilleri ve bilgisayar programlarını belirtebilirsin.Birkaç program ya da yabancı dil biliyorsan yanlarına hangi seviyede bildiğini ekleyebilirsin.Bunların dışında başvurduğun pozisyon özelinde yazılabilecek becerilerini yazabilirsin.Mesleğinizle ilgili profesyonel kuruluşlara üye iseniz bunları yazmanız sizin içinEğer pozisyon için gerekli ise ek bilgi başlığı altında sürücü belgenin olduğunu belirtebilirsin"
      />
      <Content
        title="Eğitimler/Sertifikalar"
        description="Katıldığın mesleki kurs ve eğitimleri, sertifikalarını uygun başlıklar altında belirtebilirsin."
      />
      <Content
        title="Referanslar"
        description='İki referans yazılabilir.Yeni mezunsan referanslarının biri hocandan, biri de stajyer olarak bağlı çalıştığın yöneticinden olabilir.Diğer seçenek ise, "Talep edildiğinde verilecektir" ibaresini eklemektir.Eğer referansın yoksa bu bölümü eklemeyebilirsin.Sizi çok yakından tanımayan kişileri referans olarak göstermeyin ve referans gösterdiğiniz kişilere önceden haber verin.'
      />
    </div>
  );
};

export default Info;
