import React, { useContext, useEffect, useState } from "react";
import { SelectComponent } from "../../../components/select-component-new";
import useFetchCdnQuery from "../../../../../../../../hooks/useFetchCdnQuery";
import { SearchComponent } from "../../../components/search-component-new";
import {
  CandidatePoolContext,
  CandidatePoolFilterProps,
} from "../../../../../index";

interface LanguagesSectionProps {
  isVisible: boolean;
}

const LanguagesSection: React.FC<LanguagesSectionProps> = ({ isVisible }) => {
  const { newSelectedValues, setNewSelectedValues } =
    useContext(CandidatePoolContext);

  //LANGUAGE SELECT DATA
  const [languageData, setLanguageData] = useState<any>([]);
  const [languageSearchterms, setLanguageSearchTerms] = useState("");
  const languagesQuery = useFetchCdnQuery(
    "languages",
    "get-general-languages",
    "general_languages",
    languageSearchterms
  );
  useEffect(() => {
    if (languagesQuery.data !== undefined) {
      setLanguageData([...languagesQuery?.data]);
    }
  }, [languagesQuery.data, languagesQuery.fetchStatus]);

  // Language levels data
  const languageLevelData = [
    { label: "Başlangıç", id: 1 },
    { label: "Orta", id: 2 },
    { label: "İyi", id: 3 },
    { label: "Çok İyi", id: 4 },
  ];

  return (
    <section
      className={`flex flex-col gap-5 mx-2 my-8 text-gray-600 md:flex-row md:w-2/3 ${
        isVisible ? "" : "hidden"
      }`}
    >
      <SearchComponent
        isFetching={languagesQuery.isFetching}
        keyString={"name"}
        data={languageData}
        label="Yabancı Dil"
        onChange={(value) => {
          let newValues: CandidatePoolFilterProps = {
            ...newSelectedValues,
            language: value,
          };
          if (value === 0) {
            delete newValues.language;
          }
          setNewSelectedValues(newValues);
        }}
        selectedvalue={newSelectedValues.language}
        setSearchData={setLanguageSearchTerms}
        searchData={languageSearchterms}
      />

      <SelectComponent
        disabled={newSelectedValues.language === undefined}
        placeholder={
          newSelectedValues.language !== undefined
            ? "Seçiniz"
            : "Önce Dil Seçiniz"
        }
        keyString={"label"}
        data={languageLevelData}
        label="Yabancı Dil Seviyesi"
        onChange={(value) => {
          let newValues: CandidatePoolFilterProps = {
            ...newSelectedValues,
            language_level: value as number,
          };
          if (value === 0) {
            delete newValues.language_level;
          }
          setNewSelectedValues(newValues);
        }}
        selectedvalue={newSelectedValues.language_level}
      />
    </section>
  );
};

export { LanguagesSection };
