import React from "react";
import { BuyDopingSection } from "./buy-doping-section";
import { FilterSection } from "./filter-section";
import { AdsSection } from "./ads-section";
import { HeaderCardItemsSection } from "./header-card-items-section";
import Axios from "../../services/axios";
import { CookieName } from "../../constants/enums";
import { useCookie } from "../../hooks/useCookie";
import { Pagination } from "../../components/isbul-ui/pagination";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import PaginationWeb from "../job-page-container/main/advert-section-body/components/pagination";
import PaginationMobile from "../job-page-container/main/advert-section-body/components/mobile-pagination";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

const axios = new Axios();

interface City {
  readonly id: number;
  readonly name: string;
}

interface Ad {
  readonly id: number;
  readonly title: string;
  readonly cities: City[];
  readonly created_at: string;
  readonly updated_at: string;
  readonly publish_date: string;
  readonly end_date: string;
  readonly status: string;
  readonly is_active: number;
  readonly advert_applies_count: number;
  readonly today_advert_applies_count: number;
  readonly slug: string;
  readonly is_use_doping: number;
  readonly is_draft: number;
  readonly doping_end_date: string;
}

export interface PaginationData {
  total: number;
  current_page: number;
  last_page: number;
  per_page: number;
  prev_page_url: string | null;
  next_page_url: string | null;
  first_page_url: string;
  last_page_url: string;
  from: number;
  to: number;
  path: string;
  has_more_pages: boolean;
  on_first_page: boolean;
  has_pages: boolean;
  count: number;
  data: Ad[];
}

function CompanyPageContainer(): React.ReactElement {
  const [adsData, setAdsData] = React.useState<Ad[]>([]);
  const [filteredAdsData, setFilteredAdsData] = React.useState<Ad[]>([]);
  const [paginationData, setPaginationData] =
    React.useState<PaginationData | null>(null);
  const { getCookie } = useCookie();
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [FindValue, setFindValue] = React.useState<string | number>("");
  const companyUserToken = getCookie(CookieName.CompanyUserToken);
  const [lastPage, setLastPage] = React.useState<number>(1);
  const [selectedStatus, setSelectedStatus] = React.useState<string>("");
  const [filterParams, setFilterParams] = React.useState<any>({}); // New state for filter parameters
  const navigate = useNavigate();

console.log(adsData)
  // boş parametreleri kaldırma

  const removeEmptyParams = (params: any) => {
    return Object.fromEntries(
      Object.entries(params).filter(([_, v]) => v != null && v !== "")
    );
  };

  // İlan verilerini getirme güncelleme filtreleme

  const fetchAdsData = async (
    page: number,
    status: string = "",
    additionalParams: any = {}
  ) => {
    try {
      const queryParams = {
        ...additionalParams,
        page,
        status,
        search:
          additionalParams.search !== undefined
            ? additionalParams.search
            : FindValue,
        not_show_archive: status === "ARCHIVED" ? 0 : 1,
      };

      const filteredQueryParams = removeEmptyParams(queryParams);

      console.log("queryParams", filteredQueryParams);

      const response = await axios.request({
        type: "get",
        endpoint: `companies/home/get-adverts`,
        payload: {
          headers: {
            Authorization: `Bearer ${companyUserToken}`,
          },
          params: filteredQueryParams,
        },
      });

      if (
        response &&
        response.data &&
        response.data.data &&
        response.data.data.adverts
      ) {
        const pagination = response.data.data.adverts;

        setAdsData(pagination.data);

        setPaginationData(pagination);
        setLastPage(pagination.last_page);
      }
    } catch (error) {
      console.error("Error fetching ads data:", error);
    }
  };

  React.useEffect(() => {
    if (companyUserToken) {
      fetchAdsData(currentPage, selectedStatus, filterParams);
    }
  }, [companyUserToken, currentPage, selectedStatus, filterParams]);

  // Filter change function

  const handleFilterChange = (filters: any) => {
    setFilterParams(filters);
    setCurrentPage(1); // Reset to first page when filter changes
    fetchAdsData(1, selectedStatus, filters); // Fetch data with new filters
  };

  // Search data function

  const handleSearchChange = (value: string | number) => {
    setFindValue(value);
    if (value === "") {
      fetchAdsData(1, selectedStatus, { ...filterParams, search: "" });
    } else {
      fetchAdsData(1, selectedStatus, { ...filterParams, search: value });
    }
  };

  // Filter data function

  const filterdata = () => {
    if (FindValue) {
      const srch = (query: string | number) => {
        const normalizeText = (text: string): string => {
          return text
            .toLocaleLowerCase("tr-TR")
            .replace(/ğ/g, "g")
            .replace(/ü/g, "u")
            .replace(/ş/g, "s")
            .replace(/ı/g, "i")
            .replace(/ö/g, "o")
            .replace(/ç/g, "c");
        };

        const _nmtext = (t: string | number): string => {
          if (typeof t === "string") {
            return normalizeText(t);
          }
          return t.toString();
        };

        const _nmQuery = _nmtext(query);
        const findData = adsData.filter((e) => {
          const title = _nmtext(e.title);
          return title.includes(_nmQuery);
        });

        setFilteredAdsData(findData);
      };

      srch(FindValue);
    } else {
      setFilteredAdsData(adsData);
    }
  };

  React.useEffect(() => {
    filterdata();
  }, [FindValue, adsData]);

  const handleChangePendingStatus = async (id: number) => {
    try {
      const response = await axios.authRequest({
        type: "post",
        endpoint: `companies/home/${id}/change-status`,
        payload: {
          new_status: "UPDATE",
        },
      });

      console.log(response);

      Swal.fire(
        "Başarılı!",
        "İlanınızı başarıyla onaya gönderdiniz.",
        "success"
      );

      fetchAdsData(1)
      //const updatedAds = adsData.filter((ad) => ad.id !== id);

      //setAdsData(updatedAds);
    } catch (error: any) {
      Swal.fire(
        "Hata!",
        error?.response?.data?.error ||
          "İlanınızı onaya gönderme işlemi başarısız oldu.",
        "error"
      );
      navigate(`/isveren/ilanlar/ilan/${id}/guncelle`);
    }
  };

  // onay süreci iptal edilmesi

  const handleDeleteChangeArchiveStatus = async (id: number) => {
    const result = await Swal.fire({
      title: "Onay Sürecini İptal Et",
      text: "İlanınızın onay süreci iptal edilecek ve pasif duruma getirilecektir. Bu işlemi onaylıyor musunuz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "İptal",
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.authRequest({
          type: "post",
          endpoint: `companies/home/${id}/change-status`,
          payload: {
            new_status: "PASSIVE",
          },
        });

        Swal.fire(
          "Başarılı!",
          "İlanınızın onay süreci başarıyla iptal edildi. İlanınız pasif durumuna getirildi.",
          "success"
        );

        setAdsData((prevAdsData) =>
          prevAdsData.map((ad) =>
            ad.id === id ? { ...ad, status: "PASSIVE" } : ad
          )
        );
      } catch (error) {
        Swal.fire(
          "Hata!",
          "İlanınızı onay süreci iptal etme işlemi başarısız oldu. Taslak durumuna alınamadı",
          "error"
        );
      }
    }
  };

  console.log(selectedStatus);

  // Function to handle page change and scroll to top
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  return (
    <div className="container mx-auto flex flex-col gap-y-2  md:gap-y-5 py-5">
      <HeaderCardItemsSection />
      <BuyDopingSection />
      <FilterSection
        onFilterChange={handleFilterChange} // Pass the handler to FilterSection
        onChangeFindValue={handleSearchChange}
        FindClick={filterdata}
        adsData={filteredAdsData}
        setAdsData={setFilteredAdsData}
        paginationData={paginationData}
        setPaginationData={setPaginationData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setLastPage={setLastPage}
        setSelectedStatus={setSelectedStatus}
      />
      <div className="flex flex-col gap-y-2">
        <AdsSection
          onChangeFindValue={handleSearchChange}
          FindClick={filterdata}
          adsData={filteredAdsData}
          setAdsData={setFilteredAdsData}
          paginationData={paginationData}
          setPaginationData={setPaginationData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setLastPage={setLastPage}
          setSelectedStatus={setSelectedStatus}
          handleChangePendingStatus={handleChangePendingStatus}
          handleDeleteChangeArchiveStatus={handleDeleteChangeArchiveStatus}
          selectedStatus={selectedStatus}
        />

        <div className="hidden xl:flex h-12 w-full   justify-center items-center">
          {paginationData && paginationData.data.length > 0 && (
            <Pagination
              currentPage={paginationData.current_page}
              firstPageNumber={1}
              lastPageNumber={lastPage}
            >
              <Pagination.ArrowLeftButton
                onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
              />
              <Pagination.Items>
                {Array.from({ length: lastPage }, (_, i) => i + 1).map((p) => (
                  <Pagination.PageButton
                    key={`p-${p}`}
                    pageNumber={p}
                    onClick={() => handlePageChange(p)}
                  >
                    {p}
                  </Pagination.PageButton>
                ))}
              </Pagination.Items>
              <Pagination.ArrowRightButton
                onClick={() =>
                  handlePageChange(Math.min(currentPage + 1, lastPage))
                }
              />
            </Pagination>
          )}
        </div>
        
        <div className="block xl:hidden w-full">
          <div className="flex flex-row items-center gap-2 justify-center">
            {currentPage != 1 &&  (
              <button
                className="flex flex-row bg-gray-100 border rounded-md border-gray-500 p-2 items-center gap-2"
                onClick={() => {
                  handlePageChange(currentPage - 1);
                }}
              >
                <IoChevronBack />
                Önceki
              </button>
            )}

              {adsData.length > 0 && (
                <select
                  className="bg-gray-100 p-2 border border-gray-500 rounded-md"
                  value={currentPage || 1}
                  onChange={(e) => {
                    handlePageChange(parseInt(e.target.value));
                  }}
                >
                  {Array.from({ length: lastPage }, (_, index) => index + 1).map((page) => (
                    <option key={`pageNumber${page}`} value={page}>
                      {currentPage === page ? `${page}/${lastPage}` : `${page}`}
                    </option>
                  ))}
                </select>
              )}
            {currentPage != lastPage && (
              <button
                className="flex flex-row bg-gray-100 border rounded-md border-gray-500 p-2 items-center gap-2"
                onClick={() => {
                  handlePageChange(currentPage + 1);
                }}
              >
                Sonraki
                <IoChevronForward />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { CompanyPageContainer };
