import { useCookie } from "../../../../../hooks/useCookie";
import { CookieName } from "../../../../../constants/enums";
import * as React from "react";
import Axios from "../../../../../services/axios";
import { useState } from "react";

interface HeaderSectionProps {
  onAllCheckboxToggle: () => void;
  isCheckedAll: boolean;
}
const axios = new Axios();
function HeaderSection({
  onAllCheckboxToggle,
  isCheckedAll,
}: HeaderSectionProps) {
  const [cvViewCount, setCvViewCount] = useState<string>("0");
  const { getCookie } = useCookie();
  const companyUserToken = getCookie(CookieName.CompanyUserToken);
  React.useEffect(() => {
    const fetchHeaderCardItems = async () => {
      try {
        const response = await axios.request({
          type: "get",
          endpoint: "companies/home/get-count-dashboard",
          payload: {
            headers: {
              Authorization: `Bearer ${companyUserToken}`,
            },
          },
        });
        if (response && response.data && response.data.data) {
          setCvViewCount(response.data.data.right_to_cv_view_count);
        }
      } catch (error) {
        throw new Error("Error fetching header card items: " + error);
      }
    };

    fetchHeaderCardItems();
  }, [companyUserToken]);

  return (
    <div className="text-primary200 mb-1">
      <div>
        {/* Mobile View */}
        <div className="flex flex-col divide-y border-y-[1px] border-gray-400 bg-mainBg justify-between pl-2 pr-5 py-2 md:hidden ml-3 mr-1 shadow ">
          <div className={"w-full"}>
            <div className={"flex py-1 self-end "}>
              Cv Görüntüleme Hakkı:
              <span className={"text-red-700 font-bold"}>{cvViewCount}</span>
            </div>
          </div>
          <div className={"flex justify-start items-center"}>
            <input
              type="checkbox"
              checked={isCheckedAll}
              onChange={onAllCheckboxToggle}
            />
            <p className="ml-3 text-lg"> Adaylar</p>
          </div>
        </div>

        {/* Desktop View */}
        <div className="flex flex-col max-md:hidden justify-between border-y-2 border-gray-300 bg-mainBg  pl-4 pr-5 pb-4  mx-10 shadow divide-y">
          <div className={"w-full"}>
            <div className={"flex py-3 self-end "}>
              Cv Görüntüleme Hakkı:
              <span className={"text-red-700 font-bold"}>{cvViewCount}</span>
            </div>
          </div>
          <div
            className={
              "flex justify-between items-center pt-3 lg:gap-[21.5rem] md:gap-[17.5rem]"
            }
          >
            <div className="flex gap-4 ">
              <input
                type="checkbox"
                checked={isCheckedAll}
                onChange={onAllCheckboxToggle}
              />
              <p> Aday</p>
            </div>
            <div className="flex justify-between items-center flex-1">
              <p className="flex-1">Meslek / Eğitim Bilgisi</p>
              <p className="flex-1 ml-3">Son İş Deneyimi </p>
              <p>İşlemler</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { HeaderSection };
