import Axios from "../services/axios";
import { cdnDrivingLicenseSrcType } from "../types";







const axios = new Axios()


type getDrivingLicenseSrcsProps = {
    search?: string,
    limit?: number
}




export async function getDrivingLicenseSrcs({
    search,
    limit
}: getDrivingLicenseSrcsProps): Promise<cdnDrivingLicenseSrcType[]> {
    try {
        const response = await axios.request({
            endpoint: `cdn/get-driving-licence-srcs?
            ${search? `search=${search}`: ''}
            ${limit? `limit=${limit}`: ''}
            `,
            type: 'get'
        })

        if (response && response.data && response.data.driving_license_srcs) {
            return JSON.parse(JSON.stringify(response.data.driving_license_srcs))
        } else {
            return []
        }
    } catch (error) {
        console.log(`Driving license srcs fetch failed. Error: ${error}`)
        return []
    }
}