import React from "react";
import Swal from "sweetalert2";
import services from "../../../../../../services/login-services";
import { useNavigate } from "react-router-dom";
import { CustomFormField } from "../../../../custom-form-field";
import * as Yup from "yup";
import { useFormik } from "formik";



interface IUserDatas {
	readonly phone_code: string;
	readonly phone: string;
  }
  
  const validationSchema = Yup.object({
	phone_code: Yup.string().required("Bu alan zorunludur"),
	phone: Yup.string()
	  .transform(value => value.replace(/\D/g, ""))
	  .matches(/^\d{3}\s\d{3}\s\d{2}\s\d{2}$/, "Geçerli bir telefon numarası giriniz")
	  .required("Telefon numarası zorunludur"),
  });

const ForgotPasswordSMS = () => {
	const [phone, setPhone] = React.useState("");
	const [error, setError] = React.useState(false);
	const router = useNavigate();

	
	const formik = useFormik({
		initialValues: {
		  phone_code: "",
		  phone: "",
		},
		validationSchema,
		onSubmit: async (values: any) => {
		  alert(JSON.stringify(values, null, 2));
		  console.log(values);
		  const userData: IUserDatas = {
			phone_code: "+90",
			phone: values.phone.replace(/[()\s]/g, ""),
		  };
		  console.log(userData);
	
		  try {
			const response = await services.forgotPasswordSMS(userData.phone);
			if (response.status === 200) {
			  Swal.fire({
				title: "Başarılı!",
				text:
				  "Şifre sıfırlama bağlantısı telefonunuza gönderildi. Lütfen gelen bağlantıya tıklayınız.",
				icon: "success",
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
				didOpen: toast => {
				  toast.addEventListener("mouseenter", Swal.stopTimer);
				  toast.addEventListener("mouseleave", Swal.resumeTimer);
				},
			  });
			  setTimeout(() => {
				router("/profilim/giris-yap");
			  }, 5000);
			} else {
			  Swal.fire({
				title: "Hata!",
				text: response.error || "Bir hata oluştu. Lütfen tekrar deneyiniz.",
				icon: "error",
				confirmButtonText: "Tamam",
				timer: 5000,
				timerProgressBar: true,
				didOpen: toast => {
				  toast.addEventListener("mouseenter", Swal.stopTimer);
				  toast.addEventListener("mouseleave", Swal.resumeTimer);
				},
			  });
			}
		  } catch (error: any) {
			console.error("Şifre sıfırlama SMS'i gönderilirken bir hata oluştu", error);
			Swal.fire({
			  title: "Hata!",
			  text: "Girilen bilgilere ait kullanıcı bulunamadı !",
			  icon: "error",
			  showConfirmButton: false,
			  timer: 5000,
			  timerProgressBar: true,
			  didOpen: toast => {
				toast.addEventListener("mouseenter", Swal.stopTimer);
				toast.addEventListener("mouseleave", Swal.resumeTimer);
			  },
			});
		  }
		},
	  });


	const sendForgotPasswordSMS = async (e: React.FormEvent) => {
		e.preventDefault();

		if (!phone) {
			setError(true);
			return;
		}
		setError(false);
		try {
			const response = await services.forgotPasswordSMS(phone);
			if (response.status === 200) {
				Swal.fire({
					title: "Başarılı!",
					text: "Şifre sıfırlama bağlantısı telefonunuza gönderildi. Lütfen gelen bağlantıya tıklayınız.",
					icon: "success",
					showConfirmButton: false,
					timer: 5000,
					timerProgressBar: true,
					didOpen: (toast) => {
						toast.addEventListener("mouseenter", Swal.stopTimer);
						toast.addEventListener("mouseleave", Swal.resumeTimer);
					},
				});
				setTimeout(() => {
					window.location.href = "/profilim/giris-yap";
				}, 5000);
			} else {
				Swal.fire({
					title: "Hata!",
					text: response.error || "Bir hata oluştu. Lütfen tekrar deneyiniz.",
					icon: "error",
					confirmButtonText: "Tamam",
					timer: 5000,
					timerProgressBar: true,
					didOpen: (toast) => {
						toast.addEventListener("mouseenter", Swal.stopTimer);
						toast.addEventListener("mouseleave", Swal.resumeTimer);
					},
				});
			}
		} catch (error: any) {
			console.error(
				"Şifre sıfırlama SMS'i gönderilirken bir hata oluştu",
				error
			);
			Swal.fire({
				title: "Hata!",
				text: "Girilen bilgilere ait kullanıcı bulunamadı !",
				icon: "error",
				showConfirmButton: false,
				timer: 5000,
				timerProgressBar: true,
				didOpen: (toast) => {
					toast.addEventListener("mouseenter", Swal.stopTimer);
					toast.addEventListener("mouseleave", Swal.resumeTimer);
				},
			});
		}
	};

	return (
		<div className='flex flex-col gap-6 p-6 mx-auto bg-white border rounded-md shadow-lg lg:my-6'>
			<h4 className='poppins-medium text-xl md:text-2xl text-[#2253af]'>
				Şifreni mi Unuttun ?
			</h4>
			<form
				className='flex flex-col gap-y-6'
				onSubmit={formik.handleSubmit}>
				<div className='flex flex-col gap-1'>
					
					<CustomFormField
					id="phone"
						type='tel'
						label='Telefon Numaranız *'
						formik={formik}
						name='phone'
						phoneMaskType='phone'
					/>
				
				</div>
				<div className='flex flex-col'>
					<p className='mb-2 font-bold font-poppins'>
						Şifre yenileme linki ulaşmazsa
					</p>
					<ul className='space-y-2 text-xs text-gray-700 custom-list font-poppins'>
						<li>Spam ve diğer e-posta klasörlerini kontrol et.</li>
						<li>Girdiğin e-posta ve cep telefonunun doğrulunu kontrol et.</li>
						<li>Şifre yenileme e-postası ulaşması 10 dakikayı bulabilir.</li>
					</ul>
				</div>
				<button
					type='submit'
					className='flex items-center justify-center w-full h-10 rounded-2xl bg-[#1a5edd]'>
					<span className='text-base font-semibold text-white poppins-medium'>
						GÖNDER
					</span>
				</button>
			</form>

			<div className='flex flex-col items-center justify-center gap-2'>
				<p className='text-center'>isbul.net'te özgeçmişin yok mu?</p>
				<button
					onClick={() => router("/profilim/kayit-ol")}
					type='button'
					className='flex items-center border-[1px] border-transparent justify-center w-full h-full px-4 py-3 bg-white rounded-lg hover:bg-gray-200 hover:border-[1px] hover:border-title'>
					<span className='text-base font-semibold text-title poppins-medium'>
						HEMEN ÜYE OL
					</span>
				</button>
			</div>
		</div>
	);
};

export default ForgotPasswordSMS;
