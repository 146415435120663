import { PiDotsThreeVertical } from "react-icons/pi";
import { dbUserInterviewType } from "../../../../../../../types";
import { useEffect, useRef, useState } from "react";
import Axios from "../../../../../../../services/axios";

const axios = new Axios();

export default function InterviewListItem({
  getInterviews,
  interview,
}: {
  getInterviews: () => void;
  interview: dbUserInterviewType;
}) {
  const divRef = useRef<HTMLDivElement>(null);

  console.log("interview", interview);

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const getStatusColor = (status: "JOIN" | "PENDING" | "NOT_JOIN") => {
    switch (status) {
      case "JOIN":
        return "text-green-600";
      case "PENDING":
        return "text-blue-600";
      case "NOT_JOIN":
        return "text-red-600";
      default:
        return "";
    }
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setIsPopupOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex justify-between items-center  w-full border-b py-2 mt-2">
      <div className="flex md:flex-row flex-col justify-between items-center w-full gap-1 md:gap-0 basis-2/5">
        <div className="flex items-center justify-start basis-2/4 w-full flex-col md:flex-row">
          <a className="w-full text-start" href="/">
            <span className="text-sm md:text-xs poppins-semibold text-mains underline">
              {interview.company_detail.company_name}
            </span>
          </a>

          <div className="flex  md:hidden items-center justify-start md:justify-center w-full basis-1/5">
            <span
              className={`text-xs poppins-semibold mr-4 ${getStatusColor(
                interview.user_status
              )}`}
            >
              {interview.user_status === "NOT_JOIN"
                ? "İptal Edildi"
                : interview.user_status === "JOIN"
                ? "Kabul Edildi"
                : "Bekleniyor"}
            </span>
          </div>
        </div>

        <div className="hidden md:flex items-center justify-start w-full">
          <span className="text-xs poppins-semibold text-gray-600">
            <p>{interview.interview_location?.name}</p>
            {/*
                TODO: Tip uyuşmazlığının ardından düzeltilecek
                <p>
                    {interview.type == 'Local' && 'Adres: '}
                    {interview.type === "Local" && (
                    <>
                        <span className="text-gray-500">Neosho 995 Tail</span>
                    </>
                    )}
                </p> */}
            <p className="whitespace-nowrap text-xs text-gray-500 font-poppins basis-3/4 w-full flex justify-start items-center">
              {interview.day}.
              {interview.month >= 10
                ? `${interview.month}`
                : `0${interview.month}`}
              .{interview.year} {interview.hour}
            </p>
          </span>
        </div>
      </div>

      <div className="hidden md:flex items-center justify-start md:justify-center w-full basis-1/5">
        <span
          className={`text-xs poppins-semibold mr-4 ${getStatusColor(
            interview.user_status
          )}`}
        >
          {interview.user_status === "NOT_JOIN"
            ? "İptal Edildi"
            : interview.user_status === "JOIN"
            ? "Kabul Edildi"
            : "Bekleniyor"}
        </span>
      </div>

      <div className="hidden md:flex items-center justify-center w-full basis-1/5">
        <span className="text-xs poppins-semibold text-gray-600 ml-6 xl:ml-12">
          {interview.notes}
        </span>
      </div>

      <div className="text-sm relative  flex justify-end items-center md:w-full basis-1/5">
        <p className=" md:hidden whitespace-nowrap text-xs text-gray-500 font-poppins basis-3/4 w-full flex justify-end items-center">
          {interview.day >= 10 ? `${interview.day}` : `0${interview.day}`}.
          {interview.month >= 10 ? `${interview.month}` : `0${interview.month}`}
          .{interview.year} {interview.hour}
        </p>

        <button
          className="flex justify-end text-end basis-1/4"
          type="button"
          onClick={() => setIsPopupOpen(true)}
        >
          <PiDotsThreeVertical className="text-gray-900 text-xl" />
        </button>
        {isPopupOpen && (
          <div
            ref={divRef}
            className="absolute -bottom-4 right-0 translate-y-full z-[99]"
          >
            <div className="relative shadow-[0_-2px_30px_5px_rgba(26,26,26,0.3)] rounded-b-lg rounded-tl-lg">
              <div className="triangle-white top-0 -translate-y-full absolute right-0" />
              <div className="bg-white p-4 flex flex-col gap-2 rounded-b-lg rounded-tl-lg">
                <button
                  className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap"
                  onClick={acceptJoin}
                >
                  Katılacağım
                </button>
                <hr />
                <button
                  className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap"
                  onClick={rejectJoin}
                >
                  Katılmayacağım
                </button>
                <hr />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  async function acceptJoin() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/interview/${interview.id.toString()}/change-status`,
        type: "put",
        payload: {
          status: "JOIN",
        },
      });

      if (response && response.status == 200) {
        getInterviews();
      } else {
      }
    } catch (error) {
      console.log(`Interview accept failed. Error: ${error}`);
    }
    setIsPopupOpen(false);
  }

  async function rejectJoin() {
    try {
      const formData = new FormData();

      formData.append("status", "NOT_JOIN");
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/interview/${interview.id.toString()}/change-status`,
        type: "put",
        payload: {
          status: "NOT_JOIN",
        },
      });

      if (response && response.status == 200) {
        getInterviews();
      } else {
      }
    } catch (error) {
      console.log(`Interview accept failed. Error: ${error}`);
    }
    setIsPopupOpen(false);
  }
}
