import { Route, Routes } from "react-router-dom";
import { MenuNavbar } from "./menu-navbar";
import { CorporateContent } from "./corporate-content";

function CorporatePageContainer(): React.ReactElement {
  return (
    <div className="container mx-auto min-h-screen flex flex-col lg:flex-row gap-x-5 justify-between">
      <div className="w-full lg:hidden mb-4">
        <MenuNavbar />
      </div>
      <div className="flex-1 w-full lg:w-3/4">
        <Routes>
          <Route path="/:slug" element={<CorporateContent />} />
          {/* You can add more static or dynamic routes as needed */}
        </Routes>
      </div>
      <div className="hidden lg:block lg:w-1/4">
        <MenuNavbar />
      </div>
    </div>
  );
}

export { CorporatePageContainer };
