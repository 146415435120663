import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdKeyboardArrowRight } from "react-icons/md";
import { CustomDropdown } from "../../../components/common/custom-dropdown";
import { useIsMobile } from "../../../hooks/useIsMobile";

const menuItems = [
	{ path: "/kurumsal/kullanim-kosullari", label: "Kullanım Koşulları" },
	{
		path: "/kurumsal/kredi-karti-saklama-kosullari",
		label: "Kredi Kartı Saklama Koşulları",
	},
	{ path: "/kurumsal/gizlilik-sozlesmesi-4", label: "Gizlilik Sözleşmesi" },
	{ path: "/kurumsal/uyelik-sozlesmesi-5", label: "Üyelik Sözleşmesi" },
	{ path: "/kurumsal/cerezlerin-kullanimi-6", label: "Çerezlerin Kullanımı" },
	{ path: "/kurumsal/kalite-politikasi-7", label: "Kalite Politikası" },
	{ path: "/kurumsal/kvkk-metni-8", label: "KVKK Metni" },
	{ path: "/kurumsal/kullanim-kosullari-9", label: "Kullanım Koşulları" },
	{
		path: "/kurumsal/on-bilgilendirme-formu-10",
		label: "Ön Bilgilendirme Formu",
	},
	{
		path: "/kurumsal/mesafeli-satis-sozlesmesi-11",
		label: "Mesafeli Satış Sözleşmesi",
	},
	{
		path: "/kurumsal/kurumsal-uyelik-sozlesmesi",
		label: "Kurumsal Üyelik Sözleşmesi",
	},
	{ path: "/kurumsal/acik-riza-metni", label: "Açık Rıza Metni" },
];

interface IMenuItemProps {
	readonly path: string;
	readonly label: string;
}

const MenuItem = (props: IMenuItemProps): React.ReactElement => (
	<li className='flex items-center gap-x-2'>
		<MdKeyboardArrowRight />
		<Link to={props.path}>{props.label}</Link>
	</li>
);

function MenuNavbar(): React.ReactElement {
	const navigate = useNavigate();
	const { isMobile } = useIsMobile();

	return (
		<>
			{isMobile ? (
				<div className='w-full whitespace-nowrap'>
					<CustomDropdown
						title='Menü'
						content={menuItems.map((item) => item.label)}
						onItemSelect={(selectedItem) => {
							const item = menuItems.find(
								(item) => item.label === selectedItem
							);
							if (item) {
								navigate(item.path);
							}
						}}
					/>
				</div>
			) : (
				<nav className='flex flex-col w-full gap-y-1 text-mains poppins-semibold'>
					<div className='p-5 bg-white rounded-sm shadow-sm poppins-semibold'>
						<h2>MENÜ</h2>
					</div>
					<ul className='flex flex-col p-5 text-base bg-white rounded-sm shadow-sm gap-y-2 poppins-medium'>
						{menuItems.map((item) => (
							<MenuItem
								key={item.path}
								path={item.path}
								label={item.label}
							/>
						))}
					</ul>
				</nav>
			)}
		</>
	);
}

export { MenuNavbar };
