import React, { useEffect, useState } from "react";
import Breadcrump from "../../../../../components/common/profile/breadcrump";

//Icon's
import { TbBellRinging } from "react-icons/tb";
import { TbDotsVertical } from "react-icons/tb";
import Axios from "../../../../../services/axios";
import { IoMdArrowDropup } from "react-icons/io";
import Swal from "sweetalert2";
import { FiAlertCircle } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { Button, Checkbox, Modal, Select } from "antd";
import { IoSearchCircle } from "react-icons/io5";

interface SavedFilter {
  id: number;
  title: string;
  params_string: string;
  params: string;
  params_array: {
    disabled: string;
    advert_date: string;
    positions: number[];
    create_alarm: string;
    period: string;
    search_type: string;
  };
  send_emails: number;
  period: FilterPeriodEnum;
}

enum FilterPeriodEnum {
  "DAILY" = "DAILY",
  "WEEKLY" = "WEEKLY",
  "MONTHLY" = "MONTHLY",
}
enum FilterPeriod {
  "DAILY" = "Günlük",
  "WEEKLY" = "Haftalık",
  "MONTHLY" = "Aylık",
}

const Body: React.FC = () => {
  const axios = new Axios();

  const [savedFilters, setSavedFilters] = useState<SavedFilter[]>([]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [hoveredCompany, setHoveredCompany] = useState<number | null>(null);
  const [selectedPeriod, setSelectedPeriod] = useState<FilterPeriodEnum>();
  const [currentFilter, setCurrentFilter] = useState<SavedFilter | null>(null);

  const showModal = (filter: SavedFilter) => {
    setCurrentFilter(filter);
    setIsCheckboxChecked(filter.send_emails === 1);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSweetAlert = async () => {
    Swal.fire({
      icon: "success",
      text: "E-posta tercihiniz başarıyla güncellendi!",
      confirmButtonText: "Tamam",
      customClass: {
        confirmButton: "sweet-alert-button",
      },
    });

    handleCancel();
  };
  const getSavedFilters = async () => {
    try {
      const response = await axios.request({
        type: "get",
        endpoint: "user/profilim/kayitli-aramalar/",
      });
      if (response.status === 200) {
        setSavedFilters(response.data.data);
      }
    } catch (error) {
      console.error("Failed to fetch saved filters", error);
    }
  };

  const handleCheckboxChange = (e: any) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const handleDeleteSaved = async (id: number, title: string) => {
    Swal.fire({
      title: "İşlemi Onaylayın",
      text: "Kayıtlı filtrelemeyi silmek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet",
      cancelButtonText: "İptal",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const params = {
            create_alarm: "on",
            period: "MONTHLY",
            city_id: "83",
            search_type: "all",
            position: ["8454"],
            advert_date: "all",
            country: ["1"],
            disabled: "all",
          };

          const response = await axios.userAuthRequest({
            type: "delete",
            endpoint: `user/profilim/kayitli-aramalar/delete/${id}`,
            payload: {},
          });

          if (response.status === 200) {
            setSavedFilters(savedFilters.filter((filter) => filter.id !== id));
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }

        Swal.fire({
          title: "Başarılı!",
          text: "Filtreleme başarıyla silindi.",
          icon: "success",
          confirmButtonText: "Tamam",
        });
      }
    });
  };

  const handleUpdateFilter = async () => {
    if (!currentFilter) return;
    try {
      const response = await axios.request({
        type: "put",
        endpoint: "user/profilim/kayitli-aramalar/update/" + currentFilter.id,
        payload: {
          title: currentFilter.title,
          send_emails: isCheckboxChecked ? 1 : 0, // 0-1 (eğer 1 verilirse belirlennen perioda göre iş alalrm oluşturulmuş olur, eğer 0 verilirse sadecefiltre kayıt edilmiş olur)
          period: selectedPeriod || FilterPeriodEnum.DAILY,
        },
      });
      if (response.status) {
        setSelectedPeriod(FilterPeriodEnum.DAILY);
        setIsCheckboxChecked(false);
        getSavedFilters();
        handleSweetAlert();
      }
    } catch (error) {
      console.error("Error updating filter:", error);
    }
  };

  useEffect(() => {
    getSavedFilters();
  }, []);

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedPeriod(FilterPeriodEnum.DAILY);
      setIsCheckboxChecked(false);
      setCurrentFilter(null);
    }
  }, [isModalOpen]);

  return (
    <div className="w-full flex flex-col gap-y-6">
      <Breadcrump type="page" pageName="KAYITLI FİLTRELER" />

      {savedFilters.length > 0 && (
        <>
          <div className="w-full bg-white flex flex-col p-5 rounded-md shadow-md">
            {savedFilters.map((filter, index) => (
              <>
                <div
                  key={`${index}-pc`}
                  className="bg-mainBg py-5 px-2 mb-3 card-shadow md:flex hidden justify-between "
                >
                  <div>
                    <a
                      href={`/is-ilanlari/${filter.params_string}`}
                      className="text-paragraph text-sm poppins-regular"
                    >
                      {filter.title}
                    </a>
                  </div>
                  <div className="flex items-center justify-between gap-[10px] relative w-5/12">
                    <span
                      onClick={() => showModal(filter)}
                      className="  text-gray-600 font-poppins text-xs flex justify-center items-center gap-1 cursor-pointer whitespace-nowrap"
                    >
                      <MdOutlineNotificationsActive className="text-gray-600 text-base" />
                      {filter.send_emails === 1
                        ? `${
                            FilterPeriod[filter.period]
                          } E-posta almak istiyorum`
                        : "E-posta almak istemiyorum"}
                    </span>
                    {/* TODO:@celikdev Dikey üç nokta Dropdown componenti yapılacak */}
                    {/* TODO: @celikdev Dropdown içindeki göster href'ine filter */}
                    <div
                      className="relative w-full flex justify-end items-center max-w-[40px] xl:max-w-[80px]"
                      onMouseEnter={() => setHoveredCompany(filter?.id)}
                      onMouseLeave={() => setHoveredCompany(null)}
                    >
                      <TbDotsVertical className="text-mains text-2xl cursor-pointer" />
                      {hoveredCompany === filter?.id && (
                        <div
                          className="absolute -right-2 top-6 mt-2 w-40 bg-white border border-gray-200 rounded-md shadow-lg z-10 p-2"
                          onMouseEnter={() => setHoveredCompany(filter?.id)}
                          onMouseLeave={() => setHoveredCompany(null)}
                        >
                          <IoMdArrowDropup className="text-white text-5xl absolute top-[-26px] -right-[4px]" />

                          <a
                            href={`/is-ilanlari/${filter.params_string}`}
                            className="px-4 py-2 text-sm hover:bg-mains hover:text-white cursor-pointer border text-mains duration-300 rounded w-full flex justify-start items-center"
                          >
                            Göster
                          </a>

                          <div
                            className="px-4 py-2 text-sm hover:bg-mains hover:text-white cursor-pointer border text-mains duration-300 rounded mt-1"
                            onClick={() => {
                              handleDeleteSaved(filter?.id, filter?.title);
                            }}
                          >
                            Sil
                          </div>
                        </div>
                      )}
                    </div>
                    {/** Dropdown */}
                    <div className="hidden absolute right-1 pt-1 z-0">
                      <div className="relative min-w-[200px] p-3 z-[999] bg-red-400 ">
                        Card
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  key={`${index}-mb`}
                  className="bg-mainBg p-5 mb-3 card-shadow flex flex-col md:hidden justify-between gap-2"
                >
                  <div className="flex justify-start items-center gap-2">
                    <a
                      href={`/is-ilanlari/${filter.params_string}`}
                      className=" text-sm poppins-regular text-mains flex justify-start items-center gap-2"
                    >
                      <IoSearchCircle className="text-blue-300 text-3xl" />
                      {filter.title}
                    </a>
                  </div>

                  <div className=" !ml-6px md:ml-0 flex items-center gap-[10px] relative w-full">
                    <span
                      onClick={() => showModal(filter)}
                      className=" !pl-6px md:pl-0 text-gray-600 font-poppins text-xs flex justify-center items-center gap-1 cursor-pointer whitespace-nowrap"
                      style={{ marginLeft: '6px' }}
                    >
                      <MdOutlineNotificationsActive className=" ml-6px text-gray-600 text-base !ml-6px md:ml-0 "  />
                      {filter.send_emails === 1
                        ? `${
                            FilterPeriod[filter.period]
                          } E-posta almak istiyorum`
                        : "E-posta almak istemiyorum"}
                    </span>

                    <div className="w-full flex justify-end">
                      <IoMdClose
                        className="text-gray-400 text-2xl cursor-pointer"
                        onClick={() =>
                          handleDeleteSaved(filter?.id, filter?.title)
                        }
                      />
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
          <Modal
            title={
              <span style={{ textTransform: "none" }}>
                E-posta Tercihini Güncelle
              </span>
            }
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
              <div className="w-full flex justify-end border-t border-color-[#dee2e6] px-[24px] py-[12px] gap-4">
                <Button
                  className="bg-gray-200 text-black font-poppins text-sm"
                  key="Kapat"
                  onClick={handleCancel}
                >
                  Kapat
                </Button>
                <Button
                  className="bg-[#2253af] text-white font-poppins text-sm"
                  key="Kaydet"
                  onClick={handleUpdateFilter}
                >
                  Kaydet
                </Button>
              </div>,
            ]}
          >
            <div className="space-y-4">
              <div>
                <p className="text-sm font-poppins font-normal text-black">
                  İlgili firmalara ait yeni iş ilanlarını e-posta ile
                  alabilirsiniz.
                </p>
              </div>

              <div>
                <Checkbox
                  checked={isCheckboxChecked}
                  onChange={handleCheckboxChange}
                >
                  Uygun iş ilanlarını e-posta olarak gönder.
                </Checkbox>
              </div>

              {isCheckboxChecked && (
                <div>
                  <p className="text-gray-400 font-poppins text-sm mb-[2px]">
                    E-posta Gönderim Sıklığı{" "}
                    <span className="text-red-500">*</span>
                  </p>
                  <Select
                    defaultValue={FilterPeriodEnum.DAILY}
                    className="w-full"
                    onChange={setSelectedPeriod}
                    style={{ height: "40px" }}
                    options={[
                      {
                        value: FilterPeriodEnum.DAILY,
                        label: "Günlük E-posta Gönder",
                      },
                      {
                        value: FilterPeriodEnum.WEEKLY,
                        label: "Haftalık E-posta Gönder",
                      },
                      {
                        value: FilterPeriodEnum.MONTHLY,
                        label: "Aylık E-posta Gönder",
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </Modal>
        </>
      )}

      {savedFilters.length === 0 && (
        <>
          <div className="flex items-center shadow-lg border rounded-xl bg-white">
            <span className="bg-[red] border border-blue mr-4 h-full text-white pt-3 pb-3 pl-1 pr-1 rounded-md">
              <FiAlertCircle size={35} />
            </span>

            <p className="font-poppins poppins-semibold text-sm flex justify-center items-center gap-[6px]">
              <span>Kayıtlı filtre bulunamadı</span>
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default Body;
