import * as React from "react";
import { Dispatch, useState, SetStateAction } from 'react'
import { HeaderCard } from "./header-card/index";
import { StepsPointsCard } from './steps-poins-card/index'
import {AddHint} from "./add-hint"
import AdvertCreateForm from "./advert-create-form/index";
import { createContext } from "react";
import { Badge } from '../../../components/isbul-ui'
import { IoPeople } from "react-icons/io5";



function CreatAdd(): React.ReactElement {

  const [mobileActivePage, setMobileActivePage] = useState<number>(1)

  return (
    <div className="container mx-auto flex flex-col gap-y-0 pb-20 lg:pb-0">
      <HeaderCard />
      <StepsPointsCard
      mobileActivePage={mobileActivePage}
      />
      {/* <AddHint/> */}
      <AdvertCreateForm
      mobileActivePage={mobileActivePage}
      setMobileActivePage={setMobileActivePage}
      />
    </div>
  );
}
export { CreatAdd };
