import React from "react";
import ContentLoader from "react-content-loader";

const InfoSkeleton = () => (
  <div className="relative w-full rounded-md lg:rounded-none !border !border-gray-300 lg:!border-transparent h-[96px] lg:h-32 px-2 md:px-4 md:py-2 gap-x-2 md:gap-x-5 flex hover-border-animation lg:shadow-md">
    <ContentLoader
      speed={2}
      width="100%"
      height={160}
      viewBox="0 0 100% 160"
      backgroundColor="#f3f3f3"
      foregroundColor="#fff"
      className="w-full hidden md:block"
    >
      {/* Checkbox and Profile Image */}
      {/* Checkbox */}
      <rect x="2%" y="10%" rx="2" ry="2" width="81" height="81" />{" "}
      {/* Profile image */}
      {/* Text placeholders for both mobile and desktop */}
      <rect x="20%" y="30" rx="3" ry="3" width="20%" height="10" />{" "}
      {/* Name and Surname */}
      <rect x="20%" y="45" rx="3" ry="3" width="15%" height="10" />{" "}
      {/* Gender/Age */}
      <rect x="20%" y="60" rx="3" ry="3" width="25%" height="10" />
    </ContentLoader>
    <ContentLoader
      speed={2}
      width="100%"
      height={130}
      viewBox="0 0 100% 160"
      backgroundColor="#f3f3f3"
      foregroundColor="#fff"
      className="w-full md:hidden"
    >
      {/* Checkbox and Profile Image */}
      {/* Checkbox */}
      <rect x="2%" y="15%" rx="2" ry="2" width="55" height="55" />{" "}
      {/* Text placeholders for both mobile and desktop */}
      <rect x="20%" y="30" rx="3" ry="3" width="20%" height="10" />{" "}
      {/* Name and Surname */}
      <rect x="20%" y="45" rx="3" ry="3" width="15%" height="10" />{" "}
      {/* Gender/Age */}
      <rect x="20%" y="60" rx="3" ry="3" width="25%" height="10" />
    </ContentLoader>
  </div>
);

const SkeletonContainer = ({ pageLimit }: any) => {
  const skeletonCount = parseInt(pageLimit);

  return (
    <div className="flex flex-col gap-3 ">
      {Array.from({ length: skeletonCount }, (_, index) => (
        <InfoSkeleton key={index} />
      ))}
    </div>
  );
};

export default SkeletonContainer;
