import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { cdnExperienceLevelType } from "../../../types";
import {
  Button,
  InputArea,
  SelectDropdown,
} from "../../../components/isbul-ui";
import Axios from "../../../services/axios";
import { getExperienceLevels } from "../../../customHooks/getExperienceLevels";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

type workingStatusType = {
  id: number;
  title: string;
  title_en: string;
};

type payloadDataType = {
  job_title: string | null;
  work_status: number | null;
  experience_level_id: number | null;
  information: string | null;
};

const axios = new Axios();

const workingStatus: workingStatusType[] = [
  { id: 0, title: "Çalışmıyor", title_en: "Not Working" },
  { id: 1, title: "Çalışıyor", title_en: "Working" },
];

export default function JobPreferencesInformation() {
  const navigate = useNavigate();

  // General states
  const [workingExperiences, setWorkingExperiences] = useState<
    cdnExperienceLevelType[]
  >([]);

  // Form states
  const [jobTitle, setJobTitle] = useState<string>("");
  const [selectedWorkStatus, setSelectedWorkStatus] =
    useState<workingStatusType | null>(null);
  const [selectedWorkExperience, setSelectedWorkExperience] =
    useState<cdnExperienceLevelType | null>(null);
  const [information, setInformation] = useState<string>("");

  async function getPersonalInformation() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: "user/profilim/welcome/setup",
        type: "get",
        payload: {},
      });

      //console.log('response', response)
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  }

  async function getWorkExperiencesFunction() {
    const response = await getExperienceLevels({ limit: 30 });
    setWorkingExperiences(response);
  }

  useEffect(() => {
    Promise.all([getPersonalInformation(), getWorkExperiencesFunction()]);
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    getFieldState,
  } = useForm<payloadDataType>();

  /* Set form states */
  // Job title
  useEffect(() => {
    setValue("job_title", jobTitle);
    clearErrors("job_title");
  }, [jobTitle]);

  // Working status
  useEffect(() => {
    setValue("work_status", selectedWorkStatus?.id ?? null);
    clearErrors("work_status");
  }, [selectedWorkStatus]);

  // Job experience
  useEffect(() => {
    setValue("experience_level_id", selectedWorkExperience?.id ?? null);
    clearErrors("experience_level_id");
  }, [selectedWorkExperience]);

  // Information
  useEffect(() => {
    setValue("information", information);
    clearErrors("information");
  }, [information]);

  /* Custom registers */
  // Job title
  register("job_title", {
    required: "Mesleğiniz / Unvanınız",
  });

  // Working status
  register("work_status", {
    required: "Çalışma Durumu",
  });

  // Work experince
  register("experience_level_id", {
    required: "Toplam İş Deneyimi",
  });

  // Information
  register("information", {
    required: "Kişisel ve Mesleki Bilgiler",
  });

  const onSubmit: SubmitHandler<payloadDataType> = (data) => {
    updateJobPreferences(data);
  };

  function onError() {
    const errorArray = Object.entries(errors).map(([type, message]) => {
      return message.message;
    });

    Swal.fire({
      icon: "warning",
      title: "Uyarı",
      html: `
            <div>
            Eksik Alanlar: <br/>
            <p class="text-red-600">
            ${errorArray.join("<br/>")}
            </p>
            </div>
            `,
    });
  }
  return (
    <div className="flex flex-col bg-white rounded-md gap-y-8">
      <span className="poppins-semibold text-sm text-mains py-4 border-b border-mains px-4 w-full">
        İş Tercihleri
      </span>
      <form
        className="flex flex-col gap-y-6 p-6"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Job title */}
          <InputArea
            className="w-full"
            required
            mode={jobTitle.length == 0 ? "empty" : "dirty"}
            error={errors.job_title?.message ? true : false}
          >
            <InputArea.Label>Mesleğiniz / Unvanınız</InputArea.Label>
            <InputArea.Input
              value={jobTitle || ""}
              onChange={(e) => {
                setJobTitle(e.target.value);
              }}
            />
          </InputArea>

          {/* Working status */}
          <SelectDropdown
            className="w-full"
            required
            mode={selectedWorkStatus ? "dirty" : "empty"}
            error={errors.experience_level_id?.message ? true : false}
          >
            <SelectDropdown.Label>Çalışma Durumu</SelectDropdown.Label>
            <SelectDropdown.Trigger>
              {selectedWorkStatus?.title ?? "Seçiniz"}
            </SelectDropdown.Trigger>
            <SelectDropdown.Items>
              {workingStatus.map((w) => {
                return (
                  <SelectDropdown.Item
                    key={`ws${w.id}`}
                    onClick={() => {
                      setSelectedWorkStatus(w);
                    }}
                  >
                    {w.title}
                  </SelectDropdown.Item>
                );
              })}
            </SelectDropdown.Items>
          </SelectDropdown>

          {/* Experience level */}
          <SelectDropdown
            className="w-full"
            required
            mode={selectedWorkExperience ? "dirty" : "empty"}
            error={errors.experience_level_id?.message ? true : false}
          >
            <SelectDropdown.Label>Toplam İş Deneyimi</SelectDropdown.Label>
            <SelectDropdown.Trigger>
              {selectedWorkExperience?.title ?? "Seçiniz"}
            </SelectDropdown.Trigger>
            <SelectDropdown.Items>
              {workingExperiences.map((w) => {
                return (
                  <SelectDropdown.Item
                    key={`we${w.id}`}
                    onClick={() => {
                      setSelectedWorkExperience(w);
                    }}
                  >
                    {w.title}
                  </SelectDropdown.Item>
                );
              })}
            </SelectDropdown.Items>
          </SelectDropdown>
        </div>
        <div className="flex">
          {/* Information */}
          <InputArea
            required
            mode={information.length == 0 ? "empty" : "dirty"}
            className="w-full"
            error={errors.information?.message ? true : false}
          >
            <InputArea.Label>Kişisel ve Mesleki Bilgiler</InputArea.Label>
            <label className="text-[11px] font-poppins">
              Eğitim bilgilerinizi, iş deneyimlerinizi,yekinliklerinizi ve diğer
              bilgilerinizi firmalar tarafından dikkat çekebilmek için özet
              halinde ekleyebilirsiniz.
            </label>
            <CKEditor
              editor={ClassicEditor}
              onReady={() => {}}
              data={information}
              onChange={(event, editor) => {
                setInformation(editor.getData());
              }}
            />
            <label className="text-gray-600 text-sm">
              {3000 - information.length}
            </label>
          </InputArea>
        </div>
        <hr />
        <div className="flex flex-row items-center justify-between">
          <Button
            type="button"
            variant="secondary"
            size="sm"
            onClick={() => {
              navigate("/profilim/hosgeldin/kisisel-bilgiler");
            }}
          >
            <Button.Icon>
              <IoChevronBack />
            </Button.Icon>
            Geri Dön
          </Button>
          <Button type="submit" size="sm">
            Kaydet ve Devam Et
            <Button.Icon>
              <IoChevronForward />
            </Button.Icon>
          </Button>
        </div>
      </form>
    </div>
  );

  async function updateJobPreferences(data: payloadDataType) {
    try {
      const response = await axios.userAuthRequest({
        endpoint: "user/profilim/welcome/job-preferences",
        type: "post",
        payload: data,
      });

      if (response && response.data && response.data.status_code == 200) {
        navigate("/profilim/cv");
      } else {
        Swal.fire({
          icon: "warning",
          title: "Hata",
          text: "İş tercihleri güncellenirken bir hata meydana geldi",
        });
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      Swal.fire({
        icon: "warning",
        title: "Hata",
        text: "İş tercihleri güncellenirken beklenmedi bir hata meydana geldi",
      });
    }
  }
}
