import React, { useEffect, useRef, useState } from "react";
import { IoLocation } from "react-icons/io5";
import { MdOutlineMail, MdOutlinePhoneEnabled } from "react-icons/md";
import services from "../../../../../services/profile-services/index";
import { useParams } from "react-router-dom";
import ProfileHeader from "./profileHeader";
import SideBar from "./sideBare";
import Details from "./details";
import { IReview } from "./types/reviewCv";
import MobileDownloadAndShare from "./components/MobileDownloadAndShare";

const CvDetails: React.FC = () => {
  const id = useParams();
  const slug = id.slug;
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<IReview>();
  const cvHtml = useRef(null);

  useEffect(() => {
    const fetchIncompleteDetail = async () => {
      try {
        const data = await services.cvDetail(slug);
        setData(data.data.data);
      } catch (error: any) {
        setError(error.message || "Bir Hata Oluştu checkUser Fetch");
      } finally {
        setIsLoading(false);
      }
    };

    fetchIncompleteDetail();
  }, []);

  if (isLoading) {
    return <div>Yükleniyor...</div>;
  }

  if (error) {
    return <div>Hata: {error}</div>;
  }

  return (
    <div className="my-6 font-medium sm:font-normal">
      <div className="">
        <div className="bg-white mb-2 box-shadow rounded-md hidden md:block">
          {data && <ProfileHeader data={{ cv: data.cv, user: data.user }} htmlRef={cvHtml} />}
        </div>
        {data && (
          <div ref={cvHtml} className="flex flex-col sm:flex-row ">
            <div className=" w-full  sm:w-1/4 min-w-[280px]">
              {data?.user && (
                <SideBar
                  user={data?.user}
                  cv={data.cv}
                  liveInfo={data?.cv_live_info}
                  socials={data?.cv_social_accounts}
                  skills={data?.competences || []}
                  isEnglish={data?.cv.lang == 'en'}
                />
              )}
            </div>
            <div className="flex-grow">{data && <Details data={data} />}</div>
          </div>
        )}
        {data && (
          <div className="bg-white rounded-md block sm:hidden">
            <MobileDownloadAndShare
            htmlRef={cvHtml}
            data={{cv: data.cv, user: data.user }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CvDetails;
