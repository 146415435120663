import * as React from "react";

function StepsPointsCard(): React.ReactElement {
  // State to track the active step, default is 1
  const [activeStep, setActiveStep] = React.useState(1);

  return (
    <div className="bg-white p-4 rounded shadow-lg py-6 font-poppins hidden md:flex flex-col items-center">
      <div className="flex justify-between items-center relative w-full sm:px-8 md:px-16">
        {/* Line between steps */}
        <div
          className="absolute w-[70%] sm:w-[75%] lg:w-[80%] h-1 bg-[#ABA5A5] block right-[12%] lg:right-[10%] sm:right-[12%]"
          style={{ top: "38%", transform: "translateY(-50%)" }}
        ></div>

        {/* Step 1 */}
        <div className="flex flex-col items-center z-10">
          <div
            className={`w-[50px] h-[50px] md:w-[55px] md:h-[55px] flex justify-center items-center rounded-full text-3xl font-semibold ${
              activeStep === 1 ? "bg-orange-500" : "bg-[#ABA5A5]"
            } text-white`}
          >
            1
          </div>
          <div
            className="mt-2 text-sm sm:text-md font-semibold"
            style={{ color: activeStep === 2 ? "#1F4CA1" : "#F97316" }}
          >
            İlan Detayları
          </div>
        </div>

        {/* Step 2 */}
        <div className="flex flex-col items-center z-10">
          <div
            className={`w-[50px] h-[50px] md:w-[55px] md:h-[55px] flex justify-center items-center rounded-full text-3xl font-semibold ${
              activeStep === 1 ? "bg-orange-500" : "bg-[#ABA5A5]"
            } text-white`}
          >
            2
          </div>
          <div
            className="mt-2 text-sm sm:text-md font-semibold"
            style={{ color: activeStep === 2 ? "#1F4CA1" : "#F97316" }}
          >
            Ön İzleme
          </div>
        </div>

        {/* Step 3 */}
        <div className="flex flex-col items-center z-10">
          <div
            className={`w-[50px] h-[50px] md:w-[55px] md:h-[55px] flex justify-center items-center rounded-full text-3xl font-semibold ${
              activeStep === 3 ? "bg-[#ABA5A5]" : " bg-blue-800"
            } text-white`}
          >
            3
          </div>
          <div
            className="mt-2 text-sm sm:text-md font-semibold"
            style={{ color: activeStep === 3 ? "#2253AF" : "#ABA5A5" }}
          >
            Doping
          </div>
        </div>

        <div className="flex flex-col items-center z-10">
          <div
            className={`w-[50px] h-[50px] md:w-[55px] md:h-[55px] flex justify-center items-center rounded-full text-3xl font-semibold ${
              activeStep === 4 ? "bg-blue-800" : "bg-[#ABA5A5]"
            } text-white`}
          >
            4
          </div>
          <div
            className="mt-2 text-sm sm:text-md font-semibold"
            style={{ color: activeStep === 4 ? "#2253AF" : "#ABA5A5" }}
          >
            Tebrikler
          </div>
        </div>
      </div>
    </div>
  );
}

export { StepsPointsCard };
