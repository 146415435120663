import { HTMLAttributes, useContext, MouseEvent } from "react"
import { MultiSelectSearchDropdownContext, multiSelectSearchDropdownContextType } from "."
import classNames from "classnames"
import { IoChevronDown } from "react-icons/io5"


type triggerPropsType = HTMLAttributes<HTMLDivElement> & {

}








export default function Trigger({
    className,
    children,
    onClick,
    ...props
}: triggerPropsType) {

    const { mode, isOpen, setIsOpen, error }: multiSelectSearchDropdownContextType = useContext(MultiSelectSearchDropdownContext)
    
    const cn = classNames({
        'py-2 px-4 rounded-md font-poppins flex flex-row gap-2 items-center text-[14px]': true,
        'border-gray-400 border text-gray-600': mode == 'empty',
        'border-blue-600 border text-blue-600': mode == 'dirty',
        'border-red-600 border text-red-600 bg-red-50': error == true,
        'formGlow border-blue-500 border': isOpen == true
    })

    return(
        <div
        {...props}
        className={`${cn} ${className}`}
        onClick={((e) => divOnClickFunction(e))}
        >
            {children} <IoChevronDown className={`${isOpen? 'rotate-180': ''} ml-auto flex-shrink-0 transition-all`}/>
        </div>
    )

    function divOnClickFunction(e: MouseEvent<HTMLDivElement>) {
        if (onClick) {
            onClick(e)
        }

        setIsOpen((prev) => !prev)
    }
}