import { IconSearch } from '@tabler/icons-react';
import React from 'react';

interface IFastSearchItem {
  title: string;
  id: number;
}

export const FastSearchItem: React.FC<IFastSearchItem> = (
  props: IFastSearchItem
) => {
  return (
    <div className="border border-gray-400 flex p-2 gap-x-2 items-center rounded-md group hover:border-[#0d6efd] cursor-pointer">
      <IconSearch
        className="text-gray-500 group-hover:text-[#0d6efd]"
        size={16}
      />
      <span className="truncate text-xs text-gray-500 font-medium select-none group-hover:text-[#0d6efd]">
        {props.title}
      </span>
    </div>
  );
};
