import { SelectComponent } from "./select-component";
import { FaPlus, FaTrash } from "react-icons/fa";
import { HiOutlineArrowUturnLeft } from "react-icons/hi2";
import { useState, useEffect, useContext } from "react";
import { IChangeProps, IStatus, ITitle } from "../../types";
import useFetchCdnQuery from "../../../../../hooks/useFetchCdnQuery";
import Axios from "../../../../../services/axios";
import { useCookie } from "../../../../../hooks/useCookie";
import { CookieName } from "../../../../../constants/enums";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApplyPoolContext } from "../../index";
import { FaArrowsRotate } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

const axios = new Axios();

interface IFolder {
  id: number;
  title: string;
  company_folder_cvs_count?: number;
}

function UpdateSection({
  checkedData,
  fetchCards,
}: {
  checkedData: number[];
  fetchCards: () => void;
}) {
  const { getCookie } = useCookie();
  const { id } = useParams();
  const companyUserToken = getCookie(CookieName.CompanyUserToken);

  const { advertStatusData, fetchApplyInfo } = useContext(ApplyPoolContext);

  const [folderValue, setFolderValue] = useState<number>(0);
  const [statusValue, setStatusValue] = useState<number>(0);
  const [adStatusValue, setAdStatusValue] = useState<number>(0);
  const [fetchFolders, setFetchFolders] = useState<IFolder[]>([]);
  const [values, setValues] = useState<IChangeProps>({
    folder: 0,
    adStatus: 0,
    status: 0,
    arr: checkedData,
  });
  const [counter, setCounter] = useState<number>(0);

  //fetch folders
  const fetchFolderNames = async () => {
    try {
      const response = await axios.getCvFolders(companyUserToken);

      if (response && response.folders) {
        const res = response.folders;
        res.unshift({
          id: 0,
          title: "Yeni Klasör ekle",
          name: "Yeni Klasör ekle",
        });
        setFetchFolders(res);
      }
    } catch (error) {
      throw new Error("Error fetching folder names: " + error);
    }
  };
  useEffect(() => {
    fetchFolderNames();
  }, [companyUserToken]);
  const folders: ITitle[] = fetchFolders as ITitle[];
  const folderData = folders.map((item: ITitle) => {
    return {
      id: item.id,
      value: item.title,
      name: item.title,
    };
  });

  //fetch statuses
  const statusesQuery = useFetchCdnQuery(
    "statuses",
    "get-company-candidate-statuses",
    "company_candidate_statuses"
  );
  const StatusData = statusesQuery.data
    ? (statusesQuery.data.map((item: any) => {
        return {
          id: item.id,
          value: item.value,
          name: item.name,
        };
      }) as Array<IStatus>)
    : [];

  //set values
  useEffect(() => {
    setValues({
      folder: folderValue,
      status: statusValue,
      adStatus: adStatusValue,
      arr: checkedData,
    });
  }, [folderValue, statusValue, checkedData, adStatusValue]);

  //send values
  useEffect(() => {
    const updateCvStatues = async () => {
      if (counter === 0) return;
      if (folderValue == 0) {
        toast.warning("Klasör seçimi yapmanız gerekiyor");
        return;
      }

      try {
        const response = await axios.updateMultiCvStatus(
          values.status,
          values.folder,
          values.arr.map((el: any) => el.cv_id),
          companyUserToken
        );

        if (response) {
          toast.success("Adaylar başarıyla klasöre eklendi");
        }
      } catch (error) {
        toast.warning("Aday yada Adaylar bu klasöre daha önce eklenmiş.");
      }
    };

    updateCvStatues();
  }, [counter]);

  const handleClick = () => {
    setCounter(counter + 1);
  };

  const handleFolderChange = (value: number) => {
    setFolderValue(value);
  };

  const handleStatusChange = (value: number) => {
    setStatusValue(value);
  };

  const handleChangeStatue = () => {
    if (values.adStatus === 0) {
      Swal.fire({
        text: "Statü Seçin",
        icon: "error",
        showCancelButton: false,
        confirmButtonText: "Tamam",
      });
    }
    axios
      .authRequest({
        type: "put",
        endpoint: `companies/advert-applies/${id}/update-status`,
        payload: {
          advert_apply_ids: checkedData.map((el: any) => {
            return el.apply_id;
          }),
          status: advertStatusData.filter(
            (el: any) => el.id === values.adStatus
          )[0].name,
        },
      })
      .then((response: any) => {
        if (response.data.status_code === 200) {
          fetchApplyInfo();
          fetchCards();
        } else {
          Swal.fire({
            text: "Başvuruların statüsü güncellenemedi",
            icon: "error",
            showCancelButton: false,
            confirmButtonText: "Tamam",
          });
        }
      })
      .catch(() => {
        Swal.fire({
          text: "Başvuruların statüsü güncellenemedi",
          icon: "error",
          showCancelButton: false,
          confirmButtonText: "Tamam",
        });
      });
  };

  const handleDelete = () => {
    Swal.fire({
      icon: "warning",
      title: "Emin misiniz?",
      text: "Seçilen başvuruları silmek istediğinizden emin misiniz?",
      showCancelButton: true,
      cancelButtonText: "İptal",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, Sil!",
    }).then((response) => {
      console.log(response);
      if (response.isConfirmed) {
        axios
          .authRequest({
            type: "put",
            endpoint: `companies/advert-applies/${id}/update-status`,
            payload: {
              advert_apply_ids: checkedData.map((el: any) => {
                return el.apply_id;
              }),
              status: "NOT_SUITABLE",
            },
          })
          .then((response: any) => {
            if (response.data.status_code === 200) {
              fetchApplyInfo();
              fetchCards();
            } else {
              Swal.fire({
                text: "Başvuruların Silinemedi",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Tamam",
              });
            }
          })
          .catch(() => {
            Swal.fire({
              text: "Başvuruların Silinemedi",
              icon: "error",
              showCancelButton: false,
              confirmButtonText: "Tamam",
            });
          });
      }
    });
  };

  return (
    <div className="flex flex-col gap-3 mx-2 my-5 md:mx-10  text-center">
      <div className={"relative text-left  font-semibold"}>
        <HiOutlineArrowUturnLeft className="text-primary200 text-2xl hidden md:block absolute top-0 left-0 rotate-180 -translate-x-[110%]" />
        Başvuru Durumu:
      </div>
      <div className={"grid grid-cols-1 md:grid-cols-3 gap-3"}>
        <SelectComponent
          blue={values.adStatus !== 0}
          data={advertStatusData
            .map((el: any) => {
              if (el.name === "ROLLBACK") return false;
              let temp: any = {
                name: el.name_tr_company,
                value: el.name,
                id: el.id,
              };
              return temp;
            })
            .filter((el: any) => {
              return el;
            })}
          label=""
          placeholder="Başvuru Durumu Seçiniz"
          onChange={(value) => {
            setAdStatusValue(value);
          }}
          selectedvalue={values.adStatus}
        />
        <button
          className="bg-primary200 text-white px-3 py-2 rounded-md flex items-center flex-1 w-full justify-center gap-2 "
          onClick={handleChangeStatue}
        >
          <FaArrowsRotate className="inline-block " />
          Olarak Değiştir
        </button>
      </div>
      <div className={"text-left font-semibold"}>Klasör Durumu:</div>
      <div className={"grid grid-cols-1 md:grid-cols-3 gap-3"}>
        <SelectComponent
          blue={values.folder !== 0}
          data={folderData}
          label=""
          placeholder="Klasör Seçiniz"
          onChange={(value) => handleFolderChange(value)}
          selectedvalue={values.folder}
          callBack={() => {
            fetchFolderNames();
          }}
        />

        <SelectComponent
          data={StatusData}
          label=""
          placeholder="İncelenmedi"
          blue={true}
          onChange={(value) => handleStatusChange(value)}
          selectedvalue={values.status}
        />

        <button
          className="bg-primary200 text-white px-3 py-2 rounded-md flex items-center flex-1 w-full justify-center gap-2 "
          onClick={handleClick}
        >
          <FaPlus className="inline-block " />
          Seçilileri Klasöre Ekle
        </button>
      </div>
      <div className={"grid grid-cols-1 md:grid-cols-3 gap-3"}>
        <button
          className="bg-red-500 text-white px-3 py-2 rounded-md flex items-center flex-1 w-full justify-center gap-2 "
          onClick={handleDelete}
        >
          <FaTrash className="inline-block " />
          Seçilileri Sil
        </button>
      </div>
    </div>
  );
}

export { UpdateSection };
