import { FaLanguage, FaPencilAlt, FaRegStar, FaRegTrashAlt, FaStar, FaTrashAlt } from "react-icons/fa";
import { Button, InputArea, SelectSearchDropdown, Textarea } from "../../../../../../components/isbul-ui";
import { Checkbox } from "../../../../../../components/isbul-ui/checkbox";
import { cdnGeneralLanguageType, dbUserLanguageType } from "../../../../../../types";
import { useForm, SubmitHandler } from "react-hook-form";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getGeneralLanguages } from "../../../../../../customHooks/getGeneralLanguages";
import { IoAdd, IoTrashBinOutline } from "react-icons/io5";
import Swal from "sweetalert2";
import Axios from "../../../../../../services/axios";
import { useParams } from "react-router-dom";
import { useIsMobile } from "../../../../../../hooks/useIsMobile";
import FormModal from "../../../../../../components/isbul-ui/form-modal";
import { useContext } from "react";
import { CvLayoutContext, cvLayoutContextType } from "../..";
import { AiOutlineLoading3Quarters } from "react-icons/ai";


type propsType = {
    language: dbUserLanguageType | null,
    generalLanguages: cdnGeneralLanguageType[],
    getAllDatas?: () => void,
    languagesLength?: number,
    isAddClicked?: boolean,
    setIsAddClicked?: Dispatch<SetStateAction<boolean>>
}


type payloadData = {
    cv_id: number,
    general_lang_id: number | null,
    read_level: number,
    speak_level: number,
    write_level: number,
    is_main: boolean,
    detail: string | null
}


const axios = new Axios()

export default function ForeignLanguageForm({
    generalLanguages: initialGeneralLanguages,
    language,
    getAllDatas,
    languagesLength,
    isAddClicked,
    setIsAddClicked
}: propsType) {

    const { showCvScore, isEnglish }: cvLayoutContextType = useContext(CvLayoutContext)

    const params = useParams()
    const cvId = params.slug

    const isMobile = useIsMobile()

    /* General states */
    const [isEditOpen, setIsEditOpen] = useState<boolean>(language? false: true)
    const [generalLanguages, setGeneralLanguages] = useState<cdnGeneralLanguageType[]>(initialGeneralLanguages)
    const [searchLanguage, setSearchLanguage] = useState<string>('')
    const [isPageOpen, setIsPageOpen] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)


    /* Page header add button click catcher */
    useEffect(() => {
        if (isAddClicked != undefined && setIsAddClicked != undefined && !language) {
            if (isAddClicked) {
                setIsModalOpen(true)
                setIsAddClicked(false)
            }
        }
    }, [isAddClicked])

    useEffect(() => {
        setGeneralLanguages(initialGeneralLanguages)
    }, [initialGeneralLanguages])


    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        clearErrors
    } = useForm<payloadData>()

    const onSubmit: SubmitHandler<payloadData> = ((data) => {
        if (!language) {
            addLanguage(data)
        } else {
            saveLanguage(data)
        }
    })

    // Language exist
    async function getDatas(language: dbUserLanguageType) {

        // set initial datas
        setSelectedlanguage(language.general_lang_list || null)

        setWriteLevel(language.write_level || 0)
        setReadLevel(language.read_level || 0)
        setSpokeLevel(language.speak_level || 0)
        setValue('detail', language.detail)
        setMainLanguage(language.is_main == 1? true: false)
    }

    useEffect(() => {
        if (language) {
            getDatas(language)
        }
    }, [language])


    // Search event
    useEffect(() => {
        async function search() {
            if (searchLanguage.trim().length > 0) {
                const data = await getGeneralLanguages({ search: searchLanguage })
                setGeneralLanguages(data)
            } else {
                setGeneralLanguages(initialGeneralLanguages)
            }
        }

        const timeOut = setTimeout(search, 500)

        return () => {
            clearTimeout(timeOut)
        }
    }, [searchLanguage])


    // Form states
    const [selectedLanguage, setSelectedlanguage] = useState<cdnGeneralLanguageType | null>(null)
    const [spokeLevel, setSpokeLevel] = useState<number>(0)
    const [readLevel, setReadLevel] = useState<number>(0)
    const [writeLevel, setWriteLevel] = useState<number>(0)
    const [mainLanguage, setMainLanguage] = useState<boolean>(false)


    // Form state changes
    /* Language change */
    useEffect(() => {
        setValue('general_lang_id', selectedLanguage?.id?? null)
        clearErrors('general_lang_id')
    }, [selectedLanguage])

    /* Main language checkbox change */
    useEffect(() => {
        setValue('is_main', mainLanguage)
    }, [mainLanguage])

    /* Read level */
    useEffect(() => {
        setValue('read_level', readLevel)
        if (readLevel != 0) {
            clearErrors('read_level')
        }
    }, [readLevel])

    /* Speak level */
    useEffect(() => {
        setValue('speak_level', spokeLevel)
        if (spokeLevel != 0) {
            clearErrors('speak_level')
        }
    }, [spokeLevel])

    /* Write level */
    useEffect(() => {
        setValue('write_level', writeLevel)
        if (writeLevel != 0) {
            clearErrors('write_level')
        }
    }, [writeLevel])

    // Register
    // Language
    register('general_lang_id', {
        required: 'Yabancı Dil'
    })

    // Speak
    register('speak_level', {
        required: 'Konuşma seviyesi',
        min: {
            value: 1,
            message: 'Konuşma seviyesi'
        }
    })

    // Read
    register('read_level', {
        required: 'Okuma seviyesi',
        min: {
            value: 1,
            message: 'Okuma seviyesi'
        }
    })

    // Write
    register('write_level', {
        required: 'Yazma seviyesi',
        min: {
            value: 1,
            message: 'Yazma seviyesi'
        }
    })

    // Custom values
    const detail = watch('detail') || ''


    if (isEditOpen) {
        return(
            <div className="flex flex-col gap-2">

                {/* Page header if language not exist */}
                {!language && (
                    <div className={`flex flex-row items-center justify-between px-4`}>
                        <Button
                        size="sm"
                        onClick={() => {
                            setIsPageOpen(true)
                            setIsModalOpen(true)
                        }}
                        >
                            <Button.Icon>
                                <IoAdd className="text-base"/>
                            </Button.Icon>
                            {isEnglish? 'Add Foreign Language': 'Yabancı Dil Ekle'}
                        </Button>
                    </div>
                )}

                {!language && (
                    <hr className="border-mains"/>
                )}

                {/* Form modal for form */}
                <FormModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                onClosing={() => {
                    setIsEditOpen(false)
                    if (language) {
                        setIsEditOpen(false)
                    } else {
                        setIsEditOpen(true)
                    }
                }}
                >
                    <FormModal.Content>
                        <form className="flex flex-col gap-y-4 px-6 pt-2 pb-20 lg:pb-2" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                            <label className="text-mains font-semibold">
                                {language? `${isEnglish? 'Edit Foreign Lanugeage': 'Yabancı Dili Düzenle'}`: `${isEnglish? 'Add Foreign Language': 'Yabancı Dil Ekle'}`}
                            </label>
                            <hr className="border-mains"/>
                            <div className="flex flex-col gap-y-6 lg:flex-row">
                                <div className="flex flex-col gap-y-6 basis-1/2 px-1">
                                    {/* Language select */}
                                    <SelectSearchDropdown
                                    className="w-full"
                                    required
                                    mode={selectedLanguage? 'dirty': 'empty'}
                                    error={errors.general_lang_id?.message? true: false}
                                    >
                                        <SelectSearchDropdown.Label>{isEnglish? 'Foreign Language': 'Yabancı Dil'}</SelectSearchDropdown.Label>
                                        <SelectSearchDropdown.Trigger
                                        onClick={ async () => {
                                            if (searchLanguage.trim().length == 0 && generalLanguages.length == 0) {
                                                setIsLoading(true)
                                                const response = await getGeneralLanguages({})
                                                setGeneralLanguages(response)
                                                setIsLoading(false)
                                            }
                                        }}
                                        >
                                            <SelectSearchDropdown.Value>
                                                {selectedLanguage? `${isEnglish? `${selectedLanguage.name_en}`: `${selectedLanguage.name}`}`: `${isEnglish? 'Select': 'Seçiniz'}`}
                                            </SelectSearchDropdown.Value>
                                            <SelectSearchDropdown.Input
                                            placeholder={`${isEnglish? 'Search': 'Arama Yap'}`}
                                            value={searchLanguage || ''}
                                            onChange={((e) => setSearchLanguage(e.target.value))}
                                            />
                                        </SelectSearchDropdown.Trigger>
                                        <SelectSearchDropdown.Items>
                                            {isLoading && (
                                                <SelectSearchDropdown.Item>
                                                    <AiOutlineLoading3Quarters className="animate-spin mx-auto"/>
                                                </SelectSearchDropdown.Item>
                                            )}
                                            {generalLanguages.map((l) => {
                                                return(
                                                    <SelectSearchDropdown.Item
                                                    key={`ls${l.id}`}
                                                    onClick={() => setSelectedlanguage(l)}
                                                    >
                                                        {isEnglish? l.name_en: l.name}
                                                    </SelectSearchDropdown.Item>
                                                )
                                            })}
                                        </SelectSearchDropdown.Items>
                                    </SelectSearchDropdown>
                                    {/* Stars */}
                                    <div className="flex flex-row items-start flex-wrap">
                                        <div className="flex basis-1/3 p-1">
                                            
                                            {/* Read level stars */}
                                            <InputArea
                                            className="w-full"
                                            required
                                            error={errors.read_level?.message? true: false}
                                            >
                                                <InputArea.Label>{isEnglish? 'Reading': 'Okuma'}</InputArea.Label>
                                                <div className="flex flex-row items-center gap-1 text-lg lg:text-xl">
                                                    <button type="button" onClick={() => setReadLevel(1)}>
                                                        {readLevel >= 1 ? (
                                                            <FaStar className={`${errors.read_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        ): (
                                                            <FaRegStar className={`${errors.read_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        )}
                                                    </button>
                                                    <button type="button" onClick={() => setReadLevel(2)}>
                                                        {readLevel >= 2 ? (
                                                            <FaStar className={`${errors.read_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        ): (
                                                            <FaRegStar className={`${errors.read_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        )}
                                                    </button>
                                                    <button type="button" onClick={() => setReadLevel(3)}>
                                                        {readLevel >= 3 ? (
                                                            <FaStar className={`${errors.read_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        ): (
                                                            <FaRegStar className={`${errors.read_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        )}
                                                    </button>
                                                    <button type="button" onClick={() => setReadLevel(4)}>
                                                        {readLevel >= 4 ? (
                                                            <FaStar className={`${errors.read_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        ): (
                                                            <FaRegStar className={`${errors.read_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        )}
                                                    </button>
                                                </div>
                                            </InputArea>
                                        </div>
                                        <div className="flex basis-1/3 p-1">
                                            
                                            {/* Write level stars */}
                                            <InputArea
                                            className="w-full"
                                            required
                                            error={errors.write_level?.message? true: false}
                                            >
                                                <InputArea.Label>{isEnglish? 'Writing': 'Yazma'}</InputArea.Label>
                                                <div className="flex flex-row items-center gap-1 text-lg lg:text-xl">
                                                    <button type="button" onClick={() => setWriteLevel(1)}>
                                                        {writeLevel >= 1 ? (
                                                            <FaStar className={`${errors.write_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        ): (
                                                            <FaRegStar className={`${errors.write_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        )}
                                                    </button>
                                                    <button type="button" onClick={() => setWriteLevel(2)}>
                                                        {writeLevel >= 2 ? (
                                                            <FaStar className={`${errors.write_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        ): (
                                                            <FaRegStar className={`${errors.write_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        )}
                                                    </button>
                                                    <button type="button" onClick={() => setWriteLevel(3)}>
                                                        {writeLevel >= 3 ? (
                                                            <FaStar className={`${errors.write_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        ): (
                                                            <FaRegStar className={`${errors.write_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        )}
                                                    </button>
                                                    <button type="button" onClick={() => setWriteLevel(4)}>
                                                        {writeLevel >= 4 ? (
                                                            <FaStar className={`${errors.write_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        ): (
                                                            <FaRegStar className={`${errors.write_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        )}
                                                    </button>
                                                </div>
                                            </InputArea>
                                        </div>
                                        <div className="flex basis-1/3 p-1">

                                            {/* Speak level stars */}
                                            <InputArea
                                            className="w-full"
                                            required
                                            error={errors.speak_level?.message? true: false}
                                            >
                                                <InputArea.Label>{isEnglish? 'Konuşma': 'Konuşma'}</InputArea.Label>
                                                <div className="flex flex-row items-center gap-1 text-lg lg:text-xl">
                                                    <button type="button" onClick={() => setSpokeLevel(1)}>
                                                        {spokeLevel >= 1 ? (
                                                            <FaStar className={`${errors.speak_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        ): (
                                                            <FaRegStar className={`${errors.speak_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        )}
                                                    </button>
                                                    <button type="button" onClick={() => setSpokeLevel(2)}>
                                                        {spokeLevel >= 2 ? (
                                                            <FaStar className={`${errors.speak_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        ): (
                                                            <FaRegStar className={`${errors.speak_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        )}
                                                    </button>
                                                    <button type="button" onClick={() => setSpokeLevel(3)}>
                                                        {spokeLevel >= 3 ? (
                                                            <FaStar className={`${errors.speak_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        ): (
                                                            <FaRegStar className={`${errors.speak_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        )}
                                                    </button>
                                                    <button type="button" onClick={() => setSpokeLevel(4)}>
                                                        {spokeLevel >= 4 ? (
                                                            <FaStar className={`${errors.speak_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        ): (
                                                            <FaRegStar className={`${errors.speak_level?.message? 'text-red-600': 'text-blue-500'}`}/>
                                                        )}
                                                    </button>
                                                </div>
                                            </InputArea>
                                        </div>
                                    </div>
                                    <Checkbox 
                                    className="flex !flex-row items-center gap-2 mt-1"
                                    checked={mainLanguage}
                                    setChecked={setMainLanguage}
                                    >
                                        <Checkbox.Trigger/>
                                        <Checkbox.Label >
                                            <button type="button" onClick={() => setMainLanguage((prev) => !prev)}>
                                                {isEnglish? 'Native Language': 'Anadil'}
                                            </button>
                                        </Checkbox.Label>
                                        
                                    </Checkbox>
                                </div>

                                
                                <div className="flex flex-col basis-1/2 px-1">

                                    {/* Details text area */}
                                    <Textarea
                                    className="w-full !h-full"
                                    mode={detail.length == 0? 'empty': 'dirty'}
                                    >
                                        <Textarea.Label>{isEnglish? 'Description': 'Açıklama'}</Textarea.Label>
                                        <Textarea.Field
                                        className="h-[200px] lg:h-full"
                                        value={detail || ''}
                                        onChange={((e) => setValue('detail', e.target.value))}
                                        />
                                    </Textarea>
                                    <label className={`font-poppins text-xs p-1 ${3000 - detail.length < 0? 'text-red-600': 'text-gray-600'}`}>
                                        {3000 - detail.length}
                                    </label>
                                </div>
                            </div>

                            {/* Save and cancel buttons */}
                            {!language && (
                                <div className="flex flex-row gap-2 ml-auto items-center mt-6">
                                    
                                    <Button
                                    size="sm"
                                    variant="secondary"
                                    onClick={() => {
                                        //setIsPageOpen(false)
                                        setIsModalOpen(false)
                                    }}
                                    >
                                        {isEnglish? 'Cancel': 'Vazgeç'}
                                    </Button>
                                    <Button type="submit" size="sm">
                                        {isEnglish? 'Save': 'Kaydet'}
                                    </Button>
                                </div>
                            )}
                
                            {language && (
                                <div className="flex flex-row mt-6 items-center justify-between">
                                    <div className="flex ml-auto flex-row items-center gap-2">
                                        <Button type="button" variant="secondary" size="sm" onClick={() => setIsEditOpen(false)}>
                                            {isEnglish? 'Cancel': 'Vazgeç'}
                                        </Button>
                                        <Button type="submit" size="sm">
                                            {isEnglish? 'Save': 'Kaydet'}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </form>
                    </FormModal.Content>
                </FormModal>

            </div>
        )
    } else {
        return(
            <>
                {/* Desktop section */}
                <div
                className="hidden lg:flex flex-row relative px-1 py-2 gap-4 border-transparent hover:border-gray-300 border-dashed border-2 rounded-md transition-all"
                >
                    {/* Edit and delete buttons */}
                    <FormModal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                    >
                        <FormModal.Trigger>
                            <div className="flex flex-col gap-2 absolute -top-2 right-2 lg:top-4 lg:right-4 z-10">
                                <Button
                                variant="ghost" 
                                size="xs" 
                                className="aspect-square !rounded-full z-10 !border-none"
                                onClick={() => {
                                    setIsEditOpen(true)
                                    //setIsPageOpen(true)
                                    setIsModalOpen(true)
                                }}
                                >
                                    <Button.Icon>
                                        <FaPencilAlt className="text-xl"/>
                                    </Button.Icon>
                                </Button>
                                <Button
                                variant="ghost" 
                                size="xs" 
                                className="aspect-square !rounded-full z-10 !border-none"
                                onClick={() => {
                                    Swal.fire({
                                        icon: 'warning',
                                        title: `${isEnglish? 'Are you sure to delete foreign language information?': 'Yabancı dil bilgisini silmek istediğinizden emin misiniz?'}`,
                                        confirmButtonText: `${isEnglish? 'Yes': 'Evet'}`,
                                        cancelButtonText: `${isEnglish? 'No': 'Hayır'}`,
                                        preConfirm: () => {
                                            deleteLanguage()
                                        },
                                        showCancelButton: true
                                    })
                                }}
                                >
                                    <Button.Icon>
                                        <IoTrashBinOutline className="text-xl"/>
                                    </Button.Icon>
                                </Button>
                            </div>
                        </FormModal.Trigger>
                    </FormModal>
                    
                    <div className="absolute top-4 left-4 lg:top-0 lg:left-0 z-0 lg:relative items-center hidden lg:flex flex-col border-r-0 lg:border-r pr-4 mr-2">
                        <FaLanguage className="text-6xl text-gray-200 lg:text-9xl lg:text-mains/40"/>

                        {/* Language name */}
                        <label className="hidden lg:flex text-center font-poppins text-sm font-semibold text-mains line-clamp-1">
                            {isEnglish? language?.general_lang_list?.name_en: language?.general_lang_list?.name}
                        </label>
                    </div>
                    <div className="flex flex-col gap-2 w-full z-[1]">
                        {/* language name */}
                        <label className="flex lg:hidden text-mains font-poppins font-semibold text-sm">
                            {isEnglish? language?.general_lang_list?.name_en: language?.general_lang_list?.name}
                        </label>
                        <hr className="block lg:hidden"/>
                        <div className="flex flex-row h-full">

                            {/* Read stars */}
                            <div className="flex flex-col basis-1/3 p-1 h-full justify-center">
                                <label className="text-xs font-poppins font-semibold text-gray-700">{isEnglish? 'Reading': 'Okuma'}</label>
                                <div className="flex flex-row items-center gap-1">
                                    {language && language.read_level >= 1? (
                                        <FaStar className="text-sm lg:text-xl text-mains"/>
                                    ): (
                                        <FaRegStar className="text-sm lg:text-xl text-mains"/>
                                    )}
                                    {language && language.read_level >= 2? (
                                        <FaStar className="text-sm lg:text-xl text-mains"/>
                                    ): (
                                        <FaRegStar className="text-sm lg:text-xl text-mains"/>
                                    )}
                                    {language && language.read_level >= 3? (
                                        <FaStar className="text-sm lg:text-xl text-mains"/>
                                    ): (
                                        <FaRegStar className="text-sm lg:text-xl text-mains"/>
                                    )}
                                    {language && language.read_level >= 4? (
                                        <FaStar className="text-sm lg:text-xl text-mains"/>
                                    ): (
                                        <FaRegStar className="text-sm lg:text-xl text-mains"/>
                                    )}
                                </div>
                            </div>

                            {/* Write stars */}
                            <div className="flex flex-col basis-1/3 p-1 h-full justify-center">
                                <label className="text-xs font-poppins font-semibold text-gray-700">{isEnglish? 'Writing': 'Yazma'}</label>
                                <div className="flex flex-row items-center gap-1">
                                    {language && language.write_level >= 1? (
                                        <FaStar className="text-sm lg:text-xl text-mains"/>
                                    ): (
                                        <FaRegStar className="text-sm lg:text-xl text-mains"/>
                                    )}
                                    {language && language.write_level >= 2? (
                                        <FaStar className="text-sm lg:text-xl text-mains"/>
                                    ): (
                                        <FaRegStar className="text-sm lg:text-xl text-mains"/>
                                    )}
                                    {language && language.write_level >= 3? (
                                        <FaStar className="text-sm lg:text-xl text-mains"/>
                                    ): (
                                        <FaRegStar className="text-sm lg:text-xl text-mains"/>
                                    )}
                                    {language && language.write_level >= 4? (
                                        <FaStar className="text-sm lg:text-xl text-mains"/>
                                    ): (
                                        <FaRegStar className="text-sm lg:text-xl text-mains"/>
                                    )}
                                </div>
                            </div>

                            {/* Speak stars */}
                            <div className="flex flex-col basis-1/3 p-1 h-full justify-center">
                                <label className="text-xs font-poppins font-semibold text-gray-700">{isEnglish? 'Speaking': 'Konuşma'}</label>
                                <div className="flex flex-row items-center gap-1">
                                    {language && language.speak_level >= 1? (
                                        <FaStar className="text-sm lg:text-xl text-mains"/>
                                    ): (
                                        <FaRegStar className="text-sm lg:text-xl text-mains"/>
                                    )}
                                    {language && language.speak_level >= 2? (
                                        <FaStar className="text-sm lg:text-xl text-mains"/>
                                    ): (
                                        <FaRegStar className="text-sm lg:text-xl text-mains"/>
                                    )}
                                    {language && language.speak_level >= 3? (
                                        <FaStar className="text-sm lg:text-xl text-mains"/>
                                    ): (
                                        <FaRegStar className="text-sm lg:text-xl text-mains"/>
                                    )}
                                    {language && language.speak_level >= 4? (
                                        <FaStar className="text-sm lg:text-xl text-mains"/>
                                    ): (
                                        <FaRegStar className="text-sm lg:text-xl text-mains"/>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Mobile section */}
                <div className="flex flex-row lg:hidden p-4">
                    <div className="flex flex-col gap-2 flex-1">
                        {/* Language name */}
                        <div className="flex flex-col">
                            <label className="font-poppins text-[13px] font-semibold text-gray-900">
                                {isEnglish? 'Foreign Language': 'Yabancı Dil'}
                            </label>
                            <label className="font-poppins text-[13px] text-gray-800">
                                {isEnglish? language?.general_lang_list?.name_en: language?.general_lang_list?.name}
                            </label>
                        </div>

                        {/* Stars */}
                        <div className="flex flex-col gap-1">
                            <label className="font-poppins text-[13px] font-semibold text-gray-900">
                                {isEnglish? 'Level': 'Seviye'}
                            </label>
                            <div className="flex flex-row items-center gap-2">

                                {/* Write stars */}
                                <div className="flex flex-col">
                                    <label className="font-poppins text-xs text-gray-900">
                                        {isEnglish? 'Writing': 'Yazma'}
                                    </label>
                                    <div className="flex flex-row text-mains text-xs">
                                        {language && language.write_level >= 1 ? (
                                            <FaStar/>
                                        ): (
                                            <FaRegStar/>
                                        )}
                                        {language && language.write_level >= 2 ? (
                                            <FaStar/>
                                        ): (
                                            <FaRegStar/>
                                        )}
                                        {language && language.write_level >= 3 ? (
                                            <FaStar/>
                                        ): (
                                            <FaRegStar/>
                                        )}
                                        {language && language.write_level >= 4 ? (
                                            <FaStar/>
                                        ): (
                                            <FaRegStar/>
                                        )}
                                    </div>
                                </div>

                                {/* Read stars */}
                                <div className="flex flex-col">
                                    <label className="font-poppins text-xs text-gray-900">
                                        {isEnglish? 'Reading': 'Okuma'}
                                    </label>
                                    <div className="flex flex-row text-mains text-xs">
                                        {language && language.read_level >= 1 ? (
                                            <FaStar/>
                                        ): (
                                            <FaRegStar/>
                                        )}
                                        {language && language.read_level >= 2 ? (
                                            <FaStar/>
                                        ): (
                                            <FaRegStar/>
                                        )}
                                        {language && language.read_level >= 3 ? (
                                            <FaStar/>
                                        ): (
                                            <FaRegStar/>
                                        )}
                                        {language && language.read_level >= 4 ? (
                                            <FaStar/>
                                        ): (
                                            <FaRegStar/>
                                        )}
                                    </div>
                                </div>

                                {/* Speak stars */}
                                <div className="flex flex-col">
                                    <label className="font-poppins text-xs text-gray-900">
                                        {isEnglish? 'Speaking': 'Konuşma'}
                                    </label>
                                    <div className="flex flex-row text-mains text-sm">
                                        {language && language.speak_level >= 1 ? (
                                            <FaStar/>
                                        ): (
                                            <FaRegStar/>
                                        )}
                                        {language && language.speak_level >= 2 ? (
                                            <FaStar/>
                                        ): (
                                            <FaRegStar/>
                                        )}
                                        {language && language.speak_level >= 3 ? (
                                            <FaStar/>
                                        ): (
                                            <FaRegStar/>
                                        )}
                                        {language && language.speak_level >= 4 ? (
                                            <FaStar/>
                                        ): (
                                            <FaRegStar/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Edit and delete buttons */}
                    <div className="flex flex-col gap-2 items-center">
                        <button className="flex flex-col gap-1 items-center text-mains font-poppins text-xs" onClick={() => {
                            setIsEditOpen(true)
                            setIsPageOpen(true)
                            setIsModalOpen(true)
                        }}>
                            <FaPencilAlt className="text-mains text-base"/>
                            {isEnglish? 'Edit': 'Düzenle'}
                        </button>
                        <button className="flex flex-col gap-1 items-center text-mains font-poppins text-xs" onClick={() => {
                            Swal.fire({
                                icon: 'warning',
                                title: `${isEnglish? 'Are you sure to delete foreign language?': 'Yabancı Dil bilgisini silmek istediğinizden emin misiniz?'}`,
                                confirmButtonText: `${isEnglish? 'Yes': 'Evet'}`,
                                cancelButtonText: `${isEnglish? 'No': 'Hayır'}`,
                                preConfirm: () => {
                                    deleteLanguage()
                                },
                                showCancelButton: true
                            })
                        }}>
                            <IoTrashBinOutline className="text-base"/>
                        </button>
                    </div>
                </div>
            </>
        )
    }

    async function addLanguage({cv_id, ...others }: payloadData) {

        if (isLoading) return

        setIsLoading(true)

        try {
            const response = await axios.userAuthRequest({
                endpoint: `user/profilim/cv/lang/create`,
                type: 'post',
                payload: {
                    cv_id: cvId,
                    ...others
                }
            })

            if (response && response.status == 200) {
                if (getAllDatas) {
                    getAllDatas()
                }

                /* Clear form datas */
                setSelectedlanguage(null)
                setMainLanguage(false)
                setReadLevel(0)
                setSpokeLevel(0)
                setWriteLevel(0)
                setValue('detail', '')

                setIsPageOpen(false)
                setIsModalOpen(false)

                showCvScore()
                /* if (isMobile.isMobile) {
                    setIsPageOpen(false)
                } */
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Hata',
                    text: 'Dil eklenirken beklenmedik bir hata meydana geldi'
                })
            }
        } catch (error) {
            console.log(`Error: ${error}`)
            Swal.fire({
                icon: 'error',
                title: 'Hata',
                text: 'Dil eklenirken beklenmedik bir hata meydana geldi'
            })
        }

        setIsLoading(false)
    }

    async function saveLanguage({ cv_id, ...others }: payloadData) {

        if (isLoading) return

        setIsLoading(true)

        try {
            if (language) {
                const response = await axios.userAuthRequest({
                    endpoint: `user/profilim/cv/lang/update/${language.id}`,
                    type: 'put',
                    payload: {
                        cv_id: cvId,
                        ...others
                    }
                })

                if (response && response.status == 200) {
                    setIsEditOpen(false)
                    if (getAllDatas) {
                        getAllDatas()
                    }

                    showCvScore()
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Hata',
                        text: 'Dil güncellenirken bir hata meydana geldi'
                    })
                }
            }

            
        } catch (error) {
            console.log(`Error: ${error}`)
            Swal.fire({
                icon: 'error',
                title: 'Hata',
                text: 'Dil güncellenirken beklenmedik bir hata meydana geldi'
            })
        }

        setIsLoading(false)
    }

    async function deleteLanguage() {

        if (isLoading) return

        setIsLoading(true)

        try {
            if (language) {
                const response = await axios.userAuthRequest({
                    endpoint: `user/profilim/cv/lang/delete/${language.id}?cv_id=${cvId}`,
                    type: 'delete',
                    payload: {}
                })

                if (response && response.status == 200) {
                    if (getAllDatas) {
                        getAllDatas()
                    }

                    showCvScore()
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Hata',
                        text: 'Dil silinirken beklenmedik bir hata meydana geldi'
                    })
                }
            }
        } catch (error) {
            console.log(`Error: ${error}`)
            Swal.fire({
                icon: 'error',
                title: 'Hata',
                text: 'Dil silinirken beklenmedik bir hata meydana geldi'
            })
        }

        setIsLoading(false)
    }
}