import React from 'react'
import Card from '../../../containers/company-page-container/setting-page/content/Card'
import { CgSpinner } from 'react-icons/cg'

type Props = {}

export const PageLoader = (props: Props) => {
    return (
        <Card className='h-[80dvh] flex flex-col justify-center items-center'>
            <CgSpinner className='animate-spin text-title text-7xl m-auto my-4' />
        </Card>
    )
}

