import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { IconChevronDown } from "@tabler/icons-react";

interface SubmenuItem {
	title: string;
	url?: string;
	icon?: JSX.Element;
	submenu?: SubmenuItem[];
}

interface SideBarItemsProps {
	url?: string;
	title: string;
	collapse?: boolean;
	submenu?: SubmenuItem[];
	onClick: (event: { title: string; subTitle: string }) => void;
	icon?: JSX.Element;
}

const SideBarItems: React.FC<SideBarItemsProps> = ({
	url,
	title,
	collapse,
	submenu,
	onClick,
	icon,
}) => {
	const [isCollapse, setIsCollapse] = useState(false);
	const navigate = useNavigate();

	const handleChangeCollapse = (e: React.MouseEvent) => {
		if (submenu && submenu.length > 0) {
			setIsCollapse(!isCollapse);
		} else if (url) {
			navigate(url);
		}
	};

	return (
		<div className='mt-5 px-2.5'>
			<div className='w-full border-t border-white border-opacity-10' />
			<div>
				<ul>
					<li>
						<div
							className={`flex items-center justify-between text-white font-bold text-sm leading-6 w-52 cursor-pointer`}
							onClick={handleChangeCollapse}>
							{icon}
							<span className='ml-4 uppercase'>{title}</span>
							{collapse && (
								<IconChevronDown
									className={`transition-transform duration-200 ${
										isCollapse ? "rotate-180" : "rotate-0"
									}`}
								/>
							)}
						</div>
						{submenu && isCollapse && (
							<ul className='flex flex-col'>
								{submenu.map((item, index) => (
									<NavLink
										key={index}
										className='flex items-center my-5 ml-5 text-sm font-medium'
										to={item.url || "#"}
										onClick={() => {
											if (item.url) {
												navigate(item.url);
											}
											onClick({ title, subTitle: item.title });
										}}>
										{item.icon}
										<span className='ml-4'>{item.title}</span>
									</NavLink>
								))}
							</ul>
						)}
					</li>
				</ul>
			</div>
		</div>
	);
};

export default SideBarItems;
