import React, { useState, useEffect } from "react";
import "leaflet/dist/leaflet.css";
import { FaTimes } from "react-icons/fa";
import { LuLoader2 } from "react-icons/lu";
import Axios from "../../../../services/axios";
import FilterAccordionWorkAlarm from "./FilterAccordionWorkAlarm";
import { IItem } from "../../../job-page-container/main/filter-section-content";
import {
  adDatesData,
  disabledData,
  experienceData,
  otherDataUser,
} from "../../../job-page-container/main";
import FilterAccordionWorkAlarmCityDistrict from "./FilterAccordionWorkAlarmCityDistrict";
import { IconChevronDown } from "@tabler/icons-react";
import { InputArea, SelectDropdown } from "../../../../components/isbul-ui";
import {
  createQueryParamsArrayFromSelectedValues,
  createQueryParamsFromSelectedValues,
} from "../../../../functions/FilterAdvertFunctions";
import { refreshQueryParam } from "../../../../hooks/useAddOrUpdateQueryParam";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";

const axios = new Axios();
interface WorkingLocationPopupProps {
  onClose: () => void;
  onSuccess?: () => void;
}

const InitialSelections = {
  cities: [],
  districts: [],
  workingTypes: [],
  positions: [],
  workingLocations: [],
  sections: [],
  sectors: [],
  advertDate: [],
  experience: [],
  disabled: [],
  other: [],
  positionLevels: [],
  filters: [],
  company: [],
  search: [],
  isFilterUpdated: false,
};

//  {{local_server}}/v1/cdn/get-group-1
//  açılış datasının alınacağı cdn ucu.
const CreateWorkAlarmPopup: React.FC<WorkingLocationPopupProps> = ({
  onClose,
  onSuccess,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialDatas, setInitialDatas] = useState<any>({});
  const [seeAll, setseeAll] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<any>(InitialSelections);
  const [creating, setCreating] = useState<boolean>(false);
  const [period, setPeriod] = useState<"DAILY" | "WEEKLY" | "MONTHLY">("DAILY");

  const location = useLocation();
  const handleSubmit = () => {
    if (creating) return;
    if (title.trim() === "") {
      setIsError(true);
      Swal.fire({
        icon: "error",
        text: "Başlık alanı boş bırakılamaz!",
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "sweet-alert-button", // TailwindCSS classes
        },
      });
      return;
    }

    console.log(
      "selectedValuesAraray",
      createQueryParamsArrayFromSelectedValues(selectedValues)
    );
    console.log(
      "selectedValuesString",
      createQueryParamsFromSelectedValues(selectedValues)
    );
    let queryParams = createQueryParamsFromSelectedValues(selectedValues);

    const params_string = refreshQueryParam(location, queryParams);

    let params = createQueryParamsArrayFromSelectedValues(selectedValues);

    params = {
      ...params,
      ...{
        create_alarm: "on",
        period, // ENUM('DAILY', 'WEEKLY', 'MONTHLY')
        search_type: "all",
      },
    };

    if (queryParams.length === 0) {
      Swal.fire({
        icon: "error",
        text: "Alarm oluşturmak için en az bir filtre belirlemelisiniz!",
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "sweet-alert-button", // TailwindCSS classes
        },
      });
      return;
    }
    setCreating(true);
    axios
      .userAuthRequest({
        type: "post",
        endpoint: "user/profilim/alarms/create",
        payload: {
          params_string: params_string,
          title: title,
          params: JSON.stringify(params),
          period,
        },
      })
      .then((response: any) => {
        setCreating(false);
        Swal.fire({
          icon: "success",
          text: "İş Alarmı Başarıyla Oluşturuldu!",
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "sweet-alert-button", // TailwindCSS classes
          },
        });
        setTitle("");
        handleCloseClick(true);
      })
      .catch((error: any) => {
        setCreating(false);
        Swal.fire({
          icon: "error",
          text: error.response.data.errors.title[0],
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "sweet-alert-button", // TailwindCSS classes
          },
        });
      });
  };

  const handleChangeTitle = (e: any) => {
    if (e.target.value.trim() !== "") {
      setIsError(false);
    }
    setTitle(e.target.value);
  };

  useEffect(() => {
    // TODO bu sorgu react query ye çevrilecek
    axios
      .userAuthRequest({
        type: "get",
        endpoint: "cdn/get-group-1",
        payload: {},
      })
      .then((response: any) => {
        setLoading(false);
        setInitialDatas(response.data.data);
      });

    const timeout = setTimeout(() => {
      document.body.classList.add("overflow-hidden", "h-auto");
      setIsVisible(true);
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  const handleCloseClick = (isSuccess = false) => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
      if (isSuccess && onSuccess) {
        onSuccess();
      }
      document.body.classList.remove("overflow-hidden", "h-auto");
    }, 500);
  };

  return (
    <div className="fixed inset-0 z-[1050] flex items-start justify-center overflow-y-auto ">
      <div
        className="fixed inset-0 bg-black opacity-50 h-auto "
        onClick={() => {
          handleCloseClick();
        }}
      ></div>

      <div
        className={`bg-white my-10 max-md:my-0 max-md:fixed max-md:top-0 max-md:left-0 max-md:w-screen max-md:h-screen max-md:overflow-y-auto md:rounded-lg md:shadow-lg  md:relative md:min-w-[40%]  transition-all duration-500 ease-in-out transform ${
          isVisible ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-10"
        }`}
      >
        <div className={"grid grid-cols-3 items-center border-b-2 py-5 px-5"}>
          <div></div>
          <div className={"text-mains justify-self-center whitespace-nowrap"}>
            İŞ ALARMI OLUŞTUR
          </div>
          <div
            onClick={() => {
              handleCloseClick();
            }}
            className={
              "flex justify-self-end justify-center items-center w-5 h-5 "
            }
          >
            <FaTimes className={"text-gray-400"} />
          </div>
        </div>
        <div className={"px-10 py-3 text-gray-600"}>
          Hayalindeki iş için uygun filtreleri ekleyerek iş alarmı
          oluşturabilirsin.
        </div>

        {loading ? (
          <div
            className={"flex p-10 w-full h-full justify-center items-center"}
          >
            <LuLoader2 className={"w-14 h-14 text-gray-400 animate-spin"} />
          </div>
        ) : (
          <>
            <div className={"py-3 px-10"}>
              <InputArea
                error={isError}
                className="w-full"
                required
                mode={title.length == 0 ? "empty" : "dirty"}
              >
                <InputArea.Label>Başlık</InputArea.Label>
                <InputArea.Input
                  value={title || ""}
                  onChange={(e) => {
                    handleChangeTitle(e);
                  }}
                />
              </InputArea>
            </div>

            <div className={"px-10 py-3 "}>
              <div className={"py-3"}>
                <FilterAccordionWorkAlarmCityDistrict
                  name={`Şehir / İlçe Ara ${
                    selectedValues.cities?.length > 0 ||
                    selectedValues.districts?.length > 0
                      ? `(${
                          selectedValues.cities.length +
                          selectedValues.districts.length
                        })`
                      : ""
                  }`}
                  placeHolder="Şehir veya İlçe Ara"
                  api={{
                    endoint: "get-cities-or-districts",
                    apiWay: "cities_or_districts",
                    propertyName: "name",
                  }}
                  initialDatas={initialDatas.cities_or_districts}
                  onChange={(e: { cities: IItem[]; districts: IItem[] }) =>
                    setSelectedValues({
                      ...selectedValues,
                      cities: e.cities,
                      districts: e.districts,
                    })
                  }
                />
              </div>
              <div className={"py-3"}>
                <FilterAccordionWorkAlarm
                  name={`Pozisyon / Meslek Seçimi ${
                    selectedValues.positions.length > 0
                      ? `(${selectedValues.positions.length})`
                      : ""
                  }`}
                  placeHolder="Ara..."
                  api={{
                    endoint: "get-positions",
                    apiWay: "positions",
                    propertyName: "title",
                  }}
                  isOpen={false}
                  initialDatas={initialDatas.positions}
                  onChange={(e) => {
                    console.log("aksiyon alındı", e);
                    setSelectedValues({ ...selectedValues, positions: e });
                  }}
                />
              </div>
              <div className={"py-3"}>
                <FilterAccordionWorkAlarm
                  name={`Çalışma Tercihi ${
                    selectedValues.workingLocations.length > 0
                      ? `(${selectedValues.workingLocations.length})`
                      : ``
                  }`}
                  api={{
                    endoint: "get-working-locations",
                    apiWay: "working_locations",
                    propertyName: "title",
                  }}
                  initialDatas={initialDatas.working_locations}
                  onChange={(e) =>
                    setSelectedValues({
                      ...selectedValues,
                      workingLocations: e,
                    })
                  }
                />
              </div>
              <div className={`${seeAll ? "" : "hidden"}`}>
                <div className={"py-3"}>
                  <FilterAccordionWorkAlarm
                    name={`Bölüm ${
                      selectedValues.sections.length > 0
                        ? `(${selectedValues.sections.length})`
                        : ""
                    }`}
                    placeHolder="Bölüm Ara"
                    api={{
                      endoint: "get-sections",
                      apiWay: "sections",
                      propertyName: "title",
                    }}
                    initialDatas={initialDatas.sections}
                    onChange={(e) =>
                      setSelectedValues({ ...selectedValues, sections: e })
                    }
                  />
                </div>
                <div className={"py-3"}>
                  <FilterAccordionWorkAlarm
                    name={`Firma Sektörü ${
                      selectedValues.sectors.length > 0
                        ? `(${selectedValues.sectors.length})`
                        : ""
                    }`}
                    placeHolder="Sektör Ara"
                    api={{
                      endoint: "get-sectors",
                      apiWay: "sectors",
                      propertyName: "title",
                    }}
                    initialDatas={initialDatas.sectors}
                    onChange={(e) =>
                      setSelectedValues({ ...selectedValues, sectors: e })
                    }
                  />
                </div>
                <div className={"py-3"}>
                  <FilterAccordionWorkAlarm
                    name={`Pozisyon Seviyesi ${
                      selectedValues.positionLevels.length > 0
                        ? `(${selectedValues.positionLevels.length})`
                        : ""
                    }`}
                    api={{
                      endoint: "get-position-levels",
                      apiWay: "position_levels",
                      propertyName: "name",
                    }}
                    initialDatas={initialDatas.position_levels}
                    onChange={(e) =>
                      setSelectedValues({
                        ...selectedValues,
                        positionLevels: e,
                      })
                    }
                  />
                </div>
                <div className={"py-3"}>
                  <FilterAccordionWorkAlarm
                    name={`İlan Tarihi ${
                      selectedValues.advertDate.length > 0
                        ? `(${selectedValues.advertDate.length})`
                        : ""
                    }`}
                    items={adDatesData}
                    initialDatas={adDatesData}
                    onChange={(e) => {
                      console.log("aksiyon alındı", e);
                      setSelectedValues({ ...selectedValues, advertDate: e });
                    }}
                    maxSelect={1}
                  />
                </div>
                <div className={"py-3"}>
                  <FilterAccordionWorkAlarm
                    name={`Çalışma Şekli ${
                      selectedValues.workingTypes.length > 0
                        ? `(${selectedValues.workingTypes.length})`
                        : ""
                    }`}
                    api={{
                      endoint: "get-working-types",
                      apiWay: "working_types",
                      propertyName: "title",
                    }}
                    initialDatas={initialDatas.workingTypes}
                    onChange={(e) =>
                      setSelectedValues({ ...selectedValues, workingTypes: e })
                    }
                  />
                </div>
                <div className={"py-3"}>
                  <FilterAccordionWorkAlarm
                    name={`Deneyim ${
                      selectedValues.experience.length > 0
                        ? `(${selectedValues.experience.length})`
                        : ""
                    }`}
                    items={experienceData}
                    initialDatas={experienceData}
                    onChange={(e) =>
                      setSelectedValues({ ...selectedValues, experience: e })
                    }
                    maxSelect={1}
                  />
                </div>
                <div className={"py-3"}>
                  <FilterAccordionWorkAlarm
                    name={`Engelli İlanı ${
                      selectedValues.disabled.length > 0
                        ? `(${selectedValues.disabled.length})`
                        : ""
                    }`}
                    items={disabledData}
                    initialDatas={disabledData}
                    onChange={(e) =>
                      setSelectedValues({ ...selectedValues, disabled: e })
                    }
                    maxSelect={1}
                  />
                </div>
                <div className={"py-3"}>
                  <FilterAccordionWorkAlarm
                    name={`Diğer Seçenekler ${
                      selectedValues.other.length > 0
                        ? `(${selectedValues.other.length})`
                        : ""
                    }`}
                    items={otherDataUser}
                    initialDatas={otherDataUser}
                    onChange={(e) =>
                      setSelectedValues({ ...selectedValues, other: e })
                    }
                  />
                </div>
              </div>
              <div
                onClick={() => {
                  setseeAll(!seeAll);
                }}
                className={"text-mains font-bold cursor-pointer flex "}
              >
                {seeAll ? "Daha az gör" : "Tümünü gör"}
                <IconChevronDown
                  className={`${seeAll && "rotate-180"} transition-all`}
                />
              </div>
            </div>

            <div className="px-10">
              <div className="py-3">
                <SelectDropdown required mode="dirty">
                  <SelectDropdown.Label>
                    E-posta Gönderim Sıklığı
                  </SelectDropdown.Label>
                  <SelectDropdown.Trigger>
                    {period == "DAILY"
                      ? "Günlük E-posta Gönder"
                      : period == "WEEKLY"
                      ? "Haftalık E-posta Gönder"
                      : period == "MONTHLY"
                      ? "Aylık E-posta Gönder"
                      : ""}
                  </SelectDropdown.Trigger>
                  <SelectDropdown.Items>
                    <SelectDropdown.Item onClick={() => setPeriod("DAILY")}>
                      Günlük E-posta Gönder
                    </SelectDropdown.Item>
                    <SelectDropdown.Item onClick={() => setPeriod("WEEKLY")}>
                      Haftalık E-posta Gönder
                    </SelectDropdown.Item>
                    <SelectDropdown.Item onClick={() => setPeriod("MONTHLY")}>
                      Aylık E-posta Gönder
                    </SelectDropdown.Item>
                  </SelectDropdown.Items>
                </SelectDropdown>
              </div>
            </div>

            <div className={"p-10 border-t-2 flex justify-end"}>
              <button
                className={` flex py-3 px-7 items-center gap-x-2 rounded-md h-14 ${
                  creating ? "bg-gray-600" : "bg-[#1f4ca1]"
                }`}
                onClick={() => handleSubmit()}
              >
                {!creating ? (
                  <span className="text-white poppins-medium text-sm leading-[21px]">
                    İş Alarmı Kur
                  </span>
                ) : (
                  <LuLoader2 className={"animate-spin text-add"} />
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateWorkAlarmPopup;
