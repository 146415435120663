import React, { useState } from "react";
import { FaRegBuilding } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import { IoPeopleOutline, IoTimeOutline } from "react-icons/io5";
import { FiUsers } from "react-icons/fi";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegCalendarAlt } from "react-icons/fa";
import { Location } from "../body/index";
import { TbDisabled } from "react-icons/tb";
import { Button, Modal } from "antd";
import { CiMail } from "react-icons/ci";
import { BsEye } from "react-icons/bs";

interface AdvertData {
  is_disabled_employee: boolean;
  created_at: string;
  cuff_end_date: string;
  updated_at: string;
}

interface IGeneralBaseInfoProps {
  title: string;
  companyFullName: string;
  locations: Location[] | undefined;
  companyLogo: string | undefined;
  advertData: AdvertData;
  advertLanguage: number;
  isDisabledEmployee: number;
}
const GeneralBaseInfo: React.FC<IGeneralBaseInfoProps> = ({
  title,
  companyFullName,
  locations,
  companyLogo,
  advertData,
  advertLanguage,
  isDisabledEmployee
}) => {

  const turkishLanguageId = 9
  const englishLanguageId = 7

  const isEnglish: boolean = advertLanguage == englishLanguageId
  //console.log(companyLogo, "companyLogo");
  const [isHovering, setIsHovering] = useState(false);
  function formatDate(dateString: string): string {
    console.log(dateString);

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("tr-TR", options); // Correctly typed options
  }
  //console.log('company logo', companyLogo);

  //console.log(advertData?.is_disabled_employee);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //console.log("advert data", locations);
  return (
    <div className="flex flex-col gap-2 bg-white">
      <div className="bg-white flex justify-start items-start px-4 py-2 lg:px-8 lg:py-8 font-poppins">
        {/*Image Section */}
        <div className="flex-shrink-0">
          {/* <img
            src="https://isbull.s3.eu-north-1.amazonaws.com/default-images/company-logo.png"
            alt=""
            className="h-auto md:h-[200px] md:w-[200px] w-32 rounded"
          /> */}
          <img
            src="https://isbull.s3.eu-north-1.amazonaws.com/default-images/company-logo.png"
            alt=""
            className="h-auto md:h-[200px] md:w-[200px] w-16 rounded"
          />
        </div>
        <div className="relative">
          {/*Informaiotn List Section */}
          <ul className="ml-8 md:pl-5 space-y-0 lg:space-y-2">
            <li className="md:text-3xl text-mains font-medium flex items-center">
              {title}
              {advertData?.is_disabled_employee === true ? (
                <>
                  <TbDisabled className="text-mains ml-2" />
                </>
              ) : (
                <></>
              )}
            </li>
            <li className="md:text-lg text-xs">
              <a href="#" className="flex items-center">
                <FaRegBuilding className="mr-2 text-mains" />{" "}
                <span className="md:font-medium text-[#363636]">
                  {companyFullName}
                </span>
              </a>
            </li>
            {locations && locations.length > 0 && (
              <li className="md:text-lg text-xs flex items-center">
                <FiMapPin className="mr-2 text-mains flex-shrink-0" />
                <span className="text-[#363636]">
                  {locations[0].city?.name} -{" "}
                  {locations[0].district != null
                    ? `(${locations[0].district?.name == 'Tüm İlçeler' && isEnglish? `All Districts`: `${locations[0].district?.name}`})`
                    : `(${isEnglish? 'All Districts': 'Tüm İlçeler'})`}
                  
                  <span onClick={showModal}>
                    {locations.length > 1 ? ` (+${locations.length - 1})` : ""}
                  </span>
                </span>
              </li>
            )}

            <Modal
              title={isEnglish? 'LOCATIONS': 'LOKASYONLAR'}
              open={isModalOpen}
              footer={null}
              onOk={handleOk}
              onCancel={handleCancel}
            >
              {locations &&
                locations.map((location, index) => (
                  <div key={index} className="flex items-center mb-1">
                    <div className=" text-gray-700 lg:text-gray-700 text-sm font-poppins">
                      {location.city?.name} -{" "}
                      {location.district != null
                        ? `${location.district?.name == 'Tüm İlçeler' && isEnglish? `All Districts`: `${location.district?.name}`}`
                        : `(${isEnglish? 'All Districts': 'Tüm İlçeler'})`}
                    </div>
                  </div>
                ))}
            </Modal>

            <li className="md:text-lg text-sm hidden lg:flex">
              <div className="md:flex md:justify-between md:space-x-4">
                <div className="flex items-center mb-2 md:m-0">
                  <IoTimeOutline className="mr-2 text-gray-200 lg:text-mains" />
                  <span className="text-gray-200 lg:text-[#363636]">
                    {formatDate(advertData?.created_at)}
                  </span>
                </div>
                <div className="flex items-center mb-2 md:m-0"></div>
                <div className="flex items-center mb-2 md:m-0"></div>
              </div>
            </li>
            <li
              onMouseEnter={() => {
                //console.log("Mouse entered, showing card");
                setIsHovering(true);
              }}
              onMouseLeave={() => {
                //console.log("Mouse left, hiding card");
                setIsHovering(false);
              }}
              className="items-center md:text-lg text-sm hidden lg:flex"
            >
              <FaRegCalendarAlt className="mr-2 text-mains" />
              <span className="text-[#363636]">
                {isEnglish? 'Updated': 'Güncellendi'}
              </span>
            </li>
          </ul>
          {isHovering && advertData && (
            <div className="absolute bottom-0  translate-y-full left-6 z-10">
              <div className="triangle-white ml-4"></div>
              <div className="bg-white shadow-black shadow-xl flex justify-start items-start p-8 font-poppins rounded">
                <div className="text-sm">
                  <p className="mb-3 mt-3">
                    <span className="font-semibold">{isEnglish? 'First Publish Date': 'İlk Yayınlama Tarihi'}:</span>{" "}
                    {formatDate(advertData.created_at)}
                  </p>
                  <hr className="mb-2"></hr>
                  <p className="mb-3">
                    <span className="font-semibold">{isEnglish? 'Last Update Date': 'Günceleme Tarihi'}:</span>{" "}
                    {formatDate(advertData.cuff_end_date)}
                  </p>
                  <hr className="mb-2"></hr>
                  <p>
                    <span className="font-semibold">{isEnglish? 'Advertisement End Date': 'İlan Bitiş Tarihi'}: </span>{" "}
                    {formatDate(advertData.updated_at)}
                  </p>
                  <hr className="mt-2"></hr>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <hr className="block lg:hidden"/>
      <div className="flex lg:hidden flex-row items-center justify-center gap-2 p-2">
        <a href={`#`} className="flex flex-row items-center gap-2 bg-white bg-opacity-10 px-2 py-1 rounded-full text-xs text-mains">
          <CiMail className="text-sm"/>
          Mesaj Gönder
        </a>
        <div className="flex flex-row items-center gap-2 text-xs text-mains">
          <IoPeopleOutline className="text-sm"/>
          0-10 Başvuru
        </div>
        <div className="flex flex-row items-center gap-2 text-xs text-mains">
          <BsEye className="text-sm"/>
          0
        </div>
      </div>
    </div>
  );
};
export default GeneralBaseInfo;
