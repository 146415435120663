import React, { useEffect, useState } from "react";
import Axios from "../../../services/axios";
import Card from "../setting-page/content/Card";
import Swal from "sweetalert2";
import { CgTrash } from "react-icons/cg";
import { PiPlus } from "react-icons/pi";
import Fancybox from "../../../components/layout/fancybox";
import Button from "../../../components/form-components/button";
import { IoAdd } from "react-icons/io5";
import FilePickerModal from "./components/FilePickerModal";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiAlertCircle } from "react-icons/fi";
import services from "../../../services/profile-services";

type Props = {};
export type Photo = {
  id: number;
  title: string;
  compony_id: number;
  type: string;
  file: string;
  created_at: string;
};
const axios = new Axios();
const GalleryPage = (props: Props) => {
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [filteredPhotos, setFilteredPhotos] = useState<Photo[]>([]);
  const [selectedPhoto, setSelectedPhoto] = useState<Photo[]>([]);
  const [editingPhoto, setEditingPhoto] = useState<Photo | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isFilePickerOpen, setIsFilePickerOpen] = useState<boolean>(false);
  const getPhoto = async () => {
    try {
      const response = await axios.authRequest({
        endpoint: "companies/advert/get-company-files?type=image",
        type: "get",
        payload: {},
      });
      setPhotos(response.data.files);
      setFilteredPhotos(response.data.files);
    } catch (error) {
      console.log(error);
    }
  };
  // delete photo
  const deletePhoto = async (ids: string[]) => {
    /* try {
      const response = await axios.authRequest({
        endpoint: "companies/reference/delete-by-selected",
        type: "delete",
        payload: { data: { ids } },
      });
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Başarılı",
          text: "Görsellerim başarıyla silindi.",
        });
        getPhoto();
      }
    } catch (error) {
      console.log(error);
    } */
  };

  useEffect(() => {
    getPhoto();
  }, []);

  useEffect(() => {
    if (!modalOpen) {
      getPhoto();
    }
  }, [modalOpen]);

  console.log(photos);

  return (
    <div className="container mx-auto flex flex-col gap-3 min-h-screen">
      <Card className="mt-6">
        <div className="flex justify-between">
          <h1 className="text-sm lg:text-2xl font-bold text-title">
            Kayıtlı Görsellerim
          </h1>
          {/* <CreateModal  open={modalOpen} setOpen={setModalOpen} /> */}
          <Button
            className="hidden lg:flex"
            onClick={() => {
              setIsFilePickerOpen(true);
            }}
          >
            <Button.Icon>
              <IoAdd />
            </Button.Icon>
            GÖRSEL EKLE
          </Button>
          <button
            className="block lg:hidden underline text-mains text-sm"
            onClick={() => setIsFilePickerOpen(true)}
          >
            Görsel Ekle
          </button>
          {isFilePickerOpen && (
            <FilePickerModal
              selectedPhoto={editingPhoto}
              getPhoto={getPhoto}
              setIsOpen={setIsFilePickerOpen}
            />
          )}
        </div>
      </Card>

      {photos.length > 0 && (
        <div className="flex-nowrap px-2 justify-around my-5 bg-white py-3 rounded-md border-t-2  md:flex hidden">
          <div className="w-full">
            <h2 className="text-xl text-title font-bold">Görsel Başlığı</h2>
          </div>
          <div className="w-full">
            <h2 className="text-xl text-title font-bold text-center">
              Görsel Linki
            </h2>
          </div>
          <div className="w-full">
            <h2 className="text-xl text-title font-bold text-end px-4">
              İşlemler
            </h2>
          </div>
        </div>
      )}

      {photos.length > 0 ? (
        <Card className="!p-0">
          <div className="flex flex-wrap gap-3">
            {filteredPhotos.map((item) => (
              <div
                className="bg-white py-2 rounded-md w-full border-b  ps-9  relative"
                key={item.id}
              >
                <div className="flex flex-row items-center w-full lg:w-auto md:flex-nowrap flex-wrap my-5">
                  <div className="md:ps-0 w-auto ps-4 flex items-center lg:w-1/2">
                    <h3 className="md:font-normal font-semibold">
                      {item.title}
                    </h3>
                  </div>
                  <div className=" hidden lg:block max-w-52">
                    <Fancybox
                      options={{
                        Carousel: {
                          infinite: false,
                        },
                      }}
                    >
                      <a
                        data-fancybox="gallery"
                        href={item.file}
                        className="whitespace-nowrap text-ellipsis overflow-hidden line-clamp-1"
                      >
                        {item.file.slice(0, 27)}
                        {item.file.length > 27 ? "..." : ""}
                      </a>
                    </Fancybox>
                  </div>
                  <div className="md:w-full ml-auto w-auto lg:w-1/2 flex items-end justify-center">
                    <div className="ml-auto px-4 relative">
                      <div className="peer">
                        <BsThreeDotsVertical className="text-xl" />
                      </div>
                      <div className="absolute top-2 right-4 hidden hover:flex flex-col peer-hover:flex w-24 py-2 px-1 gap-1 bg-white z-40 border rounded-md shadow-md">
                        <a
                          href={item.file}
                          className="flex lg:hidden flex-col w-full p-1 border rounded-sm bg-white text-mains hover:text-white hover:bg-mains transition-all"
                        >
                          Görüntüle
                        </a>
                        <button
                          className="flex flex-col w-full p-1 border rounded-sm bg-white text-mains hover:text-white hover:bg-mains transition-all"
                          onClick={() => {
                            setEditingPhoto((prev) => item);
                            setIsFilePickerOpen(true);
                          }}
                        >
                          Düzenle
                        </button>
                        <button
                          className="flex flex-col w-full p-1 border rounded-sm bg-white text-mains hover:text-white hover:bg-mains transition-all"
                          onClick={() => deleteImage(item.id)}
                        >
                          Sil
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Card>
      ) : (
        <div className="flex items-stretch mt-8 mb-12 ml-4 mr-4 shadow-lg bg-white rounded-md">
          <span className="flex items-center justify-center bg-red-500 text-white p-3 rounded-l-md">
            <FiAlertCircle size={35} />
          </span>
          <p className="flex items-center font-poppins text-sm poppins-medium p-4">
            Kayıtlı görsellerinizi bu alandan inceleyebilirsiniz, şu anda
            oluşturduğunuz kayıtlı görseliniz bulunmamaktadır.
          </p>
        </div>
      )}

      <div className="py-2 flex justify-end w-full">
        {selectedPhoto.length > 0 && (
          <button
            className="px-4 py-3 flex gap-3 items-center justify-center text-red-600 md:w-auto w-full"
            onClick={() => {
              Swal.fire({
                icon: "warning",
                title: "İşlemi onaylayın",
                text: "Seçili görseli silmek istediğinize emin misiniz?",
                confirmButtonColor: "#2253af",
                confirmButtonText: "Evet",
                cancelButtonText: "Hayır",
                showCancelButton: true,
                showCloseButton: true,
                preConfirm: async () => {
                  await deletePhoto(selectedPhoto.map((item: any) => item.id));
                },
              });
            }}
          >
            <CgTrash className="text-xl" />
            <span>Seçilenleri Sil</span>
          </button>
        )}
      </div>
    </div>
  );

  async function deleteImage(imageId: number) {
    Swal.fire({
      html: `<div class="text-red-500"> <p>Görseli silmek istediğinize emin misiniz?</p> </div>`,
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
      confirmButtonColor: "#2253af",
      cancelButtonColor: "#6c757d",
    }).then((result) => {
      const doThis = async () => {
        try {
          const response = await axios.authRequest({
            endpoint: `companies/advert/delete-file/${imageId}`,
            type: "delete",
            payload: {},
          });

          if (response.status == 200) {
            getPhoto();
            Swal.fire({
              title: "Başarılı",
              text: "Görsel başarıyla silindi",
              icon: "success",
              confirmButtonText: "Tamam",
            });
          } else {
            Swal.fire({
              title: "Hata",
              icon: "error",
              text: "Görsel silinirken bir hata meydana geldi",
              confirmButtonText: "Tamam",
            });
          }
        } catch (error) {
          console.log(`Image delete failed. Error: ${error}`);
          Swal.fire({
            title: "Hata",
            text: "Görsel silinirken beklenmedik bir hata meydana geldi.",
            icon: "error",
            confirmButtonText: "Tamam",
          });
        }
      };
      if (result.isConfirmed) {
        doThis();
      }
    });
  }
};

export default GalleryPage;
