import { Dispatch, HTMLAttributes, SetStateAction, createContext } from "react";
import classNames from "classnames";
import ArrowLeftButton from "./ArrowLeftButton";
import ArrowRightButton from "./ArrowRightButton";
import Items from "./Items";
import PageButton from "./PageButton";
import PageLink from "./PageLink";

export type paginationContextType = {
  currentPage: number;
  firstPageNumber: number;
  lastPageNumber: number;
};

type paginationPropsType = HTMLAttributes<HTMLDivElement> & {
  currentPage?: number;
  firstPageNumber?: number;
  lastPageNumber?: number;
};

export const PaginationContext = createContext<paginationContextType>({
  currentPage: 0,
  firstPageNumber: 0,
  lastPageNumber: 0,
});

export function Pagination({
  className,
  currentPage = 0,
  firstPageNumber = 0,
  lastPageNumber = 0,
  ...props
}: paginationPropsType) {
  const cn = classNames({
    "flex flex-row items-center gap-1": true,
  });

  const value: paginationContextType = {
    currentPage,
    firstPageNumber,
    lastPageNumber,
  };
  return (
    <PaginationContext.Provider value={value}>
      <div className={`${cn} ${className}`} {...props}></div>
    </PaginationContext.Provider>
  );
}

Pagination.ArrowLeftButton = ArrowLeftButton;
Pagination.ArrowRightButton = ArrowRightButton;
Pagination.Items = Items;
Pagination.PageButton = PageButton;
Pagination.PageLink = PageLink;
