import {
  useEffect,
  useRef,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";
import {
  cdnDistrictType,
  getDistrictsResponseType,
} from "../../../../../../types";
import { locationRowContextType, LocationRowContext } from "./LocationRow";
import { FaChevronDown } from "react-icons/fa6";
import Axios from "../../../../../../services/axios";
import Swal from "sweetalert2";
import { WorkingLocationsContext, workingLocationContextType } from ".";
import { fieldErrorType } from "../../../../../../containers/company-page-container/creat-ads-page/advert-create-form";

const axios = new Axios();

export default function DistrictSelect({
  setFieldErrors,
}: {
  setFieldErrors?: Dispatch<SetStateAction<fieldErrorType>>;
}) {
  const divRef = useRef<HTMLDivElement>(null);

  const {
    selectedCity,
    selectedDistrict,
    setSelectedDistrict,
  }: locationRowContextType = useContext(LocationRowContext);

  const { isDistrictError, workingLocations }: workingLocationContextType =
    useContext(WorkingLocationsContext);

  const [districts, setDistricts] = useState<cdnDistrictType[]>([]);
  const [filteredDistricts, setFilteredDistricts] = useState<cdnDistrictType[]>(
    []
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        if (selectedDistrict) {
          setSearchTerm(selectedDistrict.name);
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [divRef]);

  async function searchByTerm() {
    if (searchTerm.trim().length > 0 && selectedCity) {
      try {
        const response: getDistrictsResponseType = await axios.request({
          endpoint: `cdn/get-districts?city_id=${selectedCity.id}&search=${searchTerm}&limit=300`,
          type: "get",
        });

        setFilteredDistricts(response.data.districts);
      } catch (error) {
        console.log(`Districts fetch failed. Error:${error}`);
        setFilteredDistricts([]);
      }
    } else {
      if (districts.length == 0) {
        setFilteredDistricts(districts);
      } else {
        const defaultSelectItem: cdnDistrictType = {
          id: -1,
          city_id: selectedCity?.id || -1,
          created_at: "",
          featured: 0,
          name: "Tüm İlçeler",
          slug: "tum-ilceler",
          updated_at: "",
        };
        setFilteredDistricts([defaultSelectItem].concat(districts));
      }
    }
  }

  useEffect(() => {
    const timeOut = setTimeout(() => searchByTerm(), 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchTerm]);

  async function getDistricts() {
    try {
      if (selectedCity?.id != 7795) {
        const response: getDistrictsResponseType = await axios.request({
          endpoint: `cdn/get-districts?limit=100&city_id=${selectedCity?.id}`,
          type: "get",
        });
        const defaultSelectItem: cdnDistrictType = {
          id: -1,
          city_id: selectedCity?.id || -1,
          created_at: "",
          featured: 0,
          name: "Tüm İlçeler",
          slug: "tum-ilceler",
          updated_at: "",
        };
        setDistricts(response.data.districts);
        setFilteredDistricts(
          [defaultSelectItem].concat(response.data.districts)
        );
      } else {
        const defaultSelectItem: cdnDistrictType = {
          id: -1,
          city_id: selectedCity?.id || -1,
          created_at: "",
          featured: 0,
          name: "Tüm İlçeler",
          slug: "tum-ilceler",
          updated_at: "",
        };
        setFilteredDistricts([defaultSelectItem]);
      }
    } catch (error) {
      console.log(`Districts fetch failed. Error:${error}`);
      setDistricts([]);
      setFilteredDistricts([]);
    }
  }

  useEffect(() => {
    if (selectedCity) {
      getDistricts();
    }
  }, [selectedCity]);
  return (
    <div ref={divRef} className={`flex relative w-full`}>
      <div
        className={`border ${
          selectedDistrict ? "border-blue-500" : "border-gray-400"
        } ${
          isDistrictError && !selectedDistrict
            ? "bg-red-50 border-red-600"
            : "bg-white"
        } ${
          isOpen ? "shadow-form-glow" : "shadow-none"
        } w-full rounded-md p-2 `}
        onClick={() => {
          if (!selectedCity) {
            Swal.fire({
              icon: "error",
              title: "Hata",
              text: "Lütfen ilk önce il seçiniz.",
              confirmButtonText: "Tamam",
            });

            if (setFieldErrors) {
              setFieldErrors(({ city, ...others }) => ({
                city: true,
                ...others,
              }));
            }
          } else {
            setIsOpen(true);
          }
        }}
      >
        {isOpen ? (
          <input
            className="outline-none bg-transparent w-full font-poppins text-[13px]"
            placeholder="İlçe Seçiniz"
            value={searchTerm || ""}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        ) : (
          <label
            className={`font-poppins text-[13px] ${
              selectedDistrict ? "text-blue-500" : "text-gray-500"
            } ${isDistrictError && !selectedDistrict ? "text-red-600" : ``}`}
          >
            {selectedDistrict ? `${selectedDistrict.name}` : `İlçe Seçiniz`}
          </label>
        )}

        <div className={`absolute right-4 top-4`}>
          <FaChevronDown
            className={`${
              selectedDistrict ? "text-blue-500" : "text-gray-600"
            } text-xs ${isOpen ? "rotate-180" : "rotate-0"} transition-all`}
          />
        </div>
      </div>
      {isOpen && (
        <div className="absolute bottom-0 left-0 translate-y-full w-full bg-white border border-[#9ca3af] rounded-md flex flex-col gap-2 p-2 shadow max-h-[200px] overflow-auto z-[1]">
          {filteredDistricts.map((district) => {
            let disabled = false;

            /* if (workingLocations.find((location) => location.ditrictId == district.id)) {
                            disabled = true
                        } */

            const selectedSameCities = workingLocations.filter(
              (location) => location.cityId == selectedCity?.id
            );

            if (selectedSameCities) {
              if (
                selectedSameCities.find(
                  (sameCity) => sameCity.ditrictId == district.id
                )
              ) {
                disabled = true;
              }
            }
            return (
              <button
                key={`districtSelect${district.id}`}
                type="button"
                className="hover:bg-gray-100 disabled:bg-gray-100 disabled:hover:bg-gray-100 transition-all text-start p-2"
                onClick={() => {
                  if (disabled) {
                    Swal.fire({
                      icon: "error",
                      title: "Hata",
                      text: "Aynı ilçeyi iki kez seçemezsiniz.",
                      confirmButtonText: "Tamam",
                    });

                    if (setFieldErrors) {
                      setFieldErrors(({ district, ...others }) => ({
                        district: true,
                        ...others,
                      }));
                    }
                    return;
                  }
                  setSelectedDistrict(district);
                  setIsOpen(false);
                }}
              >
                {district.name}
              </button>
            );
          })}
          {filteredDistricts.length == 0 && (
            <div className="text-center text-gray-500">Sonuç Bulunamadı</div>
          )}
        </div>
      )}
    </div>
  );
}
