import Header from "./contents/header/index"
import Body from "./contents/body/index";
import { useEffect, useState } from "react";
import Axios from "../../../services/axios";
import { useLocation, useParams } from "react-router-dom";
import Cookies from "js-cookie";


type RouteParams = {
  [key: string]: string | undefined;
};


const axios = new Axios()

const CompanyShow: React.FC = () => {

    const { slug } = useParams<RouteParams>();
    // console.log("slug ",slug)
    const location = useLocation()
    const [companydata, setCompanydata] = useState<any>(location.state);
    const [companyData, setCompanyData] = useState<any>(null)


  

    const userToken = Cookies.get("userToken") || Cookies.get("companyUserToken");
    
    /* useEffect(() => {
        console.log("SATA",companydata)
    
    }, [companydata]); */

    async function getCompanyDatas() {
      try {
        const response = await axios.request({
          endpoint: `companies/company-detail/${slug}/get-company-detail`,
          type: 'get'
        })

        if (response && response.data && response.data.data && response.data.data.company_detail) {
          const resCompanyData = response.data.data.company_detail
          setCompanyData(resCompanyData)
          console.log('res', resCompanyData)
          console.log('state', companydata)
        } else {

        }
      } catch (error) {
        console.log(`Error: ${error}`)
      }
    }

    useEffect(() => {
      getCompanyDatas()
    }, [])


    return (
    <div className="font-poppins">
      {companyData && (
        <>
        <Header companyData={companyData}/>
        <Body companyData={companyData}/>
        </>
      )}
    </div>
    );
}
export default CompanyShow;