





type useYearsPropsType = {
    startYear: number,
    endYear: number
}







export function useYears({
    endYear,
    startYear
}: useYearsPropsType): number[] {
    const years: number[] = Array.from({ length: startYear - endYear + 1 }, (_, i) => (i + endYear))

    return years.sort((a, b) => b - a)
}