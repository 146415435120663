import { Link } from "react-router-dom";





export default function InfoCard({
    advertCount = 0,
    isLoading
}: {
    advertCount?: number,
    isLoading?: boolean
}) {
    return(
        <div>
            <div className="hidden md:hidden lg:flex flex-col items-center p-4 bg-gray-100 rounded-lg shadow-xl mr-10 py-12 px-8 border">
                {!isLoading && (
                    <>
                        <div className={`text-5xl font-bold`}>{advertCount}</div>
                        <div className="mt-2 text-sm">İlan ekleme hakkınız var!</div>
                        <Link
                        to="/isveren/paket"
                        className="mt-4 px-4 py-2 border border-[#2253af] text-[#2253af] text-[15px] rounded hover:bg-[#2253af] hover:text-white transition-colors"
                        >
                            Daha Fazla İlan Hakkı
                        </Link>
                    </>
                )}
            </div>
        </div>
    ); 
}