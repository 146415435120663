import { ReactNode } from "react";
import cn from "classnames";

interface TabPanelsProps {
  children: ReactNode;
  className?: string;
}

const TabPanels: React.FC<TabPanelsProps> = ({ children, className }) => (
  <div className={cn("mt-4", className)}>{children}</div>
);

export default TabPanels;