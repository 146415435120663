import { CookieName } from "../../../../../constants/enums";
import { useCookie } from "../../../../../hooks/useCookie";

interface Position {
  title: string;
  slug: string;
  min_salary: number;
  max_salary: number;
  detail: string;
  avg_salary: number;
  calculate_salary: number;
  calculate_university: number;
  created_at: string;
  order: number;
}

interface GeneralInfoProps {
  positionData: Position[];
}

const PostJobAd: React.FC<GeneralInfoProps> = ({ positionData }) => {
  const { getCookie } = useCookie();
  const companyToken = getCookie(CookieName.CompanyUserToken);

  console.log(companyToken);

  return (
    <div className="bg-white p-4 shadow rounded-lg">
      <p className="text-center text-[#63636c]">
        {positionData[0]?.title} olarak iş arayanlara ulaşmak için hemen firma
        girişi yapabilir ve iş ilanı yayınlayabilirsiniz.
      </p>

      {companyToken ? (
        <div className="flex justify-center">
          <a
            href="/isveren/ilanlar/olustur/ilan-bilgileri"
            className="w-2/5 text-white bg-[#2253af] rounded mt-4 lg:w-1/4 py-3 hover:bg-[#0d6efd]  font-medium flex justify-center items-center"
          >
            İş İlan Yayınla
          </a>
        </div>
      ) : (
        <div className="flex justify-center">
          <a
            href="/isveren/giris-yap"
            className="w-2/5 text-white bg-[#2253af] rounded mt-4 lg:w-1/4 py-3 hover:bg-[#0d6efd]  font-medium flex justify-center items-center"
          >
            İş İlan Yayınla
          </a>
        </div>
      )}
    </div>
  );
};

export default PostJobAd;
