import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Position from './Positions/index'
import NetworkManager from './details/index';


const Dashboard: React.FC = () => {
  return (
    <div className=''>
      <div className='w-full'>
        <Routes>
          <Route path="/" element={<Position />} />
          <Route path="/:slug" element={<NetworkManager />} />
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;