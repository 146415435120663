import { createContext, ReactNode, useContext, useState } from "react";
import classNames from "classnames";

// Tabs Context
type tabsContextType = {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
};

export const TabsContext = createContext<tabsContextType | undefined>(
  undefined
);

type tabsPropsType = {
  children: ReactNode;
  defaultTab: string;
  className?: string;
};

export function Tabs({ children, defaultTab, className }: tabsPropsType) {
  const [selectedTab, setSelectedTab] = useState<string>(defaultTab);

  const cn = classNames({
    "flex flex-col": true,
    [className as string]: !!className,
  });

  return (
    <TabsContext.Provider value={{ selectedTab, setSelectedTab }}>
      <div className={cn}>{children}</div>
    </TabsContext.Provider>
  );
}
