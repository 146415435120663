import { SetStateAction, useState } from "react";
import { FaRegBuilding } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import { IoTimeOutline } from "react-icons/io5";
import { FiUsers } from "react-icons/fi";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegCalendarAlt } from "react-icons/fa";
import { Location as ImportedLocation } from "../../../../containers/company-page-container/first-show-ad/body";
import { WorkingLocation } from "../../../../containers/job-page-container/detials";
import { GoPeople } from "react-icons/go";
import { FaRegEye } from "react-icons/fa";
import { Dropdown, Space, Form, Input } from "antd";
import type { MenuProps } from "antd";

import {
  RiMessage2Line,
  RiEyeLine,
  RiGroupLine,
  RiArrowLeftLine,
  RiMore2Fill,
} from "react-icons/ri";
import { Button, Modal } from "antd";
import { Tooltip } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { GoPlus } from "react-icons/go";
import { IoDocumentTextOutline } from "react-icons/io5";
import { CiShare2 } from "react-icons/ci";
import { CiFlag1 } from "react-icons/ci";
import Cookies from "js-cookie";
import { CiSquarePlus } from "react-icons/ci";
import Swal from "sweetalert2";
import { TiMinus } from "react-icons/ti";
import { FaFacebook } from "react-icons/fa6";
import { RiTwitterXFill } from "react-icons/ri";
import { FaWhatsapp } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { IoShareSocialOutline } from "react-icons/io5";
import { TiArrowSortedUp } from "react-icons/ti";
import { set } from "lodash";
import ReportPopup from "../application-popup";
import { cdnCityType, cdnDistrictType } from "../../../../types";
import { CookieName } from "../../../../constants/enums";
import { TbDisabled } from "react-icons/tb";

const { TextArea } = Input;

interface City {
  name: string;
  id: number;
  country_id: number;
  slug: string;
  seo_name: string | null;
}

interface District {
  name: string;
  id: number;
  city_id: number;
  slug: string;
  seo_name: string | null;
}

interface AdvertData {
  created_at: string;
  cuff_end_date: string;
  updated_at: string;
  user_advert_views: number;
  hidden_company: number;
  end_date: string;
  is_send_message_users: number;
  is_disabled_employee: number;
  hide_company_info: number;
}

interface CompanyDetail {
  id: number;
  facebook: string;
  logo: string;
  twitter: string;
  whatsapp: string;
  company_name: string;
  company_id: number;
  employee_count: number;
  created_at: string;
}

type locationType = {
  city: cdnCityType;
  district?: cdnDistrictType;
};

interface IGeneralBaseInfoProps {
  title: string;
  companyFullName: string;
  advertData: AdvertData;
  logo: string;
  cities: City[];
  districts: District[];
  workingLocations: WorkingLocation[] | undefined;
  companyDetail: CompanyDetail;
  handlApplicationPopupVisableClick: () => void;
  handleToggleFollow: any;
  isFollowing: boolean;
  setIsFollowing: any;
  handleApplyChange: () => void;
  isApply: boolean;
  locations: locationType[];
  setIsMessagePopup: React.Dispatch<SetStateAction<boolean>>;
}

const GeneralBaseInfo: React.FC<IGeneralBaseInfoProps> = ({
  title,
  companyFullName,
  advertData,
  cities,
  logo,
  workingLocations,
  companyDetail,
  handlApplicationPopupVisableClick,
  districts,
  handleToggleFollow,
  isFollowing,
  setIsFollowing,
  handleApplyChange,
  isApply,
  locations,
  setIsMessagePopup,
}) => {
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const userToken = Cookies.get("userToken");
  const companyToken = Cookies.get(CookieName.CompanyUserToken);

  console.log(districts);
  console.log(advertData);
  console.log("/////////////");
  console.log(advertData);

  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleOk2 = () => {
    setIsModalOpen2(false);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const showModal3 = () => {
    setIsModalOpen3(true);
  };

  const handleOk3 = () => {
    setIsModalOpen3(false);
  };

  const handleCancel3 = () => {
    setIsModalOpen3(false);
  };

  // Logged out items  mobile menu

  const loggedOutItems: MenuProps["items"] = [
    {
      label: (
        <div className="flex justify-start items-center gap-1">
          <IoDocumentTextOutline className="text-black" />
          <a
            href={`/firma/${companyDetail?.company_id}`}
            className="font-poppins poppins-regular text-[15px]"
          >
            Firmanın Diğer İlanlarına Göz At
          </a>
        </div>
      ),
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div
          onClick={showModal2}
          className="flex justify-start items-center gap-1"
        >
          <CiShare2 className="text-black" />
          <p className="font-poppins poppins-regular text-[15px]">Paylaş</p>
        </div>
      ),
      key: "3",
    },
  ];

  // Logged in items mobile menu

  const loggedInItems: MenuProps["items"] = [
    {
      label: !isApply ? (
        <div
          onClick={() => handlApplicationPopupVisableClick()}
          className="flex justify-start items-center gap-1 relative"
        >
          <GoPlus className="text-black" />
          <p className="font-poppins poppins-regular text-[15px]">
            İlana Başvur
          </p>

          <>
            <div className="absolute -top-8 -right-6">
              <TiArrowSortedUp className="text-white text-5xl" />
            </div>
          </>
        </div>
      ) : (
        <div
          onClick={() => handleApplyChange()}
          className="flex justify-start items-center gap-1 relative"
        >
          <TiMinus className="text-black" />
          <p className="font-poppins poppins-regular text-[15px]">
            Başvuruyu Geri Çek
          </p>

          <>
            <div className="absolute -top-8 -right-6">
              <TiArrowSortedUp className="text-white text-5xl" />
            </div>
          </>
        </div>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div className="flex justify-start items-center gap-1">
          {isFollowing ? (
            <TiMinus className="text-black" />
          ) : (
            <GoPlus className="text-black" />
          )}
          <p
            onClick={() => handleToggleFollow()}
            className="font-poppins poppins-regular text-[15px]"
          >
            {isFollowing ? "Firmayı Takibi Bırak" : "Firmayı Takip Et"}
          </p>
        </div>
      ),
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div className="flex justify-start items-center gap-1">
          <IoDocumentTextOutline className="text-black" />
          <a
            href={`/firma/${companyDetail?.company_id}`}
            className="font-poppins poppins-regular text-[15px]"
          >
            Firmanın Diğer İlanlarına Göz At
          </a>
        </div>
      ),
      key: "1",
    },
    {
      type: "divider",
    },
    {
      label: (
        <div
          onClick={showModal2}
          className="flex justify-start items-center gap-1"
        >
          <CiShare2 className="text-black" />
          <p className="font-poppins poppins-regular text-[15px]">Paylaş</p>
        </div>
      ),
      key: "3",
    },
    {
      type: "divider",
    },
    {
      label: (
        <>
          <div
            onClick={() => handleAddLocationClick()}
            className="flex justify-start items-center gap-1"
          >
            <CiFlag1 className="text-black" />
            <p className="font-poppins poppins-regular text-[15px]">
              Şikayet Et
            </p>
          </div>
        </>
      ),
      key: "3",
    },
  ];

  const [isHovering, setIsHovering] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopupVisible, setIsPopupVisable] = useState<boolean>(false);

  const handleClosePopup = (): void => {
    setIsPopupVisable(false);
  };

  const handleAddLocationClick = (): void => {
    setIsPopupVisable(true);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // Format date

  function formatDate(dateString: string): string {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("tr-TR", options); // Correctly typed options
    return formattedDate.split(".").join(".");
  }

  // Truncate text

  const truncate = (text: string | undefined, length: number) => {
    if (!text) {
      return "";
    }
    return text?.length > length ? text.slice(0, length) + "..." : text;
  };

  const navigate = useNavigate();
  console.log("/////////////");
  console.log(advertData);

  return (
    <div>
      <div className=" flex flex-col  items-center bg-[#146297] h-auto w-full min-h-52 absolute !z-[99]  top-0 left-0 lg:hidden ">
        <div className="w-full h-12 flex justify-center">
          <div className="w-full md:w-9/12 flex justify-between items-center px-6 border-b border-gray-400">
            <button
              onClick={() => navigate(-1)}
              className="text-[#eee] text-3xl text-bold"
            >
              {" "}
              <RiArrowLeftLine />{" "}
            </button>
            <p className="text-white font-poppins poppins-medium text-sm">
              {" "}
              İlan Detayı
            </p>

            <Dropdown
              menu={{
                items:
                  userToken && !companyToken ? loggedInItems : loggedOutItems,
              }}
              trigger={["click"]}
              className="relative"
            >
              <button
                onClick={(e) => e.preventDefault()}
                className="text-[#eee] text-3xl text-bold"
              >
                <Space>
                  <RiMore2Fill />
                </Space>
              </button>
            </Dropdown>
          </div>
        </div>
        <div className="w-full h-auto flex justify-center">
          <div className="py-4 w-11/12 border-b flex-row flex px-2">
            <a
              href={`/firma/${companyDetail?.company_id}`}
              className=" rounded-md flex justify-center items-center w-[70px] md:w-36"
            >
              <img src={logo} alt="" className="h-auto w-[70px] md:w-36" />
            </a>
            <div className={"w-full overflow-hidden"}>
              <ul className="ml-5 md:pl-5 space-y-2 overflow-hidden text-ellipsis">
                <h1 className={"overflow-hidden text-ellipsis"}>
                  <div className="flex flex-row items-center gap-2">
                    <li className=" w-full md:text-2xl text-[#eee] font-medium flex items-center overflow-hidden text-ellipsis">
                      {title}
                    </li>
                    {advertData?.is_disabled_employee == 1 ? (
                      <TbDisabled />
                    ) : null}
                  </div>
                  <li className="text-[12px] sm:text-sm md:text-lg  mt-[2px]">
                    {advertData?.hide_company_info &&
                    advertData?.hide_company_info == 1 ? (
                      <label className="flex items-center">
                        <FaRegBuilding className="mr-2 text-[#eee]" />{" "}
                        <span className="md:font-medium text-[#eee]">
                          Firma Bilgisi Gizli
                        </span>
                      </label>
                    ) : (
                      <Link
                        state={companyDetail}
                        to={`/firma/${companyDetail?.company_id}`}
                        className="flex items-center"
                      >
                        <FaRegBuilding className="mr-2 text-[#eee]" />{" "}
                        <span className="md:font-medium text-[#eee]">
                          {truncate(companyDetail?.company_name, 40)}
                        </span>
                      </Link>
                    )}
                  </li>

                  <li className="md:text-lg text-sm flex items-center mt-[2px]">
                    <FiMapPin className="mr-2 text-[#eee]" />
                    {/* <span className="text-[#363636] text-sm">{"Ankara(Çankaya)"}
              </span> */}

                    {locations && locations.length > 0 ? (
                      <div className="flex justify-center items-center cursor-pointer ">
                        <div className="text-[10px] sm:text-sm md:text-lg text-[#eee]  mr-1 ">
                          <a href={`/is-ilanları/${locations[0].city.slug}`}>
                            {locations[0].city.name}
                          </a>
                        </div>

                        {cities.length > 1 && (
                          <div
                            onClick={showModal}
                            className=" text-[#eee] text-xs"
                          >
                            {`(+${cities.length - 1})`}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="text-sm text-[#eee]">
                        No cities available
                      </div>
                    )}
                  </li>
                </h1>

                {/* {locations && locations.map((location, index) => (
                    <li key={index} className="md:text-lg text-sm flex items-center">
                        <FiMapPin className="mr-2 text-[#2253af]" />
                        <span className="text-[#363636]">{location.city.name} - {location.district.name}</span>
                    </li>
                    ))} */}
                {/* <li className="md:text-lg text-sm">
                  <div className="md:flex md:space-x-4">
                    <div className="flex items-center mb-2 md:m-0">
                      <IoTimeOutline className="mr-1 text-[#eee]" />
                      <span className="text-[#eee] text-sm">06.04.2024</span>
                    </div>


                  </div>
                </li> */}
                {/* <li
                  onMouseEnter={() => {
                    console.log("Mouse entered, showing card");
                    setIsHovering(true);
                  }}
                  onMouseLeave={() => {
                    console.log("Mouse left, hiding card");
                    setIsHovering(false);
                  }}
                  className="flex items-center md:text-lg text-sm"
                >
                  <FaRegCalendarAlt className="mr-2 text-[#eee]" />
                  <span className="text-[#363636] text-sm">Güncellendi</span>
                </li> */}
              </ul>
            </div>
          </div>
        </div>

        <div className="w-full h-auto flex justify-center items-center mt-3 pb-2">
          {userToken &&
            advertData?.is_send_message_users &&
            advertData?.is_send_message_users == 1 && (
              <button
                onClick={() => {
                  setIsMessagePopup(true);
                }}
                className="scale-75 md:scale-100 mr-0 md:mr-4  text-[#eee] flex flex-row justify-center items-center bg-[#eee] bg-opacity-10 text-sm p-2 px-3 rounded-full hover:bg-opacity-50"
              >
                <RiMessage2Line className="mr-2" /> <span>Mesaj Gönder</span>
              </button>
            )}

          <Tooltip title="Başvuru Sayısı">
            <div className="flex items-center mr-1 md:mr-3 scale-75 md:scale-100 ">
              <RiGroupLine className="mr-1 text-[#eee]" />
              <span className="text-[#eee] text-md">0-10 Başvuru</span>
            </div>
          </Tooltip>

          <Tooltip title="Görüntülenme Sayısı">
            <div className="flex items-center  scale-75 md:scale-100 ">
              <RiEyeLine className="mr-1 text-[#eee] text-lg" />
              <span className="text-[#eee] text-md">
                {advertData?.user_advert_views}
              </span>
            </div>
          </Tooltip>
        </div>
      </div>

      <div style={{ position: "relative" }}>
        <div className="hidden lg:flex bg-white shadow-md  justify-start items-start p-8 font-poppins border-t-2 border-[#FFAC15]">
          {/*Image Section */}
          <a
            href={`/firma/${companyDetail?.company_id}`}
            className="flex-shrink-0"
          >
            <img src={logo} alt="" className="h-auto md:w-36 w-32 rounded" />
          </a>
          <div>
            {/*Informaiotn List Section */}
            <ul className="ml-8 md:pl-5 space-y-2">
              <h1>
                <li className="md:text-2xl text-[#2253af] font-medium flex items-center gap-2">
                  {title}{" "}
                  {advertData?.is_disabled_employee ? (
                    <TbDisabled className="text-xl" />
                  ) : null}
                </li>
                <li className="md:text-lg text-sm mt-[2px]">
                  {advertData?.hide_company_info &&
                  advertData?.hide_company_info == 1 ? (
                    <label className="flex items-center">
                      <FaRegBuilding className="mr-2 text-[#2253af]" />{" "}
                      <span className="md:font-medium text-[#363636]">
                        Firma Bilgisi Gizli
                      </span>
                    </label>
                  ) : (
                    <Link
                      state={companyDetail}
                      to={`/firma/${companyDetail?.company_id}`}
                      className="flex items-center"
                    >
                      <FaRegBuilding className="mr-2 text-[#2253af]" />{" "}
                      <span className="md:font-medium text-[#363636]">
                        {truncate(companyDetail?.company_name, 40)}
                      </span>
                    </Link>
                  )}
                </li>
              </h1>
              <li className="md:text-lg text-sm flex items-center">
                <FiMapPin className="mr-2 text-[#2253af]" />
                {/* <span className="text-[#363636] text-sm">{"Ankara(Çankaya)"}
              </span> */}

                {locations && locations.length > 0 ? (
                  <div className="flex justify-center items-center cursor-pointer">
                    <div className=" text-gray-700  mr-1 text-sm">
                      <a href={`/is-ilanları/${locations[0].city.slug}`}>
                        {locations[0].city.name}
                      </a>
                    </div>

                    <div className="text-gray-700 mr-1 text-sm">
                      -
                      {locations[0]?.district
                        ? locations[0].district.name
                        : " Tüm İlçeler"}
                    </div>

                    {locations.length > 1 && (
                      <div
                        onClick={showModal}
                        className=" text-gray-700 text-xs"
                      >
                        {`(+${locations.length - 1})`}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="text-sm text-gray-500">
                    No cities available
                  </div>
                )}
              </li>
              {/* {locations && locations.map((location, index) => (
                    <li key={index} className="md:text-lg text-sm flex items-center">
                        <FiMapPin className="mr-2 text-[#2253af]" />
                        <span className="text-[#363636]">{location.city.name} - {location.district.name}</span>
                    </li>
                    ))} */}
              <li className="md:text-lg text-sm">
                <div className="md:flex md:space-x-4">
                  <div className="flex items-center mb-2 md:m-0">
                    <IoTimeOutline className="mr-1 text-[#2253af]" />
                    <span className="text-[#363636] text-sm">
                      {formatDate(companyDetail?.created_at)}
                    </span>
                  </div>

                  <Tooltip title="Başvuru Sayısı">
                    <div className="flex items-center mb-2 md:m-0">
                      <GoPeople className="mr-1 text-[#2253af]" />
                      <span className="text-[#363636] text-sm">0 Başvuru</span>
                    </div>
                  </Tooltip>

                  <Tooltip title="Görüntülenme Sayısı">
                    <div className="flex items-center mb-2 md:m-0">
                      <FaRegEye className="mr-1 text-[#2253af]" />
                      <span className="text-[#363636] text-sm">
                        {advertData?.user_advert_views} Görüntülenme
                      </span>
                    </div>
                  </Tooltip>
                </div>
              </li>
              <li
                onMouseEnter={() => {
                  //console.log("Mouse entered, showing card");
                  setIsHovering(true);
                }}
                onMouseLeave={() => {
                  //console.log("Mouse left, hiding card");
                  setIsHovering(false);
                }}
                className="flex items-center md:text-lg text-sm relative"
              >
                <FaRegCalendarAlt className="mr-2 text-[#2253af]" />
                <span className="text-[#363636] text-sm">Güncellendi</span>

                {isHovering && advertData && (
                  <div
                    className="bg-white update-shadow flex justify-start items-start p-4 font-poppins rounded -bottom-44"
                    style={{ position: "absolute", zIndex: "10000" }}
                  >
                    <div className="relative">
                      {/* <TiArrowSortedUp className="text-white text-3xl absolute -top-[34px]" /> */}
                      <span className="arrow-white-up"></span>

                      <div className="text-sm">
                        <p className="mb-3 mt-3">
                          <span className="font-semibold">
                            İlk Yayınlanma Tarihi:
                          </span>{" "}
                          <span className="text-nowrap">
                            {formatDate(advertData.created_at)}
                          </span>
                        </p>
                        <hr className="mb-2"></hr>
                        <p className="mb-3">
                          <span className="font-semibold">
                            Güncelleme Tarihi:
                          </span>{" "}
                          <span className="text-nowrap">
                            {" "}
                            {formatDate(advertData.updated_at)}
                          </span>
                        </p>
                        <hr className="mb-2"></hr>
                        <p>
                          <span className="font-semibold">
                            İlan Bitiş Tarihi:{" "}
                          </span>{" "}
                          <span className="text-nowrap">
                            {" "}
                            {formatDate(advertData.end_date)}
                          </span>
                        </p>
                        <hr className="mt-2"></hr>
                      </div>
                    </div>
                  </div>
                )}
              </li>
            </ul>

            <Modal
              title="Lokasyonlar"
              open={isModalOpen}
              footer={null}
              onOk={handleOk}
              onCancel={handleCancel}
              className="top-56"
            >
              {locations &&
                locations.map((location, i) => {
                  return (
                    <div className="flex items-center mb-1">
                      <div className="text-gray-700 text-sm capitalize">
                        {location.city?.name} -{" "}
                        {location.district
                          ? location.district?.name
                          : "Tüm İlçeler"}
                      </div>
                    </div>
                  );
                })}
            </Modal>
          </div>
        </div>
      </div>

      <Modal
        title={
          <p className="text-center flex justify-center items-center text-mains font-poppins poppins-medium mt-6">
            İlanı Paylaş
          </p>
        }
        open={isModalOpen2}
        onOk={handleOk2}
        onCancel={handleCancel2}
        footer={null}
        className="relative top-[270px]"
      >
        <div>
          <div className="flex justify-center items-start gap-3 flex-col px-14">
            <a
              href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex justify-center items-center gap-6"
            >
              <FaFacebook className="text-[#3b5998] text-4xl" />
              <span className="font-poppins poppins-medium text-lg text-mains">
                Facebook
              </span>
            </a>
            <a
              href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex justify-center items-center gap-6"
            >
              <RiTwitterXFill className="text-black text-4xl" />
              <span className="font-poppins poppins-medium text-lg text-mains">
                Twitter
              </span>
            </a>
            <a
              href={`https://api.whatsapp.com/send?text=${window.location.href}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex justify-center items-center gap-6"
            >
              <FaWhatsapp className="text-[#25d366] text-4xl" />
              <span className="font-poppins poppins-medium text-lg text-mains">
                Whatsapp
              </span>
            </a>
            <a
              href={`mailto:?subject=İş İlanı&body=${window.location.href}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex justify-center items-center gap-6"
            >
              <IoMdMail className="text-gray-400 text-4xl" />
              <span className="font-poppins poppins-medium text-lg text-mains">
                E-posta
              </span>
            </a>
          </div>

          <div className="p-5 bg-mains absolute -top-11 border-[5px] border-white  rounded-full left-[148px]">
            <IoShareSocialOutline className="text-white text-4xl" />
          </div>
        </div>
      </Modal>

      {/* <Modal
        title={
          <p className="text-start  text-mains font-poppins poppins-medium">
            Şikayet Et
          </p>
        }
        open={isModalOpen3}
        onOk={handleOk3}
        onCancel={handleCancel3}
        footer={
          <div className="flex justify-end items-center gap-2 pr-6 pb-4">
            <Button
              onClick={handleCancel3}
              className="bg-gray-300 text-black font-poppins poppins-medium"
            >
              Kapat
            </Button>
            <Button
              onClick={handleOk3}
              className="bg-[#2253af] text-white font-poppins poppins-medium"
            >
              Gönder
            </Button>
          </div>
        }
        className="top-56 relative"
      >
        <Form
          className="flex flex-col gap-y-3"
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
        >
          <Form.Item
            name="name"
            label="Adınız & Soyadınız"
            rules={[{ required: true }]}
          >
            <Input className="h-10" />
          </Form.Item>

          <Form.Item
            name="age"
            label="E-Posta Adresiniz"
            rules={[{ required: true }]}
          >
            <Input className="h-10" />
          </Form.Item>

          <Form.Item
            name="age"
            label="Telefon Numaranız"
            rules={[{ required: true }]}
          >
            <Input className="h-10" />
          </Form.Item>

          <Form.Item
            name="age"
            label="Şikayet Sebebi"
            rules={[{ required: true }]}
          >
            <TextArea rows={4} maxLength={6} />
          </Form.Item>
        </Form>
      </Modal> */}

      {isPopupVisible && (
        <ReportPopup
          onClose={handleClosePopup}
          companyID={companyDetail?.company_id}
          companyName={companyDetail?.company_name}
        />
      )}
    </div>
  );
};
export default GeneralBaseInfo;
