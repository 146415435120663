import React, { useContext, useEffect, useState } from "react";
import { CVPreviewContext } from "../contents";
import Axios from "../../../../services/axios";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import { FaRegTrashAlt } from "react-icons/fa";
import { GoEye } from "react-icons/go";
import Swal from "sweetalert2";

const axios = new Axios();

const Folders = () => {
  const { setAddFolderShow, data, fetchIncompleteDetail } =
    useContext(CVPreviewContext);

  const handleDelete = (id: number, cv: number) => {
    Swal.fire({
      html: `Adayı klasörden silmek istediğinize emin misiniz?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
      confirmButtonColor: "#2253af",
      cancelButtonColor: "#6c757d",
    }).then((result) => {
      if (result.isConfirmed) {
        //TODO backendden hata dönüyor CV not ekleme
        axios
          .authRequest({
            type: "post",
            endpoint: `companies/folder/${id}/multi-extract-cv-from-folder`,
            payload: { cv_ids: [cv] },
          })
          .then((reponse: any) => {
            fetchIncompleteDetail();
            Swal.fire({
              html: `Aday klasörden silindi`,
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Tamam",
              confirmButtonColor: "#2253af",
            });
          })
          .catch((error: any) => {
            Swal.fire({
              html: `Aday klasörden silinirken bir hata oluştu`,
              icon: "error",
              showCancelButton: false,
              confirmButtonText: "Tamam",
              confirmButtonColor: "#2253af",
            });
          });
      }
    });
  };

  return (
    <div
      className={
        "flex flex-col justify-center items-center p-5 bg-white gap-3 rounded-xl"
      }
    >
      <div className={"text-center text-2xl font-bold "}>Klasörler</div>
      <div className={"text-center text-sm font-light"}>
        Adayı daha sonra kolay bir şekilde ulaşmak veya gruplamak için klasöre
        ekleyebilirsiniz.
      </div>

      <div className={"flex flex-col gap-3 w-full"}>
        {data.company_folder_cvs &&
          data.company_folder_cvs.map((note: any, i: any) => (
            <div
              key={i}
              className={"flex flex-col gap-3 p-3 border rounded-xl w-full"}
            >
              <Link
                to={`/isveren/klasorler/${note.company_folder_id}/cv-listesi`}
              >
                <div
                  className={"font-bold text-mains underline cursor-pointer"}
                >
                  {note.company_folder.title}
                </div>
              </Link>
              <div className={""}>
                Durum:{" "}
                {note.company_candidate_status
                  ? note.company_candidate_status.value
                  : ""}
              </div>
              {note.note && (
                <div className={"w-full text-wrap break-words"}>
                  {note.note}
                </div>
              )}

              <div className={"text-sm"}>
                ({moment(note.updated_at).format("DD MMMM YYYY HH:MM")})
                tarihinde eklendi
              </div>
              <div className={"flex gap-2"}>
                <Link
                  to={`/isveren/klasorler/${note.company_folder_id}/cv-listesi`}
                >
                  <div
                    className={
                      "flex gap-2 justify-center items-center px-3 py-1 border border-mains text-mains hover:bg-mains hover:text-white cursor-pointer"
                    }
                  >
                    <GoEye /> Görüntüle
                  </div>
                </Link>
                <div
                  onClick={() => {
                    handleDelete(note.company_folder_id, note.cv_id);
                  }}
                  className={
                    "flex gap-2 justify-center items-center px-3 py-1 border border-red-500 text-red-500 hover:bg-red-500 hover:text-white cursor-pointer"
                  }
                >
                  <FaRegTrashAlt />
                  Sil
                </div>
              </div>
            </div>
          ))}
      </div>

      <div
        onClick={() => setAddFolderShow(true)}
        className={
          "mt-3 w-full px-5 py-2 border border-mains hover:bg-mains hover:text-white rounded text-center transition-all cursor-pointer font-bold text-mains"
        }
      >
        Klasöre Ekle
      </div>
    </div>
  );
};

export default Folders;
