import React, { useState } from "react";

interface subTitle {
  title: string;
  text: string;
}

interface ExpandableTextProps {
  title: string;
  text?: string;
  subTitle?: subTitle[];
}

const ExpandableText: React.FC<ExpandableTextProps> = ({
  title,
  text,
  subTitle,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="bg-white rounded-lg shadow-md">
      <h2 className="text-lg font-semibold mb-4 border-b p-5">{title}</h2>
      <p
        className={`transition-all duration-500 ease-in-out text-justify px-4 ${
          isExpanded ? "line-clamp-none" : "line-clamp-3"
        }`}
      >
        {text}
        {subTitle &&
          subTitle.map((sub, index) => (
            <div key={index}>
              <h3 className="font-bold mt-2">{sub.title}</h3>
              <p className="text-gray-500">{sub.text}</p>
            </div>
          ))}
      </p>
      <button
        className={`text-blue-600 w-full hover:text-blue-800 focus:outline-none mb-2 ${
          !isExpanded ? "shadow-[0px_-20px_30px_20px_rgba(255,255,255,1)]" : ""
        }`}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? "Daha Az Göster" : "Tümünü Gör"}
      </button>
    </div>
  );
};

export default ExpandableText;
