
//import { Button, InputArea } from "../../components/isbul-ui";

import { Button, InputArea } from "../../../components/isbul-ui";










export default function EMailForm() {
    return(
        <form className="flex flex-col gap-6 w-full">
            <InputArea
            required
            className="w-full"
            >
                <InputArea.Label>E-Posta</InputArea.Label>
                <InputArea.Input
                placeholder="E-posta Adresi"
                />
            </InputArea>
            <Button
            className="w-full"
            type="submit"
            >
                Gönder
            </Button>
        </form>
    )
}