import { ReactNode } from "react";
import { useContext } from "react";
import { BreadcrumbContext } from "./index";

type breadcrumbItemPropsType = {
  children: ReactNode;
  href?: string;
  className?: string;
};

export function BreadcrumbItem({
  children,
  href,
  className,
}: breadcrumbItemPropsType) {
  const { separator } = useContext(BreadcrumbContext);

  return (
    <div className={`flex items-center ${className}`}>
      {href ? (
        <a href={href} className="text-blue-600 hover:underline">
          {children}
        </a>
      ) : (
        <span>{children}</span>
      )}
      <span className="mx-2">{separator}</span>
    </div>
  );
}
