import React from "react";
import Contents from "./contents";

const Cv: React.FC = () => {
  return (
    <>
      <Contents />
    </>
  );
};

export default Cv;
