import { HTMLAttributes } from "react";
import { useContext } from "react";
import { TooltipContext, tooltipContextType } from ".";

type triggerPropsType = HTMLAttributes<HTMLDivElement> & {};

export default function TooltipTrigger({
  className,
  children,
  ...props
}: triggerPropsType) {
  const { setVisible }: tooltipContextType = useContext(TooltipContext);

  return (
    <div
      {...props}
      className={className}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
    </div>
  );
}