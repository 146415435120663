import React, { useState } from "react";
import Axios from "../../../../services/axios";
import Swal from "sweetalert2";
import { LuLoader2 } from "react-icons/lu";
const ContactForm: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const maxMessageLength = 1000;

  const axios = new Axios();

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-numeric characters

    // Apply formatting
    if (value.length <= 11) {
      // '05' + 9 digits
      setPhoneNumber(formatPhoneNumber(value));
    }
  };

  const formatPhoneNumber = (digits: string): string => {
    // Formatting: "0 (5xx) xxx xx xx", allowing deletion
    const match = digits.match(/^(\d{2})(\d{2})(\d{0,3})(\d{0,2})(\d{0,2})$/);
    if (match) {
      return `0 (${match[1][1]}${match[2]}) ${match[3]} ${match[4]} ${match[5]}`.trim();
    }
    return digits;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === "Backspace" &&
      phoneNumber.length &&
      e.currentTarget.selectionStart !== null &&
      e.currentTarget.selectionStart <= phoneNumber.length
    ) {
      e.preventDefault(); // Prevent default to manage deletion including spaces
      let value = phoneNumber.replace(/\D*$/, "").slice(0, -1); // Remove the last digit
      setPhoneNumber(
        formatPhoneNumber("05" + value.replace(/[^\d]/g, "").slice(2))
      );
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!phoneNumber) {
      setPhoneNumber("0 (5");
    }
  };

  const handleSubmit = () => {
    if (loading) {
      return;
    }
    let errors = [];
    if (phoneNumber.length < 17) {
      errors.push("Telefon Numarası Girin");
    }
    if (name.length < 3) {
      errors.push("İsim Girin");
    }
    if (
      email.match(/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) === null
    ) {
      errors.push("Geçerli Bir E-Posta Girin");
    }
    if (message.length < 100) {
      errors.push("Mesaj Girin (en az 100 karakter)");
    }

    console.log(email.match(/^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/));
    if (errors.length > 0) {
      const innerHtml = errors.join("<br/>");
      Swal.fire({
        icon: "warning",
        title: "Uyarı",
        html: `
        <div>
        Eksik Alanlar: <br/>
        <p class="text-red-600">
        ${innerHtml}
        </p>
        </div>
        `,
      });
      return;
    }
    setLoading(true);
    axios
      .request({
        type: "post",
        endpoint: "help/send-message",
        payload: {
          name: name,
          email: email,
          phone_code: "+90",
          phone: phoneNumber,
          message: message,
          type: "question", // question - suggestion
        },
      })
      .then((response: any) => {
        setPhoneNumber("");
        setName("");
        setEmail("");
        setMessage("");
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: "Mesaj Gönderildi!",
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "sweet-alert-button", // TailwindCSS classes
          },
        });
      })
      .catch((err: any) => {
        Swal.fire({
          icon: "error",
          text: "Mesaj Gönderilemedi",
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "sweet-alert-button", // TailwindCSS classes
          },
        });
      });

    console.log(phoneNumber.length, name, email, message);
  };

  return (
    <div className="w-full lg:w-1/2 px-4 mb-6 lg:mb-0">
      <div className="mb-6">
        <label
          className="block mb-2 text-[#63636c] text-[17px]"
          htmlFor="full-name"
        >
          Ad Soyad <span className="text-red-500">*</span>
        </label>
        <input
          className="w-full px-3 py-3 border border-[#2253af] rounded-md bg-gray-50"
          id="full-name"
          type="text"
          style={{ backgroundColor: "#f8f8f8" }}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </div>
      <div className="mb-6">
        <label
          className="block mb-2 text-[#63636c] text-[17px]"
          htmlFor="email"
        >
          E-Posta Adresiniz <span className="text-red-500">*</span>
        </label>
        <input
          className="w-full px-3 py-3 border border-[#2253af] rounded-md bg-gray-50"
          id="email"
          type="email"
          style={{ backgroundColor: "#f8f8f8" }}
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>
      <div className="mb-6">
        <label
          className="block mb-2 text-[#63636c] text-[17px]"
          htmlFor="phone"
        >
          Telefon Numaranız <span className="text-red-500">*</span>
        </label>
        <input
          className="w-full px-3 py-3 border border-[#2253af] rounded-md bg-gray-50"
          id="phone"
          type="tel"
          style={{ backgroundColor: "#f8f8f8" }}
          placeholder="0 (5__) ___ __ __"
          value={phoneNumber}
          onChange={handlePhoneChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
        />
      </div>
      <div className="mb-6">
        <label
          className="block mb-2 text-[#63636c] text-[17px]"
          htmlFor="message"
        >
          Mesajınız <span className="text-red-500">*</span>
        </label>
        <textarea
          className="w-full px-3 py-2 border border-[#2253af] rounded-md bg-gray-50"
          id="message"
          rows={4}
          style={{ backgroundColor: "#f8f8f8", height: "auto" }}
          value={message}
          onChange={(e) => {
            if (e.target.value.length > maxMessageLength) return;
            setMessage(e.target.value);
          }}
        ></textarea>
        <div>{maxMessageLength - message.length} </div>
      </div>
      <div className="w-full mx-auto mb-4">
        <button
          onClick={() => {
            handleSubmit();
          }}
          className="flex justify-center items-center w-full text-center text-white bg-[#2253af] rounded-full py-2 min-h-14"
        >
          {!loading ? "GÖNDER" : <LuLoader2 className={"animate-spin"} />}
        </button>
      </div>
    </div>
  );
};
export default ContactForm;
