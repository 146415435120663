import { useState, useEffect } from "react";
import Axios from "../../../services/axios";
import GeneralBaseInfo from "./general-base-info";
import ShareButtons from "./share-buttons";
import AddDetail from "./ad-detail";
import MainInfo from "./main-info";
//import MainInfo from "../../company-page-container/first-show-ad/main-info";
import { Link, useParams } from "react-router-dom";
import { FaListUl } from "react-icons/fa";
import { SlFlag } from "react-icons/sl";
import { FaChevronRight } from "react-icons/fa";
import AdvertSectionBody from "./ad-cards/idnex";
import SaveAndNextButton from "./save-and-next-button";
import ReportPopup from "./application-popup";
import ApplicationPopup from "./report-popup";
import { MdEmail } from "react-icons/md";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { FaRegStar, FaStar } from "react-icons/fa";
import { IconChevronRight } from "@tabler/icons-react";
import BreadcrumbJsonLd from "../../../components/common/BreadcrumbJsonLd";
import { useNavigate } from "react-router-dom";
import axios from "../../../services/axios";
import CvApplicationPopup from "./cv-application-popup";
import { set } from "lodash";
import { useLocation } from "react-router-dom";
import { useCookie } from "../../../hooks/useCookie";
import { CookieName } from "../../../constants/enums";
import AdvertApplyPopup from "./advert-apply-popup";
import { cdnEducationLevelType, cdnSectionType } from "../../../types";
import { Button } from "antd";
import MessageSendPopup from "./message-send-popup";
import { AdvertService } from "../../../services/similer-advert-services";

interface MapLocation {
  lat: number;
  longitude: number;
}
interface City {
  id: number;
  country_id: number;
  name: string;
  slug: string;
  seo_name: string | null;
  // Add other properties as necessary
}

interface District {
  id: number;
  city_id: number;
  name: string;
  slug: string;
  content: string;
  // Add other properties as necessary
}
export interface Location {
  advert_id: number;
  city: City;
  city_id: number;
  created_at: string;
  district: District;
  district_id: number;
  id: number;
  updated_at: string;
}
interface PositionDetails {
  created_at: string;
  id: number;
  is_individual: number;
  order: number;
  slug: string;
  title: string;
  title_en: string;
  updated_at: string;
}

export interface Position {
  slug: string;
  title: string;
  title_en: string;
  advert_id: number;
  created_at: string;
  id: number;
  position: PositionDetails;
  position_id: number;
  updated_at: string;
}
interface PositionLevelDetails {
  slug: string;
  title: string;
  name: string;
  created_at: string;
  id: number;
  name_en: string | null;
  updated_at: string;
}

export interface PositionLevel {
  slug: string;
  title: string;
  name: string;
  name_en: string;
  advert_id: number;
  created_at: string;
  id: number;
  position_level: PositionLevelDetails;
  position_level_id: number;
  updated_at: string;
  position_level_name: string;
  position_level_name_en: string;
  position_level_slug: string;
}
interface SectorDetails {
  created_at: string;
  id: number;
  is_publish: string;
  order: number;
  slug: string;
  title: string;
  title_en: string | null;
  updated_at: string;
}

export interface Sector {
  slug: string;
  title: string;
  title_en: string;
  name: string;
  advert_id: number;
  created_at: string;
  id: number;
  sector: SectorDetails;
  sector_id: number;
  updated_at: string;
}

interface SectionDetails {
  created_at: string;
  id: number;
  order: number;
  slug: string;
  title: string;
  title_en: string;
  updated_at: string;
}

export interface Section {
  slug: string;
  title: string;
  title_en: string;
  name: string;
  advert_id: number;
  created_at: string;
  id: number;
  section: SectionDetails;
  section_id: number;
  updated_at: string;
}

interface WorkingLocationDetails {
  name: string;
  created_at: string;
  id: number;
  order: number;
  slug: string;
  title: string;
  title_en: string;
  updated_at: string;
}

export interface WorkingLocation {
  slug: string;
  title: string;
  title_en: string;
  name: string;
  advert_id: number;
  created_at: string;
  id: number;
  updated_at: string;
  working_location: WorkingLocationDetails;
  working_location_id: number;
}
interface WorkingTypeDetails {
  created_at: string;
  id: number;
  order: number;
  slug: string;
  title: string;
  title_en: string;
  updated_at: string;
}

export interface WorkingType {
  slug: string;
  title: string;
  name: string;
  advert_id: number;
  created_at: string;
  id: number;
  updated_at: string;
  working_type: WorkingTypeDetails;
  working_type_id: number;
}
interface EducationLevelsDetails {
  created_at: string;
  id: number;
  order: number;
  slug: string;
  title: string;
  title_en: string;
  updated_at: string;
  name: string;
}
export interface EducationLevels {
  slug: string;
  title: string;
  title_en: string;
  name: string;
  advert_id: number;
  created_at: string;
  id: number;
  updated_at: string;
  education_level: cdnEducationLevelType;
  education_level_id: number;
}

interface militaryStatusesDetails {
  created_at: string;
  id: number;
  order: number;
  slug: string;
  title: string;
  title_en: string;
  updated_at: string;
  name: string;
}

export interface MilitaryStatuses {
  slug: string;
  title: string;
  name: string;
  advert_id: number;
  created_at: string;
  id: number;
  updated_at: string;
  military_statuses: militaryStatusesDetails;
  military_statuses_id: number;
}

interface ExperienceLevelDetail {
  created_at: string;
  id: number;
  order: number;
  slug: string;
  title: string;
  title_en: string;
  updated_at: string;
  value: number;
}
export interface ExperienceLevel {
  experience_level_min: ExperienceLevelDetail;
  experience_level_max: ExperienceLevelDetail;
}

export interface CompanyDetail {
  id?: number;
  facebook?: string;
  logo?: string;
  twitter?: string;
  whatsapp?: string;
  company_name?: string;
  company_id?: string;
  created_at: string;
  employee_count: number;
}

interface JobBenefitsDetails {
  id: number;
  name: string;
  name_en: string;
  created_at: string;
  updated_at: string;
}

export interface JobBenefits {
  job_benefit: JobBenefitsDetails;
}

interface DrivingLicenceSrc {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface DrivingLicenceSrcs {
  driving_licence_src: DrivingLicenceSrc;
}

export interface LanguageDetails {
  id: number;
  name: string;
  name_en: string;
  created_at: string;
  updated_at: string;
}

export interface Languages {
  language: LanguageDetails;
}

interface AdvertPayload {
  [key: string]: any;
  title: string;
  company_slug: string;
  map_location: MapLocation;
  sectors: Sector[]; // Array of sector IDs
  sections: Section[]; // Array of section IDs TODO: Değişiklik yapıldı kontrol edilecek
  positions: Position[]; // Array of position IDs
  working_types: WorkingType[]; // Array of working type IDs
  working_locations: WorkingLocation[]; // Array of working location IDs
  position_levels: PositionLevel[]; // Array of position level IDs
  military_statuses: MilitaryStatuses[];
  experience_level: ExperienceLevel;
  experience_level_type: "not-matter" | "experienced" | "inexperienced";
  experience_level_min: number;
  experience_level_max: number;
  education_levels: EducationLevels[]; // Array of education level IDs
  created_at: string;
  employee_count: number;
  min_age: number;
  max_age: number;
  gender: number; // Gender ID
  language: number; // Primary language ID
  languages: number[]; // Array of language IDs TODO: Yeni hali kontrol edilip daha sonra silinecek
  //languages: Languages[];
  country: number; // Country ID
  keywords: string[]; // Array of keywords
  detail: string; // Detailed description
  hide_contact_info: "show_all" | "show_gsm" | "show_phone" | "hide_all";
  hide_employee_info: boolean;
  hide_company_info: boolean;
  //locations: Location[]; // Array of locations (city and district IDs)
  //job_benefits: number[]; // Array of job benefit IDs TODO: Yeni hali kontrol edilip sonra silinecek
  job_benefits: {
    id: number;
    name: string;
    name_en: string;
    created_at: string;
    updated_at: string;
  }[];
  driving_licence_srcs: number[]; // Array of driving license source IDs TODO: Yeni hali kontrol edilip sonra silinecek
  //driving_licence_srcs: DrivingLicenceSrcs[];
  is_send_message_users: boolean;
  is_disabled_employee: boolean;
  is_favorite?: boolean;
  is_follow?: boolean;
  template_type: "DEFAULT" | "MAP" | "IMAGE" | "MAP-IMAGE";
  questions: { question: number; is_required: boolean }[];
  terms: number;
  advert_file: number;
  company_id: number;
  company: {
    id: "";
    name: "";
    surname: "";
    phone: "";
    land_phone: "";
    email: "";
  };
  companyDetail: CompanyDetail;
  cities: [];
  minAge: number;
  maxAge: number;
}
type RouteParams = {
  [key: string]: string | undefined;
};
const Body: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { slug } = useParams<RouteParams>();
  const [isPopupVisible, setIsPopupVisable] = useState<boolean>(false);
  const [isApplicationPopupVisable, setIsApplicationPopupVisable] =
    useState<boolean>(false);
  const lastSixChars = slug ? slug.slice(-6) : "";
  //console.log(lastSixChars, "last six characters of slug");
  const [adData, setAdData] = useState<AdvertPayload>();
  const [isMessagePopup, setIsMessagePopup] = useState<boolean>(false);

  const adDetail = adData?.advert.detail;
  const advertData = adData?.advert;
  const advertId = adData?.advert.id;
  const companyData = adData?.company;
  const companyID = adData?.company.id;
  const companyDetail = adData?.company_detail;
  const companyFullName = [companyData?.name, companyData?.surname]
    .filter(Boolean)
    .join(" ");
  const logo = companyDetail?.logo;
  const companyPhone = companyData?.phone;
  const companyLand_phone = companyData?.land_phone;
  const companyEmail = companyData?.email;
  const title = advertData?.title;
  const minAge = advertData?.min_age;
  const maxAge = advertData?.max_age;
  const hideCompanyTitle = adData?.advert.hide_company_info
  console.log(advertData);
  const comapnyUrl = "/is-ilanlari/" + advertData?.title + "-" + advertData?.id;
  const [saved, setSaved] = useState<boolean>(adData?.is_favorite || false);
  const [isFollowing, setIsFollowing] = useState(adData?.is_follow || false);
  const [isApply, setIsApply] = useState<boolean>(adData?.is_apply || false);
  // const userToken = Cookies.get("userToken") || Cookies.get("companyUserToken");
  const [cvId, setCvId] = useState<number>(0);
  const [educationInfo, setEducationInfo] = useState(false);
  const [jobExperienceInfo, setJobExperienceInfo] = useState(false);
  const [isCvDefault, setIsCvDefault] = useState(
    educationInfo && jobExperienceInfo
  );
  const [defaultCvData, setDefaultCvData] = useState<any>();

  {
    /*Arrays */
  }
  const positions = adData?.positions;
  const positionTitles = positions
    ?.map((position) => position.title)
    .join(", ");
  const positionLevels = adData?.advert_position_levels;
  const sectors = adData?.sectors;
  const sections = adData?.sections;
  const locations = adData?.locations;
  const workingLocations = adData?.working_locations;
  const workingTypes = adData?.working_types;
  //const languages = adData?.languages;
  const languages = adData?.general_language;
  const keywords = adData?.keywords;
  const jobBenefits = adData?.job_benefits;
  const drivingLicenceSrcs = adData?.driving_licence_srcs;
  const educationLevels = adData?.advert_education_levels;
  const experienceLevel = adData?.advert_experience_level;
  const districts = adData?.districts;
  const military = adData?.military_statuses;
  const dictionary = adData?.position_dictionaries;
  const dictionaryKeys = dictionary ? Object.keys(dictionary) : [];
  const dictArray = dictionaryKeys.filter((key: any) => {
    return !!dictionary[key];
  });
  const isAnyDictionary = dictArray.length > 0;
  const firstDictionary: any = isAnyDictionary ? dictionary[dictArray[0]] : {};
  const dictPositions: any = positions
    ? positions.filter((e: any) => firstDictionary.title === e.title)[0]
    : {};

  //console.log("adData", adData);

  /* $cv_score = 0;
    if ($cv->summary) {
        $cv_score += 15;
    }
    if ($cv->cvJobExperiences()->exists()) {
        $cv_score += 10;
    }
    if ($cv->cvJobPreferences()->exists()) {
        $cv_score += 10;
    }
    if ($cv->cvEducations()->exists()) {
        $cv_score += 10;
    }
    if ($cv->cvReferences()->exists()) {
        $cv_score += 10;
    }
    if ($cv->cvSeminars()->exists()) {
        $cv_score += 5;
    }
    if ($cv->prize) {
        $cv_score += 10;
    }

    if ($cv->hobby) {
        $cv_score += 2;
    }

    if ($cv->cvFiles()->exists()) {
        $cv_score += 3;
    }
    if ($cv->cvExams()->exists()) {
        $cv_score += 3;
    }

    if ($cv->lang) {
        $cv_score += 3;
    }
    if ($cv->cvCertificates()->exists()) {
        $cv_score += 3;
    }
    if ($cv->user->phone) {
        $cv_score += 3;
    }
    if ($cv->user->birth_date) {
        $cv_score += 4;
    }
    if ($cv->user->email) {
        $cv_score += 5;
    }
    if ($cv->competences()->exists()) {
        $cv_score += 4;
    }
    */

  const handleClosePopup = (): void => {
    setIsPopupVisable(false);
  };

  const handleCloseMessagePopup = () => {
    setIsMessagePopup(false);
  };

  const handleCloseApplicationPopup = (): void => {
    setIsApplicationPopupVisable(false);
  };
  const handleAddLocationClick = (): void => {
    setIsPopupVisable(true);
  };
  const handlApplicationPopupVisableClick = (): void => {
    setIsApplicationPopupVisable(true);
  };
  const customeAxios = new Axios();
  const { getCookie } = useCookie();

  const userTokenCookie = getCookie(CookieName.UserToken);
  const userToken = userTokenCookie ? userTokenCookie : "";
  const companyUserTokenCookie = getCookie(CookieName.CompanyUserToken);
  const companyUserToken = companyUserTokenCookie ? companyUserTokenCookie : "";

  const [similarLength, setSimilarLength] = useState<number>(0);

  //console.log(userToken);

  //console.log(location);

  const fetchAdData = async () => {
    if (!slug) return;

    // Extract the companyId from the query string
    const searchParams = new URLSearchParams(location.search);
    const companyId = searchParams.get("companyId");
    const isPreview = searchParams.get("preview") === "1";

    /* const endpoint =
      isPreview && companyId
        ? `companies/advert/get-show/${companyId}`
        : `adverts/${slug}/get-show`; */

    const endpoint = `adverts/${slug}/get-show`;

    //console.log(endpoint);

    try {
      /* const response = await customeAxios.authRequest({
        type: "get",
        endpoint: endpoint,
        payload: {
          headers: {
            Authorization: `Bearer ${companyUserToken || userToken}`,
          },
        },
      }); */

      const response = await customeAxios.request({
        endpoint,
        type: "get",
      });

      //console.log("response ", response.data.data);
      if (response.data) {
        console.log("RESPONSEEEE", response.data.data);

        setAdData(response.data.data);
      } else {
        return;
      }
    } catch (error: any) {
      Swal.fire({
        title: "Hata",
        text: error?.response?.data?.error || "Bir hata oluştu",
        icon: "error",
        confirmButtonText: "Tamam",
        showCancelButton: true,
        cancelButtonText: "Anasayfaya Git",
      }).then((result) => {
        if (result.isConfirmed) {
          // Handle "Tamam" button click
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // Handle "Anasayfaya Git" button click
          navigate("/");
        }
      });
      console.error("Error fetching ad data: ", error);
    }
  };

  useEffect(() => {
    async function getCount() {
      try {
        const response = await AdvertService.getSimilerAdvert(advertId);
        if (response && response.data && response.data.data) {
          setSimilarLength(response.data.data.length || 0);
        }
      } catch (error) {
        console.log(`Error`);
      }
    }

    getCount();
  }, [adData]);

  useEffect(() => {
    fetchAdData();
  }, [slug, location.search, companyUserToken]);

  const handleUpdateCv = () => {
    Swal.fire({
      title: "Başarılı",
      text: "CV Tarihi başarıyla güncellendi.",
      icon: "success",
      confirmButtonText: "Tamam",
    });
  };

  console.log(location.search, "location");

  useEffect(() => {
    setSaved(adData?.is_favorite || false);
  }, [adData]);

  useEffect(() => {
    setIsFollowing(adData?.is_follow || false);
  }, [adData]);

  useEffect(() => {
    setIsApply(adData?.is_apply || false);
  }, [adData]);

  const axios = new Axios();

  // Takip etme takipten çıkarma işlemi

  const handleToggleFollow = () => {
    if (!isFollowing) {
      Swal.fire({
        title: "İşlemi Onaylayın",
        text: "Firmayı takip etmek istiyor musunuz?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await axios
            .request({
              type: "post",
              endpoint: `companies/company-detail/${companyID}/follow`,
              payload: {
                headers: {
                  Authorization: `Bearer ${userToken}`,
                },
              },
            })
            .then((res: any) => {
              setIsFollowing(true); // Set following to true

              Swal.fire({
                title: "Başarılı",
                text: "Firma takip edildi.",
                icon: "success",
                confirmButtonText: "Tamam",
              });
            });
        }
      });
    } else {
      Swal.fire({
        title: "İşlemi Onaylayın",
        text: "Firmayı takip listesinden çıkartmak istiyor musunuz?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await axios
            .request({
              type: "delete",
              endpoint: `companies/company-detail/${companyID}/unfollow`,
              payload: {
                headers: {
                  Authorization: `Bearer ${userToken}`,
                },
              },
            })

            .then((res: any) => {
              setIsFollowing(false); // Set following to false
              Swal.fire({
                title: "Başarılı",
                text: "Firma takip listesinden çıkarıldı.",
                icon: "success",
                confirmButtonText: "Tamam",
              });
            });
        }
      });
    }
  };

  function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  // ilana başvurabilmek için giriş yapma işlemi

  const handleisLoginPopup = () => {
    navigate("/profilim/giris-yap", {
      state: {
        fromApplication: true,
        message: `${positionTitles}`,
      },
    });
  };

  // useEffect(() => {
  //   const fetchDefaultCv = async () => {
  //     try {
  //       const response = await axios.request({
  //         type: "get",
  //         endpoint: "user/profilim/cv",
  //         payload: {
  //           headers: {
  //             Authorization: `Bearer ${userToken}`,
  //           },
  //         },
  //       });

  //       if (response.data.data) {
  //         setCvId(response.data.data.id);
  //         setDefaultCvData(response.data.data);
  //       }
  //     } catch (error) {}
  //   };

  //   fetchDefaultCv();
  // }, []);

  // useEffect(() => {
  //   const fetchEducationInfo = async () => {
  //     try {
  //       const response = await axios.request({
  //         type: "get",
  //         endpoint: `user/profilim/cv/edu/index?cv_id=${cvId}`,
  //         payload: {
  //           headers: {
  //             Authorization: `Bearer ${userToken}`,
  //           },
  //         },
  //       });

  //       console.log(response.data.educations, "response data");

  //       if (response.data.educations.length > 0) {
  //         setEducationInfo(true);
  //         setIsCvDefault(true);
  //       } else {
  //         setEducationInfo(false);
  //         setIsCvDefault(false);
  //       }
  //     } catch (error) {}
  //   };

  //   fetchEducationInfo();
  // }, [cvId]);

  // useEffect(() => {
  //   const fetchJobExperinceInfo = async () => {
  //     try {
  //       const response = await axios.request({
  //         type: "get",
  //         endpoint: `user/profilim/cv/exp/index?cv_id=${cvId}`,
  //         payload: {
  //           headers: {
  //             Authorization: `Bearer ${userToken}`,
  //           },
  //         },
  //       });

  //       console.log(response, "response data");

  //       if (response.data.job_experiences.length > 0) {
  //         setJobExperienceInfo(true);
  //         setIsCvDefault(true);
  //       } else {
  //         setJobExperienceInfo(false);
  //         setIsCvDefault(false);
  //       }
  //     } catch (error) {}
  //   };

  //   fetchJobExperinceInfo();
  // }, [cvId]);

  const handleApplyChange = async () => {
    if (isApply) {
      Swal.fire({
        title: "İşlemi Onaylayın",
        text: "Başvuruyu geri çekmek istiyor musunuz?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Evet",
        cancelButtonText: "Hayır",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await axios
            .request({
              type: "post",
              endpoint: `adverts/${advertId}/apply-rollback`,
              payload: {
                headers: {
                  Authorization: `Bearer ${userToken}`,
                },
              },
            })
            .then((res: any) => {
              setIsApply(false);

              setAdData((prevData: any) => ({
                ...prevData,
                is_apply: false,
              }));

              Swal.fire({
                title: "Başarılı",
                text: "Başvuru geri çekildi.",
                icon: "success",
                confirmButtonText: "Tamam",
              });
            });
        }
      });
    }
  };

  /* console.log(
    "adData",
    dictionary,
    dictionaryKeys,
    dictArray,
    isAnyDictionary,
    firstDictionary
  ); */

  //console.log(adData?.company?.id);

  //console.log("ad Data", adData);

  return (
    <div className="container mx-auto flex flex-col lg:flex-row gap-4 mt-8 font-poppins">
      {/* Left Column */}

      <BreadcrumbJsonLd />

      <div className={"flex flex-row gap-4"}>
        <div className={"w-3/4 max-lg:w-full"}>
          <div className="w-full">
            <div className="hidden lg:block w-full pb-3">
              <div className="flex items-center">
                <a
                  href="/"
                  aria-label="Anasayfa"
                  className="poppins-regular text-sm"
                >
                  Ana Sayfa
                </a>

                <IconChevronRight className="text-blue-700 mx-1" size={18} />

                <a
                  href="/is-ilanlari"
                  aria-label="İşilanları"
                  className="poppins-regular text-sm"
                >
                  İş İlanları
                </a>

                <IconChevronRight className="text-blue-700 mx-1" size={18} />

                <div className="poppins-regular text-sm">
                  {adData?.cities?.map((city: City) => city?.name).join(", ")}
                </div>

                <IconChevronRight className="text-blue-700 mx-1" size={18} />

                <div className="poppins-regular text-sm">
                  {capitalizeFirstLetter(adData?.advert.title || "loading")}
                </div>
              </div>
            </div>

            {/* <div className="flex  bg-blue-300 h-auto w-full min-h-40 absolute  top-10 left-0 lg:hidden" >

</div> */}

            <GeneralBaseInfo
              advertData={adData?.advert}
              companyDetail={companyDetail}
              cities={adData?.cities || []}
              districts={adData?.districts || []}
              title={title || ""}
              companyFullName={companyFullName || "Firma Bilgisi Gizli"}
              logo={logo || ""}
              workingLocations={workingLocations}
              handlApplicationPopupVisableClick={
                handlApplicationPopupVisableClick
              }
              handleApplyChange={handleApplyChange}
              handleToggleFollow={handleToggleFollow}
              isFollowing={isFollowing}
              isApply={isApply}
              setIsFollowing={setIsFollowing}
              locations={adData?.locations}
              setIsMessagePopup={setIsMessagePopup}
            />

            <ShareButtons
              advertId={adData?.advert.id}
              companyDetail={companyDetail}
              handlApplicationPopupVisableClick={
                handlApplicationPopupVisableClick
              }
              initialIsFavorite={adData?.is_favorite}
              saved={saved}
              setSaved={setSaved}
              is_apply={adData?.is_apply}
              handleApplyChange={handleApplyChange}
              email={adData?.company.email}
            />

            <div className="hidden lg:flex bg-white w-full h-32  justify-center items-center border-b">
              <p className="text-blue-400 text-lg w-full text-center">
                İMAGE ALANI
              </p>
            </div>

            <AddDetail adDetail={adDetail || ""} />
            <MainInfo
              positions={adData?.positions}
              sectors={sectors}
              sections={sections}
              workingTypes={workingTypes}
              positionLevels={positionLevels}
              dictionary={adData?.position_dictionaries}
              workingLocations={workingLocations}
              locations={locations}
              experienceLevel={experienceLevel}
              educationLevels={educationLevels}
              companyFullName={companyFullName || "loading"}
              companyPhone={companyPhone || "loading.."}
              land_phone={companyLand_phone || ""}
              companyDetail={companyDetail}
              militaryStatuses={military}
              minAge={minAge}
              maxAge={maxAge}
              jobBenefits={jobBenefits}
              languages={languages}
              hide_contact_info={adData?.advert.hide_contact_info}
              hide_employee_info={adData?.advert.hide_employee_info}
              hide_company_info={adData?.advert.hide_company_info}
              employee_name={`${
                adData?.company.name && adData.company.surname
                  ? `${adData?.company?.name} ${adData?.company?.surname}`
                  : ``
              }`}
              drivingLicenceClasses={adData?.advert_driving_licence_classes}
              drivingLicenceSrcs={adData?.advert_driving_licence_srcs}
              positionDictionary={adData?.position_dictionary}
            />
            {/* <MainInfo
            positions={adData?.positions}
            sectors={adData?.sectors}
            //sections={adData?.sections}
            sections={[]}
            advertLanguage={adData?.language || 9}
            companyFullName={companyFullName || ''}
            companyPhone={companyPhone || ''}
            //drivingLicenceSrcs={adData?.driving_licence_srcs}
            drivingLicenceSrcs={[]}
            companyLand_phone={companyLand_phone || ''}
            drivingLicenceClasses={adData?.driving_licence_classes}
            educationLevels={adData?.education_levels}
            employee_name={`${adData?.company.name && adData.company.surname? `${adData?.company?.name} ${adData?.company?.surname}` : ``}`}
            experienceLevel={adData?.experience_level}
            //jobBenefits={adData?.job_benefits}
            jobBenefits={[]}
            //languages={adData?.languages}
            languages={[]}
            locations={adData?.locations}
            positionLevels={adData?.position_levels}
            workingLocations={adData?.working_locations}
            workingTypes={adData?.working_types}
            employee_count={adData?.employee_count}
            hide_company_info={adData?.hide_company_info}
            hide_contact_info={adData?.hide_contact_info}
            hide_employee_info={adData?.hide_employee_info}
            /> */}
            <div className="lg:flex hidden justify-between items-center bg-white border-t p-3 gap-4 ">
              {userToken && (
                <>
                  {" "}
                  <button
                    onClick={() => handleUpdateCv()}
                    className="bg-[#de5b2b] hover:bg-[#df6a40] duration-300 text-white md:px-8 rounded-md font-medium p-[14px] text-base"
                  >
                    CV TARİHİNİ GÜNCELLE
                  </button>
                </>
              )}

              {!userToken && !companyUserTokenCookie && (
                <button
                  onClick={() => navigate("/profilim/kayit-ol")}
                  className="bg-[#de5b2b] hover:bg-[#df6a40] duration-300 text-white md:px-8 rounded-md font-medium p-[14px] text-base"
                >
                  HIZLI ÖZGEÇMİŞ OLUŞTUR
                </button>
              )}

              {userToken ? (
                <>
                  {" "}
                  {adData?.is_apply ? (
                    <>
                      <a
                        onClick={() => handleApplyChange()}
                        href="#"
                        className="bg-gray-400 hover:bg-gray-500 duration-300 text-white rounded-md font-medium py-3 px-10 text-xl"
                      >
                        {" "}
                        BAŞVURUYU GERİ ÇEK
                      </a>
                    </>
                  ) : (
                    <>
                      <a
                        onClick={handlApplicationPopupVisableClick}
                        href="#"
                        className="bg-[#2253af] hover:bg-[#0a58ca] duration-300 text-white rounded-md font-medium py-3 px-10 text-xl"
                      >
                        {" "}
                        İLANA BAŞVUR
                      </a>
                    </>
                  )}
                </>
              ) : companyUserToken ? (
                <></>
              ) : (
                <>
                  {isApply ? (
                    <button
                      onClick={() => handleApplyChange()}
                      className="bg-gray-400 hover:bg-gray-500 duration-300 text-white rounded-md font-medium py-3 px-10 text-xl"
                    >
                      {" "}
                      BAŞVURUYU GERİ ÇEK
                    </button>
                  ) : (
                    <button
                      onClick={() => handleisLoginPopup()}
                      className="bg-[#2253af] hover:bg-[#0a58ca] duration-300 text-white rounded-md font-medium py-3 px-10 text-xl"
                    >
                      {" "}
                      İLANA BAŞVUR
                    </button>
                  )}
                </>
              )}
            </div>
            <img src="/ad-870.png" className=" border-t shadow-lg border-b" />
            {similarLength > 0 && (
              <div className="flex justify-between items-center bg-white border-b py-3">
                <h2 className="text-xl ml-6 font-poppins poppins-semibold">
                  Benzer İlanlar
                </h2>
                <a
                  href="/is-ilanlari"
                  className="bg-white py-2 px-4 lg:rounded lg:shadow-sm mr-6 font-poppins poppins-medium text-sm lg:border underline lg:no-underline"
                >
                  Tümü
                </a>
              </div>
            )}
            {advertId !== undefined && (
              <AdvertSectionBody advertId={advertId} dontShowPopUp={true} />
            )}
          </div>
        </div>
        {/* Right Column */}
        <div className={"w-1/4 max-lg:hidden"}>
          <div className="w-full hidden lg:flex  flex-col md:flex-row lg:flex-col gap-y-8 pb-8 lg:pb-0 gap-4 md:mt-6">
            {/* Placeholder for the first card */}
            <div className="p-4 bg-gray-100 rounded shadow-md w-full ">
              {/* <h3 className="text-[#2253af] text-center font-bold text-base mb-2">
            ZAHİR TEKSTİL İNŞAAT TAAHHÜT TURİZM TİCARET LİMİTED ŞİRKETİ
          </h3> */}

              {userToken && (
                <div
                  onClick={() => setIsMessagePopup(true)}
                  className="cursor-pointer mb-4 bg-[#2253af] flex items-center border border-[#2253af] rounded text-white justify-center py-2 hover:bg-white hover:text-[#2253af]"
                >
                  <MdEmail className="text-lg mr-2" />{" "}
                  {/* Icon with a right margin */}
                  MESAJ GÖNDER
                </div>
              )}

              <a
                target="blank"
                href={comapnyUrl}
                className="flex items-center border border-[#2253af] rounded text-[#2253af] justify-center py-2 hover:bg-[#2253af] hover:text-white gap-2 px-1"
              >
                <FaListUl className="text-lg mr-2" />{" "}
                {/* Icon with a right margin */}
                FİRMANIN DİĞER İLANLARI
              </a>
              {userToken && !hideCompanyTitle && (
                <button
                  onClick={handleToggleFollow}
                  className="flex items-center border border-[#2253af] rounded text-[#2253af] justify-center py-2 hover:bg-[#2253af] hover:text-white mt-4 hover:border-[#2253af] w-full"
                >
                  {isFollowing ? (
                    <FaStar className="text-lg mr-2 text-blue-500" />
                  ) : (
                    <FaRegStar className="text-lg mr-2" />
                  )}
                  {isFollowing ? "FİRMAYI TAKİPTEN ÇIK" : "FİRMAYI TAKİP ET"}
                </button>
              )}
              <button
                onClick={handleAddLocationClick}
                className="flex items-center border border-[#2253af] rounded text-[#f00] justify-center py-2 hover:bg-[#f00] hover:text-white mt-4 hover:border-[#f00] w-full"
              >
                <SlFlag className="text-lg mr-2" />{" "}
                {/* Icon with a right margin */}
                ŞİKAYET ET
              </button>
            </div>
            {/* Placeholder for the second card */}
            {dictionaryKeys.length > 0 && isAnyDictionary && (
              <div className="p-4 bg-white rounded shadow-md  md:w-2/4 lg:w-full hidden lg:block">
                <h3 className="text-[#2253af] font-bold text-lg mb-4">
                  İLGİNİZİ ÇEKEBİLECEK BAŞLIKLAR
                </h3>
                <hr />
                <p className="mt-4">
                  {firstDictionary.title} pozisyonu hakkında daha detaylı bilgi
                  edinmek için aşağıdaki bağlantılara göz atabilirsiniz.
                </p>
                <ul className="mt-4 font-medium">
                  <li className="flex items-center text-[#2253af] mb-3">
                    <FaChevronRight size={11} />
                    <Link to={`/pozisyon/${firstDictionary.id}`}>
                      {firstDictionary.title}
                    </Link>{" "}
                  </li>
                  <li className="flex items-center text-[#2253af] mb-3">
                    <FaChevronRight size={11} />
                    <Link to={`/pozisyon/${firstDictionary.id}`}>
                      {firstDictionary.title} Maaşları
                    </Link>{" "}
                  </li>
                  <li className="flex items-center text-[#2253af] mb-3">
                    <FaChevronRight size={11} />
                    <Link to={`/pozisyon/${firstDictionary.id}`}>
                      {firstDictionary.title} Nedir?
                    </Link>{" "}
                  </li>
                  <li className="flex items-center text-[#2253af] mb-3">
                    <FaChevronRight size={11} />
                    <Link to={`/is-ilanlari?positions=[${dictPositions?.id}]`}>
                      {firstDictionary.title} İş İlanları?
                    </Link>{" "}
                  </li>
                </ul>
              </div>
            )}

            {/* <img
          src="/ad-870.png"
          className="mt-1 rounded shadow-lg mb-3 w-[100%] "
        /> */}
          </div>
        </div>
      </div>

      {isPopupVisible && (
        <ReportPopup
          onClose={handleClosePopup}
          companyID={companyID}
          companyName={companyDetail?.company_name}
          isApply={isApply}
          setIsApply={setIsApply}
        />
      )}

      {/* TODO: Mesaj gönderme için bu popup kullanılacak unutma */}
      {isMessagePopup && (
        <MessageSendPopup
          onSuccess={() => {
            handleCloseMessagePopup();
            Swal.fire({
              title: "Başarılı",
              text: "Mesajınız Başarılı Bir Şekilde Gönderildi.",
              icon: "success",
              confirmButtonText: "Tamam",
            });
          }}
          adData={adData}
          advertId={advertId}
          onClose={handleCloseMessagePopup}
        ></MessageSendPopup>
      )}

      {isApplicationPopupVisable && (
        <AdvertApplyPopup
          onSuccess={() => {
            setIsApply(true);

            setAdData((prevData: any) => ({
              ...prevData,
              is_apply: true,
            }));
          }}
          adData={adData}
          advertId={advertId}
          onClose={handleCloseApplicationPopup}
        ></AdvertApplyPopup>
      )}

      {isCvDefault ? (
        <>
          {false && (
            <CvApplicationPopup
              adData={adData}
              onClose={handleCloseApplicationPopup}
              educationInfo={educationInfo}
              jobExperienceInfo={jobExperienceInfo}
              defaultCvData={defaultCvData}
              setAdData={setAdData}
            />
          )}
        </>
      ) : (
        <>
          {false && (
            <ApplicationPopup
              onClose={handleCloseApplicationPopup}
              // companyName={companyDetail?.company_name}
            />
          )}{" "}
        </>
      )}
    </div>
  );
};
export default Body;
function getCookie(CompanyUserToken: any) {
  throw new Error("Function not implemented.");
}
