import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "../../services/axios";
import { useLocation } from "react-router-dom";
import { IReview } from "../../containers/profile-page-container/cv-detail/contents/cv-detail/types/reviewCv";
import Details from "../../containers/profile-page-container/cv-detail/contents/cv-detail/details";
import { dataTagSymbol } from "@tanstack/react-query";
import SideBar from "../../containers/profile-page-container/cv-detail/contents/cv-detail/sideBare";

const axios = new Axios();

export default function CvShowPage() {
  const params = useParams();
  const cvId = params.slug;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [cvData, setCvData] = useState<IReview | null>(null);

  async function getCvData() {
    try {
      const shareToken = searchParams.get("share_token");
      const response = await axios.request({
        endpoint: `user/profilim/cv/${cvId}/check-share-cv-token`,
        type: "post",
        payload: {
          share_token: shareToken,
        },
      });

      if (response && response.data && response.data.data) {
        setCvData(response.data.data);
      } else {
        setCvData(null);
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      setCvData(null);
    }
  }

  useEffect(() => {
    getCvData();
  }, []);
  return (
    <div className="container mx-auto my-6">
      {cvData ? (
        <div className="flex flex-col sm:flex-row">
          <div className=" w-full  sm:w-1/4 min-w-[280px]">
            {cvData?.user && (
              <SideBar
                user={cvData?.user}
                cv={cvData.cv}
                liveInfo={cvData?.cv_live_info}
                socials={cvData?.cv_social_accounts}
                skills={cvData?.competences || []}
                isEnglish={cvData?.cv.lang == "en"}
              />
            )}
          </div>
          <Details data={cvData} />
        </div>
      ) : (
        <div className="flex font-poppins text-center items-center justify-center">
          Yükleniyor...
        </div>
      )}
    </div>
  );
}
