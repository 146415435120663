import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BlogData } from "../types";
import Axios from "../../../services/axios";
import Swal from "sweetalert2";

// create blog list card
function BlogCard({ blog }: { blog: BlogData }): React.ReactElement {
  const navigate = useNavigate();
  return (
    <div
      className="flex flex-col gap-y-1 p-4 bg-white shadow-md rounded-md cursor-pointer"
      onClick={() => navigate(`/${blog.url}`)}
    >
      <img
        src={blog.guid}
        alt={blog.post_title}
        className="w-full h-48 object-cover rounded-md"
      />
      <div className="flex flex-col gap-y-1">
        <h1 className="text-lg font-bold text-mains">{blog.post_title}</h1>
      </div>
    </div>
  );
}

function BlogList(): React.ReactElement {
  const axios = new Axios();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [blogList, setBlogList] = React.useState<BlogData[]>([]);

  useEffect(() => {
    const fetchBlogList = async () => {
      setLoading(true);
      try {
        const response = await axios.request({
          type: "get",
          endpoint: "home/get-home-blogs",
        });
        setBlogList(response?.data?.blogs);
      } catch (error: any) {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: error.response?.data?.error || "Bir hata oluştu",
        });
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogList();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-96">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-mains"></div>
      </div>
    );
  }

  return (
    <div className="container  gap-2 w-full mx-auto grid grid-cols-1 md:grid-cols-3 py-4">
      {blogList.map((blog: BlogData) => (
        <BlogCard key={blog.id} blog={blog} />
      ))}
    </div>
  );
}

export default BlogList;
