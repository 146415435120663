import React, { useEffect, useState } from "react";
import EducationForm from "./components/EducationForm";
import { cdnCityType, cdnCountryType, cdnEducationLevelType, cdnGeneralLanguageType, cdnHighSchoolBranchType, cdnHighSchoolTypeType, cdnUniversitiesType, dbUserEducationType } from "../../../../../types";
import { getEducationLevels } from "../../../../../customHooks/getEducationLevels";
import { getCountries } from "../../../../../customHooks/getCountries";
import { getCities } from "../../../../../customHooks/getCities";
import { getUniversities } from "../../../../../customHooks/getUniversities";
import { getHighSchoolTypes } from "../../../../../customHooks/getHighSchoolTypes";
import { getHighSchoolBranches } from "../../../../../customHooks/getHighSchoolBranches";
import { getUniversityBranches } from "../../../../../customHooks/getUniversityBranches";
import { getGeneralLanguages } from "../../../../../customHooks/getGeneralLanguages";
import Axios from "../../../../../services/axios";
import { useParams } from "react-router-dom";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { PiWarningCircle } from "react-icons/pi";
import { useContext } from "react";
import { CvLayoutContext, cvLayoutContextType } from "..";


const axios = new Axios()


const EduInfo: React.FC = () => {

  const { isEnglish }: cvLayoutContextType = useContext(CvLayoutContext)

  const params = useParams()
  const cvId = params.slug



  // General states
  const [educationLevels, setEducationLevels] = useState<cdnEducationLevelType[]>([])
  const [countries, setCountries] = useState<cdnCountryType[]>([])
  const [cities, setCities] = useState<cdnCityType[]>([])
  const [educationTypes, setEducationTypes] = useState<any[]>([])
  const [universities, setUniversities] = useState<cdnUniversitiesType[]>([])
  const [universityBranches, setUniversityBranches] = useState<cdnUniversitiesType[]>([])
  const [highSchoolTypes, setHighSchoolTypes] = useState<cdnHighSchoolTypeType[]>([])
  const [highSchoolBrances, setHighSchoolBranches] = useState<cdnHighSchoolBranchType[]>([])
  const [languages, setLanguages] = useState<cdnGeneralLanguageType[]>([])
  const [noteSystems, setNoteSystem] = useState<any[]>([])
  const [educations, setEducations] = useState<dbUserEducationType[]>([])
  const [educationsLength, setEducationsLength] = useState<number>(1)
  const [pageLoading, setPageLoading] = useState<boolean>(true)




  async function getAllCdns() {

    setPageLoading(true)
    const [
      cdnEducationLevels,
      cdnCountries,
      cdnUniversities,
      cdnUniversityBranches,
      cdnHighSchoolTypes,
      cdnHighSchollBranches,
      cdnLanguages
    ] = await Promise.all([
      getEducationLevels({}),
      getCountries({}),
      getUniversities({}),
      getUniversityBranches({}),
      getHighSchoolTypes({ limit: 30 }),
      getHighSchoolBranches({}),
      getGeneralLanguages({})
    ])

    setEducationLevels(cdnEducationLevels)
    setCountries(cdnCountries)
    setUniversities(cdnUniversities)
    setUniversityBranches(cdnUniversityBranches)
    setHighSchoolTypes(cdnHighSchoolTypes)
    setHighSchoolBranches(cdnHighSchollBranches)
    setLanguages(cdnLanguages)
    setPageLoading(false)
  }

  async function getAllDatas() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/edu/index?cv_id=${cvId}`,
        type: 'get',
        payload: {}
      })

      if (response && response.data && response.data.educations) {
        setEducations(JSON.parse(JSON.stringify(response.data.educations)))
        setEducationsLength(response.data.educations)
      } else {
        setEducations([])
      }
    } catch (error) {
      console.log(`Error: ${error}`)
      setEducations([])
    }
  }

  

  useEffect(() => {
    async function getDatas() {
      await getAllDatas()
      await getAllCdns()
    }
    
    getDatas()
  }, [])

  const [isAddClicked, setIsAddClicked] = useState<boolean>(false)
  return (
    <div className="flex flex-col w-full gap-y-2 rounded-md bg-white lg:shadow-md pt-2 pb-4">
      
      {/* Empty education form */}
      <EducationForm
      education={null}
      cities={[]}
      countries={countries}
      educationLevels={educationLevels}
      highSchoolBranches={highSchoolBrances}
      highSchoolTypes={highSchoolTypes}
      universities={universities}
      universityBranches={universityBranches}
      languages={languages}
      getAllDatas={getAllDatas}
      educationsLength={educationsLength}
      isAddClicked={isAddClicked}
      setIsAddClicked={setIsAddClicked}
      />

      {/* Educations not exist message */}
      {educations.length == 0 && (
        <div className="flex flex-row gap-4 m-4 border border-gray-200 rounded-md">
          <div className="flex p-4 bg-red-600 rounded-l-md items-center justify-center">
            <PiWarningCircle className="text-3xl text-white"/>
          </div>
          <div className="flex flex-col gap-1 py-2 px-1">
            <p className="font-poppins font-semibold text-gray-700 text-sm">
              {isEnglish? "You don't have any educational information added yet": 'Henüz eklenmiş bir eğitim bilginiz yok.'}
            </p>
            {isEnglish? (
              <p className="font-poppins text-sm text-gray-700 font-semibold">
                You can add new education with
                {' '}
                <button className="text-mains underline" onClick={() => setIsAddClicked(true)}>Add Education Information</button>
                {' '}
                button.
              </p>
            ): (
              <p className="font-poppins text-sm text-gray-700 font-semibold">
                <button className="text-mains underline underline-offset-4" onClick={() => setIsAddClicked(true)}>Eğitim Bilgisi Ekle</button> butonuna tıklayarak hemen yeni bir eğitim bilgisi ekleyebilirsiniz.
              </p>
            )}
          </div>
        </div>
      )}

      {/* All educations mapping */}
      {educations.map((edu) => {
        return(
          <div className="flex flex-col gap-2 lg:gap-0" key={`eduForm${edu.id}`}>
            <hr/>
            <EducationForm
            education={edu}
            cities={[]}
            countries={countries}
            educationLevels={educationLevels}
            highSchoolBranches={highSchoolBrances}
            highSchoolTypes={highSchoolTypes}
            universities={universities}
            universityBranches={universityBranches}
            languages={languages}
            getAllDatas={getAllDatas}
            />
          </div>
        )
      })}
    </div>
  );
};

export default EduInfo;
