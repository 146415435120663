import { useContext, useEffect, useState } from "react";
import services from "../../../../../services/profile-services/index";
import { ICv } from "./types/cv";
import { IUser } from "./types/users";
import ReactDomServer from "react-dom/server";
import { SlCalender } from "react-icons/sl";
import { IoCloseOutline, IoPerson, IoPrintOutline } from "react-icons/io5";
import { GoDownload } from "react-icons/go";
import { FaFacebook, FaRegEnvelope } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { CiShare2 } from "react-icons/ci";
import { IoShareSocialOutline } from "react-icons/io5";

/* import htmlToPdfMake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts'; */

import moment, { invalid } from "moment";
import "moment/locale/tr";
import { useReactToPrint } from "react-to-print";
//import generatePDF from 'react-to-pdf'
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
  TwitterIcon,
} from "react-share";
import ShareModal from "./shareModal";
import Axios from "../../../../../services/axios";
import { useLocation } from "react-router-dom";
import { CVPreviewContext } from "../index";
import { MdOutlineMessage } from "react-icons/md";
import { Tooltip } from "react-tooltip";

interface IProfileHeaderProps {
  data: {
    cv: ICv;
    user: IUser;
  };
  htmlRef: any;
}

const axios = new Axios();

export default function ProfileHeader({ data, htmlRef }: IProfileHeaderProps) {
  //pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const {
    isBlock,
    setAddNoteShow,
    handlePrint,
    handleDownloadPdf,
    setSendMessageShow,
    handleSendMessageShow,
  } = useContext(CVPreviewContext);

  const { cv, user } = data;
  const [pdfUrl, setPdfUrl] = useState<any>();

  const location = useLocation();

  const action = new URLSearchParams(location.search).get("action");

  // get share cv url
  // async function fetchData() {
  //   try {
  //     const data = await services.getShareCvUrl(cv.id);
  //     setPdfUrl(data.data.share_url);
  //   } catch (error: any) {
  //     console.log(error);
  //   }
  // }

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useEffect(() => {
    if (action) {
      if (action == "print") {
        handlePrint();
      } else if (action == "download") {
        handleDownloadPdf();
      }
    }
  }, [action]);

  return (
    <>
      <div
        className="flex justify-between py-5 px-7 text-lg font-medium w-full "
        style={{ boxShadow: "0 1px 1px 0 rgb(0 0 0 / 0.16)" }}
      >
        <div className="">
          <h5 className="text-title">CV ÖNİZLEME</h5>
          <div className="flex text-xs font-semibold mt-3">
            <div className="flex">
              <span className="w-4 mr-1">
                <SlCalender size="15" />
              </span>
              <span className="color-a">Güncelleme Tarihi: </span>
              <span className="ml-2 text-title">
                {moment(cv.updated_at)
                  .locale("tr")
                  .format("DD MMMM YYYY")
                  .toString()}
              </span>
            </div>
            {user.last_login_date && moment(user.last_login_date).isValid() && (
              <div className="flex mx-3">
                <span className="w-4 mr-1">
                  <SlCalender size="15" />
                </span>
                <span className="color-a">Son Giriş Tarihi: </span>
                <span className="ml-2 text-title">
                  {moment(user.last_login_date)
                    .locale("tr")
                    .format("DD MMMM YYYY")
                    .toString()}
                </span>
              </div>
            )}
          </div>
        </div>

        <Tooltip id={"tooltip_bla"} />

        {/* print download share  ikons */}
        {!isBlock && (
          <div className="flex">
            <button
              data-tooltip-id="tooltip_bla"
              data-tooltip-content="Mesaj Gönder"
              type="button"
              className="text-lg box-shadow bg-[#1e4a9e] rounded-md p-5 mx-1 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300"
              onClick={() => {
                handleSendMessageShow();
              }}
            >
              <FaRegEnvelope color="white" />
            </button>
            <button
              data-tooltip-id="tooltip_bla"
              data-tooltip-content="CV Yazdır"
              type="button"
              className="text-lg box-shadow bg-[#1e4a9e] rounded-md p-5 mx-1 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300"
              onClick={handlePrint}
            >
              <IoPrintOutline color="white" />
            </button>
            <button
              data-tooltip-id="tooltip_bla"
              data-tooltip-content="CV İndir"
              onClick={handleDownloadPdf}
              type="button"
              className="text-lg box-shadow bg-[#1e4a9e] rounded-md p-5 mx-1 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300"
            >
              <GoDownload color="white" />
            </button>
            <button
              data-tooltip-id="tooltip_bla"
              data-tooltip-content="Yorum Ekle"
              type="button"
              className="text-lg box-shadow bg-[#1e4a9e] rounded-md p-5 mx-1 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300"
              onClick={() => setAddNoteShow(true)}
            >
              <MdOutlineMessage color="white" />
            </button>
          </div>
        )}
      </div>
    </>
  );
}
