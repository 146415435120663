import { Dispatch, HTMLAttributes, SetStateAction, createContext, useEffect, useState, useRef } from "react"
import classNames from "classnames"
import Trigger from "./Trigger"
import Label from './Label'
import Items from "./Items"
import Item from "./Item"
import Error from "./Error"

type modeType = 'empty' | 'dirty'


// Component Type
type selectDropdownPropsType = HTMLAttributes<HTMLDivElement> & {
    isOpen?: boolean,
    setIsOpen?: Dispatch<SetStateAction<boolean>>,
    required?: boolean,
    error?: boolean,
    mode?: modeType
}

// Context Type
export type selectDropdownContextType = {
    required?: boolean,
    mode?: modeType,
    error?: boolean,
    isOpen: boolean,
    setIsOpen: Dispatch<SetStateAction<boolean>>
}

// Context Provider
export const SelectDropdownContext = createContext<selectDropdownContextType>({
    required: false,
    mode: 'empty',
    error: false,
    isOpen: false,
    setIsOpen: () => {}
})

export function SelectDropdown({
    className,
    isOpen: initialIsOpen,
    setIsOpen: initialSetIsOpen,
    children,
    required,
    error,
    mode = 'empty',
    ...props
}: selectDropdownPropsType) {

    const divRef = useRef<HTMLDivElement>(null)

    const [isOpen, setIsOpen] = useState<boolean>(initialIsOpen || false)

    const cn = classNames({
        'flex flex-col gap-1 relative': true
    })

    const value: selectDropdownContextType = {
        required,
        mode,
        error,
        isOpen: initialIsOpen != undefined? initialIsOpen: isOpen,
        setIsOpen: initialSetIsOpen != undefined? initialSetIsOpen: setIsOpen
    }

    /* 
    TODO: Bunsuz denenecek gereksizse kaldırılacak
    useEffect(() => {
        if (initialIsOpen != undefined) {
            setIsOpen(initialIsOpen)
        }
    }, [initialIsOpen]) */

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if ( divRef.current && !divRef.current.contains(event.target as Node)) {
                if (initialSetIsOpen) {
                    initialSetIsOpen(false)
                } else {
                    setIsOpen(false)
                }
            }
        }
        document.addEventListener("mousedown", handleClickOutside)
        return () => document.removeEventListener("mousedown", handleClickOutside)
    }, [])
    return(
        <SelectDropdownContext.Provider value={value}>
            <div ref={divRef} className={`${cn} ${className}`} {...props}>
                {children}
            </div>
        </SelectDropdownContext.Provider>
    )
}


SelectDropdown.Trigger = Trigger
SelectDropdown.Label = Label
SelectDropdown.Items = Items
SelectDropdown.Item = Item
SelectDropdown.Error = Error