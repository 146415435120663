import {
  CalendarTimeIcon,
  CertificateIcon,
  CheckIcon,
  PhoneCallIcon,
  SquareRoundedPlus,
  StarIcon,
  UserIcon,
  UsersIcon,
} from "../../../../../theme/icons";
import { PackageType } from "../constants/enum";

export const mockHeaderButtonContents: string[] = [
  PackageType.Paketler,
  PackageType.İlanPaketleri,
  PackageType.Doping,
  PackageType.CvGörüntülemePaketleri,
];

export const mockCardContents = [
  {
    type: "free",
    title: "Ücretsiz İlan Yayınla",
    quantity: "12 Adet 30 Günlük İlan",
    quantityPrice: "Adet Fiyatı: 449.50 TL",
    description: [
      {
        text: "İlan Yayınlama",
        icon: "https://via.placeholder.com/50",
      },
      {
        text: "Öne Çıkarılmış İlan",
        icon: "https://via.placeholder.com/50",
      },
      {
        text: "İlan Yayınlama",
        icon: "https://via.placeholder.com/50",
      },
    ],
    actionButtonText: "Ücretsiz İlan Yayınla",
    onClick: () => {},
  },
  {
    _id: "1",
    title: "Paket 2",
    price: "200",
    quantity: "12 Adet 30 Günlük İlan",
    quantityPrice: "Adet Fiyatı: 449.50 TL",
    description: [
      {
        text: "İlan Yayınlama",
        icon: "https://via.placeholder.com/50",
      },
      {
        text: "Öne Çıkarılmış İlan",
        icon: "https://via.placeholder.com/50",
      },
      {
        text: "İlan Yayınlama",
        icon: "https://via.placeholder.com/50",
      },
    ],
    actionButtonText: "Satın Al",
    onClick: () => {},
  },
  {
    _id: "2",
    title: "Paket 3",
    price: "300",
    quantity: "12 Adet 30 Günlük İlan",
    quantityPrice: "Adet Fiyatı: 449.50 TL",
    description: [
      {
        text: "İlan Yayınlama",
        icon: "https://via.placeholder.com/50",
      },
      {
        text: "Öne Çıkarılmış İlan",
        icon: "https://via.placeholder.com/50",
      },
      {
        text: "İlan Yayınlama",
        icon: "https://via.placeholder.com/50",
      },
    ],
    actionButtonText: "Satın Al",
    onClick: () => {},
  },
  {
    _id: "3",
    title: "Paket 4",
    price: "400",
    quantity: "12 Adet 30 Günlük İlan",
    quantityPrice: "Adet Fiyatı: 449.50 TL",
    description: [
      {
        text: "İlan Yayınlama",
        icon: "https://via.placeholder.com/50",
      },
      {
        text: "Öne Çıkarılmış İlan",
        icon: "https://via.placeholder.com/50",
      },
      {
        text: "İlan Yayınlama",
        icon: "https://via.placeholder.com/50",
      },
    ],
    actionButtonText: "Satın Al",
    onClick: () => {},
  },
  {
    _id: "4",
    title: "Paket 5",
    price: "500",
    quantity: "12 Adet 30 Günlük İlan",
    quantityPrice: "Adet Fiyatı: 449.50 TL",
    description: [
      {
        text: "İlan Yayınlama",
        icon: "https://via.placeholder.com/50",
      },
      {
        text: "Öne Çıkarılmış İlan",
        icon: "https://via.placeholder.com/50",
      },
      {
        text: "İlan Yayınlama",
        icon: "https://via.placeholder.com/50",
      },
    ],
    actionButtonText: "Satın Al",
    onClick: () => {},
  },
  {
    _id: "5",
    title: "Paket 6",
    price: "600",
    quantity: "12 Adet 30 Günlük İlan",
    quantityPrice: "Adet Fiyatı: 449.50 TL",
    description: [
      {
        text: "İlan Yayınlama",
        icon: "https://via.placeholder.com/50",
      },
      {
        text: "Öne Çıkarılmış İlan",
        icon: "https://via.placeholder.com/50",
      },
      {
        text: "İlan Yayınlama",
        icon: "https://via.placeholder.com/50",
      },
    ],
    actionButtonText: "Satın Al",
    onClick: () => {},
  },
];

export const mockPackagesBodyContent = [
  {
    text: "1 Yıl Kullanım Süresi",
    icon: CalendarTimeIcon,
  },
  {
    text: "Tüm Başvuruları Görüntüleme",
    icon: CertificateIcon,
  },
  {
    text: "Premium Üyelik Avantajları",
    icon: StarIcon,
  },
  {
    text: "Özel Müşteri Temsilcisi",
    icon: UserIcon,
  },
  {
    text: "İlan Ekleme Desteği",
    icon: SquareRoundedPlus,
  },
  {
    text: "Öncelikli İlan Onayı",
    icon: CheckIcon,
  },
  {
    text: "Başvuru Desteği",
    icon: UsersIcon,
  },
  {
    text: "7 Gün Çağrı Merkezinden Ulaşabilme (*)",
    icon: PhoneCallIcon,
  },
];
