import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdKeyboardArrowLeft, MdOutlineCorporateFare } from "react-icons/md";
import { SlPaperPlane } from "react-icons/sl";
import { IoCheckmarkDoneOutline, IoEye } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import Axios from "../../../../services/axios";
import Pusher from "pusher-js";
import * as PusherTypes from "pusher-js";
import moment from "moment";
import Swal from "sweetalert2";
import { LuLoader2 } from "react-icons/lu";
import { PiDotsThreeVertical } from "react-icons/pi";
import { GrAttachment } from "react-icons/gr";
import { LiaClipboardListSolid } from "react-icons/lia";
import { FaRegTrashAlt } from "react-icons/fa";
import AddInterviewPopup from "../../../company-page-container/cv-detail/components/AddInterviewPopup";
var presenceChannel: PusherTypes.PresenceChannel;

const axios = new Axios();
const Body: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [data, setData] = useState<any>(null);
  const [messages, setMessages] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [newMessage, setNewMessage] = useState<string>("");
  const [sending, setSending] = useState<boolean>(false);
  const [dateKeys, setDateKeys] = useState<string[]>([]);

  const usertoken = Cookies.get("userToken");
  const companyUserToken = Cookies.get("companyUserToken");
  const chatWindow = useRef<HTMLDivElement>(null);
  const isCompany = !!companyUserToken;
  const appKey = process.env.REACT_APP_PUSHER_APP_KEY as string;
  const cluster = process.env.REACT_APP_PUSHER_APP_CLUSTER as string;

  useEffect(() => {
    if (loading) {
      return;
    }

    let pusher = new Pusher(appKey, {
      cluster: cluster,
    });
    pusher.connect();
    let channel = pusher.subscribe("is_chat." + params.id);
    channel.bind("is_chat", (recieve: any) => {
      console.log("chat recieve", recieve.chatMessage, data);
      setData((prev: any) => {
        let temp = [recieve.chatMessage, ...prev.messages];
        return { ...prev, messages: temp };
      });
    });

    channel.bind("MessageRead", (recieve: any) => {
      console.log("message read", recieve);
      setData((prev: any) => {
        let messages = prev.messages.map((el: any) => {
          if (el.id === recieve.message_id) {
            return { ...el, is_read: 1 };
          } else {
            return { ...el };
          }
        });

        console.log(messages);

        return {
          ...prev,
          messages: messages,
        };
      });
    });

    return () => {
      pusher.unsubscribe("chat." + params.id);
      pusher.disconnect();
    };
  }, [loading]);

  useEffect(() => {
    if (params.id) {
      getMessageList();
    } else {
      navigate(-1);
      return;
    }
  }, []);

  const getMessageList = () => {
    if (companyUserToken) {
      axios
        .authRequest({
          type: "get",
          endpoint: `chat/get-messages/${params.id}`,
          payload: {},
        })
        .then((response: any) => {
          setData(response.data);
        });
    }

    if (usertoken) {
      axios
        .userAuthRequest({
          type: "get",
          endpoint: `chat/get-messages/${params.id}`,
          payload: {},
        })
        .then((response: any) => {
          setData(response.data);
        });
    }
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    console.log(data);
    let temp: any = {};
    data.messages.forEach((message: any) => {
      if (
        !Array.isArray(temp[moment(message.created_at).format("DD.MM.YYYY")])
      ) {
        temp[moment(message.created_at).format("DD.MM.YYYY")] = [];
      }
      temp[moment(message.created_at).format("DD.MM.YYYY")].push(message);
    });
    setDateKeys(Object.keys(temp).reverse());
    Object.keys(temp).forEach((key) => {
      temp[key].reverse();
    });

    setMessages(temp);
    setLoading(false);
    setTimeout(() => {
      if (chatWindow !== null) {
        chatWindow?.current?.scrollTo({
          top: chatWindow?.current?.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 100);
  }, [data]);

  const handleBackClick = () => {
    navigate(-1); // This will navigate to the previous page
  };

  const sendMessage = () => {
    console.log(newMessage, data);
    if (sending) return;

    if (newMessage === "") {
      Swal.fire({
        title: "Uyarı",
        text: "Mesajınızı girin.",
        icon: "error",
        confirmButtonText: "Tamam",
      });
      return;
    }
    setSending(true);
    (companyUserToken
      ? axios.authRequest({
          type: "post",
          endpoint: "chat/send-message",
          payload: {
            message: newMessage,
            receiver_id: data.chat.user_id,
          },
        })
      : axios.userAuthRequest({
          type: "post",
          endpoint: "chat/send-message",
          payload: {
            message: newMessage,
            receiver_id: data.chat.company_id,
          },
        })
    )
      .then((response: any) => {
        if (response.data.status_code === 200) {
          setSending(false);
          setNewMessage("");
        }
      })
      .catch((error: any) => {
        Swal.fire({
          title: "Uyarı...",
          text: "Mesaj Gönderilirken Bir Hata Oluştu",
          icon: "error",
          confirmButtonText: "Tamam",
        });
        setSending(false);
        console.log(error);
      });
  };

  const handleSingleDelete = (id: number) => {
    Swal.fire({
      title: "Dikkat",
      text: "Mesajı silmek istediğinize emin misiniz? Bu işlem geri alınamaz.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sil",
      cancelButtonText: "Kapat",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (companyUserToken) {
          axios
            .authRequest({
              type: "delete",
              endpoint: `companies/chat/delete-chats?chat_ids[]=${id}`,
              payload: {},
            })
            .then((response: any) => {
              setTimeout(() => {
                navigate("/isveren/mesajlar");
              }, 500);

              Swal.fire({
                title: "Mesaj Silindi",
                text: "Mesaj silme işlemi başarılı.",
                icon: "success",
                confirmButtonText: "Tamam",
              });
            })
            .catch(() => {
              Swal.fire({
                title: "Mesajlar Silinemedi",
                text: "Mesaj silme işlemi yapılamadı daha sonra tekrar deneyin",
                icon: "error",
                confirmButtonText: "Tamam",
              });
            });
        }

        if (usertoken) {
          axios
            .userAuthRequest({
              type: "delete",
              endpoint: `user/profilim/chat/delete-chats?chat_ids[]=${id}`,
              payload: {},
            })
            .then((response: any) => {
              setTimeout(() => {
                navigate("/profilim/mesajlar");
              }, 500);
              Swal.fire({
                title: "Mesaj Silindi",
                text: "Mesaj silme işlemi başarılı.",
                icon: "success",
                confirmButtonText: "Tamam",
              });
            })
            .catch(() => {
              Swal.fire({
                title: "Mesajlar Silinemedi",
                text: "Mesaj silme işlemi yapılamadı daha sonra tekrar deneyin",
                icon: "error",
                confirmButtonText: "Tamam",
              });
            });
        }
      }
    });
  };

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isInterviewOpen, setIsInterviewOpen] = useState<boolean>(false);

  const divRef = useRef<HTMLDivElement>(null);

  const togglePopup = () => {
    setIsPopupOpen((prev) => !prev);
  };
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        if (!isInterviewOpen) {
          setIsPopupOpen(false);
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (loading)
    return (
      <div
        className={
          "min-h-56 flex justify-center items-center text-gray-600 w-full "
        }
      >
        <LuLoader2 className={"w-16 h-16 animate-spin"}></LuLoader2>
      </div>
    );
  return (
    <div
      className={`w-full flex flex-col ${
        location.pathname.startsWith("/isveren/mesajlar")
          ? "container mx-auto my-10"
          : ""
      }`}
    >
      <div className="h-20 bg-white w-full flex justify-start items-center px-2">
        <MdKeyboardArrowLeft
          className="text-5xl text-mains cursor-pointer"
          onClick={handleBackClick}
        />

        <Link
          className={"flex items-center"}
          to={
            isCompany
              ? "/isveren/cv/" + data?.chat?.user?.cv_id
              : "/firma/" + data?.chat?.company?.company_id
          }
        >
          <img
            src={
              isCompany ? data?.chat?.user?.profile : data?.chat?.company?.logo
            }
            alt=""
            className="w-10 h-10 rounded-full object-cover"
          />

          <p className="font-poppins poppins-medium text-sm ml-4">
            {isCompany
              ? data?.chat?.user?.name + " " + data?.chat?.user?.surname
              : data?.chat?.company?.company_name}
          </p>
        </Link>
        <div className={"grow flex justify-end"}>
          {isInterviewOpen && (
            <AddInterviewPopup
              onClose={() => {
                setIsInterviewOpen(false);
              }}
              id={data.chat?.user?.id}
              byPassBlock={true}
            />
          )}
          <span className="text-xl lg:text-2xl relative flex justify-center items-center">
            <div
              onClick={(e) => {
                togglePopup();
                e.stopPropagation();
              }}
            >
              <button type="button" className={""}>
                <PiDotsThreeVertical className="text-black text-3xl" />
              </button>
            </div>

            {isPopupOpen && (
              <div
                ref={divRef}
                className="absolute -bottom-4 right-0 translate-y-full z-[99]"
              >
                <div className="relative shadow-[0_-2px_30px_5px_rgba(26,26,26,0.3)] rounded-b-lg rounded-tl-lg">
                  <div className="triangle-white top-0 -translate-y-full absolute right-0" />
                  <div className="bg-white p-4 flex flex-col gap-2 rounded-b-lg rounded-tl-lg ">
                    <Link
                      to={
                        isCompany
                          ? "/isveren/cv/" + data.chat?.user?.cv_id
                          : "/firma/" + data.chat?.company?.company_id
                      }
                      className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap"
                    >
                      {isCompany ? (
                        <GrAttachment />
                      ) : (
                        <MdOutlineCorporateFare />
                      )}{" "}
                      {isCompany ? "Cv Görüntüle" : "Firmayı Görüntüle"}
                    </Link>
                    <hr />
                    {isCompany && data.chat.user.is_visibility && (
                      <>
                        <div
                          onClick={(e) => {
                            console.log("open interview");
                            setIsInterviewOpen(true);
                            e.stopPropagation();
                          }}
                          className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap cursor-pointer"
                        >
                          <LiaClipboardListSolid /> Mülakat Ekle
                        </div>
                        <hr />
                      </>
                    )}

                    <div
                      onClick={() => {
                        handleSingleDelete(data.chat.id);
                      }}
                      className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap"
                    >
                      <button className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap">
                        <FaRegTrashAlt /> Sil
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </span>
        </div>
      </div>

      <div className="bg-[#edeaea]  flex flex-col justify-end rounded-b-md ">
        <div
          ref={chatWindow}
          className={"h-[600px]  flex flex-col overflow-y-auto"}
        >
          {dateKeys.map((key: any) => (
            <div className="flex flex-col justify-center items-center gap-6 px-6">
              {/* time */}
              <div className="p-2 my-3 bg-[#1a75ba] rounded-full text-white font-poppins poppins-regular text-xs">
                <p>{key}</p>
              </div>

              {messages[key].map((message: any) => {
                if (data.guard === message.sender) {
                  return (
                    <div className="flex justify-end items-end flex-col w-full">
                      <div className="w-[250px] shadow-md pt-4 px-4 pb-2 font-poppins poppins-regular text-sm bg-[#a9ffca] flex flex-col rounded-md">
                        <p className="max-w-[250px] break-words">
                          {message.message}
                        </p>

                        <p className="flex justify-end w-full items-center gap-1 bg-[#a9ffca]">
                          <span className="font-poppins poppins-regular text-[10px]">
                            {moment(message.created_at).format("HH:mm")}
                          </span>
                          <IoCheckmarkDoneOutline
                            className={`${
                              message.is_read === 1 ? "text-mains" : ""
                            } text-sm`}
                          />
                        </p>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className="flex justify-start items-start flex-col w-full ">
                      <div className="w-[250px] shadow-md pt-4 px-4 pb-2 font-poppins poppins-regular text-sm bg-[#eff1f7] flex flex-col rounded-md">
                        <p className="max-w-[250px] break-words">
                          {message.message}
                        </p>

                        <p className="flex justify-end w-full items-center gap-1 bg-[#eff1f7]">
                          <span className="font-poppins poppins-regular text-[10px]">
                            {moment(message.created_at).format("HH:mm")}
                          </span>
                          <IoCheckmarkDoneOutline
                            className={`${
                              message.is_read === 1 ? "text-mains" : ""
                            } text-sm`}
                          />
                        </p>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          ))}
        </div>

        <div
          className={`${
            (isCompany && data?.chat?.user?.is_visibility) || !isCompany
              ? "grid grid-cols-12"
              : "grid grid-cols-1"
          } gap-2 w-full p-4`}
        >
          {(isCompany && data?.chat?.user?.is_visibility) || !isCompany ? (
            <>
              <div className={"col-span-9 md:col-span-11"}>
                <textarea
                  className=" min-h-12 bg-white px-2 py-2 border border-black rounded-md w-full text-wrap"
                  placeholder="Mesaj"
                  value={newMessage}
                  onChange={(e) => {
                    setNewMessage(e.target.value);
                  }}
                />
              </div>

              <div
                className={
                  "flex justify-center items-center col-span-3 md:col-span-1"
                }
              >
                <button
                  onClick={() => {
                    sendMessage();
                  }}
                  className="max-w-[50px] h-12 w-12 bg-mains text-white rounded-full flex justify-center items-center"
                >
                  {sending ? (
                    <LuLoader2 className="text-xl animate-spin" />
                  ) : (
                    <SlPaperPlane className="text-xl" />
                  )}
                </button>
              </div>
            </>
          ) : (
            <div className={"w-full"}>
              Mesaj gönderebilmek için kullanıcının iletişim bilgilerini açmanız
              gerekiyor.{" "}
              <Link to={"/isveren/cv/" + data?.chat?.user?.cv_id}>
                <span className={"underline"}>Cv görüntüle </span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Body;
