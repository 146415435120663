import React from "react";
import { GeneralPackages } from "../../login-page-container/company/login/general-packages";

const index = () => {
  return (
    <div>
      <GeneralPackages />
    </div>
  );
};

export default index;
