import { useEffect, useRef, useState } from "react";
import { InputType } from "../../../containers/login-page-container/company/register/constants/enum";
import { CustomInput } from "../custom-input";
import { CustomSelect } from "../custom-select";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

interface IFormFieldProps {
  readonly type: string;
  readonly label: React.ReactNode | string;
  readonly formik?: any;
  readonly name: string;
  readonly options?: { value: string; label: string }[];
  readonly phoneMaskType?: "phone" | "landline" | "taxNumber";
  readonly popup?: boolean;
  readonly disabled?: boolean;
  readonly required?: boolean;
  id?: string;
  value?: string;
  onClick?: (e: React.MouseEvent<HTMLSelectElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function CustomFormField(props: IFormFieldProps): React.ReactElement {
  const { type, label, formik, name, options, disabled, required, onClick } =
    props;
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);

  const fieldRef = useRef<HTMLDivElement>(null);

  const getPasswordStrength = (password: string) => {
    if (password.length >= 8) {
      setShowPasswordPopup(false)
    }
    return password.length >= 8 ? "Güçlü" : "Zayıf";
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        fieldRef.current &&
        !fieldRef.current.contains(event.target as Node)
      ) {
        setShowPasswordPopup(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className="relative flex flex-col w-full gap-y-2 font-poppins"
      ref={fieldRef}
    >
      {type === InputType.Select ? (
        <CustomSelect
          onClick={onClick}
          required={required}
          disabled={disabled}
          className={`
          ${formik?.values[name] && "text-mains border-mains"}
           ${
             formik.errors[name] && formik.touched[name]
               ? "border-red-500 !bg-red-100 text-red-500 required:ring-red-500  "
               : ""
           }
`}
          value={formik.values[name] || "+90"}
          onChange={
            formik.handleChange
              ? (e: React.ChangeEvent<HTMLSelectElement>) => {
                  formik.handleChange(e);
                  formik.setFieldValue(name, e.target.value);
                }
              : (e: React.ChangeEvent<HTMLSelectElement>) => {
                  formik.setFieldValue(name, e.target.value);
                }
          }
          name={name}
          options={options || []}
          placeholder={typeof label === "string" ? label : ""}
        />
      ) : type === InputType.Checkbox ? (
        <label className="flex items-start cursor-pointer">
          <div className={"mt-1"}>
            <CustomInput
              value={formik.values[name] || false}
              onChange={() => formik.setFieldValue(name, !formik.values[name])}
              name={name}
              type={type}
              placeholder=""
              className={""}
            />
          </div>
          <span className="ml-2 text-sm">{label}</span>
        </label>
      ) : name === "password" &&
        formik.values.password &&
        formik.touched["password"] ? (
        <CustomInput
          className={
            formik.errors[name] && formik.touched[name] ? "border-red-500 !bg-red-100" : ""
          }
          maxLength={20}
          value={formik.values[name] || ""}
          onChange={(e) => formik.setFieldValue(name, e.target.value) as void}
          name={name}
          type={type}
          placeholder={label as string}
          phoneMaskType={props.phoneMaskType}
        />
      ) : (
        <CustomInput
          className={`
          ${formik?.values[name] && "text-mains border-mains"}
          ${
            formik?.errors[name] && formik?.touched[name]
              ? "border-red-500  !bg-red-100"
              : ""
          }
              `}
          value={formik?.values[name] || ""}
          onChange={(e) => {
            formik?.setFieldValue(name, e.target.value) as void;
            if (name === "password") {
              setShowPasswordPopup(true);
            }
          }}
          name={name}
          maxLength={name === "password" ? 20 : undefined}
          type={type}
          placeholder={label as string}
          phoneMaskType={props.phoneMaskType}
        />
      )}

      {name === "password" && showPasswordPopup && (
        <div
          className={`absolute bg-white border w-full p-2 mt-1 top-16 left-0 z-10 shadow-lg rounded-md`}
        >
          <div className="flex flex-col gap-2 text-xs">
            <div className="w-full">
              <div
                className={`
                 ${
                   getPasswordStrength(formik.values.password) === "Zayıf" &&
                   " w-40  bg-red-500 "
                 }
                 ${
                   getPasswordStrength(formik.values.password) === "Güçlü" &&
                   " w-100  bg-green-500 "
                 }
                 transition-all duration-3 ease-out
                 h-5  pl-2 flex items-center text-md p-3 border rounded-md`}
              >
                {getPasswordStrength(formik.values.password)}
              </div>
            </div>
            <div className="flex gap-2">
              {formik.values.password.length >= 8 ? (
                <FaCheckCircle color="#00b341" />
              ) : (
                <FaTimesCircle color="#ff0000" />
              )}
              <span>En az 8 karakter</span>
            </div>
            <div className="flex gap-2">
              {formik.values.password.length <= 20 ? (
                <FaCheckCircle color="#00b341" />
              ) : (
                <FaTimesCircle color="#ff0000" />
              )}
              <span>En fazla 20 karakter</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export { CustomFormField };
