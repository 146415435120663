export enum InputType {
  Text = "text",
  Password = "password",
  Email = "email",
  Tel = "tel",
  Checkbox = "checkbox",
  Select = "select",
  TCorvergi="TCorvergi"

}
