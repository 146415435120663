import { TfiLayoutListThumb } from "react-icons/tfi";
import React from "react";

interface ArticleContentsProps {
  details: {
    id: number;
    section_id: number;
    title: string;
    order: number;
    detail: string;
  }[];
}

const ArticleContents: React.FC<ArticleContentsProps> = ({ details }) => {
  return (
    <div className="flex flex-col md:flex-row">
      <div className="p-5 xl:w-1/2 md:w-full lg:pr-8 lg:ml-8 lg:mr-8 shadow-lg">
        <h2
          className="text-md mb-4 flex items-center"
          style={{ fontSize: "21px" }}
        >
          <TfiLayoutListThumb className="mr-2" /> Yazının İçindekiler
        </h2>
        <ol className="custom-ol">
          {details.map((detail, index) => (
            <li
              key={index}
              className="mb-4 text-title fw-6 font-medium text-[16px]"
            >
              <a href={`#detail-${detail.id}`}>{detail.title}</a>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default ArticleContents;
