import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { IoIosArrowDown } from "react-icons/io";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { MdExpandMore } from "react-icons/md";
import ExamplePopup from "./content/exampl-popup/index";

import Axios from "../../../../services/axios";
import { DataContextType } from "../../../../store/contextApi/ad-api-context/Iget-ad-data";
import { AdvertPayload } from "../../../../containers/company-page-container/creat-ads-page/advert-create-form";
import { formatPhoneNumber, formatTurkishPhone } from "../../../../lib/utils";
type OptionType = {
  value: string;
  label: string;
};
interface BaseInfo {
  phone: number;
  land_phone: number;
  full_name: string;
  company_name: string;
}

// Options for the second dropdown
const optionsFirst: OptionType[] = [
  { value: "Gizle", label: "Gizle" },
  { value: "Göster", label: "Göster" },
];
const optionsSecond: OptionType[] = [
  { value: "show_all", label: "Tümü Gösterilsin" },
  { value: "show_gsm", label: "Cep Telefonu Gösterilsin" },
  { value: "show_phone", label: "Sabit Telefon Gösterilsin" },
  { value: "hide_all", label: "Tümü Gizlensin" },
  // Add more options as needed
];
const optionsThierd: OptionType[] = [
  { value: "Gizle", label: "Gizle" },
  { value: "Goster", label: "Göster" },
];
interface CompanyDetailAndDescriptionProps {
  onDetailChange: (detail: string) => void;
  onHideContactInfoChange: (
    value: "show_all" | "show_gsm" | "show_phone" | "hide_all"
  ) => void;
  onHideCompanyInfoChange: (value: boolean) => void;
  onHideEmployeeInfoChange: (value: boolean) => void;
  fieldErrors: { [key: string]: boolean };
  contextData?: DataContextType;
  formData?: AdvertPayload | null;
  setFormData?: Dispatch<SetStateAction<AdvertPayload>>;
}

const CompanyDetailAndDescription: React.FC<
  CompanyDetailAndDescriptionProps
> = ({
  fieldErrors,
  onDetailChange,
  onHideContactInfoChange,
  onHideCompanyInfoChange,
  onHideEmployeeInfoChange,
  contextData,
}) => {
  const [showMinLengthWarning, setShowMinLengthWarning] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const customeAxios = new Axios();
  const [isPopupVisible, setIsPopupVisable] = useState<boolean>(false);
  const dropdownRefFirst = useRef<HTMLDivElement | null>(null);
  const dropdownRefSecond = useRef<HTMLDivElement | null>(null);
  const dropdownRefThierd = useRef<HTMLDivElement | null>(null);
  const toggleOpen = () => setIsOpen(!isOpen);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [isOpenFirst, setIsOpenFirst] = useState(false);
  const [isOpenSecond, setIsOpenSecond] = useState(false);
  const [isOpenThierd, setIsOpenThierd] = useState(false);
  const [baseInfo, setBaseInfo] = useState<BaseInfo>();
  const [selectedOptionFirst, setSelectedOptionFirst] =
    useState<OptionType | null>(
      contextData
        ? //TODO: Gizli hali de test edilecek
          optionsFirst.find((option) =>
            contextData.data?.advert.hide_company_info
              ? option.value == "Gizle"
              : option.value == "Göster"
          ) || null
        : optionsFirst.find((option) => option.value == "Göster") || null
    );
  const [selectedOptionSecond, setSelectedOptionSecond] =
    useState<OptionType | null>(
      contextData
        ? optionsSecond.find(
            (option) =>
              option.value === contextData.data?.advert.hide_contact_info
          ) || null
        : optionsSecond.find((option) => option.value === "show_all") || null
    );

  const [selectedOptionThierd, setSelectedOptionThierd] =
    useState<OptionType | null>(
      contextData
        ? //TODO: Gizli hali de test edilecek
          optionsThierd.find((option) =>
            contextData.data?.advert.hide_employee_info
              ? option.value == "Gizle"
              : option.value == "Goster"
          ) || null
        : optionsThierd.find((option) => option.value == "Goster") || null
    );

  const handleSelectOptionFirst = (option: OptionType) => {
    setSelectedOptionFirst(option);
    onHideCompanyInfoChange(option.value === "Gizle");
    setIsOpenFirst(false);
  };

  const handleSelectOptionSecond = (option: OptionType) => {
    setSelectedOptionSecond(option);
    onHideContactInfoChange(
      option.value as "show_all" | "show_gsm" | "show_phone" | "hide_all"
    );
    setIsOpenSecond(false); // Close the dropdown
  };

  const handleSelectOptionThierd = (option: OptionType) => {
    setSelectedOptionThierd(option);
    onHideEmployeeInfoChange(option.value === "Gizle");
    setIsOpenThierd(false); // Close the dropdown
  };

  const handleClosePopup = (): void => {
    setIsPopupVisable(false);
  };

  useEffect(() => {
    // Explicitly type the event parameter
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as Node; // Cast event.target to Node

      // Check each dropdown ref
      if (
        dropdownRefFirst.current &&
        !dropdownRefFirst.current.contains(target)
      ) {
        setIsOpenFirst(false);
      }
      if (
        dropdownRefSecond.current &&
        !dropdownRefSecond.current.contains(target)
      ) {
        setIsOpenSecond(false);
      }
      if (
        dropdownRefThierd.current &&
        !dropdownRefThierd.current.contains(target)
      ) {
        setIsOpenThierd(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchBaseInfo = async () => {
      const response = await customeAxios.authRequest({
        type: "get",
        endpoint: "companies/advert/get-base-info",
        payload: "",
      });
      if (response.data) {
        setBaseInfo(response.data.info);
      }
    };
    fetchBaseInfo();
  }, []);

  function renderContactInfoBasedOnSelection() {
    return (
      <div className="grid grid-cols-12 gap-4 my-4">
        {selectedOptionSecond?.value != "show_gsm" &&
          selectedOptionSecond?.value != "hide_all" &&
          baseInfo?.land_phone && (
            <div
              className="col-span-12 md:col-span-6 lg:col-span-3"
              id="phone_info_div"
            >
              <div className="flex flex-col w-full group">
                <p className="mb-0 font-medium md:mb-2">Sabit Telefon</p>
                <p className="text-sm font-medium">
                  {formatTurkishPhone(baseInfo?.land_phone.toString())}
                </p>
              </div>
            </div>
          )}

        {/* <hr className="mb-2"></hr> */}

        {selectedOptionThierd?.value !== "Gizle" && (
          <div
            className="col-span-12 md:col-span-6 lg:col-span-3 hidden-authorized"
            id="authorized_info_div"
          >
            <div className="flex flex-col w-full group">
              <p className="mb-0 font-medium md:mb-2">Yetkili</p>
              <p className="w-full text-sm font-medium">
                {baseInfo?.full_name}
              </p>
            </div>
          </div>
        )}

        {selectedOptionSecond?.value != "hide_all" &&
          selectedOptionSecond?.value != "show_phone" &&
          baseInfo?.phone && (
            <div className="col-span-12 md:col-span-6 lg:col-span-3">
              <div className="flex flex-col w-full " id="gsm_info_div">
                <div className="flex flex-col ">
                  <p className="mb-0 font-medium md:mb-2">Cep Telefonu</p>
                  <p className="text-sm font-medium">
                    {formatTurkishPhone(baseInfo?.phone.toString())}
                  </p>
                </div>
              </div>
            </div>
          )}

        {selectedOptionFirst?.value !== "Gizle" && (
          <div className="col-span-12 md:col-span-6 lg:col-span-3">
            <div id="compDiv">
              <div className="flex flex-col w-full group">
                <p className="mb-0 font-medium md:mb-2">Firma Adı</p>
                <p className="w-full text-sm font-medium">
                  {baseInfo?.company_name}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  const handleEditorChange = (event: any, editor: ClassicEditor) => {
    const data = editor.getData();
    const textLength = data.replace(/<[^>]*>/g, "").length; // Remove HTML tags and get text length

    // Safely apply styles to the editor's editable element
    if (editor.ui.view.editable.element) {
      editor.ui.view.editable.element.style.paddingLeft = "20px";
    }

    setCharacterCount(textLength);
    setShowMinLengthWarning(textLength > 0 && textLength < 140);

    if (textLength <= 4500) {
      onDetailChange(data);
    } else {
      editor.setData(data.substring(0, 4500)); // Prevent input if it exceeds the maximum limit (optional)
    }
  };

  return (
    <div className="w-full mb-1 ">
      <div
        className="hidden md:flex items-center justify-between w-full p-4 mb-2 bg-white rounded-md shadow-md cursor-pointer"
        onClick={toggleOpen}
      >
        <h2 className="font-medium text-[#2253AF] md:text-2xl pl-6">
          İlan Şirket / Firma Bilgileri
        </h2>
        <MdExpandMore
          className={`transition-transform duration-500 text-[#69737f] ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          size={30}
        />
      </div>
      {/* Accordion context start */}
      <div
        className={`w-full p-4 rounded bg-[#FFF] shadow-md ${
          isOpen ? "flex" : "hidden"
        } flex-col transition-all z-30`}
        id="ilanSirketBilgileriDropdown"
      >
        <div className="flex md:flex-row flex-col-reverse items-start md:items-center justify-between create-advert-label ">
          <div className="flex flex-col text-">
            <label className="text-md text-[#69737f] font-medium">
              Şirket / İş Tanımı / Aranan Nitelikler
              <em className="text-[red]">*</em>
            </label>
            <p className="text-sm text-red-500" id="175-error"></p>
          </div>

          <button
            className="bg-[#2253af] text-white px-2 py-2 rounded-md font-bold mb-4 md:mb-0 w-auto md:w-[19%] text-center hover:bg-[#0d6efd] cursor-pointer "
            data-bs-toggle="modal"
            data-bs-target="#file-upload"
            onClick={() => {
              setIsPopupVisable(true);
            }}
          >
            Örnek İlan Metni
          </button>
        </div>
        {/* ilan metni ksımı buraya editor gelicek */}
        <div className="w-full pt-0 md:pt-4">
          <div className="h-auto">
            <div className="w-full pt-4 custom-editor">
              {showMinLengthWarning && (
                <div className="mb-1 text-red-500 text-md">
                  {/* Lütfen en az 140 karakter uzunluğunda bir metin giriniz */}
                  Tamamlamak için en az {140 - characterCount} karakter daha
                  giriniz
                </div>
              )}

              {/* TODO: Güzel değilse kaldırılacak */}
              <div>
                <CKEditor
                  editor={ClassicEditor}
                  data={""}
                  config={{
                    removePlugins: ["Underline"], // Altı çizili özelliğini kaldırır
                  }}
                  onReady={(editor) => {
                    if (contextData && contextData.data?.advert.detail) {
                      editor.setData(contextData.data.advert.detail);
                    }
                  }}
                  onChange={handleEditorChange}
                />
              </div>
              <div>
                {/* Display remaining characters */}
                {4500 - characterCount}
              </div>
            </div>
          </div>
        </div>
        {baseInfo && renderContactInfoBasedOnSelection()}

        <div className="flex flex-col w-full mt-3 md:flex-row ">
          <div
            ref={dropdownRefSecond}
            className="relative group w-full md:w-[35%] mr-8 mb-3 md:mb-0"
          >
            <label className="mb-2 ml-0 text-sm font-poppins font-semibold text-[13px] text-gray-700">
              İletişim Bilgisi Gizleme
            </label>
            <button
              onClick={() => setIsOpenSecond(!isOpenSecond)}
              className={`flex items-center justify-between text-sm w-full ${
                isOpenSecond ? "shadow-form-glow" : "shadow-none"
              } py-2 text-blue-500 border border-blue-500 rounded-lg indent-2 focus:border-blue-700/50 focus:text-blue-700 focus:border-opacity-50 form-input`}
            >
              {selectedOptionSecond?.label || "Seçiniz"}
              <IoIosArrowDown
                className={`ml-2 mr-3 transition-transform duration-300 ${
                  isOpenSecond ? "rotate-180" : ""
                }`}
              />
            </button>
            {isOpenSecond && (
              <div className="absolute z-50 w-full mt-1 bg-white border border-gray-400 rounded-md">
                {optionsSecond.map((option) => (
                  <div
                    key={option.value}
                    onClick={() => handleSelectOptionSecond(option)}
                    className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                  >
                    {/* <span className="inline-block w-4 h-4 mr-2 border-2 border-blue-500 rounded-full"></span> */}
                    {option.label}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div
            ref={dropdownRefFirst}
            className="relative group w-full md:w-[35%] mb-3 md:mb-6"
          >
            <label className="mb-2 ml-0 text-sm font-poppins text-[13px] font-semibold text-gray-700">
              Firma Bilgilerini Gizle
            </label>
            <button
              onClick={() => setIsOpenFirst(!isOpenFirst)}
              className={`flex items-center justify-between text-sm w-full py-2 text-blue-500 border border-blue-500 rounded-lg ${
                isOpenFirst ? "shadow-form-glow" : "shadow-none"
              } indent-2 focus:border-blue-700/50 focus:text-blue-700 focus:border-opacity-50 form-input`}
            >
              {selectedOptionFirst?.label || "Seçiniz"}
              <IoIosArrowDown
                className={`ml-2 mr-3 transition-transform duration-300 ${
                  isOpenFirst ? "rotate-180" : ""
                }`}
              />
            </button>
            {isOpenFirst && (
              <div className="absolute z-50 w-full mt-1 bg-white border border-gray-400 rounded-md">
                {optionsFirst.map((option) => (
                  <div
                    key={option.value}
                    onClick={() => handleSelectOptionFirst(option)}
                    className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                  >
                    {/* <span className="inline-block w-4 h-4 mr-2 border-2 border-blue-500 rounded-full"></span> */}
                    {option.label}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div
          ref={dropdownRefThierd}
          className="relative group w-full md:w-[35%]"
        >
          <label className="mb-2 ml-0 text-sm font-semibold font-poppins text-[13px] text-gray-700">
            Yetkili Bilgisi Gizle
          </label>
          <button
            onClick={() => setIsOpenThierd(!isOpenThierd)}
            className={`flex items-center text-sm justify-between w-full py-2 text-blue-500 border border-blue-500 rounded-lg ${
              isOpenThierd ? "shadow-form-glow" : "shadow-none"
            } indent-2 focus:border-blue-700/50 focus:text-blue-700 focus:border-opacity-50 form-input`}
          >
            {selectedOptionThierd?.label || "Seçiniz"}
            <IoIosArrowDown
              className={`ml-2 mr-3 transition-transform duration-300 ${
                isOpenThierd ? "rotate-180" : ""
              }`}
            />
          </button>
          {isOpenThierd && (
            <div className="absolute z-50 w-full mt-1 bg-white border border-gray-400 rounded-md">
              {optionsThierd.map((option) => (
                <div
                  key={option.value}
                  onClick={() => handleSelectOptionThierd(option)}
                  className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                >
                  {/* <span className="inline-block w-4 h-4 mr-2 border-2 border-blue-500 rounded-full"></span> */}
                  {option.label}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {/* Accordion context end */}
      {isPopupVisible && <ExamplePopup onClose={handleClosePopup} />}
    </div>
  );
};

export default CompanyDetailAndDescription;
