import * as React from "react";
import { CustomButton } from "../../../../../../components/common/login/custom-button";
import { CustomDropdown } from "../../../../../../components/common/custom-dropdown";
import { useIsMobile } from "../../../../../../hooks/useIsMobile";
import { PackageType } from "../../constants/enum";

interface IPackagesHeaderProps {
  readonly headerButtonContents: string[] | undefined;
  readonly selectedButton: string;
  readonly setSelectedButton: React.Dispatch<React.SetStateAction<string>>;
}

function PackagesHeader(props: IPackagesHeaderProps): React.ReactElement {
  const { isMobile } = useIsMobile();

  const handleDropdownSelect = (selectedItem: string) => {
    props.setSelectedButton(selectedItem);
  };

  return (
    <div className="mb-2">
      <div className="flex min-h-[50px] w-full mx-auto bg-white rounded-md py-4 shadow-md items-center justify-center px-4">
        <div className="flex items-center justify-between w-full gap-y-4 md:gap-y-0">
          <span className="text-mains text-[22px] poppins-semibold tracking-wide text-center md:text-left">
            {PackageType.Paketler}
          </span>
          <div className="flex flex-row justify-between gap-4 md:items-center">
            {!isMobile ? (
              props.headerButtonContents &&
              props.headerButtonContents.map((content, index) => (
                <CustomButton
                  key={index}
                  text={content}
                  className={`text-white rounded-md px-4 py-2 md:px-2 lg:px-4 lg:py-2 text-[14px] poppins-semibold md:text-[15px] md:whitespace-nowrap ${
                    content === props.selectedButton
                      ? "bg-company-card shadow-md hover:shadow-lg"
                      : "bg-mains"
                  }`}
                  onClick={() => props.setSelectedButton(content)}
                />
              ))
            ) : (
              <CustomDropdown
                title={"Menü"}
                content={props.headerButtonContents || []}
                onItemSelect={handleDropdownSelect}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export { PackagesHeader };
