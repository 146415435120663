
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import services from "../../services/profile-services";

// @ts-nocheck
interface State {
  cities: Record<string, any>;
  educationLevels: Record<string, any>;
  highSchoolTypes: Record<string, any>;
  schoolBranches: Record<string, any>;
  universities: Record<string, any>;
  universityBranches: Record<string, any>;
  foreignLanguageData: Record<string, any>;
  references: Record<string, any>;
  seminars: Record<string, any>;
  awards: Record<string, any>;
  exams: Record<string, any>;
  certs: Record<string, any>;
  files: Record<string, any>;
  hobbies: Record<string, any>;
}

const initialState: State = {
  cities: {},
  educationLevels: {},
  highSchoolTypes: {},
  schoolBranches: {},
  universities: {},
  universityBranches: {},
  foreignLanguageData: {},
  references: {},
  seminars: {},
  awards: {},
  exams: {},
  certs: {},
  files: [],
  hobbies: {},
};

export const getAllCities = createAsyncThunk<any>(
  "cvEdit/cvEditGetAllCities",
  async () => {
    try {
      const response = await services.getAllCities();
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
    }
  }
);

export const getHobbies = createAsyncThunk<any>(
  "cvEdit/cvEditGetHobbies",
  async () => {
    try {
      const response = await services.getHobby();
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
    }
  }
);

export const getAllEducationLevels = createAsyncThunk<any>(
  "cvEdit/getEducationLevels",
  async () => {
    try {
      const response = await services.getEducationLevels();
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
    }
  }
);

export const getAllHighSchoolTypes = createAsyncThunk(
  "cvEdit/getHighSchoolTypes",
  async () => {
    try {
      const response = await services.getHighSchoolTypes();
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
    }
  }
);

export const getAllSchoolBranches = createAsyncThunk(
  "cvEdit/getAllSchoolBranches",
  async () => {
    try {
      const response = await services.getAllSchoolBranches();
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
    }
  }
);

export const getAllUniversities = createAsyncThunk(
  "cvEdit/getAllUniversities",
  async () => {
    try {
      const response = await services.getUniversities();
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
    }
  }
);

export const getAllUniversityBranches = createAsyncThunk(
  "cvEdit/getAllUniversityBranches",
  async () => {
    try {
      const response = await services.getUniversityBranches();
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
    }
  }
);

export const getAllForeignLangInfo = createAsyncThunk(
  "cvEdit/getAllForeignLangInfo",
  async (slug) => {
    try {
      const response = await services.getForeignLanguageData(slug);
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
    }
  }
);

export const getAllReferences = createAsyncThunk<any, any>(
  "cvEdit/getAllReferences",
  async (slug) => {
    try {
      const response = await services.getReferences(slug);
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
    }
  }
);

export const getSeminars = createAsyncThunk<any, any>(
  "cvEdit/getSeminars",
  async (slug) => {
    try {
      const response = await services.getSeminars(slug);
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
    }
  }
);

export const getAwards = createAsyncThunk<any>("cvEdit/getAwards", async () => {
  try {
    const response = await services.getAward();
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    console.log(error);
  }
});

export const getExams = createAsyncThunk<any, any>(
  "cvEdit/getExams",
  async (slug) => {
    try {
      const response = await services.getExams(slug);
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
    }
  }
);

export const getCerts = createAsyncThunk<any, any>(
  "cvEdit/getCerts",
  async (slug) => {
    try {
      const response = await services.getCertificates(slug);
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      console.log(error);
    }
  }
);

export const getFiles = createAsyncThunk<any>("cvEdit/getFiles", async () => {
  try {
    const response = await services.getAllFiles();
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    console.log(error);
  }
});
const cvEditSlice = createSlice({
  name: "cvEdit",
  initialState,
  reducers: {
    removeFile: (state, action: PayloadAction<number>) => {
      state.files = state.files.filter(
        (file: { id: number }) => file.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllCities.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.cities = action.payload;
      }
    );
    builder.addCase(
      getAllEducationLevels.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.educationLevels = action.payload;
      }
    );
    builder.addCase(
      getAllHighSchoolTypes.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.highSchoolTypes = action.payload;
      }
    );
    builder.addCase(
      getAllSchoolBranches.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.schoolBranches = action.payload;
      }
    );
    builder.addCase(
      getAllUniversities.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.universities = action.payload;
      }
    );
    builder.addCase(
      getAllUniversityBranches.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.universityBranches = action.payload;
      }
    );
    builder.addCase(
      getAllForeignLangInfo.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.foreignLanguageData = action.payload;
      }
    );
    builder.addCase(
      getAllReferences.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.references = action.payload;
      }
    );
    builder.addCase(
      getSeminars.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.references = action.payload;
      }
    );
    builder.addCase(
      getAwards.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.awards = action.payload;
      }
    );
    builder.addCase(getExams.fulfilled, (state, action: PayloadAction<any>) => {
      state.exams = action.payload;
    });
    builder.addCase(getCerts.fulfilled, (state, action: PayloadAction<any>) => {
      state.certs = action.payload;
    });
    builder.addCase(getFiles.fulfilled, (state, action: PayloadAction<any>) => {
      state.files = action.payload;
    });
    builder.addCase(
      getHobbies.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.hobbies = action.payload;
      }
    );
  },
});
export const { removeFile } = cvEditSlice.actions;

export default cvEditSlice.reducer;
