import { useEffect, useState } from "react";
import ButtonsComponent from "../components/buttons-component";
import { PersonalInfoSection } from "./sections/personal-info";
import { EducationSection } from "./sections/education";
import { ExperienceSection } from "./sections/job-experiences";
import { LanguagesSection } from "./sections/languages";
import { SpecialCasesSection } from "./sections/special-cases";
import { OtherSections } from "./sections/others";
interface PageProps {
  isApplyPage?: boolean;
}
const DetailsSection: React.FC<PageProps> = ({
  isApplyPage = false,
}: PageProps) => {
  const [active, setActive] = useState<string>("Kişisel Bilgiler");

  const currentComponent = () => {
    return (
      <div>
        <PersonalInfoSection isVisible={active == "Kişisel Bilgiler"} />
        <EducationSection isVisible={active == "Eğitim"} />
        <ExperienceSection isVisible={active == "İş Deneyimleri"} />
        <LanguagesSection isVisible={active == "Yabancı Dil"} />

        <SpecialCasesSection isVisible={active == "Özel Durumlar"} />

        <OtherSections isVisible={active == "Diğer Seçenekler"} />
      </div>
    );
  };

  return (
    <section className="mx-2">
      <ButtonsComponent active={active} setActive={setActive} />
      {currentComponent()}
    </section>
  );
};

export { DetailsSection };
