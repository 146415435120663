const LatestArticles: React.FC = () => {
  return (
    <div className="bg-white p-4 shadow rounded-lg">
      <h2 className="font-medium text-xl">Son Yazılar</h2>
      <div className="ml-4">
        <a href="#">
          <div className="flex items-center space-x-2 mt-4 mb-4">
            <img
              src="https://iskatel.com/wp-content/uploads/2022/01/Ozero-Baykal-14-e1642764773265-1120x500.jpg"
              alt="Description"
              className="w-14 h-14 rounded-xl"
            />
            <p className="text-[#63636c] font-medium">
              Sosyal Medyayı Kariyerimiz için Nasıl Kullanabiliriz?
            </p>
          </div>
        </a>
        <a href="#">
          <div className="flex items-center space-x-2 mt-4 mb-4">
            <img
              src="https://iskatel.com/wp-content/uploads/2022/01/Ozero-Baykal-14-e1642764773265-1120x500.jpg"
              alt="Description"
              className="w-14 h-14 rounded-xl"
            />
            <p className="text-[#63636c] font-medium">
              Sosyal Medyayı Kariyerimiz için Nasıl Kullanabiliriz?
            </p>
          </div>
        </a>
        <a href="#">
          <div className="flex items-center space-x-2 mt-4 mb-4">
            <img
              src="https://iskatel.com/wp-content/uploads/2022/01/Ozero-Baykal-14-e1642764773265-1120x500.jpg"
              alt="Description"
              className="w-14 h-14 rounded-xl"
            />
            <p className="text-[#63636c] font-medium">
              Sosyal Medyayı Kariyerimiz için Nasıl Kullanabiliriz?
            </p>
          </div>
        </a>
        <div className="flex justify-center">
          <button className="text-white bg-[#2253af] py-3 rounded-lg w-1/2 text-md">
            Tümünü Göster
          </button>
        </div>
      </div>
    </div>
  );
};

export default LatestArticles;
