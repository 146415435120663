import ReactInputMask from "react-input-mask";
import { Button, InputArea } from "../../../../../../components/isbul-ui";
import { dbUserReferenceType } from "../../../../../../types";
import { useForm, SubmitHandler } from "react-hook-form";
import { Form, useParams } from "react-router-dom";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { IoAdd, IoMail, IoPerson, IoTrashBinOutline } from "react-icons/io5";
import { FaCity, FaRegTrashCan } from "react-icons/fa6";
import Swal from "sweetalert2";
import Axios from "../../../../../../services/axios";
import { BsFillPersonVcardFill } from "react-icons/bs";
import {
  FaEdit,
  FaPencilAlt,
  FaPhone,
  FaPhoneAlt,
  FaTrashAlt,
} from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { useIsMobile } from "../../../../../../hooks/useIsMobile";
import FormModal from "../../../../../../components/isbul-ui/form-modal";
import { useContext } from "react";
import { cvLayoutContextType, CvLayoutContext } from "../..";
import { formatPhoneNumber } from "../../../../../../lib/utils";

type payloadDataType = {
  cv_id: number;
  full_name: string;
  company: string;
  phone: string | null;
  email: string;
};

const axios = new Axios();

export default function ReferenceForm({
  reference,
  getAllReferences,
  referencesLength,
  isAddClicked,
  setIsAddClicked,
}: {
  reference: dbUserReferenceType | null;
  getAllReferences?: () => void;
  referencesLength?: number;
  isAddClicked?: boolean;
  setIsAddClicked?: Dispatch<SetStateAction<boolean>>;
}) {
  const { showCvScore, isEnglish }: cvLayoutContextType =
    useContext(CvLayoutContext);

  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<ReactInputMask>(null);

  const [isEditOpen, setIsEditOpen] = useState<boolean>(
    reference ? false : true
  );
  const [isPageOpen, setIsPageOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const params = useParams();
  const cvId = params.slug;

  // Header add button click catcher
  useEffect(() => {
    if (
      isAddClicked != undefined &&
      setIsAddClicked != undefined &&
      !reference
    ) {
      if (isAddClicked) {
        setIsModalOpen(true);
        setIsAddClicked(false);
      }
    }
  }, [isAddClicked]);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm<payloadDataType>({
    defaultValues: {
      cv_id: reference?.cv_id,
      company: reference?.company,
      email: reference?.email,
      full_name: reference?.name,
      phone: reference?.phone,
    },
    criteriaMode: "all",
  });

  const onSubmit: SubmitHandler<payloadDataType> = (data) => {
    if (!reference) {
      addNewReference(data);
    } else {
      updateReference(data);
    }
  };

  /* Values for input mode changes */
  const full_name = watch("full_name") || "";
  const company = watch("company") || "";
  const email = watch("email") || "";
  const phone = watch("phone") || "";

  useEffect(() => {
    if (reference) {
      setValue("company", reference.company);
      setValue("cv_id", reference.cv_id);
      setValue("full_name", reference.name);
      setValue("phone", reference.phone);
      setValue("email", reference.email);
    }

    setValue("company", "");
    setValue("full_name", "");
    setValue("phone", "");
    setValue("email", "");
    reset();
  }, [isModalOpen]);

  if (isEditOpen) {
    return (
      <div className="flex flex-col gap-2">
        {!reference && (
          <div className={`flex flex-row items-center justify-between px-4`}>
            {/* Header form modal open */}
            <Button
              id="addReferenceButton"
              className="underline"
              size="sm"
              onClick={() => {
                setIsModalOpen(true);
                setIsPageOpen(true);
              }}
            >
              <Button.Icon>
                <IoAdd className="text-base" />
              </Button.Icon>
              {isEnglish ? "Add Reference" : "Referans Ekle"}
            </Button>
          </div>
        )}

        {!reference && <hr className="border-mains" />}

        {/* Form modal for form */}
        <FormModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          onClosing={() => {
            setIsEditOpen(false);
            if (reference) {
              setIsEditOpen(false);
            } else {
              setIsEditOpen(true);
            }
          }}
        >
          <FormModal.Content>
            <form
              ref={formRef}
              className="flex flex-col gap-y-4 px-6 pt-2 pb-20 lg:pb-2"
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              <label className="text-mains font-semibold">
                {reference
                  ? `${isEnglish ? "Edit Reference" : "Referansı Düzenle"}`
                  : `${isEnglish ? "Add Reference" : "Referans Ekle"}`}
              </label>

              <hr className="border-mains" />

              <div className="flex flex-col lg:flex-row gap-y-4 gap-x-4">
                {/* Fullname input*/}
                <div className="flex basis-1/2">
                  <InputArea
                    required
                    error={errors.full_name?.message ? true : false}
                    mode={full_name.length == 0 ? "empty" : "dirty"}
                    className="w-full"
                  >
                    <InputArea.Label>
                      {isEnglish ? "Full Name" : "Adı Soyadı"}
                    </InputArea.Label>
                    <InputArea.Input
                      {...register("full_name", {
                        required: `${isEnglish ? "Full Name" : "Adı Soyadı"}`,
                      })}
                      value={full_name || ""}
                      onChange={(e) => {
                        setValue("full_name", e.target.value);
                        clearErrors("full_name");
                      }}
                    />
                  </InputArea>
                </div>

                {/* Company input */}
                <div className="flex basis-1/2">
                  <InputArea
                    required
                    className="w-full"
                    error={errors.company?.message ? true : false}
                    mode={company.length == 0 ? "empty" : "dirty"}
                  >
                    <InputArea.Label>
                      {isEnglish
                        ? "Company / Position"
                        : "Çalıştığı Firma / Görevi"}
                    </InputArea.Label>
                    <InputArea.Input
                      {...register("company", {
                        required: `${
                          isEnglish
                            ? "Company / Position"
                            : "Çalıştığı Firma / Görevi"
                        }`,
                      })}
                      value={company || ""}
                      onChange={(e) => {
                        setValue("company", e.target.value);
                        clearErrors("company");
                      }}
                    />
                  </InputArea>
                </div>
              </div>
              <div className="flex flex-col lg:flex-row gap-y-4 gap-x-4">
                {/* Phone input */}
                <div className="flex basis-1/2">
                  <InputArea
                    className="w-full"
                    error={errors.phone?.message ? true : false}
                  >
                    <InputArea.Label>
                      {isEnglish ? "Phone" : "Telefon"}
                    </InputArea.Label>
                    <ReactInputMask
                      ref={inputRef}
                      className={`py-[6px] px-2 border focus:shadow-form-glow outline-none rounded-md ${
                        phone.length == 0
                          ? "border-gray-400 text-gray-600"
                          : "border-blue-500 text-blue-500"
                      } ${
                        errors.phone?.message
                          ? "border-red-600 bg-red-50 text-red-600"
                          : ""
                      } transition-all`}
                      mask={"(999) 999 99 99"}
                      placeholder="(___) ___ __ __"
                      maskChar={""}
                      value={phone || ""}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        if (inputValue.startsWith("(0")) {
                          inputValue = inputValue.replace(/^0+/, "");
                          formatPhone(inputValue);
                        } else {
                          formatPhone(inputValue);
                        }
                        clearErrors("phone");
                      }}
                    />
                  </InputArea>
                </div>

                {/* E-mail input */}
                <div className="flex basis-1/2">
                  <InputArea
                    required
                    className="w-full"
                    error={errors.email?.message ? true : false}
                    mode={email.length == 0 ? "empty" : "dirty"}
                  >
                    <InputArea.Label>
                      {isEnglish ? "Email" : "E-Posta"}
                    </InputArea.Label>
                    <InputArea.Input
                      {...register("email", {
                        required: `${isEnglish ? "Email" : "E-Posta"}`,
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: "E-Posta",
                        },
                      })}
                      value={email || ""}
                      onChange={(e) => {
                        setValue("email", e.target.value);
                        clearErrors("email");
                      }}
                    />
                  </InputArea>
                </div>
              </div>
              {!reference && (
                <div className="flex flex-row gap-2 ml-auto items-center justify-between mt-6">
                  <Button
                    type="button"
                    className="flex"
                    variant="secondary"
                    size="sm"
                    onClick={() => setIsModalOpen(false)}
                  >
                    {isEnglish ? "Cancel" : "Vazgeç"}
                  </Button>

                  <Button type="submit" size="sm">
                    {isEnglish ? "Save" : "Kaydet"}
                  </Button>
                </div>
              )}
              {reference && (
                <div className="flex flex-row gap-2 items-center justify-between mt-6">
                  <div className="flex ml-auto flex-row items-center gap-2">
                    <Button
                      type="button"
                      variant="secondary"
                      size="sm"
                      onClick={() => setIsEditOpen(false)}
                    >
                      {isEnglish ? "Cancel" : "Vazgeç"}
                    </Button>
                    <Button type="submit" size="sm">
                      {isEnglish ? "Save" : "Kaydet"}
                    </Button>
                  </div>
                </div>
              )}
            </form>
          </FormModal.Content>
        </FormModal>
      </div>
    );
  } else {
    return (
      <>
        {/* Desktop section */}
        <div className="hidden lg:flex flex-row relative px-1 py-2 gap-4 border-transparent hover:border-gray-300 border-dashed border-2 rounded-md transition-all">
          {/* Form modal for destrop card */}
          <FormModal isOpen={isPageOpen} setIsOpen={setIsPageOpen}>
            <FormModal.Trigger>
              <div className="flex flex-col gap-2 absolute -top-2 right-2 lg:top-4 lg:right-4 z-10">
                {/* Edit button */}
                <Button
                  variant="ghost"
                  size="xs"
                  className=" aspect-square !rounded-full z-10 !border-none"
                  onClick={() => {
                    setIsEditOpen(true);
                    // setIsPageOpen(true);
                    setIsModalOpen(true);
                  }}
                >
                  <Button.Icon>
                    <FaPencilAlt className="text-xl" />
                  </Button.Icon>
                </Button>

                {/* Delete button */}
                <Button
                  variant="ghost"
                  size="xs"
                  className="aspect-square !rounded-full !border-none"
                  onClick={() => {
                    Swal.fire({
                      icon: "warning",
                      title: `${
                        isEnglish
                          ? "Are you sure to delete reference?"
                          : "Referans bilgisini silmek istediğinizden emin misiniz?"
                      }`,
                      confirmButtonText: `${isEnglish ? "Yes" : "Evet"}`,
                      cancelButtonText: `${isEnglish ? "No" : "Hayır"}`,
                      preConfirm: () => {
                        deleteReference();
                      },
                      showCancelButton: true,
                    });
                  }}
                >
                  <Button.Icon className="text-xl">
                    <IoTrashBinOutline />
                  </Button.Icon>
                </Button>
              </div>
            </FormModal.Trigger>
          </FormModal>

          <div className="hidden lg:flex flex-col border-r pr-4">
            <BsFillPersonVcardFill className="text-9xl text-mains/40" />
            <label className="text-center font-poppins font-semibold text-mains">
              {isEnglish ? "Reference" : "Referans"}
            </label>
          </div>
          <div className="flex flex-row items-center w-full">
            <div className="flex flex-col w-full lg:w-[calc(50%)] flex-shrink-0 gap-3 items-start">
              {/* Name field */}
              <div className="flex flex-col">
                <label className="hidden lg:flex items-center gap-2 text-xs font-poppins font-semibold text-gary-700">
                  <IoPerson className="text-sm" />
                  {isEnglish ? "Full Name" : "Adı Soyadı"}
                </label>
                <label className="font-poppins text-gray-600 text-sm">
                  {reference?.name}
                </label>
              </div>
              {/* Company field */}
              <div className="flex flex-col">
                <label className="hidden lg:flex items-center gap-2 text-xs font-poppins font-semibold text-gary-700">
                  <FaCity className="text-xs" />
                  {isEnglish ? "Company / Position" : "Firma / Görev"}
                </label>
                <label className="font-poppins text-gray-600 text-sm">
                  {reference?.company}
                </label>
              </div>
            </div>
            <div className="flex flex-col w-full lg:w-[calc(50%)] gap-3 items-start ">
              {/* Email field */}
              <div className="flex flex-col max-w-[calc(90%)]">
                <label className="hidden lg:flex items-center gap-2 text-xs font-poppins font-semibold text-gary-700">
                  <IoMail className="text-xs" />
                  {isEnglish ? "E-mail" : "E-Posta"}
                </label>
                <label className="font-poppins max-w-[calc(100%)] text-gray-600 text-sm overflow-hidden text-ellipsis whitespace-nowrap ">
                  {reference?.email}
                </label>
              </div>
              {/* Phone field */}
              {reference?.phone && (
                <div className="flex flex-col">
                  <label className="hidden lg:flex items-center gap-2 text-xs font-poppins font-semibold text-gary-700">
                    <FaPhoneAlt className="text-xs" />
                    {isEnglish ? "Phone" : "Telefon"}
                  </label>
                  <label className="font-poppins text-gray-600 text-sm">
                    {reference?.phone}
                  </label>
                </div>
              )}
              {!reference?.phone && (
                <div className="flex flex-col opacity-0">
                  <label className="hidden lg:flex items-center gap-2 text-xs font-poppins font-semibold text-gary-700">
                    .
                  </label>
                  <label className="font-poppins text-gray-600 text-sm">
                    .
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Mobile Section */}
        <div className="flex flex-row lg:hidden pt-4 pb-0 px-4">
          <div className="flex flex-col gap-2 flex-1">
            {/* Name field */}
            <div className="flex flex-col">
              <label className="font-poppins text-[13px] font-semibold text-gray-900">
                {isEnglish ? "Full Name" : "Adı-Soyadı"}
              </label>
              <label className="font-poppins text-[13px] text-gray-800">
                {reference?.name}
              </label>
            </div>
            {/* Company field */}
            <div className="flex flex-col">
              <label className="font-poppins text-[13px] font-semibold text-gray-900">
                {isEnglish ? "Company / Position" : "Firma / Görevi"}
              </label>
              <label className="font-poppins text-[13px] text-gray-800">
                {reference?.company}
              </label>
            </div>
            {/* Email field */}
            <div className="flex flex-col">
              <label className="font-poppins text-[13px] font-semibold text-gray-900">
                {isEnglish ? "Email" : "E-posta"}
              </label>
              <label className="font-poppins text-[13px] text-gray-800">
                {reference?.email}
              </label>
            </div>
            {/* Phone field */}
            {reference?.phone && (
              <div className="flex flex-col">
                <label className="font-poppins text-[13px] font-semibold text-gray-900">
                  {isEnglish ? "Phone" : "Telefon"}
                </label>
                <label className="font-poppins text-[13px] text-gray-800">
                  {/* {reference?.phone} */}
                  {reference?.phone
                    ? formatPhoneNumber(reference?.phone, "90")
                    : "Telefon Numarası Giriniz"}
                </label>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-2 items-center">
            {/* Edit button */}
            <button
              className="flex flex-col gap-1 items-center text-mains font-poppins text-xs"
              onClick={() => {
                setIsEditOpen(true);
                setIsPageOpen(true);
                setIsModalOpen(true);
              }}
            >
              <FaPencilAlt className="text-mains text-base" />
              {isEnglish ? "Edit" : "Düzenle"}
            </button>

            {/* Delete button */}
            <button
              className="flex flex-col gap-1 items-center text-mains font-poppins text-xs"
              onClick={() => {
                Swal.fire({
                  icon: "warning",
                  title: `${
                    isEnglish
                      ? "Are you sure to delete reference?"
                      : "Referans bilgisini silmek istediğinizden emin misiniz?"
                  }`,
                  confirmButtonText: `${isEnglish ? "Yes" : "Evet"}`,
                  cancelButtonText: `${isEnglish ? "No" : "Hayır"}`,
                  preConfirm: () => {
                    deleteReference();
                  },
                  showCancelButton: true,
                });
              }}
            >
              <IoTrashBinOutline className="text-base" />
            </button>
          </div>
        </div>
      </>
    );
  }

  function formatPhone(phone?: string) {
    if (!phone) {
      setValue("phone", null);
      return;
    }

    let formattedPhone = phone;
    formattedPhone = formattedPhone.replaceAll("(", "");
    formattedPhone = formattedPhone.replaceAll(")", "");
    formattedPhone = formattedPhone.replaceAll(" ", "");
    formattedPhone = formattedPhone.replaceAll("_", "");
    if (formattedPhone.startsWith("0")) {
      setValue("phone", formattedPhone.replace("0", ""));
    } else {
      setValue("phone", formattedPhone);
    }
  }

  async function addNewReference({ cv_id, phone, ...others }: payloadDataType) {
    if (isLoading) return;

    setIsLoading(true);

    try {
      let formattedPhone = phone;
      if (formattedPhone) {
        formattedPhone = formattedPhone.replaceAll(" ", "");
        formattedPhone = formattedPhone.replaceAll("(", "");
        formattedPhone = formattedPhone.replaceAll(")", "");
        if (formattedPhone.length != 10) {
          setError("phone", {
            message: "Geçerli bir telefon numarası giriniz",
          });
          return;
        }
      }
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/ref/create`,
        type: "post",
        payload: {
          cv_id: cvId,
          phone: formattedPhone ? formattedPhone : null,
          ...others,
        },
      });

      if (response.status == 200) {
        if (formRef && formRef.current) {
          formRef.current.reset();
        }
        if (getAllReferences) {
          getAllReferences();
        }

        setValue("company", "");
        setValue("email", "");
        setValue("full_name", "");
        setValue("phone", "");

        setIsPageOpen(false);
        setIsModalOpen(false);
        showCvScore();
      }
    } catch (error) {
      console.log(`Reference create failed. Error: ${error}`);
      Swal.fire({
        icon: "error",
        title: "Referans eklenemedi",
        text: "Referans eklenirken bir hata meydana geldi",
        confirmButtonText: "Tamam",
      });
    }

    setIsLoading(false);
  }

  async function updateReference({ cv_id, phone, ...data }: payloadDataType) {
    if (isLoading) return;

    setIsLoading(true);

    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/ref/update/${reference?.id}`,
        type: "put",
        payload: {
          cv_id: cvId,
          phone: phone ? phone : null,
          ...data,
        },
      });

      if (response && response.status == 200) {
        if (getAllReferences) {
          getAllReferences();
        }

        setIsEditOpen(false);

        showCvScore();
      }
    } catch (error) {
      console.log(`Reference update failed. Error: ${error}`);
      Swal.fire({
        icon: "error",
        title: "Referans güncellenemedi",
        text: "Referans güncellenirken bir hata meydana geldi",
        confirmButtonText: "Tamam",
      });
    }

    setIsLoading(false);
  }

  async function deleteReference() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/ref/delete/${reference?.id}?cv_id=${cvId}`,
        type: "delete",
        payload: {
          cv_id: reference?.cv_id,
        },
      });

      if (response && response.status == 200) {
        if (getAllReferences) {
          getAllReferences();
        }

        showCvScore();
      }
    } catch (error) {
      console.log(`Reference delete failed. Error: ${error}`);
    }
  }
}
