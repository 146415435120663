import Axios from "../axios/index";

const axios = new Axios();

const services = {
  getDefaultCv: async () => {
    return await axios.request({
      type: "get",
      endpoint: `user/profilim/cv`,
      payload: {},
    });
  },

  incomplateDetail: async (id: any) => {
    return await axios.request({
      type: "get",
      endpoint: `user/profilim/cv/miss-info/${id}`,
      payload: {},
    });
  },

  cityGet: async (country: any) => {
    return await axios.request({
      type: "get",
      endpoint: `cdn/get-cities?country_id=${country}&limit=81`,
      payload: {},
    });
  },

  getAllCities: async () => {
    return await axios.request({
      type: "get",
      endpoint: `cdn/get-cities`,
      payload: {},
    });
  },

  getEducationLevels: async () => {
    return await axios.request({
      type: "get",
      endpoint: `cdn/get-education-levels`,
      payload: {},
    });
  },

  getAllSchoolBranches: async () => {
    return await axios.request({
      type: "get",
      endpoint: `cdn/get-high-school-branches`,
      payload: {},
    });
  },

  updateEducationData: async (userData: any, id: any) => {
    return await axios.request({
      type: "put",
      endpoint: `user/profilim/cv/edu/update/${id}`,
      payload: userData,
    });
  },

  deleteEducationData: async (id: any) => {
    return await axios.request({
      type: "delete",
      endpoint: `user/profilim/cv/edu/${id}`,
      payload: {},
    });
  },

  deleteForeignLanguageData: async (userData: any, id: any) => {
    return await axios.request({
      type: "delete",
      endpoint: `user/profilim/cv/lang/${id}`,
      payload: userData,
    });
  },

  updateForeignLanguageData: async (userData: any, id: any) => {
    return await axios.request({
      type: "put",
      endpoint: `user/profilim/cv/lang/update/${id}`,
      payload: userData,
    });
  },

  getHighSchoolTypes: async () => {
    return await axios.request({
      type: "get",
      endpoint: `cdn/get-high-school-types`,
      payload: {},
    });
  },

  getUniversities: async () => {
    return await axios.request({
      type: "get",
      endpoint: `cdn/get-universities`,
      payload: {},
    });
  },

  getUniversityBranches: async () => {
    return await axios.request({
      type: "get",
      endpoint: `cdn/get-university-branches`,
      payload: {},
    });
  },

  getEducationData: async (id: any) => {
    return await axios.request({
      type: "get",
      endpoint: `user/profilim/cv/edu/${id}`,
      payload: {},
    });
  },

  getForeignLanguageData: async (id: any) => {
    return await axios.request({
      type: "get",
      endpoint: `user/profilim/cv/lang/${id}`,
      payload: {},
    });
  },

  //cv-edit-references
  getReferences: async (id: any) => {
    return await axios.request({
      type: "get",
      endpoint: `user/profilim/cv/ref/${id}`,
      payload: {},
    });
  },

  updateReference: async (userData: any, id: any) => {
    return await axios.request({
      type: "put",
      endpoint: `user/profilim/cv/ref/${id}`,
      payload: userData,
    });
  },

  //cv-edit-seminars
  getSeminars: async (id: any) => {
    return await axios.request({
      type: "get",
      endpoint: `user/profilim/cv/sem/${id}`,
      payload: {},
    });
  },

  updateSeminar: async (userData: any, id: any) => {
    return await axios.request({
      type: "put",
      endpoint: `user/profilim/cv/sem/update/${id}`,
      payload: userData,
    });
  },

  deleteSeminar: async (id: any) => {
    return await axios.request({
      type: "delete",
      endpoint: `user/profilim/cv/sem/delete/${id}`,
      payload: {},
    });
  },
  //cv-edit-hobbies
  getHobby: async () => {
    return await axios.request({
      type: "get",
      endpoint: `user/profilim/cv/hob/index`,
      payload: {},
    });
  },
  updateHobby: async (values: any, id: any) => {
    return await axios.request({
      type: "put",
      endpoint: `user/profilim/cv/hob/update/${id}`,
      payload: values,
    });
  },
  deleteHobby: async (id: any) => {
    return await axios.request({
      type: "delete",
      endpoint: `user/profilim/cv/hob/destroy/${id}`,
      payload: {},
    });
  },

  //cv-edit-awards
  getAward: async () => {
    return await axios.request({
      type: "get",
      endpoint: `user/profilim/cv/aw/index/`,
      payload: {},
    });
  },
  updateAward: async (values: any, id: any) => {
    return await axios.request({
      type: "put",
      endpoint: `user/profilim/cv/aw/update/${id}`,
      payload: values,
    });
  },
  deleteAward: async (id: any) => {
    return await axios.request({
      type: "delete",
      endpoint: `user/profilim/cv/aw/destroy/${id}`,
      payload: {},
    });
  },
  //cv-edit-exams
  getExams: async (id: any) => {
    return await axios.request({
      type: "get",
      endpoint: `user/profilim/cv/ex/${id}`,
      payload: {},
    });
  },
  updateExams: async (values: any, id: any) => {
    return await axios.request({
      type: "put",
      endpoint: `user/profilim/cv/ex/${id}`,
      payload: values,
    });
  },
  deleteExam: async (id: any) => {
    return await axios.request({
      type: "delete",
      endpoint: `user/profilim/cv/ex/${id}`,
      payload: {},
    });
  },

  //cv-edit-cert
  getCertificates: async (id: any) => {
    return await axios.request({
      type: "get",
      endpoint: `user/profilim/cv/cert/${id}`,
      payload: {},
    });
  },
  updateCertificates: async (values: any, id: any) => {
    return await axios.request({
      type: "put",
      endpoint: `user/profilim/cv/cert/${id}`,
      payload: values,
    });
  },
  deleteCertificates: async (id: any) => {
    return await axios.request({
      type: "delete",
      endpoint: `user/profilim/cv/cert/${id}`,
      payload: {},
    });
  },

  //cv-edit-files
  getAllFiles: async () => {
    return await axios.request({
      type: "get",
      endpoint: `user/profilim/cv/files/`,
      payload: {},
    });
  },
  updateFiles: async (values: any, slug: any) => {
    return await axios.requestWithMultipartFormData({
      type: "put",
      endpoint: `user/profilim/cv/files/update/${slug}`,
      payload: values,
    });
  },
  deleteFiles: async (id: any) => {
    return await axios.request({
      type: "delete",
      endpoint: `user/profilim/cv/files/delete/${id}`,
      payload: {},
    });
  },

  addFiles: async (values: any) => {
    return await axios.requestWithMultipartFormData({
      type: "post",
      endpoint: `user/profilim/cv/files/create`,
      payload: values,
    });
  },

  countryGet: async () => {
    return await axios.request({
      type: "get",
      endpoint: `cdn/get-countries`,
      payload: {},
    });
  },

  districtGet: async (city: any) => {
    return await axios.request({
      type: "get",
      endpoint: `cdn/get-districts?limit=100&city_id=${city}&limit=100`,
      payload: {},
    });
  },

  jobPrefences: async (userData: any) => {
    return await axios.request({
      type: "post",
      endpoint: `user/profilim/welcome/job-preferences`,
      payload: userData,
    });
  },

  personalInfo: async (userData: any) => {
    return await axios.request({
      type: "post",
      endpoint: `user/profilim/welcome/personal-info`,
      payload: userData,
    });
  },

  welcomeSetup: async () => {
    return await axios.request({
      type: "get",
      endpoint: `user/profilim/welcome/setup`,
      payload: {},
    });
  },

  saveCompany: async () => {
    return await axios.request({
      type: "get",
      endpoint: `user/profilim/cv/ilanlarim`,
      payload: {},
    });
  },

  forMeJob: async () => {
    return await axios.request({
      type: "get",
      endpoint: `user/profilim/favoriler`,
      payload: {},
    });
  },

  removeSingleFavoriteJob: async (id: any) => {
    return await axios.request({
      type: "delete",
      endpoint: `user/profilim/favoriler/${id}`,
      payload: {},
    });
  },

  removeAllFavoriteJobs: async () => {
    return await axios.request({
      type: "post",
      endpoint: `user/profilim/favoriler/delete-all`,
      payload: {},
    });
  },

  myCv: async () => {
    return await axios.request({
      type: "get",
      endpoint: `user/profilim/cv`,
      payload: {},
    });
  },

  cvDetail: async (id: any) => {
    return await axios.request({
      type: "get",
      endpoint: `user/profilim/cv/preview/${id}`,
      payload: {},
    });
  },

  getJobAlarms: async () => {
    return await axios.request({
      type: "get",
      endpoint: `user/profilim/alarms`,
      payload: {},
    });
  },
  createJobAlarm: async (userData: any) => {
    return await axios.request({
      type: "post",
      endpoint: `user/profilim/alarms/create`,
      payload: userData,
    });
  },
  removeJobAlarm: async (id: any) => {
    return await axios.request({
      type: "delete",
      endpoint: `user/profilim/alarms/${id}/delete`,
      payload: {},
    });
  },
  getAppliedJobs: async () => {
    return await axios.request({
      type: "get",
      endpoint: `user/profilim/applies/get-applies`,
      payload: {},
    });
  },
  getArchivedAppliedJobs: async () => {
    return await axios.request({
      type: "get",
      endpoint: `user/profilim/applies/get-archived-applies`,
      payload: {},
    });
  },
  archiveAppliedJob: async (id: number, status: string = "ARCHIVED") => {
    return await axios.request({
      type: "put",
      endpoint: `user/profilim/applies/change-status/${id}`,
      payload: {
        status: status,
      },
    });
  },
};
export default services;
