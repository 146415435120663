import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { HiLockClosed, HiChevronDown } from "react-icons/hi";
import { Link, useParams } from "react-router-dom";
import ProgressBar from "./GradientProgressBar";
import GradientProgressBar from "./GradientProgressBar";
import { useLocation } from "react-router-dom";
import { CvLayoutContext, cvLayoutContextType } from ".";
import { FaLock, FaRegBuilding } from "react-icons/fa";
import Swal from "sweetalert2";
import Axios from "../../../../services/axios";
import { IoAdd, IoCheckmark } from "react-icons/io5";

const axios = new Axios();

const allStepsOnApi = [
  {
    id: 1,
    name: "Kişisel Bilgiler",
    link: "/",
    description:
      "Kişisel bilgilerinizi doldurarak işverenlere kendinizi tanıtın.",
    lang_en: "Personal Information",
  },
  {
    id: 2,
    name: "İletişim Bilgileri",
    link: "/iletisim-bilgileri",
    description:
      "Özgeçmişinizde ietişim bilgileriniz eksiksiz biçimde yer almalıdır.",
    lang_en: "Contact Information",
  },
  {
    id: 3,
    name: "İş Tercihleri",
    link: "/is-tercihleri",
    description:
      "İş tercihlerinizi güncellediğinizde size uygun iş ilanlarını görme şansınız artar.",
    lang_en: "Job Preferences",
  },
  {
    id: 4,
    name: "İş Deneyimleri",
    link: "/is-deneyimleri",
    description:
      "İş deneyimini ekleyerek firmalar tarafından daha görünür bir CV oluştur.",
    lang_en: "Work experiences",
  },
  {
    id: 5,
    name: "Eğitim Bilgileri & Yabancı Diller",
    link: "/egitim-bilgileri",
    description: "Özgeçmişine Eğitim Bilgileri & Yabancı Diller eklemelisiniz.",
    lang_en: "Educational Information & Foreign Languages",
  },
  {
    id: 6,
    name: "Referanslar",
    link: "/referanslar",
    description:
      "Referans eklemek, şirketlerin sana karşı güvenini arttırır ve seni öne çıkartır.",
    lang_en: "References",
  },
  {
    id: 7,
    name: "Seminer, Kurs ve Eğitimler",
    link: "/seminer-kurs-egitimler",
    description:
      "Almış olduğun seminer, kurs ve eğitimler seni daha eğitimli bir aday olarak öne çıkarır.",
    lang_en: "Seminars, Courses and Trainings",
  },
  {
    id: 8,
    name: "Başarı & Ödül ve Burslar",
    link: "/basari-odul-ve-burslar",
    description:
      "Özgeçmişinde kazandığın bursları eklemen, şirketlere başarılı olduğun izlenimini verir.",
    lang_en: "Achievement & Awards and Scholarships",
  },
  {
    id: 9,
    name: "Sınavlar & Sertifikalar",
    link: "/sinavlar-sertifikalar",
    description:
      "Sertifika ve sınav bilgilerini eklemen özgeçmişini daha profesyonel gösterir.",
    lang_en: "Exams & Certificates",
  },
  {
    id: 10,
    name: "Hobiler",
    link: "/hobiler",
    description: "Özgeçmişinize Hobiler eklemelisiniz.",
    lang_en: "Hobbies",
  },
  {
    id: 11,
    name: "Dosyalar",
    link: "/dosyalar",
    description: "Özgeçmişinize Dosyalar eklemelisiniz.",
    lang_en: "Folders",
  },
];

function CvEditHeader() {
  const [progress, setProgress] = useState(50);

  const params = useParams();
  const cvId = params.slug;

  const {
    isEnglish,
    getCvById,
    visibility,
    getMissingInfo,
    missingInfo,
  }: cvLayoutContextType = useContext(CvLayoutContext);

  // Example function to update progress
  const updateProgress = () => {
    const newProgress = Math.floor(Math.random() * 100); // Random progress value between 0 and 100
    setProgress(newProgress);
  };

  //placeholder dropdown
  const id = useParams();
  const slug = id.slug;
  const location = useLocation();

  const [currentStepName, setCurrentStepName] = useState<string>("");
  const [privacyPopupOpen, setPrivacyPopupOpen] = useState<boolean>(false);

  const steps = [
    {
      id: 1,
      title: "Kişisel Bilgiler",
      title_en: "Personal Information",
      path: `/profilim/cv/duzenle/${slug}`,
      required: true,
    },
    {
      id: 2,
      title: "İletişim Bilgileri",
      title_en: "Contact Information",
      path: `/profilim/cv/duzenle/${slug}/iletisim-bilgileri`,
      required: true,
    },
    {
      id: 3,
      title: "İş Tercihleri",
      title_en: "Job Preferences",
      path: `/profilim/cv/duzenle/${slug}/is-tercihleri`,
      required: false,
    },
    {
      id: 4,
      title: "İş Deneyimleri",
      title_en: "Work Experiences",
      path: `/profilim/cv/duzenle/${slug}/is-deneyimleri`,
      required: false,
    },
    {
      id: 5,
      title: "Eğitim Bilgileri",
      title_en: "Education Information",
      path: `/profilim/cv/duzenle/${slug}/egitim-bilgileri`,
      required: true,
    },
    {
      id: 6,
      title: "Referanslar",
      title_en: "References",
      path: `/profilim/cv/duzenle/${slug}/referanslar`,
      required: false,
    },
    {
      id: 7,
      title: "Seminerler ve Kurslar",
      title_en: "Seminars and Courses",
      path: `/profilim/cv/duzenle/${slug}/seminer-kurs-egitimler`,
      required: false,
    },
    {
      id: 8,
      title: "Başarı, Ödül ve Burslar",
      title_en: "Achievements & Awards",
      path: `/profilim/cv/duzenle/${slug}/basari-odul-ve-burslar`,
      required: false,
    },
    {
      id: 9,
      title: "Sınavlar & Sertifikalar",
      title_en: "Exams & Certifications",
      path: `/profilim/cv/duzenle/${slug}/sinavlar-sertifikalar`,
      required: false,
    },
    {
      id: 10,
      title: "Hobiler",
      title_en: "Hobbies",
      path: `/profilim/cv/duzenle/${slug}/hobiler`,
      required: false,
    },
    {
      id: 11,
      title: "Dosyalar",
      title_en: "Files",
      path: `/profilim/cv/duzenle/${slug}/dosyalar`,
      required: false,
    },
  ];

  const privacyDivRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        privacyDivRef.current &&
        !privacyDivRef.current.contains(event.target as Node)
      ) {
        setPrivacyPopupOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [privacyDivRef]);

  useEffect(() => {
    const currentStep = steps.find((s) => s.path.startsWith(location.pathname));
    if (currentStep) {
      setCurrentStepName(
        `${isEnglish ? `${currentStep.title_en}` : `${currentStep.title}`}`
      );
    }
  }, [location, isEnglish]);

  const [isOpen, setIsOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [containerRef]);

  return (
    <div className="flex flex-wrap  text-gray-700 bg-white  bg-clip-border rounded-xl  mx-auto relative">
      {" "}
      <div className="p-6 flex  items-center justify-evenly gap-3 w-full sm:flex-row relative">
        {" "}
        <h5 className="m-0 font-poppins text-sm w-[45%] text-center sm:w-auto mb-2  antialiased  leading-snug tracking-normal text-mains">
          {currentStepName}
        </h5>
        <div
          ref={privacyDivRef}
          className=" sm:w-auto w-[35%] justify-center font-poppins relative flex flex-col items-center mb-2  text-sm  antialiased  leading-snug tracking-normal text-blue-gray-900 text-mains"
        >
          <button
            className="flex flex-wrap items-center gap-1"
            onClick={() => setPrivacyPopupOpen((prev) => !prev)}
          >
            <HiLockClosed className="h-4 w-4 animate-pulse mr-1 text-mains" />
            {isEnglish ? "Perms" : "İzinler"}
            {privacyPopupOpen && (
              <div className="flex flex-col gap-2 absolute min-w-[300px] bottom-0 left-1/2 -translate-x-1/2 translate-y-full p-2 border border-gray-200 shadow-md rounded-md bg-white z-50">
                {/* CV visibility all button */}
                <button
                  className={`flex flex-row items-center gap-2 p-2 border border-gray-200 ${
                    visibility == "ALL"
                      ? "bg-mains text-white"
                      : "bg-white text-gray-700"
                  }  hover:bg-mains hover:text-white rounded-md transition-all`}
                  onClick={async () => {
                    try {
                      const response = await axios.userAuthRequest({
                        endpoint: `user/profilim/cv/visibility/${cvId}`,
                        type: "post",
                        payload: {
                          visibility: "ALL",
                        },
                      });

                      if (response && response.status) {
                        Swal.fire({
                          icon: "success",
                          title: "Başarılı",
                        });

                        getCvById();
                      } else {
                        Swal.fire({
                          icon: "warning",
                          title: "Başarısız",
                          text: "CV gizliliği güncellenirken bir hata meydana geldi.",
                        });
                      }
                    } catch (error) {
                      console.log(`Error: ${error}`);
                      Swal.fire({
                        icon: "warning",
                        title: "Başarısız",
                        text: "CV gizliliği güncellenirken beklenmedik bir hata meydana geldi.",
                      });
                    }
                  }}
                >
                  <FaRegBuilding />
                  <div className="flex flex-col gap-2">
                    <label className="cursor-pointer text-sm">
                      {isEnglish ? "All Companies" : "Tüm Firmalar"}
                    </label>
                    <label className="cursor-pointer text-xs">
                      {isEnglish
                        ? "Your CV is visible to all companies on isbul.net"
                        : "Özgeçmişin isbul.net'teki tüm firmalar tarafından görüntülenir."}
                    </label>
                  </div>
                </button>

                {/* CV visibility company button */}
                <button
                  className={`flex flex-row items-center gap-2 p-2 border border-gray-200 ${
                    visibility == "APPLY_COMPANY"
                      ? "bg-mains text-white"
                      : "bg-white text-gray-700"
                  } hover:bg-mains hover:text-white rounded-md transition-all`}
                  onClick={async () => {
                    try {
                      const response = await axios.userAuthRequest({
                        endpoint: `user/profilim/cv/visibility/${cvId}`,
                        type: "post",
                        payload: {
                          visibility: "APPLY_COMPANY",
                        },
                      });

                      if (response && response.status) {
                        Swal.fire({
                          icon: "success",
                          title: "Başarılı",
                        });

                        getCvById();
                      } else {
                        Swal.fire({
                          icon: "warning",
                          title: "Başarısız",
                          text: "CV gizliliği güncellenirken bir hata meydana geldi.",
                        });
                      }
                    } catch (error) {
                      console.log(`Error: ${error}`);
                      Swal.fire({
                        icon: "warning",
                        title: "Başarısız",
                        text: "CV gizliliği güncellenirken beklenmedik bir hata meydana geldi.",
                      });
                    }
                  }}
                >
                  <FaLock />
                  <div className="flex flex-col gap-2">
                    <label className="cursor-pointer text-sm">
                      {isEnglish ? "Applied Companies" : "Başvurduğum Firmalar"}
                    </label>
                    <label className="cursor-pointer text-xs">
                      {isEnglish
                        ? "Your CV is only visible to the company you applied to."
                        : "Özgeçmişin sadece başvuru yaptığın firma tarafından görüntülenir."}
                    </label>
                  </div>
                </button>
              </div>
            )}
          </button>
        </div>
        <div ref={containerRef} className="w-[35%]">
          <button
            onClick={() => setIsOpen((prev) => !prev)}
            className="w-full sm:w-auto flex items-center justify-center select-none font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-2 px-4 rounded-lg bg-orng100 text-white shadow-md shadow-orange-500/10 hover:shadow-lg hover:shadow-orange-500/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            type="button"
          >
            <span className="mr-1">{isEnglish ? "Steps" : "Adımlar"}</span>
            <HiChevronDown
              className={`h-4 w-4 text-white ${
                isOpen ? "rotate-180" : ""
              } transition-all`}
            />
          </button>
          {isOpen ? (
            <div className="absolute right-16 top-14 md:top-16 lg:top-24 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
              <div
                className="py-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                {/* Completed steps map */}
                {steps.map((step, index) => {
                  if (!missingInfo.find((m) => m.id == step.id)) {
                    return (
                      <Link
                        onClick={() => setIsOpen(!isOpen)}
                        key={`completed${index}`}
                        to={step.path}
                        className="flex flex-row items-center gap-1 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      >
                        <IoCheckmark className="text-green-600" />
                        {isEnglish ? step.title_en : step.title}
                        {step.required && (
                          <b className="text-xs text-red-600">*</b>
                        )}
                      </Link>
                    );
                  }
                })}

                {/* Missing steps map */}
                {steps.map((step, index) => {
                  if (missingInfo.find((m) => m.id == step.id)) {
                    return (
                      <Link
                        onClick={() => setIsOpen(!isOpen)}
                        key={`missing${index}`}
                        to={step.path}
                        className="flex flex-row items-center gap-1 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      >
                        <IoAdd className="text-red-600" />
                        {isEnglish ? step.title_en : step.title}
                        {step.required && (
                          <b className="text-xs text-red-600">*</b>
                        )}
                      </Link>
                    );
                  }
                })}
              </div>
            </div>
          ) : null}
        </div>
        <div className="absolute bottom-2 left-6 right-6 flex justify-center mt-4">
          <GradientProgressBar progress={progress} />
        </div>
      </div>
    </div>
  );
}

export default CvEditHeader;
