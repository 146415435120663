import React, { Dispatch, SetStateAction, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addOrUpdateQueryParam } from "../../../../hooks/useAddOrUpdateQueryParam";
import {
  adDatesData,
  disabledData,
  experienceData,
  JobsPageContext,
  jobsPageContextType,
  otherData,
  otherDataUser,
} from "..";
import Cookies from "js-cookie";
import FilterCategoryInfo from "../filter-category-info";
import FilterCategoryAccordion from "../filter-category-accordion";
import { useEffect, useState } from "react";
import Axios from "../../../../services/axios";
import FilterCategoryAccordionCityDistrict from "../filter-category-accordion-city-district";
import { BiSolidMapPin } from "react-icons/bi";
import services from "../../../../services/login-services";
import Swal from "sweetalert2";

export interface IItem {
  params_string?: string;
  title: string;
  id: number;
  params?: string;
}

interface FilterSectionContentCopyProps {
  savedFilters: IItem[];
  setSavedFilters: Dispatch<SetStateAction<IItem[]>>;
  deleteItem: boolean;
}

const FilterSectionContentCopy: React.FC<FilterSectionContentCopyProps> = ({
  savedFilters,
  setSavedFilters,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({ last_page: "", total: "" });
  const userToken = Cookies.get("userToken");
  const companyToken = Cookies.get("companyUserToken");
  const axios = new Axios();

  useEffect(() => {
    const fetchAdData = async () => {
      const response = await axios.userAuthRequest({
        type: "get",
        endpoint: `user/profilim/kayitli-aramalar/`,
        payload: "",
      });
      //console.log(response, "response");
      setSavedFilters(response.data.data);
    };

    if (userToken) {
      fetchAdData();
    } else {
      console.log("userToken mevcut değil");
    }
  }, [userToken]); // userToken bağımlılığını ekleyerek değişiklikleri dinleyin

  const {
    newSelectedValues: selectedValues,
    setNewSelectedValues: setSelectedValues,
    selectedValues: oldSelectedValues,
    setSelectedValues: setOldSelectedValues,
    handleFilterButton,
  }: jobsPageContextType = useContext(JobsPageContext);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const total = searchParams.get("total") || "";
    const last_page = searchParams.get("last_page") || "";
    setPagination({ total, last_page });
  }, [location.search]);

  useEffect(() => {
    //console.log('triggered')
    setSelectedValues(oldSelectedValues);
  }, [oldSelectedValues]);

  const fireNearbyError = (isData = false, isCompany = false) => {
    if (isData) {
      if (isCompany) {
        Swal.fire({
          title: "Hata",
          text: "Hesap ayarlarınızdan firma adres bilgilerinizi güncelleyerek yakınımdaki ilanlar özelliğinden yararlanabilirsiniz",
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Hata",
          text: "Yakınınızdaki iş ilanlarını görebilmek için şehir ve ilçe bilgisini giriniz",
          icon: "error",
        });
      }
    } else {
      Swal.fire({
        title: "Hata",
        text: "Lokasyon verisi alınırken bir hata oluştu",
        icon: "error",
      });
    }
  };

  const handleNewrbyAdverts = () => {
    let total_filter_items = 0;
    Object.keys(selectedValues).forEach((key: any) => {
      if (typeof selectedValues[key] !== "boolean") {
        let item = selectedValues[key] as IItem[];
        total_filter_items += item.length;
      }
    });

    if (userToken) {
      axios
        .userAuthRequest({
          type: "get",
          endpoint: "user/profilim/cv",
          payload: {},
        })
        .then((response: any) => {
          axios
            .userAuthRequest({
              type: "get",
              endpoint:
                "user/profilim/cv/contact/index?cv_id=" + response.data.data.id,
              payload: {},
            })
            .then((response: any) => {
              if (
                response.data.data.city_list &&
                response.data.data.district_list
              ) {
                let go = false;
                if (total_filter_items !== 5) {
                  go = true;
                } else {
                  if (selectedValues.cities.length !== 1) go = true;
                  if (selectedValues.districts.length !== 1) go = true;
                  if (!go) {
                    if (
                      selectedValues.cities[0].id !==
                      response.data.data.city_list.id
                    ) {
                      go = true;
                    }
                    if (
                      selectedValues.districts[0].id !==
                      response.data.data.district_list.id
                    ) {
                      go = true;
                    }
                  }
                }
                if (go) {
                  setSelectedValues({
                    advertDate: [],
                    cities: [response.data.data.city_list],
                    districts: [response.data.data.district_list],
                    disabled: [],
                    experience: [],
                    other: [],
                    positionLevels: [],
                    positions: [],
                    sections: [],
                    sectors: [],
                    workingLocations: [],
                    workingTypes: [],
                    filters: [],
                    company: [],
                    search: [],
                    isFilterUpdated: true,
                  });
                }
              } else {
                fireNearbyError(true);
              }
            })
            .catch(() => {
              fireNearbyError();
            });
        })
        .catch(() => {
          fireNearbyError();
        });
    } else if (companyToken) {
      services
        .checkTokenCompany()
        .then((response: any) => {
          if (
            response.data.company.company_detail.city_id &&
            response.data.company.company_detail.district_id
          ) {
            let go = false;
            if (total_filter_items !== 5) {
              go = true;
            } else {
              if (selectedValues.cities.length !== 1) go = true;
              if (selectedValues.districts.length !== 1) go = true;
              if (!go) {
                if (
                  selectedValues.cities[0].id !==
                  response.data.company.company_detail.city_id
                ) {
                  go = true;
                }
                if (
                  selectedValues.districts[0].id !==
                  response.data.company.company_detail.district_id
                ) {
                  go = true;
                }
              }
            }

            if (go) {
              setSelectedValues({
                advertDate: [],
                cities: [
                  {
                    id: response.data.company.company_detail.city_id,
                    title: "",
                  },
                ],
                districts: [
                  {
                    id: response.data.company.company_detail.district_id,
                    title: "",
                  },
                ],
                disabled: [],
                experience: [],
                other: [],
                positionLevels: [],
                positions: [],
                sections: [],
                sectors: [],
                workingLocations: [],
                workingTypes: [],
                filters: [],
                company: [],
                search: [],
                isFilterUpdated: true,
              });
            }
          } else {
            fireNearbyError(true, true);
          }
        })
        .catch(() => {
          fireNearbyError();
        });
    } else {
      fireNearbyError();
    }
  };

  return (
    <>
      {false && (
        <div className="text-xs m-2 text-gray-500">
          <span className="font-black">{pagination.last_page + " "}</span>
          sayfada
          <span className="font-black">{" " + pagination.total + " "}</span>
          iş ilanları listelendi.
        </div>
      )}

      <div className="hidden xl:block w-full xl:h-20">
        <FilterCategoryInfo categoryName="Şehir" />
      </div>
      <div className="w-full mb-[10px]">
        <FilterCategoryAccordionCityDistrict
          nearbyHandle={() => {
            handleNewrbyAdverts();
          }}
          name={`Şehir / İlçe Ara ${
            selectedValues.cities?.length > 0 ||
            selectedValues.districts?.length > 0
              ? `(${
                  selectedValues.cities.length + selectedValues.districts.length
                })`
              : ""
          }`}
          placeHolder="Şehir veya İlçe Ara"
          api={{
            endoint: "get-cities-or-districts",
            apiWay: "cities_or_districts",
            propertyName: "name",
          }}
          initialSelectedItems={{
            cities: oldSelectedValues.cities,
            districts: oldSelectedValues.districts,
          }}
          initialNewSelectedItems={{
            cities: selectedValues.cities,
            districts: selectedValues.districts,
          }}
          onChange={(e: { cities: IItem[]; districts: IItem[] }) => {
            setSelectedValues((prev) => ({
              ...prev,
              cities: e.cities,
              districts: e.districts,
            }));
          }}
        />
      </div>

      <div className="hidden xl:block w-full xl:h-20">
        <FilterCategoryInfo categoryName="Pozisyon" />
      </div>
      <div className="w-full mb-[10px]">
        <FilterCategoryAccordion
          name={`Pozisyon / Meslek Seçimi ${
            selectedValues.positions.length > 0
              ? `(${selectedValues.positions.length})`
              : ""
          }`}
          placeHolder="Ara..."
          api={{
            endoint: "get-positions",
            apiWay: "positions",
            propertyName: "title",
          }}
          isOpen={true}
          initialSelectedItems={oldSelectedValues.positions}
          initialNewSelectedItems={selectedValues.positions}
          onChange={(e) =>
            setSelectedValues({ ...selectedValues, positions: e })
          }
        />
      </div>

      <div className="w-full mb-[10px]">
        <FilterCategoryAccordion
          name={`Çalışma Tercihi ${
            selectedValues.workingLocations.length > 0
              ? `(${selectedValues.workingLocations.length})`
              : ``
          }`}
          api={{
            endoint: "get-working-locations",
            apiWay: "working_locations",
            propertyName: "title",
          }}
          initialSelectedItems={oldSelectedValues.workingLocations}
          initialNewSelectedItems={selectedValues.workingLocations}
          onChange={(e) =>
            setSelectedValues({ ...selectedValues, workingLocations: e })
          }
        />
      </div>

      <div className="w-full mb-[10px]">
        <FilterCategoryAccordion
          name={`Bölüm ${
            selectedValues.sections.length > 0
              ? `(${selectedValues.sections.length})`
              : ""
          }`}
          placeHolder="Bölüm Ara"
          api={{
            endoint: "get-sections",
            apiWay: "sections",
            propertyName: "title",
          }}
          initialSelectedItems={oldSelectedValues.sections}
          initialNewSelectedItems={selectedValues.sections}
          onChange={(e) =>
            setSelectedValues({ ...selectedValues, sections: e })
          }
        />
      </div>

      <div className="w-full mb-[10px]">
        <FilterCategoryAccordion
          name={`Firma Sektörü ${
            selectedValues.sectors.length > 0
              ? `(${selectedValues.sectors.length})`
              : ""
          }`}
          placeHolder="Sektör Ara"
          api={{
            endoint: "get-sectors",
            apiWay: "sectors",
            propertyName: "title",
          }}
          initialSelectedItems={oldSelectedValues.sectors}
          initialNewSelectedItems={selectedValues.sectors}
          onChange={(e) => setSelectedValues({ ...selectedValues, sectors: e })}
        />
      </div>

      <div className="w-full mb-[10px]">
        <FilterCategoryAccordion
          name={`Pozisyon Seviyesi ${
            selectedValues.positionLevels.length > 0
              ? `(${selectedValues.positionLevels.length})`
              : ""
          }`}
          api={{
            endoint: "get-position-levels",
            apiWay: "position_levels",
            propertyName: "name",
          }}
          initialSelectedItems={oldSelectedValues.positionLevels}
          initialNewSelectedItems={selectedValues.positionLevels}
          onChange={(e) =>
            setSelectedValues({ ...selectedValues, positionLevels: e })
          }
        />
      </div>

      <div className="w-full mb-[10px]">
        <FilterCategoryAccordion
          name={`İlan Tarihi ${
            selectedValues.advertDate.length > 0
              ? `(${selectedValues.advertDate.length})`
              : ""
          }`}
          items={adDatesData}
          initialSelectedItems={oldSelectedValues.advertDate}
          initialNewSelectedItems={selectedValues.advertDate}
          onChange={(e) =>
            setSelectedValues({ ...selectedValues, advertDate: e })
          }
          maxSelect={1}
        />
      </div>

      <div className="w-full mb-[10px]">
        <FilterCategoryAccordion
          name={`Çalışma Şekli ${
            selectedValues.workingTypes.length > 0
              ? `(${selectedValues.workingTypes.length})`
              : ""
          }`}
          api={{
            endoint: "get-working-types",
            apiWay: "working_types",
            propertyName: "title",
          }}
          initialSelectedItems={oldSelectedValues.workingTypes}
          initialNewSelectedItems={selectedValues.workingTypes}
          onChange={(e) =>
            setSelectedValues({ ...selectedValues, workingTypes: e })
          }
        />
      </div>

      <div className="w-full mb-[10px]">
        <FilterCategoryAccordion
          name={`Deneyim ${
            selectedValues.experience.length > 0
              ? `(${selectedValues.experience.length})`
              : ""
          }`}
          items={experienceData}
          initialSelectedItems={oldSelectedValues.experience}
          initialNewSelectedItems={selectedValues.experience}
          onChange={(e) =>
            setSelectedValues({ ...selectedValues, experience: e })
          }
          maxSelect={1}
        />
      </div>

      <div className="w-full mb-[10px]">
        <FilterCategoryAccordion
          name={`Engelli İlanı ${
            selectedValues.disabled.length > 0
              ? `(${selectedValues.disabled.length})`
              : ""
          }`}
          items={disabledData}
          initialSelectedItems={oldSelectedValues.disabled}
          initialNewSelectedItems={selectedValues.disabled}
          onChange={(e) =>
            setSelectedValues({ ...selectedValues, disabled: e })
          }
          maxSelect={1}
        />
      </div>

      <div className="w-full mb-[10px]">
        {userToken ? (
          <FilterCategoryAccordion
            name={`Diğer Seçenekler ${
              selectedValues.other.length > 0
                ? `(${selectedValues.other.length})`
                : ""
            }`}
            items={otherDataUser}
            initialSelectedItems={oldSelectedValues.other}
            initialNewSelectedItems={selectedValues.other}
            onChange={(e) => setSelectedValues({ ...selectedValues, other: e })}
          />
        ) : (
          <FilterCategoryAccordion
            name={`Diğer Seçenekler ${
              selectedValues.other.length > 0
                ? `(${selectedValues.other.length})`
                : ""
            }`}
            items={otherData}
            initialSelectedItems={oldSelectedValues.other}
            initialNewSelectedItems={selectedValues.other}
            onChange={(e) => setSelectedValues({ ...selectedValues, other: e })}
          />
        )}
      </div>

      {savedFilters?.length > 0 && (
        <>
          {" "}
          {userToken && (
            <div className="w-full mb-2">
              <FilterCategoryAccordion
                name={`Kayıtlı Filtreler ${
                  selectedValues.filters.length > 0
                    ? `(${selectedValues.filters.length})`
                    : ""
                }`}
                items={savedFilters}
                initialSelectedItems={oldSelectedValues.filters}
                initialNewSelectedItems={selectedValues.filters}
                onChange={(e) =>
                  setSelectedValues({ ...selectedValues, filters: e })
                }
                deleteItem={true}
                maxSelect={1}
              />
            </div>
          )}
        </>
      )}

      <div
        onClick={handleFilterButton}
        className="bg-[#2253af] sticky bottom-14 xl:bottom-0 left-0 rounded-lg h-12 w-full ml-auto flex justify-center items-center text-white select-none cursor-pointer text-lg font-bold"
      >
        Filtrele
      </div>
    </>
  );
};

export default FilterSectionContentCopy;
