import {
    Dispatch,
    HTMLAttributes,
    SetStateAction,
    createContext,
    useEffect,
    useState,
    useRef,
  } from "react";
import classNames from "classnames";
import Trigger from "./Trigger";
import Error from "./Error";
import Label from "./Label";
  
// Component Type
type checkboxPropsType = HTMLAttributes<HTMLDivElement> & {
    checked?: boolean;
    setChecked?: Dispatch<SetStateAction<boolean>>;
    required?: boolean;
    error?: boolean;
};

// Context Type
export type checkboxContextType = {
    required?: boolean;
    error?: boolean;
    checked: boolean;
    setChecked: Dispatch<SetStateAction<boolean>>;
};

// Context Provider
export const CheckboxContext = createContext<checkboxContextType>({
    required: false,
    error: false,
    checked: false,
    setChecked: () => {},
});
  
  export function Checkbox({
    className,
    checked: initialChecked,
    setChecked: initialSetChecked,
    children,
    required,
    error,
    ...props
  }: checkboxPropsType) {
    const [checked, setChecked] = useState<boolean>(initialChecked || false);
  
    const cn = classNames({
      "flex flex-col gap-1 relative": true,
    });
  
    const value: checkboxContextType = {
      required,
      error,
      checked: initialChecked != undefined ? initialChecked : checked,
      setChecked: initialSetChecked != undefined ? initialSetChecked : setChecked,
    };
  
    useEffect(() => {
      if (initialChecked != undefined) {
        setChecked(initialChecked);
      }
    }, [initialChecked]);
  
    return (
      <CheckboxContext.Provider value={value}>
        <div className={`${cn} ${className}`} {...props}>
          {children}
        </div>
      </CheckboxContext.Provider>
    );
  }
  
  Checkbox.Trigger = Trigger;
  Checkbox.Label = Label;
  Checkbox.Error = Error;
  