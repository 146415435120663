import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { PiDotsThreeVertical } from "react-icons/pi";
import { dbUserAbstractType } from "../../../../../../types";
import Axios from "../../../../../../services/axios";
import Swal from "sweetalert2";

const axios = new Axios();

export default function AbstractItem({
  isEnglish = false,
  abstract,
  getAllDatas,
  setSelectedAbstract,
  setIsModalOpen,
}: {
  isEnglish?: boolean;
  abstract: dbUserAbstractType;
  getAllDatas?: () => void;
  setSelectedAbstract: Dispatch<SetStateAction<dbUserAbstractType | undefined>>;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const divRef = useRef<HTMLDivElement>(null);
  const mobileDivRef = useRef<HTMLDivElement>(null);

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isMobilePopupOpen, setIsMobilePopupOpen] = useState<boolean>(false);

  // Desktop contains
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setIsPopupOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [divRef]);

  // Mobile contains
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        mobileDivRef.current &&
        !mobileDivRef.current.contains(event.target as Node)
      ) {
        setIsMobilePopupOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [mobileDivRef]);

  console.log(abstract);

  return (
    <>
      {/* Desktop section */}
      <div className="h-auto w-full px-3 md:px-4 py-3 my-1 hidden lg:flex border-b justify-between items-center hover:bg-[#ebebeb] duration-100">
        <div className="flex items-center w-full basis-4/12">
          <label className="pr-10 text-xs text-gray-600 truncate poppins-semibold">
            {abstract.title}
          </label>
        </div>

        <span
          className="flex justify-start w-full pr-6 text-xs text-gray-600 truncate poppins-semibold basis-4/12 !line-clamp-1"
          dangerouslySetInnerHTML={{ __html: abstract.detail }}
        />

        <div
          className="relative flex items-center justify-end w-full col-span-1 gap-x-2 basis-4/12"
          ref={divRef}
        >
          <span
            className="text-xl rounded cursor-pointer focus:bg-gray-200 hover:bg-gray-300"
            onClick={() => setIsPopupOpen((prev) => !prev)}
          >
            <PiDotsThreeVertical className="text-gray-900" size={32} />
          </span>
          {isPopupOpen && (
            <div className="absolute z-10 flex flex-col items-center justify-center p-2 text-xs border rounded shadow text-primary100 font-poppins -right-1 top-8 bg-gray-50 ">
              <button
                className="w-32 px-1 py-2 mb-1 text-left transition-all duration-300 ease-in-out bg-white border rounded hover:bg-primary100 hover:text-white"
                onClick={() => {
                  setSelectedAbstract((prev) => abstract);
                  setIsModalOpen(true);
                }}
              >
                Düzenle
              </button>
              <button
                className="w-32 px-1 py-2 mb-1 text-left transition-all duration-300 ease-in-out bg-white border rounded hover:bg-primary100 hover:text-white"
                onClick={() => {
                  Swal.fire({
                    icon: "question",
                    title: "Uyarı",
                    text: "Ön yazıyı silmek istediğinize emin misiniz?",
                    confirmButtonText: "Evet",
                    cancelButtonText: "Hayır",
                    showCancelButton: true,
                    preConfirm: () => {
                      deleteAbstract();
                    },
                  });
                }}
              >
                Kaldır
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Mobile Section */}
      <div className="flex lg:hidden flex-col w-full gap-2 p-4 bg-white border border-gray-200 text-sm my-1 rounded-md shadow-lg">
        <div className="flex flex-row items-center justify-between">
          <label
            onClick={() => {
              setSelectedAbstract((prev) => abstract);
              setIsModalOpen(true);
            }}
            className="text-mains font-semibold font-poppins text-sm line-clamp-1 cursor-pointer"
          >
            {abstract.title}
          </label>
          <div ref={mobileDivRef} className="relative">
            <button
              type="button"
              className="text-xl"
              onClick={() => setIsMobilePopupOpen((prev) => !prev)}
            >
              <PiDotsThreeVertical />
            </button>
            {isMobilePopupOpen && (
              <div className="absolute bottom-0 right-0 translate-y-full z-10 flex flex-col items-center justify-center p-2 text-xs border rounded shadow text-primary100 font-poppins bg-gray-50 ">
                <button
                  className="w-32 px-1 py-2 mb-1 text-left transition-all duration-300 ease-in-out bg-white border rounded hover:bg-primary100 hover:text-white"
                  onClick={() => {
                    setSelectedAbstract((prev) => abstract);
                    setIsModalOpen(true);
                    setIsMobilePopupOpen(false); // Close popup
                  }}
                >
                  {isEnglish ? "Edit" : "Düzenle"}
                </button>
                <button
                  className="w-32 px-1 py-2 mb-1 text-left transition-all duration-300 ease-in-out bg-white border rounded hover:bg-primary100 hover:text-white"
                  onClick={() => {
                    Swal.fire({
                      icon: "question",
                      title: "Uyarı",
                      text: "Ön yazıyı silmek istediğinize emin misiniz?",
                      confirmButtonText: "Evet",
                      cancelButtonText: "Hayır",
                      showCancelButton: true,
                      preConfirm: () => {
                        deleteAbstract();
                      },
                    });
                  }}
                >
                  {isEnglish ? "Delete" : "Kaldır"}
                </button>
              </div>
            )}
          </div>
        </div>
        <span
          className="line-clamp-1 font-poppins text-gray-600 text-sm"
          dangerouslySetInnerHTML={{ __html: abstract.detail }}
        ></span>
      </div>
    </>
  );

  async function deleteAbstract() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/text/${abstract.id}`,
        type: "delete",
        payload: {},
      });

      if (response && response.status == 200) {
        if (getAllDatas) {
          getAllDatas();
        }

        Swal.fire({
          icon: "success",
          title: "Başarılı",
        });
      } else {
        Swal.fire({
          icon: "warning",
          title: "Hata",
          text: "Ön yazı silinirken bir hata meydana geldi.",
        });
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      Swal.fire({
        icon: "warning",
        title: "Hata",
        text: "Ön yazı silinirken beklenmedik bir hata meydana geldi.",
      });
    }
  }
}
