import { Popover, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import Axios from "../../../../services/axios";
import { Folder } from "../index";
import { useDispatch } from "react-redux";
import {
  changeArchiveStatus,
  deleteFolder,
  getArchivedFolders,
  getNotArchivedFolders,
} from "../../../../store/features/companyFoldersSlice";
import EditModal from "./EditModal";
import Swal from "sweetalert2";

type Props = {
  item: Folder;
};
const axios = new Axios();
const ActionsPopover = (props: Props) => {
  const { item } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleArchiveToggle = async () => {
    const isUnarchiving = item.is_archived;
    Swal.fire({
      icon: "question",
      title: "Uyarı",
      text: isUnarchiving
        ? "Klasörü arşivden çıkarmak istediğinizden emin misiniz?"
        : "Klasörü arşivlemek istediğinizden emin misiniz?",
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#2253af",
      cancelButtonColor: "#cf0000",
      preConfirm: async () => {
        await dispatch(
          changeArchiveStatus(props.item.id, isUnarchiving ? 0 : 1)
        );
        if (isUnarchiving) {
          await dispatch(getArchivedFolders());
        } else {
          await dispatch(getNotArchivedFolders());
        }
        Swal.fire({
          icon: "success",
          title: isUnarchiving
            ? "Klasör Arşivden Çıkarıldı!"
            : "Klasör Arşivlendi!",
          timer: 3000,
        });
      },
    });
  };

  return (
    <div className="relative">
      <EditModal item={props.item} open={open} setOpen={setOpen} />
      <Popover>
        {({ open }) => (
          <div className=" h-full flex justify-center">
            <Popover.Button>
              <BsThreeDotsVertical className="text-gray-500 text-[24px]" />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className="absolute top-5 right-0 z-[999999] mt-3  before:w-4 before:h-4
               before:bg-white before:absolute before:rotate-45 before:-top-2 before:end-2 shadow-xl"
              >
                <div className="flex flex-col bg-white p-3 shadow-md gap-2">
                  <Popover.Button
                    className={`text-sm  flex justify-left w-32 items-center text-nowrap 
                    gap-2 border border-gray-300 p-2 rounded-md transition-all ${
                      item.is_archived
                        ? "bg-title text-white hover:text-title hover:bg-white"
                        : "text-title hover:text-white hover:bg-title"
                    }`}
                    onClick={handleArchiveToggle}
                  >
                    {item.is_archived ? "Arşivden Çıkar" : "Arşivle"}
                  </Popover.Button>
                  <Popover.Button
                    className="text-sm  flex justify-left items-center text-nowrap gap-2 text-title border
                     border-gray-300 p-2 rounded-md hover:bg-title hover:text-white transition-all"
                    as={Link}
                    to={`${props.item.id}/cv-listesi`}
                  >
                    Görüntüle
                  </Popover.Button>
                  <Popover.Button
                    className="text-sm  flex justify-left items-center text-nowrap gap-2 text-title border
                     border-gray-300 p-2 rounded-md hover:bg-title hover:text-white transition-all"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Düzenle
                  </Popover.Button>
                  <Popover.Button
                    className="text-sm  flex justify-left items-center 
                                        text-nowrap gap-2 text-title border border-gray-300 p-2 rounded-md
                                         hover:bg-title hover:text-white transition-all"
                    onClick={async () => {
                      Swal.fire({
                        icon: "question",
                        title: "Uyarı",
                        text: "Klasörü silmek istediğinizden emin misiniz ?",
                        confirmButtonText: "Evet",
                        cancelButtonText: "Hayır",
                        showCancelButton: true,
                        showCloseButton: true,
                        confirmButtonColor: "#cf0000",
                        cancelButtonColor: "#2253af",
                        preConfirm: async () => {
                          await dispatch(deleteFolder(props.item.id));
                          if (item.is_archived) {
                            await dispatch(getArchivedFolders());
                          } else {
                            await dispatch(getNotArchivedFolders());
                          }
                          Swal.fire({
                            icon: "success",
                            title: "Klasör Silindi!",
                            timer: 3000,
                          });
                        },
                      });
                    }}
                  >
                    Sil
                  </Popover.Button>
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        )}
      </Popover>
    </div>
  );
};

export default ActionsPopover;
