/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react";
import { TbHammerOff } from "react-icons/tb";
import { CiCalendarDate } from "react-icons/ci";
import { FiAlertCircle } from "react-icons/fi";
import { useGetMonthName } from "../../../../hooks/useFormatDate";
import CustomModal from "../../../../components/common/custom-modal";

type Props = {
  jobEx: any;
  open: boolean;
  setOpen: any;
};

function JobExModal(props: Props) {
  return (
    <CustomModal
      title={"ADAYIN SON İŞ DENEYİMLERİ"}
      open={props.open}
      onClose={() => props.setOpen(false)}
      content={
        props.jobEx?.length > 0 ? (
          <div className="flex flex-col gap-3">
            {props.jobEx.map((job: any, index: number) => (
              <div
                className="bg-gray-200 p-3 rounded-md flex justify-start"
                key={index}
              >
                <span className="w-1/12  text-title stroke-title text-2xl bordered-text flex justify-center items-center">
                  {index + 1}
                </span>
                <div className="w-10/12">
                  <p className="flex gap-2 text-black">
                    <TbHammerOff /> {job.company_name}
                  </p>
                  {job.company_name && (
                    <p className="text-black">
                      <span className="text-sm text-title"> {"»"} </span>{" "}
                      {job.position}
                    </p>
                  )}
                  <p className="flex gap-2 text-black">
                    <CiCalendarDate className="text-xl mt-1" />
                    İşe Başlama Tarihi:
                    <span className="text-title font-semibold">
                      {" " + useGetMonthName(JSON.parse(job.start_date).month)}{" "}
                      {JSON.parse(job.start_date).year}
                    </span>
                    <CiCalendarDate className="text-xl mt-1" />
                    İşten Çıkış Tarihi:
                    <span className="text-title font-semibold">
                      {" " + useGetMonthName(JSON.parse(job.end_date).month)}{" "}
                      {JSON.parse(job.end_date).year}
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex items-center gap-3">
            <FiAlertCircle />
            <p>Kayıtlı İş Deneyimleri Bulunamadı</p>
          </div>
        )
      }
      footer=" "
    />
  );
}

export default JobExModal;
