import Axios from '../axios';

const SectorsService = {
  getSectorsByFilter: (filter: string) => {
    const axios = new Axios();
    return axios.request({
      type: 'get',
      endpoint: `cdn/get-sectors?search=${filter}`,
      payload: {},
    });
  }, 

};

export { SectorsService };
