import React from 'react';
import Contents from './contents';

const Dashboard: React.FC = () => {
  return (
  <Contents/>
  );
};

export default Dashboard;
