import React, { useEffect, useState } from "react";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useFormik } from "formik";
import { CustomFormField } from "../../../../../components/common/custom-form-field";
import services from "../../../../../services/profile-services";
import * as Yup from "yup";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object({
  job: Yup.string().required("Zorunludur"),
  jobYear: Yup.string().required("Zorunludur"),
  jobType: Yup.string().required("Zorunludur"),
});

const JobPrefences: React.FC = () => {
  const [text, setText] = useState<string>("");
  const navigate = useNavigate();

  console.log(text);

  const [job, setJob] = useState({
    jobType: [
      { value: "0", label: "Çalışmıyor" },
      { value: "1", label: "Çalışıyor" },
    ],
    jobYear: [
      { value: "0", label: "Deneyimsiz" },
      { value: "1", label: "1-2 Yıl" },
      { value: "2", label: "2-3 Yıl" },
      { value: "3", label: "3-4 Yıl" },
      { value: "4", label: "5+ Yıl" },
    ],
  });

  const formik = useFormik({
    initialValues: {
      job: "",
      jobYear: "",
      jobType: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const userData = {
        ...values,
        job_title: values.job,
        experience_level_id: values.jobYear,
        work_status: values.jobType,
        information: text,
      };
      try {
        const response = await services.jobPrefences(userData);

        if (response) {
          navigate("/profilim");
        }
      } catch (error) {
        throw new Error(error as string);
      }
    },
  });

  return (
    <div className="bgwelcome">
      <div className="w-full h-auto py-5 lg:py-10 items-center justify-center flex mx-auto container">
        <div className="bg-white w-full lg:w-2/3 h-auto flex flex-col gap-y-5 shadow-lg py-5  border rounded-md">
          <div className="w-full border-b border-mains py-2 ">
            <span className="px-10 text-md poppins-medium text-mains">
              İş Tercihleri
            </span>
          </div>
          <div className="w-full px-10 py-2  flex flex-col">
            <form
              className="flex flex-col gap-y-6 h-auto"
              onSubmit={formik.handleSubmit}
            >
              <div className="w-full  gap-x-4  items-center justify-center">
                <div className="flex flex-col lg:flex-row w-full gap-y-5 lg:gap-y-0 lg:gap-x-5">
                  <div className="flex flex-col gap-x-2 w-full lg:w-1/3">
                    <span className="text-sm poppins-medium text-gray-500">
                      Mesleğiniz Unvanınız
                    </span>
                    <CustomFormField
                      type="text"
                      label="Unvanınız *"
                      formik={formik}
                      name="job"
                    />
                  </div>

                  <div className="flex flex-col gap-x-2 w-full lg:w-1/3">
                    <span className="text-sm poppins-medium text-gray-500">
                      Çalışma Durumu
                    </span>
                    <CustomFormField
                      type="select"
                      label="Unvanınız *"
                      formik={formik}
                      name="jobType"
                      options={job.jobType}
                    />
                  </div>

                  <div className="flex flex-col gap-x-2 w-full lg:w-1/3">
                    <span className="text-sm poppins-medium text-gray-500">
                      Toplam İş Deneyimi
                    </span>
                    <CustomFormField
                      type="select"
                      label="Unvanınız *"
                      formik={formik}
                      name="jobYear"
                      options={job.jobYear}
                    />
                  </div>
                </div>
              </div>

              <div className="h-auto">
                <CKEditor
                  editor={ClassicEditor}
                  data="<p>İşbul</p>"
                  onReady={() => {}}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setText(data);
                  }}
                />
              </div>

              <div className="w-full lg:justify-between flex flex-col lg:flex-row gap-y-3 lg:gap-y-0">
                <div className="flex gap-x-2 bg-gray-300 justify-center lg:justify-start w-full lg:w-auto rounded-md px-5 py-3 ">
                  <button className="flex items-center ">
                    <MdKeyboardArrowLeft className="text-black text-lg" />
                    <span className="text-black poppins-medium text-sm">
                      Geri Dön
                    </span>
                  </button>
                </div>
                <div className="flex gap-x-2 bg-mains justify-center lg:justify-start w-full lg:w-auto rounded-md px-5 py-3 ">
                  <button className="flex items-center  ">
                    <span className="text-white poppins-medium text-sm">
                      Kaydet ve Devam Et
                    </span>
                    <MdKeyboardArrowRight className="text-white text-lg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPrefences;
