import { IconType } from "react-icons";
import CustomButton from "../custom-button";
import { MdBlock } from "react-icons/md";
import { useEffect, useState } from "react";
import { Modal, Button, Select } from "antd";
import { FaPaperPlane } from "react-icons/fa";
import Axios from "../../../../../services/axios";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import { FiAlertCircle } from "react-icons/fi";

interface IFilterCompanyProps {
  icon: IconType;
}

const BlockCompany = ({ icon: Icon }: IFilterCompanyProps) => {
  const axios = new Axios();
  const [advert, setAdvert] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [term, setTerm] = useState("");
  const [options, setOptions] = useState<any[]>([]);
  const [blackList, setBlackList] = useState<any[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
    null
  );
  const userToken = Cookies.get("userToken") || Cookies.get("companyUserToken");

  const showModal = () => {
    fetchPositionsAndCompanies()
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSearch = (value: string) => {
    console.log(value)
    setTerm(value);
    fetchPositionsAndCompanies()
  };

  const handleSelectCompany = (value: number) => {
    setSelectedCompanyId(value);
  };

  const fetchPositionsAndCompanies = async () => {

    console.log(term)
    if(term===''){
      setTerm('a')
    }
    const url = new URL(
      "https://testapi.isbul.net/api/v1/cdn/get-positions-or-companies"
    );
    const params = { search: term };
    url.search = new URLSearchParams(params).toString();

    try {
      const response = await fetch(url);
      const data = await response.json();
      return {
        companies: data.companies || [],
      };
    } catch (error) {
      console.error("Error fetching positions and companies:", error);
      return { companies: [] };
    }
  };

  console.log(blackList);
  console.log(options, "options");

  useEffect(() => {
    const loadData = async () => {
      const { companies } = await fetchPositionsAndCompanies();
      setAdvert(companies);
      setOptions(
        companies.map((company: any) => ({
          value: company.company_id,
          label: company.company_name,
        }))
      );
    };

    if (term) {
      loadData();
    } else {
      setOptions([]);
    }
  }, [term]);

  useEffect(() => {
    const fetchBlackList = async () => {
      try {
        const response = await axios.request({
          type: "get",
          endpoint: `user/profilim/blacklist`,
          payload: {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          },
        });
      
        setBlackList(response?.data?.data || []);
          fetchPositionsAndCompanies();
      } catch (error) {
        console.error("Error fetching blacklist:", error);
      }
    };

    fetchBlackList();
  }, [userToken]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  };

  console.log(blackList);

  const handleDeleteBlock = async (id: number) => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: "Emin misiniz?",
      text: "Firma engelini kaldırmak istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "İptal",
    });

    if (result.isConfirmed) {
      try {
        const response = await axios.request({
          type: "delete",
          endpoint: `user/profilim/blacklist/delete/${id}`,
          payload: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        const newBlackList = blackList.filter(
          (company) => company.company.company_id !== id
        );

        setBlackList(newBlackList);

        Swal.fire("Başarılı", "Firma engeli başarıyla kaldırıldı", "success");

      } catch (error) {
        console.error("Error deleting block:", error);
      }
    } else {
      console.log("Cancelled delete block.");
    }
  };

  const handleAddBlackList = async () => {
    if (!selectedCompanyId) {
      return;
    }

    const result = await Swal.fire({
      title: "Emin misiniz?",
      text: "Firmayı engellemek istediğinize emin misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "İptal",
    });

    if (result.isConfirmed) {
      console.log("Adding block:", selectedCompanyId);
      try {
        const response = await axios.userAuthRequest({
          type: "post",
          endpoint: `user/profilim/blacklist/create`,
          payload: {
            company_id: selectedCompanyId,
          },
        });

        console.log("Add block response:", response);

        // Update blackList state to include the newly added block
        const newCompany = options.find(
          (company) => company.value === selectedCompanyId
        );
        if (newCompany) {
          const newEntry = {
            company: {
              company_id: newCompany.value,
              company_name: newCompany.label,
            },
            created_at: new Date().toISOString(),
          };
          setBlackList((prevBlackList) => [...prevBlackList, newEntry]);
        }

        Swal.fire("Başarılı", "Firma başarıyla engellendi", "success");
        setIsModalOpen(false);
        setSelectedCompanyId(null); // Reset selected company
        setTerm(""); // Reset search term
      } catch (error) {
        console.error("Error adding block:", error);
      }
    } else {
      console.log("Cancelled block.");
    }
  };

  return (
    <div className="">
      <div className="flex justify-around sm:justify-between items-center mt-4">
        <label className="flex items-center border-blue-900 border rounded-lg h-9 lg:h-12 max-w-[160px] sm:max-w-none">
          <Icon size={22} className="text-black pl-1 ml-1" />
          <input
            type="text"
            className="outline-none font-poppins text-sm pl-1 max-w-[135px] sm:max-w-none"
            placeholder="Firma Ara"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </label>
        <div onClick={showModal}>
          <CustomButton
            title="Firma Engelle"
            icon={MdBlock}
            designs="flex items-center gap-1 font-medium text-xs font-poppins min-w-[110px] rounded-lg px-4 h-[40px] duration-300 !rounded-md"
          />
        </div>
      </div>
      <div className="flex flex-col my-6 ">
        {blackList.length > 0 && (
          <div className="flex justify-between bg-gray-200 px-2 md:px-4 py-5 font-poppins text-xs">
            <div className="flex justify-start w-full">
              <p className="font-bold text-black">Engellenen Firmalar</p>
            </div>
            <div className="md:flex justify-center hidden w-full">
              <p className="font-bold text-black">Engelleme Tarihi</p>
            </div>
            <div className="flex justify-end w-full">
              <p className="font-bold text-black">İşlemler</p>
            </div>
          </div>
        )}

        {blackList.length === 0 && (
          <div className="flex items-center mt-4 mb-12 ml-4 mr-4 shadow-lg border rounded-xl bg-white">
            <span className="bg-[red] border border-blue mr-4 h-full text-white pt-3 pb-3 pl-1 pr-1 rounded-md">
              <FiAlertCircle size={35} />
            </span>
            <p className="font-poppins poppins-medium text-sm flex justify-center items-center gap-[6px]">
              <span>Engellenen firma bulunamadı</span>
            </p>
          </div>
        )}

        <div className="flex justify-between flex-col shadow-lg w-full items-center">
          {blackList.map((company: any) => (
            <div
              key={company.id}
              className="flex justify-between w-full hover:bg-gray-300 px-2 md:px-4 md:py-3 whitespace-nowrap line-clamp-1 "
            >
              <div className="flex justify-start w-1/2">
                <p className="line-clamp-1 break-keep mr-1 whitespace-nowrap" >{company?.company?.company_name}</p>
              </div>
              <div className="md:flex hidden justify-center w-full">
                <p className="whitespace-nowrap text-sm">
                  {formatDate(company?.created_at)}
                </p>
              </div>
              <div
                onClick={() => handleDeleteBlock(company.company.company_id)}
                className="flex justify-end w-full"
              >
                <CustomButton
                  title="Engeli Kaldır"
                  designs="flex items-center gap-1 font-medium !text-[12px] font-poppins min-w-[110px] rounded-lg !px-2 !md:px-4 h-[40px] duration-300 !rounded-md"
                  btnSize="!justify-end"
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal
        title={"Firma Engelle"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div className="w-full flex justify-end border-t border-color-[#dee2e6] px-[24px] py-[12px] gap-4">
            <Button
              className="bg-mains text-white font-poppins text-sm px-5 py-4 flex justify-center items-center"
              key="Hayır"
              onClick={handleAddBlackList}
            >
              Engelle
              <FaPaperPlane className="ml-2" />
            </Button>
          </div>,
        ]}
      >
        <div>
          <p className="font-poppins text-sm pb-4">
            Engellediğiniz firmanın ilanlarını göremeyeceksiniz, engellemek
            istediğinize emin misiniz?
          </p>

          <Select
            showSearch
            placeholder="Firma Ara"
            onSearch={handleSearch}
            onChange={handleSelectCompany}
            filterOption={false}
            options={options}
            value={selectedCompanyId}
            className="w-full"
            notFoundContent={<div className="text-red-600 text-sm">Aradığınız firma bulunamadı.</div>} // Custom no data message
          />
                  
        </div>
      </Modal>
    </div>
  );
};

export default BlockCompany;
