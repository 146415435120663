import classNames from "classnames";
import { AnchorHTMLAttributes, useContext } from "react";
import { paginationContextType, PaginationContext } from ".";
import { Link } from "react-router-dom";

type pageLinkPropsType = AnchorHTMLAttributes<HTMLAnchorElement> & {
  pageNumber: number;
};

export default function PageLink({
  className,
  pageNumber,
  ...props
}: pageLinkPropsType) {
  const { currentPage }: paginationContextType = useContext(PaginationContext);

  const cn = classNames({
    "flex flex-shrink-0 p-2 min-w-10 h-10 items-center justify-center text-mains rounded-md hover:bg-mains hover:text-white":
      true,
    "bg-mains text-white": pageNumber == currentPage,
  });
  return (
    <Link className={`${cn} ${className}`} to={props.href ?? ""} {...props}>
      {props.children}
    </Link>
  );
}
