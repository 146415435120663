import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import WordCount from "@ckeditor/ckeditor5-word-count/src/wordcount";
import services from "../../../../../services/profile-services";
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../seminar-course/custom-button/CustomButton";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import { getAwards } from "../../../../../store/cv-reducer/cvEditSlice";
import Axios from "../../../../../services/axios";
import { Button } from "../../../../../components/isbul-ui";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import Swal from "sweetalert2";
import { useContext } from "react";
import { cvLayoutContextType, CvLayoutContext } from "..";

interface DateDay {
  value: string;
  label: string;
}

interface DateOption {
  value: string;
  label: string;
}

const axios = new Axios()

export const initialDate: {
  day: DateDay[];
  month: DateDay[];
  years: DateDay[];
} = {
  day: [],
  month: [
    // { value: "Ay", label: "Ay" },
    { value: "1", label: "Ocak" },
    { value: "2", label: "Şubat" },
    { value: "3", label: "Mart" },
    { value: "4", label: "Nisan" },
    { value: "5", label: "Mayıs" },
    { value: "6", label: "Haziran" },
    { value: "7", label: "Temmuz" },
    { value: "8", label: "Ağustos" },
    { value: "9", label: "Eylül" },
    { value: "10", label: "Ekim" },
    { value: "11", label: "Kasım" },
    { value: "12", label: "Aralık" },
  ],
  years: [],
};

const AchivementAward: React.FC = () => {

  const { showCvScore, isEnglish }: cvLayoutContextType = useContext(CvLayoutContext)


  const params = useParams();
  const slug = params.slug
  const cvId = params.slug

  const navigate = useNavigate()


  const dispatch = useAppDispatch();

  const awards = useAppSelector((state) => state.cvEdit.awards);

  useEffect(() => {
    dispatch(getAwards());
  }, []);

  const formik = useFormik({
    initialValues: {
      hobbies: "",
    },
    onSubmit: async (values) => {

      if (values.hobbies.length > 5000) {
        Swal.fire({
          icon: 'error',
          title: 'Hata',
          text: 'İçerik en fazla 5000 karakterden oluşabilir'
        })

        return 
      }
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/aw/update`,
        type: 'put',
        payload: {
          cv_id: cvId,
          description: values.hobbies
        }
      })

      if (response && response.status == 200) {
        showCvScore()
        navigate(`/profilim/cv/duzenle/${cvId}/hobiler`)
      } else {
        Swal.fire({
          icon: 'error',
          text: 'Hobiler kaydedilirken beklenmedik bir hata meydana geldi',
          title: 'Hata'
        })
      }
      // console.log(values);
      // Handle form submission here
    },
  });

  const [description, setDescription] = useState<string>('')


  async function getDescription() {
    const response = await axios.userAuthRequest({
      endpoint: `user/profilim/cv/aw/index?cv_id=${cvId}`,
      type: 'get',
      payload: {}
    })

    if (response && response.data && response.data.data) {
      setDescription(response.data.data)
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  useEffect(() => {
    getDescription()
  }, [])

  return (
    <div className="flex flex-col w-full gap-y-2 rounded-md bg-white shadow-md">
      <div className="flex lg:flex border-b border-mains py-4 flex-col gap-x-2 items-start ">
        <span className="font-normal m-0 pl-4 text-base lg:text-[16px] lg:font-medium leading-7 font-poppins lg:poppins-semibold lg:text-sm text-mains lg:pl-4 lg:col text-center flex justify-center lg:justify-start">
          {isEnglish? 'Achievements & Awards': 'Başarı & Ödül ve Burslar'}
        </span>
        <label className="poppins-semibold text-xs text-gray-500 mx-4">
          {isEnglish? 'Show companies your competencies and past achievements by adding the achievements, awards and scholarships sections to your resume.': 'Başarı, ödül ve burslar bölümünü özgeçmişine ekleyerek yetkinliklerini ve geçmiş başarılarını şirketlere göster.'}
        </label>
      </div>
      <form className="flex flex-col gap-2 p-6" onSubmit={formik.handleSubmit}>
        <div className="flex flex-col gap-1">
          <CKEditor
            editor={ClassicEditor}
            data={description}
            onChange={(event, editor) => {
              const data = editor.getData();
              formik.setFieldValue("hobbies", data);
            }}
            onBlur={() => formik.setFieldTouched("hobbies")}
          />
          <label className={`text-sm ${5000 - formik.values.hobbies.length < 0? 'text-red-600': 'text-gray-600'} font-poppins`}>{5000 - formik.values.hobbies.length}</label>
        </div>

        <hr/>
        <div className="flex flex-row items-center gap-2 flex-wrap justify-center lg:justify-between">
          <Button 
          type="button" 
          size="sm" 
          variant="secondary"
          onClick={() => navigate(`/profilim/cv/duzenle/${cvId}/seminer-kurs-egitimler`)}
          >
            <Button.Icon>
              <IoChevronBack/>
            </Button.Icon>
            {isEnglish? 'Go Back': 'Geri Dön'}
          </Button>
          <Button type="submit" size="sm" >
            {isEnglish? 'Save and Continue': 'Kaydet ve Devam Et'}
            <Button.Icon>
              <IoChevronForward/>
            </Button.Icon>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AchivementAward;
