import Axios from "../services/axios";
import { cdnCountryType } from "../types";

type getCountriesPropsType = {
  search?: string;
  limit?: number;
  only_ids?: number[];
  lang?: string;
};

const axios = new Axios();

export async function getCountries({
  limit,
  only_ids,
  search,
  lang,
}: getCountriesPropsType): Promise<cdnCountryType[]> {
  try {
    const response = await axios.request({
      endpoint: `cdn/get-countries?
      ${lang ? `lang=${lang}&` : ""}
            ${search ? `search=${search}&` : ""}
            ${limit ? `limit=${limit}&` : ""}
            ${only_ids ? `only_ids=${JSON.stringify(only_ids)}&` : ""}
            `,
      type: "get",
    });

    if (response && response.data && response.data.countries) {
      return JSON.parse(JSON.stringify(response.data.countries));
    } else {
      return [];
    }
  } catch (error) {
    console.log(`Countries fetch failed. Error: ${error}`);
    return [];
  }
}
