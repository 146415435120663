import { HTMLAttributes, createElement } from "react"
import { useContext } from "react"
import { MultiSelectDropdownContext, multiSelectDropdownContextType } from "."
import classNames from "classnames"





type itemsPropsType = HTMLAttributes<HTMLDivElement> & {

}






export default function Items({
    className,
    children,
    ...props
}: itemsPropsType) {

    const { isOpen }: multiSelectDropdownContextType = useContext(MultiSelectDropdownContext)

    const cn = classNames({
        'absolute -bottom-px left-0 border border-gray-400 rounded-md translate-y-full p-2 flex flex-col gap-2 w-full bg-white max-h-[200px] overflow-auto z-[1]': true
    })
    if (isOpen) {
        return createElement('div', {
            className: `${cn} ${className}`,
            ...props
        }, children)
    } else {
        return null
    }
}