import * as React from "react";
import { InputHTMLAttributes, useState, useEffect } from "react";
import classNames from "classnames";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  required?: boolean;
  className?: string;
  error?: boolean;
  onBlur?: () => void; // Add onBlur prop
}

export const CustomInput: React.FC<InputProps> = ({
  label,
  required = false,
  className,
  error = false,
  onBlur,
  ...rest
}) => {
  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [formattedValue, setFormattedValue] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    if (rest.type === "tel" && rest.value) {
      const cleanValue = (rest.value as string).replace(/\D/g, "");
      formatPhoneNumber(cleanValue);
    } else {
      setInputValue((rest.value as string) || "");
    }
  }, [rest.value, rest.type]);

  const formatPhoneNumber = (value: string) => {
    const cleaned = value.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{1,4})$/);
    if (match) {
      setInputValue(cleaned);
      setFormattedValue(`0(${match[1]})${match[2]}${match[3]}`);
    } else {
      setInputValue(cleaned);
      setFormattedValue(value);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (rest.type === "tel") {
      const numbers = e.target.value.replace(/[^\d]/g, "");
      formatPhoneNumber(numbers);
    } else {
      setInputValue(e.target.value);
    }
  };

  return (
    <div className="relative flex flex-col font-poppins ">
      <label
        className={` pointer-events-none text-xs poppins-medium items-center flex w-full mb-1 gap-x-1
				${className || ""}`}
        style={{ color: "#6B7280", border: "none" }}
        htmlFor={rest.id}
        onClick={() => document.getElementById(rest.id as string)?.focus()}
      >
        {label}{" "}
        {required && (
          <span className="text-base font-bold text-red-500 -top-1">*</span>
        )}
      </label>
      <input
        onFocus={() => setFocused(true)}
        onBlur={() => {
          setFocused(false);
          onBlur && onBlur();
        }}
        value={rest.type === "tel" ? formattedValue : inputValue}
        onChange={handleInputChange}
        {...rest}
        type={
          rest.type === "password" && passwordVisible
            ? "text"
            : rest.type === "password"
            ? "password"
            : rest.type === "tel"
            ? "number"
            : "text"
        }
        className={classNames(
          `${formattedValue || (inputValue && "text-mains")}`,
          "border rounded-[6px] text-sm border-gray-400 focus:outline-none p-2 px-4 focus:border-title w-full",
          className,
          {
            "bg-white border-gray-400": !error,
            "border-red-500 bg-red-100": error,
          }
        )}
        id={rest.id}
      />
      {rest.type === "password" && (
        <button
          type="button"
          className={classNames(
            "absolute right-0 flex flex-row items-center flex-shrink-0 gap-2 mx-2 text-xs font-[500] text-title font-poppins",
            {
              "top-[36px]": label && required,
              "top-9": !label && required,
              "top-7": label && !required,
              "top-3": !label && !required,
            }
          )}
          onClick={() => setPasswordVisible((prev) => !prev)}
        >
          {!passwordVisible ? (
            <>
              Göster
              <IoMdEye className="text-lg" />
            </>
          ) : (
            <>
              Gizle
              <IoMdEyeOff className="text-lg" />
            </>
          )}
        </button>
      )}
    </div>
  );
};
