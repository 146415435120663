import { CiMonitor } from "react-icons/ci";
import { FiBox } from "react-icons/fi";
import { MdMonitor } from "react-icons/md";
import { TbTable } from "react-icons/tb";












export default function FreeAdvertSection() {
    return(
        <div className="bg-white py-6">
            <div className="flex flex-col items-center gap-4 container mx-auto font-semibold px-4">
                <h3 className="text-2xl text-[#555555]">
                    ÜCRETSİZ İŞ İLANI VER
                </h3>
                <p className="text-[#555555] font-[300] text-justify leading-[1.75] font-poppins">
                    Teknolojinin ilerlemesi ve nüfusun artmasıyla birlikte çalışma hayatında da doğru orantıda bir hareketlenme başlamıştır. İş alanlarının artması, iş arayan ve işveren arasında bir köprü oluşturmuş ve bu anlamda yoğun bir arz talep durumu söz konusu olmuştur. Teknolojik ilerlemeler, iş arayışının ve işçi alımının eskiye nazaran daha kolay bir şekilde gerçekleşmesine olanak sağlayacak çalışmalar meydana getirmiştir. Bu doğrultuda bireyler özellikle internet ortamı üzerinden aradıkları işlere, işverenler de aradıkları elemanlara kolaylıkla ulaşmaya başlamıştır. Daha önceleri gazetelerin sarı sayfalarında iş ilanı yayınlayan firmalar artık internet ortamı üzerinden bu işlemi çok daha kolay bir şekilde gerçekleştirmektedir. İnternet ortamı için tasarlanarak iş arayan ve işverenin kullanımına sunulmuş olan çeşitli iş ilanı sitesi mevcuttur. Firmalar bu siteler üzerinden ücretsiz iş ilanı vermekte ve iş arayanlar da rahatlıkla bu ilanlara ulaşarak başvuruda bulunmaktadırlar. İsbul.net, bu alanda kurulmuş olan ve sunmuş olduğu olanaklar sayesinde iş arayan ve işveren için oldukça kullanışlı imkanlar sağlayan bir web sitesidir. Ücretsiz iş ilanı vermenin en kaliteli adresi olan isbul.net ile ücretsiz iş ilanları verebilir, dilediğiniz elemana hızlı bir şekilde ulaşabilirsiniz.
                </p>
                <div className="grid grid-cols-12 gap-8 lg:gap-16 lg:flex-row items-start mt-4">
                    <div className="col-span-12 lg:col-span-4 flex flex-col flex-shrink-0 basis-1/3 items-center gap-4">
                        <div className="aspect-square rounded-full flex-shrink-0 bg-[#d2e1ff] p-12">
                            <MdMonitor className="text-4xl text-title" />
                        </div>
                        <label className="text-title font-bold text-xl text-center">
                            ARADIĞINIZ ELEMANA KISA SÜREDE ULAŞIN
                        </label>
                        <p className=" text-[#555555] font-[300] text-justify leading-[1.75] font-poppins">
                            İsbul.net üzerinden ücretsiz iş ilanı verebilmek için öncelikle yapmanız gereken işlem &quot;Firma Girişi&quot; kısmından &quot;Üye Ol&quot; ya da &quot;Ücretsiz İlan Ver&quot; seçeneklerine tıklayarak firmanız adına üyelik oluşturmaktır. Daha sonra size otomatik olarak tanımlanmış olan 1 adet ücretsiz ilan hakkınız ile dilediğiniz pozisyonda ilan yayınlayabilirsiniz.
                        </p>
                    </div>
                    <div className="col-span-12 lg:col-span-4 flex flex-col flex-shrink-0 basis-1/3 items-center gap-4">
                        <div className="aspect-square rounded-full flex-shrink-0 bg-[#d2e1ff] p-12">
                            <TbTable className="text-4xl text-title" />
                        </div>
                        <label className="text-title font-bold text-xl text-center">
                            HER AY KESİNTİSİZ İLAN YAYINLAYIN
                        </label>
                        <p className=" text-[#555555] font-[300] text-justify leading-[1.75] font-poppins">
                            Sistemimizin size her ay otomatik olarak tanımladığı 1 adet ücretsiz ilan yayınlama hakkı&quot;nın yanı sıra gelen başvuruları ücretsiz görüntüleme hakkınız mevcuttur. Bu hakkınızla, ilanınıza başvuran tüm adayların iletişim bilgilerini ücretsiz olarak görüntüleyebilirsiniz.
                        </p>
                    </div>
                    <div className="col-span-12 lg:col-span-4 flex flex-col flex-shrink-0 basis-1/3 items-center gap-4">
                        <div className="aspect-square rounded-full flex-shrink-0 bg-[#d2e1ff] p-12">
                            <FiBox className="text-4xl text-title" />
                        </div>
                        <label className="text-title font-bold text-xl text-center">
                            UYGUN FİYATLI ÜRÜNLER
                        </label>
                        <p className=" text-[#555555] font-[300] text-justify leading-[1.75] font-poppins">
                            İkinci ya da üçüncü ilan yayınlamanız durumunda bu ilanlar için sizlerden belirli bir ücret talep edilecektir. Ürünlerimiz ve fiyatlandırmalar konusunda detaylı bilgi için ana sayfanızda yer alan &quot;Ürünlerimiz&quot; sayfasını ziyaret edebilirsiniz.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}