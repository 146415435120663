import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import parse, { domToReact } from "html-react-parser";
import { GoDotFill } from "react-icons/go";
import { BlogData } from "../../types";
import Axios from "../../../../services/axios";
import Swal from "sweetalert2";

function BlogDetail(): React.ReactElement {
  const axios = new Axios();
  const { slug } = useParams();
  const navigate = useNavigate();
  const [postTitle, setPostTitle] = React.useState<string>("");
  const [postContent, setPostContent] = React.useState<string>("");
  const [loading, setLoading] = React.useState(false);
  const [blogData, setBlogData] = React.useState<BlogData>();

  useEffect(() => {
    const fetchBlogData = async (blogSlug?: string) => {
      if (!blogSlug) return;
      setLoading(true);
      try {
        const response = await axios.request({
          type: "get",
          endpoint: "home/get-home-blogs",
        });
        const newData = response?.data?.blogs.map((blog: BlogData) => {
          const url = blog.url.slice(0, -1);
          const slug = url.split("/").pop();
          return { ...blog, post_name: slug };
        });

        const blog = newData.find((b: BlogData) => b.post_name === blogSlug);

        if (!blog) {
          Swal.fire({
            icon: "error",
            title: "Hata",
            text: "Blog bulunamadı",
          });
          navigate("/blog");
          return;
        }
        setPostTitle(blog.post_title);
        setPostContent(blog.post_content);
        setBlogData(newData);
      } catch (error: any) {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: error.response?.data?.error || "Bir hata oluştu",
        });
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogData(slug);
  }, [slug]);
  const cleanDetail = (content: string) => {
    // Remove empty <p> tags, &nbsp; entities, and empty <br> tags
    return content
      .replace(/<p>(&nbsp;|\s|<br>)*<\/p>/g, "")
      .replace(/<br\s*\/?>/g, "") // Remove empty <br> tags
      .replace(/&nbsp;/g, " ")
      .replace(/\s\s+/g, " ");
  };

  let pCount = 0;

  const options = {
    replace: (domNode: any) => {
      if (domNode.type === "tag") {
        switch (domNode.name) {
          case "h1":
            return (
              <h1 className="text-black poppins-semibold text-2xl my-4 text-justify">
                {domToReact(domNode.children)}
              </h1>
            );
          case "h2":
            return (
              <h2 className="text-black poppins-semibold text-2xl my-4 text-justify">
                {domToReact(domNode.children)}
              </h2>
            );
          case "h3":
            return (
              <h3 className="text-black poppins-semibold my-4 text-xl text-justify">
                {domToReact(domNode.children)}
              </h3>
            );
          case "p":
            pCount++;
            const marginBottomClass = pCount % 2 === 0 ? "mb-4" : "mb-1";
            const marginTopClass = pCount % 2 === 0 ? "mt-4" : "mt-1";
            return (
              <p className={`${marginBottomClass} ${marginTopClass}`}>
                {domToReact(domNode.children)}
              </p>
            );
          case "ul":
            return (
              <ul className="flex flex-col gap-y-2">
                {React.Children.toArray(
                  domNode.children
                    .filter((child: any) => child.name === "li")
                    .map((li: any, index: number) => (
                      <li key={index} className="flex items-center gap-x-2">
                        <GoDotFill size={14} />
                        {parse(
                          li.children
                            .map((child: any) => child.data || "")
                            .join("")
                        )}
                      </li>
                    ))
                )}
              </ul>
            );
          default:
            return undefined;
        }
      }
    },
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-96">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-mains"></div>
      </div>
    );
  }

  return (
    <div className="container flex flex-col gap-y-1 w-full mx-auto">
      {postTitle && (
        <h1 className="bg-white p-5 rounded-sm poppins-semibold shadow-sm text-mains">
          {postTitle}
        </h1>
      )}
      <div
        className="bg-white p-5 rounded-sm shadow-sm text-justify text-base poppins-regular,
        tracking-wide leading-5"
      >
        {postContent && (
          <div className="poppins-regular">
            {parse(cleanDetail(postContent), options)}
          </div>
        )}
      </div>
    </div>
  );
}

export default BlogDetail;
