import Axios from "../services/axios"
import { cdnUniversitiesType } from "../types"







type getUniversitiesPropsType = {
    search?: string,
    limit?: number,
    only_ids?: number[]
}





const axios = new Axios()


export async function getUniversities({
    limit,
    search,
    only_ids
}: getUniversitiesPropsType): Promise<cdnUniversitiesType[]> {
    try {
        const response = await axios.request({
            endpoint: `cdn/get-universities?
            ${search? `search=${search}&`: ''}
            ${limit? `limit=${limit}&`: ''}
            ${only_ids? `only_ids=${JSON.stringify(only_ids)}&`: ''}
            `,
            type: 'get'
        })

        if (response && response.data && response.data.universities) {
            return JSON.parse(JSON.stringify(response.data.universities))
        } else {
            return []
        }
    } catch (error) {
        console.log(`Universities fetch failed. Error: ${error}`)
        return []
    }
}