import React, { useEffect, useState } from "react";
import { FaHeart } from "react-icons/fa";
import { BsTrash3 } from "react-icons/bs";
import {
  MdOutlineNotificationsActive,
  MdOutlineWorkOutline,
} from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { CiCalendarDate } from "react-icons/ci";
import Swal from "sweetalert2";
import { Button, Modal, Checkbox, Select } from "antd";
import { BsArrowRightCircleFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import Axios from "../../../../../services/axios";
import { TbDotsVertical } from "react-icons/tb";
import { IoMdArrowDropup } from "react-icons/io";
import services from "../../../../../services/profile-services";
import { FiAlertCircle } from "react-icons/fi";

type JobData = {
  id: number;
  title: string;
  company: string;
  employmentType: string;
  location: string;
  date: string;
  applied: boolean;
  link: string;
};

type FollowedCompany = {
  id: number;
  company_id: number;
  name: string;
  slug: string;
  logo: string;
  send_emails: number;
  period: FollowedCompanyPeriodEnum;
  follow_date: string;
  is_have_not_viewed_adverts: boolean;
  not_viewed_advert_count: number;
};

const jobsDatas: JobData[] = [
  {
    id: 1,
    title: "Ön Muhasebe Personeli",
    company: "MADAŞ MÜHENDİSLİK",
    employmentType: "Tam Zamanlı",
    location: "İstanbul - Avrupa",
    date: "18.03.2024",
    applied: false,
    link: "is-ilanlari/on-muhasebe-personeli-156985",
  },
  {
    id: 2,
    title: "Front End Developer/ Web Arayüz Geliştirici",
    company: "Zeplin Creative",
    employmentType: "Yarı Zamanlı",
    location: "İstanbul - Anadolu",
    date: "17.03.2024",
    applied: false,
    link: "is-ilanlari/front-end-developer-web-arayuz-gelistirici-is-ilani-218402",
  },
  {
    id: 3,
    title: "Motorcu Aranıyorı",
    company: "Nimel Dergi",
    employmentType: "Tam Zamanlı",
    location: "İstanbul - Avrupa",
    date: "16.03.2024",
    applied: false,
    link: "is-ilanlari/motorcu-araniyor-is-ilani-187949",
  },
];

interface DataItem {
  user_id: number;
  favorite_id: number;
  advert_id: number;
  advert_title: string;
  advert_slug: string;
  company_logo: string;
  advert_status: string;
  company_detail: {
    company_name: string;
    company_id: number;
  };
  is_apply: boolean;
}

enum FollowedCompanyPeriodEnum {
  "DAILY" = "DAILY",
  "WEEKLY" = "WEEKLY",
  "MONTHLY" = "MONTHLY",
}
enum FollowedCompanyPeriod {
  "DAILY" = "Günlük",
  "WEEKLY" = "Haftalık",
  "MONTHLY" = "Aylık",
}

const SaveCompany: React.FC = () => {
  const axios = new Axios();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>("sponsored");
  const [savedJobs, setSavedJobs] = useState<JobData[]>(jobsDatas);
  const [selectedPeriod, setSelectedPeriod] =
    useState<FollowedCompanyPeriodEnum>(FollowedCompanyPeriodEnum.DAILY);
  const [followedCompanies, setFollowedCompanies] = useState<FollowedCompany[]>(
    []
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(false);
  const [hoveredCompany, setHoveredCompany] = useState<number | null>(null);
  const [data, setData] = useState<DataItem[]>([]);

  const showModal = (company: FollowedCompany) => {
    setIsCheckboxChecked(company.send_emails === 1);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSweetAlert = async () => {
    Swal.fire({
      icon: "success",
      text: "E-posta tercihiniz başarıyla güncellendi!",
      confirmButtonText: "Tamam",
      customClass: {
        confirmButton: "sweet-alert-button",
      },
    });

    handleCancel();
  };

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const handleDelete = (id: number) => {
    const confirmationText =
      activeTab === "sponsored"
        ? "Bu ilanı, kayıtlı ilanlar listesinden çıkartmak istediğinize emin misiniz?"
        : "Bu firmayı takip ettiğiniz firmalar listesinden çıkartmak istediğinize emin misiniz?";

    Swal.fire({
      title: "İşlemi Onaylayın",
      text: confirmationText,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "İptal",
      customClass: {
        confirmButton: "sweet-alert-button",
        cancelButton: "swal2-cancel",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (activeTab === "sponsored") {
          axios
            .userAuthRequest({
              endpoint: `user/profilim/favoriler/` + id,
              type: "delete",
              payload: {},
            })
            .then((el: any) => {
              Swal.fire({
                icon: "success",
                text: "İlan Başarıyla Kayıtlı İlanlarımdan Silindi!",
                confirmButtonText: "Tamam",
                customClass: {
                  confirmButton: "sweet-alert-button",
                },
              });
              getFavoriteJobs();
            });
        } else {
          axios
            .userAuthRequest({
              endpoint: `user/profilim/follow/delete/` + id,
              type: "delete",
              payload: {},
            })
            .then((el: any) => {
              Swal.fire({
                icon: "success",
                text: "Firma Başarıyla Takipten Çıkarıldı!",
                confirmButtonText: "Tamam",
                customClass: {
                  confirmButton: "sweet-alert-button",
                },
              });
              fetchFollowedCompanies();
            });
        }
      }
    });
  };

  const handleCheckboxChange = (e: any) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const getFavoriteJobs = async () => {
    try {
      const response = await services?.forMeJob();

      if (response && response.data && response.data.data) {
        setData(response.data.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error(
        "Kullanıcı erişim yetkisi kontrol edilirken bir hata oluştu:",
        error
      );
    }
  };
  const fetchFollowedCompanies = async () => {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/follow/get-follow-companies`,
        type: "get",
        payload: {},
      });

      setFollowedCompanies(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getFavoriteJobs();
    fetchFollowedCompanies();
  }, []);

  const handleUnFollowCompany = async (id: number) => {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/follow/delete/${id}`,
        type: "delete",
        payload: {},
      });

      const updatedCompanies = followedCompanies.filter(
        (company) => company.id !== id
      );
      setFollowedCompanies(updatedCompanies);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateFollowing = async (company: FollowedCompany) => {
    try {
      const response = await axios.request({
        type: "put",
        endpoint: "user/profilim/follow/update/" + company.id,
        payload: {
          send_email: isCheckboxChecked ? 1 : 0, // 0-1 (eğer 1 verilirse belirlennen perioda göre iş alalrm oluşturulmuş olur, eğer 0 verilirse sadecefiltre kayıt edilmiş olur)
          period: selectedPeriod,
        },
      });
      if (response.status) {
        setSelectedPeriod(selectedPeriod);
        setIsCheckboxChecked(false);
        fetchFollowedCompanies();
        handleSweetAlert();
      }
    } catch (error) {
      console.error("Error updating filter:", error);
    }
  };

  const truncate = (str: string, n: number) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <>
      <div className="flex flex-col w-full items-center shadow-lg bg-white rounded-md">
        <div className="w-full px-4 py-3 border-b justify-between flex">
          <div className="flex justify-between w-full md:mb-4">
            <button
              style={{
                textDecoration: "underline",
                textUnderlineOffset: "4px",
              }}
              className={`text-base lg:text-lg font-medium underline font-poppins ${
                activeTab === "sponsored"
                  ? "text-[#2253AF] underline"
                  : "text-gray-500"
              }`}
              onClick={() => handleTabClick("sponsored")}
            >
              Kayıtlı İlanlarım
            </button>
            <button
              style={{
                textDecoration: "underline",
                textUnderlineOffset: "4px",
              }}
              className={`text-base lg:text-lg font-medium underline font-poppins ${
                activeTab === "notSponsored"
                  ? "text-[#2253AF] underline"
                  : "text-gray-500"
              }`}
              onClick={() => handleTabClick("notSponsored")}
            >
              Takip Ettiğim Firmalar
            </button>
          </div>
        </div>

        <div
          className={`flex w-full flex-col-reverse ${
            activeTab === "sponsored" ? "w-full" : "hidden"
          } flex-col gap-y-2 h-auto`}
        >
          {data.length > 0 && (
            <div className="flex flex-col gap-y-4 px-4 py-3 w-full">
              {data?.map((job) => (
                <div
                  onClick={() => {
                    navigate("/is-ilanlari/" + job.advert_slug);
                  }}
                  key={job.favorite_id}
                  className="w-full bg-gray-200 px-4 h-32 flex gap-x-4 py-3 cursor-pointer"
                >
                  <div className="w-2/12 lg:w-1/12 h-full flex items-center justify-center">
                    <img
                      className="w-full object-cover rounded-full md:rounded-none"
                      src={
                        job.company_logo ||
                        "https://isbull.s3.eu-north-1.amazonaws.com/default-images/company-logo.png"
                      }
                      alt="Company Logo"
                    />
                  </div>

                  <div className="w-10/12 lg:w-11/12 h-full">
                    <div className="flex flex-col h-full justify-center gap-y-3">
                      <div className="flex flex-col gap-y-1 w-full">
                        <div className="w-full">
                          <a
                            href={`/firma/${job.company_detail.company_id}`}
                            className="text-md poppins-semibold text-mains line-clamp-1 line-clamp-1"
                          >
                            {job.advert_title}
                          </a>
                        </div>

                        <div className="w-full flex items-center justify-between">
                          <div className="flex gap-x-2">
                            <span className="text-xs poppins-semibold text-gray-500 line-clamp-1 ">
                              {job.company_detail.company_name}
                            </span>
                          </div>
                          <button
                            onClick={(e) => {
                              handleDelete(job.favorite_id);
                              e.stopPropagation();
                            }}
                            className="lg:block hidden"
                          >
                            <FaHeart className="text-red-500 text-sm" />
                          </button>
                        </div>

                        <div className="flex justify-end items-center gap-4 md:hidden pt-2">
                          {!job.is_apply ? (
                            <Link
                              to={`/is-ilanlari/${job.advert_slug}`}
                              className="px-4 py-2 bg-[#2553AA] text-white font-poppins text-xs rounded-lg"
                            >
                              Başvur
                            </Link>
                          ) : (
                            <Link
                              to={`/is-ilanlari/${job.advert_slug}`}
                              className="px-4 py-2 bg-[#2553AA] text-white font-poppins text-xs rounded-lg"
                            >
                              Başvuruldu
                            </Link>
                          )}

                          <button
                            onClick={(e) => {
                              handleDelete(job.favorite_id);
                              e.stopPropagation();
                            }}
                          >
                            <BsTrash3 className="text-red-500 text-sm" />
                          </button>
                        </div>
                      </div>

                      <div className="w-full hidden md:flex justify-between">
                        {/*<div className="flex gap-x-2 items-center w-full">*/}
                        {/*  <MdOutlineWorkOutline className="text-md text-gray-500" />*/}
                        {/*  <span className="text-xs poppins-medium text-gray-500">*/}
                        {/*    {job.advert_status}*/}
                        {/*  </span>*/}
                        {/*</div>*/}

                        {/*<div className="flex gap-x-2 items-center w-full">*/}
                        {/*  <IoLocationOutline className="text-md text-gray-500" />*/}
                        {/*  <span className="text-xs poppins-medium text-gray-500">*/}
                        {/*    {job.advert_slug}*/}
                        {/*  </span>*/}
                        {/*</div>*/}

                        {/*<div className="flex gap-x-2 items-center justify-end w-full">*/}
                        {/*  <CiCalendarDate className="text-md text-gray-500" />*/}
                        {/*  <span className="text-xs poppins-medium text-gray-500">*/}
                        {/*    {job.company_logo}*/}
                        {/*  </span>*/}
                        {/*</div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {data.length === 0 && (
            <div className="flex items-stretch mt-4 mb-9 border border-gray-300 bg-white rounded-md w-full">
              <span className="flex items-center justify-center bg-red-500 text-white p-3 rounded-l-md">
                <FiAlertCircle size={35} />
              </span>
              <p className="flex flex-col sm:flex-row justify-start items-center font-poppins text-sm poppins-medium p-4 whitespace-nowrap">
                Kayıtlı ilan bulunamadı!{" "}
                <a
                  href="/is-ilanlari"
                  className="text-blue-500 underline ml-1 w-full text-start whitespace-nowrap"
                  style={{
                    textDecoration: "underline",
                    textUnderlineOffset: "4px",
                  }}
                >
                  İş İlanlarını İncele
                </a>
              </p>
            </div>
          )}
        </div>

        <div
          className={`flex w-full flex-col-reverse ${
            activeTab === "notSponsored" ? "w-full" : "hidden"
          } flex-col gap-y-2 h-auto`}
        >
          {followedCompanies.length > 0 && (
            <div className="flex flex-col gap-y-4 px-4 py-3 w-full">
              {followedCompanies?.map((company) => (
                <>
                  <div
                    key={company?.id + "pc"}
                    className="w-full bg-gray-200 px-4 h-32 md:flex hidden gap-x-4 py-3 "
                  >
                    <div className="h-full flex items-center justify-between w-full">
                      <img
                        className="w-full object-cover rounded-full md:rounded-none max-w-[60px] max-h-[70px]"
                        src={company.logo}
                        alt="Company Logo"
                      />

                      <div className="w-full ml-4">
                        <span className="text-md poppins-semibold text-mains hidden xl:block whitespace-nowrap">
                          {truncate(company?.name, 25)}
                        </span>
                        <span className="text-md poppins-semibold text-mains xl:hidden block whitespace-nowrap">
                          {truncate(company?.name, 15)}
                        </span>
                      </div>

                      <div className="flex gap-x-2 items-center justify-center w-full">
                        <CiCalendarDate className="text-md text-gray-500" />
                        <span className="text-xs poppins-medium text-gray-500">
                          {new Date(company.follow_date).toLocaleDateString(
                            "tr-TR"
                          )}
                        </span>
                      </div>

                      <div
                        onClick={() => showModal(company)}
                        className="text-gray-600 font-poppins text-xs underline flex justify-center items-center gap-1 cursor-pointer w-full whitespace-nowrap"
                      >
                        <MdOutlineNotificationsActive className="text-gray-600 text-sm" />
                        {company.send_emails === 1
                          ? `${
                              FollowedCompanyPeriod[company.period]
                            } E-posta almak istiyorum`
                          : "E-posta almak istemiyorum"}
                      </div>

                      <div
                        className="relative w-full flex justify-end items-center max-w-[40px] xl:max-w-[80px]"
                        onMouseEnter={() => setHoveredCompany(company.id)}
                        onMouseLeave={() => setHoveredCompany(null)}
                      >
                        <TbDotsVertical className="text-mains text-2xl cursor-pointer" />
                        {hoveredCompany === company.id && (
                          <div
                            className="absolute -right-2 top-6 mt-2 w-40 bg-white border border-gray-200 rounded-md shadow-lg z-10 p-2"
                            onMouseEnter={() => setHoveredCompany(company.id)}
                            onMouseLeave={() => setHoveredCompany(null)}
                          >
                            <IoMdArrowDropup className="text-white text-5xl absolute top-[-26px] -right-[4px]" />

                            <div
                              className="px-4 py-2 text-sm hover:bg-mains hover:text-white cursor-pointer border text-mains duration-300 rounded"
                              onClick={() => {
                                handleDelete(company.id);
                              }}
                            >
                              Takipten Çıkar
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    key={company?.id + "mb"}
                    className="w-full bg-gray-200 px-4 h-32 flex gap-x-4 py-3 md:hidden"
                  >
                    <div className="w-2/12 lg:w-1/12 h-full flex items-center justify-center">
                      <img
                        className="w-full object-cover rounded-full md:rounded-none"
                        src={company.logo}
                        alt="Company Logo"
                      />
                    </div>

                    <div className="w-10/12 lg:w-11/12 h-full">
                      <div className="flex flex-col h-full justify-center gap-y-3">
                        <div className="flex flex-col gap-y-1 w-full">
                          <div className="w-full">
                            <span className="text-md poppins-semibold text-mains hidden md:block">
                              {company?.name}
                            </span>
                          </div>

                          <div className="w-full flex items-center justify-between">
                            <div className="flex gap-x-2">
                              <div className="flex md:flex-row flex-col">
                                <span className="text-base md:text-xs poppins-light text-mains md:text-gray-500 poppins-semibold">
                                  {company?.name}
                                </span>
                                <span className="text-gray-500 font-poppins text-xs md:hidden">
                                  {new Date(
                                    company.follow_date
                                  ).toLocaleDateString("tr-TR")}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-between items-center gap-4 mt-3">
                            <span
                              onClick={() => showModal(company)}
                              className="text-gray-600 font-poppins text-xs underline flex justify-center items-start gap-1 cursor-pointer"
                            >
                              <MdOutlineNotificationsActive className="text-gray-600 text-sm mt-[2px]" />
                              {company.send_emails === 1
                                ? `${
                                    FollowedCompanyPeriod[company.period]
                                  } E-posta almak istiyorum`
                                : "E-posta almak istemiyorum"}
                            </span>
                            <button
                              className="md:hidden"
                              onClick={() => handleDelete(company?.id)}
                            >
                              <BsTrash3 className="text-gray-600 text-sm" />
                            </button>
                          </div>
                        </div>

                        <div className="w-full hidden md:flex justify-between">
                          <div className="flex gap-x-2 items-center justify-end w-full">
                            <CiCalendarDate className="text-md text-gray-500" />
                            <span className="text-xs poppins-medium text-gray-500">
                              {new Date(company.follow_date).toLocaleDateString(
                                "tr-TR"
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Modal
                    title={
                      <span style={{ textTransform: "none" }}>
                        E-posta Tercihini Güncelle
                      </span>
                    }
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={[
                      <div className="w-full flex justify-end border-t border-color-[#dee2e6] px-[24px] py-[12px] gap-4">
                        <Button
                          className="bg-gray-200 text-black font-poppins text-sm"
                          key="Kapat"
                          onClick={handleCancel}
                        >
                          Kapat
                        </Button>
                        <Button
                          className="bg-[#2253af] text-white font-poppins text-sm"
                          key="Kaydet"
                          onClick={() => handleUpdateFollowing(company)}
                        >
                          Kaydet
                        </Button>
                      </div>,
                    ]}
                  >
                    <div className="space-y-4">
                      <div>
                        <p className="text-sm font-poppins font-normal text-black">
                          İlgili firmalara ait yeni iş ilanlarını e-posta ile
                          alabilirsiniz.
                        </p>
                      </div>

                      <div>
                        <Checkbox
                          checked={isCheckboxChecked}
                          onChange={handleCheckboxChange}
                        >
                          Uygun iş ilanlarını e-posta olarak gönder.
                        </Checkbox>
                      </div>

                      {isCheckboxChecked && (
                        <div>
                          <p className="text-gray-400 font-poppins text-sm mb-[2px]">
                            E-posta Gönderim Sıklığı{" "}
                            <span className="text-red-500">*</span>
                          </p>
                          <Select
                            defaultValue={company.period}
                            className="w-full"
                            onChange={setSelectedPeriod}
                            style={{ height: "40px" }}
                            options={[
                              {
                                value: FollowedCompanyPeriodEnum.DAILY,
                                label: "Günlük E-posta Gönder",
                              },
                              {
                                value: FollowedCompanyPeriodEnum.WEEKLY,
                                label: "Haftalık E-posta Gönder",
                              },
                              {
                                value: FollowedCompanyPeriodEnum.MONTHLY,
                                label: "Aylık E-posta Gönder",
                              },
                            ]}
                          />
                        </div>
                      )}
                    </div>
                  </Modal>
                </>
              ))}
            </div>
          )}
          {followedCompanies.length === 0 && (
            <div className="flex items-stretch mt-4 mb-9 border border-gray-300 bg-white rounded-md w-full">
              <span className="flex items-center justify-center bg-red-500 text-white p-3 rounded-l-md">
                <FiAlertCircle size={35} />
              </span>
              <p className="flex flex-col sm:flex-row justify-start items-center font-poppins text-sm poppins-medium p-4 whitespace-nowrap">
                Takip ettiğiniz firma bulunamadı.{" "}
                <a
                  className="text-blue-500 underline ml-1 w-full text-start whitespace-nowrap"
                  href="/is-ilanlari"
                  style={{
                    textDecoration: "underline",
                    textUnderlineOffset: "4px",
                  }}
                >
                  İş İlanlarını İncele
                </a>
              </p>
            </div>
          )}
        </div>
      </div>

      {data.length > 0 && (
        <button
          onClick={() => {
            if (activeTab === "sponsored")
              navigate("/profilim/favoriler/liste");
            else navigate("/profilim/follow");
          }}
          className="w-full flex justify-end items-center pr-8 font-poppins poppins-medium underline text-[13px] text-gray-500"
        >
          <span>Tümünü Gör</span>
          <BsArrowRightCircleFill className="text-mains text-lg ml-2" />
        </button>
      )}
    </>
  );
};

export default SaveCompany;
