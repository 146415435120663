import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Axios from "../../../../../services/axios";
import { Link } from "react-router-dom";
import { BlogData } from "../../../../blog-page-container/types";

const Guide: React.FC = () => {
  const axios = new Axios();

  const [blogData, setBlogData] = useState<BlogData[]>([]);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await axios.request({
          type: "get",
          endpoint: "home/get-home-blogs",
        });
        // exatract slug from url  "url": "https:\/\/blog.isbul.net\/dil-bolumu-secmeden-once-bilmeniz-gerekenler\/"
        const newData = response?.data?.blogs.map((blog: BlogData) => {
          const url = blog.url.slice(0, -1);
          const slug = url.split("/").pop();
          return { ...blog, post_name: slug };
        });
        setBlogData(newData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchBlogData();
  }, []);

  // const blogData = [
  //   {
  //     id: 1,
  //     title: "Sosyal Medyayı Kariyerimiz için Nasıl Kullanabiliriz?",
  //     url: "#",
  //     image:
  //       "https://blog.isbul.net/wp-content/uploads/2022/11/e8aeedc2-3f73-43ad-b953-7a579204b9af-0bCHL0-696x284.png",
  //     description:
  //       "Sosyal medyanın kariyer için kullanımı hakkında bazı ipuçları.",
  //   },
  //   {
  //     id: 2,
  //     title: "Sosyal Medyayı Kariyerimiz için Nasıl Kullanabiliriz?",
  //     url: "#",
  //     image:
  //       "https://blog.isbul.net/wp-content/uploads/2022/11/e8aeedc2-3f73-43ad-b953-7a579204b9af-0bCHL0-696x284.png",
  //     description:
  //       "Sosyal medyanın kariyer için kullanımı hakkında bazı ipuçları.",
  //   },
  //   {
  //     id: 3,
  //     title: "Sosyal Medyayı Kariyerimiz için Nasıl Kullanabiliriz?",
  //     url: "#",
  //     image:
  //       "https://blog.isbul.net/wp-content/uploads/2022/11/e8aeedc2-3f73-43ad-b953-7a579204b9af-0bCHL0-696x284.png",
  //     description:
  //       "Sosyal medyanın kariyer için kullanımı hakkında bazı ipuçları.",
  //   },
  //   {
  //     id: 4,
  //     title: "Sosyal Medyayı Kariyerimiz için Nasıl Kullanabiliriz?",
  //     url: "#",
  //     image:
  //       "https://blog.isbul.net/wp-content/uploads/2022/11/e8aeedc2-3f73-43ad-b953-7a579204b9af-0bCHL0-696x284.png",
  //     description:
  //       "Sosyal medyanın kariyer için kullanımı hakkında bazı ipuçları.",
  //   },
  // ];

  const truncate = (text: string, length: number) => {
    return text?.length > length ? text?.slice(0, length) + "..." : text;
  };

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1278,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          dots: true,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  console.log(blogData);

  return (
    <div className="bg-gray-100 h-auto cursor-pointer mx-auto container">
      <div className="w-full md:py-10 py-5 md:container md:px-4 md:mx-auto">
        <div className="flex flex-col gap-y-6 md:gap-y-5">
          <div className="flex items-center justify-start">
            <h2 className="text-2xl poppins-semibold text-blue-900 ml-4">
              İŞ REHBERİ
            </h2>
          </div>
          {/* React Slick Slider */}
          <Slider {...settings}>
            {blogData?.map((blog, index) => (
              <Link
                key={index}
                to={`/kariyer-rehberi/${blog?.post_name}`}
                className="bg-white flex flex-col rounded-lg shadow-sm justify-center min-h-[207px] md:min-h-[233px] p-2"
              >
                <div className="flex w-full h-full justify-center items-center">
                  <img
                    alt={blog?.post_title}
                    className="object-cover object-center rounded-lg w-full h-full"
                    src={blog?.guid}
                  />
                </div>

                <div className="flex flex-col justify-between mt-2 md:mt-4 ">
                  <a className="w-full" href="#">
                    <h3 className="poppins-medium text-[13px]  md:text-base md:block hidden">
                      {truncate(blog?.post_title, 40)}
                    </h3>
                    <h3 className="poppins-medium text-[13px] md:text-base block md:hidden">
                      {truncate(blog?.post_title, 30)}
                    </h3>
                  </a>

                  <span className="flex items-end justify-end md:mt-2 pr-2">
                    <a
                      href="#"
                      target="_blank"
                      className="p-2 bg-[#2253af] rounded-md flex justify-center items-center mt-3"
                    >
                      <span className="text-white poppins-medium text-[11px] tracking-wider">
                        Devamı...
                      </span>
                    </a>
                  </span>
                </div>
              </Link>
            ))}
          </Slider>
          <Link
            to="/kariyer-rehberi"
            className="text-sm md:text-lg text-[#2253af] font-medium underline underline-offset-2 text-right mr-2"
          >
            Tümünü Gör
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Guide;
