import React, { useState, useEffect } from "react";
import AxiosService from "../../../../../services/axios";
import ApplicationCard from "../../../../../components/common/application-card";

const JobPost: React.FC = () => {
  const [notSponsorAdverts, setNotSoponserAdverts] = useState<any[]>([]);
  const [sponsorAdverts, setSoponserAdverts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState("sponsored");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const axiosService = new AxiosService();
        const response1 = await axiosService.request({
          type: "get",
          endpoint: "home/get-last-not-doping-adverts",
          payload: "",
        });
        if (response1.data) {
          setNotSoponserAdverts(response1.data.adverts);
        }
        const response2 = await axiosService.request({
          type: "get",
          endpoint: "home/get-doping-adverts?limit=5",
          payload: "",
        });
        if (response2.data) {
          const resSponsoredAdverts = response2.data.adverts.map(
            ({ ...others }) => {
              return {
                workingTypes: others.working_types,
                ...others,
              };
            }
          );
          // console.log("RRR",resSponsoredAdverts)
          setSoponserAdverts(resSponsoredAdverts);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching adverts:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="bg-gray-100 md:block lg:block xl:block h-auto lg:px-0 px-3">
        <div className="w-full py-10 lg:pb-10 lg:py-0 h-auto sm:container mx-auto">
          <div className="bg-white w-full h-24 md:h-32 flex justify-center items-center mb-10 container mx-auto">
            <p className="text-blue-400 text-lg w-full text-center ">
              REKLAM ALANI
            </p>
          </div>

          <div className="flex flex-col lg:flex-row gap-x-6 gap-y-2 lg:gap-y-0">
            {/* Tab Navigation for small screens */}
            <div className="md:hidden flex justify-between w-full md:mb-4">
              <button
                className={`text-sm md:text-xl font-medium ${
                  activeTab === "sponsored" ? "text-blue-500" : "text-gray-500"
                }`}
                onClick={() => handleTabClick("sponsored")}
              >
                SPONSORLU İŞ İLANLARI
              </button>
              <button
                className={`text-sm md:text-xl font-medium ${
                  activeTab === "notSponsored"
                    ? "text-blue-500"
                    : "text-gray-500"
                }`}
                onClick={() => handleTabClick("notSponsored")}
              >
              {/* SON İŞ İLANLARI 2  */}
              </button>
            </div>

            {/* Content for SON İŞ İLANLARI */}

            <div
              className={`flex w-full  ${
                activeTab === "sponsored" || window.innerWidth >= 768
                  ? "lg:w-1/2"
                  : "hidden"
              } md:flex-col flex-col-reverse gap-y-2 h-auto`}
            >
              <div className="flex justify-end md:justify-between items-center w-full gap-2">
                <h2 className="hidden md:inline-block poppins-medium md:text-2xl text-sm text-mains bottom-border-highlight ml-1">
                  SPONSORLU İŞ İLANLARI
                </h2>
                <a
                  target="blank"
                  href="is-ilanlari"
                  className="bg-white shadow pl-2 pr-2 inline-block rounded"
                >
                  Tümü
                </a>
              </div>
              <div className="w-full flex flex-col gap-y-1 md:gap-y-4 bg-white px-2 py-2">
                {sponsorAdverts.slice(-5).map((advert, index) => (
                  <ApplicationCard
                    key={index}
                    data={advert}
                    isSponsored={true}
                    setAdverts={setSoponserAdverts} // Provide setAdverts prop
                  />
                ))}
              </div>
            </div>
            <button
                className={`text-sm text-start md:text-xl font-medium  md:hidden ${
                  activeTab === "sponsored" ? "text-blue-500" : "text-gray-500"
                }`}
                // onClick={() => handleTabClick("sponsored")}
              >
                SON İŞ İLANLARI
              </button>
            <div
              className={`flex w-full ${
                activeTab === "notSponsored" || window.innerWidth >= 768
                  ? "lg:w-1/2"
                  : ""
              } md:flex-col flex-col-reverse gap-y-2 h-auto`}
            >
              <div className="flex justify-end md:justify-between items-center w-full gap-2 mt-4 lg:mt-0">
                <h2 className="hidden  md:inline-block poppins-medium md:text-2xl text-sm text-mains bottom-border-highlight ml-1">
                  SON İŞ İLANLARI
                </h2>
                <a
                  target="blank"
                  href="is-ilanlari"
                  className="bg-white shadow pl-2 pr-2 inline-block rounded"
                >
                  Tümü
                </a>
              </div>
              <div className="w-full flex flex-col gap-y-1 md:gap-y-4 bg-white px-2 py-2 pt-1">
                {notSponsorAdverts.slice(-5).sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()).map((advert, index) => (
                  <ApplicationCard
                    key={index}
                    data={advert}
                    isSponsored={false}
                    setAdverts={setNotSoponserAdverts} // Provide setAdverts prop
                  />
                ))}
              </div>
            </div>

            {/* <div className='flex justify-end items-center bg-[#F2F4F5]'>
              <a className='underline' href="/is-ilanlari">Tümü</a>
            </div> */}

            {/* Content for SPONSORLU İŞ İLANLARI */}
          </div>
        </div>
      </div>
    </>
  );
};

export default JobPost;
