import React, { useState, useEffect, SetStateAction } from "react";
import { BasicsSection } from "../components/basics-section";
import { ButtonGroupSection } from "../components/button-group-section";
import { IItem } from "../../types";
import { useLocation } from "react-router-dom";
import { FaAngleUp } from "react-icons/fa";
import { DetailsSectionMobile } from "../components/details-section-mobile";

interface PageProps {
  isApplyPage?: boolean;
}
const AccordionContentMobile: React.FC<PageProps> = ({
  isApplyPage = false,
}: PageProps) => {
  return (
    <div
      className={`flex justify-center  ${isApplyPage ? "" : "bg-[#194599]"}`}
    >
      <div className={"w-full max-w-[1200px] "}>
        <section
          className={`rounded-md md:rounded-b-md bg-white mx-2 md:mx-10 mt-1 `}
        >
          <DetailsSectionMobile />

          <ButtonGroupSection />
        </section>
      </div>
    </div>
  );
};

export { AccordionContentMobile };
