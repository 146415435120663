import { IoCheckmark, IoCheckmarkCircle } from "react-icons/io5";
import { IItem, ITitle } from "../../../../types";
import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { LuLoader2 } from "react-icons/lu";
import { FaAngleUp, FaTimes } from "react-icons/fa";

export default function MultiSelect({
  disabled,
  data,
  label,
  blue = false,
  placeholder = "Seçiniz",
  onChange,
  selectedValues,
  setSearchData,
  searchData = "",
  isFetching,
  keyString,
}: {
  data: any;
  label: string;
  placeholder?: string;
  blue?: boolean;
  onChange?: (value: number[]) => void;
  selectedValues: number[];
  setSearchData?: React.Dispatch<SetStateAction<string>>;
  searchData?: string;
  disabled?: boolean;
  isFetching?: boolean;
  keyString: string;
}) {
  const divRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [localSelected, setLocalSelected] = useState<any>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isInitilized, setIsInitilized] = useState<boolean>(false);

  const openDropdown = () => {
    setIsOpen(true);
    if (inputRef.current) {
      // inputRef.current.value="";
      inputRef.current.focus();
    }
  };

  const toggleDropdown = () => {
    if (inputRef.current && !isOpen) {
      inputRef.current.focus();
    }
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClearButton = () => {
    setSearchData && setSearchData("");
    onChange && onChange([]);
  };

  useEffect(() => {
    if (selectedValues.length === 0) {
      setLocalSelected([]);
    }
  }, [selectedValues]);

  useEffect(() => {
    if (!isInitilized) {
      setLocalSelected(
        data.filter((el: any) => {
          return selectedValues.indexOf(el.id) !== -1;
        })
      );
      if (data.length > 0) {
        setIsInitilized(true);
      }
    }
  }, [data]);

  function inputKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Backspace") {
      if (searchData?.length === 0) {
        e.preventDefault();
        if (selectedValues.length > 1) {
          let newValues = [...selectedValues];
          newValues.pop();
          onChange && onChange(newValues);
        } else if (selectedValues.length == 1) {
          onChange && onChange([]);
        }
      }
    }
  }

  return (
    <div ref={divRef} className="relative w-full">
      <p className="text-gray-600 text-sm md:text-base">{label}</p>
      <div
        className={` ${
          blue || (isOpen && !disabled)
            ? "border-primary200 text-primary200"
            : "border-gray-400"
        }
				${selectedValues.length > 0 ? "text-primary200  border-mains" : "text-gray-600"}
				bg-white border rounded-md text-sm md:text-base shadow-sm px-4 py-2 w-full flex justify-between items-center text-left`}
        onClick={() => openDropdown()}
      >
        {selectedValues!.length > 0 && (
          <label className="whitespace-nowrap mr-2">
            {localSelected.length > 0 ? localSelected[0][keyString] : ""}
            {selectedValues.length > 1 && ` (+${selectedValues.length - 1})`}
          </label>
        )}
        <input
          ref={inputRef}
          className="outline-none rounded-md placeholder:text-gray-600 w-full text-ellipsis"
          placeholder={selectedValues.length > 0 ? "" : "Seçiniz"}
          onKeyDown={(e) => inputKeydown(e)}
          value={searchData}
          onChange={(e) => setSearchData && setSearchData(e.target.value)}
        />

        {isFetching && <LuLoader2 className={"animate-spin"}></LuLoader2>}
        <div
          className={`flex transition-all ${
            selectedValues.length > 0
              ? "w-8 justify-between"
              : "w-4 justify-end"
          }`}
        >
          <FaTimes
            onClick={(e) => {
              e.stopPropagation();
              handleClearButton();
            }}
            className={`transition-transform fade duration-300 transform cursor-pointer  ${
              selectedValues.length > 0 ? "flex" : "hidden"
            }`}
          />
          <FaAngleUp
            onClick={(e) => {
              e.stopPropagation();
              toggleDropdown();
            }}
            className={`transition-transform duration-300 transform ${
              !isOpen ? "rotate-180" : ""
            }`}
          />
        </div>
      </div>
      {isOpen && (
        <div className="absolute flex flex-col bottom-0 left-0 w-full border border-gray-600 rounded-md p-2 translate-y-full gap-2 max-h-[200px] overflow-auto bg-white shadow z-40">
          {data.map((value: any) => {
            const isSelectedCheck = selectedValues.find((v) => v === value.id);
            return (
              <button
                key={`multiSelect${value.id}`}
                type="button"
                className="flex flex-row items-center gap-2 p-2 group hover:bg-title transition-all"
                onClick={() => {
                  let newValues = [...selectedValues];
                  setSearchData && setSearchData("");
                  if (isSelectedCheck) {
                    setLocalSelected((prevState: any) =>
                      prevState.filter((v: any) => v.id !== value.id)
                    );
                    newValues.splice(newValues.indexOf(value.id), 1);
                    onChange && onChange(newValues);
                  } else {
                    setLocalSelected((prevState: any) => {
                      return [
                        ...prevState,
                        { id: value.id, [keyString]: value[keyString] },
                      ];
                    });
                    newValues.push(value.id);
                    onChange && onChange(newValues);
                  }
                }}
              >
                <div
                  className={`w-6 h-6 aspect-square flex-shrink-0 flex items-center justify-center border-2 ${
                    isSelectedCheck ? "bg-title" : "bg-transparent"
                  } border-title rounded-full group-hover:border-white transition-all`}
                >
                  {isSelectedCheck && <IoCheckmark className="text-white" />}
                </div>
                <label className="cursor-pointer text-gray-600 group-hover:text-white transition-all">
                  {value[keyString]}
                </label>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}
