import { useEffect, useRef, useState } from "react";
import { Button, SelectSearchDropdown } from "../../../../../../components/isbul-ui";
import { cdnSkillType, dbUserCompetenceType } from "../../../../../../types";
import { getSkills } from "../../../../../../customHooks/getSkills";
import { IoChevronDown, IoClose } from "react-icons/io5";
import Axios from "../../../../../../services/axios";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { CvLayoutContext, cvLayoutContextType } from "../..";
import Swal from "sweetalert2";





type skillType = {
    id: number,
    name: string,
    name_en: string
}


const axios = new Axios()


export default function SkillsForm({
    skills: initialSkills
}: {
    skills: dbUserCompetenceType[]
}) {

    const { showCvScore, isEnglish }: cvLayoutContextType = useContext(CvLayoutContext)


    const params = useParams()
    const cvId = params.slug


    const divRef = useRef<HTMLDivElement>(null)


    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [skills, setSkills] = useState<skillType[]>(initialSkills.map((s) => {
        return {
            id: s.id,
            name: s.name,
            name_en: s.name
        }
    }))

    const [skillList, setSkillList] = useState<cdnSkillType[]>([])

    const [searchTerm, setSearchTerm] = useState<string>('')

    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        setSkills(initialSkills.map((s) => {
            return {
                id: s.id,
                name: s.name,
                name_en: s.name
            }
        }))
    }, [initialSkills])

    /* Search skill by name */
    useEffect(() => {
        async function search() {
            const response = await getSkills({ search: searchTerm })
            setSkillList(response)
        }

        const timeOut = setTimeout(search, 500)

        return () => {
            clearTimeout(timeOut)
        }
    }, [searchTerm])

    /* Handle click outside of select items */
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          if (
            divRef.current &&
            !divRef.current.contains(event.target as Node)
          ) {
            setIsOpen(false)
          }
        }
    
        document.addEventListener("mousedown", handleClickOutside)
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside)
        }
      }, [divRef])
    return(
        <div className="flex flex-col gap-2 py-2 px-4">
            <div ref={divRef} className="flex flex-col relative">
                <div  className="flex flex-row items-start" onClick={() => setIsOpen((prev) => !prev)}>
                    <div className="flex flex-row gap-1 items-start flex-wrap gap-x-2">

                        {/* All skills map */}
                        {skills.map((s) => {
                            return(
                                <div
                                key={`skillSelected${s.id}`}
                                className="flex flex-row items-center gap-2 text-xs bg-mains text-white font-poppins px-2 py-1 rounded-full"
                                >
                                    <label className="line-clamp-1">
                                        {isEnglish? s.name_en: s.name}
                                    </label>
                                    <button 
                                    type="button"
                                    className="text-white"
                                    onClick={() => {

                                        /* Removing skill */
                                        setSkills((prev) => [...prev.filter((p) => p.name != s.name)])
                                        setIsOpen((prev) => !prev)
                                    }}
                                    >
                                        <IoClose/>
                                    </button>
                                </div>
                            )
                        })}

                        {/* Skill search input */}
                        <input
                        className="outline-none py-2 px-4 h-8 relative items-center justify-center bg-gray-100 border border-gray-200 rounded-full"
                        placeholder={`${isEnglish? '+ Add Skill': "+ Yetenek Ekle"}`}
                        value={searchTerm || ''}
                        onChange={((e) => setSearchTerm(e.target.value))}
                        />
                    </div>
                    <div className="ml-auto">
                        <IoChevronDown className={`text-xl ${isOpen? 'rotate-180': ''} transition-all`}/>
                    </div>
                </div>
                {isOpen && (
                    <div className="absolute bottom-0 left-0 w-full translate-y-full max-h-[200px] overflow-auto rounded-md border border-gray-200 bg-white p-2 flex flex-col shadow-md">
                        {skillList.map((skill) => {
                            if (!skills.some((s) => s.name == skill.name)) {
                                return(
                                    <button
                                    className="hover:bg-mains hover:text-white text-black text-start transition-all p-2"
                                    key={`skillSelect${skill.id}`}
                                    onClick={() => {
                                        setSkills((prev) => [...prev, { id: skill.id, name: skill.name, name_en: skill.name_en }])
                                    }}
                                    >
                                        {isEnglish? skill.name_en: skill.name}
                                    </button>
                                )
                            } else {
                                return null
                            }
                        })}
                    </div>
                )}
            </div>
            <hr/>

            {/* Save skills button */}
            <Button
            className="ml-auto"
            type="button"
            size="sm"
            onClick={() => saveSkills()}
            >
                {isEnglish? 'Save': 'Kaydet'}
            </Button>
        </div>
    )

    async function saveSkills() {
        try {
            const response = await axios.userAuthRequest({
                endpoint: `user/profilim/cv/competences/update`,
                type: 'put',
                payload: {
                    cv_id: cvId,
                    competences: skills.map((s) => {
                        return {
                            name: isEnglish? s.name_en: s.name
                        }
                    })
                }
            })

            if (response && response.status == 200) {
                showCvScore()
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Uyarı',
                    text: 'Yetenekler eklenirken beklenmedik bir hata meydana geldi.'
                })
            }
        } catch (error) {
            console.log(`Error: ${error}`)
        }
    }
}