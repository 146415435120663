import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Section from './Sections/Index';
import Details from './Details/Index';

const Dashboard: React.FC = () => {
  return (
    <div className=''>
       <div className='w-full'>
        <Routes>
         <Route path="/" element={<Section/>} />
         <Route path="/:slug" element={<Details/>} />
        </Routes>
       </div>
    </div>
  );
};

export default Dashboard;