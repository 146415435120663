import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import services from "../../../services/search-services";
import SearchBarInput from "./input";
import {
  jobsPageContextType,
  JobsPageContext,
} from "../../../containers/job-page-container/main/index";
import { refreshQueryParam } from "../../../hooks/useAddOrUpdateQueryParam";
import { DomEvent } from "leaflet";
import preventDefault = DomEvent.preventDefault;
import { boolean } from "yup";

const SearchBar: React.FC = () => {
  const {
    newSelectedValues,
    setNewSelectedValues,
    selectedValues,
    setSelectedValues,
  }: jobsPageContextType = useContext(JobsPageContext);

  const navigate = useNavigate();
  const location = useLocation();

  const searchInitialParams = new URLSearchParams(location.search);

  const [searchPosition, setSearchPosition] = useState<string>(
    searchInitialParams.get("pht") || ""
  );
  const [positionsResult, setPositionsResult] = useState<any[]>([]);
  const [searchLocation, setSearchLocation] = useState<string>(
    searchInitialParams.get("lht") || ""
  );
  const [locationsResult, setLocationsResult] = useState<any[]>([]);
  const [recentSearches, setRecentSearches] = useState<any[]>([]);

  const [selectedPosition, setSelectedPosition] = useState<any>({});
  const [selectedLocation, setSelectedLocation] = useState<any>({});
  const [lastSearches, setLastSearches] = useState<string>("");

  useEffect(() => {
    const savedSearches = JSON.parse(Cookies.get("recentSearches") || "[]");
    setRecentSearches(savedSearches);
  }, []);

  useEffect(() => {
    let searchparams = new URLSearchParams(location.search);
    let lht = searchparams.get("lht") || "";
    let pht = searchparams.get("pht") || "";
    setSearchPosition(pht);
    setSearchLocation(lht);

    if (selectedValues.positions[0] !== undefined) {
      if (selectedValues.positions[0].title === pht) {
        setSelectedPosition(selectedValues.positions[0]);
      } else {
        setSelectedPosition({});
      }
    } else if (selectedValues.company[0] !== undefined) {
      if (selectedValues.company[0].title === pht) {
        setSelectedPosition(selectedValues.company[0]);
      } else {
        setSelectedPosition({});
      }
    } else {
      setSelectedPosition({});
    }

    if (selectedValues.cities[0] !== undefined) {
      if (selectedValues.cities[0].title === lht) {
        setSelectedLocation(selectedValues.cities[0]);
      } else {
        setSelectedLocation({});
      }
    } else if (selectedValues.districts[0] !== undefined) {
      if (selectedValues.districts[0].title === lht) {
        setSelectedLocation(selectedValues.districts[0]);
      } else {
        setSelectedLocation({});
      }
    } else {
      setSelectedLocation({});
    }
  }, [location.search, selectedValues]);

  const initialPos = useMemo(
    () => ({
      header: recentSearches.length > 0 ? "Son Aramalar" : "",
      headerRight: "Temizle",
      items: recentSearches.map((el: any) => {
        console.log(el);
        return {
          title: el.job.title
            ? el.job.title
            : el.location.name
            ? el.location.name
            : el.jobString !== ""
            ? el.jobString
            : el.locationString,
          alt: el.job.title ? el.location.name : "",
          isRecent: true,
          search: el,
        };
      }),
    }),
    [recentSearches]
  );

  const handleClear = () => {
    Cookies.remove("recentSearches");
    setRecentSearches([]);
    setPositionsResult([]);
  };

  const fetchPositions = useCallback(async () => {
    if (searchPosition.trim().length > 1) {
      try {
        const positionsAndCompanies =
          await services.searchServicesPositionsOrCompanies(searchPosition);

        const formattedPositions = {
          header: "",
          items: positionsAndCompanies.data.positions.map((el: any) => ({
            title: el.title,
            name: el.title,
            id: el.id,
            param: "positions",
          })),
        };

        const formattedCompanies = {
          header: "Şirketlerde Ara",
          items: positionsAndCompanies.data.companies.map((el: any) => ({
            title: el.company_name,
            name: el.company_name,
            id: el.id,
            param: "company",
          })),
        };

        setPositionsResult([formattedPositions, formattedCompanies]);
      } catch (error: any) {
        console.log(error.message);
      }
    } else {
      setPositionsResult([initialPos]);
    }
  }, [initialPos, searchPosition]);

  useEffect(() => {
    const delay = setTimeout(() => {
      fetchPositions();
    }, 300);

    return () => {
      clearTimeout(delay);
    };
  }, [fetchPositions, searchPosition]);

  const initialLoc2 = useMemo(
    () => ({
      header: "Önerilen Konumlar",
      items: [
        {
          id: 82,
          country_id: 1,
          name: "İstanbul - Anadolu",
          title: "İstanbul - Anadolu",
          slug: "istanbul-anadolu",
          param: "city",
        },
        {
          id: 81,
          country_id: 1,
          name: "İstanbul - Avrupa",
          title: "İstanbul - Avrupa",
          slug: "istanbul-avrupa",
          param: "city",
        },
        {
          id: 83,
          country_id: 1,
          name: "İstanbul - Tümü",
          title: "İstanbul - Tümü",
          slug: "istanbul",
          param: "city",
        },
        {
          id: 7795,
          country_id: 1,
          name: "Türkiye Geneli",
          title: "Türkiye Geneli",
          slug: "turkiye-geneli",
          param: "city",
        },
        {
          id: 1,
          country_id: 1,
          name: "Adana",
          title: "Adana",
          slug: "adana",
          param: "city",
        },
        {
          id: 2,
          country_id: 1,
          name: "Adıyaman",
          title: "Adıyaman",
          slug: "adiyaman",
          param: "city",
        },
        {
          id: 3,
          country_id: 1,
          name: "Afyonkarahisar",
          title: "Afyonkarahisar",
          slug: "afyonkarahisar",
          param: "city",
        },
        {
          id: 4,
          country_id: 1,
          name: "Ağrı",
          title: "Ağrı",
          slug: "agri",
          param: "city",
        },
        {
          id: 66,
          country_id: 1,
          name: "Aksaray",
          title: "Aksaray",
          slug: "aksaray",
          param: "city",
        },
        {
          id: 5,
          country_id: 1,
          name: "Amasya",
          title: "Amasya",
          slug: "amasya",
          param: "city",
        },
        {
          id: 6,
          country_id: 1,
          name: "Ankara",
          title: "Ankara",
          slug: "ankara",
          param: "city",
        },
        {
          id: 7,
          country_id: 1,
          name: "Antalya",
          title: "Antalya",
          slug: "antalya",
          param: "city",
        },
        {
          id: 73,
          country_id: 1,
          name: "Ardahan",
          title: "Ardahan",
          slug: "ardahan",
          param: "city",
        },
        {
          id: 8,
          country_id: 1,
          name: "Artvin",
          title: "Artvin",
          slug: "artvin",
          param: "city",
        },
        {
          id: 9,
          country_id: 1,
          name: "Aydın",
          title: "Aydın",
          slug: "aydin",
          param: "city",
        },
        {
          id: 10,
          country_id: 1,
          name: "Balıkesir",
          title: "Balıkesir",
          slug: "balikesir",
          param: "city",
        },
        {
          id: 72,
          country_id: 1,
          name: "Bartın",
          title: "Bartın",
          slug: "bartin",
          param: "city",
        },
        {
          id: 70,
          country_id: 1,
          name: "Batman",
          title: "Batman",
          slug: "batman",
          param: "city",
        },
        {
          id: 67,
          country_id: 1,
          name: "Bayburt",
          title: "Bayburt",
          slug: "bayburt",
          param: "city",
        },
        {
          id: 11,
          country_id: 1,
          name: "Bilecik",
          title: "Bilecik",
          slug: "bilecik",
          param: "city",
        },
      ],
    }),
    []
  );

  const fetchLocations = useCallback(async () => {
    if (searchLocation.trim().length > 1) {
      try {
        const data = await services.searchServicesCitiesAndDictrict(
          searchLocation
        );

        const mappedData = data.data.cities_or_districts.map((c: any) => ({
          title: c.name,
          name: c.name,
          id: c.id,
          param: c.type,
        }));

        setLocationsResult([{ items: mappedData }]);
      } catch (error: any) {
        console.log(error.message);
      }
    } else {
      setLocationsResult([initialLoc2]);
    }
  }, [initialLoc2, searchLocation]);

  useEffect(() => {
    const delay = setTimeout(() => {
      fetchLocations();
    }, 500);

    return () => {
      clearTimeout(delay);
    };
  }, [fetchLocations, searchLocation]);

  const handleLocationInputChange = (value: any, type: string) => {
    if (type === "type") {
      setSearchLocation(value);
      setSelectedLocation({});
    } else if (type === "set") {
      setSelectedLocation(value);
    }
  };
  const handlePositionInputChange = (value: any, type: string) => {
    if (value.isRecent) {
      setLastSearches(
        JSON.stringify([
          value.search.job,
          value.search.location,
          value.search.jobString,
          value.search.locationString,
        ])
      );
      makeSearch(
        value.search.job,
        value.search.location,
        value.search.jobString,
        value.search.locationString,
        true
      );
      return;
    }
    if (type === "type") {
      setSearchPosition(value);
      setSelectedPosition({});
    } else if (type === "set") {
      setSelectedPosition(value);
    }
  };

  const makeSearch = (
    sp: any,
    sl: any,
    pt: string = "",
    lt: string = "",
    isRecent: boolean = false
  ) => {
    if (pt === "" && lt === "") {
      return;
    }
    const queryParams = [];

    const selectedPositionId = sp.id ? sp.id : 0;
    const selectedLocationId = sl.id ? sl.id : 0;

    let selectedCity,
      selectedDistrict,
      selectedPos,
      selectedCompany,
      isSearching = false;

    if (selectedPositionId !== 0) {
      if (sp.param === "company") {
        queryParams.push({
          key: "company_ids",
          value: `[${selectedPositionId}]`,
        });
        queryParams.push({ key: "pht", value: sp.title });
        selectedCompany = true;
      } else {
        queryParams.push({
          key: "positions",
          value: `[${selectedPositionId}]`,
        });
        queryParams.push({ key: "pht", value: sp.title });
        selectedPos = true;
      }
    } else if (pt !== "") {
      queryParams.push({
        key: "search",
        value: `${pt}`,
      });
      queryParams.push({ key: "pht", value: pt });
      isSearching = true;
    }

    if (selectedLocationId !== 0) {
      if (sl.param === "city") {
        queryParams.push({ key: "cities", value: `[${selectedLocationId}]` });
        queryParams.push({ key: "lht", value: sl.title });
        selectedCity = true;
      } else {
        queryParams.push({
          key: "districts",
          value: `[${selectedLocationId}]`,
        });
        queryParams.push({ key: "lht", value: sl.title });
        selectedDistrict = true;
      }
    } else if (lt !== "") {
      //queryParams.push({ key: "lht", value: searchLocation });
    }

    setSelectedValues({
      cities: selectedCity ? [sl] : [],
      districts: selectedDistrict ? [sl] : [],
      workingTypes: [],
      positions: selectedPos ? [sp] : [],
      workingLocations: [],
      sections: [],
      sectors: [],
      advertDate: [],
      experience: [],
      disabled: [],
      other: [],
      positionLevels: [],
      filters: [],
      company: selectedCompany ? [sp] : [],
      search: isSearching ? [{ id: 0, title: pt }] : [],
      isFilterUpdated: false,
    });
    setNewSelectedValues({
      cities: selectedCity ? [sl] : [],
      districts: selectedDistrict ? [sl] : [],
      workingTypes: [],
      positions: selectedPos ? [sp] : [],
      workingLocations: [],
      sections: [],
      sectors: [],
      advertDate: [],
      experience: [],
      disabled: [],
      other: [],
      positionLevels: [],
      filters: [],
      company: selectedCompany ? [sp] : [],
      search: isSearching ? [{ id: 0, title: pt }] : [],
      isFilterUpdated: false,
    });
    if (!isRecent && pt !== "") {
      const recentSearches = JSON.parse(Cookies.get("recentSearches") || "[]");
      const newSearch = {
        job: sp,
        jobString: pt,
        location: sl,
        locationString: lt,
      };
      const updatedRecentSearches = [newSearch, ...recentSearches].slice(0, 5);
      Cookies.set("recentSearches", JSON.stringify(updatedRecentSearches));
      setRecentSearches(updatedRecentSearches);
    }
    const searchPath = refreshQueryParam(location, queryParams);
    navigate("/is-ilanlari" + searchPath, { replace: true });
  };

  const handleSearch = () => {
    if (
      lastSearches ===
      JSON.stringify([
        selectedPosition,
        selectedLocation,
        searchPosition,
        searchLocation,
      ])
    ) {
      return;
    }

    setLastSearches(
      JSON.stringify([
        selectedPosition,
        selectedLocation,
        searchPosition,
        searchLocation,
      ])
    );
    makeSearch(
      selectedPosition,
      selectedLocation,
      searchPosition,
      searchLocation
    );
  };

  return (
    <div className="h-full w-full flex flex-col gap-2 xl:gap-0 xl:flex-row relative">
      <div className="w-full xl:w-6/12 z-[41] h-[40px] xl:h-9 ">
        <SearchBarInput
          searchTerm={searchPosition}
          setSearchTerm={(value) => {
            handlePositionInputChange(value, "type");
          }}
          results={positionsResult}
          placeHolder="Pozisyon veya şirket ara"
          style={{
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
          }}
          isRounded={true}
          setInputValue={(value) => {
            handlePositionInputChange(value, "set");
          }}
          handleClear={handleClear}
        />
      </div>
      <div className="flex flex-row xl:w-6/12 gap-0 xl:gap-0">
        <div className="w-full xl:w-10/12 z-[40] h-[40px] xl:h-9 ">
          <SearchBarInput
            searchTerm={searchLocation}
            setSearchTerm={(value) => {
              handleLocationInputChange(value, "type");
            }}
            results={locationsResult}
            placeHolder="Şehir veya ilçe ara"
            style={{
              borderRadius: 0,
            }}
            setInputValue={(value) => {
              handleLocationInputChange(value, "set");
            }}
            handleClear={handleClear}
          />
        </div>
        <button
          onClick={handleSearch}
          className="flex flex-row items-center text-white px-4 gap-1 rounded-r-md xl:rounded-r-md xl:rounded-l-none bg-[#DE5C2B] border border-[#DE5C2B] h-[40px] xl:h-9 cursor-pointer transition duration-300 ease-in-out "
        >
          <label className="whitespace-nowrap font-semibold cursor-pointer">
            İş Bul
          </label>
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
