import { FaFilter } from "react-icons/fa";
import React, { useContext, useState } from "react";
import { Button, Checkbox, Modal, Select } from "antd";
import Swal from "sweetalert2";
import { refreshQueryParam } from "../../../../../../hooks/useAddOrUpdateQueryParam";
import Axios from "../../../../../../services/axios";
import { JobsPageContext, jobsPageContextType } from "../../../index";
import { Link, useLocation } from "react-router-dom";
import { CiFilter } from "react-icons/ci";
import { InputArea } from "../../../../../../components/isbul-ui";
import Cookies from "js-cookie";
import { TbBellRinging2 } from "react-icons/tb";
interface IItem {
  title: string;
  id: number;
}
const AlarmSaveModal = () => {
  const [titleText, setTitleText] = useState<string>("");
  const [frequance, setFrequence] = useState("DAILY");
  const [ring, setRing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);

  const userToken = Cookies.get("userToken") || Cookies.get("companyUserToken");

  const location = useLocation();
  const axios = new Axios();
  const {
    createQueryParamsFromSelectedValues,
    newSelectedValues,
  }: jobsPageContextType = useContext(JobsPageContext);

  const handleChangeTitle = (e: any) => {
    if (e.target.value.trim() !== "") {
      setIsError(false);
    }
    setTitleText(e.target.value);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const createQueryParamsArrayFromSelectedValues = () => {
    // seçilmiş filtreleri search parametresine çeviriyor
    const positionIds = newSelectedValues.positions.map((item: any) => item.id);
    const cityIds = newSelectedValues.cities.map((item) => item.id);
    const companyId = newSelectedValues.company.map((item) => item.id);
    const districtIds = newSelectedValues.districts.map((item) => item.id);
    const searchIds = newSelectedValues.search.map((item) => item.id);
    const workingTypeIds = newSelectedValues.workingTypes.map(
      (item) => item.id
    );
    const workingLocationsIds = newSelectedValues.workingLocations.map(
      (item) => item.id
    );
    const sectionsIds = newSelectedValues.sections.map((item) => item.id);
    const sectorIds = newSelectedValues.sectors.map((item) => item.id);
    let advertDate;

    let minExp, maxExp;

    if (newSelectedValues.experience[0]?.id === 1) {
      // Kullanıcı deneyimli
      minExp = 0;
      maxExp = 25;
    } else if (newSelectedValues.experience[0]?.id === 2) {
      // Kullanıcı deneyimsiz
      minExp = 0;
      maxExp = 0;
    } else if (newSelectedValues.experience[0]?.id === 0) {
      // Kullanıcı farketmez seçmiş
      minExp = 0;
      maxExp = 26;
    } else {
      // Varsayılan değerler (eğer başka bir id gelirse)
      minExp = 0;
      maxExp = 26;
    }

    let disabled;
    switch (newSelectedValues.advertDate[0]?.id || 0) {
      case 1:
        advertDate = "all";
        break;
      case 2:
        advertDate = "new";
        break;
      case 3:
        advertDate = "week";
        break;
      case 4:
        advertDate = "month";
        break;
      default:
        advertDate = "";
    }

    switch (newSelectedValues.disabled[0]?.id || 0) {
      case 1:
        disabled = "all";
        break;
      case 2:
        disabled = "disabled";
        break;
      case 3:
        disabled = "non_disabled";
        break;
      default:
        disabled = "";
    }
    const notTurkeyGeneral = newSelectedValues.other.some((d) => d.id === 0);
    const hiddenCompany = newSelectedValues.other.some((d) => d.id === 1);
    const notShowWieved = newSelectedValues.other.some((d) => d.id === 2);
    const followCompanyAdverts = newSelectedValues.other.some(
      (d) => d.id === 3
    );
    const forMeAdverts = newSelectedValues.other.some((d) => d.id === 4);
    const notShowApplied = newSelectedValues.other.some((d) => d.id === 5);

    const positionLevelsIds = newSelectedValues.positionLevels.map(
      (item) => item.id
    );

    const queryParams: any = {};

    if (notTurkeyGeneral) {
      queryParams["not_turkey_general"] = "1";
    }
    if (hiddenCompany) {
      queryParams["not_show_hidden_company_title"] = "1";
    }
    if (notShowWieved) {
      queryParams["not_show_viewed"] = "1";
    }
    if (followCompanyAdverts) {
      queryParams["followed_company_adverts"] = "1";
    }
    if (forMeAdverts) {
      queryParams["for_me_adverts"] = "1";
    }
    if (notShowApplied) {
      queryParams["not_show_applied"] = "1";
    }

    if (disabled !== "") {
      queryParams["disabled"] = disabled;
    }

    if (maxExp !== 26) {
      queryParams["experience_level_min"] = minExp.toString();
      queryParams["experience_level_max"] = maxExp.toString();
    }

    if (advertDate !== "") {
      queryParams["advert_date"] = advertDate;
    }

    if (positionIds.length > 0) {
      queryParams["positions"] = positionIds;
    }
    if (cityIds.length > 0) {
      queryParams["cities"] = cityIds;
    }
    if (districtIds.length > 0) {
      queryParams["districts"] = districtIds;
    }

    if (positionLevelsIds.length > 0) {
      queryParams["position_levels"] = positionLevelsIds;
    }
    if (workingTypeIds.length > 0) {
      queryParams["working_types"] = workingTypeIds;
    }
    if (workingLocationsIds.length > 0) {
      queryParams["working_locations"] = workingLocationsIds;
    }
    if (sectionsIds.length > 0) {
      queryParams["sections"] = sectionsIds;
    }
    if (sectorIds.length > 0) {
      queryParams["sectors"] = sectorIds;
    }
    if (companyId.length > 0) {
      queryParams["company_ids"] = companyId;
    }
    if (searchIds.length > 0) {
      queryParams["search"] = newSelectedValues.search[0].title;
    }

    return queryParams;
  };

  const handleSweetAlert = async () => {
    //console.log(ring, frequance);

    if (titleText === "") {
      setIsError(true);
      Swal.fire({
        icon: "error",
        text: "Başlık alanı boş bırakılamaz!",
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "sweet-alert-button", // TailwindCSS classes
        },
      });
      return;
    }

    let queryParams = createQueryParamsFromSelectedValues();

    const params_string = refreshQueryParam(location, queryParams);

    let params = createQueryParamsArrayFromSelectedValues();

    params = {
      ...params,
      ...{
        create_alarm: "on",
        period: frequance, // ENUM('DAILY', 'WEEKLY', 'MONTHLY')
        search_type: "all",
      },
    };
    axios
      .userAuthRequest({
        type: "post",
        endpoint: "user/profilim/alarms/create",
        payload: {
          params_string: params_string,
          title: titleText,
          params: JSON.stringify(params),
          period: frequance,
        },
      })
      .then((response: any) => {
        Swal.fire({
          icon: "success",
          text: "İş Alarmı Başarıyla Oluşturuldu!",
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "sweet-alert-button", // TailwindCSS classes
          },
        });
        setTitleText("");
        handleCancel();
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "İş Alarmı Oluşturulamadı!",
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "sweet-alert-button", // TailwindCSS classes
          },
        });
      });
  };

  return (
    <>
      <div className="flex items-center w-1/3">
        {userToken ? (
          <>
            <button
              onClick={showModal}
              className="flex flex-row items-center justify-center w-full gap-2 py-1 px-4 text-base rounded-md bg-green-100 text-green-800 border border-green-800"
            >
              <TbBellRinging2 className="text-xl" />
              İş Alarmı Kur
            </button>
          </>
        ) : (
          <Link
            to="/profilim/giris-yap"
            className="flex flex-row items-center justify-center w-full gap-2 py-1 px-4 text-base rounded-md bg-green-100 text-green-800 border border-green-800"
          >
            <TbBellRinging2 className="text-xl" />
            İş Alarmı Kur
          </Link>
        )}
      </div>

      <Modal
        title="İş Alarmı Oluştur"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <div className="w-full flex justify-between border-t border-color-[#dee2e6] px-[24px] py-[12px]">
            <Button
              className="bg-gray-200 text-black font-poppins text-sm"
              key="Kapat"
              onClick={handleCancel}
            >
              Kapat
            </Button>
            <Button
              className="bg-[#2253af] text-white font-poppins text-sm"
              key="Kaydet"
              onClick={() => handleSweetAlert()}
            >
              Kaydet
            </Button>
          </div>,
        ]}
      >
        <div className="space-y-4">
          <div>
            <p className="text-sm font-poppins font-normal text-black">
              İlgili aramanıza ait yeni iş ilanlarını e-posta ile alabilirsiniz.
            </p>
          </div>

          <div>
            <InputArea
              error={isError}
              className="w-full"
              required
              mode={titleText?.length == 0 ? "empty" : "dirty"}
            >
              <InputArea.Label>Başlık</InputArea.Label>
              <InputArea.Input
                value={titleText || ""}
                onChange={(e) => {
                  handleChangeTitle(e);
                }}
              />
            </InputArea>
          </div>

          <div>
            <p className="text-gray-400 font-poppins text-sm mb-[2px]">
              E-posta Gönderim Sıklığı <span className="text-red-500">*</span>{" "}
            </p>
            <Select
              defaultValue="Günlük E-posta Gönder"
              className="w-full"
              style={{ height: "40px" }}
              onChange={(e, option) => {
                setFrequence(e);
              }}
              value={frequance}
              options={[
                {
                  value: "DAILY",
                  label: "Günlük E-posta Gönder",
                },
                {
                  value: "WEEKLY",
                  label: "Haftalık E-posta Gönder",
                },
                {
                  value: "MONTHLY",
                  label: "Aylık E-posta Gönder",
                },
              ]}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AlarmSaveModal;
