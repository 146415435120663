import React from "react";
import { useParams } from "react-router-dom";
import parse, { domToReact } from "html-react-parser";
import { GoDotFill } from "react-icons/go";
import { useCorporateContent } from "../hooks/useCorporateContent";

function CorporateContent(): React.ReactElement {
  const { slug } = useParams();
  const { title, detail, loading } = useCorporateContent({ slug: slug ?? "" });

  const cleanDetail = (content: string) => {
    // Remove empty <p> tags, &nbsp; entities, and empty <br> tags
    return content
      .replace(/<p>(&nbsp;|\s|<br>)*<\/p>/g, "")
      .replace(/<br\s*\/?>/g, "") // Remove empty <br> tags
      .replace(/&nbsp;/g, " ")
      .replace(/\s\s+/g, " ");
  };

  let pCount = 0;

  const options = {
    replace: (domNode: any) => {
      if (domNode.type === "tag") {
        switch (domNode.name) {
          case "h1":
            return (
              <h1 className="text-black poppins-semibold text-2xl my-4 text-justify">
                {domToReact(domNode.children)}
              </h1>
            );
          case "h2":
            return (
              <h2 className="text-black poppins-semibold text-2xl my-4 text-justify">
                {domToReact(domNode.children)}
              </h2>
            );
          case "h3":
            return (
              <h3 className="text-black poppins-semibold my-4 text-xl text-justify">
                {domToReact(domNode.children)}
              </h3>
            );
          case "p":
            pCount++;
            const marginBottomClass = pCount % 2 === 0 ? "mb-4" : "mb-1";
            const marginTopClass = pCount % 2 === 0 ? "mt-4" : "mt-1";
            return (
              <p className={`${marginBottomClass} ${marginTopClass}`}>
                {domToReact(domNode.children)}
              </p>
            );
          case "ul":
            return (
              <ul className="flex flex-col gap-y-2">
                {React.Children.toArray(
                  domNode.children
                    .filter((child: any) => child.name === "li")
                    .map((li: any, index: number) => (
                      <li key={index} className="flex items-center gap-x-2">
                        <GoDotFill size={14} />
                        {parse(
                          li.children
                            .map((child: any) => child.data || "")
                            .join("")
                        )}
                      </li>
                    ))
                )}
              </ul>
            );
          default:
            return undefined;
        }
      }
    },
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-96">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-mains"></div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-y-1 w-full">
      <h1 className="bg-white p-5 rounded-sm poppins-semibold shadow-sm text-mains">
        {title || "Başlık Bulunamadı"}
      </h1>
      <div
        className="bg-white p-5 rounded-sm shadow-sm text-justify text-base poppins-regular,
        tracking-wide leading-5"
      >
        <div className="poppins-regular">
          {parse(cleanDetail(detail), options)}
        </div>
      </div>
    </div>
  );
}

export { CorporateContent };
