import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ISmsContentRenderState {
  smsContentRender: boolean;
}

const initialState: ISmsContentRenderState = {
  smsContentRender: false,
};

export const renderSlice = createSlice({
  name: "renderSlice",
  initialState,
  reducers: {
    showSmsContent: (state) => {
      state.smsContentRender = true;
    },
    hideSmsContent: (state) => {
      state.smsContentRender = false;
    },
    toggleSmsContent: (state) => {
      state.smsContentRender = !state.smsContentRender;
    },
    setSmsContentRender: (state, action: PayloadAction<boolean>) => {
      state.smsContentRender = action.payload;
    },
  },
});

export const {
  showSmsContent,
  hideSmsContent,
  toggleSmsContent,
  setSmsContentRender,
} = renderSlice.actions;

export default renderSlice.reducer;
