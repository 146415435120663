import { Modal } from "antd";
import React, { useState } from "react";

type PositionType = {
  id: number;
  title: string;
};

interface IApplicationPopupListItem {
  createdAt: string;
  updatedAt: string;
  endDate: string;
  keys: any[];
}

const ApplicationPopupListItem: React.FC<IApplicationPopupListItem> = (
  props: IApplicationPopupListItem
) => {
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [modalinfo, setModalinfo] = useState<any>();

  const showModal3 = (d: any) => {
    setModalinfo(d);
    setIsModalOpen3(true);
  };
  const handleOk3 = () => {
    setIsModalOpen3(false);
  };
  const handleCancel3 = () => {
    setIsModalOpen3(false);
  };

  const getUniqueByKey = (array: any[], key: string) => {
    const uniqueItems = array.reduce((acc, current) => {
      const x = acc.find((item: any) => item[key] === current[key]);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    return uniqueItems;
  };

  const uniqueModalInfo = modalinfo?.value
    ? getUniqueByKey(modalinfo?.value, modalinfo?.target)
    : [];

  const generateLink = (key: string, item: any) => {
    switch (key) {
      case "POZİSYON":
        return `/is-ilanlari?positions=[${item.id}]`;
      case "SEKTÖR":
        return `/is-ilanlari?sectors=[${item.id}]`;
      case "ÇALIŞMA YERİ":
        return `/is-ilanlari?cities=[${item.id}]`;
      case "TECRÜBE":
        return item.value === "Deneyimsiz"
          ? `/is-ilanlari?experience_level_min=0&experience_level_max=0`
          : `/is-ilanlari?experience_level_min=0&experience_level_max=25`;
      default:
        return "#";
    }
  };

  return (
    <>
      <Modal
        title={modalinfo?.key}
        open={isModalOpen3}
        footer={null}
        onOk={handleOk3}
        onCancel={handleCancel3}
      >
        {uniqueModalInfo.length > 0 &&
          uniqueModalInfo.map((t: any, i: number) => (
            <div key={i} className="flex items-center mb-1">
              <div className="text-gray-700 text-sm capitalize">
                {t[modalinfo?.target]}
              </div>
            </div>
          ))}
      </Modal>
      {props.keys
        .filter((el) => el.value && el.value.length > 0)
        .map((el, i) => (
          <div key={i} className="mt-1 w-full justify-between grid grid-cols-2">
            <div className="font-semibold text-gray-600">{el.key}</div>
            <div className="flex items-center">
              <div className="mr-1">:</div>
              <div className="text-blue-600 underline flex flex-row">
                {Array.isArray(el.value) ? (
                  <a
                    href={generateLink(el.key, el.value[0])}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {el.value[0]?.name || el.value[0]?.title}
                  </a>
                ) : (
                  <a
                    href={generateLink(el.key, { value: el.value })}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {el.value}
                  </a>
                )}
              </div>
              {Array.isArray(el.value) &&
                (() => {
                  const uniqueValues = getUniqueByKey(el.value, el.target);
                  return uniqueValues.length > 1 ? (
                    <div
                      onClick={() => showModal3(el)}
                      className="text-[#555] !ml-1 text-md cursor-pointer"
                    >
                      {`(+${uniqueValues.length - 1})`}
                    </div>
                  ) : null;
                })()}
            </div>
          </div>
        ))}

      <div className="mt-1 w-full  grid grid-cols-2">
        <div className="font-semibold text-gray-600 line-clamp-1">
          {"İlk Yayınlama Tarihi"}
        </div>
        <div className="flex">
          <div className="mr-1">{":"}</div>
          <div className="text-gray-600">{props.createdAt}</div>
        </div>
      </div>
      <div className="mt-1 w-full  grid grid-cols-2">
        <div className="font-semibold text-gray-600 line-clamp-1">
          {"Güncelleme Tarihi"}
        </div>
        <div className="flex">
          <div className="mr-1">{":"}</div>
          <div className="text-gray-600">{props.updatedAt}</div>
        </div>
      </div>
      <div className="mt-1 w-full  grid grid-cols-2">
        <div className="font-semibold text-gray-600 line-clamp-1">
          {"Son Başvuru Tarihi"}
        </div>
        <div className="flex">
          <div className="mr-1">{":"}</div>
          <div className="text-gray-600">{props.endDate}</div>
        </div>
      </div>
    </>
  );
};

export default ApplicationPopupListItem;
