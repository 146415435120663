import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Button from "../../../components/form-components/button";
import { IoMdClose } from "react-icons/io";

export default function CookieSettingsModal({
  acceptCookie,
  setIsModalOpen,
}: {
  acceptCookie: () => void;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const divRef = useRef<HTMLDivElement>(null);

  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        const clickedElement = event.target as HTMLElement;
        if (clickedElement.id === "popup") {
          setIsModalOpen(false);
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [setIsModalOpen]);
  return (
    <>
      {/* desktop */}
      <div
        id="popup"
        className="fixed z-50 bg-black hidden lg:flex items-center justify-center bg-opacity-30 w-screen h-screen top-0 left-0"
      >
        <div
          ref={divRef}
          className="flex flex-row gap-2 bg-white rounded-md max-w-[800px] min-h-[400px] w-full"
        >
          <div className="flex flex-col gap-2 bg-gray-100 p-4 rounded-l-md flex-shrink-0">
            <button
              className={`${
                activeTab == 0 ? "text-mains" : "text-black"
              } hover:bg-gray-200 font-semibold p-2 text-start transition-all`}
              onClick={() => setActiveTab(0)}
            >
              Ayarlar
            </button>
            <button
              className={`${
                activeTab == 1 ? "text-mains" : "text-black"
              } hover:bg-gray-200 font-semibold p-2 text-start transition-all`}
              onClick={() => setActiveTab(1)}
            >
              Gerekli Çerezler
            </button>
            <button
              className={`${
                activeTab == 2 ? "text-mains" : "text-black"
              } hover:bg-gray-200 font-semibold p-2 text-start transition-all`}
              onClick={() => setActiveTab(2)}
            >
              Fonksiyonel Çerezler
            </button>
            <button
              className={`${
                activeTab == 3 ? "text-mains" : "text-black"
              } hover:bg-gray-200 font-semibold p-2 text-start transition-all`}
              onClick={() => setActiveTab(3)}
            >
              Analitik Çerezler
            </button>
          </div>
          <div className="flex flex-col gap-2 w-full px-2 pt-10 pb-2">
            {activeTab == 0 ? (
              <p className="text-sm">
                Çerezlerle sitemizi ziyaret eden kullanıcıların davranışlarını
                analiz ederiz. Böylece daha gelişmiş düzeyde iş bulma deneyimini
                sizler için tasarlayarak deneyiminizi eşsiz bir hale getiririz.
                Sitemizi ziyaret ettiğinizde çerezleri kabul etmeniz
                deneyiminizi özelleştirmemize olanak tanır.
              </p>
            ) : activeTab == 1 ? (
              <div className="flex flex-col gap-2 w-full text-sm">
                <div className="flex flex-row items-center justify-between">
                  <label className="font-semibold text-mains">
                    Gerekli Çerezler
                  </label>
                </div>
                <hr />
                <p>
                  Gerekli olan çerezler, isbul.net’in sistemsel olarak doğru
                  çalışabilmesini sağlar. isbul.net'e üye olma, giriş yap gibi
                  aktivitelerinin yanında site içinde yaptığın faaliyetlerini
                  bir sonraki ziyaretinde hatırlamamız adına ihtiyaç duyduğumuz
                  çerezlerdir.
                </p>
              </div>
            ) : activeTab == 2 ? (
              <div className="flex flex-col gap-2 w-full text-sm">
                <div className="flex flex-row items-center justify-between">
                  <label className="font-semibold text-mains">
                    Fonksiyonel Çerezler
                  </label>
                </div>
                <hr />
                <p>
                  Fonksiyonel çerezler, isbul.net'i daha kolay kullanmanı
                  sağlar. Geçmiş aramaların, arama tercihlerin gibi
                  fonksiyonları kullanman için fonksiyonel çerezler gereklidir.
                  Bu çerezler güvenli gezinme imkanını da sağlar. Bu çerezler
                  sayesinde dil, yaşadığın yer gibi kişiselleştirilmiş tanımları
                  yapabilirsin.
                </p>
              </div>
            ) : activeTab == 3 ? (
              <div className="flex flex-col gap-2 w-full text-sm">
                <div className="flex flex-row items-center justify-between">
                  <label className="font-semibold text-mains">
                    Analitik Çerezler
                  </label>
                </div>
                <hr />
                <p>
                  Analitik olan çerezler, isbul.net'i kullanan kullanıcıların
                  davranışlarını anlamamızı sağlar. Size daha iyi bir kullanıcı
                  deneyimi sunmak için çalışmalar yapmamıza olanak tanır. Bu
                  çerezler sayesinde kullanıcılarımızın davranışlarını da
                  istatistiksel olarak analiz ederiz ve analiz sonuçlarını sizin
                  deneyimlerinizi iyileştirmek için kullanırız.
                </p>
              </div>
            ) : null}
            <div className="flex flex-col md:flex-row gap-2 items-center justify-end w-full mt-auto">
              <button
                className="text-[14px] underline text-mains underline-offset-4"
                onClick={() => acceptCookie()}
              >
                Kaydet
              </button>
              <Button size="sm" onClick={() => acceptCookie()}>
                Tüm Çerezleri Kabul Et
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* mobile */}

      <div className="fixed z-50 bg-black flex lg:hidden items-center justify-center bg-opacity-30 w-screen h-screen top-0 left-0">
        <div
          ref={divRef}
          className="flex flex-col bg-gray-50 rounded-md h-full w-full"
        >
          <div className="bg-gray-50 h-16">
            <IoMdClose
              className="absolute top-3 right-3 text-black text-md cursor-pointer"
              onClick={() => setIsModalOpen(false)}
            />
          </div>

          <div className="flex flex-col gap-2 bg-gray-100 rounded-l-md flex-shrink-0 border-b shadow-sm">
            <button
              className={`${
                activeTab == 0 ? "text-mains" : "text-black"
              } hover:bg-gray-200 font-semibold text-start transition-all px-3 py-2 `}
              onClick={() => setActiveTab(0)}
            >
              Ayarlar
            </button>
            <button
              className={`${
                activeTab == 1 ? "text-mains" : "text-black"
              } hover:bg-gray-200 font-semibold px-3 py-2 text-start transition-all`}
              onClick={() => setActiveTab(1)}
            >
              Gerekli Çerezler
            </button>
            <button
              className={`${
                activeTab == 2 ? "text-mains" : "text-black"
              } hover:bg-gray-200 font-semibold px-3 py-2 text-start transition-all`}
              onClick={() => setActiveTab(2)}
            >
              Fonksiyonel Çerezler
            </button>
            <button
              className={`${
                activeTab == 3 ? "text-mains" : "text-black"
              } hover:bg-gray-200 font-semibold px-3 py-2 text-start transition-all`}
              onClick={() => setActiveTab(3)}
            >
              Analitik Çerezler
            </button>
          </div>

          <div className="flex flex-col gap-2 w-full h-full px-3 pt-4 pb-2">
            {activeTab == 0 ? (
              <p className="text-sm">
                Çerezlerle sitemizi ziyaret eden kullanıcıların davranışlarını
                analiz ederiz. Böylece daha gelişmiş düzeyde iş bulma deneyimini
                sizler için tasarlayarak deneyiminizi eşsiz bir hale getiririz.
                Sitemizi ziyaret ettiğinizde çerezleri kabul etmeniz
                deneyiminizi özelleştirmemize olanak tanır.
              </p>
            ) : activeTab == 1 ? (
              <div className="flex flex-col gap-2 w-full text-sm">
                <div className="flex flex-row items-center justify-between">
                  <label className="font-semibold text-mains">
                    Gerekli Çerezler
                  </label>
                </div>
                <hr />
                <p>
                  Gerekli olan çerezler, isbul.net’in sistemsel olarak doğru
                  çalışabilmesini sağlar. isbul.net'e üye olma, giriş yap gibi
                  aktivitelerinin yanında site içinde yaptığın faaliyetlerini
                  bir sonraki ziyaretinde hatırlamamız adına ihtiyaç duyduğumuz
                  çerezlerdir.
                </p>
              </div>
            ) : activeTab == 2 ? (
              <div className="flex flex-col gap-2 w-full text-sm">
                <div className="flex flex-row items-center justify-between">
                  <label className="font-semibold text-mains">
                    Fonksiyonel Çerezler
                  </label>
                </div>
                <hr />
                <p>
                  Fonksiyonel çerezler, isbul.net'i daha kolay kullanmanı
                  sağlar. Geçmiş aramaların, arama tercihlerin gibi
                  fonksiyonları kullanman için fonksiyonel çerezler gereklidir.
                  Bu çerezler güvenli gezinme imkanını da sağlar. Bu çerezler
                  sayesinde dil, yaşadığın yer gibi kişiselleştirilmiş tanımları
                  yapabilirsin.
                </p>
              </div>
            ) : activeTab == 3 ? (
              <div className="flex flex-col gap-2 w-full text-sm">
                <div className="flex flex-row items-center justify-between">
                  <label className="font-semibold text-mains">
                    Analitik Çerezler
                  </label>
                </div>
                <hr />
                <p>
                  Analitik olan çerezler, isbul.net'i kullanan kullanıcıların
                  davranışlarını anlamamızı sağlar. Size daha iyi bir kullanıcı
                  deneyimi sunmak için çalışmalar yapmamıza olanak tanır. Bu
                  çerezler sayesinde kullanıcılarımızın davranışlarını da
                  istatistiksel olarak analiz ederiz ve analiz sonuçlarını sizin
                  deneyimlerinizi iyileştirmek için kullanırız.
                </p>
              </div>
            ) : null}
            <div className="flex flex-row gap-3 items-center justify-end w-full mt-auto mr-1">
              <button
                className="text-[14px] underline text-mains underline-offset-4"
                onClick={() => acceptCookie()}
              >
                Kaydet
              </button>
              <Button size="sm" onClick={() => acceptCookie()}>
                Tüm Çerezleri Kabul Et
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
