import React from "react";

interface ITitleProps {
  title: string;
}

const HeaderTitle = ({ title }: ITitleProps) => {
  return (
    <div className="w-full border border-blue-700 rounded-md   md:px-2 md:py-3 sm:py-1 px-2 ">
      <p className="tracking-wide text-base font-semibold text-blue-800">
        {title}
      </p>
    </div>
  );
};

export default HeaderTitle;
