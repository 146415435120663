import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";

export const useHamburgerMenu = () => {
  const [isHamburger, setIsHamburger] = useState(false);
  const hamburgerRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        hamburgerRef.current &&
        !hamburgerRef.current.contains(event.target as Node)
      ) {
        setIsHamburger(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isHamburger]);

  // useEffect(() => {
  //   setIsHamburger(false);
  // }, [location]);

  return {
    isHamburger,
    setIsHamburger,
    hamburgerRef,
  };
};
