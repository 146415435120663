import * as React from 'react';
import Homes from '../../containers/home-page-container/main';
function Home(): React.ReactElement {
  return (
    <>
      <Homes />
    </>
  );
}
export { Home };
