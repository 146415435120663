import * as React from "react";
import {
  CompanyLogin,
  CompanyRegister,
  ProfileLogin,
  ProfileRegister,
} from "../../containers/login-page-container/index";

function CompanyL(): React.ReactElement {
  return <CompanyLogin />;
}
function CompanyR(): React.ReactElement {
  return <CompanyRegister />;
}
function ProfileL(): React.ReactElement {
  return <ProfileLogin />;
}
function ProfileR(): React.ReactElement {
  return <ProfileRegister />;
}
export { CompanyL, CompanyR, ProfileL, ProfileR };
