import React, { useEffect, useState } from "react";
import { PersonalInfoSection } from "../details-section/sections/personal-info";
import { EducationSection } from "../details-section/sections/education";
import { ExperienceSection } from "../details-section/sections/job-experiences";
import { LanguagesSection } from "../details-section/sections/languages";
import { SpecialCasesSection } from "../details-section/sections/special-cases";
import { OtherSections } from "../details-section/sections/others";
import { FaAngleUp } from "react-icons/fa";
import { BasicsSection } from "../basics-section";

function DetailsSectionMobile() {
  const [tabOpenPosition, setTabOpenPosition] = useState<any>({
    position: false,
    personal: false,
    job: false,
    language: false,
    education: false,
    special: false,
    others: false,
  });

  const handleTabOpen = (openThis: string) => {
    let newValue = {
      position: openThis === "position",
      personal: openThis === "personal",
      job: openThis === "job",
      language: openThis === "language",
      education: openThis === "education",
      special: openThis === "special",
      others: openThis === "others",
    };
    if (tabOpenPosition[openThis]) {
      newValue = {
        position: false,
        personal: false,
        job: false,
        language: false,
        education: false,
        special: false,
        others: false,
      };
    }
    setTabOpenPosition(newValue);
  };

  const renderMobileAccordiyonCords = (title: string, target: string) => {
    return (
      <button
        type="button"
        className={" flex px-2 pt-2 items-center justify-between  w-full "}
        onClick={() => {
          handleTabOpen(target);
        }}
      >
        <div
          className={
            "bg-[#f6f6f6] flex px-2 items-center justify-between text-stone-600  border border-gray-600 w-full py-2 rounded"
          }
        >
          <span className="font-poppins poppins-medium whitespace-nowrap text-[15px]">
            {title}
          </span>
          <FaAngleUp
            className={`ml-2 transition-transform duration-300 transform ${
              !tabOpenPosition[target] ? "rotate-180" : ""
            }`}
          />
        </div>
      </button>
    );
  };

  const currentComponent = () => {
    return (
      <div>
        {renderMobileAccordiyonCords("Yaşadığı Yer", "position")}
        <div
          className={`${
            tabOpenPosition.position ? "h-auto" : "h-0 overflow-hidden"
          }    relative`}
        >
          <BasicsSection />
        </div>

        {renderMobileAccordiyonCords("Kişisel Bilgiler", "personal")}

        <div
          className={`${
            tabOpenPosition.personal ? "h-auto" : "h-0 overflow-hidden"
          }    relative`}
        >
          <PersonalInfoSection isVisible={true} />
        </div>

        {renderMobileAccordiyonCords("Eğitim", "education")}

        <div
          className={`${
            tabOpenPosition.education ? "h-auto" : "h-0 overflow-hidden"
          }    relative`}
        >
          <EducationSection isVisible={true} />
        </div>

        {renderMobileAccordiyonCords("İş Deneyimi", "job")}

        <div
          className={`${
            tabOpenPosition.job ? "h-auto" : "h-0 overflow-hidden"
          }    relative`}
        >
          <ExperienceSection isVisible={true} />
        </div>

        {renderMobileAccordiyonCords("Yabancı Dil", "language")}

        <div
          className={`${
            tabOpenPosition.language ? "h-auto" : "h-0 overflow-hidden"
          }    relative`}
        >
          <LanguagesSection isVisible={true} />
        </div>

        {renderMobileAccordiyonCords("Özel Durumlar", "special")}

        <div
          className={`${
            tabOpenPosition.special ? "h-auto" : "h-0 overflow-hidden"
          }    relative`}
        >
          <SpecialCasesSection isVisible={true} />
        </div>
      </div>
    );
  };

  return <section className="">{currentComponent()}</section>;
}

export { DetailsSectionMobile };
