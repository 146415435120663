import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import services from "../../services/profile-services";

interface Country {
    name_tr: any;
    id: number;
    name: string;
}

interface City {
    name_tr?: any;
    id: number;
    name: string;
}

interface District {
    [x: string]: any;
    id: number;
    name: string;
}

export interface AddressState {
    countries: Country[];
    cities: City[];
    districts: District[];
}

const initialState: AddressState = {
    countries: [],
    cities: [],
    districts: []
};


// Thunk action to get countries
export const getCountries: any = () => async (dispatch: (arg0: {
    payload: Country[]; // Thunk action to get countries
    // Thunk action to get countries
    type: "address/getCountriesSuccess";
}) => void) => {
    try {
        const res = await services.countryGet();
        dispatch(getCountriesSuccess(res.data.countries));
    } catch (error) {
        console.log(error);
    }
};

// Thunk action to get cities
export const getCities: any = (country_id: number) => async (dispatch: (arg0: {
    payload: City[];
    type: "address/getCitiesSuccess";
}) => void) => {
    try {
        const res = await services.cityGet(country_id);
        dispatch(getCitiesSuccess(res.data.cities));
    } catch (error) {
        console.log(error);
    }
};

// Thunk action to get districts
export const getDistricts: any = (city_id: string) => async (dispatch: (arg0: {
    payload: District[]; // Thunk action to get countries
    // Thunk action to get countries
    type: "address/getDistrictsSuccess";
}) => void) => {
    try {
        const res = await services.districtGet(city_id);
        dispatch(getDistrictsSuccess(res.data.districts));
    } catch (error) {
        console.log(error);
    }
};

export const addressSlice = createSlice({
    name: "address",
    initialState,
    reducers: {
        getCountriesSuccess(state, action: PayloadAction<Country[]>) {
            state.countries = action.payload;
        },
        getCitiesSuccess(state, action: PayloadAction<City[]>) {
            state.cities = action.payload;
        },
        getDistrictsSuccess(state, action: PayloadAction<District[]>) {
            state.districts = action.payload;
        }
    },
});

export const { getCountriesSuccess, getCitiesSuccess, getDistrictsSuccess } = addressSlice.actions;

export default addressSlice.reducer;
