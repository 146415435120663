import { useEffect, useState } from "react";
import Axios from "../../../../../services/axios";
import AbstractAddModal from "../../../files/contents/save-abstract/components/AbstractAddModal";
import AbstractItem from "../../../files/contents/save-abstract/components/AbstractItem";
import Button from "../../../../../components/form-components/button";
import { IoAdd } from "react-icons/io5";
import { dbUserAbstractType } from "../../../../../types";
import { PiWarningCircle } from "react-icons/pi";

const axios = new Axios();

export default function MyAbstracts() {
  const [selectedAbstract, setSelectedAbstract] = useState<
    dbUserAbstractType | undefined
  >(undefined);
  const [isAbstractModalOpen, setIsAbstractModalOpen] =
    useState<boolean>(false);
  const [isEditAbstractModalOpen, setIsEditAbstractModalOpen] =
    useState<boolean>(false);
  const [abstracts, setAbstracts] = useState<dbUserAbstractType[]>([]);

  async function getAllDatas() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: "user/profilim/text",
        type: "get",
        payload: {},
      });

      if (response && response.data && response.data.data) {
        setAbstracts(response.data.data);
      } else {
        setAbstracts([]);
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      setAbstracts([]);
    }
  }

  useEffect(() => {
    getAllDatas();
  }, []);


  return (
    <div
      className={`lg:flex flex flex-col p-2 items-center w-full bg-white rounded-md shadow-lg mt-6`}
    >
      <div className="flex items-center justify-between w-full px-3 md:px-4 py-3 border-b">
        <span className="text-mains text-md poppins-semibold">
          Kayıtlı Ön Yazılarım
        </span>
        <Button
          size="sm"
          onClick={() => {
            setSelectedAbstract((prev) => undefined);
            setIsAbstractModalOpen(true);
          }}
        >
          <Button.Icon>
            <IoAdd />
          </Button.Icon>
          Ön Yazı Ekle
        </Button>
      </div>

      {isAbstractModalOpen && (
        <AbstractAddModal
          setIsOpen={setIsAbstractModalOpen}
          getAllDatas={getAllDatas}
          selectedAbstract={selectedAbstract}
        />
      )}
      {isEditAbstractModalOpen && (
        <AbstractAddModal
          isEdit={true}
          setIsOpen={setIsEditAbstractModalOpen}
          getAllDatas={getAllDatas}
          selectedAbstract={selectedAbstract}
        />
      )}

      {abstracts.length == 0 && (
        <div className="flex flex-row gap-2 border border-gray-200 rounded-md mt-4 w-full">
          <div className="flex p-4 rounded-l-md items-center justify-center bg-red-600 min-h-full">
            <PiWarningCircle className="text-3xl text-white" />
          </div>
          <div className="flex flex-col gap-1 h-full justify-center py-2 items-start">
            <label className="font-poppins font-semibold text-gray-700 text-sm">
              Kayıtlı ön yazınız bulunamadı.
            </label>
            <label className="font-poppins font-semibold text-gray-700 text-sm">
              <button
                className="text-mains underline"
                onClick={() => {
                  setSelectedAbstract((prev) => undefined);
                  setIsAbstractModalOpen(true);
                }}
              >
                Ön Yazı Ekle
              </button>
              &apos;ye tıklayarak ön yazı ekleyebilirsiniz.
            </label>
          </div>
        </div>
      )}

      {abstracts.length > 0 && (
        <div className="hidden lg:flex h-10 w-full px-3 md:px-4 py-2 items-center justify-between hover:bg-[#ebebeb] duration-100">
          <span className="flex justify-start w-full text-xs truncate poppins-semibold text-mains basis-4/12">
            Ön Yazı Adı
          </span>

          <span className="flex justify-start w-full text-xs poppins-semibold text-mains basis-4/12">
            İçerik
          </span>

          <span className="flex justify-end w-full text-xs poppins-semibold text-mains basis-4/12">
            İşlemler
          </span>
        </div>
      )}

      {abstracts.map((item) => (
        <AbstractItem
          key={`absItem${item.id}`}
          abstract={item}
          getAllDatas={getAllDatas}
          setSelectedAbstract={setSelectedAbstract}
          setIsModalOpen={setIsEditAbstractModalOpen}
        />
      ))}
    </div>
  );
}
