import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IconArrowLeft } from "@tabler/icons-react";
import HamburgerIcon from "../components/hamburger-icon";
import HeaderButton from "../components/header-button";
import HeaderButtons from "./components/header-buttons";
import { HamburgerContent } from "../components/hamburger/hamburger-content";
import SearchBar from "../../../common/search-bar";
import { useCookie } from "../../../../hooks/useCookie";
import { CookieName } from "../../../../constants/enums";
import Cookies from "js-cookie";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHamburger, setIsHamburger] = useState(false);
  const hamburgerRef = useRef<HTMLDivElement | null>(null);
  const hamburgerIconRef = useRef<HTMLDivElement | null>(null);
  const { getCookie } = useCookie();
  const baseLogoURL =
    "https://isbull.s3.eu-north-1.amazonaws.com/settings/J3KHCRhMrpXMXwT6aX2VVUAwodZYErFbUQulK6sy.png";
  const fixedLogoURL =
    "https://isbull.s3.eu-north-1.amazonaws.com/logo-mavi.png";
  const companyUserCookie = getCookie(CookieName.CompanyUserData);
  const companyUser = companyUserCookie ? JSON.parse(companyUserCookie) : null;

  const userCookie = getCookie(CookieName.UserData);
  const user = userCookie ? JSON.parse(userCookie) : "";

  const userToken = Cookies.get("userToken");
  const companyUserTokenCookie = getCookie(CookieName.CompanyUserToken);
  const companyUserToken = companyUserTokenCookie ? companyUserTokenCookie : "";

  const fixedTop = isScrolled ? "top-0" : "top-[-80px]";
  const isShown = isScrolled ? "flex" : "hidden";

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 75) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        hamburgerRef.current &&
        !hamburgerRef.current.contains(event.target as Node) &&
        hamburgerIconRef.current &&
        !hamburgerIconRef.current.contains(event.target as Node)
      ) {
        setIsHamburger(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="w-full sticky top-0 drop-shadow-lg shadow-lg bg-white lg:bg-[#155CAE] z-[100]">
        <div className=" flex items-center justify-between lg:justify-center w-full h-12  select-none lg:h-16 ">
          {/* Mobile LeftArrow Icon */}
          <div
            className="cursor-pointer lg:hidden"
            onClick={() => {
              navigate(-1);
            }}
          >
            <IconArrowLeft className="text-blue-700 ml-2" size={28} />
          </div>
          {/* Mobile Header Logo */}
          <div className="my-auto h-3/4 lg:hidden ">
            <img
              onClick={() => navigate("/")}
              src={fixedLogoURL}
              alt="logo"
              className="object-contain h-full cursor-pointer max-w-[100px]"
            />
          </div>
          <div className="lg:hidden mr-2" ref={hamburgerIconRef}>
            <HamburgerIcon state={isHamburger} setState={setIsHamburger} />
          </div>
          <Link to="/" className="hidden lg:block h-1/3 mr-4 xl:mr-16">
            <img
              src={baseLogoURL}
              className="object-contain h-full m-auto"
              alt="logo"
            />
          </Link>
          <HeaderButtons />

          {!companyUserToken && !userToken && (
            <div className="lg:flex hidden justify-center items-center pl-1 xl:pl-5">
              <a
                href="/isveren/giris-yap"
                className="text-white bg-[#DE5B2B] py-[6px] px-4 rounded-full font-poppins poppins-regular text-sm"
              >
                Ücretsiz İlan Ver
              </a>
            </div>
          )}

          {!companyUserToken && !userToken && (
            <div className="lg:flex hidden justify-center items-center pl-2 xl:pl-5 gap-2 xl:gap-5">
              <a
                href="/profilim/giris-yap"
                className="relative text-[#155CAE] bg-white py-[6px] px-6 rounded-full font-poppins poppins-regular text-sm min-w-[90px] xl:min-w-[110px] flex justify-center items-center transition duration-300 pl-1 pr-1 overflow-hidden group border border-white"
              >
                <span className="text-[#155CAE] font-poppins poppins-medium text-md text-sm relative z-10 group-hover:text-white transition-all duration-500">
                  Giriş Yap
                </span>
                <span className="absolute inset-0 bg-[#155CAE] w-0 h-full group-hover:w-full transition-all duration-500"></span>
              </a>

              <a
                href="/profilim/kayit-ol"
                className="relative text-white bg-[#155CAE] py-[6px] px-6 rounded-full font-poppins poppins-regular text-sm min-w-[90px] xl:min-w-[110px] flex justify-center items-center transition duration-300 pl-1 pr-1 overflow-hidden group border border-white"
              >
                <span className="text-white font-poppins poppins-medium text-md text-sm relative z-10 transition-all duration-500 group-hover:text-[#155CAE]">
                  Üye Ol
                </span>
                <span className="absolute inset-0 bg-white w-0 h-full group-hover:w-full transition-all duration-500"></span>
              </a>
            </div>
          )}
        </div>
      </div>
      <div ref={hamburgerRef} className="z-99">
        {companyUser ? (
          <HamburgerContent
            isLogined={true}
            isHamburger={isHamburger}
            companyUser={companyUser}
            userType="company"
            setIsHamburger={setIsHamburger}
          />
        ) : (
          <HamburgerContent
            isLogined={true}
            isHamburger={isHamburger}
            companyUser={user}
            userType="user"
            setIsHamburger={setIsHamburger}
          />
        )}
      </div>
      {/* Top Fixed Header */}
      {/* <div
        className={`transition-all h-16 w-full ${fixedTop} hidden lg:block fixed z-50 bg-white`}
      >
        <div className={`w-full h-full shadow-x-2xl container mx-auto`}>
          <div
            className={`h-full w-full justify-between items-center ${isShown}`}
          >
            <Link to="/" className="my-auto h-2/3">
              <img
                src={fixedLogoURL}
                alt="logo"
                className="object-contain h-full"
              />
            </Link>
            <div className="w-1/2 h-1/2">
              <SearchBar />
            </div>
            <div className="flex h-2/3 gap-x-2">
              <HeaderButton
                variant="default"
                text="İŞVEREN"
                isLoginRequire={true}
                href="/isveren"
              />
              <HeaderButton
                variant="default"
                text="İŞ ARAYAN"
                isLoginRequire={true}
                href="/profilim"
              />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Header;
