import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Button,
  InputArea,
  SelectDropdown,
  Textarea,
} from "../../../../../../components/isbul-ui";
import { dbUserExamType } from "../../../../../../types";
import { useForm, SubmitHandler } from "react-hook-form";
import { useParams } from "react-router-dom";
import { IoAdd, IoTrashBin, IoTrashBinOutline } from "react-icons/io5";
import Swal from "sweetalert2";
import Axios from "../../../../../../services/axios";
import { FaCity, FaRegTrashCan } from "react-icons/fa6";
import { MdEdit } from "react-icons/md";
import { PiExamFill } from "react-icons/pi";
import { FaCalendar, FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { CgDetailsMore } from "react-icons/cg";
import { HiDocument } from "react-icons/hi";
import { useIsMobile } from "../../../../../../hooks/useIsMobile";
import FormModal from "../../../../../../components/isbul-ui/form-modal";
import { CvLayoutContext, cvLayoutContextType } from "../..";

const axios = new Axios();

const currentYear = new Date().getFullYear();

const totalYear = currentYear - 1974 + 1;

const years: number[] = Array.from({ length: totalYear }, (_, i) => i + 1974);

type payloadDataType = {
  cv_id: number;
  name: string;
  company: string;
  year: number;
  note: string;
  detail: string | null;
};

export default function ExamForm({
  exam,
  getAllExams,
  examsLength,
  isAddClicked,
  setIsAddClicked,
}: {
  exam: dbUserExamType | null;
  getAllExams?: () => void;
  examsLength?: number;
  isAddClicked?: boolean;
  setIsAddClicked?: Dispatch<SetStateAction<boolean>>;
}) {
  const { isEnglish }: cvLayoutContextType = useContext(CvLayoutContext);

  const params = useParams();
  const cvId = params.slug;

  const isMobile = useIsMobile();

  /* Page states */
  const [isEditOpen, setIsEditOpen] = useState<boolean>(exam ? false : true);
  const [isPageOpen, setIsPageOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // TODO: eklendi
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isAddClicked != undefined && setIsAddClicked != undefined && !exam) {
      if (isAddClicked) {
        setIsModalOpen(true);
        setIsAddClicked(false);
      }
    }
  }, [isAddClicked]);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
    getFieldState,
  } = useForm<payloadDataType>({
    defaultValues: {
      company: exam?.company || "",
      detail: exam?.detail || "",
      name: exam?.name || "",
      note: exam?.note.toString() || "",
    },
  });

  const onSubmit: SubmitHandler<payloadDataType> = (data) => {
    if (exam) {
      updateExam(data);
    } else {
      addExam(data);
    }
  };

  /* Form field states */
  const [selectedYear, setSelectedYear] = useState<number | null>(
    exam?.year || null
  );

  /* Validation custom registers */
  register("year", {
    required: `${isEnglish ? "Exam Year" : "Sınav Yılı"}`,
  });

  /* State change form set */
  useEffect(() => {
    if (selectedYear) {
      setValue("year", selectedYear);
    }
  }, [selectedYear]);

  /* Custom form values watching */
  const name = watch("name") || "";
  const company = watch("company") || "";
  const note = watch("note") || "";
  const detail = watch("detail") || "";

  /* Form fill if exist */
  useEffect(() => {
    if (exam) {
      setValue("company", exam.company);
      setValue("detail", exam.detail);
      setValue("name", exam.name);
      setValue("note", exam.note.toString());
      setSelectedYear(exam.year);
      return;
    }
    setValue("company", "");
    setValue("detail", "");
    setValue("name", "");
    setValue("note", "");
    setSelectedYear(null);

    reset();
  }, [isModalOpen]);

  if (isEditOpen) {
    return (
      <div className="flex flex-col gap-2">
        {!exam && (
          /* Page header if exam not exist */
          <div className="flex flex-row items-center justify-between px-4">
            {/* Form modal for header */}
            <Button
              size="sm"
              onClick={() => {
                // TODO: Modal open eklenecek
                setIsModalOpen(true);
                setIsEditOpen(true);
              }}
            >
              <Button.Icon>
                <IoAdd />
              </Button.Icon>
              {isEnglish ? "Add Exam" : "Sınav Ekle"}
            </Button>
          </div>
        )}

        {/* Header hr  */}
        {!exam && <hr className="border-mains" />}

        {/* Form modal for form edit */}
        <FormModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          onClosing={() => {
            setIsModalOpen(false);
            if (exam) {
              setIsEditOpen(false);
            } else {
              setIsEditOpen(true);
            }
          }}
        >
          <FormModal.Content>
            <form
              className="flex flex-col gap-y-4 px-6 pt-2 pb-20 lg:pb-2"
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
            >
              {/* Form header */}
              <label className="text-mains font-semibold">
                {exam
                  ? `${isEnglish ? "Edit Exam" : "Sınavı Düzenle"}`
                  : `${isEnglish ? "Add Exam" : "Sınav Ekle"}`}
              </label>
              <hr className="border-mains" />
              <div className="flex flex-col gap-y-4 lg:flex-row gap-2 items-start">
                {/* Name input */}
                <div className="flex basis-1/4 w-full lg:w-auto px-0 lg:px-1">
                  <InputArea
                    className="w-full"
                    required
                    mode={name.length == 0 ? "empty" : "dirty"}
                    error={errors.name?.message ? true : false}
                  >
                    <InputArea.Label>
                      {isEnglish ? "Exam Name" : "Sınav Adı"}
                    </InputArea.Label>
                    <InputArea.Input
                      {...register("name", {
                        required: `${isEnglish ? "Exam Name" : "Sınav Adı"}`,
                      })}
                      value={name || ""}
                      onChange={(e) => {
                        setValue("name", e.target.value);
                        clearErrors("name");
                      }}
                    />
                  </InputArea>
                </div>

                {/* Company input */}
                <div className="flex basis-1/4 w-full lg:w-auto px-0 lg:px-1">
                  <InputArea
                    className="w-full"
                    required
                    mode={company.length == 0 ? "empty" : "dirty"}
                    error={errors.company?.message ? true : false}
                  >
                    <InputArea.Label>
                      {isEnglish ? "Institution" : "Kurum"}
                    </InputArea.Label>
                    <InputArea.Input
                      {...register("company", {
                        required: `${isEnglish ? "Institution" : "Kurum"}`,
                      })}
                      value={company || ""}
                      onChange={(e) => {
                        setValue("company", e.target.value);
                        clearErrors("company");
                      }}
                    />
                  </InputArea>
                </div>

                {/* Year dropdown */}
                <div className="flex basis-1/4 w-full lg:w-auto px-0 lg:px-1">
                  <SelectDropdown
                    className="w-full"
                    required
                    mode={selectedYear ? "dirty" : "empty"}
                    error={errors.year?.message ? true : false}
                  >
                    <SelectDropdown.Label>
                      {isEnglish ? "Year" : "Yıl"}
                    </SelectDropdown.Label>
                    <SelectDropdown.Trigger>
                      {selectedYear
                        ? selectedYear
                        : `${isEnglish ? "Select" : "Seçiniz"}`}
                    </SelectDropdown.Trigger>
                    <SelectDropdown.Items>
                      {years
                        .sort((a, b) => b - a)
                        .map((year) => {
                          return (
                            <SelectDropdown.Item
                              key={`yearSelect${year.toString()}`}
                              onClick={() => {
                                setSelectedYear(year);
                                clearErrors("year");
                              }}
                            >
                              {year}
                            </SelectDropdown.Item>
                          );
                        })}
                    </SelectDropdown.Items>
                  </SelectDropdown>
                </div>

                {/* Note input */}
                <div className="flex basis-1/4 w-full lg:w-auto px-0 lg:px-1">
                  <InputArea
                    className="w-full"
                    required
                    mode={note.toString().length == 0 ? "empty" : "dirty"}
                    error={errors.note?.message ? true : false}
                  >
                    <InputArea.Label>
                      {isEnglish ? "Exam Grade" : "Sınav Notu"}
                    </InputArea.Label>
                    <InputArea.Input
                      {...register("note", {
                        required: `${isEnglish ? "Exam Grade" : "Sınav Notu"}`,
                        max: {
                          value: 1000,
                          message: `${isEnglish ? "Exam Grade" : "Sınav Notu"}`,
                        },
                      })}
                      max={10000}
                      type="number"
                      value={note || ""}
                      onChange={(e) => {
                        setValue("note", e.target.value);
                        clearErrors("note");
                      }}
                    />
                  </InputArea>
                </div>
              </div>

              {/* Detail textarea */}
              <div className="flex px-0 lg:px-1">
                <Textarea
                  className="w-full"
                  mode={detail.length == 0 ? "empty" : "dirty"}
                >
                  <Textarea.Label>
                    {isEnglish ? "Description" : "Açıklama"}
                  </Textarea.Label>
                  <Textarea.Field
                    placeholder={
                      isEnglish
                        ? "Adding exam information articulates academic achievements clearly, strengthens the resume, and draws attention."
                        : "Sınav bilgileri eklemek akademik başarılarını net bir şekilde ifade ederek, özgeçmişini güçlendirir ve dikkat çeker."
                    }
                    className="min-h-[200px]"
                    value={detail || ""}
                    onChange={(e) => {
                      setValue("detail", e.target.value);
                    }}
                  />
                </Textarea>
              </div>
              {!exam && (
                <div className="flex flex-row gap-2 ml-auto items-center justify-between mt-6">
                  <Button
                    type="button"
                    variant="secondary"
                    size="sm"
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                  >
                    {isEnglish ? "Cancel" : "Vazgeç"}
                  </Button>
                  <Button type="submit" size="sm">
                    {isEnglish ? "Save" : "Kaydet"}
                  </Button>
                </div>
              )}

              {/* Save and close buttons for exist exam */}
              {exam && (
                <div className="flex flex-row items-center gap-2 justify-between mt-6">
                  <div className="flex ml-auto flex-row items-center gap-2">
                    <Button
                      type="button"
                      variant="secondary"
                      size="sm"
                      onClick={() => setIsEditOpen(false)}
                    >
                      {isEnglish ? "Cancel" : "Vazgeç"}
                    </Button>
                    <Button type="submit" size="sm">
                      {isEnglish ? "Save" : "Kaydet"}
                    </Button>
                  </div>
                </div>
              )}
            </form>
          </FormModal.Content>
        </FormModal>
      </div>
    );
  } else {
    return (
      <>
        {/* Desktop section */}
        <div className="hidden lg:flex flex-row relative px-1 py-2 gap-4 border-transparent hover:border-gray-300 border-dashed border-2 rounded-md transition-all">
          <FormModal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
            <FormModal.Trigger>
              <div className="flex flex-col gap-2 absolute -top-2 right-2 lg:top-4 lg:right-4 z-10">
                <Button
                  variant="ghost"
                  size="xs"
                  className="aspect-square !rounded-full !border-none"
                  onClick={() => {
                    setIsEditOpen(true);
                    //setIsPageOpen(true)
                    //TODO: set is modal open
                    setIsModalOpen(true);
                  }}
                >
                  <Button.Icon>
                    <FaPencilAlt className="text-xl" />
                  </Button.Icon>
                </Button>

                {/* TODO: Button eklenecek */}
                <Button
                  variant="ghost"
                  size="xs"
                  className="aspect-square !rounded-full !border-none"
                  onClick={() => {
                    Swal.fire({
                      icon: "warning",
                      title: `${
                        isEnglish
                          ? "Are you sure to delete exam?"
                          : "Sınav bilgisini silmek istediğinizden emin misiniz?"
                      }`,
                      confirmButtonText: `${isEnglish ? "Yes" : "Evet"}`,
                      cancelButtonText: `${isEnglish ? "No" : "Hayır"}`,
                      preConfirm: () => {
                        deleteExam();
                      },
                      showCancelButton: true,
                    });
                  }}
                >
                  <Button.Icon className="text-xl">
                    <IoTrashBinOutline />
                  </Button.Icon>
                </Button>
              </div>
            </FormModal.Trigger>
          </FormModal>
          <div className="hidden lg:flex flex-col border-r pr-4">
            <PiExamFill className="text-9xl text-mains/40" />
            <label className="text-center font-poppins font-semibold text-mains">
              {isEnglish ? "Exam" : "Sınav"}
            </label>
          </div>
          <div className="flex flex-col w-full">
            <div className="flex flex-row items-center h-full w-full">
              <div className="flex flex-col basis-1/2 gap-3 justify-center items-start h-full">
                {/* Exam name sections */}
                <div className="flex flex-col">
                  <label className="hidden lg:flex items-center gap-1 text-xs font-poppins font-semibold text-gary-700">
                    <HiDocument className="text-xs" />
                    {isEnglish ? "Exam Name" : "Sınav Adı"}
                  </label>
                  <label className="font-poppins text-gray-600 text-sm">
                    {exam?.name}
                  </label>
                </div>

                {/* Company section */}
                <div className="flex flex-col">
                  <label className="hidden lg:flex items-center gap-1 text-xs font-poppins font-semibold text-gary-700">
                    <FaCity className="text-xs" />
                    {isEnglish ? "Institution" : "Kurum"}
                  </label>
                  <label className="font-poppins text-gray-600 text-sm">
                    {exam?.company}
                  </label>
                </div>
              </div>
              <div className="flex flex-col basis-1/2 gap-3 justify-center items-start h-full">
                {/* Year section */}
                <div className="flex flex-col">
                  <label className="hidden lg:flex items-center gap-1 text-xs font-poppins font-semibold text-gary-700">
                    <FaCalendar className="text-xs" />
                    {isEnglish ? "Year" : "Yıl"}
                  </label>
                  <label className="font-poppins text-gray-600 text-sm">
                    {exam?.year}
                  </label>
                </div>

                {/* Memo section */}
                <div className="flex flex-col">
                  <label className="hidden lg:flex items-center gap-1 text-xs font-poppins font-semibold text-gary-700">
                    <PiExamFill className="text-xs" />
                    {isEnglish ? "Exam Grade" : "Not"}
                  </label>
                  <label className="font-poppins text-gray-600 text-sm">
                    {exam?.note}
                  </label>
                </div>
              </div>
            </div>

            {/* Exam detail if exist */}
            {exam?.detail && (
              <div className="flex flex-col w-full">
                <label className="hidden lg:flex items-center gap-1 text-xs font-poppins font-semibold text-gary-700">
                  <CgDetailsMore className="text-xs" />
                  {isEnglish ? "Description" : "Açıklama"}
                </label>
                <label className="font-poppins text-gray-600 text-sm line-clamp-3">
                  {exam.detail}
                </label>
              </div>
            )}
          </div>
        </div>

        {/* Mobile section */}
        <div className="flex flex-row lg:hidden px-4">
          <div className="flex flex-col gap-2 flex-1">
            {/* Exam name */}
            <div className="flex flex-col">
              <label className="font-poppins text-[13px] font-semibold text-gray-900">
                {isEnglish ? "Exam Name" : "Sınav Adı"}
              </label>
              <label className="font-poppins text-[13px] text-gray-800">
                {exam?.name}
              </label>
            </div>

            {/* Company section */}
            <div className="flex flex-col">
              <label className="font-poppins text-[13px] font-semibold text-gray-900">
                {isEnglish ? "Institution" : "Kurum"}
              </label>
              <label className="font-poppins text-[13px] text-gray-800">
                {exam?.company}
              </label>
            </div>

            {/* Year section */}
            <div className="flex flex-col">
              <label className="font-poppins text-[13px] font-semibold text-gray-900">
                {isEnglish ? "Year" : "Yıl"}
              </label>
              <label className="font-poppins text-[13px] text-gray-800">
                {exam?.year}
              </label>
            </div>
          </div>

          {/* Edit and delete buttons */}
          <div className="flex flex-col gap-2 items-center">
            <button
              className="flex flex-col gap-1 items-center text-mains font-poppins text-xs"
              onClick={() => {
                setIsEditOpen(true);
                setIsPageOpen(true);
                setIsModalOpen(true);
              }}
            >
              <FaPencilAlt className="text-mains text-base" />
              {isEnglish ? "Edit" : "Düzenle"}
            </button>
            <button
              className="flex flex-col gap-1 items-center text-mains font-poppins text-xs"
              onClick={() => {
                Swal.fire({
                  icon: "warning",
                  title: `${
                    isEnglish
                      ? "Are you sure to delete exam?"
                      : "Sınav bilgisini silmek istediğinizden emin misiniz?"
                  }`,
                  confirmButtonText: `${isEnglish ? "Yes" : "Evet"}`,
                  cancelButtonText: `${isEnglish ? "no" : "Hayır"}`,
                  preConfirm: () => {
                    deleteExam();
                  },
                  showCancelButton: true,
                });
              }}
            >
              <IoTrashBinOutline className="text-base" />
            </button>
          </div>
        </div>
      </>
    );
  }

  async function addExam({ cv_id, note, ...others }: payloadDataType) {
    if (isLoading) return;

    setIsLoading(true);

    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/ex/create`,
        type: "post",
        payload: {
          cv_id: cvId,
          note: parseInt(note),
          ...others,
        },
      });

      if (response && response.status == 200) {
        if (getAllExams) {
          getAllExams();
          Swal.fire({
            icon: "success",
            title: "Başarılı",
            text: "Sınav eklendi.",
            confirmButtonText: "Tamam",
          });
        }

        /* Clear form fields */
        setValue("company", "");
        setValue("detail", "");
        setValue("name", "");
        setValue("note", "");
        setSelectedYear(null);

        setIsPageOpen(false);
        setIsModalOpen(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Sınav eklenirken bir hata meydana geldi.",
          confirmButtonText: "Tamam",
        });
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Sınav eklenirken beklenmedik bir hata meydana geldi.",
        confirmButtonText: "Tamam",
      });
    }

    setIsLoading(false);
  }

  async function updateExam({ note, cv_id, ...others }: payloadDataType) {
    if (isLoading) return;

    setIsLoading(true);

    if (isNaN(parseInt(note))) {
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Lütfen geçerli bir sınav notu giriniz",
        confirmButtonText: "Tamam",
      });

      return;
    }

    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/ex/update/${exam?.id}`,
        type: "put",
        payload: {
          cv_id: cvId,
          note: parseInt(note),
          ...others,
        },
      });

      if (response && response.status == 200) {
        setIsEditOpen(false);
        if (getAllExams) {
          getAllExams();
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Sınav güncellenirken bir hata meydana geldi",
          confirmButtonText: "Tamam",
        });
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Sınav güncellenirken beklenmedik bir hata meydana geldi",
        confirmButtonText: "Tamam",
      });
    }

    setIsLoading(false);
  }

  async function deleteExam() {
    if (isLoading) return;

    setIsLoading(true);

    try {
      const response = await axios.request({
        endpoint: `user/profilim/cv/ex/delete/${exam?.id}?cv_id=${cvId}`,
        type: "delete",
        payload: {
          cv_id: cvId,
        },
      });

      if (response && response.status == 200) {
        if (getAllExams) {
          getAllExams();
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Sınav silinirken bir hata meydana geldi.",
          confirmButtonText: "Tamam",
        });
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Sınav silinirken beklenemdik bir hata meydana geldi.",
        confirmButtonText: "Tamam",
      });
    }

    setIsLoading(false);
  }
}
