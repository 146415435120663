import * as React from "react";
import { PackagesHeader } from "../packages-header";
import { CardSection } from "./card-section";
import { OtherSection } from "./other-section";
import { PackageType } from "../../constants/enum";
import { mockHeaderButtonContents } from "../../mocks";
import { ICustomCard } from "../../types/ICustomCard";

interface IPackagesContentsProps {
  readonly packages: ICustomCard[];
  freeNavigate?: () => void;
}

function PackagesContents(props: IPackagesContentsProps): React.ReactElement {
  const [headerButtonContents, setHeaderButtonContents] = React.useState<
    string[]
  >(mockHeaderButtonContents);
  const [selectedButton, setSelectedButton] = React.useState<string>(
    PackageType.Paketler
  );

  React.useEffect(() => {
    setHeaderButtonContents((prev) => {
      return prev.map((content) => {
        return content === selectedButton ? `${content}` : content;
      });
    });
  }, [selectedButton]);

  return (
    <div className="flex flex-col gap-y-1 px-2 md:px-0 mt-6">
      <PackagesHeader
        headerButtonContents={headerButtonContents}
        selectedButton={selectedButton}
        setSelectedButton={setSelectedButton}
      />
      <div>
        {selectedButton === PackageType.Paketler ? (
          <CardSection
            packages={props.packages}
            freeNavigate={props.freeNavigate}
          />
        ) : selectedButton ? (
          <div className="mb-6">
            <div className="flex justify-between items-center p-4 bg-white rounded-lg shadow-md mt-4 relative mb-2">
              <h2 className="text-2xl text-mains font-semibold">
                {selectedButton}
              </h2>
            </div>
            <OtherSection
              selectedButton={selectedButton}
              packages={props.packages}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
}
export { PackagesContents };
