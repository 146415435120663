import React from "react";

interface LinkItem {
  text: string;
  url: string;
}
interface AccordionItemProps {
  title: string;
  content: LinkItem[][];
  isOpen: boolean;
  onToggle: () => void;
}

function splitIntoColumns(data: LinkItem[]): LinkItem[][] {
  const columns: LinkItem[][] = [[], [], [], []];
  const itemsPerColumn = Math.ceil(data.length / columns.length);

  data.forEach((item, index) => {
    const columnIndex = Math.floor(index / itemsPerColumn);
    if (columns[columnIndex]) {
      columns[columnIndex].push(item);
    }
  });

  return columns;
}
const AccordionItem = React.forwardRef<HTMLDivElement, AccordionItemProps>(
  ({ title, content, isOpen, onToggle }, ref) => {
    // Split content into 4 columns
    const columns = splitIntoColumns(content[0]);

    console.log(columns);

    return (
      <div ref={ref} className="border-b mb-4 mx-auto lg:w-3/4 bg-[#F5F5F5]">
        <button
          style={{ padding: "18px 22px" }}
          className="text-[#2253AF] py-2 px-4 w-full text-left flex justify-between items-center bg-[#FFFFFE]"
          onClick={onToggle}
        >
          <h2 className="text-4xl" style={{ fontWeight: "600" }}>
            {title}
          </h2>
        </button>
        <div
          className={`accordion-transition ${isOpen ? "accordion-open" : ""}`}
        >
          <div className="p-4 flex flex-col md:flex-row justify-around">
            {columns.map((column, index) => (
              <ul key={index} className="mb-0 w-full md:w-1/4">
                {column.map((item, itemIndex) => (
                  <li className="mb-4 section-accordion-a" key={itemIndex}>
                    <a target="blank" href={item.url}>
                      {item.text}
                    </a>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
      </div>
    );
  }
);
export default AccordionItem;
