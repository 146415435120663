import React from "react";
import image from "../../../../../assets/images/checked.png";
import { Link } from "react-router-dom";
import AdvertSectionBody from "../../ad-cards/idnex";

type CvSelect = {
  inPopup?: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
  onReady?: () => void;
  cvs: any;
  adData: any;
};

const AppliedSuccess: React.FC<CvSelect> = ({
  inPopup = false,
  onSuccess = () => {},
  onCancel = () => {},
  onReady = () => {},
  cvs,
  adData,
}) => {
  onReady && onReady();
  console.log(adData);
  return (
    <div>
      <div className={"p-5"}>
        <div className={"h-40"}>
          <img src={image} alt={"image"} className={"h-full"} />
        </div>
        <div className={" text-center text-wrap"}>
          Başvurunuz{" "}
          <span className={"font-bold"}>
            {adData.company_detail.company_name
              ? adData.company_detail.company_name
              : "*****"}
          </span>{" "}
          firmasına iletilmiştir
        </div>
        <div className={"text-center text-wrap my-5"}>
          Başvurunuzla ilgili cevaplama, değerlendirme ve diğer işlemler{" "}
          <span className={"font-bold"}>
            {adData.company_detail.company_name
              ? adData.company_detail.company_name
              : "*****"}
          </span>{" "}
          firması tarafından gerçekleştirilecektir
        </div>
      </div>
      <AdvertSectionBody
        advertId={adData.advert.id}
        showTitle={true}
        dontShowPopUp={true}
      />
      <div className="w-full rounded-md text-xs md:text-xl p-3 md:p-5 border-t flex gap-5 ">
        <div className={"flex-1"}>
          <button
            onClick={() => {
              onCancel && onCancel();
            }}
            className={`bg-gray-400 text-white text-sm md:text-lg font-poppins poppins-medium text-base w-full  rounded-md h-12 flex justify-center items-center gap-1`}
          >
            KAPAT
          </button>
        </div>

        <div className={"flex-1"}>
          <Link to={"/is-ilanlari"}>
            <button
              className={`bg-mains text-white text-sm md:text-lg font-poppins poppins-medium text-base w-full min-w-165px  rounded-md h-12 flex justify-center items-center gap-1`}
            >
              DİĞER İLANLARI GÖR
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AppliedSuccess;
