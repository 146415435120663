import { Helmet } from "react-helmet-async";

const BreadcrumbJsonLd = () => {
  const breadcrumbList = [
    {
      "@type": "ListItem",
      position: 1,
      name: "Ana Sayfa",
      item: "/",
    },
    {
      "@type": "ListItem",
      position: 2,
      name: "Tüm İş İlanları",
      item: "/is-ilanlari",
    },
  ];

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: breadcrumbList,
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
    </Helmet>
  );
};

export default BreadcrumbJsonLd;
