import * as React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import services from "../../../../../../../services/login-services";
import { useCookie } from "../../../../../../../hooks/useCookie";
import { CookieName } from "../../../../../../../constants/enums";
import { CustomInput } from "../../../../../../form-components/input";
import ForgotPasswordModal from "../../../ForgotPasswordModal";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { IoInformationCircleOutline } from "react-icons/io5";
import { LuLoader2 } from "react-icons/lu";

function Login() {
  
  const location = useLocation();
  console.log(location)
  const fromApplication = location.state?.fromApplication || false;
  const message = location.state?.message || "";
  const [missingFields, setMissingFields] = React.useState<string[]>([]);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { setCookie } = useCookie();
  const router = useNavigate();
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] =
    React.useState(false);

  const handleForgotPasswordModal = () => {
    setForgotPasswordModalOpen(true);
  };


  const handleBlur = (fieldName: string) => {
    setMissingFields((prev) => prev.filter((field) => field !== fieldName));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (isLoading) return;
    const fields: string[] = [];
    if (!email) fields.push("E-posta veya Cep Telefonu");
    if (!password) fields.push("Şifre");

    if (fields.length > 0) {
      setMissingFields(fields);
      Swal.fire({
        title: "Uyarı!",
        html:
          "Eksik alanlar: <br/><strong style='color: red;'>" +
          fields.join(", ") +
          "</strong>",
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Tamam",
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      return;
    }
    setIsLoading(true);
    setError("");

    const userData = { email, password };

    if (!userData.email || !userData.password) {
      setError("E-posta ve şifre alanları boş bırakılamaz.");
      setIsLoading(false);
      return;
    }
    try {
      const data = await services.loginUser(userData);
      setCookie(CookieName.UserToken, data.data.access_token);
      setCookie(CookieName.UserData, JSON.stringify(data.data.user));
      router("/profilim", { replace: true });
    } catch (error: any) {
      setError(error.response?.data?.error);
    } finally {
      setIsLoading(false);
    }
  };

  const isError = (fieldName: string) => missingFields.includes(fieldName);

  return (
    <div
      className={`h-auto items-center justify-center w-full flex font-poppins flex-col`}
    >
      {message && (
        <>
          <div
            className={`flex items-center shadow-lg border-2 h-[100px] w-[350px] md:w-[475px] border-red-600 bg-white rounded-md mt-4 gap-4`}
          >
            <div className="bg-red-600 w-2/12 lg:w-3/12 h-full items-center justify-center flex">
              <IoInformationCircleOutline className="text-white text-4xl md:text-5xl" />
            </div>

            <div>
              {fromApplication && (
                <div className="alert alert-warning text-sm px-1 font-poppins poppins-regular">
                  <span className="font-bold">"{message}"</span> ilanına
                  başvurunuzu tamamlamak için giriş yapın ve özgeçmiş oluşturun.
                </div>
              )}
              {/* Giriş yapma formu burada */}
            </div>
          </div>
        </>
      )}

      <div className="flex flex-col w-[100%] md:w-[470px] min-w-2xl h-screen lg:h-auto py-8 px-4 md:px-10 bg-transparent md:bg-white rounded-md md:shadow-md gap-y-4 md:my-10">
        <div className="flex flex-col w-full gap-y-2">
          <h4 className="font-poppins font-[600] text-xl md:text-2xl text-[#2253af]">
            İş Arayan Üye Girişi
          </h4>
          <p className="text-sm md:text-[15px] poppins-semibold text-[#63636c]">
            Üye değil misin?{" "}
            <a
              href="/profilim/kayit-ol"
              className="text-[#ff7f1d] poppins-semibold text-[15px]"
            >
              Üye Ol.
            </a>
          </p>
        </div>
        <form className="flex flex-col gap-y-4">
          <CustomInput
            required={true}
            label="E-posta veya Cep Telefonu"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={() => handleBlur("E-posta veya Cep Telefonu")}
            className={`w-full h-full px-2 text-xs text-black outline-none poppins-medium`}
            error={isError("E-posta veya Cep Telefonu")}
          />
          <CustomInput
            required={true}
            label="Şifre"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onBlur={() => handleBlur("Şifre")}
            className={`w-full h-full px-2 text-xs text-black outline-none poppins-medium`}
            error={isError("Şifre")}
          />
          <div className="flex justify-between">
            <div className="flex items-center gap-x-2">
              <input
                className="w-4 h-4 rounded-md accent-title"
                type="checkbox"
              />
              <span className="text-[10px] text-gray-500 poppins-medium">
                Beni Hatırla
              </span>
            </div>
            <div className="flex">
              <Link
              to={'/sifremi-unuttum/is-arayan'}
                className="text-xs cursor-pointer hover:underline text-title poppins-medium"
              >
                Şifremi Unuttum
              </Link>
            </div>
          </div>

          <div className="w-full max-h-[50px] h-[50px] mt-10">
            <button
              type="submit"
              onClick={handleSubmit}
              className="flex items-center justify-center w-full h-full rounded-lg bg-[#1a5edd]"
            >
              <span className="text-base font-semibold text-white poppins-medium">
                {isLoading ? (
                  <LuLoader2 className={"animate-spin"}></LuLoader2>
                ) : (
                  "GİRİŞ YAP"
                )}
              </span>
            </button>
          </div>
        </form>
        <div className={"text-sm text-red-500"}> {error}</div>
      </div>

      {forgotPasswordModalOpen && (
        <ForgotPasswordModal
          open={forgotPasswordModalOpen}
          onClose={() => setForgotPasswordModalOpen(false)}
        />
      )}
    </div>
  );
}

export default Login;
