import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  InputArea,
  SelectDropdown,
  SelectSearchDropdown,
  Textarea,
} from "../../../../../../components/isbul-ui";
import { Checkbox } from "../../../../../../components/isbul-ui/checkbox";
import {
  cdnCityType,
  cdnCountryType,
  cdnPositionLevelType,
  cdnPositionType,
  cdnSectionType,
  cdnSectorType,
  cdnWorkingTypeType,
  dbUserJobExperienceType,
} from "../../../../../../types";
import {
  IoAdd,
  IoCheckmark,
  IoDocumentText,
  IoTrashBinOutline,
} from "react-icons/io5";
import { FaMapLocationDot, FaRegTrashCan, FaUserDoctor } from "react-icons/fa6";
import { getPositions } from "../../../../../../customHooks/getPositions";
import { getPositionLevels } from "../../../../../../customHooks/getPositionLevels";
import { getSectors } from "../../../../../../customHooks/getSectors";
import { getSections } from "../../../../../../customHooks/getSections";
import { getCountries } from "../../../../../../customHooks/getCountries";
import { getCities } from "../../../../../../customHooks/getCities";
import { useForm, SubmitHandler } from "react-hook-form";
import Swal from "sweetalert2";
import Axios from "../../../../../../services/axios";
import { useParams } from "react-router-dom";
import { getWorkingTypes } from "../../../../../../customHooks/getWorkingTypes";
import { MdEdit, MdWork } from "react-icons/md";
import { FaCalendar, FaCity, FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { BiWorld } from "react-icons/bi";
import { useIsMobile } from "../../../../../../hooks/useIsMobile";
import FormModal from "../../../../../../components/isbul-ui/form-modal";
import { useContext } from "react";
import { CvLayoutContext, cvLayoutContextType } from "../..";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import WorkExperienceFormComponent from "./WorkExperienceFormComponent";

type payloadDataType = {
  cv_id: number;
  company_name: string;
  position: number | null;
  position_level: number | null;
  sector: number | null;
  section: number | null;
  is_abroad: number;
  city?: number;
  country?: number;
  working_type: number | null;
  start_date_month: number | null;
  start_date_year: number | null;
  now_working: 0 | 1;
  end_date_month?: number;
  end_date_year?: number;
  leaving_reason: string | null;
  work_info: string;
};

type monthType = {
  id: number;
  name: string;
  name_en: string;
};

const months: monthType[] = [
  { id: 1, name: "Ocak", name_en: "January" },
  { id: 2, name: "Şubat", name_en: "February" },
  { id: 3, name: "Mart", name_en: "March" },
  { id: 4, name: "Nisan", name_en: "April" },
  { id: 5, name: "Mayıs", name_en: "May" },
  { id: 6, name: "Haziran", name_en: "June" },
  { id: 7, name: "Temmuz", name_en: "July" },
  { id: 8, name: "Ağustos", name_en: "August" },
  { id: 9, name: "Eylül", name_en: "September" },
  { id: 10, name: "Ekim", name_en: "October" },
  { id: 11, name: "Kasım", name_en: "November" },
  { id: 12, name: "Aralık", name_en: "December" },
];

const currentYear = new Date().getFullYear();

const totalYear = currentYear - 1974 + 1;

const years: number[] = Array.from({ length: totalYear }, (_, i) => i + 1974);

type propsType = {
  jobExperience?: dbUserJobExperienceType | null;
  positions?: cdnPositionType[];
  positionLevels?: cdnPositionLevelType[];
  sectors?: cdnSectorType[];
  sections?: cdnSectionType[];
  countries?: cdnCountryType[];
  workingTypes?: cdnWorkingTypeType[];
  cities?: cdnCityType[];
  getAllJobExperiences?: () => void;
  jobExperiencesLength?: number;
  isAddClicked?: boolean;
  setIsAddClicked?: Dispatch<SetStateAction<boolean>>;
  isEnglish?: boolean;
  inPopup?: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
  onReady?: () => void;
  cvId?: string;
};

const axios = new Axios();

export default function WorkExperienceForm({
  jobExperience,
  positions: initialPositions,
  positionLevels: initialPositionLevels,
  sectors: initialSectors,
  sections: initialSections,
  countries: initialCountries,
  workingTypes: initialWorkingTypes,
  cities: initialCities,
  getAllJobExperiences,
  jobExperiencesLength,
  isAddClicked,
  setIsAddClicked,
  isEnglish,
  inPopup = false,
  onSuccess = () => {},
  onCancel = () => {},
  onReady = () => {},
  cvId,
}: propsType) {
  const { showCvScore }: cvLayoutContextType = useContext(CvLayoutContext);

  const params = useParams();

  const isMobile = useIsMobile();

  // Page states
  const [isPageOpen, setIsPageOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  // Page header add click catcher
  useEffect(() => {
    if (
      isAddClicked != undefined &&
      setIsAddClicked != undefined &&
      !jobExperience
    ) {
      if (isAddClicked) {
        setIsModalOpen(true);
        setIsAddClicked(false);
      }
    }
  }, [isAddClicked]);

  // General states
  const [isNotTurkey, setIsNotTurkey] = useState<boolean>(false);
  const [isStillWorking, setIsStillWorking] = useState<boolean>(false);
  const [isEditOpen, setIsEditOpen] = useState<boolean>(
    jobExperience ? false : true
  );
  const [positions, setPositions] = useState<cdnPositionType[]>(
    initialPositions || []
  );
  const [positionLevels, setPositionLevels] = useState<cdnPositionLevelType[]>(
    initialPositionLevels || []
  );
  const [sectors, setSectors] = useState<cdnSectorType[]>(initialSectors || []);
  const [sections, setSections] = useState<cdnSectionType[]>(
    initialSections || []
  );
  const [countries, setCountries] = useState<cdnCountryType[]>(
    initialCountries || []
  );
  const [workingTypes, setWorkingTypes] = useState<cdnWorkingTypeType[]>(
    initialWorkingTypes || []
  );
  const [cities, setCities] = useState<cdnCityType[]>(initialCities || []);
  const [timeDiff, setTimeDiff] = useState<string>("");

  // Initial states changes
  // Position
  useEffect(() => {
    setPositions(initialPositions || []);
  }, [initialPositions]);

  // Position levels
  useEffect(() => {
    setPositionLevels(initialPositionLevels || []);
  }, [initialPositionLevels]);

  // Sectors
  useEffect(() => {
    setSectors(initialSectors || []);
  }, [initialSectors]);

  // Sections
  useEffect(() => {
    setSections(initialSections || []);
  }, [initialSections]);

  // Countries
  useEffect(() => {
    setCountries(initialCountries || []);
  }, [initialCountries]);

  // Working types
  useEffect(() => {
    setWorkingTypes(initialWorkingTypes || []);
  }, [initialWorkingTypes]);

  // Cities
  useEffect(() => {
    setCities(initialCities || []);
  }, [initialCities]);

  // Form states
  const [selectedPosition, setSelectedPosition] =
    useState<cdnPositionType | null>(null);
  const [selectedPositionLevel, setSelectedPositionLevel] =
    useState<cdnPositionLevelType | null>(null);
  const [selectedSector, setSelectedSector] = useState<cdnSectorType | null>(
    null
  );
  const [selectedSection, setSelectedSection] = useState<cdnSectionType | null>(
    null
  );
  const [selectedCountry, setSelectedCountry] = useState<cdnCountryType | null>(
    null
  );
  const [selectedCity, setSelectedCity] = useState<cdnCityType | null>(null);
  const [selectedWorkingType, setSelectedWorkingType] =
    useState<cdnWorkingTypeType | null>(null);
  const [selectedStartMonth, setSelectedStartMonth] =
    useState<monthType | null>(null);
  const [selectedEndMonth, setSelectedEndMonth] = useState<monthType | null>(
    null
  );
  const [selectedStartYear, setSelectedStartYear] = useState<number | null>(
    null
  );
  const [selectedEndYear, setSelectedEndYear] = useState<number | null>(null);
  // Search states
  const [searchPosition, setSearchPosition] = useState<string>("");
  const [searchPositionLevel, setSearchPositionLevel] = useState<string>("");
  const [searchSector, setSearchSector] = useState<string>("");
  const [searchSection, setSearchSection] = useState<string>("");
  const [searchCountry, setSearchCountry] = useState<string>("");
  const [searchCity, setSearchCity] = useState<string>("");

  // Search events
  // Positions
  useEffect(() => {
    async function search() {
      if (searchPosition.trim().length > 0) {
        const response = await getPositions({
          search: searchPosition,
          lang: isEnglish ? "en" : "tr",
        });
        setPositions(response);
      } else {
        setPositions(initialPositions || []);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchPosition]);

  // Position levels
  useEffect(() => {
    async function search() {
      if (searchPositionLevel.trim().length > 0) {
        const response = await getPositionLevels({
          search: searchPositionLevel,
          lang: isEnglish ? "en" : "tr",
        });
        setPositionLevels(response);
      } else {
        setPositionLevels(initialPositionLevels || []);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchPositionLevel]);

  // Sector
  useEffect(() => {
    async function search() {
      if (searchSector.trim().length > 0) {
        const response = await getSectors({
          search: searchSector,
          lang: isEnglish ? "en" : "tr",
        });
        setSectors(response);
      } else {
        setSectors(initialSectors || []);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchSector]);

  // Section
  useEffect(() => {
    async function search() {
      if (searchSection.trim().length > 0) {
        const response = await getSections({
          search: searchSection,
          lang: isEnglish ? "en" : "tr",
        });
        setSections(response);
      } else {
        setSections(initialSections || []);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchSection]);

  // Country
  useEffect(() => {
    async function search() {
      if (searchCountry.trim().length > 0) {
        const response = await getCountries({
          search: searchCountry,
          lang: isEnglish ? "en" : "tr",
        });
        setCountries(response);
      } else {
        setCountries(initialCountries || []);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchCountry]);

  // City
  useEffect(() => {
    async function search() {
      if (searchCity.trim().length > 0) {
        const response = await getCities({
          search: searchCity,
          lang: isEnglish ? "en" : "tr",
        });
        setCities(response);
      } else {
        setCities(initialCities || []);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchCity]);

  // Form states change event
  // Positions
  useEffect(() => {
    if (selectedPosition) {
      setValue("position", selectedPosition.id);
      clearErrors("position");
    } else {
      setValue("position", null);
    }
  }, [selectedPosition]);

  // Position levels
  useEffect(() => {
    if (selectedPositionLevel) {
      setValue("position_level", selectedPositionLevel.id);
      clearErrors("position_level");
    } else {
      setValue("position_level", null);
    }
  }, [selectedPositionLevel]);

  // Sectors
  useEffect(() => {
    if (selectedSector) {
      setValue("sector", selectedSector.id);
      clearErrors("sector");
    } else {
      setValue("sector", null);
    }
  }, [selectedSector]);

  // Sections
  useEffect(() => {
    if (selectedSection) {
      setValue("section", selectedSection.id);
      clearErrors("section");
    } else {
      setValue("section", null);
    }
  }, [selectedSection]);

  // Country
  useEffect(() => {
    if (selectedCountry) {
      setValue("country", selectedCountry.id);
      clearErrors("country");
    } else {
      setValue("country", undefined);
    }
  }, [selectedCountry]);

  // City
  useEffect(() => {
    if (selectedCity) {
      setValue("city", selectedCity.id);
      clearErrors("city");
    } else {
      setValue("city", undefined);
    }
  }, [selectedCity]);

  // Working types
  useEffect(() => {
    if (selectedWorkingType) {
      setValue("working_type", selectedWorkingType.id);
      clearErrors("working_type");
    } else {
      setValue("working_type", null);
    }
  }, [selectedWorkingType]);

  // Start month
  useEffect(() => {
    if (selectedStartMonth) {
      setValue("start_date_month", selectedStartMonth.id);
      clearErrors("start_date_month");
    } else {
      setValue("start_date_month", null);
    }
  }, [selectedStartMonth]);

  // Start year
  useEffect(() => {
    if (selectedStartYear) {
      if (selectedEndYear) {
        if (selectedStartYear > selectedEndYear) {
          Swal.fire({
            icon: "error",
            title: `${isEnglish ? "Error" : "Hata"}`,
            text: `${
              isEnglish
                ? "A start date must be selected before the end date"
                : "İşten ayrılma tarihiniz işe başlama tarihinizden daha eski olamaz"
            }`,
          });

          return;
        }
      }
      setValue("start_date_year", selectedStartYear);
      clearErrors("start_date_year");
    } else {
      setValue("start_date_year", null);
    }
  }, [selectedStartYear]);

  // End month
  useEffect(() => {
    if (selectedEndMonth) {
      setValue("end_date_month", selectedEndMonth.id);
      clearErrors("end_date_month");
    } else {
      setValue("end_date_month", undefined);
    }
  }, [selectedEndMonth]);

  // End year
  useEffect(() => {
    if (selectedEndYear) {
      setValue("end_date_year", selectedEndYear);
      clearErrors("end_date_year");
    } else {
      setValue("end_date_year", undefined);
    }
  }, [selectedEndYear]);

  // Is abroad
  useEffect(() => {
    if (isNotTurkey) {
      setValue("is_abroad", 1);
    } else {
      setValue("is_abroad", 0);
    }
  }, [isNotTurkey]);

  // Now working
  useEffect(() => {
    if (isStillWorking) {
      setValue("now_working", 1);
    } else {
      setValue("now_working", 0);
      setSelectedEndMonth(null);
      setSelectedEndYear(null);
    }
  }, [isStillWorking]);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors, isValid },
    watch,
    getFieldState,
    setError,
  } = useForm<payloadDataType>({
    criteriaMode: "all",
  });

  const onSubmit: SubmitHandler<payloadDataType> = (data) => {
    if (jobExperience) {
      updateJobExperience(data);
    } else {
      addJobExperience(data);
    }
  };

  // Custom registers
  // Position
  register("position", {
    required: "Pozisyon",
  });

  // Position level
  register("position_level", {
    required: "Pozisyon Seviyesi",
  });

  // Sector
  register("sector", {
    required: "Sektör",
  });

  // Section
  register("section", {
    required: "Bölüm",
  });

  // Country
  register("country", {
    required: isNotTurkey ? "Ülke" : false,
  });

  // City
  register("city", {
    required: !isNotTurkey ? "Şehir" : false,
  });

  // Working type
  register("working_type", {
    required: "Çalışma Şekli",
  });

  // Start month
  register("start_date_month", {
    required: "İşe Başlama Ayı",
  });

  // Start year
  register("start_date_year", {
    required: "İşe Başlama Yılı",
  });

  // End month
  register("end_date_month", {
    required: isStillWorking ? false : "İşten Ayrılma Ayı",
  });

  // End year
  register("end_date_year", {
    required: isStillWorking ? false : "İşten Ayrılma Yılı",
  });

  // Watch form values
  const company_name = watch("company_name") || "";
  const work_info = watch("work_info") || "";
  const leaving_reason = watch("leaving_reason") || "";

  // Get initial values
  useEffect(() => {
    if (jobExperience) {
      //console.log("exp", jobExperience);
      getJobExperienceValues(jobExperience);
    }
  }, [jobExperience]);

  async function getJobExperienceValues(exp: dbUserJobExperienceType) {
    setValue("company_name", exp.company_name);
    setValue("leaving_reason", exp.leaving_reason);
    setValue("work_info", exp.work_info);

    // Is abroad
    if (exp.is_abroad == "0") {
      setIsNotTurkey(false);
    } else {
      setIsNotTurkey(true);
    }

    // Is working
    if (exp.now_working == 0) {
      setIsStillWorking(false);
    } else {
      setIsStillWorking(true);
    }

    setSelectedStartMonth(
      months.find((m) => m.id == exp.start_date.month) || null
    );
    setSelectedStartYear(exp.start_date.year);

    if (exp.end_date?.month) {
      setSelectedEndMonth(
        months.find((m) => m.id == exp.end_date?.month) || null
      );
    }

    if (exp.end_date?.year) {
      setSelectedEndYear(exp.end_date.year);
    }

    // Position
    setSelectedPosition(exp.position_list || null);

    // Position level
    setSelectedPositionLevel(exp.position_level_list || null);

    // Sector
    setSelectedSector(exp.sector_list || null);

    // Section
    setSelectedSection(exp.section_list || null);

    // Country
    if (exp.country_id) {
      setSelectedCountry(exp.country_list || null);
    }

    // City
    if (exp.city_id) {
      setSelectedCity(exp.city_list || null);
    }

    // Working type
    setSelectedWorkingType(exp.working_type_list || null);

    // Working time
    if (exp.end_date?.month && exp.end_date.year) {
      let startDate = new Date();
      startDate = new Date(startDate.setDate(1));
      startDate = new Date(startDate.setMonth(exp.start_date.month - 1));
      startDate = new Date(startDate.setFullYear(exp.start_date.year));

      let endDate = new Date();
      endDate = new Date(endDate.setDate(1));
      endDate = new Date(endDate.setMonth(exp.end_date.month - 1));
      endDate = new Date(endDate.setFullYear(exp.end_date.year));

      const monthDiff =
        (endDate.getFullYear() - startDate.getFullYear()) * 12 +
        (endDate.getMonth() - startDate.getMonth());
      const yearDiff = endDate.getFullYear() - startDate.getFullYear();

      if (monthDiff >= 12) {
        setTimeDiff(
          `${yearDiff} ${isEnglish ? "Year" : "Yıl"} ${
            monthDiff % 12 > 0
              ? `${monthDiff % 12} ${isEnglish ? `Month` : `Ay`}`
              : ``
          }`
        );
      } else {
        setTimeDiff(`${monthDiff} ${isEnglish ? "Month" : "Ay"}`);
      }
    }
  }

  // Date check if start date greater than end date
  function dateCheck({
    startMonth,
    startYear,
    endMonth,
    endYear,
  }: {
    startMonth?: number;
    startYear?: number;
    endMonth?: number;
    endYear?: number;
  }): boolean {
    if (startMonth && startYear && endMonth && endYear) {
      const startDate = new Date(startYear, startMonth - 1, 1);
      const endDate = new Date(endYear, endMonth - 1, 1);

      if (startDate.getTime() > endDate.getTime()) {
        /* Swal.fire({
          icon: "error",
          title: `${isEnglish? 'Error': "Hata"}`,
          text: `${isEnglish? 'A start date must be selected before the end date': "İşten ayrılma tarihiniz işe başlama tarihinizden daha eski olamaz"}`,
        }); */

        const errorMessage = `${
          isEnglish
            ? "A start date must be selected before the end date"
            : "İşten ayrılma tarihiniz işe başlama tarihinizden daha eski olamaz"
        }`;
        setError("start_date_month", { message: errorMessage });
        setError("start_date_year", { message: errorMessage });
        setError("end_date_month", { message: errorMessage });
        setError("end_date_year", { message: errorMessage });

        return false;
      }

      clearErrors("start_date_month");
      clearErrors("start_date_year");
      clearErrors("end_date_month");
      clearErrors("end_date_year");

      return true;
    }

    return true;
  }
  if (isEditOpen) {
    return (
      <div className="flex flex-col gap-2">
        {!jobExperience && (
          <div className={`flex flex-row items-center justify-between px-4`}>
            {/* Page header modal */}
            <Button
              size="sm"
              onClick={() => {
                setIsModalOpen(true);
                setIsPageOpen(true);
              }}
            >
              <Button.Icon>
                <IoAdd className="text-base" />
              </Button.Icon>
              {isEnglish ? "Add Work Experience" : "İş Deneyimi Ekle"}
            </Button>
          </div>
        )}
        {!jobExperience && <hr className="border-mains" />}

        {/* Form modal with form content */}
        <FormModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          onClosing={() => {
            setIsModalOpen(false);
            if (jobExperience) {
              setIsEditOpen(false);
            } else {
              setIsEditOpen(true);
            }
          }}
        >
          <FormModal.Content>
            <WorkExperienceFormComponent
              isEnglish={isEnglish}
              isAddClicked={isAddClicked}
              setIsAddClicked={setIsAddClicked}
              jobExperience={jobExperience}
              cvId={cvId}
              initialPositions={initialPositions}
              initialPositionLevels={initialPositionLevels}
              initialSectors={initialSectors}
              initialSections={initialSections}
              initialCountries={initialCountries}
              initialWorkingTypes={initialWorkingTypes}
              initialCities={initialCities}
              getAllJobExperiences={getAllJobExperiences}
              setIsModalOpen={setIsModalOpen}
              setIsPageOpen={setIsPageOpen}
              setIsEditOpen={setIsEditOpen}
            />
          </FormModal.Content>
        </FormModal>
      </div>
    );
  } else {
    return (
      <>
        {/* Desktop section */}
        <div className="hidden lg:flex flex-row relative px-1 py-2 gap-4 border-transparent hover:border-gray-300 border-dashed border-2 rounded-md transition-all">
          {/* Edit and delete buttons */}
          <FormModal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
            <FormModal.Trigger>
              <div className="flex flex-col gap-2 absolute -top-2 right-2 lg:top-4 lg:right-4 z-10">
                <Button
                  variant="ghost"
                  size="xs"
                  className=" aspect-square !rounded-full z-10 !border-none"
                  onClick={() => {
                    setIsEditOpen(true);
                    // setIsPageOpen(true);
                    setIsModalOpen(true);
                  }}
                >
                  <Button.Icon>
                    <FaPencilAlt className="text-xl" />
                  </Button.Icon>
                </Button>

                <Button
                  variant="ghost"
                  size="xs"
                  className="aspect-square !rounded-full !border-none"
                  onClick={() => {
                    Swal.fire({
                      icon: "warning",
                      title: `${
                        isEnglish
                          ? "Are you sure to delete work experience?"
                          : "İş deneyimi bilgisini silmek istediğinizden emin misiniz?"
                      }`,
                      confirmButtonText: `${isEnglish ? "Yes" : "Evet"}`,
                      cancelButtonText: `${isEnglish ? "No" : "Hayır"}`,
                      preConfirm: () => {
                        deleteJobExperience();
                      },
                      showCancelButton: true,
                    });
                  }}
                >
                  <Button.Icon className="text-xl">
                    <IoTrashBinOutline />
                  </Button.Icon>
                </Button>
              </div>
            </FormModal.Trigger>
          </FormModal>

          <div className="absolute top-4 left-4 lg:left-0 lg:top-0 z-0 lg:relative flex flex-col border-r-0 lg:border-r pr-4 mr-2">
            <FaCity className="text-6xl text-gray-200 lg:text-9xl lg:text-mains/40" />
            <label className="hidden lg:flex text-center items-center justify-center font-poppins font-semibold text-mains whitespace-nowrap overflow-hidden text-ellipsis line-clamp-1">
              {jobExperience?.company_name.slice(0, 10)}
              {jobExperience && jobExperience?.company_name.length > 10
                ? "..."
                : ""}
            </label>
          </div>
          <div className="flex flex-col gap-2 w-full z-[1] pr-12">
            <div className="flex flex-row items-center w-full">
              <div className="flex flex-col basis-1/2 gap-3 pr-2 items-start h-full">
                {/* Position name/level section */}
                <div className="flex flex-col">
                  <label className="hidden lg:flex items-center gap-1 text-xs font-poppins font-semibold text-gary-700">
                    <MdWork className="text-sm" />
                    {isEnglish
                      ? "Position Name / Level"
                      : "Pozisyon Adı / Seviyesi"}
                  </label>
                  <label className="font-poppins text-xs lg:text-sm text-gray-600 line-clamp-1">
                    {`${
                      isEnglish
                        ? `${jobExperience?.position_list?.title_en}`
                        : `${jobExperience?.position_list?.title}`
                    }`}{" "}
                    /{" "}
                    {`${
                      isEnglish
                        ? `${jobExperience?.position_level_list?.name_en}`
                        : `${jobExperience?.position_level_list?.name}`
                    }`}
                  </label>
                </div>

                {/* Sector/section  */}
                <div className="flex flex-col">
                  <label className="hidden lg:flex items-center gap-1 text-xs font-poppins font-semibold text-gary-700">
                    <FaUserDoctor className="text-sm" />
                    {isEnglish ? `Industry / Department` : `Sektör / Bölüm`}
                  </label>
                  <label className="font-poppins text-xs lg:text-sm text-gray-600 line-clamp-1">
                    {`${
                      isEnglish
                        ? `${jobExperience?.sector_list?.title_en}`
                        : `${jobExperience?.sector_list?.title}`
                    }`}{" "}
                    /{" "}
                    {`${
                      isEnglish
                        ? `${jobExperience?.section_list?.title_en}`
                        : `${jobExperience?.section_list?.title}`
                    }`}
                  </label>
                </div>
              </div>
              <div className="flex flex-col basis-1/2 gap-3 pl-2 items-start h-full">
                {/* Locations working type section */}
                <div className="flex flex-col">
                  <label className="hidden lg:flex items-center gap-1 text-xs font-poppins font-semibold text-gary-700">
                    {isNotTurkey ? (
                      <BiWorld className="text-sm" />
                    ) : (
                      <FaMapLocationDot className="text-sm" />
                    )}
                    {isNotTurkey
                      ? `${isEnglish ? "Country" : "Ülke"}`
                      : `${isEnglish ? "City" : "Şehir"}`}{" "}
                    / {isEnglish ? "Employment Type" : "Çalışma Şekli"}
                  </label>
                  <label className="font-poppins text-xs lg:text-sm text-gray-600 line-clamp-1">
                    {isNotTurkey
                      ? `${
                          isEnglish
                            ? `${jobExperience?.country_list?.name_en}`
                            : `${jobExperience?.country_list?.name_tr}`
                        }`
                      : `${
                          isEnglish
                            ? `${jobExperience?.city_list?.name}`
                            : `${jobExperience?.city_list?.name}`
                        }`}
                    /
                    {`${
                      isEnglish
                        ? `${jobExperience?.working_type_list?.title_en}`
                        : `${jobExperience?.working_type_list?.title}`
                    }`}
                  </label>
                </div>

                {/* Working time section */}
                <div className="flex flex-col">
                  <label className="hidden lg:flex items-center gap-1 text-xs font-poppins font-semibold text-gary-700">
                    <FaCalendar className="text-sm" />
                    {isEnglish ? "Working Time" : "Çalışma Süresi"}
                  </label>
                  {jobExperience?.now_working == 0 && (
                    <label className="font-poppins text-xs lg:text-sm text-gray-600 line-clamp-1">
                      {timeDiff}
                    </label>
                  )}
                  {jobExperience?.now_working == 1 && (
                    <label className="font-poppins text-xs lg:text-sm text-gray-600 line-clamp-1">
                      {jobExperience.start_date.month < 10 ? "0" : ""}
                      {jobExperience.start_date.month}/
                      {jobExperience.start_date.year} (
                      {isEnglish ? "Still Working" : "Halen Çalışıyor"})
                    </label>
                  )}
                </div>
              </div>
            </div>

            {/* Job detail section */}
            <div className="flex flex-col w-full">
              <label className="hidden lg:flex text-xs font-poppins font-semibold text-gary-700">
                {isEnglish ? "Job Description" : "İş Tanımı"}
              </label>
              <label className="font-poppins text-xs lg:text-sm text-gray-600 line-clamp-2 break-all">
                {jobExperience?.work_info}
              </label>
            </div>
          </div>
        </div>

        {/* Mobile section */}
        <div className="flex flex-row lg:hidden py-2 px-4">
          <div className="flex flex-col gap-2 flex-1">
            {/* Position name section */}
            <div className="flex flex-col">
              <label className="font-poppins text-[13px] font-semibold text-gray-900">
                {isEnglish ? "Position" : "Pozisyon Adı"}
              </label>
              <label className="font-poppins text-[13px] text-gray-800">
                {`${
                  isEnglish
                    ? `${jobExperience?.position_level_list?.name_en}`
                    : `${jobExperience?.position_level_list?.name}`
                }`}
              </label>
            </div>

            {/* Company name section */}
            <div className="flex flex-col">
              <label className="font-poppins text-[13px] font-semibold text-gray-900">
                {isEnglish ? "Company Name" : "Firma Adı"}
              </label>
              <label className="font-poppins text-[13px] text-gray-800">
                {jobExperience?.company_name}
              </label>
            </div>
          </div>

          {/* Edit and delete buttons */}
          <div className="flex flex-col gap-2 items-center">
            <button
              className="flex flex-col gap-1 items-center text-mains font-poppins text-xs"
              onClick={() => {
                setIsEditOpen(true);
                setIsPageOpen(true);
                setIsModalOpen(true);
              }}
            >
              <FaPencilAlt className="text-mains text-base" />
              {isEnglish ? "Edit" : "Düzenle"}
            </button>

            <button
              className="flex flex-col gap-1 items-center text-mains font-poppins text-xs"
              /* TODO: onclik değişecek */
              onClick={() => {
                Swal.fire({
                  icon: "warning",
                  title: `${
                    isEnglish
                      ? "Are you sure to delete work experience?"
                      : "İş deneyimi bilgisini silmek istediğinizden emin misiniz?"
                  }`,
                  confirmButtonText: `${isEnglish ? "Yes" : "Evet"}`,
                  cancelButtonText: `${isEnglish ? "No" : "Hayır"}`,
                  preConfirm: () => {
                    deleteJobExperience();
                  },
                  showCancelButton: true,
                });
              }}
            >
              {/* TODO: Icon değişsefcek */}
              <IoTrashBinOutline className="text-base" />
            </button>
          </div>
        </div>
      </>
    );
  }

  async function addJobExperience({ cv_id, ...others }: payloadDataType) {
    if (isLoading) return;

    setIsLoading(true);

    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/exp/create`,
        type: "post",
        payload: {
          cv_id: cvId,
          job_experiences: {
            ...others,
          },
        },
      });

      if (response && response.status == 200) {
        if (getAllJobExperiences) {
          getAllJobExperiences();
        }

        /* Clear form fields */
        setValue("company_name", "");
        setValue("leaving_reason", "");
        setValue("work_info", "");
        setSelectedPosition(null);
        setSelectedPositionLevel(null);
        setSelectedSector(null);
        setSelectedSection(null);
        setSelectedCity(null);
        setSelectedCountry(null);
        setSelectedWorkingType(null);
        setIsNotTurkey(false);
        setIsStillWorking(false);
        setSelectedStartMonth(null);
        setSelectedStartYear(null);
        setSelectedEndMonth(null);
        setSelectedEndYear(null);

        setIsPageOpen(false);
        setIsModalOpen(false);

        showCvScore();
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "İş deneyimi eklenirken bir hata meydana geldi.",
        });
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "İş deneyimi eklenirken beklenmedik bir hata meydana geldi.",
      });
    }

    setIsLoading(false);
  }

  async function updateJobExperience({ cv_id, ...others }: payloadDataType) {
    if (isLoading) return;

    setIsLoading(true);

    if (jobExperience) {
      try {
        const response = await axios.userAuthRequest({
          endpoint: `user/profilim/cv/exp/update`,
          type: "put",
          payload: {
            cv_id: cvId,
            job_experiences: {
              id: jobExperience.id,
              ...others,
            },
          },
        });

        if (response && response.data) {
          if (getAllJobExperiences) {
            getAllJobExperiences();
          }

          setIsEditOpen(false);
          showCvScore();
        } else {
          Swal.fire({
            icon: "error",
            title: "Hata",
            text: "İş deneyimi güncellenirken bir hata meydana geldi.",
          });
        }
      } catch (error) {
        console.log(`Error: ${error}`);
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "İş deneyimi güncellenirken beklenmedik bir hata meydana geldi.",
        });
      }
    }

    setIsLoading(false);
  }

  async function deleteJobExperience() {
    if (isLoading) return;

    setIsLoading(true);

    if (jobExperience) {
      try {
        const response = await axios.userAuthRequest({
          endpoint: `user/profilim/cv/exp/delete/${jobExperience.id}?cv_id=${cvId}`,
          type: "delete",
          payload: {
            cv_id: cvId,
          },
        });

        if (response && response.status == 200) {
          if (getAllJobExperiences) {
            getAllJobExperiences();
          }

          showCvScore();
        } else {
          Swal.fire({
            icon: "error",
            title: "Hata",
            text: "İş deneyimi silinirken bir hata meydana geldi.",
          });
        }
      } catch (error) {
        console.log(`Error: ${error}`);
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "İş deneyimi silinirken beklenmedik bir hata meydana geldi.",
        });
      }
    }

    setIsLoading(false);
  }
}
