import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CompanyShow from './company-show/index';


const Dashboard: React.FC = () => {
  return (
    <div className=''>
       <div className='w-full'>
        <Routes>
         <Route path="/:slug" element={<CompanyShow/>} />
        </Routes>
       </div>
    </div>
  );
};

export default Dashboard;