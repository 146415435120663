/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useMemo, useState } from "react";
import ApplicationPopupListItem from "../application-popup-list-item";
import { AdvertService } from "../../../../../services/advert-services";
import { useFormatDate } from "../../../../../hooks/useFormatDate";
import { Link } from "react-router-dom";

interface IApplicationPopup {
  advertId: number;
  isPopup: boolean;
}

type PositionType = {
  id: number;
  title: string;
};

interface IDetails {
  detail: string;
  experienceLevel: string;
  position: PositionType[];
  sector: string;
  section: string;
  city: string;
  createdAt: string;
  updatedAt: string;
  endDate: string;
  slug: string;
}

const ApplicationPopup: React.FC<IApplicationPopup> = (
  props: IApplicationPopup
) => {
  const [details, setDetails] = useState<IDetails>();

  useEffect(() => {
    const getAdvertDetail = async () => {
      const res = await AdvertService.getAdvertDetailById(props.advertId);

      console.log(res);
      setDetails({
        slug: res.data?.data?.advert?.slug,
        detail: res.data?.data?.advert?.detail,
        experienceLevel:
          res.data?.data?.advert_experience_level?.experience_level_min?.title,
        position: res.data?.data?.positions,
        sector: res.data?.data?.sectors,
        section: res.data?.data?.sections,
        city: res.data?.data?.cities,
        createdAt: useFormatDate(res.data?.data?.advert?.created_at),
        updatedAt: useFormatDate(res.data?.data?.advert?.updated_at),
        endDate: useFormatDate(res.data?.data?.advert?.end_date),
      });
    };

    !details && props.isPopup && getAdvertDetail();
  }, [details, props.advertId, props.isPopup]);

  const detailQualificationItems = useMemo(() => {
    return [
      {
        key: "TECRÜBE",
        target: "title",
        value: details?.experienceLevel || [],
      },
      { key: "POZİSYON", target: "title", value: details?.position || [] },
      { key: "SEKTÖR", target: "title", value: details?.sector || [] },
      { key: "BÖLÜM", target: "title", value: details?.section || [] },
      { key: "ÇALIŞMA YERİ", target: "name", value: details?.city || [] },
    ];
  }, [details]);

  //console.log("propsda", details);

  return (
    <div
      className={`absolute  h-72 w-80 md:w-[450px] lg:w-[600px] xl:w-[525px] 2xl:w-[600px] left-2 md:left-10 lg:left-12 xl:left-12 top-[110px]  ${
        details ? "hidden group-hover:block" : "hidden"
      } bg-white border-2  border-mains z-10 pl-4 pt-6`}
    >
      <div className="triangle top-[-20px] absolute left-5" />
      <div className="w-full h-full overflow-y-scroll">
        <div
          dangerouslySetInnerHTML={{ __html: details?.detail || "" }}
          className="flex-1 px-2"
        />
        <div className="w-full h-px mt-6 bg-gray-300" />
        <ApplicationPopupListItem
          createdAt={details?.createdAt || ""}
          updatedAt={details?.updatedAt || ""}
          endDate={details?.endDate || ""}
          keys={detailQualificationItems}
        />
        <div className="w-full h-px mt-6 mb-12 bg-gray-300" />
      </div>

      <Link to={`/is-ilanlari/${details?.slug}`} target={"_blank"}>
        <div className="absolute bottom-0 right-0 flex items-center justify-center h-12 w-36 bg-mains">
          <span className="text-lg font-semibold text-white">Başvur</span>
        </div>
      </Link>
    </div>
  );
};

export default ApplicationPopup;
