import React, { useEffect, useState, useRef, useContext } from "react";
import { MdOutlineSearch } from "react-icons/md";

import services from "../../../../../services/login-services";
import { IconChevronDown, IconMapPin, IconSearch } from "@tabler/icons-react";

import { CiFilter } from "react-icons/ci";
import { ITitle, IItem, IName, IData } from "../../types";
import usePartialBold from "../../../../../customHooks/usePartialBold";
import {
  RiMapPinUserLine,
  RiSave2Line,
  RiFilter2Line,
  RiArrowDropDownLine,
} from "react-icons/ri";
import { IoMdCloseCircle } from "react-icons/io";
import { handleSearchChange } from "../components/components/search-component";
import { CandidatePoolContext, CandidatePoolFilterProps } from "../../index";
import useFetchCdnQuery from "../../../../../hooks/useFetchCdnQuery";
import { SearchComponentHeader } from "../components/components/search-component-header-new";
import { isMobile } from "@headlessui/react/dist/utils/platform";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import Swal from "sweetalert2";

const MobileTriggerSection: React.FC = () => {
  const [showSavedSearchesDropdown, setShowSavedSearchesDropdown] =
    useState(false);

  const {
    newSelectedValues,
    setNewSelectedValues,
    handleFilter,
    detailFiltersOpen,
    setDetailFiltersOpen,
    savedSearchFilters,
    setShowFilterSaveButton,
    setSyncCounter,
  } = useContext(CandidatePoolContext);

  //fetch cities
  const [cityData, setCityData] = useState<any>([]);
  const [citySearchTerm, setCitySearchTerm] = useState("");
  const citiesQuery = useFetchCdnQuery(
    "cities-or-districts",
    "get-cities-or-districts",
    "cities_or_districts",
    citySearchTerm,
    "20",
    (newSelectedValues.city
      ? `&city_include_ids=[${newSelectedValues.city}]`
      : "") +
      (newSelectedValues.district
        ? `&district_include_ids=[${newSelectedValues.district}]`
        : "")
  );
  useEffect(() => {
    if (citiesQuery.data !== undefined) {
      setCityData([...citiesQuery?.data]);
    }
  }, [citiesQuery.data, citiesQuery.fetchStatus]);
  //fetch positions
  const [positionsData, setPositionsData] = useState<any>([]);
  const [positionSearchTerm, setPositionSearchTerm] = useState("");
  const positionsQuery = useFetchCdnQuery(
    "positions",
    "get-positions",
    "positions",
    positionSearchTerm,
    "20",
    newSelectedValues.position
      ? `&include_ids=[${newSelectedValues.position}]`
      : ""
  );
  useEffect(() => {
    if (positionsQuery.data !== undefined) {
      setPositionsData([...positionsQuery?.data]);
    }
  }, [positionsQuery.data, positionsQuery.fetchStatus]);

  const toggleSavedSearchesDropdown = () => {
    setShowSavedSearchesDropdown(!showSavedSearchesDropdown);
  };

  function generateLinkWithSortedParams(params: string) {
    const paramString = new URLSearchParams(JSON.parse(params)).toString();
    return `/isveren/aday-havuzu?${paramString}`;
  }

  const handleNearby = () => {
    services
      .checkTokenCompany()
      .then((response) => {
        setNewSelectedValues({
          city: response.data.company.company_detail.city_id,
          district: response.data.company.company_detail.district_id,
        });
        setSyncCounter((prev) => prev + 1);
      })
      .catch(() => {
        Swal.fire({
          title: "Hata",
          text: "Lokasyon verisi alınırken bir hata oluştu",
          icon: "error",
        });
      });
  };

  return (
    <section className="relative flex  bg-mains h-auto shadow-lg ">
      <div className="w-full  h-auto container mx-auto z-10">
        <div className="flex gap-x-20">
          <div className=" flex flex-col  gap-3 py-4 px-4   w-full ">
            <div className="flex flex-col  items-start ">
              <h1 className=" text-white font-semibold text-sm ml-4">
                Aday Havuzunda Ara
              </h1>
            </div>
            <div className="relative">
              <SearchComponentHeader
                AccordionContentisOpen={detailFiltersOpen}
                isRadius
                data={positionsData}
                placeholder={"Örnek : Satış, Muhasebe, Mühendislik..."}
                label={""}
                keyString={"title"}
                onChange={(value) => {
                  let newValues: CandidatePoolFilterProps = {
                    ...newSelectedValues,
                    position: value.id,
                  };
                  if (value.id === 0) {
                    delete newValues.position;
                  }
                  setNewSelectedValues(newValues);
                }}
                selectedvalue={newSelectedValues.position}
                searchData={positionSearchTerm}
                setSearchData={setPositionSearchTerm}
              />
            </div>
            <div className={`flex ${detailFiltersOpen ? "hidden" : ""}`}>
              <SearchComponentHeader
                isCity
                AccordionContentisOpen={detailFiltersOpen}
                data={cityData}
                placeholder={"Şehir veya İlçe Ara"}
                label={""}
                keyString={"name"}
                onChange={(value) => {
                  console.log(value);
                  if (value.type === "city") {
                    let newValues: CandidatePoolFilterProps = {
                      ...newSelectedValues,
                      city: value.id,
                    };
                    if (value === 0) {
                      delete newValues.city;
                    }
                    setNewSelectedValues(newValues);
                  } else {
                    let newValues: CandidatePoolFilterProps = {
                      ...newSelectedValues,
                      city: value.city_id,
                      district: value.id,
                    };
                    if (value.id === 0) {
                      delete newValues.city;
                      delete newValues.district;
                    }
                    setNewSelectedValues(newValues);
                  }
                }}
                selectedvalue={
                  newSelectedValues.district
                    ? newSelectedValues.district
                    : newSelectedValues.city
                }
                searchData={citySearchTerm}
                setSearchData={setCitySearchTerm}
              />

              <div className={"w-2/6 h-full"}>
                <button
                  onClick={() => {
                    setShowFilterSaveButton(true);
                    handleFilter();
                  }}
                  className="flex items-center justify-center w-full h-full bg-zinc-800 gap-x-2 rounded-r-md"
                >
                  <IconSearch className="text-gray-50" />
                  <span className="text-lg uppercase poppins-light text-gray-50">
                    Ara
                  </span>
                </button>
              </div>
            </div>
            <div className="flex flex-row justify-between ">
              <button
                onClick={() => {
                  handleNearby();
                }}
                className="text-white mr-auto ml-3 text-[12px] mb-2 mt-1 flex items-center gap-1 "
              >
                <RiMapPinUserLine className="text-white" />
                <p> Yakınımdaki Adaylar</p>
              </button>
              <button
                onClick={() => {
                  setDetailFiltersOpen(!detailFiltersOpen);
                }}
                className="text-white mr-auto ml-3 text-[12px] mb-2 mt-1 flex items-center gap-1 "
              >
                <RiFilter2Line className="text-white " />
                <p> Detaylı Ara</p>
                <IconChevronDown
                  size={15}
                  className={`scale-75 !transition-transform duration-300 transform ${
                    detailFiltersOpen ? "rotate-180" : ""
                  }`}
                  // className="text-xl cursor-pointer"
                />
              </button>

              <div className={"relative"}>
                <button
                  onClick={toggleSavedSearchesDropdown}
                  className="text-white mr-auto ml-3  text-[12px] mb-2 mt-1 flex items-center gap-1 "
                >
                  <RiSave2Line className="text-white " />
                  <p> Kayıtlı</p>
                  <IconChevronDown
                    size={15}
                    className={`scale-75 !transition-transform duration-300 transform ${
                      showSavedSearchesDropdown ? "rotate-180" : ""
                    }`}
                    // className="text-xl cursor-pointer"
                  />
                </button>
                {showSavedSearchesDropdown && (
                  <div className="absolute right-0 bg-[#1E4A9E] border-2 mt-1 rounded-lg scroll-hidden shadow-lg z-10 max-h-60 overflow-y-auto w-72">
                    <div className="flex justify-between p-2 bg-gray-100 border-b">
                      <div className="flex gap-2 items-center content-center">
                        <RiSave2Line size={24} className="text-[#1E4A9E] " />
                        <span className="text-center font-bold capitalize">
                          Kayıtlı Aramalarım
                        </span>
                      </div>
                      <button
                        onClick={() => setShowSavedSearchesDropdown(false)}
                      >
                        <IoMdCloseCircle size={24} />
                      </button>
                    </div>
                    {savedSearchFilters.length > 0 ? (
                      savedSearchFilters.map((search, i) => (
                        <a
                          key={i}
                          href={generateLinkWithSortedParams(
                            JSON.stringify(search.params)
                          )}
                        >
                          <div
                            key={search.id}
                            className="p-2 hover:bg-white text-white text-sm hover:text-[#1E4A9E] cursor-pointer "
                          >
                            {search.title}
                          </div>
                        </a>
                      ))
                    ) : (
                      <div className="p-2 text-white">
                        Kayıtlı arama bulunamadı
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobileTriggerSection;
