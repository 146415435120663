import Axios from "../services/axios";
import { cdnEducationLevelType } from "../types";







type getEducationLevelsPropsType = {
    search?: string,
    limit?: number,
    only_ids?: number[]
}


const axios = new Axios()


export async function getEducationLevels({
    search,
    limit,
    only_ids
}: getEducationLevelsPropsType): Promise<cdnEducationLevelType[]> {
    try {
        const response = await axios.request({
            endpoint: `cdn/get-education-levels?
            ${search? `search=${search}&`: ''}
            ${limit? `limit=${limit}&`: ''}
            ${only_ids? `only_ids=${JSON.stringify(only_ids)}&`: ''}
            `,
            type: 'get'
        })
        if (response && response.data && response.data.education_levels) {
            return JSON.parse(JSON.stringify(response.data.education_levels))
        } else {
            return []
        }
    } catch (error) {
        console.log(`Education level fetch failed. Error: ${error}`)
        return []
    }
}