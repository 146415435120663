import React from "react";
import { useAppSelector } from "../../../../store/store";
interface BreadcrumpProps {
  type: "dashboard" | "page";
  pageName?: string;
}

const Breadcrump: React.FC<BreadcrumpProps> = ({ type, pageName }) => {
  // const auth = useAppSelector((state)=>state.auth.currentUser)
  const userInfo = useAppSelector((state) => state.userInfo);
  return (
    <div className="flex items-center justify-between w-full px-4 py-5 bg-white rounded-md shadow-lg line-clamp-1">
      {type === "dashboard" ? (
        <span className="text-lg lg:text-xl text-mains poppins-medium">
          Profilim
        </span>
      ) : (
        <span className="text-lg uppercase lg:text-xl text-mains poppins-medium">
          {pageName}
        </span>
      )}
      {type === "dashboard" && (
        <div className="flex items-center gap-x-1">
          <span className="text-xs lg:text-xl poppins-medium text-mains line-clamp-1 ">
            Merhaba,
          </span>
          <span className="text-xs lg:text-xl poppins-medium text-mains line-clamp-1 ">
            {userInfo?.name} {userInfo?.surname}
          </span>
        </div>
      )}
    </div>
  );
};

export default Breadcrump;
