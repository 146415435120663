import React, { useState, useEffect, useRef } from "react";
import Axios from "../../../../../services/axios/index";
import { IoIosArrowDown } from "react-icons/io";

// Create an instance of the custom Axios wrapper
const customAxios = new Axios();

// Define interface for link items
interface LinkItem {
  text: string;
  slug: string;
}

// Define interface for AccordionItem props
interface AccordionItemProps {
  title: string;
  content: LinkItem[][];
  isOpen: boolean;
  onToggle: () => void;
}

// AccordionItem component
const AccordionItem = React.forwardRef<HTMLDivElement, AccordionItemProps>(
  ({ title, content, isOpen, onToggle }, ref) => (
    <div
      ref={ref}
      className="border-b mb-3 mx-auto lg:w-3/4 bg-[#F5F5F5] lg:px-0 px-4"
    >
      <button
        className="text-[#2253AF] py-5 px-4 w-full text-left flex justify-between items-center bg-[#FFFFFE] "
        onClick={onToggle}
      >
        <h2 className="font-medium text-4xl">{title}</h2>
        <IoIosArrowDown
          className={`text-2xl transition-transform ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
        />
      </button>
      <div className={`accordion-transition ${isOpen ? "accordion-open" : ""}`}>
        <div className="p-4 flex flex-col md:flex-row justify-around">
          {content.map((list, index) => (
            <ul key={index} className="mb-0 w-full md:w-1/3">
              {list.map((item, itemIndex) => (
                <li className="mb-4 section-accordion-a" key={itemIndex}>
                  <a href={`/bolumler${item.slug}`}>{item.text}</a>
                </li>
              ))}
            </ul>
          ))}
        </div>
      </div>
    </div>
  )
);

// AlphabetCollapse component
const AlphabetCollapse: React.FC = () => {
  const [openItems, setOpenItems] = useState<string[]>([]);
  const [data, setData] = useState<{
    [key: string]: { title: string; slug: string }[];
  }>({});
  const itemRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>(
    {}
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await customAxios.request({
          type: "get",
          endpoint: "home/sections",
          payload: {},
        });
        console.log(response.data, "response.data");
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  const getContentForLetter = (letter: string): LinkItem[][] => {
    const items = data[letter] || [];
    const numberOfColumns = 3;
    // Initialize each column as an empty array within the columns array
    const columns: LinkItem[][] = Array.from(
      { length: numberOfColumns },
      () => []
    );

    items.forEach((item, index) => {
      const columnIndex = index % numberOfColumns;
      // Push the item into the correct column as an object
      columns[columnIndex].push({
        text: item.title,
        slug: `/${item.slug}`,
      });
    });

    return columns;
  };

  const allLetters = "ABCÇDEFGHİJKLMNOÖPRSŞTUVYZW".split("");

  useEffect(() => {
    // Set all letters as open initially
    setOpenItems(allLetters);
  }, []);

  allLetters.forEach((letter) => {
    if (!itemRefs.current[letter]) {
      itemRefs.current[letter] = React.createRef<HTMLDivElement>();
    }
  });

  const toggleLetter = (letter: string) => {
    setOpenItems((prevOpenItems) => {
      if (prevOpenItems.includes(letter)) {
        return prevOpenItems; // If the letter is already open, don't change anything
      } else {
        return [...prevOpenItems, letter]; // Add the letter to the open items
      }
    });

    // Scroll to the element if it was just opened
    setTimeout(() => {
      const element = itemRefs.current[letter]?.current;
      if (element && !isElementInView(element)) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 300);
  };

  // Helper function to check if an element is in the viewport
  function isElementInView(element: Element): boolean {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  return (
    <div className="font-poppins mb-8">
      <div className="bg-white shadow-lg">
        <div className="flex flex-col items-center justify-center md:w-3/4 w-full mx-auto">
          <div className="flex flex-wrap justify-center gap-2 my-4">
            {allLetters.map((letter) => (
              <button
                key={letter}
                onClick={() => toggleLetter(letter)}
                className={`w-10 h-10 flex justify-center items-center border-2 border-blue-600 cursor-pointer ${
                  openItems.includes(letter)
                    ? "bg-white text-blue-600"
                    : "bg-blue-600 text-white"
                }`}
              >
                {letter}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="divide-y divide-gray-200 mt-8">
        {allLetters.map((letter) => (
          <AccordionItem
            key={letter}
            ref={itemRefs.current[letter]}
            title={letter}
            content={getContentForLetter(letter)}
            isOpen={openItems.includes(letter)}
            onToggle={() => toggleLetter(letter)}
          />
        ))}
      </div>
    </div>
  );
};

export default AlphabetCollapse;
