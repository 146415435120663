import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../../../../../store/store";
import NumberVerification from "./number-verification";
import EmailVerification from "./email-verification";
import {
  sendUserVerifyEmail,
  sendUserVerifySms,
  getUserInfo,
} from "../../../../../store/content-reducer/personInfoSlice";
import { formatPhoneNumber } from "../../../../../lib/utils";



const Contact: React.FC = () => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.userInfo);
  const [phones, setPhones] = useState(false);
  const [emails, setEmails] = useState(false);

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  const email = () => {
    dispatch(sendUserVerifyEmail({ email: userInfo?.email }));
    setEmails(true);
  };

  const emailc = () => {
    setEmails(false);
  };

  const phone = () => {
    dispatch(sendUserVerifySms({}));
    setPhones(true);
  };

  const phonec = () => {
    setPhones(false);
  };

  console.log("userInfo", userInfo); // Logging userInfo to check the phone number format
  return (
    <div className="md:flex hidden items-center w-full h-auto px-4 bg-white rounded-md shadow-lg">
      <div className="flex flex-col w-full">
        <div className="w-full h-auto">
          <div className="flex items-center justify-between w-full py-4 border-b">
            <div className="flex items-center">
              <span className="w-16 text-black poppins-semibold text-sm md:text-md">
                E-mail:
              </span>
              <span className="text-[13px] text-gray-500 truncate poppins-medium lg:text-md">
                {userInfo?.email || "Email Giriniz"}
              </span>
            </div>
            {userInfo.is_email_verified !== undefined &&
              userInfo.is_email_verified === 0 && (
                <button
                  onClick={email}
                  className="px-4 py-1 bg-gray-300 hover:bg-mains duration-300 group"
                >
                  <span className="text-sm text-gray-600 poppins-semibold group-hover:text-white">
                    Doğrula
                  </span>
                </button>
              )}

            {emails && <EmailVerification onClose={emailc} />}
          </div>

          <div className="flex items-center justify-between w-full gap-1 py-4 border-b">
            <div className="flex items-center gap-1 truncate">
              <span className="w-16 text-black poppins-semibold text-sm md:text-md">
                Telefon:
              </span>
              <span className="text-[13px] text-gray-500 truncate poppins-medium lg:text-md">
                {userInfo?.phone
                  ? formatPhoneNumber(userInfo.phone, userInfo.phone_code)
                  : "Telefon Numarası Giriniz"}
              </span>
            </div>
            {userInfo.is_phone_verified !== undefined &&
              userInfo.is_phone_verified === 0 && (
                <button
                  className="px-4 py-1 bg-gray-300 hover:bg-mains duration-300 group"
                  onClick={phone}
                >
                  <span className="text-sm text-gray-600 poppins-semibold group-hover:text-white">
                    Doğrula
                  </span>
                </button>
              )}

            {phones && <NumberVerification onClose={phonec} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
