import CustomInput from "../custom-input";
import { BsFillEyeSlashFill } from "react-icons/bs";
import CustomButton from "../custom-button";
import { useState } from "react";
import { IoEyeSharp } from "react-icons/io5";
import Axios from "../../../../../services/axios";
import { useDispatch } from "react-redux";
import { postUserPassword } from "../../../../../store/content-reducer/personInfoSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface IUpdatePassword {
  old_password: string;
  new_password: string;
  new_password_confirmation: string;
}

const ChangePassword = () => {
  const axiosInstance = new Axios();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [leftIconChange, setLeftIconChange] = useState<boolean>(false);
  const [midIconChange, setMidIconChange] = useState<boolean>(false);
  const [rightIconChange, setRightIconChange] = useState<boolean>(false);
  const [updatedPassword, setUpdatedPassword] = useState<IUpdatePassword>({
    old_password: "",
    new_password: "",
    new_password_confirmation: "",
  });

  const [errors, setErrors] = useState({
    old_password: false,
    new_password: false,
    new_password_confirmation: false,
  });

  const leftClick = (): void => {
    setLeftIconChange(!leftIconChange);
  };
  const midClick = (): void => {
    setMidIconChange(!midIconChange);
  };
  const rightClick = (): void => {
    setRightIconChange(!rightIconChange);
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();
    console.log(updatedPassword);
    dispatch<any>(postUserPassword(updatedPassword));
  };
  const handleChange = (field: string, value: string): void => {
    setUpdatedPassword({
      ...updatedPassword,
      [field]: value !== "" ? value.toString() : "",
    });
  };

  const handleSave = async () => {
    let hasError = false;
    const newErrors = {
      old_password: false,
      new_password: false,
      new_password_confirmation: false,
    };

    if (!updatedPassword.old_password) {
      newErrors.old_password = true;
      hasError = true;
    }
    if (!updatedPassword.new_password) {
      newErrors.new_password = true;
      hasError = true;
    }
    if (!updatedPassword.new_password_confirmation) {
      newErrors.new_password_confirmation = true;
      hasError = true;
    }

    if (
      updatedPassword.new_password !== updatedPassword.new_password_confirmation
    ) {
      newErrors.new_password = true;
      newErrors.new_password_confirmation = true;
      hasError = true;
      toast.error("Şifreler uyuşmuyor");
    }

    if (
      updatedPassword.new_password.length <= 8 ||
      updatedPassword.new_password_confirmation.length <= 8 ||
      updatedPassword.old_password.length <= 8
    ) {
      toast.error("Şifre en az 8 karakter olmalıdır");
      hasError = true;
    }

    setErrors(newErrors);

    if (hasError) {
      return;
    }

    try {
      await dispatch<any>(postUserPassword(updatedPassword)).unwrap();
      toast.success("Şifre başarıyla değiştirildi");
      setUpdatedPassword({
        old_password: "",
        new_password: "",
        new_password_confirmation: "",
      });
      navigate("/profilim");
    } catch (error: any) {
      if (error && error.error) {
        toast.error(error.error);
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col border-t-2 border-gray-200 "
    >
      <div className="flex gap-10 items-center mt-6 person-info">
        <CustomInput
          type={leftIconChange ? "text" : "password"}
          onIconClick={leftClick}
          icon={leftIconChange ? BsFillEyeSlashFill : IoEyeSharp}
          title="Eski Şifre"
          design={`w-full pl-2 text-base/9`}
          onChange={(value) => handleChange("old_password", value)}
          isError={errors.old_password}
        />
        <CustomInput
          type={midIconChange ? "text" : "password"}
          onIconClick={midClick}
          icon={midIconChange ? BsFillEyeSlashFill : IoEyeSharp}
          title="Yeni Şifre"
          design={`w-full pl-2 text-base/9`}
          onChange={(value) => handleChange("new_password", value)}
          isError={errors.new_password}
        />
        <CustomInput
          type={rightIconChange ? "text" : "password"}
          onIconClick={rightClick}
          icon={rightIconChange ? BsFillEyeSlashFill : IoEyeSharp}
          title="Yeni Şifre Onay"
          design={`w-full pl-2 text-base/9`}
          onChange={(value) => handleChange("new_password_confirmation", value)}
          isError={errors.new_password_confirmation}
        />
      </div>

      <div className="flex justify-end my-2">
        <div onClick={() => handleSave()}>
          <CustomButton title="KAYDET" designs="py-2 px-3 custom-btn mt-2" />
        </div>
      </div>
    </form>
  );
};

export default ChangePassword;
