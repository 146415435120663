import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { AiOutlinePlus, AiOutlineClose, AiOutlineLoading3Quarters } from 'react-icons/ai';
import Cropper from 'react-easy-crop';
import { Point, Area } from 'react-easy-crop/types';
import Swal from 'sweetalert2';

interface CropArea {
  x: number;
  y: number;
  width: number;
  height: number;
}

export const PhotoUploadModal = ({
  isOpen,
  closeModal,
  onFileSelect,
  onSave,
}: {
  isOpen: boolean;
  closeModal: () => void;
  onFileSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSave: (file: File) => void;
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [croppedImage, setCroppedImage] = useState<File | null>(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return null;
    }
    const file = event.target.files[0];
    if (file) {
      const size = (file.size / (1024 * 1024))

      if (size > 2) {
        setErrorMessage('Lütfen daha küçük boyuta sahip bir görsel seçiniz.')
      } else {
        setSelectedFile(file);
        setPreviewUrl(URL.createObjectURL(file));
        setErrorMessage(null)
      }
    }
  };

  const handleSave = async () => {

    if (loading) return

    setLoading(true)
    if (selectedFile) {
      await onSave(croppedImage!);
      setSelectedFile(null);
      setPreviewUrl(null);
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Uyarı',
        text: 'Lütfen görsel seçiniz'
      })
    }
    
    setLoading(false)
  };

  const handleSelectCroppedImage = () => {
    if (croppedImage) {
      onSave(croppedImage);
      closeModal();
      setSelectedFile(null);
      setPreviewUrl(null);
      setCroppedImage(null);
      setCroppedImageUrl(null);
    }
  };

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.setAttribute('crossOrigin', 'anonymous'); // CORS politikaları için gerekebilir
      image.onload = () => resolve(image);
      image.onerror = reject;
      image.src = url;
    });

  const getCroppedImg = async (
    imageSrc: string,
    pixelCrop: CropArea,
  ): Promise<File> => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error('Unable to create canvas context');
    }

    // Ensuring the image never shows up distorted
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        resolve(new File([blob], 'croppedImage.jpeg', { type: 'image/jpeg' }));
      }, 'image/jpeg');
    });
  };

  const onCropComplete = async (
    croppedArea: CropArea,
    croppedAreaPixels: CropArea,
  ) => {
    try {
      const croppedImageFile = await getCroppedImg(
        previewUrl!,
        croppedAreaPixels,
      );
      setCroppedImage(croppedImageFile);
    } catch (error) {
      console.error('Error during image cropping:', error);
    }
  };

  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={closeModal}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500  bg-opacity-75 transition-opacity " />
        </Transition.Child>

        <div className="fixed  z-10 inset-0 overflow-y-auto ">
          <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center  sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative font-poppins  bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full">
                <button
                  onClick={closeModal}
                  className="absolute top-3 right-3 text-gray-500 hover:text-gray-800">
                  <AiOutlineClose size={16} />
                </button>
                <div className="bg-white px-4 pt-3 sm:p-6 sm:pb-4 min-h-[200px]">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full h-full">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-primary100">
                        LOGO GÜNCELLE
                      </Dialog.Title>
                      <div className="flex-col md:flex-row flex gap-x-5 h-full gap-4">
                        <div className='basis-1/2'>
                          <div className="mt-2">
                            <label
                              htmlFor="fileChange"
                              className="flex w-full justify-between text-xs p-3 border rounded-lg items-center cursor-pointer">
                              <div>Fotoğraf Seçiniz</div>
                              <div className="flex items-center px-3 py-2 bg-primary100 text-white rounded text-sm space-x-1">
                                <AiOutlinePlus />
                                <div className=" ">Seçim</div>
                              </div>
                            </label>
                            <input
                              type="file"
                              hidden
                              id="fileChange"
                              accept='image/*'
                              onChange={handleFileChange}
                            />
                            {previewUrl && (
                              <div className="relative bg-white w-72 h-72 mt-5">
                                <Cropper
                                  image={previewUrl}
                                  crop={crop}
                                  zoom={zoom}
                                  aspect={1 / 1}
                                  onCropChange={setCrop}
                                  onCropComplete={onCropComplete}
                                  onZoomChange={setZoom}
                                />
                                <div className="controls ">
                                  <input
                                    type="range"
                                    value={zoom}
                                    min={1}
                                    max={5}
                                    //step={0.1}
                                    aria-labelledby="Zoom"
                                    onChange={(e: any) => {
                                      setZoom(e.target.value);
                                    }}
                                    className="zoom-range"
                                  />
                                </div>
                              </div>
                            )}
                            {croppedImageUrl && (
                              <div className="cropped-image-preview">
                                <img src={croppedImageUrl} alt="Cropped" />
                                <button
                                  onClick={handleSelectCroppedImage}
                                  className="select-cropped-image-button">
                                  Select Cropped Image
                                </button>
                              </div>
                            )}
                          </div>
                          {errorMessage && (
                            <label className='text-red-600 font-poppins text-xs'>
                              {errorMessage}
                            </label>
                          )}
                        </div>
                        <div className="basis-1/2">
                          <div className="text-gray-400 mb-3">
                            Logo Ekleme Hakkında Bilgiler
                          </div>
                          <div className="">
                            <div className="flex gap-x-2 items-center mb-1">
                              <div className="bg-primary100 rounded-full w-2 h-2"></div>
                              <p className="text-xs text-gray-500">
                                Logo boyutu 2 MB'dan fazla olmamalıdır.
                              </p>
                            </div>
                            <div className="flex gap-x-2 items-center">
                              <div className="bg-primary100 rounded-full w-2 h-2"></div>
                              <p className="text-xs text-gray-500">
                                Logo türleri PNG, JPEG, JPG olmalıdır.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleSave}>
                    {loading? (
                      <AiOutlineLoading3Quarters className='animate-spin text-xl'/>
                    ): 'Kaydet'}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={closeModal}>
                    Kapat
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
