import { LabelHTMLAttributes, useContext } from "react"
import { textareaContextType, TextareaContext } from "."
import classNames from "classnames"




type labelPropsType = LabelHTMLAttributes<HTMLLabelElement> & {

}








export default function Label({
    children,
    className,
    ...props
}: labelPropsType) {

    const { required }: textareaContextType = useContext(TextareaContext)

    const cn = classNames({
        'text-gray-700 font-semibold text-[13px] font-poppins': true
    })
    return(
        <label
        className={`${cn} ${className}`}
        {...props}
        >
            {children}{required && <b className="text-red-600">*</b>}
        </label>
    )
}