import Axios from "../services/axios"
import { cdnHighSchoolBranchType } from "../types"







type getHighSchoolBranchesPropsType = {
    search?: string,
    limit?: number,
    only_ids?: number[]
}




const axios = new Axios()



export async function getHighSchoolBranches({
    limit,
    search,
    only_ids
}: getHighSchoolBranchesPropsType): Promise<cdnHighSchoolBranchType[]> {
    try {
        const response = await axios.request({
            endpoint: `cdn/get-high-school-branches?
            ${search? `search=${search}&`: ''}
            ${limit? `limit=${limit}&`: ''}
            ${only_ids? `only_ids=${JSON.stringify(only_ids)}&`: ''}
            `,
            type: 'get'
        })

        if (response && response.data && response.data.high_school_branches) {
            return JSON.parse(JSON.stringify(response.data.high_school_branches))
        } else {
            return []
        }
    } catch (error) {
        console.log(`High school branches fetch failed. Error: ${error}`)
        return []
    }
}