import * as React from "react";
import { ICustomCard } from "../../../types/ICustomCard";
import { FreeCard } from "../../../../../../../components/common/login/free-card";
import { CustomCard } from "../../../../../../../components/common/login/custom-card";
import { CustomCardMobile } from "../../../../../../../components/common/login/custom-card/mobile/index";
import { useIsMobile } from "../../../../../../../hooks/useIsMobile";
import { useNavigate } from "react-router-dom";
import Axios from "../../../../../../../services/axios";
import { useCookie } from "../../../../../../../hooks/useCookie";
import { CookieName } from "../../../../../../../constants/enums";

interface ICardSectionProps {
  readonly packages: ICustomCard[];
  freeNavigate?: () => void;
}

function CardSection(props: ICardSectionProps): React.ReactElement {
  const axios = new Axios();
  const { isMobile } = useIsMobile();
  const [expandedState, setExpandedState] = React.useState<boolean[]>([]);
  const navigate = useNavigate();

  const [rightToCreateAdvertCount, setRightToCreateAdvertCount] =
    React.useState<number>(0);

  React.useEffect(() => {
    setExpandedState(props.packages.map((_, index) => index < 2));
  }, [props.packages]);

  const { getCookie } = useCookie();
  const companyUserToken = getCookie(CookieName.CompanyUserToken);

  React.useEffect(() => {
    if (companyUserToken) {
      const fetchCount = async () => {
        const response = await axios.authRequest({
          type: "get",
          endpoint: "companies/home/get-count-dashboard",
          payload: {},
        });

        setRightToCreateAdvertCount(
          response.data.data.right_to_create_advert_count
        );
      };
      fetchCount();
    }
  }, []);

  const toggleExpand = (index: number) => {
    setExpandedState((prevState) =>
      prevState.map((state, i) => (i === index ? !state : state))
    );
  };

  const isSingleProduct = React.useMemo(() => {
    return props.packages?.filter((item) => item.single_product === "0");
  }, [props.packages]);

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full px-3 py-12 rounded drop-shadow-md bg-gray-50/80 transition-all duration-300">
        <FreeCard
          id={1}
          name={"Ücretsiz İlan Paketi"}
          prices={""}
          description={
            "Hesabınıza her ay tanımlanan 1 adet ücretsiz ilan paketi ile ilan yayınlayabilirsiniz. İlanınız 30 gün boyunca yayında kalacaktır."
          }
          onClick={() => {
            if (rightToCreateAdvertCount > 0) {
              if (props.freeNavigate) {
                props.freeNavigate();
              } else {
                navigate("/isveren/ilanlar/olustur/ilan-bilgileri");
              }
            }
          }}
        />
        {isSingleProduct &&
          isSingleProduct.map((item, index) => {
            if (isMobile) {
              return (
                <CustomCardMobile
                  key={index}
                  {...item}
                  isExpanded={expandedState[index]}
                  onToggleExpand={() => toggleExpand(index)}
                />
              );
            }

            return (
              <div key={index}>
                <CustomCard
                  id={item.id}
                  name={item.name}
                  prices={item.prices}
                  description={item.description}
                  isFirstElement={index < 2}
                  advert={item.advert}
                  cuff={item.cuff}
                  cv={item.cv}
                  doping={item.doping}
                  is_active={item.is_active}
                  onClick={item.onClick}
                  single_product={item.single_product}
                  discounted_price={item.discounted_price}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export { CardSection };
