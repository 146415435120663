import React, { useEffect, useState } from "react";
import { FaCheck, FaPlus, FaRegCircle } from "react-icons/fa";
import { FaCircle, FaRegBuilding } from "react-icons/fa6";
import { Checkbox, Input } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useNavigate } from "react-router-dom";
import Axios from "../../../../../services/axios";
import Cookies from "js-cookie";
import { GrSend } from "react-icons/gr";
import { IoLocationOutline } from "react-icons/io5";
import { useCookie } from "../../../../../hooks/useCookie";
import { CookieName } from "../../../../../constants/enums";
import Swal from "sweetalert2";
import { LuLoader2 } from "react-icons/lu";
import { SelectComponent } from "../../../../company-page-container/advert-applies-page/filter-section/components/components/select-component-new";

type CvSelect = {
  inPopup?: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
  onReady?: () => void;
  cvs: any;
  adData: any;
};

const AdvertApply: React.FC<CvSelect> = ({
  inPopup = false,
  onSuccess = () => {},
  onCancel = () => {},
  onReady = () => {},
  cvs,
  adData,
}) => {
  const [selected, setSelected] = useState(
    cvs[0] !== undefined ? cvs[0].id : 0
  );
  const axios = new Axios();
  const [title, setTitle] = useState("");
  const [onYaziVisible, setOnYaziVisible] = useState(false);
  const [text, setText] = useState("");
  const [textId, setTextId] = useState<number | null>(null);
  const [followCompany, setFollowCompany] = useState(true);
  const { getCookie } = useCookie();
  const companyToken = getCookie(CookieName.CompanyUserToken);
  const userToken = getCookie(CookieName.UserToken);
  const TOKEN = userToken ? userToken : companyToken;
  const [texts, setTexts] = useState<any[]>([]);
  const [loading, setloading] = useState(false);
  const [plainTextLength, setPlainTextLength] = useState(0);

  useEffect(() => {
    const fetchOnYazi = async () => {
      try {
        const response = await axios.request({
          type: "get",
          endpoint: `user/profilim/text`,
          payload: {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
            },
          },
        });
        setTexts(response.data.data);
      } catch (error) {
        console.log(error, "error");
      }
    };

    fetchOnYazi();
  }, []);

  const handleOnYaziVisible = () => {
    if (onYaziVisible) {
      setTitle("");
      setText("");
    }
    setOnYaziVisible(!onYaziVisible);
  };

  const handleChangeTitle = (e: any) => {
    setTitle(e.target.value);
  };

  const handleChangeText = (event: any, editor: any) => {
    const data = editor.getData();
    setText(data);
    setPlainTextLength(stripHtmlTags(data).length);
  };

  const stripHtmlTags = (html: string) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  function formatDate(dateString: string): string {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("tr-TR", options);
    return formattedDate.split(".").join(".");
  }

  const handleApply = () => {
    if (loading) return;

    if (title !== "" || text !== "") {
      if (title === "") {
        Swal.fire({
          title: "Uyarı...",
          text: "Başlık Alanınıda giriniz",
          icon: "error",
          confirmButtonText: "Tamam",
        });
        return;
      }
      if (text === "") {
        Swal.fire({
          title: "Uyarı...",
          text: "Ön yazı detayını girin.",
          icon: "error",
          confirmButtonText: "Tamam",
        });
        return;
      }
    }

    let data = {
      text_id: textId,
      follow_company: followCompany,
      title: title,
      detail: text,
      cv_id: cvs.id,
      answers: [],
    };

    let config = {
      type: "post",
      endpoint: `adverts/${adData.advert.id}/apply-to-advert`,
      payload: {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
        ...data,
      },
    };

    setloading(true);
    axios
      .request(config)
      .then((response: any) => {
        if (response.data.status_code === 200) {
          onSuccess && onSuccess();
        }
      })
      .catch((error: any) => {
        Swal.fire({
          title: "Uyarı...",
          text: "Başvuru Yapılırken Bir Hata Oluştu",
          icon: "error",
          confirmButtonText: "Tamam",
        });
        setloading(false);
        console.log(error);
      });
  };

  onReady && onReady();
  return (
    <div>
      <div className={"p-5"}>
        <div className="flex flex-col justify-center items-center w-full  gap-1  border-b pb-3">
          <div className="flex w-full justify-between items-center">
            <p className="text-mains font-poppins poppins-medium text-lg">
              {adData?.advert?.title}
            </p>
            <p className=" font-poppins poppins-medium text-xs text-gray-600">
              {formatDate(adData?.company_detail?.created_at)}
            </p>
          </div>

          <div className="flex flex-col justify-center items-start w-full gap-[2px]">
            <p className=" font-poppins poppins-medium text-xs flex justify-center items-center gap-1 text-gray-600">
              <FaRegBuilding className=" text-xs text-mains" />
              {adData.company_detail.company_name
                ? adData.company_detail.company_name
                : "FİRMA ADI GİZLİ"}
            </p>
            <p className=" font-poppins poppins-medium text-xs flex justify-center items-center gap-1 text-gray-600">
              <IoLocationOutline className=" text-xs text-mains" />
              {adData.districts
                ? adData.districts.length > 0
                  ? adData.districts[0].name + " / " + adData.cities[0].name
                  : adData.cities[0].name
                : adData.cities[0].name}
            </p>
          </div>
        </div>

        <div className="flex flex-col items-start  pt-3 pb-2 w-full justify-center">
          <p className="font-poppins poppins-medium text-[13px] text-gray-600">
            Özgeçmiş
          </p>

          <div className=" flex flex-col justify-center items-start pt-3 pb-3">
            <p className="font-poppins poppins-medium text-sm flex justify-center items-center gap-1 text-gray-600 cursor-pointer">
              <FaCheck className="text-green-500 text-sm" />
              <span>{cvs?.title}</span>-
              <span>{cvs?.lang === "tr" ? "Türkçe" : "English"}</span>-
              <span>%{cvs?.score} </span>
            </p>
          </div>
        </div>

        <div className="flex flex-col items-start justify-center w-full ">
          <p className="flex justify-center items-center font-poppins poppins-medium text-[13px] text-gray-600 gap-1 mb-3">
            <span>Ön Yazı Seçimi</span> <span className="text-red-500">*</span>{" "}
          </p>

          <SelectComponent
            placeholder="Ön Yazı Kullanmak İstemiyorum"
            label={""}
            keyString={"label"}
            selectedvalue={textId}
            onChange={(e) => {
              if (e === 0) {
                setOnYaziVisible(false);
                setTitle("");
                setText("");
                setTextId(null);
              } else {
                setOnYaziVisible(true);
                setTitle(texts.filter((el: any) => el.id === e)[0].title);
                setText(texts.filter((el: any) => el.id === e)[0].detail);
                setTextId(e as number);
                setPlainTextLength(stripHtmlTags(text).length);
              }
            }}
            data={[
              {
                detail: "Ön Yazı Kullanmak İstemiyorum",
                id: 0,
                label: "Ön Yazı Kullanmak İstemiyorum",
              },
              ...texts.map((el: any) => {
                return {
                  detail: el.detail,
                  id: el.id,
                  label: el.title,
                };
              }),
            ]}
          />
        </div>

        <div
          onClick={() => handleOnYaziVisible()}
          className="flex justify-start items-center gap-1 text-mains w-full pt-4 cursor-pointer"
        >
          <FaPlus className="text-xs" />
          <p className="font-poppins poppins-medium text-[13px]">
            Yeni Ön Yazı Oluştur
          </p>
        </div>

        <div className="flex justify-start items-center w-full mt-2 gap-1 gro">
          <Checkbox
            id={"cucubox"}
            className=""
            checked={followCompany}
            onChange={(e) => setFollowCompany(e.target.checked)}
          />
          <label
            htmlFor={"cucubox"}
            className="font-poppins poppins-medium text-xs text-gray-600"
          >
            Yeni iş ilanlarını kaçırmamak için bu şirketi takip et
          </label>
        </div>

        {onYaziVisible ? (
          <div className="flex flex-col items-start justify-center  pt-3 w-full">
            <div className="flex flex-col w-full">
              <p className="font-poppins poppins-medium text-sm text-gray-600">
                Ön Yazı Adı <span className="text-red-500">*</span>
              </p>
              <Input
                value={title}
                onChange={handleChangeTitle}
                className="w-full mt-2"
                placeholder="Başlık"
              />
            </div>

            <div className="w-full mt-2">
              <CKEditor
                editor={ClassicEditor}
                data={text}
                onChange={handleChangeText}
                config={{
                  toolbar: [
                    "heading",
                    "|",
                    "bold",
                    "italic",
                    "link",
                    "bulletedList",
                    "numberedList",
                    "blockQuote",
                    "|",
                    "undo",
                    "redo",
                  ],
                }}
              />
              <div className={"text-sm py-1"}>{plainTextLength}/3000</div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div
        onClick={() => {
          handleApply();
        }}
        className="w-full rounded-md p-5 border-t "
      >
        <button
          className={`${
            loading ? "bg-gray-400" : "bg-mains"
          }  text-white font-poppins poppins-medium text-base w-full  rounded-md h-12 flex justify-center items-center gap-1`}
        >
          Başvur
          {loading ? (
            <LuLoader2 className="text-xl animate-spin" />
          ) : (
            <GrSend className="text-xl" />
          )}
        </button>
      </div>
    </div>
  );
};

export default AdvertApply;
