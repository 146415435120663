import React from "react";
import Breadcrump from "../../../../components/common/profile/breadcrump";
import MyCv from "./my-cv";
import SaveWrite from "./save-write";
import MyAbstracts from "./abstracts/MyAbstracts";

const Body: React.FC = () => {
  return (
    <div className="w-full flex flex-col gap-y-6">
      <Breadcrump type="page" pageName="ÖZGEÇMİŞLER" />
      <MyCv />
      <MyAbstracts/>
    </div>
  );
};

export default Body;
