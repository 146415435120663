import { useEffect, useRef, useState } from "react";
import { IoChevronDown } from "react-icons/io5";

interface AdDetailProps {
    adDetail: string,
    file?: string,
    advertLanguage: number
}
function AdDetail({ adDetail, file, advertLanguage }: AdDetailProps): React.ReactElement {

    const contentDivRef = useRef<HTMLDivElement>(null)

    const turkishlanguageId = 9
    const englishLanguageId = 7

    const isEnglish: boolean = advertLanguage == englishLanguageId

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isExpandActive, setIsExpandActive] = useState<boolean>(false)
    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    useEffect(() => {
        if (contentDivRef && contentDivRef.current) {
            const divHeight = contentDivRef.current.clientHeight
            if (divHeight >= 200) {
                setIsExpandActive(true)
            }
        }
    }, [contentDivRef, adDetail])
    return (
        <div className="font-poppins">
            <div className="bg-white md:text-xl shadow-lg rounded py-3 pl-3 font-medium">
                {isEnglish? 'Job Description & Required Qualifications': 'İş Tanımı & Aranan Nitelikler'}
            </div>

            <div className="relative">
                <div
                ref={contentDivRef}
                className={`adDetailHTMLContent pr-12 bg-white md:text-lg shadow-lg border-t py-3 pl-8 text-[#63636c] ${isExpandActive? `${isExpanded? `max-h-max overflow-auto`: `max-h-[200px] overflow-hidden`}`: `overflow-auto`} transition-all`}
                //style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}
                dangerouslySetInnerHTML={{ __html: adDetail }}>
                {/* HTML content from adDetail will be rendered here */}
                </div>
                {isExpandActive && (
                    <div className={`${isExpanded? 'flex h-auto bg-white': 'absolute h-[100px] bg-gradient-to-t from-white via-white to-transparent'} bottom-0 left-0 flex items-end justify-center w-full p-2 `}>
                        <button className="p-2 flex flex-row items-center gap-2 outline-none" onClick={() => setIsExpanded((prev) => !prev)}>
                            {isExpanded? `${isEnglish? 'Show Less': 'Daha Az Göster'}`: `${isEnglish? 'Read More': 'Devamını Oku'}`}
                            <IoChevronDown className={`${isExpanded? 'rotate-180': ''} transition-all`}/>
                        </button>
                    </div>
                )}
            </div>
            
        </div>
    );
}

export default AdDetail;