import { LabelHTMLAttributes, useContext } from "react"
import { MultiSelectSearchDropdownContext, multiSelectSearchDropdownContextType } from "."
import classNames from "classnames"



type labelPropsType = LabelHTMLAttributes<HTMLLabelElement> & {

}










export default function Label({
    onClick,
    className,
    children,
    ...props
}: labelPropsType) {

    const { required, setIsOpen, error, mode }: multiSelectSearchDropdownContextType = useContext(MultiSelectSearchDropdownContext)
    
    const cn = classNames({
        'text-[13px] font-poppins font-semibold text-gray-700': true
    })
    return(
        <label 
        {...props} 
        className={`${className} ${cn}`}
        onClick={((e) => {
            if (onClick) {
                onClick(e)
            }

            setIsOpen((prev) => !prev)
        })}
        >
            {children} {required? (
                <b className="text-red-600">*</b>
            ): null}
        </label>
    )
}