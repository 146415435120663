import React, { useState, useEffect, useContext } from "react";
import "leaflet/dist/leaflet.css";
import { FaTimes } from "react-icons/fa";
import { useCookie } from "../../../../hooks/useCookie";
import { CookieName } from "../../../../constants/enums";
import Axios from "../../../../services/axios";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { CVPreviewContext } from "../contents";
import { SelectComponent } from "../../candidate-pool-page/filter-section/components/components/select-component-new";
import { CandidatePoolFilterProps } from "../../candidate-pool-page";
import useFetchCdnQuery from "../../../../hooks/useFetchCdnQuery";
import { Textarea } from "../../../../components/isbul-ui";

const axios = new Axios();
interface SendMessagePopupProps {
  onClose: () => void;
  onSuccess?: () => void;
  byPassBlock?: boolean;
  id: number;
}

const SendMessagePopup: React.FC<SendMessagePopupProps> = ({
  onClose,
  id,
  onSuccess,
  byPassBlock,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [text, setText] = useState("");
  const [loading, setloading] = useState(false);
  const { isBlock } = useContext(CVPreviewContext);
  const [formData, setFormData] = useState<any>({
    year: undefined,
    month: undefined,
    day: undefined,
    hour: "",
    interview_location_id: 2,
    address: "",
    notes: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({
    year: false,
    month: false,
    day: false,
    hour: false,
    interview_location_id: false,
    address: false,
    notes: false,
    message: false,
  });

  const { slug } = useParams();

  useEffect(() => {
    const timeout = setTimeout(() => {
      document.body.classList.add("overflow-hidden", "h-auto");
      setIsVisible(true);
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  const handleCloseClick = (isSuccess = false) => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
      if (isSuccess && onSuccess) {
        onSuccess();
      }
      document.body.classList.remove("overflow-hidden", "h-auto");
    }, 500);
  };

  const handleSubmit = () => {
    if (isBlock && !byPassBlock) return;
    if (loading) return;

    let errorArr = [];

    if (!formData.day) errorArr.push("day");
    if (!formData.month) errorArr.push("month");
    if (!formData.year) errorArr.push("year");
    if (formData.hour.trim() === "" || formData.hour.length !== 5) {
      errorArr.push("hour");
    }
    if (formData.interview_location_id === 0) {
      errorArr.push("interview_location_id");
    }

    if (
      formData.interview_location_id === 1 &&
      formData.address.trim() === ""
    ) {
      errorArr.push("address");
    }

    if (errorArr.length > 0) {
      errorArr.forEach((key) => {
        setFormErrors((prevs) => {
          return {
            ...prevs,
            [key]: true,
          };
        });
      });
      return;
    }

    setloading(true);
    axios
      .authRequest({
        type: "post",
        endpoint: "companies/interview/create",
        payload: {
          user_id: id,
          year: formData.year,
          month: formData.month,
          day: formData.day,
          hour: formData.hour,
          interview_location_id: formData.interview_location_id,
          address: formData.address,
          notes: formData.notes,
          message: formData.message,
        },
      })
      .then((response: any) => {
        if (response.data.status_code === 200) {
          Swal.fire({
            icon: "success",
            title: "Mülakat Daveti Gönderildi",
            confirmButtonText: "Tamam",
          });
          handleCloseClick(true);
        }
      })
      .catch((error: any) => {
        Swal.fire({
          title: "Uyarı...",
          text: "Mülakat Daveti Bir Hata Oluştu",
          icon: "error",
          confirmButtonText: "Tamam",
        });
        setloading(false);
      });
  };

  //day data
  const dayData: any[] = [];
  for (let i = 1; i <= 31; i++) {
    dayData.push({ label: `${i}`, id: i });
  }
  //month data
  const monthNames = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];
  const monthData: any = [];
  for (let i = 0; i < 12; i++) {
    monthData.push({ label: monthNames[i], id: i + 1 });
  }
  //year data
  const startYear = 2024;
  const currentYear = startYear + 3;

  const yearData: any = [];
  for (let year = currentYear; year >= startYear; year--) {
    yearData.unshift({ label: year.toString(), id: year });
  }

  const hours = [
    { value: "", id: 0 },
    { value: "08:00", id: 1 },
    { value: "08:30", id: 2 },
    { value: "09:00", id: 3 },
    { value: "09:30", id: 4 },
    { value: "10:00", id: 5 },
    { value: "10:30", id: 6 },
    { value: "11:00", id: 7 },
    { value: "11:30", id: 8 },
    { value: "12:00", id: 9 },
    { value: "12:30", id: 10 },
    { value: "13:00", id: 11 },
    { value: "13:30", id: 12 },
    { value: "14:00", id: 13 },
    { value: "14:30", id: 14 },
    { value: "15:00", id: 15 },
    { value: "15:30", id: 16 },
    { value: "16:00", id: 17 },
    { value: "16:30", id: 18 },
    { value: "17:00", id: 19 },
    { value: "17:30", id: 20 },
    { value: "18:00", id: 21 },
    { value: "18:30", id: 22 },
    { value: "19:00", id: 23 },
    { value: "19:30", id: 24 },
    { value: "20:00", id: 25 },
    { value: "20:30", id: 26 },
    { value: "21:00", id: 27 },
    { value: "21:30", id: 28 },
    { value: "22:00", id: 29 },
    { value: "22:30", id: 30 },
  ];

  //SRC SELECT DATA
  const [interviewPlace, setInterviewPlace] = useState<any>([]);
  const intQuery = useFetchCdnQuery(
    "get-interview-locations",
    "get-interview-locations",
    "interview_locations"
  );
  useEffect(() => {
    if (intQuery.data !== undefined) {
      setInterviewPlace([...intQuery?.data]);
    }
  }, [intQuery.data, intQuery.fetchStatus]);

  return (
    <div className="fixed top-0 left-0 inset-0 z-[1050] flex items-start justify-center overflow-y-auto ">
      <div
        className="fixed inset-0 bg-black opacity-50 h-auto "
        onClick={(e) => {
          handleCloseClick();
          e.stopPropagation();
        }}
      ></div>

      <div
        className={`bg-white my-10 max-md:my-0 max-md:fixed max-md:top-0 max-md:left-0 max-md:w-screen max-md:h-screen max-md:overflow-y-auto md:rounded-lg md:shadow-lg  md:relative md:min-w-[40%] transition-all duration-500 ease-in-out transform ${
          isVisible ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-10"
        }`}
      >
        <div className={"flex justify-between border-b-2 py-5 px-5"}>
          <div className={"text-mains font-bold text-lg"}>Mülakat Oluştur</div>
          <div
            onClick={(e) => {
              handleCloseClick();
              e.stopPropagation();
            }}
            className={
              "flex justify-self-end justify-center items-center w-5 h-5 "
            }
          >
            <FaTimes className={"text-gray-400"} />
          </div>
        </div>

        <div className="flex flex-col items-start justify-center gap-3 p-5 w-full">
          <div className={"flex flex-col w-full"}>
            <p className="text-sm text-gray-600 md:text-base">
              Mülakat Tarihi
              <span className={"align-super text-red-500"}>*</span>
            </p>
            <div className={"flex gap-x-5 w-full"}>
              <SelectComponent
                error={formErrors.day}
                data={dayData}
                keyString={"label"}
                label=""
                placeholder="Gün"
                onChange={(value) => {
                  setFormData((prev: any) => {
                    return {
                      ...prev,
                      day: value as number,
                    };
                  });
                  setFormErrors((prev) => {
                    return {
                      ...prev,
                      day: false,
                    };
                  });
                }}
                selectedvalue={formData.day}
              />
              <SelectComponent
                error={formErrors.month}
                keyString={"label"}
                data={monthData}
                label=""
                placeholder="Ay"
                onChange={(value) => {
                  setFormData((prev: any) => {
                    return {
                      ...prev,
                      month: value as number,
                    };
                  });
                  setFormErrors((prev) => {
                    return {
                      ...prev,
                      month: false,
                    };
                  });
                }}
                selectedvalue={formData.month}
              />
              <SelectComponent
                error={formErrors.year}
                keyString={"label"}
                data={yearData}
                label=""
                placeholder="Yıl"
                onChange={(value) => {
                  setFormData((prev: any) => {
                    return {
                      ...prev,
                      year: value as number,
                    };
                  });
                  setFormErrors((prev) => {
                    return {
                      ...prev,
                      year: false,
                    };
                  });
                }}
                selectedvalue={formData.year}
              />
            </div>
          </div>
          <div className={"grid grid-cols-1 md:grid-cols-3 gap-5 w-full"}>
            <div className={"flex flex-col"}>
              <p className="text-sm text-gray-600 md:text-base">
                Mülakat Yeri
                <span className={"align-super text-red-500"}>*</span>
              </p>
              <SelectComponent
                keyString={"name"}
                data={interviewPlace}
                label=""
                placeholder="Mülakat Yeri"
                onChange={(value) => {
                  setFormData((prev: any) => {
                    if (value === 0) {
                      return {
                        ...prev,
                        interview_location_id: 2,
                      };
                    }
                    if (value === 1) {
                      setFormErrors((prev) => {
                        return {
                          ...prev,
                          address: false,
                        };
                      });
                    }

                    return {
                      ...prev,
                      interview_location_id: value as number,
                    };
                  });
                }}
                selectedvalue={formData.interview_location_id}
              />
            </div>
            <div className={"flex flex-col"}>
              <p className="text-sm text-gray-600 md:text-base">
                Mülakat Saati
                <span className={"align-super text-red-500"}>*</span>
              </p>
              <SelectComponent
                error={formErrors.hour}
                keyString={"value"}
                data={hours}
                label=""
                placeholder="Saat"
                onChange={(value) => {
                  setFormData((prev: any) => {
                    if (value === 0) {
                      return {
                        ...prev,
                        hour: "",
                      };
                    }
                    return {
                      ...prev,
                      hour: (value + "") as string,
                    };
                  });
                  if (value !== 0) {
                    setFormErrors((prev) => {
                      return {
                        ...prev,
                        hour: false,
                      };
                    });
                  }
                }}
                selectedvalue={formData.hour}
                checkWithValue={true}
              />
            </div>
          </div>
          <div className={"grid grid-cols-1 md:grid-cols-2 gap-5 w-full"}>
            {formData.interview_location_id === 1 && (
              <div className={"flex flex-col col-span-2"}>
                <p className="text-sm text-gray-600 md:text-base">
                  Mülakat Adresi
                  <span className={"align-super text-red-500"}>*</span>
                </p>
                <Textarea
                  required
                  className="my-0 lg:my-0"
                  mode={formData.address.trim().length > 0 ? "dirty" : "empty"}
                  error={formErrors.address}
                >
                  <Textarea.Field
                    className="min-h-[100px]"
                    value={formData.address}
                    onChange={(e) => {
                      setFormData((prev: any) => {
                        return {
                          ...prev,
                          address: e.target.value,
                        };
                      });
                      setFormErrors((prev) => {
                        return {
                          ...prev,
                          address: e.target.value === "" ? true : false,
                        };
                      });
                    }}
                  />
                </Textarea>
              </div>
            )}

            <div className={"flex flex-col col-span-2"}>
              <p className="text-sm text-gray-600 md:text-base">Notlar</p>
              <Textarea
                required
                className="my-0 lg:my-0 w-full"
                mode={false ? "dirty" : "empty"}
                error={false}
              >
                <Textarea.Field
                  placeholder={
                    "Bu alana yazdığınız notlar iş arayana görünmeyecektir."
                  }
                  className="min-h-[100px]"
                  value={formData.notes}
                  onChange={(e) => {
                    setFormData((prev: any) => {
                      return {
                        ...prev,
                        notes: e.target.value,
                      };
                    });
                  }}
                />
              </Textarea>
            </div>
          </div>

          <div className={"grid grid-cols-1 gap-5 w-full"}>
            <div className={"flex flex-col"}>
              <p className="text-sm text-gray-600 md:text-base">Mesaj</p>
              <Textarea
                required
                className="my-0 lg:my-0"
                mode={false ? "dirty" : "empty"}
                error={false}
              >
                <Textarea.Field
                  className="min-h-[100px]"
                  value={formData.message}
                  onChange={(e) => {
                    setFormData((prev: any) => {
                      return {
                        ...prev,
                        message: e.target.value,
                      };
                    });
                  }}
                />
              </Textarea>
            </div>
          </div>
        </div>

        <div className={"flex justify-end gap-5 border-t-2 py-5 px-5"}>
          <div
            onClick={(e) => {
              handleCloseClick();
              e.stopPropagation();
            }}
            className={
              "p-3 bg-gray-200 hover:bg-[#ff7f1d] hover:text-white transition-all rounded cursor-pointer font-bold"
            }
          >
            Kapat
          </div>
          {isBlock && !byPassBlock ? (
            <></>
          ) : (
            <div
              onClick={() => {
                handleSubmit();
              }}
              className={
                "p-3 bg-mains text-white justify-self-center rounded cursor-pointer font-bold"
              }
            >
              Gönder
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SendMessagePopup;
