import { clsx, type ClassValue } from "clsx";
import { NavigateFunction } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { Notification } from "../types/Notification";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(...inputs));
}

export const formatPhoneNumber = (phoneNumber: string, phoneCode: string) => {
  // Remove non-numeric characters
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  // Match and format the number
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return `0 (${match[1]}) ${match[2]} ${match[3]} ${match[4]}`;
  }
  return phoneNumber;
};

export function formatTurkishPhone(number: string) {
  // Tüm rakam olmayan karakterleri kaldır
  let digits = number.replace(/\D/g, "");

  // Eğer başında 0 varsa kaldır
  if (digits.startsWith("0")) {
    digits = digits.slice(1);
  }

  // Eğer başında ülke kodu (90) varsa kaldır
  if (digits.startsWith("90")) {
    digits = digits.slice(2);
  }

  // Eğer 10 hane yoksa, eksik haneleri X ile doldur
  while (digits.length < 10) {
    digits += "X";
  }

  // Eğer 10 haneden fazlaysa, ilk 10 haneyi al
  digits = digits.slice(0, 10);

  // Formatı uygula
  return `0(${digits.slice(0, 3)}) ${digits.slice(3, 6)} ${digits.slice(
    6,
    8
  )} ${digits.slice(8)}`;
}
export const formatMoney = (amount: number | string) => {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const onNotificationClick = (
  notification: Notification,
  navigate: NavigateFunction
) => {
  if (notification.guard === "USER") {
    if (notification.type_info === "new_message" && notification.param_id) {
      navigate(`/profilim/mesajlar/${notification.param_id}`);
    }

    if (
      notification.type_info === "open_cv_visibility" &&
      notification.param_id
    ) {
      //http://localhost:3001/profilim/cv/2139061/goruntuleyen-firmalar
      navigate(`/profilim/cv/${notification.param_id}/goruntuleyen-firmalar`);
    }

    if (notification.type_info === "user_welcome_adverts") {
      navigate(`/is-ilanlari`);
    }

    if (notification.type_info === "user_welcome_cv") {
      navigate(`/profilim/cv`);
    }
  }

  if (notification.guard === "COMPANY") {
    if (notification.type_info === "new_message" && notification.param_id) {
      navigate(`/isveren/mesajlar/${notification.param_id}`);
    }

    if (
      notification.type_info === "company_apply_info" &&
      notification.param_id
    ) {
      navigate(`/isveren/cv/${notification.param_id}`);
    }
  }
};
