import { FaWhatsapp } from "react-icons/fa";
import { CiFacebook } from "react-icons/ci";
import { FaXTwitter } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
interface CompanyDetail {
  companyDetail:
    | { facebook: ""; logo: ""; twitter: ""; whatsapp: "" }
    | undefined;
    email?: string
}
function ShareButtons({ companyDetail, email }: CompanyDetail): React.ReactElement {

  //console.log('company detail', companyDetail)
  return (
    <div className="flex justify-between border-b py-3 font-poppins bg-white border-t shadow-sm">
      <div className="flex justify-start w-full">
        <div className="flex items-center space-x-4 basis-1/4">
          <span className="md:text-lg text-[#2253af] font-medium pl-3">
            PAYLAŞ:
          </span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${companyDetail?.whatsapp}`}
          >
            <div className="flex items-center justify-center md:h-7 md:w-7 w-5 h-5 rounded-full bg-green-500">
              <FaWhatsapp className="md:text-xl text-white" />
            </div>
          </a>
          <a target="_blank" href={`${companyDetail?.facebook}`}>
            <div className="flex items-center justify-center md:h-7 md:w-7 w-5 h-5 rounded-full bg-[#1057D8]">
              <CiFacebook className="md:text-2xl text-white" />
            </div>
          </a>
          <a target="_blank" href={`${companyDetail?.twitter}`}>
            <div className="flex items-center justify-center md:h-7 md:w-7 w-5 h-5 rounded-full bg-[black]">
              <FaXTwitter className="md:text-xl text-white" />
            </div>
          </a>
          {email && (
            <a target="_blank" href={`mailto:${email}`}>
              <div className="flex items-center justify-center md:h-7 md:w-7 w-5 h-5 rounded-full bg-[#E47A29]">
                <MdEmail className="md:text-xl text-white" />
              </div>
            </a>
          )}
          
        </div>
      </div>
    </div>
  );
}
export default ShareButtons;
