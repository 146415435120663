import * as React from "react";
import { CustomButton } from "../custom-button";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { ICustomCard } from "../../../../containers/login-page-container/company/login/types/ICustomCard";
import { StarIcon } from "../../../../theme/icons";
import { mockPackagesBodyContent } from "../../../../containers/login-page-container/company/login/mocks/index";
import { CustomCardMobile } from "./mobile";
import { useNavigate } from "react-router-dom";
import { useCookie } from "../../../../hooks/useCookie";
import { CookieName } from "../../../../constants/enums";
import Swal from "sweetalert2";

function CustomCardComponent(props: ICustomCard) {
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();

  const { getCookie } = useCookie();
  const companyUserToken = getCookie(CookieName.CompanyUserToken);

  if (isMobile) {
    return (
      <CustomCardMobile
        isExpanded={false}
        onToggleExpand={function (): void {
          throw new Error("Function not implemented.");
        }}
        {...props}
      />
    );
  }

  const handleBuyClick = () => {
    if (companyUserToken) {
      navigate(`/isveren/paket/${props.id}/satin-al`);
    } else {
      Swal.fire({
        title: "Paket satın almak için lütfen giriş yapın.",
        icon: "warning",
        cancelButtonText: "İptal",
        confirmButtonText: "Giriş Yap",
        confirmButtonColor: "#2253af",
        showCancelButton: true,
        customClass: {
          confirmButton: "custom-buy-confirm-button bg-mains",
          cancelButton: "custom-buy-cancel-button",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/isveren/giris");
        }
      });
    }
  };

  return (
    <div className="transition-all duration-500 shadow-xl w-full min-h-[640px] flex flex-col gap-y-5 items-center pt-5 relative bg-mains/95  hover:shadow-lg hover:shadow-company-card border-mains hover:border-company-card justify-between text-sm rounded-md">
      <div className="flex flex-row items-center w-full justify-center">
        <h2 className="poppins-semibold text-2xl text-white">{props.name}</h2>

        <div className="absolute right-0 top-0 px-4 py-5">
          <StarIcon size={28} color="gold" />
        </div>
      </div>

      <div className="flex flex-col justify-center items-center">
        <p className="font-poppins text-white poppins-regular text-base text-center">
          {props?.advert} Adet 30 Günlük İlan
        </p>

        <p className="font-poppins text-white poppins-regular text-base text-center">
          {props?.doping && props.doping !== 0
            ? `${props?.doping} Adet Doping`
            : ""}
        </p>

        <p className="font-poppins text-white poppins-regular text-base text-center">
          {props?.cv && props.cv !== 0
            ? `${props.cv} Adet CV Görüntüleme Hakkı`
            : ""}
        </p>

        <p className="font-poppins text-white poppins-regular text-base text-center">
          {props?.cuff && props.cuff !== 0 ? `${props.cuff} Adet Manşet` : ""}
        </p>
      </div>

      <div className="h-[1px] w-full bg-white opacity-50" />
      <div className="flex gap-y-3 flex-col justify-center">
        {mockPackagesBodyContent.map((item, index) => (
          <div
            key={index}
            className="flex gap-x-2 items-center  md:px-2 lg:px-4 xl:p-0"
          >
            <item.icon
              size={20}
              color={`${item.icon === StarIcon ? "gold" : "white"}`}
            />
            <p className="poppins-medium text-white">{item.text}</p>
          </div>
        ))}
      </div>
      <div className="h-[1px] w-full bg-white opacity-50" />
      {props.prices && (
        <div className="flex flex-col">
          {props.discounted_price && (
            <span className="text-xl text-white poppins-semibold line-through">
              {props.prices}
            </span>
          )}
          <div className="flex items-center justify-center">
            <span className="text-xl text-white poppins-semibold">
              {props.discounted_price} ₺
            </span>
            <span className="text-base text-white poppins-semibold ml-1">
              {" "}
              + KDV
            </span>
          </div>
        </div>
      )}
      <CustomButton
        text={"Satın Al"}
        className="px-4 py-2 text-white bg-company-card text-lg poppins-semibold w-[95%] h-[50px] rounded-md mb-2"
        // onClick={() => navigate(`/isveren/paket/${props.id}/satin-al`)}
        onClick={() => handleBuyClick()}
      />
    </div>
  );
}

const CustomCard = React.memo(CustomCardComponent);

export { CustomCard };
