import React from 'react';
import ScatterPlot from "./ScatterPlot";

const SalaryHistory: React.FC = () => {
  const salaryData = [
    { year: 2017, minSalary: 10000, avgSalary: 15000, maxSalary: 20000 },
    { year: 2018, minSalary: 11000, avgSalary: 16000, maxSalary: 21000 },
    { year: 2019, minSalary: 12000, avgSalary: 17000, maxSalary: 22000 },
    { year: 2020, minSalary: 13000, avgSalary: 18000, maxSalary: 23000 },
    { year: 2021, minSalary: 14000, avgSalary: 19000, maxSalary: 24000 },
    { year: 2022, minSalary: 15000, avgSalary: 20000, maxSalary: 25000 },
    { year: 2023, minSalary: 16000, avgSalary: 21000, maxSalary: 26000 },
    { year: 2024, minSalary: 17000, avgSalary: 22000, maxSalary: 27000 },
  ];

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      <div className="p-6">
        <ScatterPlot />
        <h1 className="text-2xl mb-4">Maaş Geçmişi</h1>
        <div className="mt-4">
          <div className="flex flex-col">
            <div className="grid grid-cols-4 gap-4 mb-2 font-bold">
              <div>Yıl</div>
              <div>En Düşük Maaş</div>
              <div>Ortalama Maaş</div>
              <div>En Yüksek Maaş</div>
            </div>
            {salaryData.map((data) => (
                <div>
              <div key={data.year} className="grid grid-cols-4 gap-4 items-center mb-4 mt-4">
                <div className="flex items-center">
                  <div className="w-12 h-12 flex justify-center items-center rounded-full bg-blue-500 text-white text-sm px-4">
                    {data.year}
                  </div>
                </div>
                <div>{data.minSalary.toLocaleString()} TL</div>
                <div>{data.avgSalary.toLocaleString()} TL</div>
                <div>{data.maxSalary.toLocaleString()} TL</div>
              </div>
            <hr ></hr>
         </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalaryHistory;
