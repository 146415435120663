import { RxHamburgerMenu } from "react-icons/rx";
import React, { MouseEvent, useContext, useEffect } from "react";
import { IoTriangleSharp } from "react-icons/io5";
import { TiArrowSortedUp } from "react-icons/ti";
import { MdOutlineMessage } from "react-icons/md";
import { GoDownload } from "react-icons/go";
import { CVPreviewContext } from "../contents";
import { FaFolderOpen } from "react-icons/fa6";

const HamburgerMenuMobile = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const { setAddNoteShow, setAddFolderShow, handleDownloadPdf } =
    useContext(CVPreviewContext);

  const handleClickOutside = (event: MouseEvent | any) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);

  return (
    <div ref={ref} className={"relative"}>
      <div
        onClick={() => setIsOpen(true)}
        className={
          "flex justify-center items-center bg-mains text-white h-8 w-8 rounded-full mr-3"
        }
      >
        <RxHamburgerMenu />
      </div>
      {isOpen && (
        <div className="absolute w-56 bg-white border  rounded-xl mt-5 shadow-lg -left-5 p-3 z-50">
          <div className="relative ">
            <div className="absolute -top-[40px] ">
              <TiArrowSortedUp className="text-5xl text-white" />
            </div>
            <div className="divide-y">
              <div
                onClick={() => {
                  setIsOpen(false);
                  setAddNoteShow(true);
                }}
                className="flex justify-start items-center py-1 text-gray-600"
              >
                <MdOutlineMessage />
                <div className={"ml-3"}>Yorum Ekle</div>
              </div>

              <div
                onClick={() => {
                  setIsOpen(false);
                  setAddFolderShow(true);
                }}
                className="flex justify-start items-center py-1 text-gray-600"
              >
                <FaFolderOpen />
                <div className={"ml-3"}>Klasöre Ekle</div>
              </div>

              <div
                onClick={() => {
                  setIsOpen(false);
                  setTimeout(() => {
                    handleDownloadPdf();
                  }, 300);
                }}
                className="flex justify-start items-center py-1 text-gray-600"
              >
                <GoDownload />
                <div className={"ml-3"}>Cv İndir</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenuMobile;
