import { LabelHTMLAttributes } from "react"
import { useContext } from "react"
import { SelectContext, selectContextType } from "."


type errorPropsType = LabelHTMLAttributes<HTMLLabelElement>





export default function Error({
    className,
    ...props
}: errorPropsType) {

    const { error }: selectContextType = useContext(SelectContext)

    if (error) {
        return(
            <label {...props} className={`${className} text-red-600 text-xs`}></label>
        )
    } else {
        return null
    }

}