import * as React from "react";
import { Dispatch, SetStateAction, useRef, useEffect } from "react";
function HeaderCard(): React.ReactElement {


  return (
    <div className="bg-white flex flex-col lg:flex-row p-4 py-6 mt-0 lg:mt-4 font-poppins border-b items-center justify-between">
        <h1 className="text-2xl text-mains font-medium pl-6">İLAN YAYINLA</h1>
        <p className="text-center lg:text-end font-poppins text-[12px] lg:text-[14px] mt-2 lg:mt-0 text-gray-800">
          <label>HESABINIZA HER AY ÜCRETSİZ 1 ADET İLAN HAKKI TANIMLANMAKTADIR</label><br/>
          <label>YAYINLADIĞINIZ HER İLAN (30 GÜN) YAYINDA KALACAKTIR</label>
        </p>
    </div>
  );
}
export { HeaderCard };
