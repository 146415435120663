import React, { useContext } from "react";
import { ApplyPoolContext } from "../../index";
import { CiFilter } from "react-icons/ci";

interface HeaderSectionProps {
  onAllCheckboxToggle: () => void;
  isCheckedAll: boolean;
}

function HeaderSection({
  onAllCheckboxToggle,
  isCheckedAll,
}: HeaderSectionProps) {
  const { setDetailFiltersOpen, detailFiltersOpen } =
    useContext(ApplyPoolContext);
  return (
    <div className="text-primary200 mb-1 font-poppins">
      {/* Mobile View */}
      <div className="flex gap-4 justify-between border-y-[1px] border-gray-400 bg-mainBg  pl-4 pr-5 py-2 md:hidden ml-1 mr-1 shadow ">
        <div className={"flex gap-4 items-center"}>
          <input
            type="checkbox"
            checked={isCheckedAll}
            onChange={onAllCheckboxToggle}
          />
          <p className="text-lg"> Başvurular</p>
        </div>
        <button
          type="button"
          className=" flex items-center px-8 py-[.35rem] bg-orng100 text-white rounded  text-left text-md   "
          onClick={() => {
            setDetailFiltersOpen(!detailFiltersOpen);
          }}
        >
          <CiFilter /> Filtrele
        </button>
      </div>

      {/* Desktop View */}
      <div
        className="justify-between font-poppins items-center border-y-2 border-gray-300 bg-mainBg  pl-4 pr-5 py-4
      hidden md:flex mx-10 shadow lg:gap-[21rem] md:gap-[25vw]"
      >
        <div className="flex gap-4  font-poppins ">
          <input
            type="checkbox"
            checked={isCheckedAll}
            onChange={onAllCheckboxToggle}
          />
          <p> Aday</p>
        </div>
        <div className="flex justify-between items-center flex-1 font-poppins">
          <p className="flex-1">Meslek/Eğitim Bilgisi</p>
          <p className="flex-1">Son İş Deneyimi </p>
          <p>İşlemler</p>
        </div>
      </div>
    </div>
  );
}

export { HeaderSection };
