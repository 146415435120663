import { IoReturnUpBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
interface SaveAndNextButtonProps {
  advertId: string | undefined;
  companyEmail: string | undefined;
}
const SaveAndNextButton: React.FC<SaveAndNextButtonProps> = ({
  advertId,
  companyEmail,
}) => {
  const navigate = useNavigate();

  console.log("advertId", advertId);

  const handleNavigate = () => {
    if (advertId) {
      navigate(`/isveren/ilanlar/ilan/${advertId}/doping`, {
        state: { companyEmail },
      });
    }
  };
  const handleEditNavigate = () => {
    if (advertId) {
      navigate(`/isveren/ilanlar/ilan/${advertId}/guncelle/`, {
        state: { companyEmail },
      });
    }
  };
  return (
    <div className="md:flex md:justify-between w-full my-3 font-poppins mt-3">
      <button
        onClick={handleEditNavigate}
        className="w-full md:w-auto px-12 h-[50px] flex justify-center
                items-center text-gray-600 bg-[#e5e6ec] rounded-md 
                cursor-pointer text-xl
                transition-colors duration-300 mb-4 md:mb-0"
        id="new-button-id"
      >
        <IoReturnUpBack className="mr-2" />
        Geri Dön
      </button>
      <button
        onClick={handleNavigate}
        className="w-full md:w-auto px-6 h-[50px] flex justify-center
                items-center text-white bg-[#2253AF] rounded-md 
                cursor-pointer text-lg hover:bg-[#1e4a8c] 
                transition-colors duration-300"
        id="save-to-publish"
      >
        Kaydet ve Devam Et &gt;
      </button>
    </div>
  );
};
export default SaveAndNextButton;
