import React, { useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

type Props = {
    title: string,
    subtitle: string,
    children: React.ReactNode;
    isOpen?: boolean;
}

const CustomAccordion = (props: Props) => {
    const [open, setOpen] = useState(false || props.isOpen);
    return (
        <div className='bg-white shadow-sm flex flex-wrap font-poppins'>
            <div className="flex flex-wrap w-full md:py-5 py-3 md:px-7 px-5 cursor-pointer" onClick={() => setOpen(!open)}>
                <div className="flex flex-col w-10/12">
                    <h4 className='text-[#2253af] font-semibold'>{props.title}</h4>
                    <p className='text-gray-800 text-sm'>{props.subtitle}</p>
                </div>
                {open ?
                    <IoIosArrowUp className='2/12 h-full text-3xl text-[#2253af] ms-auto' />
                    :
                    <IoIosArrowDown className='2/12 h-full text-3xl text-[#2253af] ms-auto' />
                }
            </div>
            <div className={`w-full md:py-5 py-3 md:px-7 px-3 pt-5 border-t border-gray-200 ${open ? 'block' : 'hidden'}`}>
                {props.children}
            </div>
        </div>
    )
}

export default CustomAccordion