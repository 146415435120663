import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import Axios from "../../../../../services/axios";

interface Position {
  title: string;
  slug: string;
  min_salary: number;
  max_salary: number;
  detail: string;
  avg_salary: number;
  calculate_salary: number;
  calculate_university: number;
  created_at: string;
  order: number;
}

interface GeneralInfoProps {
  positionData: Position[];
}

const CreateResume: React.FC<GeneralInfoProps> = ({ positionData }) => {
  const axios = new Axios();
  const userToken = Cookies.get("userToken");

  const [cvId, setCvId] = useState<number>(0);

  useEffect(() => {
    if (userToken) {
      const fetchCv = async () => {
        try {
          const response = await axios.request({
            endpoint: `user/profilim/cv`,
            type: "get",
            payload: {},
          });

          console.log(response);

          if (response?.data?.data) {
            setCvId(response.data.data.id);
          }
        } catch (error) {
          console.log("Error fetching data:", error);
        }
      };

      fetchCv();
    }
  }, []);

  return (
    <div className="bg-white p-4 shadow rounded-lg ">
      <p className="text-center text-[#63636c] font-poppins text-base">
        isbul.net'te her gün güncel {positionData[0]?.title} ilanları
        yayınlanıyor. {positionData[0]?.title} olarak işe başlamak için özgeçmiş
        oluştur ve sana en uygun ilanlara başvur.
      </p>

      {userToken ? (
        <div className="flex justify-center items-center">
          <a
            href={`/profilim/cv/duzenle/${cvId}`}
            className="w-[65%] text-white bg-[#2253af] whitespace-nowrap px-2 text-center rounded mt-4 xl:w-[80%] lg:w-[80%] py-3 hover:bg-[#0d6efd] font-medium text-md flex justify-center items-center"
          >
            Özgeçmişini Düzenle
          </a>
        </div>
      ) : (
        <div className="flex justify-center items-center">
          <a
            href="/profilim/giris-yap"
            className="w-[65%] text-white bg-[#2253af]  whitespace-nowrap px-2 text-center rounded mt-4 xl:w-[80%] lg:w-[80%] py-3 hover:bg-[#0d6efd] font-medium text-md flex justify-center items-center"
          >
            Hemen Özgeçmiş Oluştur
          </a>
        </div>
      )}
    </div>
  );
};

export default CreateResume;
