











export default function WelcomePage() {
    return(
        <div>
            
        </div>
    )
}