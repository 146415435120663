import React, { useContext } from "react";
import { RadioComponent } from "../../../components/radio-component-new";
import {
  CandidatePoolContext,
  CandidatePoolFilterProps,
} from "../../../../../index";

interface LanguagesSectionProps {
  isVisible: boolean;
}

const OtherSections: React.FC<LanguagesSectionProps> = ({ isVisible }) => {
  const { newSelectedValues, setNewSelectedValues } =
    useContext(CandidatePoolContext);

  //types data
  const typeData: any = [
    {
      id: 0,
      label: "Tüm Adaylar",
    },
    {
      id: 1,
      label: "İncelenenler",
    },
    {
      id: 2,
      label: "İncelenmeyenler",
    },
  ];

  return (
    <section
      className={`flex flex-col gap-5 mx-2 my-8 text-gray-600 md:flex-row md:w-2/3 ${
        isVisible ? "" : "hidden"
      }`}
    >
      <RadioComponent
        id={"filtertypes"}
        keyString={"label"}
        header=""
        data={typeData}
        onChange={(value) => {
          let newValues: CandidatePoolFilterProps = {
            ...newSelectedValues,
            filter_type: value,
          };
          if (value === 0) {
            delete newValues.filter_type;
          }
          setNewSelectedValues(newValues);
        }}
        selected={newSelectedValues.filter_type}
      />
    </section>
  );
};

export { OtherSections };
