export const addOrUpdateQueryParam = (
  location: any,
  params: { key: string; value: string }[]
): string => {
  const searchParams = new URLSearchParams(location.search);

  params.forEach(({ key, value }) => {
    if (key == "page") {
      if (parseInt(value) === 1) {
        searchParams.delete("page");
      } else {
        searchParams.has(key)
          ? searchParams.set(key, value)
          : searchParams.append(key, value);
      }
    } else {
      searchParams.has(key)
        ? searchParams.set(key, value)
        : searchParams.append(key, value);
    }
  });

  const newSearch = searchParams.toString();
  const newUrl = `${newSearch ? `?${newSearch}` : ""}`;
  return newUrl;
};

export const refreshQueryParam = (
  location: any,
  params: { key: string; value: string }[]
): string => {
  const searchParams = new URLSearchParams();

  params.forEach(({ key, value }) => {
    searchParams.has(key)
      ? searchParams.set(key, value)
      : searchParams.append(key, value);
  });

  const newSearch = searchParams.toString();
  const newUrl = `${newSearch ? `?${newSearch}` : ""}`;
  return newUrl;
};
