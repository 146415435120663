import React, { useEffect, useState } from "react";
import { PiPlus } from "react-icons/pi";
import { Link } from "react-router-dom";
import services from "../../../../../services/profile-services/index";

const IncompleteDetail: React.FC = () => {
  const [userData, setUserData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [cvId, setCvId] = useState<number | null>(null);

  useEffect(() => {
    const fetchIncompleteDetail = async () => {
      try {
        const defaultCvRequest = await services.getDefaultCv();
        console.log(defaultCvRequest.data);
        const { id } = defaultCvRequest.data.data;
        setCvId(id);
        const data = await services.incomplateDetail(id);
        setUserData(data.data.data);
      } catch (error: any) {
        setError(error.message || "Bir Hata Oluştu checkUser Fetch");
      } finally {
        setIsLoading(false);
      }
    };

    fetchIncompleteDetail();
  }, []);

  if (isLoading) {
    return <div>Yükleniyor...</div>;
  }

  if (error) {
    return <div>Hata: {error}</div>;
  }

  return (
    <div
      className={`${
        userData.length == 0 ? "hidden" : "flex"
      } flex-col w-full items-start justify-center shadow-lg h-auto bg-white rounded-md`}
    >
      <div className="w-full px-4 py-3 border-b">
        <span className="text-red-500 text-md poppins-semibold">
          Tamamlanmamış Bölümler
        </span>
      </div>
      <div className="flex  flex-col gap-y-2  py-3 px-3">
        {userData.map((item, index) => (
          <Link to={`/profilim/cv/duzenle/${cvId}${item.link}`} key={item.id}>
            <div className="flex gap-x-3 px-2 py-2 items-center">
              <div className="flex">
                <PiPlus className="text-2xl text-red-500" />
              </div>
              <div className="flex flex-col">
                <span className="text-xs poppins-semibold text-red-500">
                  {item.name}
                </span>
                {item.description && (
                  <span className="text-xs text-light">{item.description}</span>
                )}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default IncompleteDetail;
