import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import WorkingLocation from "./content/working-location";
import { KeywordArea } from "./content/keyword-area";
import { MdExpandMore } from "react-icons/md";
import CustomDropdown from "./content/custom-dropdown";
import ExperienceTime from "./content/experience-time-dropdown/index";
import DropdownLanguages from "./content/dropdown-languages";
import { FaInfo } from "react-icons/fa";
import DualThumbSlider from "./content/dual-thumb-slider";
import HelpCard from "./content/help-card/index";
import InfoCard from "./content/info-card/index";
import {
  AdvertPayload,
  fieldErrorType,
} from "../../../../containers/company-page-container/creat-ads-page/advert-create-form";
import Education from "../../../../containers/company-page-container/creat-ads-page/Database/Education";
import WorkLocation from "../../../../containers/company-page-container/creat-ads-page/Database/WorkLocation";
import TypeWork from "../../../../containers/company-page-container/creat-ads-page/Database/TypeWork";
import Sections from "../../../../containers/company-page-container/creat-ads-page/Database/Sections";
import Sectors from "../../../../containers/company-page-container/creat-ads-page/Database/Sectors";
import Languages from "../../../../containers/company-page-container/creat-ads-page/Database/Languages";
import Positions from "../../../../containers/company-page-container/creat-ads-page/Database/Positions";
import PositionLevel from "../../../../containers/company-page-container/creat-ads-page/Database/PositionLevel";
import { PositionsService } from "../../../../services/positions/index";
import { PostionsLevels } from "../../../../services/postions-levels";
import { SectionsService } from "../../../../services/sections";
import { SectorsService } from "../../../../services/sectors";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { Tooltip } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  APIProvider,
  ControlPosition,
  Map,
  MapControl,
  Marker,
} from "@vis.gl/react-google-maps";

import WorkingLocations from "./content/working-locations";
import Axios from "../../../../services/axios";
import {
  cdnCityType,
  cdnEducationLevelType,
  cdnGeneralLanguageType,
  cdnPositionLevelType,
  cdnPositionType,
  cdnSectionType,
  cdnSectorType,
  cdnWOrkingLocationType,
  cdnWorkingTypeType,
  getCitiesResponseType,
} from "../../../../types";
import { DataContextType } from "../../../../store/contextApi/ad-api-context/Iget-ad-data";
import { googleMapsApiKey } from "../../../../constants/enums";
import { IoClose, IoTrashBinOutline } from "react-icons/io5";
import {
  InputArea,
  MultiSelectDropdown,
  MultiSelectSearchDropdown,
  SelectDropdown,
  SelectSearchDropdown,
} from "../../../isbul-ui";
import { getPositions } from "../../../../customHooks/getPositions";
import { getPositionLevels } from "../../../../customHooks/getPositionLevels";
import { getSections } from "../../../../customHooks/getSections";
import { getSectors } from "../../../../customHooks/getSectors";
import { getWorkingTypes } from "../../../../customHooks/getWorkingTypes";
import { getWorkingLocations } from "../../../../customHooks/getWorkingLocations";
import { getEducationLevels } from "../../../../customHooks/getEducationLevels";
import { getGeneralLanguages } from "../../../../customHooks/getGeneralLanguages";
import { useYears } from "../../../../customHooks/useYears";
import Swal from "sweetalert2";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const positionOptions = [
  { value: "1", label: "Sekreter" },
  { value: "2", label: "Muhasebeci" },
];

const levelOptions = [
  { value: "1", label: "Uzman" },
  { value: "2", label: "Müdür" },
];
const experienceOptions = [
  { value: "1-5", label: "1-5" },
  { value: "5-10", label: "5-10" },
  { value: "10'den fazla", label: "10'den fazla" },
];

export interface MapLocation {
  lat: number;
  longitude: number;
}

interface Position {
  id: number;
  title: string;
  title_en: string;
}
interface Sections {
  id: number;
  title: string;
  title_en: string;
}
interface PostionsLevels {
  id: number;
  name: string;
  name_en: string;
}
interface PositionsResponse {
  positions: Position[];
}
interface Option {
  value: string;
  label: string;
}
export interface Location {
  city: number;
  district: number;
}
interface AddDetailProps {
  companyEmail?: string | undefined;
  onDropdownChange: (id: string, selectedOptions: Option[]) => void;
  handleChange: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    isNested?: boolean,
    nestedKey?: string
  ) => void;
  handleLanguageChange: (selectedLanguage: number) => void;
  onExperienceLevelChange: (values: [number, number]) => void;
  onKeywordsChange: (newKeywords: string[]) => void;
  onLocationChange: (locations: Location[]) => void;
  updateMapLocation: (location: MapLocation | null) => void;
  fieldErrors: { [key: string]: boolean };
  lat?: number;
  long?: number;
  formData?: AdvertPayload | null;
  setFormData?: Dispatch<SetStateAction<AdvertPayload>>;
  contextData?: DataContextType;
  setFieldErrors?: Dispatch<SetStateAction<fieldErrorType>>;
}

const axios = new Axios();

const AddDetail: React.FC<AddDetailProps> = ({
  fieldErrors,
  updateMapLocation,
  onLocationChange,
  onDropdownChange,
  handleChange,
  handleLanguageChange,
  onExperienceLevelChange,
  onKeywordsChange,
  companyEmail,
  lat,
  long,
  formData,
  setFormData,
  contextData,
  setFieldErrors,
}) => {
  const languageOptions = ["Türkçe", "İngilizce"];

  const navigate = useNavigate();

  const location = useLocation();

  const [positions, setPositions] = useState<Option[]>([]);
  const [generalLanguages, setGeneralLanguages] = useState<Option[]>([]);
  const [postionsLevels, setPostionsLevels] = useState<Option[]>([]);
  const [sections, setSections] = useState<Option[]>([]);
  const [sectors, setSectors] = useState<Option[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingAdvertCount, setIsLoadingAdvertCount] =
    useState<boolean>(false);
  const [isExperienced, setIsExperienced] = useState(
    contextData?.data?.experience_level != null &&
      contextData?.data?.experience_level.experience_level_min_id
      ? contextData?.data?.experience_level.experience_level_min_id > 41
      : false
  );
  const [experienceRange, setExperienceRange] = useState({ start: 1, end: 25 });
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);
  const [advertLeft, setAdvertLeft] = useState<number>(0);

  const toggleVisibility = () => setIsAccordionOpen(!isAccordionOpen);

  const [cities, setCities] = useState<cdnCityType[]>([]);

  const handleExperienceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleChange(event); // Call the existing handleChange function
    if (event.target.value === "experienced") {
      setIsExperienced(true);
      onExperienceLevelChange([3, 15]);
    } else {
      onExperienceLevelChange([0, 0]);
      setIsExperienced(false);
    }
  };

  //FIXME: Tamamlanınca silinecek
  // Positions fetch
  useEffect(() => {
    const fetchPositions = async () => {
      setIsLoading(true);
      try {
        const response = await axios.request({
          endpoint: "cdn/get-positions",
          type: "get",
        });

        setPositions(
          response.data.positions.map((p: any) => {
            return {
              value: p.id.toString(),
              label: p.title,
            };
          })
        );

        setCdnPositions(response.data.positions);
        setFilteredCdnPositions(response.data.positions);
      } catch (error) {
        console.error("Failed to fetch positions:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPositions();
  }, []);

  // FIXME: tamamlanınca silinecek
  // Position levels fetch
  useEffect(() => {
    const fetchPositions = async () => {
      setIsLoading(true);
      try {
        const response = await axios.request({
          endpoint: "cdn/get-position-levels",
          type: "get",
        });

        setPostionsLevels(
          response.data.position_levels.map((p: any) => {
            return {
              value: p.id.toString(),
              label: p.name,
            };
          })
        );

        setCdnPositionLevels(response.data.position_levels);
        setFilteredCdnPositionLevels(response.data.position_levels);
      } catch (error) {
        console.error("Failed to fetch positions:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPositions();
  }, []);

  // FIXME: Tamamlanınca silinecek
  // Sections
  useEffect(() => {
    const fetchPositions = async () => {
      setIsLoading(true);
      try {
        const response = await axios.request({
          endpoint: "cdn/get-sections",
          type: "get",
        });

        setSections(
          response.data.sections.map((s: any) => {
            return {
              value: s.id.toString(),
              label: s.title,
            };
          })
        );

        setCdnSections(response.data.sections);
        setFilteredCdnSections(response.data.sections);
      } catch (error) {
        console.error("Failed to fetch positions:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPositions();
  }, []);

  // FIXME: Tamamlanınca silinecek
  // Sectors
  useEffect(() => {
    const fetchPositions = async () => {
      setIsLoading(true);
      try {
        const response = await SectorsService.getSectorsByFilter(searchTerm);
        const positionsData = response.data.sectors;
        //console.log("API Response:", positionsData);
        const options = positionsData.map((pos: Sections) => ({
          value: pos.id.toString(),
          label: pos.title,
        }));
        //console.log(options, "optionsoptionsoptions");
        setSectors(options);

        setCdnSectors(response.data.sectors);
        setFilteredCdnSectors(response.data.sectors);
      } catch (error) {
        console.error("Failed to fetch positions:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPositions();
  }, []);

  //FIXME: Tamamlanınca silinecek
  // General languages
  useEffect(() => {
    const fetchGeneralLanguages = async () => {
      setIsLoading(true);
      try {
        const response = await axios.request({
          endpoint: "cdn/get-general-languages",
          type: "get",
        });

        setGeneralLanguages(
          response.data.general_languages.map((l: any) => {
            return {
              value: l.id,
              label: l.name,
            };
          })
        );
      } catch (error) {
        console.log(`Languages fetch failed. Error: ${error}`);
        setGeneralLanguages([]);
      }
      setIsLoading(false);
    };

    fetchGeneralLanguages();
  }, []);

  // FIXME: Tamamlanınca silinecek
  async function getCities() {
    try {
      const response: getCitiesResponseType = await axios.request({
        endpoint: "cdn/get-cities?limit=100",
        type: "get",
      });

      setCities(response.data.cities);
    } catch (error) {
      console.log(`Cities fetch failed. Error: ${error}`);
      setCities([]);
    }
  }

  // FIXME: Tamamlanınca silinecek
  useEffect(() => {
    getCities();
  }, []);

  const initialEmployeeCounts = useYears({ startYear: 100, endYear: 1 });
  const initialAgeCounts = useYears({ startYear: 80, endYear: 15 });

  // Get all cdns
  async function getAllCdns() {
    const [workingTypes, workingLocations, educationLevels, generalLanguages] =
      await Promise.all([
        getWorkingTypes({}),
        getWorkingLocations({}),
        getEducationLevels({}),
        getGeneralLanguages({}),
      ]);

    // Working types
    setCdnWorkingTypes(workingTypes);
    setFilteredCdnWorkingTypes(workingTypes);

    // Workign locations
    setCdnWorkingLocations(workingLocations);
    setFilteredCdnWorkingLocations(workingLocations);

    // Education levels
    setCdnEducationLevels(educationLevels);
    setFilteredCdnEducationLevels(educationLevels);

    // General languages
    setCdnGeneralLanguages(generalLanguages);
    setFilteredCdnGeneralLanguages(generalLanguages);
  }

  async function getAdvertCountLeft(): Promise<number> {
    setIsLoadingAdvertCount(true);
    try {
      const response = await axios.authRequest({
        endpoint: `companies/home/get-count-dashboard`,
        type: "get",
        payload: {},
      });

      if (response && response.data && response.data.data) {
        setAdvertLeft(response.data.data.right_to_create_advert_count);
        if (response.data.data.right_to_create_advert_count <= 0) {
          if (
            location.pathname.startsWith(
              "/isveren/ilanlar/olustur/ilan-bilgileri"
            )
          ) {
            navigate("/isveren/paket");
          }
        }
        return response.data.data.right_to_create_advert_count;
      } else {
        return 0;
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      return 0;
    } finally {
      setIsLoadingAdvertCount(false);
    }
  }
  useEffect(() => {
    getAllCdns();
    getAdvertCountLeft();
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  //Google map component default props
  const mapDefaultProps = {
    center: {
      lat: 0,
      lng: 0,
    },
    zoom: 11,
  };

  useEffect(() => {
    if (contextData) {
      // Positions set
      const initialPositions = contextData.data?.positions;
      if (initialPositions) {
        type posType = {
          position: cdnPositionType;
        };

        const filteredPosition: posType[] = JSON.parse(
          JSON.stringify(initialPositions)
        );

        setSelectedPositions(
          filteredPosition.map((p) => {
            const { ...fields } = p.position;
            return {
              ...fields,
            };
          })
        );
      }

      // Position levels set
      const initialPositionLevels = contextData.data?.position_levels;
      if (initialPositionLevels) {
        type posLvlType = {
          position_level: cdnPositionLevelType;
        };

        const filteredPositionLevels: posLvlType[] = JSON.parse(
          JSON.stringify(initialPositionLevels)
        );

        setSelectedPositionLevels(
          filteredPositionLevels.map((pl) => {
            const { ...fields } = pl.position_level;
            return {
              ...fields,
            };
          })
        );
      }

      // Sections set
      const initialSections = contextData.data?.sections;
      if (initialSections) {
        type sectionType = {
          section: cdnSectionType;
        };

        const filteredSections: sectionType[] = JSON.parse(
          JSON.stringify(initialSections)
        );

        setSelectedSections(
          filteredSections.map((s) => {
            const { ...fields } = s.section;
            return {
              ...fields,
            };
          })
        );
      }

      // Sectors set
      const initialSectors = contextData.data?.sectors;
      if (initialSectors) {
        type sectorType = {
          sector: cdnSectorType;
        };

        const filteredSectors: sectorType[] = JSON.parse(
          JSON.stringify(initialSectors)
        );

        setSelectedSectors(
          filteredSectors.map((s) => {
            const { ...fields } = s.sector;
            return {
              ...fields,
            };
          })
        );
      }

      // Working types set
      const initialWorkingTypes = contextData.data?.working_types;
      if (initialWorkingTypes) {
        type workingTypeType = {
          working_type: cdnWorkingTypeType;
        };

        const filteredWorkingTypes: workingTypeType[] = JSON.parse(
          JSON.stringify(initialWorkingTypes)
        );

        setSelectedWorkingTypes(
          filteredWorkingTypes.map((w) => {
            const { ...fields } = w.working_type;

            return {
              ...fields,
            };
          })
        );
      }

      // Working locations set
      const initialWorkingLocations = contextData.data?.working_locations;
      if (initialWorkingLocations) {
        type workingLocationType = {
          working_location: cdnWOrkingLocationType;
        };

        const filteredWorkingLocations: workingLocationType[] = JSON.parse(
          JSON.stringify(initialWorkingLocations)
        );

        setSelectedWorkingLocations(
          filteredWorkingLocations.map((w) => {
            const { ...fields } = w.working_location;

            return {
              ...fields,
            };
          })
        );
      }

      // Education levels
      const initialEducationLevels = contextData.data?.education_levels;
      if (initialEducationLevels) {
        type educationLevelType = {
          education_level: cdnEducationLevelType;
        };

        const filteredEducationLevels: educationLevelType[] = JSON.parse(
          JSON.stringify(initialEducationLevels)
        );

        setSelectedEducationLevels(
          filteredEducationLevels.map((e) => {
            const { ...fields } = e.education_level;

            return {
              ...fields,
            };
          })
        );
      }

      // General languages
      const initialGeneralLanguages = contextData.data?.languages;
      if (initialGeneralLanguages) {
        type generalLanguageType = {
          language: cdnGeneralLanguageType;
        };

        const filteredGeneralLanguages: generalLanguageType[] = JSON.parse(
          JSON.stringify(initialGeneralLanguages)
        );

        setSelectedLanguages(
          filteredGeneralLanguages.map((l) => {
            const { ...fields } = l.language;

            return {
              ...fields,
            };
          })
        );
      }

      // Advert language
      const initialAdverLanguage = contextData.data?.advert.general_language_id;
      if (initialAdverLanguage) {
        if (initialAdverLanguage == 7) {
          setSelectedAdvertLanguage({
            id: 7,
            name: "İngilizce",
            name_en: "English",
            order: 2,
            created_at: "2015-10-11T21:00:00.000000Z",
            updated_at: "2023-05-09T21:59:47.000000Z",
          });
        } else {
          setSelectedAdvertLanguage({
            id: 9,
            name: "Türkçe",
            name_en: "Turkish",
            order: 1,
            created_at: "2015-10-11T21:00:00.000000Z",
            updated_at: "2023-05-09T21:59:48.000000Z",
          });
        }
      } else {
        setSelectedAdvertLanguage({
          id: 9,
          name: "Türkçe",
          name_en: "Turkish",
          order: 1,
          created_at: "2015-10-11T21:00:00.000000Z",
          updated_at: "2023-05-09T21:59:48.000000Z",
        });
      }

      // Advert Title
      const title = contextData.data?.advert.title;
      setAdvertTitle(title || "");

      // Employee count
      const initialEmployeeCount = contextData.data?.advert.employee_count;
      setSelectedEmployeeCount(initialEmployeeCount || 0);

      // Min age
      const initialMinAge = contextData.data?.advert.min_age;
      setSelectedMinAge(initialMinAge || 0);

      // Max age
      const initialMaxAge = contextData.data?.advert.max_age;
      setSelectedMaxAge(initialMaxAge || 0);
    }
  }, [contextData]);

  /* New states and hooks for new form components */
  // Positions
  const [cdnPositions, setCdnPositions] = useState<cdnPositionType[]>([]);
  const [filteredCdnPositions, setFilteredCdnPositions] = useState<
    cdnPositionType[]
  >([]);
  const [selectedPositions, setSelectedPositions] = useState<cdnPositionType[]>(
    []
  );
  const [searchPosition, setSearchPosition] = useState<string>("");
  // Position Levels
  const [cdnPositionLevels, setCdnPositionLevels] = useState<
    cdnPositionLevelType[]
  >([]);
  const [filteredCdnPositionLevels, setFilteredCdnPositionLevels] = useState<
    cdnPositionLevelType[]
  >([]);
  const [selectedPositionLevels, setSelectedPositionLevels] = useState<
    cdnPositionLevelType[]
  >([]);
  const [searchPositionLevel, setSearchPositionLevel] = useState<string>("");
  // Section
  const [cdnSections, setCdnSections] = useState<cdnSectionType[]>([]);
  const [filteredCdnSections, setFilteredCdnSections] = useState<
    cdnSectionType[]
  >([]);
  const [selectedSections, setSelectedSections] = useState<cdnSectionType[]>(
    []
  );
  const [searchSection, setSearchSection] = useState<string>("");
  // Sectors
  const [cdnSectors, setCdnSectors] = useState<cdnSectorType[]>([]);
  const [filteredCdnSectors, setFilteredCdnSectors] = useState<cdnSectorType[]>(
    []
  );
  const [selectedSectors, setSelectedSectors] = useState<cdnSectorType[]>([]);
  const [searchSector, setSearchSector] = useState<string>("");
  // Working types
  const [cdnWorkingTypes, setCdnWorkingTypes] = useState<cdnWorkingTypeType[]>(
    []
  );
  const [filteredCdnWOrkingTypes, setFilteredCdnWorkingTypes] = useState<
    cdnWorkingTypeType[]
  >([]);
  const [selectedWorkingTypes, setSelectedWorkingTypes] = useState<
    cdnWorkingTypeType[]
  >([]);
  const [searchWorkingType, setSearchWorkingType] = useState<string>("");
  // Working locations
  const [cdnWorkingLocations, setCdnWorkingLocations] = useState<
    cdnWOrkingLocationType[]
  >([]);
  const [filteredCdnWorkingLocations, setFilteredCdnWorkingLocations] =
    useState<cdnWOrkingLocationType[]>([]);
  const [selectedWorkingLocations, setSelectedWorkingLocations] = useState<
    cdnWOrkingLocationType[]
  >([]);
  const [searchWorkingLocation, setSearchWorkingLocation] =
    useState<string>("");
  // Education level
  const [cdnEducationLevels, setCdnEducationLevels] = useState<
    cdnEducationLevelType[]
  >([]);
  const [filteredCdnEducationLevels, setFilteredCdnEducationLevels] = useState<
    cdnEducationLevelType[]
  >([]);
  const [selectedEducationLevels, setSelectedEducationLevels] = useState<
    cdnEducationLevelType[]
  >([]);
  const [searchEducationLevel, setSearchEducationLevel] = useState<string>("");
  // Foreign language
  const [cdnGeneralLanguages, setCdnGeneralLanguages] = useState<
    cdnGeneralLanguageType[]
  >([]);
  const [filteredCdnGeneralLanguages, setFilteredCdnGeneralLanguages] =
    useState<cdnGeneralLanguageType[]>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<
    cdnGeneralLanguageType[]
  >([]);
  const [searchLanguage, setSearchLanguage] = useState<string>("");
  // Advert language
  const [advertLanguages, setAdvertLanguages] = useState<
    cdnGeneralLanguageType[]
  >([
    {
      id: 9,
      name: "Türkçe",
      name_en: "Turkish",
      order: 1,
      created_at: "2015-10-11T21:00:00.000000Z",
      updated_at: "2023-05-09T21:59:48.000000Z",
    },
    {
      id: 7,
      name: "İngilizce",
      name_en: "English",
      order: 2,
      created_at: "2015-10-11T21:00:00.000000Z",
      updated_at: "2023-05-09T21:59:47.000000Z",
    },
  ]);
  const [selectedAdvertLanguage, setSelectedAdvertLanguage] =
    useState<cdnGeneralLanguageType | null>({
      id: 9,
      name: "Türkçe",
      name_en: "Turkish",
      order: 1,
      created_at: "2015-10-11T21:00:00.000000Z",
      updated_at: "2023-05-09T21:59:48.000000Z",
    });
  // Advert Title
  const [advertTitle, setAdvertTitle] = useState<string>("");
  // Employee count
  const [selectedEmployeeCount, setSelectedEmployeeCount] = useState<number>(0);
  // Age
  const [minAges, setMinAges] = useState<number[]>(initialAgeCounts);
  const [maxAges, setMaxAges] = useState<number[]>(initialAgeCounts);
  const [selectedMinAge, setSelectedMinAge] = useState<number>(0);
  const [selectedMaxAge, setSelectedMaxAge] = useState<number>(0);

  // Set form data position
  useEffect(() => {
    if (setFormData) {
      setFormData(({ positions, ...others }) => ({
        positions: selectedPositions.map((p) => {
          return p.id;
        }),
        ...others,
      }));
    }
  }, [selectedPositions]);

  // Set form data position levels
  useEffect(() => {
    if (setFormData) {
      setFormData(({ position_levels, ...others }) => ({
        position_levels: selectedPositionLevels.map((p) => {
          return p.id;
        }),
        ...others,
      }));
    }
  }, [selectedPositionLevels]);

  // Set form data sections
  useEffect(() => {
    if (setFormData) {
      setFormData(({ sections, ...others }) => ({
        sections: selectedSections.map((s) => {
          return s.id;
        }),
        ...others,
      }));
    }
  }, [selectedSections]);

  // Set form data sectors
  useEffect(() => {
    if (setFormData) {
      setFormData(({ sectors, ...others }) => ({
        sectors: selectedSectors.map((s) => {
          return s.id;
        }),
        ...others,
      }));
    }
  }, [selectedSectors]);

  // Set form data working types
  useEffect(() => {
    if (setFormData) {
      setFormData(({ working_types, ...others }) => ({
        working_types: selectedWorkingTypes.map((w) => {
          return w.id;
        }),
        ...others,
      }));
    }
  }, [selectedWorkingTypes]);

  // Set form data working location
  useEffect(() => {
    if (setFormData) {
      setFormData(({ working_locations, ...others }) => ({
        working_locations: selectedWorkingLocations.map((w) => {
          return w.id;
        }),
        ...others,
      }));
    }
  }, [selectedWorkingLocations]);

  // Set form data education level
  useEffect(() => {
    if (setFormData) {
      setFormData(({ education_levels, ...others }) => ({
        education_levels: selectedEducationLevels.map((e) => {
          return e.id;
        }),
        ...others,
      }));
    }
  }, [selectedEducationLevels]);

  // Set form data general languages
  useEffect(() => {
    if (setFormData) {
      setFormData(({ languages, ...others }) => ({
        languages: selectedLanguages.map((l) => {
          return l.id;
        }),
        ...others,
      }));
    }
  }, [selectedLanguages]);

  // Advert language
  useEffect(() => {
    if (selectedAdvertLanguage) {
      if (setFormData) {
        setFormData(({ language, ...others }) => ({
          language: selectedAdvertLanguage.id,
          ...others,
        }));
      }
    }
  }, [selectedAdvertLanguage]);

  // Advert title
  useEffect(() => {
    if (setFormData) {
      setFormData(({ title, ...others }) => ({
        title: advertTitle,
        ...others,
      }));
    }
  }, [advertTitle]);

  // Employee count
  useEffect(() => {
    if (setFormData) {
      setFormData(({ employee_count, ...others }) => ({
        employee_count: selectedEmployeeCount,
        ...others,
      }));
    }
  }, [selectedEmployeeCount]);

  // Min age
  useEffect(() => {
    if (setFormData) {
      setFormData(({ min_age, ...others }) => ({
        min_age: selectedMinAge,
        ...others,
      }));
    }
  }, [selectedMinAge]);

  // Max age
  useEffect(() => {
    if (setFormData) {
      setFormData(({ max_age, ...others }) => ({
        max_age: selectedMaxAge,
        ...others,
      }));
    }
  }, [selectedMaxAge]);

  /* Search actions */
  // Search position
  useEffect(() => {
    async function search() {
      try {
        if (searchPosition.trim().length > 0) {
          const res = await getPositions({ search: searchPosition });
          setFilteredCdnPositions(res);
        } else {
          setFilteredCdnPositions(cdnPositions);
        }
      } catch (error) {
        console.log(`Error: ${error}`);
        setFilteredCdnPositions(cdnPositions);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchPosition]);

  // Position levels
  useEffect(() => {
    async function search() {
      try {
        if (searchPositionLevel.trim().length > 0) {
          const res = await getPositionLevels({ search: searchPositionLevel });
          setFilteredCdnPositionLevels(res);
        } else {
          setFilteredCdnPositionLevels(cdnPositionLevels);
        }
      } catch (error) {
        console.log(`Error: ${error}`);
        setFilteredCdnPositionLevels(cdnPositionLevels);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchPositionLevel]);

  // Sections
  useEffect(() => {
    async function search() {
      if (searchSection.trim().length > 0) {
        const res = await getSections({ search: searchSection });
        setFilteredCdnSections(res);
      } else {
        setFilteredCdnSections(cdnSections);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchSection]);

  // Search sector
  useEffect(() => {
    async function search() {
      if (searchSector.trim().length > 0) {
        const res = await getSectors({ search: searchSector });

        setFilteredCdnSectors(res);
      } else {
        setFilteredCdnSectors(cdnSectors);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchSector]);

  // Search working type
  useEffect(() => {
    async function search() {
      if (searchWorkingType.trim().length > 0) {
        const res = await getWorkingTypes({ search: searchWorkingType });
        setFilteredCdnWorkingTypes(res);
      } else {
        setFilteredCdnWorkingTypes(cdnWorkingTypes);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchWorkingType]);

  // Search working locations
  useEffect(() => {
    async function search() {
      if (searchWorkingLocation.trim().length > 0) {
        const res = await getWorkingLocations({
          search: searchWorkingLocation,
        });
        setFilteredCdnWorkingLocations(res);
      } else {
        setFilteredCdnWorkingLocations(cdnWorkingLocations);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchWorkingLocation]);

  // Search education levels
  useEffect(() => {
    async function search() {
      if (searchEducationLevel.trim().length == 0) {
        const res = await getEducationLevels({ search: searchEducationLevel });
        setFilteredCdnEducationLevels(res);
      } else {
        setFilteredCdnEducationLevels(cdnEducationLevels);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchEducationLevel]);

  // Search general languages
  useEffect(() => {
    async function search() {
      if (searchLanguage.trim().length > 0) {
        const res = await getGeneralLanguages({ search: searchLanguage });
        setFilteredCdnGeneralLanguages(res);
      } else {
        setFilteredCdnGeneralLanguages(cdnGeneralLanguages);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchLanguage]);

  // New Card Component
  return (
    <div>
      {/* Accordion header */}
      <div
        className="w-full bg-white p-4 cursor-pointer mb-2 rounded-b-md shadow-md hidden md:flex justify-between items-center"
        onClick={toggleVisibility}
      >
        <h2 className="font-medium text-[#2253AF] md:text-2xl pl-6">
          İlan Bilgileri
        </h2>
        <MdExpandMore
          className={`transition-transform duration-500 text-[#69737f] ${
            isAccordionOpen ? "rotate-180" : "rotate-0"
          }`}
          size={30}
        />
      </div>
      <h2 className="font-medium text-[#2253AF] md:text-2xl  md:hidden  bg-white p-4">
        İlan Bilgileri
      </h2>
      {/* Accordion Content */}

      {/*Accordion context start  */}
      <div
        className={`${
          isAccordionOpen ? "grid" : "hidden"
        }  w-full  grid-cols-12`}
      >
        {/* Left Column - Most Inputs */}

        <div className="flex flex-col col-span-12 lg:col-span-8 bg-white mb-2 p-4">
          {/* <div>
                  <label
                    htmlFor="advertTitle"
                    className="font-medium text-gray-500 text-sm mb-2"
                  >
                    İlan Başlığı <span className="text-rose-500">*</span>
                  </label>
                  <input
                  type="text"
                  id="title"
                  name="title"
                  value={formData?.title || ''}
                  onChange={handleChange}
                  className={`py-3 px-2 w-full indent-2 border rounded-lg focus:border-blue-500 placeholder:text-gray-800 focus:outline-none shadow-none form-input text-sm ${
                    fieldErrors.title
                      ? "border-red-500 bg-red-100 text-red-700"
                      : formData && formData?.title.trim().length > 0? 'border-blue-500 text-blue-500': 'border-gray-400'
                  }`}
                  placeholder="Ör. Pazarlama Kordinatörü"
                  />
                  <div className="text-xs flex items-center mt-2 mb-4">
                    <FaInfo
                      className="bg-gray-400 rounded-full text-white ml-1 mr-2 p-1"
                      size={16}
                    />
                    <span className="text-red-500">
                      Her ilanı yalnızca bir pozisyon için yayınlayabilirsiniz.
                    </span>
                  </div>
                </div> */}
          <InputArea
            required
            error={fieldErrors.title && advertTitle.length == 0 ? true : false}
            mode={advertTitle.length == 0 ? "empty" : "dirty"}
          >
            <InputArea.Label>İlan Başlığı</InputArea.Label>
            <InputArea.Input
              value={advertTitle || ""}
              onChange={(e) => setAdvertTitle(e.target.value)}
            />
            <div className="text-xs flex items-center mt-2 mb-6">
              <FaInfo
                className="bg-gray-400 rounded-full text-white ml-1 mr-2 p-1"
                size={16}
              />
              <span className="text-red-500">
                Her ilanı yalnızca bir pozisyon için yayınlayabilirsiniz.
              </span>
            </div>
          </InputArea>

          {/* TODO: Yeni component içerisinde onLocationChange ile alakalı bir sorun var
                <div className="mb-4">
                  <WorkingLocation
                    isCityError={fieldErrors.isCityError}
                    isDistrictError={fieldErrors.isDistrictError}
                    onLocationChange={onLocationChange}
                    updateMapLocation={updateMapLocation}
                  />
                </div> */}

          {/* İl-İlçe Seçimi */}
          <div className="mb-4">
            <WorkingLocations
              isCityError={fieldErrors.city}
              isDistrictError={fieldErrors.district}
              setFieldErrors={setFieldErrors}
              onLocationChange={onLocationChange}
              updateMapLocation={updateMapLocation}
              cities={cities}
              /* initialSelectedCityIds={formData?.locations?.map((l) => { return l.city })}
                  initialSelectedDistrictIds={formData?.locations?.map((l) => { return l.district })}
                  initialSelectedLocationIds={formData?.locations} */
              contextLocations={contextData?.data?.locations}
              initialLat={lat}
              initialLong={long}
            />
          </div>

          {/* Harita Konumu Gösterimi */}
          {lat && long && (
            <div className="flex flex-col gap-2 mb-4">
              <div className="flex flex-row items-center justify-between">
                <label className="text-[#6b7280] text-sm">Seçilen Konum</label>
                <button
                  type="button"
                  onClick={() => {
                    updateMapLocation(null);
                  }}
                >
                  <IoTrashBinOutline className="text-red-600" />
                </button>
              </div>
              <APIProvider apiKey={googleMapsApiKey}>
                <Map
                  style={{
                    width: "100%",
                    height: "300px",
                  }}
                  defaultZoom={11}
                  defaultCenter={{ lat: lat || 38, lng: long || 33 }}
                  gestureHandling={"greedy"}
                  disableDefaultUI={false}
                >
                  <Marker position={{ lat: lat || 38, lng: long || 33 }} />
                </Map>
              </APIProvider>
            </div>
          )}

          <div className="flex flex-col lg:flex-row gap-x-4 gap-y-6 justify-start mb-6 lg:mb-4">
            {/* Positions select */}
            <div className="flex w-full">
              <MultiSelectSearchDropdown
                className="w-full"
                error={fieldErrors.positions}
                required
                mode={selectedPositions.length == 0 ? "empty" : "dirty"}
              >
                <MultiSelectSearchDropdown.Label>
                  Pozisyon Adı
                </MultiSelectSearchDropdown.Label>
                <MultiSelectSearchDropdown.Trigger
                  onClick={async () => {
                    if (
                      searchPosition.length == 0 &&
                      filteredCdnPositions.length == 0
                    ) {
                      setIsLoadingData(true);
                      const res = await getPositions({});
                      setFilteredCdnPositions(res);
                      setCdnPositions(res);
                      setIsLoadingData(false);
                    }
                  }}
                >
                  <MultiSelectSearchDropdown.Value>
                    {selectedPositions.length > 0
                      ? `${selectedPositions[0].title} ${
                          selectedPositions.length > 1
                            ? `(+${selectedPositions.length - 1})`
                            : ``
                        }`
                      : "Seçiniz"}
                  </MultiSelectSearchDropdown.Value>
                  <MultiSelectSearchDropdown.Input
                    placeholder={
                      selectedPositions.length > 0
                        ? `${selectedPositions[0].title} ${
                            selectedPositions.length > 1
                              ? `(+${selectedPositions.length - 1})`
                              : ``
                          }`
                        : "Arama Yap"
                    }
                    value={searchPosition || ""}
                    onChange={(e) => setSearchPosition(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key == "Backspace" && searchPosition.length == 0) {
                        e.preventDefault();
                        setSelectedPositions((prev) => prev.slice(0, -1));
                      }
                    }}
                  />
                </MultiSelectSearchDropdown.Trigger>
                <MultiSelectSearchDropdown.Items>
                  {isLoadingData && (
                    <SelectSearchDropdown.Item>
                      <AiOutlineLoading3Quarters className="mx-auto animate-spin" />
                    </SelectSearchDropdown.Item>
                  )}
                  {selectedPositions.map((p) => {
                    return (
                      <MultiSelectSearchDropdown.Item
                        key={`posSelect${p.id}`}
                        selected={
                          selectedPositions.find((pos) => pos.id == p.id) !=
                          undefined
                        }
                        onClick={() => {
                          if (
                            selectedPositions.find((pos) => pos.id == p.id) !=
                            undefined
                          ) {
                            setSelectedPositions((prev) => [
                              ...prev.filter((ps) => ps.id != p.id),
                            ]);
                          } else {
                            if (selectedPositions.length >= 5) {
                              Swal.fire({
                                icon: "warning",
                                title: "Uyarı",
                                text: "En fazla 5 adet pozisyon seçebilirsiniz.",
                              });
                            } else {
                              setSelectedPositions((prev) => [...prev, p]);
                            }
                          }

                          if (setFieldErrors) {
                            setFieldErrors(({ positions, ...others }) => ({
                              positions: false,
                              ...others,
                            }));
                          }
                        }}
                      >
                        {p.title}
                      </MultiSelectSearchDropdown.Item>
                    );
                  })}
                  {filteredCdnPositions.map((p) => {
                    if (
                      selectedPositions.find((sp) => sp.id == p.id) == undefined
                    ) {
                      return (
                        <MultiSelectSearchDropdown.Item
                          key={`posSelect${p.id}`}
                          selected={
                            selectedPositions.find((pos) => pos.id == p.id) !=
                            undefined
                          }
                          onClick={() => {
                            if (
                              selectedPositions.find((pos) => pos.id == p.id) !=
                              undefined
                            ) {
                              setSelectedPositions((prev) => [
                                ...prev.filter((ps) => ps.id != p.id),
                              ]);
                            } else {
                              if (selectedPositions.length >= 5) {
                                Swal.fire({
                                  icon: "warning",
                                  title: "Uyarı",
                                  text: "En fazla 5 adet pozisyon seçebilirsiniz.",
                                });
                              } else {
                                setSelectedPositions((prev) => [...prev, p]);
                              }
                            }

                            if (setFieldErrors) {
                              setFieldErrors(({ positions, ...others }) => ({
                                positions: false,
                                ...others,
                              }));
                            }
                          }}
                        >
                          {p.title}
                        </MultiSelectSearchDropdown.Item>
                      );
                    } else {
                      return null;
                    }
                  })}
                </MultiSelectSearchDropdown.Items>
              </MultiSelectSearchDropdown>
            </div>

            {/* Position levels select */}
            <div className="flex w-full">
              <MultiSelectSearchDropdown
                className="w-full"
                mode={selectedPositionLevels.length == 0 ? "empty" : "dirty"}
                error={fieldErrors.position_levels}
              >
                <MultiSelectSearchDropdown.Label>
                  Pozisyon Seviyesi
                </MultiSelectSearchDropdown.Label>
                <MultiSelectSearchDropdown.Trigger
                  onClick={async () => {
                    if (
                      searchPositionLevel.length == 0 &&
                      filteredCdnPositionLevels.length == 0
                    ) {
                      setIsLoadingData(true);
                      const res = await getPositionLevels({});
                      setFilteredCdnPositionLevels(res);
                      setCdnPositionLevels(res);
                      setIsLoadingData(false);
                    }
                  }}
                >
                  <MultiSelectSearchDropdown.Value>
                    {selectedPositionLevels.length > 0
                      ? `${selectedPositionLevels[0].name} ${
                          selectedPositionLevels.length > 1
                            ? `(+${selectedPositionLevels.length - 1})`
                            : ``
                        }`
                      : "Seçiniz"}
                  </MultiSelectSearchDropdown.Value>
                  <MultiSelectSearchDropdown.Input
                    placeholder={
                      selectedPositionLevels.length > 0
                        ? `${selectedPositionLevels[0].name} ${
                            selectedPositionLevels.length > 1
                              ? `(+${selectedPositionLevels.length - 1})`
                              : ``
                          }`
                        : "Arama Yap"
                    }
                    value={searchPositionLevel || ""}
                    onChange={(e) => setSearchPositionLevel(e.target.value)}
                    onKeyDown={(e) => {
                      if (
                        e.key == "Backspace" &&
                        searchPositionLevel.length == 0
                      ) {
                        e.preventDefault();
                        setSelectedPositionLevels((prev) => prev.slice(0, -1));
                      }
                    }}
                  />
                </MultiSelectSearchDropdown.Trigger>
                <MultiSelectSearchDropdown.Items>
                  {isLoadingData && (
                    <SelectSearchDropdown.Item>
                      <AiOutlineLoading3Quarters className="mx-auto animate-spin" />
                    </SelectSearchDropdown.Item>
                  )}
                  {selectedPositionLevels.map((sp) => {
                    return (
                      <MultiSelectSearchDropdown.Item
                        key={`msslectedsp${sp.id}`}
                        selected={
                          selectedPositionLevels.find((p) => p.id == sp.id) !=
                          undefined
                        }
                        onClick={() => {
                          if (
                            selectedPositionLevels.find((p) => p.id == sp.id) !=
                            undefined
                          ) {
                            setSelectedPositionLevels((prev) =>
                              prev.filter((pr) => pr.id != sp.id)
                            );
                          } else {
                            setSelectedPositionLevels((prev) => [...prev, sp]);
                          }
                        }}
                      >
                        {sp.name}
                      </MultiSelectSearchDropdown.Item>
                    );
                  })}
                  {filteredCdnPositionLevels.map((sp) => {
                    if (
                      selectedPositionLevels.find((p) => p.id == sp.id) ==
                      undefined
                    ) {
                      return (
                        <MultiSelectSearchDropdown.Item
                          key={`msunslectedsp${sp.id}`}
                          selected={
                            selectedPositionLevels.find((p) => p.id == sp.id) !=
                            undefined
                          }
                          onClick={() => {
                            if (
                              selectedPositionLevels.find(
                                (p) => p.id == sp.id
                              ) != undefined
                            ) {
                              setSelectedPositionLevels((prev) =>
                                prev.filter((pr) => pr.id != sp.id)
                              );
                            } else {
                              setSelectedPositionLevels((prev) => [
                                ...prev,
                                sp,
                              ]);
                            }
                          }}
                        >
                          {sp.name}
                        </MultiSelectSearchDropdown.Item>
                      );
                    } else {
                      return null;
                    }
                  })}
                </MultiSelectSearchDropdown.Items>
              </MultiSelectSearchDropdown>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-x-4 gap-y-6 justify-start mb-6 lg:mb-4">
            {/* Section select */}
            <div className="flex w-full">
              <MultiSelectSearchDropdown
                className="w-full"
                required
                error={fieldErrors.sections}
                mode={selectedSections.length == 0 ? "empty" : "dirty"}
              >
                <MultiSelectSearchDropdown.Label>
                  Bölüm
                </MultiSelectSearchDropdown.Label>
                <MultiSelectSearchDropdown.Trigger
                  onClick={async () => {
                    if (
                      searchSection.length == 0 &&
                      filteredCdnSections.length == 0
                    ) {
                      setIsLoadingData(true);
                      const res = await getSections({});
                      setFilteredCdnSections(res);
                      setFilteredCdnSections(res);
                      setIsLoadingData(false);
                    }
                  }}
                >
                  <MultiSelectSearchDropdown.Value>
                    {selectedSections.length > 0
                      ? `${selectedSections[0].title} ${
                          selectedSections.length > 1
                            ? `(+${selectedSections.length - 1})`
                            : ``
                        }`
                      : "Seçiniz"}
                  </MultiSelectSearchDropdown.Value>
                  <MultiSelectSearchDropdown.Input
                    placeholder={
                      selectedSections.length > 0
                        ? `${selectedSections[0].title} ${
                            selectedSections.length > 1
                              ? `(+${selectedSections.length - 1})`
                              : ``
                          }`
                        : "Arama Yap"
                    }
                    value={searchSection || ""}
                    onChange={(e) => setSearchSection(e.target.value)}
                  />
                </MultiSelectSearchDropdown.Trigger>
                <MultiSelectSearchDropdown.Items>
                  {isLoadingData && (
                    <SelectSearchDropdown.Item>
                      <AiOutlineLoading3Quarters className="mx-auto animate-spin" />
                    </SelectSearchDropdown.Item>
                  )}
                  {selectedSections.map((section) => {
                    return (
                      <MultiSelectSearchDropdown.Item
                        key={`selectedSectiona${section.id}`}
                        selected={
                          selectedSections.find((s) => s.id == section.id) !=
                          undefined
                        }
                        onClick={() => {
                          if (
                            selectedSections.find((s) => s.id == section.id) !=
                            undefined
                          ) {
                            setSelectedSections((prev) => [
                              ...prev.filter((p) => p.id != section.id),
                            ]);
                          } else {
                            if (selectedSections.length >= 5) {
                              Swal.fire({
                                icon: "warning",
                                title: "Uyarı",
                                text: "En fazla 5 adet bölüm seçebilirsiniz.",
                              });
                            } else {
                              setSelectedSections((prev) => [...prev, section]);
                            }
                          }

                          if (setFieldErrors) {
                            setFieldErrors(({ sections, ...others }) => ({
                              sections: false,
                              ...others,
                            }));
                          }
                        }}
                      >
                        {section.title}
                      </MultiSelectSearchDropdown.Item>
                    );
                  })}
                  {filteredCdnSections.map((section) => {
                    if (
                      selectedSections.find((s) => s.id == section.id) ==
                      undefined
                    ) {
                      return (
                        <MultiSelectSearchDropdown.Item
                          key={`selectedSectiona${section.id}`}
                          selected={
                            selectedSections.find((s) => s.id == section.id) !=
                            undefined
                          }
                          onClick={() => {
                            if (
                              selectedSections.find(
                                (s) => s.id == section.id
                              ) != undefined
                            ) {
                              setSelectedSections((prev) => [
                                ...prev.filter((p) => p.id != section.id),
                              ]);
                            } else {
                              if (selectedSections.length >= 5) {
                                Swal.fire({
                                  icon: "warning",
                                  title: "Uyarı",
                                  text: "En fazla 5 adet bölüm seçebilirsiniz.",
                                });
                              } else {
                                setSelectedSections((prev) => [
                                  ...prev,
                                  section,
                                ]);
                              }
                            }

                            if (setFieldErrors) {
                              setFieldErrors(({ sections, ...others }) => ({
                                sections: false,
                                ...others,
                              }));
                            }
                          }}
                        >
                          {section.title}
                        </MultiSelectSearchDropdown.Item>
                      );
                    } else {
                      return null;
                    }
                  })}
                </MultiSelectSearchDropdown.Items>
              </MultiSelectSearchDropdown>
            </div>

            {/* Sector select */}
            <div className="flex w-full">
              <MultiSelectSearchDropdown
                className="w-full"
                required
                error={fieldErrors.sectors}
                mode={selectedSectors.length == 0 ? "empty" : "dirty"}
              >
                <MultiSelectSearchDropdown.Label>
                  Sektör
                </MultiSelectSearchDropdown.Label>
                <MultiSelectSearchDropdown.Trigger
                  onClick={async () => {
                    if (
                      searchSector.length == 0 &&
                      filteredCdnSectors.length == 0
                    ) {
                      setIsLoadingData(true);
                      const res = await getSectors({});
                      setFilteredCdnSectors(res);
                      setCdnSectors(res);
                      setIsLoadingData(false);
                    }
                  }}
                >
                  <MultiSelectSearchDropdown.Value>
                    {selectedSectors.length > 0
                      ? `${selectedSectors[0].title} ${
                          selectedSectors.length > 1
                            ? `(+${selectedSectors.length - 1})`
                            : ``
                        }`
                      : "Seçiniz"}
                  </MultiSelectSearchDropdown.Value>
                  <MultiSelectSearchDropdown.Input
                    placeholder={
                      selectedSectors.length > 0
                        ? `${selectedSectors[0].title} ${
                            selectedSectors.length > 1
                              ? `(+${selectedSectors.length - 1})`
                              : ``
                          }`
                        : "Arama Yap"
                    }
                    value={searchSector || ""}
                    onChange={(e) => setSearchSector(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key == "Backspace" && searchSector.length == 0) {
                        e.preventDefault();
                        setSelectedSectors((prev) => prev.slice(0, -1));
                      }
                    }}
                  />
                </MultiSelectSearchDropdown.Trigger>
                <MultiSelectSearchDropdown.Items>
                  {isLoadingData && (
                    <SelectSearchDropdown.Item>
                      <AiOutlineLoading3Quarters className="mx-auto animate-spin" />
                    </SelectSearchDropdown.Item>
                  )}
                  {selectedSectors.map((sector) => {
                    return (
                      <MultiSelectSearchDropdown.Item
                        key={`selectedSector${sector.id}`}
                        selected={
                          selectedSectors.find((s) => s.id == sector.id) !=
                          undefined
                        }
                        onClick={() => {
                          if (
                            selectedSectors.find((s) => s.id == sector.id) !=
                            undefined
                          ) {
                            setSelectedSectors((prev) => [
                              ...prev.filter((s) => s.id != sector.id),
                            ]);
                          } else {
                            if (selectedSectors.length >= 5) {
                              Swal.fire({
                                icon: "warning",
                                title: "Uyarı",
                                text: "En fazla 5 adet sektör seçebilirsiniz.",
                              });
                            } else {
                              setSelectedSectors((prev) => [...prev, sector]);
                            }
                          }

                          if (setFieldErrors) {
                            setFieldErrors(({ sectors, ...others }) => ({
                              sectors: false,
                              ...others,
                            }));
                          }
                        }}
                      >
                        {sector.title}
                      </MultiSelectSearchDropdown.Item>
                    );
                  })}
                  {filteredCdnSectors.map((sector) => {
                    if (
                      selectedSectors.find((s) => s.id == sector.id) ==
                      undefined
                    ) {
                      return (
                        <MultiSelectSearchDropdown.Item
                          key={`filteredSector${sector.id}`}
                          selected={
                            selectedSectors.find((s) => s.id == sector.id) !=
                            undefined
                          }
                          onClick={() => {
                            if (
                              selectedSectors.find((s) => s.id == sector.id) !=
                              undefined
                            ) {
                              setSelectedSectors((prev) => [
                                ...prev.filter((s) => s.id != sector.id),
                              ]);
                            } else {
                              if (selectedSectors.length >= 5) {
                                Swal.fire({
                                  icon: "warning",
                                  title: "Uyarı",
                                  text: "En fazla 5 adet sektör seçebilirsiniz.",
                                });
                              } else {
                                setSelectedSectors((prev) => [...prev, sector]);
                              }
                            }

                            if (setFieldErrors) {
                              setFieldErrors(({ sectors, ...others }) => ({
                                sectors: false,
                                ...others,
                              }));
                            }
                          }}
                        >
                          {sector.title}
                        </MultiSelectSearchDropdown.Item>
                      );
                    } else {
                      return null;
                    }
                  })}
                </MultiSelectSearchDropdown.Items>
              </MultiSelectSearchDropdown>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-x-4 gap-y-6 justify-start mb-6 lg:mb-4">
            {/* Working type select */}
            <div className="flex w-full">
              <MultiSelectSearchDropdown
                className="w-full"
                required
                error={fieldErrors.working_types}
                mode={selectedWorkingTypes.length == 0 ? "empty" : "dirty"}
              >
                <MultiSelectSearchDropdown.Label>
                  Çalışma Şekli
                </MultiSelectSearchDropdown.Label>
                <MultiSelectSearchDropdown.Trigger
                  onClick={async () => {
                    if (
                      searchWorkingType.length == 0 &&
                      filteredCdnWOrkingTypes.length == 0
                    ) {
                      setIsLoadingData(true);
                      const res = await getWorkingTypes({});
                      setFilteredCdnWorkingTypes(res);
                      setCdnWorkingTypes(res);
                      setIsLoadingData(false);
                    }
                  }}
                >
                  <MultiSelectSearchDropdown.Value>
                    {selectedWorkingTypes.length > 0
                      ? `${selectedWorkingTypes[0].title} ${
                          selectedWorkingTypes.length > 1
                            ? `(+${selectedWorkingTypes.length - 1})`
                            : ``
                        }`
                      : "Seçiniz"}
                  </MultiSelectSearchDropdown.Value>
                  <MultiSelectSearchDropdown.Input
                    placeholder={
                      selectedWorkingTypes.length > 0
                        ? `${selectedWorkingTypes[0].title} ${
                            selectedWorkingTypes.length > 1
                              ? `(+${selectedWorkingTypes.length - 1})`
                              : ``
                          }`
                        : "Arama Yap"
                    }
                    value={searchWorkingType || ""}
                    onChange={(e) => setSearchWorkingType(e.target.value)}
                    onKeyDown={(e) => {
                      if (
                        e.key == "Backspace" &&
                        searchWorkingType.length == 0
                      ) {
                        e.preventDefault();

                        setSelectedWorkingTypes((prev) => prev.slice(0, -1));
                      }
                    }}
                  />
                </MultiSelectSearchDropdown.Trigger>
                <MultiSelectSearchDropdown.Items>
                  {isLoadingData && (
                    <SelectSearchDropdown.Item>
                      <AiOutlineLoading3Quarters className="mx-auto animate-spin" />
                    </SelectSearchDropdown.Item>
                  )}
                  {selectedWorkingTypes.map((workingType) => {
                    return (
                      <MultiSelectSearchDropdown.Item
                        key={`selectWorkingType${workingType.id}`}
                        selected={
                          selectedWorkingTypes.find(
                            (s) => s.id == workingType.id
                          ) != undefined
                        }
                        onClick={() => {
                          if (
                            selectedWorkingTypes.find(
                              (s) => s.id == workingType.id
                            ) != undefined
                          ) {
                            setSelectedWorkingTypes((prev) => [
                              ...prev.filter((p) => p.id != workingType.id),
                            ]);
                          } else {
                            setSelectedWorkingTypes((prev) => [
                              ...prev,
                              workingType,
                            ]);
                          }

                          if (setFieldErrors) {
                            setFieldErrors(({ working_types, ...others }) => ({
                              working_types: false,
                              ...others,
                            }));
                          }
                        }}
                      >
                        {workingType.title}
                      </MultiSelectSearchDropdown.Item>
                    );
                  })}
                  {filteredCdnWOrkingTypes.map((workingType) => {
                    if (
                      selectedWorkingTypes.find(
                        (s) => s.id == workingType.id
                      ) == undefined
                    ) {
                      return (
                        <MultiSelectSearchDropdown.Item
                          key={`selectedWorkingType${workingType.id}`}
                          selected={
                            selectedWorkingTypes.find(
                              (s) => s.id == workingType.id
                            ) != undefined
                          }
                          onClick={() => {
                            if (
                              selectedWorkingTypes.find(
                                (s) => s.id == workingType.id
                              ) != undefined
                            ) {
                              setSelectedWorkingTypes((prev) => [
                                ...prev.filter((p) => p.id != workingType.id),
                              ]);
                            } else {
                              setSelectedWorkingTypes((prev) => [
                                ...prev,
                                workingType,
                              ]);
                            }

                            if (setFieldErrors) {
                              setFieldErrors(
                                ({ working_types, ...others }) => ({
                                  working_types: false,
                                  ...others,
                                })
                              );
                            }
                          }}
                        >
                          {workingType.title}
                        </MultiSelectSearchDropdown.Item>
                      );
                    } else {
                      return null;
                    }
                  })}
                </MultiSelectSearchDropdown.Items>
              </MultiSelectSearchDropdown>
            </div>

            {/* Working location select */}
            <div className="flex w-full">
              <MultiSelectSearchDropdown
                className="w-full"
                required
                error={fieldErrors.working_locations}
                mode={selectedWorkingLocations.length == 0 ? "empty" : "dirty"}
              >
                <MultiSelectSearchDropdown.Label>
                  Çalışma Tercihi
                </MultiSelectSearchDropdown.Label>
                <MultiSelectSearchDropdown.Trigger
                  onClick={async () => {
                    if (
                      searchWorkingLocation.length == 0 &&
                      filteredCdnWorkingLocations.length == 0
                    ) {
                      setIsLoadingData(true);
                      const res = await getWorkingLocations({});
                      setFilteredCdnWorkingLocations(res);
                      setCdnWorkingLocations(res);
                      setIsLoadingData(false);
                    }
                  }}
                >
                  <MultiSelectSearchDropdown.Value>
                    {selectedWorkingLocations.length > 0
                      ? `${selectedWorkingLocations[0].title} ${
                          selectedWorkingLocations.length > 1
                            ? `(+${selectedWorkingLocations.length - 1})`
                            : ``
                        }`
                      : "Seçiniz"}
                  </MultiSelectSearchDropdown.Value>
                  <MultiSelectSearchDropdown.Input
                    placeholder={
                      selectedWorkingLocations.length > 0
                        ? `${selectedWorkingLocations[0].title} ${
                            selectedWorkingLocations.length > 1
                              ? `(+${selectedWorkingLocations.length - 1})`
                              : ``
                          }`
                        : "Seçiniz"
                    }
                    value={searchWorkingLocation || ""}
                    onChange={(e) => setSearchWorkingLocation(e.target.value)}
                    onKeyDown={(e) => {
                      if (
                        e.key == "Backspace" &&
                        searchWorkingLocation.length == 0
                      ) {
                        e.preventDefault();

                        setSelectedWorkingLocations((prev) =>
                          prev.slice(0, -1)
                        );
                      }
                    }}
                  />
                </MultiSelectSearchDropdown.Trigger>
                <MultiSelectSearchDropdown.Items>
                  {isLoadingData && (
                    <SelectSearchDropdown.Item>
                      <AiOutlineLoading3Quarters className="mx-auto animate-spin" />
                    </SelectSearchDropdown.Item>
                  )}
                  {selectedWorkingLocations.map((location) => {
                    return (
                      <MultiSelectSearchDropdown.Item
                        key={`selectedLocation${location.id}`}
                        selected={
                          selectedWorkingLocations.find(
                            (w) => w.id == location.id
                          ) != undefined
                        }
                        onClick={() => {
                          if (
                            selectedWorkingLocations.find(
                              (s) => s.id == location.id
                            ) != undefined
                          ) {
                            setSelectedWorkingLocations((prev) => [
                              ...prev.filter((p) => p.id != location.id),
                            ]);
                          } else {
                            setSelectedWorkingLocations((prev) => [
                              ...prev,
                              location,
                            ]);
                          }

                          if (setFieldErrors) {
                            setFieldErrors(
                              ({ working_locations, ...others }) => ({
                                working_locations: false,
                                ...others,
                              })
                            );
                          }
                        }}
                      >
                        {location.title}
                      </MultiSelectSearchDropdown.Item>
                    );
                  })}
                  {filteredCdnWorkingLocations.map((location) => {
                    if (
                      selectedWorkingLocations.find(
                        (s) => s.id == location.id
                      ) == undefined
                    ) {
                      return (
                        <MultiSelectSearchDropdown.Item
                          key={`selectedLocation${location.id}`}
                          selected={
                            selectedWorkingLocations.find(
                              (w) => w.id == location.id
                            ) != undefined
                          }
                          onClick={() => {
                            if (
                              selectedWorkingLocations.find(
                                (s) => s.id == location.id
                              ) != undefined
                            ) {
                              setSelectedWorkingLocations((prev) => [
                                ...prev.filter((p) => p.id != location.id),
                              ]);
                            } else {
                              setSelectedWorkingLocations((prev) => [
                                ...prev,
                                location,
                              ]);
                            }

                            if (setFieldErrors) {
                              setFieldErrors(
                                ({ working_locations, ...others }) => ({
                                  working_locations: false,
                                  ...others,
                                })
                              );
                            }
                          }}
                        >
                          {location.title}
                        </MultiSelectSearchDropdown.Item>
                      );
                    } else {
                      return null;
                    }
                  })}
                </MultiSelectSearchDropdown.Items>
              </MultiSelectSearchDropdown>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-x-4 gap-y-6 justify-start mb-6 lg:mb-4">
            {/* Education level select */}
            <div className="flex w-full">
              <MultiSelectDropdown
                className="w-full"
                required
                error={fieldErrors.education_levels}
                mode={selectedEducationLevels.length == 0 ? "empty" : "dirty"}
              >
                <MultiSelectDropdown.Label>
                  Eğitim Seviyesi
                </MultiSelectDropdown.Label>
                <MultiSelectDropdown.Trigger>
                  {selectedEducationLevels.length > 0
                    ? `${selectedEducationLevels[0].title} ${
                        selectedEducationLevels.length > 1
                          ? `(+${selectedEducationLevels.length - 1})`
                          : ``
                      }`
                    : "Seçiniz"}
                </MultiSelectDropdown.Trigger>
                <MultiSelectDropdown.Items>
                  {selectedEducationLevels.map((education) => {
                    return (
                      <MultiSelectDropdown.Item
                        key={`selectedEL${education.id}`}
                        selected={
                          selectedEducationLevels.find(
                            (e) => e.id == education.id
                          ) != undefined
                        }
                        onClick={() => {
                          if (
                            selectedEducationLevels.find(
                              (e) => e.id == education.id
                            ) != undefined
                          ) {
                            setSelectedEducationLevels((prev) => [
                              ...prev.filter((p) => p.id != education.id),
                            ]);
                          } else {
                            setSelectedEducationLevels((prev) => [
                              ...prev,
                              education,
                            ]);
                          }

                          if (setFieldErrors) {
                            setFieldErrors(
                              ({ education_levels, ...others }) => ({
                                education_levels: false,
                                ...others,
                              })
                            );
                          }
                        }}
                      >
                        {education.title}
                      </MultiSelectDropdown.Item>
                    );
                  })}
                  {filteredCdnEducationLevels.map((education) => {
                    if (
                      selectedEducationLevels.find(
                        (e) => e.id == education.id
                      ) == undefined
                    ) {
                      return (
                        <MultiSelectDropdown.Item
                          key={`selectedsL${education.id}`}
                          selected={
                            selectedEducationLevels.find(
                              (e) => e.id == education.id
                            ) != undefined
                          }
                          onClick={() => {
                            if (
                              selectedEducationLevels.find(
                                (e) => e.id == education.id
                              ) != undefined
                            ) {
                              setSelectedEducationLevels((prev) => [
                                ...prev.filter((p) => p.id != education.id),
                              ]);
                            } else {
                              setSelectedEducationLevels((prev) => [
                                ...prev,
                                education,
                              ]);
                            }

                            if (setFieldErrors) {
                              setFieldErrors(
                                ({ education_levels, ...others }) => ({
                                  education_levels: false,
                                  ...others,
                                })
                              );
                            }
                          }}
                        >
                          {education.title}
                        </MultiSelectDropdown.Item>
                      );
                    } else {
                      return null;
                    }
                  })}
                </MultiSelectDropdown.Items>
              </MultiSelectDropdown>
            </div>

            {/* Foreign language select */}
            <div className="flex w-full">
              <MultiSelectSearchDropdown
                className="w-full"
                error={fieldErrors.languages}
                mode={selectedLanguages.length == 0 ? "empty" : "dirty"}
              >
                <MultiSelectSearchDropdown.Label>
                  Yabancı Dil
                </MultiSelectSearchDropdown.Label>
                <MultiSelectSearchDropdown.Trigger
                  onClick={async () => {
                    if (
                      searchLanguage.length == 0 &&
                      selectedLanguages.length == 0
                    ) {
                      setIsLoadingData(true);
                      const res = await getGeneralLanguages({});
                      setFilteredCdnGeneralLanguages(res);
                      setCdnGeneralLanguages(res);
                      setIsLoadingData(false);
                    }
                  }}
                >
                  <MultiSelectSearchDropdown.Value>
                    {selectedLanguages.length > 0
                      ? `${selectedLanguages[0].name} ${
                          selectedLanguages.length > 1
                            ? `(+${selectedLanguages.length - 1})`
                            : ``
                        }`
                      : "Seçiniz"}
                  </MultiSelectSearchDropdown.Value>
                  <MultiSelectSearchDropdown.Input
                    placeholder={
                      selectedLanguages.length > 0
                        ? `${selectedLanguages[0].name} ${
                            selectedLanguages.length > 1
                              ? `(+${selectedLanguages.length - 1})`
                              : ``
                          }`
                        : "Arama Yap"
                    }
                    value={searchLanguage || ""}
                    onChange={(e) => setSearchLanguage(e.target.value)}
                  />
                </MultiSelectSearchDropdown.Trigger>
                <MultiSelectSearchDropdown.Items>
                  {isLoadingData && (
                    <SelectSearchDropdown.Item>
                      <AiOutlineLoading3Quarters className="mx-auto animate-spin" />
                    </SelectSearchDropdown.Item>
                  )}
                  {selectedLanguages.map((language) => {
                    return (
                      <MultiSelectSearchDropdown.Item
                        key={`selectedLang${language.id}`}
                        selected={
                          selectedLanguages.find((s) => s.id == language.id) !=
                          undefined
                        }
                        onClick={() => {
                          if (
                            selectedLanguages.find(
                              (l) => l.id == language.id
                            ) != undefined
                          ) {
                            setSelectedLanguages((prev) => [
                              ...prev.filter((p) => p.id != language.id),
                            ]);
                          } else {
                            setSelectedLanguages((prev) => [...prev, language]);
                          }
                        }}
                      >
                        {language.name}
                      </MultiSelectSearchDropdown.Item>
                    );
                  })}
                  {filteredCdnGeneralLanguages.map((language) => {
                    if (
                      selectedLanguages.find((l) => l.id == language.id) ==
                      undefined
                    ) {
                      return (
                        <MultiSelectSearchDropdown.Item
                          key={`selectedCdnLang${language.id}`}
                          selected={
                            selectedLanguages.find(
                              (s) => s.id == language.id
                            ) != undefined
                          }
                          onClick={() => {
                            if (
                              selectedLanguages.find(
                                (l) => l.id == language.id
                              ) != undefined
                            ) {
                              setSelectedLanguages((prev) => [
                                ...prev.filter((p) => p.id != language.id),
                              ]);
                            } else {
                              setSelectedLanguages((prev) => [
                                ...prev,
                                language,
                              ]);
                            }
                          }}
                        >
                          {language.name}
                        </MultiSelectSearchDropdown.Item>
                      );
                    } else {
                      return null;
                    }
                  })}
                </MultiSelectSearchDropdown.Items>
              </MultiSelectSearchDropdown>
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-x-4 gap-y-6 justify-start mb-1 lg:mb-4">
            {/* Advert language */}
            <div className="flex w-full">
              {/* TODO: Devam Edilecek */}
              <SelectDropdown
                className="w-full"
                required
                mode={selectedAdvertLanguage ? "dirty" : "empty"}
                error={fieldErrors.language}
              >
                <SelectDropdown.Label>İlan Dili</SelectDropdown.Label>
                <SelectDropdown.Trigger>
                  {selectedAdvertLanguage?.name ?? "Seçiniz"}
                </SelectDropdown.Trigger>
                <SelectDropdown.Items>
                  {advertLanguages.map((language) => {
                    return (
                      <SelectDropdown.Item
                        key={`adverLang${language.id}`}
                        onClick={() => setSelectedAdvertLanguage(language)}
                      >
                        {language.name}
                      </SelectDropdown.Item>
                    );
                  })}
                </SelectDropdown.Items>
              </SelectDropdown>
            </div>

            <div className="hidden lg:flex w-full"></div>
          </div>

          {/* FIXME: Silinen kısımlar buraya gelecek */}

          <div className="w-full mt-4 mb-4 flex flex-col  justify-start">
            {/* Deneyim Süresi Label and Radio Buttons */}
            <div className="flex flex-col md:flex-row md:items-center mt-4 mb-4">
              <div className="mb-4 md:mb-0 md:mr-4 ">
                <label
                  htmlFor="experienceLevel"
                  className="font-medium text-gray-500 text-md"
                >
                  Deneyim<span className="text-rose-500">*</span>
                </label>
              </div>
              <div className="flex flex-wrap gap-1">
                {/* Your existing radio buttons for Deneyim Süresi */}
                <div className="flex items-center md:mr-6 mr-3">
                  <input
                    type="radio"
                    name="experience_level_type"
                    value="not-matter"
                    id="noPreference"
                    defaultChecked={contextData?.data?.experience_level == null}
                    className="mr-2 scale-150"
                    onChange={handleExperienceChange}
                  />
                  <label
                    htmlFor="noPreference"
                    className="text-[#6B7280] font-medium text-[15px] line-clamp-1"
                  >
                    Fark Etmez
                  </label>
                </div>
                <div className="flex items-center md:mr-6 mr-3">
                  <input
                    type="radio"
                    name="experience_level_type"
                    value="experienced"
                    defaultChecked={
                      contextData?.data?.experience_level != null &&
                      contextData?.data?.experience_level
                        .experience_level_min_id
                        ? contextData.data.experience_level
                            .experience_level_min_id > 41
                        : false
                    }
                    id="experienced"
                    className="mr-2 scale-150"
                    onChange={handleExperienceChange}
                  />
                  <label
                    htmlFor="experienced"
                    className="text-[#6B7280] font-medium text-[15px]"
                  >
                    Deneyimli
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    name="experience_level_type"
                    defaultChecked={
                      contextData?.data?.experience_level != null &&
                      contextData?.data?.experience_level
                        .experience_level_max_id == 41
                    }
                    value="inexperienced"
                    id="inexperienced"
                    className="mr-2 scale-150"
                    onChange={handleExperienceChange}
                  />
                  <label
                    htmlFor="inexperienced"
                    className="text-[#6B7280] font-medium text-[15px]"
                  >
                    Deneyimsiz
                  </label>
                </div>
              </div>
            </div>
            {isExperienced && (
              <div className="ml-0 lg:ml-8 w-full">
                <DualThumbSlider
                  contextMin={
                    contextData?.data?.experience_level?.experience_level_min
                      ?.value ?? 1
                  }
                  contextMax={
                    contextData?.data?.experience_level?.experience_level_max
                      ?.value ?? 15
                  }
                  onExperienceLevelChange={(e) => onExperienceLevelChange(e)}
                />
              </div>
            )}
          </div>
          <div className="mb-4 mx-2 lg:mx-0">
            <KeywordArea
              onKeywordsChange={onKeywordsChange}
              contextKeywords={contextData?.data?.keywords}
            />
          </div>
        </div>

        {/* Right Column - HelpCard and InfoCard */}
        <div className="w-full  hidden  lg:col-span-4 lg:flex flex-col p-4 mb-2 bg-white">
          <div className="mb-4">
            <InfoCard
              advertCount={advertLeft}
              isLoading={isLoadingAdvertCount}
            />
          </div>
          <div className="mb-4">
            <HelpCard />
          </div>
        </div>
      </div>

      {/* Acordion context end */}
    </div>
  );
};

export default AddDetail;
