type caseTypeType = "NormalCase" | "lowercase" | "UPPERCASE";

export default function usePartialBold({
  boldString: rawBoldString,
  fullString: rawFullString,
  returnCase = "NormalCase",
}: {
  boldString?: string;
  fullString: string;
  returnCase?: caseTypeType;
}) {
  const boldLabelStartIndex = 6;

  if (!rawBoldString) {
    return rawFullString;
  }

  let boldString = rawBoldString?.toLocaleLowerCase("tr-TR");
  let fullString = rawFullString.toLocaleLowerCase("tr-TR");

  // Ahmet GöR edit
  var Maps: { [key: string]: string } = {
    İ: "I",
    Ş: "S",
    Ç: "C",
    Ğ: "G",
    Ü: "U",
    Ö: "O",
    ı: "i",
    ş: "s",
    ç: "c",
    ğ: "g",
    ü: "u",
    ö: "o",
  };
  Object.keys(Maps).forEach(function (Old) {
    boldString = boldString.replace(Old, Maps[Old]);
    fullString = fullString.replace(Old, Maps[Old]);
  });

  const regex = new RegExp(`(${boldString})`, "gi");

  if (fullString.match(regex)) {
    const array = fullString.split(regex);

    // Ahmet GÖR edit.
    // türkçe karakter eşleştirmesi mysql in LIKE ı ile aynı çalışmadığı için
    // orjinal texti convertlenmiş bir text ile kıyaslayıp parçalıyoruz.
    let nextIndex = 0;
    let tempArray: string[] = [];
    array.forEach((el) => {
      let newIndex = nextIndex + el.length;
      tempArray.push(rawFullString.substring(nextIndex, newIndex));
      nextIndex = newIndex;
    });

    const boldLabel = array
      .map((a, index) => {
        if (a.match(regex)) {
          return `<b style="color:#111111"><u>${tempArray[index]}</u></b>`;
        } else {
          return tempArray[index];
        }
      })
      .join("");

    const boldLabelSpaceArray = boldLabel.split(" ");

    const boldLabelEdited = boldLabelSpaceArray
      .map((label) => {
        if (label.startsWith("<b>")) {
          const labelArray = label.split("");
          const labelCased = labelArray
            .map((a, i) => {
              if (i == boldLabelStartIndex) {
                if (returnCase == "lowercase") {
                  return a;
                }
                return a.toLocaleUpperCase("tr-TR");
              } else {
                if (returnCase == "UPPERCASE") {
                  return a.toLocaleUpperCase("tr-TR");
                }
                return a;
              }
            })
            .join("");

          return labelCased;
        } else {
          const labelArray = label.split("");
          const labelCased = labelArray
            .map((a, i) => {
              if (i == 0) {
                if (returnCase == "lowercase") {
                  return a;
                }
                return a.toLocaleUpperCase("tr-TR");
              } else {
                if (returnCase == "UPPERCASE") {
                  return a.toLocaleUpperCase("tr-TR");
                }
                return a;
              }
            })
            .join("");

          return labelCased;
        }
      })
      .join(" ");

    return boldLabelEdited;
  } else {
    return rawFullString;
  }
}
