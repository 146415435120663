import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "./main";

const ProcutsPageContainer: React.FC = () => {
  return (
    <div className="">
      <div className="w-full container mx-auto">
        <Routes>
          <Route path="/" element={<Main />} />
        </Routes>
      </div>
    </div>
  );
};

export default ProcutsPageContainer;
