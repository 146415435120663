import { InputHTMLAttributes, useContext } from "react"
import { MultiSelectSearchDropdownContext, multiSelectSearchDropdownContextType } from "."
import { selectSearchDropdownContextType } from "../select-search-dropdown"
import classNames from "classnames"




type inputPropsType = InputHTMLAttributes<HTMLInputElement> & {

}






export default function Input({
    onClick,
    autoFocus = true,
    className,
    ...props
}: inputPropsType) {

    const { isOpen, setIsOpen }: selectSearchDropdownContextType = useContext(MultiSelectSearchDropdownContext)
    
    const cn = classNames({
        'bg-transparent outline-none w-full': true
    })

    if (isOpen) {
        return(
            <input
            className={`${cn} ${className}`}
            autoFocus={autoFocus}
            {...props}
            onClick={((e) => {
                if (onClick) {
                    onClick(e)
                }

                setIsOpen((prev) => !prev)
            })}

            />
        )
    } else {
        return null
    }
}