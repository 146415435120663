import React from 'react';
import Contents from './contents';

const Favorite: React.FC = () => {
  return (
    <>
        <Contents/>
    </>
  );
};

export default Favorite;
