import Axios from "../services/axios";
import { cdnPositionLevelType } from "../types";

type getPositionLevelsPropsType = {
  search?: string;
  limit?: number;
  get_only_by_string?: string[];
  lang?: string;
};

const axios = new Axios();

export async function getPositionLevels({
  limit,
  search,
  get_only_by_string,
  lang,
}: getPositionLevelsPropsType): Promise<cdnPositionLevelType[]> {
  console.log(lang);
  try {
    const response = await axios.request({
      endpoint: `cdn/get-position-levels?${search ? `search=${search}&` : ""}${
        lang ? `lang=${lang}&` : ""
      }${limit ? `limit=${limit}&` : ""}${
        get_only_by_string
          ? `get_only_by_string=${JSON.stringify(get_only_by_string)}`
          : ""
      }`,
      type: "get",
    });

    if (response && response.data && response.data.position_levels) {
      return JSON.parse(JSON.stringify(response.data.position_levels));
    } else {
      return [];
    }
  } catch (error) {
    console.log(`Position levels fetch failed. Error: ${error}`);
    return [];
  }
}
