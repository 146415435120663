import React from "react";

interface IHamburgerContainer {
  isHamburger: boolean;
  children: React.ReactNode;
}

const HamburgerContainer: React.FC<IHamburgerContainer> = (
  props: IHamburgerContainer
) => {
  const hamburgerMargin = props.isHamburger ? "" : "ml-[-256px]";
  return (
    <div
      id="hamburgerMenu"
      className={`transition-[margin] w-64 ${hamburgerMargin} h-[100vh] fixed left-0 top-0 z-[9999999999] bg-black bg-opacity-90 flex items-end justify-center lg:hidden overflow-y-scroll pt-10`}
    >
      <div className={`w-3/4 flex h-full flex-col gap-y-2`}>
        {props.children}
      </div>
    </div>
  );
};

export default HamburgerContainer;
