import React, { useState, useEffect } from "react";
import Breadcrump from "../../../../components/common/profile/breadcrump";
import { IoClose } from "react-icons/io5";
import Axios from "../../../../services/axios";
import { RiErrorWarningLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Notification } from "../../../../types/Notification";
import { onNotificationClick } from "../../../../lib/utils";

const Body: React.FC = () => {
  const axios = new Axios();

  const [notificationData, setNotificationData] = useState<Notification[]>([]);
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true);
  const navigate = useNavigate();

  async function getNotifications() {
    setIsLoadingPage(true);
    try {
      const response = await axios.authRequest({
        type: "get",
        endpoint: `companies/notification/get-all`,
        payload: "",
      });
      setNotificationData(response.data.notifications);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
    setIsLoadingPage(false);
  }

  async function readAllNotifications() {
    try {
      const response = await axios.authRequest({

        endpoint: 'companies/notification/read-all',
        type: 'post',
        payload: {}
      });
      console.log('response', response);

    } catch (error) {
      console.log(`Error: ${error}`);
    }
  }

  useEffect(() => {

    getNotifications();
    readAllNotifications();

    Promise.all([getNotifications(), readAllNotifications()]);

  }, []);

  const dateFormat = (date: string) => {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString("tr-TR", {
      year: "numeric",
      month: "long",
      day: "numeric",
      minute: "numeric",
      hour: "numeric",
    });
  };

  const isTodayNotifications = (date: string, is_view: number) => {
    const today = new Date();
    const dateObj = new Date(date);
    const isSameDay =
      dateObj.getDate() === today.getDate() &&
      dateObj.getMonth() === today.getMonth() &&
      dateObj.getFullYear() === today.getFullYear();

    return isSameDay && is_view === 0 ? "Yeni!" : "";
  };

  const handleDelete = async (id: number) => {
    try {
      const response = await axios.authRequest({
        type: "delete",
        endpoint: `companies/notification/delete/${id}`,
        payload: "",
      });
      console.log(response);

      const newNotificationData = notificationData.filter(
        (notification) => notification.id !== id
      );

      setNotificationData(newNotificationData);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };

  const handleAllDelete = async () => {
    try {
      const response = await axios.authRequest({
        type: "delete",
        endpoint: `companies/notification/delete-all`,
        payload: "",
      });
      console.log(response);

      setNotificationData([]);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };

  return (
    <div className="w-full flex flex-col gap-y-6 min-h-screen container mx-auto mt-4">
      <Breadcrump type="page" pageName="BİLDİRİMLER" />

      {notificationData.length > 0 ? (
        <div className="w-full bg-white flex flex-col rounded-md p-2 relative">
          <button
            onClick={handleAllDelete}
            className="flex justify-end items-center text-red-500 text-xs font-poppins mt-2 absolute right-2"
          >
            Bildirimleri Temizle
          </button>

          {notificationData.map((notification) => (
            <div
              key={notification.id}
              onClick={() => onNotificationClick(notification, navigate)}
              className="flex w-full justify-between items-center hover:bg-gray-200 duration-300 p-2 py-6 mt-2 border-b gap-2 cursor-pointer"
            >
              <div className="flex justify-center items-center gap-3">
                <div>
                  <img
                    className="w-8 lg:w-12 min-w-8 lg:min-w-12 rounded-full object-cover"
                    src={notification.logo}
                    alt=""
                  />
                </div>

                <div className="flex flex-col gap-1">
                  <p className="text-mains font-poppins text-sm font-semibold">
                    {notification.title}
                  </p>

                  <p className="text-xs font-poppins text-gray-500 font-medium w-full lg:w-[90%]">
                    {notification.detail}
                  </p>
                </div>
              </div>

              <div className="flex flex-col justify-center items-center gap-4">
                <div className="flex justify-end items-center gap-2 w-full">
                  <p className="text-mains text-xs font-medium">
                    {isTodayNotifications(notification.created_at, notification.is_view)}
                  </p>
                  <IoClose
                    onClick={() => handleDelete(notification.id)}
                    className="text-gray-400 text-lg cursor-pointer"
                  />
                </div>

                <div className="font-poppins text-xs whitespace-nowrap">
                  {dateFormat(notification.created_at)}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          {!isLoadingPage && (
            <>
              <div className="lg:flex hidden items-center mx-2 h-20 flex-row border border-gray-200 bg-white shadow rounded-lg">
                <div className="h-full flex items-center p-2 bg-[#fe0000] rounded-l-lg">
                  <RiErrorWarningLine className="text-5xl text-white" />
                </div>
                <label className="p-4 font-poppins font-[500]">
                  Bildirim bulunamadı
                </label>
              </div>

              <div className="flex flex-col justify-center items-center lg:hidden gap-2 mt-8">
                <div className="bg-[#F4F6FA] p-4 rounded-full">
                  <div className="rounded-full p-3 bg-[#DEE5F1]">
                    <svg
                      width="48"
                      height="44"
                      viewBox="0 0 48 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24.2221 41.9999C34.0405 41.9999 41.9999 34.0405 41.9999 24.2221C41.9999 14.4037 34.0405 6.44434 24.2221 6.44434C14.4037 6.44434 6.44434 14.4037 6.44434 24.2221C6.44434 34.0405 14.4037 41.9999 24.2221 41.9999Z"
                        stroke="#1F4CA1"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M8.66667 2L2 8.66667"
                        stroke="#1F4CA1"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M46.4445 8.66667L39.7778 2"
                        stroke="#1F4CA1"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M11.7332 36.8892L6.44434 42.0003"
                        stroke="#1F4CA1"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M36.7554 36.8223L41.9998 42"
                        stroke="#1F4CA1"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M17.5557 24.2223L22.0001 28.6667L30.889 19.7778"
                        stroke="#1F4CA1"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </div>
                </div>

                <div className="flex flex-col justify-center items-center gap-1">
                  <p className="font-poppins poppins-medium text-base text-center">
                    Henüz Bildiriminiz Yok!
                  </p>

                  <p className="font-poppins poppins-regular text-[13px] text-center">
                    Bildiriminiz geldiğinde bu alanda görüntüleyebilirsiniz.
                  </p>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Body;