import React, { useState } from "react";
import SideBarItems from "./SideBarItems";
import {
	IconAffiliate,
	IconUser,
	IconNotes,
	IconFolder,
	IconEdit,
	IconCertificate,
	IconChecklist,
	IconList,
	IconClipboardList,
} from "@tabler/icons-react";

const sideBarData = [
	{
		title: "Profilim",
		icon: <IconUser stroke={1} />,
		url: "/profilim",
	},
	{
		title: "Özgeçmişler",
		icon: <IconNotes stroke={1} />,
		url: "/profilim/cv",
	},
	{
		title: "Dosyalar & Ön Yazılar",
		icon: <IconFolder stroke={1} />,
		url: "/profilim/dosyalar-onyazilar",
	},
	{
		title: "Başvurularım",
		icon: <IconEdit stroke={1} />,
		url: "/profilim/başvurular",
	},
	{
		title: "Mülakatlarım",
		icon: <IconCertificate stroke={1} />,
		url: "/profilim/mulakat-listesi",
	},
	{
		title: "İş İlanları",
		icon: <IconClipboardList stroke={1} />,
		url: "/profilim/mulakat-listesi",
	},
	{
		title: "Bana Uygun İlanlar",
		icon: <IconList stroke={1} />,
		url: "/profilim/is-alarmlari",
	},
	{
		title: "Favorilerim",
		icon: <IconAffiliate stroke={1} />,
		url: "/profilim/favoriler",
		collapse: true,
		submenu: [
			{ title: "Kayıtlı İlanlar", url: "/profilim/favoriler/liste" },
			{
				title: "Takipteki Firmalar",
				url: "/profilim/follow",
			},
			{
				title: "Kayıtlı Filtreler",
				url: "/profilim/kayitli-aramalar",
			},
			{
				title: "Son İncelenen İlanlar",
				url: "/profilim/son-incelenen-ilanlar",
			},
		],
	},
	{
		title: "İş Alarmları",
		icon: <IconChecklist stroke={1} />,
		url: "/profilim/is-alarmlari",
	},
	{
		title: "Mesajlar",
		icon: <IconAffiliate stroke={1} />,
		url: "/profilim/mesajlar",
	},
	{
		title: "Ayarlar",
		icon: <IconAffiliate stroke={1} />,
		url: "/profilim/ayarlar",
	},
	{
		title: "Çıkış",
		icon: <IconAffiliate stroke={1} />,
		url: "/cikis",
	},
	{
		title: "Başvurularım ",
		icon: <IconAffiliate stroke={1} />,
		collapse: true,
		submenu: [
			{
				title: "Sub Menu Test 1",
				url: "/#",
			},
			{
				title: "Sub Menu Test 2",
				url: "/#",
			},
		],
	},
];

interface SideBarProps {
	onClick: (event: { title: string; subTitle?: string }) => void;
	contraction?: boolean;
}

const SideBar: React.FC<SideBarProps> = ({ onClick, contraction }) => {
	const [data] = useState(sideBarData);

	return (
		<div className='w-64 min-h-screen p-5 text-white bg-gray-800'>
			<div>User</div>
			{data.map((events, index) => (
				<SideBarItems
					key={index}
					url={events.url}
					icon={events.icon}
					title={events.title}
					collapse={events.collapse}
					submenu={events.submenu}
					onClick={onClick}
				/>
			))}
		</div>
	);
};

export default SideBar;
