
import { IUserData } from "../../containers/login-page-container/company/register/types/IUserData";
import { IUserDatas } from "../../containers/login-page-container/profile/register/types/IUserDatas";
import Axios from "../axios";

const axios = new Axios();

const RegisterServices = {
  registerCompany: async (userData: IUserData) => {
    return await axios.request({
      type: "post",
      endpoint: "companies/register",
      payload: userData,
    });
  },

  registerProfile: async (userData: IUserDatas) => {
    console.log(userData)
    return await axios.request({
      type: "post",
      endpoint: "user/profilim/register",
      payload: userData,
    });
  },
};

export { RegisterServices };
