import { MdOutlineMessage } from "react-icons/md";
import React, { useState } from "react";
import EditNotePopup from "./EditNotePopup";

const EditNote = ({
  note,
  id,
  onSuccess,
}: {
  note: string;
  id: number;
  onSuccess: () => void;
}) => {
  const [isShow, setIsShow] = useState<boolean>(false);
  return (
    <>
      <div
        onClick={() => setIsShow(true)}
        className={
          "flex gap-2 justify-center items-center px-3 py-1 border border-mains text-mains hover:bg-mains hover:text-white cursor-pointer"
        }
      >
        <MdOutlineMessage /> Güncelle
      </div>
      {isShow && (
        <EditNotePopup
          onClose={() => {
            setIsShow(false);
          }}
          onSuccess={onSuccess}
          note={note}
          id={id}
        />
      )}
    </>
  );
};

export default EditNote;
