import Axios from "../axios/index";

const axios = new Axios();

const SearchServices = {
  searchServices: async (searchTerm: any) => {
    return await axios.request({
      type: "get",
      endpoint: `cdn/get-company-details?search=${searchTerm.searchTerm}&limit=5`,
      payload: searchTerm,
    });
  },

  searchServicesPosition: async (searchTerm: any, limit?: number) => {
    return await axios.request({
      type: "get",
      endpoint: `cdn/get-positions?search=${searchTerm}&limit=${
        limit ? limit : 20
      }`,
      payload: searchTerm,
    });
  },

  searchServicesPositionsOrCompanies: async (
    searchTerm: any,
    limit?: number
  ) => {
    return await axios.request({
      type: "get",
      endpoint: `cdn/get-positions-or-companies?search=${searchTerm}&limit=${
        limit ? limit : 20
      }`,
      payload: searchTerm,
    });
  },

  searchServicesCities: async (searchTerm: any, limit?: number) => {
    return await axios.request({
      type: "get",
      endpoint: `cdn/get-cities?search=${searchTerm}&limit=${
        limit ? limit : 20
      }`,
      payload: searchTerm,
    });
  },
  searchServicesCitiesAndDictrict: async (searchTerm: any, limit?: number) => {
    return await axios.request({
      type: "get",
      endpoint: `cdn/get-cities-or-districts?search=${searchTerm}&limit=${
        limit ? limit : 20
      }`,
      payload: searchTerm,
    });
  },
  searchServicesDistricts: async (cityId: string, search: string) => {
    return await axios.request({
      type: "get",
      endpoint: `cdn/get-districts?limit=100&city_id=${cityId}&search=${search}&limit=200`,
      payload: cityId,
    });
  },

  searchServicesCompanies: async (searchTerm: any) => {
    return await axios.request({
      type: "get",
      endpoint: `cdn/get-companies?search=${searchTerm}&limit=20`,
      payload: searchTerm,
    });
  },

  searchServicesCustom: async (
    api: string,
    searchTerm: string,
    include: string = "",
    limit = 10
  ) => {
    return await axios.request({
      type: "get",
      endpoint: `cdn/${api}?search=${searchTerm}${
        include.trim() !== "" ? "&include_ids=" + include : ""
      }&limit=${limit}`,
      payload: searchTerm,
    });
  },

  searchServicesLimitCustom: async (
    api: string,
    searchTerm: string,
    limit: string
  ) => {
    return await axios.request({
      type: "get",
      endpoint: `cdn/${api}?search=${searchTerm}&limit=${limit}`,
      payload: searchTerm,
    });
  },
};
export default SearchServices;
