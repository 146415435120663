import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  createContext,
} from "react";
import AltHeader from "./alt-header";
import { IItem } from "./filter-section-content/FilterSectionContentCopy";
import AdverSectionHeader from "./advert-section-header";
import AdvertSectionBody from "./advert-section-body";
import FilterSectionContentCopy from "./filter-section-content/FilterSectionContentCopy";
import { IoChevronDown, IoTrashBinOutline } from "react-icons/io5";
import FilteredItemsList from "./advert-section-header/FilteredItemsList";
import { CiFilter } from "react-icons/ci";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import {
  addOrUpdateQueryParam,
  refreshQueryParam,
} from "../../../hooks/useAddOrUpdateQueryParam";
import { useSelector } from "react-redux";
import { Button, Modal, Input, Checkbox, Select } from "antd";
import Swal from "sweetalert2";
import Axios from "../../../services/axios/index";
import { AdvertService } from "../../../services/advert-services";
import { Advert } from "../../../store/contextApi/ad-api-context/Iget-ad-data";
import { AdvertPayload } from "../../company-page-container/creat-ads-page/advert-create-form";
import { getPositions } from "../../../customHooks/getPositions";
import Cookies from "js-cookie";
import { PaginationData } from "../../company-page-container";
import FilterSaveModal from "./advert-section-body/components/filter-save-modal/FilterSaveModal";
import { FaFilter } from "react-icons/fa";

interface ISelectedValues {
  positions: IItem[];
  cities: IItem[];
  districts: IItem[];
  workingTypes: IItem[];
  workingLocations: IItem[];
  sections: IItem[];
  sectors: IItem[];
  advertDate: IItem[];
  experience: IItem[];
  disabled: IItem[];
  other: IItem[];
  positionLevels: IItem[];
  filters: IItem[];
  company: IItem[];
  search: IItem[];
  [key: string]: IItem[] | boolean;
}

export type jobsPageContextType = {
  selectedValues: ISelectedValues;
  setSelectedValues: Dispatch<SetStateAction<ISelectedValues>>;
  newSelectedValues: ISelectedValues;
  setNewSelectedValues: Dispatch<SetStateAction<ISelectedValues>>;
  handleFilterButton: () => void;
  pagination: PaginationData | null;
  loading: boolean;
  pageLimit: string;
  createQueryParamsFromSelectedValues: () => any;
  setIsFilterDeleteApplied: Dispatch<SetStateAction<boolean>>;
};

const initialFilters: ISelectedValues = {
  cities: [],
  districts: [],
  workingTypes: [],
  positions: [],
  workingLocations: [],
  sections: [],
  sectors: [],
  advertDate: [],
  experience: [],
  disabled: [],
  other: [],
  positionLevels: [],
  filters: [],
  company: [],
  search: [],
  isFilterUpdated: false,
};

export const JobsPageContext = createContext<jobsPageContextType>({
  selectedValues: initialFilters,
  setSelectedValues: () => {},
  newSelectedValues: initialFilters,
  setNewSelectedValues: () => {},
  handleFilterButton: () => {},
  pagination: null,
  loading: false,
  pageLimit: "35",
  createQueryParamsFromSelectedValues: () => {},
  setIsFilterDeleteApplied: () => {},
});

type RouteParams = {
  [key: string]: string | undefined;
};

// filter acoordionlarının sabit olanların dataları. içerden buraya taşındı
export const adDatesData = [
  { id: 1, title: "Tüm ilanlar", exp: "all" },
  { id: 2, title: "Yeni İlanlar", exp: "new" },
  { id: 3, title: "Son Bir Haftada Yayına Girenler", exp: "week" },
  { id: 4, title: "Son Bir Ayda Yayına Girenler", exp: "month" },
];

export const otherDataUser = [
  {
    id: 0,
    title: '"Türkiye Geneli" İlanlarını Gösterme',
    param: "not_turkey_general",
  },
  {
    id: 1,
    title: "Firma Adı Gizli İlanları Gösterme",
    param: "not_show_hidden_company_title",
  },
  {
    id: 2,
    title: "Görüntülediklerimi Gösterme",
    param: "not_show_viewed",
  },
  {
    id: 3,
    title: "Takipteki Firmaların İlanları",
    param: "followed_company_adverts",
  },
  {
    id: 4,
    title: "Bana Uygun İlanları Göster",
    param: "for_me_adverts",
  },
  {
    id: 5,
    title: "Başvurulan İlanları Gösterme",
    param: "not_show_applied",
  },
];
export const otherData = [
  {
    id: 0,
    title: '"Türkiye Geneli" İlanlarını Gösterme',
    param: "not_turkey_general",
  },
  {
    id: 1,
    title: "Firma Adı Gizli İlanları Gösterme",
    param: "not_show_hidden_company_title",
  },
];

export const disabledData = [
  { id: 1, title: "Tümü", exp: "all" },
  {
    id: 2,
    title: "Sadece Engelli İlanlarını Göster",
    exp: "disabled",
  },
  {
    id: 3,
    title: "Engelli ilanlarını gösterme",
    exp: "non_disabled",
  },
];

export const experienceData = [
  { id: 0, title: "Tümü" },
  {
    id: 1,
    title: "Deneyimli",
  },
  {
    id: 2,
    title: "Deneyimsiz",
  },
];

const Main: React.FC = () => {
  const axios = new Axios();
  const location = useLocation();
  const navigate = useNavigate();
  const { slug } = useParams<RouteParams>();
  const userToken = Cookies.get("userToken");

  const [pagination, setPagination] = useState<PaginationData | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(true);
  const [isFilterDeleteApplied, setIsFilterDeleteApplied] =
    useState<boolean>(false);

  const searchParams = new URLSearchParams(location.search);

  const [selectedValues, setSelectedValues] = useState<ISelectedValues>({
    cities: [],
    districts: [],
    workingTypes: [],
    positions: [],
    workingLocations: [],
    sections: [],
    sectors: [],
    advertDate: [],
    experience: [],
    disabled: [],
    other: [],
    positionLevels: [],
    filters: [],
    company: [],
    search: [],
    isFilterUpdated: false,
  });

  const [newSelectedValues, setNewSelectedValues] = useState<ISelectedValues>({
    cities: [],
    districts: [],
    workingTypes: [],
    positions: [],
    workingLocations: [],
    sections: [],
    sectors: [],
    advertDate: [],
    experience: [],
    disabled: [],
    other: [],
    positionLevels: [],
    filters: [],
    company: [],
    search: [],
    isFilterUpdated: false,
  });

  useEffect(() => {
    // bu efekt sayfadaki seçilmiş filtreler bölümünden silme yapılırsa
    // isFilterUpdated i kontrol ederek seçilen filtreleri yeniliyerek sayfa reloadını tetikliyor
    if (newSelectedValues.isFilterUpdated) {
      setNewSelectedValues((prevs) => {
        return { ...prevs, isFilterUpdated: false };
      });
      handleFilterButton();
    }
  }, [newSelectedValues]);

  const selectedValuesLength =
    (selectedValues.advertDate[0] !== undefined &&
    selectedValues.advertDate[0].id !== 1
      ? selectedValues.advertDate.length
      : 0) +
    selectedValues.cities.length +
    selectedValues.districts.length +
    (selectedValues.disabled[0] !== undefined &&
    selectedValues.disabled[0].id !== 1
      ? selectedValues.disabled.length
      : 0) +
    (selectedValues.experience[0] !== undefined &&
    selectedValues.experience[0].id !== 0
      ? selectedValues.experience.length
      : 0) +
    selectedValues.other.length +
    selectedValues.positionLevels.length +
    selectedValues.positions.length +
    selectedValues.sections.length +
    selectedValues.sectors.length +
    selectedValues.workingLocations.length +
    selectedValues.company.length +
    selectedValues.search.length +
    selectedValues.workingTypes.length;

  const [mobileTab, setMobileTab] = useState<string>("");
  const [pageSort, setPageSort] = useState<string>("");
  const [pageLimit, setPagelimit] = useState<string>("");
  const [savedFilters, setSavedFilters] = useState<IItem[]>([]);

  const [advert, setAdvert] = useState([]);

  const handleClearFilters = () => {
    setSelectedValues({
      advertDate: [],
      cities: [],
      districts: [],
      disabled: [],
      experience: [],
      other: [],
      positionLevels: [],
      positions: [],
      sections: [],
      sectors: [],
      workingLocations: [],
      workingTypes: [],
      filters: [],
      company: [],
      search: [],
      isFilterUpdated: false,
    });

    navigate("/is-ilanlari", { replace: true });
  };

  const createQueryParamsFromSelectedValues = () => {
    // seçilmiş filtreleri search parametresine çeviriyor
    const positionIds = newSelectedValues.positions.map((item: any) => item.id);
    const cityIds = newSelectedValues.cities.map((item) => item.id);
    const companyId = newSelectedValues.company.map((item) => item.id);
    const districtIds = newSelectedValues.districts.map((item) => item.id);
    const searchIds = newSelectedValues.search.map((item) => item.id);

    const workingTypeIds = newSelectedValues.workingTypes.map(
      (item) => item.id
    );

    const workingLocationsIds = newSelectedValues.workingLocations.map(
      (item) => item.id
    );

    const sectionsIds = newSelectedValues.sections.map((item) => item.id);
    const sectorIds = newSelectedValues.sectors.map((item) => item.id);
    let advertDate;

    let minExp, maxExp;

    if (newSelectedValues.experience[0]?.id === 1) {
      // Kullanıcı deneyimli
      minExp = 0;
      maxExp = 25;
    } else if (newSelectedValues.experience[0]?.id === 2) {
      // Kullanıcı deneyimsiz
      minExp = 0;
      maxExp = 0;
    } else if (newSelectedValues.experience[0]?.id === 0) {
      // Kullanıcı farketmez seçmiş
      minExp = 0;
      maxExp = 26;
    } else {
      // Varsayılan değerler (eğer başka bir id gelirse)
      minExp = 0;
      maxExp = 26;
    }

    let disabled;
    switch (newSelectedValues.advertDate[0]?.id || 0) {
      case 1:
        advertDate = "all";
        break;
      case 2:
        advertDate = "new";
        break;
      case 3:
        advertDate = "week";
        break;
      case 4:
        advertDate = "month";
        break;
      default:
        advertDate = "";
    }

    switch (newSelectedValues.disabled[0]?.id || 0) {
      case 1:
        disabled = "all";
        break;
      case 2:
        disabled = "disabled";
        break;
      case 3:
        disabled = "non_disabled";
        break;
      default:
        disabled = "";
    }
    const notTurkeyGeneral = newSelectedValues.other.some((d) => d.id === 0);
    const hiddenCompany = newSelectedValues.other.some((d) => d.id === 1);
    const notShowWieved = newSelectedValues.other.some((d) => d.id === 2);
    const followCompanyAdverts = newSelectedValues.other.some(
      (d) => d.id === 3
    );
    const forMeAdverts = newSelectedValues.other.some((d) => d.id === 4);
    const notShowApplied = newSelectedValues.other.some((d) => d.id === 5);

    const positionLevelsIds = newSelectedValues.positionLevels.map(
      (item) => item.id
    );

    const queryParams = [];

    if (notTurkeyGeneral) {
      queryParams.push({ key: "not_turkey_general", value: "1" });
    }
    if (hiddenCompany) {
      queryParams.push({ key: "not_show_hidden_company_title", value: "1" });
    }
    if (notShowWieved) {
      queryParams.push({ key: "not_show_viewed", value: "1" });
    }
    if (followCompanyAdverts) {
      queryParams.push({ key: "followed_company_adverts", value: "1" });
    }
    if (forMeAdverts) {
      queryParams.push({ key: "for_me_adverts", value: "1" });
    }
    if (notShowApplied) {
      queryParams.push({ key: "not_show_applied", value: "1" });
    }

    if (disabled !== "" && disabled !== "all") {
      queryParams.push({ key: "disabled", value: disabled });
    }

    if (maxExp !== 26) {
      queryParams.push({
        key: "experience_level_min",
        value: minExp.toString(),
      });
      queryParams.push({
        key: "experience_level_max",
        value: maxExp.toString(),
      });
    }

    if (advertDate !== "" && advertDate !== "all") {
      queryParams.push({ key: "advert_date", value: advertDate });
    }

    if (positionIds.length > 0) {
      queryParams.push({
        key: "positions",
        value: JSON.stringify(positionIds),
      });
    }
    if (cityIds.length > 0) {
      queryParams.push({ key: "cities", value: JSON.stringify(cityIds) });
    }
    if (districtIds.length > 0) {
      queryParams.push({
        key: "districts",
        value: JSON.stringify(districtIds),
      });
    }

    if (positionLevelsIds.length > 0) {
      queryParams.push({
        key: "position_levels",
        value: JSON.stringify(positionLevelsIds),
      });
    }
    if (workingTypeIds.length > 0) {
      queryParams.push({
        key: "working_types",
        value: JSON.stringify(workingTypeIds),
      });
    }
    if (workingLocationsIds.length > 0) {
      queryParams.push({
        key: "working_locations",
        value: JSON.stringify(workingLocationsIds),
      });
    }
    if (sectionsIds.length > 0) {
      queryParams.push({ key: "sections", value: JSON.stringify(sectionsIds) });
    }
    if (sectorIds.length > 0) {
      queryParams.push({ key: "sectors", value: JSON.stringify(sectorIds) });
    }
    if (companyId.length > 0) {
      queryParams.push({
        key: "company_ids",
        value: JSON.stringify(companyId),
      });
    }
    if (searchIds.length > 0) {
      queryParams.push({
        key: "search",
        value: newSelectedValues.search[0].title,
      });
    }

    return queryParams;
  };

  const handleFilterButton = async () => {
    // filtrele düğmesine basılınca alınan aksiyonlar.
    if (newSelectedValues.filters.length > 0) {
      navigate("/is-ilanlari" + newSelectedValues.filters[0].params_string, {
        replace: true,
      });
      setIsFilterApplied(true);
    } else {
      setSelectedValues(newSelectedValues);
      const searchPath = refreshQueryParam(
        location,
        createQueryParamsFromSelectedValues()
      );
      navigate("/is-ilanlari" + searchPath, { replace: true });
    }

    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const filtersKeyBindings: any = {
    cities: "cities",
    districts: "districts",
    working_types: "workingTypes",
    positions: "positions",
    working_locations: "workingLocations",
    sections: "sections",
    sectors: "sectors",
    position_levels: "positionLevels",
    filters: "filters",
    company: "company",
  };
  const filtersKeyBindingsValids = [
    "cities",
    "districts",
    "working_types",
    "positions",
    "working_locations",
    "sections",
    "sectors",
    "position_levels",
    "filters",
    "company",
  ];
  const pushAdvertsToFilters = (
    search_params: any,
    location_search: string
  ) => {
    const searchParams = new URLSearchParams(location_search);

    const paramKeys = [];
    for (const [key, value] of searchParams.entries()) {
      paramKeys.push(key);
    }
    let temp = { ...initialFilters };

    paramKeys.forEach((key) => {
      if (filtersKeyBindingsValids.indexOf(key) !== -1) {
        if (key == "cities") {
          temp[filtersKeyBindings[key]] = search_params[key].map((el: any) => {
            return { ...el, type: "city", param: "city", title: el.name };
          });
        } else if (key == "districts") {
          temp[filtersKeyBindings[key]] = search_params[key].map((el: any) => {
            return {
              ...el,
              type: "district",
              title: el.name + ", " + el.city.name,
              name: el.name + ", " + el.city.name,
              param: "district",
            };
          });
        } else if (key == "company") {
          temp[filtersKeyBindings[key]] = search_params[key].map((el: any) => {
            return {
              ...el,
              title: el.company_detail.company_name,
              param: "company",
            };
          });
        } else {
          temp[filtersKeyBindings[key]] = search_params[key];
        }
      }
    });

    let advertDateParam = searchParams.get("advert_date") ?? "all";
    temp.advertDate = adDatesData.filter((el) => el.exp === advertDateParam);
    let paramfilters: any = {
      not_turkey_general: searchParams.get("not_turkey_general") ?? "0",
      not_show_hidden_company_title:
        searchParams.get("not_show_hidden_company_title") ?? "0",
      not_show_viewed: searchParams.get("not_show_viewed") ?? "0",
      not_show_applied: searchParams.get("not_show_applied") ?? "0",
      followed_company_adverts:
        searchParams.get("followed_company_adverts") ?? "0",
      for_me_adverts: searchParams.get("for_me_adverts") ?? "0",
    };
    temp.other = otherDataUser.filter((el: any) => {
      return paramfilters[el.param] === "1";
    });
    let disabledDateParam = searchParams.get("disabled") ?? "all";
    temp.disabled = disabledData.filter((el) => el.exp === disabledDateParam);
    let minimumExp = searchParams.get("experience_level_min") ?? "0";
    let maximumExp = searchParams.get("experience_level_max") ?? "24";
    let expId = 0;
    switch (minimumExp + maximumExp) {
      case "00":
        expId = 2;
        break;
      case "024":
        expId = 0;
        break;
      case "025":
        expId = 1;
        break;
      default:
        expId = 9999;
    }
    temp.experience = experienceData.filter((el) => el.id === expId);
    let searchParam = searchParams.get("search") ?? "";
    temp.search = searchParam ? [{ id: 0, title: searchParam }] : [];

    setSelectedValues(temp);
    setNewSelectedValues(temp);
  };

  const GetfilteredAdvert = async () => {
    // sayfadaki veriyi yenileyen metod
    setLoading(true);
    const adverts = await AdvertService.getAdvertByFilter(location.search);

    //gelen advert filtrelerini sayfadaki filtrelere basmak için
    pushAdvertsToFilters(adverts.data.search_data, location.search);

    setPagination(adverts.data.pagination);
    setAdvert(
      adverts.data.adverts.map((el: any) => ({
        advertId: el.advert.id || "",
        advertslug: el.advert.slug || "",
        advertTitle: el.advert.title || "",
        companyName: el.company_detail?.company_name || "",
        workingTypes: el?.working_types || [],
        workingType: el?.working_types[0]?.title || "",
        cities: el?.cities || [],
        city: el?.cities[0]?.name || "",
        companyLogo: el.company_detail?.logo,
        createdAt: el.advert.created_at || "",
        is_disabled_employee: el.advert.is_disabled_employee || 1,
        isSponsored: el.advert.is_use_doping ? true : false,
        is_auth: el.is_auth || false,
        is_view: el.is_view || false,
        is_favorite: el.is_favorite || false,
      }))
    );
    setLoading(false);
    // console.log("RET  ",adverts.data.adverts)
  };

  useEffect(() => {
    // sayfa sıralaması ve gösterim adedinin güncellenmesi
    const initiator = async () => {
      setMobileTab("");
      const searchParams = new URLSearchParams(location.search);
      const page_sort = searchParams.get("page_sort") || "";
      const pageLimit = searchParams.get("page_limit") || "35";
      setPageSort(page_sort);
      setPagelimit(pageLimit);
      GetfilteredAdvert();
    };

    initiator();
  }, [location.search]);

  const jobsPageValue: jobsPageContextType = {
    selectedValues,
    setSelectedValues,
    newSelectedValues,
    setNewSelectedValues,
    handleFilterButton,
    pagination,
    loading,
    pageLimit,
    setIsFilterDeleteApplied,
    createQueryParamsFromSelectedValues,
  };

  return (
    <JobsPageContext.Provider value={jobsPageValue}>
      <div className={`w-full h-full mb-6`}>
        <AltHeader />
        <div className="hidden xl:grid grid-cols-[27%_2%_71%] container mx-auto">
          <div className="w-full">
            <FilterSectionContentCopy
              savedFilters={savedFilters}
              setSavedFilters={setSavedFilters}
              deleteItem={true}
            />
          </div>
          <div />
          <div className="w-full flex flex-col gap-y-2">
            <AdverSectionHeader
              adverts={advert}
              savedFilters={savedFilters}
              setSavedFilters={setSavedFilters}
            />
            <AdvertSectionBody adverts={advert}  />
          </div>
        </div>
        <div className="block xl:hidden bg-white mx-2">
          <div className="flex flex-row border-b-2 border-b-[#de5c2b] items-center justify-between gap-1">
            {advert.length > 0 && (
              <button
                className={`flex flex-1 items-center justify-center text-sm ${
                  mobileTab === "order"
                    ? "text-white bg-[#de5c2b]"
                    : "text-gray-800 bg-slate-100"
                }  py-3 rounded-t-sm flex-row gap-2 border-gray-600 border border-b-transparent transition-all`}
                onClick={() =>
                  setMobileTab((activeTab) =>
                    activeTab === "order" ? "" : "order"
                  )
                }
              >
                Sıralama
                <IoChevronDown
                  className={`${
                    mobileTab === "order" ? "rotate-180" : ""
                  } transition-all`}
                />
              </button>
            )}

            {selectedValuesLength > 0 && (
              <button
                className={`flex flex-1 flex-shrink-0 px-2 items-center justify-center text-sm ${
                  mobileTab === "choosen"
                    ? "text-white bg-[#de5c2b]"
                    : "text-gray-800 bg-slate-100"
                }  py-3 rounded-t-sm flex-row gap-2 border-gray-600 border border-b-transparent transition-all`}
                onClick={() =>
                  setMobileTab((activeTab) =>
                    activeTab === "choosen" ? "" : "choosen"
                  )
                }
              >
                <div className="bg-white flex flex-shrink-0 w-5 h-5 rounded-full items-center justify-center text-sm font-bold text-[#de5c2b] aspect-square">
                  {selectedValuesLength > 9 ? "+9" : `${selectedValuesLength}`}
                </div>
                Seçtikleriniz
                <IoChevronDown
                  className={`${
                    mobileTab === "order" ? "rotate-180" : ""
                  } transition-all`}
                />
              </button>
            )}
            <button
              className={`flex flex-1 items-center justify-center text-sm ${
                mobileTab === "filter"
                  ? "text-white bg-[#de5c2b]"
                  : "text-gray-800 bg-slate-100"
              }  py-3 rounded-t-sm flex-row gap-2 border-gray-600 border border-b-transparent transition-all`}
              onClick={() =>
                setMobileTab((activeTab) =>
                  activeTab === "filter" ? "" : "filter"
                )
              }
            >
              Filtreleme
              <IoChevronDown
                className={`${
                  mobileTab === "filter" ? "rotate-180" : ""
                } transition-all`}
              />
            </button>
          </div>
          <div
            className={`${
              mobileTab === "order" ? "" : "hidden"
            } bg-white rounded-b-md px-2 py-4 shadow`}
          >
            <div className="flex flex-row items-center gap-2">
              <input
                id="updateRadio"
                type="radio"
                name="orderRadio"
                defaultChecked={pageSort === "update"}
                value={"update"}
                onChange={() => {
                  navigate(
                    "/is-ilanlari" +
                      addOrUpdateQueryParam(location, [
                        { key: "page_sort", value: "update" },
                      ]),
                    { replace: true }
                  );
                }}
              />
              <label htmlFor="updateRadio" className="text-gray-600 text-md">
                Güncelleme Tarihine Göre
              </label>
            </div>
            <hr className="my-2" />
            <div className="flex flex-row items-center gap-2">
              <input
                id="publishRadio"
                type="radio"
                name="orderRadio"
                defaultChecked={pageSort === "publish"}
                value={"publish"}
                onChange={() => {
                  navigate(
                    "/is-ilanlari" +
                      addOrUpdateQueryParam(location, [
                        { key: "page_sort", value: "publish" },
                      ]),
                    { replace: true }
                  );
                }}
              />
              <label htmlFor="publishRadio" className="text-gray-600 text-md">
                Yayınlanma Tarihine Göre
              </label>
            </div>
          </div>
          <div
            className={`${
              mobileTab === "choosen" && selectedValuesLength > 0
                ? ""
                : "hidden"
            } flex flex-col gap-2`}
          >
            <div className="flex flex-col gap-2 p-2">
              <label>Seçtikleriniz: ({selectedValuesLength})</label>
              <FilteredItemsList />
              <div className="flex flex-row items-center py-2 px-6 justify-between">
                {userToken ? (
                  <>
                    <FilterSaveModal
                      isMobile={true}
                      savedFilters={savedFilters}
                      setSavedFilters={setSavedFilters}
                    />
                  </>
                ) : (
                  <Link
                    to="/profilim/giris-yap"
                    className="flex flex-row gap-2 text-sm text-gray-600 items-center"
                  >
                    <FaFilter />
                    Filtre Kaydet
                  </Link>
                )}
                <button
                  type="button"
                  className="flex flex-row gap-2 text-sm text-gray-600 items-center"
                  onClick={handleClearFilters}
                >
                  <IoTrashBinOutline />
                  Tümünü Temizle
                </button>
              </div>
            </div>
            <div className="bg-[#f5f5f5] p-2"></div>
          </div>
          <div className={mobileTab === "filter" ? "" : "hidden"}>
            <FilterSectionContentCopy
              savedFilters={savedFilters}
              setSavedFilters={setSavedFilters}
              deleteItem={true}
            />
          </div>
          <AdvertSectionBody adverts={advert}   />
        </div>
      </div>
    </JobsPageContext.Provider>
  );
};

export default Main;
