import { useEffect, useState } from "react";
import Axios from "../../../../../../services/axios";
interface HelpInfo {
  phone: number;
  email: string;
}
const HelpCard: React.FC = () => {
  const customeAxios = new Axios();
  const [helpInfo, setHelpInfo] = useState<HelpInfo>();

  useEffect(() => {
    const fetchHelpInfo = async () => {
      const response = await customeAxios.authRequest({
        type: "get",
        endpoint: "get-setting",
        payload: {},
      });
      if (response.data) {
        setHelpInfo(response.data.data);
      }
    };
    fetchHelpInfo();
  }, []);
  //console.log(helpInfo, "helpInfo");
  return (
    <div>
      <div className="hidden md:hidden lg:flex flex-col items-center p-4 bg-gray-100 rounded-lg shadow-xl mr-10 mt-8 px-16 py-8 border">
        <div className="mt-2 text-sm mb-4 font-medium text-nowrap">
          Yardım - Destek
        </div>
        <div className="text-[#2253af] mb-1">
          <a href="#">
            <span></span>
            <span className="text-nowrap">{helpInfo?.phone}</span>
          </a>
        </div>
        <div className="text-[#2253af] mb-1">
          <a href="#">
            <span></span>
            <span>{helpInfo?.email}</span>
          </a>
        </div>
      </div>
    </div>
  );
};
export default HelpCard;
