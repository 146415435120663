import { HTMLAttributes, LabelHTMLAttributes } from "react"
import { useContext } from "react"
import { SelectContext, selectContextType } from "."
import { IoChevronDown } from "react-icons/io5"







type valuePropsType = LabelHTMLAttributes<HTMLLabelElement>





export default function Value({
    className,
    ...props
}: valuePropsType) {

    const { isOpen, setIsOpen, selectedValue, error }: selectContextType = useContext(SelectContext)

    if (!isOpen && selectedValue) {
        return(
            <div className={`${className} relative p-2 border ${error? 'border-red-600 bg-red-100': 'border-mains'} rounded-md flex items-center h-[45px] w-full pr-10`}  onClick={() => setIsOpen(true)}>
                <label {...props} className={`bg-transparent w-full outline-none text-[14px] font-poppins  text-mains line-clamp-1`}></label>
                <button className="absolute right-4 top-1/2 -translate-y-1/2" onClick={() => setIsOpen((prev) => !prev)}>
                    <IoChevronDown className={`${isOpen? 'rotate-180': ''} text-mains transition-all`}/>
                </button>
            </div>
        )
    } else {
        return null
    }
    
}