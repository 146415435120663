import React from "react";
import CustomAccordion from "../../../../components/common/custom-accordion";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CgSpinner } from "react-icons/cg";
import { toast } from "react-toastify";
import FormField from "../../../../components/common/form-field";
import Axios from "../../../../services/axios";
import { CustomButton } from "../../../../components/common/custom-button";

type formField = {
  label: string;
  name: string;
  required?: boolean;
  type?: "password";
  emailVerified?: boolean;
  limit?: number;
  [key: string]: any; //
};
const axios = new Axios();
function CompanyChangePassword() {
  const changePassword = async (values: { [key: string]: string }) => {
    try {
      await axios.authRequest({
        type: "put",
        endpoint: "companies/account-settings/password-update",
        payload: {
          _method: "put",
          password: values.password,
        },
      });
      toast.success("Şifre değiştirme işlemi başarılı.");
    } catch (error: any) {
      console.log(error);
      if (error.response && error.response.data && error.response.data.errors) {
        // Check if errors exist in the response
        const apiErrors = error.response.data.errors;
        // Iterate through the errors and display them using toast
        Object.keys(apiErrors).forEach((key) => {
          apiErrors[key].forEach((errorMessage: string) => {
            toast.error(errorMessage);
          });
        });
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.error
      ) {
        // If there is a single error message
        toast.error(error.response.data.error);
      } else {
        // If no specific error message received
        toast.error("An error occurred while changing password");
      }

      // Redirect to login if token is invalid
      if (
        error.response &&
        error.response.data &&
        error.response.data.error === "Token is invalid"
      ) {
        window.location.href = "/company/login";
      }
    }
  };

  const formFields: formField[] = [
    {
      label: "Yeni şifre",
      required: true,
      type: "password",
      limit: 8,
      name: "password",
    },
    {
      label: "Yeni şifreniz(Tekrar)",
      required: true,
      type: "password",
      limit: 8,
      name: "password_confirmation",
    },
  ];

  const formValidationSchema = Yup.object().shape(
    formFields.reduce<Record<string, Yup.StringSchema<string>>>(
      (schema, field) => {
        schema[field.name] = Yup.string()
          .required(`${field.label} Alanı Zorunlu`)
          .min(
            field.limit ? field.limit : 0,
            field.limit
              ? `${field.label} en az ${field.limit} karakter olmalı`
              : ""
          );
        if (field.name === "password") {
          schema[field.name] = schema[field.name].matches(
            /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{1,}$/,
            "Şifre en az 1 karakter ve 1 rakam içermelidir."
          );
        }
        if (field.name === "password_confirmation") {
          schema[field.name] = schema[field.name].oneOf(
            [Yup.ref("password")],
            "Şifreler Uyuşmuyor"
          );
        }
        return schema;
      },
      {}
    )
  );

  const initialValues: { [key: string]: string } = {};

  formFields.forEach((field) => {
    initialValues[field.name] = "";
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await changePassword(values);
      setSubmitting(false);
    },
  });
  const showError = () => {
    if (formik.errors && Object.keys(formik.errors).length > 0) {
      Object.keys(formik.errors).forEach((key) => {
        toast.error(formik.errors[key]);
      });
    }
  };
  return (
    <CustomAccordion
      title="Şifreyi Değiştir"
      subtitle="Şifreni değiştirebilir veya sıfırlayabilirsin."
    >
      <form onSubmit={formik.handleSubmit}>
        <>
          <div className="flex justify-center md:flex-row flex-col items-center gap-4 w-full mt-4 space-y-4 md:space-y-0 max-w-[85%] mx-auto">
            {formFields.map((field, index) => (
              <FormField
                onChange={formik.handleChange}
                key={index}
                label={field.label}
                name={field.name}
                type={field.type}
                emailVerified={field.emailVerified}
                options={field.options}
                error={formik.errors[field.name]}
                required={field.required}
                value={formik.values[field.name]}
                invalid={formik.errors[field.name]?.length}
              />
            ))}
          </div>

          <div className="col-span-2 flex justify-end">
            <CustomButton
              onClick={() => showError()}
              className="bg-blue-700 px-5 py-2 mt-4 text text-white rounded "
              type="submit"
              isSubmitting={formik.isSubmitting}
            >
              Kaydet
            </CustomButton>
          </div>
        </>
      </form>
    </CustomAccordion>
  );
}

export default CompanyChangePassword;
