import React, { useState, useEffect } from "react";
import { IoIosClose } from "react-icons/io";
import Swal from "sweetalert2";
import { CustomFormField } from "../../../../components/common/custom-form-field";
import { InputMask } from "@react-input/mask";
import MaskedInput from "react-text-mask";
import Axios from "../../../../services/axios";
import Cookies from "js-cookie";
import { FaTimes } from "react-icons/fa";

interface WorkingLocationPopupProps {
  onClose: () => void;
  companyID: any;
  companyName: any;
  isApply?: any;
  setIsApply?: any;
}

const ReportPopup: React.FC<WorkingLocationPopupProps> = ({
  onClose,
  companyID,
  companyName,
}) => {
  const userToken = Cookies.get("userToken") || Cookies.get("companyUserToken");
  const axios = new Axios();
  const [isVisible, setIsVisible] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [complaint, setComplaint] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      document.body.classList.add("overflow-hidden", "h-auto");
      setIsVisible(true);
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  const handleCloseClick = () => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
      document.body.classList.remove("overflow-hidden", "h-auto");
    }, 500);
  };

  const getInputClasses = (value: string, hasError: boolean): string => {
    let baseClasses =
      "block w-full shadow-sm sm:text-sm rounded py-2 focus:outline-none";
    if (formSubmitted && hasError) {
      return `${baseClasses} pl-3 border border-[red] bg-red-100 text-red-700 focus:ring-red-500`;
    } else if (value) {
      return `${baseClasses} pl-3 border border-blue-500 bg-white text-blue-700 focus:ring-blue-500`;
    }
    return `${baseClasses} pl-3 border border-gray-300`;
  };

  const isEmailValid = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // şikayet etme işlemi

  function trim(str: string, ch: any) {
    var start = 0,
      end = str.length;

    while (start < end && str[start] === ch) ++start;

    while (end > start && str[end - 1] === ch) --end;

    return start > 0 || end < str.length ? str.substring(start, end) : str;
  }

  const handleSubmission = () => {
    setFormSubmitted(true); // Set form submitted to true to trigger validation styling
    let hasError =
      !userName ||
      !email ||
      !isEmailValid(email) ||
      !phone ||
      trim(phone, "_").length < 16 ||
      !complaint;
    if (hasError) {
      let errorMessage = "Lütfen tüm alanları doldurunuz.";
      if (email && !isEmailValid(email)) {
        errorMessage = "Lütfen geçerli bir e-posta adresi giriniz.";
      }
      Swal.fire({
        icon: "error",
        title: "Hata!",
        text: errorMessage,
        confirmButtonText: "Tamam",
      });
      return; // Prevent further actions if validation fails
    }

    if (userToken) {
      Swal.fire({
        title: "Şikayetiniz Alındı",
        text: "Şikayet başarıyla tarafımıza ulaştı. İsterseniz firmayı engelleyebilirsiniz.",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Firmayı Engelle",
        cancelButtonText: "Kapat",
      }).then(async (result) => {
        if (result.isConfirmed) {
          handleBlockCompany();

          const response = await axios.request({
            type: "post",
            endpoint: `user/profilim/blacklist/create`,
            payload: {
              headers: {
                Authorization: `Bearer ${userToken}`,
              },
              company_id: companyID,
            },
          });

          console.log("Block company response:", response);
        } else {
          handleCloseClick();
        }
      });
    } else {
      Swal.fire({
        title: "Şikayetiniz Alındı",
        text: "Şikayet başarıyla tarafımıza ulaştı.",
        icon: "success",
        confirmButtonText: "Tamam",
      });
      handleCloseClick();
    }
  };

  //engelleme işlemi

  const handleBlockCompany = () => {
    Swal.fire({
      title: "Firma Engellendi",
      text: "Eğer engeli kaldırmak istiyorsanız aşağıdaki 'Engeli Kaldır' butonuna tıklayarak engeli kaldırabilirsiniz.",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Engeli Kaldır",
      cancelButtonText: "Kapat",
    }).then(async (result) => {
      if (result.isConfirmed) {
        handleSubmission(); // Re-open the initial Swal to potentially unblock or re-address the situation

        const response = await axios.request({
          type: "delete",
          endpoint: `user/profilim/blacklist/delete/${companyID}`,
          payload: {
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          },
        });

        console.log(response);
      } else {
        handleCloseClick();
      }
    });
  };

  return (
    <div className="fixed inset-0 z-[1050] flex items-start justify-center overflow-y-auto ">
      <div
        className="fixed inset-0 bg-black opacity-50 h-auto "
        onClick={handleCloseClick}
      ></div>

      <div
        className={`bg-white my-10 max-md:my-0 max-md:fixed max-md:top-0 max-md:left-0 max-md:w-screen max-md:h-screen max-md:overflow-y-auto md:rounded-lg md:shadow-lg  md:relative md:min-w-[40%] transition-all duration-500 ease-in-out transform ${
          isVisible ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-10"
        }`}
      >
        <div className={"flex justify-between border-b-2 py-5 px-5"}>
          <div className={"text-mains font-bold text-lg"}>Şikayet Et</div>
          <div
            onClick={handleCloseClick}
            className={
              "flex justify-self-end justify-center items-center w-5 h-5 "
            }
          >
            <FaTimes className={"text-gray-400"} />
          </div>
        </div>

        <div className="mt-6 space-y-4 p-5">
          <div>
            <label
              htmlFor="userName"
              className="block text-gray-700 text-sm font-semibold mb-2"
            >
              Adınız & Soyadınız <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              id="userName"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              className={getInputClasses(userName, formSubmitted && !userName)}
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-gray-700 text-sm font-semibold mb-2"
            >
              E-Posta Adresiniz <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={getInputClasses(
                email,
                formSubmitted && (!email || !isEmailValid(email))
              )}
            />
          </div>
          <div>
            <label
              htmlFor="phone"
              className="block text-gray-700 text-sm font-semibold mb-2"
            >
              Telefon Numaranız <span className="text-red-500">*</span>
            </label>

            <MaskedInput
              className={getInputClasses(
                phone,
                formSubmitted && (!phone || trim(phone, "_").length < 16)
              )}
              mask={[
                "0", // Başına 0 ekleniyor
                "(",
             /[1-9]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
              ]}
              placeholder="0(___) ___ __ __" // Placeholder da güncelleniyor
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              id="phone"
              type="text"
            />
          </div>
          <div>
            <label
              htmlFor="complaint"
              className="block text-gray-700 text-sm font-semibold mb-2"
            >
              Şikayet Sebebi <span className="text-red-500">*</span>
            </label>
            <textarea
              id="complaint"
              value={complaint}
              onChange={(e) => setComplaint(e.target.value)}
              className={getInputClasses(
                complaint,
                formSubmitted && !complaint
              )}
            ></textarea>
          </div>
        </div>
        <div className="mt-4 flex justify-end p-5">
          <button
            onClick={handleCloseClick}
            className="bg-gray-300 hover:bg-[#DE5B2B] hover:text-white text-base md:text-xl py-2 px-3 rounded mr-2"
          >
            Kapat
          </button>
          <button
            onClick={handleSubmission}
            className="bg-[#2b54ab] hover:bg-blue-700 text-white text-base font-semibold py-2 px-3 rounded"
          >
            Gönder
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportPopup;
