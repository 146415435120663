import Axios from "../axios";
import Cookies from "js-cookie";

const getTokenFromCookies = () => {
  return Cookies.get("userToken");
};

const AdvertService = {
  getAdvertByFilter: (filter: string) => {
    const token = getTokenFromCookies(); // Token'ı çerezlerden al
    const axios = new Axios();
    return axios.request({
      type: "get",
      endpoint: `adverts/get-filtered-adverts${filter}`,
      payload: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });
  },

  getAdvertDetailById: (id: number) => {
    const axios = new Axios();
    return axios.request({
      type: "get",
      endpoint: `adverts/${id}/detail`,
      payload: {},
    });
  },
};

export { AdvertService };
