import { CiUser } from "react-icons/ci";
import { FaPhoneAlt } from "react-icons/fa";
import { LiaFaxSolid } from "react-icons/lia";
import { Position, JobBenefits, Languages, SectionType } from "../body/index";
import { PositionLevel } from "../body/index";
import { Sector } from "../body/index";
import { WorkingLocation } from "../body/index";
import { WorkingType } from "../body/index";
import { Location } from "../body/index";
import { EducationLevels } from "../body/index";
import { ExperienceLevel } from "../body/index";
import { DrivingLicenceSrcs } from "../body/index";
import { Button, Modal } from "antd";
import { useState } from "react";
import { BsTelephone } from "react-icons/bs";
import { FaCity } from "react-icons/fa6";
import { IoPersonOutline } from "react-icons/io5";
import { DrivingLicenceClassType } from "../../../../store/contextApi/ad-api-context/Iget-ad-data";

interface MainInfoProps {
  companyFullName: string;
  companyLand_phone: string;
  companyPhone: string;
  positions: Position[] | undefined;
  positionLevels: PositionLevel[] | undefined;
  sectors: Sector[] | undefined;
  sections: SectionType[] | undefined;
  workingTypes: WorkingType[] | undefined;
  workingLocations: WorkingLocation[] | undefined;
  locations: Location[] | undefined;
  educationLevels: EducationLevels[] | undefined;
  experienceLevel: ExperienceLevel | undefined;
  drivingLicenceSrcs: DrivingLicenceSrcs[] | undefined;
  drivingLicenceClasses: DrivingLicenceClassType[] | undefined
  jobBenefits: JobBenefits[] | undefined;
  languages: Languages[] | undefined;
  hide_company_info?: boolean;
  hide_contact_info?: "show_all" | "show_gsm" | "show_phone" | "hide_all";
  hide_employee_info?: boolean;
  advertLanguage: number;
  employee_name: string | null;
  employee_count?: number;
  min_age?: number;
  max_age?: number;
}
const MainInfo: React.FC<MainInfoProps> = ({
  companyFullName,
  positions,
  sectors,
  sections,
  workingTypes,
  positionLevels,
  workingLocations,
  locations,
  companyPhone,
  companyLand_phone,
  educationLevels,
  experienceLevel,
  drivingLicenceSrcs,
  drivingLicenceClasses,
  jobBenefits,
  languages,
  hide_company_info,
  hide_contact_info,
  hide_employee_info,
  advertLanguage,
  employee_name,
  employee_count,
  max_age,
  min_age
}) => {

  function formatPhoneNumber(phoneNumber: string): string {
    console.log(phoneNumber);
    
    if (phoneNumber.length === 10) {
      phoneNumber = '0' + phoneNumber;
    }
    
    if (phoneNumber.length === 11 && phoneNumber.startsWith('0')) {
      return `0(${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)} ${phoneNumber.slice(7, 9)} ${phoneNumber.slice(9, 11)}`;
    }
    
    console.log(phoneNumber);
    return phoneNumber; 
  }
  

  // General language ids
  const turkishlanguageId = 9;
  const englishLanguageId = 7;

  const isEnglish: boolean = advertLanguage == englishLanguageId;

  const formattedCompanyPhone = formatPhoneNumber(companyPhone);
  const formattedLandPhone = formatPhoneNumber(companyLand_phone);

  const [isPositionsModalOpen, setIsPositionsModalOpen] =
    useState<boolean>(false);
  const [isEducationLevelsModalOpen, setIsEducationLevelsModalOpen] =
    useState<boolean>(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isPositionLevelsModalOpen, setIsPositionLevelsModalOpen] =
    useState<boolean>(false);
  const [isSectorModalOpen, setIsSectorModalOpen] = useState<boolean>(false);
  const [isSectionModalOpen, setIsSectionModalOpen] = useState<boolean>(false);
  const [isWorkingTypesModalOpen, setIsWorkingTypesModalOpen] =
    useState<boolean>(false);
  const [isWorkingLocationsModalOpen, setIsWorkingLocationsModalOpen] =
    useState<boolean>(false);
  const [isLanguagesModalOpen, setIsLanguagesModalOpen] =
    useState<boolean>(false);
  const [isLocationsModalOpen, setIsLocationsModalOpen] =
    useState<boolean>(false);
  const [isJobBenefitsModalOpen, setIsJobBenefitsModalOpen] =
    useState<boolean>(false);
  const [isSrcsModalOpen, setIsSrcsModalOpen] = useState<boolean>(false);
  const [isClassesModalOpen, setIsClassesModalOpen] = useState<boolean>(false)

  //console.log(languages)
  //console.log(jobBenefits)

  const firstLocationName =
    locations && locations[0]
      ? `${locations[0].city?.name} - ${
          !locations[0].district
            ? `${isEnglish ? "All Districts" : "Tüm İlçeler"}`
            : `${
                locations[0].district?.name == "Tüm İlçeler"
                  ? `${isEnglish ? "All Districts" : "Tüm İlçeler"}`
                  : `${locations[0].district?.name}`
              }`
        }`
      : ``;

  return (
    <div className="font-poppins">
      <div className="py-2 pl-3 font-medium md:text-lg  shadow-sm border-b border-t bg-[#fbfbfb]">
        {isEnglish ? "ADDITIONAL INFORMATION" : "EK BİLGİLER"}
      </div>

      {/* Mobile Details */}
      <div className="bg-white block md:hidden">

        <div className="flex flex-row flex-wrap py-2 px-6 max-w-[600px]">

          {/* Tecrübe */}
          <div className="flex flex-col basis-1/2 px-1">
            <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
              {advertLanguage == turkishlanguageId
                ? "Tecrübe"
                : advertLanguage == englishLanguageId
                ? "Experience"
                : "Tecrübe"}
            </label>
            <div className="flex flex-row items-start flex-wrap">
              <label className="text-xs lg:text-sm text-gray-600 font-poppins">
                {experienceLevel?.experience_level_min?.value &&
                experienceLevel?.experience_level_max?.value
                  ? `${experienceLevel.experience_level_min.value} - ${
                      experienceLevel.experience_level_max.value
                    } ${isEnglish ? "Years" : "Yıl"}`
                  : `${isEnglish ? "Doesn't Matter" : "Fark Etmez"}`}
              </label>
            </div>
          </div>

          {/* Eğitim seviyesi */}
          <div className="flex flex-col basis-1/2 px-1">
            <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
              {isEnglish ? "Education Level" : "Eğitim Seviyesi"}
            </label>
            <div className="flex flex-row items-start flex-wrap">
              <a
                href={`/is-ilanlari?education_level=${
                  educationLevels && educationLevels[0]
                    ? educationLevels[0].education_level?.slug
                    : ""
                }`}
                className="text-xs lg:text-sm text-gray-600 font-poppins"
              >
                {educationLevels &&
                  educationLevels[0] &&
                  `${
                    isEnglish
                      ? `${educationLevels[0].education_level.title_en.slice(
                          0,
                          15
                        )}`
                      : `${educationLevels[0].education_level.title.slice(
                          0,
                          15
                        )}`
                  }${
                    educationLevels[0].education_level.title.length > 15
                      ? "..."
                      : ""
                  }`}
              </a>
              {educationLevels && educationLevels.length > 1 && (
                <button
                  type="button"
                  className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                  onClick={() => setIsEducationLevelsModalOpen(true)}
                >
                  (+{educationLevels.length - 1})
                </button>
              )}
            </div>
          </div>

          {/* Pozisyon */}
          <div className="flex flex-col basis-1/2 px-1">
            <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
              {isEnglish ? "Position" : "Pozisyon"}
            </label>
            <div className="flex flex-row items-start flex-wrap">
              <a
                target="_blank"
                href={`/is-ilanlari?position=${
                  positions && positions[0]
                    ? `${positions[0].position?.slug}`
                    : ""
                }`}
                className="text-xs lg:text-sm text-gray-600 font-poppins"
              >
                {positions &&
                  positions[0] &&
                  `${
                    isEnglish
                      ? `${positions[0].position.title_en.slice(0, 17)}`
                      : `${positions[0].position.title.slice(0, 17)}`
                  }${positions[0].position.title.length > 17 ? "..." : ""}`}
              </a>
              {positions && positions.length > 1 && (
                <button
                  type="button"
                  className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                  onClick={() => setIsPositionsModalOpen(true)}
                >
                  (+{positions.length - 1})
                </button>
              )}
            </div>
          </div>

          {/* Pozisyon seviyeleri */}
          {positionLevels && positionLevels.length > 0 && (
            <div className="flex flex-col basis-1/2 px-1">
              <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
                {isEnglish ? "Position Level" : "Pozisyon Seviyesi"}
              </label>
              <div className="flex flex-row items-start flex-wrap">
                <a
                  href={`/is-ilanlari?education_level=${
                    positionLevels && positionLevels[0]
                      ? positionLevels[0].position_level?.slug
                      : ""
                  }`}
                  className="text-xs lg:text-sm text-gray-600 font-poppins"
                >
                  {positionLevels &&
                    positionLevels[0] &&
                    `${
                      isEnglish
                        ? `${positionLevels[0].position_level?.name_en?.slice(
                            0,
                            15
                          )}`
                        : `${positionLevels[0].position_level?.name?.slice(
                            0,
                            15
                          )}`
                    }${
                      positionLevels[0].position_level.name?.length > 15
                        ? "..."
                        : ""
                    }`}
                </a>
                {positionLevels && positionLevels.length > 1 && (
                  <button
                    type="button"
                    className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                    onClick={() => setIsPositionLevelsModalOpen(true)}
                  >
                    (+{positionLevels.length - 1})
                  </button>
                )}
              </div>
            </div>
          )}

          {/* Sektör */}
          <div className="flex flex-col basis-1/2 px-1">
            <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
              {isEnglish ? "Sector" : "Sektör"}
            </label>
            <div className="flex flex-row items-start flex-wrap">
              <a
                target="_blank"
                href={`/is-ilanlari?sector=${
                  sectors && sectors[0] ? `${sectors[0].sector?.slug}` : ""
                }`}
                className="text-xs lg:text-sm text-gray-600 font-poppins"
              >
                {sectors &&
                  sectors[0] &&
                  `${
                    isEnglish
                      ? `${sectors[0].sector.title_en?.slice(0, 17)}`
                      : `${sectors[0].sector.title.slice(0, 17)}`
                  }${sectors[0].sector.title.length > 17 ? "..." : ""}`}
              </a>
              {sectors && sectors.length > 1 && (
                <button
                  type="button"
                  className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                  onClick={() => setIsSectorModalOpen(true)}
                >
                  (+{sectors.length - 1})
                </button>
              )}
            </div>
          </div>

          {/* Bölüm */}
          <div className="flex flex-col basis-1/2 px-1">
            <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
              {isEnglish ? "Section" : "Bölüm"}
            </label>
            <div className="flex flex-row items-start flex-wrap">
              <a
                href={`/is-ilanlari?section=${
                  sections && sections[0] ? sections[0].section?.slug : ""
                }`}
                className="text-xs lg:text-sm text-gray-600 font-poppins"
              >
                {sections &&
                  sections[0] &&
                  `${
                    isEnglish
                      ? `${sections[0].section.title_en.slice(0, 14)}`
                      : `${sections[0].section.title.slice(0, 14)}`
                  }${sections[0].section.title.length > 14 ? "..." : ""}`}
              </a>
              {sections && sections.length > 1 && (
                <button
                  type="button"
                  className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                  onClick={() => setIsSectionModalOpen(true)}
                >
                  (+{sections.length - 1})
                </button>
              )}
            </div>
          </div>

          {/* Çalışma şekli */}
          <div className="flex flex-col basis-1/2 px-1">
            <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
              {isEnglish ? "Working Type" : "Çalışma Şekli"}
            </label>
            <div className="flex flex-row items-start flex-wrap">
              <a
                target="_blank"
                href={`/is-ilanlari?working_type=${
                  workingTypes && workingTypes[0]
                    ? `${workingTypes[0].working_type?.slug}`
                    : ""
                }`}
                className="text-xs lg:text-sm text-gray-600 font-poppins"
              >
                {workingTypes &&
                  workingTypes[0] &&
                  `${
                    isEnglish
                      ? `${workingTypes[0].working_type.title_en.slice(0, 15)}`
                      : `${workingTypes[0].working_type.title.slice(0, 15)}`
                  }${
                    workingTypes[0].working_type.title.length > 15 ? "..." : ""
                  }`}
              </a>
              {workingTypes && workingTypes.length > 1 && (
                <button
                  type="button"
                  className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                  onClick={() => setIsWorkingTypesModalOpen(true)}
                >
                  (+{workingTypes.length - 1})
                </button>
              )}
            </div>
          </div>

          {/* Çalışma tercihi */}
          <div className="flex flex-col basis-1/2 px-1">
            <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
              {isEnglish ? "Work Preference" : "Çalışma Tercihi"}
            </label>
            <div className="flex flex-row items-start flex-wrap">
              <a
                href={`/is-ilanlari?working_location=${
                  workingLocations && workingLocations[0]
                    ? workingLocations[0].working_location?.slug
                    : ""
                }`}
                className="text-xs lg:text-sm text-gray-600 font-poppins"
              >
                {workingLocations &&
                  workingLocations[0] &&
                  `${
                    isEnglish
                      ? `${workingLocations[0].working_location.title_en.slice(
                          0,
                          15
                        )}`
                      : `${workingLocations[0].working_location.title.slice(
                          0,
                          15
                        )}`
                  }${
                    workingLocations[0].working_location.title.length > 15
                      ? "..."
                      : ""
                  }`}
              </a>
              {workingLocations && workingLocations.length > 1 && (
                <button
                  type="button"
                  className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                  onClick={() => setIsWorkingLocationsModalOpen(true)}
                >
                  (+{workingLocations.length - 1})
                </button>
              )}
            </div>
          </div>

          {/* Çalışma yeri */}
          <div className="flex flex-col basis-1/2 px-1">
            <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
              {isEnglish ? "Workplace" : "Çalışma Yeri"}
            </label>
            <div className="flex flex-row items-start flex-wrap">
              <a
                href={`/is-ilanlari?location=${
                  locations && locations[0] ? locations[0].city?.slug : ""
                }`}
                className="text-xs lg:text-sm text-gray-600 font-poppins"
              >
                {locations &&
                  locations[0] &&
                  `${firstLocationName.slice(0, 15)}${
                    firstLocationName.length > 15 ? "..." : ""
                  }`}
              </a>
              {locations && locations.length > 1 && (
                <button
                  type="button"
                  className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                  onClick={() => setIsLocationsModalOpen(true)}
                >
                  (+{locations.length - 1})
                </button>
              )}
            </div>
          </div>

          {/* Diller */}
          {languages && languages.length > 0 && (
            <div className="flex flex-col basis-1/2 px-1">
              <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
                {isEnglish ? "Foreign Language" : "Yabancı Dil"}
              </label>
              <div className="flex flex-row items-start flex-wrap">
                <a
                  target="_blank"
                  href={`/is-ilanlari?language=${
                    languages && languages[0]
                      ? `${languages[0].language?.id}`
                      : ""
                  }`}
                  className="text-xs lg:text-sm text-gray-600 font-poppins"
                >
                  {languages &&
                    languages[0] &&
                    `${
                      isEnglish
                        ? `${languages[0].language?.name_en.slice(0, 15)}`
                        : `${languages[0].language?.name.slice(0, 15)}`
                    }${languages[0].language?.name.length > 15 ? "..." : ""}`}
                </a>
                {languages && languages.length > 1 && (
                  <button
                    type="button"
                    className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                    onClick={() => setIsLanguagesModalOpen(true)}
                  >
                    (+{languages.length - 1})
                  </button>
                )}
              </div>
            </div>
          )}

          {/* Yan haklar */}
          {jobBenefits && jobBenefits.length > 0 && (
            <div className="flex flex-col basis-1/2 px-1">
              <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
                {isEnglish ? "Job Benefits" : "Yan Haklar"}
              </label>
              <div className="flex flex-row items-start flex-wrap">
                <a
                  href={`/is-ilanlari?job_benefit=${
                    jobBenefits && jobBenefits[0]
                      ? jobBenefits[0].job_benefit.id
                      : ""
                  }`}
                  className="text-xs lg:text-sm text-gray-600 font-poppins"
                >
                  {jobBenefits &&
                    jobBenefits[0] &&
                    `${
                      isEnglish
                        ? `${jobBenefits[0].job_benefit?.name_en.slice(0, 15)}`
                        : `${jobBenefits[0].job_benefit?.name.slice(0, 15)}`
                    }${
                      jobBenefits[0].job_benefit?.name.length > 17 ? "..." : ""
                    }`}
                </a>
                {jobBenefits && jobBenefits.length > 1 && (
                  <button
                    type="button"
                    className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                    onClick={() => setIsJobBenefitsModalOpen(true)}
                  >
                    (+{jobBenefits.length - 1})
                  </button>
                )}
              </div>
            </div>
          )}

          {/* SRC belgeleri */}
          {drivingLicenceSrcs && drivingLicenceSrcs.length > 0 && (
            <div className="flex flex-col basis-1/2 px-1">
              <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
                {isEnglish ? "SRC Certificates" : "SRC Belgeleri"}
              </label>
              <div className="flex flex-row items-start flex-wrap">
                <a
                  target="_blank"
                  href={`/is-ilanlari?driving_license_src=${
                    drivingLicenceSrcs && drivingLicenceSrcs[0]
                      ? `${drivingLicenceSrcs[0].driving_licence_src.id}`
                      : ""
                  }`}
                  className="text-xs lg:text-sm text-gray-600 font-poppins"
                >
                  {drivingLicenceSrcs &&
                    drivingLicenceSrcs[0] &&
                    `${drivingLicenceSrcs[0].driving_licence_src?.name}`}
                </a>
                {drivingLicenceSrcs && drivingLicenceSrcs.length > 1 && (
                  <button
                    type="button"
                    className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                    onClick={() => setIsSrcsModalOpen(true)}
                  >
                    (+{drivingLicenceSrcs.length - 1})
                  </button>
                )}
              </div>
            </div>
          )}

          {/* Sürücü belgeleri */}
          {drivingLicenceClasses && drivingLicenceClasses.length > 0 && (
            <div className="flex flex-col basis-1/2 px-1">
              <label className="text-sm lg:text-base font-semibold text-gray-600 font-poppins">
                {isEnglish ? "Driver's Licenses" : "Sürücü Lisansları"}
              </label>
              <div className="flex flex-row items-start flex-wrap">
                <a
                  target="_blank"
                  href={`/is-ilanlari?driving_license_src=${
                    drivingLicenceClasses && drivingLicenceClasses[0]
                      ? `${drivingLicenceClasses[0].driving_licence_class.id.toString()}`
                      : ""
                  }`}
                  className="text-xs lg:text-sm text-gray-600 font-poppins"
                >
                  {drivingLicenceClasses &&
                    drivingLicenceClasses[0] &&
                    `${drivingLicenceClasses[0].driving_licence_class?.name}`}
                </a>
                {drivingLicenceClasses && drivingLicenceClasses.length > 1 && (
                  <button
                    type="button"
                    className="text-black text-[10px] lg:text-sm font-semibold font-poppins ml-1"
                    onClick={() => setIsClassesModalOpen(true)}
                  >
                    (+{drivingLicenceClasses.length - 1})
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Desktop Details */}
      <div className="bg-white hidden md:block font-poppins text-sm py-3 pl-3 shadow-lg">
        <div className="pl-3">
          <ul>
            {/* Positions */}
            <li className="flex items-center space-x-4 md:mb-2 mb-4">
              <span className="w-[38%] md:w-[20%] text-[#4e4e4e] flex-shrink-0">
                {isEnglish ? "Position" : "Pozisyon"}
              </span>
              {positions && positions.length > 0 && (
                <>
                  <a
                    key={0}
                    target="_blank"
                    href={`/is-ilanlari?working_type=${positions[0]?.position?.slug}`}
                  >
                    <div className="text-[#2253af]">
                      :{" "}
                      {isEnglish
                        ? positions[0].position.title_en
                        : positions[0].position.title}
                    </div>
                  </a>
                  {positions.length > 1 && (
                    <div
                      onClick={() => setIsPositionsModalOpen(true)}
                      className="text-[#2253af] !ml-2 font-semibold text-xs cursor-pointer"
                    >
                      {`(+${positions.length - 1})`}
                    </div>
                  )}
                </>
              )}
            </li>

            {/* Position Levels */}
            {positionLevels && positionLevels.length > 0 && (
              <li className="flex items-center space-x-4 md:mb-2 mb-4">
                <span className="w-[38%] md:w-[20%] text-[#4e4e4e] flex-shrink-0">
                  {isEnglish ? "Position Level" : "Pozisyon Seviyesi"}
                </span>
                <div className="flex flex-row text-[#2253af]">
                  {positionLevels && positionLevels[0] && (
                    <a
                      target="_blank"
                      href={`/is-ilanlari?position_level=${positionLevels[0]?.position_level?.slug}`}
                    >
                      :{" "}
                      {isEnglish
                        ? positionLevels[0].position_level?.name_en
                        : positionLevels[0].position_level?.name}
                    </a>
                  )}
                  {positionLevels && positionLevels.length > 1 && (
                    <button
                      type="button"
                      className="text-[#2253af] !ml-2 font-semibold text-xs cursor-pointer"
                      onClick={() => setIsPositionLevelsModalOpen(true)}
                    >
                      (+{positionLevels.length - 1})
                    </button>
                  )}
                </div>
              </li>
            )}

            {/* Education Level */}
            <li className="flex items-center space-x-4 md:mb-2 mb-4">
              <span className="w-[38%] md:w-[20%] text-[#4e4e4e] flex-shrink-0">
                {isEnglish ? "Education Level" : "Eğitim Seviyesi"}
              </span>
              {educationLevels && educationLevels.length > 0 && (
                <>
                  <a
                    key={0}
                    target="_blank"
                    href={`/is-ilanlari?working_type=${educationLevels[0]?.education_level?.slug}`}
                  >
                    <div className="text-[#2253af]">
                      :{" "}
                      {isEnglish
                        ? educationLevels[0].education_level.title_en
                        : educationLevels[0].education_level.title}
                    </div>
                  </a>
                  {educationLevels.length > 1 && (
                    <div
                      onClick={() => setIsEducationLevelsModalOpen(true)}
                      className="text-[#2253af] !ml-2 font-semibold text-xs cursor-pointer"
                    >
                      {`(+${educationLevels.length - 1})`}
                    </div>
                  )}
                </>
              )}
            </li>

            {/* Sections */}
            <li className="flex items-center space-x-4 md:mb-2 mb-4">
              <span className="w-[38%] md:w-[20%] text-[#4e4e4e] flex-shrink-0">
                {isEnglish ? "Section" : "Bölüm"}
              </span>
              {sections && sections.length > 0 && (
                <>
                  <a
                    key={0}
                    target="_blank"
                    href={`/is-ilanlari?section=${sections[0]?.section?.slug}`}
                  >
                    <div className="text-[#2253af]">
                      :{" "}
                      {isEnglish
                        ? sections[0].section.title_en
                        : sections[0].section.title}
                    </div>
                  </a>
                  {sections.length > 1 && (
                    <div
                      onClick={() => setIsSectionModalOpen(true)}
                      className="text-[#2253af] !ml-2 font-semibold text-xs cursor-pointer"
                    >
                      {`(+${sections.length - 1})`}
                    </div>
                  )}
                </>
              )}
            </li>

            {/* Experience Level */}
            <li className="flex items-center space-x-4 md:mb-2 mb-4">
              <span className="w-[38%] md:w-[20%] text-[#4e4e4e] flex-shrink-0">
                {isEnglish ? "Experience Level" : "Deneyim Seviyesi"}
              </span>
              {/* <div className="text-[#2253af]">
                :{" "}
                {experienceLevel?.experience_level_min?.value &&
                experienceLevel?.experience_level_max?.value
                  ? `${experienceLevel.experience_level_min.value} - ${
                      experienceLevel.experience_level_max.value
                    } ${isEnglish ? "Year" : "Yıl"}`
                  : `${isEnglish ? "Doesn't Matter" : "Fark Etmez"}`}
              </div> */}
              <div className="text-[#2253af]">
                :{' '}
                {isEnglish? experienceLevel?.experience_level_min.title_en?? "Doesn't Matter": experienceLevel?.experience_level_min.title?? 'Fark Etmez'}
              </div>
            </li>

            {/* Sector */}
            <li className="flex items-center space-x-4 md:mb-2 mb-4">
              <span className="w-[38%] md:w-[20%] text-[#4e4e4e] flex-shrink-0">
                {isEnglish ? "Sector" : "Sektör"}
              </span>
              {sectors && sectors[0] && (
                <div className="flex flex-row items-center">
                  <a
                    target="_blank"
                    href={`/is-ilanlari?sector=${sectors[0].sector?.slug}`}
                  >
                    <div className="text-[#2253af]">
                      :{" "}
                      {isEnglish
                        ? sectors[0].sector.title_en
                        : sectors[0].sector.title}
                    </div>
                  </a>
                  {sectors && sectors.length > 1 && (
                    <button
                      type="button"
                      onClick={() => setIsSectorModalOpen(true)}
                      className="text-[#2253af] !ml-2 font-semibold text-xs cursor-pointer"
                    >
                      (+{sectors.length - 1})
                    </button>
                  )}
                </div>
              )}
            </li>

            {/* Working locations */}
            <li className="flex items-center space-x-4 md:mb-2 mb-4">
              <span className="w-[38%] md:w-[20%] text-[#4e4e4e] flex-shrink-0">
                {isEnglish ? "Work Preference" : "Çalışma Tercihi"}
              </span>
              {workingLocations && workingLocations[0] && (
                <div className="flex flex-row items-center">
                  <a
                    target="_blank"
                    href={`/is-ilanlari?working_location=${workingLocations[0].working_location?.slug}`}
                  >
                    <div className="text-[#2253af]">
                      :{" "}
                      {isEnglish
                        ? workingLocations[0].working_location.title_en
                        : workingLocations[0].working_location.title}
                    </div>
                  </a>
                  {workingLocations && workingLocations.length > 1 && (
                    <button
                      type="button"
                      onClick={() => setIsWorkingLocationsModalOpen(true)}
                      className="text-[#2253af] !ml-2 font-semibold text-xs cursor-pointer"
                    >
                      (+{workingLocations.length - 1})
                    </button>
                  )}
                </div>
              )}
            </li>

            {/* Working Types */}
            <li className="flex items-center space-x-4 md:mb-2 mb-4">
              <span className="w-[38%] md:w-[20%] text-[#4e4e4e] flex-shrink-0">
                {isEnglish ? "Working Type" : "Çalışma Şekli"}
              </span>
              {workingTypes && workingTypes.length > 0 && (
                <>
                  <a
                    key={0}
                    target="_blank"
                    href={`/is-ilanlari?working_type=${workingTypes[0]?.working_type?.slug}`}
                  >
                    <div className="text-[#2253af]">
                      :{" "}
                      {isEnglish
                        ? workingTypes[0].working_type.title_en
                        : workingTypes[0].working_type.title}
                    </div>
                  </a>
                  {workingTypes.length > 1 && (
                    <div
                      onClick={() => setIsWorkingTypesModalOpen(true)}
                      className="text-[#2253af] !ml-2 font-semibold text-xs cursor-pointer"
                    >
                      {`(+${workingTypes.length - 1})`}
                    </div>
                  )}
                </>
              )}
            </li>

            {/* Locations */}
            <li className="flex items-center space-x-4 md:mb-2 mb-4">
              <span className="w-[38%] md:w-[20%] text-[#4e4e4e] flex-shrink-0">
                {isEnglish ? "Workplace" : "Çalışma Yeri"}
              </span>
              {locations && locations[0] && (
                <div className="flex flex-row items-center">
                  <a
                    target="_blank"
                    href={`/is-ilanlari?working_location=${locations[0].city?.slug}`}
                  >
                    <div className="text-[#2253af]">
                      : {locations[0].city?.name} -{" "}
                      {locations[0].district
                        ? `${
                            locations[0].district?.name == "Tüm İlçeler" &&
                            isEnglish
                              ? `All Districts`
                              : `${locations[0].district?.name}`
                          }`
                        : `${isEnglish ? "All Districts" : "Tüm İlçeler"}`}
                    </div>
                  </a>
                  {locations && locations.length > 1 && (
                    <button
                      type="button"
                      onClick={() => setIsLocationsModalOpen(true)}
                      className="text-[#2253af] !ml-2 font-semibold text-xs cursor-pointer"
                    >
                      (+{locations.length - 1})
                    </button>
                  )}
                </div>
              )}
            </li>

            {/* Driving licenses srcs */}
            {drivingLicenceSrcs && drivingLicenceSrcs.length > 0 && (
              <>
                <li className="flex items-center space-x-4 md:mb-2 mb-4">
                  <span className="w-[38%] md:w-[20%] text-[#4e4e4e] flex-shrink-0">
                    {isEnglish ? "SRC Certificates" : "SRC Belgeleri"}
                  </span>
                  {drivingLicenceSrcs && drivingLicenceSrcs[0] && (
                    <div className="flex flex-row items-center">
                      <a
                        target="_blank"
                        href={`/is-ilanlari?src=${drivingLicenceSrcs[0].driving_licence_src.id}`}
                      >
                        <div className="text-[#2253af]">
                          : {drivingLicenceSrcs[0].driving_licence_src?.name}
                        </div>
                      </a>
                      {drivingLicenceSrcs && drivingLicenceSrcs.length > 1 && (
                        <button
                          type="button"
                          onClick={() => setIsSrcsModalOpen(true)}
                          className="text-[#2253af] !ml-2 font-semibold text-xs cursor-pointer"
                        >
                          (+{drivingLicenceSrcs.length - 1})
                        </button>
                      )}
                    </div>
                  )}
                </li>
              </>
            )}

            {/* Driving license classes */}
            {drivingLicenceClasses && drivingLicenceClasses.length > 0 && (
              <>
                <li className="flex items-center space-x-4 md:mb-2 mb-4">
                  <span className="w-[38%] md:w-[20%] text-[#4e4e4e] flex-shrink-0">
                    {isEnglish ? "Driver Licenses" : "Sürücü Lisansları"}
                  </span>
                  {drivingLicenceClasses && drivingLicenceClasses[0] && (
                    <div className="flex flex-row items-center">
                      <a
                        target="_blank"
                        href={`/is-ilanlari?src=${drivingLicenceClasses[0].driving_licence_class.id.toString()}`}
                      >
                        <div className="text-[#2253af]">
                          : {drivingLicenceClasses[0].driving_licence_class?.name}
                        </div>
                      </a>
                      {drivingLicenceClasses && drivingLicenceClasses.length > 1 && (
                        <button
                          type="button"
                          onClick={() => setIsClassesModalOpen(true)}
                          className="text-[#2253af] !ml-2 font-semibold text-xs cursor-pointer"
                        >
                          (+{drivingLicenceClasses.length - 1})
                        </button>
                      )}
                    </div>
                  )}
                </li>
              </>
            )}

            {/* Job benefits */}
            {jobBenefits && jobBenefits.length > 0 && (
              <>
                <li className="flex items-center space-x-4 md:mb-2 mb-4">
                  <span className="w-[38%] md:w-[20%] text-[#4e4e4e] flex-shrink-0">
                    {isEnglish ? "Job Benefits" : "Yan Haklar"}
                  </span>

                  {jobBenefits && jobBenefits[0] && (
                    <div className="flex flex-row items-center">
                      <a
                        target="_blank"
                        href={`/is-ilanlari?job_benefit=${jobBenefits[0].job_benefit.id}`}
                      >
                        <div className="text-[#2253af]">
                          {/* FIXME: veritabanı ingilizce eksik o gelince düzenlenecek */}
                          :{" "}
                          {isEnglish
                            ? jobBenefits[0].job_benefit?.name_en
                            : jobBenefits[0].job_benefit?.name}
                        </div>
                      </a>
                      {jobBenefits && jobBenefits.length > 1 && (
                        <button
                          type="button"
                          onClick={() => setIsJobBenefitsModalOpen(true)}
                          className="text-[#2253af] !ml-2 font-semibold text-xs cursor-pointer"
                        >
                          (+{jobBenefits.length - 1})
                        </button>
                      )}
                    </div>
                  )}
                </li>
              </>
            )}

            {/* Diller */}
            {languages && languages.length > 0 && (
              <li className="flex items-center space-x-4 md:mb-2 mb-4">
                <span className="w-[38%] md:w-[20%] text-[#4e4e4e] flex-shrink-0">
                  {isEnglish ? "Foreign Languages" : "Yabancı Dil"}
                </span>

                {languages && languages[0] && (
                  <div className="flex flex-row items-center">
                    <a
                      target="_blank"
                      href={`/is-ilanlari?job_benefit=${languages[0].language?.id}`}
                    >
                      <div className="text-[#2253af]">
                        :{" "}
                        {isEnglish
                          ? languages[0].language?.name_en
                          : languages[0].language?.name}
                      </div>
                    </a>
                    {languages && languages.length > 1 && (
                      <button
                        type="button"
                        onClick={() => setIsLanguagesModalOpen(true)}
                        className="text-[#2253af] !ml-2 font-semibold text-xs cursor-pointer"
                      >
                        (+{languages.length - 1})
                      </button>
                    )}
                  </div>
                )}
              </li>
            )}
          </ul>
        </div>
      </div>

      {/* Contact Details */}
      <div className="p-2 bg-white">
        <div className="pl-3">
          <h3 className="text-lg font-bold mt-8 mb-4">
            {hide_contact_info==='hide_all' && !hide_employee_info&& !hide_company_info&&(isEnglish ? "Contact Information" : "İletişim Bilgileri")}
          </h3>
          {hide_contact_info == "show_all" || !hide_contact_info ? (
            <ul>
              {!hide_employee_info && (
                <li className="flex items-center mb-2 space-x-4">
                  <IoPersonOutline className="text-gray-600 text-xl" />
                  <span className="text-[#4e4e4e]">{employee_name}</span>
                </li>
              )}
              <li className="flex items-center mb-2 space-x-4">
                <LiaFaxSolid className="text-gray-600 text-xl" />
                <span className="text-[#4e4e4e]">{formattedLandPhone}</span>
              </li>
              <li className="flex items-center mb-2 space-x-4">
                <BsTelephone className="text-gray-600 text-xl" />
                <span className="text-[#4e4e4e]">{formattedCompanyPhone}</span>
              </li>
            </ul>
          ) : hide_contact_info == "show_phone" ? (
            <ul>
              {!hide_company_info && (
                <li className="flex items-center mb-2 space-x-4">
                  <CiUser className="text-gray-600 text-xl" />
                  <span className="text-[#4e4e4e]">{employee_name}</span>
                </li>
              )}
              <li className="flex items-center mb-2 space-x-4">
                <LiaFaxSolid className="text-gray-600 text-xl" />
                <span className="text-[#4e4e4e]">{formattedLandPhone}</span>
              </li>
            </ul>
          ) : hide_contact_info == "show_gsm" ? (
            <ul>
              {!hide_company_info && (
                <li className="flex items-center mb-2 space-x-4">
                  <CiUser className="text-gray-600 text-xl" />
                  <span className="text-[#4e4e4e]">{employee_name}</span>
                </li>
              )}
              <li className="flex items-center mb-2 space-x-4">
                <FaPhoneAlt className="text-gray-600 text-xl" />
                <span className="text-[#4e4e4e]">{formattedCompanyPhone}</span>
              </li>
            </ul>
          ) : hide_contact_info == "hide_all" ? (
            <ul>
              {!hide_employee_info && (
                <li className="flex items-center mb-2 space-x-4">
                  <CiUser className="text-gray-600 text-xl" />
                  <span className="text-[#4e4e4e]">{employee_name}</span>
                </li>
              )}
            </ul>
          ) : null}
          {/*
            TODO: İlk hali kontrollerin ardından silinecek
            <ul>
              <li className="flex items-center mb-2 space-x-4">
                <CiUser className="text-gray-600 text-xl" />
                <span className="text-[#4e4e4e]">{companyFullName}</span>
              </li>
              <li className="flex items-center mb-2 space-x-4">
                <FaPhoneAlt className="text-gray-600 text-xl"/>
                <span className="text-[#4e4e4e]">{formattedCompanyPhone}</span>
              </li>
              <li className="flex items-center mb-2 space-x-4">
                <LiaFaxSolid className="text-gray-600 text-xl" />
                <span className="text-[#4e4e4e]">{formattedLandPhone}</span>
              </li>
            </ul> */}
        </div>
      </div>

      {/* Modals */}
      <div>

        {/* Eğitim seviyesi */}
        <Modal
          title={isEnglish ? "EDUCATION LEVEL" : "EĞİTİM SEVİYESİ"}
          open={isEducationLevelsModalOpen}
          footer={null}
          onOk={() => setIsEducationLevelsModalOpen(false)}
          onCancel={() => setIsEducationLevelsModalOpen(false)}
        >
          {educationLevels &&
            educationLevels.map((educationLevel, index) => (
              <div key={index} className="flex items-center mb-1">
                <div className=" text-gray-700 text-sm capitalize">
                  {isEnglish
                    ? educationLevel.education_level.title_en
                    : educationLevel.education_level.title}
                </div>
              </div>
            ))}
        </Modal>

        {/* Pozisyon */}
        <Modal
          title={isEnglish ? "POSITION" : "POZİSYON"}
          open={isPositionsModalOpen}
          footer={null}
          onOk={() => setIsPositionsModalOpen(false)}
          onCancel={() => setIsPositionsModalOpen(false)}
        >
          {positions &&
            positions.map((position, index) => (
              <div key={index} className="flex items-center mb-1">
                <div className=" text-gray-700 text-sm capitalize">
                  {isEnglish
                    ? position.position.title_en
                    : position.position.title}
                </div>
              </div>
            ))}
        </Modal>

        {/* Pozisyon seviyesi */}
        <Modal
          title={isEnglish ? "POSITION LEVEL" : "POZİSYON SEVİYESİ"}
          open={isPositionLevelsModalOpen}
          onOk={() => setIsPositionLevelsModalOpen(false)}
          onCancel={() => setIsPositionLevelsModalOpen(false)}
          footer={null}
        >
          {positionLevels &&
            positionLevels.map((positionLevel) => {
              return (
                <div
                  key={`positionLevels${positionLevel.position_level.id}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {isEnglish
                      ? positionLevel.position_level?.name_en
                      : positionLevel.position_level?.name}
                  </div>
                </div>
              );
            })}
        </Modal>

        {/* Sektör */}
        <Modal
          title={isEnglish ? "SECTOR" : "SEKTÖR"}
          open={isSectorModalOpen}
          onOk={() => setIsSectorModalOpen(false)}
          onCancel={() => setIsSectorModalOpen(false)}
          footer={null}
        >
          {sectors &&
            sectors.map((sector) => {
              return (
                <div
                  key={`sectorDiv${sector.id}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {isEnglish ? sector.sector.title_en : sector.sector.title}
                  </div>
                </div>
              );
            })}
        </Modal>

        {/* Bölüm */}
        <Modal
          title={isEnglish ? "SECTION" : "BÖLÜM"}
          open={isSectionModalOpen}
          onOk={() => setIsSectionModalOpen(false)}
          onCancel={() => setIsSectionModalOpen(false)}
          footer={null}
        >
          {sections &&
            sections.map((section) => {
              return (
                <div
                  key={`sectionDiv${section.section.id}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {isEnglish
                      ? section.section.title_en
                      : section.section.title}
                  </div>
                </div>
              );
            })}
        </Modal>

        {/* Çalışma şekli */}
        <Modal
          title={isEnglish ? "WORKING TYPE" : "ÇALIŞMA ŞEKLİ"}
          open={isWorkingTypesModalOpen}
          onOk={() => setIsWorkingTypesModalOpen(false)}
          onCancel={() => setIsWorkingTypesModalOpen(false)}
          footer={null}
        >
          {workingTypes &&
            workingTypes.map((workingType) => {
              return (
                <div
                  key={`positionLevelsDiv${workingType.id}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {isEnglish
                      ? workingType.working_type.title_en
                      : workingType.working_type.title}
                  </div>
                </div>
              );
            })}
        </Modal>

        {/* Çalışma tercihi */}
        <Modal
          title={isEnglish ? "WORK PREFERENCE" : "ÇALIŞMA TERCİHİ"}
          open={isWorkingLocationsModalOpen}
          onOk={() => setIsWorkingLocationsModalOpen(false)}
          onCancel={() => setIsWorkingLocationsModalOpen(false)}
          footer={null}
        >
          {workingLocations &&
            workingLocations.map((workingLocation) => {
              return (
                <div
                  key={`workingLocationDiv${workingLocation.working_location.id}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {isEnglish
                      ? workingLocation.working_location.title_en
                      : workingLocation.working_location.title}
                  </div>
                </div>
              );
            })}
        </Modal>

        {/* Yabancı dil */}
        <Modal
          title={isEnglish ? "FOREIGN LANGUAGES" : "YABANCI DİL"}
          open={isLanguagesModalOpen}
          onOk={() => setIsLanguagesModalOpen(false)}
          onCancel={() => setIsLanguagesModalOpen(false)}
          footer={null}
        >
          {languages &&
            languages.map((language) => {
              return (
                <div
                  key={`positionLevelsDiv${language.language?.id}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {isEnglish
                      ? language.language?.name_en
                      : language.language?.name}
                  </div>
                </div>
              );
            })}
        </Modal>

        {/* Çalışma yeri */}
        <Modal
          title={isEnglish ? "WORKPLACE" : "ÇALIŞMA YERİ"}
          open={isLocationsModalOpen}
          onOk={() => setIsLocationsModalOpen(false)}
          onCancel={() => setIsLocationsModalOpen(false)}
          footer={null}
        >
          {locations &&
            locations.map((location, i) => {
              return (
                <div
                  key={`positionLevelsDiv${location.id}-${i}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {location.city?.name} -{" "}
                    {location.district
                      ? `${
                          isEnglish && location.district?.name == "Tüm İlçeler"
                            ? `All Districts`
                            : `${location.district?.name}`
                        }`
                      : `${isEnglish ? "All Districts" : "Tüm İlçeler"}`}
                  </div>
                </div>
              );
            })}
        </Modal>

        {/* Yan haklar */}
        <Modal
          title={isEnglish ? "JOB BENEFITS" : "YAN HAKLAR"}
          open={isJobBenefitsModalOpen}
          onOk={() => setIsJobBenefitsModalOpen(false)}
          onCancel={() => setIsJobBenefitsModalOpen(false)}
          footer={null}
        >
          {jobBenefits &&
            jobBenefits.map((jobBenefit) => {
              return (
                <div
                  key={`positionLevelsDiv${jobBenefit.job_benefit.id}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {isEnglish
                      ? jobBenefit.job_benefit?.name_en
                      : jobBenefit.job_benefit?.name}
                  </div>
                </div>
              );
            })}
        </Modal>

        {/* Src belgeleri */}
        <Modal
          title={isEnglish ? "SRC CERTIFICATES" : "SRC BELGELERİ"}
          open={isSrcsModalOpen}
          onOk={() => setIsSrcsModalOpen(false)}
          onCancel={() => setIsSrcsModalOpen(false)}
          footer={null}
        >
          {drivingLicenceSrcs &&
            drivingLicenceSrcs.map((src) => {
              return (
                <div
                  key={`positionLevelsDiv${src.driving_licence_src.id}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {src.driving_licence_src?.name}
                  </div>
                </div>
              );
            })}
        </Modal>

        {/* Sürücü belgeleri */}
        <Modal
          title={isEnglish ? "DRIVER'S LICENSES" : "SÜRÜCÜ LİSANSLARI"}
          open={isClassesModalOpen}
          onOk={() => setIsClassesModalOpen(false)}
          onCancel={() => setIsClassesModalOpen(false)}
          footer={null}
        >
          {drivingLicenceClasses &&
            drivingLicenceClasses.map((src) => {
              return (
                <div
                  key={`positionLevelsDiv${src.driving_licence_class.id}`}
                  className="flex items-center mb-1"
                >
                  <div className=" text-gray-700 text-sm capitalize">
                    {src.driving_licence_class?.name}
                  </div>
                </div>
              );
            })}
        </Modal>
      </div>
    </div>
  );
};
export default MainInfo;
