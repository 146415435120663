import React, { useEffect, useState } from "react";
import { useCookie } from "../../../../../hooks/useCookie";
import { CookieName } from "../../../../../constants/enums";
import { hiddenTopPaths } from "../constants/hiddenTopPaths";
import { Link, useLocation } from "react-router-dom";
import Axios from "../../../../../services/axios";
import Swal from "sweetalert2";
import { CgSpinner } from "react-icons/cg";
import { FaRegTrashAlt } from "react-icons/fa";
import { TbCameraPlus } from "react-icons/tb";
import { PhotoUploadModal } from "../PhotoUploadModal";
import { FaArrowsRotate } from "react-icons/fa6";
import Fancybox from "../../../../layout/fancybox";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import {
  getUserInfo,
  sendUserVerifyEmail,
  sendUserVerifySms,
} from "../../../../../store/content-reducer/personInfoSlice";
import EmailVerification from "../../../../../containers/profile-page-container/dashboard/contents/contact/email-verification";
import NumberVerification from "../../../../../containers/profile-page-container/dashboard/contents/contact/number-verification";
import ProfilePhotoChanger from "../../../../pages/MyResumes/CreateCV/PersonalInformation/components/ProfilePhotoChanger";

const DEFAULT_IMAGE_URL =
  "https://isbull.s3.eu-north-1.amazonaws.com/default-images/user-profile.png?v=2.9.90";

const Top: React.FC = () => {
  const axios = new Axios();
  const { getCookie } = useCookie();
  const location = useLocation();
  const userCookie = getCookie(CookieName.UserData);
  const user = userCookie ? JSON.parse(userCookie) : "";
  const [cvId, setCvId] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>(DEFAULT_IMAGE_URL);
  const [deletingLogo, setDeletingLogo] = useState(false);

  // Redux dispatcher and selector
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.userInfo);

  // Local state for managing modals
  const [phones, setPhones] = useState(false);
  const [emails, setEmails] = useState(false);

  // Fetch user info on component mount
  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  const email = () => {
    // Send verification email
    dispatch(sendUserVerifyEmail({ email: userInfo?.email }));
    setEmails(true);
  };

  const emailc = () => {
    setEmails(false);
  };

  const phone = () => {
    // Send verification SMS
    dispatch(sendUserVerifySms({}));
    setPhones(true);
  };

  const phonec = () => {
    setPhones(false);
  };

  const formatDate = (date: string) => {
    const newDate = new Date(date);
    const cleanDate = newDate.toLocaleDateString("tr-TR").replace(/\//g, ".");
    return cleanDate;
  };

  useEffect(() => {
    if (user) {
      const fetchCv = async () => {
        try {
          const response = await axios.request({
            endpoint: `user/profilim/cv`,
            type: "get",
            payload: {},
          });

          if (response?.data?.data) {
            setCvId(response.data.data.id);
            setImageUrl(response.data.user.profile || DEFAULT_IMAGE_URL);
          }
        } catch (error) {
          console.log("Error fetching data:", error);
        }
      };

      fetchCv();
    }
  }, [user]);

  const handleChangeData = async () => {
    try {
      const response = await axios.userAuthRequest({
        type: "put",
        endpoint: `user/profilim/cv/${cvId}/update-date`,
        payload: "",
      });

      if (response.data) {
        Swal.fire({
          icon: "success",
          text: "CV tarihi başarıyla güncellendi.",
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "sweet-alert-button",
          },
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const createLogo = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append("profile_logo", file);
      formData.append("_method", "post");
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/create`,
        type: "post",
        payload: formData,
      });
      if (response && response.data) {
        Swal.fire({
          icon: "success",
          text: "Profil fotoğrafı başarıyla yüklendi.",
          confirmButtonText: "Tamam",
        });
        setImageUrl(URL.createObjectURL(file));
        setModalOpen(false);
      } else {
        throw new Error("No data received from the server");
      }
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Profil fotoğrafı yüklenirken bir hata oluştu.",
        confirmButtonText: "Tamam",
      });
    }
  };

  const updateLogo = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append("profile_logo", file);
      formData.append("_method", "put");
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/update`,
        type: "post",
        payload: formData,
      });
      if (response && response.data) {
        Swal.fire({
          icon: "success",
          text: "Profil fotoğrafı başarıyla güncellendi.",
          confirmButtonText: "Tamam",
        });
        setImageUrl(URL.createObjectURL(file));
        setModalOpen(false);
      } else {
        throw new Error("No data received from the server");
      }
    } catch (error: any) {
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Profil fotoğrafı güncellenirken bir hata oluştu.",
        confirmButtonText: "Tamam",
      });
    }
  };

  const deleteLogo = async () => {
    try {
      setDeletingLogo(true);
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/delete`,
        type: "delete",
        payload: {},
      });
      if (response.data) {
        Swal.fire({
          icon: "success",
          text: "Profil fotoğrafı başarıyla silindi.",
          confirmButtonText: "Tamam",
        });
        setImageUrl(DEFAULT_IMAGE_URL);
      }
      setDeletingLogo(false);
    } catch (error: any) {
      setImageUrl(DEFAULT_IMAGE_URL);
      setDeletingLogo(false);
      console.error(error);
      Swal.fire({
        icon: "error",
        text: "Profil fotoğrafı silinirken bir hata oluştu.",
        confirmButtonText: "Tamam",
      });
    }
  };

  const [cvViewsCount, setCvViewsCount] = useState<number>(0);

  useEffect(() => {
    const getViewsCompany = async () => {
      try {
        const response = await axios.userAuthRequest({
          endpoint: "user/profilim/cv-views/get-cv-views",
          type: "get",
          payload: {},
        });
        if (response.data.data.length > 0) {
          let totalViews = 0;
          response.data.data.forEach((item: any) => {
            totalViews += item.cv_view_count;
          });
          setCvViewsCount(totalViews);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getViewsCompany();
  }, []);

  console.log("cvViewsCount", cvViewsCount);

  return (
    <div
      className={`${
        hiddenTopPaths.find((h) => location.pathname.startsWith(h.pathname)) !=
        undefined
          ? "hidden lg:flex"
          : "flex"
      } flex-col h-auto px-3 md:px-5 py-5 drop-shadow-xl bg-gradient-to-r from-[#1E4691] to-[#0F3479] gap-y-4`}
    >
      <div className="flex items-center justify-center w-full">
        <div className="relative">
          <Fancybox options={{ Carousel: { infinite: false } }}>
            <a data-fancybox="gallery" href={imageUrl}>
              <img
                src={imageUrl}
                alt="profile-logo"
                className="w-[100px] h-[100px] object-contain rounded-full border border-black"
              />
            </a>
          </Fancybox>

          {imageUrl === DEFAULT_IMAGE_URL ? (
            <button
              type="button"
              className="absolute p-1.5 bg-blue-700 rounded-full cursor-pointer
              text-lg w-7 h-7 flex justify-center items-center text-white border-2 border-white -bottom-0 -right-2"
              onClick={() => setModalOpen(true)}
            >
              <TbCameraPlus />
            </button>
          ) : (
            <>
              <button
                type="button"
                className="absolute p-1.5 bg-blue-700 rounded-full cursor-pointer
                text-lg w-7 h-7 flex justify-center items-center text-white border-2 border-white -bottom-0 -left-2"
                onClick={() => setModalOpen(true)}
              >
                <FaArrowsRotate />
              </button>
              <div
                className="absolute -bottom-0 -right-2 p-1.5 bg-title rounded-full cursor-pointer
                text-lg w-7 h-7 flex justify-center items-center text-white border-2 border-white"
                onClick={() => {
                  Swal.fire({
                    icon: "warning",
                    title: "İşlemi onaylayın",
                    text: "Profil fotoğrafınızı silmek istediğinize emin misiniz?",
                    confirmButtonColor: "#2253af",
                    confirmButtonText: "Evet",
                    cancelButtonText: "Hayır",
                    showCancelButton: true,
                    showCloseButton: true,
                    preConfirm: async () => {
                      await deleteLogo();
                    },
                  });
                }}
              >
                {deletingLogo ? (
                  <CgSpinner className="animate-spin" />
                ) : (
                  <FaRegTrashAlt />
                )}
              </div>
            </>
          )}

          <PhotoUploadModal
            isOpen={modalOpen}
            closeModal={() => setModalOpen(false)}
            setIsModalOpen={setModalOpen}
            onFileSelect={(file: any) => console.log(file)}
            onSave={imageUrl === DEFAULT_IMAGE_URL ? createLogo : updateLogo}
          />
        </div>
      </div>

      <div className="flex flex-wrap items-center justify-center w-full gap-x-2">
        <span className="text-lg text-white poppins-semibold line-clamp-1">
          {user.name ? user.name.toLocaleUpperCase("tr-TR") : ""}
        </span>
        <span className="text-lg text-white poppins-semibold line-clamp-1">
          {user.surname ? user.surname.toLocaleUpperCase("tr-TR") : ""}
        </span>
      </div>

      <div className="flex justify-center items-center flex-col gap-1 lg:hidden">
        <span className="text-xs text-white poppins-light flex justify-center items-center gap-2">
          <span className="font-poppins text-base"> {user.email}</span>
          {userInfo.is_email_verified !== undefined &&
            userInfo.is_email_verified === 0 && (
              <span onClick={email} className="font-poppins poppins-semibold">
                Doğrula
              </span>
            )}
          {emails && <EmailVerification onClose={emailc} />}
        </span>

        <span className="text-xs text-white poppins-light flex justify-center items-center gap-2">
          <span className="font-poppins text-base">{`0 ${
            user?.phone?.substring(0, 3) ?? ""
          } ${user?.phone?.substring(3, 6) ?? ""} ${
            user?.phone?.substring(6, 8) ?? ""
          } ${user?.phone?.substring(8, 10) ?? ""}`}</span>
          {userInfo.is_phone_verified !== undefined &&
            userInfo.is_phone_verified === 0 && (
              <span onClick={phone} className="font-poppins poppins-semibold">
                Doğrula
              </span>
            )}
          {phones && <NumberVerification onClose={phonec} />}
        </span>
      </div>

      <div className="flex justify-center items-center gap-2 md:gap-4 lg:hidden">
        <button
          onClick={() => handleChangeData()}
          className="w-full bg-[#de5b2b] px-3 py-2 flex justify-center items-center rounded-md"
        >
          <span className="text-[12px]  poppins-semibold text-white text-nowrap whitespace-nowrap">
            CV Tarihini Güncelle
          </span>
        </button>

        <a
          href={`/profilim/cv/duzenle/${cvId}`}
          className="flex items-center justify-center w-full bg-[#1565c0] px-3 py-2  rounded-md"
        >
          <span className="text-[13px]  poppins-semibold text-white text-nowrap">
            CV Düzenle
          </span>
        </a>
      </div>

      <div className="bg-white rounded-md flex flex-col justify-center items-center p-3 lg:hidden">
        <span className="flex items-center justify-center poppins-semibold text-base gap-1">
          <p>CV Görüntülenme Sayısı</p>{" "}
          <span className="w-5 h-5 bg-red-600 text-white rounded-full text-xs flex justify-center items-center">
            {cvViewsCount}
          </span>
        </span>

        <span className="flex items-center justify-center text-xs poppins-light">
          CV Son Güncelleme: {formatDate(user.updated_at)}
        </span>

        <Link
          to={`/profilim/cv/${cvId}/goruntuleyen-firmalar`}
          className="underline text-xs mt-1 text-nowrap"
          style={{ textDecoration: "underline", textUnderlineOffset: "3px" }}
        >
          Görüntüleyen Firmaları Göster
        </Link>
      </div>

      <button onClick={() => handleChangeData()} className="lg:block hidden">
        <div className="flex items-center justify-center w-full h-auto bg-gray-300 rounded-sm">
          <span className="py-2 text-xs text-gray-700 poppins-semibold whitespace-nowrap">
            CV Tarihini Güncelle
          </span>
        </div>
      </button>

      <a
        href={`/profilim/cv/duzenle/${cvId}`}
        className="lg:flex hidden  items-center justify-center w-full"
      >
        <span className="text-sm text-white underline poppins-semibold">
          CV Düzenle
        </span>
      </a>

      <span className="lg:flex hidden items-center justify-center text-xs text-white poppins-light">
        Son Güncelleme: {formatDate(user.updated_at)}
      </span>
    </div>
  );
};

export default Top;
