import * as React from "react";
import { WhyItemContainer } from "./why-item-container";

interface ILeftSideProps {
  readonly showLeftSideTopCard: boolean;
}

function LeftSide(props: ILeftSideProps): React.ReactElement {
  const location = window.location.pathname;
  const isCompany = location.includes("isveren");
  return (
    <div className="hidden w-full py-8 lg:block">
      <div className="flex flex-col items-center w-full h-full px-10 space-y-2 text-center sm:px-0 md:items-start md:text-left">
        <h2 className="poppins-semibold text-2xl text-[#2253af]">
          Saniyeler içinde üye ol!
        </h2>
        <div>
          <span>
            {isCompany ? (
              <p className="poppins-medium text-[#63636c] text-sm">
                Hızlı ve etkili! isbul.net sayesinde iş ilanlarınızı
                yayınlayarak potansiyel adayların size hızla ulaşmasını
                sağlayabilirsiniz. Şirketinizin büyümesine katkıda bulunacak
                uygun adayları bulmak artık çok daha kolay!
              </p>
            ) : (
              <p className="poppins-medium font-[700] text-[#63636c] text-left">
                Her yıl yeni işini isbul.net ile bulan
                <br />
                <span className="text-title">1.6 milyon adayın</span> arasına
                sen de katıl!
              </p>
            )}
          </span>
        </div>
        <h4 className="poppins-medium text-lg text-[#2253af]">
          Neden isbul.net'e üye olmalıyım?
        </h4>

        <WhyItemContainer isCompany={isCompany} />
      </div>
    </div>
  );
}
export { LeftSide };
