import { LabelHTMLAttributes, useContext } from "react";
import { RadioContext, radioContextType } from ".";

type errorPropsType = LabelHTMLAttributes<HTMLLabelElement> & {};

export default function RadioError({ className, ...props }: errorPropsType) {
  const { error }: radioContextType = useContext(RadioContext);

  if (error) {
    return (
      <label {...props} className={`${className} text-xs text-red-600`}>
        {/* Error mesajını buraya yazabilirsiniz */}
      </label>
    );
  }
  return null;
}
