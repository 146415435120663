import React from "react";
import { FcGoogle } from "react-icons/fc";
import { CookieName } from "../../../../../constants/enums";
import { useCookie } from "../../../../../hooks/useCookie";

const ApplyPostingsNow: React.FC = () => {
  const { getCookie } = useCookie();

  const userTokenCookie = getCookie(CookieName.UserToken);
  const userToken = userTokenCookie ? userTokenCookie : "";

  // Show the component if neither userToken nor companyUserToken is present
  if (userToken) {
    return null;
  }

  return (
    <div className="bg-white p-4 shadow rounded-lg space-y-4">
      <h2 className="font-medium text-xl mb-4 text-center">
        Hemen ilanlara başvur!
      </h2>
      {/* Buttons next to each other */}
      <div className="flex justify-center space-x-2 mb-4">
        <a
          href="/profilim/kayit-ol"
          className="bg-[#2253af] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Üye Ol
        </a>
        <a
          href="/profilim/giris-yap"
          className="bg-[green] hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        >
          Giriş Yap
        </a>
      </div>
      {/* Description */}
      <p className="text-center text-[#878787]">
        veya sosyal hesap ile devam et
      </p>
      {/* Social Login Buttons */}
      <div className="flex justify-center space-x-2">
        <button className="text-white font-bold py-2 px-4 rounded flex items-center border border-[#2253af] pl-12 pr-12">
          <span>
            <FcGoogle />
          </span>
        </button>
        <button className="text-white font-bold py-2 px-4 rounded flex items-center border border-[#2253af] pl-12 pr-12">
          <span>
            <img alt="facebook" src="/facebook.png" />
          </span>
        </button>
      </div>
    </div>
  );
};

export default ApplyPostingsNow;
