import * as React from "react";
import { RegisterServices } from "../../../../../../../services/register-services";
import Axios from "../../../../../../../services/axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CustomFormField } from "../../../../../custom-form-field";
import { Link, useNavigate } from "react-router-dom";
import { useCookie } from "../../../../../../../hooks/useCookie";
import { CookieName } from "../../../../../../../constants/enums";
import { useEffect } from "react";
import { SelectDropdown } from "../../../../../../isbul-ui";
import Swal from "sweetalert2";
import { AxiosError } from "axios";
import { trim } from "lodash";

const axios = new Axios();

const containsTurkishChars = (value: any) => {
  const turkishChars = /[çÇğĞıİöÖşŞüÜ]/;
  return turkishChars.test(value);
};

const validationSchema = Yup.object({
  firstName: Yup.string().required("İsim alanı zorunludur"),
  lastName: Yup.string().required("Soyadınız alanı zorunludur"),
  email: Yup.string()
    .email("Geçerli bir E-mail adresi giriniz")
    .test(
      "is-valid-email",
      "Geçerli bir E-mail adresi giriniz",
      (value) => !containsTurkishChars(value)
    )
    .required("Email alanı zorunludur"),
  phoneNumber: Yup.string()
    .transform((value) => value.replace(/\D/g, ""))
    .matches(/^\d+$/, "Telefon numarası sadece rakamlardan oluşmalıdır")
    .min(10, "Telefon numarası en az 11 karakter olmalıdır")
    .required("Telefon numarası zorunludur"),
  landline: Yup.string()
    .transform((value) => value.replace(/\D/g, ""))
    .min(10, "Sabit telefon numarası en az 11 karakter olmalıdır")
    .required("Sabit telefon numarası zorunludur"),
  password: Yup.string()
    .min(8, "Şifre en az 8 karakter olmalıdır")
    .required("Şifre alanı zorunludur"),
  companyName: Yup.string().required("Şirket adı zorunludur"),
  city: Yup.string().required("Şehir alanı zorunludur"),
  district: Yup.string().required("İlçe alanı zorunludur"),
  taxOfficeCity: Yup.string().required("Vergi dairesi ili zorunludur"),
  taxOffice: Yup.string().required("Vergi dairesi zorunludur"),
  taxNumber: Yup.string()
    .max(11, "Vergi numarası en fazla 11 karakter olmalıdır")
    .min(10, "Vergi numarası en az 10 karakter olmalıdır")
    .transform((value) => value.replace(/\D/g, ""))
    .matches(/^\d+$/, "Vergi numarası sadece rakamlardan oluşmalıdır")
    .required("Vergi numarası zorunludur"),
  permissionEmail: Yup.boolean(),
  readKVKK: Yup.boolean()
    .oneOf([true], "KVKK Aydınlatma Metni okunmalı ve kabul edilmelidir")
    .required("KVKK Aydınlatma Metni okunmalı ve kabul edilmelidir"),
});

type taxOfficeType = {
  label: string;
  value: string;
};

function RegisterCompany() {
  const navigate = useNavigate();
  const { setCookie } = useCookie();
  const [missingFields, setMissingFields] = React.useState<string[]>([]);
  const [locations, setLocations] = React.useState({
    cities: [],
    districts: [],
    taxOfficeDistricts: [],
  });

  const [capsLockWarning, setCapsLockWarning] = React.useState(false);

  React.useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      const isLetter = /^[a-zA-Z]$/.test(e.key);
      if (!isLetter) return;

      const isCapsLockOn =
        (e.getModifierState && e.getModifierState("CapsLock")) ||
        (!e.shiftKey && e.key >= "A" && e.key <= "Z") ||
        (e.shiftKey && e.key >= "a" && e.key <= "z");
      setCapsLockWarning(isCapsLockOn);
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.getModifierState && !e.getModifierState("CapsLock")) {
        setCapsLockWarning(false);
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  React.useEffect(() => {
    fetchCities();
  }, []);

  // İl getirme

  const fetchCities = async () => {
    try {
      const response = await axios.request({
        type: "get",
        endpoint: "cdn/get-cities?limit=100",
        payload: {},
      });
      const cities = response.data.cities.map(
        (city: { id: number; name: string }) => ({
          value: city.id.toString(),
          label: city.name,
        })
      );
      //cities.unshift({ value: "", label: "Seçiniz" });
      setLocations((prev) => ({ ...prev, cities }));
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  // İlçe ve vergi dairesi getirme

  const fetchDistricts = async (cityId: string, type: string) => {
    if (!cityId) return;
    try {
      const endpoint =
        type === "city"
          ? `cdn/get-districts?limit=100&city_id=${cityId}`
          : `cdn/get-districts?limit=100&city_id=${cityId}`;
      const response = await axios.request({
        type: "get",
        endpoint: endpoint,
        payload: {},
      });
      const districts = response.data.districts.map(
        (district: { id: number; name: string }) => ({
          value: district.id.toString(),
          label: district.name,
        })
      );

      const endpointTax = `cdn/get-tax-offices?city_id=${cityId}`;

      const taxOfficesResponse = await axios.request({
        type: "get",
        endpoint: endpointTax,
        payload: {},
      });

      const taxOffices = taxOfficesResponse.data.tax_offices.map(
        (tax_office: { id: number; name: string }) => ({
          value: tax_office.id,
          label: tax_office.name,
        })
      );

      //districts.unshift({ value: "", label: "Seçiniz" });
      if (type === "city") {
        setLocations((prev) => ({ ...prev, districts }));
      } else {
        setLocations((prev) => ({
          ...prev,
          taxOfficeDistricts: taxOffices || [],
        }));
      }
    } catch (error) {
      console.error(
        `Error fetching ${
          type === "city" ? "districts" : "tax office districts"
        }:`,
        error
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      landline: "",
      password: "",
      companyName: "",
      city: "",
      district: "",
      taxOfficeCity: "",
      taxOffice: "",
      taxNumber: "",
      permissionEmail: false,
      readKVKK: false,
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async (values) => {
      const cleanedPhoneNumber = values.phoneNumber.replace(/\D/g, "");
      const cleanedLandline = values.landline.replace(/\D/g, "");
      const userData = {
        name: values.firstName,
        surname: values.lastName,
        email: values.email,
        phone: cleanedPhoneNumber,
        land_phone: cleanedLandline,
        password: values.password,
        city: values.city,
        district: values.district,
        tax_dep_city: values.taxOfficeCity,
        tax_dep: values.taxOffice,
        tax_no: values.taxNumber,
        company_name: values.companyName,
        referral_code: "", // referral_code if needed, otherwise leave it as an empty string
      };

      //console.log(userData);

      try {
        const phone = formik.values.landline;

        //console.log("Girilen Telefon Numarası:", phone);

        if (phone.startsWith("0 (5")) {
          Swal.fire({
            title: "Uyarı",
            text: "Sabit telefon numarası giriniz",
            icon: "warning",
            confirmButtonText: "Tamam",
          });
          return;
        }

        const response = await RegisterServices.registerCompany(userData);
        setCookie(CookieName.CompanyUserToken, response.data.token, {
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30),
        });

        console.log(response);

        navigate("/isveren");
        if (response?.data?.company) {
          setCookie(
            CookieName.CompanyUserData,
            JSON.stringify(response?.data?.company),
            {
              expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30),
            }
          );
        }
      } catch (error) {
        let errorMessage = "Kayıt sırasında bir hata oluştu.";

        if (error instanceof AxiosError && error.response) {
          const responseData = error.response.data as {
            data: any;
            status: boolean;
            status_code: number;
            errors?: Record<string, string[]>;
          };
          if (responseData.errors) {
            if (responseData.errors.email) {
              formik.setFieldError(
                "email",
                "Bu e-posta adresi zaten kullanılmaktadır."
              );
            }
            if (responseData.errors.tax_no) {
              formik.setFieldError(
                "taxNumber",
                "Bu vergi numarası zaten kullanılmaktadır."
              );
            }
            const errorMessages = Object.values(responseData.errors)
              .flat()
              .join(" ");
            errorMessage = errorMessages;
            console.log("Error messages:", errorMessages);
          }
        }

        const htmlErrorMessage = errorMessage.split(". ").join(".<br>");
        console.log(htmlErrorMessage);
        Swal.fire({
          title: "Hata",
          html: htmlErrorMessage,

          icon: "error",
          confirmButtonText: "Tamam",
        });
      }
    },
  });

  React.useEffect(() => {
    if (formik.values.city) {
      fetchDistricts(formik.values.city, "city");
      formik.setFieldValue("district", "");
    }
  }, [formik.values.city]);

  React.useEffect(() => {
    if (formik.values.taxOfficeCity) {
      fetchDistricts(formik.values.taxOfficeCity, "taxOfficeCity");
    }
  }, [formik.values.taxOfficeCity]);

  useEffect(() => {
    const firstNameUpper = handleUpperCase(formik.values.firstName);
    formik.setFieldValue("firstName", firstNameUpper);
  }, [formik.values.firstName]);

  useEffect(() => {
    const lastNameUpper = handleUpperCase(formik.values.lastName);
    formik.setFieldValue("lastName", lastNameUpper);
  }, [formik.values.lastName]);

  useEffect(() => {
    const companyAllNameUpper = handleAllUpperCase(formik.values.companyName);
    formik.setFieldValue("companyName", companyAllNameUpper);
  }, [formik.values.companyName]);

  // tüm harfleri büyük yapma

  const handleAllUpperCase = (value: string): string => {
    if (value.length == 0) {
      return "";
    }

    if (value.toLocaleUpperCase("tr-TR") != value) {
      const valueArray = value.split("");
      const newValueArray = valueArray.map((v) => v.toLocaleUpperCase("tr-TR"));
      const newValue = newValueArray.join("");
      return newValue;
    } else {
      return value;
    }
  };

  // büyük harf kontrolü

  const handleUpperCase = (value: string): string => {
    if (value.length == 0) {
      return "";
    }

    const wordsArray = value.split(" ");
    const newWordsArray = wordsArray.map((word) => {
      if (word.length == 0) {
        return word;
      }

      return word[0].toLocaleUpperCase("tr-TR") + word.slice(1);
    });

    const newValue = newWordsArray.join(" ");

    return newValue;
  };

  const citiesFilter = locations.cities.filter(
    (el: { value: string }) => el.value !== "7795" && el.value !== "83"
  );

  useEffect(() => {
    console.log(formik.values);
  }, [formik.values]);

  // boş alan kontrolü

  const handleAlert = () => {
    const fields: string[] = [];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formik.values.firstName) {
      fields.push("Ad");
      formik.setFieldTouched("firstName", true, true);
    }
    if (!formik.values.lastName) {
      fields.push("Soyad");
      formik.setFieldTouched("lastName", true, true);
    }
    if (!formik.values.email) {
      fields.push("E-mail");
      formik.setFieldTouched("email", true, true);
    }
    if (
      !formik.values.phoneNumber ||
      trim(formik.values.phoneNumber, "_").length < 15
    ) {
      fields.push("Cep Telefon");
      formik.setFieldTouched("phoneNumber", true, true);
    }
    if (
      !formik.values.landline ||
      trim(formik.values.landline, "_").length < 15
    ) {
      fields.push("Sabit Telefon");
      formik.setFieldTouched("landline", true, true);
    }
    if (!formik.values.password) {
      fields.push("Şifre");
      formik.setFieldTouched("password", true, true);
    }
    if (formik.values.password.length < 8) {
      // if alread "Şifre" then dont push again
      if (!fields.includes("Şifre")) {
        fields.push("Şifre (En az 8 karakter olmalıdır)");
        formik.setFieldTouched("password", true, true);
      }
    }
    if (!formik.values.companyName) {
      fields.push("Şirket Adı");
      formik.setFieldTouched("companyName", true, true);
    }
    if (!formik.values.city) {
      fields.push("İl");
      formik.setFieldTouched("city", true, true);
    }
    if (!formik.values.district) {
      fields.push("İlçe");
      formik.setFieldTouched("district", true, true);
    }
    if (!formik.values.taxOfficeCity) {
      fields.push("Vergi Dairesi İli");
      formik.setFieldTouched("taxOfficeCity", true, true);
    }
    if (!formik.values.taxOffice) {
      fields.push("Vergi Dairesi");
      formik.setFieldTouched("taxOffice", true, true);
    }
    if (!formik.values.taxNumber || formik.values.taxNumber.length < 10) {
      fields.push("Vergi Numarası");
      formik.setFieldTouched("taxNumber", true, true);
    }
    if (!formik.values.readKVKK) {
      fields.push("Açık Rıza Metni");
      formik.setFieldTouched("readKVKK", true, true);
    }

    console.log(fields);

    if (fields.length > 0) {
      setMissingFields(fields);
      Swal.fire({
        title: "Uyarı!",
        html:
          "Eksik alanlar: <br/><strong style='color: red; font-weight:400;'>" +
          fields.join("<br/>") +
          "</strong>",
        icon: "warning",
        showConfirmButton: true,
        confirmButtonText: "Tamam",
        timer: 5000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      return;
    }

    if (
      !emailRegex.test(formik.values.email) ||
      containsTurkishChars(formik.values.email)
    ) {
      Swal.fire({
        title: "Hata",
        text: "Geçerli bir E-mail adresi giriniz",
        icon: "error",
        confirmButtonText: "Tamam",
      });
      return;
    }

    formik.handleSubmit();
  };

  return (
    <div className="flex flex-col w-full h-auto px-4 md:px-8 py-8 mx-auto bg-white rounded-md shadow-md gap-y-3 md:mx-0">
      <div>
        {capsLockWarning && (
          <div className="capslock-alert text-white bg-[#ffaf2a] m-2 p-2 rounded-md">
            <b>Caps-Lock açık!</b> Şifrelerin büyük-küçük harfe duyarlı olduğunu
            unutmayın
          </div>
        )}
      </div>
      <div className="flex flex-col w-full gap-y-2">
        <h1 className="poppins-medium text-base text-[#2253af]">
          İşveren Üye Ol
        </h1>
        <p className="text-[15px] poppins-semibold text-[#63636c]">
          Zaten üye misin?{" "}
          <a
            href="/isveren/giris-yap"
            className="text-[#ff7f1d] poppins-semibold text-[15px]"
          >
            Giriş Yap.
          </a>
        </p>
      </div>
      <form
        className="flex flex-col gap-y-4"
        onSubmit={(e) => {
          e.preventDefault();
          handleAlert();
        }}
        autoComplete="off"
      >
        <input hidden autoComplete="off" />
        <div className="flex flex-col lg:flex-row gap-3 w-full gap-x-6">
          <div className="flex flex-col w-full gap-y-1">
            <CustomFormField
              type="text"
              label="Adınız *"
              formik={formik}
              name="firstName"
            />
          </div>
          <div className="flex flex-col w-full gap-y-1">
            <CustomFormField
              type="text"
              label="Soyadınız *"
              formik={formik}
              name="lastName"
            />
          </div>
        </div>
        <CustomFormField
          type="text"
          label="E-posta Adresiniz *"
          formik={formik}
          name="email"
        />
        <CustomFormField
          type="tel"
          label="Telefon Numaranız *"
          formik={formik}
          name="phoneNumber"
          phoneMaskType="phone"
        />
        <CustomFormField
          type="tel"
          label="Sabit Telefon Numaranız *"
          formik={formik}
          name="landline"
          phoneMaskType="landline"
        />
        <CustomFormField
          type="password"
          label="Şifre *"
          formik={formik}
          name="password"
        />
        <CustomFormField
          type="text"
          label="Şirket Adı *"
          formik={formik}
          name="companyName"
        />
        <div className="flex flex-col w-full gap-4 lg:flex-row gap-x-6">
          <div className="flex flex-col w-full gap-y-1">
            <CustomFormField
              required
              type="select"
              label="İl Seçiniz *"
              formik={formik}
              name="city"
              options={citiesFilter}
            />
          </div>
          <div className="flex flex-col w-full gap-y-1">
            <CustomFormField
              onClick={() => {
                if (!formik.values.city) {
                  Swal.fire({
                    title: "Uyarı",
                    text: "Lütfen Önce İl Seçiniz",
                    icon: "error",
                    confirmButtonText: "tamam",
                    confirmButtonColor: "#195EDD",
                  });
                }
              }}
              type="select"
              label="İlçe Seçiniz *"
              formik={formik}
              name="district"
              options={locations.districts}
            />
          </div>
        </div>
        <div className="flex flex-col w-full gap-4 lg:flex-row gap-x-6">
          <div className="flex flex-col w-full gap-y-1">
            <CustomFormField
              type="select"
              label="Vergi Dairesi İli *"
              formik={formik}
              name="taxOfficeCity"
              options={citiesFilter}
            />
          </div>
          <div className="flex flex-col w-full gap-y-1">
            <CustomFormField
              type="select"
              label="Vergi Dairesi *"
              formik={formik}
              name="taxOffice"
              options={locations.taxOfficeDistricts}
            />
          </div>
        </div>
        <CustomFormField
          type="TCorvergi"
          label="Vergi No (Şahıs firmaları için TC Kimlik No) *"
          formik={formik}
          name="taxNumber"
          phoneMaskType="taxNumber"
        />

        <div className="flex flex-col gap-y-4">
          <span className="text-sm text-black poppins-semibold">
            Firma tam unvanı, vergi dairesi, vergi numarası ve adres bilginizi
            eksiksiz ve doğru olarak giriniz.
          </span>
          <div className="flex flex-wrap items-center gap-y-4">
            <CustomFormField
              type="checkbox"
              label={
                <span className={"text-xs text-gray-600"}>
                  İletişim bilgilerime e-ileti gönderilmesine izin veriyorum.
                </span>
              }
              formik={formik}
              name="permissionEmail"
            />
            <CustomFormField
              type="checkbox"
              label={
                <Link
                  to="/kurumsal/kvkk-metni-8"
                  target={"_blank"}
                  className={
                    "text-xs text-gray-600  hover:underline underline-offset-4"
                  }
                >
                  Kişisel verilerimin işlenmesine ait{" "}
                  <span className="text-xs text-red-500 cursor-pointer poppins-medium">
                    KVKK Aydınlatma Metni&apos;ni
                  </span>{" "}
                  okudum.
                </Link>
              }
              formik={formik}
              name="readKVKK"
            />
          </div>
          <div className="flex flex-col items-center justify-between w-full">
            <button
              onClick={(e) => {
                e.preventDefault();
                handleAlert();
              }}
              type="submit"
              className="w-full bg-[#195edd] flex rounded-sm items-center justify-center h-10"
            >
              <span className="text-lg text-white poppins-medium w-full h-full flex items-center justify-center cursor-pointer">
                ÜYE OL
              </span>
            </button>
            <a
              href="/profilim/giris-yap"
              className="text-lg text-[#2253af] poppins-medium text-center hover:underline cursor-pointer mt-4"
            >
              İş Arayan mısınız?
            </a>
          </div>
        </div>
      </form>
    </div>
  );
}

export { RegisterCompany };
