import { Fragment, useEffect, useState } from "react";
import Card from "../setting-page/content/Card";
import { BsSearch } from "react-icons/bs";
import CustomTable from "../../../components/common/custom-table";
import ActionsPopover from "./components/ActionsPopover";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFolders,
  getArchivedFolders,
  getNotArchivedFolders,
  searchFolder,
} from "../../../store/features/companyFoldersSlice";
import CreateModal from "./components/CreateModal";
import { useFormatDate } from "../../../hooks/useFormatDate";
import { CgCornerDownRight, CgDanger, CgTrash } from "react-icons/cg";
import Swal from "sweetalert2";
import { FolderCard } from "./components/FolderCard";
type Props = {};

export type Folder = {
  title: string;
  company_folder_cvs_count: number;
  id: number;
  created_at: string;
  is_archived: boolean;
};
export default function FoldersPage(props: Props) {
  const dispatch = useDispatch();
  const { folders, filteredFolders } = useSelector(
    (state: any) => state.companyFolders
  );
  const [tab, setTab] = useState("folders");
  const [selectedFolders, setSelectedFolders] = useState<Folder[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (tab === "folders") {
        await dispatch(getNotArchivedFolders());
      } else {
        await dispatch(getArchivedFolders());
      }
      setIsLoading(false);
    };

    fetchData();
  }, [tab, dispatch]);

  console.log("folders", folders);

  return (
    <div className="container mx-auto font-poppins min-h-screen">
      <div className="flex flex-col gap-3 my-3">
        <Card className="flex justify-between !py-2">
          <h1 className="text-[24px] font-semibold">Klasörler</h1>
          <CreateModal />
        </Card>
        <Card>
          {folders && folders?.length > 0 && (
            <div className="flex justify-end mb-3">
              <div className="relative max-w-[375px] w-full">
                <BsSearch className="text-gray-500 text-[16px] absolute end-3 top-3" />
                <input
                  type="text"
                  placeholder="Klasörlerde Ara"
                  className="ps-4 py-4 w-full focus-visible:outline-none placeholder:text-xs focus:border-title transition-all h-[40px] text-sm rounded-md border-2 border-gray-300 "
                  onChange={async (e: any) => {
                    await dispatch<any>(searchFolder(e.target.value));
                  }}
                />
              </div>
            </div>
          )}

          <div className="flex justify-center gap-3 mb-4">
            <button
              className={`bg-white  border-title font-bold transition-all ${
                tab === "folders" ? "text-title border-b-2" : ""
              }`}
              onClick={() => {
                setTab("folders");
                // dispatch(getNotArchivedFolders());
              }}
            >
              Klasörler
            </button>
            <button
              className={`bg-white  border-title font-bold transition-all ${
                tab === "archive" ? "text-title border-b-2" : ""
              }`}
              onClick={() => {
                setTab("archive");
                // dispatch(getArchivedFolders());
              }}
            >
              Arşiv
            </button>
          </div>

          {/* Table View */}
          <div className="hidden md:block ">
            {folders && folders?.length > 0 ? (
              <div className="max-w-full min-h-[400px] mb-10">
                <CustomTable
                  title=""
                  data={filteredFolders ? filteredFolders : folders}
                  columns={[
                    {
                      title: (
                        <input
                          type="checkbox"
                          className="my-auto accent-title"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedFolders(folders);
                            } else {
                              setSelectedFolders([]);
                            }
                          }}
                        />
                      ),
                      content: (item: any) => (
                        <input
                          type="checkbox"
                          className="my-auto accent-title"
                          checked={selectedFolders.includes(item)}
                          onChange={() => {
                            if (selectedFolders.includes(item)) {
                              setSelectedFolders(
                                selectedFolders.filter((f) => f !== item)
                              );
                            } else {
                              setSelectedFolders([...selectedFolders, item]);
                            }
                          }}
                        />
                      ),
                    },
                    {
                      title: "Klasör Adı",
                      key: "title",
                      dataIndex: "title",
                    },
                    {
                      title: "Aday Sayısı",
                      key: "company_folder_cvs_count",
                      dataIndex: "company_folder_cvs_count",
                    },
                    {
                      title: "Oluşturulma Tarİhİ",
                      key: "created_at",
                      dataIndex: "created_at",
                      content: (item: any) =>
                        // eslint-disable-next-line react-hooks/rules-of-hooks
                        useFormatDate(item.created_at.toString()),
                    },
                    {
                      title: "İşlemler",
                      content: (item: any) => (
                        <div>
                          <ActionsPopover item={item} />
                        </div>
                      ),
                    },
                  ]}
                />
                {selectedFolders?.length ? (
                  <div className="flex items-center justify-start gap-3 my-3">
                    <CgCornerDownRight className="text-3xl" />
                    <button
                      className="flex items-center justify-center gap-3 px-4 py-3 text-sm text-red-600 border-2 border-red-600 rounded-lg"
                      onClick={() => {
                        Swal.fire({
                          icon: "warning",
                          title: "İşlemi onaylayın",
                          text: "Seçili klasörleri silmek istediğinize emin misiniz?",
                          confirmButtonColor: "#2253af",
                          confirmButtonText: "Evet",
                          cancelButtonText: "Hayır",
                          showCancelButton: true,
                          showCloseButton: true,
                          preConfirm: async () => {
                            await dispatch(
                              deleteFolders(selectedFolders.map((f) => f.id))
                            );
                            if (selectedFolders[0].is_archived) {
                              await dispatch(getArchivedFolders());
                            } else {
                              await dispatch(getNotArchivedFolders());
                            }
                            Swal.fire({
                              icon: "success",
                              title: "Klasörler Silindi!",
                              timer: 3000,
                            });
                          },
                        });
                      }}
                    >
                      <CgTrash className="text-xl" />
                      <span className="ml-2">Seçilen Klasörleri Sil</span>
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div className="flex items-center justify-start border border-gray-300 rounded-lg shadow-lg">
                <div className="p-2 mr-5 bg-red-500 rounded-l">
                  <CgDanger size={41} color="#fff" />
                </div>
                <div className="text-gray-500">
                  {tab === "archive"
                    ? "Arşivlenmiş klasör bulunamadı!"
                    : "Kayıtlı klasör bulunamadı!"}
                </div>
              </div>
            )}
          </div>

          {/* List View for mobile */}
          <div className="md:hidden">
            {folders && folders.length > 0 ? (
              folders.map((folder: Folder) => (
                <FolderCard key={folder.id} folder={folder} />
              ))
            ) : (
              <div className="flex items-center justify-start border border-gray-300 rounded-lg shadow-lg">
                <div className="p-2 mr-5 bg-red-500 rounded-l">
                  <CgDanger size={41} color="#fff" />
                </div>
                <div className="text-gray-500">
                  {tab === "archive"
                    ? "Arşivlenmiş klasör bulunamadı!"
                    : "Kayıtlı klasör bulunamadı!"}
                </div>
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
}
