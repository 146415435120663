/* const SvalueeRights = [ 
    {value:"1",label:"Yol"},
    {value:"2",label:"Yemek"},
    {value:"3",label:"SGK"},
    {value:"4",label:"Özel Sigorta"},
    {value:"5",label:"Prim"},
    {value:"6",label:"Özel Servis"},
] */

const SvalueeRights = [
    { value: '1', label: 'Yemek Kartı' },
    { value: '2', label: 'Özel Servis' },
    { value: '3', label: 'Özel Sağlık Sigortası' },
    { value: '4', label: 'Yemek' },
    { value: '5', label: 'Yol Yardımı' },
    { value: '6', label: 'Eğitim Yardımı' },
    { value: '7', label: 'Yabancı Dil Desteği' },
    { value: '8', label: 'Maaş Kartı' },
    { value: '9', label: 'Yemek' },
    { value: '10', label: 'SGK' },
    { value: '11', label: 'Araç' },
    { value: '12', label: 'Telefon' },
    { value: '13', label: 'Bilgisayar' }
]
export default SvalueeRights