import * as React from "react";
import { useContext } from "react";
import { AccordionContent } from "./content-section";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { AccordionContentMobile } from "./content-section-mobile";
import { ApplyPoolContext } from "../index";
import { TriggerSection } from "./trigger-section";

const FilterSection: React.FC = () => {
  const { isMobile } = useIsMobile();

  const { detailFiltersOpen } = useContext(ApplyPoolContext);

  return (
    <section className="md:mx-10 mb-2 text-primary200 ">
      <TriggerSection />

      <div className={`${!isMobile && detailFiltersOpen ? "" : "hidden"}`}>
        <AccordionContent isApplyPage={true} />
      </div>
    </section>
  );
};

export { FilterSection };
