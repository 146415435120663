const Banner = () => {
  return (
    <div className=" bg-cv-image bg-cover h-auto py-[100px]">
      <div className=" flex items-center justify-center">
        <div className="flex flex-col items-center justify-center w-[60%] font-medium">
          <h2 className="text-4xl text-[#2253af] text-center mt-4">
            Sana özel CV örneklerine göre en doğru CV’yi hemen oluştur
          </h2>
          <p className="text-center text-lg mt-2 text-[#333]">
            isbul.net'e üye ol ve 72.000’den fazla iş ilanı arasından sana en
            uygun ilana hemen başvur!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
