import * as React from "react";

interface ICustomButtonProps {
  readonly text: string;
  readonly className?: string;
  readonly onClick?: () => void;
}

function CustomButton(props: ICustomButtonProps): React.ReactElement {
  return (
    <button
      className={`flex items-center justify-center ${props.className}`}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
}
export { CustomButton };
