import React from "react";
import LoginCompany from "./company/login/index"; // Eğer Login bileşeni kullanılacaksa bu kısmı güncelleyin.
import LoginProfile from "./profile/login/index";
import { RegisterCompany } from "./company/register";
import RegisterProfile from "./profile/register";

interface IRightSideProps {
	readonly type: string;
	readonly categories: string;
}

function RightSide({ type, categories }: IRightSideProps): React.ReactElement {
	const contentMap: { [key: string]: { [key: string]: React.ReactElement } } = {
		login: {
			profile: (
				<div>
					<LoginProfile />
				</div>
			),
			company: (
				<div>
					<LoginCompany showLeftSide={false} />
				</div>
			),
		},
		register: {
			profile: (
				<div>
					<RegisterProfile />
				</div>
			),
			company: (
				<div>
					<RegisterCompany />
				</div>
			),
		},
	};

	const content = contentMap[type]?.[categories] || null;

	return <div className='w-full'>{content}</div>;
}

export { RightSide };
