import { createContext, Dispatch, HTMLAttributes, SetStateAction } from "react";
import RadioButton from "./RadioButton";
import Error from "./Error";
import Label from "./Label";

type RadioButtonPropsType = HTMLAttributes<HTMLDivElement> & {
	value: string;
	setValue: Dispatch<SetStateAction<string>>;
	error?: boolean;
	required?: boolean;
};

export type RadioButtonContextType = {
	value: string;
	setValue: Dispatch<SetStateAction<string>>;
	error?: boolean;
	required?: boolean;
};

export const RadioButtonContext = createContext<RadioButtonContextType>({
	value: "",
	setValue: () => {},
	error: false,
	required: false,
});

const RadioButtonField = ({
	className,
	value,
	setValue,
	error = false,
	required = false,
	...props
}: RadioButtonPropsType) => {
	const contextValue: RadioButtonContextType = {
		value,
		setValue,
		error,
		required,
	};

	return (

		/* TODO: Burada içerik direkt sağdan sola sıralı geliyor label ve buttonların col olması sağlanacak */
		<RadioButtonContext.Provider value={contextValue}>
			<div
				{...props}
				className={`${className} flex flex-col gap-1`}>
				{props.children}
			</div>
		</RadioButtonContext.Provider>
	);
};

RadioButtonField.RadioButton = RadioButton;
RadioButtonField.Error = Error;
RadioButtonField.Label = Label;

export default RadioButtonField;
