import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import AxiosService from "../../../../../services/axios";
import { IconChevronsLeft, IconChevronsRight } from "@tabler/icons-react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";

interface Position {
  title: string;
}

const Featured: React.FC = () => {
  const [adverts, setAdverts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const axiosService = new AxiosService();
        const response = await axiosService.request({
          type: "get",
          endpoint: `home/get-cuff-adverts`,
          payload: "",
        });

        console.log(response);
        if (response.data) {
          setAdverts(response.data.adverts);
        } else return;
        setLoading(false);
      } catch (error) {
        console.error("Error fetching adverts:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const truncate = (text: string, length: number) => {

    return text?.length > length ? text.slice(0, length) + "..." : text;

  };

  const NextArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className="nextArrow" onClick={onClick}>
        <MdKeyboardArrowRight className="text-blue-600" size={44} />
      </div>
    );
  };

  const PrevArrow = (props: any) => {
    const { onClick } = props;
    return (
      <div className="prevArrow" onClick={onClick}>
        <MdKeyboardArrowLeft className="text-blue-600" size={44} />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    appendDots: (dots: React.ReactNode) => (
      <div>
        <ul className="custom-dots">{dots}</ul>
      </div>
    ),
  };

  return (
    <div className="select-none lg:mx-auto lg:flex px-4 lg:px-0 justify-center w-full h-auto">
      <div className="container w-full h-auto pt-2 mx-auto md:pt-8">
        <div className="slider-container mb-10">
          <Slider {...settings}>
            {adverts.slice(0, 15).map((advert, index) => (
              <div key={index} className="p-2 group">
                <a
                  href={`/is-ilanlari/${advert?.slug || ""}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-col items-center justify-between h-full p-4 py-10 transition-all bg-white group-hover:shadow-lg shadow-sm border rounded-xl duration-300"
                >
                  <div className="flex justify-center items-center h-24">
                    <img
                      src={advert.company_logo}
                      alt="ftd"
                      className="object-contain max-h-full max-w-[150px]"
                    />
                  </div>
                  <div className="flex flex-col items-center mt-2">
                    <div className="text-sm font-bold text-center line-clamp-1 lg:text-base ">
                      {truncate(advert.title, 15)}
                    </div>
                    <div className="text-xs font-semibold text-center text-gray-500 line-clamp-1 lg:text-sm ">
                      {advert.positions.map(
                        (position: Position, posIndex: number) => (
                          <div key={posIndex}>
                            {truncate(position.title, 20)}
                          </div>
                        )
                      )}
                    </div>
                    <div className="hidden pt-2 text-sm text-center border-t lg:block ">
                      <span className="line-clamp-1">
                        {advert.company_name.length > 16
                          ? `${advert.company_name
                              .slice(0, 16)
                              .toUpperCase()}...`
                          : advert.company_name.toUpperCase()}
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Featured;
