import React, { useState, useEffect } from "react";
import { FiEye } from "react-icons/fi";
import { TbCashBanknote } from "react-icons/tb";
import { BsListCheck } from "react-icons/bs";
import { FaRegCircleQuestion } from "react-icons/fa6";
import Overview from "./overview/index";
import SalariesSection from "./salaries/index";
import WhySection from "./why/index";
import Axios from "../../../../services/axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
interface Params {
  slug?: string;
}

interface Position {
  title: string;
  slug: string;
  min_salary: number;
  max_salary: number;
  detail: string;
  avg_salary: number;
  calculate_salary: number;
  calculate_university: number;
  created_at: string;
  order: number;
}

const SearchSection: React.FC = () => {
  const axios = new Axios();
  const { slug }: Params = useParams();
  const [selectedLink, setSelectedLink] = useState("GENEL BAKIŞ");
  const [positionData, setPositionData] = useState<Position[]>([]);
  console.log(slug);

  const links = [
    { name: "GENEL BAKIŞ", icon: <FiEye className="text-lg sm:text-xl" /> },
    {
      name: "MAAŞLAR",
      icon: <TbCashBanknote className="text-lg sm:text-xl" />,
    },
    {
      name: "NEDİR",
      icon: <FaRegCircleQuestion className="text-lg sm:text-xl" />,
    },
    {
      name: "İŞ İLANLARI (0)",
      icon: <BsListCheck className="text-lg sm:text-xl" />,
    },
  ];
  console.log(slug)
  console.log(selectedLink)
  console.log(positionData)
  

  async function getPosition() {
    try {
      const response = await axios.request({
        endpoint: `home/positions/${slug}`,
        type: "get",
      });

      console.log(response);
      setPositionData(response?.data?.position_dictionary);
    } catch (error) {
      console.log(`Positions fetch failed. Error:${error}`);
    }
  }

  useEffect(() => {
    getPosition();
  }, []);

  const handleSelectLink = (linkName: string) => {
    setSelectedLink(linkName);
  };

  console.log(positionData);

  return (
    <div className="font-poppins">
      <div className="flex justify-center bg-[#FFFFFF99] shadow-md mb-2">
        <div className="flex flex-wrap w-full lg:w-3/4">
          <div className="w-full space-y-4 px-4 py-6">
            <h1 className="text-xl sm:text-2xl font-bold text-[#2253af]">
              {positionData[0]?.title}
            </h1>
          </div>
        </div>
      </div>

      <div className="flex justify-center bg-white shadow-md">
        <div className="flex flex-wrap w-full lg:w-3/4">
          <div className="w-full space-y-4 px-4 py-6 flex flex-col sm:flex-row sm:items-center justify-between">
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6 lg:space-x-16">
              {links.map(({ name, icon }) => (
                <div
                  key={name}
                  onClick={() => handleSelectLink(name)}
                  className={`relative flex items-center cursor-pointer space-x-2 text-lg sm:text-xl md:text-xl font-medium link-hover-underline ${
                    selectedLink === name
                      ? "selected text-[#2253af]"
                      : "text-[#63636c]"
                  } hover:text-[#2253af]`}
                >
                  {icon}
                  <span>{name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {selectedLink === "GENEL BAKIŞ" && (
        <Overview positionData={positionData} />
      )}
      {selectedLink === "MAAŞLAR" && (
        <SalariesSection positionData={positionData} />
      )}
      {selectedLink === "NEDİR" && <WhySection positionData={positionData} />}
      {selectedLink === "İŞ İLANLARI (0)" && <div className="container mx-auto py-4 px-24">Şu anda iş ilanı bulunmamaktadır.</div>}

    </div>
  );
};

export default SearchSection;
