import { PiDotsThreeVertical } from "react-icons/pi";
import { cvType, fileType } from "..";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import Axios from "../../../../../../services/axios";
import Swal from "sweetalert2";

const axios = new Axios();

export default function FileItem({
  file,
  setSelectedFile,
  setIsModalOpen,
  cvs,
  getAllDatas,
  isEnglish = false,
}: {
  file: fileType;
  setSelectedFile: Dispatch<SetStateAction<fileType | undefined>>;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  cvs: cvType[];
  getAllDatas: () => void;
  isEnglish?: boolean;
}) {
  const divRef = useRef<HTMLDivElement>(null);
  const mobileDivRef = useRef<HTMLDivElement>(null);

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isMobilePopupOpen, setIsMobilePopupOpen] = useState<boolean>(false);

  // Desktop contains
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setIsPopupOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [divRef]);

  // Mobile contains
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        mobileDivRef.current &&
        !mobileDivRef.current.contains(event.target as Node)
      ) {
        setIsMobilePopupOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [mobileDivRef]);

  const truncate = (str: string, n: number) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <>
      {/* Desktop section */}
      <div className="h-auto w-full px-3 md:px-4 py-3 my-1 hidden lg:flex border-b justify-between items-center hover:bg-[#ebebeb] duration-100">
        <div className="flex items-center col-span-2 basis-4/12">
          <a href={file.path}>
            <span className="pr-10 text-xs text-gray-600 underline truncate poppins-semibold">
              {truncate(file.filename, 20)}
            </span>
          </a>
        </div>

        <span className="pr-6 text-xs text-gray-600 truncate poppins-semibold basis-3/12">
          {file.description}
        </span>

        <span className="pr-2 text-xs text-gray-600 truncate poppins-semibold basis-3/12">
          {cvs.find((c) => c.id == file.cv_id)?.title}
        </span>

        <div
          className="relative flex items-center justify-end col-span-1 gap-x-2 basis-2/12"
          ref={divRef}
        >
          <span
            className="text-xl rounded cursor-pointer focus:bg-gray-200 hover:bg-gray-300"
            onClick={() => {
              setIsPopupOpen(true);
            }}
          >
            <PiDotsThreeVertical className="text-gray-900" size={32} />
          </span>
          {isPopupOpen && (
            <div className="absolute z-10 flex flex-col items-center justify-center p-2 text-xs border rounded shadow text-primary100 font-poppins -right-1 top-8 bg-gray-50 ">
              <button
                type="button"
                className="w-32 px-1 py-2 mb-1 text-left transition-all duration-300 ease-in-out bg-white border rounded hover:bg-primary100 hover:text-white"
                onClick={() => {
                  setSelectedFile((prev) => file);
                  setIsModalOpen(true);
                  setIsPopupOpen(false);
                }}
              >
                {isEnglish ? "Edit" : "Düzenle"}
              </button>
              <button
                className="w-32 px-1 py-2 mb-1 text-left transition-all duration-300 ease-in-out bg-white border rounded hover:bg-primary100 hover:text-white"
                onClick={() => {
                  Swal.fire({
                    icon: 'question',
                    title: 'Uyarı',
                    text: 'Dosyayı silmek istediğinize emin misiniz?',
                    confirmButtonText: 'Evet',
                    cancelButtonText: 'Hayır',
                    showCancelButton: true,
                    preConfirm: () => {
                      deleteFile()
                    }
                  })
                }}
              >
                {isEnglish ? "Delete" : "Kaldır"}
              </button>
            </div>
          )}
        </div>
      </div>

      {/* Mobile section */}
      <div className="flex lg:hidden flex-col w-full gap-2 p-4 bg-white border border-gray-200 text-sm my-1 rounded-md shadow-lg">
        <div className="flex flex-row items-center justify-between">
          <label className="text-mains font-poppins font-semibold">
            {file.filename}
          </label>
          <div
            className="relative flex items-center justify-end col-span-1 gap-x-2 basis-2/12"
            //ref={divRef}
            ref={mobileDivRef}
          >
            <span
              className="text-xl rounded cursor-pointer focus:bg-gray-200 hover:bg-gray-300"
              onClick={() => {
                setIsMobilePopupOpen(true);
              }}
            >
              <PiDotsThreeVertical className="text-gray-900" size={32} />
            </span>
            {isMobilePopupOpen && (
              <div className="absolute z-10 flex flex-col items-center justify-center p-2 text-xs border rounded shadow text-primary100 font-poppins -right-1 top-8 bg-gray-50 ">
                <button
                  className="w-32 px-1 py-2 mb-1 text-left transition-all duration-300 ease-in-out bg-white border rounded hover:bg-primary100 hover:text-white"
                  onClick={() => {
                    setSelectedFile((prev) => file);
                    setIsModalOpen(true);
                    //setIsPopupOpen(false)
                  }}
                >
                  {isEnglish ? "Edit" : "Düzenle"}
                </button>
                <button
                  className="w-32 px-1 py-2 mb-1 text-left transition-all duration-300 ease-in-out bg-white border rounded hover:bg-primary100 hover:text-white"
                  onClick={() => {
                    Swal.fire({
                      icon: 'question',
                      title: 'Uyarı',
                      text: 'Dosyayı silmek istediğinize emin misiniz?',
                      confirmButtonText: 'Evet',
                      cancelButtonText: 'Hayır',
                      showCancelButton: true,
                      preConfirm: () => {
                        deleteFile()
                      }
                    })
                  }}
                >
                  {isEnglish ? "Delete" : "Kaldır"}
                </button>
              </div>
            )}
          </div>
        </div>
        <hr />
        <div className="flex flex-row gap-2 items-center justify-between p-1">
          <label className="font-poppins text-gray-600 flex-shrink-0">
            {isEnglish ? "File Type" : "Dosya Tipi"}
          </label>
          <label className="font-poppins text-gray-600 whitespace-nowrap text-ellipsis overflow-hidden">
            {file.format.toUpperCase()}
          </label>
        </div>
        <hr />
        <div className="flex flex-row gap-2 items-center justify-between p-1">
          <label className="font-poppins text-gray-600 flex-shrink-0">
            {isEnglish ? "Description" : "Açıklama"}
          </label>
          <label className="font-poppins text-gray-600 whitespace-nowrap text-ellipsis overflow-hidden">
            {file.description}
          </label>
        </div>
        <hr />
        <div className="flex flex-row gap-2 items-center justify-between p-1">
          <label className="font-poppins text-gray-600 flex-shrink-0">
            {isEnglish ? "Attached to CV" : "Bağlı Olduğu Özgeçmiş"}
          </label>
          <label className="font-poppins text-gray-600 whitespace-nowrap text-ellipsis overflow-hidden">
            {cvs.find((c) => c.id == file.cv_id)?.title}
          </label>
        </div>
        <hr />
        <div className="flex flex-row justify-end">
          <a href={file.path} className="font-poppins text-gray-600 underline">
            {isEnglish ? "Show" : "Görüntüle"}
          </a>
        </div>
      </div>
    </>
  );

  async function deleteFile() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/files/delete/${file.id}`,
        type: "delete",
        payload: {
          cv_id: file.cv_id,
        },
      });

      if (response.status == 200) {
        getAllDatas();
      } else {
        Swal.fire({
          title: "Hata",
          icon: "error",
          text: "Dosya silinirken bir hata meydana geldi.",
        });
      }
    } catch (error) {
      console.log(`File delete failed. Error: ${error}`);
    }

    setIsPopupOpen(false);
  }
}
