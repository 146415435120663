import { IconChevronDown } from "@tabler/icons-react";
import React, { useEffect, useRef, useState } from "react";

interface IAdvertSectionDropdown {
  value?: any;
  onChange?: (e: string) => void;
  options?: any[];
  isActive: boolean;
  setIsActive: React.Dispatch<boolean>;
  onClick: () => void;
}

const AdvertSectionDropdown: React.FC<IAdvertSectionDropdown> = (
  props: IAdvertSectionDropdown
) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(dropdownRef.current?.clientHeight || 0);
  }, [dropdownRef]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        props.setIsActive(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  return (
    <div
      onClick={(e: any) => {
        props.setIsActive(!props.isActive);
        props.onClick();
      }}
      ref={dropdownRef}
      className="flex items-center px-2 border border-gray-400 gap-x-2 relative rounded cursor-pointer"
    >
      <div className="poppins-regular text-[12px] text-gray-400 select-none">
        {props.options &&
          props.options.filter((option: any) => option.value === props.value)[0]
            .label}
      </div>
      <IconChevronDown
        size={20}
        className={`${
          props.isActive && "rotate-180"
        } transition-all text-gray-400`}
      />
      {props.isActive && (props.options?.length || 0) > 0 && (
        <div
          style={{ top: height + 1 }}
          className="absolute w-full bg-white z-10 left-0 rounded border border-gray-400 shadow-lg"
        >
          {props.options?.map((el, i) => (
            <div
              key={i}
              onClick={() => {
                props.onChange && props.onChange(el);
              }}
              className="w-full flex flex-col items-center"
            >
              <div
                className={`my-1 poppins-medium text-sm ${
                  props.value === el.value ? "text-mains" : "text-gray-500"
                }`}
              >
                {el?.label}
              </div>
              {(props.options?.length || 0) > i + 1 && (
                <div className="h-px w-full bg-gray-300" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdvertSectionDropdown;
