import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { CustomFormField } from "../../../../../components/common/custom-form-field";
import * as Yup from "yup";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Axios from "../../../../../services/axios";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  Button,
  InputArea,
  MultiSelectDropdown,
  MultiSelectSearchDropdown,
  SelectDropdown,
  Separator,
} from "../../../../../components/isbul-ui";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { useParams } from "react-router-dom";
import {
  cdnCityType,
  cdnExperienceLevelType,
  cdnPositionType,
  cdnSalaryExpectationType,
  cdnSectionType,
  cdnSectorType,
  cdnWorkingTypeType,
  dbUserJobPreferencesType,
} from "../../../../../types";
import { getCities } from "../../../../../customHooks/getCities";
import { getWorkingTypes } from "../../../../../customHooks/getWorkingTypes";
import { getSectors } from "../../../../../customHooks/getSectors";
import { getSections } from "../../../../../customHooks/getSections";
import { getPositions } from "../../../../../customHooks/getPositions";
import { getSalaryExpectations } from "../../../../../customHooks/getSalaryExpectation";
import { getExperienceLevels } from "../../../../../customHooks/getExperienceLevels";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useContext } from "react";
import { CvLayoutContext, cvLayoutContextType } from "..";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

type payloadDataType = {
  cv_id: number;
  job_title: string | null;
  work_status: number;
  experience_level_id: number;
  travel_status_ids: number[] | null;
  work_cities: number[] | null;
  work_types: number[] | null;
  sectors: number[] | null;
  sections: number[] | null;
  positions: number[] | null;
  salary_expectation_min_id: number | null;
  salary_expectation_max_id: number | null;
  information: string;
};

const axios = new Axios();

type workingStatusType = {
  id: number;
  title: string;
  title_en: string;
};

type travelStatusType = {
  id: number;
  title: string;
  title_en: string;
};

const workingStatus: workingStatusType[] = [
  { id: 0, title: "Çalışmıyor", title_en: "Not Working" },
  { id: 1, title: "Çalışıyor", title_en: "Working" },
];

const travelStatus: travelStatusType[] = [
  { id: 1, title: "Fark Etmez", title_en: "Does Not Matter" },
  { id: 2, title: "Seyahat Edemem", title_en: "Cannot Travel" },
  { id: 3, title: "Şehir İçi", title_en: "Within City" },
  { id: 4, title: "Şehir Dışı", title_en: "Outside City" },
  { id: 5, title: "Yurt İçi", title_en: "Domestic" },
  { id: 6, title: "Yurt Dışı", title_en: "Abroad" },
];

const JobPrefences: React.FC = () => {
  const { showCvScore, isEnglish }: cvLayoutContextType =
    useContext(CvLayoutContext);

  const params = useParams();

  const navigate = useNavigate();

  // General states
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const [experienceLevels, setExperienceLevels] = useState<
    cdnExperienceLevelType[]
  >([]);
  const [cities, setCities] = useState<cdnCityType[]>([]);
  const [filteredCities, setFilteredCities] = useState<cdnCityType[]>([]);
  const [workingTypes, setWorkingTypes] = useState<cdnWorkingTypeType[]>([]);
  const [sectors, setSectors] = useState<cdnSectorType[]>([]);
  const [filteredSectors, setFilteredSectors] = useState<cdnSectorType[]>([]);
  const [sections, setSections] = useState<cdnSectionType[]>([]);
  const [filteredSections, setFilteredSections] = useState<cdnSectionType[]>(
    []
  );
  const [positions, setPositions] = useState<cdnPositionType[]>([]);
  const [filteredPositions, setFilteredPostions] = useState<cdnPositionType[]>(
    []
  );
  const [salaryExpectations, setSalaryExpectations] = useState<
    cdnSalaryExpectationType[]
  >([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  // Search term states
  const [citySearchTerm, setCitySearchTerm] = useState<string>("");
  const [sectorSearchTerm, setSectorSearchTerm] = useState<string>("");
  const [sectionSearchTerm, setSectionSearchTerm] = useState<string>("");
  const [positionSearchTerm, setPositionSearchTerm] = useState<string>("");

  //States for payloadForm
  const [jobTitle, setJobTitle] = useState<string | null>("");
  const [selectedWorkingStatus, setSelectedWorkingStatus] =
    useState<workingStatusType | null>(null);
  const [selectedTravelStatus, setSelectedTravelStatus] =
    useState<travelStatusType | null>(null);
  const [selectedTravelStatuses, setSelectedTravelStatuses] = useState<
    travelStatusType[]
  >([]);
  const [selectedExperienceLevel, setSelectedExperienceLevel] =
    useState<cdnExperienceLevelType | null>(null);
  const [selectedCities, setSelectedCities] = useState<cdnCityType[]>([]);
  const [selectedWorkingTypes, setSelectedWorkingTypes] = useState<
    cdnWorkingTypeType[]
  >([]);
  const [selectedSectors, setSelectedSectors] = useState<cdnSectorType[]>([]);
  const [selectedSections, setSelectedSections] = useState<cdnSectionType[]>(
    []
  );
  const [selectedPositions, setSelectedPositions] = useState<cdnPositionType[]>(
    []
  );
  const [selectedSalaryExpectation, setSelectedSalaryExpectation] =
    useState<cdnSalaryExpectationType | null>(null);
  const [information, setInformation] = useState<string>("");

  const cvId = params.slug;
  async function getJobPreferences() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/pref/index?cv_id=${params.slug}`,
        type: "get",
        payload: {},
      });

      if (response && response.data && response.data.job_preference) {
        const pref: dbUserJobPreferencesType = response.data.job_preference;
        console.log("pref", pref);
        //console.log('pref', pref)
        setJobTitle(pref.job_title);

        if (pref.sections && pref.sections.length > 0) {
          // Get selected sections
          setSelectedSections(pref.section_list || []);
        }

        if (pref.positions && pref.positions.length > 0) {
          // Get selected positions
          setSelectedPositions(pref.position_list || []);
        }

        if (pref.work_cities && pref.work_cities.length > 0) {
          // Get working cities
          setSelectedCities(pref.city_list || []);
        }

        if (pref.sectors && pref.sectors.length > 0) {
          // Get sectors
          setSelectedSectors(pref.sector_list || []);
        }

        if (pref.work_types && pref.work_types.length > 0) {
          // Get working types
          setSelectedWorkingTypes(pref.work_type_list || []);
        }

        if (pref.travel_status_id) {
          // Travel status
          setSelectedTravelStatus(
            pref.travel_status_list
              ? {
                  id: pref.travel_status_list.id,
                  title: pref.travel_status_list.name,
                  title_en: pref.travel_status_list.name_en,
                }
              : null
          );
        }

        // FIXME: Buradan translate bekleniyor
        if (pref.cv_job_travel_statuses) {
          setSelectedTravelStatuses(
            pref.cv_job_travel_statuses.map((t) => {
              return {
                id: t.travel_status_id,
                title: t.name,
                title_en: t.name_en,
              };
            })
          );
        }

        console.log("pref", pref.cv_job_travel_statuses);

        // Work status
        const selectedWorkStatusData = workingStatus.find(
          (w) => w.id == pref.work_status
        );
        setSelectedWorkingStatus(
          selectedWorkStatusData ? selectedWorkStatusData : null
        );

        // Information
        setInformation(pref.information || "");

        if (pref.salary_expectation_min_id) {
          // Salary expectation
          setSelectedSalaryExpectation(
            pref.salary_expectation_min_list || null
          );
        }

        if (pref.experience_level_id) {
          // Experience level
          setSelectedExperienceLevel(pref.experience_level_list);
        }
      }
    } catch (error) {
      console.log(`Job preferences fetch failed. Error:${error}`);
    }
  }

  async function getInitialDatas() {
    // Experience level data
    const responseExperienceLevels = await axios.request({
      endpoint: "cdn/get-experience-levels?limit=30",
      type: "get",
    });
    if (
      responseExperienceLevels &&
      responseExperienceLevels.data &&
      responseExperienceLevels.data.experience_levels
    ) {
      setExperienceLevels(responseExperienceLevels.data.experience_levels);
    }

    // City data
    const cityDatas = await getCities({});
    setCities(cityDatas);
    setFilteredCities(cityDatas);

    // Working type data
    const workingTypeDatas = await getWorkingTypes({});
    setWorkingTypes(workingTypeDatas);

    // Sector data
    const sectorDatas = await getSectors({});
    setSectors(sectorDatas);
    setFilteredSectors(sectorDatas);

    // Section data
    const sectionDatas = await getSections({});
    setSections(sectionDatas);
    setFilteredSections(sectionDatas);

    // Position data
    const positionDatas = await getPositions({});
    setPositions(positionDatas);
    setFilteredPostions(positionDatas);

    // Salary expectation data
    const salaryExpectationDatas = await getSalaryExpectations({ limit: 20 });
    setSalaryExpectations(salaryExpectationDatas);
  }

  async function getAllDatas() {
    setLoadingPage(true);
    await getJobPreferences();
    setLoadingPage(false);
    await getInitialDatas();
  }

  useEffect(() => {
    getAllDatas();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useForm<payloadDataType>({});

  const job_title = watch("job_title") || "";

  const onSubmit: SubmitHandler<payloadDataType> = async ({
    cv_id,
    ...others
  }) => {
    try {
      console.log("travel", others.travel_status_ids);
      const response = await axios.userAuthRequest({
        endpoint: "user/profilim/cv/pref/update",
        type: "put",
        payload: {
          cv_id: cvId,
          ...others,
        },
      });

      if (response && response.status == 200) {
        showCvScore();
        navigate(`/profilim/cv/duzenle/${cvId}/referanslar`);
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "İş tercihleri güncellenirken bir hata meydana geldi.",
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "İş tercihleri güncellenirken beklenmedik bir hata meydana geldi.",
      });
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  /* Search term changes */
  // City search
  useEffect(() => {
    async function searchByTerm() {
      if (citySearchTerm.trim().length > 0) {
        const cityFilter = await getCities({ search: citySearchTerm });

        setFilteredCities(cityFilter);
      } else {
        setFilteredCities(cities);
      }
    }

    const timeOut = setTimeout(searchByTerm, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [citySearchTerm]);

  // Sector search
  useEffect(() => {
    async function searchByTerm() {
      if (sectorSearchTerm.trim().length > 0) {
        const sectorFilter = await getSectors({ search: sectorSearchTerm });

        setFilteredSectors(sectorFilter);
      } else {
        setFilteredSectors(sectors);
      }
    }

    const timeOut = setTimeout(searchByTerm, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [sectorSearchTerm]);

  // Section search
  useEffect(() => {
    async function searchByTerm() {
      if (sectionSearchTerm.trim().length > 0) {
        const sectionFilter = await getSections({ search: sectionSearchTerm });

        setFilteredSections(sectionFilter);
      } else {
        setFilteredSections(sections);
      }
    }

    const timeOut = setTimeout(searchByTerm, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [sectionSearchTerm]);

  // Position search
  useEffect(() => {
    async function searchByTerm() {
      if (positionSearchTerm.trim().length > 0) {
        const positionFilter = await getPositions({
          search: positionSearchTerm,
        });

        setFilteredPostions(positionFilter);
      } else {
        setFilteredPostions(positions);
      }
    }

    const timeOut = setTimeout(searchByTerm, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [positionSearchTerm]);

  /* Form values set by state change */
  // Job title
  useEffect(() => {
    if (!jobTitle || jobTitle.trim().length == 0) {
      setValue("job_title", null);
    } else {
      setValue("job_title", jobTitle);
      clearErrors("job_title");
    }
  }, [jobTitle]);

  // Work status
  useEffect(() => {
    if (selectedWorkingStatus) {
      setValue("work_status", selectedWorkingStatus.id);
      clearErrors("work_status");
    }
  }, [selectedWorkingStatus]);

  // Experience level
  useEffect(() => {
    if (selectedExperienceLevel) {
      setValue("experience_level_id", selectedExperienceLevel.id);
      clearErrors("experience_level_id");
    }
  }, [selectedExperienceLevel]);

  // Travel status
  useEffect(() => {
    setValue(
      "travel_status_ids",
      selectedTravelStatuses.map((t) => {
        return t.id;
      })
    );
  }, [selectedTravelStatuses]);

  // Cities
  useEffect(() => {
    if (selectedCities.length == 0) {
      setValue("work_cities", []);
    } else {
      setValue(
        "work_cities",
        selectedCities.map((c) => {
          return c.id;
        })
      );
    }
  }, [selectedCities]);

  // Working types
  useEffect(() => {
    if (selectedWorkingTypes.length == 0) {
      setValue("work_types", []);
    } else {
      setValue(
        "work_types",
        selectedWorkingTypes.map((w) => {
          return w.id;
        })
      );
    }
  }, [selectedWorkingTypes]);

  // Sectors
  useEffect(() => {
    if (selectedSectors.length == 0) {
      setValue("sectors", []);
    } else {
      setValue(
        "sectors",
        selectedSectors.map((s) => {
          return s.id;
        })
      );
    }
  }, [selectedSectors]);

  // Sections
  useEffect(() => {
    if (selectedSections.length == 0) {
      setValue("sections", []);
    } else {
      setValue(
        "sections",
        selectedSections.map((s) => {
          return s.id;
        })
      );
    }
  }, [selectedSections]);

  // Positions
  useEffect(() => {
    if (selectedPositions.length == 0) {
      setValue("positions", []);
    } else {
      setValue(
        "positions",
        selectedPositions.map((p) => {
          return p.id;
        })
      );
    }
  }, [selectedPositions]);

  // Salary expectation
  useEffect(() => {
    if (!selectedSalaryExpectation) {
      setValue("salary_expectation_min_id", null);
      setValue("salary_expectation_max_id", null);
    } else {
      setValue("salary_expectation_min_id", selectedSalaryExpectation.id);
      setValue("salary_expectation_max_id", selectedSalaryExpectation.id);
    }
  }, [selectedSalaryExpectation]);

  // Information
  useEffect(() => {
    setValue("information", information);
  }, [information]);

  /* Custom registers */
  //Job title
  register("job_title", {
    required: `${isEnglish ? "Your Profession / Title" : "Meslek / Unvan"}`,
  });

  // Information
  register("information", {
    required: `${
      isEnglish
        ? "Personal and Professional Information"
        : "Kişisel ve Mesleki Bilgiler"
    }`,
    maxLength: {
      value: 3000,
      message:
        "Kişisel ve mesleki bilgiler alanı en fazla 3000 karakterden oluşabilir",
    },
  });

  if (loadingPage) {
    return <div className="p-2 border-mains border">Yükleniyor</div>;
  }

  // Validation on error
  function onError() {
    if (Object.entries(errors).length > 0) {
      const innerHtmlArray = Object.entries(errors).map(([type, message]) => {
        return message.message;
      });

      const innerHtml = innerHtmlArray.join("<br/>");

      Swal.fire({
        icon: "warning",
        title: `${isEnglish ? "Warning" : "Uyarı"}`,
        html: `
        <div>
        ${isEnglish ? "Missing Fields" : "Eksik Alanlar"}: <br/>
        <p class="text-red-600">
        ${innerHtml}
        </p>
        </div>
        `,
      });
    }
  }
  return (
    <div className="flex flex-col w-full gap-y-8 rounded-md bg-white pb-20 lg:pb-0">
      <span className="poppins-semibold text-sm text-mains py-4 border-b border-mains px-4">
        {isEnglish ? "Job Preferences" : "İş Tercihleri"}
      </span>

      <form
        className="flex flex-col gap-y-6 py-0 px-6"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <div className="flex flex-col lg:flex-row gap-y-6 gap-x-0 lg:gap-y-0 lg:gap-x-4 items-start">
          {/* Mesleğiniz input */}
          <div className="flex w-full lg:w-auto lg:basis-1/3 items-start">
            <InputArea
              className="w-full"
              required
              mode={job_title.trim().length == 0 ? "empty" : "dirty"}
              error={errors.job_title?.message ? true : false}
            >
              <InputArea.Label>
                {isEnglish
                  ? "Your Profession / Title"
                  : "Mesleğiniz / Unvanınız"}
              </InputArea.Label>
              <InputArea.Input
                value={jobTitle || ""}
                onChange={(e) => {
                  setJobTitle(e.target.value);
                }}
              />
            </InputArea>
          </div>

          {/* Çalışma Durumu select */}
          <div className="flex w-full lg:w-auto lg:basis-1/3 items-start">
            <SelectDropdown
              className="w-full"
              required
              {...register("work_status", {
                required: `${
                  isEnglish ? "Employment Status" : "Çalışma Durumunuz"
                }`,
              })}
              mode={selectedWorkingStatus ? "dirty" : "empty"}
              error={errors.work_status ? true : false}
            >
              <SelectDropdown.Label>
                {isEnglish ? "Employment Status" : "Çalışma Durumu"}
              </SelectDropdown.Label>
              <SelectDropdown.Trigger>
                {selectedWorkingStatus
                  ? `${
                      isEnglish
                        ? `${selectedWorkingStatus.title_en}`
                        : `${selectedWorkingStatus.title}`
                    }`
                  : `${isEnglish ? "Select" : "Seçiniz"}`}
              </SelectDropdown.Trigger>
              <SelectDropdown.Items>
                {workingStatus.map((w) => {
                  return (
                    <SelectDropdown.Item
                      key={`workingStatusSelectItem${w.id}`}
                      onClick={() => {
                        setSelectedWorkingStatus(w);
                      }}
                    >
                      {isEnglish ? w.title_en : w.title}
                    </SelectDropdown.Item>
                  );
                })}
              </SelectDropdown.Items>
            </SelectDropdown>
          </div>

          {/* Toplam iş deneyimi select */}
          <div className="flex w-full lg:w-auto lg:basis-1/3 items-start">
            <SelectDropdown
              className="w-full"
              required
              {...register("experience_level_id", {
                required: `${
                  isEnglish ? "Total Work Experience" : "Toplam İş Deneyimi"
                }`,
              })}
              mode={selectedExperienceLevel ? "dirty" : "empty"}
              error={errors.experience_level_id?.message ? true : false}
            >
              <SelectDropdown.Label>
                {isEnglish ? "Total Work Experience" : "Toplam İş Deneyimi"}
              </SelectDropdown.Label>
              <SelectDropdown.Trigger>
                {selectedExperienceLevel
                  ? `${
                      isEnglish
                        ? `${selectedExperienceLevel.title_en}`
                        : `${selectedExperienceLevel.title}`
                    }`
                  : `${isEnglish ? "Select" : "Seçiniz"}`}
              </SelectDropdown.Trigger>
              <SelectDropdown.Items>
                {experienceLevels.map((experienceLevel) => {
                  return (
                    <SelectDropdown.Item
                      key={`elselectItem${experienceLevel.id}`}
                      onClick={() => {
                        setSelectedExperienceLevel(experienceLevel);
                      }}
                    >
                      {isEnglish
                        ? experienceLevel.title_en
                        : experienceLevel.title}
                    </SelectDropdown.Item>
                  );
                })}
              </SelectDropdown.Items>
            </SelectDropdown>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-y-6 gap-x-0 lg:gap-y-0 lg:gap-x-4 items-start">
          {/* İş Seyahati tercihi select */}
          <div className="flex w-full lg:w-auto lg:basis-1/3 items-start">
            <MultiSelectDropdown
              className="w-full"
              mode={selectedTravelStatuses.length == 0 ? "empty" : "dirty"}
            >
              <MultiSelectDropdown.Label>
                {isEnglish
                  ? "Work Travel Preference"
                  : "İş Seyahati Tercihiniz"}
              </MultiSelectDropdown.Label>
              <MultiSelectDropdown.Trigger>
                {selectedTravelStatuses.length > 0
                  ? `${
                      isEnglish
                        ? `${selectedTravelStatuses[0].title_en}`
                        : `${selectedTravelStatuses[0].title}`
                    } ${
                      selectedTravelStatuses.length > 1
                        ? `(+${selectedTravelStatuses.length - 1})`
                        : ``
                    }`
                  : `${isEnglish ? "Select" : "Seçiniz"}`}
              </MultiSelectDropdown.Trigger>
              <MultiSelectDropdown.Items>
                {selectedTravelStatuses.map((t) => {
                  return (
                    <MultiSelectDropdown.Item
                      key={`selectedTravel${t.id}`}
                      selected={
                        selectedTravelStatuses.some((s) => s.id == t.id)
                          ? true
                          : false
                      }
                      onClick={() => {
                        if (
                          selectedTravelStatuses.find((s) => s.id == t.id) ==
                          undefined
                        ) {
                          setSelectedTravelStatuses((prev) => [...prev, t]);
                        } else {
                          setSelectedTravelStatuses((prev) => [
                            ...prev.filter((p) => p.id != t.id),
                          ]);
                        }
                      }}
                    >
                      {isEnglish ? t.title_en : t.title}
                    </MultiSelectDropdown.Item>
                  );
                })}
                {travelStatus.map((t) => {
                  if (
                    selectedTravelStatuses.find((s) => s.id == t.id) ==
                    undefined
                  ) {
                    return (
                      <MultiSelectDropdown.Item
                        key={`selectedTravel${t.id}`}
                        selected={
                          selectedTravelStatuses.some((s) => s.id == t.id)
                            ? true
                            : false
                        }
                        onClick={() => {
                          if (
                            selectedTravelStatuses.find((s) => s.id == t.id) ==
                            undefined
                          ) {
                            setSelectedTravelStatuses((prev) => [...prev, t]);
                          } else {
                            setSelectedTravelStatuses((prev) => [
                              ...prev.filter((p) => p.id != t.id),
                            ]);
                          }
                        }}
                      >
                        {isEnglish ? t.title_en : t.title}
                      </MultiSelectDropdown.Item>
                    );
                  } else {
                    return null;
                  }
                })}
              </MultiSelectDropdown.Items>
            </MultiSelectDropdown>
          </div>

          {/* Çalışmak istediğiniz şehirler multiselect */}
          <div className="flex w-full lg:w-auto lg:basis-1/3 items-start">
            <MultiSelectSearchDropdown
              className="w-full"
              mode={selectedCities.length == 0 ? "empty" : "dirty"}
            >
              <MultiSelectSearchDropdown.Label>
                {isEnglish
                  ? "Cities You Want to Work In"
                  : "Çalışmak İstediğiniz Şehirler"}
              </MultiSelectSearchDropdown.Label>
              <MultiSelectSearchDropdown.Trigger
                onClick={async () => {
                  if (
                    citySearchTerm.length == 0 &&
                    filteredCities.length == 0
                  ) {
                    setIsLoadingData(true);
                    const res = await getCities({});
                    setFilteredCities(res);
                    setIsLoadingData(false);
                  }
                }}
              >
                <MultiSelectSearchDropdown.Value>
                  {selectedCities.length > 0
                    ? `${
                        isEnglish
                          ? `${
                              selectedCities[0].name_en ??
                              selectedCities[0].name
                            }`
                          : `${selectedCities[0].name}`
                      } ${
                        selectedCities.length > 1
                          ? `(+${selectedCities.length - 1})`
                          : ""
                      }`
                    : `${isEnglish ? "Select" : "Seçiniz"}`}
                </MultiSelectSearchDropdown.Value>
                <MultiSelectSearchDropdown.Input
                  placeholder={
                    selectedCities.length > 0
                      ? `${
                          isEnglish
                            ? `${
                                selectedCities[0].name_en ??
                                selectedCities[0].name
                              }`
                            : `${selectedCities[0].name}`
                        } ${
                          selectedCities.length > 1
                            ? `(+${selectedCities.length - 1})`
                            : ""
                        }`
                      : `${isEnglish ? "Select" : "Seçiniz"}`
                  }
                  value={citySearchTerm || ""}
                  onChange={(e) => setCitySearchTerm(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key == "Backspace" && citySearchTerm.length == 0) {
                      e.preventDefault();
                      setSelectedCities((prev) => prev.slice(0, -1));
                    }
                  }}
                />
              </MultiSelectSearchDropdown.Trigger>
              <MultiSelectSearchDropdown.Items>
                {isLoadingData && (
                  <MultiSelectDropdown.Item>
                    <AiOutlineLoading3Quarters className="mx-auto animate-spin" />
                  </MultiSelectDropdown.Item>
                )}
                {selectedCities.map((city) => {
                  return (
                    <MultiSelectSearchDropdown.Item
                      key={`selectedCityItem${city.id}`}
                      onClick={() => {
                        if (
                          selectedCities.find((c) => c.id == city.id) !=
                          undefined
                        ) {
                          setSelectedCities((prev) => [
                            ...prev.filter((p) => p.id != city.id),
                          ]);
                        } else {
                          setSelectedCities((prev) => [...prev, city]);
                        }
                      }}
                      selected={
                        selectedCities.find((c) => c.id == city.id) != undefined
                          ? true
                          : false
                      }
                    >
                      {isEnglish ? city.name_en ?? city.name : city.name}
                    </MultiSelectSearchDropdown.Item>
                  );
                })}
                {filteredCities.map((city) => {
                  if (
                    selectedCities.find((c) => c.id == city.id) == undefined
                  ) {
                    return (
                      <MultiSelectSearchDropdown.Item
                        key={`filteredCityItem${city.id}`}
                        onClick={() => {
                          if (
                            selectedCities.find((c) => c.id == city.id) !=
                            undefined
                          ) {
                            setSelectedCities((prev) => [
                              ...prev.filter((p) => p.id != city.id),
                            ]);
                          } else {
                            setSelectedCities((prev) => [...prev, city]);
                          }
                        }}
                        selected={
                          selectedCities.find((c) => c.id == city.id) !=
                          undefined
                            ? true
                            : false
                        }
                      >
                        {isEnglish ? city.name_en ?? city.name : city.name}
                      </MultiSelectSearchDropdown.Item>
                    );
                  } else {
                    return null;
                  }
                })}
              </MultiSelectSearchDropdown.Items>
            </MultiSelectSearchDropdown>
          </div>

          {/* Çalışma durumu tercihi multiselect */}
          <div className="flex w-full lg:w-auto lg:basis-1/3 items-start">
            <MultiSelectDropdown
              className="w-full"
              mode={selectedWorkingTypes.length > 0 ? "dirty" : "empty"}
            >
              <MultiSelectDropdown.Label>
                {isEnglish
                  ? "Employment Status Preference"
                  : "Çalışma Durumu Tercihiniz"}
              </MultiSelectDropdown.Label>
              <MultiSelectDropdown.Trigger>
                {selectedWorkingTypes.length > 0
                  ? `${
                      isEnglish
                        ? `${selectedWorkingTypes[0].title_en}`
                        : `${selectedWorkingTypes[0].title}`
                    } ${
                      selectedWorkingTypes.length > 1
                        ? `(+${selectedWorkingTypes.length - 1})`
                        : ""
                    }`
                  : `${isEnglish ? "Select" : "Seçiniz"}`}
              </MultiSelectDropdown.Trigger>
              <MultiSelectDropdown.Items>
                {selectedWorkingTypes.map((workingType) => {
                  return (
                    <MultiSelectDropdown.Item
                      key={`swt${workingType.id}`}
                      onClick={() => {
                        if (
                          selectedWorkingTypes.find(
                            (w) => w.id == workingType.id
                          ) != undefined
                        ) {
                          setSelectedWorkingTypes((prev) => [
                            ...prev.filter((p) => p.id != workingType.id),
                          ]);
                        } else {
                          setSelectedWorkingTypes((prev) => [
                            ...prev,
                            workingType,
                          ]);
                        }
                      }}
                      selected={
                        selectedWorkingTypes.find(
                          (w) => w.id == workingType.id
                        ) != undefined
                          ? true
                          : false
                      }
                    >
                      {isEnglish ? workingType.title_en : workingType.title}
                    </MultiSelectDropdown.Item>
                  );
                })}
                {workingTypes.map((workingType) => {
                  if (
                    selectedWorkingTypes.find((s) => s.id == workingType.id) ==
                    undefined
                  ) {
                    return (
                      <MultiSelectDropdown.Item
                        key={`swt${workingType.id}`}
                        onClick={() => {
                          if (
                            selectedWorkingTypes.find(
                              (w) => w.id == workingType.id
                            ) != undefined
                          ) {
                            setSelectedWorkingTypes((prev) => [
                              ...prev.filter((p) => p.id != workingType.id),
                            ]);
                          } else {
                            setSelectedWorkingTypes((prev) => [
                              ...prev,
                              workingType,
                            ]);
                          }
                        }}
                        selected={
                          selectedWorkingTypes.find(
                            (w) => w.id == workingType.id
                          ) != undefined
                            ? true
                            : false
                        }
                      >
                        {isEnglish ? workingType.title_en : workingType.title}
                      </MultiSelectDropdown.Item>
                    );
                  } else {
                    return null;
                  }
                })}
              </MultiSelectDropdown.Items>
            </MultiSelectDropdown>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-y-6 gap-x-0 lg:gap-y-0 lg:gap-x-4 items-start">
          {/* Çalışmak istediğiniz sektörler multiselect*/}
          <div className="flex w-full lg:w-auto lg:basis-1/3">
            <MultiSelectSearchDropdown
              className="w-full"
              mode={selectedSectors.length == 0 ? "empty" : "dirty"}
            >
              <MultiSelectSearchDropdown.Label>
                {isEnglish
                  ? "Industries You Want to Work In"
                  : "Çalışmak İstediğiniz Sektörler"}
              </MultiSelectSearchDropdown.Label>
              <MultiSelectSearchDropdown.Trigger
                onClick={async () => {
                  if (
                    sectorSearchTerm.length == 0 &&
                    filteredSectors.length == 0
                  ) {
                    setIsLoadingData(true);
                    const res = await getSectors({});
                    setFilteredSectors(res);
                    setIsLoadingData(false);
                  }
                }}
              >
                <MultiSelectSearchDropdown.Value>
                  {selectedSectors.length > 0
                    ? `${
                        isEnglish
                          ? `${selectedSectors[0].title_en}`
                          : `${selectedSectors[0].title}`
                      } ${
                        selectedSectors.length > 1
                          ? `(+${selectedSectors.length - 1})`
                          : ""
                      }`
                    : `${isEnglish ? "Select" : "Seçiniz"}`}
                </MultiSelectSearchDropdown.Value>
                <MultiSelectSearchDropdown.Input
                  value={sectorSearchTerm || ""}
                  placeholder={
                    selectedSectors.length > 0
                      ? `${
                          isEnglish
                            ? `${selectedSectors[0].title_en}`
                            : `${selectedSectors[0].title}`
                        } ${
                          selectedSectors.length > 1
                            ? `(+${selectedSectors.length - 1})`
                            : ""
                        }`
                      : `${isEnglish ? "Search" : "Arama Yap"}`
                  }
                  onChange={(e) => setSectorSearchTerm(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key == "Backspace" && sectorSearchTerm.length == 0) {
                      e.preventDefault();
                      setSelectedSectors((prev) => prev.slice(0, -1));
                    }
                  }}
                />
              </MultiSelectSearchDropdown.Trigger>
              <MultiSelectSearchDropdown.Items>
                {isLoadingData && (
                  <MultiSelectSearchDropdown.Item>
                    <AiOutlineLoading3Quarters className="mx-auto animate-spin" />
                  </MultiSelectSearchDropdown.Item>
                )}
                {selectedSectors.map((sector) => {
                  return (
                    <MultiSelectSearchDropdown.Item
                      key={`ssector${sector.id}`}
                      selected={
                        selectedSectors.find((s) => s.id == sector.id) !=
                        undefined
                          ? true
                          : false
                      }
                      onClick={() => {
                        const ss = selectedSectors.find(
                          (s) => s.id == sector.id
                        );
                        if (ss) {
                          setSelectedSectors((prev) => [
                            ...prev.filter((s) => s.id != sector.id),
                          ]);
                        } else {
                          setSelectedSectors((prev) => [...prev, sector]);
                        }
                      }}
                    >
                      {isEnglish ? sector.title_en : sector.title}
                    </MultiSelectSearchDropdown.Item>
                  );
                })}
                {filteredSectors.map((sector) => {
                  if (
                    selectedSectors.find((s) => s.id == sector.id) == undefined
                  ) {
                    return (
                      <MultiSelectSearchDropdown.Item
                        key={`fsector${sector.id}`}
                        selected={
                          selectedSectors.find((s) => s.id == sector.id) !=
                          undefined
                            ? true
                            : false
                        }
                        onClick={() => {
                          const ss = selectedSectors.find(
                            (s) => s.id == sector.id
                          );
                          if (ss) {
                            setSelectedSectors((prev) => [
                              ...prev.filter((s) => s.id != sector.id),
                            ]);
                          } else {
                            setSelectedSectors((prev) => [...prev, sector]);
                          }
                        }}
                      >
                        {isEnglish ? sector.title_en : sector.title}
                      </MultiSelectSearchDropdown.Item>
                    );
                  } else {
                    return null;
                  }
                })}
              </MultiSelectSearchDropdown.Items>
            </MultiSelectSearchDropdown>
          </div>

          {/* Çalışmak istediğniz bölümler multiselect */}
          <div className="flex w-full lg:w-auto lg:basis-1/3">
            <MultiSelectSearchDropdown
              className="w-full"
              mode={selectedSections.length > 0 ? "dirty" : "empty"}
            >
              <MultiSelectSearchDropdown.Label>
                {isEnglish
                  ? `Departments You Want to Work In`
                  : `Çalışmak İstediğiniz Bölümler`}
              </MultiSelectSearchDropdown.Label>
              <MultiSelectSearchDropdown.Trigger
                onClick={async () => {
                  if (
                    sectionSearchTerm.length == 0 &&
                    filteredSections.length == 0
                  ) {
                    setIsLoadingData(true);
                    const res = await getSections({});
                    setFilteredSections(res);
                    setIsLoadingData(false);
                  }
                }}
              >
                <MultiSelectSearchDropdown.Value>
                  {selectedSections.length > 0
                    ? `${
                        isEnglish
                          ? `${selectedSections[0].title_en}`
                          : `${selectedSections[0].title}`
                      } ${
                        selectedSections.length > 1
                          ? `(+${selectedSections.length - 1})`
                          : ""
                      }`
                    : `${isEnglish ? "Select" : "Seçiniz"}`}
                </MultiSelectSearchDropdown.Value>
                <MultiSelectSearchDropdown.Input
                  placeholder={
                    selectedSections.length > 0
                      ? `${
                          isEnglish
                            ? `${selectedSections[0].title_en}`
                            : `${selectedSections[0].title}`
                        } ${
                          selectedSections.length > 1
                            ? `(+${selectedSections.length - 1})`
                            : ""
                        }`
                      : `${isEnglish ? "Search" : "Arama yap"}`
                  }
                  value={sectionSearchTerm || ""}
                  onChange={(e) => {
                    setSectionSearchTerm(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key == "Backspace" && sectionSearchTerm.length == 0) {
                      e.preventDefault();

                      setSelectedSections((prev) => prev.slice(0, -1));
                    }
                  }}
                />
              </MultiSelectSearchDropdown.Trigger>
              <MultiSelectSearchDropdown.Items>
                {isLoadingData && (
                  <MultiSelectDropdown.Item>
                    <AiOutlineLoading3Quarters className="mx-auto animate-spin" />
                  </MultiSelectDropdown.Item>
                )}
                {selectedSections.map((section) => {
                  return (
                    <MultiSelectDropdown.Item
                      key={`ssection${section.id}`}
                      onClick={() => {
                        const ss = selectedSections.find(
                          (s) => s.id == section.id
                        );
                        if (ss) {
                          setSelectedSections((prev) => [
                            ...prev.filter((p) => p.id != section.id),
                          ]);
                        } else {
                          setSelectedSections((prev) => [...prev, section]);
                        }
                      }}
                      selected={
                        selectedSections.some((s) => s.id == section.id)
                          ? true
                          : false
                      }
                    >
                      {isEnglish ? section.title_en : section.title}
                    </MultiSelectDropdown.Item>
                  );
                })}
                {filteredSections.map((section) => {
                  if (
                    selectedSections.find((s) => s.id == section.id) ==
                    undefined
                  ) {
                    return (
                      <MultiSelectDropdown.Item
                        key={`fsection${section.id}`}
                        onClick={() => {
                          const ss = selectedSections.find(
                            (s) => s.id == section.id
                          );
                          if (ss) {
                            setSelectedSections((prev) => [
                              ...prev.filter((p) => p.id != section.id),
                            ]);
                          } else {
                            setSelectedSections((prev) => [...prev, section]);
                          }
                        }}
                        selected={
                          selectedSections.some((s) => s.id == section.id)
                            ? true
                            : false
                        }
                      >
                        {isEnglish ? section.title_en : section.title}
                      </MultiSelectDropdown.Item>
                    );
                  } else {
                    return null;
                  }
                })}
              </MultiSelectSearchDropdown.Items>
            </MultiSelectSearchDropdown>
          </div>

          {/* Çalışmak istediğiniz pozisyonlar multiselect*/}
          <div className="flex w-full lg:w-auto lg:basis-1/3">
            <MultiSelectSearchDropdown
              className="w-full"
              mode={selectedPositions.length > 0 ? "dirty" : "empty"}
            >
              <MultiSelectSearchDropdown.Label>
                {isEnglish
                  ? "Positions You Want to Work In"
                  : "Çalışmak İstediğiniz Pozisyonlar"}
              </MultiSelectSearchDropdown.Label>
              <MultiSelectSearchDropdown.Trigger
                onClick={async () => {
                  if (
                    positionSearchTerm.length == 0 &&
                    filteredPositions.length == 0
                  ) {
                    setIsLoadingData(true);
                    const res = await getPositions({});
                    setFilteredPostions(res);
                    setIsLoadingData(false);
                  }
                }}
              >
                <MultiSelectSearchDropdown.Value>
                  {selectedPositions.length > 0
                    ? `${
                        isEnglish
                          ? `${selectedPositions[0].title_en}`
                          : `${selectedPositions[0].title}`
                      } ${
                        selectedPositions.length > 1
                          ? `(+${selectedPositions.length - 1})`
                          : ""
                      }`
                    : `${isEnglish ? "Select" : "Seçiniz"}`}
                </MultiSelectSearchDropdown.Value>
                <MultiSelectSearchDropdown.Input
                  value={positionSearchTerm || ""}
                  placeholder={
                    selectedPositions.length > 0
                      ? `${
                          isEnglish
                            ? `${selectedPositions[0].title_en}`
                            : `${selectedPositions[0].title}`
                        } ${
                          selectedPositions.length > 1
                            ? `(+${selectedPositions.length - 1})`
                            : ""
                        }`
                      : `${isEnglish ? "Search" : "Arama yap"}`
                  }
                  onChange={(e) => setPositionSearchTerm(e.target.value)}
                  onKeyDown={(e) => {
                    if (
                      e.key == "Backspace" &&
                      positionSearchTerm.length == 0
                    ) {
                      e.preventDefault();
                      setSelectedPositions((prev) => prev.slice(0, -1));
                    }
                  }}
                />
              </MultiSelectSearchDropdown.Trigger>
              <MultiSelectSearchDropdown.Items>
                {isLoadingData && (
                  <MultiSelectSearchDropdown.Item>
                    <AiOutlineLoading3Quarters className="mx-auto animate-spin" />
                  </MultiSelectSearchDropdown.Item>
                )}
                {selectedPositions.map((position) => {
                  return (
                    <MultiSelectSearchDropdown.Item
                      key={`sposition${position.id}`}
                      selected={
                        selectedPositions.find((p) => p.id == position.id)
                          ? true
                          : false
                      }
                      onClick={() => {
                        if (
                          selectedPositions.find((p) => p.id == position.id)
                        ) {
                          setSelectedPositions((prev) => [
                            ...prev.filter((p) => p.id != position.id),
                          ]);
                        } else {
                          setSelectedPositions((prev) => [...prev, position]);
                        }
                      }}
                    >
                      {isEnglish ? position.title_en : position.title}
                    </MultiSelectSearchDropdown.Item>
                  );
                })}
                {filteredPositions.map((position) => {
                  if (
                    selectedPositions.find((p) => p.id == position.id) ==
                    undefined
                  ) {
                    return (
                      <MultiSelectSearchDropdown.Item
                        key={`fposition${position.id}`}
                        selected={
                          selectedPositions.find((p) => p.id == position.id)
                            ? true
                            : false
                        }
                        onClick={() => {
                          if (
                            selectedPositions.find((p) => p.id == position.id)
                          ) {
                            setSelectedPositions((prev) => [
                              ...prev.filter((p) => p.id != position.id),
                            ]);
                          } else {
                            setSelectedPositions((prev) => [...prev, position]);
                          }
                        }}
                      >
                        {isEnglish ? position.title_en : position.title}
                      </MultiSelectSearchDropdown.Item>
                    );
                  } else {
                    return null;
                  }
                })}
              </MultiSelectSearchDropdown.Items>
            </MultiSelectSearchDropdown>
          </div>
        </div>

        <div className="flex flex-row gap-x-4">
          {/* Ücret beklentisi select */}
          <div className="flex w-full lg:w-auto lg:basis-1/3 pr-0 lg:pr-4">
            <SelectDropdown
              className="w-full"
              mode={selectedSalaryExpectation ? "dirty" : "empty"}
            >
              <SelectDropdown.Label>
                {isEnglish ? "Salary Expectation" : "Ücret Beklentisi"}
              </SelectDropdown.Label>
              <SelectDropdown.Trigger>
                {selectedSalaryExpectation
                  ? `${
                      isEnglish
                        ? `${selectedSalaryExpectation.title}`
                        : `${selectedSalaryExpectation.title}`
                    }`
                  : `${isEnglish ? "Select" : "Seçiniz"}`}
              </SelectDropdown.Trigger>
              <SelectDropdown.Items>
                <SelectDropdown.Item
                  onClick={() => setSelectedSalaryExpectation(null)}
                >
                  {isEnglish ? "Select" : "Seçiniz"}
                </SelectDropdown.Item>
                {salaryExpectations.map((salary) => {
                  return (
                    <SelectDropdown.Item
                      key={`ssalary${salary.id}`}
                      onClick={() => setSelectedSalaryExpectation(salary)}
                    >
                      {isEnglish ? salary.title_en : salary.title}
                    </SelectDropdown.Item>
                  );
                })}
              </SelectDropdown.Items>
            </SelectDropdown>
          </div>
        </div>

        <div className="flex flex-col gap-1">
          {/* Kişisel ve mesleki bilgiler editor */}
          <InputArea error={errors.information?.message ? true : false}>
            <InputArea.Label>
              {isEnglish
                ? "Personal and Professional Information"
                : "Kişisel ve Mesleki Bilgiler"}
              <b className="text-red-600"> * </b> <br />
              <label className="text-[10px] font-poppins text-gray-600">
                {isEnglish
                  ? "You can add a summary of your education, work experience, certifications and other information to attract attention from companies."
                  : "Eğitim bilgilerinizi, iş deneyimlerinizi, yekinliklerinizi ve diğer bilgilerinizi firmalar tarafından dikkat çekebilmek için özet halinde ekleyebilirsiniz."}
              </label>
            </InputArea.Label>
            <div className="custom-editor">
              <CKEditor
                editor={ClassicEditor}
                data={information}
                onReady={() => {}}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setValue("information", data);
                  setInformation(data);
                }}
              />
            </div>
          </InputArea>
          <label className="text-gray-700 font-poppins text-sm">
            {3000 - information.length}
          </label>
        </div>

        <Separator />

        {/* Buttons */}
        <div className="flex flex-row items-center justify-center sm:justify-between flex-wrap gap-2 mb-4">
          <Button
            size="sm"
            type="button"
            variant="secondary"
            className="lg:!px-6"
            onClick={() =>
              navigate(`/profilim/cv/duzenle/${cvId}/iletisim-bilgileri`)
            }
          >
            <Button.Icon>
              <IoChevronBack />
            </Button.Icon>
            {isEnglish ? "Go Back" : "Geri Dön"}
          </Button>
          <Button size="sm" className="lg:!px-6">
            {isEnglish ? "Save and Continue" : "Kaydet ve Devam Et"}
            <Button.Icon>
              <IoChevronForward />
            </Button.Icon>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default JobPrefences;
