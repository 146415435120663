import { Children, HTMLAttributes, ReactElement, ReactNode, isValidElement, useContext } from "react"
import classNames from "classnames"
import PageButton from "./PageButton"
import { paginationContextType, PaginationContext } from "."
import PageLink from "./PageLink"


type arrowItemsPropsType = HTMLAttributes<HTMLDivElement> & {

}






export default function Items({
    children,
    className,
    ...props
}: arrowItemsPropsType) {

    const cn = classNames({
        'flex flex-row items-center gap-1': true
    })

    const componentChildren = Children.toArray(children)

    const { currentPage, firstPageNumber, lastPageNumber }: paginationContextType = useContext(PaginationContext)

    return(
        <div className={`${cn} ${className}`} {...props}>
            {Math.abs(currentPage - firstPageNumber) > 3 && (
                <>
                    {componentChildren[0]}
                </>
            )}
            {Math.abs(currentPage - firstPageNumber) > 4 && (
                '...'
            )}
            {componentChildren.map((child) => {
                if (isValidElement(child)) {
                    if (child.type == PageButton || child.type == PageLink) {
                        if (Math.abs(child.props.pageNumber - currentPage) <= 3) {
                            return child
                        } else {
                            return null
                        }
                    } else {
                        return null
                    }
                } else {
                    return null
                }
                
            })}
            {Math.abs(currentPage - lastPageNumber) > 4 && (
                '...'
            )}
            {Math.abs(currentPage - lastPageNumber) > 3 && (
                <>
                    {componentChildren[componentChildren.length - 1]}
                </>
            )}
        </div>
    )
}