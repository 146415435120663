import * as React from "react";
import { RegisterServices } from "../../../../../../../services/register-services";
import Axios from "../../../../../../../services/axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { CustomFormField } from "../../../../../custom-form-field";
import { Link, useNavigate } from "react-router-dom";
import { useCookie } from "../../../../../../../hooks/useCookie";
import { CookieName } from "../../../../../../../constants/enums";
import Swal from "sweetalert2";
import { trim } from "lodash";
import { IUserDatas } from "../../../../../../../containers/login-page-container/profile/register/types/IUserDatas";
import { InputArea, SelectDropdown } from "../../../../../../isbul-ui";
import { FiEye, FiEyeOff } from "react-icons/fi";
import ReactCountryFlag from "react-country-flag";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

const validationSchema = Yup.object({
  name: Yup.string().required("İsim alanı zorunludur"),
  surname: Yup.string().required("Soyadınız alanı zorunludur"),
  emailField: Yup.string()
    .email("Geçerli bir E-mail adresi giriniz")
    .required("Email alanı zorunludur"),
  phone_code: Yup.string().required("Bu alan zorunludur"),
  phone: Yup.string()
    .transform((value) => value.replace(/\D/g, ""))
    .matches(/^\d+$/, "Telefon numarası sadece rakamlardan oluşmalıdır")
    .min(10, "Telefon numarası en az 10 karakter olmalıdır")
    .required("Telefon numarası zorunludur"),
  password: Yup.string()
    .min(8, "Şifre en az 8 karakter olmalıdır")
    .required("Şifre alanı zorunludur"),
  permissionEmail: Yup.boolean(),
  readKVKK: Yup.boolean()
    .oneOf([true], "Açık Rıza Metni okunmalı ve kabul edilmelidir")
    .required("Açık Rıza Metni okunmalı ve kabul edilmelidir"),
});

function RegisterProfile() {
  const navigate = useNavigate();
  const { setCookie } = useCookie();
  const axiosInstance = new Axios();
  const [missingFields, setMissingFields] = React.useState<string[]>([]);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [phone, setPhone] = React.useState<string>("");

  const [countriesPhoneCode, setCountriesPhoneCode] = React.useState<
    Array<{
      value: string;
      label: string;
    }>
  >([]);

  // Fetch country phone codes
  React.useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axiosInstance.request({
          type: "get",
          endpoint: "cdn/get-countries?limit=1",
        });
        if (!response.data || !response.data.countries) {
          throw new Error("Failed to fetch countries data");
        }
        const countriesData = response.data.countries;

        const formattedCountries = countriesData.map((country: any) => ({
          value: country.phone_code,
          label: `${country.name_tr} +${country.phone_code}`,
        }));
        formik.setFieldValue("phone_code", formattedCountries?.[0]?.value);
        setCountriesPhoneCode(formattedCountries);
      } catch (error) {
        console.error("Error fetching countries data:", error);
      }
    };

    fetchCountries();
  }, []);

  const capitalizeWords = (str: string) => {
    return str
      .split(" ")
      .map(
        (word) =>
          word.charAt(0).toLocaleUpperCase("tr-TR") +
          word.slice(1).toLocaleLowerCase("tr-TR")
      )
      .join(" ");
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      surname: "",
      emailField: "",
      phone_code: "",
      phone: "",
      password: "",
      permissionEmail: 0,
      readKVKK: 0,
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values: any) => {
      const userData: IUserDatas = {
        name: values.name,
        surname: values.surname,
        email: values.emailField,
        phone_code: "+90",
        phone: values.phone.replace(/[()\s]/g, ""),
        password: values.password,
        permissionEmail: values.permissionEmail,
        readKVKK: values.readKVKK,
      };

      try {
        const response = await RegisterServices.registerProfile(userData);
        localStorage.setItem("token", response.data.token);
        setCookie(CookieName.UserToken, response.data.access_token);
        setCookie(CookieName.UserData, JSON.stringify(response.data.user));
        if (response.data.status_code === 200) {
          Swal.fire({
            title: "Hoşgeldiniz!",
            text: "Üyeliğiniz başarıyla oluşturuldu.",
            icon: "success",
            showConfirmButton: false,
            timer: 4000,
          });

          navigate("/profilim", { replace: true });
        }
      } catch (error: any) {
        console.log(error.response.data);

        if (error.response.data.status_code === 422) {
          const errorMessages = [];

          if (error.response.data.errors.phone) {
            errorMessages.push(error.response.data.errors.phone[0]);
          }
          if (error.response.data.errors.email) {
            errorMessages.push(error.response.data.errors.email[0]);
          }
          if (error.response.data.errors.phone_code) {
            errorMessages.push(error.response.data.errors.phone_code[0]);
          }

          if (errorMessages.length > 0) {
            Swal.fire({
              title: "Hata!",
              html: errorMessages.join("<br/>"),
              icon: "error",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        }

        throw new Error(error as string);
      }
    },
  });

  const handleAlert = () => {
    const fields: string[] = [];
    const emailValue = formik.values.emailField;

    if (!formik.values.name) fields.push("Ad");
    if (!formik.values.surname) fields.push("Soyad");
    if (!emailValue || formik.errors.emailField) {
      if (!emailValue) {
        fields.push("E-mail");
      } else if (formik.errors.emailField) {
        Swal.fire({
          title: "Uyarı!",
          text: "Geçerli bir E-mail adresi giriniz",
          icon: "warning",
          timer: 5000,
          showConfirmButton: true,
          confirmButtonText: "Tamam",
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        return;
      }
    }

    if (!formik.values.phone || trim(formik.values.phone, "_").length < 15)
      fields.push("Telefon");

    if (!formik.values.password) {
      fields.push("Şifre");
    }

    if (!formik.values.readKVKK) fields.push("Açık Rıza Metni");

    if (fields.length > 0) {
      setMissingFields(fields);
      Swal.fire({
        title: "Uyarı!",
        html:
          "Eksik alanlar: <br/><strong style='color: red; font-weight:400;'>" +
          fields.join("<br/>") +
          "</strong>",
        icon: "warning",
        timer: 5000,
        showConfirmButton: true,
        confirmButtonText: "Tamam",
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      return;
    }

    // Specific validations
    if (formik.values.password.length < 8) {
      Swal.fire({
        title: "Uyarı!",
        text: "Şifre en az 8 karakter olmalıdır",
        icon: "warning",
        timer: 5000,
        showConfirmButton: true,
        confirmButtonText: "Tamam",
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      return;
    }

    if (formik.values.password.length > 20) {
      Swal.fire({
        title: "Uyarı!",
        text: "Şifre en fazla 20 karakter olmalıdır",
        icon: "warning",
        timer: 5000,
        showConfirmButton: true,
        confirmButtonText: "Tamam",
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      return;
    }
  };

  React.useEffect(() => {
    const nameUpper = capitalizeWords(formik.values.name);
    formik.setFieldValue("name", nameUpper);
  }, [formik.values.name]);

  React.useEffect(() => {
    const surnameUpper = capitalizeWords(formik.values.surname);
    formik.setFieldValue("surname", surnameUpper);
  }, [formik.values.surname]);

  React.useEffect(() => {
    formik.setFieldValue("phone", phone);
  }, [phone]);

  return (
    <div className="flex flex-col w-full h-auto px-4 md:px-8 py-8 mx-auto bg-white rounded-md shadow-md gap-y-3 md:mx-0">
      <div className="flex flex-col w-full gap-y-2">
        <h1 className="poppins-medium text-base text-[#2253af]">
          İş Arayan Üye Ol
        </h1>
        <p className="text-[15px] poppins-semibold text-[#63636c]">
          Zaten üye misin?{" "}
          <a
            href="/profilim/giris-yap"
            className="text-[#ff7f1d] poppins-semibold text-[15px]"
          >
            Giriş Yap.
          </a>
        </p>
      </div>
      <div className="h-screen lg:h-auto">
        <form
          className="flex flex-col gap-y-4"
          onSubmit={formik.handleSubmit}
          autoComplete="off"
        >
          <div className="flex flex-col gap-3 lg:flex-row w-full gap-x-3">
            <div className="flex flex-col w-full gap-y-1">
              {/* <CustomFormField
                type="text"
                label="Adınız *"
                formik={formik}
                name="name"
              /> */}
              <InputArea
                className="w-full"
                required
                mode={formik.values["name"] ? "dirty" : "empty"}
                error={
                  formik.errors["name"] && formik.touched["name"] ? true : false
                }
              >
                <InputArea.Label>Adınız</InputArea.Label>
                <InputArea.Input
                  value={formik.values["name"] || ""}
                  onChange={(e) => {
                    formik.setFieldValue("name", e.target.value);
                  }}
                />
              </InputArea>
            </div>
            <div className="flex flex-col w-full gap-y-1">
              {/* <CustomFormField
                type="text"
                label="Soyadınız *"
                formik={formik}
                name="surname"
              /> */}
              <InputArea
                className="w-full"
                required
                mode={formik.values["surname"] ? "dirty" : "empty"}
                error={
                  formik.errors["surname"] && formik.touched["surname"]
                    ? true
                    : false
                }
              >
                <InputArea.Label>Soyadınız</InputArea.Label>
                <InputArea.Input
                  value={formik.values["surname"]}
                  onChange={(e) => {
                    formik.setFieldValue("surname", e.target.value);
                  }}
                />
              </InputArea>
            </div>
          </div>

          {/* <CustomFormField
            type="text"
            label="E-posta Adresiniz *"
            formik={formik}
            name="emailField"
          /> */}
          <InputArea
            className="w-full"
            required
            mode={formik.values["emailField"] ? "dirty" : "empty"}
            error={
              formik.errors["emailField"] && formik.touched["emailField"]
                ? true
                : false
            }
          >
            <InputArea.Label>E-posta Adresiniz</InputArea.Label>
            <InputArea.Input
              name="emailField"
              // onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
                formik.setFieldTouched("emailField", true, false);
                formik.validateField("emailField");
              }}
            />
          </InputArea>

          <div className="flex w-full gap-x-2">
            {/* <CustomFormField
              onClick={() => {
                alert("henüz türkiye hatları kabul ediliyor");
              }}
              disabled={false}
              type="select"
              label="Ülke Kodu *"
              formik={formik}
              name="phone_code"
              options={countriesPhoneCode}
            /> */}
            <SelectDropdown
              className="w-full max-w-[50%]"
              required
              mode="dirty"
            >
              <SelectDropdown.Label>Ülke Kodu</SelectDropdown.Label>
              <SelectDropdown.Trigger
                onClick={() => {
                  Swal.fire({
                    icon: "warning",
                    title: "Uyarı",
                    text: "Henüz Türkiye hatları kabul ediliyor.",
                  });
                  return;
                }}
              >
                <div className="flex items-center gap-2 lg:hidden">
                  <ReactCountryFlag countryCode="TR" className="text-xl" svg />
                  +90
                </div>
                <label className="hidden lg:block cursor-pointer">
                  Türkiye +90
                </label>
              </SelectDropdown.Trigger>
            </SelectDropdown>
            {/* <CustomFormField
              type="tel"
              label="Telefon Numaranız *"
              formik={formik}
              name="phone"
              phoneMaskType="phone"
            /> */}
            <InputArea
              className="w-full max-w-[50%]"
              required
              mode={phone.length > 0 ? "dirty" : "empty"}
              error={
                formik.errors["phone"] && formik.touched["phone"] ? true : false
              }
            >
              <InputArea.Label>Telefon Numaranız</InputArea.Label>
              <InputArea.Input
                value={phone || ""}
                onClick={() => {
                  // Initial write (5 to input
                  if (phone == "" || phone == "(" || phone == "(5") {
                    setPhone("(5");
                  }
                }}
                onChange={(e) => {
                  let value = e.target.value;

                  value = value.replace(/\D/g, "");
                  if (!value.startsWith("5")) {
                    value = "5" + value;
                  }

                  // Phone number formatting
                  let formattedValue = "";
                  if (value.length > 0)
                    formattedValue += `(${value.substring(0, 3)}`;
                  if (value.length >= 4)
                    formattedValue += `) ${value.substring(3, 6)}`;
                  if (value.length >= 7)
                    formattedValue += ` ${value.substring(6, 8)}`;
                  if (value.length >= 9)
                    formattedValue += ` ${value.substring(8, 10)}`;
                  setPhone(formattedValue);
                }}
                onBlur={() => {
                  if (phone == "(5") {
                    setPhone("");
                  }
                }}
                onKeyDown={(e) => {
                  if (phone == "(5" && e.key == "ArrowLeft") {
                    e.preventDefault();
                  }
                }}
                name="psField"
              />
            </InputArea>
          </div>

          {/* <CustomFormField
            type="password"
            label="Şifre *"
            formik={formik}
            name="password"
          /> */}
          <div className="relative w-full">
            <InputArea
              required
              mode={formik.values["password"] ? "dirty" : "empty"}
              error={
                formik.errors["password"] && formik.touched["password"]
                  ? true
                  : false
              }
              className="w-full"
            >
              <InputArea.Label>Şifre</InputArea.Label>
              <div className="relative w-full">
                <InputArea.Input
                  className="w-full pr-20"
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => {
                    formik.setFieldValue("password", e.target.value);
                  }}
                />
                <button
                  type="button"
                  className="absolute top-1/2 -translate-y-1/2 right-2 flex flex-row items-center gap-2 text-xs text-mains font-poppins"
                  onClick={() => {
                    setShowPassword((prev) => !prev);
                  }}
                >
                  {showPassword ? "Gizle" : "Göster"}
                  {showPassword ? (
                    <FiEye className="text-base" />
                  ) : (
                    <FiEyeOff className="text-base" />
                  )}
                </button>
              </div>
            </InputArea>
            {formik.values["password"].length > 0 &&
              formik.values["password"].length < 8 && (
                <div className="absolute bottom-0 translate-y-full p-2 w-full bg-white shadow-md border border-gray-600 rounded-md z-10">
                  <label className="bg-red-600 text-white px-2 py-1 text-xs rounded-md">
                    Zayıf
                  </label>
                  <hr className="my-2" />
                  <div className="flex flex-col gap-1 text-xs font-poppins">
                    <div className="flex items-center gap-2">
                      {formik.values.password.length >= 8 ? (
                        <FaCheckCircle color="#00b341" />
                      ) : (
                        <FaTimesCircle color="#ff0000" />
                      )}
                      <span>En az 8 karakter</span>
                    </div>
                    <div className="flex items-center gap-2">
                      {formik.values.password.length <= 20 ? (
                        <FaCheckCircle color="#00b341" />
                      ) : (
                        <FaTimesCircle color="#ff0000" />
                      )}
                      <span>En fazla 20 karakter</span>
                    </div>
                  </div>
                </div>
              )}
            {formik.values["password"].length > 20 && (
              <div className="absolute bottom-0 translate-y-full p-2 w-full bg-white shadow-md border border-gray-600 rounded-md z-10">
                <label className="bg-green-600 text-white px-2 py-1 text-xs rounded-md">
                  Güçlü
                </label>
                <hr className="my-2" />
                <div className="flex flex-col gap-1 text-xs font-poppins">
                  <div className="flex items-center gap-2">
                    {formik.values.password.length >= 8 ? (
                      <FaCheckCircle color="#00b341" />
                    ) : (
                      <FaTimesCircle color="#ff0000" />
                    )}
                    <span>En az 8 karakter</span>
                  </div>
                  <div className="flex items-center gap-2">
                    {formik.values.password.length <= 20 ? (
                      <FaCheckCircle color="#00b341" />
                    ) : (
                      <FaTimesCircle color="#ff0000" />
                    )}
                    <span>En fazla 20 karakter</span>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-y-4">
            <div className="flex flex-wrap items-center gap-y-1">
              <CustomFormField
                type="checkbox"
                label={
                  <span className="text-[10px]">
                    Verilerimin{" "}
                    <Link
                      to="/kurumsal/acik-riza-metni"
                      target="_blank"
                      rel="noreferrer"
                      className="text-[10px] text-mains underline underline-offset-4"
                    >
                      Açık Rıza Metni
                    </Link>
                    'nde belirtilen şekilde işlenmesine onay veriyorum.
                  </span>
                }
                formik={formik}
                name="readKVKK"
              />
              {formik.errors.readKVKK && formik.touched.readKVKK ? (
                <p className="text-red-500 text-[10px] font-poppins">
                  {String(formik.errors.readKVKK)}
                </p>
              ) : null}
              <CustomFormField
                type="checkbox"
                label={
                  <span className="text-[10px]">
                    İletişim bilgilerime e-ileti gönderilmesine izin veriyorum.
                  </span>
                }
                formik={formik}
                name="permissionMail"
              />

              <p className="text-[10px] text-gray-500 font-poppins">
                Üye Ol butonuna tıklayarak{" "}
                <Link
                  to="/kurumsal/uyelik-sozlesmesi-5"
                  className="underline text-mains underline-offset-4"
                  target="_blank"
                  rel="noreferrer"
                >
                  Hizmet Sözleşmesi
                </Link>
                'ni ve{" "}
                <Link
                  to="/kurumsal/kvkk-metni-8"
                  target="_blank"
                  className="underline text-mains underline-offset-4"
                >
                  Aydınlatma Metni
                </Link>
                'ni kabul etmiş olursunuz.
              </p>
            </div>
            <div className="flex flex-col items-center justify-between w-full">
              <button
                onClick={formik.handleSubmit as any}
                type="submit"
                className="w-full bg-[#195edd] flex rounded-sm items-center justify-center h-10"
              >
                <span
                  onClick={() => handleAlert()}
                  className="text-lg text-white poppins-medium w-full h-full flex items-center justify-center"
                >
                  ÜYE OL
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default RegisterProfile;
