import * as React from "react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  StarIcon,
} from "@heroicons/react/20/solid";
import { mockPackagesBodyContent } from "../../../../../containers/login-page-container/company/login/mocks";
import { InfoItem } from "../../info-item";
import { CustomButton } from "../../custom-button";
import { ICustomCard } from "../../../../../containers/login-page-container/company/login/types/ICustomCard";
import { useNavigate } from "react-router-dom";
import { useCookie } from "../../../../../hooks/useCookie";
import { CookieName } from "../../../../../constants/enums";
import Swal from "sweetalert2";

function CustomCardMobile(
  props: ICustomCard & { isExpanded: boolean; onToggleExpand: () => void }
): React.ReactElement {
  const [contentHeight, setContentHeight] = React.useState(0);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const { getCookie } = useCookie();
  const companyUserToken = getCookie(CookieName.CompanyUserToken);

  React.useEffect(() => {
    if (props.isExpanded && contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight + 1);
    } else {
      setContentHeight(0);
    }
  }, [props.isExpanded]);

  const handleBuyClick = () => {
    if (companyUserToken) {
      navigate(`/isveren/paket/${props.id}/satin-al`);
    } else {
      Swal.fire({
        title: "Paket satın almak için lütfen giriş yapın.",
        icon: "warning",
        cancelButtonText: "İptal",
        confirmButtonText: "Giriş Yap",
        confirmButtonColor: "#2253af",
        showCancelButton: true,
        customClass: {
          confirmButton: "custom-buy-confirm-button bg-mains",
          cancelButton: "custom-buy-cancel-button",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/isveren/giris");
        }
      });
    }
  };

  return (
    <div className="relative flex flex-col w-full bg-mains text-white border rounded-md select-none border-white">
      <div
        className="flex items-center justify-between w-full gap-4 px-5 pt-3 pb-3 cursor-pointer md:flex-row"
        onClick={props.onToggleExpand}
      >
        <div className="flex flex-col gap-y-1">
          <h2 className="text-lg text-white poppins-bold">{props.name}</h2>
          <p className="text-xs poppins-medium text-gray-200">
            12 Adet 30 Günlük İlan
          </p>
          <p className="text-xs text-gray-200 poppins-medium">
            Adet Fiyatı : 449.50 ₺
          </p>
        </div>
        <div className="flex flex-col gap-x-5">
          <div className="flex flex-row justify-between gap-10 md:items-center">
            <div className="flex flex-col">
              <div className="flex items-center justify-center whitespace-nowrap">
                <span className="text-base text-white poppins-bold">
                  {props.prices} ₺
                </span>
                <span className="text-md text-white poppins-medium">
                  {" "}
                  + KDV
                </span>
              </div>
            </div>
            <div className="flex flex-row justify-between gap-4 md:items-center">
              <button>
                {props.isExpanded ? (
                  <ChevronUpIcon className="w-6 h-6 text-white" />
                ) : (
                  <ChevronDownIcon className="w-6 h-6 text-white" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        ref={contentRef}
        className="overflow-hidden duration-300 ease-in-out transition-height"
        style={{ height: props.isExpanded ? `${contentHeight}px` : "0px" }}
      >
        <div className="first:w-full first:bg-center first:rounded-md first:bg-mains/30 first:border-dashed first:border-b first:border-[1px] first:mb-2" />
        <div className="flex flex-col px-5 gap-x-5 gap-y-2">
          <InfoItem label="İlan Hakkı" value={props.advert as number} />
          <InfoItem label="Doping Hakkı" value={props.doping as number} />
          <InfoItem label="CV Hakkı" value={props.cv as number} />
          <InfoItem label="Manşet Hakkı" value={props.cuff as number} />
          {mockPackagesBodyContent.map((item, i) => {
            return (
              <div
                key={`contentItem-${i}`}
                className="flex flex-row items-start gap-2"
              >
                <item.icon
                  size={20}
                  color={`${item.icon === StarIcon ? "gold" : "white"}`}
                />
                <label className="font-poppins text-xs">{item.text}</label>
              </div>
            );
          })}
        </div>
        <div className="px-5">
          <CustomButton
            text={"Satın Al"}
            className="py-2 text-white bg-company-card text-lg poppins-semibold w-full h-[40px] rounded-md mb-2 mx-auto mt-2"
            // onClick={() => navigate(`/isveren/paket/${props.id}/satin-al`)}
            onClick={() => handleBuyClick()}
          />
        </div>
      </div>
    </div>
  );
}

export { CustomCardMobile };
