import CustomInput from "../custom-input";
import CustomButton from "../custom-button";
import { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { IoIosArrowUp } from "react-icons/io";
import Axios from "../../../../../services/axios";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserInfo,
  postUserInfo,
  sendUserVerifySms,
  sendUserVerifyEmail,
  updatePhone,
} from "../../../../../store/content-reducer/personInfoSlice";
import { toast } from "react-toastify";
import { showSmsContent } from "../../../../../store/content-reducer/smsPageSlice";
import Swal from "sweetalert2";
import "react-toastify/dist/ReactToastify.css";

const axiosInstance = new Axios();

interface IFormData {
  name: string;
  surname: string;
  email: string;
  phone: string;
  phone_code: string;
}

const PersonalInfo = () => {
  const dispatch = useDispatch<any>();
  const userInfo: any = useSelector<any>((state: any) => state.userInfo);
  const [iconChange, setIconChange] = useState<boolean>(false);
  const [formData, setFormData] = useState<IFormData>({
    name: "",
    surname: "",
    email: "",
    phone: "",
    phone_code: "",
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [emailError, setEmailError] = useState<boolean>(false);

  useEffect(() => {
    const fetchUserInfo = async () => {
      await dispatch(getUserInfo());
      setLoading(false);
    };
    fetchUserInfo();
  }, [dispatch]);

  useEffect(() => {
    if (userInfo) {
      setFormData({
        name: userInfo.name || "",
        surname: userInfo.surname || "",
        email: userInfo.email || "",
        phone: userInfo.phone || "",
        phone_code: userInfo.phone_code || "",
      });
    }
  }, [userInfo]);

  const handleClick = (): void => {
    setIconChange(!iconChange);
  };

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    const missingFields = [];
    if (!formData.name) missingFields.push("Ad Alanı Zorunludur!");
    if (!formData.surname) missingFields.push("Soyisim Alanı Zorunludur!");
    if (!formData.email) {
      missingFields.push("E-Posta Alanı Zorunludur!");
      setEmailError(true);
    } else if (!validateEmail(formData.email)) {
      missingFields.push("Geçerli bir E-mail adresi girilmelidir!");
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (!formData.phone)
      missingFields.push("Telefon Numarası Alanı Zorunludur!");

    if (missingFields.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Eksik Alanlar:",
        html: missingFields.join("<br>"),
      });
      return;
    }

    setTimeout(() => {
      dispatch(postUserInfo(formData));
    }, 500);
  };

  const handleChange = (field: string, value: string): void => {
    setFormData({
      ...formData,
      [field]: value !== "" ? value.toString() : "",
    });

    if (field === "email") {
      setEmailError(!validateEmail(value));
    }
  };

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const verifyEmail = async (): Promise<void> => {
    const email = { email: formData.email };
    if (formData.email === "") {
      setEmailError(true);
      toast.error("Email adresi doğru bir şekilde girilmelidir!", {
        className: "toast-error-message",
      });
    } else if (!validateEmail(formData.email)) {
      setEmailError(true);
      toast.error("Geçerli bir E-mail adresi girilmelidir!", {
        className: "toast-error-message",
      });
    } else {
      setEmailError(false);
      const resultAction = await dispatch(sendUserVerifyEmail(email));
      if (resultAction.type === sendUserVerifyEmail.fulfilled.type) {
        toast.success("Doğrulama e-postası e-mail adresinize gönderildi!");
      } else {
        toast.error("Bir hata oluştu!", {
          className: "toast-error-message",
        });
      }
    }
  };

  const verifySms = async (): Promise<void> => {
    const phone = { phone: formData.phone };
    if (formData.phone.length < 10 || formData.phone.length > 11) {
      toast.warning("Telefon numarası doğru bir şekilde girilmelidir!");
      return;
    }
    if (formData.phone !== "") {
      const resultAction = await dispatch(sendUserVerifySms(phone));
      if (resultAction.type === sendUserVerifySms.fulfilled.type) {
        toast.success("Doğrulama sms'i telefonunuza gönderildi!");
        dispatch(showSmsContent());
        dispatch(updatePhone({ phone: formData.phone })); // Update phone in the state
      } else {
        toast.error("Bir hata oluştu!", {
          className: "toast-error-message",
        });
      }
    } else {
      toast.warning("Telefon numarası doğru bir şekilde girilmelidir!");
    }
  };

  const isFieldEmpty = (field: string): boolean => field === "";

  if (loading) {
    return <div>Loading...</div>; // You can customize this loading state
  }

  return (
    <div className="flex flex-col mb-2 border-t-2 border-gray-200">
      <form
        onSubmit={handleSubmit}
        className="flex justify-between gap-2 mt-4 person-info"
      >
        <div className="flex flex-col gap-3">
          <div className="flex gap-3 mt-2 person-info">
            <CustomInput
              design={`w-[100%] ${
                isFieldEmpty(formData.name) ? "bg-red-100 border-red-500" : ""
              }`}
              title="Ad"
              onChange={(value) => handleChange("name", value)}
              defaultValue={formData?.name}
            />
            <CustomInput
              design={`w-[100%] ${
                isFieldEmpty(formData.surname)
                  ? "bg-red-100 border-red-500"
                  : ""
              }`}
              title="Soyad"
              onChange={(value) => handleChange("surname", value)}
              defaultValue={formData?.surname}
            />
          </div>
          <div className="flex gap-3 my-2 person-info">
            <CustomInput
              design={`w-[100%] ${
                emailError || isFieldEmpty(formData.email)
                  ? "bg-red-100 border-red-500"
                  : ""
              }`}
              title="E-Posta Adresi"
              onChange={(value) => handleChange("email", value)}
              defaultValue={formData?.email}
            />
            <div>
              <CustomButton
                title="Doğrula"
                designs="bg-gray-400 h-[35px] text-sm/[45px] hover:text-white sm:w-[120px] custom-btn"
                btnType="button"
                handleClick={verifyEmail}
              />
            </div>
          </div>
          <div className="flex justify-between gap-3">
            {/* <SelectSearchDropdown
            required
            >
              <SelectSearchDropdown.Label>
                Ülke Kodu
              </SelectSearchDropdown.Label>
            </SelectSearchDropdown> */}
            <CustomInput
              title="Türkiye +90"
              design={`w-[100%] border-blue-300 text-blue-300 pl-10`}
              // onIconClick={handleClick}
              // icon={iconChange ? FaChevronDown : IoIosArrowUp}
              onChange={(value) => handleChange("phone_code", value)}
              defaultValue={userInfo?.phone_code}
              disabled={true}
              iconLeft={
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b4/Flag_of_Turkey.svg/220px-Flag_of_Turkey.svg.png"
                  alt="Turkish Flag"
                  className="w-[22px] h-4 object-cover"
                />
              }
            />
            <CustomInput
              title="Telefon Numarası"
              design={`w-[100%] ${
                isFieldEmpty(formData.phone) ? "bg-red-100 border-red-500" : ""
              } custom-input`}
              type="text"
              onChange={(value) => handleChange("phone", value)}
              defaultValue={
                formData?.phone ? formData.phone : "(5__) ___ __ __"
              }
              maxLength={30}
            />
            <div>
              <CustomButton
                title="Doğrula"
                designs="bg-gray-400 h-[35px] text-sm/[45px] hover:text-white w-[120px] custom-btn sm:flex hidden"
                btnType="button"
                handleClick={verifySms}
              />
            </div>
          </div>
          <CustomButton
            title="Doğrula"
            designs="bg-gray-400 h-[35px] text-sm/[45px] hover:text-white sm:w-[120px] custom-btn flex sm:hidden"
            btnType="button"
            handleClick={verifySms}
          />
          <div className="flex my-8">
            <p className="w-full text-gray-700">
              Ad, soyad ve e-posta bilgilerinde değişiklik yaptığında, tüm
              özgeçmişlerin güncellenecektir.
            </p>
          </div>
        </div>
        <div className="flex items-end justify-end">
          <CustomButton
            title="KAYDET"
            btnType="submit"
            designs="py-2 px-3 w-full"
          />
        </div>
      </form>
    </div>
  );
};

export default PersonalInfo;
