import React from 'react';


import { PersonalInformation } from '../../../../../components';



const PersonelInfo: React.FC = () => {



  return (
    <>
      <PersonalInformation />
    </>
  );
};

export default PersonelInfo;
