import classNames from "classnames"
import { Dispatch, HTMLAttributes, SetStateAction, createContext, useState } from "react"
import Field from "./Field"
import Label from "./Label"
import Error from "./Error"


type modeType = 'dirty' | 'empty'


// Component props type
type textareaPropsType = HTMLAttributes<HTMLDivElement> & {
    mode?: modeType,
    required?: boolean,
    error?: boolean,
    value?: string | number,
    setValue?: Dispatch<SetStateAction<string | number>>
}


export type textareaContextType = {
    mode?: modeType,
    required?: boolean,
    error?: boolean,
    value: string | number,
    setValue: Dispatch<SetStateAction<string | number>>
}


export const TextareaContext = createContext<textareaContextType>({
    mode: 'empty',
    required: false,
    error: false,
    value: '',
    setValue: () => {}
})



export function Textarea({
    mode = 'empty',
    required = false,
    error = false,
    value: initialValue,
    setValue: initialSetValue,
    className,
    ...props
}: textareaPropsType) {

    const [value, setValue] = useState<string | number>('')

    const cn = classNames({
        'flex flex-col gap-1': true
    })

    const contextValue: textareaContextType = {
        value: initialValue != undefined? initialValue: value,
        setValue: initialSetValue != undefined? initialSetValue: setValue,
        error,
        mode,
        required
    }
    return(
        <TextareaContext.Provider value={contextValue}>
            <div
            className={`${cn} ${className}`}
            {...props}
            >

            </div>
        </TextareaContext.Provider>
    )
}

Textarea.Field = Field
Textarea.Label = Label
Textarea.Error = Error