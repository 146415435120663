import React from "react";
import {
  IconRosette,
  IconBriefcase,
  IconCash,
  IconFileText,
  IconArrowBadgeRight,
} from "@tabler/icons-react";

const Plan: React.FC = () => {
  return (
    <div className="bg-white">
      <div className="container mx-auto md:w-[65%] px-4 py-10">
        <div className="flex flex-col lg:flex-row justify-center items-center gap-x-10 gap-y-6">
          <div className="flex flex-col w-full lg:w-3/6 gap-y-8">
            <div>
              <p className="text-3xl font-medium text-gray-700">
                Geleceğini Planla
              </p>
              <p className="mt-2 text-base text-gray-700">
                Kariyerini planlamaya hazır mısın? Üniversite tercihinden
                itibaren kariyer yolculuğunun her adımını titizlikle
                planlayabileceğin içerikleri senin için bir araya getirdik.
                Merak ettiğin tüm sorulara yanıt bulacak, iş fırsatlarını
                keşfedeceksin!
              </p>
            </div>
            <div className="flex flex-col gap-y-3">
              {buttons.map((button) => (
                <a href={button.link} target="blank" key={button.text}>
                  <button className="flex items-center gap-x-2 pl-5 bg-gray-200 hover:bg-orange-600 w-full h-14 border border-gray-300 group">
                    <button.icon className="group-hover:text-white transition-all" />
                    <span className="flex-1 font-medium text-gray-700 group-hover:text-white text-left transition-all">
                      {button.text}
                    </span>
                    <IconArrowBadgeRight className="w-12 group-hover:pl-2 group-hover:text-white transition-all duration-300" />
                  </button>
                </a>
              ))}
            </div>
          </div>
          <div className="w-full lg:w-3/6 lg:block hidden">
            <img
              alt="Career Planning"
              className="object-cover w-full h-auto"
              src="https://isbul.net/frontend/images/home/career.webp"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const buttons = [
  {
    icon: IconRosette,
    text: "Hangi bölümde seni ne bekliyor?",
    link: "bolumler",
  },
  {
    icon: IconBriefcase,
    text: "Pozisyonların iş tanımları neler?",
    link: "pozisyonlar",
  },
  {
    icon: IconCash,
    text: "Meslek grupları ne kadar maaş alıyor?",
    link: "pozisyonlar",
  },
  {
    icon: IconFileText,
    text: "Sana özel CV şablonları burda!",
    link: "cv-sablonlari",
  },
];

export default Plan;
