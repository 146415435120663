import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Advert tipi
interface Advert {
  advertId: number;
  advertTitle: string;
  companyName: string;
  workingTypes: any[];
  workingType: string;
  cities: any[];
  city: string;
  companyLogo: string;
  createdAt: string;
  isSponsored: boolean;
  is_disabled_employee: number;
  is_auth: boolean;
  is_favorite: boolean;
  is_view: boolean;
}

interface AdvertState {
  adverts: Advert[];
}

const initialState: AdvertState = {
  adverts: [],
};

const advertSlice = createSlice({
  name: "advert",
  initialState,
  reducers: {
    setAdverts: (state, action: PayloadAction<Advert[]>) => {
      state.adverts = action.payload;
    },
    // Diğer action creator'lar buraya eklenebilir
  },
});

export const { setAdverts } = advertSlice.actions;

export default advertSlice.reducer;
