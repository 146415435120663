import { useEffect, useState } from "react";
import Axios from "../../../../../services/axios";
import { dbUserExamType } from "../../../../../types";
import { useParams } from "react-router-dom";
import ExamForm from "./components/ExamForm";
import { PiWarningCircle } from "react-icons/pi";
import { useContext } from "react";
import { CvLayoutContext, cvLayoutContextType } from "..";



// Başka bir sayfada kullanılıyor o yüzden silmeyin
export const mockGradYear = [
  {
    label: 2013,
  },
  {
    label: 2014,
  },
]


const axios = new Axios()


export default function Exams({}) {

  const { isEnglish }: cvLayoutContextType = useContext(CvLayoutContext)

  const params = useParams()
  const cvId = params.slug
  const [exams, setExams] = useState<dbUserExamType[]>([])
  const [examsLength, setExamsLength] = useState<number>(1)
  const [isAddClicked, setIsAddClicked] = useState<boolean>(false)

  async function getAllExams() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/ex/index?cv_id=${cvId}`,
        type: 'get',
        payload: {}
      })

      if (response && response.data && response.data.exams) {
        setExams(response.data.exams)
        setExamsLength(response.data.exams.length)
      }
    } catch (error) {
      console.log(`Exams fetch failed. Error: ${error}`)
    }
  }

  useEffect(() => {
    getAllExams()
  }, [])

  return (
    <div className=" flex flex-col gap-y-2 rounded-md bg-white pt-2 pb-2">
      {/* Empty exam form */}
      <ExamForm 
      exam={null}
      getAllExams={getAllExams}
      examsLength={examsLength}
      isAddClicked={isAddClicked}
      setIsAddClicked={setIsAddClicked}
      />

      {/* Empty exam message */}
      {exams.length == 0 && (
        <div className="flex flex-row gap-4 m-4 border border-gray-200 rounded-md">
          <div className="flex p-4 bg-red-600 rounded-l-md items-center justify-center">
            <PiWarningCircle className="text-3xl text-white"/>
          </div>
          <div className="flex flex-col gap-1 py-2 px-1">
            <p className="font-poppins text-gray-700 font-semibold text-sm">
              {isEnglish? "You don't have any exams added yet.": 'Henüz eklenmiş bir sınavınız yok.'}
            </p>
            {isEnglish? (
              <p className="font-poppins text-sm text-gray-700 font-semibold">
                You can add a new exam with
                {' '}
                <button className="text-mains underline" onClick={() => setIsAddClicked(true)}>Add Exam</button> 
                {' '}
                button.
              </p>
            ): (
              <p className="font-poppins text-sm text-gray-700 font-semibold">
                <button className="text-mains underline" onClick={() => setIsAddClicked(true)}>Sınav Ekle</button> butonuna tıklayarak hemen yeni bir sınav bilgisi ekleyebilirsiniz.
              </p>
            )}
          </div>
        </div>
      )}

      {/* All exams mapping */}
      {exams.map((exam) => {
        return(
          <div className="flex flex-col gap-3 lg:gap-0" key={`examForm${exam.id}`}>
            <hr/>
            <ExamForm 
            exam={exam}
            getAllExams={getAllExams}
            />
          </div>
        )
      })}
    </div>
  );
}

