import { LabelHTMLAttributes } from "react";
import { useContext } from "react";
import { RadioContext, radioContextType } from ".";

type labelPropsType = LabelHTMLAttributes<HTMLLabelElement> & {};

export default function RadioLabel({
  className,
  children,
  ...props
}: labelPropsType) {
  const { required }: radioContextType = useContext(RadioContext);

  return (
    <label
      className={`${className} text-sm text-gray-500 font-poppins`}
      {...props}
    >
      {children} {required ? <b className="text-red-600">*</b> : null}
    </label>
  );
}
