import React, { useEffect, useState, useRef, useContext } from "react";
import { MdOutlineSearch } from "react-icons/md";
import { IconChevronDown, IconMapPin, IconSearch } from "@tabler/icons-react";
import { IoSettingsSharp } from "react-icons/io5";
import { CiFilter } from "react-icons/ci";
import { ITitle, IItem, IName, IData } from "../../types";
import { RiFilter2Line, RiMapPinUserLine, RiSave2Line } from "react-icons/ri";
import { IoMdCloseCircle } from "react-icons/io";
import Cookies from "js-cookie";
import usePartialBold from "../../../../../customHooks/usePartialBold";
import { handleSearchChange } from "../components/components/search-component";
import { CandidatePoolContext, CandidatePoolFilterProps } from "../../index";
import { SearchComponentHeader } from "../components/components/search-component-header-new";
import useFetchCdnQuery from "../../../../../hooks/useFetchCdnQuery";
import services from "../../../../../services/login-services";
import Swal from "sweetalert2";

const ElemannetTriggerSection: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [showSavedSearchesDropdown, setShowSavedSearchesDropdown] =
    useState(false);

  const {
    newSelectedValues,
    setNewSelectedValues,
    handleFilter,
    detailFiltersOpen,
    setDetailFiltersOpen,
    savedSearchFilters,
    setShowFilterSaveButton,
    setSyncCounter,
  } = useContext(CandidatePoolContext);

  //fetch cities
  const [cityData, setCityData] = useState<any>([]);
  const [citySearchTerm, setCitySearchTerm] = useState("");
  const citiesQuery = useFetchCdnQuery(
    "cities-or-districts",
    "get-cities-or-districts",
    "cities_or_districts",
    citySearchTerm,
    "20",
    (newSelectedValues.city
      ? `&city_include_ids=[${newSelectedValues.city}]`
      : "") +
      (newSelectedValues.district
        ? `&district_include_ids=[${newSelectedValues.district}]`
        : "") +
      "&except_ids=[7795]"
  );
  useEffect(() => {
    if (citiesQuery.data !== undefined) {
      setCityData([...citiesQuery?.data]);
    }
  }, [citiesQuery.data, citiesQuery.fetchStatus]);

  //fetch positions
  const [positionsData, setPositionsData] = useState<any>([]);
  const [positionSearchTerm, setPositionSearchTerm] = useState("");
  const positionsQuery = useFetchCdnQuery(
    "positions",
    "get-positions",
    "positions",
    positionSearchTerm,
    "20",
    newSelectedValues.position
      ? `&include_ids=[${newSelectedValues.position}]`
      : ""
  );
  useEffect(() => {
    if (positionsQuery.data !== undefined) {
      setPositionsData([...positionsQuery?.data]);
    }
  }, [positionsQuery.data, positionsQuery.fetchStatus]);

  const toggleSavedSearchesDropdown = () => {
    setShowSavedSearchesDropdown(!showSavedSearchesDropdown);
  };

  function generateLinkWithSortedParams(params: string) {
    const paramString = new URLSearchParams(JSON.parse(params)).toString();
    return `/isveren/aday-havuzu?${paramString}`;
  }

  const handleNearby = () => {
    services
      .checkTokenCompany()
      .then((response) => {
        setNewSelectedValues({
          city: response.data.company.company_detail.city_id,
          district: response.data.company.company_detail.district_id,
        });
        setSyncCounter((prev) => prev + 1);
      })
      .catch(() => {
        Swal.fire({
          title: "Hata",
          text: "Lokasyon verisi alınırken bir hata oluştu",
          icon: "error",
        });
      });
  };

  return (
    <section className="relative flex justify-center bg-mains h-auto  shadow-lg mb-3 ">
      <div className="flex flex-col w-full max-w-[1200px] justify-start h-auto z-10">
        <div className="flex mx-10 ">
          <div className={"flex w-10/12"}>
            <div className="flex flex-col gap-3 py-4 w-full">
              <div className="flex flex-col items-start">
                <h1 className="text-white font-semibold text-3xl mt-14">
                  Aday Havuzunda Ara
                </h1>
              </div>

              <div className="relative">
                <div className={`flex h-14 divide-x-4 divide-[#194599]`}>
                  <SearchComponentHeader
                    AccordionContentisOpen={detailFiltersOpen}
                    isRadius
                    data={positionsData}
                    placeholder={"Örnek : Satış, Muhasebe, Mühendislik..."}
                    label={""}
                    keyString={"title"}
                    onChange={(value) => {
                      let newValues: CandidatePoolFilterProps = {
                        ...newSelectedValues,
                        position: value.id,
                      };
                      if (value.id === 0) {
                        delete newValues.position;
                      }
                      setNewSelectedValues(newValues);
                    }}
                    selectedvalue={newSelectedValues.position}
                    searchData={positionSearchTerm}
                    setSearchData={setPositionSearchTerm}
                  />
                  {!detailFiltersOpen && (
                    <SearchComponentHeader
                      isCity
                      AccordionContentisOpen={detailFiltersOpen}
                      data={cityData}
                      placeholder={"Şehir veya İlçe Ara"}
                      label={""}
                      keyString={"name"}
                      onChange={(value) => {
                        if (value.id === 0) {
                          let newValues: CandidatePoolFilterProps = {
                            ...newSelectedValues,
                          };
                          delete newValues.city;
                          delete newValues.district;
                          setNewSelectedValues(newValues);
                          return;
                        }
                        if (value.type === "city") {
                          let newValues: CandidatePoolFilterProps = {
                            ...newSelectedValues,
                            city: value.id,
                          };
                          if (value === 0) {
                            delete newValues.city;
                          }
                          setNewSelectedValues(newValues);
                        } else {
                          let newValues: CandidatePoolFilterProps = {
                            ...newSelectedValues,
                            city: value.city_id,
                            district: value.id,
                          };
                          if (value.id === 0) {
                            delete newValues.city;
                            delete newValues.district;
                          }
                          setNewSelectedValues(newValues);
                        }
                      }}
                      selectedvalue={
                        newSelectedValues.district
                          ? newSelectedValues.district
                          : newSelectedValues.city
                      }
                      searchData={citySearchTerm}
                      setSearchData={setCitySearchTerm}
                    />
                  )}

                  <div className="w-1/6 h-full">
                    <button
                      onClick={() => {
                        setShowFilterSaveButton(true);
                        handleFilter();
                      }}
                      className="flex items-center justify-center w-full h-full bg-zinc-800 gap-x-2 rounded-r-md"
                    >
                      <IconSearch className="text-gray-50" />
                      <span className="text-lg uppercase poppins-light text-gray-50">
                        Ara
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="flex flex-row">
                <button
                  onClick={() => {
                    handleNearby();
                  }}
                  className="text-white ml-3 text-[12px] mb-2 mt-1 flex items-center gap-1"
                >
                  <RiMapPinUserLine className="text-white" />
                  <p> Yakınımdaki Adaylar</p>
                </button>
                <button
                  onClick={() => {
                    setDetailFiltersOpen(!detailFiltersOpen);
                  }}
                  className="text-white ml-3 text-[12px] mb-2 mt-1 flex items-center gap-1"
                >
                  <RiFilter2Line className="text-white " />
                  <p> Detaylı Ara</p>
                  <IconChevronDown
                    size={15}
                    className={`scale-75 !transition-transform duration-300 transform ${
                      detailFiltersOpen ? "rotate-180" : ""
                    }`}
                  />
                </button>

                <div className="relative" ref={containerRef}>
                  <button
                    onClick={toggleSavedSearchesDropdown}
                    className="text-white ml-3 text-[12px] mb-2 mt-1 flex items-center gap-1"
                  >
                    <RiSave2Line className="text-white" />
                    <p> Kayıtlı Aramalarım</p>
                    <IconChevronDown
                      size={15}
                      className={`scale-75 !transition-transform duration-300 transform ${
                        showSavedSearchesDropdown ? "rotate-180" : ""
                      }`}
                    />
                  </button>
                  {showSavedSearchesDropdown && (
                    <div className="absolute bg-[#1E4A9E] border-2 mt-1 rounded-lg scroll-hidden shadow-lg z-10 max-h-60 overflow-y-auto w-72">
                      <div className="flex justify-between p-2 bg-gray-100 border-b">
                        <div className="flex gap-2 items-center content-center">
                          <RiSave2Line size={24} className="text-[#1E4A9E]" />
                          <span className="text-center font-bold capitalize">
                            Kayıtlı Aramalarım
                          </span>
                        </div>
                        <button
                          onClick={() => setShowSavedSearchesDropdown(false)}
                        >
                          <IoMdCloseCircle size={24} />
                        </button>
                      </div>
                      {savedSearchFilters.length > 0 ? (
                        savedSearchFilters.map((search, i) => (
                          <a
                            key={i}
                            href={generateLinkWithSortedParams(
                              JSON.stringify(search.params)
                            )}
                          >
                            <div
                              key={search.id}
                              className="p-2 hover:bg-white text-white text-sm hover:text-[#1E4A9E] cursor-pointer "
                            >
                              {search.title}
                            </div>
                          </a>
                        ))
                      ) : (
                        <div className="p-2 text-white">
                          Kayıtlı arama bulunamadı
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ElemannetTriggerSection;
