import Axios from "../axios/index";

const axios = new Axios();

const services = {
  checkTokenUser: async () => {
    return await axios.userAuthRequest({
      type: "get",
      endpoint: "user/profilim/token",
      payload: {},
    });
  },

  checkTokenCompany: async () => {
    return await axios.authRequest({
      type: "get",
      endpoint: "companies/get-company",
      payload: {},
    });
  },

  companyLogin: async (userData: any) => {
    return await axios.request({
      type: "post",
      endpoint: "companies/login",
      payload: userData,
    });
  },

  loginUser: async (userData: any) => {
    return await axios.request({
      type: "post",
      endpoint: "user/profilim/login",
      payload: userData,
    });
  },

  forgotPasswordEmail: async (email: any) => {
    return await axios.request({
      type: "post",
      endpoint: "user/profilim/send-forgot-email",
      payload: { email },
    });
  },

  forgotPasswordSMS: async (phone: any) => {
    return await axios.request({
      type: "post",
      endpoint: "user/profilim/send-forgot-sms",
      payload: { phone },
    });
  },
};
export default services;
