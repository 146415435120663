import React, { useState, useEffect } from "react";
import { List, Row, Col } from "antd";
import { FaRegEye } from "react-icons/fa";
import Axios from "../../../services/axios";
import { FiAlertCircle } from "react-icons/fi";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

interface DataType {
  id: number;
  company_id: number;
  company_name: string;
  company_slug: string;
  cv_view_count: number;
  candidate_view_count: number;
  apply_view_count: number;
  last_cv_view_date: string;
}

const Body: React.FC = () => {
  const axios = new Axios();

  const [data, setData] = useState<DataType[]>([]);

  useEffect(() => {
    const getViewsCompany = async () => {
      try {
        const response = await axios.userAuthRequest({
          endpoint: "user/profilim/cv-views/get-cv-views",
          type: "get",
          payload: {},
        });

        console.log(response.data.data);
        setData(response.data.data);
      } catch (error) {
        console.log(error);
      }
    };

    getViewsCompany();
  }, []);

  const dateFormat = (date: string) => {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString("tr-TR", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const userToken = Cookies.get("userToken");

  const [cvId, setCvId] = useState<number | null>(null);

  useEffect(() => {
    if (userToken) {
      const fetchCv = async () => {
        try {
          const response = await axios.request({
            endpoint: `user/profilim/cv`,
            type: "get",
            payload: {},
          });

          if (response?.data?.data) {
            setCvId(response.data.data.id);
          }
        } catch (error) {
          console.log("Error fetching data:", error);
        }
      };

      fetchCv();
    }
  }, [userToken]);

  const handleChangeData = async () => {
    try {
      const response = await axios.userAuthRequest({
        type: "put",
        endpoint: `user/profilim/cv/${cvId}/update-date`,
        payload: "",
      });

      if (response.data) {
        Swal.fire({
          icon: "success",
          text: "CV tarihi başarıyla güncellendi.",
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "sweet-alert-button",
          },
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="w-full flex flex-col">
      <div className="flex items-center justify-between w-full px-4 py-5 bg-white rounded-md shadow-lg">
        <span className="text-lg lg:text-xl text-mains poppins-medium">
          CV GÖRÜNTÜLEYEN FİRMALAR
        </span>
      </div>

      {data.length === 0 ? (
        <div className="flex items-stretch mt-8 mb-12 ml-4 mr-4 shadow-lg bg-white rounded-md">
          <span className="flex items-center justify-center bg-red-500  text-white p-3 rounded-l-md">
            <FiAlertCircle size={35} />
          </span>
          <p className="font-poppins text-sm poppins-medium py-4 px-3 leading-8 text-start">


            Şirketler henüz özgeçmişinizi incelemedi. Şirketlerin seni fark
            etmeleri için özgeçmişinin güncel olduğundan emin ol!{" "}


            <span
              onClick={() => handleChangeData()}
              className="px-4 py-1 bg-mains text-white font-poppins poppins-medium text-sm mt-1 ml-4 cursor-pointer whitespace-nowrap"
            >
              Cv Tarihini Güncelle
            </span>
          </p>
        </div>
      ) : (
        <>
          <div className="lg:flex hidden flex-col w-full px-4 py-5 bg-white rounded-md shadow-lg  mt-4">
            <div className="w-full mb-4 px-1">
              <div className="font-poppins poppins-medium text-gray-600 w-full flex justify-between">
                <Col span={6}>Firma İsmi</Col>
                <Col span={4}>Aday Havuzu</Col>
                <Col span={4}>Başvurular</Col>
                <Col span={4}>Son Görüntülenme</Col>
                <Col className="flex justify-end" span={4}>
                  Toplam
                </Col>
              </div>
            </div>
            <List
              className="w-full custom-list"
              itemLayout="vertical"
              dataSource={data}
              renderItem={(item) => (
                <List.Item key={item.id} className="mb-2">
                  <Row className="flex items-center justify-between font-poppins poppins-regular">
                    <Col span={6}>
                      <a
                        href={`/firma/${item.company_id}`}
                        className="text-blue-500"
                      >
                        {item.company_name}
                      </a>
                    </Col>
                    <Col span={4}>{item.candidate_view_count}</Col>
                    <Col span={4}>{item.apply_view_count}</Col>
                    <Col span={4}>{dateFormat(item.last_cv_view_date)}</Col>
                    <Col className="flex justify-end" span={4}>
                      {item.cv_view_count}
                    </Col>
                  </Row>
                </List.Item>
              )}
            />
          </div>

          <div>
            <div className="lg:hidden flex flex-col w-full  rounded-md  mt-4">
              {data.map((item) => (
                <Link
                  key={item.id}
                  to={`/firma/${item.company_id}`}
                  className="flex justify-start items-center p-4 gap-4 mb-4 bg-white shadow-md  rounded-md"
                >
                  <img
                    className="w-12 h-12 rounded-full"
                    src="https://isbull.s3.eu-north-1.amazonaws.com/v2/no-image.png"
                    alt=""
                  />

                  <div className="flex flex-col items-start justify-center gap-[1px]">
                    <p className="font-poppins poppins-medium text-sm">
                      {item.company_name}
                    </p>
                    <p className="font-poppins poppins-medium text-[10px]">
                      {dateFormat(item.last_cv_view_date)}
                    </p>
                    <p className="font-poppins poppins-medium text-[10px]">
                      Aday havuzundan ulaşıldı
                    </p>
                    <p className="font-poppins poppins-medium text-[10px] flex justify-center items-center gap-1">
                      <FaRegEye className="text-[11px] text-gray-500" />
                      {item.cv_view_count}
                    </p>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Body;
function getCookie(UserData: any) {
  throw new Error("Function not implemented.");
}
