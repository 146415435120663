import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  Button,
  InputArea,
  SelectDropdown,
  SelectSearchDropdown,
} from "../../../../../../components/isbul-ui";
import { Checkbox } from "../../../../../../components/isbul-ui/checkbox";
import {
  cdnCityType,
  cdnCountryType,
  cdnEducationLevelType,
  cdnGeneralLanguageType,
  cdnHighSchoolBranchType,
  cdnHighSchoolTypeType,
  cdnUniversitiesType,
  cdnUniversityBranchType,
  dbUserEducationType,
} from "../../../../../../types";
import { useYears } from "../../../../../../customHooks/useYears";
import { useForm, SubmitHandler } from "react-hook-form";
import { getCountries } from "../../../../../../customHooks/getCountries";
import { getCities } from "../../../../../../customHooks/getCities";
import { getGeneralLanguages } from "../../../../../../customHooks/getGeneralLanguages";
import { getHighSchoolBranches } from "../../../../../../customHooks/getHighSchoolBranches";
import { getUniversities } from "../../../../../../customHooks/getUniversities";
import { getUniversityBranches } from "../../../../../../customHooks/getUniversityBranches";
import { FaCity, FaPencilAlt, FaRegTrashAlt, FaTrashAlt } from "react-icons/fa";
import { getEducationLevels } from "../../../../../../customHooks/getEducationLevels";
import { getHighSchoolTypes } from "../../../../../../customHooks/getHighSchoolTypes";
import { IoAdd, IoTrashBinOutline } from "react-icons/io5";
import Axios from "../../../../../../services/axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { GiGraduateCap } from "react-icons/gi";
import { useIsMobile } from "../../../../../../hooks/useIsMobile";
import FormModal from "../../../../../../components/isbul-ui/form-modal";
import { useContext } from "react";
import { cvLayoutContextType, CvLayoutContext } from "../..";
import EducationAddOrUpdateForm from "./EducationAddOrUpdateForm";

type educationFormPropsType = {
  education: dbUserEducationType | null;
  educationLevels: cdnEducationLevelType[];
  countries: cdnCountryType[];
  cities: cdnCityType[];
  universities: cdnUniversitiesType[];
  universityBranches: cdnUniversityBranchType[];
  highSchoolTypes: cdnHighSchoolTypeType[];
  highSchoolBranches: cdnHighSchoolBranchType[];
  languages: cdnGeneralLanguageType[];
  getAllDatas?: () => void;
  educationsLength?: number;
  isAddClicked?: boolean;
  setIsAddClicked?: Dispatch<SetStateAction<boolean>>;
};

type payloadDataType = {
  cv_id: number;
  education_level_id: number;
  country_id: number | null;
  city_id: number | null;
  education_type_id: number | null;
  university_id: number | null;
  university_branch_id: number | null;
  high_school_name: string | null;
  high_school_branch_id: number | null;
  high_school_type_id: number | null;
  general_language_id: number | null;
  note_system_id: number | null;
  note: number | null;
  graduation_year: number | null;
  is_continue: boolean;
};

type noteSystemType = {
  id: number;
  value: string;
};

const noteSystem: noteSystemType[] = [
  { id: 1, value: "0-100" },
  { id: 2, value: "0-4" },
  { id: 3, value: "0-5" },
];

type educationTypeType = {
  id: number;
  name: string;
  name_en: string;
};

const educationType: educationTypeType[] = [
  { id: 1, name: "Örgün Öğretim", name_en: "Formal Education" },
  { id: 2, name: "Açık Öğretim", name_en: "Distance Education" },
  { id: 3, name: "İkinci Öğretim", name_en: "Evening Education" },
  { id: 4, name: "Uzaktan Öğretim", name_en: "Remote Education" },
];

const axios = new Axios();

export default function EducationForm({
  education,
  cities: initialCities,
  countries: initialCountries,
  educationLevels,
  highSchoolBranches: initialHighSchoolBranches,
  highSchoolTypes,
  universities: initialUniversities,
  universityBranches: initialUniversityBranches,
  languages: initialLanguages,
  getAllDatas,
  educationsLength,
  isAddClicked,
  setIsAddClicked,
}: educationFormPropsType) {
  const { showCvScore, isEnglish }: cvLayoutContextType =
    useContext(CvLayoutContext);

  const params = useParams();
  const cvId = params.slug;

  const isMobile = useIsMobile();

  // General states
  const [isEditOpen, setIsEditOpen] = useState<boolean>(
    education ? false : true
  );
  const [cities, setCities] = useState<cdnCityType[]>(initialCities);
  const [filteredCities, setFilteredCities] =
    useState<cdnCityType[]>(initialCities);
  const [countries, setCountries] =
    useState<cdnCountryType[]>(initialCountries);
  const [highSchoolBranches, setHighSchoolBranches] = useState<
    cdnHighSchoolBranchType[]
  >(initialHighSchoolBranches);
  const [universities, setUniversities] =
    useState<cdnUniversitiesType[]>(initialUniversities);
  const [universityBranches, setUniversityBranches] = useState<
    cdnUniversityBranchType[]
  >(initialUniversityBranches);
  const [languages, setLanguages] =
    useState<cdnGeneralLanguageType[]>(initialLanguages);
  const years = useYears({
    startYear: new Date().getFullYear(),
    endYear: 1974,
  });
  const [searchCountry, setSearchCountry] = useState<string>("");
  const [searchCity, setSearchCity] = useState<string>("");
  const [searchUniversity, setSearchUniversity] = useState<string>("");
  const [searchUniversityBranch, setSearchUniversityBranch] =
    useState<string>("");
  const [searchLanguage, setSearchLanguage] = useState<string>("");
  const [searchHighSchoolBranch, setSearchHighSchoolBranch] =
    useState<string>("");
  const [isPageOpen, setIsPageOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Page header add click catcher
  useEffect(() => {
    if (
      isAddClicked != undefined &&
      setIsAddClicked != undefined &&
      !education
    ) {
      if (isAddClicked) {
        setIsModalOpen(true);
        setIsAddClicked(false);
      }
    }
  }, [isAddClicked]);

  useEffect(() => {
    if (!education) {
      /* Education not exist set default selected country */
      setSelectedCountry({
        id: 1,
        name_tr: "Türkiye",
        name_en: "Turkey",
        iso: "TR",
        iso3: "TUR",
        lcole: 792,
        phone_code: 90,
        updated_at: "",
      });

      /* Education not exist set default selected language */
      setSelectedLanguage({
        id: 9,
        name: "Türkçe",
        name_en: "Turkish",
        order: 1,
        created_at: "",
        updated_at: "",
      });

      /* Education not exist set default get cities form turkey */
      getCities({ country_id: 1 }).then((data) => {
        setCities(data);
      });
    }
  }, [education]);

  // Initial props change
  // Cities
  useEffect(() => {
    setCities(initialCities);
  }, [initialCities]);

  // Countries
  useEffect(() => {
    setCountries(initialCountries);
  }, [initialCountries]);

  // High school branches
  useEffect(() => {
    setHighSchoolBranches(initialHighSchoolBranches);
  }, [initialHighSchoolBranches]);

  // Universities
  useEffect(() => {
    setUniversities(initialUniversities);
  }, [initialUniversities]);

  // University branches
  useEffect(() => {
    setUniversityBranches(initialUniversityBranches);
  }, [initialUniversityBranches]);

  // Languages
  useEffect(() => {
    setLanguages(initialLanguages);
  }, [initialLanguages]);

  // Form states
  const [selectedEducationLevel, setSelectedEducationLevel] =
    useState<cdnEducationLevelType | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<cdnCountryType | null>(
    null
  );
  const [selectedCity, setSelectedCity] = useState<cdnCityType | null>(null);
  const [selectedHighSchoolType, setSelectedHighSchoolType] =
    useState<cdnHighSchoolTypeType | null>(null);
  const [selectedHighSchoolBranch, setSelectedHighSchoolBranch] =
    useState<cdnHighSchoolBranchType | null>(null);
  const [selectedUniversityEducationType, setSelectedUniversityEducationType] =
    useState<educationTypeType | null>(null);
  const [selectedUniversity, setSelectedUniversity] =
    useState<cdnUniversitiesType | null>(null);
  const [selectedUniversityBranch, setSelectedUniversityBranch] =
    useState<cdnUniversityBranchType | null>(null);
  const [selectedLanguage, setSelectedLanguage] =
    useState<cdnGeneralLanguageType | null>(null);
  const [selectedNoteSystem, setSelectedNoteSystem] =
    useState<noteSystemType | null>(null);
  const [selectedGraduateYear, setSelectedGraduateYear] = useState<
    number | null
  >(null);
  const [isContinue, setIsCountinue] = useState<boolean>(false);

  // Is open change
  useEffect(() => {
    if (isEditOpen) {
      setSelectedCity(education?.city_level_list || null);
    }
  }, [isEditOpen]);

  // Selected Values Set
  async function setSelectedValue(education: dbUserEducationType) {
    // Education Level
    setSelectedEducationLevel(education.education_level_list || null);

    // Country
    setSelectedCountry(education.country_level_list || null);

    // City
    setSelectedCity(education.city_level_list || null);

    // Language
    setSelectedLanguage(education.general_language_list || null);

    // High school type
    if (education.high_school_type_id) {
      setSelectedHighSchoolType(education.high_school_type_list || null);
    }

    // High school branch
    if (education.high_school_branch_id) {
      setSelectedHighSchoolBranch(education.high_school_branch_list || null);
    }

    // Education type
    if (education.education_type_id) {
      const educationTypeData = educationType.find(
        (e) => e.id == education.education_type_id
      );
      setSelectedUniversityEducationType(educationTypeData ?? null);
    }

    // Univesity
    if (education.university_id) {
      setSelectedUniversity(education.university_id || null);
    }

    // University branch
    if (education.university_branch_id) {
      setSelectedUniversityBranch(education.university_branch_list || null);
    }

    // Note system
    if (education.note_system_id) {
      const data = noteSystem.find((n) => n.id == education.note_system_id);
      setSelectedNoteSystem(data ?? null);
    }

    // Graduate year
    if (education.graduation_year) {
      setSelectedGraduateYear(education.graduation_year);
    }

    // School name
    if (education.high_school_name) {
      setValue("high_school_name", education.high_school_name);
    }

    // Note
    if (education.note) {
      setValue("note", education.note);
    }

    // Continue
    setIsCountinue(education.is_continue == 1 ? true : false);
  }
  useEffect(() => {
    if (education) {
      setSelectedValue(education);
    }
  }, [education]);

  // Search actions
  // Country
  useEffect(() => {
    async function search() {
      if (searchCountry.trim().length > 0) {
        const filter = await getCountries({ search: searchCountry });
        setCountries(filter);
      } else {
        setCountries(initialCountries);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchCountry]);

  // City
  useEffect(() => {
    async function search() {
      if (searchCity.trim().length > 0 && selectedCountry) {
        const filter = await getCities({
          search: searchCity,
          country_id: selectedCountry.id,
          except_ids: [83, 7795],
        });
        setCities(filter);
        setFilteredCities(filter);
      } else {
        setFilteredCities(cities);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchCity]);

  // Languages
  useEffect(() => {
    async function search() {
      if (searchLanguage.trim().length > 0) {
        const filter = await getGeneralLanguages({ search: searchLanguage });
        setLanguages(filter);
      } else {
        setLanguages(initialLanguages);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchLanguage]);

  // High school branch
  useEffect(() => {
    async function search() {
      if (searchHighSchoolBranch.trim().length > 0) {
        const filter = await getHighSchoolBranches({
          search: searchHighSchoolBranch,
        });
        setHighSchoolBranches(filter);
      } else {
        setHighSchoolBranches(initialHighSchoolBranches);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchHighSchoolBranch]);

  // University
  useEffect(() => {
    async function search() {
      if (searchUniversity.trim().length > 0) {
        const filter = await getUniversities({ search: searchUniversity });
        setUniversities(filter);
      } else {
        setUniversities(initialUniversities);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchUniversity]);

  // University branch
  useEffect(() => {
    async function search() {
      if (searchUniversityBranch.trim().length > 0) {
        const filter = await getUniversityBranches({
          search: searchUniversityBranch,
        });
        setUniversityBranches(filter);
      } else {
        setUniversityBranches(initialUniversityBranches);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchUniversityBranch]);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm<payloadDataType>();

  const onSubmit: SubmitHandler<payloadDataType> = (data) => {
    if (!education) {
      addEducation(data);
    } else {
      saveEducation(data);
    }
  };

  // Form state change value set
  // Education level
  useEffect(() => {
    if (selectedEducationLevel) {
      setValue("education_level_id", selectedEducationLevel.id);
      clearErrors("education_level_id");
    }
  }, [selectedEducationLevel]);

  // Country
  useEffect(() => {
    if (selectedCountry) {
      setSelectedCity(null);
      setValue("country_id", selectedCountry.id);
      setValue("city_id", null);
      getCities({
        country_id: selectedCountry.id,
        except_ids: [83, 7795],
      }).then((data) => {
        setCities(data);
        setFilteredCities(data);
      });
      clearErrors("country_id");
    } else {
      setCities([]);
      setFilteredCities([]);
      setValue("city_id", null);
      setValue("country_id", null);
    }
  }, [selectedCountry]);

  // City
  useEffect(() => {
    setValue("city_id", selectedCity?.id ?? null);
    clearErrors("city_id");
  }, [selectedCity]);

  // High school type
  useEffect(() => {
    setValue("high_school_type_id", selectedHighSchoolType?.id ?? null);
    clearErrors("high_school_type_id");
  }, [selectedHighSchoolType]);

  // High school branch
  useEffect(() => {
    setValue("high_school_branch_id", selectedHighSchoolBranch?.id ?? null);
    clearErrors("high_school_branch_id");
  }, [selectedHighSchoolBranch]);

  // University type
  useEffect(() => {
    setValue("education_type_id", selectedUniversityEducationType?.id ?? null);
    clearErrors("education_type_id");
  }, [selectedUniversityEducationType]);

  // University
  useEffect(() => {
    setValue("university_id", selectedUniversity?.id ?? null);
    clearErrors("university_id");
  }, [selectedUniversity]);

  // University branch
  useEffect(() => {
    setValue("university_branch_id", selectedUniversityBranch?.id ?? null);
    clearErrors("university_branch_id");
  }, [selectedUniversityBranch]);

  // Note system
  useEffect(() => {
    setValue("note_system_id", selectedNoteSystem?.id ?? null);
    clearErrors("note_system_id");
  }, [selectedNoteSystem]);

  // Graduate year
  useEffect(() => {
    setValue("graduation_year", selectedGraduateYear);
    clearErrors("graduation_year");
  }, [selectedGraduateYear]);

  // Is continue
  useEffect(() => {
    setValue("is_continue", isContinue);
  }, [isContinue]);

  useEffect(() => {
    setValue("general_language_id", selectedLanguage?.id ?? null);
    clearErrors("general_language_id");
  }, [selectedLanguage]);

  // Custom form registers
  // Education level
  register("education_level_id", {
    required: "Eğitim seviyesi",
  });

  // Country
  register("country_id", {
    required: "Ülke / Bölge",
  });

  // City
  register("city_id", {
    required: "Şehir",
  });

  // School name

  register("high_school_name", {
    required:
      selectedEducationLevel?.id == 2 || selectedEducationLevel?.id == 1
        ? "Okul Adı"
        : false,
  });

  // High school type
  register("high_school_type_id", {
    required: selectedEducationLevel?.id == 2 ? "Lise Türü" : false,
  });

  // High school branch
  register("high_school_branch_id", {
    required: selectedEducationLevel?.id == 2 ? "Lise Bölümü" : false,
  });

  // University type
  register("education_type_id", {
    required:
      selectedEducationLevel && selectedEducationLevel.id > 2
        ? "Öğrenim Türü"
        : false,
  });

  //University
  register("university_id", {
    required:
      selectedEducationLevel && selectedEducationLevel.id > 2
        ? "Üniversite"
        : false,
  });

  // University branch
  register("university_branch_id", {
    required:
      selectedEducationLevel && selectedEducationLevel.id > 2
        ? "Üniversite Bölümü"
        : false,
  });

  // General language
  register("general_language_id", {
    required:
      selectedEducationLevel && selectedEducationLevel.id > 1
        ? "Öğretim Dili"
        : false,
  });

  // Note system
  register("note_system_id", {
    required: !isContinue ? "Not Sistemi" : false,
  });

  // graduate year
  register("graduation_year", {
    required: !isContinue ? "Mezuniyet Yılı" : false,
  });

  // Custom form values
  const schoolName = watch("high_school_name") || "";
  const note = watch("note") || "";

  if (isEditOpen) {
    return (
      <div className="flex flex-col gap-2">
        {/* Form modal for page header */}
        {!education && (
          <div className={`flex flex-row items-center justify-between px-4`}>
            <Button
              size="sm"
              onClick={() => {
                setIsPageOpen(true);
                setIsModalOpen(true);
              }}
            >
              <Button.Icon>
                <IoAdd className="text-base" />
              </Button.Icon>
              {isEnglish ? "Add Education Information" : "Eğitim Bilgisi Ekle"}
            </Button>
          </div>
        )}

        {!education && <hr className="border-mains" />}

        {/* Form modal for form */}
        <FormModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          onClosing={() => {
            setIsModalOpen(false);
            if (education) {
              setIsEditOpen(false);
            } else {
              setIsEditOpen(true);
            }
          }}
        >
          <FormModal.Content>
            <EducationAddOrUpdateForm
              education={education}
              isAddClicked={isAddClicked}
              setIsAddClicked={setIsAddClicked}
              cvId={cvId}
              isEditOpen={isEditOpen}
              isModalOpen={isModalOpen}
              showCvScore={showCvScore}
              setIsModalOpen={setIsModalOpen}
              setIsPageOpen={setIsPageOpen}
              setIsEditOpen={setIsEditOpen}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              getAllDatas={getAllDatas}
              isEnglish={isEnglish}
            ></EducationAddOrUpdateForm>
          </FormModal.Content>
        </FormModal>
      </div>
    );
  } else {
    return (
      <>
        {/* Desktop section */}
        <div className="hidden lg:flex flex-row relative px-1 py-2 gap-4 border-transparent hover:border-gray-300 border-dashed border-2 rounded-md transition-all ">
          <FormModal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
            <FormModal.Trigger>
              <div className="flex flex-col gap-2 absolute -top-2 right-2 lg:top-4 lg:right-4 z-10">
                <Button
                  variant="ghost"
                  size="xs"
                  className="aspect-square !rounded-full z-10 !border-none"
                  onClick={() => {
                    setIsEditOpen(true);
                    //setIsPageOpen(true)
                    setIsModalOpen(true);
                  }}
                >
                  <Button.Icon>
                    <FaPencilAlt className="text-xl" />
                  </Button.Icon>
                </Button>
                <Button
                  variant="ghost"
                  size="xs"
                  className="aspect-square !rounded-full !border-none"
                  onClick={() => {
                    Swal.fire({
                      icon: "warning",
                      title: `${
                        isEnglish
                          ? `Are you sure to delete education information?`
                          : `Eğitim bilgisini silmek istediğinizden emin misiniz?`
                      }`,
                      confirmButtonText: `${isEnglish ? "Yes" : "Evet"}`,
                      cancelButtonText: `${isEnglish ? "No" : "Hayır"}`,
                      preConfirm: () => {
                        deleteEducation();
                      },
                      showCancelButton: true,
                    });
                  }}
                >
                  <Button.Icon className="text-xl">
                    <IoTrashBinOutline />
                  </Button.Icon>
                </Button>
              </div>
            </FormModal.Trigger>
          </FormModal>

          <div className="absolute top-4 left-4 lg:top-0 lg:left-0 z-0 lg:relative items-center flex flex-col border-r-0 lg:border-r pr-4 mr-2">
            <GiGraduateCap className="text-6xl text-gray-200 lg:text-9xl lg:text-mains/40" />

            {/* Education level names */}
            <label className="hidden lg:flex text-center font-poppins text-sm font-semibold text-mains line-clamp-1">
              {education?.education_level_id == 1
                ? `${isEnglish ? "Primary Education" : "İlköğretim"}`
                : education?.education_level_id == 2
                ? `${isEnglish ? "High School" : "Lise"}`
                : education?.education_level_id == 3
                ? `${isEnglish ? "Associate Degree" : "Ön Lisans"}`
                : education?.education_level_id == 4
                ? `${isEnglish ? "Licence" : "Lisans"}`
                : education?.education_level_id == 5
                ? `${isEnglish ? "Master Degree" : "Yüksek Lisans"}`
                : education?.education_level_id == 6
                ? `${isEnglish ? "Doctorate" : "Doktora"}`
                : null}
            </label>
          </div>
          <div className="flex flex-col gap-2 w-full z-[1]">
            {education && (
              <div className="flex flex-row gap-2 items-center h-full mr-10">
                <div className="flex flex-col flex-shrink-0 basis-1/2 gap-2">
                  {/* Country section */}
                  <div className="flex flex-col">
                    <label className="hidden lg:flex text-xs font-poppins font-semibold text-gary-700">
                      {isEnglish ? "Country" : "Ülke"}
                    </label>
                    <label className="font-poppins text-gray-600 text-sm">
                      {isEnglish
                        ? education.country_level_list?.name_en
                        : education.country_level_list?.name_tr}
                    </label>
                  </div>

                  {/* City section */}
                  <div className="flex flex-col">
                    <label className="hidden lg:flex text-xs font-poppins font-semibold text-gary-700">
                      {isEnglish ? "City" : "Şehir"}
                    </label>
                    <label className="font-poppins text-gray-600 text-sm">
                      {isEnglish
                        ? `${
                            education.city_level_list?.name_en ??
                            education.city_level_list?.name
                          }`
                        : `${education.city_level_list?.name}`}
                    </label>
                  </div>

                  {/* Education language section */}
                  {education.general_language_id && (
                    <div className="flex flex-col">
                      <label className="hidden lg:flex text-xs font-poppins font-semibold text-gary-700">
                        {isEnglish ? "Education Language" : "Öğrenim Dili"}
                      </label>
                      <label className="font-poppins text-gray-600 text-sm">
                        {isEnglish
                          ? education.general_language_list?.name_en
                          : education.general_language_list?.name}
                      </label>
                    </div>
                  )}
                </div>

                {/* Primary school */}
                {education.education_level_id == 1 && (
                  <div className="flex flex-col basis-1/2 gap-2">
                    {/* School name */}
                    <div className="flex flex-col">
                      <label className="hidden lg:flex text-xs font-poppins font-semibold text-gary-700">
                        {isEnglish ? "School Name" : "Okul Adı"}
                      </label>
                      <label className="font-poppins text-gray-600 text-sm line-clamp-1">
                        {education.high_school_name}
                      </label>
                    </div>
                    {/* Education status */}
                    {education.is_continue == 1 ? (
                      <div className="flex flex-col">
                        <label className="hidden lg:flex text-xs font-poppins font-semibold text-gary-700">
                          Eğitim Durumu
                        </label>
                        <label className="font-poppins text-gray-600 text-sm">
                          Devam ediyor
                        </label>
                      </div>
                    ) : (
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-col">
                          <label className="hidden lg:flex text-xs font-poppins font-semibold text-gary-700">
                            {isEnglish ? "Graduation Year" : "Mezuniyet Yılı"}
                          </label>
                          <label className="font-poppins text-gray-600 text-sm">
                            {education.graduation_year}
                          </label>
                        </div>
                        {education.note && (
                          <div className="flex flex-col">
                            <label className="hidden lg:flex text-xs font-poppins font-semibold text-gary-700">
                              {isEnglish ? "Grade Average" : "Not"}
                            </label>
                            <label className="font-poppins text-gray-600 text-sm">
                              {education.note} {""} (
                              {
                                noteSystem.find(
                                  (n) => n.id == education.note_system_id
                                )?.value
                              }
                              )
                            </label>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

                {/* High school */}
                {education.education_level_id == 2 && (
                  <div className="flex flex-col basis-1/2 gap-2">
                    {/* Educations type section */}
                    <div className="flex flex-col">
                      <label className="hidden lg:flex text-xs font-poppins font-semibold text-gary-700">
                        {isEnglish ? "Education Type" : "Öğrenim Türü"}
                      </label>
                      <label className="font-poppins text-gray-600 text-sm">
                        {isEnglish
                          ? education.high_school_type_list?.title_en
                          : education.high_school_type_list?.title}
                      </label>
                    </div>

                    {/* School name section */}
                    <div className="flex flex-col">
                      <label className="hidden lg:flex text-xs font-poppins font-semibold text-gary-700">
                        {isEnglish ? "School Name" : "Okul Adı"}
                      </label>
                      <label className="font-poppins text-gray-600 text-sm line-clamp-1">
                        {education.high_school_name}
                      </label>
                    </div>

                    {/* Section section */}
                    <div className="flex flex-col">
                      <label className="hidden lg:flex text-xs font-poppins font-semibold text-gary-700">
                        {isEnglish ? "Department" : "Bölüm"}
                      </label>
                      <label className="font-poppins text-gray-600 text-sm line-clamp-1">
                        {isEnglish
                          ? education.high_school_branch_list?.title_en
                          : education.high_school_branch_list?.title}
                      </label>
                    </div>
                  </div>
                )}

                {/* University */}
                {education.education_level_id >= 3 && (
                  <div className="flex flex-col basis-1/2 gap-2">
                    {/* University name section */}
                    <div className="flex flex-col">
                      <label className="hidden lg:flex text-xs font-poppins font-semibold text-gary-700">
                        {isEnglish ? "University" : "Üniversite"}
                      </label>
                      <label className="font-poppins text-gray-600 text-sm line-clamp-1 pr-8">
                        {isEnglish
                          ? education.university_id?.title_en
                          : education.university_id?.title}
                      </label>
                    </div>

                    {/* Section section */}
                    <div className="flex flex-col">
                      <label className="hidden lg:flex text-xs font-poppins font-semibold text-gary-700">
                        {isEnglish ? "Department" : "Bölüm"}
                      </label>
                      <label className="font-poppins text-gray-600 text-sm">
                        {isEnglish
                          ? education.university_branch_list?.title_en
                          : education.university_branch_list?.title}
                      </label>
                    </div>

                    {/* Education type section */}
                    <div className="flex flex-col">
                      <label className="hidden lg:flex text-xs font-poppins font-semibold text-gary-700">
                        {isEnglish ? "Education Type" : "Öğrenim Türü"}
                      </label>

                      <label className="font-poppins text-gray-600 text-sm">
                        {isEnglish
                          ? selectedUniversityEducationType?.name_en
                          : selectedUniversityEducationType?.name}
                      </label>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Mobile section */}
        <div className="flex flex-row lg:hidden py-2 px-4">
          <div className="flex flex-col gap-2 flex-1">
            {/* Education level section */}
            <div className="flex flex-col">
              <label className="font-poppins text-[13px] font-semibold text-gray-900">
                {isEnglish ? "Education Level" : "Eğitim Seviyesi"}
              </label>
              <label className="font-poppins text-[13px] text-gray-800">
                {isEnglish
                  ? education?.education_level_list?.title_en
                  : education?.education_level_list?.title}
              </label>
            </div>

            {/* School name section */}
            <div className="flex flex-col">
              <label className="font-poppins text-[13px] font-semibold text-gray-900">
                {isEnglish ? "University Name" : "Üniversite Adı"}
              </label>
              <label className="font-poppins text-[13px] text-gray-800">
                {education?.university_id
                  ? `${education.university_id.title}`
                  : education?.high_school_name
                  ? `${education.high_school_name}`
                  : ""}
              </label>
            </div>

            {/* Section section */}
            {education?.high_school_branch_list?.title && (
              <div className="flex flex-col">
                <label className="font-poppins text-[13px] font-semibold text-gray-900">
                  {isEnglish ? "Department" : "Bölüm"}
                </label>
                <label className="font-poppins text-[13px] text-gray-800">
                  {isEnglish
                    ? education.high_school_branch_list.title_en
                    : education.high_school_branch_list.title}
                </label>
              </div>
            )}

            {/* unviersity Section section */}
            {education?.university_branch_list?.title && (
              <div className="flex flex-col">
                <label className="font-poppins text-[13px] font-semibold text-gray-900">
                  {isEnglish ? "Department" : "Bölüm"}
                </label>
                <label className="font-poppins text-[13px] text-gray-800">
                  {isEnglish
                    ? education.university_branch_list.title_en
                    : education.university_branch_list.title}
                </label>
              </div>
            )}
          </div>

          {/* Edit and delete buttons */}
          <div className="flex flex-col gap-2 items-center">
            <button
              className="flex flex-col gap-1 items-center text-mains font-poppins text-xs"
              onClick={() => {
                setIsEditOpen(true);
                setIsPageOpen(true);
                setIsModalOpen(true);
              }}
            >
              <FaPencilAlt className="text-mains text-base" />
              {isEnglish ? "Edit" : "Düzenle"}
            </button>
            <button
              className="flex flex-col gap-1 items-center text-mains font-poppins text-xs"
              onClick={() => {
                Swal.fire({
                  icon: "warning",
                  title: `${
                    isEnglish
                      ? "Are you sure to delete education information?"
                      : "Eğitim bilgisini silmek istediğinizden emin misiniz?"
                  }`,
                  confirmButtonText: `${isEnglish ? "Yes" : "Evet"}`,
                  cancelButtonText: `${isEnglish ? "No" : "Hayır"}`,
                  preConfirm: () => {
                    deleteEducation();
                  },
                  showCancelButton: true,
                });
              }}
            >
              <IoTrashBinOutline className="text-base" />
            </button>
          </div>
        </div>
      </>
    );
  }

  async function addEducation({ cv_id, ...others }: payloadDataType) {
    if (isLoading) return;

    setIsLoading(true);

    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/edu/create`,
        type: "post",
        payload: {
          cv_id: cvId,
          ...others,
        },
      });

      if (response && response.status == 200) {
        if (getAllDatas) {
          getAllDatas();
        }

        //clear form values
        setSelectedEducationLevel(null);
        setSelectedCountry({
          id: 1,
          name_tr: "Türkiye",
          name_en: "Turkey",
          iso: "TR",
          iso3: "TUR",
          lcole: 792,
          phone_code: 90,
          updated_at: "",
        });
        setSelectedCity(null);
        setValue("high_school_name", "");
        setSelectedHighSchoolType(null);
        setSelectedHighSchoolBranch(null);
        setSelectedLanguage(null);
        setSelectedUniversityEducationType(null);
        setSelectedUniversity(null);
        setSelectedUniversityBranch(null);
        setSelectedNoteSystem(null);
        setValue("note", null);
        setSelectedGraduateYear(null);
        setIsCountinue(false);

        /* if (isMobile.isMobile) {
                    setIsPageOpen(false)
                } */

        setIsPageOpen(false);
        setIsModalOpen(false);

        showCvScore();
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Eğitim bilgisi eklenirken bir hata meydana geldi",
        });
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Eğitim bilgisi eklenirken beklendik bir hata meydana geldi",
      });
    }

    setIsLoading(false);
  }

  async function saveEducation({ cv_id, ...others }: payloadDataType) {
    if (isLoading) return;

    setIsLoading(true);

    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/edu/update/${education?.id}`,
        type: "put",
        payload: {
          cv_id: cvId,
          ...others,
        },
      });

      if (response && response.status == 200) {
        if (getAllDatas) {
          getAllDatas();
        }

        setIsEditOpen(false);

        showCvScore();
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Eğitim bilgisi güncellenirken bir hata meydana geldi",
        });
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Eğitim bilgisi güncellenirken beklenmedik bir hata meydana geldi",
      });
    }

    setIsLoading(false);
  }

  async function deleteEducation() {
    if (isLoading) return;

    setIsLoading(true);

    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/edu/delete/${education?.id}?cv_id=${cvId}`,
        type: "delete",
        payload: {
          cv_id: cvId,
        },
      });

      if (response && response.status == 200) {
        if (getAllDatas) {
          getAllDatas();
        }

        showCvScore();
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: "Eğitim bilgisi silinirken bir hata meydana geldi",
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "Eğitim bilgisi silinirken beklenmedik bir hata meydana geldi",
      });
    }

    setIsLoading(false);
  }
}
