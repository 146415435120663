import React, { useEffect, useState } from "react";
import { TbBellRinging2, TbBellRinging } from "react-icons/tb";
import services from "../../../../../services/profile-services";
import { useNavigate } from "react-router-dom";
import { FaEllipsisVertical } from "react-icons/fa6";
import { IoAdd, IoEyeOutline, IoTrash } from "react-icons/io5";
import { FiAlertCircle } from "react-icons/fi";
import { LuAlarmClock } from "react-icons/lu";
import Button from "../../../../../components/form-components/button";
import { RiErrorWarningLine } from "react-icons/ri";
import { IItem } from "../../../../job-page-container/main/filter-section-content";
import CreateWorkAlarmPopup from "../../components/CreateWorkAlarmPopup";
import Swal from "sweetalert2";
import moment from "moment";

interface DataItem {
  id: number;
  title: string;
  date: string;
  params?: string;
  params_string?: string;
  updated_at?: string;
}

const List: React.FC = () => {
  const navigate = useNavigate();
  const [mobilButtons, setMobilButtons] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [data, setData] = useState<DataItem[]>([]);
  const [filteredData, setFilteredData] = useState<DataItem[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isPopup, setIsPopup] = useState<boolean>(false);

  const getJobAlarms = async () => {
    try {
      const response = await services.getJobAlarms();
      setData(response.data.data);
    } catch (error) {
      console.error(
        "Kullanıcı erişim yetkisi kontrol edilirken bir hata oluştu:",
        error
      );
    }
  };
  useEffect(() => {
    getJobAlarms();
  }, []);

  const removeAlarm = async (id: number) => {
    Swal.fire({
      html: `<div class="text-red-500"> <p>İş alarmını silmek istediğinize emin misiniz?</p> </div>`,
      icon: "error",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
      confirmButtonColor: "#2253af",
      cancelButtonColor: "#6c757d",
    }).then((result) => {
      const doThis = async () => {
        try {
          await services.removeJobAlarm(id);
          const response = await services.getJobAlarms();
          setData(response.data.data);
          setMobilButtons(false);
        } catch (error) {
          console.error(
            "Kullanıcı erişim yetkisi kontrol edilirken bir hata oluştu:",
            error
          );
        }
      };
      if (result.isConfirmed) {
        doThis();
      }
    });
  };

  const handleSearchChange = (title: string) => {
    setSearchTerm(title);
  };

  useEffect(() => {
    const newAlarms = data.filter((item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(newAlarms);
  }, [searchTerm, data]);

  const handleBlur = () => {
    setMobilButtons(false);
  };

  const allData = searchTerm ? filteredData : data;

  return (
    <div className="flex flex-col min-h-screen gap-3">
      <div className="w-full px-7 py-3 border-b flex justify-between items-center shadow-lg bg-white rounded-md">
        <span className="text-mains text-sm lg:text-[19px] poppins-medium">
          İŞ ALARMLARI
        </span>
        {/* <button
          className="bg-[#1f4ca1] flex lg:py-3 lg:px-7 items-center gap-x-2 rounded-md"
          onClick={() => setIsPopup(true)}
        >
          <TbBellRinging2 className="text-white text-sm leading-[21px]" />
          <span className="text-white poppins-medium text-sm leading-[21px]">
            İş Alarmı Kur
          </span>
        </button> */}
        <Button onClick={() => setIsPopup(true)} size="sm">
          <Button.Icon>
            <TbBellRinging2 />
          </Button.Icon>
          İş Alarmı Kur
        </Button>
      </div>

      {isPopup && (
        <CreateWorkAlarmPopup
          onSuccess={() => {
            getJobAlarms();
            setIsPopup(false);
          }}
          onClose={() => {
            setIsPopup(false);
          }}
        />
      )}
      {/* <div>
        <input
          type="text"
          placeholder="Ara..."
          className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
          onChange={(e) => handleSearchChange(e.target.value)}
        />
      </div> */}

      {allData.length > 0 ? (
        <div className="flex flex-col w-full items-center shadow-lg bg-white rounded-md">
          <div className="flex-col flex gap-y-2 w-full h-auto py-5 px-4">
            {allData.map((data, index) => (
              <div
                key={data.id}
                className="h-auto bg-[#f8f8f8] flex w-full justify-between gap-x-3 py-5 px-5 rounded-md shadow-md"
              >
                <div className="flex gap-x-4 w-full h-auto">
                  <div className="w-auto h-full flex items-center justify-center">
                    <div className="flex w-10 h-10 rounded-full items-center justify-center bg-[#D6E2F0]">
                      <TbBellRinging className="text-[#1f4ca1] text-md" />
                    </div>
                  </div>
                  <div className="w-full h-full flex flex-col items-start justify-center">
                    <div className="flex gap-x-1 items-center">
                      <div
                        onClick={() =>
                          navigate(`/is-ilanlari${data.params_string}`)
                        }
                        className="flex items-center cursor-pointer"
                      >
                        <p className="text-sm poppins overflow-hidden whitespace-nowrap text-ellipsis text-paragraph">
                          {data.title.slice(0, 30)}
                          {data.title.length > 27 ? "..." : ""}
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-x-1 items-center">
                      <span className="text-[11px] poppins truncate leading-[13.2px]">
                        {moment(data.updated_at).format("DD.MM.YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
                <button className="focus:outline-none" onBlur={handleBlur}>
                  <div
                    className="flex items-center lg:hidden"
                    style={{ position: "relative" }}
                    onClick={() => {
                      setMobilButtons(true);
                      setSelectedItem(index);
                    }}
                  >
                    <FaEllipsisVertical className="text-[#C4C5D3] text-md leading-[24px] h-[25px] cursor-pointer scale-y-[1.2]" />
                    {mobilButtons && index === selectedItem && (
                      <div
                        className="bg-white rounded-md w-[200px] px-3"
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "30px",
                        }}
                      >
                        <div
                          className="flex items-center gap-3 text-sm h-[44px] w-[160px]"
                          onClick={() =>
                            navigate(`/is-ilanlari${data.params_string}`)
                          }
                        >
                          <IoEyeOutline className="text-[#333] cursor-pointer scale-y-[1.2]" />
                          <p className="text-[#000008]">Göster</p>
                        </div>
                        <hr />
                        <div
                          className="flex items-center gap-3 text-sm h-[44px] w-[160px] cursor-pointer"
                          onClick={() => removeAlarm(data.id)}
                        >
                          <IoTrash className="text-[#333] scale-y-[1.2]" />
                          <p className="text-[#000008]">Sil</p>
                        </div>
                      </div>
                    )}
                  </div>
                </button>

                <div className="w-auto h-auto justify-between items-center gap-[20px] hidden lg:flex">
                  <button
                    className="bg-transparent border border-[#1f4ca1] text-[#1f4ca1] transition-all duration-300 easy-in hover:bg-[#1f4ca1] hover:text-white px-[22px] flex items-center gap-x-2 rounded-[3px]"
                    onClick={() =>
                      navigate(`/is-ilanlari${data.params_string}`)
                    }
                  >
                    <span className="poppins-medium text-sm leading-8">
                      Göster
                    </span>
                  </button>
                  <button
                    className="bg-transparent border border-[#ff0000] text-[#ff0000] transition-all duration-300 easy-in hover:bg-[#ff0000] hover:text-white px-[22px] flex items-center gap-x-2 rounded-[3px]"
                    onClick={() => removeAlarm(data.id)}
                  >
                    <span className="poppins-medium text-sm leading-8">
                      Sil
                    </span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-4 items-center mb-10">
          <div className="flex flex-shrink-0 w-[100px] h-[100px] mt-10 mb-3 aspect-square bg-mains/10 items-center justify-center rounded-full">
            <div className="flex flex-shrink-0 w-[80px] h-[80px] aspect-square bg-mains/30 items-center justify-center rounded-full">
              <LuAlarmClock className="text-4xl text-mains" />
            </div>
          </div>
          <label className="font-poppins font-semibold">İş Alarmları</label>
          <label className="font-poppins text-sm">
            Bildirim almak istediğin ilanlar için alarm kurabilir ve daha sonra
            burada listeleyebilirsin.
          </label>
          <Button
            onClick={() => setIsPopup(true)}
            size="sm"
            className="w-full md:w-auto items-center justify-center"
          >
            <Button.Icon>
              <IoAdd />
            </Button.Icon>
            İş Alarmı Ekle
          </Button>
        </div>
      )}
    </div>
  );
};

export default List;
