import React from "react";
import { Route, Routes } from "react-router-dom";
import Blog from "./blog/blog-show";
import BlogList from "./blog";

const BlogPage: React.FC = () => {
  return (
    <div className="">
      <div className="w-full">
        <Routes>
          <Route path="/" element={<BlogList />} />
          <Route path="/:slug" element={<Blog />} />
        </Routes>
      </div>
    </div>
  );
};

export default BlogPage;
