interface Position {
  title: string;
  slug: string;
  min_salary: number;
  max_salary: number;
  detail: string;
  avg_salary: number;
  calculate_salary: number;
  calculate_university: number;
  created_at: string;
  order: number;
}

interface GeneralInfoProps {
  positionData: Position[];
}
const truncate = (str: string, n: number) => {
  return str?.length > n ? str.substr(0, n - 1) + "..." : str;
};

const ReadMore: React.FC<GeneralInfoProps> = ({ positionData }) => {
  const plainText = positionData[0]?.detail
    ? new DOMParser().parseFromString(positionData[0]?.detail, "text/html").body
        .textContent || ""
    : "";

  return (
    <div className="bg-white p-4 shadow rounded-lg">
      <h1 className="font-medium text-2xl p-2">
        {positionData[0]?.title} Nedir, Ne İş Yapar?{" "}
      </h1>
      <p className="mt-3">{truncate(plainText, 200)}</p>
      <div className="flex justify-center">
        <button className="w-2/5 text-white bg-[#2253af] rounded mt-4 lg:w-1/3 py-3 hover:bg-[#0d6efd]  font-medium ">
          Devamını Göster
        </button>
      </div>
    </div>
  );
};

export default ReadMore;
