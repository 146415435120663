//? FIXM This is the hiddenTopPaths constants file

export const hiddenTopPaths = [
  {
    pathname: "/profilim/is-alarmlari",
  },
  {
    pathname: "/profilim/bildirimler",
  },
  {
    pathname: "/profilim/favoriler",
  },
  {
    pathname: "/profilim/mesajlar",
  },
  {
    pathname: "/profilim/follow",
  },
];
