import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import Axios from "../../services/axios";
import { Folder } from "../../containers/company-page-container/folders-page";

const axios = new Axios();
export interface Cvs {
  id: string;
  name: string;
  cv: string;
  folderId: string;
  createdAt: string;
  updatedAt: string;
}

interface IState {
  readonly folders: Folder[] | null;
  readonly archivedFolders: Folder[] | null;
  readonly notArchivedFolders: Folder[] | null;
  readonly filteredFolders: Folder[] | null;
  readonly cvs: [] | null;
}

const initialState: IState = {
  folders: null,
  filteredFolders: null,
  archivedFolders: null,
  notArchivedFolders: null,
  cvs: null,
};
// get folders
export const getNotArchivedFolders: any =
  () =>
  async (
    dispatch: (arg0: {
      payload: Folder[];
      type: "folders/getNotArchivedFoldersSuccess";
    }) => void
  ) => {
    try {
      const response = await axios.authRequest({
        endpoint: "companies/folder/get-not-archived-folders",
        type: "get",
        payload: {},
      });

      dispatch({
        payload: response.data.folders,
        type: "folders/getNotArchivedFoldersSuccess",
      });
    } catch (error) {
      console.log(error);
    }
  };
// get archived folders
export const getArchivedFolders: any =
  () =>
  async (
    dispatch: (arg0: {
      payload: Folder[];
      type: "folders/getArchivedFoldersSuccess";
    }) => void
  ) => {
    try {
      const response = await axios.authRequest({
        endpoint: "companies/folder/get-archived-folders",
        type: "get",
        payload: {},
      });

      dispatch({
        payload: response.data.folders,
        type: "folders/getArchivedFoldersSuccess",
      });
    } catch (error) {
      console.log(error);
    }
  };
export const getFolders: any =
  () =>
  async (
    dispatch: (arg0: {
      payload: Folder[];
      type: "folders/getCompanyFoldersSuccess";
    }) => void
  ) => {
    try {
      const response = await axios.authRequest({
        endpoint: "companies/folder/get-folders",
        type: "get",
        payload: {},
      });

      dispatch({
        payload: response.data.folders,
        type: "folders/getCompanyFoldersSuccess",
      });
    } catch (error) {
      console.log(error);
    }
  };
// get folder by id
export const getFolderCvsById: any =
  (id: string) =>
  async (
    dispatch: (arg0: {
      payload: Folder[];
      type: "folders/getCompanyFolderCvsSuccess";
    }) => void
  ) => {
    try {
      const response = await axios.authRequest({
        endpoint: `companies/folder/${id}/get-folder-cvs`,
        type: "get",
        payload: {},
      });
      dispatch({
        payload: response.data.cvs,
        type: "folders/getCompanyFolderCvsSuccess",
      });
    } catch (error) {
      console.log(error);
    }
  };
// delete folder
export const deleteFolder: any =
  (id: string) =>
  async (
    dispatch: (arg0: {
      payload: Folder[];
      type: "folders/deleteCompanyFoldersSuccess";
    }) => void
  ) => {
    try {
      const response = await axios.authRequest({
        endpoint: `companies/folder/${id}/delete`,
        type: "delete",
        payload: {},
      });
      dispatch({
        payload: response.data.folders,
        type: "folders/deleteCompanyFoldersSuccess",
      });
    } catch (error) {}
  };

// companies/folder/multi-delete
export const deleteFolders: any =
  (folder_ids: string[]) =>
  async (
    dispatch: (arg0: {
      payload: Folder[];
      type: "folders/deleteCompanyFoldersSuccess";
    }) => void
  ) => {
    try {
      const response = await axios.authRequest({
        endpoint: `companies/folder/multi-delete`,
        type: "delete",
        payload: {
          data: {
            folder_ids,
          },
        },
      });
      dispatch({
        payload: response.data.folders,
        type: "folders/deleteCompanyFoldersSuccess",
      });
    } catch (error) {}
  };

// changeArchiveStatus

export const changeArchiveStatus: any =
  (id: string, status: any) =>
  async (
    dispatch: (arg0: {
      payload: Folder[];
      type: "folders/companyFolderChangeArchiveStatusSuccess";
    }) => void
  ) => {
    try {
      await axios.authRequest({
        endpoint: `companies/folder/${id}/change-archive-status`,
        type: "put",
        payload: {
          archive: status,
        },
      });
      dispatch({
        payload: [],
        type: "folders/companyFolderChangeArchiveStatusSuccess",
      });
    } catch (error) {
      console.log(error);
    }
  };

// search folder by title
export const searchFolder =
  (title: string) => (dispatch: any, getState: any) => {
    const { folders } = getState().companyFolders;
    if (folders) {
      const filteredFolders = folders.filter((folder: Folder) =>
        folder.title.toLowerCase().includes(title.toLowerCase())
      );
      dispatch({
        type: "folders/companyFolderSearchSuccess",
        payload: filteredFolders,
      });
    }
  };

export const companyFoldersSlice = createSlice({
  name: "companyUser",
  initialState,
  reducers: {
    getCompanyFoldersSuccess(state, action: PayloadAction<Folder[]>) {
      state.folders = action.payload;
    },
    companyFolderSearchSuccess(state, action: PayloadAction<Folder[]>) {
      state.filteredFolders = action.payload;
    },
    getNotArchivedFoldersSuccess(state, action: PayloadAction<Folder[]>) {
      state.notArchivedFolders = action.payload;
    },
    getArchivedFoldersSuccess(state, action: PayloadAction<Folder[]>) {
      state.archivedFolders = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase("folders/getCompanyFoldersSuccess", (state, action: any) => {
        state.folders = action.payload;
        state.filteredFolders = action.payload;
      })
      .addCase("folders/companyFolderSearchSuccess", (state, action: any) => {
        state.filteredFolders = action.payload;
      })
      .addCase("folders/getNotArchivedFoldersSuccess", (state, action: any) => {
        state.folders = action.payload;
        state.filteredFolders = action.payload;
      })
      .addCase("folders/getArchivedFoldersSuccess", (state, action: any) => {
        state.folders = action.payload;
        state.filteredFolders = action.payload;
      });
  },
});

export const { getCompanyFoldersSuccess } = companyFoldersSlice.actions;

export default companyFoldersSlice.reducer;
