import * as React from "react";
import { CustomButton } from "../../../components/common/login/custom-button";
import { RocketIcon } from "../../../theme/icons";
import { useNavigate } from "react-router-dom";

function BuyDopingSection(): React.ReactElement {
  const navigate = useNavigate();
  return (
    <div className="lg:flex hidden flex-col md:flex-row justify-between items-center bg-white shadow-lg p-4 rounded-lg gap-y-4">
      <div className="flex flex-col gap-y-2">
        <span className="text-base text-mains poppins-semibold">
          <p>Daha fazla adaya ulaşmaya ne dersin?</p>
        </span>
        <span className="flex flex-row gap-x-1 lg:items-center">
          <p className="text-base text-mains poppins-semibold">DOPİNG </p>
          <p className="text-sm text-gray-600 poppins-medium">
            satın alarak ilanınızın 250 kata kadar daha fazla başvuru almasını
            sağlayın.
          </p>
        </span>
      </div>

      <div className="flex flex-row h-10 gap-x-2 items-center bg-mains py-2 px-10 rounded-lg hover:bg-mains/80 cursor-pointer" onClick={() => navigate("/isveren/ürünler")}>
        <RocketIcon size={28} color="white" />
        <CustomButton
          /* TODO: Burası paketler sayfası hazırlandıktan sonra çalışacak */
          text="Doping Satın Al"
          className="text-white poppins-medium"
        />
      </div>
    </div>
  );
}
export { BuyDopingSection };
