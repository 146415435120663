import { ButtonHTMLAttributes, useContext } from "react"
import { IoChevronBack } from "react-icons/io5"
import classNames from "classnames"
import { paginationContextType, PaginationContext } from "."


type arrowLeftButtonPropsType = ButtonHTMLAttributes<HTMLButtonElement> & {

}





export default function ArrowLeftButton({
    className,
    children,
    ...props
}: arrowLeftButtonPropsType) {

    const { currentPage, firstPageNumber }: paginationContextType = useContext(PaginationContext)

    const cn = classNames({
        'flex flex-row gap-2 text-mains rounded-md hover:bg-mains hover:text-white p-2 hover:text-white items-center transition-all': true
    })

    if (currentPage == firstPageNumber) {
        return null
    } else {
        return(
            <button className={`${cn} ${className}`} {...props}>
                {children? children: (
                    <>
                        <IoChevronBack className="text-xl"/>
                        Önceki
                    </>
                )}
            </button>
        )
    }
}