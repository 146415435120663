interface ICheckboxProps {
  content: string;
  isChecked?: number | null;
  isOnChange?: (isChecked: boolean) => void;
}

const CheckboxInfo = ({ content, isChecked, isOnChange }: ICheckboxProps) => {
  return (
    <div className="flex justify-between mx-[10px] border-b-2 border-gray-200 mt-4">
      <p className="text-gray-500 text-sm">{content}</p>
      <div className="flex gap-2 w-[140px] justify-end mb-2">
        <p className="w-[90px] text-sm show-info">{`${
          isChecked ? "İstiyorum" : "İstemiyorum"
        }`}</p>
        <label
          className={`relative flex justify-start items-center px-1 w-[40px] h-[18px] rounded-full transition-colors border border-black ${
            isChecked
              ? "bg-blue-700 justify-end border-none"
              : "bg-white justify-start "
          }`}
        >
          <input
            type="checkbox"
            checked={isChecked === 1}
            className={`outline-none  ${
              isChecked
                ? "bg-white transition duration-200 ease-linear"
                : "bg-gray-400 transition duration-200 ease-linear"
            } appearance-none w-2 h-2 cursor-pointer px-1 rounded-full`}
            onChange={() => isOnChange && isOnChange(!isChecked)}
          />
        </label>
      </div>
    </div>
  );
};

export default CheckboxInfo;
