import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../services/axios";
import { toast } from "react-toastify";

const axiosInstance = new Axios();

export interface IUserInfoProps {
  is_email_verified: number;
  is_phone_verified: number;
  name: string;
  surname: string;
  email: string;
  phone: string;
  phone_code: string;
}

export const initialState: IUserInfoProps = {
  is_email_verified: 0,
  is_phone_verified: 0,
  name: "",
  surname: "",
  email: "",
  phone: "",
  phone_code: "",
};

// USER INFO
export const getUserInfo = createAsyncThunk(
  "userInfo/getUserInfo",
  async () => {
    try {
      const response = await axiosInstance.request({
        type: "get",
        endpoint: "user/profilim/cv",
        payload: {},
      });
      if (!response.data) {
        throw new Error("Failed to fetch user info");
      }
      console.log("response from store request", response.data.data);
      return response.data.user;
    } catch (error) {
      console.log("Error fetching user info", error);
      throw error;
    }
  }
);

// POST USER INFO
export const postUserInfo = createAsyncThunk(
  "userInfo/postUserInfo",
  async (formData: any) => {
    try {
      // Process phone number
      const formattedPhone = formData.phone.replace(/[\s\(\)-]/g, "");
      const updatedFormData = { ...formData, phone: formattedPhone };

      console.log("Updated formData", updatedFormData);
      const response = await axiosInstance.request({
        type: "post",
        endpoint: "user/profilim/settings/set-personal-info",
        payload: updatedFormData,
      });
      toast.success("Profil bilgileri başarıyla güncellendi.");
    } catch (error: any) {
      let errorMsg;
      if (error.response && error.response.data && error.response.data.errors) {
        // Backend'den gelen hata mesajını al
        errorMsg = Object.values(error.response.data.errors).flat().join(" ");
      } else {
        // Genel hata mesajı
        errorMsg = "An error occurred while updating user info.";
      }
      console.log("Error updating user info", errorMsg);
      toast.error(errorMsg);
      throw errorMsg;
    }
  }
);

// POST USER PASSWORD
export const postUserPassword = createAsyncThunk(
  "userPassword/postUserPassword",
  async (userPassword: any, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.request({
        type: "post",
        endpoint: "user/profilim/settings/change-password",
        payload: userPassword,
      });
      toast.success(response.data.message);
    } catch (error: any) {
      if (error.response && error.response.data) {
        // Return the specific error message from the server
        return rejectWithValue(error.response.data);
      } else {
        throw error;
      }
    }
  }
);

// SEND VERIFY EMAIL
export const sendUserVerifyEmail = createAsyncThunk(
  "userEmail/sendUserVerifyEmail",
  async (email: any) => {
    try {
      const response = await axiosInstance.request({
        type: "post",
        endpoint: "user/profilim/send-verify-email",
        payload: email,
      });
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      console.log("Error invalid mail adress", error);
      throw error;
    }
  }
);

// SEND CONFIRM EMAIL
export const sendUserConfirmEmail = createAsyncThunk(
  "userEmail/sendUserConfirmEmail",
  async (code: any) => {
    try {
      const response = await axiosInstance.request({
        type: "post",
        endpoint: "user/profilim/confirm-verify-email",
        payload: code,
      });
      toast.success(response.data.message);
      return response.data;
    } catch (error) {
      console.log("Error invalid confirm code", error);
      throw error;
    }
  }
);

// SEND VERIFY SMS
export const sendUserVerifySms = createAsyncThunk(
  "userPhone/sendUserVerifySms",
  async (phone: any) => {
    try {
      const response = await axiosInstance.request({
        type: "post",
        endpoint: "user/profilim/send-verify-sms",
        payload: phone,
      });

      return response.data;
    } catch (error) {
      console.log("Error invalid number", error);
      throw error;
    }
  }
);

// CONFIRM VERIFY SMS
export const confirmUserVerifySms = createAsyncThunk(
  "userPhone/confirmUserVerifySms",
  async (phone: any) => {
    try {
      const response = await axiosInstance.request({
        type: "post",
        endpoint: "user/profilim/confirm-verify-sms",
        payload: phone,
      });

      toast.success(response.data.message);
      return response.data;
    } catch (error: any) {
      console.log("Error invalid number", error);
      toast.error("Doğrulama Kodu hatalı!");
      throw error;
    }
  }
);

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    updatePhone: (state, action) => {
      console.log("action.payload.phone", action.payload.phone);
      state.phone = action.payload.phone;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.pending, (state) => {})
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.name = action.payload.name;
        state.surname = action.payload.surname;
        state.email = action.payload.email;
        state.phone = action.payload.phone;
        state.phone_code = action.payload.phone_code;
        state.is_email_verified = action.payload.is_email_verified;
        state.is_phone_verified = action.payload.is_phone_verified;
      })
      .addCase(getUserInfo.rejected, (state, action) => {});
  },
});

export const { updatePhone } = userInfoSlice.actions;
export default userInfoSlice.reducer;
