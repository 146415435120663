import { LoginContent } from "../../../../components/common/login/login-content/index";

function RegisterPages(): React.ReactElement {
  return (
    <div className="flex container mx-auto flex-col gap-y-8">
      <LoginContent type="register" categories="company" />
    </div>
  );
}

export default RegisterPages;
