import React, { useEffect, useState } from "react";
import { SectionsService } from "../../../../../services/sections";
import { IconChevronDown } from "@tabler/icons-react";
import { SectorsService } from "../../../../../services/sectors";
import { PositionsService } from "../../../../../services/positions";
import Axios from "../../../../../services/axios";
import { Link } from "react-router-dom";

interface Section {
  id: number;
  title: string;
  title_en: string;
  slug: string;
  is_publish: string;
  order: number;
  created_at: string;
  updated_at: string;
}
interface Sectors {
  id: number;
  title: string;
  title_en: string;
  slug: string;
  is_publish: string;
  order: number;
  created_at: string;
  updated_at: string;
}
interface Positions {
  id: number;
  title: string;
  title_en: string;
  slug: string;
  is_publish: string;
  order: number;
  created_at: string;
  updated_at: string;
}

interface Cities {
  id: number;
  name: string;
  name_en: string;
  slug: string;
  is_publish: string;
  order: number;
  created_at: string;
  updated_at: string;
}

const Sectors: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [sections, setSections] = useState<Section[]>([]);
  const [sectors, setSectors] = useState<Sectors[]>([]);
  const [positions, setPositions] = useState<Positions[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [cities, setCities] = useState<Cities[]>([]);

  const axios = new Axios();

  useEffect(() => {
    const fetchData = async () => {
      if (isOpen) {
        setIsLoading(true);
        setError(null);
        try {
          const response = await SectorsService.getSectorsByFilter("");
          console.log(response, "response");
          console.log(response.data.sectors, "response.data.sectors");
          setSectors(response.data.sectors || []);
        } catch (error) {
          console.error("Failed to fetch sections:", error);
          setError("Failed to load data");
          setSectors([]);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [isOpen]); // This effect runs when isOpen changes

  const toggleDropdown2 = async () => {
    setIsOpen2(!isOpen2);
  };
  const toggleDropdown = async () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.authRequest({
          type: "get",
          endpoint: "cdn/get-cities?limit=20",
          payload: {},
        });

        console.log(response, "response.data.cities");
        setCities(response.data.cities);
      } catch (error) {
        console.log("Failed to fetch cities:", error);
      }
    };

    fetchCities();
  }, []);

  const toggleDropdown3 = async () => {
    setIsOpen3((prev) => !prev); // Use functional update for better reliability
    if (!isOpen3) {
      // Check the previous state, not the potentially not-yet-updated state
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.authRequest({
          type: "get",
          endpoint: "cdn/get-section-dictionaries",
          payload: {},
        });
        console.log(response, "response");
        setSections(response.data.section_dictionaries || []);
      } catch (error) {
        console.error("Failed to fetch sections:", error);
        setError("Failed to load data");
        setSections([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const toggleDropdown4 = async () => {
    setIsOpen4((prev) => !prev); // Use functional update for better reliability
    if (!isOpen4) {
      // Check the previous state, not the potentially not-yet-updated state
      setIsLoading(true);
      setError(null);
      try {
        const response = await PositionsService.getPostionsByFilter("");
        console.log(response, "response");
        setPositions(response.data.positions || []);
      } catch (error) {
        console.error("Failed to fetch sections:", error);
        setError("Failed to load data");
        setPositions([]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  console.log(sections, "sections");

  console.log(positions);

  return (
    <div className="bg-white h-auto ">
      <div className="w-full py-10 h-auto container mx-auto">
        <div className="flex flex-col gap-y-3 lg:gap-y-5">
          <div className="flex flex-col gap-y-0 shadow-sm">
            <button onClick={toggleDropdown} className="w-full h-16 items-center px-2 md:px-10 bg-gray-100 flex justify-between border-b border-gray-300 md:borber-none">
              <span
                className={`text-[15px] md:text-base text-gray-700 poppins-semibold `}
              >
                Sektöre Göre İş İlanları
              </span>

              <div className="flex justify-center items-center gap-2">
                <span>
                  {isOpen && (
                    <a
                      href="sektorler"
                      className="text-[13px] text-[#0a58ca] font-medium"
                    >
                      Tümünü Gör
                    </a>
                  )}
                </span>
                <IconChevronDown
                  className={`text-gray-700 ${
                    isOpen ? "rotate-180" : ""
                  } transition-all`}
                  size={16}
                />
              </div>
            </button>
            {isOpen && (
              <div className="w-full h-auto bg-gray-100  flex flex-col">
                <div className="grid h-auto grid-cols-1 md:grid-cols-4 px-2 md:px-10 py-5 gap-y-3 md:gap-y-5">
                  {!isLoading &&
                    sectors.map((sector) => (
                      <Link
                        to={`/is-ilanlari?sectors=[${sector.id}]`}
                        key={sector.id}
                        className="text-gray-600 text-xs md:text-[13px] mr-auto poppins-light col-span-1 hover:text-black hover:font-semibold duration-75 hover:tracking-wide"
                      >
                        {sector.title}
                      </Link>
                    ))}
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-y-0 shadow-sm">
            <button
              onClick={toggleDropdown2}
              className="w-full h-16 items-center px-2 md:px-10 bg-gray-100 flex justify-between border-b border-gray-300 md:borber-none"
            >
              <span className="text-[15px] md:text-base text-gray-700 poppins-semibold">
                İllere Göre İş İlanları
              </span>
              <div className="flex justify-center items-center gap-2">
                <span>
                  {isOpen2 && (
                    <a
                      href="iller"
                      className="text-[13px] text-[#0a58ca] font-medium"
                    >
                      Tümünü Gör
                    </a>
                  )}
                </span>
                <IconChevronDown
                  className={`text-gray-700 ${
                    isOpen2 ? "rotate-180" : ""
                  } transition-all`}
                  size={16}
                />
              </div>
            </button>
            {isOpen2 && (
              <div className="w-full bg-gray-100 h-auto flex flex-col">
                <div className="grid grid-cols-1 md:grid-cols-4 px-2 md:px-10 py-5 gap-y-5">
                  {cities.map((city) => (
                    <>
                      {" "}
                      <Link
                        to={`/is-ilanlari?cities=[${city.id}]`}
                        className="text-gray-600 text-xs md:text-[13px] poppins-light col-span-1 hover:text-black hover:font-semibold duration-75 hover:tracking-wide"
                      >
                        {city.name}
                      </Link>
                    </>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-y-0 shadow-sm">
            <button
              onClick={toggleDropdown3}
              className="w-full h-16 items-center px-2 md:px-10 bg-gray-100 flex justify-between border-b border-gray-300 md:borber-none"
            >
              <span className="text-[15px] md:text-base text-gray-700 poppins-semibold">
                Bölümler
              </span>
              <div className="flex justify-center items-center gap-2">
                <span>
                  {isOpen3 && (
                    <a
                      href="bolumler"
                      className="text-[13px] text-[#0a58ca] font-medium"
                    >
                      Tümünü Gör
                    </a>
                  )}
                </span>
                <IconChevronDown
                  className={`text-gray-700 ${
                    isOpen3 ? "rotate-180" : ""
                  } transition-all`}
                  size={16}
                />
              </div>
            </button>
            {isOpen3 && (
              <div className="w-full h-auto bg-gray-100 flex flex-col">
                <div className="grid h-auto grid-cols-1 md:grid-cols-4 px-2 md:px-10 py-5 gap-y-5">
                  {!isLoading &&
                    !error &&
                    sections?.map((section) => (
                      <Link
                        to={`/bolumler/${section.slug}`}
                        key={section.id}
                        className="text-gray-600 text-xs md:text-[13px] poppins-light col-span-1 hover:text-black hover:font-semibold duration-75 hover:tracking-wide"
                      >
                        {section.title}
                      </Link>
                    ))}
                </div>
              </div>
            )}
          </div>

          <div className="flex flex-col gap-y-0 shadow-sm">
            <button
              onClick={toggleDropdown4}
              className="w-full h-16 items-center  px-2 md:px-10 bg-gray-100 flex justify-between border-b border-gray-300 md:borber-none"
            >
              <span className="text-[15px] md:text-base text-gray-700 poppins-semibold">
                Pozisyonlara Göre İş İlanları
              </span>

              <div className="flex justify-center items-center gap-2">
                <span>
                  {isOpen4 && (
                    <a
                      href="pozisyonlar"
                      className="text-[13px] text-[#0a58ca] font-medium"
                    >
                      Tümünü Gör
                    </a>
                  )}
                </span>
                <IconChevronDown
                  className={`text-gray-700 ${
                    isOpen4 ? "rotate-180" : ""
                  } transition-all`}
                  size={16}
                />
              </div>
            </button>
            {isOpen4 && (
              <div className="w-full h-auto bg-gray-100 flex flex-col">
                <div className="grid h-auto grid-cols-1 md:grid-cols-4 px-2 md:px-10 py-5 gap-y-5">
                  {error && <div>Error: {error}</div>}
                  {!isLoading &&
                    !error &&
                    positions.map((position) => (
                      <Link
                        to={`/is-ilanlari?positions=[${position.id}]`}
                        key={position.id}
                        className="text-gray-600 text-xs md:text-[13px] poppins-light col-span-1 hover:text-black hover:font-semibold duration-75 hover:tracking-wide"
                      >
                        {position.title}
                      </Link>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sectors;
