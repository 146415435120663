import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SeminarForm from "./components/SeminarForm";
import { dbUserSeminarType } from "../../../../../types";
import Axios from "../../../../../services/axios";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../../components/isbul-ui";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { PiWarningCircle } from "react-icons/pi";
import { useContext } from "react";
import { CvLayoutContext, cvLayoutContextType } from "..";




const axios = new Axios()


const SeminarCourse: React.FC = () => {

  const { isEnglish }: cvLayoutContextType = useContext(CvLayoutContext)

  const params = useParams();
  const cvId = params.slug

  const navigate = useNavigate()

  // General states
  const [seminars, setSeminars] = useState<dbUserSeminarType[]>([])
  const [seminarsLength, setSeminarsLength] = useState<number>(1)
  const [isAddClicked, setIsAddClicked] = useState<boolean>(false)

  async function getAllDatas() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/sem/index?cv_id=${cvId}`,
        type: 'get',
        payload: {}
      })

      if (response && response.data && response.data.seminars) {
        setSeminars(JSON.parse(JSON.stringify(response.data.seminars)))
        setSeminarsLength(response.data.seminars.length)
      } else {
        setSeminars([])
      }
    } catch (error) {
      console.log(error)
      setSeminars([])
    }
  }

  // Initial page scroll to top
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])


  useEffect(() => {
    getAllDatas()
  }, [])
  return (
    
    <div className="flex flex-col w-full gap-2 rounded-md bg-white lg:shadow-md mt-2 pb-4">
      
      {/* Empty seminar form */}
      <SeminarForm 
      seminar={null}
      getAllDatas={getAllDatas}
      seminarsLength={seminarsLength}
      isAddClicked={isAddClicked}
      setIsAddClicked={setIsAddClicked}
      />

      {/* Empty seminars message */}
      {seminars.length == 0 && (
        <div className="flex flex-row gap-4 m-4 border border-gray-200 rounded-md">
          <div className="flex p-4 bg-red-600 rounded-l-md items-center justify-center">
            <PiWarningCircle className="text-3xl text-white"/>
          </div>
          <div className="flex flex-col gap-1 py-2 pr-1">
            <p className="font-poppins font-semibold text-gray-700 text-sm">
              {isEnglish? "You don't have any seminars added yet.": 'Henüz eklenmiş bir semineriniz yok.'}
            </p>
            {isEnglish? (
              <p className="font-poppins text-sm text-gray-700 font-semibold">
                You can add seminar with
                {' '}
                <button className="text-mains underline" onClick={() => setIsAddClicked(true)}>Add Seminar</button>
                {' '}
                button.
              </p>
            ): (
              <p className="font-poppins text-sm text-gray-700 font-semibold">
                <button className="text-mains underline" onClick={() => setIsAddClicked(true)}>Seminer Ekle</button> butonuna tıklayarak hemen yeni bir seminer ekleyebilirsiniz.
              </p>
            )}
          </div>
        </div>
      )}

      {/* All seminars mapping */}
      {seminars.map((s) => {
        return(
          <div key={`sForm${s.id}`} className="flex flex-col gap-2 lg:gap-0">
            <hr/>
            <SeminarForm
            seminar={s}
            getAllDatas={getAllDatas}
            />
          </div>
        )
      })}

      <div className="px-2">
        <hr/>
      </div>

      {/* Prev and next buttons */}
      <div className="p-4 flex items-center justify-between">
        <Button
        variant="secondary"
        size="sm"
        onClick={() => navigate(`/profilim/cv/duzenle/${cvId}/egitim-bilgileri`)}
        >
          <Button.Icon>
            <IoChevronBack/>
          </Button.Icon>
          {isEnglish? 'Go Back': 'Geri Dön'}
        </Button>

        <Button
        size="sm"
        onClick={() => navigate(`/profilim/cv/duzenle/${cvId}/basari-odul-ve-burslar`)}
        >
          {isEnglish? 'Continue': 'Devam Et'}
          <Button.Icon>
            <IoChevronForward/>
          </Button.Icon>
        </Button>
      </div>
    </div>
  );
};

export default SeminarCourse;
