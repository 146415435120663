import React, { useEffect, useState, useContext } from "react";
import AdvertSectionDropdown from "../advert-section-dropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  addOrUpdateQueryParam,
  refreshQueryParam,
} from "../../../../hooks/useAddOrUpdateQueryParam";
import { JobsPageContext, jobsPageContextType } from "..";
import Swal from "sweetalert2";

import { TbBellRinging2 } from "react-icons/tb";
import { FaFilter } from "react-icons/fa";
import FilteredItemsList from "./FilteredItemsList";
import { Button, Modal, Input, Checkbox, Select } from "antd";
import Axios from "../../../../services/axios";
import { useCookie } from "../../../../hooks/useCookie";

import Cookies from "js-cookie";
import FilterSaveModal from "../advert-section-body/components/filter-save-modal/FilterSaveModal";
import AlarmSaveModal from "../advert-section-body/components/alarm-save-modal/AlarmSaveModal";

interface IItem {
  title: string;
  id: number;
}

interface AdverSectionHeaderProps {
  savedFilters: IItem[];
  setSavedFilters: React.Dispatch<React.SetStateAction<IItem[]>>;
  adverts: any;
}

const AdverSectionHeader: React.FC<AdverSectionHeaderProps> = ({
  adverts,
  savedFilters,
  setSavedFilters,
}) => {
  const axios = new Axios();
  const {
    selectedValues,
    setSelectedValues,
    pagination,
    createQueryParamsFromSelectedValues,
  }: jobsPageContextType = useContext(JobsPageContext);

  const selectedValuesLength =
    (selectedValues.advertDate[0] !== undefined &&
    selectedValues.advertDate[0].id !== 1
      ? selectedValues.advertDate.length
      : 0) +
    selectedValues.cities.length +
    selectedValues.districts.length +
    (selectedValues.disabled[0] !== undefined &&
    selectedValues.disabled[0].id !== 1
      ? selectedValues.disabled.length
      : 0) +
    (selectedValues.experience[0] !== undefined &&
    selectedValues.experience[0].id !== 0
      ? selectedValues.experience.length
      : 0) +
    selectedValues.other.length +
    selectedValues.positionLevels.length +
    selectedValues.positions.length +
    selectedValues.sections.length +
    selectedValues.sectors.length +
    selectedValues.workingLocations.length +
    selectedValues.company.length +
    selectedValues.search.length +
    selectedValues.workingTypes.length;

  const location = useLocation();
  const navigate = useNavigate();
  const [isSortActive, setIsSortActive] = useState<boolean>(false);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [limit, setLimit] = useState<string>("35");
  const [date, setDate] = useState("update");
  const [titleText, setTitleText] = useState<string>("");
  const [frequance, setFrequence] = useState("DAILY");
  const [ring, setRing] = useState(false);

  const [titleText2, setTitleText2] = useState<string>("");

  const { getCookie } = useCookie();
  const userToken = Cookies.get("userToken") || Cookies.get("companyUserToken");
  // TODO: add userData to the redux store

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSweetAlert = async () => {
    // seçilen bir filrelemenin kaydedilmesini sağlıyor

    if (titleText === "") {
      Swal.fire({
        icon: "error",
        text: "Başlık alanı boş bırakılamaz!",
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "sweet-alert-button", // TailwindCSS classes
        },
      });
      return;
    }

    // burdaki parametrelerin formatı değişecek.
    let queryParams = createQueryParamsFromSelectedValues();

    const params = refreshQueryParam(location, queryParams);

    axios
      .userAuthRequest({
        type: "post",
        endpoint: "user/profilim/kayitli-aramalar/create",
        payload: {
          title: titleText,
          params: JSON.stringify(params),
          send_emails: ring ? 1 : 0,
          period: frequance,
        },
      })
      .then((response: any) => {
        setSavedFilters([...savedFilters, response.data.data]);
        Swal.fire({
          icon: "success",
          text: "Filtre başarıyla kaydedildi!",
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "sweet-alert-button", // TailwindCSS classes
          },
        });
        setTitleText("");
        handleCancel();
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: "Filtre Eklenemedi!",
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "sweet-alert-button", // TailwindCSS classes
          },
        });
      });

    return;

    if (false) {
      Swal.fire({
        icon: "success",
        text: "Filtre başarıyla kaydedildi!",
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "sweet-alert-button", // TailwindCSS classes
        },
      });

      setSavedFilters([
        ...savedFilters,
        { title: titleText, id: savedFilters.length + 1 },
      ]);

      setTitleText("");
      handleCancel();
    }

    try {
      const params = {
        create_alarm: "on",
        period: "MONTHLY",
        city_id: "83",
        search_type: "all",
        position: ["8454"],
        advert_date: "all",
        country: ["1"],
        disabled: "all",
      };

      const response = await axios.userAuthRequest({
        type: "post",
        endpoint: "user/profilim/kayitli-aramalar/create",
        payload: {
          title: titleText,
          params: JSON.stringify(params),
          send_emails: 1,
          period: "DAILY",
        },
      });

      console.log(response, "response");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //console.log(savedFilters);

  const showModal2 = () => {
    setIsModalOpen2(true);
  };

  const handleOk2 = () => {
    setIsModalOpen2(false);
  };

  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };

  const handleSweetAlert2 = () => {
    if (titleText2 === "") {
      Swal.fire({
        icon: "error",
        text: "Başlık alanı boş bırakılamaz!",
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "sweet-alert-button", // TailwindCSS classes
        },
      });
      return;
    } else {
      Swal.fire({
        icon: "success",
        text: "Alarm başarıyla kaydedildi!",
        confirmButtonText: "Tamam",
        customClass: {
          confirmButton: "sweet-alert-button", // TailwindCSS classes
        },
      });

      setTitleText2("");
      handleCancel2();
    }
  };

  const handleChangeTitle2 = (e: any) => {
    setTitleText2(e.target.value);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const pageLimit = pagination?.per_page.toString();
    const totalPage = pagination?.total.toString();

    if (!totalPage) {
      setTotalPage(0);
    } else {
      if (isNaN(parseInt(totalPage))) {
        setTotalPage(0);
      } else {
        setTotalPage(parseInt(totalPage));
      }
    }

    pageLimit && setLimit(pageLimit);
  }, [location.search, pagination]);

  const handleClearFilters = () => {
    setSelectedValues({
      advertDate: [],
      cities: [],
      districts: [],
      disabled: [],
      experience: [],
      other: [],
      positionLevels: [],
      positions: [],
      sections: [],
      sectors: [],
      workingLocations: [],
      workingTypes: [],
      filters: [],
      company: [],
      search: [],
      isFilterUpdated: false,
    });

    navigate("/is-ilanlari");
  };

  return (
    <div>
      <div className="h-11 shadow bg-white rounded-b-md flex items-center justify-between px-4">
        <h1 className="poppins-semibold text-title">İş İlanları</h1>
        <div className="flex gap-x-2 h-full py-[10px]">
          <div className={"mr-5 text-gray-400"}>
            {pagination?.total ? pagination?.total : "0"} İş İlanı Bulundu
          </div>
          {adverts.length > 0 && (
            <>
              <AdvertSectionDropdown
                isActive={isSortActive}
                setIsActive={setIsSortActive}
                value={limit}
                onChange={(e: any) => {
                  navigate(
                    "/is-ilanlari" +
                      addOrUpdateQueryParam(location, [
                        { key: "page_limit", value: e.label },
                      ])
                  );
                }}
                onClick={() => setIsActive(false)}
                options={[
                  { label: "35", value: "35" },
                  { label: "50", value: "50" },
                  { label: "100", value: "100" },
                ]}
              />
              <AdvertSectionDropdown
                isActive={isActive}
                setIsActive={setIsActive}
                value={date}
                onClick={() => setIsSortActive(false)}
                /* HERE: Burası update yapıyor */
                onChange={(e: any) => {
                  navigate(
                    "/is-ilanlari" +
                      addOrUpdateQueryParam(location, [
                        { key: "page_sort", value: e?.value },
                      ])
                  );
                  setDate(e.value);
                }}
                options={[
                  { label: "Güncellenme Tarihi", value: "update" },
                  { label: "Yayınlanma Tarihi", value: "publish" },
                ]}
              />
            </>
          )}
        </div>
      </div>

      {/* Filtrelemeler bölümü */}
      {selectedValuesLength > 0 && (
        <div className="flex flex-col items-start gap-2 shadow bg-white rounded-b-md py-2 px-6 my-4">
          <div className="flex flex-row w-full gap-2 items-center">
            <label className="font-semibold text-title text-xl">
              Filtreler: ({selectedValuesLength.toString()})
            </label>
            <button
              className="text-lg text-orange-800"
              onClick={handleClearFilters}
            >
              Tümünü Temizle
            </button>
            {userToken ? (
              <>
                <FilterSaveModal
                  isMobile={false}
                  savedFilters={savedFilters}
                  setSavedFilters={setSavedFilters}
                />
              </>
            ) : (
              <Link
                to="/profilim/giris-yap"
                className="flex flex-row ml-auto gap-2 items-center bg-title text-white p-2 rounded-md"
              >
                <FaFilter className="text-xl" />
                Filtre Kaydet
              </Link>
            )}
          </div>
          <hr className="w-full" />

          <FilteredItemsList />
        </div>
      )}

      {/* Alarm kurma bölümü */}
      {selectedValuesLength > 0 && totalPage > 0 && (
        <div className="flex flex-row items-center justify-between bg-white gap-2 p-6 rounded-md shadow">
          <div className="flex flex-col 2/3">
            <label className="text-title text-lg font-semibold">
              Hiçbir ilanı kaçırma!
            </label>
            <label className="text-gray-800">
              Bu aramana uygun yeni bir ilan yayınlandığında ilk senin haberin
              olsun
            </label>
          </div>
          <AlarmSaveModal />
        </div>
      )}
    </div>
  );
};

export default AdverSectionHeader;
