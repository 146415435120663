import * as React from "react";

function StepsPointsCard(): React.ReactElement {
  // State to track the active step, default is 1
  const [activeStep, setActiveStep] = React.useState(1);

  return (
    <>
      {/* Desktop View */}
      <div className="bg-white p-4 rounded shadow-lg py-6 font-poppins hidden md:flex flex-col items-center">
        <div className="flex justify-between items-center relative w-full sm:px-8 md:px-16">
          {/* Line between steps */}
          <div
            className="absolute w-[70%] sm:w-[75%] lg:w-[80%] h-1 bg-[#ABA5A5] block right-[12%] lg:right-[10%] sm:right-[12%]"
            style={{ top: "38%", transform: "translateY(-50%)" }}
          ></div>

          {/* Step 1 */}
          <div className="flex flex-col items-center z-10">
            <div
              className={`w-[50px] h-[50px] md:w-[55px] md:h-[55px] flex justify-center items-center rounded-full text-3xl font-semibold ${
                activeStep === 1 ? "bg-[#1E4DA1]" : "bg-[#1E4DA1]"
              } text-white`}
            >
              1
            </div>
            <div
              className="mt-2 text-sm sm:text-md font-semibold"
              style={{ color: activeStep === 1 ? "#1E4DA1" : "#ABA5A5" }}
            >
              İlan Detayları
            </div>
          </div>

          {/* Step 2 */}
          <div className="flex flex-col items-center z-10">
            <div
              className={`w-[50px] h-[50px] md:w-[55px] md:h-[55px] flex justify-center items-center rounded-full text-3xl font-semibold ${
                activeStep === 2 ? "bg-[#ABA5A5]" : "bg-[#ABA5A5]"
              } text-white`}
            >
              2
            </div>
            <div
              className="mt-2 text-sm sm:text-md font-semibold"
              style={{ color: activeStep === 2 ? "#1F4CA1" : "#ABA5A5" }}
            >
              Ön İzleme
            </div>
          </div>

          {/* Step 3 */}
          <div className="flex flex-col items-center z-10">
            <div
              className={`w-[50px] h-[50px] md:w-[55px] md:h-[55px] flex justify-center items-center rounded-full text-3xl font-semibold ${
                activeStep === 3 ? "bg-blue-800" : "bg-[#ABA5A5]"
              } text-white`}
            >
              3
            </div>
            <div
              className="mt-2 text-sm sm:text-md font-semibold"
              style={{ color: activeStep === 3 ? "#2253AF" : "#ABA5A5" }}
            >
              Doping
            </div>
          </div>

          <div className="flex flex-col items-center z-10">
            <div
              className={`w-[50px] h-[50px] md:w-[55px] md:h-[55px] flex justify-center items-center rounded-full text-3xl font-semibold ${
                activeStep === 4 ? "bg-blue-800" : "bg-[#ABA5A5]"
              } text-white`}
            >
              4
            </div>
            <div
              className="mt-2 text-sm sm:text-md font-semibold"
              style={{ color: activeStep === 4 ? "#2253AF" : "#ABA5A5" }}
            >
              Tebrikler
            </div>
          </div>
        </div>
      </div>

      {/* Mobile View */}
      <div className="py-4 bg-white hidden h-16 flex-row items-center md:hidden relative w-full">
        <div className="h-px w-full bg-mains">

        </div>
        <div className="absolute w-full flex flex-row items-center justify-around bg-transparent top-1/2 -translate-y-3 left-0">

          {/* Step 1 Item */}
          <div className="flex flex-col items-center">
            <div className={`h-6 w-6 flex ${activeStep == 1? 'bg-mains': 'bg-white'} border border-mains flex-shrink-0 shadow-md shadow-gray-600 aspect-square rounded-full`}>

            </div>
            <label className="text-[10px]">İlan Oluştur</label>
          </div>

          {/* Step 2 Item */}
          <div className="flex flex-col items-center">
            <div className={`h-6 w-6 flex ${activeStep == 2? 'bg-mains': 'bg-white'} border border-mains flex-shrink-0 shadow-md shadow-gray-600  aspect-square rounded-full`}>

            </div>
            <label className="text-[10px]">Ön İzleme</label>
          </div>

          {/* Step 3 Item */}
          <div className="flex flex-col items-center">
            <div className={`h-6 w-6 flex ${activeStep == 3? 'bg-mains': 'bg-white'} border border-mains flex-shrink-0 shadow-md shadow-gray-600  aspect-square rounded-full`}>

            </div>
            <label className="text-[10px]">Doping</label>
          </div>

          {/* Step 4 Item */}
          <div className="flex flex-col items-center">
            <div className={`h-6 w-6 flex ${activeStep == 4? 'bg-mains': 'bg-white'} border border-mains flex-shrink-0 shadow-md shadow-gray-600  aspect-square rounded-full`}>

            </div>
            <label className="text-[10px]">Tebrikler</label>
          </div>
        </div>
      </div>
    </>
  );
}

export { StepsPointsCard };
