import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface User {
  id: number;
  name: string;
  surname: string;
  email: string;
  phone: string;
  phone_code: string;
  country_id: number | null;
  status: string;
  is_email_verified: string;
  is_phone_verified: string;
  profile: any | null;
  birth_date: string | null;
  gender: string | null;
  last_login_date: string | null;
  oauth_key: string | null;
  show_candidate_pool: string;
  not_show_references: string;
  hide_working_company: string;
  is_job_search: string;
  is_blocked: string;
  is_setup: string;
  api_token: string;
}

interface AuthState {
  currentUser: User | null;
}

const initialState: AuthState = {
  currentUser: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
    },
    clearCurrentUser: (state) => {
      state.currentUser = null;
    },
  },
});

export const { setCurrentUser, clearCurrentUser } = authSlice.actions;
export default authSlice.reducer;
