import React from "react";
import SearchBar from "./searchBar";
import Featured from "./featured";
import JobPost from "./jobPost";
import Plan from "./plan";
import Guide from "./guide";
import Sectors from "./sectors";

const Body: React.FC = () => {
  return (
    <div className="h-auto bg-gray-100  flex flex-col  w-full ">
      <SearchBar />
      <Featured />
      <JobPost />
      <Plan />
      <Guide />
      <Sectors />
    </div>
  );
};

export default Body;
