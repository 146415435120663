import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


import services from "../../services/profile-services";




interface ICvDetail {
  id: number;
  user_id: number;
  company_id: number | null;
  visibility: "ALL";
  title: string;
  marital_status: string | null;
  military_status: string | null;
  military_postponement_date: Date | null;
  smoke_status: string | null;
  driving_licence_status: string | null;
  disability_status: string | null;
  website: string | null;
  summary: string | null;
  experience_level_id: number | null;
  education_level_id: number | null;
  score: number;
  prize: string | null;
  hobby: string | null;
  video_status: string | null;
  lang: "tr";
  is_default: 0 | 1;
  token: string;
  date: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

const initialState: ICvDetail = {
  id: 0,
  user_id: 0,
  company_id: null,
  visibility: "ALL",
  title: "",
  marital_status: null,
  military_status: null,
  military_postponement_date: null,
  smoke_status: null,
  driving_licence_status: null,
  disability_status: null,
  website: null,
  summary: null,
  experience_level_id: null,
  education_level_id: null,
  score: 0,
  prize: null,
  hobby: null,
  video_status: null,
  lang: "tr",
  is_default: 0,
  token: "",
  date: "",
  created_at: "",
  updated_at: "",
  deleted_at: null,
};

export const getCvDetail = createAsyncThunk(
  "cvDetail/getCvDetail",
  async () => {
    try {
      const response = await services.myCv();
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
);

const cvDetailSlice = createSlice({
  name: "CvDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCvDetail.fulfilled, (state, action) => {
      return { ...state, ...action.payload };
    });
  },
});

export default cvDetailSlice.reducer;
