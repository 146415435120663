import { ButtonHTMLAttributes } from "react"
import Icon from "./Icon"
import classNames from "classnames"
import { createContext } from "react"



type buttonVariantType = 'default' | 'secondary' | 'ghost' | 'destructive' | 'orange' | 'green'
type buttonSizeType = 'xs' | 'sm' | 'base' | 'xl'

type buttonPropsType = ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: buttonVariantType,
    size?: buttonSizeType,
    loading?: boolean
}


export type buttonContextType = {
    variant: buttonVariantType,
    size: buttonSizeType,
    loading: boolean
}


export const ButtonContext = createContext<buttonContextType>({
    variant: 'default',
    loading: false,
    size: 'base'
})

export function Button({
    variant = 'default',
    size = 'base',
    loading = false,
    className,
    ...props
}: buttonPropsType) {

    const values: buttonContextType = {
        variant,
        size,
        loading
    }

    const cn = classNames({
        'bg-primary-button-background text-white': variant == 'default',
        'bg-gray-200 text-gray-800 border !border-gry-100': variant == 'secondary',
        'bg-[#de5b2b] text-white': variant == 'orange',
        'bg-white text-primary-button-background !border-primary-button-background': variant == 'ghost',
        'bg-red-600 text-white': variant == 'destructive',
        'bg-green-600 text-white': variant == 'green',
        'text-base': size == 'base',
        'text-xs !py-1 !px-2': size == 'xs',
        'text-sm !px-4': size == 'sm',
        'text-xl !py-3 !px-8': size == 'xl'
    })

    return(
        <ButtonContext.Provider value={values}>
            <button {...props} className={`${cn} ${className} py-2 px-10 flex flex-row items-center justify-center gap-2 whitespace-nowrap border border-transparent rounded-md font-poppins transition-all`}>
                
            </button>
        </ButtonContext.Provider>
        
    )
}

Button.Icon = Icon