import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SiteMap from './sitemap/Index';


const Dashboard: React.FC = () => {
  return (
    <div className=''>
       <div className='w-full'>
        <Routes>
         <Route path="/" element={<SiteMap/>} />
        </Routes>
       </div>
    </div>
  );
};

export default Dashboard;