import React, { useContext, useEffect, useState } from "react";
import { CVPreviewContext } from "../contents";
import Axios from "../../../../services/axios";
import { useParams } from "react-router-dom";
import moment from "moment";
import { MdOutlineMessage } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";

const axios = new Axios();

const Messages = () => {
  const { setSendMessageShow, addNoteShow, handleSendMessageShow } =
    useContext(CVPreviewContext);
  const { slug } = useParams();
  const [notes, setNotes] = useState([]);

  return (
    <div
      className={
        "flex flex-col justify-center items-center p-5 bg-white gap-3 rounded-xl"
      }
    >
      <div className={"text-center text-2xl font-bold "}>Mesajlar</div>
      <div className={"text-center text-sm font-light"}>
        Aday ile iletişime geçmek için hemen mesaj gönderebilirsiniz.
      </div>

      <div
        onClick={() => handleSendMessageShow()}
        className={
          "mt-3 w-full px-5 py-2 border border-mains hover:bg-mains hover:text-white rounded text-center transition-all cursor-pointer font-bold text-mains"
        }
      >
        Yeni Mesaj
      </div>
    </div>
  );
};

export default Messages;
