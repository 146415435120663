import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { CustomFormField } from '../../../../../components/common/custom-form-field';
import { MdKeyboardArrowRight } from 'react-icons/md';
import services from '../../../../../services/profile-services';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object({
  country: Yup.string().required('Ülke alanı zorunludur'),
  city: Yup.string().required('Şehir alanı zorunludur'),
  district: Yup.string().required('İlçe alanı zorunludur'),
  day: Yup.string().required('Zorunlu*'),
  year: Yup.string().required('Zorunlu*'),
  month: Yup.string().required('Zorunlu*'),
});

interface DateDay {
  value: string;
  label: string;
}

interface DateOption {
  value: string;
  label: string;
}

const PersonelInformation: React.FC = (DateDay) => {
  const navigate = useNavigate();
  const [locations, setLocations] = useState({
    country: [],
    cities: [],
    district: [],
  });

  const initialDate: { day: DateDay[]; month: DateDay[]; year: DateDay[] } = {
    day: [],
    month: [
      { value: '1', label: 'Ocak' },
      { value: '2', label: 'Şubat' },
      { value: '3', label: 'Mart' },
      { value: '4', label: 'Nisan' },
      { value: '5', label: 'Mayıs' },
      { value: '6', label: 'Haziran' },
      { value: '7', label: 'Temmuz' },
      { value: '8', label: 'Ağustos' },
      { value: '9', label: 'Eylül' },
      { value: '10', label: 'Ekim' },
      { value: '11', label: 'Kasım' },
      { value: '12', label: 'Aralık' },
    ],
    year: [],
  };

  for (let i = 1; i <= 30; i++) {
    initialDate.day.push({ value: `${i}`, label: `${i}` });
  }

  for (let i = 1950; i <= 2024; i++) {
    initialDate.year.push({ value: `${i}`, label: `${i}` });
  }

  useEffect(() => {
    fetchCountry();
  }, []);

  const fetchCountry = async () => {
    try {
      const response = await services.countryGet();
      const country = response.data.countries.map(
        (country: { id: number; name_tr: string }) => ({
          value: country.id,
          label: country.name_tr,
        })
      );
      setLocations({ ...locations, country });
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const fetchCities = async (country: string) => {
    if (!country) return;
    try {
      const response = await services.cityGet(country);
      const cities = response.data.cities.map(
        (district: { id: number; name: string }) => ({
          value: district.id.toString(),
          label: district.name,
        })
      );
      setLocations((prev) => ({ ...prev, cities }));
    } catch (error) {
      console.error('hata');
    }
  };

  const fetchDistricts = async (city: string) => {
    if (!city) return;

    try {
      const response = await services.districtGet(city);
      const district = response.data.districts.map(
        (district: { id: number; name: string }) => ({
          value: district.id.toString(),
          label: district.name,
        })
      );
      setLocations((prev) => ({ ...prev, district }));
    } catch (error) {
      console.error('hata');
    }
  };

  const formik = useFormik({
    initialValues: {
      country: '',
      city: '',
      district: '',
      year: '',
      month: '',
      day: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      const country_id = values.country;
      const city_id = values.city;
      const district_id = values.district;
      const birth_day = values.day;
      const birth_month = values.month;
      const birth_year = values.year;
      const gender_id = '1';

      const userData = {
        country_id,
        city_id,
        district_id,
        birth_day,
        birth_month,
        birth_year,
        gender_id,
      };

      try {
        const response = await services.personalInfo(userData);

        if (response) {
          navigate('/profilim/hosgeldin/istercihleri');
        }
      } catch (error) {
        throw new Error(error as string);
      }
    },
  });

  const [selectedOption, setSelectedOption] = useState<string>('');
  const [daysInMonth, setDaysInMonth] = useState<DateOption[]>([]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value);
  };

  const handleMonthChange = (selectedMonth: string) => {
    const month = parseInt(selectedMonth, 10);
    let days = 31;

    if (month === 2) {
      const year = parseInt(formik.values.year, 10);
      if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        days = 29;
      } else {
        days = 28;
      }
    } else if ([4, 6, 9, 11].includes(month)) {
      days = 30;
    }

    const updatedDaysInMonth = Array.from(
      { length: days },
      (_, index) => index + 1
    ).map((day) => ({
      value: `${day}`,
      label: `${day}`,
    }));
    setDaysInMonth(updatedDaysInMonth);
  };

  useEffect(() => {
    if (formik.values.month) {
      handleMonthChange(formik.values.month);
    }
  }, [formik.values.month]);

  React.useEffect(() => {
    if (formik.values.country) {
      fetchCities(formik.values.country);
    }
  }, [formik.values.country]);

  React.useEffect(() => {
    if (formik.values.city) {
      fetchDistricts(formik.values.city);
    }
  }, [formik.values.city]);

  return (
    <div className="bgwelcome ">
      <div className="w-full h-auto py-10 items-center justify-center flex mx-auto container">
        <div className="bg-white w-2/3 h-auto flex flex-col gap-y-5 shadow-lg py-5 border rounded-md">
          <div className="w-full border-b border-mains py-2 ">
            <span className="px-10 text-md poppins-medium text-mains">
              Kişisel Bilgiler
            </span>
          </div>
          <div className="w-full px-10 py-2  flex flex-col">
            <form
              className="flex flex-col gap-y-6"
              onSubmit={formik.handleSubmit}
            >
              <div className="w-full flex  justify-center">
                <img
                  className="w-20 h-20 rounded-full"
                  src="https://isbull.s3.eu-north-1.amazonaws.com/default-images/user-profile.png?v=2.9.90"
                  alt="user profile"
                />
              </div>

              <div className="w-full flex gap-x-4 items-center justify-center">
                <div className="flex flex-col w-1/2 gap-y-2">
                  <span className="text-sm poppins-medium text-gray-500">
                    Doğum Tarihi
                  </span>
                  <div className="flex gap-x-2 w-full">
                    <CustomFormField
                      type="select"
                      label="Yıl Seçiniz *"
                      formik={formik}
                      name="year"
                      options={initialDate.year}
                    />
                    <CustomFormField
                      type="select"
                      label="Ay Seçiniz *"
                      formik={formik}
                      name="month"
                      options={initialDate.month}
                    />
                    <CustomFormField
                      type="select"
                      label="Gün Seçiniz *"
                      formik={formik}
                      name="day"
                      options={daysInMonth}
                    />
                  </div>
                </div>

                <div className="flex flex-col w-1/2 gap-y-2">
                  <span className="text-sm poppins-medium text-gray-500">
                    Cinsiyetiniz
                  </span>
                  <div className="flex gap-x-2 w-full">
                    <input
                      id="male"
                      type="radio"
                      name="gender"
                      value="male"
                      checked={selectedOption === 'male'}
                      onChange={handleChange}
                      className="radio-input"
                    />
                    <label htmlFor="male">Erkek</label>

                    <input
                      id="female"
                      type="radio"
                      name="gender"
                      value="female"
                      checked={selectedOption === 'female'}
                      onChange={handleChange}
                      className="radio-input"
                    />
                    <label htmlFor="female">Kadın</label>

                    <input
                      id="unspecified"
                      type="radio"
                      name="gender"
                      value="unspecified"
                      checked={selectedOption === 'unspecified'}
                      onChange={handleChange}
                      className="radio-input"
                    />
                    <label htmlFor="unspecified">Belirtilmemiş</label>
                  </div>
                </div>
              </div>

              <div className="flex flex-col w-2/3 gap-y-2">
                <span className="text-sm poppins-medium text-gray-500">
                  Yaşadığınız Ülke
                </span>
                <div className="flex gap-x-2 w-full">
                  <CustomFormField
                    type="select"
                    label="Ülke Seçiniz *"
                    formik={formik}
                    name="country"
                    options={locations.country}
                  />
                  <CustomFormField
                    type="select"
                    label="Şehir Seçiniz *"
                    formik={formik}
                    name="city"
                    options={locations.cities}
                  />
                  <CustomFormField
                    type="select"
                    label="İlçe Seçiniz *"
                    formik={formik}
                    name="district"
                    options={locations.district}
                  />
                </div>
              </div>
              <div className="w-full justify-end flex">
                <div className="flex gap-x-2 bg-mains rounded-md px-5 py-3 ">
                  <button
                    onClick={formik.handleSubmit as any}
                    className="flex items-center "
                  >
                    <span className="text-white poppins-medium text-sm">
                      Kaydet ve Devam Et
                    </span>
                    <MdKeyboardArrowRight className="text-white text-lg" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonelInformation;
