import { IoIosRocket } from "react-icons/io";
import {
  IoPeople,
  IoPeopleCircle,
  IoPeopleCircleOutline,
} from "react-icons/io5";

export default function StepsSection() {
  return (
    <div className="py-2">
      <div className="container mx-auto">
        {/* About */}
        <div className="w-full max-w-[1140px] flex flex-col gap-4 mx-auto p-4 bg-white border border-gray-100 shadow-lg rounded-[36px]">
          <h2 className="text-title text-3xl font-semibold text-center">
            İlan Yayınlama Adımları
          </h2>
          <p className="text-[#555555] px-4 text-justify leading-[1.75] font-poppins">
            Vereceğiniz ilanda adaylarda aradığınız niteliklerin detaylı olarak
            ifade edebileceğiniz özel bir kısım bulunmaktadır. Bu kısımda
            dilediğiniz nitelikleri listeleyebilir, özel olarak belirtmek
            istediğiniz noktaları belirtebilirsiniz. Ayrıca ilanı yayınlamadan
            önce adaylara deneyimleri ya da yetkinlikleri hakkında soru
            sorabilme imkanınız bulunmaktadır. Böylece adayları daha ilk aşamada
            oluşturduğunuz sorularla eleme ya da gruplandırabilme imkanına sahip
            olabilirsiniz. İlanınızda eklemek istediğiniz alanları doldurduktan
            sonra firmanızın bulunduğu ya da aday toplayacağınız lokasyonları
            sayfanızın en altında yer alan kısımdan seçebilirsiniz. Ardından
            &quot;İlan Yayınlama Koşulları ve Gizlilik Politikasını Kabul
            Ediyorum&quot; seçeneğini tıklayarak ilanınızı &quot;Kaydet ve
            İlerle&quot; kısmından editör onayına gönderebilirsiniz. İlanınız
            yayınlandıktan kısa bir süre sonra başvurularınız artacak ve siz de
            bu adaylar içerisinde bir eleme imkanına sahip olacaksınız. Aday
            veri tabanında yüz binlerce adayı barındıran ve onlara iş olanakları
            sunan isbul.net&apos;in ücretsiz iş ilanı ver seçeneği ile mühendis,
            öğretmen, muhasebeci, bankacı, pazarlamacı, doktor, uzman, yönetici
            gibi ilanlara ek olarak vasıflı ve vasıfsız eleman, işçi, evden
            çalışabilecek ya da engelli eleman gibi çok farklı pozisyonlarda da
            ilan verebilirsiniz. Firmamız, her türlü alandan dilediğiniz
            çalışanı bulma şansı sunan ve bunu ücretsiz bir şekilde
            gerçekleştirmenizi sağlayan profesyonel firmalardan biridir.
            Aradığınız elemana hızlı bir şekilde ulaşmak isterseniz, isbul.net
            üzerinden ücretsiz ilan ver seçeneğini tıklayarak dilediğiniz ilanı
            yayına alabilirsiniz.
          </p>
        </div>

        {/* Steps */}
        <div className="grid grid-cols-2 max-w-[1140px] mx-auto w-full gap-6 mt-16">
          <div className="col-span-2 lg:col-span-1 flex flex-row items-center basis-1/2 gap-4 p-4 bg-white border border-gray-300 shadow-lg">
            <div className="aspect-square border border-title rounded-3xl p-4">
              <IoPeopleCircleOutline className="text-6xl text-title" />
            </div>
            <div className="flex flex-col gap-2">
              <label className="text-title text-xl font-semibold">ADIM 1</label>
              <p className="text-[#555555] font-[300] text-justify leading-[1.75] font-poppins">
                isbul.net&apos;e ücretsiz ve süre sınırlaması olmadan
                üyeliğinizi oluşturun.
              </p>
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1 flex flex-row items-center basis-1/2 gap-4 p-4 bg-white border border-gray-300 shadow-lg">
            <div className="aspect-square border flex-shrink-0 flex border-title rounded-3xl p-[22px]">
              <img
                src="https://isbul.net/frontend/images/isbul/ads-publish-step4.svg"
                className="w-[48px] h-[48px] aspect-square"
              />
              {/* <IoPeopleCircleOutline className="text-6xl text-title"/> */}
            </div>
            <div className="flex flex-col gap-2">
              <label className="text-title text-xl font-semibold">ADIM 2</label>

              <p className="text-[#555555] font-[300] text-justify leading-[1.75] font-poppins">
                Üyelik oluşturduktan sonra 1 adet ücretsiz ilan hakkınız
                hesabınıza otomatik olarak tanımlanır.
              </p>
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1 flex flex-row items-center basis-1/2 gap-4 p-4 bg-white border border-gray-300 shadow-lg">
            <div className="aspect-square border border-title rounded-3xl p-4">
              <IoIosRocket className="text-6xl text-title" />
            </div>
            <div className="flex flex-col gap-2">
              <label className="text-title text-xl font-semibold">ADIM 3</label>
              <p className="text-[#555555] font-[300] text-justify leading-[1.75] font-poppins">
                İlan hakkınızı kullanmak için hesabınızdaki &quot;İlan
                Yayınla&quot; butonuna tıklamanız yeterlidir.
              </p>
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1 flex flex-row items-center basis-1/2 gap-4 p-4 bg-white border border-gray-300 shadow-lg">
            <div className="aspect-square border flex-shrink-0 flex border-title rounded-3xl p-[22px]">
              <img
                src="https://isbul.net/frontend/images/isbul/ads-publish-step5.svg"
                className="w-[48px] h-[48px] aspect-square"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label className="text-title text-xl font-semibold">ADIM 4</label>
              <p className="text-[#555555] font-[300] text-justify leading-[1.75] font-poppins">
                İlan içeriğiniz tamamlandıktan sonra ilanınız yayınlanmak üzere
                sisteme gönderilir.
              </p>
            </div>
          </div>

          <div className="col-span-2 lg:col-span-1 flex flex-row items-center basis-1/2 gap-4 p-4 bg-white border border-gray-300 shadow-lg">
            <div className="aspect-square border flex-shrink-0 flex border-title rounded-3xl p-[22px]">
              <img
                src="https://isbul.net/frontend/images/isbul/ads-publish-step3.svg"
                className="w-[48px] h-[48px] aspect-square"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label className="text-title text-xl font-semibold">ADIM 5</label>
              <p className="text-[#555555] font-[300] text-justify leading-[1.75] font-poppins">
                Sisteme gönderilen ilanınız isbul.net editörleri tarafından
                kontrol edildikten sonra yayına alınır.
              </p>
            </div>
          </div>
          <div className="col-span-2 lg:col-span-1 flex flex-row items-center basis-1/2 gap-4 p-4 bg-white border border-gray-300 shadow-lg">
            <div className="aspect-square border flex-shrink-0 flex border-title rounded-3xl p-[22px]">
              <img
                src="https://isbul.net/frontend/images/isbul/ads-publish-step6.svg"
                className="w-[48px] h-[48px] aspect-square"
              />
            </div>
            <div className="flex flex-col gap-2">
              <label className="text-title text-xl font-semibold">ADIM 6</label>
              <p className="text-[#555555] font-[300] text-justify leading-[1.75] font-poppins">
                Yayına alınan ilanınıza iş arayanlar tarafından gelen
                başvuruları hesabınızdan kontrol edebilirsiniz.
              </p>
            </div>
          </div>
        </div>

        <div className="w-full max-w-[1140px] flex flex-col gap-4 mx-auto mt-12">
          <h2 className="text-2xl text-center text-[#555555]">
            ELEMAN BULMANIN NET ADRESİ
          </h2>
          <p className="text-[#555555] px-4 text-justify leading-[1.75] font-poppins">
            İsbul.net üzerinden verilen ilanlar, ilk önce editör onayından geçer
            ve otuz gün boyunca yayında kalır. İlanınızı dilediğiniz zaman
            durdurabilir dilediğiniz zaman yeniden aktif edebilirsiniz.
            Aradığınız elemanı bulduğunuz zaman ilanınızı pasif edebilir ya da
            içerikle ilgili birtakım düzenlemeler yapabilirsiniz. Yaptığınız
            düzenlemeler yine editör onayına düşer ve ilan tekrar yayına alınır.
            Süresi biten ya da pasif edilen ilanlarınızdaki başvuruları daha
            önceden görüntülemiş olsanız bile tekrar tekrar
            görüntüleyebilirsiniz.İsbul.net&apos;in sizlere sağladığı bir başka
            özellik ise ilanınızı verirken firma bilgilerinizi gizleyebilir,
            firmanızın logosunun ve isminin iş arayan adaylar tarafından
            görünmemesini sağlayabilirsiniz. Böylece adaylar sadece ilanın
            niteliği, tanımı, lokasyonu gibi alanlara erişebilirler. Aynı
            zamanda tam tersi şekilde ilanınıza logo ekleyebilir, firmanızın
            isminin ve logosunun tanınmasını da ücretsiz bir şekilde
            sağlayabilirsiniz.İsbul.net&apos;te her firmanın ihtiyacına göre
            eleman, her iş arayanın niteliklerine göre de iş bulunmaktadır. Yüz
            binlerce aday sizleri ilan vermeniz için bekliyor. Öyleyse isbul.net
            üzerinden hemen ilan verin, ücretsiz iş ilanınız ile de binlerce iş
            arayan adaya anında ulaşın!
          </p>
        </div>
      </div>
    </div>
  );
}
