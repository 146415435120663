import { Outlet, useParams } from "react-router-dom";
import LeftDashboard from "../../../components/common/profile/left-dashboard";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const DashboardLayout = () => {
  const [showLeftDashboard, setShowLeftDashboard] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/profilim/cv/goruntule") || location.pathname.startsWith('/profilim/hosgeldin')) {
      //FIXME: Buralara sonra bakılacak
      setShowLeftDashboard(false);
    } else {
      setShowLeftDashboard(true);
    }
  }, [location]);

  return (
    <div className="mx-auto container lg:mt-6 mb-10">
      <div className="lg:flex lg:gap-x-5 gap-y-5">
        {showLeftDashboard && (
          <div className={`w-full lg:w-2/6 xl:w-3/12 2xl:w-3/12`}>
            <LeftDashboard />
          </div>
        )}

        <div
          className={`w-full mt-5 lg:mt-0 ${
            showLeftDashboard && "lg:w-4/6 xl:w-9/12 2xl:w-9/12"
          }`}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
