import React, { useState, ChangeEvent, useEffect, Dispatch, SetStateAction, useRef } from "react";
import axios, { AxiosError } from "axios";
import AddDetail from "../../../../components/common/add-create-form/addDetail";
import CompanyDetailAndDescription from "../../../../components/common/add-create-form/company-detail-and-description";
import ExtraInformation from "./../../../../components/common/add-create-form/extra-information";
import TemplateSetting from "././../../../../components/common/add-create-form/template-setting";
import AddQuestion from "./././../../../../components/common/add-create-form/add-question";
import Conditions from "../../../../components/common/add-create-form/conditions";
import SaveAndNextuton from "../../../../components/common/add-create-form/save-and-next-button";
import Axios from "../../../../services/axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Button from "../../../../components/form-components/button";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";


interface ApiErrorResponse {
	errors: {
		[key: string]: string[];
	};
}
export interface MapLocation {
	lat: number;
	longitude: number;
}
interface Option {
	value: string;
	label: string;
}
interface Location {
	city: number;
	district: number;
}

export type fieldErrorType = {
	title: boolean,
	positions: boolean,
	position_levels: boolean,
	sections: boolean,
	sectors: boolean,
	working_types: boolean,
	working_locations: boolean,
	education_levels: boolean,
	detail: boolean,
	city: boolean,
	district: boolean
}

export interface Question {
	id: number;
	title: string;
	is_required: boolean;
}
export interface QuestionToAdd {
	question: number;
	is_required: boolean;
}

export interface AdvertPayload {
	[key: string]: any;
	title: string;
	//TODO: Test için null yapıldı tekrar düzeltilecek.
	map_location: MapLocation | null;
	sections: number[]; // Array of section IDs
	sectors: number[]; // Array of sector IDs
	positions: number[]; // Array of position IDs
	working_types: number[]; // Array of working type IDs
	working_locations: number[]; // Array of working location IDs
	position_levels: number[]; // Array of position level IDs
	experience_level_type: "not-matter" | "experienced" | "inexperienced";
	experience_level_min: number;
	experience_level_max: number;
	education_levels: number[]; // Array of education level IDs
	employee_count: number;
	min_age: number;
	max_age: number;
	gender: number; // Gender ID
	language: number; // Primary language ID
	languages: number[]; // Array of language IDs
	country: number; // Country ID
	keywords: string[]; // Array of keywords
	detail: string; // Detailed description
	hide_contact_info: "show_all" | "show_gsm" | "show_phone" | "hide_all";
	hide_employee_info: boolean;
	hide_company_info: boolean;
	locations: Location[]; // Array of locations (city and district IDs)
	job_benefits: number[]; // Array of job benefit IDs
	driving_licence_srcs: number[]; // Array of driving license source IDs
	is_send_message_users: boolean;
	is_disabled_employee: boolean;
	template_type: "DEFAULT" | "MAP" | "IMAGE" | "MAP-IMAGE";
	questions: { question: number; is_required: boolean }[];
	terms: number;
	advert_file: number;
}
const languageIdMap: { [key: string]: number } = {
	English: 1,
	Türkçe: 2,
	// other languages...
};
function isAxiosError(error: any): error is AxiosError<ApiErrorResponse> {
	// The double negation !! ensures the expression evaluates to a boolean
	return !!(
		axios.isAxiosError(error) &&
		error.response &&
		"errors" in error.response.data
	);
}
function AdvertCreateForm({
	mobileActivePage,
	setMobileActivePage
}: {
	mobileActivePage?: number,
	setMobileActivePage?: Dispatch<SetStateAction<number>>
}) {
	const navigate = useNavigate();
	const [isSubmit, setIsSubmit] = useState(false);
	const [formData, setFormData] = useState<AdvertPayload>({
		title: "",
		locations: [{ city: 0, district: 0 }], // For Cities Districts select input
		//map_location: { lat: 38, longitude: 33 }, // For map input
		map_location: null,
		positions: [],
		position_levels: [],
		sections: [],
		sectors: [],
		working_types: [],
		working_locations: [], // For /Uzakta/Hibert....
		languages: [],
		education_levels: [],
		experience_level_type: "not-matter", // Default value
		language: 9, // General languages Türkçe id
		experience_level_min: 0,
		experience_level_max: 0,
		keywords: [],
		detail: "",
		hide_employee_info: false,
		hide_company_info: false,
		hide_contact_info: "show_all", // Default value
		driving_licence_srcs: [],
		driving_licence_classes: [],
		job_benefits: [],
		is_send_message_users: false,
		is_disabled_employee: false,
		template_type: "DEFAULT", // Default value
		questions: [], // Default empty question

		min_age: 18, // Default minimum age
		max_age: 40, // Default maximum age
		gender: 0,
		country: 0,

		employee_count: 0,
		terms: 0,
		advert_file: 0,
	});

	useEffect(() => {
		console.log('formData', formData)
	}, [formData])

	
	const [fieldErrors, setFieldErrors] = useState<fieldErrorType>({
		title: false, // İlan Başlığı
		positions: false, // Pozisyon Adı
		position_levels: false, // Pozisyon Seviyesi
		sections: false, // Bölüm
		sectors: false, // Sektor
		working_types: false, // Çalışma Şekli
		working_locations: false, // Çalışma Tercihi
		education_levels: false, // Eğitim Seviyesi
		detail: false,
		city: false,
		district: false
	});

	const validateForm = () => {
		setIsSubmit(true);

		const errors = {
			title: !formData.title.trim(),
			city: false,
			district: false,
			positions: false,
			position_levels: false,
			sections: false,
			sectors: false,
			working_types: false,
			working_locations: false,
			education_levels: false,
			detail: false,
		};

		errors.title = !formData.title.trim();
		for (const location of formData.locations) {
			if (location.city == 0) {
				errors.city = true
			}
			if (location.district == 0) {
				errors.district = true
			}
		}
		errors.positions = formData.positions.length === 0;
		//errors.position_levels = formData.position_levels.length === 0
		errors.sections = formData.sections.length === 0;
		errors.sectors = formData.sectors.length === 0;
		errors.working_types = formData.working_types.length === 0;
		errors.working_locations = formData.working_locations.length === 0;
		errors.education_levels = formData.education_levels.length === 0;
		errors.detail = formData.detail.length === 0;

		setFieldErrors(errors);
		console.log("Updated fieldErrors:", errors);
		return Object.values(errors).every((value) => !value);
	};
	//console.log("nasee", formData.locations);

	const handleCheckboxArrayChange = (value: number, key: string) => {
		setFormData((prevState) => {
			// Assuming the array contains numbers based on your interface definitions
			const array = (prevState[key] as number[]) || [];
			const newArray = array.includes(value)
				? array.filter((item: number) => item !== value)
				: [...array, value];
			return { ...prevState, [key]: newArray };
		});
	};

	//console.log('language', formData.language)

	const handleChange = (
		event: React.ChangeEvent<
			HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
		>,
		isNested: boolean = false,
		nestedKey?: string
	) => {
		const target = event.target as HTMLInputElement; // Assumption made here for simplification
		const { name, value, type } = target;
		const checked = type === "checkbox" ? target.checked : undefined;

		if (isNested && nestedKey) {
			// Handle nested objects like map_location
			setFormData((prevState) => ({
				...prevState,
				[nestedKey]: {
					...prevState[nestedKey],
					[name]: type === "checkbox" ? checked : value,
				},
			}));
		} else if (type === "checkbox") {
			// Handle checkboxes
			setFormData((prevState) => ({
				...prevState,
				[name]: checked,
			}));
		} else {
			console.log(`Field: ${name}, Value: ${value}`);
			// Handle regular inputs
			setFormData((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};
	const handleChangeCheckBox = (
		event: React.ChangeEvent<
			HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
		>,
		isNested: boolean = false,
		nestedKey?: string
	) => {
		// Assert event.target as HTMLInputElement for checkbox handling
		const target = event.target as HTMLInputElement;
		const { name, value, type, checked } = target;

		if (type === "checkbox" && name === "terms") {
			// Special handling for the 'terms' checkbox
			setFormData((prevState) => ({
				...prevState,
				[name]: checked ? 1 : 0,
			}));
		} else if (type === "checkbox") {
			// Handle other checkboxes, if any
		} else {
			// Handle other types of inputs
			setFormData((prevState) => ({
				...prevState,
				[name]: value,
			}));
		}
	};
	const handleDropdownChange = (id: string, selectedOptions: Option[]) => {
		setFormData((prevState) => ({
			...prevState,
			[id]: selectedOptions.map((option) => option.value), // Update the appropriate field based on the dropdown's ID
		}));
	};
	const handleLanguageChange = (languageId: number) => {
		setFormData((prevState) => ({
			...prevState,
			language: languageId,
		}));
	};
	const handleExperienceLevelChange = (values: [number, number]) => {

		//console.log('experience values', values)
		setFormData((prevState) => ({
			...prevState,
			experience_level_min: values[0],
			experience_level_max: values[1],
		}));
	};
	const handleKeywordsChange = (newKeywords: string[]) => {
		setFormData((prevState) => ({
			...prevState,
			keywords: newKeywords,
		}));
		//console.log('keywords', newKeywords)
	};
	const handleDetailChange = (detail: string) => {
		setFormData((prevState) => ({
			...prevState,
			detail: detail,
		}));
	};

	const handleHideContactInfoChange = (
		value: "show_all" | "show_gsm" | "show_phone" | "hide_all"
	) => {
		setFormData((prevState) => ({
			...prevState,
			hide_contact_info: value,
		}));
	};

	const handleHideCompanyInfoChange = (value: boolean) => {
		setFormData((prevState) => ({
			...prevState,
			hide_company_info: value,
		}));
	};

	const handleHideEmployeeInfoChange = (value: boolean) => {
		setFormData((prevState) => ({
			...prevState,
			hide_employee_info: value,
		}));
	};
	const handleYanHaklarChange = (selectedOptions: Option[]) => {
		const jobBenefits = selectedOptions.map((option) => {
			console.log("Converting option value to number:", option.value); // Debug log
			return Number(option.value);
		});
		console.log("Updated job benefits:", jobBenefits); // Check the results
		setFormData((prevState) => ({
			...prevState,
			job_benefits: jobBenefits.filter((value) => !isNaN(value)), // Ensure only valid numbers are included
		}));
	};

	// Handler for Sürücü Lisansları selection
	const handleSurucuLisanslariChange = (selectedOptions: Option[]) => {
		const drivingLicenceSrcs = selectedOptions.map((option) =>
			Number(option.value)
		); // Assuming the values are IDs
		setFormData((prevState) => ({
			...prevState,
			driving_licence_classes: drivingLicenceSrcs,
		}));
	};


	const handleSrcLisanslariChange = (selectedOptions: Option[]) => {
		const drivingLicenceSrcs = selectedOptions.map((option) =>
			Number(option.value)
		); // Assuming the values are IDs
		setFormData((prevState) => ({
			...prevState,
			driving_licence_srcs: drivingLicenceSrcs,
		}));
	};

	const handleIsSendMessageUsersChange = (
		event: ChangeEvent<HTMLInputElement>
	) => {
		setFormData((prevState) => ({
			...prevState,
			is_send_message_users: event.target.value === "1", // Assuming '1' represents true/yes
		}));
	};

	const handleIsDisabledEmployeeChange = (
		event: ChangeEvent<HTMLInputElement>
	) => {
		setFormData((prevState) => ({
			...prevState,
			is_disabled_employee: event.target.checked,
		}));
	};



	const handleTemplateTypeChange = (
		templateType: "DEFAULT" | "MAP" | "IMAGE" | "MAP-IMAGE"
	) => {
		setFormData((prevState) => ({
			...prevState,
			template_type: templateType,
		}));
	};
	const handleLocationChange = (newLocations: Location[]) => {

		setFormData((prevState) => ({
			...prevState,
			locations: newLocations,
		}));
	};

	const clearQuestionsForm = () => {
		setFormData((prev) => ({
			...prev,
			questions: []
		}))
	}
	const addQuestionToForm = (newQuestion: {
		id: number;
		is_required: boolean;
	}) => {
		setFormData((prevState) => ({
			...prevState,
			questions: [
				...prevState.questions,
				{ question: newQuestion.id, is_required: newQuestion.is_required },
			],
		}));
	};
	const updateMapLocation = (newLocation: MapLocation | null) => {
		setFormData({ ...formData, map_location: newLocation });
	};
	const handleImageSelect = (imageId: number) => {
		setFormData((prevState) => ({
			...prevState,
			advert_file: imageId,
		}));
	};
	const handleSubmit = async () => {
		const isValid = validateForm();
		
		//console.log('isValid', isValid, fieldErrors)
		if (!isValid) {
			//const missingFieldsHtml2 = Object.entries(fieldErrors)

			const errors = {
				title: !formData.title.trim(),
				city: false,
				district: false,
				positions: false,
				position_levels: false,
				sections: false,
				sectors: false,
				working_types: false,
				working_locations: false,
				education_levels: false,
				detail: false,
			};
	
			errors.title = !formData.title.trim();
			for (const location of formData.locations) {
				if (location.city == 0) {
					errors.city = true
				}
				if (location.district == 0) {
					errors.district = true
				}
			}
			errors.positions = formData.positions.length === 0;
			//errors.position_levels = formData.position_levels.length === 0
			errors.sections = formData.sections.length === 0;
			errors.sectors = formData.sectors.length === 0;
			errors.working_types = formData.working_types.length === 0;
			errors.working_locations = formData.working_locations.length === 0;
			errors.education_levels = formData.education_levels.length === 0;
			errors.detail = formData.detail.length < 140;

			/* 
			TODO: Bu kısım ilk submit işleminde boş dündüğü için yukarıdaki gibi değiştirildi
			.filter(([_, value]) => value)
			.map(([key]) => {
				let fieldName = "";
				switch (key) {
					case "title":
						fieldName = "İlan Başlığı";
						break;
					case "positions":
						fieldName = "Pozisyon Adı ";
						break;
					case "position_levels":
						fieldName = "Pozisyon Seviyesi";
						break;
					case "sections":
						fieldName = "Bölüm ";
						break;
					case "sectors":
						fieldName = "Sektor ";
						break;
					case "working_types":
						fieldName = "Çalışma Şekli";
						break;
					case "working_locations":
						fieldName = "Çalışma Tercihi ";
						break;
					case "languages":
						fieldName = "Yabancı Dil ";
						break;
					case "education_levels":
						fieldName = "Eğitim Seviyesi ";
						break;
					case "detail":
						fieldName = "Şirket / İş Tanımı / Aranan Nitelikler";
						break;
					default:
						fieldName = key.charAt(0).toUpperCase() + key.slice(1);
				}
				return `<div style="color: red;">${fieldName}</div>`;
			})
			.join(""); */

			const missingFieldsHtml = `
			<div style="color: red;">
			${errors.title? 'İlan Başlığı <br/>': ''}
			${errors.city? 'Şehir <br/>': ''}
			${errors.district? 'İlçe <br/>': ''}
			${errors.positions? 'Pozisyon Adı <br/>': ''}
			${errors.position_levels? 'Pozisyon Seviyesi <br/>': ''}
			${errors.sections? 'Bölüm <br/>': ''}
			${errors.sectors? 'Sektör <br/>': ''}
			${errors.working_types? 'Çalışma Şekli <br/>': ''}
			${errors.working_locations? 'Çalışma Tercihi <br/>': ''}
			${errors.education_levels? 'Eğitim Seviyesi <br/>': ''}
			${errors.detail? 'Şirket / İş Tanımı / Aranan Nitelikler <br/>': ''}
			</div>
			`

			Swal.fire({
				icon: "error",
				title: "Uyarı:",
				html: `Lütfen aşağıdaki alanları doldurun: ${missingFieldsHtml}`,
				confirmButtonText: "OK",
				customClass: {
					confirmButton: "sweet-alert-button", // Optionally add styles with TailwindCSS
				},
			});
			console.log("Validation failed, errors:", fieldErrors);
			return; // Stop the submission if validation fails
		}
		if (formData.terms !== 1) {
			Swal.fire({
				icon: "error",
				title: "Uyarı:",
				html: `Gizlilk Sözleşmesi, Üyelik Sözleşmesi ve Kişisel Verilerin Korunması Bilgilendirmesini okudum anladım seçeneğini işaretleyiniz.`,
				confirmButtonText: "OK",
				customClass: {
					confirmButton: "sweet-alert-button", // Optionally add styles with TailwindCSS
				},
			});
			return;
		}

		const axiosInstance = new Axios();

		try {
			const response = await axiosInstance.authRequest({
				endpoint: `companies/home/get-count-dashboard`,
				type: 'get',
				payload: {}
			})

			//console.log('response', response)
	
			if (response && response.data && response.data.data) {

				if (response.data.data.right_to_create_advert_count > 0) {

				} else {
					Swal.fire({
						icon: 'error',
						title: 'Hata',
						text: 'İlan ekleme hakkınız kalmadı. Paketler sayfasına yönlendiriliyorsunuz.'
					})

					//TODO: kontroller sağlandıktan sonra yönlendirme yapılacak
					setTimeout(() => navigate('/isveren/paket'), 3000)
					return
				}
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Hata',
					text: 'Kalan ilan sayınız görüntülenemedi.'
				})

				return
			}
		} catch (error) {
			console.log(`Error: ${error}`)
			Swal.fire({
				icon: 'error',
				title: 'Hata',
				text: 'Kalan ilan sayınız görüntülenemedi.'
			})

			return
		}
		
		try {		
			const response = await axiosInstance.authRequest({
				type: "post",
				endpoint: "companies/advert/create-advert",
				payload: formData,
			});

			//console.log('formdata', formData)
			//console.log(response);

			const { advert_id } = response.data;
			if (advert_id) {
				setTimeout(() => {
					// Navigate to the new URL using the advert_id
					navigate(`/isveren/ilanlar/ilan/${advert_id}/onizleme`);
				}, 200);
			}
			// Handle success (e.g., show a success message or redirect)
		} catch (error) {
			if (
				isAxiosError(error) &&
				error.response &&
				error.response.data &&
				error.response.data.errors
			) {
				const errors = error.response.data.errors;
				let errorMessages = Object.keys(errors)
					.map((key) => {
						return `<div style="color: red;">${errors[key].join("<br>")}</div>`;
					})
					.join("<br>");

				Swal.fire({
					icon: "error",
					title: "Hata",
					html: errorMessages,
					confirmButtonText: "Tamam",
					customClass: {
						confirmButton: "sweet-alert-button", // Optionally add styles with TailwindCSS
					},
				});
			} else {
				// Default error message if no detailed errors provided
				Swal.fire({
					icon: "error",
					title: "Uyarı...",
					text: "Beklenmeyen bir hata oluştu.",
					confirmButtonText: "Tamam",
					customClass: {
						confirmButton: "sweet-alert-button",
					},
				});
			}
			console.error(error);
		}
	};

	/* useEffect(() => {
		console.log('formData', formData)
	}, [formData]) */

	// Advert detail observation container
	/* const advertDetailRef = useRef<HTMLDivElement>(null)
	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			const entry = entries[0]

			if (setMobileActivePage && entry.isIntersecting) {
				setMobileActivePage(1)
			}
		})

		if (advertDetailRef.current == null) return
		observer.observe(advertDetailRef.current)
	}, []) */


	// Advert others observation container
	/* const advertOthersRef = useRef<HTMLDivElement>(null)
	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			const entry = entries[0]

			if (setMobileActivePage && entry.isIntersecting) {
				setMobileActivePage(2)
			}
		})

		if (advertOthersRef.current == null) return
		observer.observe(advertOthersRef.current)
	}, []) */

	// Advert bottom site observation container
	/* const advertBottomRef = useRef<HTMLDivElement>(null)
	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			const entry = entries[0]

			if (setMobileActivePage && entry.isIntersecting) {
				setMobileActivePage(3)
			}
		})

		if (advertBottomRef.current == null) return
		observer.observe(advertBottomRef.current)
	}, []) */

	

	// Mobile page next
	// Page 1
	function mobilePage1Confirm() {
		if (setMobileActivePage) {
			validateForm()
			
			const errors = {
				title: !formData.title.trim(),
				city: false,
				district: false,
				positions: false,
				position_levels: false,
				sections: false,
				sectors: false,
				working_types: false,
				working_locations: false,
				education_levels: false,
				detail: false,
			};
	
			errors.title = !formData.title.trim();
			for (const location of formData.locations) {
				if (location.city == 0) {
					errors.city = true
				}
				if (location.district == 0) {
					errors.district = true
				}
			}

			errors.positions = formData.positions.length === 0;
			//errors.position_levels = formData.position_levels.length === 0
			errors.sections = formData.sections.length === 0;
			errors.sectors = formData.sectors.length === 0;
			errors.working_types = formData.working_types.length === 0;
			errors.working_locations = formData.working_locations.length === 0;
			errors.education_levels = formData.education_levels.length === 0;

			let isError: boolean = Object.values(errors).some((value) => value == true)

			if (isError) {
				const missingFieldsHtml = `
				<div style="color: red;">
				${errors.title? 'İlan Başlığı <br/>': ''}
				${errors.city? 'Şehir <br/>': ''}
				${errors.district? 'İlçe <br/>': ''}
				${errors.positions? 'Pozisyon Adı <br/>': ''}
				${errors.position_levels? 'Pozisyon Seviyesi <br/>': ''}
				${errors.sections? 'Bölüm <br/>': ''}
				${errors.sectors? 'Sektör <br/>': ''}
				${errors.working_types? 'Çalışma Şekli <br/>': ''}
				${errors.working_locations? 'Çalışma Tercihi <br/>': ''}
				${errors.education_levels? 'Eğitim Seviyesi <br/>': ''}
				</div>
				`
				Swal.fire({
					icon: 'error',
					title: 'Uyarı',
					html: missingFieldsHtml
				})
			} else {
				setMobileActivePage((prev) => prev + 1)
				window.scrollTo({ top:0, behavior: 'smooth'})
			}
			
		}
	}

	// Page2
	function mobilePage2Confirm() {
		if (setMobileActivePage) {

			const errors = {
				title: !formData.title.trim(),
				city: false,
				district: false,
				positions: false,
				position_levels: false,
				sections: false,
				sectors: false,
				working_types: false,
				working_locations: false,
				education_levels: false,
				detail: false,
			};
			errors.title = !formData.title.trim();
			for (const location of formData.locations) {
				if (location.city == 0) {
					errors.city = true
				}
				if (location.district == 0) {
					errors.district = true
				}
			}

			errors.positions = formData.positions.length === 0;
			//errors.position_levels = formData.position_levels.length === 0
			errors.sections = formData.sections.length === 0;
			errors.sectors = formData.sectors.length === 0;
			errors.working_types = formData.working_types.length === 0;
			errors.working_locations = formData.working_locations.length === 0;
			errors.education_levels = formData.education_levels.length === 0;
			errors.detail = formData.detail.length < 140 ;
			
			let isError: boolean = Object.values(errors).some((value) => value == true)

			if (isError) {
				const missingFieldsHtml = `
				<div style="color: red;">
				${errors.title? 'İlan Başlığı <br/>': ''}
				${errors.city? 'Şehir <br/>': ''}
				${errors.district? 'İlçe <br/>': ''}
				${errors.positions? 'Pozisyon Adı <br/>': ''}
				${errors.position_levels? 'Pozisyon Seviyesi <br/>': ''}
				${errors.sections? 'Bölüm <br/>': ''}
				${errors.sectors? 'Sektör <br/>': ''}
				${errors.working_types? 'Çalışma Şekli <br/>': ''}
				${errors.working_locations? 'Çalışma Tercihi <br/>': ''}
				${errors.education_levels? 'Eğitim Seviyesi <br/>': ''}
				${errors.detail? 'Şirket / İş Tanımı/ Aranan Nitelikler <br/>': ''}
				</div>
				`
				Swal.fire({
					icon: 'error',
					title: 'Uyarı',
					html: missingFieldsHtml
				})
			} else {
				setMobileActivePage((prev) => prev + 1)
				window.scrollTo({ top: 0, behavior: 'smooth' })

			}
		}
	}

	return (
		<div className='w-full  font-poppins'>
			<div className={`${mobileActivePage == 1? 'block': 'hidden md:block'} w-full`}>
				<AddDetail
				fieldErrors={fieldErrors}
				setFieldErrors={setFieldErrors}
				onDropdownChange={handleDropdownChange}
				handleChange={handleChange}
				handleLanguageChange={handleLanguageChange}
				onExperienceLevelChange={handleExperienceLevelChange}
				onKeywordsChange={handleKeywordsChange}
				onLocationChange={handleLocationChange}
				updateMapLocation={updateMapLocation}
				lat={formData.map_location?.lat}
				long={formData.map_location?.longitude}
				formData={formData}
				setFormData={setFormData}
				/>
				<Button 
				className="block md:hidden w-full items-center justify-center"
				size="sm"
				onClick={() => {
					mobilePage1Confirm()
				}}>
					Sonraki
					<Button.Icon>
						<IoChevronForward/>
					</Button.Icon>
				</Button>
			</div>
			<div className='flex flex-col justify-center w-full '>
				<div className={`${mobileActivePage == 2? 'block': 'hidden md:block'} w-full`}>
					<CompanyDetailAndDescription
						fieldErrors={fieldErrors}
						onDetailChange={handleDetailChange}
						onHideContactInfoChange={handleHideContactInfoChange}
						onHideCompanyInfoChange={handleHideCompanyInfoChange}
						onHideEmployeeInfoChange={handleHideEmployeeInfoChange}
						formData={formData}
						setFormData={setFormData}
					/>
					<div className="flex md:hidden flex-row items-center gap-2 mt-2">
						<Button 
						className="flex w-full items-center justify-center"
						variant="ghost"
						size="sm"
						onClick={() => {
							if (setMobileActivePage) {
								setMobileActivePage((prev) => prev - 1)
								window.scrollTo({ top: 0, behavior: 'smooth' })
							}
						}}
						>
							<Button.Icon>
								<IoChevronBack/>
							</Button.Icon>
							Önceki
						</Button>
						<Button
						className="flex w-full items-center justify-center"
						size="sm"
						onClick={() => {
							mobilePage2Confirm()
						}}
						>
							Sonraki
							<Button.Icon>
								<IoChevronForward/>
							</Button.Icon>
						</Button>
					</div>
				</div>
				<div className={`${mobileActivePage == 3? 'flex': 'hidden md:flex'}  flex-col justify-center w-full`}>
					<ExtraInformation
						onYanHaklarChange={handleYanHaklarChange}
						onSurucuLisanslariChange={handleSurucuLisanslariChange}
						onSrcLisanslariChange={handleSrcLisanslariChange}
						handleIsSendMessageUsersChange={handleIsSendMessageUsersChange}
						handleIsDisabledEmployeeChange={handleIsDisabledEmployeeChange}
					/>
					<TemplateSetting
						onTemplateTypeChange={handleTemplateTypeChange}
						onImageSelect={handleImageSelect}
						updateMapLocation={updateMapLocation}
						initialLat={formData.map_location?.lat || null}
						initialLong={formData.map_location?.longitude || null}
					/>
					{/* 
					FIXME: Burası kaldırıldı gerekli olursa tamamen silinecek veya tekrar eklenecek
					<AddQuestion 
					addQuestion={addQuestionToForm}
					initialQuestions={formData.questions}
					formQuestions={formData.questions}
					clearQuestions={clearQuestionsForm}
					/> */}
					{/* 
					Soruları görüntülemek için hazırlandı gerektiğinde kullanılacak
					<div>
						{formData.questions
						.filter((question) => question.question !== 0)
						.map((question, index) => (
							<div
								className='pl-3 mb-2 bg-white border border-gray-300 rounded shadow-lg'
								key={index}>
								<p className='text-gray-500'>Soru: {question.question}</p>
								<p className='text-gray-400'>
									Başvuru Soruları Zorunlu:{" "}
									{question.is_required ? "Evet" : "Hayır"}
								</p>
							</div>
						))}
						<div></div>
					</div> */}
					<Conditions
						onChange={handleChangeCheckBox}
						isChecked={formData.terms === 1}
					/>
					<div className="flex flex-row items-center justify-between gap-2 w-full my-6">
						<Button 
						variant="ghost"
						size="sm"
						className="flex md:hidden"
						onClick={() => {
							if (setMobileActivePage) {
								setMobileActivePage((prev) => prev - 1)
								window.scrollTo({ top: 0, behavior: 'smooth' })
							}
						}}
						>
							<Button.Icon>
								<IoChevronBack/>
							</Button.Icon>
							Geri
						</Button>

						<Button className="md:ml-auto" size="sm" onClick={handleSubmit}>
							Kaydet ve Devam Et
							<Button.Icon>
								<IoChevronForward/>
							</Button.Icon>
						</Button>
					</div>
					{/* <SaveAndNextuton onSubmit={handleSubmit} /> */}
				</div>
				
			</div>
		</div>
	);
}

export default AdvertCreateForm;
