import { HTMLAttributes } from "react";
import { useContext } from "react";
import { HovercardContext, hovercardContextType } from ".";

type contentPropsType = HTMLAttributes<HTMLDivElement> & {};

export default function HovercardContent({
  className,
  ...props
}: contentPropsType) {
  const { visible, content }: hovercardContextType =
    useContext(HovercardContext);

  if (!visible) return null;

  return (
    <div
      className={`${className} absolute bg-white border p-4 rounded shadow-lg`}
      {...props}
    >
      {content}
    </div>
  );
}
