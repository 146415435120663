import * as React from "react";
import { InfoSection } from "./info-section";
import { FilterSection } from "./filter-section";
import { useState, useEffect, createContext, useRef } from "react";
import CandidatePoolError from "./filter-section/components/components/error-boundary";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { IItem } from "./types";
import {
  ParamKeyValuePair,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { object } from "yup";
import { CookieName } from "../../../constants/enums";
import { useCookie } from "../../../hooks/useCookie";
import Axios from "../../../services/axios";
import { PaginationData } from "../index";

export type CandidatePoolFilterProps = {
  total_work_experience_min?: number;
  total_work_experience_max?: number;
  district?: number;
  cv_update_date_day?: number;
  cv_update_date_month?: number;
  working_types?: number[];
  salary_expectation_min?: number;
  salary_expectation_max?: number;
  sectors?: number[];
  language?: number;
  language_level?: number;
  education_level?: number;
  university_branch?: number;
  high_school_branch?: number;
  university?: number;
  disabled_status?: number;
  disability_types?: number[];
  disability_percent?: number;
  military_status?: number;
  military_postponement_date?: string;
  position?: number;
  filter_type?: number;
  cv_update_date_year?: number;
  driving_licence_status?: number;
  driving_licence_classes?: number[];
  driving_licence_srcs?: number[];
  smoke_status?: number;
  gender?: number;
  sections?: number[];
  min_age?: number;
  max_age?: number;
  marital_status?: number;
  page?: number;
  city?: number;
  now_working?: number;
};

interface IFilter {
  id?: number;
  title: string;
  params: { [key: string]: number };
}

interface CandidadeType {
  setNewSelectedValues: React.Dispatch<
    React.SetStateAction<CandidatePoolFilterProps>
  >;
  newSelectedValues: CandidatePoolFilterProps;
  handleFilter: () => void;
  clearFilters: () => void;
  queryParams: string;
  detailFiltersOpen: boolean;
  setDetailFiltersOpen: React.Dispatch<React.SetStateAction<boolean>>;
  scrollRef?: React.MutableRefObject<any>;
  savedSearchFilters: IFilter[];
  fetchSearchItems: () => void;
  pagination: PaginationData | null;
  setPagination: React.Dispatch<React.SetStateAction<PaginationData | null>>;
  showFilterSaveButton: boolean;
  setShowFilterSaveButton: React.Dispatch<React.SetStateAction<boolean>>;
  syncCounter: number;
  setSyncCounter: React.Dispatch<React.SetStateAction<number>>;
}

export const CandidatePoolContext = createContext<CandidadeType>({
  newSelectedValues: {},
  setNewSelectedValues: () => {},
  handleFilter: () => {},
  clearFilters: () => {},
  queryParams: "",
  detailFiltersOpen: false,
  setDetailFiltersOpen: () => {},
  savedSearchFilters: [],
  fetchSearchItems: () => {},
  pagination: null,
  setPagination: () => {},
  showFilterSaveButton: true,
  setShowFilterSaveButton: () => {},
  syncCounter: 0,
  setSyncCounter: () => {},
});

const axios = new Axios();

function CandidatePoolPage(): React.ReactElement {
  const [newSelectedValues, setNewSelectedValues] = useState<any>({});
  const [queryParams, setQueryParams] = useState("");
  const [queryParamsHold, setQueryParamsHold] = useState("");
  const [detailFiltersOpen, setDetailFiltersOpen] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [savedSearchFilters, setSavedSearchFilters] = useState<IFilter[]>([]);
  const [pagination, setPagination] = useState<PaginationData | null>(null);
  const location = useLocation();
  const { getCookie } = useCookie();
  const navigate = useNavigate();
  const companyUserToken = getCookie(CookieName.CompanyUserToken);
  const [isInit, setIsInit] = React.useState<boolean>(false);
  const [showFilterSaveButton, setShowFilterSaveButton] = useState(true);
  const [syncCounter, setSyncCounter] = React.useState<number>(0);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let newParams: any = {};
    params.forEach((value, key) => {
      newParams[key] = JSON.parse(value);
    });

    if (!isInit) {
      setNewSelectedValues(newParams);
      setIsInit(true);
    }
    setQueryParamsHold(location.search);
  }, [location.search]);

  useEffect(() => {
    setQueryParams(queryParamsHold);
  }, [queryParamsHold]);

  const handleFilter = (isEmpty = false) => {
    let constructedParams = "";
    Object.keys(isEmpty ? {} : newSelectedValues).forEach((key, index) => {
      if (key === "page") return;
      const value = newSelectedValues[key];
      constructedParams += `${index === 0 ? "?" : "&"}${key}=${
        typeof value === "object" ? `[${value}]` : value
      }`;
    });

    navigate(location.pathname + constructedParams);
    //setQueryParamsHold(constructedParams);
  };

  useEffect(() => {
    if (syncCounter > 0) {
      handleFilter();
    }
  }, [syncCounter]);

  const clearFilters = () => {
    setNewSelectedValues({});
    handleFilter(true);
  };

  const fetchSearchItems = async () => {
    try {
      const response = await axios.request({
        type: "get",
        endpoint: `companies/search/get-saved-filter`,
        payload: {
          headers: {
            Authorization: `Bearer ${companyUserToken}`,
          },
        },
      });
      if (response && response.data && response.data.company_searches) {
        const convertedFilters = response.data.company_searches.map(
          (item: { id: number; title: string; params: string }) => ({
            id: item.id,
            title: item.title,
            params: JSON.parse(item.params),
          })
        );

        setSavedSearchFilters(convertedFilters);
      }
    } catch (error) {
      throw new Error("Error fetching header card items: " + error);
    }
  };

  useEffect(() => {
    fetchSearchItems();
  }, []);

  const value = {
    newSelectedValues,
    setNewSelectedValues,
    handleFilter,
    clearFilters,
    queryParams,
    detailFiltersOpen,
    setDetailFiltersOpen,
    scrollRef,
    savedSearchFilters,
    fetchSearchItems,
    pagination,
    setPagination,
    showFilterSaveButton,
    setShowFilterSaveButton,
    syncCounter,
    setSyncCounter,
  };

  return (
    <main className=" flex flex-col items-center">
      <CandidatePoolContext.Provider value={value}>
        <CandidatePoolError>
          <FilterSection />
          <div id={"scroll-to-this"} ref={scrollRef}></div>
          <div className="  min-h-screen w-full max-w-[1200px] ">
            <InfoSection queryParams={queryParams} />
          </div>
        </CandidatePoolError>
      </CandidatePoolContext.Provider>
    </main>
  );
}
export { CandidatePoolPage };
