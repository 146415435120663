import React from "react";
import { LoginContent } from "../../../../components/common/login/login-content/index";

function LoginPages(): React.ReactElement {
	return (
		<div className='flex flex-col mx-auto '>
			<LoginContent
				type='login'
				categories='profile'
			/>
		</div>
	);
}

export default LoginPages;
