import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

interface ScatterData {
  x: number; // the years
  y: number;
}

const ScatterPlot: React.FC = () => {
  const generateData = (startYear: number, endYear: number, yMultiplier: number, yAddition: number): ScatterData[] => {
    const length = endYear - startYear + 1; 
    return Array.from({ length }, (_, i): ScatterData => ({
      x: startYear + i, 
      y: Math.random() * yMultiplier + yAddition,
    }));
  };

  const data = {
    datasets: [
      {
        label: 'Low',
        data: generateData(2017, 2024, 20, 0),
        backgroundColor: 'rgba(255, 99, 132, 1)',
        borderColor: 'rgba(255, 99, 132, 1)',
        showLine: true,
        fill: false,
      },
      {
        label: 'Medium',
        data: generateData(2017, 2024, 50, 25),
        backgroundColor: 'rgba(54, 162, 235, 1)',
        borderColor: 'rgba(54, 162, 235, 1)',
        showLine: true,
        fill: false,
      },
      {
        label: 'High',
        data: generateData(2017, 2024, 25, 75),
        backgroundColor: 'rgba(75, 192, 192, 1)',
        borderColor: 'rgba(75, 192, 192, 1)',
        showLine: true,
        fill: false,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: 'linear' as const, 
        title: {
          display: true,
          text: 'Year',
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default ScatterPlot;
