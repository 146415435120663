import React from "react";

interface DetailProps {
  id: string;
  title: string;
  details: string;
}

const Detail: React.FC<DetailProps> = ({ id, title, details }) => {
  console.log("Detail Props:", { id, title, details });
  return (
    <div id={id} className="p-5 lg:p-8">
      <h3 className="mb-4 fw-6 text-lg lg:text-xl">{title}</h3>
      <div
        className="text-base lg:text-md leading-relaxed"
        dangerouslySetInnerHTML={{ __html: details }}
      />
    </div>
  );
};

export default Detail;
