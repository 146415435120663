import React, { useContext } from "react";
import { CVPreviewContext } from "../contents";
import OpenCvPopup from "./OpenCvPopup";
import Packages from "./Packages";

const OpenCvInfo = () => {
  const { isBlock, cvViewCount, setOpenCvShow } = useContext(CVPreviewContext);
  return (
    <>
      {isBlock && cvViewCount > 0 && (
        <div
          className={
            "flex flex-col items-center rounded-2xl border border-red-500 bg-red-50 p-5 text-center m-5"
          }
        >
          <div className={"font-bold mb-3"}>İletişim Bilgileri</div>
          <div className={"font-bold mb-3"}>
            Bu özgeçmişi ilk defa görüntülüyorsunuz.
          </div>
          <div className={"mb-3"}>
            Adayın iletişim bilgilerini görüntüleyebilmek için alt kısımdaki
            “iletişim bilgilerini görüntüle” butonuna basınız.
          </div>
          <div
            onClick={() => setOpenCvShow(true)}
            className={
              "bg-mains text-white font-bold py-1 px-5 rounded-full text-sm cursor-pointer"
            }
          >
            İletişim Bilgilerini Görüntüle
          </div>
        </div>
      )}

      {isBlock && cvViewCount === 0 && <Packages />}
    </>
  );
};

export default OpenCvInfo;
