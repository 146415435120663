import React from "react";
import BarChart from "./BarChar";

const SalariesByCities: React.FC = () => {
  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      <div className="p-6">
        <h1 className="text-2xl mb-4">Şehirlere Göre Maaşlar</h1>
        <p>
          Yazılımcı, şehirlere göre aylık ne kadar maaş alır inceleyebilirsiniz.
        </p>

        {/* Adjusting this div to use grid instead of flex */}
        <div className="mt-6 grid gap-4 grid-cols-2 md:grid-cols-3">
          {/* Card 1 */}
          <div className="bg-gray-100 rounded-lg p-4 text-center">
            <h2 className="text-lg font-semibold text-[#217fe7]">İstanbul</h2>
            <p className="mt-2">Ortalama maaş 25.000 TL</p>
            <h3 className="mt-2 text-xl font-bold">30.000 TL</h3>
          </div>
          {/* Card 2 */}
          <div className="bg-gray-100 rounded-lg p-4 text-center">
            <h2 className="text-lg font-semibold text-[#64c11f]">Ankara</h2>
            <p className="mt-2">Ortalama maaş 20.000 TL</p>
            <h3 className="mt-2 text-xl font-bold">25.000 TL</h3>
          </div>
          {/* Card 3 */}
          <div className="bg-gray-100 rounded-lg p-4 text-center">
            <h2 className="text-lg font-semibold text-[#ffaf2a]">İzmir</h2>
            <p className="mt-2">Ortalama maaş 22.000 TL</p>
            <h3 className="mt-2 text-xl font-bold">27.000 TL</h3>
          </div>
          {/* Card 4 */}
          <div className="bg-gray-100 rounded-lg p-4 text-center">
            <h2 className="text-lg font-semibold text-[#0A246A]">Bursa</h2>
            <p className="mt-2">Ortalama maaş 23.000 TL</p>
            <h3 className="mt-2 text-xl font-bold">28.000 TL</h3>
          </div>
          {/* Card 5 */}
          <div className="bg-gray-100 rounded-lg p-4 text-center">
            <h2 className="text-lg font-semibold text-[#00AEEF]">Antalya</h2>
            <p className="mt-2">Ortalama maaş 21.000 TL</p>
            <h3 className="mt-2 text-xl font-bold">26.000 TL</h3>
          </div>
          {/* Card 6 */}
          <div className="bg-gray-100 rounded-lg p-4 text-center">
            <h2 className="text-lg font-semibold text-[#32b3b3]">Adana</h2>
            <p className="mt-2">Ortalama maaş 19.000 TL</p>
            <h3 className="mt-2 text-xl font-bold">24.000 TL</h3>
          </div>
        </div>
        <BarChart />
      </div>
    </div>
  );
};

export default SalariesByCities;
