import React from "react";
import { FiSearch } from "react-icons/fi";

const Title: React.FC = () => {
  return (
    <div className="w-full bg-white">
      <div
        className="container flex w-full mx-auto h-72"
        style={{ paddingTop: "60px" }}
      >
        <div className="flex items-center justify-between w-full h-full lg:w-full">
          {/* Adjusted Column for Title */}
          <div className="flex items-center w-full h-full mb-4">
            <h1 className="text-4xl font-bold lg:text-5xl text-title font-poppins">
              İllere Göre İş İlanları
            </h1>
          </div>

          {/* Right Column - Ensure this is aligned or displayed as needed */}
          <div className="flex items-center justify-center w-full lg:w-auto">
            <img
              src="/assets/images/PositionsHeader.png"
              alt="Description"
              className="h-full objcet-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Title;
