import * as React from "react";

interface IInfoItem {
  readonly label: string;
  readonly value: number;
}

function InfoItem(props: IInfoItem): React.ReactElement {
  if (!props.label || !props.value) {
    return <></>;
  }

  return (
    <div className="flex gap-x-2 items-center">
      <div className="w-[6px] h-[6px] bg-cover bg-center rounded-md bg-white" />
      <p className="poppins-medium text-white text-sm">
        {`${props.label} : ${props.value}`}
      </p>
    </div>
  );
}
export { InfoItem };
