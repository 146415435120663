import React, { useEffect, useState } from "react";
import Contents from "./contents";
import { useLocation } from "react-router-dom";
import services from "../../../services/profile-services";

const AddOnYazı: React.FC = () => {
  const location = useLocation();
  const [applies, setApplies] = useState([]);
  const [coverLetterText, setCoverLetterText] = useState(""); // Initial state without default text
  const pathSegments = location.pathname.split("/");
  const id = pathSegments[pathSegments.length - 2]; // Extracting the ID from the pathname

  // Function to update the cover letter text based on the current applies state
  const updateCoverLetterText = (appliedJobs: any[]) => {
    const appliedJob = appliedJobs.find((job) => job.id.toString() === id);
    if (appliedJob) {
      if (appliedJob.cover_letter !== null) {
        setCoverLetterText("ÖN YAZI GÜNCELLE");
      } else {
        setCoverLetterText("ÖN YAZI EKLE");
      }
    }
  };

  useEffect(() => {
    // Check current applies state initially
    updateCoverLetterText(applies);

    const getAppliedJobs = async () => {
      const response = await services.getAppliedJobs();
      const appliedJobs = response.data.applies;

      setApplies(appliedJobs);
      console.log("Applied Jobs:", appliedJobs);

      // Update the cover letter text based on the new applies state
      updateCoverLetterText(appliedJobs);
    };

    getAppliedJobs();
  }, [id]);

  useEffect(() => {
    // Check again once applies state is set
    if (applies.length > 0) {
      updateCoverLetterText(applies);
    }
  }, [applies]);

  return (
    <>
      <div className="flex items-center justify-between w-full px-4 py-5 bg-white rounded-md shadow-lg">
        <span className="text-lg lg:text-xl text-mains poppins-medium">
          {coverLetterText}
        </span>
      </div>

      <Contents />
    </>
  );
};

export default AddOnYazı;
