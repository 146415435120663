const Body: React.FC = () => {
  return (
    <div className="bg-gray-100 flex flex-col w-full py-10 font-poppins">
      <div className=" px-4 sm:px-4 lg:px-8 w-4/5 mx-auto">
        <h1 className="text-xl md:text-2xl font-bold text-center mb-4">
          İş Arama ve Bulma Platformu
        </h1>

        {/* We gonnna use api here  */}
        <div className="p-5 mb-1">
          <h2
            className="text-lg md:text-xl text-title font-medium"
            style={{ fontWeight: "500", fontSize: "30px" }}
          >
            İş Arama
          </h2>
          <div className="mt-8">
            <ul className="grid grid-cols-1 md:grid-cols-3 gap-4 list-disc pl-5 site-map-ul-li fw-5">
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Yönetici İş İlanları</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Engelli İş İlanları</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Bugün Yayınlanan İş İlanları</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Yeni Mezun İş İlanları</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>İşçi İş İlanları</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>İşçi İş İlanları</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>İşçi İş İlanları</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>İşçi İş İlanları</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className=" p-5 mb-1">
          <h2
            className="text-lg md:text-xl text-title font-medium"
            style={{ fontWeight: "500", fontSize: "30px" }}
          >
            İş Bul
          </h2>
          <div className="mt-8">
            <ul className="grid grid-cols-1 md:grid-cols-3 gap-4 list-disc pl-5 site-map-ul-li fw-5">
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Yönetici İş İlanları</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Engelli İş İlanları</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className=" p-5 mb-1">
          <h2
            className="text-lg md:text-xl text-title font-medium"
            style={{ fontWeight: "500", fontSize: "30px" }}
          >
            İsbul Blog
          </h2>
          <div className="mt-8">
            <ul className="grid grid-cols-1 md:grid-cols-3 gap-4 list-disc pl-5 site-map-ul-li fw-5">
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Yönetici İş İlanları</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Engelli İş İlanları</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className=" p-5 mb-1">
          <h2
            className="text-lg md:text-xl text-title font-medium"
            style={{ fontWeight: "500", fontSize: "30px" }}
          >
            Kurumsal
          </h2>
          <div className="mt-8">
            <ul className="grid grid-cols-1 md:grid-cols-3 gap-4 list-disc pl-5 site-map-ul-li fw-5">
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Yönetici İş İlanları</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Engelli İş İlanları</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className=" p-5 mb-1">
          <h2
            className="text-lg md:text-xl text-title font-medium"
            style={{ fontWeight: "500", fontSize: "30px" }}
          >
            İşverenler
          </h2>
          <div className="mt-8">
            <ul className="grid grid-cols-1 md:grid-cols-3 gap-4 list-disc pl-5 site-map-ul-li fw-5">
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Yönetici İş İlanları</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Engelli İş İlanları</span>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className=" p-5 mb-1">
          <h2
            className="text-lg md:text-xl text-title font-medium"
            style={{ fontWeight: "500", fontSize: "30px" }}
          >
            Yardım
          </h2>
          <div className="mt-8">
            <ul className="grid grid-cols-1 md:grid-cols-3 gap-4 list-disc pl-5 site-map-ul-li fw-5">
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Detaylı İş Arama</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Yönetici İş İlanları</span>
                </a>
              </li>
              <li className="fz-14px">
                <a href="#">
                  <span>Engelli İş İlanları</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Body;
