import { HTMLAttributes, createElement } from "react";
import classNames from "classnames";



type separatorPropsType = HTMLAttributes<HTMLDivElement | HTMLSpanElement> & {
    as?: 'div' | 'span',
    orientation?: 'vertical' | 'horizontal'
}




export function Separator({
    as = 'div',
    orientation = 'horizontal',
    className,
    children,
    ...props
}: separatorPropsType) {

    const cn = classNames({
        'bg-gray-300': true,
        'h-px w-full': orientation == 'horizontal',
        'w-px h-full': orientation == 'vertical'
    })
    return createElement(as, {
        className: `${cn} ${className}`,
        ...props
    })
}