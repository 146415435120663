import { HTMLAttributes, useContext, useEffect, useRef } from "react";
import { SelectContext, selectContextType } from ".";
import { TiArrowSortedUp } from "react-icons/ti";

type itemsPropsType = HTMLAttributes<HTMLDivElement>;

export default function Items({ className, ...props }: itemsPropsType) {
  const { isOpen }: selectContextType = useContext(SelectContext);

  if (isOpen) {
    return (
      <>
        {" "}
        <div className="absolute -bottom-7 right-[3px] z-[100]">
          <TiArrowSortedUp size={30} className="text-mains border-black" />
        </div>
        <div
          {...props}
          className={`${className} absolute shadow-xl flex flex-col max-h-[300px] md:overflow-auto  translate-y-full z-[999] -left-[40px] -bottom-4   bg-white border border-mains rounded-md`}
        ></div>
      </>
    );
  } else {
    return null;
  }
}

{
  /* 
FIXME: Mobil görünüm için düzenlenecek kısım
<div {...props} className={`${className} fixed lg:absolute flex flex-col gap-2 h-full max-h-[calc(100vh-30px)] lg:max-h-[300px] lg:h-[300px] overflow-auto lg:bottom-0 top-[15px] translate-y-0 lg:translate-y-full left-4 lg:left-0 lg:w-full p-2 bg-white w-[calc(100vw-32px)] border border-mains rounded-md z-40`}>
    
</div>
 */
}
