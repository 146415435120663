import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { IoMdArrowDropup } from "react-icons/io";

interface ICustomDropdownProps {
  readonly content: string[];
  readonly onItemSelect: (selectedItem: string) => void;
  readonly title?: string;
}

function CustomDropdown(props: ICustomDropdownProps): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleItemSelect = (item: string) => {
    props.onItemSelect(item);
    setIsOpen(false);
  };

  return (
    <div className="flex flex-col justify-between gap-4 md:flex-row md:items-center">
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <div>
              <Menu.Button
                className="inline-flex w-full justify-between rounded-md bg-mains px-4 py-2 text-lg poppins-semibold text-white hover:bg-mains/80 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 min-w-[125px]"
                onClick={handleButtonClick}
              >
                {props.title}
                {open ? (
                  <ChevronUpIcon
                    className="-mr-1 ml-2 mt-[2px] h-6 w-6 text-violet-200 hover:text-violet-100"
                    aria-hidden="true"
                  />
                ) : (
                  <ChevronDownIcon
                    className="-mr-1 ml-2 mt-[2px] h-6 w-6 text-violet-200 hover:text-violet-100"
                    aria-hidden="true"
                  />
                )}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              afterLeave={() => setIsOpen(false)}
            >
              <Menu.Items className="absolute top-[30px] right-0 w-56 mt-4 origin-top-right bg-white divide-y z-20 divide-gray-100 rounded-md shadow-lg ring-1 ring-black/5 focus:outline-none">
                <div className="px-1 py-1 relative">
                  {props.content.map((item, index) => (
                    <Menu.Item key={index}>
                      {({ active }) => (
                        <button
                          onClick={() => handleItemSelect(item)}
                          className={`${
                            active ? "bg-mains/90 text-white" : "text-gray-900"
                          } group flex w-full items-center px-2 py-2 text-md border-b`}
                        >
                          {item}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                  <IoMdArrowDropup
                    className="absolute -top-7 right-5 text-5xl text-white"
                    aria-hidden="true"
                  />
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}

export { CustomDropdown };
