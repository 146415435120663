import React from 'react';
import Contents from './contents';

const Notifications: React.FC = () => {
  return (
    <>
        <Contents/>
    </>
  );
};

export default Notifications;
