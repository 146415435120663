import { useEffect, useState } from "react";
import { getCertificates } from "../../../../../customHooks/getCertificates";
import {
  cdnCertificateType,
  dbUserCertificateType,
} from "../../../../../types";
import CertificateForm from "./components/CertificateForm";
import Axios from "../../../../../services/axios";
import { useParams } from "react-router-dom";
import { PiWarningCircle } from "react-icons/pi";
import { useContext } from "react";
import { CvLayoutContext, cvLayoutContextType } from "..";

const axios = new Axios();

export default function Certificates() {
  const { isEnglish }: cvLayoutContextType = useContext(CvLayoutContext);

  const params = useParams();

  const cvId = params.slug;

  /* General states */
  const [certificates, setCertificates] = useState<cdnCertificateType[]>([]);
  const [userCertificates, setUserCertificates] = useState<
    dbUserCertificateType[]
  >([]);
  const [userCertificatesLength, setUserCertificatesLength] =
    useState<number>(1);
  const [isCertificateFormModalOpen, setIsCertificateFormModalOpen] =
    useState<boolean>(false);

  async function getAllCertificates() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/cert/index?cv_id=${cvId}`,
        type: "get",
        payload: {},
      });

      if (response && response.data && response.data.certificates) {
        setUserCertificates(
          JSON.parse(JSON.stringify(response.data.certificates))
        );
        setUserCertificatesLength(response.data.certificates.length);
      } else {
        setUserCertificates([]);
      }
    } catch (error) {
      console.log(error);
      setUserCertificates([]);
    }
  }

  // fetch for certificates
  useEffect(() => {
    getAllCertificates();

    getCertificates({}).then((data) => {
      setCertificates(data);
    });
  }, []);
  return (
    <div className="flex flex-col gap-y-2 rounded-md bg-white lg:mt-5 pt-2 pb-4">
      {/* Empty certificate form */}
      <CertificateForm
        certificate={null}
        certificates={certificates}
        getAllCertificates={getAllCertificates}
        certificatesLength={userCertificatesLength}
        isModalOpen={isCertificateFormModalOpen}
        setIsModalOpen={setIsCertificateFormModalOpen}
      />

      {/* Empty certificate message */}
      {userCertificates.length == 0 && (
        <div className="flex flex-row gap-4 m-4 border border-gray-200 rounded-md">
          <div className="flex p-4 bg-red-600 rounded-l-md items-center justify-center">
            <PiWarningCircle className="text-3xl text-white" />
          </div>
          <div className="flex flex-col gap-1 py-2 px-1">
            <p className="font-poppins text-gray-700 font-semibold text-sm">
              {isEnglish
                ? "You don't have a certificate added yet."
                : "Henüz eklenmiş bir sertifikanız yok."}
            </p>
            {isEnglish ? (
              <p className="font-poppins text-sm text-gray-700 font-semibold">
                You can add new certificate with{" "}
                <button
                  className="text-mains underline"
                  onClick={() => setIsCertificateFormModalOpen(true)}
                >
                  Add Certificate
                </button>{" "}
                button.
              </p>
            ) : (
              <p className="font-poppins text-sm text-gray-700 font-semibold">
                <button
                  className="text-mains underline"
                  onClick={() => setIsCertificateFormModalOpen(true)}
                >
                  Sertifika Ekle
                </button>{" "}
                butonuna tıklayarak hemen yeni bir sertifika ekleyebilirsiniz.
              </p>
            )}
          </div>
        </div>
      )}

      {/* All certificates mapping */}
      {userCertificates.map((certificate) => {
        return (
          <div
            key={`certificateForm${certificate.id}`}
            className="flex flex-col gap-1 lg:gap-2"
          >
            <hr />
            <CertificateForm
              certificate={certificate}
              certificates={certificates}
              getAllCertificates={getAllCertificates}
              isModalOpen={isCertificateFormModalOpen}
              setIsModalOpen={setIsCertificateFormModalOpen}
            />
          </div>
        );
      })}
    </div>
  );
}
