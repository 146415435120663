import { IItem } from "../../../types";
import React, { useContext, useEffect, useState } from "react";
import { CustomModal } from "./modal";
import { CiFilter } from "react-icons/ci";
import { IoTrashBinOutline } from "react-icons/io5";
import { useIsMobile } from "../../../../../../hooks/useIsMobile";
import { ApplyPoolContext } from "../../../index";

function ButtonGroupSection() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    newSelectedValues,
    clearFilters,
    handleFilter,
    detailFiltersOpen,
    setDetailFiltersOpen,
    scrollRef,
    setShowFilterSaveButton,
    showFilterSaveButton,
  } = useContext(ApplyPoolContext);

  const { isMobile } = useIsMobile();

  const handleSaveValues = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    setShowFilterSaveButton(false);
  }, [newSelectedValues]);

  return (
    <section className="flex my-5 mx-2 md:mx-4 gap-3 flex-col md:flex-row text-white">
      {Object.keys(newSelectedValues).length > 0 && !isMobile && (
        <>
          <button
            type="button"
            onClick={() => clearFilters()}
            className="bg-gry200 px-10 py-2 rounded-[2px]"
          >
            Filtreleri Temizle
          </button>
        </>
      )}

      {isMobile && (
        <div className={"flex justify-center"}>
          <button
            type="submit"
            className={`bg-primary200 text-xl font-bold px-6 py-3 rounded-[2px] w-1/2 ${
              Object.keys(newSelectedValues).length < 1 ? "mb-5" : ""
            }`}
            onClick={() => {
              handleFilter();
              setShowFilterSaveButton(true);
              setDetailFiltersOpen(false);
              setTimeout(() => {
                if (scrollRef?.current) {
                  scrollRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }
              }, 200);
            }}
          >
            Ara
          </button>
        </div>
      )}

      {!isMobile && (
        <button
          type="submit"
          className={`bg-primary200 px-6 py-2 rounded-[2px]`}
          onClick={() => {
            handleFilter();
            setShowFilterSaveButton(true);
          }}
        >
          Filtrele
        </button>
      )}

      {Object.keys(newSelectedValues).length > 0 && isMobile && (
        <div className={"flex justify-between py-5 px-3"}>
          <button
            type="button"
            className="flex flex-row gap-2 text-sm text-gray-600 items-center"
            onClick={() => clearFilters()}
          >
            <IoTrashBinOutline />
            Tümünü Temizle
          </button>
        </div>
      )}

      <CustomModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </section>
  );
}

export { ButtonGroupSection };
