import React from "react";
import ContentLoader from "react-content-loader";

const InfoSkeleton = () => (
  <div className="md:border-2 flex items-center   justify-between bg-white md:bg-stone-200 border pr-5 md:pl-2 md:pr-10 md:hover:border-primary200 ml-3 mr-1 md:mx-10 md:shadow-md">
    <ContentLoader
      speed={2}
      width="100%"
      height={160}
      viewBox="0 0 100% 160"
      backgroundColor="#f3f3f3"
      foregroundColor="#fff"
      className="w-full hidden md:block"
    >
      {/* Checkbox and Profile Image */}
      <rect x="2%" y="70" rx="2" ry="2" width="20" height="20" />{" "}
      {/* Checkbox */}
      <circle cx="12%" cy="80" r="30" /> {/* Profile image */}
      {/* Text placeholders for both mobile and desktop */}
      <rect x="20%" y="60" rx="3" ry="3" width="20%" height="10" />{" "}
      {/* Name and Surname */}
      <rect x="20%" y="75" rx="3" ry="3" width="15%" height="10" />{" "}
      {/* Gender/Age */}
      <rect x="20%" y="90" rx="3" ry="3" width="25%" height="10" />
    </ContentLoader>
    <ContentLoader
      speed={2}
      width="100%"
      height={130}
      viewBox="0 0 100% 160"
      backgroundColor="#f3f3f3"
      foregroundColor="#fff"
      className="w-full md:hidden"
    >
      {/* Checkbox and Profile Image */}
      <rect x="2%" y="58" rx="2" ry="2" width="15" height="15" />{" "}
      {/* Checkbox */}
      <circle cx="25%" cy="65" r="50" /> {/* Profile image */}
      {/* Text placeholders for both mobile and desktop */}
      <rect x="45%" y="30" rx="3" ry="3" width="40%" height="10" />{" "}
      {/* Name and Surname */}
      <rect x="45%" y="55" rx="3" ry="3" width="15%" height="10" />{" "}
      {/* Gender/Age */}
      <rect x="45%" y="70" rx="3" ry="3" width="15%" height="10" />{" "}
      {/* Gender/Age */}
      <rect x="45%" y="85" rx="3" ry="3" width="15%" height="10" />{" "}
    </ContentLoader>
  </div>
);

const SkeletonContainer = () => {
  const skeletonCount = 5;

  return (
    <div className="flex flex-col gap-3 my-4">
      {Array.from({ length: skeletonCount }, (_, index) => (
        <InfoSkeleton key={index} />
      ))}
    </div>
  );
};

export default SkeletonContainer;
