import React, { useEffect, useState } from 'react';
import CustomAccordion from '../../../../components/common/custom-accordion';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CgSpinner } from 'react-icons/cg';
import { toast } from 'react-toastify';
import FormField from '../../../../components/common/form-field';
import Axios from '../../../../services/axios';
import { CustomButton } from '../../../../components/common/custom-button';

type formField = {
  label: string;
  name: string;
  required?: boolean;
  type?:
    | 'text'
    | 'email'
    | 'number'
    | 'select'
    | 'textarea'
    | 'editor'
    | 'file'
    | 'string';
  emailVerified?: boolean;
  limit?: number;
  [key: string]: any;
};
const axios = new Axios();

function SocialInfoForm() {
  const [socialData, setSocialData] = useState<formField[]>([]);
  const getSocialData = async () => {
    try {
      const response = await axios.authRequest({
        endpoint: 'companies/account-settings/get-social-info',
        type: 'get',
        payload: {},
      });
      setSocialData(response.data.social);
    } catch (error) {
      console.log(error);
    }
  };
  const updateSocial = async (values: any) => {
    try {
      await axios.authRequest({
        endpoint: 'companies/account-settings/social-update',
        type: 'put',
        payload: values,
      });
      toast.success('Sosyal Medya Güncellendi');
    } catch (error: any) {
      console.log(error);
      toast.error(error.response.data.error);
    }
  };
  const formFields: formField[] = [
    {
      label: 'Whatsapp',
      type: 'number',
      required: false,
      name: 'whatsapp',
    },
    {
      label: 'Facebook Kullanıcı Adı',
      required: false,
      name: 'facebook',
    },
    {
      label: 'Twitter Kullanıcı Adı',
      required: false,
      name: 'twitter',
    },
    {
      label: 'Instagram Kullanıcı Adı',
      required: false,
      name: 'instagram',
    },
    {
      label: 'Linkedin Kullanıcı Adı',
      required: false,
      name: 'linkedin',
    },
  ];

  const formValidationSchema = Yup.object().shape(
    formFields.reduce<Record<string, Yup.StringSchema<string>>>(
      (schema, field) => {
        if (field.required) {
          schema[field.name] = Yup.string()
            .required(`${field.label} Zorunlu`)
            .min(
              field.limit ? field.limit : 0,
              field.limit
                ? `${field.label} en az ${field.limit} karakter olmalı`
                : '',
            );
        }
        return schema;
      },
      {},
    ),
  );

  const initialValues: { [key: string]: string } = {};

  formFields.forEach((field) => {
    initialValues[field.name] = '';
  });

  const formik = useFormik({
    initialValues,
    validationSchema: formValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await updateSocial({
        _method: 'PUT',
        ...values,
      });
      setSubmitting(false);
    },
  });
  useEffect(() => {
    getSocialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (socialData) {
      formFields.forEach((field) => {
        formik.setFieldValue(
          field.name,
          socialData[field.name as keyof typeof socialData],
        );
      });
    }
  }, [socialData]);
  const showError = () => {
    if (formik.errors && Object.keys(formik.errors).length > 0) {
      Object.keys(formik.errors).forEach((key) => {
        toast.error(formik.errors[key]);
      });
    }
  };

  const handleButtonClick = () => {
    console.log("Button was clicked"); // Additional action before submit
    formik.handleSubmit(); // This will trigger the form submission
  };


  return (
    <CustomAccordion
      title="Sosyal Medya"
      subtitle="Sosyal medya hesaplanızı bu menüden güncelleyebilirsiniz.">
      <div className='max-w-[75%] mx-auto'>
        <form
          className="grid grid-cols-1 md:grid-cols-2 gap-6 space-y-2 mt-2"
          onSubmit={formik.handleSubmit}>
          {formFields.map((field, index) => (
            <FormField
              onChange={formik.handleChange}
              key={index}
              label={field.label}
              name={field.name}
              type={field.type}
              emailVerified={field.emailVerified}
              options={field.options}
              error={formik.errors[field.name]}
              required={field.required}
              value={formik.values[field.name]}
              phoneMaskType="phone"
              invalid={formik.errors[field.name]?.length}
            />
          ))}
        </form>
        <div className="flex justify-end">
          <CustomButton
           // onClick={() => showError()}
            className="bg-blue-700 px-5 py-2 mt-4 text text-white rounded "
            type="submit"
            onClick={handleButtonClick}
            isSubmitting={formik.isSubmitting}>
            Kaydet
          </CustomButton>
        </div>
      </div>
    </CustomAccordion>
  );
}

export default SocialInfoForm;
