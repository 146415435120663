import Axios from "../services/axios"
import { cdnSkillType } from "../types"






type getSkillsPropsType = {
    search?: string,
    limit?: number
}




const axios = new Axios()


export async function getSkills({
    search,
    limit
}: getSkillsPropsType): Promise<cdnSkillType[]> {
    try {
        const response = await axios.request({
            endpoint: `cdn/get-skills?
            ${search? `search=${search}&`: ''}
            ${limit? `limit=${limit}&`: ''}
            `,
            type: 'get'
        })

        if (response && response.data && response.data.skills) {
            return JSON.parse(JSON.stringify(response.data.skills))
        } else {
            return []
        }
    } catch (error) {
        console.log(`Skills fetch failed. Error: ${error}`)
        return []
    }
}