import React from "react";

interface HoverProps {
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
}

function Hover({ icon, label, onClick }: HoverProps) {
  return (
    <div className="relative inline-flex group">
      <div className="relative">
        <div className="inline-block" onClick={onClick}>
          {icon}
        </div>
        <div className="absolute -top-1 -left-14 transform -translate-x-2/3 bg-black text-white text-xs px-4 py-2 rounded-md opacity-0 transition-opacity duration-300  pointer-events-none group-hover:opacity-100 whitespace-nowrap">
          {label}
        </div>
      </div>
    </div>
  );
}

export { Hover };
