import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Main from './main';
import Detials from "./detials/index"
const JobPageContainer: React.FC = () => {
  return (
    <div className="">
      <div className="w-full">
        <Routes>
          <Route path="/" element={<Main />} />
          {/* <Route path="/:slug" element={<Detials />} /> */}
        </Routes>
      </div>
    </div>
  );
};

export default JobPageContainer;
