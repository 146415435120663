import { useContext } from "react";
import { RadioButtonContext } from ".";

type Props = {
	label: string;
	radioValue: string;
};

const RadioButton = ({ label, radioValue, ...props }: Props) => {
	const { value, setValue, error } = useContext(RadioButtonContext);

	return (
		<label
			className={`flex items-center text-sm ${
				error ? "text-red-600" : "text-gray-800"
			}`}>
			<input
				type='radio'
				{...props}
				checked={radioValue === value}
				onChange={() => setValue(radioValue)}
				className={`form-radio h-5 w-5 mr-1  ${
					error ? "bg-red-600" : "bg-blue-500"
				}`}
			/>
			<span>{label}</span>
		</label>
	);
};

export default RadioButton;
