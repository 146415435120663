import Axios from "../services/axios";
import { cdnDrivingLicesesType } from "../types";







const axios = new Axios()

type getDriverLicenseClassesPropsType = {
    search?: string,
    limit?: number
}




export async function getDriverLicenseClasses({
    limit,
    search
}: getDriverLicenseClassesPropsType): Promise<cdnDrivingLicesesType[]> {
    try {
        const response = await axios.request({
            endpoint: `cdn/get-driving-licence-classes?
            ${search? `search=${search}&`: ``}
            ${limit? `limit=${limit}&`: ``}
            `,
            type: 'get'
        })

        if (response && response.data && response.data.driving_license_classes) {
            return JSON.parse(JSON.stringify(response.data.driving_license_classes))
        } else {
            return []
        }
    } catch (error) {
        console.log(`Driver license classes fetch failed. Error: ${error}`)
        return []
    }
}