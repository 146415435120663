import React from "react";
import Breadcrump from "../../../../components/common/profile/breadcrump";
import Info from "./info";
import SaveFile from "./save-file";
import SaveAbstract from "./save-abstract";

const Body: React.FC = () => {
	return (
		<div className='flex flex-col w-full gap-y-[10px] mb-10 lg:mb-0'>
			<Breadcrump
				type='page'
				pageName='Dosyalar & Ön Yazılar'
			/>
			<Info />
			<SaveFile />
			{/* <SaveAbstract /> */}
		</div>
	);
};

export default Body;
