/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import ApplicationCard from "../../../../components/common/application-card-for-ad";
import { useLocation, useNavigate } from "react-router-dom";
import { AdvertService } from "../../../../services/similer-advert-services";
import { useFormatDate } from "../../../../hooks/useFormatDate";
import Pagination from "./components/pagination";
import SearchServices from "../../../../services/search-services";

interface IFastItem {
  title: string;
  id: number;
  type?: string;
}
interface AdvertSectionBodyProps {
  advertId: number;
  showTitle?: boolean; // Adjust the type as necessary, perhaps `number` or `string`
  dontShowPopUp?:boolean;
}
const AdvertSectionBody: React.FC<AdvertSectionBodyProps> = ({
  advertId,
  showTitle = false,dontShowPopUp
  
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fastCities, setFastCities] = useState<IFastItem[]>([]);
  const [fastPositions, setFastPositions] = useState<IFastItem[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [adverts, setAdverts] = useState([]);

  useEffect(() => {
    const getCities = async () => {
      const res = await SearchServices.searchServicesCities("", 6);

      setFastCities(
        res.data.cities.map((el: { name: string; id: number }) => ({
          title: el.name,
          id: el.id,
        }))
      );
    };

    getCities();
  }, []);

  useEffect(() => {
    const getPositions = async () => {
      const res = await SearchServices.searchServicesPosition("", 10);

      setFastPositions(
        res.data.positions.map((el: { title: string; id: number }) => ({
          title: el.title,
          id: el.id,
        }))
      );
    };

    getPositions();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const page = searchParams.get("page") || "1";
    const pageLimit = searchParams.get("page_limit") || "35";
    const positions = searchParams.get("positions") || "[]";
    const cities = searchParams.get("cities") || "[]";
    const workTypes = searchParams.get("work_types") || "[]";
    const workingLocations = searchParams.get("working_locations") || "[]";
    const sections = searchParams.get("sections") || "[]";
    const sectors = searchParams.get("sectors") || "[]";
    const advertDate = searchParams.get("advert_date") || "";
    const minExp = searchParams.get("experience_level_min") || "";
    const maxExp = searchParams.get("experience_level_max") || "";
    const disabled = searchParams.get("disabled") || "";
    const notTurkeyGeneral = searchParams.get("not_turkey_general") || "";
    const hiddenCompany =
      searchParams.get("not_show_hidden_company_title") || "";
    const positionLevels = searchParams.get("position_levels") || "";

    getAdverts(
      page,
      pageLimit,
      positions,
      cities,
      workTypes,
      workingLocations,
      sections,
      sectors,
      advertDate,
      minExp,
      maxExp,
      disabled,
      notTurkeyGeneral,
      hiddenCompany,
      positionLevels
    );
  }, [location.search]);

  const getAdverts = async (
    page: string,
    pageLimit: string,
    positions: string,
    cities: string,
    workTypes: string,
    workingLocations: string,
    sections: string,
    sectors: string,
    advertDate: string,
    minExp: string,
    maxExp: string,
    disabled: string,
    notTurkeyGeneral: string,
    hiddenCompany: string,
    positionLevels: string
  ) => {
    setIsLoading(true);
    const adverts = await AdvertService.getSimilerAdvert(advertId);

    console.log(adverts, "similersadverts");
    console.log(advertId, "advertId");

    setAdverts(
      adverts.data.data.map((el: any) => ({
        advertId: el.advert.id || "",
        advertSlug: el.advert.slug || "",
        advertTitle: el.advert.title || "",
        companyName: el.company_detail.company_name || "",
        workingType: el?.working_types[0]?.title || "",
        city: el?.cities[0]?.name || "",
        companyLogo: el.company_detail.logo,
        createdAt: useFormatDate(el.advert.created_at) || "",
        isSponsored:
          Date.now() < new Date(el.advert.doping_end_date).getTime()
            ? true
            : false,
        is_favorite: el.is_favorite,
      }))
    );

    setIsLoading(false);
  };

  if (isLoading) {
    return <div>{showTitle ? "" : "Loading..."}</div>;
  }

  console.log(adverts, "adverts");

  return (
    <div className="w-full">
      <div className="bg-white flex flex-col p-3  gap-y-3 mb-8">
        {adverts.length > 0 && showTitle && <div>BENZER İLANLAR</div>}
        {adverts &&
          adverts.map((el: any, i: number) => (
            <ApplicationCard
              key={i}
              data={el}
              isSponsored={el.isSponsored}
              setAdverts={setAdverts}
              dontShowPopUp={dontShowPopUp}
            />
          ))}
      </div>
      {/* <div className="h-12 w-full">
        <Pagination />
      </div>  */}
    </div>
  );
};

export default AdvertSectionBody;
