import { InputHTMLAttributes } from "react";
import classNames from "classnames";
import { useContext } from "react";
import { RadioContext, radioContextType } from ".";

type triggerPropsType = InputHTMLAttributes<HTMLInputElement> & {
  value: string;
};

export default function RadioTrigger({
  className,
  value,
  ...props
}: triggerPropsType) {
  const { selectedValue, setSelectedValue, error }: radioContextType =
    useContext(RadioContext);

  const cn = classNames({
    "w-4 h-4 rounded-full": true,
    "border-gray-400 border": !error,
    "border-red-600 border": error,
  });

  return (
    <input
      {...props}
      type="radio"
      value={value}
      checked={selectedValue === value}
      className={`${cn} ${className}`}
      onChange={() => setSelectedValue(value)}
    />
  );
}
