import React, { useState, useEffect } from "react";
import "leaflet/dist/leaflet.css";
import { FaTimes } from "react-icons/fa";
import { useCookie } from "../../../../hooks/useCookie";
import { CookieName } from "../../../../constants/enums";
import Axios from "../../../../services/axios";
import MessageSender from "./components/MessageSender";

const axios = new Axios();
interface WorkingLocationPopupProps {
  onClose: () => void;
  onSuccess?: () => void;
  advertId: number;
  adData: any;
}

const MessageSendPopup: React.FC<WorkingLocationPopupProps> = ({
  adData,
  onClose,
  onSuccess,
  advertId,
}) => {
  //console.log('ad', adData)
  const [isVisible, setIsVisible] = useState(false);

  const { getCookie } = useCookie();
  const companyToken = getCookie(CookieName.CompanyUserToken);
  const userToken = getCookie(CookieName.UserToken);

  useEffect(() => {
    const timeout = setTimeout(() => {
      document.body.classList.add("overflow-hidden", "h-auto");
      setIsVisible(true);
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  const handleCloseClick = (success = false) => {
    setIsVisible(false);
    setTimeout(() => {
      onClose();
      if (success && onSuccess) {
        onSuccess();
      }
      document.body.classList.remove("overflow-hidden", "h-auto");
    }, 500);
  };

  return (
    <div className="fixed inset-0 z-[1050] flex items-start justify-center overflow-y-auto ">
      <div
        className="fixed inset-0 bg-black opacity-50 h-auto "
        onClick={() => {
          handleCloseClick();
        }}
      ></div>

      <div
        className={`bg-white my-10 max-md:my-0 max-md:fixed max-md:top-0 max-md:left-0 max-md:w-screen max-md:h-screen max-md:overflow-y-auto md:rounded-lg md:shadow-lg  md:relative md:min-w-[40%] transition-all duration-500 ease-in-out transform ${
          isVisible ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-10"
        }`}
      >
        <div className={"grid grid-cols-3 items-center border-b-2 py-5 px-5"}>
          <div></div>
          <div className={"text-mains justify-self-center whitespace-nowrap"}>
            Mesaj Gönder
          </div>
          <div
            onClick={() => {
              handleCloseClick();
            }}
            className={
              "flex justify-self-end justify-center items-center w-5 h-5 "
            }
          >
            <FaTimes className={"text-gray-400"} />
          </div>
        </div>

        {
          <div className={``}>
            <MessageSender
              adData={adData}
              onSuccess={() => {
                handleCloseClick(true);
              }}
            />
          </div>
        }
      </div>
    </div>
  );
};

export default MessageSendPopup;
