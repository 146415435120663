import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import {
  Button,
  InputArea,
  Textarea,
} from "../../../../../../components/isbul-ui";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useForm, SubmitHandler } from "react-hook-form";
import Axios from "../../../../../../services/axios";
import { isAxiosError } from "axios";
import { IoMdInformationCircleOutline } from "react-icons/io";
import Swal from "sweetalert2";

import { dbUserAbstractType } from "../../../../../../types";

const axios = new Axios();


type payloadDataType = {
  title: string;
  detail: string;
};

export default function AbstractAddModal({
  setIsOpen,
  getAllDatas,
  isEdit,
  isEnglish,
  selectedAbstract,
}: {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  getAllDatas?: () => void;
  isEdit?: boolean;
  isEnglish?: boolean;
  selectedAbstract?: dbUserAbstractType;
}) {
  const divRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const [title, setTitle] = useState<string>("");
  const [detail, setDetail] = useState<string>("");

  useEffect(() => {
    if (selectedAbstract) {
      setTitle(selectedAbstract.title);
      setDetail(selectedAbstract.detail);
    }
  }, [selectedAbstract]);

  // useEffect(() => {
  //   function handleClickOutside(event: MouseEvent) {
  //     if (divRef.current && !divRef.current.contains(event.target as Node)) {
  //       setIsOpen(false);
  //     }
  //   }
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [divRef]);

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<payloadDataType>();

  const onSubmit: SubmitHandler<payloadDataType> = (data) => {
    if (selectedAbstract) {
      updateAbstract(data);
    } else {
      addAbstract(data);
    }
  };

  function onError() {
    const errorFields = [];
    if (errors.title) {
      errorFields.push("Başlık");
      document
        .getElementById("titleInput")
        ?.classList.add("bg-red-100", "border-red-500");
    }
    if (errors.detail) {
      errorFields.push("Ön Yazı");
      document
        .getElementById("detailInput")
        ?.classList.add("bg-red-100", "border-red-500");
    }

    if (errorFields.length > 0) {
      Swal.fire({
        title: "Eksik Alanlar",
        html: `<ul>${errorFields
          .map((field) => `<li>${field}</li>`)
          .join("")}</ul>`,
        icon: "error",
        confirmButtonText: "Tamam",
      });
    }
  }

  /* Custom registers */
  // title
  register("title", {
    required: true,
  });

  // detail
  register("detail", {
    required: true,
  });

  /* Set form values */
  //title
  useEffect(() => {
    setValue("title", title);
    clearErrors("title");
  }, [title]);

  // detail
  useEffect(() => {
    setValue("detail", detail);
    clearErrors("detail");
  }, [detail]);

  return (
    <div className="fixed top-0 left-0 flex items-center justify-center bg-black bg-opacity-30 h-screen w-screen z-[100]">
      <div
        ref={divRef}
        className="flex flex-col gap-4 p-4 bg-white rounded-md max-h-[calc(100vh-50px)] max-w-[600px] w-full overflow-auto mb-14"
      >
        <div className="flex flex-row items-center justify-between">
          <h3 className="font-poppins text-xl font-semibold text-mains">
            {isEnglish
              ? isEdit
                ? "EDIT ABSTRACT"
                : "ADD ABSTRACT"
              : isEdit
              ? "ÖN YAZIYI DÜZENLE"
              : "ÖN YAZI EKLE "}
          </h3>
          <button type="button" onClick={() => setIsOpen(false)}>
            <IoClose className="text-2xl text-gray-600" />
          </button>
        </div>
        <hr />
        <form
          className="flex flex-col gap-6"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <InputArea required mode={title.length == 0 ? "empty" : "dirty"}>
            <InputArea.Label>Ön Yazı Adı</InputArea.Label>
            <InputArea.Input
              id="titleInput"
              value={title || ""}
              onChange={(e) => {
                setTitle(e.target.value);
                document
                  .getElementById("titleInput")
                  ?.classList.remove("bg-red-100", "border-red-500");
              }}
            />
          </InputArea>
          <Textarea required>
            <Textarea.Label>Ön Yazı</Textarea.Label>
            <label className="text-gray-400 font-poppins text-xs">
              Ön yazı metninizi bu alana girebilirsiniz. Unutmayın ilan
              başvurularında bir ön yazı kullanmak başvurunuzun dikkat çekici
              olmasını sağlar ve sizi bir adım öne taşır.
            </label>
            <a
              href="#"
              className="text-blue-500 font-poppins text-xs flex justify-start items-center gap-[2px] mt-[2px]"
            >
              <IoMdInformationCircleOutline className="text-blue-500" />
              Ön yazı nasıl yazılmalıdır?
            </a>
            <div
              id="detailInput"
              className={`ckeditor-wrapper ${
                errors.detail ? "bg-red-100 border-red-500" : ""
              }`}
            >
              <CKEditor
                editor={ClassicEditor}
                data={detail}
                onChange={(event, editor) => {
                  setDetail(editor.getData());
                  document
                    .getElementById("detailInput")
                    ?.classList.remove("bg-red-100", "border-red-500");
                }}
              />
            </div>
            <label className="text-gray-500 font-poppins text-xs">
              {3000 - detail.length}
            </label>
          </Textarea>
          <div className="flex flex-col gap-2">
            <div>
              <Button type="submit" className="mx-auto" size="sm">
                Kaydet
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  async function addAbstract(data: payloadDataType) {
    if (loading) return;

    setLoading(true);

    try {
      const response = await axios.userAuthRequest({
        endpoint: "user/profilim/text/create",
        type: "post",
        payload: data,
      });


      console.log('create')
      if (response && response.status == 200) {
        Swal.fire({
          title: "Başarılı!",
          text: "Ön Yazınız başarıyla eklendi.",
          icon: "success",
          showConfirmButton: true,
          confirmButtonText: "Tamam",
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        if (getAllDatas) {
          getAllDatas();
        }

        setIsOpen(false);
      } else {
        Swal.fire({
          title: "Hata",
          text: "Ön yazı eklenirken bir hata meydana geldi.",
          icon: "error",
          confirmButtonText: "Tamam",
        });
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      Swal.fire({
        title: "Hata",
        text:
          isAxiosError(error) && error.response && error.response.data.error
            ? error.response.data.error
            : "Ön yazı eklenirken beklenmedik bir hata meydana geldi.",
        icon: "error",
        confirmButtonText: "Tamam",
      });
    }

    setLoading(false);
  }

  async function updateAbstract(data: payloadDataType) {




    if (loading) return;
    if (!selectedAbstract) return;
    setLoading(true);

    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/text/${selectedAbstract.id}`,
        type: "put",
        payload: data,
      });

      if (response && response.status == 200) {

        Swal.fire({
          title: "Başarılı!",
          text: "Ön yazınız başarıyla güncellendi.",
          icon: "success",
          showConfirmButton: true,
          confirmButtonText: "Tamam",
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });



        if (getAllDatas) {
          getAllDatas();
        }

        setIsOpen(false);
      } else {
        Swal.fire({
          title: "Hata",
          text: "Ön yazı güncellenirken bir hata meydana geldi.",
          icon: "error",
          confirmButtonText: "Tamam",
        });
      }
    } catch (error) {
      console.log(`Error: ${error}`);
      Swal.fire({
        title: "Hata",
        text:
          isAxiosError(error) && error.response && error.response.data.error
            ? error.response.data.error
            : "Ön yazı güncellenirken beklenmedik bir hata meydana geldi.",
        icon: "error",
        confirmButtonText: "Tamam",
      });
    }

    setLoading(false);
  }
}
