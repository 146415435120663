import React, { useContext, useEffect, useState } from "react";
import { CVPreviewContext } from "../contents";
import Axios from "../../../../services/axios";
import { useParams } from "react-router-dom";
import moment from "moment";
import { MdOutlineMessage } from "react-icons/md";
import { FaRegTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import { CookieName } from "../../../../constants/enums";
import { useCookie } from "../../../../hooks/useCookie";
import EditNotePopup from "./EditNotePopup";
import EditNote from "./EditNote";

const axios = new Axios();

const Notes = () => {
  const { setAddNoteShow, addNoteShow } = useContext(CVPreviewContext);
  const { slug } = useParams();
  const [notes, setNotes] = useState([]);
  const { getCookie } = useCookie();
  const companyUserCookie = getCookie(CookieName.CompanyUserData);
  const companyUser = companyUserCookie ? JSON.parse(companyUserCookie) : null;

  const fetchNotes = () => {
    //companies/cv/${slug}/comment/get-comments
    axios
      .authRequest({
        type: "get",
        endpoint: `companies/cv/${slug}/comment/get-comments`,
        payload: {},
      })
      .then((reponse: any) => {
        console.log(reponse.data.comments, reponse);
        setNotes(reponse.data.comments);
      });
  };
  useEffect(() => {
    if (!addNoteShow) {
      fetchNotes();
    }
  }, [addNoteShow]);

  const handleDelete = (id: number) => {
    Swal.fire({
      html: `Yorumu silmek istediğinize emin misiniz? Bu işlem geri alınamaz.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Hayır",
      confirmButtonColor: "#2253af",
      cancelButtonColor: "#6c757d",
    }).then((result) => {
      if (result.isConfirmed) {
        //TODO backendden hata dönüyor CV not ekleme
        axios
          .authRequest({
            type: "delete",
            endpoint: `companies/cv/${slug}/comment/delete-comment?note_id=${id}`,
            payload: { note_id: id },
          })
          .then((reponse: any) => {
            fetchNotes();
            Swal.fire({
              html: `Yorum Silindi`,
              icon: "success",
              showCancelButton: false,
              confirmButtonText: "Tamam",
              confirmButtonColor: "#2253af",
            });
          });
      }
    });
  };
  return (
    <div
      className={
        "flex flex-col justify-center items-center p-5 bg-white gap-3 rounded-xl"
      }
    >
      <div className={"text-center text-2xl font-bold "}>Yorum Ekle</div>
      <div className={"text-center text-sm font-light"}>
        Adayla ilgili notlarınızı bu alana ekleyebilirsiniz. Adaylar
        yorumlarınızı göremezler.
      </div>

      <div className={"flex flex-col gap-3 w-full"}>
        {notes.map((note: any, i) => (
          <div
            key={i}
            className={"flex flex-col gap-3 p-3 border rounded-xl w-full"}
          >
            <div className={"font-bold"}>
              {companyUser.name} {companyUser.surname}
            </div>
            <div className={"w-full text-wrap break-words"}>{note.note}</div>
            <div className={"text-sm"}>
              ({moment(note.updated_at).format("DD MMMM YYYY HH:MM")})
            </div>
            <div className={"flex gap-2"}>
              <EditNote
                onSuccess={() => {
                  fetchNotes();
                }}
                note={note.note}
                id={note.id}
              />
              <div
                onClick={() => {
                  handleDelete(note.id);
                }}
                className={
                  "flex gap-2 justify-center items-center px-3 py-1 border border-red-500 text-red-500 hover:bg-red-500 hover:text-white cursor-pointer"
                }
              >
                <FaRegTrashAlt />
                Sil
              </div>
            </div>
          </div>
        ))}
      </div>

      <div
        onClick={() => setAddNoteShow(true)}
        className={
          "mt-3 w-full px-5 py-2 border border-mains hover:bg-mains hover:text-white rounded text-center transition-all cursor-pointer font-bold text-mains"
        }
      >
        Yorum Ekle
      </div>
    </div>
  );
};

export default Notes;
