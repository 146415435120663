import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import React, { Fragment } from 'react'

type Props = {
    title: string;
    content: React.ReactNode;
    footer?: React.ReactNode;
    open: boolean;
    acceptLabel?: React.ReactNode;
    closeLabel?: string;
    onClose?: () => void;
    onAccept?: () => void;
    acceptClassName?: string;
    className?: string;
}

const CustomModal = (props: Props) => {
    return (
        <div className={`fixed z-40 left-0 right-0 top-0 h-[calc(100%-1rem)] max-h-full w-full max-w-full overflow-y-auto overflow-x-hidden p-4 md:inset-0 flex justify-center items-center ${props.open ? 'block' : 'hidden'}`}>
            <div className='bg-[#0005] backdrop-blur-3 absolute top-0 right-0 w-full h-full' onClick={props.onClose} />
            <Transition
                show={props.open}
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="relative max-h-full w-full max-w-4xl  z-50">
                    <div className="relative rounded-lg bg-white shadow">
                        <div className="flex items-start justify-between rounded-t border-b p-5 dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-title lg:text-2xl">
                                {props.title}
                            </h3>
                            <button
                                type="button"
                                className="ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={props.onClose}
                            >
                                <span className="sr-only">Close modal</span>
                                <XMarkIcon />
                            </button>
                        </div>

                        <div className="space-y-6 p-6 text-title">
                            {props.content}
                        </div>
                        {props.footer ? props.footer :
                            <div className="flex items-center space-x-2 rtl:space-x-reverse rounded-b border-t border-gray-200 p-6 dark:border-gray-600">
                                <button type="button" className={`rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 ${props.acceptClassName}`} onClick={props.onAccept}>
                                    {props.acceptLabel ?
                                        props.acceptLabel :
                                        'Accept'
                                    }
                                </button>
                                <button
                                    type="button"
                                    className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:border-gray-500 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                                    onClick={props.onClose}
                                >
                                    {props.closeLabel ?
                                        props.closeLabel :
                                        'Decline'
                                    }
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </Transition>
        </div >
    )
}

export default CustomModal