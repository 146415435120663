import React, { ChangeEvent, useEffect, useState } from "react";
import {
  NumericInput,
  AntdInput,
  AntdRadioButton,
  AntdSelect,
  Upload,
} from "../../../../../components/common";
import { Button as AntdButton } from "../../../../../components/common";
import { IconChevronRight } from "@tabler/icons-react";

import { Form, Input, Row, Col } from "antd";
import moment from "moment";
import InputField from "../../../../form-components/input-field";
import RadioButtonField from "../../../../form-components/radio-button";
import Select from "../../../../form-components/select";
import Axios from "../../../../../services/axios";
import {
  cdnDisabilityTypesType,
  cdnDrivingLicenseSrcType,
  cdnDrivingLicesesType,
  cvPersonalPutDataType,
  cvPersonalType,
  dbPersonalInformationType,
  getCountriesResponseType,
  getDisabilityTypesResponseType,
  getDrivingLicenseSrcsType,
  getDrivingLicesesResponseType,
  getPersonalInformationResponseType,
  itemType,
} from "../../../../../types";

//TODO: react hook form denemesi için bu kütüphaneler eklendi duruma göre silinecek
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import MultiSelect from "../../../../form-components/multi-select";
import { IoChevronForward, IoPerson } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  Button,
  InputArea,
  MultiSelectDropdown,
  SelectDropdown,
  SelectSearchDropdown,
  Textarea,
} from "../../../../isbul-ui";
import { useYears } from "../../../../../customHooks/useYears";
import Swal from "sweetalert2";
import ProfilePhotoChanger from "./components/ProfilePhotoChanger";
import { getCountries } from "../../../../../customHooks/getCountries";
import { FaCloudUploadAlt, FaTrashAlt } from "react-icons/fa";
import { FiRefreshCcw } from "react-icons/fi";
import TestComponent from "./components/TestComponent";
import { useFormat } from "../../../../../customHooks/useFormat";
import { useContext } from "react";
import {
  CvLayoutContext,
  cvLayoutContextType,
} from "../../../../../containers/profile-page-container/cv-edit/contents";
import { MdAddAPhoto } from "react-icons/md";
import { useIsMobile } from "../../../../../hooks/useIsMobile";
import { LuLoader2 } from "react-icons/lu";
import { useParams } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

moment.locale("tr");

const axios = new Axios();

interface DayOptions {
  key: any;
  value: any;
  label: string;
}

interface MonthOptions {
  id: any;
  key: any;
  value: any;
  label: string;
  label_en: string;
  maxDay: number;
}

interface YearOptions {
  value: string;
  label: string;
}

interface ObstaclePercentageOptions {
  key: any;
  value: any;
  label: string;
}

interface PostponementYearOptions {
  value: string;
  label: string;
}

type countryType = {
  id: number;
  name: string;
  name_en: string;
};

type drivingLicenseStatusType = {
  id: number;
  name: string;
  name_en: string;
};
/** burdaki bazı veriler servisten gelmeli düzenleme yapılmalı. */
const genderDb = [
  { id: 0, value: "Erkek", label: "Erkek" },
  { id: 1, value: "Kadın", label: "Kadın" },
  { id: 2, value: "Belirtilmemiş", label: "Belirtilmemiş" },
];

const maritalStatus = [
  { id: 0, value: "Evli", label: "Evli" },
  { id: 1, value: "Bekar", label: "Bekar" },
  { id: 2, value: "Belirtilmemiş", label: "Belirtilmemiş" },
];

const smoking = [
  { id: 0, value: "Evet", label: "Evet" },
  { id: 1, value: "Hayır", label: "Hayır" },
];

const militaryServiceStatus = [
  { id: 0, value: "Yaptı", label: "Yaptı" },
  { id: 1, value: "Tecilli", label: "Tecilli" },
  { id: 2, value: "Muaf", label: "Muaf" },
  { id: 3, value: "Belirtmemiş", label: "Belirtmemiş" },
];

const initialDrivingLiceses: drivingLicenseStatusType[] = [
  { id: 0, name: "Yok", name_en: "No" },
  { id: 1, name: "Var", name_en: "Yes" },
];

const physicalDisability = [
  { id: 0, value: "Var", label: "Var" },
  { id: 1, value: "Yok", label: "Yok" },
];

type PersonalInformationProps = {
  inPopup?: boolean;
  onSuccess?: () => void;
  onCancel?: () => void;
  onReady?: () => void;
};

const { TextArea } = Input;

const PersonalInformation: React.FC<PersonalInformationProps> = ({
  inPopup = false,
  onSuccess = () => {},
  onCancel = () => {},
  onReady = () => {},
}: PersonalInformationProps) => {
  const { isEnglish }: cvLayoutContextType = useContext(CvLayoutContext);
  const [form] = Form.useForm();

  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const params = useParams();
  const cvId = params.slug;

  const toDay = new Date();
  const minAge = 16;
  const maxAxge = 60;
  const maxPostponementYear = 15;
  const years = parseInt(moment(toDay).format("YYYY"));
  const minAgeCalculate = years - minAge;
  const maxAgeCalculate = years - maxAxge;
  const deferredYearCalculation = years - maxPostponementYear;

  const [day] = useState<DayOptions[]>([]);
  const days = useYears({ startYear: 31, endYear: 1 }).sort((a, b) => a - b);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [year] = useState<YearOptions[]>([]);
  const [obstaclePercentage] = useState<ObstaclePercentageOptions[]>([]);
  const [postponementYear] = useState<PostponementYearOptions[]>([]);

  for (let i = 1; i < 32; i++) {
    day.push({
      key: `${i}`,
      value: `${i}`,
      label: `${i}`,
    });
  }

  for (let i = minAgeCalculate; i >= maxAgeCalculate; i--) {
    year.push({
      value: `${i}`,
      label: `${i}`,
    });
  }

  for (let i = 5; i < 101; i++) {
    obstaclePercentage.push({
      key: i,
      value: `${"%" + i}`,
      label: `${"%" + i}`,
    });
  }

  for (let i = years; i >= deferredYearCalculation; i--) {
    postponementYear.push({
      value: `${i}`,
      label: `${i}`,
    });
  }

  const onFinish = (values: any) => {
    console.log("onFinish :", values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const { showCvScore }: cvLayoutContextType = useContext(CvLayoutContext);

  /* Bu kısımdan aşağısı component testi için eklenmiştir */

  /* Initial values */
  const [personalInformation, setPersonalInformation] =
    useState<dbPersonalInformationType | null>(null);
  const [avatarUrl, setAvatarUrl] = useState<string>(
    "https://isbull.s3.eu-north-1.amazonaws.com/default-images/user-profile.png?v=2.9.90"
  );
  const [drivingLicenses, setDrivingLicenses] = useState<itemType[]>([]);
  const [filteredDrivingLicenses, setFilteredDrivingLicenses] = useState<
    itemType[]
  >([]);
  const [srcCertificates, setSrcCertificates] = useState<itemType[]>([]);
  const [filteredSrcCertificates, setFilteredSrcCertificates] = useState<
    itemType[]
  >([]);
  const [disabilityTypes, setDisabilityTypes] = useState<
    cdnDisabilityTypesType[]
  >([]);
  const [disabilityPercentages, setDisabilityPercentages] = useState<
    itemType[]
  >(
    Array.from({ length: 96 }, (_, index) => ({
      id: index + 5,
      title: `%${index + 5}`,
    }))
  );
  const [nationalityList, setNationalityList] = useState<countryType[]>();
  const [countries, setCountries] = useState<countryType[]>([]);
  const [filteredCountries, setFilteredCountries] = useState<countryType[]>([]);

  const initialMilitaryDelayYears: string[] = [
    `${new Date().getFullYear()}`,
    `${new Date().getFullYear() + 1}`,
    `${new Date().getFullYear() + 2}`,
    `${new Date().getFullYear() + 3}`,
    `${new Date().getFullYear() + 4}`,
    `${new Date().getFullYear() + 5}`,
    `${new Date().getFullYear() + 6}`,
    `${new Date().getFullYear() + 7}`,
    `${new Date().getFullYear() + 8}`,
    `${new Date().getFullYear() + 9}`,
    `${new Date().getFullYear() + 10}`,
    `${new Date().getFullYear() + 11}`,
  ];

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  // Form initial personal information
  useEffect(() => {
    setName(personalInformation?.name || "");
    setValue("name", personalInformation?.name || "");
    setSurname(personalInformation?.surname || "");
    setValue("surname", personalInformation?.surname || "");
    setIdentityNumber(personalInformation?.tc_id || "");
    setValue("tc_id", personalInformation?.tc_id || "");
    if (personalInformation?.gender?.name == "Fark Etmez") {
      setGender("Belirtilmemiş");
    } else {
      setGender(personalInformation?.gender?.name || "");
    }
    setMilitaryStatus(
      personalInformation?.military_status?.id.toString() || ""
    );
    if (personalInformation?.military_postponement_date) {
      const date = new Date(personalInformation.military_postponement_date);
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const monthName = months.find((m) => m.value == month);
      setMilitaryDelayMonth(monthName?.label || "");
      setMilitaryDelayYear(year.toString());
    }

    setMaritalStatus(personalInformation?.marital_status?.toString() || "2");
    setSmokingStatus(personalInformation?.smoke_status?.toString() || "");

    if (personalInformation?.birth_date) {
      const fullDate = new Date(personalInformation.birth_date);
      const dayOfBirthDate = fullDate.getDate();
      const monthOfBirthDate = fullDate.getMonth() + 1;
      const yearOfBirthDate = fullDate.getFullYear();
      setSelectedDay(dayOfBirthDate.toString());
      const birthMonth = months.find((m) => m.value == monthOfBirthDate);
      if (birthMonth) {
        setSelectedMonth(birthMonth.label);
      }
      setSelectedYear(yearOfBirthDate.toString());
    }

    // Nationality
    if (personalInformation?.country_id) {
      setSelectedNationality({
        id: personalInformation.country?.id || -1,
        name: personalInformation.country?.name_tr || "",
        name_en: personalInformation.country?.name_en || "",
      });
    }

    // Driving license status
    setDrivingLicenseStatus(
      personalInformation?.driving_licence_status?.toString() || ""
    );

    // Driving licenses
    setSelectedDrivingLicenseItems(
      personalInformation?.driving_license_classes
        ? personalInformation.driving_license_classes.map((d) => {
            return {
              id: d.id,
              title: d.name,
            };
          })
        : []
    );

    // SRC certificates
    setSelectedSrcCertificates(
      personalInformation?.driving_license_srcs
        ? personalInformation.driving_license_srcs.map((s) => {
            return {
              id: s.id,
              title: s.name,
            };
          })
        : []
    );

    // Disability statuses
    setDisabilityStatus(
      personalInformation?.disability_status?.toString() || "0"
    );
    setSelectedDisabilityPercentage(
      personalInformation?.disability?.disability_percent || null
    );
    setSelectedDisability(
      personalInformation?.disability?.disability_type?.id.toString() || ""
    );
    setDisabilityDetails(
      personalInformation?.disability?.disability_detail || ""
    );

    // Profile photo changer initial open
    if (
      personalInformation?.profile_photo ==
      "https://isbull.s3.eu-north-1.amazonaws.com/default-images/user-profile.png?v=2.9.90"
    ) {
      if (!isMobile.isMobile && !isMobile.isTablet) {
        setAvatarModalOpen(!inPopup);
      }
    }

    setAvatarUrl(
      personalInformation?.profile_photo ||
        "https://isbull.s3.eu-north-1.amazonaws.com/default-images/user-profile.png?v=2.9.90"
    );
  }, [personalInformation]);

  // Page states
  const [loadingPage, setLoadingPage] = useState<boolean>(true);
  const [avatarModalOpen, setAvatarModalOpen] = useState<boolean>(false);
  const [searchCountry, setSearchCountry] = useState<string>("");
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);

  // Form states
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [identityNumber, setIdentityNumber] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [maritalStatus, setMaritalStatus] = useState<string>("");
  const [smokingStatus, setSmokingStatus] = useState<string>("");
  const [selectedDay, setSelectedDay] = useState<string>("");
  const [selectedMonth, setSelectedMonth] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [selectedNationality, setSelectedNationality] = useState<
    countryType | undefined
  >();
  const [militaryStatus, setMilitaryStatus] = useState<string>("");
  const [militaryDelayMonth, setMilitaryDelayMonth] = useState<string>("");
  const [militaryDelayYear, setMilitaryDelayYear] = useState<string>("");
  const [drivingLicenseStatus, setDrivingLicenseStatus] = useState<string>("");
  const [selectedDrivingLicenseItems, setSelectedDrivingLicenseItems] =
    useState<itemType[]>([]);
  const [drivingLicensesSearchTerm, setDrivingLicensesSearchTerm] =
    useState<string>("");
  const [selectedSrcCertificates, setSelectedSrcCertificates] = useState<
    itemType[]
  >([]);
  const [srcSearchTerm, setSrcSearchTerm] = useState<string>("");
  const [disabilityStatus, setDisabilityStatus] = useState<string>("");
  const [selectedDisability, setSelectedDisability] = useState<string>("");
  const [selectedDisabilityPercentage, setSelectedDisabilityPercentage] =
    useState<number | null>();
  const [disabilityDetails, setDisabilityDetails] = useState<string>("");

  const [valueP, setValueP] = useState<string>("");

  const formattedPhone = useFormat({ value: valueP });

  const [months] = useState<MonthOptions[]>([
    { id: 0, key: 0, label: "Ocak", label_en: "January", value: 1, maxDay: 31 },
    {
      id: 1,
      key: 1,
      label: "Şubat",
      label_en: "February",
      value: 2,
      maxDay: 28,
    },
    { id: 2, key: 2, label: "Mart", label_en: "March", value: 3, maxDay: 31 },
    { id: 3, key: 3, label: "Nisan", label_en: "April", value: 4, maxDay: 30 },
    { id: 4, key: 4, label: "Mayıs", label_en: "May", value: 5, maxDay: 31 },
    { id: 5, key: 5, label: "Haziran", label_en: "June", value: 6, maxDay: 30 },
    { id: 6, key: 6, label: "Temmuz", label_en: "July", value: 7, maxDay: 31 },
    {
      id: 7,
      key: 7,
      label: "Ağustos",
      label_en: "August",
      value: 8,
      maxDay: 31,
    },
    {
      id: 8,
      key: 8,
      label: "Eylül",
      label_en: "September",
      value: 9,
      maxDay: 30,
    },
    {
      id: 9,
      key: 9,
      label: "Ekim",
      label_en: "October",
      value: 10,
      maxDay: 31,
    },
    {
      id: 10,
      key: 10,
      label: "Kasım",
      label_en: "November",
      value: 11,
      maxDay: 30,
    },
    {
      id: 11,
      key: 11,
      label: "Aralık",
      label_en: "December",
      value: 12,
      maxDay: 31,
    },
  ]);

  function formatPhone(e: ChangeEvent<HTMLInputElement>) {
    setValueP(e.target.value);
  }

  /* Get form initial form field datas from cdn */
  // Driving licenses
  async function getDrivingLicenses(): Promise<cdnDrivingLicesesType[]> {
    try {
      const endpoint = "cdn/get-driving-licence-classes?limit=20";
      const res = await axios.request({
        endpoint,
        type: "get",
        payload: {},
      });

      const response: getDrivingLicesesResponseType = JSON.parse(
        JSON.stringify(res)
      );

      //console.log('response', response)
      if (response && response.data && response.data.driving_license_classes) {
        const licenses: cdnDrivingLicesesType[] = JSON.parse(
          JSON.stringify(response.data.driving_license_classes)
        );
        //console.log('licenses', licenses)
        return licenses;
      } else {
        return [];
      }
    } catch (error) {
      console.log(`Driving licenses fetch failed. Error: ${error}`);

      return [];
    }
  }

  // SRC certificates
  async function getSrcCertificates(): Promise<cdnDrivingLicenseSrcType[]> {
    try {
      const endpoint = "cdn/get-driving-licence-srcs?limit=20";

      const res = await axios.request({
        endpoint,
        type: "get",
        payload: {},
      });

      const response: getDrivingLicenseSrcsType = JSON.parse(
        JSON.stringify(res)
      );

      const srcs: cdnDrivingLicenseSrcType[] = JSON.parse(
        JSON.stringify(response.data.driving_license_srcs)
      );

      return srcs;
    } catch (error) {
      console.log(`SRC certificates fetch failed. Error:${error}`);
      return [];
    }
  }

  // Dİsability types
  async function getDisabilityTypes(): Promise<cdnDisabilityTypesType[]> {
    try {
      const endpoint = "cdn/get-disability-types?limit=20";

      const res = await axios.request({
        endpoint,
        type: "get",
        payload: {},
      });

      const response: getDisabilityTypesResponseType = JSON.parse(
        JSON.stringify(res)
      );

      const disabilities: cdnDisabilityTypesType[] = JSON.parse(
        JSON.stringify(response.data.disability_types)
      );

      return disabilities;
    } catch (error) {
      console.log(`Disability types fetch failed. Error ${error}`);
      return [];
    }
  }

  // Personal information
  async function getPersonalInformation(): Promise<dbPersonalInformationType | null> {
    try {
      const endpoint = "user/profilim/cv/personal/index";

      const res = await axios.request({
        endpoint,
        type: "get",
        payload: {},
      });

      const response: getPersonalInformationResponseType = JSON.parse(
        JSON.stringify(res)
      );

      const personalInformationData:
        | dbPersonalInformationType
        | undefined
        | null = response.data.data;

      if (personalInformationData) {
        return personalInformationData;
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async function getAllDatas() {
    setLoadingPage(true);

    const [resCountry, resDrivingLicenses, resSrc, resDisbaility, info] =
      await Promise.all([
        getCountries({}),
        getDrivingLicenses(),
        getSrcCertificates(),
        getDisabilityTypes(),
        getPersonalInformation(),
      ]);

    // Country list initial
    setCountries(
      resCountry.map((c) => {
        return {
          id: c.id,
          name: c.name_tr,
          name_en: c.name_en,
        };
      })
    );

    setFilteredCountries(
      resCountry.map((c) => {
        return {
          id: c.id,
          name: c.name_tr,
          name_en: c.name_en,
        };
      })
    );

    // Driving licenses initial
    setDrivingLicenses(
      resDrivingLicenses.map((d) => {
        return {
          id: d.id,
          title: d.title,
        };
      })
    );

    //console.log('res driving', resDrivingLicenses)
    setFilteredDrivingLicenses(
      resDrivingLicenses.map((d) => {
        return {
          id: d.id,
          title: d.name,
        };
      })
    );

    // SRC certificates initial
    setSrcCertificates(
      resSrc.map((s) => {
        return {
          id: s.id,
          title: s.name,
        };
      })
    );

    setFilteredSrcCertificates(
      resSrc.map((s) => {
        return {
          id: s.id,
          title: s.name,
        };
      })
    );

    // Disability types initial
    setDisabilityTypes(resDisbaility);

    // Personal information state set
    if (info) {
      setPersonalInformation(info);
    }

    setLoadingPage(false);
    onReady && onReady();
  }

  // All data fetch
  useEffect(() => {
    getAllDatas();
  }, []);

  type formType = {
    name: string;
    surname: string;
    tc_id: string;
    gender: string;
    marital_status: string;
    smoking_status: string;
    day: string;
    month: string;
    year: string;
    birth_date: string;
    country_id: string;
    military_status: string;
    militaryDelayMonth: string;
    militaryDelayYear: string;
    military_postponement_date: string;
    driving_license_status: number;
    driving_license_classes: number[];
    driving_license_srcs: number[];
    disability_status: number;
    disability_type: number;
    disability_percent: number;
    disability_detail: string;
  };

  // TODO: Postmandeki gibi yapılınca üstteki yerine kullanılacak
  type newFormType = cvPersonalType;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    clearErrors,
    watch,
    setError,
  } = useForm<formType>();

  // Search events
  // Country search
  useEffect(() => {
    async function search() {
      if (searchCountry.trim().length > 0) {
        const response = await getCountries({ search: searchCountry });
        setFilteredCountries(
          response.map((r) => {
            return {
              id: r.id,
              name: r.name_tr,
              name_en: r.name_en,
            };
          })
        );
      } else {
        setFilteredCountries(countries);
      }
    }

    const timeOut = setTimeout(search, 500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [searchCountry]);

  /* Filtreleme işlemleri */

  //Driving license filter
  useEffect(() => {
    if (drivingLicensesSearchTerm.trim().length == 0) {
      setFilteredDrivingLicenses(drivingLicenses);
    } else {
      setFilteredDrivingLicenses(
        drivingLicenses.filter((d) =>
          d.title
            .toLocaleLowerCase("tr-TR")
            .startsWith(drivingLicensesSearchTerm.toLocaleLowerCase("tr-TR"))
        )
      );
    }
  }, [drivingLicensesSearchTerm]);

  /* Src certificate filter */
  useEffect(() => {
    if (srcSearchTerm.trim().length == 0) {
      setFilteredSrcCertificates(srcCertificates);
    } else {
      setFilteredSrcCertificates(
        srcCertificates.filter((s) =>
          s.title
            .toLocaleLowerCase("tr-TR")
            .startsWith(srcSearchTerm.toLocaleLowerCase("tr-TR"))
        )
      );
    }
  }, [srcSearchTerm]);

  /* Form register işlemi yapıldı */
  // Name register
  register("name", {
    required: `${isEnglish ? "Name" : "Adınız"}`,
    minLength: {
      value: 2,
      message: "İsminiz daha uzun olmak zorunda.",
    },
  });

  // Surname register
  register("surname", {
    required: `${isEnglish ? "Surname" : "Soyadınız"}`,
  });

  // Identity number register
  register("tc_id", {
    required: false,
    minLength: {
      value: 11,
      message: `${isEnglish ? "Turkish ID Number" : "TC Kimlik Numarası"}`,
    },
    maxLength: {
      value: 11,
      message: `${isEnglish ? "Turkish ID Number" : "TC Kimlik Numarası"}`,
    },
    pattern: {
      value: /^\d{11}$/,
      message: `${isEnglish ? "Turkish ID Number" : "TC Kimlik Numarası"}`,
    },
  });

  // Gender register
  register("gender", {
    required: `${isEnglish ? "Gender" : "Cinsiyet"}`,
  });

  // Marital status register
  register("marital_status", {
    required: false,
  });

  // Smoke status register
  register("smoking_status", {
    required: false,
  });

  // Birth-date day register
  register("day", {
    required: `${isEnglish ? "Day" : "Gün"}`,
  });

  // Birth-date month register
  register("month", {
    required: `${isEnglish ? "Month" : "Ay"}`,
  });

  // Birth-date year register
  register("year", {
    required: `${isEnglish ? "Year" : "Yıl"}`,
  });

  // Nationality register
  register("country_id", {
    required: `${isEnglish ? "Nationality" : "Uyruk"}`,
  });

  // Military status register
  register("military_status", {
    required:
      gender != "Erkek"
        ? false
        : `${isEnglish ? "Military Status" : "Askerlik Durumu"}`,
  });

  // Military delay month register
  register("militaryDelayMonth", {
    required:
      militaryStatus == "3" && gender == "Erkek"
        ? `${isEnglish ? "Postponement Month" : "Tecil Ayı"}`
        : false,
  });

  // Military delay year register
  register("militaryDelayYear", {
    required:
      militaryStatus == "3" && gender == "Erkek"
        ? `${isEnglish ? "Postponement Year" : "Tecil Yılı"}`
        : false,
  });

  // Driving license status register
  register("driving_license_status", {
    required: false,
  });

  // Driving license classes register
  register("driving_license_classes", {
    required:
      drivingLicenseStatus == "1"
        ? `${isEnglish ? "Driver's License Class" : "Ehliyet Sınıfı"}`
        : false,
    minLength: {
      value: 1,
      message: `${isEnglish ? "Driver's License Class" : "Ehliyet Sınıfı"}`,
    },
  });

  // Driving license srcs register
  register("driving_license_srcs", {
    required: false,
  });

  // Disablity status register
  register("disability_status", {
    required: false,
  });

  // Disability type register
  register("disability_type", {
    required:
      disabilityStatus == "1"
        ? `${isEnglish ? "Disability Category" : "Engel Kategorisi"}`
        : false,
  });

  // Disability type register
  register("disability_percent", {
    required:
      disabilityStatus == "1"
        ? `${isEnglish ? "Disability Percentage" : "Engel Yüzdesi"}`
        : false,
  });

  register("disability_detail", {
    required:
      disabilityStatus == "1"
        ? `${isEnglish ? "Disability Details" : "Engel Detayı"}`
        : false,
  });

  /* Bazı kısımlar için manuel register işlemleri */
  // Name
  useEffect(() => {
    setValue("name", name);
    clearErrors("name");
  }, [name]);

  // Surname
  useEffect(() => {
    setValue("surname", surname);
    clearErrors("surname");
  }, [surname]);

  // Gender form set
  useEffect(() => {
    setValue("gender", gender);
    if (gender != "Erkek") {
      setMilitaryStatus("4");
      //TODO: Kadınsa military status vs resetlenecek
    }
    clearErrors("gender");
  }, [gender]);

  // Nationality form set
  useEffect(() => {
    if (selectedNationality) {
      setValue("country_id", selectedNationality.id.toString());
      clearErrors("country_id");
    }
  }, [selectedNationality]);

  // Birth-date day form set
  useEffect(() => {
    setValue("day", selectedDay);
    clearErrors("day");
  }, [selectedDay]);

  // Birth-date month form set
  useEffect(() => {
    const selectedMonthData = months.find((m) => m.label == selectedMonth);
    if (
      selectedMonthData &&
      selectedDay.length > 0 &&
      !isNaN(parseInt(selectedDay))
    ) {
      if (parseInt(selectedDay) > selectedMonthData.maxDay) {
        setSelectedDay("");
      }
    }
    setValue("month", selectedMonth);
    clearErrors("month");
  }, [selectedMonth]);

  // Birth-date year form set
  useEffect(() => {
    if (selectedYear.length > 0 && !isNaN(parseInt(selectedYear))) {
      if (parseInt(selectedYear) % 4 == 0) {
        months[1].maxDay = 29;
      } else {
        months[1].maxDay = 28;
      }
    }
    setValue("year", selectedYear);
    clearErrors("year");
  }, [selectedYear]);

  // Driving license status form set
  // TODO: Belki hata olabilir db kontrol edilecek
  useEffect(() => {
    //console.log('driving license status', drivingLicenseStatus)
    if (!isNaN(parseInt(drivingLicenseStatus))) {
      setValue("driving_license_status", parseInt(drivingLicenseStatus));
      clearErrors("driving_license_status");
    }
  }, [drivingLicenseStatus]);

  // Driving license classes
  useEffect(() => {
    setValue(
      "driving_license_classes",
      selectedDrivingLicenseItems.map((d) => {
        return d.id;
      })
    );
    clearErrors("driving_license_classes");
  }, [selectedDrivingLicenseItems]);

  // Drivin license srcs
  useEffect(() => {
    setValue(
      "driving_license_srcs",
      selectedSrcCertificates.map((s) => {
        return s.id;
      })
    );
    clearErrors("driving_license_srcs");
  }, [selectedSrcCertificates]);

  // Marital status form set
  useEffect(() => {
    setValue("marital_status", maritalStatus);
    clearErrors("marital_status");
  }, [maritalStatus]);

  // Smoking status form set
  useEffect(() => {
    setValue("smoking_status", smokingStatus);
    clearErrors("smoking_status");
  }, [smokingStatus]);

  // Military status form set
  useEffect(() => {
    setValue("military_status", militaryStatus);
    clearErrors("military_status");
  }, [militaryStatus]);

  // Military delay month form set
  useEffect(() => {
    setValue("militaryDelayMonth", militaryDelayMonth);
    clearErrors("militaryDelayMonth");
  }, [militaryDelayMonth]);

  // Military delay year form set
  useEffect(() => {
    setValue("militaryDelayYear", militaryDelayYear);
    clearErrors("militaryDelayYear");
  }, [militaryDelayYear]);

  // Disability status form set
  useEffect(() => {
    setValue("disability_status", parseInt(disabilityStatus));
    clearErrors("disability_status");
  }, [disabilityStatus]);

  // Disability types form set
  useEffect(() => {
    if (!isNaN(parseInt(selectedDisability))) {
      setValue("disability_type", parseInt(selectedDisability));
      clearErrors("disability_type");
    }
  }, [selectedDisability]);

  // Disability percent
  useEffect(() => {
    if (selectedDisabilityPercentage) {
      setValue("disability_percent", selectedDisabilityPercentage);
      clearErrors("disability_percent");
    }
  }, [selectedDisabilityPercentage]);

  // Disability detail
  useEffect(() => {
    setValue("disability_detail", disabilityDetails);
    clearErrors("disability_detail");
  }, [disabilityDetails]);

  /* Form submit function */
  const onSubmit: SubmitHandler<formType> = (data) => {
    updatePersonalInformation(data);
  };

  async function updatePersonalInformation(data: formType) {
    // Birthday failed return
    const monthId = months.find((m) => m.label == data.month)?.id;
    if (
      isNaN(parseInt(data.day)) ||
      isNaN(parseInt(monthId)) ||
      isNaN(parseInt(data.year))
    ) {
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: `${
          isEnglish
            ? "Please select a valid date of birth"
            : "Lütfen geçerli bir doğum tarihi seçiniz"
        }`,
      });
      return;
    }

    // Country failed return
    if (isNaN(parseInt(data.country_id))) {
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: `${
          isEnglish
            ? "Please select a valid country"
            : "Lütfen geçerli bir ülke seçiniz"
        }`,
      });
      return;
    }

    const monthName = months.find((m) => m.label == militaryDelayMonth);
    if (militaryStatus == "3" && gender == "Erkek") {
      // military month return
      if (!monthName) {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: `${
            isEnglish
              ? "Please select a valid postponement date"
              : "Lütfen geçerli bir tecil tarihi seçiniz"
          }`,
        });
        return;
      }

      // Military year
      if (isNaN(parseInt(data.militaryDelayYear))) {
        Swal.fire({
          icon: "error",
          title: "Hata",
          text: `${
            isEnglish
              ? "Please select a valid postponement date"
              : "Lütfen geçerli bir tecil tarihi seçiniz"
          }`,
        });
        return;
      }
    }
    //console.log('data tc', data.tc_id)

    //console.log('smoke', data.smoking_status)

    //console.log('monthName', monthName)
    const payloadData: cvPersonalPutDataType = {
      cv_id: parseInt(cvId || "0") || undefined,
      name: data.name,
      surname: data.surname,
      tc_id: data.tc_id,
      gender_id: data.gender == "Erkek" ? 1 : data.gender == "Kadın" ? 2 : 3, // gender id
      marital_status:
        data.marital_status == "0" ? 0 : data.marital_status == "1" ? 1 : null, // evlilik durumu, bekar - evli, 0-1
      smoke_status:
        data.smoking_status == "0" ? 0 : data.smoking_status == "1" ? 1 : null, // sigara içiyor - içmiyor,   1 - 2
      birth_day: parseInt(data.day),
      birth_month: parseInt(monthId + 1),
      birth_year: parseInt(data.year),
      country_id: parseInt(data.country_id), // country id
      military_status: parseInt(data.military_status), // military_status ids 1,2,3 ek olarak belirtilmemiş 4
      military_postponement_month:
        data.military_status == "3" ? monthName?.id + 1 : null,
      military_postponement_year:
        data.military_status == "3" ? parseInt(data.militaryDelayYear) : null,
      driving_licence_status: data.driving_license_status, // ehliyeti varmı yokmu 0-1
      driving_license_classes: data.driving_license_classes, // driving_licence_classes ids
      driving_license_srcs: data.driving_license_srcs, // driving_license_srcs ids
      disability_status: data.disability_status, // engellilik durumu 0-1
      disability_type: data.disability_type, // disability_type id
      disability_percent: data.disability_percent, // 1-100
      disability_detail: data.disability_detail, // max:2000 character
    };

    const endpoint = "user/profilim/cv/personal/update";

    try {
      const res = await axios.request({
        endpoint,
        type: "put",
        payload: payloadData,
      });

      if (res && res.status == 200) {
        if (inPopup) {
          onSuccess && onSuccess();
        } else {
          navigate("iletisim-bilgileri");
          showCvScore();
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "Uyarı",
          text: "Kişisel bilgiler güncellenirken bir hata meydana geldi",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  if (loadingPage) {
    return <div className="p-2 border-mains border">Yükleniyor...</div>;
  }

  // Form submit on error popup show event
  function onError() {
    if (Object.entries(errors).length > 0) {
      const innerHtmlArray = Object.entries(errors).map(([type, message]) => {
        return message.message;
      });

      const innerHtml = innerHtmlArray.join("<br/>");

      Swal.fire({
        icon: "warning",
        title: "Uyarı",
        html: `
        <div>
        ${isEnglish ? "Missing Fields" : "Eksik Alanlar"}: <br/>
        <p class="text-red-600">
        ${innerHtml}
        </p>
        </div>
        `,
      });
    }
  }

  // Profile photo delete function
  async function deleteProfilePhoto() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/delete`,
        type: "delete",
        payload: {},
      });

      if (response && response.status == 200) {
        //getAllDatas()
        Swal.fire({
          icon: "success",
          text: `${
            isEnglish ? "Profile photo removed" : "Profil fotoğrafı kaldırıldı"
          }`,
        });
        setAvatarUrl(
          "https://isbull.s3.eu-north-1.amazonaws.com/default-images/user-profile.png?v=2.9.90"
        );
      } else {
        Swal.fire({
          icon: "warning",
          title: "Uyarı",
          text: "Profil fotoğrafı kaldırılırken bir hata meydana geldi",
        });
      }
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  }

  return (
    <div className="flex flex-col w-full gap-y-8 rounded-md bg-white">
      <span className="poppins-semibold text-sm text-mains py-4 border-b border-mains px-4">
        {isEnglish ? "Personal Information" : "Kişisel Bilgiler"}
      </span>
      <form
        className="flex flex-col gap-y-6 p-6"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <div className="flex items-center justify-center mb-10">
          {/* Profile photo section */}
          <div className="relative">
            <div className="flex w-36 h-36 rounded-full border-2 border-mains items-center justify-center bg-gray-200">
              {personalInformation?.profile_photo ? (
                <img
                  src={avatarUrl}
                  className="w-full h-full aspect-square rounded-full object-contain"
                />
              ) : (
                <IoPerson className="flex text-7xl text-mains" />
              )}
            </div>
            {avatarUrl !=
            "https://isbull.s3.eu-north-1.amazonaws.com/default-images/user-profile.png?v=2.9.90" ? (
              <button
                type="button"
                className="flex items-center justify-center absolute bottom-0 right-0 bg-mains border border-white w-8 h-8 rounded-full"
                onClick={() => {
                  Swal.fire({
                    icon: "warning",
                    title: `${
                      isEnglish
                        ? "Are you sure to delete profile photo"
                        : "Profil fotoğrafınızı kaldırmak istediğinizden emin misiniz?"
                    }`,
                    preConfirm: () => {
                      deleteProfilePhoto();
                    },
                    confirmButtonText: `${isEnglish ? "Yes" : "Evet"}`,
                    cancelButtonText: `${isEnglish ? "No" : "Hayır"}`,
                    showCancelButton: true,
                  });
                }}
              >
                <FaTrashAlt className="text-white" />
              </button>
            ) : (
              <button
                type="button"
                className="flex items-center justify-center absolute bottom-0 right-0 bg-mains border border-white w-8 h-8 rounded-full"
                onClick={() => setAvatarModalOpen(true)}
              >
                <MdAddAPhoto className="text-white" />
              </button>
            )}
          </div>

          {/* Profile photo popup */}
          {avatarModalOpen && (
            <ProfilePhotoChanger
              getAllDatas={() => getAllDatas()}
              setIsModalOpen={setAvatarModalOpen}
              lang={isEnglish ? "en" : "tr"}
            />
          )}
        </div>
        <div className="flex flex-col lg:flex-row items-start gap-y-6 lg:gap-6">
          <div className="flex w-full">
            {/* Name input */}
            <InputArea
              className="w-full"
              required
              mode={name.length == 0 ? "empty" : "dirty"}
              error={errors.name?.message ? true : false}
            >
              <InputArea.Label>
                {isEnglish ? "First Name" : "Adınız"}
              </InputArea.Label>
              <InputArea.Input
                value={name || ""}
                onChange={(e) => {
                  const value = e.target.value;

                  const valueArray = value.split(" ");

                  let formattedName = valueArray
                    .map((v) => {
                      const nameArray = v.split("");
                      const formattedNameString = nameArray.map((n, i) => {
                        if (i == 0) {
                          return n.toLocaleUpperCase("tr-TR");
                        } else {
                          return n.toLocaleLowerCase("tr-TR");
                        }
                      });

                      return formattedNameString.join("");
                    })
                    .join(" ");

                  setName(formattedName);
                }}
              />
            </InputArea>
          </div>
          <div className="flex w-full">
            {/* Surname input */}
            <InputArea
              className="w-full"
              required
              mode={surname.length == 0 ? "empty" : "dirty"}
              error={errors.surname?.message ? true : false}
            >
              <InputArea.Label>
                {isEnglish ? "Last Name" : "Soyadınız"}
              </InputArea.Label>
              <InputArea.Input
                value={surname || ""}
                onChange={(e) => {
                  const value = e.target.value;

                  const valueArray = value.split(" ");

                  let formattedName = valueArray
                    .map((v) => {
                      const nameArray = v.split("");
                      const formattedNameString = nameArray.map((n, i) => {
                        if (i == 0) {
                          return n.toLocaleUpperCase("tr-TR");
                        } else {
                          return n.toLocaleLowerCase("tr-TR");
                        }
                      });

                      return formattedNameString.join("");
                    })
                    .join(" ");

                  setSurname(formattedName);
                }}
              />
            </InputArea>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-start my-2 lg:my-0 gap-y-6 lg:gap-6">
          <div className="flex w-full">
            {/* Identity number input */}
            <InputArea
              className="w-full"
              mode={identityNumber.length == 0 ? "empty" : "dirty"}
              error={errors.tc_id?.message ? true : false}
            >
              <InputArea.Label>
                {isEnglish ? "Turkish ID Number" : "TC Kimlik Numarası"}
              </InputArea.Label>
              <InputArea.Input
                type="number"
                className="[&::-webkit-inner-spin-button]:appearance-none"
                onWheel={(e) => e.currentTarget.blur()}
                value={identityNumber || ""}
                onChange={(e) => {
                  const value = e.target.value.substring(0, 11);

                  setIdentityNumber(value);
                  setValue("tc_id", value);
                  clearErrors("tc_id");
                }}
                maxLength={11}
                max={99999999999}
                placeholder="Zorunlu Değil"
              />
            </InputArea>
          </div>
          <div className="flex w-full">
            {/* Gender radio button */}
            <Controller
              control={control}
              name="gender"
              render={({ field }) => (
                <RadioButtonField
                  {...field}
                  value={gender}
                  setValue={setGender}
                  required
                  error={errors.gender?.message ? true : false}
                >
                  <div className="flex flex-col gap-1 py-1">
                    <RadioButtonField.Label>
                      {isEnglish ? "Gender" : "Cinsiyet"}
                    </RadioButtonField.Label>
                    <div className="flex flex-row flex-wrap gap-x-6 gap-y-2">
                      <RadioButtonField.RadioButton
                        label={`${isEnglish ? "Male" : "Erkek"}`}
                        radioValue="Erkek"
                      />
                      <RadioButtonField.RadioButton
                        label={`${isEnglish ? "Female" : "Kadın"}`}
                        radioValue="Kadın"
                      />
                      <RadioButtonField.RadioButton
                        label={`${
                          isEnglish ? "Not Specified" : "Belirtilmemiş"
                        }`}
                        radioValue="Belirtilmemiş"
                      />
                    </div>
                  </div>
                </RadioButtonField>
              )}
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-start gap-y-6 lg:gap-6">
          <div className="flex w-full my-0 lg:my-0">
            {/* Marital status radio button */}
            <Controller
              control={control}
              name="marital_status"
              render={({ field }) => (
                <RadioButtonField
                  {...field}
                  value={maritalStatus}
                  setValue={setMaritalStatus}
                >
                  <div className="flex flex-col gap-1">
                    <RadioButtonField.Label>
                      {isEnglish ? "Marital Status" : "Medeni Hal"}
                    </RadioButtonField.Label>
                    <div className="flex flex-row flex-wrap gap-6">
                      <RadioButtonField.RadioButton
                        label={`${isEnglish ? "Married" : "Evli"}`}
                        radioValue="0"
                      />
                      <RadioButtonField.RadioButton
                        label={`${isEnglish ? "Single" : "Bekar"}`}
                        radioValue="1"
                      />
                      <RadioButtonField.RadioButton
                        label={`${
                          isEnglish ? "Not Specified" : "Belirtilmemiş"
                        }`}
                        radioValue="2"
                      />
                    </div>
                  </div>
                </RadioButtonField>
              )}
            />
          </div>
          <div className="flex w-full my-1 lg:my-0">
            {/* Smoking status radio button */}
            <Controller
              control={control}
              name="smoking_status"
              render={({ field }) => (
                <RadioButtonField
                  {...field}
                  value={smokingStatus}
                  setValue={setSmokingStatus}
                >
                  <div className="flex flex-col gap-1">
                    <RadioButtonField.Label>
                      {isEnglish ? "Do You Smoke?" : "Sigara İçiyor Musunuz?"}
                    </RadioButtonField.Label>
                    <div className="flex flex-row flex-wrap gap-6">
                      <RadioButtonField.RadioButton
                        label={`${isEnglish ? "Yes" : "Evet"}`}
                        radioValue="1"
                      />
                      <RadioButtonField.RadioButton
                        label={`${isEnglish ? "No" : "Hayır"}`}
                        radioValue="0"
                      />
                    </div>
                  </div>
                </RadioButtonField>
              )}
            />
          </div>
        </div>

        <div className="flex flex-col lg:flex-row items-start gap-y-6 lg:gap-6">
          {/* Birthday component */}
          <div className="flex w-full">
            <div className="flex flex-col w-full gap-1">
              <SelectDropdown.Label>
                {isEnglish ? "Date of Birth" : "Doğum Tarihi"}{" "}
                <b className="text-red-600">*</b>
              </SelectDropdown.Label>
              <div className="flex flex-row w-full gap-2 lg:gap-4">
                {/* Day */}
                <div className="flex basis-1/3 ">
                  <SelectDropdown
                    className="w-full"
                    mode={selectedDay ? "dirty" : "empty"}
                    error={errors.day?.message ? true : false}
                  >
                    <SelectDropdown.Trigger className="line-clamp-1">
                      {selectedDay.length > 0
                        ? selectedDay
                        : `${isEnglish ? "Day" : "Gün"}`}
                    </SelectDropdown.Trigger>
                    <SelectDropdown.Items>
                      {days
                        .filter((d) => {
                          if (!selectedMonth) {
                            return d;
                          } else {
                            const selectedMonthData = months.find(
                              (m) => m.label == selectedMonth
                            );
                            if (!selectedMonthData) {
                              return d;
                            } else {
                              return d <= selectedMonthData.maxDay;
                            }
                          }
                        })
                        .map((d) => {
                          return (
                            <SelectDropdown.Item
                              key={`daySelect${d}`}
                              onClick={() => setSelectedDay(d.toString())}
                            >
                              {d}
                            </SelectDropdown.Item>
                          );
                        })}
                    </SelectDropdown.Items>
                    <SelectDropdown.Error>
                      {errors.birth_date?.message}
                    </SelectDropdown.Error>
                  </SelectDropdown>
                </div>

                {/* Month */}
                <div className="flex flex-row basis-1/3">
                  <SelectDropdown
                    className="w-full"
                    mode={selectedMonth ? "dirty" : "empty"}
                    error={errors.month?.message ? true : false}
                  >
                    <SelectDropdown.Trigger>
                      <label className="hidden lg:flex cursor-pointer">
                        {selectedMonth.length > 0
                          ? `${
                              isEnglish
                                ? `${
                                    months.find((m) => m.label == selectedMonth)
                                      ?.label_en
                                  }`
                                : `${selectedMonth}`
                            }`
                          : `${isEnglish ? "Month" : "Ay"}`}
                      </label>
                      <label className="flex lg:hidden cursor-pointer">
                        {selectedMonth.length > 0
                          ? `${
                              isEnglish
                                ? `${months
                                    .find((m) => m.label == selectedMonth)
                                    ?.label_en.slice(0, 3)}`
                                : `${selectedMonth.slice(0, 3)}`
                            }`
                          : `${isEnglish ? "Month" : "Ay"}`}
                      </label>
                    </SelectDropdown.Trigger>
                    <SelectDropdown.Items>
                      {months.map((m) => {
                        return (
                          <SelectDropdown.Item
                            key={`monthSelect${m.id}`}
                            onClick={() => setSelectedMonth(m.label)}
                          >
                            {isEnglish ? `${m.label_en}` : `${m.label}`}
                          </SelectDropdown.Item>
                        );
                      })}
                    </SelectDropdown.Items>
                  </SelectDropdown>
                </div>

                {/* Year */}
                <div className="flex flex-row basis-1/3">
                  <SelectDropdown
                    className="w-full"
                    mode={selectedYear ? "dirty" : "empty"}
                    error={errors.year?.message ? true : false}
                  >
                    <SelectDropdown.Trigger>
                      {selectedYear.length > 0
                        ? selectedYear
                        : `${isEnglish ? "Year" : "Yıl"}`}
                    </SelectDropdown.Trigger>
                    <SelectDropdown.Items>
                      {year.map((y, i) => {
                        return (
                          <SelectDropdown.Item
                            key={`yselec${y.value}+${i}`}
                            onClick={() => setSelectedYear(y.value)}
                          >
                            {y.label}
                          </SelectDropdown.Item>
                        );
                      })}
                    </SelectDropdown.Items>
                  </SelectDropdown>
                </div>
              </div>
            </div>
          </div>

          {/* Nationality component */}
          <div className="flex w-full">
            <SelectSearchDropdown
              className="w-full"
              required
              mode={selectedNationality ? "dirty" : "empty"}
              error={errors.country_id?.message ? true : false}
            >
              <SelectSearchDropdown.Label>
                {isEnglish ? "Nationality" : "Uyruk"}
              </SelectSearchDropdown.Label>
              <SelectSearchDropdown.Trigger
                onClick={async () => {
                  if (
                    searchCountry.length > 0 &&
                    filteredCountries.length == 0
                  ) {
                    setIsLoading(true);

                    const res = await getCountries({});
                    setFilteredCountries(
                      res.map((r) => {
                        return {
                          id: r.id,
                          name: r.name_tr,
                          name_en: r.name_en,
                        };
                      })
                    );
                    setCountries(
                      res.map((r) => {
                        return {
                          id: r.id,
                          name: r.name_tr,
                          name_en: r.name_en,
                        };
                      })
                    );

                    setIsLoadingData(false);
                  }
                }}
              >
                <SelectSearchDropdown.Value>
                  {selectedNationality
                    ? `${
                        isEnglish
                          ? `${selectedNationality.name_en}`
                          : `${selectedNationality.name}`
                      }`
                    : `${isEnglish ? "Select" : "Seçiniz"}`}
                </SelectSearchDropdown.Value>
                <SelectSearchDropdown.Input
                  placeholder={`${isEnglish ? "Search" : "Arama Yap"}`}
                  value={searchCountry || ""}
                  onChange={(e) => setSearchCountry(e.target.value)}
                />
              </SelectSearchDropdown.Trigger>
              <SelectSearchDropdown.Items>
                {isLoadingData && (
                  <SelectSearchDropdown.Item>
                    <AiOutlineLoading3Quarters className="mx-auto animate-spin" />
                  </SelectSearchDropdown.Item>
                )}
                {filteredCountries.map((c) => {
                  return (
                    <SelectSearchDropdown.Item
                      key={`countselct${c.id}`}
                      onClick={() => setSelectedNationality(c)}
                    >
                      {isEnglish ? c.name_en : c.name}
                    </SelectSearchDropdown.Item>
                  );
                })}
              </SelectSearchDropdown.Items>
            </SelectSearchDropdown>
          </div>
        </div>

        {/* Military status field should visible if gender==erkek */}
        {gender == "Erkek" && (
          <div className="flex flex-col lg:flex-row items-start gap-y-6 lg:gap-6">
            <div className="flex flex-col lg:flex-row gap-4 lg:gap-6 w-full justify-between">
              <div className="flex flex-col w-full gap-1">
                {/* Military status radio button */}
                <Controller
                  control={control}
                  name="military_status"
                  render={({ field }) => (
                    <RadioButtonField
                      {...field}
                      value={militaryStatus}
                      setValue={setMilitaryStatus}
                      required={gender == "Erkek"}
                      error={errors.military_status?.message ? true : false}
                    >
                      <RadioButtonField.Label>
                        {isEnglish
                          ? "Military Service Status"
                          : "Askerlik Durumu"}
                      </RadioButtonField.Label>
                      <div className="flex flex-row gap-x-6 gap-y-2 flex-wrap">
                        <RadioButtonField.RadioButton
                          label={`${isEnglish ? "Completed" : "Yaptı"}`}
                          radioValue="1"
                        />
                        <RadioButtonField.RadioButton
                          label={`${isEnglish ? "Postponed" : "Tecilli"}`}
                          radioValue="3"
                        />
                        <RadioButtonField.RadioButton
                          label={`${isEnglish ? "Exampt" : "Muaf"}`}
                          radioValue="2"
                        />
                        <RadioButtonField.RadioButton
                          label={`${
                            isEnglish ? "Not Specified" : "Belirtilmemiş"
                          }`}
                          radioValue="4"
                        />
                      </div>
                    </RadioButtonField>
                  )}
                />
              </div>

              {/* Military delay date field should visible militaryStatus==tecilli and gender==erkek */}

              {gender == "Erkek" && militaryStatus == "3" && (
                <div className="flex flex-col w-full lg:w-full gap-1">
                  {/* Military delay date selects */}
                  <SelectDropdown.Label>
                    {isEnglish
                      ? "Postponement / Completion Date"
                      : "Tecil Tamamlama Tarihi"}{" "}
                    <b className="text-red-600">*</b>
                  </SelectDropdown.Label>
                  <div className="flex flex-row w-full gap-4">
                    {/* Month */}
                    <div className="flex flex-row basis-1/2">
                      <SelectDropdown
                        className="w-full"
                        required
                        mode={militaryDelayMonth ? "dirty" : "empty"}
                        error={
                          errors.militaryDelayMonth?.message ? true : false
                        }
                      >
                        <SelectDropdown.Trigger>
                          {months.find((m) => m.label == militaryDelayMonth)
                            ?.label
                            ? `${
                                isEnglish
                                  ? `${
                                      months.find(
                                        (m) => m.label == militaryDelayMonth
                                      )?.label_en
                                    }`
                                  : `${
                                      months.find(
                                        (m) => m.label == militaryDelayMonth
                                      )?.label
                                    }`
                              }`
                            : `${isEnglish ? "Month" : "Ay"}`}
                        </SelectDropdown.Trigger>
                        <SelectDropdown.Items className="!bottom-1">
                          {months.map((m) => {
                            return (
                              <SelectDropdown.Item
                                key={`milm${m.id}`}
                                onClick={() => setMilitaryDelayMonth(m.label)}
                              >
                                {isEnglish ? m.label_en : m.label}
                              </SelectDropdown.Item>
                            );
                          })}
                        </SelectDropdown.Items>
                      </SelectDropdown>
                    </div>

                    {/* Year */}
                    <div className="flex flex-row basis-1/2">
                      <SelectDropdown
                        className="w-full"
                        mode={militaryDelayYear ? "dirty" : "empty"}
                        error={errors.militaryDelayYear?.message ? true : false}
                      >
                        <SelectDropdown.Trigger>
                          {militaryDelayYear || `${isEnglish ? "Year" : "Yıl"}`}
                        </SelectDropdown.Trigger>
                        <SelectDropdown.Items className="!bottom-1">
                          {initialMilitaryDelayYears.map((y, i) => {
                            return (
                              <SelectDropdown.Item
                                key={`miyaeras${y}`}
                                onClick={() => setMilitaryDelayYear(y)}
                              >
                                {y}
                              </SelectDropdown.Item>
                            );
                          })}
                        </SelectDropdown.Items>
                      </SelectDropdown>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="flex flex-col lg:flex-row items-start gap-y-6 lg:gap-6">
          {/* Driving license status */}
          <div className="flex w-full lg:w-1/3">
            <Controller
              control={control}
              name="driving_license_status"
              render={({ field }) => (
                <RadioButtonField
                  {...field}
                  value={drivingLicenseStatus}
                  setValue={setDrivingLicenseStatus}
                  error={errors.driving_license_status?.message ? true : false}
                >
                  <RadioButtonField.Label>
                    {isEnglish
                      ? "Do you have a driver's license?"
                      : "Ehliyetiniz Var Mı?"}
                  </RadioButtonField.Label>
                  <div className="flex flex-row gap-6">
                    {initialDrivingLiceses.map((d, i) => {
                      return (
                        <RadioButtonField.RadioButton
                          key={`radioButton${i}`}
                          label={isEnglish ? d.name_en : d.name}
                          radioValue={d.id.toString()}
                        />
                      );
                    })}
                  </div>
                </RadioButtonField>
              )}
            />
          </div>

          {/* Driving Liceses */}
          {drivingLicenseStatus == "1" && (
            <div className="flex w-full lg:w-2/3">
              <div className="flex flex-row gap-x-4 gap-y-6 w-full flex-wrap">
                {/* Driving license type */}
                <MultiSelectDropdown
                  className="flex flex-1"
                  mode={
                    selectedDrivingLicenseItems.length > 0 ? "dirty" : "empty"
                  }
                  error={errors.driving_license_classes?.message ? true : false}
                >
                  <MultiSelectDropdown.Label>
                    <div className="flex items-center">
                      <label className="line-clamp-1">
                        {isEnglish
                          ? "Driver's License Class"
                          : "Ehliyet Sınıfınız"}
                      </label>
                      <b className="text-red-600">*</b>
                    </div>
                  </MultiSelectDropdown.Label>
                  <MultiSelectDropdown.Trigger>
                    {selectedDrivingLicenseItems.length > 0
                      ? `${selectedDrivingLicenseItems[0].title} ${
                          selectedDrivingLicenseItems.length > 1
                            ? `(+${selectedDrivingLicenseItems.length - 1})`
                            : ""
                        }`
                      : `${isEnglish ? "Select" : "Seçiniz"}`}
                  </MultiSelectDropdown.Trigger>
                  <MultiSelectDropdown.Items>
                    {filteredDrivingLicenses.map((dl) => {
                      return (
                        <MultiSelectDropdown.Item
                          key={`dl${dl.id}`}
                          onClick={() => {
                            if (
                              selectedDrivingLicenseItems.find(
                                (i) => i.id == dl.id
                              )
                            ) {
                              setSelectedDrivingLicenseItems((prev) => [
                                ...prev.filter((i) => i.id != dl.id),
                              ]);
                            } else {
                              setSelectedDrivingLicenseItems((prev) => [
                                ...prev,
                                dl,
                              ]);
                            }
                          }}
                          selected={
                            selectedDrivingLicenseItems.find(
                              (i) => i.id == dl.id
                            )
                              ? true
                              : false
                          }
                        >
                          {dl.title}
                        </MultiSelectDropdown.Item>
                      );
                    })}
                  </MultiSelectDropdown.Items>
                </MultiSelectDropdown>

                {/* SRC certificates */}
                <MultiSelectDropdown
                  className="flex flex-1"
                  mode={selectedSrcCertificates.length == 0 ? "empty" : "dirty"}
                  error={errors.driving_license_srcs?.message ? true : false}
                >
                  <MultiSelectDropdown.Label className="line-clamp-1">
                    {isEnglish
                      ? "If Available, Your SRC Certificate"
                      : "Varsa SRC Belgeniz"}
                  </MultiSelectDropdown.Label>
                  <MultiSelectDropdown.Trigger className="line-clamp-1">
                    {selectedSrcCertificates.length > 0
                      ? `${selectedSrcCertificates[0].title}${
                          selectedSrcCertificates.length > 1
                            ? ` (+${selectedSrcCertificates.length - 1})`
                            : ""
                        }`
                      : `${isEnglish ? "Select" : "Seçiniz"}`}
                  </MultiSelectDropdown.Trigger>
                  <MultiSelectDropdown.Items>
                    {filteredSrcCertificates.map((src) => {
                      return (
                        <MultiSelectDropdown.Item
                          key={`srcl${src.id}`}
                          onClick={() => {
                            if (
                              selectedSrcCertificates.find(
                                (s) => s.id == src.id
                              )
                            ) {
                              setSelectedSrcCertificates((prev) => [
                                ...prev.filter((s) => s.id != src.id),
                              ]);
                            } else {
                              setSelectedSrcCertificates((prev) => [
                                ...prev,
                                src,
                              ]);
                            }
                          }}
                          selected={
                            selectedSrcCertificates.find((s) => s.id == src.id)
                              ? true
                              : false
                          }
                        >
                          {src.title}
                        </MultiSelectDropdown.Item>
                      );
                    })}
                  </MultiSelectDropdown.Items>
                </MultiSelectDropdown>
              </div>
            </div>
          )}
        </div>

        {/* Disabilities */}
        <div className="flex flex-col lg:flex-row items-start gap-y-6 lg:gap-6">
          <div className="flex w-full lg:w-1/3">
            <Controller
              control={control}
              name="disability_status"
              render={({ field }) => (
                <RadioButtonField
                  {...field}
                  value={disabilityStatus}
                  setValue={setDisabilityStatus}
                >
                  <RadioButtonField.Label>
                    {isEnglish
                      ? "Do you have a physical disability?"
                      : "Bedensel Engeliniz Var Mı?"}
                  </RadioButtonField.Label>
                  <div className="flex flex-row gap-6">
                    <RadioButtonField.RadioButton
                      label={`${isEnglish ? "Yes" : "Evet"}`}
                      radioValue="1"
                    />
                    <RadioButtonField.RadioButton
                      label={`${isEnglish ? "No" : "Hayır"}`}
                      radioValue="0"
                    />
                  </div>
                </RadioButtonField>
              )}
            />
          </div>
          {disabilityStatus == "1" && (
            <div className="flex flex-col lg:flex-row w-full lg:w-2/3 gap-y-6">
              {/* Disability Type */}
              <SelectDropdown
                className="w-full mx-1"
                required
                mode={selectedDisability ? "dirty" : "empty"}
                error={errors.disability_type?.message ? true : false}
              >
                <SelectDropdown.Label>
                  {isEnglish ? "Disability Category" : "Engel Kategorisi"}
                </SelectDropdown.Label>
                <SelectDropdown.Trigger>
                  <label className="whitespace-nowrap overflow-hidden text-ellipsis cursor-pointer">
                    {disabilityTypes.find(
                      (d) => d.id.toString() == selectedDisability
                    )
                      ? `${
                          isEnglish
                            ? `${
                                disabilityTypes.find(
                                  (d) => d.id.toString() == selectedDisability
                                )?.title_en ?? ""
                              }`
                            : `${
                                disabilityTypes.find(
                                  (d) => d.id.toString() == selectedDisability
                                )?.title
                              }`
                        }`
                      : `${isEnglish ? "Select" : "Seçiniz"}`}
                  </label>
                </SelectDropdown.Trigger>
                <SelectDropdown.Items>
                  {disabilityTypes.map((dis) => {
                    return (
                      <SelectDropdown.Item
                        key={`dis${dis.id}`}
                        onClick={() => setSelectedDisability(dis.id.toString())}
                      >
                        {isEnglish ? dis.title_en : dis.title}
                      </SelectDropdown.Item>
                    );
                  })}
                </SelectDropdown.Items>
              </SelectDropdown>

              {/* Disability percent */}
              <SelectDropdown
                className="w-full mx-1"
                required
                mode={selectedDisabilityPercentage ? "dirty" : "empty"}
                error={errors.disability_percent?.message ? true : false}
              >
                <SelectDropdown.Label>
                  {isEnglish ? "Details of Your Disability" : "Engel Yüzdesi"}
                </SelectDropdown.Label>
                <SelectDropdown.Trigger>
                  {selectedDisabilityPercentage
                    ? `%${selectedDisabilityPercentage}`
                    : `${isEnglish ? "Select" : "Seçiniz"}`}
                </SelectDropdown.Trigger>
                <SelectDropdown.Items>
                  {disabilityPercentages.map((d) => {
                    return (
                      <SelectDropdown.Item
                        key={`disp${d}`}
                        onClick={() => setSelectedDisabilityPercentage(d.id)}
                      >
                        {d.title}
                      </SelectDropdown.Item>
                    );
                  })}
                </SelectDropdown.Items>
              </SelectDropdown>
            </div>
          )}
        </div>

        {/* Disaiblity detail */}
        {disabilityStatus == "1" && (
          <Textarea
            required
            className="my-0 lg:my-0"
            mode={
              disabilityDetails && disabilityDetails.length > 0
                ? "dirty"
                : "empty"
            }
            error={errors.disability_detail?.message ? true : false}
          >
            <Textarea.Label>
              {isEnglish ? "Details of Your Disability" : "Engelinizin Detayı"}
            </Textarea.Label>
            <Textarea.Field
              className="min-h-[100px]"
              value={disabilityDetails || ""}
              onChange={(e) => {
                setDisabilityDetails(e.target.value);
              }}
            />
          </Textarea>
        )}

        {/* Save button */}
        <div className={`flex w-full ${inPopup ? "my-1" : "my-8"}`}>
          <Button type="submit" size="sm" className="ml-auto !px-6">
            {isEnglish ? "Save and Continue" : "Kaydet ve Devam Et"}
            <Button.Icon>
              <IoChevronForward />
            </Button.Icon>
          </Button>
        </div>

        {/* {!inPopup && <div className="mt-1"></div>} */}
      </form>
    </div>
  );
};

export default PersonalInformation;
