import React from "react";

const data = [
	{ id: 1, text: "Kendi hazırladığınız özgeçmişinizi" },
	{ id: 2, text: "Projelerinizi, sunumlarınızı, makalelerinizi" },
	{ id: 3, text: "Eğitim ve sertifika belgelerinizi" },
	{ id: 4, text: "Portfolyonuzu özgeçmişinize ekleyebilirsiniz" },
];

const Info: React.FC = () => {
	return (
		<div className='flex items-center w-full py-3 rounded-md '>
			<div className='flex w-full h-auto'>
				<div className='flex flex-col w-full md:w-3/4 lg:w-3/4 2xl:w-3/4 gap-y-4'>
					<span className='text-xl text-mains poppins-semibold'>
						Özgeçmişinizde kendinizi daha fazla anlatmak mı istiyorsunuz?
					</span>
					<div className='flex flex-col gap-y-2'>
						{data.map((item, index) => (
							<div
								key={index}
								className='flex items-center gap-x-1'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									className='icon icon-tabler icon-tabler-point-filled'
									width='30'
									height='30'
									viewBox='0 0 24 24'
									strokeWidth='1.5'
									stroke='#267ec1'
									fill='none'
									strokeLinecap='round'
									strokeLinejoin='round'>
									<path
										stroke='none'
										d='M0 0h24v24H0z'
										fill='none'
									/>
									<path
										d='M12 7a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z'
										stroke-width='0'
										fill='#267ec1'
									/>
								</svg>
								<span className='text-sm text-gray-500 poppins-medium lg:text-base'>
									{item.text}
								</span>
							</div>
						))}
					</div>
				</div>

				<div className='flex items-center justify-center hidden w-1/4 md:block lg:block 2xl:block'>
					<img
						className='object-cover h-full lg:py-0'
						src='https://isbul.net/frontend/images/svg/cover.svg'
						alt='cover'
					/>
				</div>
			</div>
		</div>
	);
};

export default Info;
