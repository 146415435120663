/* eslint-disable react-hooks/exhaustive-deps */
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import React, { memo, useEffect, useState } from "react";
import services from "../../../../services/search-services";
import { useContext } from "react";
import { Checkbox } from "antd";
import { FaRegTrashAlt } from "react-icons/fa";
import Axios from "../../../../services/axios";
import Swal from "sweetalert2";
import { IItem } from "../../../job-page-container/main/filter-section-content";

interface IFilterCategoryAccordion {
  maxSelect?: number;
  name: string;
  initialSelectedItems?: IItem[];
  initialNewSelectedItems?: IItem[];
  initialDatas: any;
  placeHolder?: string;
  api?: {
    endoint: string;
    apiWay: string;
    propertyName: string;
  };
  items?: IItem[];
  isOpen?: boolean;
  onChange?: (e: IItem[]) => void;
  deleteItem?: boolean;
}

const FilterAccordionWorkAlarm: React.FC<IFilterCategoryAccordion> = (
  props: IFilterCategoryAccordion
) => {
  const [selectedItems, setSelectedItems] = useState<IItem[]>([]);
  const [newSelected, setNewSelected] = useState<IItem[]>([]);
  const [isActive, setIsActive] = useState(props.isOpen || false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState<IItem[]>(
    props.initialDatas ? props.initialDatas : []
  );
  const axios = new Axios();

  const selectedValuesLength = 0;

  useEffect(() => {
    //console.log("initial datas", props.initialDatas);
    setFilteredItems(props.initialDatas ? props.initialDatas : []);
  }, [props.initialDatas]);

  useEffect(() => {
    const delay = setTimeout(() => {
      search();
    }, 500);

    return () => {
      clearTimeout(delay);
    };
  }, [searchTerm, selectedItems]);

  useEffect(() => {
    isActive && search();
  }, [isActive]);

  const search = async () => {
    if (props.items) {
      setFilteredItems(
        props.items?.filter((d) =>
          d.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
        )
      );
      return;
    }

    if (searchTerm.length > 0) {
      if (props.api && isActive) {
        const options = await services.searchServicesCustom(
          props.api.endoint,
          searchTerm,
          "[" + selectedItems.map((el) => el.id).join(",") + "]",
          10 + selectedItems.length
        );

        setFilteredItems(
          options.data[props.api.apiWay].map((el: any) => ({
            [props.api!.propertyName]: el[props.api!.propertyName],
            id: el.id,
            slug: el.slug,
          }))
        );
      }
    } else {
      setFilteredItems(props.initialDatas ? props.initialDatas : []);
    }
  };

  const isChecked = (id: number) => {
    return newSelected.some((item) => item.id === id);
  };

  const addOrRemoveItem = (el: any) => {
    const type = isChecked(el.id) ? "remove" : "add";
    let newSelectedItems;

    if (type === "add") {
      if (props.maxSelect && newSelected.length === props.maxSelect) {
        newSelectedItems = [el];
      } else {
        newSelectedItems = [...newSelected, el];
      }
    } else {
      newSelectedItems = newSelected.filter(
        (item: any) =>
          item[props.api?.propertyName || "title"] !==
          el[props.api?.propertyName || "title"]
      );
    }

    setNewSelected(newSelectedItems);
    setSelectedItems(newSelectedItems);
  };

  useEffect(() => {
    //FIXME: Bu kısım yukarıdaki props.initialSelectedItemsa bağlı useEffectin sürekli triggerlanmasına sebep oluyor

    if (
      JSON.stringify(props.initialNewSelectedItems) !==
      JSON.stringify(newSelected)
    ) {
      props.onChange && props.onChange(newSelected);
    }
  }, [newSelected]);

  return (
    <div className="w-full bg-gray-200 border border-gray-400 rounded select-none">
      {/** Accordion Header */}
      <div
        onClick={() => {
          setIsActive(!isActive);
        }}
        className="flex items-center justify-between w-full p-3 cursor-pointer"
      >
        <div className="text-sm text-gray-500 poppins-semibold">
          {props.name}
        </div>
        <IconChevronDown
          className={`${isActive && "rotate-180"} transition-all`}
        />
      </div>
      {/** Accordion Body */}
      <div className={`transition-all ${isActive ? "h-42" : "h-0"}`}>
        {isActive && (
          <div className="w-full">
            {props.placeHolder && (
              <div className="flex items-center w-11/12 px-2 py-2 mx-auto mb-2 bg-white border border-black rounded gap-x-2">
                <IconSearch size={18} />
                <input
                  className="h-6 text-sm bg-transparent outline-none poppins-medium"
                  placeholder={props.placeHolder}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            )}

            {selectedItems.length > 0 && (
              <div className="flex flex-col gap-y-2">
                {selectedItems.map((item: any, i) => {
                  return (
                    <div key={i} className="flex items-center px-4 gap-x-2">
                      {props.maxSelect === 1 ? (
                        <input
                          type="radio"
                          checked={isChecked(item.id)}
                          className="radio"
                          onChange={(e) => {
                            addOrRemoveItem(item);
                          }}
                        />
                      ) : (
                        <input
                          type="checkbox"
                          checked={isChecked(item.id)}
                          className="test"
                          onChange={(e) => {
                            addOrRemoveItem(item);
                          }}
                        />
                      )}

                      <div
                        onClick={() => {
                          addOrRemoveItem(item);
                        }}
                        className="text-sm text-gray-600 poppins-medium"
                      >
                        {
                          item[
                            props.api?.propertyName
                              ? props.api?.propertyName
                              : "title"
                          ]
                        }
                      </div>
                    </div>
                  );
                })}

                <div className="w-full bg-gray-300 p-[1px] my-1"></div>
              </div>
            )}

            <div className="flex flex-col mb-2 overflow-auto gap-y-3 lg:gap-y-[10px] max-h-36 mt-3">
              {filteredItems
                .filter(
                  (el) =>
                    el.id !==
                    selectedItems.find((compare) => compare.id === el.id)?.id
                )
                .map((item: any, i) => {
                  return (
                    <div key={i} className="flex items-center px-4 gap-x-2">
                      <>
                        {props.maxSelect === 1 ? (
                          <input
                            type="radio"
                            checked={isChecked(item.id)}
                            className="radio"
                            onChange={(e) => {
                              addOrRemoveItem(item);
                            }}
                          />
                        ) : (
                          <input
                            type="checkbox"
                            checked={isChecked(item.id)}
                            className="test"
                            onChange={(e) => {
                              addOrRemoveItem(item);
                            }}
                          />
                        )}

                        <div
                          onClick={() => {
                            addOrRemoveItem(item);
                          }}
                          className="text-sm text-gray-600 poppins-medium"
                        >
                          {
                            item[
                              props.api?.propertyName
                                ? props.api?.propertyName
                                : "title"
                            ]
                          }
                        </div>
                      </>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(FilterAccordionWorkAlarm);
