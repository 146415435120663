import Axios from '../axios';

const PositionsService = {
  getPostionsByFilter: (filter: string) => {
    const axios = new Axios();
    return axios.request({
      type: 'get',
      endpoint: `cdn/get-positions?search=${filter}`,
      payload: {},
    });
  }, 

};

export { PositionsService };
