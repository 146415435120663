import {
  Dispatch,
  HTMLAttributes,
  SetStateAction,
  useEffect,
  useRef,
  createContext,
  useState,
} from "react";
import Input from "./Input";
import Label from "./Label";
import Items from "./Items";
import Item from "./Item";
import Value from "./Value";
import Error from "./Error";

type selectedValueType = string | number | null | undefined | any;

export type selectContextType = {
  required: boolean;
  error: boolean;
  search: boolean;
  isOpen: boolean;
  placeholder: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedValue: selectedValueType;
};

type selectPropsType = HTMLAttributes<HTMLDivElement> & {
  required?: boolean;
  search?: boolean;
  placeholder?: string;
  error?: boolean;
  selectedValue: selectedValueType;
};

export const SelectContext = createContext<selectContextType>({
  required: false,
  error: false,
  search: false,
  placeholder: "",
  isOpen: false,
  setIsOpen: () => {},
  selectedValue: undefined,
});

export default function Select({
  required = false,
  error = false,
  search = false,
  placeholder = "",
  className,
  children = null,
  selectedValue,
  ...props
}: selectPropsType) {
  const divRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const contextValues: selectContextType = {
    required,
    error,
    search,
    placeholder,
    isOpen,
    setIsOpen,
    selectedValue,
  };

  useEffect(() => {
    setIsOpen(false);
  }, [selectedValue]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [divRef]);
  return (
    <SelectContext.Provider value={contextValues}>
      {/* TODO: Burada relative ile alakalı problem var düzelt */}

      <div
        onClick={() => setIsOpen(!isOpen)}
        ref={divRef}
        {...props}
        className={`${className} relative w-full flex flex-col gap-1`}
      >
        {children}
      </div>
    </SelectContext.Provider>
  );
}

Select.Input = Input;
Select.Label = Label;
Select.Items = Items;
Select.Item = Item;
Select.Value = Value;
Select.Error = Error;
