import React, { useEffect, useRef, useState } from "react";
import { BsEye, BsPlus } from "react-icons/bs";
import { PiDotsThreeVertical } from "react-icons/pi";
import { IoCheckmarkCircleOutline, IoClose, IoEye } from "react-icons/io5";
import { TbEditCircle } from "react-icons/tb";
import { LiaEditSolid } from "react-icons/lia";
import { LuCalendarClock } from "react-icons/lu";
import { HiOutlinePrinter } from "react-icons/hi";
import { AiOutlineFilePdf } from "react-icons/ai";
import { FiSend } from "react-icons/fi";
import { Tooltip } from "antd";
import { IoDiamondOutline } from "react-icons/io5";
import { Select } from "antd";
import Axios from "../../../../../services/axios";
import { Link } from "react-router-dom";
import { FaRegTrashAlt } from "react-icons/fa";
import Swal from "sweetalert2";
import { Modal, Button, Input } from "antd";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgress } from "../../../../../components/common/profile/left-dashboard/CircularProgress";

const axios = new Axios();

const MyCv: React.FC = () => {
  const customeAxios = new Axios();

  const [data, setData] = useState<any[]>([]);
  const divRef = useRef<HTMLDivElement>(null);
  const cvRef = useRef<HTMLDivElement>(null);
  const [term, setTerm] = useState<string>("");

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isCvOpen, setIsCvOpen] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentTitle, setCurrentTitle] = useState<string>("");
  const [currentId, setCurrentId] = useState<number | null>(null);
  const [isDefaultEnglish, setIsDefaultEnglish] = useState<boolean>(false);

  const showModal = (title: string, id: number) => {
    setCurrentTitle(title);
    setCurrentId(id);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  async function fetchCvInfo() {
    const response = await customeAxios.userAuthRequest({
      type: "get",
      endpoint: "user/profilim/cv/all",
      payload: "",
    });
    if (response.data) {
      setData(response.data.data);
    }
  }

  useEffect(() => {
    fetchCvInfo();
  }, []);

  useEffect(() => {
    if (data && data[0]) {
      if (data[0]?.lang == "en") {
        setIsDefaultEnglish(true);
      } else {
        setIsDefaultEnglish(false);
      }
    }
  }, [data]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setIsPopupOpen(false);
        setActiveIndex(null);
      }
    }

    function handleCvClickOutside(event: MouseEvent) {
      if (cvRef.current && !cvRef.current.contains(event.target as Node)) {
        setIsCvOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("mousedown", handleCvClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("mousedown", handleCvClickOutside);
    };
  }, []);

  const handleChangeTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTerm(e.target.value);
  };

  const handleAddCv = async () => {
    console.log("Term:", term);
    try {
      const response = await customeAxios.userAuthRequest({
        type: "post",
        endpoint: "user/profilim/cv/create",
        payload: {
          title: term,
        },
      });

      console.log("Response:", response);

      if (response.data) {
        console.log("Response Data:", response.data);

        const newCv = response.data.cv;
        // Ensure total_view_count is set to 0 if it's not defined
        newCv.total_view_count = newCv.total_view_count || 0;
        setData((prev) => [...prev, newCv]);
        setIsCvOpen(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const togglePopup = (index: number) => {
    setIsPopupOpen((prev) => !prev);
    setActiveIndex(index);
  };

  const handleDeleteCv = async (is_default: number) => {
    if (is_default === 1) {
      Swal.fire({
        text: "Varsayılan Cv silinemez!",
        icon: "error",
      });
      return;
    } else {
      Swal.fire({
        title: "Emin misiniz?",
        text: "Bu özgeçmişi silmek istediğinize emin misiniz?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet, sil",
        cancelButtonText: "Hayır, iptal et",
        customClass: {
          confirmButton: "sweet-alert-button",
          cancelButton: "swal2-cancel",
        },
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          try {
            const response = await customeAxios.userAuthRequest({
              type: "delete",
              endpoint: "user/profilim/cv/delete",
              payload: "",
            });

            if (response.data) {
              setData((prev) =>
                prev.filter((item, index) => index !== activeIndex)
              );
              setIsPopupOpen(false);
              setActiveIndex(null);
            }
          } catch (error) {
            console.error("Error:", error);
          }
        }
      });
    }
  };

  const handleChangeData = async (id: number) => {
    try {
      const response = await customeAxios.userAuthRequest({
        type: "put",
        endpoint: `user/profilim/cv/${id}/update-date`,
        payload: "",
      });

      if (response.data) {
        setIsPopupOpen(false);
        setActiveIndex(null);
        fetchCvInfo();

        Swal.fire({
          icon: "success",
          text: "CV tarihi başarıyla güncellendi.",
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "sweet-alert-button",
          },
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateTitle = async () => {
    if (currentId === null) return;
    console.log(currentId, currentTitle);
    try {
      const response = await customeAxios.userAuthRequest({
        type: "put",
        endpoint: `user/profilim/cv/${currentId}/update-cv-title`,
        payload: {
          title: currentTitle,
        },
      });

      if (response.data) {
        setData((prevData) =>
          prevData.map((cv) =>
            cv.id === currentId ? { ...cv, title: currentTitle } : cv
          )
        );
        setIsModalOpen(false);
        Swal.fire({
          icon: "success",
          text: "CV başlığı başarıyla güncellendi.",
          confirmButtonText: "Tamam",
          customClass: {
            confirmButton: "sweet-alert-button",
          },
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const formatDate = (isoDate: string) => {
    const date = new Date(isoDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ay 0'dan başladığı için +1 ekliyoruz
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  };

  return (
    <div className="flex flex-col w-full items-center rounded-md">
      {isCvOpen && (
        <div className="fixed flex items-center justify-center top-0 left-0 bottom-0 max-h-screen h-screen max-w-screen w-screen bg-black bg-opacity-30 z-50">
          <div
            ref={cvRef}
            className="md:min-w-[400px] flex flex-col gap-2 max-w-screen max-h-screen overflow-auto p-4 rounded-xl bg-white"
          >
            <div className="flex flex-row items-center justify-between w-full">
              <label className="text-title text-xl">Özgeçmiş Ekle</label>
              <button type="button" onClick={() => setIsCvOpen(false)}>
                <IoClose className="text-2xl text-gray-600" />
              </button>
            </div>
            <hr />
            <div className="flex flex-col gap-2 p-2">
              <div className="flex flex-col gap-2">
                <label className="text-gray-600 font-semibold">
                  Özgeçmiş Başlığı
                  <b className="text-red-600">*</b>
                </label>
                <input
                  onChange={handleChangeTerm}
                  className="border border-gray-600 rounded-lg p-2 outline-none"
                />
              </div>
              <div className="flex flex-col">
                <label className="text-xs text-gray-600">
                  <i>
                    Bilgi: Özgeçmişine verdiğin isim, şirketler tarafından
                    görüntülenmeyecektir.
                  </i>
                </label>
                <label className="text-xs text-gray-600">
                  <i>
                    Bilgi: {isDefaultEnglish ? "İngilizce" : "Türkçe"}{" "}
                    özgeçmişiniz bulunduğu için bir sonraki özgeçmişiniz{" "}
                    {isDefaultEnglish ? "Türkçe" : "İngilizce"}{" "}
                    oluşturulacaktır.
                  </i>
                </label>
              </div>
            </div>
            <hr />
            <div className="flex flex-row gap-2 items-center">
              <button
                className="bg-gray-300 py-2 px-4 rounded-lg text-gray-800 ml-auto"
                onClick={() => {
                  setIsCvOpen(false);
                }}
              >
                Kapat
              </button>
              <button
                onClick={() => handleAddCv()}
                className="flex flex-row items-center gap-2 bg-title py-2 px-4 rounded-lg text-white font-semibold"
              >
                Ekle
                <FiSend />
              </button>
            </div>
          </div>
        </div>
      )}

      {data
        ?.sort((a, b) => b.is_default - a.is_default)
        .map((item, index) => (
          <>
            <div
              key={index}
              className={`${
                item?.is_default === 1 ? "border border-green-500" : ""
              }  "h-auto flex w-full justify-between bg-white  rounded-lg  shadow-lg lg:shadow-none gap-x-3 py-2 px-2 md:px-4 my-1`}
            >
              <div className="flex gap-x-2 w-auto h-20">
                <div className="w-auto h-full flex items-center justify-center">
                  <CircularProgress
                    percentage={item?.score} // Progres göstergesinin yüzdesi
                    width={70} // İsteğe bağlı olarak genişlik belirtebilirsiniz
                    height={70} // İsteğe bağlı olarak yükseklik belirtebilirsiniz
                    strokeWidth={6} // İsteğe bağlı olarak çizgi kalınlığını belirtebilirsiniz
                  />
                </div>

                <div className="w-auto h-full flex flex-col gap-y-1 items-start justify-center">
                  <div className="flex gap-x-1 items-start md:items-center md:flex-row flex-col gap-1 md:gap-2">
                    <div className="flex items-center">
                      <a
                        href={`/profilim/cv/duzenle/${item.id}`}
                        className="text-sm poppins-semibold text-mains whitespace-nowrap overflow-hidden text-ellipsis max-w-[100px] lg:max-w-[250px]"
                      >
                        {item?.title}
                      </a>
                    </div>
                    <Link
                      to={`/profilim/cv/${item?.id}/goruntuleyen-firmalar`}
                      className="flex gap-1 items-center"
                    >
                      <Tooltip title="Özgeçmiş Görüntülenme Sayısı">
                        <BsEye className="text-mains text-lg" />
                      </Tooltip>

                      <div className="text-mains text-xs flex items-center h-5">
                        {item?.total_view_count}
                      </div>
                    </Link>
                  </div>
                  <div className="hidden md:flex gap-x-1 items-center">
                    <div className="flex gap-x-1 items-center">
                      <span className="text-xs poppins-medium truncate">
                        Son Güncelleme:
                      </span>
                      <span className="text-xs poppins-medium">
                        {formatDate(item?.updated_at)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-auto flex h-20 justify-center md:justify-between items-center">
                <div className="flex md:hidden gap-x-1 items-center flex-col gap-2">
                  {item?.is_default === 1 ? (
                    <div className="flex justify-center items-center gap-2">
                      <IoDiamondOutline className="text-gray-500 text-sm lg:text-xl" />
                      <p className="text-xs lg:text-sm poppins-medium text-green-400">
                        Varsayılan
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="flex flex-col sm:flex-row  gap-x-1 items-center">
                    <span className="text-[10px] poppins-medium truncate">
                      Son Güncelleme:
                    </span>
                    <span className="text-[10px] poppins-medium">
                      {formatDate(item?.updated_at)}
                    </span>
                  </div>
                </div>

                <div className="transition-all duration-300 easy-in border-gray-300 md:px-5 md:py-2 gap-x-6 flex justify-end w-full">
                  {item?.is_default === 1 ? (
                    <>
                      <div className="border border-[#194599] bg-transparent rounded-md py-[6px] px-8 text-[#194599] font-poppins text-[13px] xl:block hidden">
                        Varsayılan
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <Select
                    className="xl:block hidden"
                    defaultValue={item.visibility}
                    onChange={(e) => {
                      axios.userAuthRequest({
                        endpoint: `user/profilim/cv/visibility/${item.id}`,
                        type: "post",
                        payload: {
                          visibility: e,
                        },
                      });
                    }}
                    options={[
                      { value: "ALL", label: "Tüm Firmalar" },
                      {
                        value: "APPLY_COMPANY",
                        label: "Başvurduğum Firmalar",
                      },
                    ]}
                    style={{
                      width: 185,
                      fontSize: "13px",
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      color: "#194599",
                      border: "1px solid #194599",
                      borderRadius: "6px",
                      outline: "none",
                    }}
                  />

                  <span className="text-xl lg:text-2xl relative">
                    <button type="button" onClick={() => togglePopup(index)}>
                      <PiDotsThreeVertical className="text-black text-3xl md:text-xl" />
                    </button>

                    {isPopupOpen && activeIndex === index && (
                      <div
                        ref={divRef}
                        className="absolute -bottom-4 right-0 translate-y-full z-[99]"
                      >
                        <div className="relative shadow-[0_-2px_30px_5px_rgba(26,26,26,0.3)] rounded-b-lg rounded-tl-lg">
                          <div className="triangle-white top-0 -translate-y-full absolute right-0" />
                          <div className="bg-white p-4 flex flex-col gap-2 rounded-b-lg rounded-tl-lg ">
                            {item.is_default != 1 && (
                              <>
                                <button
                                  onClick={async () => {
                                    try {
                                      const response =
                                        await axios.userAuthRequest({
                                          endpoint: `user/profilim/cv/change-default-cv`,
                                          type: "post",
                                          payload: {
                                            cv_id: item.id,
                                          },
                                        });

                                      if (response && response.status == 200) {
                                        fetchCvInfo();
                                        Swal.fire({
                                          icon: "success",
                                          title: "Başarılı",
                                          text: "CV'niz başarıyla varsayılan yapıldı.",
                                        });
                                      } else {
                                        Swal.fire({
                                          icon: "error",
                                          title: "Hata",
                                          text: "CV Varsayılan yapılırken bir hata meydana geldi.",
                                        });
                                      }
                                    } catch (error) {
                                      console.log(`Error: ${error}`);
                                      Swal.fire({
                                        icon: "error",
                                        title: "Hata",
                                        text: "CV Varsayılan yapılırken beklenmedik bir hata meydana geldi.",
                                      });
                                    }
                                  }}
                                  className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap"
                                >
                                  <IoCheckmarkCircleOutline /> Varsayılan Yap
                                </button>
                                <hr />
                              </>
                            )}
                            <Link
                              to={`goruntule/${item?.id}`}
                              className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap"
                            >
                              <IoEye /> Görüntüle
                            </Link>
                            <hr />
                            <Link
                              to={`duzenle/${item?.id}`}
                              className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap"
                            >
                              <TbEditCircle /> Düzenle
                            </Link>
                            <hr />
                            <button
                              onClick={() => showModal(item?.title, item?.id)}
                              className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap"
                            >
                              <LiaEditSolid /> Başlığı Düzenle
                            </button>
                            <hr />
                            <button
                              onClick={() => handleChangeData(item?.id)}
                              className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap"
                            >
                              <LuCalendarClock /> CV Tarihini Güncelle
                            </button>
                            <hr />
                            <a
                              href={`/profilim/cv/goruntule/${item.id}?action=print`}
                              className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap"
                            >
                              <HiOutlinePrinter /> Yazdır
                            </a>
                            <hr />
                            <a
                              href={`/profilim/cv/goruntule/${item.id}?action=download`}
                              className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap"
                            >
                              <AiOutlineFilePdf /> PDF Olarak İndir
                            </a>
                            <hr />
                            <a
                              href={`/profilim/cv/goruntule/${item.id}?action=share`}
                              className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap"
                            >
                              <FiSend /> Özgeçmişi Gönder
                            </a>
                            {item.is_default != 1 && (
                              <>
                                <hr />
                                <button
                                  onClick={() =>
                                    handleDeleteCv(item?.is_default)
                                  }
                                  className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap"
                                >
                                  <FaRegTrashAlt /> Sil
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </span>
                </div>
              </div>
            </div>

            <Modal
              title="Özgeçmiş Güncelle"
              open={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={[
                <div className="w-full flex justify-end border-t border-color-[#dee2e6] px-[24px] py-[12px]">
                  <Button
                    className="bg-[#2253af] text-white font-poppins text-sm"
                    key="Güncelle"
                    onClick={handleUpdateTitle}
                  >
                    Güncelle
                  </Button>
                </div>,
              ]}
            >
              <div>
                <p className="text-gray-400 font-poppins text-sm mb-[2px]">
                  Özgeçmiş Başlığı <span className="text-red-500">*</span>{" "}
                </p>
                <Input
                  onChange={(e) => setCurrentTitle(e.target.value)}
                  style={{ height: "40px" }}
                  value={currentTitle}
                />

                <div className="mt-4">
                  <p>
                    Bilgi: Özgeçmişine verdiğin isim, şirketler tarafından
                    görüntülenmeyecektir.
                  </p>
                </div>
              </div>
            </Modal>
          </>
        ))}

      {data.length < 2 && (
        <div className="w-full px-0 md:px-4 py-3  flex justify-between items-center">
          {/* <span className="text-gray-800 lg:text-mains text-xs lg:text-lg poppins-semibold tracking-wide">
            Özgeçmişlerim
          </span> */}

          <>
            <button
              type="button"
              className="bg-transparent lg:bg-mains py-2 px-4 flex items-center gap-x-1 lg:gap-x-2"
              onClick={() => {
                setIsCvOpen(true);
              }}
            >
              <BsPlus className="text-mains lg:text-white text-base lg:text-xl" />
              <span className="text-mains lg:text-white text-xs lg:text-lg poppins-medium tracking-wide">
                {isDefaultEnglish ? "Türkçe" : "İngilizce"} Özgeçmiş Ekle
              </span>
            </button>
          </>
        </div>
      )}
    </div>
  );
};

export default MyCv;
