import React, { useContext, useEffect, useState } from "react";
import ApplicationCard from "../../../../components/common/application-card-for-ad-details";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AdvertService } from "../../../../services/advert-services";
import { addOrUpdateQueryParam } from "../../../../hooks/useAddOrUpdateQueryParam";
import { useFormatDate } from "../../../../hooks/useFormatDate";
import SearchServices from "../../../../services/search-services";
import { FastSearchItem } from "./components/fast-search-item";
import ExpandableText from "./components/expandable-text";
import SuspenseContainer from "./components/suspense";
import PaginationMobile from "./components/mobile-pagination";
import { RiErrorWarningLine } from "react-icons/ri";
import { TbBellRinging2 } from "react-icons/tb";
import { useSelector } from "react-redux";
import Axios from "../../../../services/axios";
import { Collapse } from "antd";
import { Pagination } from "../../../../components/isbul-ui/pagination";
import { JobsPageContext } from "../index";
import PaginationWeb from "./components/pagination";
import SkeletonContainer from "./components/loader-skeleton";
import AlarmSaveModal from "./components/alarm-save-modal/AlarmSaveModal";

const items = [
  {
    key: "1",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        isbul.net’e üye olmak ücretli mi?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        isbul.net’e üye olmak tamamen ücretsizdir.
      </p>
    ),
  },
  {
    key: "2",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Özgeçmiş ne demek?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Özgeçmiş, bir kişinin eğitim, iş deneyimi, beceriler, ilgi alanları ve
        kişisel bilgiler gibi önemli bilgilerini içeren bir belge veya
        dokümandır. Bu belge, genellikle bir iş başvurusu veya akademik başvuru
        sırasında sunulur ve kişinin yeteneklerini, deneyimlerini ve
        niteliklerini potansiyel işverenlere veya eğitim kurumlarına tanıtmak
        amacıyla kullanılır.
      </p>
    ),
  },
  {
    key: "3",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        isbul.net kurumsal üyelik ücretli mi?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Sitemizden ücretsiz şekilde kurumsal üyelik oluşturabilirsiniz.
      </p>
    ),
  },
  {
    key: "4",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Uygulamayı nasıl indirebilirim?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Uygulamamızı Google Play Store veya App Store'dan kolayca
        indirebilirsiniz.
      </p>
    ),
  },
  {
    key: "5",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        isbul.net’e üye olmadan uygulamayı kullanabilir miyim?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Uygulamanın bütün özelliklerini kullanmak için üyelik yapmanız gerekir.
        Ama üye olmadan da uygulamamızı kullanabilirsiniz.
      </p>
    ),
  },
  {
    key: "6",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        İşverenler özgeçmiş adını görebilir mi?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Hayır, işverenler gönderdiğiniz özgeçmişlere verdiğiniz isimleri
        göremezler.
      </p>
    ),
  },
  {
    key: "7",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Ücretsiz ilan hakkı ne zaman tanımlanır?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Kurumsal üyelerimize her ay 1 adet ücretsiz ilan hakkı tanımlanır.
      </p>
    ),
  },
  {
    key: "8",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Uygulamadan özgeçmiş oluşturabilir miyim?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Evet, isbul.net uygulamamızdan da kolaylıkla özgeçmiş oluşturabilir ve
        başvuru gerçekleştirebilirsiniz.
      </p>
    ),
  },
  {
    key: "9",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Hesabımı siler ve üyeliğimi iptal edersem ne olur?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Hesabınızı istediğiniz zaman silebilir ve üyeliğinizi iptal
        edebilirsiniz. Sonrasında dilediğiniz zaman yeniden üye olmanız gerekir.
      </p>
    ),
  },
  {
    key: "10",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Kaç tane özgeçmiş oluşturabilirim?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Türkçe ve ingilizce olmak üzere sitemizden iki adet cv
        oluşturabilirsiniz.
      </p>
    ),
  },
  {
    key: "11",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        İlanım yayınlanmadı ne yapmam gerekiyor?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        İlanlar editörlerimiz tarafından kontrol edildikten sonra onaylanır.
        İçerik ile ilgili sakıncalı bir durum varsa sizlere mail yoluyla bilgi
        verilir.
      </p>
    ),
  },
  {
    key: "12",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Uygulamayı kullanarak ilan verebilir miyim?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Evet, uygulamamız ile kolayca ilan oluşturabilirsiniz.
      </p>
    ),
  },
  {
    key: "13",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Mail adresimi ve şifremi değiştirebilir miyim?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Evet mail adresinizi ve şifrenizi hesabınızın ayarlar sayfasından
        değiştirebilirsiniz.
      </p>
    ),
  },
  {
    key: "14",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Özgeçmişim olmadan iş başvurusu yapabilir miyim?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Sitemizden özgeçmiş olmadan başvuru yapamazsınız. Başvuru yapmadan önce
        ücretsiz ve hızlı bir şekilde özgeçmiş oluşturabilirsiniz.
      </p>
    ),
  },
  {
    key: "15",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        İlanlar neden onay işleminden geçiyor?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        İlanların güvenilirliğini ve ilandaki hataları tespit edebilmemiz için
        bütün ilanlar editörlerimizin onayından geçmektedir.
      </p>
    ),
  },
  {
    key: "16",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Uygulama ücretsiz mi?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Uygulamamız tamamen ücretsizdir.
      </p>
    ),
  },
  {
    key: "17",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        isbul.net’ten herhangi bir e-posta almak istemiyorum ne yapamam
        gerekiyor?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Hesap ayarlarım veya ayarlarım sayfasından görmek istemediğiniz
        bildirimleri kapatabilirsiniz.
      </p>
    ),
  },
  {
    key: "18",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Önyazı nedir?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        İş başvurusu veya başka bir türdeki başvurunun ekinde sunulan yazılı bir
        mektuptur. Özgeçmişin yanında sunulan bu mektup, başvuru sahibinin
        işverene veya ilgili kuruluşa daha detaylı bir şekilde kendini tanıttığı
        ve neden bu pozisyon veya fırsat için uygun olduğunu açıkladığı bir
        belgedir.
      </p>
    ),
  },
  {
    key: "19",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        İlan nasıl verilir?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        isbul.net’te ilan vermek ücretsizdir. Hemen kurumsal isbul.net sayfasına
        girip üyelik işlemlerinizi tamamladıktan sonra, “İlan Yayınla” başlığı
        adı altında dilediğiniz ilanı ücretsiz olarak yayınlayabilirsiniz.
        İlanınızı yayına alırken, İlan Bilgilerini, ve tercihen Başvuru Soruları
        alanlarını doldurmanız yeterlidir.
      </p>
    ),
  },
  {
    key: "20",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Telefon doğrulaması neden gereklidir?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        İşverenlerin veya iş arayan adayların size güvenli bir şekilde
        ulaşabilmesi için telefon doğrulaması gereklidir.
      </p>
    ),
  },
  {
    key: "21",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Cv nasıl indiriliyor?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Cv indirmek için özgeçmişlerim sayfasındaki işlemler menüsünden cv indir
        seçeneğine tıklayıp cvnizi kolayca indirebilirsiniz.
      </p>
    ),
  },
  {
    key: "22",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        İlan yayınlanma süresi ne kadardır?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        İlanınızın yayında kalma süresi 30 gündür. İstediğiniz takdirde
        ilanınızı daha erken pasif hale getirebilirsiniz.
      </p>
    ),
  },
  {
    key: "23",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Şifre sıfırlama maili gelmiyor ne yapmam gerekir?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Spam kutunuzu kontrol edebilir veya yardım formumuzu doldurarak bize
        ulaşabilirsiniz.
      </p>
    ),
  },
  {
    key: "24",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Referans eklemek neden önemlidir?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Özgeçmişe referans eklemek, iş başvurularında veya diğer profesyonel
        fırsatlarda başvuru sahibinin yeteneklerini ve geçmiş performansını
        doğrulamak amacıyla önemlidir. Referanslar, başvuru sahibinin daha
        önceki işverenleri, yöneticileri veya akademik danışmanları gibi
        kişilerden gelen olumlu geri bildirimleri içerebilir.
      </p>
    ),
  },
  {
    key: "25",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Engelli iş ilanı nasıl verilir?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        İlan yayınlama “İlan Bilgileri” aşamasında, “Engelli Personel”
        kutucuğunu işaretleyebilirsiniz. Bu sayede engelli iş ilanları arasında
        ilanınız listelenir.
      </p>
    ),
  },
  {
    key: "26",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        isbul.net güvenilir mi?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Sitemizde yazılımsal güvenlik kurallarına uyulmaktadir. KVKK kuralları
        gereği tüm bilgileriniz saklanmaktadır.
      </p>
    ),
  },
  {
    key: "27",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Stajlarımı özgeçmişime nasıl ekleyebilirim?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        İş deneyimleri bölümden ekleyip pozisyonlar seçenekleri arasında stajyer
        pozisyonunu seçebilirsiniz.
      </p>
    ),
  },
  {
    key: "28",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        İlan tarihini nasıl güncelleyebilirim?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Firma anasayfasında yer alan ilan veya ilanlarınızın tarihini tek tek
        sağ taraflarında bulunan takvim işaretine tıklayarak iki günde bir
        güncelleyebilirsiniz. Ayrıca birden fazla ilanınızın tarihini
        güncellemek istiyorsanız ilan listenizin en altında bulunan “Tüm İlan
        Tarihlerini Güncelle” sekmesine tıklayarak gerçekleştirebilirsiniz.
      </p>
    ),
  },
  {
    key: "29",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Şirket bilgileri nasıl gizlenir?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        İlan yayınlama “İlan Bilgileri” aşamasında, “Şirket Adı ve Bilgilerini
        Gizle” kutucuğunu işaretleyebilirsiniz. Bu seçeneği tercih ettiğinizde
        ilanınızda firma unvanınız ve logonuz yer almayacaktır.
      </p>
    ),
  },
  {
    key: "30",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Engellilere yönelik ilanlara nasıl ulaşabilirim?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Filtreleme seçeneklerimizden engelli ilanı filtreleme seçeneğine gelip
        “sadece engelli ilanlarını göster” filresini seçebilirsiniz.
      </p>
    ),
  },
  {
    key: "31",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Şifremi unuttum?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Şifre işlemleriniz için, işveren giriş sayfasında bulunan “Şifremi
        Unuttum” butonuna tıklayarak yeni şifrenizi talep edebilirsiniz.
        Mailinize gelen e-postada yer alan "Şifremi Sıfırla" butonuna tıklayarak
        açılan sayfada yeni şifrenizi hemen belirleyebilirsiniz.
      </p>
    ),
  },
  {
    key: "32",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Geçersiz ilanları nereye bildirebilirim?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        İlanın içindeki şikayet et butonundan ekibimiz ile şikayetinizi ve
        sebebini paylaşabilirsiniz.
      </p>
    ),
  },
  {
    key: "33",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Firma telefon ve mail doğrulaması nasıl yapılır?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        isbul.net adresinden “Üyelik Formu” alanını eksiksiz bir şekilde
        doldurup, üyeliğinizi gerçekleştirdikten sonra hesap ayarlarım
        kısmından, cep telefonunuza ve mail adresinize doğrulama kodu gönderip
        doğrulama yapabilirsiniz.
      </p>
    ),
  },
  {
    key: "34",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Şikayet ettiğim firma kim olduğumu bilecek mi?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Şikayet ettiğiniz ilanlar veya firmalarda işveren ile adınız
        paylaşılmaz.
      </p>
    ),
  },
  {
    key: "35",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        Neden özgeçmiş tarihimi güncellemeliyim?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        Özgeçmiş tarihini sıkça güncellemeniz sitemizin aday veri tabanında
        pozisyon, yaş, aranılan özellikler çerçevesinde arama yapan firmaların
        listelerinde yer almanızı sağlayacaktır. Bu sayede siz başvurmasanız
        bile firmalar size ulaşabilecektir. Firmalar genel olarak aday veri
        tabanında arama yaparken yakın tarihlerde güncellenmiş özgeçmişlere
        erişmek isterler. Sıkça özgeçmişinizi güncelleyerek iş bulma şansınızı
        daha da artırabilirsiniz.
      </p>
    ),
  },
  {
    key: "36",
    label: (
      <p
        className="font-poppins poppins-medium text-[18px]"
        style={{ color: "#2253af" }}
      >
        İş ilanına başvuru nasıl yapılır?
      </p>
    ),
    children: (
      <p className="px-16 text-gray-500 font-poppins poppins-regular text-[14px]">
        İş ilanları sekmesinden yeni ve güncel ilanların sıralamasına
        ulaşabilirsiniz. İlanların üzerine tıklayarak ilan detaylarına
        gidebilirsiniz. İlana başvurmak için ilanın yanında bulunan “Başvur”
        butonuna tıklamanız yeterlidir.
      </p>
    ),
  },
];

interface IFastItem {
  title: string;
  id: number;
  type?: string;
}
interface Advert {
  advertId: number;
  advertSlug: string;
  advertTitle: string;
  companyName: string;
  workingTypes: [];
  workingType: string;
  cities: [];
  city: string;
  companyLogo: string;
  createdAt: string;
  isSponsored: boolean;
  advertslug: string;
  advertsData: any;
  is_disabled_employee: boolean;
  is_auth: boolean;
  is_view: boolean;
  is_favorite: boolean;
}
interface PropsType {
  adverts: Advert[];
}

interface FastAd {
  id: number;
  title: string;
  title_en: string;
  slug: string;
  is_individual: number;
  order: number;
  title_view: string | null;
  icon: string | null;
  featured: number;
  description: string | null;
  created_at: string;
  updated_at: string;
}

const AdvertSectionBody: React.FC<{ adverts: any }> = ({ adverts }) => {
  const axios = new Axios();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [fastCities, setFastCities] = useState<IFastItem[]>([]);
  const [fastPositions, setFastPositions] = useState<IFastItem[]>([]);
  const [totalPages, setTotalPages] = useState<number>(12);
  const [fastSearch, setFastSearch] = useState<FastAd[]>([]);

  const advertsList = adverts; //useSelector((state: any) => state.advert.adverts || []);

  const { loading, pageLimit, pagination, setNewSelectedValues } =
    useContext(JobsPageContext);

  useEffect(() => {
    const getCities = async () => {
      const res = await SearchServices.searchServicesCities("", 6);
      setFastCities(
        res.data.cities.map((el: { name: string; id: number }) => ({
          title: el.name,
          id: el.id,
        }))
      );
    };
    getCities();
  }, []);

  useEffect(() => {
    const getFastSearch = async () => {
      const res = await axios.request({
        type: "get",
        endpoint: "cdn/get-most-searched-positions",
      });

      console.log(res?.data?.most_searched_positions);

      setFastSearch(res?.data?.most_searched_positions);
    };
    getFastSearch();
  }, []);

  useEffect(() => {
    const getPositions = async () => {
      const res = await SearchServices.searchServicesPosition("", 10);
      setFastPositions(
        res.data.positions.map((el: { title: string; id: number }) => ({
          title: el.title,
          id: el.id,
        }))
      );
    };
    getPositions();
  }, []);

  useEffect(() => {
    // Sort the advertsList by createdAt date
    const sortedAdverts = [...advertsList].sort((a, b) => {
      const dateA: Date = new Date(a.createdAt);
      const dateB: Date = new Date(b.createdAt);
      if (a.isSponsored && !b.isSponsored) {
        return -1; // a comes before b
      } else if (!a.isSponsored && b.isSponsored) {
        return 1; // b comes before a
      } else {
        return dateB.getTime() - dateA.getTime(); // Sort in descending order
      }
    });
    //setAdverts(sortedAdverts);

    setIsLoading(false);
  }, [advertsList]);

  const uniqueCities: { [key: string]: boolean } = {};

  console.log(fastSearch);

  return (
    <div className="w-full">
      {loading ? (
        <div className="bg-white flex flex-col p-3 gap-y-3 mb-8">
          <SkeletonContainer pageLimit={pageLimit} />
        </div>
      ) : (
        <>
          {advertsList?.length > 0 ? (
            <div className="bg-white flex flex-col p-3 gap-y-3 mb-8">
              {advertsList.map((el: any, i: number) => (
                <ApplicationCard
                  key={i}
                  data={el}
                  isSponsored={el.isSponsored}
                
                />
              ))}
            </div>
          ) : (
            <>
              {/* Masaüstü ilan bulunamadı bölümü */}
              <div
                className="bg-white hidden sm:flex flex-col p-3 py-4 mt-6 rounded-md flex-wrap   border-2  gap-y-3 mb-8"
                style={{ borderColor: "red" }}
              >
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-col w-2/3">
                    <h6 className="text-lg font-medium text-[#2253af]">
                      Aramanıza uygun ilan bulunamadı!
                    </h6>
                    <p className="text-base">
                      Bu aramana uygun bir ilan yayınlanırsa sana haber
                      vermemizi ister misin?
                    </p>
                  </div>
                  <AlarmSaveModal />
                </div>
              </div>

              {/* Mobil ilan bulunamadı bölümü */}
              <div className="flex xl:hidden items-center mx-2 h-20 my-5 lg:my-20 flex-row border border-gray-200 bg-white shadow rounded-lg">
                <div className="h-full flex items-center p-2 bg-[#fe0000] rounded-l-lg">
                  <RiErrorWarningLine className="text-6xl text-white" />
                </div>
                <label className="p-4 font-poppins font-[500]">
                  Aramanıza uygun ilan bulunamadı!
                </label>
              </div>

              <div className="mb-4 mx-4 lg:mx-0">
                <p
                  style={{
                    fontWeight: "bold",
                    marginTop: "20px",
                    fontSize: "18px",
                  }}
                >
                  Doğru sonuçlara ulaşmak için şunlara dikkat etmelisin:
                </p>
                <ul
                  className="ads-lists-alerts-text !list-disc !list-inside"
                  style={{ listStyleType: "circle" }}
                >
                  <li
                    className="job-alerts-li !list-item"
                    style={{
                      listStyleType: "disc",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "6px",
                    }}
                  >
                    Yazım yanlışı yapmadığından emin olmalısın.
                  </li>
                  <li
                    className="job-alerts-li !list-item"
                    style={{
                      listStyleType: "disc",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "6px",
                    }}
                  >
                    Daha genel kullanıma sahip kelimeler kullanabilirsin. (Örn:
                    "HR" yerine "İK" ya da "İnsan Kaynakları")
                  </li>
                  <li
                    className="job-alerts-li !list-item"
                    style={{
                      listStyleType: "disc",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "6px",
                    }}
                  >
                    Daha farklı kelimeler kullanabilirsin. (Örn: "Yazılımcı"
                    yerine "Backend Yazılım Uzmanı")
                  </li>
                  <li
                    className="job-alerts-li !list-item"
                    style={{
                      listStyleType: "disc",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "6px",
                    }}
                  >
                    Kısaltma kullanmak yerine, kelimeleri açık şekilde
                    yazabilirsin. (Örn: "Müh" yerine "Mühendis")
                  </li>
                </ul>
              </div>
            </>
          )}
        </>
      )}

      {/* Desktop pagination */}
      {(pagination
        ? pagination.last_page
          ? pagination.last_page > 1
          : false
        : false) && (
        <div className="hidden xl:block h-12 w-full">
          <PaginationWeb
            pagination={pagination}
            onChange={() => {
              window.scroll(0, 0);
            }}
          />
        </div>
      )}

      {/* Mobile pagination */}
      {(pagination
        ? pagination.last_page
          ? pagination.last_page > 1
          : false
        : false) && (
        <div className="block xl:hidden w-full">
          <PaginationMobile
            pagination={pagination}
            onChange={() => {
              window.scroll(0, 0);
            }}
          />
        </div>
      )}

      <div className="mb-2 font-bold text-2xl my-4">Nerede iş arıyorsunuz?</div>
      <div className="flex gap-x-2 w-full flex-wrap gap-y-2">
        {advertsList.map((ad: any) =>
          ad.cities.map((city: { slug: string; name: string; id: number }) => {
            if (!uniqueCities[city.slug]) {
              uniqueCities[city.slug] = true;
              return (
                <Link
                  key={city.slug}
                  to={`/is-ilanlari?cities=[${city.id}]&lht=${city.name}`}
                >
                  <FastSearchItem title={city.name} id={parseInt(city.slug)} />
                </Link>
              );
            }
            return null;
          })
        )}
      </div>

      <div className="mb-2 font-bold text-2xl my-4">Hızlı aramalar</div>
      <div className="flex gap-x-2 w-full flex-wrap gap-y-2">
        {fastSearch.map((ad: any) => {
          return (
            <Link
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              key={ad.id}
              to={`/is-ilanlari?positions=[${ad.id}]`}
            >
              <FastSearchItem title={ad.title} id={ad.id} />
            </Link>
          );
        })}
      </div>

      <div className="w-full rounded shadow-md items-start mt-4 bg-white">
        <p className="font-poppins poppins-medium text-2xl shadow-md w-full p-3 border border-b">
          Sıkça Sorulan Kısımlar
        </p>

        <div className="mt-4">
          <Collapse
            expandIconPosition="right"
            bordered={false}
            accordion
            items={items}
            className="bg-white custom-collapse"
          />
        </div>
      </div>

      <div className="w-full rounded shadow-md items-start mt-4">
        <ExpandableText
          title="İş İlanları"
          text="Hem mavi yaka hem ara kademede lider bir inan kaynakları platformu olan isbul.net’te her gün birbirinden farklı sektör ve pozisyon için yüzlerce iş ilanı yayınlanmaktadır. Türkiye’nin her yerinden birçok firma ve sektör çeşitliliği nedeniyle mesleğinize yönelik başvuruları sistemimizden gerçekleştirebilirsiniz. isbul.net, büyük ve orta ölçekli birçok firmanın personel ihtiyacına çözüm üretmektedir. Hizmet sektöründen, lojistiğe ve perakendeye kadar birçok sektörde güncel iş ilanlarına ulaşabilirsiniz. Sizlere en uygun ilanlara başvurmak için sisteme üye olabilirsiniz. Yakınımdaki ilanlar özelliğini kullanarak lokasyonunuza en yakın ilanları keşfedebilir ve başvurularınızı gerçekleştirebilirsiniz. isbul.net’e üye olup özgeçmişinizi doldurduktan sonra saniyeler içerisinde iş bulma şansı elde edebilirsiniz. 
            isbul.net, yapay zekâ destekli özel bir aday havuzu sistemi kullandığı için özgeçmişiniz, saniyeler içerisinde personel arayan firmalara gösterilir. Bu sayede iş başvurusu yapmanıza gerek kalmadan dahi iş teklifi alabileceksiniz. Özgeçmişinizi doğru doldurarak adaylar içerisinde öne çıkma imkânı yakalayabilirsiniz. Profil fotoğrafınız, en güncel halinizi yansıtmalı ve özgeçmişinizdeki bilgiler doğru olmalıdır. Bu sayede işe alım uzmanlarının dikkatini çekmeniz son derece mümkündür"
        />
      </div>

      {/* <div className="w-full rounded shadow-md items-start mt-4">
        <ExpandableText
          title="İş İlanları"
          subTitle={[
            {
              title: "İş İlanları",
              text: "Mavi yaka ve ara kademede lider insan kaynakları platformu Eleman.net’te her gün yüzlerce sektör ve pozisyonda iş ilanı yayınlanıyor. Türkiye’nin neresinde olursanız olun, geniş firma ve sektör çeşitliliği sayesinde mesleğinize ve beklentilerinize en uygun iş ilanlarına burada ulaşabilirsiniz. Büyük ve orta ölçekli firmaların acil personel ihtiyaçları için öncelikli tercih ettiği Eleman.net’te, üretimden hizmet sektörüne, lojistikten perakendeye kadar en aktif sektörlerde güncel iş ilanlarına kolayca başvurabilir, size uygun işlerde kısa sürede istihdam edilme fırsatı yakalayabilirsiniz. Üstelik, yakınımdaki iş ilanları özelliği sayesinde konumunuza en yakın iş ilanlarını saniyeler içinde görüntüleyebilirsiniz. Eleman.net’e üye olup özgeçmişinizi aktif hale getirdiğinizde iş bulma şansınız daha fazladır. Eleman.net’e özel yapay zeka destekli aday havuzu sistemi sayesinde Eleman.net’teki özgeçmişiniz, sizin niteliklerinize sahip personel arayan firmaların aday havuzunda gösterilir. Böylece, iş başvuru yapmadan da yeni iş teklifleri alabilirsiniz. Klasik özgeçmişinizin yanı sıra Eleman.net’in video özgeçmiş sistemi sayesinde kendinizi, beklentilerinizi ve mesleki bilgilerini daha iyi ifade edebilir, özgeçmiş değerlendirme sürecinde işe alım uzmanlarının dikkatini çekebilirsiniz.",
            },
            {
              title: "İş İlanları",
              text: "Mavi yaka ve ara kademede lider insan kaynakları platformu Eleman.net’te her gün yüzlerce sektör ve pozisyonda iş ilanı yayınlanıyor. Türkiye’nin neresinde olursanız olun, geniş firma ve sektör çeşitliliği sayesinde mesleğinize ve beklentilerinize en uygun iş ilanlarına burada ulaşabilirsiniz. Büyük ve orta ölçekli firmaların acil personel ihtiyaçları için öncelikli tercih ettiği Eleman.net’te, üretimden hizmet sektörüne, lojistikten perakendeye kadar en aktif sektörlerde güncel iş ilanlarına kolayca başvurabilir, size uygun işlerde kısa sürede istihdam edilme fırsatı yakalayabilirsiniz. Üstelik, yakınımdaki iş ilanları özelliği sayesinde konumunuza en yakın iş ilanlarını saniyeler içinde görüntüleyebilirsiniz. Eleman.net’e üye olup özgeçmişinizi aktif hale getirdiğinizde iş bulma şansınız daha fazladır. Eleman.net’e özel yapay zeka destekli aday havuzu sistemi sayesinde Eleman.net’teki özgeçmişiniz, sizin niteliklerinize sahip personel arayan firmaların aday havuzunda gösterilir. Böylece, iş başvuru yapmadan da yeni iş teklifleri alabilirsiniz. Klasik özgeçmişinizin yanı sıra Eleman.net’in video özgeçmiş sistemi sayesinde kendinizi, beklentilerinizi ve mesleki bilgilerini daha iyi ifade edebilir, özgeçmiş değerlendirme sürecinde işe alım uzmanlarının dikkatini çekebilirsiniz.",
            },
            {
              title: "İş İlanları",
              text: "Mavi yaka ve ara kademede lider insan kaynakları platformu Eleman.net’te her gün yüzlerce sektör ve pozisyonda iş ilanı yayınlanıyor. Türkiye’nin neresinde olursanız olun, geniş firma ve sektör çeşitliliği sayesinde mesleğinize ve beklentilerinize en uygun iş ilanlarına burada ulaşabilirsiniz. Büyük ve orta ölçekli firmaların acil personel ihtiyaçları için öncelikli tercih ettiği Eleman.net’te, üretimden hizmet sektörüne, lojistikten perakendeye kadar en aktif sektörlerde güncel iş ilanlarına kolayca başvurabilir, size uygun işlerde kısa sürede istihdam edilme fırsatı yakalayabilirsiniz. Üstelik, yakınımdaki iş ilanları özelliği sayesinde konumunuza en yakın iş ilanlarını saniyeler içinde görüntüleyebilirsiniz. Eleman.net’e üye olup özgeçmişinizi aktif hale getirdiğinizde iş bulma şansınız daha fazladır. Eleman.net’e özel yapay zeka destekli aday havuzu sistemi sayesinde Eleman.net’teki özgeçmişiniz, sizin niteliklerinize sahip personel arayan firmaların aday havuzunda gösterilir. Böylece, iş başvuru yapmadan da yeni iş teklifleri alabilirsiniz. Klasik özgeçmişinizin yanı sıra Eleman.net’in video özgeçmiş sistemi sayesinde kendinizi, beklentilerinizi ve mesleki bilgilerini daha iyi ifade edebilir, özgeçmiş değerlendirme sürecinde işe alım uzmanlarının dikkatini çekebilirsiniz.",
            },
          ]}
        />
      </div> */}
    </div>
  );
};

export default AdvertSectionBody;
