import React from "react";

type Props = {
  children?: React.ReactNode;
  className?: string;
};

const Card = ({ children, className }: Props) => {
  return (
    <div className={`px-5 py-4 bg-white shadow-sm ${className}`}>
      {children}
    </div>
  );
};

export default Card;
