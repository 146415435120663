import React, { useRef, forwardRef } from "react";
import {
  TbMessageChatbot,
  TbMailForward,
  TbBrandFacebook,
  TbBrandInstagram,
} from "react-icons/tb";
import { RiTwitterXFill } from "react-icons/ri";
import { FiMapPin } from "react-icons/fi";

type ContactInfoProps = {
  mapRef: React.RefObject<HTMLDivElement>;
};
const ContactInfo: React.FC<ContactInfoProps> = ({ mapRef }) => {
  const scrollToMap = () => {
    mapRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="w-full lg:w-1/2 px-4">
      <div className="w-full lg:w-full px-4 mb-6 lg:mb-0">
        <h1 className="text-2xl font-medium mb-2 text-[#2253af]">
          İletişim Bilgilerimiz
        </h1>
        <ul className="list-disc">
          <li
            className="border border-[#2253af] p-2 flex items-center mb-2 rounded py-7 bg-[#f5f5f5] cursor-pointer"
            onClick={scrollToMap}
          >
            <FiMapPin className="text-[#2253af] w-9 h-9 mr-2 ml-4 flex-shrink-0" />
            <span className="text-[#63636c]">
              {" "}
              Yakuplu Mahallesi, Hürriyet Bulvarı No: 1 Skyport Residence 10.
              Kat, 34500 Beylikdüzü/İstanbul{" "}
            </span>
          </li>
          <li className="border border-[#2253af] p-2 flex items-center mb-2 rounded py-7 bg-[#f5f5f5]">
            <TbMessageChatbot className="text-[#2253af] w-9 h-9 mr-2 ml-4 flex-shrink-0" />
            <span className="text-[#63636c]"> (0549) 598 2502 </span>
          </li>
          <li className="border border-[#2253af] p-2 flex items-center mb-2 rounded py-7 bg-[#f5f5f5]">
            <TbMessageChatbot className="text-[#2253af] w-9 h-9 mr-2 ml-4 flex-shrink-0" />
            <span className="text-[#63636c]"> (0212) 452 1604 </span>
          </li>
          <li className="border border-[#2253af] p-2 flex items-center mb-2 rounded py-7 bg-[#f5f5f5]">
            <TbMailForward className="text-[#2253af] w-9 h-9 mr-2 ml-4 flex-shrink-0" />
            <a href="mailto:info@isbul.net" className="text-[#63636c]">
              info@isbul.net
            </a>
          </li>
          {/* Add more list items as needed */}
        </ul>

        {/* Social Media Section */}
        <h2 className="text-2xl font-medium mb-2 mt-8 text-[#2253af]">
          Sosyal Medya
        </h2>
        <ul className="list-none">
          <li className="flex items-center mb-2 group">
            <a
              href="#"
              className="flex items-center no-underline duration-500 ease-in-out"
            >
              <div className="bg-[#79797d] p-2 rounded-full mr-2 flex items-center justify-center group-hover:bg-[#2253af] duration-500 ease-in-out w-10 h-10">
                <TbBrandFacebook className="text-white text-xl" size={24} />
              </div>
              <span className="text-[#79797d] font-medium group-hover:text-[#2253af]">
                Facebook
              </span>
            </a>
          </li>
          <li className="flex items-center mb-2 group">
            <a
              href="#"
              className="flex items-center no-underline transition duration-500 ease-in-out"
            >
              <div className="bg-[#79797d] p-2 rounded-full mr-2 flex items-center justify-center group-hover:bg-[#2253af] transition duration-500 ease-in-out w-10 h-10">
                <TbBrandInstagram className="text-white text-2xl" />
              </div>
              <span className="text-[#79797d] font-medium group-hover:text-[#2253af]">
                Instagram
              </span>
            </a>
          </li>
          <li className="flex items-center mb-2 group">
            <a
              href="#"
              className="flex items-center no-underline transition duration-500 ease-in-out"
            >
              <div className="bg-[#79797d] p-2 rounded-full mr-2 flex items-center justify-center group-hover:bg-[#2253af] transition duration-500 ease-in-out w-10 h-10">
                <RiTwitterXFill className="text-white text-2xl" />
              </div>
              <span className="text-[#79797d] font-medium group-hover:text-[#2253af]">
                Twitter
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default ContactInfo;
