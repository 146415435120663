import { ButtonHTMLAttributes, MouseEvent } from "react"
import classNames from "classnames"
import { FaCircle } from "react-icons/fa6"





type itemPropsType = ButtonHTMLAttributes<HTMLButtonElement> & {
    selected?: boolean
}







export default function Item({
    type = 'button',
    className,
    children,
    selected,
    onClick,
    ...props
}: itemPropsType) {

    const cn = classNames({
        'p-2 text-start flex flex-row items-center gap-2 hover:bg-gray-100 transition-all': true
    })


    return(
        <button
        {...props}
        type={type}
        className={`${cn} ${className}`}
        onClick={((e) => itemOnClick(e))}
        >
            <div className="flex items-center flex-shrink-0 justify-center w-4 h-4 rounded-full border border-blue-600">
                {selected? (
                    <FaCircle className="text-blue-600 text-[10px]"/>
                ): null}
            </div>
            {children}
        </button>
    )

    function itemOnClick(e: MouseEvent<HTMLButtonElement>) {
        if (onClick) {
            onClick(e)
        }
    }
}