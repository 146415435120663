import { QueryFunction, useQuery } from "@tanstack/react-query";
import services from "../services/search-services";
import {
  IName,
  ITitle,
  IExperienceLevel,
  IStatus,
} from "../containers/company-page-container/candidate-pool-page/types";

interface IFetchData {
  name: string;
  name_tr: string;
}

type ResponseType =
  | IName[]
  | ITitle[]
  | IExperienceLevel[]
  | IStatus[]
  | IFetchData[];

const fetchCdn: QueryFunction<ResponseType, string[]> = async ({
  queryKey,
}) => {
  const query = queryKey[1];
  const dataName = queryKey[2];

  let searchTerm = queryKey[3] ?? " ";
  const limit = queryKey[4] ?? "20";
  const include = queryKey[5] ?? "";

  if (query === "get-cities") {
    searchTerm = searchTerm + "&except_ids=[7795]";
  }
  if (include !== "") {
    searchTerm = searchTerm + include;
  }

  const res = await services.searchServicesLimitCustom(
    `${query}`,
    searchTerm,
    limit
  );

  if (!res.data) return [] as ResponseType;

  const response = res?.data[dataName] || [];

  return response;
};

export default function useFetchCdnQuery(
  storeName: string,
  query: string,
  dataName: string,
  searchTerm?: string,
  limit?: string,
  include?: string
) {
  return useQuery({
    queryKey: [
      storeName,
      query,
      dataName,
      searchTerm ?? " ",
      limit ?? "20",
      include ?? "",
    ],
    queryFn: fetchCdn,
    staleTime: Infinity,
  });
}
