import { useState, useEffect } from "react";

interface IDataProps {
  value: number;
  label: string;
}

interface RadioProps {
  data: IDataProps[];
  header?: string;
  onChange?: (value: number) => void;
  selected?: number;
  keyString: string;
  id: string;
}

function RadioComponent({
  data,
  header,
  onChange,
  selected,
  keyString,
  id,
}: RadioProps) {
  const handleChange = (item: any) => {
    onChange && onChange(item.id);
  };

  useEffect(() => {
    //!selected && setSelectedValue({ value: 0, label: "" });
  }, [selected]);

  return (
    <div className="my-0">
      {header && <p className="my-2 text-gray-500">{header}</p>}
      <div className="flex flex-col gap-5 text-gray-600 md:flex-row">
        {data.map((item: any) => (
          <div
            key={id + item.id}
            className="flex items-center justify-start space-x-2 pointer-events-none"
            onClick={() => handleChange(item)}
          >
            <input
              type="radio"
              id={id + item.id}
              value={item[keyString]}
              checked={
                item.id !== 0 ? selected === item.id : selected === undefined
              }
              onChange={() => handleChange(item)}
              className="w-5 h-5 pointer-events-auto"
            />
            <label htmlFor={id + item.id} className="pointer-events-auto">
              {item[keyString]}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

export { RadioComponent };
