import React from "react";
import Breadcrump from "../../../../components/common/profile/breadcrump";
import Main from "./main";

const Body: React.FC = () => {
	return (
		<div className='flex flex-col w-full gap-y-6'>
			<Breadcrump
				type='page'
				pageName='Başvurulan İlanlar'
			/>
			<Main />
		</div>
	);
};

export default Body;
