import { useEffect, useState, createContext, Dispatch, SetStateAction, useRef } from "react"
import Axios from "../../../../../../services/axios"
import { cdnCityType, getCitiesResponseType } from "../../../../../../types"
import CitySelect from "./CitySelect"
import LocationRow from "./LocationRow"
import { v4 as uuidv4 } from 'uuid'
import Swal from "sweetalert2"
import WorkingLocationPopup from "../working-location-popup"
import { MapLocation, fieldErrorType } from "../../../../../../containers/company-page-container/creat-ads-page/advert-create-form"
import { Location } from "../../../../../common/add-create-form/addDetail/index";
import { Location as ContextLocation } from '../../../../../../store/contextApi/ad-api-context/Iget-ad-data'
import { APIProvider, ControlPosition, Map, MapControl, Marker, useMapsLibrary } from '@vis.gl/react-google-maps'
import { googleMapsApiKey } from "../../../../../../constants/enums"
import Button from "../../../../../form-components/button"
import { IoClose, IoSearch } from "react-icons/io5"
import MapLocationSelect from "./MapLocationSelect"


export const WorkingLocationsContext = createContext<workingLocationContextType>({
    workingLocations: [],
    setWorkingLocations: () => {},
    cities: [],
})


export interface LocationMap {
	lat: number;
	longitude: number;
}

export type workingLocationType = {
    id: string,
    cityId: number | null,
    ditrictId: number | null
}

export type workingLocationContextType = {
    workingLocations: workingLocationType[],
    setWorkingLocations: Dispatch<SetStateAction<workingLocationType[]>>,
    cities: cdnCityType[],
    isCityError?: boolean,
    isDistrictError?: boolean,
    contextLocations?: ContextLocation[]
}

type mapAutocompleteProps = {
    onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void
}

const axios = new Axios()

interface WorkingLocationProps {
	onLocationChange: (location: Location[]) => void;
	isCityError?: boolean;
	isDistrictError?: boolean;
    cities: cdnCityType[];
    contextLocations?: ContextLocation[];
    initialLat?: number;
    initialLong?: number;
    setFieldErrors?: Dispatch<SetStateAction<fieldErrorType>>
    /* initialSelectedCityIds?: number[];
    initialSelectedDistrictIds?: number[];
    initialSelectedLocationIds?: Location[]; */
}


export default function WorkingLocations({
	isCityError,
	isDistrictError,
	onLocationChange,
	updateMapLocation,
    cities,
    contextLocations,
    initialLat,
    initialLong,
    setFieldErrors
    /* initialSelectedCityIds,
    initialSelectedDistrictIds,
    initialSelectedLocationIds */
}: WorkingLocationProps & {
	updateMapLocation: (location: MapLocation) => void;
}) {

    //console.log('row context locations', contextLocations)
    /* States */
 

    //const mapPlaces = useMapsLibrary('places')

    const [selectedMapLocation, setSelectedMapLocation] = useState<MapLocation>({
        lat: initialLat || 38,
        longitude: initialLong || 33
    })

    const [selectedLocations, setSelectedLocations] = useState<
		{ city_id: number; district_id: number }[]
	>([])
    /* const [searchLocation, setSearchLocation] = useState<string>('')
    const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null) */

    const [workingLocations, setWorkingLocations] = useState<workingLocationType[]>(
        contextLocations?
        contextLocations.map((c) => {
            return {
                id: uuidv4(),
                cityId: c.city.id,
                ditrictId: c.district != null? c.district.id: null
            }
        }): [
            {
                id: uuidv4(),
                cityId: 0,
                ditrictId: 0
            }
        ]
    )
    const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)

    useEffect(() => {
        const locations: Location[] = workingLocations.map((l) => {
            return {
                city: l.cityId || 0,
                district: l.ditrictId || 0
            }
        })
        onLocationChange(locations)
    }, [workingLocations])

    useEffect(() => {
        setSelectedMapLocation({
            lat: initialLat || 38,
            longitude: initialLong || 33
        })
    }, [initialLat, initialLong])


    //FIXME: Harita için ödeme gelince tekrar test edilecek
    const [placeAutocomplete, setPlaceAutocomplete] = useState<google.maps.places.Autocomplete | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const places = useMapsLibrary('places');

    useEffect(() => {
        if (!places || !inputRef.current) return

        const options = {
            fields: ['geometry', 'name', 'formatted_address']
        }

        setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options))
    }, [places, inputRef.current])

    useEffect(() => {
        
        if (!placeAutocomplete) return

        placeAutocomplete.addListener('place_changed', () => {
            console.log('place', placeAutocomplete.getPlace())
        })

    }, [placeAutocomplete])

    useEffect(() => {
        if (isPopupOpen) {
            try {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((loc) => {
                        if (loc) {
                            setSelectedMapLocation({
                                lat: loc.coords.latitude,
                                longitude: loc.coords.longitude
                            })
                        }
                    })

                } else {
                    console.log('Geolocation is not supperted by this browser.')
                }
            } catch (error) {
                console.log(`Geolocation fetch failed. Error: ${error}`)
            }
        }
    }, [isPopupOpen])

    const value: workingLocationContextType = {
        workingLocations,
        setWorkingLocations,
        cities,
        isCityError,
        isDistrictError,
        contextLocations: contextLocations
    }

    const handleLocationUpdate = (location: MapLocation) => {
        updateMapLocation(location);
    };

    const saveLocation = (location: LocationMap) => {
        const newMapLocation = { city_id: location.lat, district_id: location.longitude };
        setSelectedLocations([...selectedLocations, newMapLocation]);
        setIsPopupOpen(false) // Close the popup after saving
        //updateParentComponent(); // Update the parent component if necessary
    };
    return(
        <WorkingLocationsContext.Provider value={value}>
            <div className="flex flex-col gap-2 w-full">
                <div className="flex text-gray-700 font-poppins text-[13px] font-semibold">
                    Çalışma Yeri (Şehir - İlçe) <b className="text-red-500"> *</b>
                </div>
                {/* <LocationRow/> */}
                {workingLocations.map((w, i) => {
                    return(
                        <LocationRow
                        key={`locationRow${w.id}`}
                        workingLocation={w}
                        setFieldErrors={setFieldErrors}
                        index={i}
                        />
                    )
                })}
            </div>
            <div className="flex flex-col lg:flex-row w-full my-4">
                <div className="flex w-full ">
                    {workingLocations.length < 5 && (
                        <button 
                        className="text-mains font-bold font-poppins text-start p-2"
                        onClick={addLocationRow}
                        >
                            + Bir Çalışma Yeri Daha Ekle
                        </button>
                    )}
                </div>
                {/* <div className="flex w-full">
                    <button className="text-mains font-semibold font-poppins text-start p-2" onClick={() => setIsPopupOpen(true)}>
                        {selectedMapLocation.lat == 38 && selectedMapLocation.longitude == 33?
                        '+ Yeni Konum Ekle':
                        'Konumu Değiştir'
                        }
                    </button>
                </div> */}
            </div>
            {isPopupOpen && (
                <MapLocationSelect
                isPopupOpen={isPopupOpen}
                setIsPopupOpen={setIsPopupOpen}
                selectedMapLocation={selectedMapLocation}
                setSelectedMapLocation={setSelectedMapLocation}
                updateMapLocation={updateMapLocation}
                />
            )}
            
        </WorkingLocationsContext.Provider>
    )



    function addLocationRow() {
        if (workingLocations.length == 5) {
            Swal.fire({
                title: 'İşlem yapılamadı',
                text: 'En fazla 5 tane çalışma yeri ekleyebilirsiniz.',
                icon: 'error'
            })

            return
        }

        if (workingLocations[workingLocations.length - 1].cityId == null || workingLocations[workingLocations.length - 1].ditrictId == null) {

            // İki alanda boşsa
            if (workingLocations[workingLocations.length - 1].cityId == null && workingLocations[workingLocations.length - 1].ditrictId == null) {
                Swal.fire({
                    title: 'İşlem yapılamadı',
                    text: 'Lütfen şehir ve ilçe seçiniz.',
                    icon: 'error'
                })
                
                if (setFieldErrors) {
                    setFieldErrors(({ city, district, ...others }) => ({
                        city: true,
                        district: true,
                        ...others
                    }))
                }
                return
            }

            // İl boşsa
            if (workingLocations[workingLocations.length - 1].cityId == null) {
                Swal.fire({
                    title: 'İşlem yapılamadı',
                    text: 'Lütfen şehir seçiniz.',
                    icon: 'error'
                })

                if (setFieldErrors) {
                    setFieldErrors(({ city, ...others }) => ({
                        city: true,
                        ...others
                    }))
                }

                return
            }

            // İlçe boşsa
            if (workingLocations[workingLocations.length - 1].ditrictId == null) {
                Swal.fire({
                    title: 'İşlem yapılamadı',
                    text: 'Lütfen ilçe seçiniz.',
                    icon: 'error'
                })

                if (setFieldErrors) {
                    setFieldErrors(({ district, ...others }) => ({
                        district: true,
                        ...others
                    }))
                }

                return
            }

            
        }

        setWorkingLocations((prev) => [...prev, { id: uuidv4(), cityId: null, ditrictId: null }])

        if (setFieldErrors) {
            setFieldErrors(({ city, district, ...others}) => ({
                city: false,
                district: false,
                ...others
            }))
        }
    }
}


{/* 
//TODO: Eski popup
<WorkingLocationPopup
    onClose={() => setIsPopupOpen(false)}
    onSaveLocation={saveLocation}
    onUpdateLocation={handleLocationUpdate}
    /> */}