import React, { useState } from 'react';

import Body from './contents';

const Main: React.FC = () => {
  return (
    <div className="overflow-x-hidden min-h-screen h-auto justify-between flex flex-col">

      <Body />
    </div>
  );
};

export default Main;
