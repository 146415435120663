import * as React from "react";
import Axios from "../../../services/axios";
import { useCookie } from "../../../hooks/useCookie";
import { CookieName } from "../../../constants/enums";
import { IHeaderCardContents, IHeaderCardItemsSectionProps } from "../types";

const axios = new Axios();

function HeaderCardItemsSection(): React.ReactElement {
  const [headerCardItems, setHeaderCardItems] =
    React.useState<IHeaderCardItemsSectionProps>({
      advert_count: 0,
      active_advert_count: 0,
      right_to_create_advert_count: 0,
      right_to_doping_count: 0,
      right_to_cv_view_count: 0,
      right_to_cv_cuff_day_count: 0,
    });
  const { getCookie } = useCookie();
  const companyUserToken = getCookie(CookieName.CompanyUserToken);
  React.useEffect(() => {
    const fetchHeaderCardItems = async () => {
      try {
        const response = await axios.request({
          type: "get",
          endpoint: "companies/home/get-count-dashboard",
          payload: {
            headers: {
              Authorization: `Bearer ${companyUserToken}`,
            },
          },
        });
        if (response && response.data && response.data.data) {
          setHeaderCardItems(response.data.data);
        }
      } catch (error) {
        throw new Error("Error fetching header card items: " + error);
      }
    };

    fetchHeaderCardItems();
  }, [companyUserToken]);

  const headerCardContents: IHeaderCardContents[] = [
    {
      icon: "/icons/company/ilan.svg",
      title: "Toplam İlan",
      amount: headerCardItems.advert_count,
    },
    {
      icon: "/icons/company/aktifilan.svg",
      title: "Aktif İlan",
      amount: headerCardItems.active_advert_count,
    },
    {
      icon: "/icons/company/kalanilan.svg",
      title: "Kalan İlan",
      amount: headerCardItems.right_to_create_advert_count,
    },
    {
      icon: "/icons/company/roket.svg",
      title: "Doping Hakkı",
      amount: headerCardItems.right_to_doping_count,
    },
    {
      icon: "/icons/company/cv.svg",
      title: "CV  Görüntüleme",
      amount: headerCardItems.right_to_cv_view_count,
    },
    {
      icon: "/icons/company/manset.svg",
      title: "Manşet Gün",
      amount: headerCardItems.right_to_cv_cuff_day_count,
    },
  ];

  return (
    <div className="flex overflow-auto md:overflow-hidden  gap-2 md:grid md:grid-cols-3 md:gap-1 lg:grid-cols-6 ">
      {headerCardContents.map((content, index) => (
        <div
          key={index}
          className="bg-white shadow-lg p-1 md:p-4 md:p-8 rounded-lg flex flex-col gap-y-1 md:gap-y-4 w-[140px] md:w-[180px] md:w-auto  items-center justify-center text-center md:whitespace-nowrap"
        >
          <img className="hidden md:block" src={content.icon} alt="icon" />
          <span className="text-base text-black poppins-regular !break-words !overflow-wrap  w-[140px] md:w-auto">
            {content.title}
          </span>
          <span className=" text-xl md:text-4xl text-mains poppins-semibold mt-2 md:mt-4">
            {content.amount}
          </span>
        </div>
      ))}
    </div>
  );
}
export { HeaderCardItemsSection };
