import ChangePassword from "./change-password";

import ContentTitleInfo from "./content-title";
import BlockCompany from "./block-company";
import PersonalInfo from "./personal-info";
import PersonalPermission from "./personel-permission";
import { CiSearch } from "react-icons/ci";

import { useEffect } from "react";
import { useAppSelector } from "../../../../store/store";
import EmailNotification from "./email-notification";
import { Dispatch } from "@reduxjs/toolkit";
import SmsNotification from "./sms-notification";
import { useState } from "react";

const Contents2 = () => {
  const userInfo = useAppSelector<any>((store: any) => store.userInfo);
  const [isActive, setIsActive] = useState<boolean>(true);
  useEffect(() => {}, [userInfo]);

  return (
    <div className=" bg-[#f5f5f6] flex flex-col gap-2">
      {/* Kişisel bilgiler */}
      <ContentTitleInfo
        headerTitle="Kişisel Bilgiler"
        content="Ad, soyad, e-posta ve telefon bilgilerini düzenleyebilirsin."
        isActive={isActive}
      >
        <PersonalInfo />
      </ContentTitleInfo>
      {/* Kişisel bilgiler */}

      {/* Şifre değiştir */}
      <ContentTitleInfo
        headerTitle="Şifreyi Değiştir"
        content="Şifreni değiştirebilir veya sıfırlayabilirsin."
      >
        <ChangePassword />
      </ContentTitleInfo>
      {/* Şifre değiştir */}

      {/* E-posta bildirimleri */}
      <ContentTitleInfo
        headerTitle="E-posta Bildirimleri"
        content="İsbul.net’ten iş fırsatları ve bilgilendirme e-postaları alma konusunda tercihini belirleyebilirsin."
      >
        <EmailNotification />
      </ContentTitleInfo>
      {/* E-posta bildirimleri */}

      {/* Sms Bildirimleri */}
      <ContentTitleInfo
        headerTitle="SMS Bildirimleri  "
        content="İsbul.net'ten iş fırsatları ve bilgilendirme SMS’leri alma konusunda tercihini belirleyebilirsin."
      >
        <SmsNotification />
      </ContentTitleInfo>
      {/* Sms Bildirimleri */}

      {/* İzinler */}
      <ContentTitleInfo
        headerTitle="İzinler"
        content="İletişim ve özgeçmiş görüntüleme ile ilgili izinlerinizi aşağıdan yönetebilirsiniz."
      >
        <PersonalPermission />
      </ContentTitleInfo>
      {/* İzinler */}

      {/* Engellenen Firmalar */}
      <ContentTitleInfo
        headerTitle="Engellenen Firmalar"
        content="Engellediğiniz firmaların listesine ve engellemek istediğiniz firmaları aşağıdan yönetebilirsiniz."
      >
        <BlockCompany icon={CiSearch} />
      </ContentTitleInfo>

      {/* Engellenen Firmalar */}
    </div>
  );
};

export default Contents2;
