import { IoCloseOutline, IoPrintOutline } from "react-icons/io5";
import { FaFacebook } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { CiShare2 } from "react-icons/ci";
import { IoShareSocialOutline } from "react-icons/io5";

import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
  TwitterIcon,
} from "react-share";

interface modalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  pdfUrl: string;
}

export default function ShareModal({
  showModal,
  setShowModal,
  pdfUrl,
}: modalProps) {
  const title = "CV'me göz atın:";

  return (
    <div>
      {showModal && (
        <>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          <div
            onClick={() => setShowModal(false)}
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div
              // stop propagation of click event
              onClick={(e) => e.stopPropagation()}
              className="relative w-96 my-6 mx-auto max-w-3xl"
            >
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex flex-col items-start justify-between rounded-t">
                  <div className="bg-white absolute rounded-full p-1 top-0 inset-x-0 w-max mx-auto -translate-y-1/2">
                    <div className="bg-blue-primary rounded-full overflow-hidden">
                      <IoShareSocialOutline className="text-white w-[80px] h-[80px] rounded-full p-6" />
                    </div>
                  </div>
                  <h5 className="text-xl text-center font-semibold text-blue-primary mt-16 w-fit mx-auto">
                    Özgeçmişimi Paylaş
                  </h5>
                  <button
                    className=" background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    <IoCloseOutline
                      size={32}
                      className="absolute top-0 right-0 m-2 text-gray-600"
                    />
                  </button>
                </div>
                {/*share body*/}
                <div className="relative p-6 flex-auto">
                  <div className="flex flex-col">
                    <div className="flex justify-around">
                      <div className="flex">
                        <FacebookShareButton url={pdfUrl} className="flex">
                          <FaFacebook size={"30"} color="#2c2cb5" />
                          <span className="ml-2 text-title">Facebook</span>
                        </FacebookShareButton>
                      </div>
                      <div className="flex">
                        <TwitterShareButton
                          url={pdfUrl}
                          title={title}
                          className="flex"
                        >
                          <TwitterIcon size={32} round={true} />
                          <span className="ml-2 text-title">Twitter</span>
                        </TwitterShareButton>
                      </div>
                    </div>
                    <div className="flex justify-around mt-5">
                      <div className="flex">
                        <WhatsappShareButton
                          url={pdfUrl}
                          title={title}
                          className="flex"
                        >
                          <FaWhatsapp size={"30"} color="#25d366" />
                          <span className="ml-2 text-title">Whatsapp</span>
                        </WhatsappShareButton>
                      </div>
                      <div className="flex">
                        <EmailShareButton
                          url={pdfUrl}
                          subject={title}
                          body="Merhaba, bu içeriği seninle paylaşmak istedim:"
                          className="flex"
                        >
                          <CiShare2 size={"30"} color="#777777" />
                          <span className="ml-2 text-title"> E-posta</span>
                        </EmailShareButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
