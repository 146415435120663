import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useCookie } from "../../../../hooks/useCookie";
import { CookieName } from "../../../../constants/enums";
import { useDispatch, useSelector } from "react-redux";
import {
  AddressState,
  getCities,
  getCountries,
  getDistricts,
} from "../../../../store/features/addressSlice";
import { CgSpinner } from "react-icons/cg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Fancybox from "../../../../components/layout/fancybox";
import CustomAccordion from "../../../../components/common/custom-accordion";
import { getCompanyUser } from "../../../../store/features/companyUserSlice";
import { TbCameraPlus } from "react-icons/tb";
import FormField from "../../../../components/common/form-field";
import { FaArrowsRotate } from "react-icons/fa6";
import { FaRegTrashAlt } from "react-icons/fa";
import Axios from "../../../../services/axios";
import { PhotoUploadModal } from "../components/PhotoUploadModal";
import { CustomButton } from "../../../../components/common/custom-button";
import Swal from "sweetalert2";

type Option = {
  label: string;
  value: string;
  id?: number;
  name_tr?: string;
  title?: string;
  slug?: string;
};
type Props = {};

export type formField = {
  label: string;
  name: string;
  required?: boolean;
  type?:
    | "text"
    | "email"
    | "number"
    | "select"
    | "textarea"
    | "editor"
    | "file"
    | "search"
    | "string";
  options?: Option[];
  emailVerified?: boolean;
  limit?: number;
  [key: string]: any; // Index signature
};
const axios = new Axios();
function ProfileForm(props: Props) {
  const [imageUrl, setImageUrl] = useState<string>(
    "/assets/images/company-logo.png"
  );
  const [sectors, setSectors] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isOpen, setIsOpen] = useState(true);

  const { companyUser } = useSelector((state: any) => state.companyUser);
  const { countries, cities, districts } = useSelector(
    (state: { address: AddressState }) => state.address
  );

  const updateProfile = async (values: any) => {
    try {
      await axios.authRequest({
        endpoint: "companies/account-settings/info-update",
        type: "put",
        payload: values,
      });
      toast.success("Profil Güncellendi");
    } catch (error: any) {
      console.log(error);
      toast.error(error.response.data.error);
    }
  };

  const formFields: formField[] = [
    {
      label: "Ad",
      required: true,
      name: "name",
    },
    {
      label: "Soyad",
      required: true,
      name: "surname",
    },
    {
      label: "E-Posta Adresi",
      required: true,
      type: "email",
      name: "email",
      emailVerified: companyUser?.is_email_verified,
    },
    {
      label: "Firma Adı",
      required: true,
      name: "company_name",
      disabled: true,
    },
    {
      label: "Vergi Numarası",
      required: true,
      name: "tax_no",
      disabled: true,
    },
    {
      label: "Vergi Dairesi",
      required: true,
      name: "tax_dep_name",
      disabled: true,
    },
    {
      label: "Cep Telefonu",
      required: true,
      type: "number",
      name: "phone",
      limit: 11,
      phoneVerified: companyUser?.is_phone_verified,
    },
    {
      label: "Sabit Telefon",
      required: true,
      type: "number",
      limit: 11,
      name: "land_phone",
    },
    {
      label: "Firmanın Sektörü",
      required: false,
      type: "search",
      name: "sector",
      options: [{ label: "Seçiniz", value: "" }].concat(
        sectors?.map((sector: any) => ({
          label: sector.label,
          value: sector.value,
        }))
      ),
    },
    {
      label: "Çalışan Sayısı",
      required: false,
      type: "select",
      name: "employee_count",
      options: [
        {
          label: "Yok",
          value: "0",
        },
        {
          label: "1-5",
          value: "1-5",
        },
        {
          label: "6-10",
          value: "6-10",
        },
        {
          label: "11-25",
          value: "11-25",
        },
        {
          label: "26-50",
          value: "26-50",
        },
        {
          label: "51-75",
          value: "51-75",
        },
        {
          label: "76-100",
          value: "76-100",
        },
        {
          label: "+100",
          value: "+100",
        },
      ],
    },
    {
      label: "Posta Kodu",
      required: false,
      name: "plz_code",
    },
    {
      label: "Web Adresi",
      required: false,
      name: "website",
    },
    {
      label: "Ülke",
      required: true,
      type: "select",
      name: "country",
      options: [
        {
          label: "Türkiye",
          value: "1",
        },
      ],
    },
    {
      label: "Şehir",
      required: true,
      type: "select",
      name: "city",
      options: [{ label: "Seçiniz", value: "" }].concat(
        cities.map((city) => ({
          label: city.name,
          value: city.id.toString(),
        }))
      ),
    },
    {
      label: "İlçe",
      required: true,
      type: "select",
      name: "district",
      options: [{ label: "Seçiniz", value: "" }].concat(
        districts?.map((district) => ({
          label: district.name,
          value: district.id.toString(),
        }))
      ),
    },
    {
      label: "Adres",
      required: true,
      type: "textarea",
      name: "address",
    },
    {
      label: "Firma Tanımlama",
      required: true,
      type: "editor",
      name: "detail",
      limit: 100,
    },
    {
      label: "image",
      required: false,
      type: "file",
      name: "logo",
    },
  ];

  const initialValues: { [key: string]: string } = {};

  formFields.forEach((field) => {
    initialValues[field.name] = "";
  });

  const getSectors = async () => {
    try {
      const response = await axios.authRequest({
        endpoint: "cdn/get-sectors",
        type: "get",
        payload: {},
      });
      const sector = response.data.sectors.map(
        (sector: { id: number; title: string }) => ({
          label: sector.title,
          value: sector.id,
        })
      );
      setSectors(sector);
    } catch (error) {
      console.log(error);
    }
  };

  const getUserData = async () => {
    if (!cities.length) {
      await dispatch(getCities(companyUser.country_id));
    }
    if (!districts.length) {
      await dispatch(getDistricts(companyUser.city_id));
      formik.setErrors({});
    }

    console.log(cities);
  };

  const updateLogo = async (image: File) => {
    try {
      const formData = new FormData();
      formData.append("profile_logo", image);
      formData.append("_method", "put");
      const response = await axios.authRequest({
        endpoint: `companies/account-settings/profile-logo/${
          !companyUser ? "create" : "update"
        }`,
        type: "post",
        payload: formData,
      });
      if (response && response.data) {
        await dispatch(getCompanyUser());
        await getUserData();
        toast.success("Logo Güncellendi");
        setImageUrl(URL.createObjectURL(image));
        setModalOpen(false);
        setSelectedFile(null);
      } else {
        Swal.fire({
          icon: "warning",
          title: "Hata",
          text: "Logo güncellenirken bir hata meydana geldi.",
        });
        throw new Error("No data received from the server");
      }
    } catch (error: any) {
      console.log(error);
      setImageUrl("/assets/images/company-logo.png");
      if (error.response && error.response.data) {
        toast.error(error.response.data.error);
        Swal.fire({
          icon: "warning",
          title: "Hata",
          text: "Logo güncellenirken bir hata meydana geldi.",
        });
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  // delete logo
  const [deletingLogo, setDeletingLogo] = useState(false);
  const deleteLogo = async () => {
    try {
      setDeletingLogo(true);
      await axios
        .authRequest({
          endpoint: `companies/account-settings/profile-logo/delete`,
          type: "delete",
          payload: {},
        })
        .then((res: any) => {
          toast.success("Logo Silindi");
        });
      dispatch(getCompanyUser());
      setDeletingLogo(false);
    } catch (error: any) {
      setImageUrl("/assets/images/company-logo.png");
      setDeletingLogo(false);
      console.log(error);
      toast.error(error.response.data.error);
    }
  };

  const dispatch = useDispatch();
  const formValidationSchema = Yup.object().shape(
    formFields.reduce<Record<string, Yup.MixedSchema<any>>>((schema, field) => {
      if (field.required) {
        schema[field.name] = Yup.string()
          .required(`${field.label} Zorunlu`)
          .min(
            field.limit ? field.limit : 0,
            field.limit
              ? `${field.label} en az ${field.limit} karakter olmalı`
              : ""
          );
      } else {
        schema[field.name] = Yup.mixed().nullable();
      }

      // Additional validation for Web Adresi
      if (field.name === "website") {
        schema[field.name] = Yup.string()
          .nullable()
          .test("is-url", "Geçerli bir web adresi giriniz", (value) => {
            if (value) {
              const urlPattern =
                /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
              return urlPattern.test(value);
            }
            return true;
          });
      }

      return schema;
    }, {})
  );

  const formik = useFormik<Record<string, string>>({
    initialValues,
    validationSchema: formValidationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      Object.keys(values).forEach((key) => {
        formik.setFieldTouched(key, false);
      });

      const phoneLength = values.phone.replace(/\D/g, "").length;
      const landPhoneLength = values.land_phone.replace(/\D/g, "").length;

      if (phoneLength < 11 && landPhoneLength < 11) {
        toast.error(
          "Cep telefonu ve sabit telefon numaraları 11 haneden az olamaz. Geçerli numaralar giriniz."
        );
        setSubmitting(false);
        return;
      }

      if (phoneLength < 11) {
        toast.error(
          "Cep telefonu numarası 11 haneden az olamaz. Geçerli bir numara giriniz."
        );
        setSubmitting(false);
        return;
      }

      if (landPhoneLength < 11) {
        toast.error(
          "Sabit telefon numarası 11 haneden az olamaz. Geçerli bir numara giriniz."
        );
        setSubmitting(false);
        return;
      }

      await updateProfile({
        ...values,
        _method: "PUT",
        sector_id: values.sector,
        country_id: values.country,
        city_id: values.city,
        district_id: values.district,
        phone: values.phone.replace(/\D/g, ""),
        land_phone: values.land_phone.replace(/\D/g, ""),
      });
      setSubmitting(false);
    },
  });

  function formatPhoneNumber(phoneNumber: string | number) {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `0(${match[1]}) ${match[2]} ${match[3]} ${match[4]}`;
    }
    return phoneNumber;
  }

  useEffect(() => {
    if (companyUser != null) {
      formFields.forEach((field) => {
        if (field.name === "phone" || field.name === "land_phone") {
          formik.setFieldValue(
            field.name,
            formatPhoneNumber(companyUser[field.name] || "")
          );
        } else {
          formik.setFieldValue(field.name, companyUser[field.name] || "");
        }
      });
      formik.setFieldValue("sector", companyUser.sector_id);
      formik.setFieldValue("country", companyUser.country_id);
      formik.setFieldValue("city", companyUser.city_id);
      formik.setFieldValue("district", companyUser.district_id);
      getUserData();
      //formik.setErrors({});
      if (companyUser.logo) {
        setImageUrl(companyUser.logo);
      }
    }
  }, [companyUser]);

  useEffect(() => {
    getSectors();
    dispatch(getCountries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // remove form errors after load
  useEffect(() => {
    if (companyUser) {
      //formik.setErrors({});
      //formik.setTouched({}, false);
    }
  }, [companyUser]);
  useEffect(() => {
    if (formik.values.city) {
      //formik.setErrors({});
      //formik.setTouched({}, false);
    }
  }, [formik.values.city]);

  // toast formik errors
  const showError = () => {
    if (formik.errors && Object.keys(formik.errors).length > 0) {
      Object.keys(formik.errors).forEach((key) => {
        toast.error(formik.errors[key]);
      });
    }
  };

  return (
    <CustomAccordion
      title="Firma Bilgileri"
      subtitle="Ad, soyad, e-posta ve telefon bilgilerini düzenleyebilirsin."
      isOpen={isOpen}
    >
      <form onSubmit={formik.handleSubmit}>
        {/* Avatar (Profile photo) Start */}
        <div className="flex justify-center">
          <div className="relative">
            <Fancybox
              options={{
                Carousel: {
                  infinite: false,
                },
              }}
            >
              <a data-fancybox="gallery" href={imageUrl}>
                <img
                  src={imageUrl}
                  alt="company-logo"
                  className="w-[100px] h-[100px] object-contain rounded-full border border-black"
                />
              </a>
            </Fancybox>

            <button
              type="button"
              className={`absolute p-1.5 bg-blue-700 rounded-full cursor-pointer 
              text-lg w-7 h-7 flex justify-center items-center text-white border-2 border-white
              ${
                companyUser?.logo ? "-bottom-0 -left-2" : "-bottom-0 -right-2"
              }`}
              onClick={() => setModalOpen(true)}
            >
              {companyUser?.logo ? <FaArrowsRotate /> : <TbCameraPlus />}
            </button>
            {companyUser?.logo && (
              <div
                className="absolute -bottom-0 -right-2 p-1.5 bg-title rounded-full cursor-pointer
                          text-lg w-7 h-7 flex justify-center items-center text-white border-2 border-white"
                onClick={() => {
                  Swal.fire({
                    icon: "warning",
                    title: "İşlemi onaylayın",
                    text: "Firma logosunu silmek istediğinize emin misiniz?",
                    confirmButtonColor: "#2253af",
                    confirmButtonText: "Evet",
                    cancelButtonText: "Hayır",
                    showCancelButton: true,
                    showCloseButton: true,
                    preConfirm: async () => {
                      setImageUrl("/assets/images/company-logo.png");
                      deleteLogo();
                    },
                  });

                  formik.setFieldValue("logo", "");
                }}
              >
                {deletingLogo ? (
                  <CgSpinner className="animate-spin" />
                ) : (
                  <FaRegTrashAlt />
                )}
              </div>
            )}
            <PhotoUploadModal
              isOpen={modalOpen}
              closeModal={() => setModalOpen(false)}
              onFileSelect={(file: any) => console.log(file)}
              onSave={updateLogo}
            />
          </div>
        </div>
        {/* Avatar (Profile photo) End */}
        {/* Form Inputs start */}
        <div className="grid grid-cols-1 md:grid-cols-3 my-6 gap-9">
          {formFields.map((field, index) => {
            if (
              field.type === "editor" ||
              field.type === "textarea" ||
              field.type === "file"
            ) {
              return null;
            }
            return (
              <FormField
                onChange={(e) => {
                  if (field.name === "sector") {
                    formik.setFieldValue(field.name, e);
                  } else {
                    if (field.name === "country") {
                      dispatch(getCities(parseInt(e.target.value)));
                      formik.values.district = "";
                    }
                    if (field.name === "city") {
                      dispatch(getDistricts(parseInt(e.target.value)));
                    }

                    formik.setFieldValue(field.name, e.target.value);
                  }
                }}
                phoneMaskType="phone"
                key={index}
                label={field.label}
                name={field.name}
                type={field.type}
                emailVerified={field.emailVerified}
                phoneVerified={field.phoneVerified}
                options={field.options}
                error={formik.errors[field.name]}
                required={field.required}
                value={formik.values[field.name]}
                invalid={formik.errors[field.name]?.length}
                disabled={field.disabled}
              />
            );
          })}
          <FormField
            className="md:col-span-3"
            value={formik.values.address}
            label="Adres"
            name="address"
            type="textarea"
            invalid={formik.errors.address}
            required={true}
            onChange={(e) => {
              formik.setFieldValue("address", e.target.value);
            }}
          />

          <FormField
            className="md:col-span-3"
            value={formik.values.detail}
            error={formik.errors.detail}
            invalid={formik.errors.detail?.length}
            required={true}
            label="Firma Tanımlama (En az 100 karakter olmalı)"
            name="detail"
            type="editor"
            onChange={(e) => {
              formik.setFieldValue("detail", e.toString());
            }}
          />
        </div>
        {/* Form Inputs end */}
        <div className="col-span-3 flex justify-end">
          <CustomButton
            onClick={() => showError()}
            className="bg-blue-700 px-5 py-2 mt-4 text text-white rounded "
            type="submit"
            isSubmitting={formik.isSubmitting}
          >
            Güncelle
          </CustomButton>
        </div>
      </form>
    </CustomAccordion>
  );
}

export default ProfileForm;
