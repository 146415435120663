import { useEffect, useRef, useState } from "react";
import { IoChevronDown } from "react-icons/io5";

interface AdDetailProps {
  adDetail: string;
}
function AdDetail({ adDetail }: AdDetailProps): React.ReactElement {
  const contentDivRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isExpandActive, setIsExpandActive] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (contentDivRef && contentDivRef.current) {
      const divHeight = contentDivRef.current.clientHeight;
      if (divHeight >= 200) {
        setIsExpandActive(true);
      }
    }
  }, [contentDivRef, adDetail]);

  return (
    <div className="mt-28 md:mt-40 lg:mt-0 font-poppins rounded-sm border-b">
      <h2 className="bg-white md:text-lg shadow-sm py-3 pl-3 font-medium border-b border-[#FFAC15] md:border-gray-200">
        İş Tanımı & Aranan Nitelikler
      </h2>
      <div className="relative">
        <div
          ref={contentDivRef}
          className={`adDetailHTMLContent pr-12 bg-white text-sm md:text-base !leading-[26px] shadow-lg border-t py-3 pl-8 max-w-[896px] text-[#63636c] ${
            isExpandActive
              ? isExpanded
                ? `max-h-max overflow-auto`
                : `max-h-[200px] overflow-hidden`
              : `overflow-auto`
          } transition-all`}
          style={{ overflowWrap: "anywhere" }}
          dangerouslySetInnerHTML={{ __html: adDetail }}
        >
          {/* HTML content from adDetail will be rendered here */}
        </div>
        {isExpandActive && (
          <div
            className={`${
              isExpanded
                ? "flex h-auto bg-white"
                : "absolute h-[100px] bg-gradient-to-t from-white via-white to-transparent"
            } bottom-0 left-0 flex items-end justify-center w-full p-2 `}
          >
            <button
              className="p-2 flex flex-row items-center gap-2 outline-none"
              onClick={() => setIsExpanded((prev) => !prev)}
            >
              {isExpanded ? "Daha Az Göster" : "Devamını Oku"}
              <IoChevronDown
                className={`${isExpanded ? "rotate-180" : ""} transition-all`}
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdDetail;
