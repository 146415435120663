import { useEffect, useState } from "react";
import ForeignLanguageForm from "./components/ForeignLanguageForm";
import { cdnGeneralLanguageType, dbUserLanguageType } from "../../../../../types";
import { getGeneralLanguages } from "../../../../../customHooks/getGeneralLanguages";
import Axios from "../../../../../services/axios";
import { useParams } from "react-router-dom";
import { PiWarningCircle } from "react-icons/pi";
import { useContext } from "react";
import { CvLayoutContext, cvLayoutContextType } from "..";




const axios = new Axios()

function ForeignLanguage() {

  const { isEnglish }: cvLayoutContextType = useContext(CvLayoutContext)

  const params = useParams()
  const cvId = params.slug
  
  //Page states
  const [generalLanguages, setGeneralLanguages] = useState<cdnGeneralLanguageType[]>([])
  const [languages, setLanguages] = useState<dbUserLanguageType[]>([])
  const [languagesLength, setLanguagesLength] = useState<number>(1)
  const [isAddClicked, setIsAddClicked] = useState<boolean>(false)

  async function getAllDatas() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/lang/index?cv_id=${cvId}`,
        type: 'get',
        payload: {}
      })

      if (response && response.data && response.data.languages) {
        setLanguages(JSON.parse(JSON.stringify(response.data.languages)))
        setLanguagesLength(response.data.languages.length)
      } else {
        setLanguages([])
      }
    } catch (error) {
      console.log(`Error: ${error}`)
    }
  }
  useEffect(() => {
    getGeneralLanguages({}).then((data) => setGeneralLanguages(data))
    getAllDatas()
  }, [])
  return (
    <div className="flex flex-col w-full gap-y-2 rounded-md bg-white lg:shadow-md pt-2 pb-4">

      {/* Empty language form */}
      <ForeignLanguageForm
      generalLanguages={generalLanguages}
      language={null}
      getAllDatas={getAllDatas}
      languagesLength={languagesLength}
      isAddClicked={isAddClicked}
      setIsAddClicked={setIsAddClicked}
      />

      {/* Empty message if not language added */}
      {languages.length == 0 && (
        <div className="flex flex-row gap-4 m-4 border border-gray-200 rounded-md">
          <div className="flex p-4 bg-red-600 rounded-l-md items-center justify-center">
            <PiWarningCircle className="text-3xl text-white"/>
          </div>
          <div className="flex flex-col gap-1 py-2 px-1">
            <p className="font-poppins font-semibold text-gray-700 text-sm">
              {isEnglish? "You don't have a foreign language added yet.": 'Henüz eklenmiş bir yabancı diliniz yok.'}
            </p>
            {isEnglish? (
              <p className="font-poppins text-sm text-gray-700 font-semibold">
                You can add new foreign language with
                {' '}
                <button className="text-mains underline" onClick={() => setIsAddClicked(true)}>Add Foreign Language</button>
                {' '}
                button.
              </p>
            ): (
              <p className="font-poppins text-sm text-gray-700 font-semibold">
                <button className="text-mains underline" onClick={() => setIsAddClicked(true)}>Yabancı Dil Ekle</button> butonuna tıklayarak hemen yeni bir yabancı dil ekleyebilirsiniz.
              </p>
            )}
          </div>
        </div>
      )}

      {/* All languages mapping */}
      {languages.map((l) => {
        return(
          <div className="flex flex-col gap-0" key={`lForm${l.id}`}>
            <hr/>
            <ForeignLanguageForm
            language={l}
            generalLanguages={generalLanguages}
            getAllDatas={getAllDatas}
            />
          </div>
        )
      })}
    </div>
  );
}

export default ForeignLanguage;
