














export default function OrangeSection() {
    return(
        <div className="bg-[#de5b2b] py-10">
            <div className="w-full max-w-[1140px] mx-auto">
                <div className="flex flex-col lg:flex-row items-center gap-10 px-4">
                    <h2 className="text-xl text-white text-center">
                        İSBUL.NET&apos;E ÜYE OL AYRICALIKLI İŞVEREN FIRSATLARINDAN YARARLANMAYA BAŞLA
                    </h2>
                    <div className="flex flex-row items-center gap-4">
                        <a href="/is-ilaniari" className="py-2 px-4 bg-white text-sm font-semibold text-gray-600 rounded-xl whitespace-nowrap">
                            İŞ İLANLARI
                        </a>
                        <a href="/isveren/kayit-al" className="py-2 px-4 bg-white text-sm font-semibold text-gray-600 rounded-xl whitespace-nowrap">
                            HEMEN ÜYE OL
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}