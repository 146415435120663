












export default function RegisterSection() {
    return(
        <div className="py-10 bg-white">
            <div className="container mx-auto">
                <div className="max-w-[600px] mx-auto flex flex-col gap-6">
                    <h2 className="text-4xl text-title font-bold text-center">
                        isbul.net&apos;ten Eleman Bulmak Çok Kolay!
                    </h2>
                    <h3 className="text-[#63636c] text-2xl">
                        Hemen üye ol, kolayca ücretsiz ilan ver, hızla gelecek başvurulardan yeni elemanını seç!
                    </h3>
                    <p className="text-[#555555] font-[300] text-justify leading-[1.75] font-poppins">
                        Her ay 3 binden fazla iş arayan, eleman aramak için web sitesine yöneliyor ve her gün 140.000&apos;den fazla başvuru yapıyor.
                    </p>
                    <p className="text-[#555555] font-[300] text-justify leading-[1.75] font-poppins">
                        isbul.net&apos;e üye olmak için saatler harcamana gerek yok! Sadece bir dakika ayırarak ücretsiz üye olabilirsin.
                    </p>

                    <a href="/isveren/kayit-ol" className="mt-20 mx-auto py-6 px-8 border-title border rounded-lg bg-white text-title font-semibold text-xl hover:bg-title hover:text-white transition-all">
                        ŞİMDİ ÜYE OL
                    </a>
                </div>
            </div>
        </div>
    )
}