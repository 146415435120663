import { LabelHTMLAttributes, useContext } from "react"
import { textareaContextType, TextareaContext } from "."
import classNames from "classnames"



type errorPropsType = LabelHTMLAttributes<HTMLLabelElement> & {

}









export default function Error({
    className,
    ...props
}: errorPropsType) {

    const { error }: textareaContextType = useContext(TextareaContext)

    const cn = classNames({
        'text-xs text-red-600 font-poppins': true
    })
    if (error) {
        return(
            <label
            className={`${cn} ${className}`}
            {...props}
            >

            </label>
        )
    } else {
        return null
    }
}