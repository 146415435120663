import React, { useEffect, useRef, useState } from "react";
import { BsSend } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Axios from "../../../../../../services/axios";
import moment from "moment";
import SearchBar from "../search-bar";
import { GrAttachment } from "react-icons/gr";
import { Tooltip } from "react-tooltip";
import { CiSearch } from "react-icons/ci";
import { FaRegTrashAlt, FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import {
  IoCheckmarkCircleOutline,
  IoEye,
  IoInformationCircleOutline,
} from "react-icons/io5";
import { LuCalendarClock, LuLoader2 } from "react-icons/lu";
import { PiDotsThreeVertical } from "react-icons/pi";
import { TbEditCircle } from "react-icons/tb";
import { LiaClipboardListSolid, LiaEditSolid } from "react-icons/lia";
import { HiOutlinePrinter } from "react-icons/hi";
import { AiOutlineFilePdf } from "react-icons/ai";
import { FiSend } from "react-icons/fi";
import { MdOutlineCorporateFare } from "react-icons/md";
import SendInterview from "../../../SendInterview";
import AddInterviewPopup from "../../../../../company-page-container/cv-detail/components/AddInterviewPopup";

const axios = new Axios();

const MessageList: React.FC = () => {
  const [data, setData] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [selected, setSelected] = useState<number[]>([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);

  const usertoken = Cookies.get("userToken");
  const companyUserToken = Cookies.get("companyUserToken");
  const isCompany = !!companyUserToken;

  const navigate = useNavigate();
  const getMessageList = () => {
    setLoading(true);
    setSelected([]);
    if (companyUserToken) {
      axios
        .authRequest({
          type: "get",
          endpoint: `companies/chat/get-chats`,
          payload: {},
        })
        .then((response: any) => {
          setData(response.data.chats);
          setFilteredData(response.data.chats);
          setLoading(false);
        });
    }

    if (usertoken) {
      axios
        .userAuthRequest({
          type: "get",
          endpoint: `user/profilim/chat/get-chats`,
          payload: {},
        })
        .then((response: any) => {
          setData(response.data.chats);
          setFilteredData(response.data.chats);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (search.trim() === "") {
      setFilteredData(data);
    } else {
      setFilteredData(
        data.filter((el: any) => {
          if (companyUserToken) {
            return (
              el.user.name
                .toLowerCase()
                .includes(search.trim().toLowerCase()) ||
              el.user.surname
                .toLowerCase()
                .includes(search.trim().toLowerCase())
            );
          } else {
            return el.company.company_name
              .toLowerCase()
              .includes(search.trim().toLowerCase());
          }
        })
      );
    }
  }, [search]);

  useEffect(() => {
    getMessageList();
  }, []);

  const handleAllDelete = () => {
    Swal.fire({
      title: "Dikkat",
      text: "Bütün mesajları silmek istediğinize emin misiniz? Bu işlem geri alınamaz.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sil",
      cancelButtonText: "Kapat",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let arr = data.map((el: any) => {
          return el.id;
        });
        if (companyUserToken) {
          axios
            .authRequest({
              type: "delete",
              endpoint: `companies/chat/delete-chats?${arr
                .map((el: any) => {
                  return "chat_ids[]=" + el;
                })
                .join("&")}`,
              payload: {},
            })
            .then((response: any) => {
              getMessageList();
              Swal.fire({
                title: "Mesajlar Silindi",
                text: "Mesaj silme işlemi başarılı.",
                icon: "success",
                confirmButtonText: "Tamam",
              });
            })
            .catch(() => {
              Swal.fire({
                title: "Mesajlar Silinemedi",
                text: "Mesaj silme işlemi yapılamadı daha sonra tekrar deneyin",
                icon: "error",
                confirmButtonText: "Tamam",
              });
            });
        }

        if (usertoken) {
          axios
            .userAuthRequest({
              type: "delete",
              endpoint: `user/profilim/chat/delete-chats?${arr
                .map((el: any) => {
                  return "chat_ids[]=" + el;
                })
                .join("&")}`,
              payload: {},
            })
            .then((response: any) => {
              getMessageList();
              Swal.fire({
                title: "Mesajlar Silindi",
                text: "Mesaj silme işlemi başarılı.",
                icon: "success",
                confirmButtonText: "Tamam",
              });
            })
            .catch(() => {
              Swal.fire({
                title: "Mesajlar Silinemedi",
                text: "Mesaj silme işlemi yapılamadı daha sonra tekrar deneyin",
                icon: "error",
                confirmButtonText: "Tamam",
              });
            });
        }
      }
    });
  };

  const handleSelectedDelete = () => {
    Swal.fire({
      title: "Dikkat",
      text: "Seçilen mesajları silmek istediğinize emin misiniz? Bu işlem geri alınamaz.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sil",
      cancelButtonText: "Kapat",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (companyUserToken) {
          axios
            .authRequest({
              type: "delete",
              endpoint: `companies/chat/delete-chats?${selected
                .map((el: any) => {
                  return "chat_ids[]=" + el;
                })
                .join("&")}`,
              payload: {},
            })
            .then((response: any) => {
              getMessageList();
              Swal.fire({
                title: "Mesajlar Silindi",
                text: "Mesaj silme işlemi başarılı.",
                icon: "success",
                confirmButtonText: "Tamam",
              });
            })
            .catch(() => {
              Swal.fire({
                title: "Mesajlar Silinemedi",
                text: "Mesaj silme işlemi yapılamadı daha sonra tekrar deneyin",
                icon: "error",
                confirmButtonText: "Tamam",
              });
            });
        }

        if (usertoken) {
          axios
            .userAuthRequest({
              type: "delete",
              endpoint: `user/profilim/chat/delete-chats?${selected
                .map((el: any) => {
                  return "chat_ids[]=" + el;
                })
                .join("&")}`,
              payload: {},
            })
            .then((response: any) => {
              getMessageList();
              Swal.fire({
                title: "Mesajlar Silindi",
                text: "Mesaj silme işlemi başarılı.",
                icon: "success",
                confirmButtonText: "Tamam",
              });
            })
            .catch(() => {
              Swal.fire({
                title: "Mesajlar Silinemedi",
                text: "Mesaj silme işlemi yapılamadı daha sonra tekrar deneyin",
                icon: "error",
                confirmButtonText: "Tamam",
              });
            });
        }
      }
    });
  };

  const handleSingleDelete = (id: number) => {
    Swal.fire({
      title: "Dikkat",
      text: "Mesajı silmek istediğinize emin misiniz? Bu işlem geri alınamaz.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sil",
      cancelButtonText: "Kapat",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (companyUserToken) {
          axios
            .authRequest({
              type: "delete",
              endpoint: `companies/chat/delete-chats?chat_ids[]=${id}`,
              payload: {},
            })
            .then((response: any) => {
              getMessageList();
              Swal.fire({
                title: "Mesaj Silindi",
                text: "Mesaj silme işlemi başarılı.",
                icon: "success",
                confirmButtonText: "Tamam",
              });
            })
            .catch(() => {
              Swal.fire({
                title: "Mesajlar Silinemedi",
                text: "Mesaj silme işlemi yapılamadı daha sonra tekrar deneyin",
                icon: "error",
                confirmButtonText: "Tamam",
              });
            });
        }

        if (usertoken) {
          axios
            .userAuthRequest({
              type: "delete",
              endpoint: `user/profilim/chat/delete-chats?chat_ids[]=${id}`,
              payload: {},
            })
            .then((response: any) => {
              getMessageList();
              Swal.fire({
                title: "Mesaj Silindi",
                text: "Mesaj silme işlemi başarılı.",
                icon: "success",
                confirmButtonText: "Tamam",
              });
            })
            .catch(() => {
              Swal.fire({
                title: "Mesajlar Silinemedi",
                text: "Mesaj silme işlemi yapılamadı daha sonra tekrar deneyin",
                icon: "error",
                confirmButtonText: "Tamam",
              });
            });
        }
      }
    });
  };

  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isInterviewOpen, setIsInterviewOpen] = useState<boolean>(false);

  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const togglePopup = (index: number) => {
    setIsPopupOpen((prev) => !prev);
    setActiveIndex(index);
  };
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        if (!isInterviewOpen) {
          setIsPopupOpen(false);
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {data.length > 0 && (
        <div className="w-full flex item-center px-4 py-3 justify-end h-16">
          <div className="w-full  h-full border items-center  px-1 gap-x-1 flex rounded-md border-gray-300">
            <CiSearch className="text-gray-500 text-lg" />
            <input
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Mesajlarımda Ara"
              className="w-full pr-2 placeholder-gray-400 text-gray-500 outline-none text-xs poppins-medium"
            />
          </div>
        </div>
      )}

      <div className={"flex justify-between w-full px-4"}>
        {selected.length > 0 ? (
          <div
            onClick={() => {
              handleSelectedDelete();
            }}
            className={
              "flex justify-center items-center py-1 px-2 border border-red-500 text-red-500 gap3 rounded-md cursor-pointer"
            }
          >
            <FaTrash />
            Seçilenleri Sil
          </div>
        ) : (
          <div></div>
        )}

        <div>
          {data.length > 1 && (
            <div
              onClick={() => {
                handleAllDelete();
              }}
              className={
                "flex justify-center items-center py-1 px-4 border border-red-500 text-red-500 gap3 rounded-md cursor-pointer "
              }
            >
              <FaTrash />
              <span className="!ml-2">Tümünü Sil</span>
            </div>
          )}
        </div>
      </div>

      <div className="w-full flex item-center h-auto">
        <div className="flex-col flex gap-y-2 w-full h-auto py-5 px-4">
          {loading ? (
            <LuLoader2 className={"animate-spin"} />
          ) : (
            <>
              {data.length === 0 && <NoMessage />}
              {filteredData.length === 0 && data.length !== 0 && (
                <NoMessageFound />
              )}
            </>
          )}

          {filteredData.map((item: any, index: number) => (
            <div className={"relative"}>
              <div className=" h-auto bg-gray-50 flex w-full justify-between border border-gray-300 transition-all duration-300 easy-in hover:border-purple-400 gap-x-3 py-5 px-3 rounded-md shadow-md  ">
                <div className="flex gap-x-4 w-auto h-auto overflow-hidden">
                  <div className={"flex justify-center items-center"}>
                    <input
                      type={"checkbox"}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e: any) => {
                        let old;
                        if (e.target.checked) {
                          old = [...selected, item.id];
                        } else {
                          old = selected.filter((el: any) => el !== item.id);
                        }
                        setSelected(old);
                      }}
                      checked={selected.includes(item.id)}
                    />
                  </div>
                  <div className="w-auto h-full flex items-center justify-center ">
                    <div className="flex w-14 h-14 border border-gray-300 rounded-sm items-center justify-center ">
                      <Link
                        to={
                          isCompany
                            ? `/isveren/mesajlar/${item.id}`
                            : `/profilim/mesajlar/${item.id}`
                        }
                      >
                        <img
                          className="w-full h-full object-cover"
                          src={
                            isCompany ? item.user.profile : item.company.logo
                          }
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="w-auto h-full flex flex-col  overflow-hidden items-start justify-center">
                    <div className="flex gap-x-1 items-center">
                      <div className="flex items-center">
                        <Link
                          to={
                            isCompany
                              ? `/isveren/mesajlar/${item.id}`
                              : `/profilim/mesajlar/${item.id}`
                          }
                        >
                          <div className="text-sm  poppins-semibold text-mains text-ellipsis">
                            {isCompany
                              ? item.user.name + " " + item.user.surname
                              : item.company.company_name}
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="flex flex-col w-full  gap-x-1 items-start overflow-hidden text-ellipsis">
                      <div
                        className={
                          "grid grid-cols-1 w-full overflow-hidden text-ellipsis"
                        }
                      >
                        <span className="text-xs poppins-medium text-nowrap overflow-hidden text-ellipsis">
                          {item.last_message.message}
                        </span>
                      </div>

                      <div className="text-xs w-full poppins-medium text-nowrap overflow-hidden text-ellipsis">
                        {moment(item.updated_at).format("DD.MM.YYYY")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hidden h-auto justify-between items-center">
                  {isCompany && (
                    <Link
                      to={"/isveren/cv/" + item.user.cv_id}
                      data-tooltip-id="tooltip_bla"
                      data-tooltip-content="CV Görüntüle"
                    >
                      <div className="transition-all px-2 py-2 flex text-xl lg:text-2xl bg-mains text-white rounded-md">
                        <GrAttachment />
                      </div>
                    </Link>
                  )}
                </div>
                {isInterviewOpen && activeIndex === index && (
                  <AddInterviewPopup
                    onClose={() => {
                      setIsInterviewOpen(false);
                    }}
                    id={item.user.id}
                    byPassBlock={true}
                  />
                )}
                <span className="text-xl lg:text-2xl relative flex justify-center items-center">
                  <div
                    onClick={(e) => {
                      togglePopup(index);
                      e.stopPropagation();
                    }}
                  >
                    <button type="button" className={""}>
                      <PiDotsThreeVertical className="text-black text-3xl" />
                    </button>
                  </div>

                  {isPopupOpen && activeIndex === index && (
                    <div
                      ref={divRef}
                      className="absolute -bottom-4 right-0 translate-y-full z-[99]"
                    >
                      <div className="relative shadow-[0_-2px_30px_5px_rgba(26,26,26,0.3)] rounded-b-lg rounded-tl-lg">
                        <div className="triangle-white top-0 -translate-y-full absolute right-0" />
                        <div className="bg-white p-4 flex flex-col gap-2 rounded-b-lg rounded-tl-lg ">
                          <Link
                            to={
                              isCompany
                                ? `/isveren/mesajlar/${item.id}`
                                : `/profilim/mesajlar/${item.id}`
                            }
                            className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap"
                          >
                            <IoEye /> Mesajları Görüntüle
                          </Link>
                          <hr />
                          <Link
                            to={
                              isCompany
                                ? "/isveren/cv/" + item?.user?.cv_id
                                : "/firma/" + item?.company?.company_id
                            }
                            className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap"
                          >
                            {isCompany ? (
                              <GrAttachment />
                            ) : (
                              <MdOutlineCorporateFare />
                            )}{" "}
                            {isCompany ? "Cv Görüntüle" : "Firmayı Görüntüle"}
                          </Link>
                          <hr />
                          {isCompany && item.user.is_visibility && (
                            <>
                              <div
                                onClick={(e) => {                                  
                                  setIsInterviewOpen(true);
                                  e.stopPropagation();
                                }}
                                className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap cursor-pointer"
                              >
                                <LiaClipboardListSolid /> Mülakat Ekle
                              </div>
                              <hr />
                            </>
                          )}

                          <div
                            onClick={() => {
                              handleSingleDelete(item.id);
                            }}
                            className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap"
                          >
                            <button className="flex fex-row items-center text-sm gap-2 text-gray-600 whitespace-nowrap">
                              <FaRegTrashAlt /> Sil
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </span>
              </div>
            </div>
          ))}
        </div>
        <Tooltip id={"tooltip_bla"} />
      </div>
    </>
  );
};

const NoMessage = () => {
  return (
    <div
      className={`flex w-full items-center shadow-lg border-2  border-red-600 bg-white rounded-md `}
    >
      <div className="bg-red-600 w-2/12 lg:w-1/12 h-full items-center justify-center flex">
        <IoInformationCircleOutline className="text-white text-4xl md:text-5xl" />
      </div>

      <div
        className={`flex w-10/12 lg:w-11/12 flex-col h-full px-3 md:px-4 py-2 justify-between   `}
      >
        <div className="w-full truncate flex flex-col">
          <span className="text-black poppins-medium text-xs md:text-lg">
            Mesajınız Yok.
          </span>
        </div>
      </div>
    </div>
  );
};

const NoMessageFound = () => {
  return (
    <div
      className={`flex w-full items-center shadow-lg border-2  border-red-600 bg-white rounded-md `}
    >
      <div className="bg-red-600 w-2/12 lg:w-1/12 h-full items-center justify-center flex">
        <IoInformationCircleOutline className="text-white text-4xl md:text-5xl" />
      </div>

      <div
        className={`flex w-10/12 lg:w-11/12 flex-col h-full px-3 md:px-4 py-2 justify-between   `}
      >
        <div className="w-full truncate flex flex-col">
          <span className="text-black poppins-medium text-xs md:text-lg">
            Aradığınız mesaj bulunamadı.
          </span>
        </div>
      </div>
    </div>
  );
};
export default MessageList;
