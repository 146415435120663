import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import DefaultCv from "./my-cv";
import CvDetails from "./cv-detail";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PaginationData } from "../../index";
import Axios from "../../../../services/axios";
import { bool } from "yup";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
const axios = new Axios();
export type CvPreviev = {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  data: any;
  error: string | null;
  cvViewCount: number;
  isBlock: boolean;
  addNoteShow: boolean;
  setAddNoteShow: React.Dispatch<React.SetStateAction<boolean>>;
  openCvShow: boolean;
  setOpenCvShow: React.Dispatch<React.SetStateAction<boolean>>;
  sendMessageShow: boolean;
  setSendMessageShow: React.Dispatch<React.SetStateAction<boolean>>;
  addFolderShow: boolean;
  setAddFolderShow: React.Dispatch<React.SetStateAction<boolean>>;
  interviewShow: boolean;
  setInterviewShow: React.Dispatch<React.SetStateAction<boolean>>;
  cvHtml: any;
  handlePrint: () => void;
  handleDownloadPdf: () => void;
  fetchIncompleteDetail: () => void;
  handleSendMessageShow: () => void;
  profilePictureShow: boolean;
  setProfilePictureShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CVPreviewContext = React.createContext<CvPreviev>({
  loading: true,
  setLoading: () => {},
  data: {},
  error: null,
  cvViewCount: 0,
  isBlock: true,
  addNoteShow: false,
  setAddNoteShow: () => {},
  openCvShow: false,
  setOpenCvShow: () => {},
  sendMessageShow: false,
  setSendMessageShow: () => {},
  cvHtml: null,
  handlePrint: () => {},
  handleDownloadPdf: () => {},
  fetchIncompleteDetail: () => {},
  addFolderShow: false,
  setAddFolderShow: () => {},
  interviewShow: false,
  setInterviewShow: () => {},
  handleSendMessageShow: () => {},
  profilePictureShow: false,
  setProfilePictureShow: () => {},
});

const Body: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<any>({});
  const [cvViewCount, setCvViewCount] = useState<number>(0);
  const [isBlock, setIsBlock] = useState<boolean>(true);

  const [profilePictureShow, setProfilePictureShow] = useState<boolean>(false);
  const [addNoteShow, setAddNoteShow] = useState<boolean>(false);
  const [openCvShow, setOpenCvShow] = useState<boolean>(false);
  const [sendMessageShow, setSendMessageShow] = useState<boolean>(false);
  const [addFolderShow, setAddFolderShow] = useState<boolean>(false);
  const [interviewShow, setInterviewShow] = useState<boolean>(false);
  const cvHtml = useRef(null);

  const { slug } = useParams();
  const navigate = useNavigate();

  const handleSendMessageShow = () => {
    if (data.chat && !isBlock) {
      navigate("/isveren/mesajlar/" + data.chat.id);
    } else {
      setSendMessageShow(true);
    }
  };

  const fetchIncompleteDetail = async () => {
    Promise.all([
      axios.authRequest({
        type: "get",
        endpoint: "companies/home/get-count-dashboard",
        payload: {},
      }),
      axios.authRequest({
        type: "get",
        endpoint: `companies/cv/${slug}/show?reference_type=Aday Havuzu`,
        payload: {},
      }),
    ])
      .then((response) => {
        setCvViewCount(response[0].data.data.right_to_cv_view_count);
        setData(response[1].data.data);
        setIsBlock(response[1].data.data.user.visibility === 0);
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchIncompleteDetail();
  }, []);

  // handle print
  const handlePrint = useReactToPrint({
    content: () => cvHtml.current,
  });

  // handle download pdf
  const handleDownloadPdf = async () => {
    if (cvHtml.current === null) return;

    const input = cvHtml.current;
    const pdf = new jsPDF("p", "mm", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    const canvas = await html2canvas(input, {
      useCORS: true,
      allowTaint: false,
    });
    const imgData = canvas.toDataURL("image/png");

    const imgProps = pdf.getImageProperties(imgData);
    const imgWidth = pdfWidth;
    const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

    let heightLeft = imgHeight;
    let position = 0;

    pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pdfHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pdfHeight;
    }

    pdf.save(data?.user.name + data?.user.surname + "_CV.pdf");
  };

  const values: CvPreviev = {
    loading,
    setLoading,
    data,
    error,
    cvViewCount,
    isBlock,
    addNoteShow,
    setAddNoteShow,
    openCvShow,
    setOpenCvShow,
    cvHtml,
    handlePrint,
    handleDownloadPdf,
    fetchIncompleteDetail,
    sendMessageShow,
    setSendMessageShow,
    addFolderShow,
    setAddFolderShow,
    interviewShow,
    setInterviewShow,
    handleSendMessageShow,
    profilePictureShow,
    setProfilePictureShow,
  };
  return (
    <CVPreviewContext.Provider value={values}>
      <div className="w-full flex flex-col gap-y-6 ">
        {/* <DefaultCv /> */}
        {/* Pass the PDF generation function to the button component */}
        <CvDetails /> {/* Pass the generated PDF to the CvDetails component */}
      </div>
    </CVPreviewContext.Provider>
  );
};

export default Body;
