/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import { FiMapPin } from "react-icons/fi";
import { FiAlertCircle } from "react-icons/fi";
import { HiOutlineBriefcase } from "react-icons/hi";
import { MdOutlineCalendarMonth } from "react-icons/md";
import AdvertSectionBody from "../ad-cards/idnex";
import Axios from "../../../../../services/axios";
import ApplicationCard from "../../../../../components/common/application-card-for-ad";
import { useFormatDate } from "../../../../../hooks/useFormatDate";
import { useParams } from "react-router-dom";




type similarCompanyType = {
  id: number,
  companyName: string,
  logo: string
}




function clearhtml(html: string) {
  if (!html) return ""; // Check if html is undefined or null
  let metin = html.replace(/<[^>]*>?/gm, "");
  metin = metin.replace(/\s+/g, " ").trim();
  return metin.split("&")[0];
}

const Body: React.FC<any> = ({ companyData }) => {

  const params = useParams()
  const companyId = params.slug
  const axios = new Axios();

  //console.log("COMPANY DATA", companyData);

  const [companyDetail, setCompanyDetail] = React.useState<any>(null);
  const [advertsLength, setAdvertsLength] = React.useState<number>(0);
  const [companyJobs, setCompanyJobs] = React.useState<any>(null);
  const [similarCompanies, setSimilarCompanies] = useState<similarCompanyType[]>([])


  
  const getCompanyDetail = async () => {
    try {
      const response = await axios.request({
        endpoint: `companies/company-detail/${companyData?.company_id}/get-adverts`,
        type: "get",
        payload: "",
      });
      console.log(response.data)
      //console.log("COMPANY DETAIL", response.data);
      setCompanyDetail(response.data);
      setAdvertsLength(response.data.adverts.length);
      console.log(response.data)
      setCompanyJobs(response.data.adverts.map((el: any) => {
        const formattedDate = useFormatDate(el.advert.created_at)
        console.log(el)
        return {
          advertId: el.advert.id || "",
          advertTitle: el.advert.title || "",
          companyName: el.company_detail.company_name || "",
          workingType: el?.working_types[0]?.title || "",
          city: el?.cities[0]?.name || "",
          advertSlug:el.advert.slug,
          companyLogo: el.company_detail.logo,
          createdAt: formattedDate || "",
          is_disabled_employee: el.is_disabled_employee || 0,
          isSponsored:
            Date.now() < new Date(el.advert.doping_end_date).getTime()
              ? true
              : false,
          is_auth: el.is_auth,
          is_view: el.is_view,
          is_favorite: el.is_favorite,
        }
      }))
      //setCompanyJobs(response.data.adverts);

      console.log('response', response.data.adverts)
    } catch (error) {
      console.log("COMPANY DETAIL ERROR", error);
    }
  };

  async function getSimilarCompany() {
    try {
      const response = await axios.request({
        endpoint: `companies/company-detail/${companyId}/get-similar-sector-companies`,
        type: 'get',
        payload: {}
      })

      if (response && response.data && response.data.data) {
        const value = response.data.data
        setSimilarCompanies(value?.map((v: any) => {
          return {
            id: v?.company?.company_detail?.id,
            companyName: v?.company_detail?.company_name,
            logo: v?.company_detail?.logo
          }
        }))
      }
    } catch (error) {
      console.log(`Error: ${error}`)
    }

  }
  const sectorTitle = companyData?.sector_id?.sector?.title;
  //console.log('similar', similarCompanies)

  //console.log('jobs', companyJobs)

  useEffect(() => {
    Promise.all([
      getCompanyDetail(),
      getSimilarCompany()
    ])
  }, []);

  /* useEffect(() => {
    if (companyDetail) {
      console.log('companyDetail', companyDetail)
      getSimilarCompany()
    }
  }, [companyDetail]) */

  /* console.log("COMPANY DETAIL", companyDetail);

  companyDetail?.adverts?.map((advert: any) => {
    advert?.sectors?.map((sector: any) => {
      console.log(sector?.title);
    });
  });

  console.log(companyJobs); */

  return (
    <div className="container mx-auto flex flex-col gap-y-5 mt-4 mb-12">
      {/* 2 Columns */}
      <div className="flex flex-row gap-x-5">
        {/* Three Cards on top each other */}
        <div className="flex flex-col gap-y-5 flex-1">
          {/* First Card */}
          <div className="bg-white shadow rounded-lg mb-12">
            <div className="text-[#2253af] pl-4 rounded-lg border border-gray-300 p-4 text-xl font-medium">
              Hakkında
            </div>
            <div className="text-[#777] pl-4 text-xl mt-4">
              {companyData?.company_name}
            </div>
            <p className="pl-8 mt-4 mb-2">{clearhtml(companyData?.detail)}</p>
          </div>
          {/* Second Card */}
          <div className="bg-white shadow rounded-lg">
            <div className="text-[#2253af] pl-4 rounded-lg border border-gray-300 p-4 text-xl font-medium text-center">
              Firmanın İş İlanları ({advertsLength})
            </div>
            {advertsLength === 0 ? (
              <div className="flex items-center mt-12 mb-12 ml-4 mr-4 shadow-xl">
                <span className="bg-red-600 border border-blue mr-4 h-full text-white pt-3 pb-3 pl-1 pr-1 rounded">
                  <FiAlertCircle size={35} />
                </span>
                <h2>Kayıtlı İlan Bulunamadı</h2>
              </div>
            ) : (
              // Add your logic to display the adverts here
              <div className="pl-3">
                {companyJobs.map((job: any) => {
                  return(
                    <div className="p-2">
                      <ApplicationCard
                      key={job.id}
                      data={job}
                      isSponsored={false}
                      setAdverts={setCompanyJobs}
                      dontShowPopUp={true}
                      />
                    </div>
                  )
                })}
              </div>
            )}
          </div>
          {/* Third Card */}
          <div className="bg-white shadow rounded-lg">
          <div className="text-[#2253af] pl-4 rounded-lg border border-gray-300 p-4 text-xl font-medium text-center flex justify-center items-center gap-1">
              {sectorTitle && <span>{sectorTitle}</span>}
        <span>Sektöründeki Diğer İş İlanları</span>
      </div>
            <AdvertSectionBody advertId={companyData?.company_id}  />
          </div>
        </div>

        {/* Second Column */}
        <div className="hidden md:block w-[28%]">
          <div className="bg-white shadow rounded-lg">
            <div className="text-[#2253af] rounded-lg border border-gray-300 p-4 text-center mb-2">
              İlginizi Çekebilecek Şirketler
            </div>
            {similarCompanies.map((c) => {
              return(
                <div key={`${c.id}`} className="shadow rounded flex item-center ml-2 mr-2 p-4">
                  <img
                    src={c.logo}
                    className="w-[50px] h-[56px] mr-8 rounded"
                  />
                  <div className="text-sm">
                    <h3 className="mb-1 text-[#2253af]">{c.companyName}</h3>
                  </div>
                </div>
              )
            })}
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
