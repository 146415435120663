import React from "react";
import Breadcrump from "../../../../components/common/profile/breadcrump";
import { Link } from "react-router-dom";

// Icon's
import { FiEye, FiFilter, FiTable } from "react-icons/fi";
import { TbBuilding } from "react-icons/tb";

const Body: React.FC = () => {
	const cardData = [
		{
			name: "Kayıtlı İlanlar",
			description: "Kayıtlı ilanlarınızı listeleyebilir ve yönetebilirsiniz.",
			icon: FiTable,
			path: "/profilim/favoriler/liste",
		},
		{
			name: "Takipteki Firmalar",
			description: "Takip ettiğiniz firmaları görüntüleyebilirsiniz.",
			icon: TbBuilding,
			path: "/profilim/follow",
		},
		{
			name: "Kayıtlı Filtreler",
			description: "Kaydettiğiniz filtreleri inceleyebilirsiniz.",
			icon: FiFilter,
			path: "/profilim/kayitli-aramalar",
		},
		{
			name: "Son İncelenen İlanlar",
			description: "En son incelediğiniz ilanları görüntüleyebilirsiniz.",
			icon: FiEye,
			path: "/profilim/son-incelenen-ilanlar",
		},
	];

	return (
		<div className='flex flex-col w-full gap-y-6'>
			<Breadcrump
				type='page'
				pageName='FAVORİLERİM'
			/>
			<div className='w-full bg-white grid grid-cols-1 lg:grid-cols-2 p-5 gap-[30px] rounded-md'>
				{cardData.map((item, index) => (
					<Link
						key={index}
						to={item.path}
						className='p-5 flex flex-col items-center transition-all duration-300 ease-in-out hover:bg-[#EDEDED] rounded-[10px] mb-5'>
						<item.icon
							color='#2253AF'
							size={60}
						/>
						<h1 className='text-title text-center text-xl poppins-medium my-[10px]'>
							{item.name}
						</h1>
						<p className='poppins-light text-center text-sm text-[#545454]'>
							{item.description}
						</p>
					</Link>
				))}
			</div>
		</div>
	);
};

export default Body;
