import React, { useState, useEffect, useRef } from "react";
import { FaAngleUp } from "react-icons/fa";
import { LuLoader2 } from "react-icons/lu";
import { IconChevronDown, IconMapPin } from "@tabler/icons-react";
import usePartialBold from "../../../../../../../customHooks/usePartialBold";
import { isMobile } from "@headlessui/react/dist/utils/platform";
import { useIsMobile } from "../../../../../../../hooks/useIsMobile";

interface SelectProps {
  data: any;
  label: string;
  placeholder?: string;
  blue?: boolean;
  onChange?: (value: any) => void;
  selectedvalue?: any | undefined;
  setSearchData?: (value: string) => void;
  searchData?: string;
  disabled?: boolean;
  characterDeletion?: any;
  isFetching?: boolean;
  keyString: string;
  AccordionContentisOpen?: boolean;
  isRadius?: boolean;
  isCity?: boolean;
}

/* HERE: Search component burada bunu eğitim dormunda kullan */
function SearchComponentHeader({
  disabled,
  data,
  label,
  blue = false,
  placeholder = "Seçiniz",
  onChange,
  selectedvalue,
  setSearchData,
  searchData,
  isFetching,
  keyString,
  AccordionContentisOpen,
  isRadius = false,
  isCity = false,
  characterDeletion,
}: SelectProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [isInputFocus, setIsInputFocus] = useState(false);

  const { isMobile } = useIsMobile();

  const toggleDropdown = () => {
    if (inputRef.current && !isOpen) {
      // inputRef.current.value="";
      inputRef.current.focus();
    }
    setIsOpen(!isOpen);
  };

  const setSearchDataToSelected = () => {
    let title = data.filter((item: any) => item.id === selectedvalue);
    if (title.length < 1) {
      setSearchData && setSearchData("");
      return;
    }
    setSearchData && setSearchData(title[0][keyString]);
  };

  useEffect(() => {
    if (!isInputFocus) {
      if (selectedvalue == 0) {
        setSearchData && setSearchData("");
        return;
      }
      setSearchDataToSelected();
    }
  }, [selectedvalue, data]);

  const handleSelect = (value: any) => {
    if (value.id !== 0) {
      setSearchData && setSearchData(value[keyString]);
    } else {
      setSearchData && setSearchData("");
    }
    onChange && onChange(value);
    setIsOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchData && setSearchData(e.target.value);
  };

  const handleBackspace = () => {
    if (selectedvalue !== undefined) {
      setSearchData && setSearchData("");
    }
    onChange && onChange({ id: 0 });
  };

  return (
    <div
      ref={containerRef}
      className={`relative bg-white px-2 py-2 flex  ${
        AccordionContentisOpen && !isMobile
          ? "w-4/5"
          : !isMobile
          ? "w-2/5"
          : isCity
          ? "w-4/6"
          : ""
      } h-full py-1 pl-6 bg-white items-center`}
    >
      {!isMobile && isCity && (
        <IconMapPin className="w-16 mr-2 text-blue-800" />
      )}
      <input
        className="w-full h-full text-sm text-black placeholder-gray-600 outline-none poppins-regular text-ellipsis"
        ref={inputRef}
        value={searchData}
        placeholder={placeholder}
        type="text"
        onChange={handleInputChange}
        onBlur={() => {
          setIsInputFocus(false);
          //setSearchDataToSelected();
        }}
        onFocus={() => {
          setIsOpen(true);
          setIsInputFocus(true);
        }}
        onKeyDown={(event) => {
          if (event.nativeEvent.key === "Backspace") {
            handleBackspace();
          }
        }}
      />
      {isFetching && <LuLoader2 className={"animate-spin"}></LuLoader2>}

      {isMobile && isCity && <IconMapPin className="w-16 mr-2 text-blue-800" />}

      {isMobile && isCity ? (
        <></>
      ) : (
        <div className={"flex w-[24px] items-center justify-center"}>
          <IconChevronDown
            className={` transition-transform duration-300 transform ${
              isOpen ? "rotate-180" : ""
            }`}
            onClick={(e) => {
              e.stopPropagation();
              toggleDropdown();
            }}
          />
        </div>
      )}

      {isOpen && (
        <div className="absolute -ml-6 bg-white border-2 mt-1 rounded shadow-lg z-50 max-h-60 overflow-y-auto w-full top-[100%]">
          {data.length > 0 ? (
            data
              .filter((el: any) => {
                return el.id !== 7795;
              })
              .map((position: any) => (
                <div
                  key={position.id}
                  onClick={() => handleSelect(position)}
                  className="p-2 hover:bg-blue-500 text-gray-600 hover:text-white cursor-pointer"
                  dangerouslySetInnerHTML={{
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    __html: usePartialBold({
                      boldString: searchData,
                      fullString: position[keyString],
                    }),
                  }}
                />
              ))
          ) : (
            <div className="p-2 text-gray-600">
              {isCity
                ? "Hiçbir Lokasyon Bulunamadı"
                : "Hiçbir Pozisyon Bulunamadı"}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

const handleSearchChange = (
  name: string,
  value: string | undefined,
  arr: any[],
  selectedValues: any[],
  setSelectedValues: (value: any) => void
) => {
  let findId = arr.find((element) => element.label === value)?.value;

  if (name === "sectors" || name === "sections") {
    findId = `[${findId}]`;
  } else {
    findId = `${findId}`;
  }

  const existingIndex = selectedValues.findIndex(
    (element: any) => name in element
  );

  if (existingIndex !== -1) {
    // If the attribute already exists, update its value
    const updatedValues = [...selectedValues];
    updatedValues[existingIndex] = {
      ...updatedValues[existingIndex],
      [name]: findId,
    };

    setSelectedValues(updatedValues);
  } else {
    // If the attribute doesn't exist, add a new entry
    setSelectedValues([...selectedValues, { [name]: findId }]);
  }
};

export { SearchComponentHeader, handleSearchChange };
