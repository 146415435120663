/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import Card from "../content/Card";
import { useCookie } from "../../../../hooks/useCookie";
import { CookieName } from "../../../../constants/enums";
import axios from "axios";
import { useFormatDate } from "../../../../hooks/useFormatDate";
import { CgSpinner } from "react-icons/cg";
import { BsDatabaseDash } from "react-icons/bs";
import { FaBan } from "react-icons/fa";
import Axios from "../../../../services/axios";

type Props = {};

const customAxios = new Axios();
function BlackList({}: Props) {
  const [blackList, setBlacklist] = useState([]);
  const cookies = useCookie();
  const companyUserToken = cookies.getCookie(CookieName.CompanyUserToken);
  // deleting state
  const [deletingState, setDeletingState] = useState({
    state: false,
    id: 0,
  });
  // fetching state
  const [fetching, setFetching] = useState(true);
  const fetchBlacklist = async () => {
    setFetching(true);
    try {
      const response = await customAxios.authRequest({
        type: "get",
        endpoint: "companies/blacklist/get-blacklists",
        payload: {},
      });
      setFetching(false);
      setBlacklist(response.data.company_black_lists);
    } catch (error) {
      setFetching(false);
      console.log(error);
    }
  };
  // remove from list
  const removeFromList = async (id: number) => {
    try {
      setDeletingState({
        state: true,
        id,
      });
      await customAxios.authRequest({
        type: "delete",
        endpoint: "companies/blacklist/remove-to-blacklist",
        payload: {
          data: {
            company_blacklist_id: id,
          },
        },
      });
      setDeletingState({
        state: false,
        id: 0,
      });
      fetchBlacklist();
    } catch (error) {
      setDeletingState({
        state: false,
        id: 0,
      });
      console.log(error);
    }
  };
  useEffect(() => {
    fetchBlacklist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Card>
      <div className="flex flex-col gap-4">
        {blackList.length > 0 ? (
          blackList.map((item: any) => (
            <div
              key={item.id}
              className="flex flex-row items-center gap-4 py-2 w-full bg-white rounded-md shadow p-3 border-2 border-blue-600"
            >
              {item.user.profile ===
              "https://isbull.s3.eu-north-1.amazonaws.com/" ? (
                <>
                  {" "}
                  <img
                    src="https://isbull.s3.eu-north-1.amazonaws.com/default-images/user-profile.png"
                    alt={item.user.name}
                    className="w-16 h-14 object-cover rounded-md"
                  />
                </>
              ) : (
                <>
                  {" "}
                  <img
                    src={item.user.profile}
                    alt={item.user.name}
                    className="w-16 h-14 object-cover rounded-md"
                  />
                </>
              )}

              <div className="w-full flex flex-col justify-start items-start gap-1">
                <span className="w-full text-start">
                  {item.user.name + " " + item.user.surname}
                </span>
                <span className="w-full text-start text-sm text-gray-500">
                  {useFormatDate(item.created_at)}
                </span>
              </div>
              <button
                className="bg-red-700 text-white text-nowrap rounded-md p-2"
                onClick={() => removeFromList(item.id)}
              >
                {deletingState.state && deletingState.id === item.id ? (
                  <CgSpinner className="animate-spin text-2xl" />
                ) : (
                  "Listeden Çıkar"
                )}
              </button>
            </div>
          ))
        ) : fetching ? (
          <div className="flex flex-col justify-center items-center gap-2 h-full">
            <CgSpinner className="animate-spin text-2xl" />
            <span className="text-lg text-gray-500">Liste yükleniyor...</span>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center gap-2 h-full">
            <FaBan className="text-6xl text-gray-500" />
            <span className="text-lg text-gray-500 text-center">
              Listede kayıtlı kullanıcı bulunmamaktadır.
            </span>
          </div>
        )}
      </div>
    </Card>
  );
}

export default BlackList;
