import React from 'react';
import Title from './title';
import ProvincesList from './provinces-list';



const Contents: React.FC = () => {
  return (
   <>
   <Title/>
   <ProvincesList/>
   </>
  );
}; 

export default Contents;