import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FaWhatsapp, FaRegHeart, FaHeart } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { CiFacebook } from "react-icons/ci";
import { FaXTwitter } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import Axios from "../../../../services/axios";
import { CookieName } from "../../../../constants/enums";

const axios = new Axios();
interface CompanyDetail {
  advertId?: number;
  companyDetail:
    | {
        facebook: string;
        logo: string;
        twitter: string;
        whatsapp: string;
      }
    | any;
  title?: string;
  handlApplicationPopupVisableClick: () => void;
  initialIsFavorite?: boolean;
  saved: boolean;
  setSaved: Dispatch<SetStateAction<boolean>>;
  is_apply: any;
  handleApplyChange: () => void;
  email: string | undefined;
}

function ShareButtons({
  advertId,
  companyDetail,
  handlApplicationPopupVisableClick,
  title,
  initialIsFavorite,
  saved,
  is_apply,
  setSaved,
  handleApplyChange,
}: CompanyDetail): React.ReactElement {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const userToken = Cookies.get("userToken");
  const companyUserTokenCookie = Cookies.get(CookieName.CompanyUserToken);
  console.log(is_apply, "is_apply");

  const handleSaveListing = async () => {
    if (loading) return;

    if (!userToken) {
      navigate(`/profilim/giris-yap`); // Redirect if no token
      return;
    }

    if (!advertId) {
      Swal.fire({
        icon: "error",
        title: "Hata",
        text: "İlan numarası bulunamadı",
      });

      return;
    }

    setLoading(true);
    try {
      if (saved) {
        setSaved(false);
        await axios.request({
          endpoint: `adverts/${advertId}/remove-favorite`,
          type: "delete",
        });
        Swal.fire({
          icon: "success",
          title: "Favorilerden Kaldırıldı",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        setSaved(true);
        await axios.request({
          endpoint: `adverts/${advertId}/add-favorite`,
          type: "post",
        });
        Swal.fire({
          icon: "success",
          title: "Favorilere Eklendi",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      setSaved(!saved);
      Swal.fire({
        icon: "error",
        title: "Bir hata oluştu",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    setLoading(false);
  };

  const handleApplyClick = () => {
    if (!userToken) {
      navigate(`/profilim/giris-yap?ilan=${encodeURI(title as string)}`); // Redirect if no token
      return;
    }
    handlApplicationPopupVisableClick(); // Call the application popup handler
  };

  return (
    <div className="flex justify-between border-b py-3 font-poppins bg-white rounded shadow-sm">
      <div className="flex justify-start w-full">
        <div className="flex items-center space-x-4 basis-1/4">
          <span className="md:text-lg text-[#2253af] font-medium pl-3">
            PAYLAŞ:
          </span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${companyDetail?.whatsapp}`}
          >
            <div className="flex items-center justify-center md:h-7 md:w-7 w-5 h-5 rounded-full bg-green-500">
              <FaWhatsapp className="md:text-xl text-white" />
            </div>
          </a>
          <a target="_blank" href={`${companyDetail?.facebook}`}>
            <div className="flex items-center justify-center md:h-7 md:w-7 w-5 h-5 rounded-full bg-[#1057D8]">
              <CiFacebook className="md:text-2xl text-white" />
            </div>
          </a>
          <a target="_blank" href={`${companyDetail?.twitter}`}>
            <div className="flex items-center justify-center md:h-7 md:w-7 w-5 h-5 rounded-full bg-[black]">
              <FaXTwitter className="md:text-xl text-white" />
            </div>
          </a>
          <div className="flex items-center justify-center md:h-7 md:w-7 w-5 h-5 rounded-full bg-[#E47A29]">
            <MdEmail className="md:text-xl text-white" />
          </div>
        </div>

        <div className="lg:flex hidden justify-center items-center basis-3/4 ">
          {userToken && !companyUserTokenCookie && (
            <button
              onClick={handleSaveListing}
              className="flex items-center text-[red] text-lg"
            >
              {saved ? (
                <FaHeart className="mr-2" size={20} color="red" />
              ) : (
                <FaRegHeart className="mr-2" size={20} />
              )}
              {saved ? "Favorilerden Kaldır" : "Favorilere Ekle"}
            </button>
          )}
        </div>
      </div>

      {userToken && !companyUserTokenCookie && (
        <>
          <div className="lg:flex hidden">
            <div className="flex items-center">
              {is_apply ? (
                <>
                  {" "}
                  <a
                    onClick={() => handleApplyChange()}
                    href="#"
                    className="bg-gray-400 hover:bg-gray-500 text-white md:py-3 md:px-8 rounded font-medium mr-3 md:text-xl py-1 px-1 whitespace-nowrap"
                  >
                    BAŞVURUYU GERİ ÇEK
                  </a>
                </>
              ) : (
                <>
                  {" "}
                  <a
                    onClick={handleApplyClick}
                    href="#"
                    className="bg-[#2253af] text-white md:py-3 md:px-8 rounded font-medium mr-3 md:text-xl py-1 px-1 whitespace-nowrap"
                  >
                    İLANA BAŞVUR
                  </a>
                </>
              )}
            </div>
          </div>
        </>
      )}

      {/* {userToken && !companyUserTokenCookie && ( */}
        <div className="fixed bottom-0 left-0 right-0 lg:hidden bg-white shadow-xl h-[60px] flex justify-between items-center z-[1000]">
          <div className="w-1/5 flex justify-center items-center min-w-[80px]">
            <button
              onClick={handleSaveListing}
              className="flex items-center justify-center text-[red] text-lg lg:text-xl min-w-[80px]"
            >
              {saved ? (
                <FaHeart className="mr-2" size={26} color="red" />
              ) : (
                <FaRegHeart className="mr-2" size={26} />
              )}
              <span className="hidden lg:block">
                {saved ? "İlan Kaydedildi" : "İlanı Kaydet"}
              </span>
            </button>
          </div>

          <div className="flex items-center w-4/5 h-full">
            {is_apply ? (
              <>
                {" "}
                <a
                  onClick={() => handleApplyChange()}
                  href="#"
                  className="bg-gray-400 hover:bg-gray-500 text-white md:py-3 font-medium md:text-xl w-full h-full text-center flex justify-center items-center"
                >
                  BAŞVURUYU GERİ ÇEK
                </a>
              </>
            ) : (
              <>
                {" "}
                <a
                  onClick={handleApplyClick}
                  href="#"
                  className="bg-[#146297] text-white md:py-3 font-medium md:text-xl w-full h-full text-center flex justify-center items-center"
                >
                  İLANA BAŞVUR
                </a>
              </>
            )}
          </div>
        </div>
      {/* )} */}
    </div>
  );
}

export default ShareButtons;
