import Body from "./contents";
import SearchBar from "./contents/SearchBar";

export default function Main() {
  return (
    <div className="my-10 container mx-auto">
      <div className="p-6 bg-white rounded-xl">
        <h1 className="text-mains text-xl font-poppins poppins-medium">MÜLAKATLAR</h1>
      </div>
      {/* <div className="w-full items-end justify-end flex my-5">
        <SearchBar />
      </div> */}
      <div>
        <Body />
      </div>
    </div>
  );
}
