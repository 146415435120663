import * as React from "react";
import { CgDanger } from "react-icons/cg";

function AlertContent(): React.ReactElement {
  return (
    <div>
      <div className="flex flex-row w-full mx-auto bg-[#f00] rounded-md md:py-8 shadow-md items-center justify-center md:max-h-[50px]">
        <CgDanger className="text-[#FFFFFF] text-5xl mx-auto" />
        <div className="flex flex-row gap-4 w-full mx-auto bg-white p-2 md:py-8 md:px-8 items-center justify-center md:max-h-[50px] rounded-r-md">
          <span className="flex flex-col gap-y-2 w-full">
            <p className="text-[13px] poppins-semibold md:text-[15px]">
              Satın aldığınız ürünlerin yayınlandıktan sonraki örnek görüntüleri
              aşağıdaki gibidir.
            </p>
          </span>
        </div>
      </div>
    </div>
  );
}
export { AlertContent };
