import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Category from "./category";
import PersonelInfo from "./personel-info";
import DefaultCv from "./my-cv";
import Contact from "./contact";
import JobPrefences from "./job-prefences";
import Reference from "./reference";
import ExamsCertificates from "./exams-certificates";
import Files from "./files";
import WorkExperiences from "./work-experiences";
import EducationInformation from "./education-information";
import SeminarCourse from "./seminar-course";
import AchievementAward from "./achievement-award";
import Hobbies from "./hobbies";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { createContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import CvEditHeader from "./CvEditWrapper";
import SaveFile from "../../files/contents/save-file";
import FilePageComponent from "./files/components/FilePageComponent";
import Axios from "../../../../services/axios";
import { FaCheckCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { dbUserCvMissInfoType } from "../../../../types";



const axios = new Axios()


export type cvLayoutContextType = {
  showCvScore: () => void,
  isEnglish: boolean,
  score: number,
  visibility: 'ALL' | 'APPLY_COMPANY',
  getCvById: () => void,
  missingInfo: dbUserCvMissInfoType[],
  getMissingInfo: () => void
}

export const CvLayoutContext = createContext<cvLayoutContextType>({
  showCvScore: () => {},
  isEnglish: false,
  score: 0,
  visibility: 'ALL',
  getCvById: () => {},
  missingInfo: [],
  getMissingInfo: () => {}
})



const Dashboard: React.FC = () => {

  const params = useParams()
  const cvId = params.slug

  const { isMobile, isTablet } = useIsMobile();

  const [scoreBoardShow, setScoreBoardShow] = useState<boolean>(false)
  const [cvScore, setCvScore] = useState<number>(0)
  const [isEnglish, setIsEnglish] = useState<boolean>(false)
  const [cvVisibility, setCvVisibility] = useState<'ALL' | 'APPLY_COMPANY'>('ALL')
  const [missingInfo, setMissinInfo] = useState<dbUserCvMissInfoType[]>([])

  // CV Score for each update
  async function showCvScore() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv-score`,
        type: 'get',
        payload: {}
      })
  
      if (response && response.data && response.data.data && response.data.data.score) {
        setCvScore((prev) => response.data.data.score)
        setScoreBoardShow(true)
      }
    } catch (error) {
      console.log(`Error: ${error}`)
    }
  }

  const value: cvLayoutContextType = {
    showCvScore,
    isEnglish,
    score: cvScore,
    visibility: cvVisibility,
    getCvById,
    missingInfo,
    getMissingInfo: getMissInfo
  }

  useEffect(() => {

    function removeScoreBoard() {
      setScoreBoardShow(false)
    }
    const timeOut = setTimeout(removeScoreBoard, 5000)

    return () => {
      clearTimeout(timeOut)
    }
  }, [scoreBoardShow])

  async function getCvById() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv?cv_id=${cvId}`,
        type: 'get',
        payload: {}
      })

      if (response && response.data && response.data.data.lang) {

        if (response.data.data.visibility) {
          if (response.data.data.visibility == 'APPLY_COMPANY') {
            setCvVisibility('APPLY_COMPANY')
          } else {
            setCvVisibility('ALL')
          }
        }

        if (response.data.data.score) {
          setCvScore(response.data.data.score)
        }
        if (response.data.data.lang == 'en') {
          setIsEnglish(true)
        } else {
          setIsEnglish(false)
        }
      }
    } catch (error) {
      console.log(`CV fetch failed. Error: ${error}`)
    }
  }

  async function getMissInfo() {
    try {
      const response = await axios.userAuthRequest({
        endpoint: `user/profilim/cv/miss-info/${cvId}`,
        type: 'get',
        payload: {}
      })

      if (response && response.data && response.data.data) {
        console.log('response', response.data.data)
        setMissinInfo(response.data.data)
      }
    } catch (error) {
      console.log(`Error: ${error}`)
    }
  }

  useEffect(() => {
    getCvById()
    getMissInfo()
  }, [])


  return (
    <CvLayoutContext.Provider value={value}>
      <div className="flex flex-col gap-y-3 lg:my-5 mx-auto container">

        {/* Cv Score popup */}
        {scoreBoardShow && (
          <div className="flex flex-row items-center bg-white border-2 border-green-600 fixed top-10 right-2 lg:right-5 px-2 py-3 rounded-2xl w-[calc(100%-16px)] lg:w-[300px] z-[51]">
            <div className="border-l-2 border-l-green-600 p-2">
              <FaCheckCircle className="text-2xl text-green-600"/>
            </div>
            <div className="flex flex-col gap-1 w-full">
              <label className="text-xs">{isEnglish? 'Succesfully complete': 'Başarılı'}</label>
              <div className="flex h-6 w-full rounded-full border border-gray-200 bg-white">
                <div 
                className="flex bg-green-600 min-w-10 text-xs rounded-full text-white px-2 font-poppins items-center justify-center"
                style={{
                  width: `${cvScore}%`
                }}
                >
                  % {cvScore}
                </div>
              </div>
            </div>
          </div>
        )}
        {!isMobile && !isTablet && <DefaultCv />}

        <div className="grid grid-cols-12 gap-3 mx-auto w-full">
          {!isMobile && !isTablet && <Category />}

          <div className={`col-span-12 lg:col-span-9 ${isMobile ? "bg-white" : ""} h-auto`}>
            {isMobile || isTablet ? <CvEditHeader /> : null}
            <Routes>
              <Route path="/" element={<PersonelInfo />} />
              <Route path="/iletisim-bilgileri" element={<Contact />} />
              <Route path="/is-tercihleri" element={<JobPrefences />} />
              <Route path="/referanslar" element={<Reference />} />
              <Route
                path="/sinavlar-sertifikalar"
                element={<ExamsCertificates />}
              />
              {/* Sayfa değiştirildi <Route path="/dosyalar" element={<Files />} /> */}
              <Route path="/dosyalar" element={<FilePageComponent/>} />
              <Route path="/is-deneyimleri" element={<WorkExperiences />} />
              <Route
                path="/egitim-bilgileri"
                element={<EducationInformation />}
              />
              <Route path="/seminer-kurs-egitimler" element={<SeminarCourse />} />
              <Route
                path="/basari-odul-ve-burslar"
                element={<AchievementAward />}
              />
              <Route path="/hobiler" element={<Hobbies />} />
              <Route path="/*" element={<Navigate to="/profilim/cv" />} />
            </Routes>
          </div>
        </div>
      </div>
    </CvLayoutContext.Provider>
  );
};

export default Dashboard;
