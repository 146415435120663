import { useEffect, useState } from "react"
import Axios from "../../../services/axios"
import { cdnCityType, cdnCountryType, cdnDistrictType, dbPersonalInformationType, getPersonalInformationResponseType } from "../../../types"
import { useForm, SubmitHandler } from "react-hook-form"
import { Button, SelectDropdown, SelectSearchDropdown } from "../../../components/isbul-ui"
import { useYears } from "../../../customHooks/useYears"
import RadioButtonField from "../../../components/form-components/radio-button"
import { getCities } from "../../../customHooks/getCities"
import { getDistricts } from "../../../customHooks/getDistricts"
import { getCountries } from "../../../customHooks/getCountries"
import Swal from "sweetalert2"
import { IoChevronForward, IoPerson } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import { MdAddAPhoto } from "react-icons/md"
import { FaTrashAlt } from "react-icons/fa"
import ProfilePhotoChanger from "../../../components/pages/MyResumes/CreateCV/PersonalInformation/components/ProfilePhotoChanger"










const axios = new Axios()


type payloadDataType = {
    birth_day: string | null,
    birth_month: string | null,
    birth_year: string | null,
    gender_id: number | null,
    country_id: number | null,
    city_id: number | null,
    district_id: number | null
}

type monthType = {
    id: number,
    label: string,
    maxDays: number
}


const months: monthType[] = [
    { id: 1, label: 'Ocak', maxDays: 31 },
    { id: 2, label: 'Şubat', maxDays: 28 },
    { id: 3, label: 'Mart', maxDays: 31 },
    { id: 4, label: 'Nisan', maxDays: 30 },
    { id: 5, label: 'Mayıs', maxDays: 31 },
    { id: 6, label: 'Haziran', maxDays: 30 },
    { id: 7, label: 'Temmuz', maxDays: 31 },
    { id: 8, label: 'Ağustos', maxDays: 31 },
    { id: 9, label: 'Eylül', maxDays: 30 },
    { id: 10, label: 'Ekim', maxDays: 31 },
    { id: 11, label: 'Kasım', maxDays: 30 },
    { id: 12, label: 'Aralık', maxDays: 31 },
] 






export default function WelcomePersonalInformation() {

    const navigate = useNavigate()

    const days = useYears({ startYear: 31, endYear: 1 }).sort((a, b) => a - b);
    const years = useYears({ startYear: new Date().getFullYear() - 16, endYear: new Date().getFullYear() - 70 })

    // General states
    const [countries, setCountries] = useState<cdnCountryType[]>([])
    const [filteredCountries, setFilteredCountries] = useState<cdnCountryType[]>([])
    const [cities, setCities] = useState<cdnCityType[]>([])
    const [districts, setDistricts] = useState<cdnDistrictType[]>([])
    const [searchCountry, setSearchCountry] = useState<string>('')
    const [searchCity, setSearchCity] = useState<string>('')
    const [searchDistrict, setSearchDistrict] = useState<string>('')
    const [avatarUrl, setAvatarUrl] = useState<string>("https://isbull.s3.eu-north-1.amazonaws.com/default-images/user-profile.png?v=2.9.90")
    const [avatarModalOpen, setAvatarModalOpen] = useState<boolean>(false)
    const [personalInformation, setPersonalInformation] = useState<dbPersonalInformationType | null>(null)

    // Form states
    const [selectedDay, setSelectedDay] = useState<number | null>(null)
    const [selectedMonth, setSelectedMonth] = useState<monthType | null>(null)
    const [selectedYear, setSelectedYear] = useState<number | null> (null)
    const [selectedGender, setSelectedGender] = useState<string>('')
    const [selectedCountry, setSelectedCountry] = useState<cdnCountryType| null>({
        id: 1,
        name_en: 'Turkey',
        name_tr: 'Türkiye',
        iso: 'TR',
        iso3: 'TUR',
        phone_code: 90,
        lcole: 792,
        updated_at: ''
    })
    const [selectedCity, setSelectedCity] = useState<cdnCityType | null>(null)
    const [selectedDistrict, setSelectedDistrict] = useState<cdnDistrictType | null>(null)

    async function getAllDatas() {
        Promise.all([
            getPersonalInformation(),
            getCountriesFunction(),
            getUserPersonalInformation()
        ])
    }


    async function getPersonalInformation() {
        try {
            const response = await axios.userAuthRequest({
                endpoint: 'user/profilim/welcome/setup',
                type: 'get',
                payload: {}
            })

            console.log('response', response)
            if (response && response.data) {
                const value = response.data

                if (value.completed1 && !value.completed2) {
                    //navigate('/profilim/hosgeldin/kisisel-bilgiler')
                    alert('ileri')
                }
            }
        } catch (error) {
            console.log(`Error: ${error}`)
        }
    }

    async function getCountriesFunction() {
        const response = await getCountries({})
        setCountries(response)
        setFilteredCountries(response)
    }

    async function getUserPersonalInformation() {
        try {
          const endpoint = "user/profilim/cv/personal/index";
    
          const res = await axios.userAuthRequest({
            endpoint,
            type: "get",
            payload: {},
          });
    
          const response: getPersonalInformationResponseType = JSON.parse(
            JSON.stringify(res)
          );
    
          const personalInformationData:
            | dbPersonalInformationType
            | undefined
            | null = response.data.data;
    
          if (personalInformationData) {
            setPersonalInformation(personalInformationData)
            setAvatarUrl(personalInformationData?.profile_photo || "https://isbull.s3.eu-north-1.amazonaws.com/default-images/user-profile.png?v=2.9.90")
          } else {
            setPersonalInformation(null)
            setAvatarUrl("https://isbull.s3.eu-north-1.amazonaws.com/default-images/user-profile.png?v=2.9.90")
          }
        } catch (error) {
          console.log(error);
          setPersonalInformation(null)
          setAvatarUrl("https://isbull.s3.eu-north-1.amazonaws.com/default-images/user-profile.png?v=2.9.90")
        }
    }


    useEffect(() => {
        getAllDatas()
    }, [])

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getFieldState,
        clearErrors
    } = useForm<payloadDataType>()

    const onSubmit: SubmitHandler<payloadDataType> = ((data) => {
        updatePersonalInformation(data)
    })

    /* Search events */
    // Country search
    useEffect(() => {
        async function search() {
            if (searchCountry.trim().length > 0) {
                const filter = await getCountries({ search: searchCountry })
                console.log('filter', filter)
                setFilteredCountries(filter)
            } else {
                setFilteredCountries(countries)
            }
        }

        const timeOut = setTimeout(search, 500)

        return () => {
            clearTimeout(timeOut)
        }
    }, [searchCountry])

    // City search
    useEffect(() => {
        async function search() {
            if (searchCity.trim().length > 0) {
                const filter = await getCities({ country_id: selectedCountry?.id, except_ids: [83, 7795], search: searchCity })
                setCities(filter)
            } else {
                const filter = await getCities({ country_id: selectedCountry?.id, except_ids: [83, 7795]})
                setCities(filter)
            }
        }

        const timeOut = setTimeout(search, 500)

        return () => {
            clearTimeout(timeOut)
        }
    }, [searchCity])

    // District search
    useEffect(() => {
        async function search() {
            if (searchDistrict.trim().length > 0) {
                const filter = await getDistricts({ city_id: selectedCity?.id, search: searchDistrict })
                setDistricts(filter)
            } else {
                const filter = await getDistricts({ city_id: selectedCity?.id })
                setDistricts(filter)
            }
        }

        const timeOut = setTimeout(search, 500)

        return () => {
            clearTimeout(timeOut)
        }
    }, [searchDistrict])

    /* Custom registers */
    // Birth day
    register('birth_day', {
        required: 'Doğum Günü'
    })

    // Birth month
    register('birth_month', {
        required: 'Doğum Ayı'
    })

    // Birty year
    register('birth_year', {
        required: 'Doğum Yılı'
    })

    // Gender
    register('gender_id', {
        required: 'Cinsiyet'
    })

    // Country
    register('country_id', {
        required: 'Yaşadığınız Ülke'
    })

    // City
    register('city_id', {
        required: 'Yaşadığınız Şehir'
    })

    // District
    register('district_id', {
        required: selectedCountry?.id == 1 ? 'Yaşadığınız İlçe' : false
    })

    /* Set form fields */
    // Birth day
    useEffect(() => {
        if (selectedDay) {
            if (selectedDay < 10) {
                setValue('birth_day', `0${selectedDay}`)
            } else {
                setValue('birth_day', selectedDay.toString())
            }
        } else {
            setValue('birth_day', null)
        }

        clearErrors('birth_day')
    }, [selectedDay])

    // Birth month
    useEffect(() => {
        if (selectedMonth) {
            if (selectedMonth.id < 10) {
                setValue('birth_month', `0${selectedMonth.id.toString()}`)
            } else {
                setValue('birth_month', selectedMonth.id.toString())
            }
        } else {
            setValue('birth_month', null)
        }

        clearErrors('birth_month')
    }, [selectedMonth])

    // Birth year
    useEffect(() => {
        if (selectedYear) {
            setValue('birth_year', selectedYear.toString())
        } else {
            setValue('birth_year', null)
        }

        clearErrors('birth_year')
    }, [selectedYear])

    // Gender
    useEffect(() => {
        if (selectedGender == 'Erkek') {
            setValue('gender_id', 1)
        } else if (selectedGender == 'Kadın') {
            setValue('gender_id', 2)
        } else if (selectedGender == 'Belirtilmemiş') {
            setValue('gender_id', 3)
        }
        clearErrors('gender_id')
    }, [selectedGender])

    // Country
    useEffect(() => {
        setValue('country_id', selectedCountry?.id?? null)

        setSelectedDistrict(null)
        setSelectedCity(null)
        if (selectedCountry) {
            getCities({ country_id: selectedCountry.id, except_ids: [83, 7795] }).then((data) => {
                setCities(data)
            })
        } else {
            setCities([])
            setDistricts([])
        }

        clearErrors('country_id')
    }, [selectedCountry])

    // City
    useEffect(() => {
        setValue('city_id', selectedCity?.id?? null)

        setSelectedDistrict(null)

        if (selectedCity) {
            getDistricts({ city_id: selectedCity.id }).then((data) => {
                setDistricts(data)
            })
        } else {
            setDistricts([])
        }

        clearErrors('city_id')
    }, [selectedCity])

    // District
    useEffect(() => {
        setValue('district_id', selectedDistrict?.id?? null)
        
        clearErrors('district_id')
    }, [selectedDistrict])

    function onError() {
        let errorList = Object.entries(errors).map(([type, message]) => {
            return message.message
        })

        //console.log('errorList', errorList)
        if (errorList && errorList.length > 0) {

            if (errorList.includes('Doğum Günü') && errorList.includes('Doğum Ayı') && errorList.includes('Doğum Yılı')) {
                errorList = errorList.filter((e) => e != 'Doğum Günü')
                errorList = errorList.filter((e) => e != 'Doğum Ayı')
                errorList = errorList.filter((e) => e != 'Doğum Yılı')
                errorList.push('Doğum Tarihi')
            }
            const htmlMessage = `
            <div>
            Eksik Alanlar: <br/>
                <p class="text-red-600">
                    ${errorList.join('<br/>')}
                </p>
            </div>
            `

            Swal.fire({
                icon: 'warning',
                title: 'Uyarı',
                html: htmlMessage
            })
        } else {
            const birthDayError = getFieldState('birth_day').error?.message
            const birthMonthError = getFieldState('birth_month').error?.message
            const birthYearError = getFieldState('birth_year').error?.message
            const genderError = getFieldState('gender_id').error?.message
            const countryError = getFieldState('country_id').error?.message
            const cityError = getFieldState('city_id').error?.message
            const districtError = getFieldState('district_id').error?.message

            Swal.fire({
                icon: 'warning',
                title: 'Uyarı',
                html: `
                <div>
                Eksik Alanlar: <br/>
                <p class="text-red-600">
                ${birthDayError ? `${birthDayError} <br/>` : ''}
                ${birthMonthError ? `${birthMonthError} <br/>` : ''}
                ${birthYearError ? `${birthYearError} <br/>` : ''}
                ${genderError ? `${genderError} <br/>` : ''}
                ${countryError ? `${countryError} <br/>` : ''}
                ${cityError ? `${cityError} <br/>` : ''}
                ${districtError ? `${cityError} <br/>` : ''}
                </p>
                </div>
                `
            })
        }
    }


    return(
        <div className="flex flex-col bg-white rounded-md gap-y-8">
            <span className="poppins-semibold text-sm text-mains py-4 border-b border-mains px-4 w-full">
                Kişisel Bilgiler
            </span>
            <form className="flex flex-col gap-y-6 p-6" autoComplete="off" onSubmit={handleSubmit(onSubmit, onError)}>
                
                {/* Profile photo section */}
                <div className="relative mx-auto">
                    <div className="flex w-36 h-36 rounded-full border-2 border-mains items-center justify-center bg-gray-200">
                    {personalInformation?.profile_photo ? (
                        <img
                        src={avatarUrl}
                        className="w-full h-full aspect-square rounded-full object-contain"
                        />
                    ) : (
                        <IoPerson className="flex text-7xl text-mains" />
                    )}
                    </div>
                    {avatarUrl !=
                    "https://isbull.s3.eu-north-1.amazonaws.com/default-images/user-profile.png?v=2.9.90" ? (
                    <button
                        type="button"
                        className="flex items-center justify-center absolute bottom-0 right-0 bg-mains border border-white w-8 h-8 rounded-full"
                        onClick={() => {
                        Swal.fire({
                            icon: "warning",
                            title: `Profil fotoğrafınızı kaldırmak istediğinizden emin misiniz?`,
                            preConfirm: () => {
                            deleteProfilePhoto();
                            },
                            confirmButtonText: `Evet`,
                            cancelButtonText: `Hayır`,
                            showCancelButton: true,
                        });
                        }}
                    >
                        <FaTrashAlt className="text-white" />
                    </button>
                    ) : (
                    <button
                        type="button"
                        className="flex items-center justify-center absolute bottom-0 right-0 bg-mains border border-white w-8 h-8 rounded-full"
                        onClick={() => setAvatarModalOpen(true)}
                    >
                        <MdAddAPhoto className="text-white" />
                    </button>
                    )}
                </div>
                {/* Profile photo popup */}
                {avatarModalOpen && (
                    <ProfilePhotoChanger
                    getAllDatas={() => getAllDatas()}
                    setIsModalOpen={setAvatarModalOpen}
                    lang={"tr"}
                    />
                )}
                <div className="flex flex-col lg:flex-row items-center gap-6">

                    {/* Birthday select */}
                    <div className="flex flex-row items-center gap-2 w-full">
                        <div className="flex flex-col gap-1 w-full">
                            <SelectDropdown.Label>Doğum Tarihi <b className="text-red-600">*</b></SelectDropdown.Label>
                            <div className="flex flex-row w-full gap-2 lg:gap-4">
                                {/* Day */}
                                <SelectDropdown
                                className="w-full"
                                mode={selectedDay? 'dirty': 'empty'}
                                error={errors.birth_day?.message? true: false}
                                >
                                    <SelectDropdown.Trigger>
                                        {selectedDay ? selectedDay : 'Gün'}
                                    </SelectDropdown.Trigger>
                                    <SelectDropdown.Items>
                                        {days.filter((d) => {
                                            if (!selectedMonth) {
                                                return d
                                            } else {
                                                return d <= selectedMonth.maxDays
                                            }
                                        }).map((d) => {
                                            return(
                                                <SelectDropdown.Item
                                                key={`d${d}`}
                                                onClick={() => setSelectedDay(d)}
                                                >
                                                    {d}
                                                </SelectDropdown.Item>
                                            )
                                        })}
                                    </SelectDropdown.Items>
                                </SelectDropdown>

                                {/* Month */}
                                <SelectDropdown
                                className="w-full"
                                mode={selectedMonth ? 'dirty' : 'empty'}
                                error={errors.birth_month?.message ? true : false}
                                >
                                    <SelectDropdown.Trigger>
                                        {selectedMonth?.label?? 'Ay'}
                                    </SelectDropdown.Trigger>
                                    <SelectDropdown.Items>
                                        {months.map((m) => {
                                            return(
                                                <SelectDropdown.Item
                                                key={`m${m.id}`}
                                                onClick={() => setSelectedMonth(m)}
                                                >
                                                    {m.label}
                                                </SelectDropdown.Item>
                                            )
                                        })}
                                    </SelectDropdown.Items>
                                </SelectDropdown>

                                {/* Year */}
                                <SelectDropdown
                                className="w-full"
                                mode={selectedYear ? 'dirty' : 'empty'}
                                error={errors.birth_year?.message ? true : false}
                                >
                                    <SelectDropdown.Trigger>
                                        {selectedYear?? 'Yıl'}
                                    </SelectDropdown.Trigger>
                                    <SelectDropdown.Items>
                                        {years.map((y) => {
                                            return(
                                                <SelectDropdown.Item
                                                key={`y${y}`}
                                                onClick={() => setSelectedYear(y)}
                                                >
                                                    {y}
                                                </SelectDropdown.Item>
                                            )
                                        })}
                                    </SelectDropdown.Items>
                                </SelectDropdown>
                            </div>
                        </div>
                    </div>

                    {/* Gender select */}
                    <div className="flex w-full">
                        <RadioButtonField
                        value={selectedGender}
                        setValue={setSelectedGender}
                        required
                        error={errors.gender_id?.message ? true : false}
                        >
                        <div className="flex flex-col gap-1 py-1">
                            <RadioButtonField.Label>
                                Cinsiyet
                            </RadioButtonField.Label>
                            <div className="flex flex-row flex-wrap gap-x-6 gap-y-2">
                                <RadioButtonField.RadioButton
                                    label="Erkek"
                                    radioValue="Erkek"
                                />
                                <RadioButtonField.RadioButton
                                    label="Kadın"
                                    radioValue="Kadın"
                                />
                                <RadioButtonField.RadioButton
                                    label="Belirtilmemiş"
                                    radioValue="Belirtilmemiş"
                                />
                            </div>
                        </div>
                        </RadioButtonField>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row items-center gap-6">
                    {/* Country select */}
                    <SelectSearchDropdown
                    className="w-full"
                    required
                    mode={selectedCountry ? 'dirty' : 'empty'}
                    error={errors.country_id?.message ? true : false}
                    >
                        <SelectSearchDropdown.Label>
                            Yaşadığınız Ülke
                        </SelectSearchDropdown.Label>
                        <SelectSearchDropdown.Trigger
                        onClick={async () => {
                            if (searchCountry.length == 0 && filteredCountries.length == 0) {
                                const res = await getCountries({})
                                setCountries(res)
                                setFilteredCountries(res)
                            }
                        }}
                        >
                            <SelectSearchDropdown.Value>
                                {selectedCountry?.name_tr?? 'Seçiniz'}
                            </SelectSearchDropdown.Value>
                            <SelectSearchDropdown.Input
                            placeholder="Arama yapınız"
                            value={searchCountry || ''}
                            onChange={((e) => {
                                setSearchCountry(e.target.value)
                            })}
                            />
                        </SelectSearchDropdown.Trigger>
                        <SelectSearchDropdown.Items>
                            {filteredCountries.map((c) => {
                                return(
                                    <SelectSearchDropdown.Item
                                    key={`co${c.id}`}
                                    onClick={() => setSelectedCountry(c)}
                                    >
                                        {c.name_tr}
                                    </SelectSearchDropdown.Item>
                                )
                            })}
                        </SelectSearchDropdown.Items>
                    </SelectSearchDropdown>

                    {/* City select */}
                    <SelectSearchDropdown
                    className="w-full"
                    mode={selectedCity ? 'dirty' : 'empty'}
                    required
                    error={errors.city_id?.message ? true : false}
                    >
                        <SelectSearchDropdown.Label>
                            Yaşadığınız Şehir
                        </SelectSearchDropdown.Label>
                        <SelectSearchDropdown.Trigger
                        onClick={async () => {
                            if (!selectedCountry) {
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'Uyarı',
                                    text: 'Lütfen yaşadığınız ülkeyi seçiniz.'
                                })

                                return
                            } else {
                                if (searchCity.length == 0 && cities.length == 0) {
                                    const res = await getCities({ country_id: selectedCountry.id, except_ids: [83, 7795] })
                                    setCities(res)
                                }
                            }
                        }}
                        >
                            <SelectSearchDropdown.Value>
                                {selectedCity?.name?? 'Seçiniz'}
                            </SelectSearchDropdown.Value>
                            <SelectSearchDropdown.Input
                            placeholder="Arama yapınız"
                            value={searchCity || ''}
                            onChange={((e) => {
                                setSearchCity(e.target.value)
                            })}
                            />
                        </SelectSearchDropdown.Trigger>
                        <SelectSearchDropdown.Items>
                            {cities.map((c) => {
                                return(
                                    <SelectSearchDropdown.Item
                                    key={`ci${c.id}`}
                                    onClick={() => setSelectedCity(c)}
                                    >
                                        {c.name}
                                    </SelectSearchDropdown.Item>
                                )
                            })}
                        </SelectSearchDropdown.Items>
                    </SelectSearchDropdown>

                    {/* District select */}
                    {selectedCountry && selectedCountry.id == 1 ? (
                        <SelectSearchDropdown
                        className="w-full"
                        required
                        mode={selectedDistrict ? 'dirty' : 'empty'}
                        error={errors.district_id?.message ? true : false}
                        >
                            <SelectSearchDropdown.Label>
                                Yaşadığınız İlçe
                            </SelectSearchDropdown.Label>
                            <SelectSearchDropdown.Trigger
                            onClick={async () => {
                                if (!selectedCity) {
                                    Swal.fire({
                                        icon: 'warning',
                                        title: 'Uyarı',
                                        text: 'Lütfen yaşadığınız şehri seçiniz.'
                                    })

                                    return
                                } else {
                                    if (searchDistrict.length == 0 && districts.length == 0) {
                                        const res = await getDistricts({ city_id: selectedCity.id, search: searchDistrict })
                                        setDistricts(res)
                                    }
                                }
                            }}
                            >
                                <SelectSearchDropdown.Value>
                                    {selectedDistrict?.name?? 'Seçiniz'}
                                </SelectSearchDropdown.Value>
                                <SelectSearchDropdown.Input
                                placeholder="Arama yapınız"
                                value={searchDistrict || ''}
                                onChange={((e) => {
                                    setSearchDistrict(e.target.value)
                                })}
                                />
                            </SelectSearchDropdown.Trigger>
                            <SelectSearchDropdown.Items>
                                {districts.map((d) => {
                                    return(
                                        <SelectSearchDropdown.Item
                                        key={`di${d.id}`}
                                        onClick={() => setSelectedDistrict(d)}
                                        >
                                            {d.name}
                                        </SelectSearchDropdown.Item>
                                    )
                                })}
                            </SelectSearchDropdown.Items>
                        </SelectSearchDropdown>
                    ): (
                        <div className="w-full">

                        </div>
                    )}
                </div>
                <hr/>
                <div className="flex">
                    <Button
                    type="submit"
                    size="sm"
                    className="ml-auto"
                    >
                        Kaydet ve Devam Et
                        <Button.Icon>
                            <IoChevronForward/>
                        </Button.Icon>
                    </Button>
                </div>
            </form>
        </div>
    )

    async function updatePersonalInformation(data: payloadDataType) {
        try {
            const response = await axios.userAuthRequest({
                endpoint: 'user/profilim/welcome/personal-info',
                type: 'post',
                payload: data,
            })

            if (response && response.data && response.data.status_code == 200) {
                navigate('/profilim/hosgeldin/is-tercihleri')
            } else {
                Swal.fire({
                    icon: 'warning',
                    title: 'Uyarı',
                    text: 'Beklenmedik bir hata meydana geldi.'
                })
            }
        } catch (error) {
            console.log(`Error: ${error}`)
            Swal.fire({
                icon: 'warning',
                title: 'Uyarı',
                text: 'Beklenmedik bir hata meydana geldi.'
            })
        }
    }

    async function deleteProfilePhoto() {

    }
}