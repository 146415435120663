import { InputHTMLAttributes } from "react"
import { useContext } from "react"
import { InputAreaContext, inputAreaContextType } from "."
import classNames from "classnames"



type inputPropsType = InputHTMLAttributes<HTMLInputElement> & {

}







export default function Input({
    className,
    onChange,
    ...props
}: inputPropsType) {

    const { mode, setValue, error }: inputAreaContextType = useContext(InputAreaContext)

    const cn = classNames({
        'py-[6px] px-2 border outline-none rounded-md focus:shadow-form-glow focus:border-blue-500 line-clamp-1 text-ellipsis overflow-hidden whitespace-nowrap': true,
        'border-gray-400 text-gray-600': mode == 'empty',
        'border-blue-500 text-blue-500': mode == 'dirty',
        'border-red-600 text-red-600 bg-red-50': error
    })
    return(
        <input
        className={`${cn} ${className} `}
        onChange={((e) => {
            if (onChange) {
                onChange(e)
            }
            setValue(e.target.value)
        })}
        {...props}
        />
    )
}


/* focus:shadow[0_0_6px_1px_rgba(59,130,246,1)] */