import * as React from "react";
import { WhyItem } from "./why-item";
import {
	CandidateAreaIcon,
	FreeAdvertisementIcon,
	PersonExperiencesIcon,
} from "../../../../../../theme/icons";

interface WhyItemContainerProps {
	isCompany: boolean;
}

interface WhyItemProps {
	title: string;
	description: string;
	img: React.ComponentType<{ size: number }>;
}

const profileWhyItems: WhyItemProps[] = [
	{
		title: "Üye Ol",
		description:
			"Kolay üyelik sistemiyle, hızlı bir şekilde üye olursun. Dilersen Sosyal Medya hesapları ile hemen olabilirsin",
		img: PersonExperiencesIcon,
	},
	{
		title: "Özgeçmiş Oluştur",
		description:
			"Özgeçmişteki bilgilerin sayesinde firmaların sana ulaşmasını sağlayabilirsin.",
		img: CandidateAreaIcon,
	},
	{
		title: "İş Ara",
		description:
			"isbul.net anasayfasındaki arama kutusuna istediğin anahtar kelimeyi yazarak, karşına çıkan ilanlardan sana en uygun pozisyona başvuru yapabilirsin.",
		img: FreeAdvertisementIcon,
	},
];

const companyWhyItems = [
	{
		title: "Gelişmiş Kullanıcı Deneyimi",
		description:
			"isbul.net'in gelişmiş kullanıcı deneyimi ile ilanlarınızı hızlıca oluşturun, yüz binlerce adaya ulaşın.",
		img: PersonExperiencesIcon,
	},
	{
		title: "Aday Havuzunda Detaylı Arama",
		description:
			"isbul.net'in gelişmiş aday havuzu sistemi sayesinde aradığınız adaylara nokta atışı ulaşın.",
		img: CandidateAreaIcon,
	},
	{
		title: "Ücretsiz İlan Yayınlama",
		description: "Her ay 1 adet ücretsiz ilan yayınlama hakkına sahip olun.",
		img: FreeAdvertisementIcon,
	},
];

const WhyItemContainer: React.FC<WhyItemContainerProps> = ({ isCompany }) => {
	const items = isCompany ? companyWhyItems : profileWhyItems;
	return (
		<div className='flex flex-col pr-4 gap-y-2'>
			{items.map(
				(
					item: {
						title: string;
						description: string;
						img: React.ComponentType<{ size: number }>;
					},
					index: React.Key | null | undefined
				) => (
					<WhyItem
						key={index}
						title={item.title}
						description={item.description}
						img={item.img}
					/>
				)
			)}
		</div>
	);
};
export { WhyItemContainer };
