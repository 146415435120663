import { ButtonHTMLAttributes, MouseEvent, useContext } from "react"
import classNames from "classnames"
import { SelectSearchDropdownContext, selectSearchDropdownContextType } from "."



type itemPropsType = ButtonHTMLAttributes<HTMLButtonElement> & {
    selected?: boolean
}






export default function Item({
    type = 'button',
    className,
    onClick,
    selected,
    ...props
}: itemPropsType) {

    const { setIsOpen }: selectSearchDropdownContextType = useContext(SelectSearchDropdownContext)

    const cn = classNames({
        'p-2 text-start hover:bg-gray-100 transition-all': true
    })

    return(
        <button
        {...props}
        type={type}
        className={`${cn} ${className}`}
        onClick={((e) => itemOnClick(e))}
        >

        </button>
    )

    function itemOnClick(e: MouseEvent<HTMLButtonElement>) {
        if (onClick) {
            onClick(e)
        }

        setIsOpen(false)
    }
}