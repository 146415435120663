import { IoClose, IoPaperPlaneOutline } from "react-icons/io5";
import { useForm, SubmitHandler } from "react-hook-form";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Axios from "../../../../services/axios";
import { useNavigate, useParams } from "react-router-dom";
import { Button, InputArea } from "../../../../components/isbul-ui";
import Swal from "sweetalert2";
import { CVPreviewContext } from "../../../company-page-container/cv-detail/contents";
import { FaTimes } from "react-icons/fa";

type payloadDataType = {
  receiver_id: number;
  message: string;
};

const axios = new Axios();

export default function SendMessageToCompany({
  companyId,
  setIsOpen,
}: {
  companyId: number;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const [isVisible, setIsVisible] = useState(false);
  const [text, setText] = useState("");
  const [loading, setloading] = useState(false);

  const { slug } = useParams();

  useEffect(() => {
    const timeout = setTimeout(() => {
      document.body.classList.add("overflow-hidden", "h-auto");
      setIsVisible(true);
    }, 10);
    return () => clearTimeout(timeout);
  }, []);

  const handleCloseClick = () => {
    setIsVisible(false);
    setTimeout(() => {
      setIsOpen(false);
      document.body.classList.remove("overflow-hidden", "h-auto");
    }, 500);
  };

  const handleSubmit = () => {
    if (loading) return;

    if (text === "") {
      Swal.fire({
        title: "Uyarı",
        text: "Mesajınızı girin.",
        icon: "error",
        confirmButtonText: "Tamam",
      });
      return;
    }
    setloading(true);
    axios
      .userAuthRequest({
        type: "post",
        endpoint: "chat/send-message",
        payload: {
          receiver_id: companyId,
          message: text,
        },
      })
      .then((response: any) => {
        console.log("mesaj gönderildi", response);
        if (response.data.status_code === 200) {
          Swal.fire({
            icon: "success",
            title: "Mesaj Gönderildi",
            confirmButtonText: "Tamam",
          });
          handleCloseClick();
        }
      })
      .catch((error: any) => {
        Swal.fire({
          title: "Uyarı",
          text: "Mesaj Gönderilirken Bir Hata Oluştu",
          icon: "error",
          confirmButtonText: "Tamam",
        });
        setloading(false);
        console.log(error);
      });
  };

  return (
    <div className="fixed inset-0 z-[1050] flex items-start justify-center overflow-y-auto ">
      <div
        className="fixed inset-0 bg-black opacity-50 h-auto "
        onClick={handleCloseClick}
      ></div>

      <div
        className={`bg-white my-10 max-md:my-0 max-md:fixed max-md:top-0 max-md:left-0 max-md:w-screen max-md:h-screen max-md:overflow-y-auto md:rounded-lg md:shadow-lg  md:relative md:min-w-[40%] transition-all duration-500 ease-in-out transform ${
          isVisible ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-10"
        }`}
      >
        <div className={"flex justify-between border-b-2 py-5 px-5"}>
          <div className={"text-mains font-bold text-lg"}>Mesaj Gönder</div>
          <div
            onClick={handleCloseClick}
            className={
              "flex justify-self-end justify-center items-center w-5 h-5 "
            }
          >
            <FaTimes className={"text-gray-400"} />
          </div>
        </div>

        {false ? (
          <div className="flex flex-col items-start justify-center  p-5 w-full">
            Üyeliğinizde aktif bir ürün olmadığı için mesaj gönderememektesiniz.
          </div>
        ) : (
          <div className="flex flex-col items-start justify-center  p-5 w-full">
            <div className="w-full mt-2">Mesaj:</div>
            <div className="w-full mt-2">
              <textarea
                className={"w-full border min-h-36 p-3"}
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />
            </div>
          </div>
        )}

        <div className={"flex justify-end gap-5 border-t-2 py-5 px-5"}>
          <div
            onClick={handleCloseClick}
            className={
              "p-3 bg-gray-200 hover:bg-[#ff7f1d] hover:text-white transition-all rounded cursor-pointer font-bold"
            }
          >
            Kapat
          </div>
          {false ? (
            <></>
          ) : (
            <div
              onClick={() => {
                handleSubmit();
              }}
              className={
                "p-3 bg-mains text-white justify-self-center rounded cursor-pointer font-bold"
              }
            >
              Mesaj Gönder
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
