export default function Hero() {
  return (
    <div
      className="bg-[#3fa5f0] bg-center bg-cover bg-no-repeat"
      style={{
        backgroundImage:
          'url("https://isbul.net/frontend/images/isbul/free-advert-bg.png")',
      }}
    >
      <div className="container flex flex-row mx-auto items-center">
        <div className="hidden lg:flex flex-shrink-0 basis-1/2">
          <img src="https://isbul.net/frontend/images/isbul/free-advert-people-bg.png" />
        </div>
        <div className="flex flex-col gap-10 flex-1 lg:flex-shrink-0 lg:basis-1/2 items-center px-2">
          <h1 className="text-white text-3xl font-bold text-center">
            Hemen ücretsiz firma üyeliği oluştur hesabına her ay 1 ücretsiz ilan
            hakkı ile
          </h1>
          <a
            href="/isveren/ilanlar/olustur/ilan-bilgileri"
            className="px-6 py-8 bg-[#de5c2b] text-white text-xl font-bold rounded-lg"
          >
            ÜCRETSİZ İŞ İLANI YAYINLA
          </a>
          <span className="text-base text-white font-bold text-center">
            ARADIĞIN PERSONELİ BULMANIN EN KOLAY YOLU
          </span>
        </div>
      </div>
    </div>
  );
}
