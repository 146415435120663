import { XMarkIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import React from "react";

interface ForgotPasswordModalProps {
  open: boolean;
  onClose: () => void;
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({
  onClose,
}) => {
  const navigate = useNavigate();
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-80">
      <div className="flex flex-col w-full max-w-[90%] gap-2 sm:gap-4 p-4 sm:p-6 bg-white rounded-md md:max-w-2xl">
        <div className="flex items-center justify-between">
          <h3 className="text-xl font-semibold text-title">Şifremi Unuttum</h3>
          <button
            onClick={onClose}
            className="text-xl text-gray-700 rounded-full hover:bg-gray-300"
          >
            <XMarkIcon className="w-7 h-7" />
          </button>
        </div>
        <div className="flex flex-col items-center justify-around gap-4 md:flex-row">
          <button
            type="button"
            onClick={() => navigate("/sifremi-unuttum-email")}
            className="flex flex-col items-center justify-center w-full gap-4 p-4 transition-all ease-in-out delay-75 rounded-lg md:w-1/2 bg-gray-200 border border-gray-400"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-mail"
              width="60"
              height="60"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="#2253af"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z" />
              <path d="M3 7l9 6l9 -6" />
            </svg>
            <h4 className="text-xl font-semibold text-title">E-Posta İle</h4>
            <p>
              Şifre yenileme bağlantısının e-posta ile gönderilmesini istiyorum.
            </p>
          </button>
          <button
            type="button"
            onClick={() => navigate("/sifremi-unuttum-sms")}
            className="flex flex-col items-center justify-center w-full gap-4 p-4 transition-all ease-in-out delay-75 rounded-lg md:w-1/2 min-h-[224px] hover:bg-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-device-mobile-message"
              width="60"
              height="60"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="#2253af"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M11 3h10v8h-3l-4 2v-2h-3z" />
              <path d="M15 16v4a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1h2" />
              <path d="M10 18v.01" />
            </svg>
            <h4 className="text-xl font-semibold text-title">SMS İle</h4>
            <p>
              Şifre yenileme bağlantısının sms ile gönderilmesini istiyorum.
            </p>
          </button>
        </div>
        <div>
          <button
            type="button"
            onClick={() => navigate("/iletisim")}
            className="w-full p-2 py-3 text-white rounded-xl bg-[#1a5edd]"
          >
            <p className="text-[13px] font-semibold md:text-base font-poppins">
              İkisini de hatırlamıyorsanız buraya tıklayın.
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;
