import { useState } from "react";
import { Button, InputArea } from "../../components/isbul-ui";
import EMailForm from "./components/EMailForm";











export default function ForgotPasswordPage() {

    const [selectedTab, setSelectedTab] = useState<'email' | 'phone'>('email')
    return(
        <div className="flex items-center justify-center container mx-auto my-14 font-poppins">
            <div className="flex flex-col items-center max-w-[450px] gap-6 bg-white border border-gray-200 rounded-md p-6">
                <div className="flex flex-col gap-2">
                    <h1 className="text-xl font-semibold text-center">
                        Şifremi Unuttum
                    </h1>
                    <p className="text-gray-700 text-sm text-center">
                        Şifre sıfırlama bağlantısını gönderebilmemiz için e-posta adresini girmelisin.
                    </p>
                </div>
                <div className="flex flex-row items-center justify-between bg-gray-200 w-full p-1 rounded-md">
                    <Button
                    className="flex w-full mr-1"
                    type="button"
                    variant={selectedTab == 'email'? 'ghost': 'secondary'}
                    size="sm"
                    onClick={() => setSelectedTab('email')}
                    >
                        E-Posta
                    </Button>
                    <Button
                    className="flex w-full ml-1"
                    type="button"
                    variant={selectedTab == 'phone'? 'ghost': 'secondary'}
                    size="sm"
                    onClick={() => setSelectedTab('phone')}
                    >
                        Telefon
                    </Button>
                </div>
                
                {selectedTab == 'email'? (
                    <EMailForm/>
                ): null}
            </div>
        </div>
    )
}