import React, { useState } from "react";
import { FaRegBuilding } from "react-icons/fa6";
import Axios from "../../../../../services/axios";
import { GrSend } from "react-icons/gr";
import { IoLocationOutline } from "react-icons/io5";
import { useCookie } from "../../../../../hooks/useCookie";
import { CookieName } from "../../../../../constants/enums";
import Swal from "sweetalert2";
import { LuLoader2 } from "react-icons/lu";

type CvSelect = {
  onSuccess?: () => void;
  adData: any;
};

const MessageSender: React.FC<CvSelect> = ({
  onSuccess = () => {},
  adData,
}) => {
  const axios = new Axios();
  const [text, setText] = useState("");
  const { getCookie } = useCookie();
  const companyToken = getCookie(CookieName.CompanyUserToken);
  const userToken = getCookie(CookieName.UserToken);
  const TOKEN = userToken ? userToken : companyToken;

  const [loading, setloading] = useState(false);

  const handleChangeText = (event: any) => {
    const data = event.target.value;
    setText(data);
  };

  const handleApply = () => {
    if (loading) return;

    if (text === "") {
      Swal.fire({
        title: "Uyarı",
        text: "Mesajınızı girin.",
        icon: "error",
        confirmButtonText: "Tamam",
      });
      return;
    }
    setloading(true);
    axios
      .userAuthRequest({
        type: "post",
        endpoint: "chat/send-message",
        payload: {
          receiver_id: adData.company.id,
          message: text,
        },
      })
      .then((response: any) => {
        console.log("mesaj gönderildi", response);
        if (response.data.status_code === 200) {
          onSuccess && onSuccess();
        }
      })
      .catch((error: any) => {
        Swal.fire({
          title: "Uyarı",
          text: "Mesaj Gönderilirken Bir Hata Oluştu",
          icon: "error",
          confirmButtonText: "Tamam",
        });
        setloading(false);
        console.log(error);
      });
  };

  return (
    <div>
      <div className={"p-5"}>
        <div className="flex flex-col justify-center items-center w-full  gap-1  border-b pb-3">
          <div className="flex w-full justify-between items-center">
            <p className="text-mains font-poppins poppins-medium text-lg">
              {adData?.advert?.title}
            </p>
          </div>

          <div className="flex flex-col justify-center items-start w-full gap-[2px]">
            <p className=" font-poppins poppins-medium text-xs flex justify-center items-center gap-1 text-gray-600">
              <FaRegBuilding className=" text-xs" />
              {adData.company_detail.company_name}
            </p>
            <p className=" font-poppins poppins-medium text-xs flex justify-center items-center gap-1 text-gray-600">
              <IoLocationOutline className=" text-xs" />
              {adData.districts
                ? adData.districts.length > 0
                  ? adData.districts[0].name + " / " + adData.cities[0].name
                  : adData.cities[0].name
                : adData.cities[0].name}
            </p>
          </div>
        </div>

        <div className="flex flex-col items-start justify-center  pt-3 w-full">
          <div className="w-full mt-2">Mesajınız:</div>
          <div className="w-full mt-2">
            <textarea
              className={"w-full border min-h-36 p-3"}
              value={text}
              onChange={handleChangeText}
            />
          </div>
        </div>
      </div>
      <div
        onClick={() => {
          handleApply();
        }}
        className="w-full rounded-md p-5 border-t "
      >
        <button
          className={`${
            loading ? "bg-gray-400" : "bg-mains"
          }  text-white font-poppins poppins-medium text-base w-full  rounded-md h-12 flex justify-center items-center gap-1`}
        >
          Mesaj Gönder
          {loading ? (
            <LuLoader2 className="text-xl animate-spin" />
          ) : (
            <GrSend className="text-xl" />
          )}
        </button>
      </div>
    </div>
  );
};

export default MessageSender;
