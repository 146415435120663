import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Button, InputArea, SelectDropdown, Textarea } from "../../../../../../components/isbul-ui"
import { useYears } from "../../../../../../customHooks/useYears"
import { dbUserSeminarType } from "../../../../../../types"
import { useForm, SubmitHandler } from "react-hook-form"
import { IoAdd, IoTrashBinOutline } from "react-icons/io5"
import { FaPencilAlt, FaRegTrashAlt, FaTrashAlt } from "react-icons/fa"
import Swal from "sweetalert2"
import Axios from "../../../../../../services/axios"
import { useParams } from "react-router-dom"
import { MdCastForEducation } from "react-icons/md"
import { useIsMobile } from "../../../../../../hooks/useIsMobile"
import FormModal from "../../../../../../components/isbul-ui/form-modal"
import { useContext } from "react"
import { cvLayoutContextType, CvLayoutContext } from "../.."



type payloadType = {
    cv_id: number,
    name: string | null,
    company: string | null,
    date_month: number | null,
    date_year: number | null,
    detail: string | null
}


type propsType = {
    seminar: dbUserSeminarType | null,
    getAllDatas?: () => void,
    seminarsLength?: number,
    isAddClicked?: boolean,
    setIsAddClicked?: Dispatch<SetStateAction<boolean>>
}

type monthType = {
    id: number,
    label: string,
    label_en: string
}

const months: monthType[] = [
    { id: 1, label: 'Ocak', label_en: 'January' },
    { id: 2, label: 'Şubat', label_en: 'February' },
    { id: 3, label: 'Mart', label_en: 'March' },
    { id: 4, label: 'Nisan', label_en: 'April' },
    { id: 5, label: 'Mayıs', label_en: 'May' },
    { id: 6, label: 'Haziran', label_en: 'June' },
    { id: 7, label: 'Temmuz', label_en: 'July' },
    { id: 8, label: 'Ağustos', label_en: 'August' },
    { id: 9, label: 'Eylül', label_en: 'September' },
    { id: 10, label: 'Ekim', label_en: 'October' },
    { id: 11, label: 'Kasım', label_en: 'November' },
    { id: 12, label: 'Aralık', label_en: 'December' },
]




const axios = new Axios()



export default function SeminarForm({
    seminar,
    getAllDatas,
    seminarsLength,
    isAddClicked,
    setIsAddClicked
}: propsType) {

    const { showCvScore, isEnglish }: cvLayoutContextType = useContext(CvLayoutContext)

    const params = useParams()
    const cvId = params.slug

    const isMobile = useIsMobile()

    // General states
    const [isEditOpen, setIsEditOpen] = useState<boolean>(seminar? false: true)
    const [isPageOpen, setIsPageOpen] = useState<boolean>(false)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // Page header click catcher
    useEffect(() => {
        if (isAddClicked != undefined && setIsAddClicked != undefined && !seminar) {
            if (isAddClicked) {
                setIsModalOpen(true)
                setIsAddClicked(false)
            }
        }
    }, [isAddClicked])

    // Years generator
    const years: number[] = useYears({ startYear: new Date().getFullYear(), endYear: 1974 })

    // Form states
    const [selectedMonth, setSelectedMonth] = useState<monthType | null> (null)
    const [selectedYear, setSelectedYear] = useState<number | null>(null)

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        clearErrors,
        getFieldState
    } = useForm<payloadType>()

    const onSubmit: SubmitHandler<payloadType> = ((data) => {
        if (!seminar) {
            addSeminar(data)
        } else {
            saveSeminar(data)
        }
    })


    /* Custom register form fields */
    //Month
    register('date_month', {
        required: 'Ay seçiniz'
    })

    // Year
    register('date_year', {
        required: 'Yıl seçiniz'
    })


    /* Form fields set */
    // Month
    useEffect(() => {
        if (selectedMonth) {
            setValue('date_month', selectedMonth.id)
            clearErrors('date_month')
        } else {
            setValue('date_month', null)
        }
    }, [selectedMonth])

    // Year
    useEffect(() => {
        if (selectedYear) {
            setValue('date_year', selectedYear)
            clearErrors('date_year')
        } else {
            setValue('date_year', null)
        }
    }, [selectedYear])

    /* Watch form values */
    const name = watch('name') || ''
    const company = watch('company') || ''
    const detail = watch('detail') || ''


    useEffect(() => {

        /* Fill form is seminar exist */
        if (seminar) {
            setValue('name', seminar.name)
            setValue('company', seminar.company)
            setValue('detail', seminar.detail)
            setSelectedYear(parseInt(seminar.date_year))

            let seminarMonth = seminar.date_month

            if (seminarMonth.startsWith('0')) {
                seminarMonth = seminarMonth[1]
            }

            const month = months.find((m) => m.id.toString() == seminarMonth)

            setSelectedMonth(month?? null)
        }
    }, [seminar])

    if (isEditOpen) {
        return(
            <div className="flex flex-col gap-2">

                {/* Form modal for page header */}
                {!seminar && (
                    <div className={`flex flex-row items-center justify-between px-4 py-2`}>
                        <Button
                        size="sm"
                        onClick={() => {
                            setIsPageOpen(true)
                            setIsModalOpen(true)
                        }}
                        >
                            <Button.Icon>
                                <IoAdd/>
                            </Button.Icon>
                            {isEnglish? 'Add Seminar': 'Seminer Ekle'}
                        </Button>
                    </div>
                )}

                {!seminar && (
                    <hr className="border-mains"/>
                )}

                {/* Form modal for form */}
                <FormModal
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
                onClosing={() => {
                    setIsModalOpen(false)
                    if (seminar) {
                        setIsEditOpen(false)
                    } else {
                        setIsEditOpen(true)
                    }
                }}
                >
                    <FormModal.Content>
                        <form className="flex flex-col gap-y-4 p-4" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                            <label className="text-mains font-semibold">
                                {seminar? `${isEnglish? 'Edit Seminar': 'Semineri Düzenle'}`: `${isEnglish? 'Add Seminar': 'Seminer Ekle'}`}
                            </label>
                            <hr className="border-mains"/>
                            <div className="flex flex-col gap-y-6 lg:flex-row">
                                <div className="flex flex-col gap-y-6 basis-1/2 px-2">
                
                                    {/* Name input */}
                                    <InputArea
                                    className="w-full"
                                    required
                                    mode={name.length == 0? 'empty': 'dirty'}
                                    error={errors.name?.message? true: false}
                                    >
                                        <InputArea.Label>{isEnglish? 'Name': 'Adı'}</InputArea.Label>
                                        <InputArea.Input
                                        {...register('name', {
                                            required: 'Adı giriniz'
                                        })}
                                        value={name || ''}
                                        onChange={((e) => {
                                            setValue('name', e.target.value)
                                            if (e.target.value.length > 0) {
                                                clearErrors('name')
                                            }
                                        })}
                                        />
                                    </InputArea>
                
                                    {/* Company input */}
                                    <InputArea
                                    className="w-full"
                                    required
                                    mode={company.length == 0? 'empty': 'dirty'}
                                    error={errors.company?.message? true: false}
                                    >
                                        <InputArea.Label>{isEnglish? 'Institution': 'Kurum'}</InputArea.Label>
                                        <InputArea.Input
                                        {...register('company', {
                                            required: 'Kurum giriniz'
                                        })}
                                        value={company || ''}
                                        onChange={((e) => {
                                            setValue('company', e.target.value)
                                            clearErrors('company')
                                        })}
                                        />
                                    </InputArea>
                                    <div className="flex flex-row items-start w-full">

                                        {/* Month select */}
                                        <div className="flex w-full pr-1">
                                            <SelectDropdown
                                            className="w-full"
                                            required
                                            mode={selectedMonth? 'dirty': 'empty'}
                                            error={errors.date_month?.message? true: false}
                                            >
                                                <SelectDropdown.Label>{isEnglish? 'Month': 'Ay'}</SelectDropdown.Label>
                                                <SelectDropdown.Trigger>
                                                    {selectedMonth? `${isEnglish? `${selectedMonth.label_en}`: `${selectedMonth.label}`}`: `${isEnglish? 'Month': 'Ay'}`}
                                                </SelectDropdown.Trigger>
                                                <SelectDropdown.Items className="!top-5 !-translate-y-full min-h-[150px]">
                                                    {months.map((m) => {
                                                        return(
                                                            <SelectDropdown.Item
                                                            key={`ms${m.id}`}
                                                            onClick={() => setSelectedMonth(m)}
                                                            >
                                                                {isEnglish? m.label_en: m.label}
                                                            </SelectDropdown.Item>
                                                        )
                                                    })}
                                                </SelectDropdown.Items>
                                            </SelectDropdown>
                                        </div>

                                        {/* Year select */}
                                        <div className="flex w-full pl-1">
                                            <SelectDropdown
                                            className="w-full"
                                            required
                                            mode={selectedYear? 'dirty': 'empty'}
                                            error={errors.date_year?.message? true: false}
                                            >
                                                <SelectDropdown.Label>{isEnglish? 'Year': 'Yıl'}</SelectDropdown.Label>
                                                <SelectDropdown.Trigger>
                                                    {selectedYear?? `${isEnglish? 'Year': 'Yıl'}`}
                                                </SelectDropdown.Trigger>
                                                <SelectDropdown.Items className="!top-5 !-translate-y-full min-h-[150px]">
                                                    {years.map((y) => {
                                                        return(
                                                            <SelectDropdown.Item
                                                            key={`ys${y}`}
                                                            onClick={() => setSelectedYear(y)}
                                                            >
                                                                {y}
                                                            </SelectDropdown.Item>
                                                        )
                                                    })}
                                                </SelectDropdown.Items>
                                            </SelectDropdown>
                                        </div>
                                        {/* <div className="flex basis-1/2 py- pl-1">
                
                                        </div> */}
                                    </div>
                                </div>
                                <div className="flex flex-col basis-1/2 px-2 h-full">

                                    {/* Detail textarea */}
                                    <Textarea
                                    className="w-full h-full"
                                    mode={detail.length == 0? 'empty': 'dirty'}
                                    >
                                        <Textarea.Label>{isEnglish? 'Description': 'Açıklama'}</Textarea.Label>
                                        <Textarea.Field
                                        className="h-full min-h-[100px] lg:min-h-[210px]"
                                        value={detail || ''}
                                        onChange={((e) => {
                                            setValue('detail', e.target.value)
                                        })}
                                        />
                                    </Textarea>
                                </div>
                            </div>
                            
                            {/* Save and cancel buttons */}
                            {!seminar && (
                                <div className="flex flex-row gap-2 ml-auto items-center mt-6">
                                    <Button
                                    type="button"
                                    variant="secondary"
                                    size="sm"
                                    className="flex"
                                    onClick={() => setIsModalOpen(false)}
                                    >
                                        {isEnglish? 'Cancel': 'Vazgeç'}
                                    </Button>
                                    <Button type="submit" size="sm">
                                        {isEnglish? 'Save': 'Kaydet'}
                                    </Button>
                                </div>
                            )}
                
                            {seminar && (
                                <div className="flex flex-row items-center justify-between mt-6">
                                    <div className="flex ml-auto flex-row items-center gap-2">
                                        <Button type="button" variant="secondary" size="sm" onClick={() => setIsEditOpen(false)}>
                                            {isEnglish? 'Cancel': 'Vazgeç'}
                                        </Button>
                                        <Button type="submit" size="sm">
                                            {isEnglish? 'Save': 'Kaydet'}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </form>
                    </FormModal.Content>
                </FormModal>

            </div>
        )
    } else {
        return(
            <>
                {/* Desktop section */}
                <div
                className="hidden lg:flex flex-row relative px-1 py-2 gap-4 border-transparent hover:border-gray-300 border-dashed border-2 rounded-md transition-all"
                >
                    {/* Edit and delete buttons */}
                    <FormModal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                    >
                        <FormModal.Trigger>
                            <div className="flex flex-col gap-2 absolute -top-2 right-2 lg:top-4 lg:right-4 z-10">
                                <Button 
                                variant="ghost" 
                                size="xs" 
                                className="aspect-square !rounded-full z-10 !border-none"
                                onClick={() => {
                                    setIsEditOpen(true)
                                    //setIsPageOpen(true)
                                    setIsModalOpen(true)
                                }}
                                >
                                    <Button.Icon>
                                        <FaPencilAlt className="text-xl"/>
                                    </Button.Icon>
                                </Button>
                                <Button
                                variant="ghost"
                                size="xs"
                                className="aspect-square !rounded-full !border-none"
                                onClick={() => {
                                    Swal.fire({
                                        icon: 'warning',
                                        title: `${isEnglish? 'Are you sure to delete seminar information?': 'Seminer silmek istediğinizden emin misiniz?'}`,
                                        confirmButtonText: `${isEnglish? 'Yes': 'Evet'}`,
                                        cancelButtonText: `${isEnglish? 'No': 'Hayır'}`,
                                        preConfirm: () => {
                                            deleteSeminar()
                                        },
                                        showCancelButton: true
                                    })
                                }}
                                >
                                    <Button.Icon className="text-xl">
                                        <IoTrashBinOutline/>
                                    </Button.Icon>
                                </Button>
                            </div>
                        </FormModal.Trigger>
                    </FormModal>
                    
                    <div className="absolute top-4 left-4 lg:top-0 lg:left-0 z-0 lg:relative items-center flex flex-col border-r-0 lg:border-r pr-4 mr-2">
                        <MdCastForEducation className="text-6xl text-gray-200 lg:text-9xl lg:text-mains/40"/>
                        <label className="hidden lg:flex text-center font-poppins text-sm font-semibold text-mains line-clamp-1">
                            {isEnglish? 'Seminar': 'Seminer'}
                        </label>
                    </div>
                    <div className="flex flex-col gap-2 w-full z-[1]">
                        <div className="flex flex-row">

                            <div className="flex flex-col gap-2 basis-1/2 w-full p-1">
                                {/* Seminar name section */}
                                <div className="flex flex-col">
                                    <label className="hidden lg:flex text-xs font-poppins font-semibold text-gary-700">
                                        {isEnglish? 'Name': 'Adı'}
                                    </label>
                                    <label className="font-poppins text-gray-600 text-sm">
                                        {seminar?.name}
                                    </label>
                                </div>

                                {/* Company section */}
                                <div className="flex flex-col">
                                    <label className="hidden lg:flex text-xs font-poppins font-semibold text-gary-700">
                                        {isEnglish? 'Institution': 'Kurum'}
                                    </label>
                                    <label className="font-poppins text-gray-600 text-sm">
                                        {seminar?.company}
                                    </label>
                                </div>
                            </div>

                            {/* Date section */}
                            <div className="flex flex-col gap-2 basis-1/2 w-full p-1">
                                <label className="hidden lg:flex text-xs font-poppins font-semibold text-gary-700">
                                    {isEnglish? 'Date': 'Tarih'}
                                </label>
                                <label className="font-poppins text-gray-600 text-sm">
                                    {seminar?.date_month}/{seminar?.date_year}
                                </label>
                            </div>
                            
                        </div>

                        {/* Detail section */}
                        {seminar?.detail && (
                            <div className="flex flex-col px-1">
                                <label className="hidden lg:flex text-xs font-poppins font-semibold text-gary-700">
                                    {isEnglish? 'Description': 'Açıklama'}
                                </label>
                                <label className="font-poppins text-gray-600 text-sm break-all">
                                    {seminar?.detail}
                                </label>
                            </div>
                        )}
                    </div>
                </div>

                {/* Mobile section */}
                <div className="flex flex-row lg:hidden p-4">
                    <div className="flex flex-col gap-2 flex-1">

                        {/* Name section */}
                        <div className="flex flex-col">
                            <label className="font-poppins text-[13px] font-semibold text-gray-900">
                                {isEnglish? 'Name': 'Adı'}
                            </label>
                            <label className="font-poppins text-[13px] text-gray-800">
                                {seminar?.name}
                            </label>
                        </div>

                        {/* Company section */}
                        <div className="flex flex-col">
                            <label className="font-poppins text-[13px] font-semibold text-gray-900">
                                {isEnglish? 'Institution': 'Kurum'}
                            </label>
                            <label className="font-poppins text-[13px] text-gray-800">
                                {seminar?.company}
                            </label>
                        </div>

                        {/* Date section */}
                        <div className="flex flex-col">
                            <label className="font-poppins text-[13px] font-semibold text-gray-900">
                                {isEnglish? 'Date': 'Tarih'}
                            </label>
                            <label className="font-poppins text-[13px] text-gray-800">
                                {seminar?.date_month}/{seminar?.date_year}
                            </label>
                        </div>
                    </div>

                    {/* Edit and delete buttons */}
                    <div className="flex flex-col gap-2 items-center">
                        <button className="flex flex-col gap-1 items-center text-mains font-poppins text-xs" onClick={() => {
                            setIsEditOpen(true)
                            setIsPageOpen(true)
                            setIsModalOpen(true)
                        }}>
                            <FaPencilAlt className="text-mains text-base"/>
                            {isEnglish? 'Edit': 'Düzenle'}
                        </button>
                        <button 
                        className="flex flex-col gap-1 items-center text-mains font-poppins text-xs" 
                        onClick={() => {
                            Swal.fire({
                                icon: 'warning',
                                title: `${isEnglish? 'Are you sure to delete seminar information?': 'Seminer bilgisini silmek istediğinizden emin misiniz?'}`,
                                confirmButtonText: `${isEnglish? 'Yes': 'Evet'}`,
                                cancelButtonText: `${isEnglish? 'No': 'Hayır'}`,
                                preConfirm: () => {
                                    deleteSeminar()
                                },
                                showCancelButton: true
                            })
                        }}>
                            <IoTrashBinOutline className="text-base"/>
                        </button>
                    </div>
                </div>
            </>
        )
    }

    async function addSeminar({ cv_id, ...others }: payloadType) {

        if (isLoading) return

        setIsLoading(true)

        try {
            const response = await axios.userAuthRequest({
                endpoint: `user/profilim/cv/sem/create`,
                type: 'post',
                payload: {
                    cv_id: cvId,
                    ...others
                }
            })

            if (response && response.status == 200) {
                if (getAllDatas) {
                    getAllDatas()
                }

                // Clear form fields
                setValue('name', '')
                setValue('company', '')
                setValue('detail', '')
                setSelectedMonth(null)
                setSelectedYear(null)

                setIsPageOpen(false)
                setIsModalOpen(false)

                showCvScore()
                /* if (isMobile.isMobile) {
                    setIsPageOpen(false)
                } */
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Hata',
                    text: 'Seminer eklenirken bir hata meydana geldi.'
                })
            }
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Hata',
                text: 'Seminer eklenirken beklenmedik bir hata meydana geldi.'
            })
        }

        setIsLoading(false)
    }

    async function saveSeminar({ cv_id, ...others }: payloadType) {

        if (isLoading) return

        setIsLoading(true)

        try {
            if (seminar) {
                const response = await axios.userAuthRequest({
                    endpoint: `user/profilim/cv/sem/update/${seminar.id}`,
                    type: 'put',
                    payload: {
                        cv_id: cvId,
                        ...others
                    }
                })

                if (response && response.status == 200) {
                    setIsEditOpen(false)
                    if (getAllDatas) {
                        getAllDatas()
                    }

                    showCvScore()
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Hata',
                        text: 'Seminer güncellenirken  bir hata meydana geldi.'
                    })
                }
            }
        } catch (error) {
            console.log(error)
            Swal.fire({
                icon: 'error',
                title: 'Hata',
                text: 'Seminer güncellenirken beklenmedik bir hata meydana geldi.'
            })
        }

        setIsLoading(false)
    }

    async function deleteSeminar() {

        if (isLoading) return

        setIsLoading(true)

        try {
            if (seminar) {
                const response = await axios.userAuthRequest({
                    endpoint: `user/profilim/cv/sem/delete/${seminar.id}?cv_id=${cvId}`,
                    type: 'delete',
                    payload: {
                        cv_id: cvId
                    }
                })

                if (response && response.status == 200) {
                    if (getAllDatas) {
                        getAllDatas()
                    }

                    showCvScore()
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Hata',
                    text: 'Seminer silinirken beklenmedik bir hata meydana geldi.'
                })
            }
        } catch (error) {
            console.log(error) 
            Swal.fire({
                icon: 'error',
                title: 'Hata',
                text: 'Seminer silinirken beklenmedik bir hata meydana geldi.'
            })
        }

        setIsLoading(false)
    }
}