import React, { useState, useEffect } from "react";
import Title from "./title/index";
import ArticleContents from "./article-contents/index";
import Detail from "./detail/index";
import Axios from "../../../../services/axios/index";
import { useParams } from "react-router-dom";

interface SectionDetail {
  id: number;
  section_id: number;
  title: string;
  order: number;
  section_detail: {
    id: number;
    section_id: number;
    title: string;
    detail: string;
    order: number;
    created_at: string;
    updated_at: string;
  };
  created_at: string;
  updated_at: string;
}

const Body: React.FC = () => {
  const [details, setDetails] = useState<SectionDetail[]>([]);
  const { slug = "" } = useParams<{ slug: string }>();
  const [fetchError, setFetchError] = useState<boolean>(false);

  useEffect(() => {
    const axiosInstance = new Axios();
    if (slug) {
      axiosInstance
        .request({
          type: "get",
          endpoint: `home/sections/${slug}`,
          payload: {},
        })
        .then((response: any) => {
          if (response.status === 200) {
            console.log("API Response:", response.data);
            const sectionDetails = Array.isArray(response.data.data)
              ? response.data.data
              : [response.data.data]; // Ensure data is an array
            setDetails(sectionDetails);
            setFetchError(false);
          } else {
            setFetchError(true);
          }
        })
        .catch((error: any) => {
          console.error("Error fetching section details:", error);
          setFetchError(true);
        });
    }
  }, [slug]);

  useEffect(() => {
    console.log("Details state updated:", details);
  }, [details]);

  const transformedDetails = details.map(detail => ({
    id: detail.section_detail.id,
    section_id: detail.section_detail.section_id,
    title: detail.section_detail.title,
    order: detail.section_detail.order,
    detail: detail.section_detail.detail,
  }));

  return (
    <div className="bg-gray-100 flex flex-col w-full py-10 font-poppins">
      <div className="px-4 sm:px-4 lg:px-8 w-4/5 mx-auto">
        <Title slug={slug} />
        <div className="bg-white shadow-lg mb-5">
          <ArticleContents details={transformedDetails} />
          <div className="mt-8">
            {fetchError || details.length === 0 ? (
              <div className="text-[red] pl-8 pb-4">Henüz veri yok.</div>
            ) : (
              details.map((detail) => (
                <Detail
                  key={detail.section_detail.id}
                  id={`detail-${detail.section_detail.id}`}
                  title={detail.section_detail.title}
                  details={detail.section_detail.detail}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
