import * as React from "react";
import { useState, useEffect, useContext } from "react";
import MobileTriggerSection from "./mobile-trigger-section";
import { AccordionContent } from "./content-section";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { IApplicantsData, IItem } from "../types";
import ElemannetTriggerSection from "./elemannet-trigger-section";
import { AccordionContentMobile } from "./content-section-mobile";
import { QueryFunction, useQuery } from "@tanstack/react-query";
import { CookieName } from "../../../../constants/enums";
import { useCookie } from "../../../../hooks/useCookie";
import Axios from "../../../../services/axios";
import { CandidatePoolContext } from "../index";

const FilterSection: React.FC = () => {
  const { isMobile } = useIsMobile();

  const { detailFiltersOpen } = useContext(CandidatePoolContext);

  return (
    <section className="md:mx-10 mb-2 text-primary200 w-full">
      {isMobile ? <MobileTriggerSection /> : <ElemannetTriggerSection />}

      <div className={`${!isMobile && detailFiltersOpen ? "" : "hidden"}`}>
        <AccordionContent />
      </div>

      <div className={`${isMobile && detailFiltersOpen ? "" : "hidden"}`}>
        <AccordionContentMobile />
      </div>
    </section>
  );
};

export { FilterSection };
