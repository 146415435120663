import classNames from "classnames"
import { TextareaHTMLAttributes, useContext } from "react"
import { textareaContextType, TextareaContext } from "."



type fieldPropsType = TextareaHTMLAttributes<HTMLTextAreaElement> & {

}








export default function Field({
    className,
    ...props
}: fieldPropsType) {

    const { error, mode }: textareaContextType = useContext(TextareaContext)

    const cn = classNames({
        'p-2 rounded-md border outline-none font-poppins text-sm focus:shadow-form-glow': true,
        'border-gray-400 text-gray-600': mode == 'empty',
        'border-blue-500 text-blue-500': mode == 'dirty',
        'border-red-600 text-red-600 bg-red-50': error
    })
    return(
        <textarea
        className={`${cn} ${className}`}
        {...props}
        />
    )
}