import { HTMLAttributes } from "react"
import { useContext } from "react"
import { buttonContextType, ButtonContext } from "."
import classNames from "classnames"




type iconPropsType = HTMLAttributes<HTMLDivElement>





export default function Icon({
    className,
    ...props
}: iconPropsType) {

    const cn = classNames({

    })
    const { loading, size, variant }: buttonContextType = useContext(ButtonContext)
    return(
        <div {...props} className={`${className} ${cn} flex`}>

        </div>
    )
}