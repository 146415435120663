import { LuLoader2 } from "react-icons/lu";
import { TiArrowSortedUp } from "react-icons/ti";
import React, { useEffect, useState, useRef } from "react";
import services from "../../../../services/search-services";
import usePartialBold from "../../../../customHooks/usePartialBold";
import { IconChevronDown, IconMapPin } from "@tabler/icons-react";
import { MdOutlineKeyboardArrowUp } from "react-icons/md";
import useFetchCdnQuery from "../../../../hooks/useFetchCdnQuery";
import { useIsMobile } from "../../../../hooks/useIsMobile";

const CitySearch = ({
  onChange,
}: {
  onChange?: (arg: {
    id: number | null;
    type: string | null;
    title: string;
  }) => void;
}) => {

  const divRef = useRef<HTMLDivElement>(null)

  const [showResult, setShowResult] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [cities, setCities] = useState<any[]>([]);
  const [isFetchingPosition, setIsFetchingPosition] = useState<boolean>(false);
  const [isCitySelected, setIsCitySelected] = useState<boolean>(false);
  const [cityId, setCityId] = useState<number>(0);
  const [type, setType] = useState<number>(0);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { isMobile } = useIsMobile();

  const handleFocus = () => {
    setShowResult(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setShowResult(false);
    }, 200);
  };

  const toggleDropdown = () => {
    setShowResult(!showResult);
  };

  const handleSearchTermChange = (value: string) => {
    onChange &&
      onChange({
        id: null,
        type: null,
        title: value,
      });
    setIsCitySelected(false);
    setSearchTerm(value);
  };

  const handleCitySelect = (city: any) => {
    onChange &&
      onChange({
        id: city.id,
        type: city.type,
        title: city.name,
      });
    setCityId(city.id);
    setType(city.type === "city" ? 0 : 1);
    setIsCitySelected(true);
    setSearchTerm(city.name);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (isCitySelected && e.nativeEvent.key === "Backspace") {
      onChange &&
        onChange({
          id: null,
          type: null,
          title: "",
        });
      setIsCitySelected(false);
      setSearchTerm("");
    }
  };

  const getBoldText = (fullString: string, boldString: string) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return usePartialBold({ fullString, boldString });
  };

  const citiesQuery = useFetchCdnQuery(
    "cities-or-districts",
    "get-cities-or-districts",
    "cities_or_districts",
    searchTerm,
    "20",
    isCitySelected
      ? (type === 0 ? `&city_include_ids=[${cityId}]` : "") +
          (type === 1 ? `&district_include_ids=[${cityId}]` : "")
      : "" + "&except_ids=[7795]"
  );

  useEffect(() => {
    if (citiesQuery.data !== undefined) {
      setCities([...citiesQuery?.data]);
    }
  }, [citiesQuery.data, citiesQuery.fetchStatus]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowResult(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className={"relative w-full h-full"} ref={dropdownRef}>
      <div className="flex items-center justify-between w-full h-full px-2 py-1 bg-white border border-white cursor-pointer">
        {!isMobile && <IconMapPin className="mr-2 text-blue-800" />}
        <input
          onKeyDown={(e) => {
            handleKeyPress(e);
          }}
          placeholder="Şehir veya İlçe Ara"
          type="text"
          value={searchTerm}
          className="flex-1 h-full text-sm text-blue-800 placeholder-gray-400 outline-none poppins-regular min-w-0 text-ellipsis"
          onChange={(e) => {
            handleSearchTermChange(e.target.value);
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <div className="flex justify-end w-[20%]">
          {isFetchingPosition && (
            <LuLoader2 className={"animate-spin mr-3"}></LuLoader2>
          )}
          <IconChevronDown
            className={`${showResult ? "rotate-180" : ""} transition-all`}
            size={18}
            onClick={toggleDropdown}
          />
        </div>
        {showResult && (
          <div className="absolute bg-white border mt-1 rounded shadow-lg max-h-52 overflow-y-auto w-full top-[100%] -ml-2 z-50">
            {cities.length > 0 ? (
              cities.map((city) => (
                <div
                  key={city.id}
                  onClick={(e) => {
                    handleCitySelect(city);
                    e.stopPropagation();
                  }}
                  className="p-2 text-gray-600 cursor-pointer hover:bg-blue-500 hover:text-white"
                >
                  <div
                    onClick={(e) => {
                      handleCitySelect(city);
                      e.stopPropagation();
                    }}
                    className="text-sm font-medium group-hover/position:text-blue-500 line-clamp-1"
                    dangerouslySetInnerHTML={{
                      __html: getBoldText(city.name, searchTerm),
                    }}
                  />
                </div>
              ))
            ) : (
              <div className="p-2 text-gray-600">
                {searchTerm.length == 0 ? (
                  <LuLoader2 className={"animate-spin mx-auto"}></LuLoader2>
                ) : (
                  "Hiçbir şehir eşleşmedi"
                )}
              </div> // Message displayed when no city matches
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CitySearch;
