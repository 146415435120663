import React from "react";

type Props = {
  title?: string;
  columns: Column[];
  data: any;
  loading?: boolean;
  pagination?: any;
  pages?: number;
  currentPage?: number;
  onPageChange?: (page: number) => void;
  onRowClick?: (item: any) => void;
  actions?: any;
};
type Column = {
  title: any;
  dataIndex?: string;
  key?: string;
  content?: any;
};

export default function CustomTable(props: Props) {
  return (
    <div className="relative shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500 rtl:text-right ">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
          <tr>
            {props.columns.map((column, index) => (
              <th scope="col" className="px-6 py-3" key={index}>
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.data.map((item: any, index: number) => (
            <tr
              key={index}
              className="bg-white border-b "
              onClick={() => props.onRowClick && props.onRowClick(item)}
            >
              {props.columns.map((column, index) => (
                <td className="px-6 py-4" key={index}>
                  {column.content
                    ? column.content(item)
                    : column.dataIndex && item[column.dataIndex]}
                </td>
              ))}
              {/* actions */}
              {props.actions && (
                <td className="px-6 py-4">{props.actions(item)}</td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      {props.pagination && props.pagination && props.pages && (
        <nav className="flex justify-center p-2">
          <ul className="inline-flex -space-x-px text-sm">
            <li>
              <button
                className={`px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700`}
                onChange={() => {
                  if (props.currentPage && props.currentPage > 0) {
                    props.onPageChange &&
                      props.onPageChange(props.currentPage - 1);
                  }
                }}
              >
                Previous
              </button>
            </li>
            {Array.from({ length: props.pages }, (_, i) => (
              <li key={i}>
                <button
                  onClick={() =>
                    props.onPageChange && props.onPageChange(i + 1)
                  }
                  className={`px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-none hover:bg-gray-100 hover:text-gray-700  ${
                    props.currentPage === i + 1 ? "!bg-title text-white" : ""
                  }`}
                >
                  {i + 1}
                </button>
              </li>
            ))}
            <li>
              <button
                className={`px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700`}
                onChange={() => {}}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      )}
    </div>
  );
}
