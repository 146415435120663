import Axios from "../services/axios"
import { cdnSalaryExpectationType } from "../types"





type getSalaryExpectationPropsType = {
    search?: string,
    limit?: number,
    only_ids?: number[]
}


const axios = new Axios()


export async function getSalaryExpectations({
    search,
    limit,
    only_ids
}: getSalaryExpectationPropsType): Promise<cdnSalaryExpectationType[]> {
    try {
        const response = await axios.request({
            endpoint: `cdn/get-salary-expectation?
            ${search? `search=${search}&`: ''}
            ${limit? `limit=${limit}&`: ''}
            ${only_ids? `only_ids:${JSON.stringify(only_ids)}&`: ''}
            `,
            type: 'get'
        })

        if (response && response.data && response.data.salary_expectations) {
            return JSON.parse(JSON.stringify(response.data.salary_expectations))
        } else {
            return []
        }
    } catch (error) {
        console.log(`Salary expectations fetch failed. Error: ${error}`)
        return []
    }
}