import { TbFileCv, TbEye, TbCloudDownload } from "react-icons/tb";
import { Link } from "react-router-dom";

interface CvBoxProps {
  title: string;
  link: string;
  downloadLink?: string;
}
const CvBoxLeft = ({ title, downloadLink, link }: CvBoxProps) => {
  return (
    <div className="border-blue-primary border-2 rounded-xl flex overflow-hidden w-2/3 max-md:w-full group hover:border-orange-primary transition duration-300">
      <div className="w-max-[100px] flex-[0_0_100px] flex flex-wrap items-center justify-center bg-blue-primary group-hover:bg-orange-primary transition duration-300">
        <TbFileCv size={35} className="text-white" />
      </div>
      <div className="p-3 w-full bg-red">
        <div className="font-semibold text-[#333] pb-5 text-center">
          {title}
        </div>
        <div className="flex items-center justify-center gap-1">
          <Link
            to={link}
            className="inline-flex items-center justify-center py-1.5 w-full px-2 gap-2.5 font-medium text-sm text-white bg-blue-primary rounded-3xl transition duration-100 hover:bg-orange-primary"
          >
            <TbEye  size={18} />
            İncele
          </Link>
          {downloadLink && (
            <Link
              to={downloadLink}
              className="inline-flex items-center justify-center py-1.5 w-full px-2 gap-2.5 font-medium text-sm text-white bg-blue-primary rounded-3xl transition duration-100 hover:bg-orange-primary"
            >
              <TbCloudDownload />
              İndir
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
const CvBoxRight = ({ title, downloadLink, link }: CvBoxProps) => {
  return (
    <div className="border-orange-primary border-2 rounded-xl flex overflow-hidden w-2/3 max-md:w-full group hover:border-blue-primary transition duration-300">
      <div className="w-max-[100px] flex-[0_0_100px] flex flex-wrap items-center justify-center bg-orange-primary group-hover:bg-blue-primary transition duration-300">
        <TbFileCv size={35} className="text-white" />
      </div>
      <div className="p-3 w-full">
        <div className="font-semibold text-[#333] pb-5 text-center">
          {title}
        </div>
        <div className="flex items-center justify-center gap-1">
          <Link
            to={link}
            className="inline-flex items-center justify-center py-1.5 px-2 w-full gap-2.5 font-medium text-sm text-white bg-orange-primary rounded-3xl transition duration-100 hover:bg-blue-primary"
          >
            <TbEye size={18} />
            İncele
          </Link>
          {downloadLink && (
            <Link
              to={downloadLink}
              className="inline-flex items-center justify-center py-1.5 px-2 w-full gap-2.5 font-medium text-sm text-white bg-orange-primary rounded-3xl transition duration-100 hover:bg-blue-primary"
            >
              <TbCloudDownload />
              İndir
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

const Cv = () => {
  return (
    <div className=" font-medium pt-5 bg-white">
      <div className="text-center mb-10">
        <h2 className="text-[#333] text-2xl">
          Hazır <span className="text-blue-primary">CV Örnekleri</span> İndir
        </h2>
      </div>
      <div className="flex flex-col lg:flex-row lg:flex-wrap container mx-auto space-y-16 lg:space-y-0 mb-8 ">
        <div className="lg:w-2/4 flex flex-col items-center space-y-5">
          <div className="text-2xl font-medium text-[#333]">
            <h3>CV Örnekleri</h3>
          </div>
          <div className="flex flex-col space-y-5 lg:w-3/4 w-full items-center">
            {/* gidiceği link eklenmeli eklenmediği zaman hata verir */}
            <CvBoxLeft
              link="https://isbul.net/cv-sablonlari/ingilizce-cv"
              title="Boş Cv Örneği"
              downloadLink="https://isbull.s3.eu-north-1.amazonaws.com/cv_sablon/bos-cv.docx"
            />
            <CvBoxLeft
              link="https://isbul.net/cv-sablonlari/ingilizce-cv"
              title="Yeni Mezun Stajyer CV Örneği"
              downloadLink="https://isbull.s3.eu-north-1.amazonaws.com/cv_sablon/yeni-mezun-stajyer-cv-ornegi.docx"
            />
            <CvBoxLeft
              link="https://isbul.net/cv-sablonlari/ingilizce-cv"
              title="İngilizce CV Örneği"
              downloadLink="https://isbull.s3.eu-north-1.amazonaws.com/cv_sablon/ingilizce-cv.docx"
            />
            {/* download link gerektiğinde eklenmesi gerrekli eklendiği zaman indirme buttonu aktif hale gelir */}
          </div>
        </div>
        <div className="lg:w-2/4 flex  flex-col items-center space-y-5">
          <div className="text-2xl font-medium text-[#333]">
            <h3>CV Örnekleri</h3>
          </div>
          <div className="flex flex-col space-y-5 lg:w-3/4 w-full  items-center">
            {/* gidiceği link eklenmeli eklenmediği zaman hata verir */}
            <CvBoxRight
              link="https://isbul.net/cv-sablonlari/ingilizce-cv"
              title="Hazır CV Örneği"
              downloadLink="https://isbull.s3.eu-north-1.amazonaws.com/cv_sablon/hazir-cv.docx"
            />
            <CvBoxRight
              link="https://isbul.net/cv-sablonlari/ingilizce-cv"
              title="Avukat CV Örneği"
              downloadLink="https://isbull.s3.eu-north-1.amazonaws.com/cv_sablon/avukat-cv.docx"
            />
            <CvBoxRight
              link="https://isbul.net/cv-sablonlari/ingilizce-cv"
              title="Bankacılık Sektörü CV Örneği"
              downloadLink="https://isbull.s3.eu-north-1.amazonaws.com/cv_sablon/bankacilik-sektoru-cv.docx"
            />
            {/* download link gerektiğinde eklenmesi gerrekli eklendiği zaman indirme buttonu aktif hale gelir */}
          </div>
        </div>
      </div>
      <div className="bg-[#efefef] pt-5 pb-6">
        <div className="text-center mb-10">
          <h2 className="text-[#333] text-2xl">
            Hazır <span className="text-blue-primary">CV Örnekleri</span> İndir
          </h2>
        </div>
        <div className="flex flex-col items-center container mx-auto space-y-5 lg:flex-row lg:space-y-0 lg:space-x-5 ">
          {/* gidiceği link eklenmeli eklenmediği zaman hata verir */}
          <CvBoxLeft
            link="https://isbul.net/cv-sablonlari/ingilizce-cv"
            title="Ön Yazı Nedir?"
            downloadLink="https://isbull.s3.eu-north-1.amazonaws.com/cv_sablon/on-yazi-nedir.docx"
          />
          <CvBoxLeft
            link="https://isbul.net/cv-sablonlari/ingilizce-cv"
            title="İstifa Mektubu Nasıl Yazılır?"
            downloadLink="https://isbull.s3.eu-north-1.amazonaws.com/cv_sablon/istifa-mektubu-nasil-yazilir.docx"
          />
          <CvBoxLeft
            link="https://isbul.net/cv-sablonlari/ingilizce-cv"
            title="Cover Letter"
            downloadLink="https://isbull.s3.eu-north-1.amazonaws.com/cv_sablon/coverletter.docx"
          />
          {/* download link gerektiğinde eklenmesi gerrekli eklendiği zaman indirme buttonu aktif hale gelir */}
        </div>
      </div>
      <div className="bg-gradient-to-t from-[#2857b0] to-[#0f3479] py-10 text-center">
        <h3 className="font-medium text-white text-xl mb-12">
          isbul.net’te CV oluşturmak ve hemen iş başvurusu yapmak için tıklayın
        </h3>
        <a
          href="/is-ilanlari"
          className="text-white uppercase rounded-lg border text-md  py-3.5 px-16 font-bold"
        >
          İlanlara başvuru
        </a>
      </div>
    </div>
  );
};

export default Cv;
