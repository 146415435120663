import React, { useContext } from "react";
import SearchBar from "../../../../components/common/search-bar";
import { IconChevronRight } from "@tabler/icons-react";
import BreadcrumbJsonLd from "../../../../components/common/BreadcrumbJsonLd";
import { BiSolidMapPin } from "react-icons/bi";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import services from "../../../../services/login-services";
import { IItem } from "../filter-section-content/FilterSectionContentCopy";
import Axios from "../../../../services/axios";
import { JobsPageContext } from "../index";

const AltHeader: React.FC = () => {
  const userToken = Cookies.get("userToken");
  const companyToken = Cookies.get("companyUserToken");
  const axios = new Axios();
  const { setSelectedValues, selectedValues } = useContext(JobsPageContext);

  const fireNearbyError = (isData = false, isCompany = false) => {
    if (isData) {
      if (isCompany) {
        Swal.fire({
          title: "Hata",
          text: "Hesap ayarlarınızdan firma adres bilgilerinizi güncelleyerek yakınımdaki ilanlar özelliğinden yararlanabilirsiniz",
          icon: "error",
        });
      } else {
        Swal.fire({
          title: "Hata",
          text: "Yakınınızdaki iş ilanlarını görebilmek için şehir ve ilçe bilgisini giriniz",
          icon: "error",
        });
      }
    } else {
      Swal.fire({
        title: "Hata",
        text: "Lokasyon verisi alınırken bir hata oluştu",
        icon: "error",
      });
    }
  };

  const handleNewrbyAdverts = () => {
    let total_filter_items = 0;
    Object.keys(selectedValues).forEach((key: any) => {
      if (typeof selectedValues[key] !== "boolean") {
        let item = selectedValues[key] as IItem[];
        total_filter_items += item.length;
      }
    });

    if (userToken) {
      axios
        .userAuthRequest({
          type: "get",
          endpoint: "user/profilim/cv",
          payload: {},
        })
        .then((response: any) => {
          axios
            .userAuthRequest({
              type: "get",
              endpoint:
                "user/profilim/cv/contact/index?cv_id=" + response.data.data.id,
              payload: {},
            })
            .then((response: any) => {
              if (
                response.data.data.city_list &&
                response.data.data.district_list
              ) {
                let go = false;
                if (total_filter_items !== 5) {
                  go = true;
                } else {
                  if (selectedValues.cities.length !== 1) go = true;
                  if (selectedValues.districts.length !== 1) go = true;
                  if (!go) {
                    if (
                      selectedValues.cities[0].id !==
                      response.data.data.city_list.id
                    ) {
                      go = true;
                    }
                    if (
                      selectedValues.districts[0].id !==
                      response.data.data.district_list.id
                    ) {
                      go = true;
                    }
                  }
                }
                if (go) {
                  setSelectedValues({
                    advertDate: [],
                    cities: [response.data.data.city_list],
                    districts: [response.data.data.district_list],
                    disabled: [],
                    experience: [],
                    other: [],
                    positionLevels: [],
                    positions: [],
                    sections: [],
                    sectors: [],
                    workingLocations: [],
                    workingTypes: [],
                    filters: [],
                    company: [],
                    search: [],
                    isFilterUpdated: true,
                  });
                }
              } else {
                fireNearbyError(true);
              }
            })
            .catch(() => {
              fireNearbyError();
            });
        })
        .catch(() => {
          fireNearbyError();
        });
    } else if (companyToken) {
      services
        .checkTokenCompany()
        .then((response: any) => {
          if (
            response.data.company.company_detail.city_id &&
            response.data.company.company_detail.district_id
          ) {
            let go = false;
            if (total_filter_items !== 5) {
              go = true;
            } else {
              if (selectedValues.cities.length !== 1) go = true;
              if (selectedValues.districts.length !== 1) go = true;
              if (!go) {
                if (
                  selectedValues.cities[0].id !==
                  response.data.company.company_detail.city_id
                ) {
                  go = true;
                }
                if (
                  selectedValues.districts[0].id !==
                  response.data.company.company_detail.district_id
                ) {
                  go = true;
                }
              }
            }

            if (go) {
              setSelectedValues({
                advertDate: [],
                cities: [
                  {
                    id: response.data.company.company_detail.city_id,
                    title: "",
                  },
                ],
                districts: [
                  {
                    id: response.data.company.company_detail.district_id,
                    title: "",
                  },
                ],
                disabled: [],
                experience: [],
                other: [],
                positionLevels: [],
                positions: [],
                sections: [],
                sectors: [],
                workingLocations: [],
                workingTypes: [],
                filters: [],
                company: [],
                search: [],
                isFilterUpdated: true,
              });
            }
          } else {
            fireNearbyError(true, true);
          }
        })
        .catch(() => {
          fireNearbyError();
        });
    } else {
      fireNearbyError();
    }
  };

  return (
    <div className="xl:container xl:mx-auto">
      <div className="xl:h-14 w-full md:pb-8 xl:pb-0 mb-2 xl:mt-2 bg-gradient-to-r from-[#1E4691] to-[#0F3479] shadow-custom-blue lg:shadow-none xl:bg-transparent xl:from-transparent xl:to-transparent p-4 xl:p-0 flex flex-col xl:flex-row items-center justify-start">
        <label className="block xl:hidden text-white text-lg font-bold">
          Aradığın işi bulmanın tam zamanı
        </label>
        <div className=" ml-0 w-[90%] h-full py-2 max-w-[750px] ">
          <SearchBar />
        </div>
        {(userToken || companyToken) && (
          <div
            onClick={() => {
              handleNewrbyAdverts();
            }}
            className={
              "xl:hidden pb-3 pt-1 px-3 flex text-sm items-center cursor-pointer text-white text-center"
            }
          >
            <BiSolidMapPin /> Yakınımdaki İşler
          </div>
        )}
      </div>

      <div className="hidden xl:block w-1/2  py-2">
        <div aria-label="Breadcrumb" className="flex items-center">
          <a href="/" aria-label="Anasayfa" className="poppins-regular text-sm">
            Ana Sayfa
          </a>
          <IconChevronRight className="text-blue-700 mx-1" size={18} />
          <a
            href="/is-ilanlari"
            aria-label="İşilanları"
            className="poppins-regular text-sm"
          >
            İş İlanları
          </a>
        </div>
      </div>

      <BreadcrumbJsonLd />

      {/* <p className="text-gray-200 mb-2">51241 İş İlanları</p> */}
    </div>
  );
};

export default AltHeader;
