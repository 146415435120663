import { InputHTMLAttributes, MouseEvent } from "react";
import classNames from "classnames";
import { useContext } from "react";
import { CheckboxContext, checkboxContextType } from ".";

type triggerPropsType = InputHTMLAttributes<HTMLInputElement> & {};

export default function Trigger({
  className,
  onClick,
  ...props
}: triggerPropsType) {
  const { checked, setChecked, error }: checkboxContextType =
    useContext(CheckboxContext);

  const cn = classNames({
    "w-4 h-4 rounded": true,
    "border-gray-400 border": !error,
    "border-red-600 border": error,
  });

  return (
    <input
      {...props}
      type="checkbox"
      checked={checked}
      className={`${cn} ${className}`}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
        setChecked((prev) => !prev);
      }}
    />
  );
}
