import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "../../../../../store/store";
import CheckboxInfo from "../checkbox-info";
import { INotificationItem } from "../../../../../store/content-reducer/notificationSlice";
import CustomButton from "../custom-button";
import { postSms } from "../../../../../store/content-reducer/notificationSlice";
import Swal from "sweetalert2";

const SmsNotification = () => {
  const smsInfo: INotificationItem[] = useAppSelector(
    (state: any) => state.notification
  );
  const dispatch = useAppDispatch();
  const sms: INotificationItem[] = smsInfo.filter(
    (item: INotificationItem) => item.code === "sms"
  );

  const [smsIsChecked, setSmsIsChecked] = useState<{
    [key: string]: number | null;
  }>({});
  const [initialSms, setInitialSms] = useState<{
    [key: string]: number | null;
  }>({});

  useEffect(() => {
    const initialSmsState: { [key: string]: number | null } = {};
    sms.forEach((item: any) => {
      initialSmsState[item.code] = item.status !== null ? item.status : 0;
    });
    setSmsIsChecked(initialSmsState);
    setInitialSms(initialSmsState);
  }, []); // Empty dependency array ensures this runs only once

  const handleValues = (code: string) => {
    setSmsIsChecked((prev) => {
      const currentValue = prev[code];
      const newValue = currentValue === 1 ? 0 : 1;
      return {
        ...prev,
        [code]: newValue,
      };
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSave();
  };

  const handleSave = () => {
    const hasChanges = Object.keys(smsIsChecked).some(
      (key) => smsIsChecked[key] !== initialSms[key]
    );

    if (hasChanges) {
      dispatch(postSms(smsIsChecked)).then(() => {
        setInitialSms({ ...smsIsChecked }); // Güncelleme başarılı olduğunda initialSms'i güncelle
        Swal.fire({
          title: "Başarılı",
          text: "Sms bildirim seçimleriniz başarıyla güncellendi.",
          icon: "success",
          confirmButtonText: "Tamam",
        });
      });
    } else {
      Swal.fire({
        title: "Bilgilendirme",
        text: "Durum değişmediği için güncelleme yapılmadı.",
        icon: "info",
        confirmButtonText: "Tamam",
      });
    }
  };

  return (
    <form className="flex flex-col" onSubmit={handleSubmit}>
      {sms.map((item: INotificationItem) => (
        <CheckboxInfo
          key={item.code}
          content={item.description}
          isChecked={smsIsChecked[item.code]}
          isOnChange={() => handleValues(item.code)}
        />
      ))}
      <div className="flex justify-end">
        <div>
          <CustomButton
            handleClick={handleSave}
            title="KAYDET"
            designs="py-2 px-3 custom-btn mt-3 mr-2"
          />
        </div>
      </div>
    </form>
  );
};

export default SmsNotification;
