import { GeneralPackages } from "../login-page-container/company/login/general-packages";
import { PackagesContents } from "../login-page-container/company/login/general-packages/packages-contents";
import Comments from "./components/Comments";
import FreeAdvertSection from "./components/FreeAdvertSection";
import Hero from "./components/Hero";
import ModernSection from "./components/ModernSection";
import OrangeSection from "./components/OrangeSection";
import RegisterSection from "./components/RegisterSection";
import StepsSection from "./components/StepsSection";

export default function FreeAdvertPageContainer() {
  return (
    <div className="flex flex-col gap-4 bg-[#f5f5f5]">
      {/* Hero section */}
      <Hero />

      <FreeAdvertSection />

      <ModernSection />

      <RegisterSection />

      <StepsSection />

      <OrangeSection />

      <div className="container mx-auto">
        <div className="p-6 bg-white shadow-md">
          <h2 className="text-2xl poppins-semibold text-title">
            İLAN PAKETLERİ
          </h2>
        </div>
      </div>

      <div className="container flex flex-col gap-4 mx-auto">
        <GeneralPackages />
      </div>

      <div className="container mx-auto mb-20">
        <div className="p-6 bg-white shadow-md">
          <h2 className="text-2xl poppins-semibold text-title">
            MÜŞTERİ YORUMLARI
          </h2>
        </div>
        <Comments />
      </div>
    </div>
  );
}
