import { RadioComponent } from "../../../components/radio-component-new";
import { IItem, ITitle } from "../../../../../types";
import React, { useContext, useEffect, useState } from "react";

import {
  ApplyPoolContext,
  CandidatePoolFilterProps,
} from "../../../../../index";
import useFetchCdnQuery from "../../../../../../../../hooks/useFetchCdnQuery";
import { SelectComponent } from "../../../components/select-component-new";
import MultiSelect from "../../../components/multi-select-component-new";

interface SpecialCasesSectionProps {
  isVisible?: boolean;
}

function SpecialCasesSection({ isVisible = false }: SpecialCasesSectionProps) {
  const { newSelectedValues, setNewSelectedValues } =
    useContext(ApplyPoolContext);

  const [disabledVisible, setDisabledVisible] = useState<boolean>(false);

  const disabilityLevelData: any = Array.from({ length: 95 }, (_, i) => ({
    id: i + 5,
    title: `%${i + 5}`,
  }));

  //DISABILITIY TYPES DATA
  const [disabilityTypesData, setDisabilityTypesData] = useState<any>([]);
  const [disabilityTypeSearchTerm, setDisabilityTypeSearchTerm] = useState("");
  const disabilityTypeQuery = useFetchCdnQuery(
    "disabilityTypes",
    "get-disability-types",
    "disability_types",
    disabilityTypeSearchTerm
  );
  useEffect(() => {
    if (disabilityTypeQuery.data !== undefined) {
      setDisabilityTypesData([...disabilityTypeQuery?.data]);
    }
  }, [disabilityTypeQuery.data, disabilityTypeQuery.fetchStatus]);

  useEffect(() => {
    const disabled = newSelectedValues.disabled_status;

    if (disabled === 1) {
      setDisabledVisible(true);
    } else {
      setDisabledVisible(false);
    }
  }, [newSelectedValues]);

  const distabilitesData: any = [
    { label: "Fark Etmez", id: 0 },
    { label: "Var", id: 1 },
    { label: "Yok", id: 2 },
  ];

  return (
    <section
      className={`text-gray-600 mx-1 my-8 md:mx-2  ${
        isVisible ? "" : "hidden xl:hidden"
      } xl:grid xl:grid-cols-3 xl:gap-5`}
    >
      <RadioComponent
        id={"disabledstatus"}
        keyString={"label"}
        header="Engel Durumu"
        data={distabilitesData}
        onChange={(value) => {
          let newValues: CandidatePoolFilterProps = {
            ...newSelectedValues,
            disabled_status: value,
          };
          if (value === 0) {
            delete newValues.disabled_status;
          }
          setNewSelectedValues(newValues);
        }}
        selected={newSelectedValues.disabled_status}
      />

      {disabledVisible && (
        <div className="flex flex-col w-full gap-2 max-xl:my-5">
          <label className="text-[#6b7280] text-sm">Engel Kategorisi</label>
          <MultiSelect
            isFetching={disabilityTypeQuery.isFetching}
            keyString={"title"}
            data={disabilityTypesData}
            label=""
            onChange={(value) => {
              let newValues: CandidatePoolFilterProps = {
                ...newSelectedValues,
                disability_types: value,
              };
              if (value.length <= 0) {
                delete newValues.disability_types;
              }
              setNewSelectedValues(newValues);
            }}
            selectedValues={
              newSelectedValues.disability_types
                ? newSelectedValues.disability_types
                : []
            }
            setSearchData={setDisabilityTypeSearchTerm}
            searchData={disabilityTypeSearchTerm}
          />
        </div>
      )}
      {disabledVisible && (
        <div className="flex flex-col w-full gap-2 ">
          <label className="text-[#6b7280] text-sm">Engel Yüzdesi</label>
          <SelectComponent
            data={disabilityLevelData}
            keyString={"title"}
            label=""
            placeholder="Seçiniz"
            onChange={(value) => {
              let newValues: CandidatePoolFilterProps = {
                ...newSelectedValues,
                disability_percent: value as number,
              };
              if (value === 0) {
                delete newValues.disability_percent;
              }
              setNewSelectedValues(newValues);
            }}
            selectedvalue={newSelectedValues.disability_percent}
          />
        </div>
      )}
    </section>
  );
}

export { SpecialCasesSection };
