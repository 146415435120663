import { useEffect, useState } from "react";
import CustomModal from "../../../../components/common/custom-modal";
import FormField from "../../../../components/common/form-field";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "../../../../services/axios";
import { useDispatch } from "react-redux";
import { getFolders } from "../../../../store/features/companyFoldersSlice";
import { CgSpinner } from "react-icons/cg";
import Swal from "sweetalert2";
type Props = {
  item: any;
  open: any;
  setOpen: any;
};

const axios = new Axios();

const EditModal = (props: Props) => {
  const dispatch = useDispatch<any>();

  const formik = useFormik({
    initialValues: {
      title: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Klasör adını giriniz"),
    }),
    onSubmit: async (values) => {
      await axios.authRequest({
        endpoint: `companies/folder/${props.item.id}/update`,
        type: "put",
        payload: {
          _method: "PUT",
          title: values.title,
        },
      });
      await dispatch(getFolders());
      Swal.fire({
        icon: "success",
        title: "Klasör Düzenlendi!",
        timer: 3000,
      });
      props.setOpen(false);
    },
  });
  useEffect(() => {
    formik.setFieldValue("title", props.item.title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <CustomModal
        open={props.open}
        onClose={() => props.setOpen(false)}
        title="Klasör Düzenle"
        acceptLabel={
          formik.isSubmitting ? (
            <CgSpinner className="animate-spin text-lg" />
          ) : (
            "Düzenle"
          )
        }
        closeLabel="İptal"
        onAccept={formik.handleSubmit}
        content={
          <form onSubmit={formik.handleSubmit}>
            <div>
              <FormField
                type="text"
                name="title"
                label="Klasör Adı"
                required
                value={formik.values.title}
                onChange={formik.handleChange}
                invalid={formik.errors.title?.length}
              />
            </div>
          </form>
        }
      />
    </>
  );
};

export default EditModal;
