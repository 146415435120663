import Axios from "../axios";

const PackageService = {
  getPackage: async () => {
    const axios = new Axios();
    return axios.request({
      type: "get",
      endpoint: "cdn/get-packets",
      payload: {},
    });
  },
};

export { PackageService };
