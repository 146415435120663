import Axios from "../services/axios"
import { cdnCertificateType } from "../types"



type getCertificatesPropsType = {
    search?: string,
    limit?: number
}



const axios = new Axios()



export async function getCertificates({
    search,
    limit
}: getCertificatesPropsType): Promise<cdnCertificateType[]> {
    try {
        const response = await axios.request({
            endpoint: `cdn/get-certificates?
            ${search? `search=${search}&`: ``}
            ${limit? `limit=${limit}&`: ''}
            `,
            type: 'get',
            payload: {}
        })

        if (response && response.data && response.data.certificates) {
            return JSON.parse(JSON.stringify(response.data.certificates))
        } else {
            return []
        }
    } catch (error) {
        console.log(`Certificates fetch failed. Error: ${error}`)
        return []
    }
}