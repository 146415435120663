import { LabelHTMLAttributes, createElement, useContext } from "react"
import { MultiSelectSearchDropdownContext, multiSelectSearchDropdownContextType } from "."





type valuePropsType = LabelHTMLAttributes<HTMLLabelElement> & {

}






export default function Value({
    className,
    children,
    ...props
}: valuePropsType) {

    const { isOpen, mode, error }: multiSelectSearchDropdownContextType = useContext(MultiSelectSearchDropdownContext)

    if (isOpen) {
        return null
    } else {
        return createElement('label', {
            className: `${className} ${mode == 'empty'? 'text-gray-500': 'text-blue-600'} ${error? 'text-red-600': ''} font-poppins whitespace-nowrap overflow-hidden text-ellipsis`,
            ...props
        }, children)
    }
}