import { useFormatDate } from "../../../../hooks/useFormatDate";
import ActionsPopover from "./ActionsPopover";

export type Folder = {
	title: string;
	company_folder_cvs_count: number;
	id: number;
	created_at: string;
	is_archived: boolean;
};

export const FolderCard = ({ folder }: { folder: Folder }) => (
	<div className='flex items-center justify-between p-4 mb-3 bg-white rounded-lg shadow'>
		<div>
			<div className='text-sm font-semibold'>{folder.title}</div>
			<div className='text-xs text-gray-600'>
				Toplam Aday: {folder.company_folder_cvs_count}
			</div>
			<div className='text-xs'>{useFormatDate(folder.created_at)}</div>
		</div>
		<ActionsPopover item={folder} />
	</div>
);
