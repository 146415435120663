import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./features/userSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { companyUserSlice } from "./features/companyUserSlice";
import { addressSlice } from "./features/addressSlice";
import userInfoReducer from "./content-reducer/personInfoSlice";
import notificationReducer from "./content-reducer/notificationSlice";
import { companyFoldersSlice } from "./features/companyFoldersSlice";
import renderReducer from "./content-reducer/smsPageSlice";
import cvDetailReducer from "./cv-reducer/cvDetailSlice";
import cvEditSlice from "./cv-reducer/cvEditSlice";
import advertReducer from "./features/advertReducer";

export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    companyUser: companyUserSlice.reducer,
    address: addressSlice.reducer,
    userInfo: userInfoReducer,
    notification: notificationReducer,
    companyFolders: companyFoldersSlice.reducer,
    renderSmsPage: renderReducer,
    cvDetail: cvDetailReducer,
    cvEdit: cvEditSlice,
    advert: advertReducer,
  },
});

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector;
