import { ReactNode, useContext } from "react";
import { Button } from "../index";
import cn from "classnames";
import { TabContext } from "./context";

interface TabProps {
  children: ReactNode;
  id: string;
  className?: string;
}

const Tab: React.FC<TabProps> = ({ children, id, className }) => {
  const context = useContext(TabContext);
  if (!context) throw new Error("Tab must be used within a TabContainer");
  const { activeTab, setActiveTab } = context;

  const isActive = activeTab === id;

  return (
    <Button
      type="button"
      className={cn("w-full", className)}
      variant={isActive ? "ghost" : "secondary"}
      size="sm"
      onClick={() => setActiveTab(id)}
    >
      {children}
    </Button>
  );
};

export default Tab;
