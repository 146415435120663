import React from 'react';

const SimilerJops: React.FC = () => {
    const jobsData = [
        { title: 'Yazılım Mühendisi', description: 'Ortalama maaş: 20.000 TL' },
        { title: 'Veri Bilimci', description: 'Ortalama maaş: 25.000 TL' },
        { title: 'Sistem Yöneticisi', description: 'Ortalama maaş: 18.000 TL' },
    ];

    return(
        <div className="bg-white p-4 shadow rounded-lg space-y-4">
            <h2 className='font-medium text-xl mb-4'>Benzer Mesleklerin Maaşları</h2>
            {jobsData.map((job, index) => (
                <div key={index} className="bg-gray-100 p-4 rounded-lg">
                <a href='#'>
                    <h3 className='font-semibold text-lg'>{job.title}</h3>
                    <p>{job.description}</p>
                </a>
                </div>
            ))}
        </div>
    );
};

export default SimilerJops;
