import * as React from "react";
import { LeftSide } from "./left-side";
import { RightSide } from "./right-side";

interface LoginContentProps {
	readonly type: string;
	readonly categories: string;
}

function LoginContent(props: LoginContentProps): React.ReactElement {
	const { type, categories } = props;

	const href = typeof window !== "undefined" ? window.location.href : "";
	const url = new URL(href).pathname;
	const showLeftSide = !url.includes("/profilim/giris-yap");
	const showLeftSideTopCard = url.includes("/isveren/kayit-ol");

	return (
		<div className='flex bg-white md:bg-inherit gap-x-10'>
			{showLeftSide && <LeftSide showLeftSideTopCard={showLeftSideTopCard} />}
			<RightSide
				type={type}
				categories={categories}
			/>
		</div>
	);
}

export { LoginContent };
