import React, { ChangeEvent, useState, useEffect, useRef } from "react";
import { MdExpandMore } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import SideRights from "../../../../containers/company-page-container/creat-ads-page/Database/SideRights";
import DriverLicenses from "../../../../containers/company-page-container/creat-ads-page/Database/DriverLicenses";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { Tooltip } from "antd";
import { DataContextType } from "../../../../store/contextApi/ad-api-context/Iget-ad-data";
import { FaCircle } from "react-icons/fa6";
import {
  cdnDrivingLicenseSrcType,
  cdnDrivingLicesesType,
} from "../../../../types";
import { getDriverLicenseClasses } from "../../../../customHooks/getDriverLicenseClasses";
import { getDrivingLicenseSrcs } from "../../../../customHooks/getDrivingLicenseSrcs";
interface Option {
  value: string;
  label: string;
}
interface ExtraInformationProps {
  onYanHaklarChange: (selectedOptions: Option[]) => void;
  onSurucuLisanslariChange: (selectedOptions: Option[]) => void;
  onSrcLisanslariChange: (selectedOptions: Option[]) => void;
  handleIsSendMessageUsersChange: (
    event: ChangeEvent<HTMLInputElement>
  ) => void;
  handleIsDisabledEmployeeChange: (
    event: ChangeEvent<HTMLInputElement>
  ) => void;
  contextData?: DataContextType;
}
const ExtraInformation: React.FC<ExtraInformationProps> = ({
  onYanHaklarChange,
  onSurucuLisanslariChange,
  onSrcLisanslariChange,
  handleIsSendMessageUsersChange,
  handleIsDisabledEmployeeChange,
  contextData,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const yanHaklarRef = useRef(null);
  const surucuLisanslariRef = useRef(null);
  const classesDivRef = useRef<HTMLDivElement>(null);

  const [driverLicenseClasses, setDriverLicenseClasses] = useState<Option[]>(
    []
  );
  const [driverLicenseSrcs, setDriverLicenseSrcs] = useState<Option[]>([]);

  async function getAllCdns() {
    const [cdnClasses, cdnSrcs] = await Promise.all([
      getDriverLicenseClasses({ limit: 20 }),
      getDrivingLicenseSrcs({}),
    ]);

    setDriverLicenseClasses(
      cdnClasses.map((c) => {
        return {
          label: c.name,
          value: c.id.toString(),
        };
      })
    );
    setDriverLicenseSrcs(
      cdnSrcs.map((s) => {
        return {
          label: s.name,
          value: s.id.toString(),
        };
      })
    );
  }
  useEffect(() => {
    getAllCdns();
  }, []);

  useOutsideClick(yanHaklarRef, () => setYanHaklarOpen(false));
  useOutsideClick(surucuLisanslariRef, () => setSurucuLisanslariOpen(false));
  useOutsideClick(classesDivRef, () => setClassesOpen(false));


  if (contextData && contextData.data?.driving_licence_srcs) {
    const filteredSrcs = contextData.data.driving_licence_srcs.filter(
      d => d.driving_licence_src !== null
    );
  
    contextData.data.driving_licence_srcs = filteredSrcs;
  
  
  }



  console.log('driving_licence_srcs', contextData?.data?.driving_licence_srcs)

  // States for Yan Haklar and Sürücü Lisansları dropdowns
  const [yanHaklarOpen, setYanHaklarOpen] = useState<boolean>(false);

  const [selectedYanHaklar, setSelectedYanHaklar] = useState<Option[]>(
    contextData
      ? contextData.data?.job_benefits.map((j) => {
          return {
            label: j.job_benefit.name,
            value: j.job_benefit.id.toString(),
          };
        }) != undefined
        ? contextData.data?.job_benefits.map((j) => {
            return {
              label: j.job_benefit.name,
              value: j.job_benefit.id.toString(),
            };
          })
        : []
      : []
  );

  const [classesOpen, setClassesOpen] = useState<boolean>(false);
  const [surucuLisanslariOpen, setSurucuLisanslariOpen] =
    useState<boolean>(false);
  const [selectedSurucuLisanslari, setSelectedSurucuLisanslari] = useState<
    Option[]
  >(
    contextData
      ? contextData.data?.driving_licence_classes.map((d) => {
          return {
            label: d.driving_licence_class?.name,
            value: d.driving_licence_class?.id.toString(),
          };
        }) != undefined
        ? contextData.data?.driving_licence_classes.map((d) => {
            /* FIXME: Burada bir hata var */
            return {
              label: d.driving_licence_class?.name,
              value: d.driving_licence_class?.id?.toString(),
            };
          })
        : []
      : []
  );

  /* FIXME: context yapısına göre düzenleme yapılacak */
  const [selectedSrcs, setSelectedSrcs] = useState<Option[]>(
    contextData
      ? contextData.data?.driving_licence_srcs.map((d) => {
          return {
            label: d.driving_licence_src?.name,
            value: d.driving_licence_src?.id.toString(),
          };
        }) != undefined
        ? contextData.data?.driving_licence_srcs.map((d) => {
            /* FIXME: Burada bir hata var */
            return {
              label: d.driving_licence_src?.name,
              value: d.driving_licence_src?.id?.toString(),
            };
          })
        : []
      : []
  );


  const handleSelectOption = (
    optionsArray: Option[],
    setOptions: React.Dispatch<React.SetStateAction<Option[]>>,
    option: Option
  ) => {
    const isSelected = optionsArray.some(
      (selectedOption) => selectedOption.value === option.value
    );
    if (isSelected) {
      setOptions(
        optionsArray.filter(
          (selectedOption) => selectedOption.value !== option.value
        )
      );
    } else {
      setOptions([...optionsArray, option]);
    }
  };

  const getSelectedOptionsLabel = (optionsArray: Option[]): string => {
    const selectedCount = optionsArray.length;
    if (selectedCount > 0) {
      let label = optionsArray[0].label;
      /* if (selectedCount > 1) {
        label += `, ${optionsArray[1].label}`;
        if (selectedCount > 2) {
          label += ` +${selectedCount - 2}`;
        }
      } */
      if (selectedCount > 1) {
        label += ` (+${selectedCount - 1})`;
      }
      return label;
    } else {
      return "Seçiniz";
    }
  };

  const handleSelectYanHaklar = (option: Option) => {
    const updatedOptions = selectedYanHaklar.some(
      (o) => o.value === option.value
    )
      ? selectedYanHaklar.filter((o) => o.value !== option.value)
      : [...selectedYanHaklar, option];
    setSelectedYanHaklar(updatedOptions);
    onYanHaklarChange(updatedOptions);
  };

  const handleSelectSurucuLisanslari = (option: Option) => {
    const updatedOptions = selectedSurucuLisanslari.some(
      (o) => o.value === option.value
    )
      ? selectedSurucuLisanslari.filter((o) => o.value !== option.value)
      : [...selectedSurucuLisanslari, option];
    setSelectedSurucuLisanslari(updatedOptions);
    onSurucuLisanslariChange(updatedOptions);
  };

  const handleSelectSrcLisanslari = (option: Option) => {
    const updatedOptions = selectedSrcs.some((o) => o.value === option.value)
      ? selectedSrcs.filter((o) => o.value !== option.value)
      : [...selectedSrcs, option];
    setSelectedSrcs(updatedOptions);
    onSrcLisanslariChange(updatedOptions);
  };

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="mb-1 w-full z-[1]">
      <div
        className="w-full bg-white p-4 cursor-pointer mb-2 rounded-md shadow-md hidden md:flex justify-between items-center"
        onClick={toggleOpen}
      >
        <h2 className="font-medium text-[#2253AF] md:text-2xl pl-6">
          İlan Ekstra Bilgiler{" "}
        </h2>
        <MdExpandMore
          className={`transition-transform duration-500 text-[#69737f] ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
          size={30}
        />
      </div>
      {isOpen && (
        <div
          className="w-full rounded drop-shadow-md bg-[#FFF] flex flex-col transition-all p-8"
          id="ilanEkstraBilgilerDropdown"
        >
          <div className="w-full flex flex-col md:flex-row">
            {/* Yan Haklar Dropdown */}
            <div
              className="relative flex flex-col w-full md:mx-1 mb-4 md:mb-0 text-gray-500"
              ref={yanHaklarRef}
            >
              <label
                htmlFor="yanHaklarDropdown"
                className="font-semibold text-gray-700 text-[13px] font-poppins mb-2 flex justify-start items-center gap-1"
              >
                Yan Haklar
                <Tooltip title="İlanınızdaki yan hakları seçiniz">
                  <IoIosInformationCircleOutline className="text-[#6B7280] text-lg " />
                </Tooltip>
              </label>
              <div
                className={`w-full cursor-pointer border ${
                  selectedYanHaklar.length > 0
                    ? "border-blue-500"
                    : "border-gray-400"
                } ${
                  yanHaklarOpen ? "shadow-form-glow" : ""
                } rounded-md py-2 px-3 flex justify-between items-center`}
                onClick={() => setYanHaklarOpen(!yanHaklarOpen)}
              >
                <span
                  className={`${
                    selectedYanHaklar.length > 0
                      ? "text-blue-500"
                      : "text-gray-500"
                  } text-sm`}
                >
                  {getSelectedOptionsLabel(selectedYanHaklar)}
                </span>
                <IoIosArrowDown
                  className={`transition-transform duration-300 ${
                    yanHaklarOpen ? "rotate-180" : ""
                  }`}
                />
              </div>
              {yanHaklarOpen && (
                <div className="absolute bottom-0 left-0 translate-y-full z-50 w-full bg-white border border-gray-400 rounded-md mt-1 max-h-32 overflow-y-auto ">
                  {SideRights.map((option) => (
                    <div
                      key={option.value}
                      onClick={() => handleSelectYanHaklar(option)}
                      className="py-2 px-4 hover:bg-gray-100 flex items-center cursor-pointer"
                    >
                      <span
                        className={`h-4 w-4 mr-2 flex rounded-full items-center justify-center border ${
                          selectedYanHaklar.some(
                            (selectedOption) =>
                              selectedOption.value === option.value
                          )
                            ? "border-blue-500"
                            : "border-gray-400"
                        }`}
                      >
                        {selectedYanHaklar.some(
                          (selectedOption) =>
                            selectedOption.value == option.value
                        ) && <FaCircle className="text-blue-500 text-[8px]" />}
                      </span>
                      {option.label}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Driver classes */}
            <div
              className="relative flex flex-col w-full mb-4 md:mb-0 md:mx-1 text-gray-500"
              ref={classesDivRef}
            >
              <label
                htmlFor="surucuLisanslariDropdown"
                className="font-semibold text-gray-700 text-[13px] font-poppins mb-2 flex justify-start items-center gap-1"
              >
                Sürücü Lisansları
                <Tooltip title="İlanınızdaki sürücü lisanslarını seçiniz">
                  <IoIosInformationCircleOutline className="text-[#6B7280] text-lg " />
                </Tooltip>
              </label>
              <div
                className={`w-full cursor-pointer border ${
                  selectedSurucuLisanslari.length > 0
                    ? "border-blue-500"
                    : "border-gray-400"
                } ${
                  classesOpen ? "shadow-form-glow" : ""
                } rounded-md py-2 px-3 flex justify-between items-center`}
                onClick={() => setClassesOpen((prev) => !prev)}
              >
                <span
                  className={`${
                    selectedSurucuLisanslari.length > 0
                      ? "text-blue-500"
                      : "text-gray-500"
                  } text-sm`}
                >
                  {getSelectedOptionsLabel(selectedSurucuLisanslari)}
                </span>
                <IoIosArrowDown className={`${classesOpen? 'rotate-180': ''} transition-all`}/>
              </div>
              {classesOpen && (
                //Burada max-h-40 değeri z index çalışmadığı için yaptın tekrar bak
                <div className="absolute bottom-0 left-0 translate-y-full z-50 w-full bg-white border border-gray-400 rounded-md mt-1 max-h-40 lg:max-h-32 overflow-y-auto">
                  {driverLicenseClasses.map((option) => (
                    <div
                      key={option.value}
                      onClick={() => handleSelectSurucuLisanslari(option)}
                      className="py-2 px-4 hover:bg-gray-100 flex items-center cursor-pointer"
                    >
                      <span
                        className={`h-4 w-4 mr-2 flex items-center justify-center rounded-full border ${
                          selectedSurucuLisanslari.some(
                            (selectedOption) =>
                              selectedOption.value === option.value
                          )
                            ? "border-blue-500"
                            : "border-gray-400"
                        }`}
                      >
                        {selectedSurucuLisanslari.some(
                          (selectedOption) =>
                            selectedOption.value == option.value
                        ) && (
                          <FaCircle className="flex text-blue-500 text-[8px]" />
                        )}
                      </span>
                      {option.label}
                    </div>
                  ))}
                </div>
              )}
            </div>

            {/* Srcler Dropdown */}
            <div
              className="relative flex flex-col w-full mb-4 md:mb-0 md:mx-1 text-gray-500"
              ref={surucuLisanslariRef}
            >
              <label
                htmlFor="surucuLisanslariDropdown"
                className="font-semibold text-gray-700 text-[13px] font-poppins mb-2 flex justify-start items-center gap-1"
              >
                SRC Belgeleri
                <Tooltip title="İlanınızdaki SRC belgelerini seçiniz">
                  <IoIosInformationCircleOutline className="text-[#6B7280] text-lg " />
                </Tooltip>
              </label>
              <div
                className={`w-full cursor-pointer border ${
                  selectedSrcs.length > 0
                    ? "border-blue-500"
                    : "border-gray-400"
                } ${
                  surucuLisanslariOpen ? "shadow-form-glow" : ""
                } rounded-md py-2 px-3 flex justify-between items-center`}
                onClick={() => setSurucuLisanslariOpen(!surucuLisanslariOpen)}
              >
                <span
                  className={`${
                    selectedSrcs.length > 0 ? "text-blue-500" : "text-gray-500"
                  } text-sm`}
                >
                  {getSelectedOptionsLabel(selectedSrcs)}
                </span>
                <IoIosArrowDown className={`${surucuLisanslariOpen ? 'rotate-180' : ''} transition-all`}/>
              </div>
              {surucuLisanslariOpen && (
                //Burada max-h-40 değeri z index çalışmadığı için yaptın tekrar bak
                <div className="absolute bottom-0 left-0 translate-y-full z-10 w-full bg-white border border-gray-400 rounded-md mt-1 max-h-40 lg:max-h-32 overflow-y-auto">
                  {driverLicenseSrcs.map((option) => (
                    <div
                      key={option.value}
                      onClick={() => handleSelectSrcLisanslari(option)}
                      className="py-2 px-4 hover:bg-gray-100 flex items-center cursor-pointer"
                    >
                      <span
                        className={`h-4 w-4 mr-2 flex items-center justify-center rounded-full border ${
                          selectedSrcs.some(
                            (selectedOption) =>
                              selectedOption.value === option.value
                          )
                            ? "border-blue-500"
                            : "border-gray-400"
                        }`}
                      >
                        {selectedSrcs.some(
                          (selectedOption) =>
                            selectedOption.value == option.value
                        ) && (
                          <FaCircle className="flex text-blue-500 text-[8px]" />
                        )}
                      </span>
                      {option.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Other Inputs */}
          <div className="w-full flex flex-col md:flex-row mt-6">
            <div className="group w-full md:w-1/2 flex flex-col">
              <div className="handicap-field mb-4">
                <p className="font-semibold text-gray-500 text-[13px] sm:text-md lg:text-base mb-4 md:mb-2">
                  Başvuran Adaylar Mesaj Gönderebilsin Mi?
                  <em className="text-red-500">*</em>
                </p>
                <div className="flex">
                  <div className="flex items-center mr-4">
                    <input
                      id="is_send_message_users_1"
                      name="is_send_message_users"
                      type="radio"
                      className="w-4 h-4 scale-150"
                      defaultValue={
                        contextData
                          ? //TODO: Bu kısım hayır seçilip gönderilip tekrar denenecek
                            contextData.data?.advert.is_send_message_users ==
                            true
                            ? 1
                            : 0
                          : 0
                      }
                      defaultChecked={
                        contextData
                          ? contextData.data?.advert.is_send_message_users ==
                            true
                            ? true
                            : false
                          : true
                      }
                      onChange={handleIsSendMessageUsersChange}
                    />
                    <label
                      className="font-medium text-gray-500 ml-2"
                      htmlFor="is_send_message_users_1"
                    >
                      Evet
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="is_send_message_users_0"
                      name="is_send_message_users"
                      type="radio"
                      defaultValue={
                        contextData
                          ? contextData.data?.advert.is_disabled_employee ==
                            true
                            ? 1
                            : 0
                          : 0
                      }
                      defaultChecked={
                        contextData
                          ? contextData.data?.advert.is_send_message_users ==
                            false
                            ? true
                            : false
                          : false
                      }
                      className="w-4 h-4 scale-150"
                      onChange={handleIsSendMessageUsersChange}
                    />
                    <label
                      className="font-medium text-gray-500 ml-2"
                      htmlFor="is_send_message_users_0"
                    >
                      Hayır
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="group w-full md:w-1/2 mt-4 md:mt-0">
              <div className="checkbox mb-4">
                <input
                  id="checkbox_is_disabled_employee"
                  name="is_disabled_employee"
                  type="checkbox"
                  className="w-4 h-4 scale-150"
                  defaultChecked={
                    contextData
                      ? contextData.data?.advert.is_disabled_employee == true
                        ? true
                        : false
                      : false
                  }
                  onChange={handleIsDisabledEmployeeChange}
                />
                <label
                  className="font-medium text-gray-500 ml-2 "
                  htmlFor="checkbox_is_disabled_employee"
                >
                  Engelli Personel İlanıdır
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExtraInformation;
