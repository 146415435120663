/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useMemo, useState } from "react";
import ApplicationPopupListItem from "../application-popup-list-item";
import { AdvertService } from "../../../../../services/advert-services";
import { useFormatDate } from "../../../../../hooks/useFormatDate";
import { Link } from "react-router-dom";

interface IApplicationPopup {
  advertId: number;
  isPopup: boolean;
}

interface IDetails {
  detail: string;
  experienceLevel: string;
  position: string;
  sector: string;
  section: string;
  city: string;
  createdAt: string;
  updatedAt: string;
  endDate: string;
}

const ApplicationPopup: React.FC<IApplicationPopup> = (
  props: IApplicationPopup
) => {
  const [details, setDetails] = useState<IDetails>();
  const [advertUrl, setAdvertUrl] = useState<string>('')

  console.log(props.advertId);

  useEffect(() => {
    const getAdvertDetail = async () => {
      const res = await AdvertService.getAdvertDetailById(props.advertId);

      //console.log(res);
      //console.log('res', res)

      if (res && res.data && res.data.data && res.data.data.advert && res.data.data.advert.slug) {
        setAdvertUrl(res.data.data.advert.slug)
      }
      setDetails({
        detail: res.data?.data?.advert?.detail,
        experienceLevel:
          res.data?.data?.advert_experience_level?.experience_level_min?.title,
        position: res.data?.data?.positions[0]?.title,
        sector: res.data?.data?.sectors[0]?.title,
        section: res.data?.data?.sections[0]?.title,
        city: res.data?.data?.cities[0]?.name,
        createdAt: useFormatDate(res.data?.data?.advert?.created_at),
        updatedAt: useFormatDate(res.data?.data?.advert?.updated_at),
        endDate: useFormatDate(res.data?.data?.advert?.end_date),
      });
    };

    !details && props.isPopup && getAdvertDetail();
  }, [details, props.advertId, props.isPopup]);

  //console.log(details);

  const detailQualificationItems = useMemo(() => {
    return [
      { key: "Tecrübe", value: details?.experienceLevel || "" },
      { key: "Pozisyon", value: details?.position || "" },
      { key: "Sektör", value: details?.sector || "" },
      { key: "Bölüm", value: details?.section || "" },
      { key: "Çalışma Yeri", value: details?.city || "" },
    ];
  }, [details]);

  //console.log(details);

  return (
    <div
      className={`absolute  h-72 w-4/5 sm:w-[550px] md:w-[650px] lg:w-[400px] xl:w-[550px] left-0 md:left-10 lg:left-12 xl:left-12 top-[110px]  ${
        details ? "hidden group-hover:block" : "hidden"
      } bg-white border-2  border-mains z-10 pl-4 pt-6`}
    >
      <div className="triangle top-[-20px] absolute left-5" />
      <div className="w-full h-full overflow-y-scroll">
        <div
          dangerouslySetInnerHTML={{ __html: details?.detail || "" }}
          className="flex-1 px-2"
        />
        <div className="w-full h-px mt-6 bg-gray-300" />
        <ApplicationPopupListItem
          createdAt={details?.createdAt || ""}
          updatedAt={details?.updatedAt || ""}
          endDate={details?.endDate || ""}
          keys={detailQualificationItems}
        />
        <div className="w-full h-px mt-6 mb-12 bg-gray-300" />
      </div>

      <Link
      to={`/is-ilanlari/${advertUrl}`}
      className="absolute bottom-0 right-0 flex items-center justify-center h-12 w-36 bg-mains">
        <span className="text-lg font-semibold text-white">Başvur</span>
      </Link>
    </div>
  );
};

export default ApplicationPopup;
