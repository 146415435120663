import Axios from "../services/axios";
import { cdnPositionType } from "../types";

type getPositionsType = {
  search?: string;
  limit?: number;
  get_only_by_string?: string[];
  only_ids?: number[];
  lang?: string;
};

const axios = new Axios();

export async function getPositions({
  search,
  limit,
  get_only_by_string,
  only_ids,
  lang,
}: getPositionsType): Promise<cdnPositionType[]> {
  try {
    const response = await axios.request({
      endpoint: `cdn/get-positions?
            ${lang ? `lang=${lang}&` : ""}
            ${search ? `search=${search}&` : ""}
            ${limit ? `limit=${limit}&` : ""}
            ${only_ids ? `only_ids=${JSON.stringify(only_ids)}&` : ""}
            ${
              get_only_by_string
                ? `get_only_by_string=${JSON.stringify(get_only_by_string)}&`
                : ""
            }
            `,
      type: "get",
    });

    if (response && response.data && response.data.positions) {
      return JSON.parse(JSON.stringify(response.data.positions));
    } else {
      return [];
    }
  } catch (error) {
    console.log(`Positions fetch failed. Error:${error}`);
    return [];
  }
}
