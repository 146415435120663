import { useEffect, useState } from "react";
import { cardStateType } from "..";
import creditCardType from "credit-card-type";
import { RiVisaLine } from "react-icons/ri";
import { FcSimCardChip } from "react-icons/fc";
import { SiAmericanexpress } from "react-icons/si";
import { FaCcMastercard } from "react-icons/fa";

type creditCardBindType = {
  niceType: string;
  type: string;
};

export default function CreditCard({
  cardState,
}: {
  cardState: cardStateType;
}) {
  const [side, setSide] = useState<"front" | "back">("front");
  const [cardBin, setCardBin] = useState<string>("");
  const [cardBinStyle, setCardBinStyle] = useState<string>(
    "from-gray-700 to-gray-500"
  );

  useEffect(() => {
    setSide(cardState.focused);

    const cards: creditCardBindType[] = creditCardType(cardState.number);

    if (cards.length == 1) {
      setCardBin(cards[0].type);
    } else {
      setCardBin("");
    }
  }, [cardState]);

  useEffect(() => {
    console.log("visa", cardBin);
    if (cardBin == "visa") {
      setCardBinStyle("from-mains to-blue-500");
    } else if (cardBin == "american-express") {
      setCardBinStyle("from-green-700 to-green-500");
    } else if (cardBin == "mastercard") {
      setCardBinStyle("from-orange-700 to-orange-500");
    } else {
      setCardBinStyle("from-gray-700 to-gray-500");
    }
  }, [cardBin]);

  return (
    <div className="flex flex-col gap-2 w-full font-poppins p-10">
      <div className="relative w-full h-full max-w-[400px] aspect-video group [perspective:1000px] text-white">
        <div
          className={`absolute bg-gradient-to-tr ${cardBinStyle} top-0 left-0 w-full h-full p-2 rounded-xl ${
            side == "front"
              ? "group-hover:[transform:rotateY(180deg)] [transform:rotateY(0deg)]"
              : "group-hover:[transform:rotateY(0deg)] [transform:rotateY(180deg)]"
          }  [backface-visibility:hidden] transition-all duration-500`}
        >
          <div className="absolute top-4 right-4">
            {cardBin == "visa" ? (
              <RiVisaLine className="text-4xl" />
            ) : cardBin == "american-express" ? (
              <SiAmericanexpress className="text-4xl" />
            ) : cardBin == "mastercard" ? (
              <FaCcMastercard className="text-4xl" />
            ) : null}
          </div>
          <div className="absolute top-4 left-4">
            <FcSimCardChip className="text-4xl" />
          </div>
          <div className="flex flex-col px-4">
            <label
              className={`mt-[25%] w-full text-base ${
                cardState.number.length > 0 ? "text-white" : "text-gray-300"
              }`}
            >
              {cardState.number.length > 0 ? cardState.number : "Kart Numarası"}
            </label>
            <div className="flex flex-row w-full items-end justify-between mt-4 gap-4">
              <label
                className={`line-clamp-1 w-full text-sm ${
                  cardState.name.length > 0 ? "text-white" : "text-gray-300"
                }`}
              >
                {cardState.name.length > 0
                  ? cardState.name.toLocaleUpperCase("tr-TR")
                  : "Adınız Soyadınız"}
              </label>
              <div className="flex flex-col w-full items-start gap-1">
                {/* <label
                  className={`text-start w-full text-[10px] ${
                    cardState.expiry.length > 0 ? "text-white" : "text-gray-300"
                  }`}
                >
                  AA/YY
                </label> */}
                <label
                  className={`text-start w-full text-xs ${
                    cardState.expiry.length > 0 ? "text-white" : "text-gray-300"
                  }`}
                >
                  {cardState.expiry.length > 0 ? cardState.expiry : "Ay/Yıl"}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`absolute top-0 left-0 w-full h-full rounded-xl ${
            side == "front"
              ? "rotate group-hover:[transform:rotateY(0deg)] [transform:rotateY(-180deg)]"
              : "rotate group-hover:[transform:rotateY(-180deg)] [transform:rotateY(0deg)]"
          } [backface-visibility:hidden] bg-mains transition-all duration-500`}
        >
          <div className="flex flex-col">
            <div className="mt-12 h-10 bg-black"></div>
            <div className="flex flex-row gap-2 mt-4 items-center w-full px-4">
              <div className="flex flex-1 bg-white text-transparent">.</div>
              <div className="flex w-20">
                <label
                  className={`text-xs ${
                    cardState.cvc.length > 0 ? "text-white" : "text-gray-300"
                  }`}
                >
                  {cardState.cvc.length > 0 ? cardState.cvc : "CVC"}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
