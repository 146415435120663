import { LabelHTMLAttributes } from "react"
import { useContext } from "react"
import { SelectDropdownContext, selectDropdownContextType } from "."






type labelPropsType = LabelHTMLAttributes<HTMLLabelElement> & {

}







export default function Label({
    className,
    children,
    ...props
}: labelPropsType) {

    const { required, setIsOpen }: selectDropdownContextType = useContext(SelectDropdownContext)
    
    return(
        <label className={`${className} text-[13px] text-gray-700 font-semibold font-poppins`} {...props} onClick={() => setIsOpen((prev) => !prev)}>
            {children} {required? (
                <b className="text-red-600">*</b>
            ): null}
        </label>
    )
}