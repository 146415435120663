import Axios from "../services/axios";
import { cdnSectorType } from "../types";

type getSectorsPropsType = {
  search?: string;
  limit?: number;
  get_only_by_string?: string[];
  only_ids?: number[];
  lang?: string;
};

const axios = new Axios();

export async function getSectors({
  search,
  limit,
  get_only_by_string,
  only_ids,
  lang,
}: getSectorsPropsType): Promise<cdnSectorType[]> {
  try {
    const response = await axios.request({
      endpoint: `cdn/get-sectors?
            ${lang ? `lang=${lang}&` : ""}
            ${search ? `search=${search}&` : ""}
            ${limit ? `limit=${limit}&` : ""}
            ${
              get_only_by_string
                ? `get_only_by_string=${JSON.stringify(get_only_by_string)}&`
                : ""
            }
            ${only_ids ? `only_ids=${JSON.stringify(only_ids)}` : ""}
            `,
      type: "get",
    });

    if (response && response.data && response.data.sectors) {
      return JSON.parse(JSON.stringify(response.data.sectors));
    } else {
      return [];
    }
  } catch (error) {
    console.log(`Sectors feetch failed. Error: ${error}`);
    return [];
  }
}
