import Axios from "../services/axios";
import { cdnExperienceLevelType } from "../types";





type getExperienceLevelsPropsType = {
    search?: string,
    limit?: number,
    only_ids?: number[]
}



const axios = new Axios()



export async function getExperienceLevels({
    limit,
    only_ids,
    search
}: getExperienceLevelsPropsType): Promise<cdnExperienceLevelType[]> {
    try {
        const response = await axios.request({
            endpoint: `cdn/get-experience-levels?
            ${search? `search=${search}&`: ''}
            ${limit? `limit=${limit}&`: ''}
            ${only_ids? `only_ids=${JSON.stringify(only_ids)}&`: ''}
            `,
            type: 'get'
        })

        if (response && response.data && response.data.experience_levels) {
            return JSON.parse(JSON.stringify(response.data.experience_levels))
        } else {
            return []
        }
    } catch (error) {
        console.log(`Experience levels fetch failed. Error: ${error}`)
        return []
    }
}