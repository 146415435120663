import React from "react";
import Contents from "./contents";
import Contents2 from "./contents2";
import ConfirmSms from "./confirm-sms";
import { useAppSelector } from "../../../store/store";

const Settings: React.FC = () => {
  const renderSmsPage = useAppSelector(
    (state) => state.renderSmsPage.smsContentRender
  );
  console.log(renderSmsPage);

  return <>{renderSmsPage ? <ConfirmSms /> : <Contents2 />}</>;
};

export default Settings;
