import { SelectComponent } from "../../../components/select-component-new";
import useFetchCdnQuery from "../../../../../../../../hooks/useFetchCdnQuery";
import React, { useContext, useEffect, useState } from "react";
import {
  ApplyPoolContext,
  CandidatePoolFilterProps,
} from "../../../../../index";
import MultiSelect from "../../../components/multi-select-component-new";

interface ExperienceSectionProps {
  isVisible: boolean;
}

function ExperienceSection({ isVisible }: ExperienceSectionProps) {
  const { newSelectedValues, setNewSelectedValues } =
    useContext(ApplyPoolContext);

  // workingTypes data
  const [workingTypeData, setWorkingTypeData] = useState<any>([]);
  const [workingTypeSearchTerm, setWorkingTypeSearchTerm] = useState("");
  const workingTypesQuery = useFetchCdnQuery(
    "workingTypes",
    "get-working-types",
    "working_types",
    workingTypeSearchTerm
  );
  useEffect(() => {
    if (workingTypesQuery.data !== undefined) {
      setWorkingTypeData([...workingTypesQuery?.data]);
    }
  }, [workingTypesQuery.data, workingTypesQuery.fetchStatus]);

  //sectors data
  const [sectorData, setSectorData] = useState<any>([]);
  const [sectorSearchTerm, setSectorSearchTerm] = useState("");
  const sectorsQuery = useFetchCdnQuery(
    "sectors",
    "get-sectors",
    "sectors",
    sectorSearchTerm
  );
  useEffect(() => {
    if (sectorsQuery.data !== undefined) {
      setSectorData([...sectorsQuery?.data]);
    }
  }, [sectorsQuery.data, sectorsQuery.fetchStatus]);

  //sections data
  const [sectionData, setSectionData] = useState<any>([]);
  const [sectionSearchTerm, setSectionSearchTerm] = useState("");
  const sectionsQuery = useFetchCdnQuery(
    "sections",
    "get-sections",
    "sections",
    sectionSearchTerm
  );
  useEffect(() => {
    if (sectionsQuery.data !== undefined) {
      setSectionData([...sectionsQuery?.data]);
    }
  }, [sectionsQuery.data, sectionsQuery.fetchStatus]);

  // salary expectations data
  const [salaryExpectationData, setSalaryExpectationData] = useState<any>([]);
  const salaryExpectationsQuery = useFetchCdnQuery(
    "salaryExpectations",
    "get-salary-expectation",
    "salary_expectations"
  );
  useEffect(() => {
    if (salaryExpectationsQuery.data !== undefined) {
      setSalaryExpectationData([...salaryExpectationsQuery?.data]);
    }
  }, [salaryExpectationsQuery.data, salaryExpectationsQuery.fetchStatus]);

  const experienceData: any = Array.from({ length: 19 }, (_, i) => ({
    id: i + 1,
    title: `${i + 1} Yıl`,
  }));
  experienceData.unshift({ id: 41, title: `Deneyimsiz / Yeni Mezun` });
  experienceData.unshift({ id: 40, title: `Tümü` });

  //working status
  const workingStatusData: any = [
    { title: "Çalışıyor", id: 2 },
    { title: "Çalışmıyor", id: 1 },
  ];

  return (
    <section className={`text-gray-600 mx-2 my-8 ${isVisible ? "" : "hidden"}`}>
      <div className="grid md:grid-cols-3 grid-cols-1 gap-5 flex-col md:flex-row md:w-full">
        <MultiSelect
          isFetching={workingTypesQuery.isFetching}
          keyString={"title"}
          data={workingTypeData}
          label="Çalışma Şekli Seçiniz"
          onChange={(value) => {
            let newValues: CandidatePoolFilterProps = {
              ...newSelectedValues,
              working_types: value,
            };
            if (value.length <= 0) {
              delete newValues.working_types;
            }
            setNewSelectedValues(newValues);
          }}
          selectedValues={
            newSelectedValues.working_types
              ? newSelectedValues.working_types
              : []
          }
          setSearchData={setWorkingTypeSearchTerm}
          searchData={workingTypeSearchTerm}
        />
        <MultiSelect
          isFetching={sectorsQuery.isFetching}
          keyString={"title"}
          data={sectorData}
          label="Deneyim Sektörü"
          onChange={(value) => {
            let newValues: CandidatePoolFilterProps = {
              ...newSelectedValues,
              sectors: value,
            };
            if (value.length <= 0) {
              delete newValues.sectors;
            }
            setNewSelectedValues(newValues);
          }}
          selectedValues={
            newSelectedValues.sectors ? newSelectedValues.sectors : []
          }
          setSearchData={setSectorSearchTerm}
          searchData={sectorSearchTerm}
        />
        <MultiSelect
          isFetching={sectionsQuery.isFetching}
          keyString={"title"}
          data={sectionData}
          label="Deneyim Bölümü"
          onChange={(value) => {
            let newValues: CandidatePoolFilterProps = {
              ...newSelectedValues,
              sections: value,
            };
            if (value.length <= 0) {
              delete newValues.sections;
            }
            setNewSelectedValues(newValues);
          }}
          selectedValues={
            newSelectedValues.sections ? newSelectedValues.sections : []
          }
          setSearchData={setSectionSearchTerm}
          searchData={sectionSearchTerm}
        />
      </div>
      <div className="flex gap-x-5 gap-y-3 mb-8 mt-2 flex-col w-full md:grid md:grid-cols-3 md:w-full">
        <SelectComponent
          data={workingStatusData}
          keyString={"title"}
          label="Çalışma Durumu"
          placeholder="Seçiniz"
          onChange={(value) => {
            let newValues: CandidatePoolFilterProps = {
              ...newSelectedValues,
              now_working: value as number,
            };
            if (value === 0) {
              delete newValues.now_working;
            }
            setNewSelectedValues(newValues);
          }}
          selectedvalue={newSelectedValues.now_working}
        />
        <SelectComponent
          checkWithValue
          data={salaryExpectationData}
          keyString={"title"}
          label="Min Ücret Beklentisi"
          placeholder="Seçiniz"
          onChange={(value) => {
            let newValues: CandidatePoolFilterProps = {
              ...newSelectedValues,
              salary_expectation_min: value as number,
            };
            if (value === 0) {
              delete newValues.salary_expectation_min;
            }
            setNewSelectedValues(newValues);
          }}
          selectedvalue={newSelectedValues.salary_expectation_min}
        />
        <SelectComponent
          checkWithValue
          data={salaryExpectationData}
          keyString={"title"}
          label="Max Ücret Beklentisi"
          placeholder="Seçiniz"
          onChange={(value) => {
            let newValues: CandidatePoolFilterProps = {
              ...newSelectedValues,
              salary_expectation_max: value as number,
            };
            if (value === 0) {
              delete newValues.salary_expectation_max;
            }
            setNewSelectedValues(newValues);
          }}
          selectedvalue={newSelectedValues.salary_expectation_max}
        />
        <SelectComponent
          data={experienceData}
          keyString={"title"}
          label="Toplam İş Deneyimi"
          placeholder="Seçiniz"
          onChange={(value) => {
            let min, max, newValues: CandidatePoolFilterProps;

            if (value === 40 || value === 41) {
              min = 0;
              max = value === 40 ? 25 : 0;
              newValues = {
                ...newSelectedValues,
                total_work_experience_min: min,
                total_work_experience_max: max,
              };
            } else {
              newValues = {
                ...newSelectedValues,
                total_work_experience_min: value as number,
              };
            }

            if (value === 0) {
              delete newValues.total_work_experience_min;
              delete newValues.total_work_experience_max;
            }
            if (value !== 40 && value !== 41) {
              delete newValues.total_work_experience_max;
            }
            setNewSelectedValues(newValues);
          }}
          selectedvalue={
            newSelectedValues.total_work_experience_min === undefined
              ? undefined
              : newSelectedValues.total_work_experience_min === 0 &&
                newSelectedValues.total_work_experience_max === 25
              ? 40
              : newSelectedValues.total_work_experience_min === 0 &&
                newSelectedValues.total_work_experience_max === 0
              ? 41
              : newSelectedValues.total_work_experience_min
          }
        />
      </div>
    </section>
  );
}

export { ExperienceSection };
