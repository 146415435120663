import React from 'react';
import Breadcrump from '../../../../components/common/profile/breadcrump';
import List from './list';




const Body: React.FC = () => {

 

  return (
    <div className='w-full flex flex-col gap-y-6'>
      <List/>

    </div>
  );
};

export default Body;
