import React, { ReactNode } from "react";
import { AccordionProvider, useAccordion } from "./AccordionContext";

interface AccordionProps {
  children: ReactNode;
  initialOpenIndex?: number;
}

interface ItemProps {
  index: number;
  children: ReactNode;
}

interface TriggerProps {
  children: ReactNode;
  index: number;
}

interface ContentProps {
  children: ReactNode;
  index: number;
}

const Accordion: React.FC<AccordionProps> & {
  Item: React.FC<ItemProps> & {
    Trigger: React.FC<TriggerProps>;
    Content: React.FC<ContentProps>;
  };
} = ({ children, initialOpenIndex }) => {
  return (
    <AccordionProvider initialOpenIndex={initialOpenIndex}>
      <div className="accordion">{children}</div>
    </AccordionProvider>
  );
};

const Item: React.FC<ItemProps> & {
  Trigger: React.FC<TriggerProps>;
  Content: React.FC<ContentProps>;
} = ({ index, children }) => {
  return <div className="accordion-item">{children}</div>;
};

const Trigger: React.FC<TriggerProps> = ({ children, index }) => {
  const { openIndex, toggleOpen } = useAccordion();

  const handleClick = () => {
    toggleOpen(index);
  };

  return (
    <div
      onClick={handleClick}
      className="accordion-trigger flex items-center justify-between cursor-pointer p-4 bg-gray-100 border border-gray-300"
    >
      {children}
      <span
        className={`transform transition-transform ${
          openIndex === index ? "rotate-180" : "rotate-0"
        }`}
      >
        ▼
      </span>
    </div>
  );
};

const Content: React.FC<ContentProps> = ({ children, index }) => {
  const { openIndex } = useAccordion();

  return (
    <div
      className={`accordion-content overflow-hidden transition-all duration-700 ease-in-out ${
        openIndex === index ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
      }`}
    >
      <div className="p-4 bg-white border border-t-0 border-gray-300">
        {children}
      </div>
    </div>
  );
};

Item.Trigger = Trigger;
Item.Content = Content;
Accordion.Item = Item;

export default Accordion;
