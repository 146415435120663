import React from "react";
import GeneralInfo from "../components/GeneralInfo";
import Why from "../components/Why";
import CreateResume from "../components/CreateResume";
import PostJobAd from "../components/PostJobAd";
import ReadMore from "../components/ReadMore";
import LatestArticles from "../components/LatestArticles";
import ApplyPostingsNow from "../components/ApplyPostingsNow";

interface Position {
  title: string;
  slug: string;
  min_salary: number;
  max_salary: number;
  detail: string;
  avg_salary: number;
  calculate_salary: number;
  calculate_university: number;
  created_at: string;
  order: number;
}

interface OverviewProps {
  positionData: Position[];
}

const WhySection: React.FC<OverviewProps> = (positionData) => {
  return (
    <div className="flex justify-center  mb-2">
      <div className="flex flex-wrap  w-full xl:w-3/4 lg:w-[90%] mt-8">
        {/* Left Column with Cards */}
        <div className="w-full md:w-full  lg:w-[60%] space-y-4 mb-4 md:px-8 ">
          <GeneralInfo positionData={positionData.positionData} />
          <Why />
          <CreateResume positionData={positionData.positionData} />
          <PostJobAd positionData={positionData.positionData} />
          <ReadMore positionData={positionData.positionData} />
        </div>

        {/* Right Column with Cards */}
        <div className="w-full md:w-full lg:w-[40%] space-y-4 md:px-8">
          <CreateResume positionData={positionData.positionData} />
          <LatestArticles />
          <ApplyPostingsNow />
        </div>
      </div>
    </div>
  );
};

export default WhySection;
