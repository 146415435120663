import React from "react";
import HeaderTitle from "../header-title";
import CheckboxInfo from "../checkbox-info";
import CustomButton from "../custom-button";
import { useAppSelector, useAppDispatch } from "../../../../../store/store";
import { INotificationItem } from "../../../../../store/content-reducer/notificationSlice";
import { postNotification } from "../../../../../store/content-reducer/notificationSlice";
import Swal from "sweetalert2";

const EmailNotification = () => {
  const notification: INotificationItem[] = useAppSelector<INotificationItem[]>(
    (state: any) => state.notification
  );
  const dispatch = useAppDispatch();

  const emailInfo: string[] = ["r01"];
  const advertInfo: string[] = ["r02", "r03", "r04", "r05"];
  const accountInfo: String[] = ["r06", "r07", "r08"];
  const smsInfo: String[] = ["r09"];
  const offerInfo: String[] = ["r010", "r11", "r12", "r13", "r14"];

  const [checked, setChecked] = React.useState<{
    [key: string]: number | null;
  }>({});
  const [prevChecked, setPrevChecked] = React.useState<{
    [key: string]: number | null;
  }>({});

  React.useEffect(() => {
    const initialCheckedState: { [key: string]: number | null } = {};
    notification
      .filter((item: INotificationItem) => item.code !== "sms")
      .forEach((item: any) => {
        initialCheckedState[item.code] = item.status !== null ? item.status : 0;
      });
    setChecked(initialCheckedState);
    setPrevChecked(initialCheckedState);
  }, [notification]);

  const handleValues = (code: string) => {
    setChecked((prev) => {
      const currentValue = prev[code];
      const newValue = currentValue === 1 ? 0 : 1;
      return {
        ...prev,
        [code]: newValue,
      };
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(postNotification(checked));
    console.log(checked);
  };

  const handleSave = () => {
    const statusChanged = Object.keys(checked).some(
      (key) => checked[key] !== prevChecked[key]
    );

    if (!statusChanged) {
      Swal.fire({
        title: "Bilgilendirme",
        text: "Durum değişmediği için güncelleme yapılmadı.",
        icon: "info",
        confirmButtonText: "Tamam",
      });
      return;
    }

    Swal.fire({
      text: "E-posta bildirim seçimleriniz başarıyla güncellenmiştir.",
      icon: "success",
      confirmButtonText: "Tamam",
    });
    setPrevChecked(checked); // Güncellenen durumu kaydediyoruz
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className={`mx-[10px] flex flex-col gap-4 mt-4 border-t-2 border-gray-200`}
      >
        {notification
          .filter((item) => emailInfo.includes(item.code))
          .map((item: INotificationItem) => (
            <CheckboxInfo
              key={item.code}
              content={item.description}
              isChecked={checked[item.code]}
              isOnChange={() => handleValues(item.code)}
            />
          ))}
        <HeaderTitle title="İLAN BİLGİLENDİRMELERİ" />
        {notification
          .filter((item) => advertInfo.includes(item.code))
          .map((item: INotificationItem) => (
            <CheckboxInfo
              key={item.code}
              content={item.description}
              isChecked={checked[item.code]}
              isOnChange={() => handleValues(item.code)}
            />
          ))}
        <HeaderTitle title="ÖZGEÇMİŞ VE HESAP BİLGİLENDİRMELERİ" />
        {notification
          .filter((item) => accountInfo.includes(item.code))
          .map((item: INotificationItem) => (
            <CheckboxInfo
              key={item.code}
              content={item.description}
              isChecked={checked[item.code]}
              isOnChange={() => handleValues(item.code)}
            />
          ))}
        <HeaderTitle title="MESAJ BİLGİLENDİRMELERİ" />
        {notification
          .filter((item) => smsInfo.includes(item.code))
          .map((item: INotificationItem) => (
            <CheckboxInfo
              key={item.code}
              content={item.description}
              isChecked={checked[item.code]}
              isOnChange={() => handleValues(item.code)}
            />
          ))}
        <HeaderTitle title="KAMPANYA VE PROMOSYONLAR BİLGİLENDİRMELERİ" />
        {notification
          .filter((item) => offerInfo.includes(item.code))
          .map((item: INotificationItem) => (
            <CheckboxInfo
              key={item.code}
              content={item.description}
              isChecked={checked[item.code]}
              isOnChange={() => handleValues(item.code)}
            />
          ))}
        <div className="flex w-full justify-end">
          <div>
            <CustomButton
              handleClick={() => handleSave()}
              title="KAYDET"
              designs="py-2 px-3 custom-btn"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default EmailNotification;
