import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { addOrUpdateQueryParam } from "../../../../../../hooks/useAddOrUpdateQueryParam";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { JobsPageContext } from "../../../index";
import { PaginationData } from "../../../../../company-page-container";

const PaginationMobile: React.FC<{
  pagination: PaginationData | null;
  onChange?: () => void;
}> = ({ pagination, onChange }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [totalPage, setTotalPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageNumbers, setPageNumbers] = useState<number[]>([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const page = Number(searchParams.get("page")) || 1;
    const lastPage = pagination?.last_page || 1;

    setCurrentPage(page);
    setTotalPage(lastPage);
  }, [pagination, location.search]);

  const changePageNumber = (pageNumber: number) => {
    const searchPath = addOrUpdateQueryParam(location, [
      { key: "page", value: pageNumber.toString() },
    ]);

    navigate(location.pathname + searchPath);
  };

  return (
    <div className="flex flex-row items-center gap-2 justify-center">
      {currentPage != 1 && (
        <button
          className="flex flex-row bg-gray-100 border rounded-md border-gray-500 p-2 items-center gap-2"
          onClick={() => {
            changePageNumber(currentPage - 1);
            onChange && onChange();
          }}
        >
          <IoChevronBack />
          Önceki
        </button>
      )}

      <select
        className="bg-gray-100 p-2 border border-gray-500 rounded-md"
        value={currentPage || 1}
        onChange={(e) => {
          changePageNumber(parseInt(e.target.value));
          onChange && onChange();
        }}
      >
        {Array.from({ length: totalPage }, (_, index) => index + 1).map(
          (page) => {
            return (
              <option key={`pageNumber${page}`} value={page}>
                {currentPage == page ? `${page}/${totalPage}` : `${page}`}
              </option>
            );
          }
        )}
      </select>
      {currentPage != totalPage && (
        <button
          className="flex flex-row bg-gray-100 border rounded-md border-gray-500 p-2 items-center gap-2"
          onClick={() => {
            changePageNumber(currentPage + 1);
            onChange && onChange();
          }}
        >
          Sonraki
          <IoChevronForward />
        </button>
      )}
    </div>
  );
};

export default PaginationMobile;
