import React from "react";
import HeaderButton from "../../../components/header-button";

const HeaderButtons: React.FC = () => {
  return (
    <div className="hidden lg:flex h-full items-center xl:gap-x-5 z-50">
      <HeaderButton
        variant="text"
        text="Ana Sayfa"
        isLoginRequire={false}
        href="/"
      />
      <HeaderButton
        variant="text"
        text="İş İlanları"
        href="/is-ilanlari"
        isLoginRequire={false}
      />
      <HeaderButton
        variant="text"
        text="CV Oluştur"
        isLoginRequire={false}
        href="/profilim/kayit-ol"
      />
      <HeaderButton
        variant="text"
        text="İşveren"
        isLoginRequire={true}
        href="/isveren"
      />
      <HeaderButton
        variant="text"
        text="İş Arayan"
        isLoginRequire={true}
        href="/profilim"
      />
      {/* <HeaderButton
        variant="text"
        text="Ücretsiz İlan Ver"
        isLoginRequire={false}
        href="/isveren/giris-yap"
      /> */}
    </div>
  );
};

export default HeaderButtons;
